import { commonDebug } from "./../../bootstrap";
import { ACTION_NAMES } from "./../../shared/commonConstants";
import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { CarouselSlide } from "../cug-portal/widgets/widget-carousel";
import { getStringForCulture } from "../../component-helpers/stringHelper";
import { useCug2AppContext } from "../../managers/useCug2AppContext";
import { useReduxState } from "../../shared/redux/useReduxState";

export const name = "ac-cug-footer";

const noDisplayActions = [ACTION_NAMES.SUMMARY];

export const Component: HauntedFunc<{}> = () => {
    // HELPERS

    const shouldDisplay = () =>
        !noDisplayActions.some((a) => a.toLowerCase() === cug2AppContext.Action) &&
        userContext.peruCompra.role === "none" &&
        userContext.chileCompra.role === "none";

    const slides = (): CarouselSlide[] => {
        const widget = cug2AppContext.Cug2BSettings?.FooterWidget;
        const slidesForType =
            cug2AppContext.Type === "agency"
                ? widget?.AgencySlides || widget?.CompanySlides
                : widget?.CompanySlides || widget?.AgencySlides;

        if (!slidesForType || slidesForType?.length === 0) {
            commonDebug.error("No slides for the CUG2b footer widget.");
            return [];
        }

        return slidesForType.map((slide): CarouselSlide => {
            const desktop = getStringForCulture(slide.Images.DesktopImageUrl, cug2AppContext.Culture);
            const tablet = getStringForCulture(slide.Images.TabletImageUrl, cug2AppContext.Culture);
            const mobile = getStringForCulture(slide.Images.MobileImageUrl, cug2AppContext.Culture);

            return {
                desktopImage: desktop || tablet || mobile,
                tabletImage: tablet || desktop || mobile,
                mobileImage: mobile || tablet || desktop,
                url: getStringForCulture(slide.LinkUrl, cug2AppContext.Culture),
            };
        });
    };

    // EVENT LISTENERS

    // COMPONENT

    const cug2AppContext = useCug2AppContext();

    const [userContext] = useReduxState("userContext");

    // TEMPLATES

    return shouldDisplay()
        ? html`
              <div class="cug2b-footer">
                  <ac-cug-widget-carousel .slides=${slides()}></ac-cug-widget-carousel>
              </div>
          `
        : html``;
};
