import { html } from "lit-html";
import { PerJourneyPerPaxPassengerDto } from "./per-journey-per-pax-passenger";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useBookingContext } from "../../../../managers/useBookingContext";

export interface Props {
    canAdd: boolean;
    dto: PerJourneyPerPaxPassengerDto;
    isMobile: boolean;
    onAdd: (e: MouseEvent) => void;
    onRemove: (e: MouseEvent) => void;
}

export const useQuantityChanger = (props: Props) => {
    const bookingContext = useBookingContext();

    const isRemoveAvailable = () =>
        props.dto.baggageSection.perJourneyPerPax.isRemoveAvailable({
            bookingContext,
            journeyIndex: props.dto.journey.index,
            passenger: props.dto.passenger,
        });

    const htmlTemplate = () =>
        props.dto.bagType !== "cabinBaggage" && props.dto.passenger.quantity > 0
            ? html`
                  <div class="b2-per-pax-quantity">
                      <button
                          class="b2-quantity-button"
                          data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_REMOVE_BUTTON, {
                              c: props.dto.bagType,
                              j: props.dto.journey.index,
                              m: props.isMobile,
                              p: props.dto.passenger.index,
                          })}
                          .disabled=${!isRemoveAvailable}
                          @click=${props.onRemove}
                      >
                          &minus;
                      </button>
                      <span
                          class="b2-per-booking-quantity"
                          data-test-value=${props.dto.passenger.quantity}
                          data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_QUANTITY, {
                              c: props.dto.bagType,
                              j: props.dto.journey.index,
                              m: props.isMobile,
                              p: props.dto.passenger.index,
                          })}
                      >
                          ${props.dto.passenger.quantity}
                      </span>
                      <button
                          class="b2-quantity-button"
                          data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_ADD_BUTTON, {
                              c: props.dto.bagType,
                              j: props.dto.journey.index,
                              m: props.isMobile,
                              p: props.dto.passenger.index,
                          })}
                          .disabled=${!props.canAdd}
                          @click=${props.onAdd}
                      >
                          &plus;
                      </button>
                  </div>
              `
            : "";

    return { htmlTemplate };
};
