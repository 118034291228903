import dayjs from "dayjs";
import i18next from "i18next";
import { html } from "lit-html";
import { sanitizeInputFieldValue } from "../../component-helpers/InputSanitizerHelper";
import { AlternateCode, Card as ApiCard } from "../../component-models/PaymentMethodDescriptors";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { padWithLeadingZeros, sanitizeRutFieldValue, SelectOption } from "../../shared/common";
import { AGENCY_PAYMENT_FOP_CODE, DEFAULT_DATE_FORMAT, PARAGUAYAN_CULTURE_CODE } from "../../shared/commonConstants";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { useInputCardNumber } from "./useInputCardNumber";
import { useInstallments } from "./useInstallments";
import { useAppContext } from "../../managers/useAppContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { CreditCardValidationResult } from "../../component-models/payment/CreditCardValidationResult";
import { paymentHelper } from "../../component-helpers/payment/PaymentHelper";
import classNames from "classnames";
import { useFluentValidator } from "../../validator/FluentValidator";
import { CardData, DEFAULT_CARD_DATA } from "../../component-models/payment/CardData";
import { Validation } from "../../validator/Validation";
import { validateDni, validateRut } from "../../shared/form-validation";

export type CardValidationStatus = "unknown" | "valid" | "invalid";

type FieldNames = keyof CardData;

export interface Props {
    isValidated: boolean;
    model: PaymentPageViewModel;
    handleInstallmentNumberChange: (newTotal: number) => void;
}

export const useCard = (props: Props) => {
    const appContext = useAppContext();

    const { isCardNumberLengthValid, validateCreditCard, isCardInvalidForCountryAndCurrency } = paymentHelper();

    const [userContext] = useReduxState("userContext");
    const [payerData] = useReduxState("payment.payer");
    const [selectedPaymentMethod] = useReduxState("payment.paymentMethod");
    const [selectedCurrency, setSelectedCurrency] = useReduxState("payment.selectedCurrency");
    const [cardData, setCardData] = useReduxState("payment.cardData");

    const [year, setYear] = useState<number>(undefined);
    const [month, setMonth] = useState<number>(undefined);

    const inputCardNumber = useInputCardNumber({
        isCardBancoEstado: cardData?.ValidationResult?.IsBancoEstado,
        isValidated: props.isValidated,
        model: props.model,
        setCardNumber: (value) => handleCardNumberChange(value),
    });

    const installments = useInstallments({
        amount: selectedCurrency && props.model ? props.model.AmountPerCurrency[selectedCurrency].Amount : 0,
        installmentType: selectedPaymentMethod?.InputFieldRequirements?.InstallmentType,
        model: props.model,
        handleInstallmentNumberChange: props.handleInstallmentNumberChange,
    });

    const validator = useFluentValidator<FieldNames, CardData>({
        vm: cardData,
        validated: props.isValidated,
        validations: [
            Validation.ruleFor("AntifraudIdNumber", (vm: CardData) => vm.AntifraudIdNumber)
                .when(() => showAntifraudIdFields())
                .isRequired(),
            Validation.ruleFor("AntifraudIdNumber", (vm: CardData) => vm.AntifraudIdNumber)
                .when((vm: CardData) => showAntifraudIdFields() && vm.AntifraudIdType === "N")
                .fulfils(
                    (id: string) => Promise.resolve(validateRut(id)),
                    i18next.t(
                        "Rut inválido, por favor verifica que el rut es correcto y que el formato sea válido. (Ej: 76472472-0)",
                    ),
                ),
            Validation.ruleFor("AntifraudIdNumber", (vm: CardData) => vm.AntifraudIdNumber)
                .when((vm: CardData) => showAntifraudIdFields() && vm.AntifraudIdType === "DNI")
                .fulfils(
                    (id: string) => Promise.resolve(validateDni(id, appContext.Culture)),
                    i18next.t("DNI invalido"),
                ),
            Validation.ruleFor("AntifraudIdType", (vm: CardData) => vm.AntifraudIdType)
                .when(() => showAntifraudIdFields())
                .isRequired(),
            Validation.ruleFor("Cvv", (vm: CardData) => vm.Cvv)
                .when(() => cardData?.ValidationResult?.Card?.IsCvvNeeded)
                .isRequired(),
            Validation.ruleFor("CardholderName", (vm: CardData) => vm.CardholderName)
                .when(() => isNameOnCardNeeded())
                .isRequired(),
            Validation.ruleFor("Ruc", (vm: CardData) => vm.Ruc)
                .when(() => isParaguay() && isNameOnCardNeeded())
                .isRequired(),
            Validation.ruleFor("Expiry", (vm: CardData) => vm.Expiry).fulfils(
                () => Promise.resolve(month !== undefined && year !== undefined),
                "",
            ),
        ],
    });

    const showAntifraudIdFields = () =>
        props.model?.AntiFraudShouldAskForDocument && selectedPaymentMethod?.ShouldCheckForAntifraud;

    const isNameOnCardNeeded = () => cardData?.ValidationResult?.Card?.IsNameOnCardNeeded;

    const handleCardNumberChange = (value: string) => {
        const validationResult = validateCreditCard(
            props.model,
            selectedPaymentMethod,
            value,
            appContext.Culture,
            appContext.BancoEstadoBins,
        );
        const alternateCode = getAlternatePaymentCode(validationResult);
        const fieldRequirements = getFieldRequirements(alternateCode);

        setCardData({
            ...cardData,
            CardNumber: value,
            AlternateCode: alternateCode,
            CardType: validationResult?.Card?.Type,
            FieldRequirements: fieldRequirements,
            IsNameNeeded: isNameOnCardNeeded(),
            CardValidationStatus: getIsCardValid(value, validationResult) ? "valid" : "invalid",
            FopBrand: validationResult?.FopBrand,
            IsCvvNeeded: validationResult?.Card?.IsCvvNeeded,
            PaymentMethodCodeToSubmit: alternateCode?.Code || validationResult?.PaymentMethodCodeToSubmit,
            ValidationResult: validationResult,
        });
    };

    const antifraudIdNumberSanitizer = (e: KeyboardEvent) => {
        if (cardData?.AntifraudIdType === "DNI") return sanitizeInputFieldValue(e, "numeric");
        if (cardData?.AntifraudIdType === "Passport") return sanitizeInputFieldValue(e, "alphanumeric");
        return sanitizeRutFieldValue(e.target as HTMLInputElement);
    };

    const handleAlternatePaymentCodeChange = () => {
        if (cardData?.AlternateCode?.ForcedCurrencies?.length > 0) {
            setSelectedCurrency(cardData?.AlternateCode.ForcedCurrencies[0]);
        }
    };

    const noCountryBlacklistForCode = (code: AlternateCode) =>
        !code.CardIssuerCountryBlacklist || code.CardIssuerCountryBlacklist.length === 0;

    const noCountryWhitelistForCode = (code: AlternateCode) =>
        !code.CardIssuerCountryWhitelist || code.CardIssuerCountryWhitelist.length === 0;

    const noCurrencyConstraintForCode = (code: AlternateCode) =>
        !code.SelectedCurrencies || code.SelectedCurrencies.length === 0;

    const doesCurrencyAllowAlternateCode = (code: AlternateCode) =>
        noCurrencyConstraintForCode(code) ||
        (code.SelectedCurrencies && code.SelectedCurrencies.includes(selectedCurrency));

    const doesCountryWhitelistAllowAlternateCode = (code: AlternateCode) =>
        noCountryWhitelistForCode(code) ||
        code.CardIssuerCountryWhitelist.includes(payerData?.CurrentCardIssuerCountry);

    const doesCountryBlacklistAllowAlternateCode = (code: AlternateCode) =>
        noCountryBlacklistForCode(code) ||
        !code.CardIssuerCountryBlacklist.includes(payerData?.CurrentCardIssuerCountry);

    const codeHasNoRuleset = (code: AlternateCode) =>
        noCountryBlacklistForCode(code) && noCountryWhitelistForCode(code) && noCurrencyConstraintForCode(code);

    const getCodeBasedOnCurrencyAndCardIssuerCountry = (card: ApiCard) =>
        card.AlternateCodes.find((code) => {
            if (codeHasNoRuleset(code)) return false;

            return (
                doesCountryWhitelistAllowAlternateCode(code) &&
                doesCountryBlacklistAllowAlternateCode(code) &&
                doesCurrencyAllowAlternateCode(code)
            );
        });

    const getCodeBasedOnCardIssuerOnly = (card: ApiCard) =>
        card.AlternateCodes.find((code) => {
            if (codeHasNoRuleset(code)) return false;

            return (
                noCurrencyConstraintForCode(code) &&
                doesCountryBlacklistAllowAlternateCode(code) &&
                doesCountryWhitelistAllowAlternateCode(code)
            );
        });

    const getCodeBasedOnCurrencyOnly = (card: ApiCard) =>
        card.AlternateCodes.find((code) => {
            if (codeHasNoRuleset(code)) return false;

            return (
                noCountryBlacklistForCode(code) &&
                noCountryWhitelistForCode(code) &&
                code.SelectedCurrencies &&
                code.SelectedCurrencies.includes(selectedCurrency)
            );
        });

    const getAlternatePaymentCode = (validationResult: CreditCardValidationResult): AlternateCode => {
        if (
            !payerData?.CurrentCardIssuerCountry ||
            !selectedCurrency ||
            !validationResult?.Card?.Type ||
            !selectedPaymentMethod?.SubmitCardCodeInsteadOfPaymentMethodCode
        ) {
            return undefined;
        }

        const currentCard = selectedPaymentMethod?.AllowedCards.find(
            (card) => card.Type === validationResult?.Card?.Type,
        );

        if (!currentCard) return undefined;

        return (
            getCodeBasedOnCurrencyAndCardIssuerCountry(currentCard) ||
            getCodeBasedOnCardIssuerOnly(currentCard) ||
            getCodeBasedOnCurrencyOnly(currentCard) ||
            undefined
        );
    };

    const isRequiredBancoEstadoCardError = (cardNumber: string, validationResult: CreditCardValidationResult) =>
        isCardNumberLengthValid(selectedPaymentMethod?.PaymentMethodCode, validationResult?.Card?.Type, cardNumber) &&
        [1, 2, 3, 5, 6, 7].includes(userContext.bancoEstado.category) &&
        !validationResult?.IsBancoEstado;

    const noCardValidationNeeded = () =>
        selectedPaymentMethod?.AllowedCards.length === 0 ||
        selectedPaymentMethod?.PaymentMethodCode === AGENCY_PAYMENT_FOP_CODE ||
        selectedPaymentMethod?.AllowUnrecognizedCardTypes;

    const getIsCardValid = (cardNumber: string, validationResult: CreditCardValidationResult) => {
        if (noCardValidationNeeded()) return true;

        if (isRequiredBancoEstadoCardError(cardNumber, validationResult)) return false;

        if (!validationResult?.Card?.Type || !validationResult?.IsValid) return false;

        if (
            !isCardNumberLengthValid(selectedPaymentMethod?.PaymentMethodCode, validationResult?.Card?.Type, cardNumber)
        ) {
            return false;
        }

        if (
            isCardInvalidForCountryAndCurrency(
                validationResult?.Card?.Type,
                selectedPaymentMethod?.AllowedCards,
                payerData?.CurrentCardIssuerCountry,
            )
        ) {
            return false;
        }

        return true;
    };

    const getFieldRequirements = (alternateCode: AlternateCode) =>
        alternateCode?.InputFieldRequirements
            ? {
                  ...selectedPaymentMethod?.InputFieldRequirements,
                  ...alternateCode.InputFieldRequirements,
              }
            : selectedPaymentMethod?.InputFieldRequirements;

    const trimCvv = (cvv: string) => cvv.slice(0, cardData?.ValidationResult?.Card?.Type === "AmEx" ? 4 : 3);

    const isParaguay = () => appContext.Culture === PARAGUAYAN_CULTURE_CODE;

    const validate = async () => {
        const isValid = await validator.validate();
        return getIsCardValid(cardData.CardNumber, cardData.ValidationResult) && isValid;
    };

    const reset = () => {
        setCardData({
            ...cardData,
            AlternateCode: DEFAULT_CARD_DATA.AlternateCode,
            AntifraudIdNumber: DEFAULT_CARD_DATA.AntifraudIdNumber,
            AntifraudIdType: DEFAULT_CARD_DATA.AntifraudIdType,
            CardholderName: DEFAULT_CARD_DATA.CardholderName,
            CardNumber: DEFAULT_CARD_DATA.CardNumber,
            CardType: DEFAULT_CARD_DATA.CardType,
            CardValidationStatus: DEFAULT_CARD_DATA.CardValidationStatus,
            Cvv: DEFAULT_CARD_DATA.Cvv,
            Expiry: DEFAULT_CARD_DATA.Expiry,
            FormattedExpiry: DEFAULT_CARD_DATA.FormattedExpiry,
            Ruc: DEFAULT_CARD_DATA.Ruc,
            SelectedInstallmentsNumber: DEFAULT_CARD_DATA.SelectedInstallmentsNumber,
            ValidationResult: DEFAULT_CARD_DATA.ValidationResult,
        });
    };

    // EVENT LISTENERS

    const handleAntifraudIdTypeChange = (value: "DNI" | "N" | "Passport") =>
        setCardData({ ...cardData, AntifraudIdType: value });

    const handleAntifraudIdNumberInput = (value: string) => setCardData({ ...cardData, AntifraudIdNumber: value });

    const handleRucInput = (value: string) => setCardData({ ...cardData, Ruc: value.trim() });

    const handleCardHolderNameInput = (value: string) => setCardData({ ...cardData, CardholderName: value });

    const handleCvvInput = (value: string) => setCardData({ ...cardData, Cvv: value });

    const handleMonthChange = (value: string) => setMonth(Number(value));

    const handleYearChange = (value: string) => setYear(Number(value));

    useEffect(() => {
        setYear(undefined);
        setMonth(undefined);
    }, [selectedPaymentMethod?.PaymentMethodCode]);

    useEffect(
        () => handleCardNumberChange(""),
        [selectedPaymentMethod?.PaymentMethodCode, payerData?.CurrentCardIssuerCountry],
    );

    useEffect(handleAlternatePaymentCodeChange, [cardData?.AlternateCode]);

    useEffect(() => {
        setCardData({
            ...cardData,
            PaymentMethodOptionId: installments.paymentMethodOptionId,
            MerchantAccountId: installments.merchantAccountId,
            SelectedInstallmentsNumber: installments.selectedInstallmentsNumber,
        });
    }, [installments.paymentMethodOptionId, installments.merchantAccountId, installments.selectedInstallmentsNumber]);

    useEffect(() => {
        const expiry =
            month !== undefined && year
                ? dayjs({ year, month, day: dayjs({ year, month, day: 1 }).endOf("month").daysInMonth() })
                : undefined;

        setCardData({ ...cardData, Expiry: expiry, FormattedExpiry: expiry?.format(DEFAULT_DATE_FORMAT) || "" });
    }, [year, month]);

    // TEMPLATES

    const inputCvvTemplate = () =>
        cardData?.ValidationResult?.Card?.IsCvvNeeded
            ? html`
                  <div class="col-xs-1 col-md-1-2">
                      <div class="relative mt-[20px]">
                          <ac-input
                              .errorMessage=${validator.getMessage("Cvv")}
                              .isInvalid=${!validator.isValid("Cvv")}
                              .label=${`${i18next.t("V2-CVV")} *`}
                              .testId=${getTestId(T.PAYMENT.CARD_CVV, {
                                  c: selectedPaymentMethod?.PaymentMethodCode,
                              })}
                              .value=${cardData.Cvv}
                              .sanitizer=${(e: KeyboardEvent) => {
                                  const newValue = sanitizeInputFieldValue(e, "numeric");
                                  return trimCvv(newValue);
                              }}
                              .onInput=${handleCvvInput}
                          ></ac-input>
                          <div class="cvv-tooltip">
                              ${i18next.t("V2-CVVInfo")}
                              <div class="cvv-tooltip-info">
                                  <div class="arrow-box">
                                      <img src="/Images/Icons/payment-types/payment-cvv-help.png" />
                                      <span>${i18next.t("V2-CVVTooltip")}</span>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const inputCardHolderNameTemplate = () =>
        isNameOnCardNeeded()
            ? html`
                  <div class=${classNames("col-xs-1", { "col-md-1-2": !isParaguay(), "col-md-1-3": isParaguay() })}>
                      <div class="relative mt-[20px] sm:mt-[35px]">
                          <ac-input
                              .errorMessage=${validator.getMessage("CardholderName")}
                              .isInvalid=${!validator.isValid("CardholderName")}
                              .label=${`${i18next.t("V2-NameOnCard")} *`}
                              .testId=${getTestId(T.PAYMENT.CARD_HOLDER_NAME, {
                                  c: selectedPaymentMethod?.PaymentMethodCode,
                              })}
                              .value=${cardData.CardholderName}
                              .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars")}
                              .onInput=${handleCardHolderNameInput}
                          ></ac-input>
                      </div>
                  </div>
              `
            : "";

    const monthTemplate = () => {
        const monthOptions: SelectOption[] = [
            { Text: "", Value: "", IsSelected: month === undefined, IsDisabled: true },
            ...[...Array(12)].map(
                (_, i): SelectOption => ({
                    Text: padWithLeadingZeros((i + 1).toString()),
                    Value: i.toString(),
                    IsSelected: i === month,
                }),
            ),
        ];

        return html`
            <label class="group-label">${i18next.t("V2-Validity")} *</label>

            <div class="mt-[35px]">
                <ac-select
                    .errorMessage=${""}
                    .isInvalid=${props.isValidated && month === undefined}
                    .label=${i18next.t("V2-Month")}
                    .options=${monthOptions}
                    .testId=${getTestId(T.PAYMENT.EXPIRY_MONTH, {
                        c: selectedPaymentMethod?.PaymentMethodCode,
                    })}
                    .onSelect=${handleMonthChange}
                >
                </ac-select>
            </div>
        `;
    };

    const yearTemplate = () => {
        const yearOptions: SelectOption[] = [
            { Text: "", Value: "", IsSelected: year === undefined, IsDisabled: true },
            ...[...Array(30)].map(
                (_, i): SelectOption => ({
                    Text: (dayjs().year() + i).toString(),
                    Value: (dayjs().year() + i).toString(),
                    IsSelected: dayjs().year() + i === year,
                }),
            ),
        ];

        return html`
            <div class="mt-[35px]">
                <ac-select
                    .errorMessage=${""}
                    .isInvalid=${props.isValidated && year === undefined}
                    .label=${i18next.t("V2-Year")}
                    .options=${yearOptions}
                    .testId=${getTestId(T.PAYMENT.EXPIRY_YEAR, {
                        c: selectedPaymentMethod?.PaymentMethodCode,
                    })}
                    .onSelect=${handleYearChange}
                >
                </ac-select>
            </div>
        `;
    };

    const inputExpiryTemplate = () => {
        const tempClassMap = classNames("col-xs-1", { "col-md-1-2": !isParaguay(), "col-md-1-3": isParaguay() });

        return html`
            <div class=${tempClassMap}>
                <div class="row">
                    <div class="col-xs-1-2">${monthTemplate()}</div>
                    <div class="col-xs-1-2">${yearTemplate()}</div>
                </div>
            </div>
        `;
    };

    const inputRucTemplate = () =>
        isParaguay() && isNameOnCardNeeded()
            ? html`
                  <div class="col-xs-1 col-md-1-3">
                      <div class="relative mt-[20px]">
                          <ac-input
                              .errorMessage=${validator.getMessage("Ruc")}
                              .isInvalid=${!validator.isValid("Ruc")}
                              .label=${`${i18next.t("ParaguayRucLabel")} *`}
                              .testId=${"paraguayRuc"}
                              .value=${cardData.Ruc}
                              .onInput=${handleRucInput}
                          ></ac-input>
                      </div>
                  </div>
              `
            : "";

    const antifraudIdTemplate = () => {
        const documentTypeOptions: SelectOption[] = [
            { Text: "", Value: "", IsSelected: !cardData.AntifraudIdType, IsDisabled: true },
            { Text: i18next.t("DNI"), Value: "DNI", IsSelected: cardData.AntifraudIdType === "DNI" },
            { Text: i18next.t("RUT"), Value: "N", IsSelected: cardData.AntifraudIdType === "N" },
            { Text: i18next.t("Pasaporte"), Value: "Passport", IsSelected: cardData.AntifraudIdType === "Passport" },
        ];

        return showAntifraudIdFields()
            ? html`
                  <div class="row">
                      <div class="col-xs-1 col-sm-1-2">
                          <div class="mt-[20px]">
                              <ac-select
                                  .errorMessage=${validator.getMessage("AntifraudIdType")}
                                  .isInvalid=${!validator.isValid("AntifraudIdType")}
                                  .label=${`${i18next.t("Document type")} *`}
                                  .options=${documentTypeOptions}
                                  .onSelect=${handleAntifraudIdTypeChange}
                              >
                              </ac-select>
                          </div>
                      </div>
                      <div class="col-xs-1 col-sm-1-2">
                          <div class="relative mt-[20px]">
                              <ac-input
                                  .errorMessage=${validator.getMessage("AntifraudIdNumber")}
                                  .isInvalid=${!validator.isValid("AntifraudIdNumber")}
                                  .isDisabled=${!cardData.AntifraudIdType}
                                  .label=${`${i18next.t("Document number")} *`}
                                  .value=${cardData.AntifraudIdNumber}
                                  .sanitizer=${antifraudIdNumberSanitizer}
                                  .onInput=${handleAntifraudIdNumberInput}
                              ></ac-input>
                              <span class="absolute right-[10px] top-[15px]">
                                  <ac-tooltip
                                      .icon=${"?"}
                                      .tooltip="${i18next.t(
                                          "Ingresa el número de documento de identidad del titular de la tarjeta",
                                      )}"
                                  ></ac-tooltip>
                              </span>
                          </div>
                      </div>
                  </div>
              `
            : "";
    };

    const htmlTemplate = () =>
        selectedPaymentMethod?.AllowedCards.length > 0
            ? html`
                  <form>
                      <div class="row">
                          <div class="col-xs-1 col-md-1-2">${inputCardNumber.htmlTemplate()}</div>
                          <div class="col-xs-1 col-md-1-2">${antifraudIdTemplate()}</div>
                      </div>
                      <div class="row">
                          ${inputCardHolderNameTemplate()} ${inputRucTemplate()} ${inputExpiryTemplate()}
                      </div>
                      <div class="row">${inputCvvTemplate()} ${installments.htmlTemplate()}</div>
                  </form>
              `
            : "";

    return {
        htmlTemplate,
        reset,
        validate,
    };
};
