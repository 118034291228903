import { html } from "haunted";
import i18next from "i18next";
import { PeruCompraMemberData } from "../../../component-models/PeruCompraMemberData";
import { CompanyInfo } from "../../../component-models/CompanyInfo";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import { useMemo } from "../../../shared/haunted/CustomHooks";
import { getTaxnumberCodeByCountry } from "../../../shared/common";

export interface Props {
    model: CompanyInfo;
    peruCompraModel?: PeruCompraMemberData;
}

export const useOrganizationInfoFields = (props: Props) => {
    const cug2AppContext = useCug2AppContext();

    const countryName = useMemo(
        () => cug2AppContext.Countries.find((c) => c.Value === props.model?.Address?.CountryCode)?.Text,
        [props.model?.Address?.CountryCode, cug2AppContext.Countries],
    );

    const taxNumberLabel = useMemo(
        () => getTaxnumberCodeByCountry(props.model?.Address?.CountryCode) || i18next.t("CUG-TaxNumber"),
        [props.model?.Address?.CountryCode],
    );

    // HELPERS

    // TEMPLATES TO EXPORT

    const countryNameTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("País")}</header>
            <article>${countryName}</article>
        </div>
    `;

    const businessName = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("Razón Social")}</header>
            <article>${props.model?.Name}</article>
        </div>
    `;

    const taxNumberTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${taxNumberLabel}</header>
            <article>${props.model?.TaxNumber}</article>
        </div>
    `;

    const giroTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("Giro comercial")}</header>
            <article>${props.model?.Giro}</article>
        </div>
    `;

    const invoicingEmailTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("Correo de facturación")}</header>
            <article>${props.model?.Email}</article>
        </div>
    `;

    const organizationDocumentNumberTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("RUT")}</header>
            <article>${props.model?.Rut}</article>
        </div>
    `;

    return {
        countryNameTemplate,
        businessName,
        taxNumberTemplate,
        giroTemplate,
        invoicingEmailTemplate,
        organizationDocumentNumberTemplate,
    };
};
