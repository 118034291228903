import { html, useEffect } from "haunted";
import i18next from "i18next";
import { ItineraryPageViewModel } from "../../component-models/itinerary/ItineraryPageViewModel";
import { useResendItineraryReportModal } from "./itinerary-modals/useResendItineraryReportModal";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { ROUTES } from "../../shared/apiRoutes";
import { useState } from "../../shared/haunted/CustomHooks";
import classNames from "classnames";
import { getCookie, setCookieWithExpiryInSeconds } from "../../shared/cookieHandling";

const lastSentItineraryCookieName = "lastSentItinararyConfirmation";

export interface Props {
    model: ItineraryPageViewModel;
}

export const useResendItineraryConfirmation = (props: Props) => {
    const { ajaxRequest } = useAjax();

    const resendItineraryReportModal = useResendItineraryReportModal({
        model: props.model,
    });

    const getResendAvailability = () => {
        const lastSent = getCookie(lastSentItineraryCookieName);

        if (!lastSent) {
            setIsResendAvailable(true);
            return;
        }

        const lastSentDate = new Date(lastSent);
        const currentDate = new Date();

        const timeDiff = 120 * 1000 - (currentDate.getTime() - lastSentDate.getTime());

        if (timeDiff > 0) {
            window.setTimeout(() => {
                setIsResendAvailable(true);
            }, timeDiff);
        }
    };

    const [isResendAvailable, setIsResendAvailable] = useState<boolean>(false);

    useEffect(() => {
        getResendAvailability();
    }, []);

    const handleResendItinerary = async () => {
        if (!isResendAvailable) return;

        await ajaxRequest({ url: ROUTES.ApiRoutes.ResendItinerary, method: "POST" });
        resendItineraryReportModal.open();

        const now = new Date();
        setCookieWithExpiryInSeconds(lastSentItineraryCookieName, now.toISOString(), 120);
        setIsResendAvailable(false);
        window.setTimeout(() => {
            getResendAvailability();
        }, 100);
    };

    // TEMPLATES

    const htmlTemplate = () =>
        props.model.ItineraryModelV2.CanResendItinerary
            ? html`
                  <div
                      class="no-print relative mt-7 flex h-full w-full flex-col rounded-lg border-none border-be-gray-16 p-4 text-brand-secondary sm:mt-10 sm:border sm:border-solid"
                  >
                      <div class="text-center text-lg font-bold sm:text-start sm:text-xl">
                          ${i18next.t("Reenviar correo de confirmación")}
                      </div>
                      <div class="text-center sm:text-start">
                          ${i18next.t("Recibe una copia de la confirmación de tu itinerario en tu correo electrónico")}
                      </div>
                      <div class="mt-8 flex h-10 items-center justify-center sm:mt-8 sm:justify-start">
                          <button
                              class=${classNames("rounded-secondary-btn with-arrow h-full", {
                                  disabled: !isResendAvailable,
                              })}
                              @click="${handleResendItinerary}}"
                          >
                              ${i18next.t("Enviar confirmación")}
                          </button>
                      </div>
                  </div>
                  ${resendItineraryReportModal.htmlTemplate()}
              `
            : "";

    return { htmlTemplate };
};
