import {
    ItineraryJourneyPassenger,
    ItineraryJourneyPaxSsrV2,
    ItineraryJourney,
} from "../../../component-models/itinerary/ApiItineraryViewModelV2";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { OUTBOUND } from "../../../shared/commonConstants";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import { getLocalizedStringValue } from "../../../shared/common";
import { useAppContext } from "../../../managers/useAppContext";
import { ItineraryPageViewModel } from "../../../component-models/itinerary/ItineraryPageViewModel";

export interface Props {
    model: ItineraryPageViewModel;
}

export const useItineraryTabsOptionals = (props: Props) => {
    const accordionContentClassName = "i2-details-accordion-content";
    const scrollerName = "itinerary-optionals-scroller";

    // HELPERS

    const orderedPassengers = (
        infants: boolean,
    ): { passengerNumber: number; journeyData: ItineraryJourneyPassenger[] }[] => {
        let sortedPassengers: { passengerNumber: number; journeyData: ItineraryJourneyPassenger[] }[];

        // TODO Infants have attached passenger numbers, not passenger numbers
        const filteredPassengers = props.model.ItineraryModelV2.Journeys.map((j) => j.JourneyPassengers)
            .reduce((a, b) => {
                return a.concat(b);
            }, [])
            .filter((p) => {
                if (infants) {
                    return p.Type === "INF";
                } else {
                    return p.Type !== "INF";
                }
            });

        filteredPassengers.forEach((j) => {
            const filteredPassengers = sortedPassengers
                ? sortedPassengers.filter((s) => s.passengerNumber === j.PassengerNumber)
                : null;
            const passenger = filteredPassengers ? filteredPassengers[0] : null;
            if (passenger) {
                passenger.journeyData.push(j);
            } else {
                if (!sortedPassengers) sortedPassengers = [];
                sortedPassengers.push({ passengerNumber: j.PassengerNumber, journeyData: [j] });
            }
        });

        sortedPassengers?.sort((a, b) => a.passengerNumber - b.passengerNumber);

        return sortedPassengers;
    };

    const isThereABundle = (journey: ItineraryJourney) =>
        journey.JourneyPassengers.some((passenger) => passenger.Ssrs.some((ssr) => ssr.IsInBundle));

    // COMPONENT

    const appContext = useAppContext();

    const [openDetailsAccordion, setOpenDetailsAccordion] = useState<number>(0);

    useEffect(() => {
        if (!window.acPerfectScrollbars) {
            window.acPerfectScrollbars = {};
        }

        window.acPerfectScrollbars[scrollerName] = ScrollHelper.addPerfectScrollbar(accordionContentClassName) as any;
    }, []);

    useEffect(() => {
        if (window.acPerfectScrollbars[scrollerName]) {
            window.acPerfectScrollbars[scrollerName].forEach((scroller) => {
                scroller.update();
            });
        }
    }, [openDetailsAccordion]);

    // EVENT HANDLERS

    const handleChangePassenger = (paxIndex: number, pax: ItineraryJourneyPassenger) => {
        if (pax.Type !== "INF") {
            const newIndex = paxIndex === openDetailsAccordion ? undefined : paxIndex;
            setOpenDetailsAccordion(newIndex);
        }
    };

    // TEMPLATES

    const paxSsrsTemplate = (journeyData: ItineraryJourneyPassenger[]) => {
        return html`
            ${props.model.ItineraryModelV2.Journeys.map((journey) => paxJourneySsrsTemplate(journey, journeyData))}
        `;
    };

    const paxJourneySsrsTemplate = (journey: ItineraryJourney, journeyData: ItineraryJourneyPassenger[]) => {
        if (
            !journeyData ||
            !journeyData[journey.JourneyIndex] ||
            !journeyData[journey.JourneyIndex].Ssrs ||
            journeyData[journey.JourneyIndex].Ssrs.length === 0
        ) {
            return "";
        }

        return html`
            <div class="flex justify-between py-2 sm:w-1/2 sm:py-0">
                <div class="i2-fare-journey">
                    ${journey.JourneyIndex === OUTBOUND ? i18next.t("Ida") : i18next.t("Vuelta")}
                </div>
                <div class="w-full">
                    ${isThereABundle(journey)
                        ? html`
                              <div class="mb-4">
                                  <div class="i2-optional-group">
                                      ${getLocalizedStringValue(journey.BundleInfo.DisplayName, appContext.Culture) ||
                                      i18next.t("Bundle")}
                                  </div>
                                  ${journeyData[journey.JourneyIndex].Ssrs.filter((ssr) => ssr.IsInBundle).map((ssr) =>
                                      paxSsrItemTemplate(ssr),
                                  )}
                              </div>
                          `
                        : ""}
                    ${journeyData[journey.JourneyIndex].Ssrs.filter((ssr) => !ssr.IsInBundle).map((ssr) =>
                        paxSsrItemTemplate(ssr),
                    )}
                </div>
            </div>
        `;
    };

    const paxSsrItemTemplate = (ssr: ItineraryJourneyPaxSsrV2) => html`
        <div class="i2-ssr-item wrap">
            ${ssr.Quantity > 1
                ? html` <span>${ssr.Quantity} - ${ssr.ScreenName || ssr.SsrCode || ssr.FeeCode}</span> `
                : html` <span>${ssr.ScreenName || ssr.SsrCode || ssr.FeeCode} ${ssr.Note}</span> `}
        </div>
    `;

    const paxHeaderTemplate = (passengerNumber: number, journeyData: ItineraryJourneyPassenger) => {
        const constantHeaderClasses = { "i2-details-accordion-header": true };

        const headerClassMap = classMap({
            ...constantHeaderClasses,
            "cursor-default": journeyData.Type === "INF",
            "open": passengerNumber === openDetailsAccordion,
        });

        return html`
            <div @click=${() => handleChangePassenger(passengerNumber, journeyData)} class=${headerClassMap}>
                <div class="flex flex-col items-start sm:flex-row sm:items-center">
                    <span class="i2-details-pax-header-number">${i18next.t("Pasajero")} ${passengerNumber + 1}</span>
                    <span class="i2-details-pax-header-name">${journeyData.FirstName} ${journeyData.LastName}</span>
                </div>
                <div class="i2-details-pax-header-total">
                    <i class="js-icon js-circle-chevron-right"></i>
                </div>
            </div>
        `;
    };

    const paxContentTemplate = (paxNumber: number, journeyData: ItineraryJourneyPassenger[]) => {
        const constantContentClasses = { [accordionContentClassName]: true };
        const constantPaneClasses = { "sm:flex": true };

        const contentClassMap = classMap({
            ...constantContentClasses,
            last: paxNumber === props.model.ItineraryModelV2.Passengers.length - 1,
        });

        const paneClassMap = classMap({
            ...constantPaneClasses,
            hidden: journeyData[0].Type === "INF",
        });

        return html`
            <div class=${contentClassMap}>
                <div class=${paneClassMap}>${paxSsrsTemplate(journeyData)}</div>
            </div>
        `;
    };

    const detailsPassengerTemplate = (pax: {
        passengerNumber: number;
        journeyData: ItineraryJourneyPassenger[];
    }) => html`
        ${paxHeaderTemplate(pax.passengerNumber, pax.journeyData[0])}
        ${paxContentTemplate(pax.passengerNumber, pax.journeyData)}
    `;

    const htmlTemplate = () => html`
        <div class="no-print">
            <div class="i2-tab-title">
                <i class="js-icon-bag js-bag-broken-circle-bag"></i>
                <div class="w-full">
                    ${i18next.t("Opcionales")}
                    <div class="i2-tab-subtitle">
                        ${i18next.t("Aquí encontrarás el detalle de todos los opcionales incluidos en tu reserva.")}
                    </div>
                </div>
            </div>
            ${orderedPassengers(false)?.map((pax) => detailsPassengerTemplate(pax))}
            ${orderedPassengers(true)?.map((pax) => detailsPassengerTemplate(pax))}
        </div>
    `;

    return { htmlTemplate };
};
