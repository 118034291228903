export type ChileCompraRole = "none" | "supervisor" | "observer";
export type PeruCompraRole = "none" | "admin" | "member";

export interface UserContext {
    bancoEstado: {
        category: number;
        remainingFreeSeats: number;
    };
    american: {
        americanAirlinesDisplayName: string;
        tier: AmericanAirlinesTier;
        eligibleForAmericanAirlinesRedemption: boolean;
        miles: number;
    };
    chileCompra: {
        availableAmount: number;
        role: ChileCompraRole;
    };
    cug: {
        isAdminOrSupervisor: boolean;
        isAgent: boolean;
        isMember: boolean;
        orgCode: string;
        orgCurrency: string;
        orgName: string;
    };
    dc: {
        canBuyMembership: boolean;
        dcLevel: string;
        groupPriceFormatted: string;
        hasGroupMembership: boolean;
        hasMembership: boolean;
        hasStandardMembership: boolean;
        membershipDaysRemaining: number;
        programNumber: string;
        showWarning15: boolean;
        showWarning30: boolean;
        standardPriceFormatted: string;
        upgradePriceFormatted: string;
    };
    peruCompra: {
        availableAmount: number;
        role: PeruCompraRole;
    };
    isFarelockDisabled: boolean;
    isLoggedIn: boolean;
    isStaff: boolean;
    userFirstName: string;
    userLastName: string;
    userUserName: string;
    userProgram: string;
    userRole: string;
}

export type AmericanAirlinesTier = "Regular" | "Gold" | "Platinum" | "PlatinumPro" | "ExecutivePlatinum";

export const DEFAULT_USER_CONTEXT: UserContext = {
    bancoEstado: {
        category: 0,
        remainingFreeSeats: 0,
    },
    american: {
        americanAirlinesDisplayName: "",
        tier: undefined,
        eligibleForAmericanAirlinesRedemption: false,
        miles: 0,
    },
    chileCompra: {
        availableAmount: 0,
        role: "none",
    },
    cug: {
        isAdminOrSupervisor: false,
        isAgent: false,
        isMember: false,
        orgCode: "",
        orgCurrency: "",
        orgName: "",
    },
    dc: {
        canBuyMembership: false,
        dcLevel: "",
        groupPriceFormatted: "",
        hasGroupMembership: false,
        hasMembership: false,
        hasStandardMembership: false,
        membershipDaysRemaining: 0,
        programNumber: "",
        showWarning15: false,
        showWarning30: false,
        standardPriceFormatted: "",
        upgradePriceFormatted: "",
    },
    peruCompra: {
        availableAmount: 0,
        role: "none",
    },
    isFarelockDisabled: false,
    isLoggedIn: false,
    isStaff: false,
    userFirstName: "",
    userLastName: "",
    userUserName: "",
    userProgram: "",
    userRole: "",
};
