import { ItineraryPageViewModel } from "../../../component-models/itinerary/ItineraryPageViewModel";
import { useFlowContext } from "../../../managers/useFlowContext";

export const itineraryTransactionsUtils = () => {
    const flowContext = useFlowContext();

    // EXPORTS

    const visiblePaymentButtonType = (model: ItineraryPageViewModel): "None" | "Pay" | "PayFarelockFull" => {
        // FIXME The order is important, which should not be

        if (model.IsGdsOnHold) {
            return "None";
        }

        // Farelock paid but not full amount
        if (flowContext.isFarelockRoundTwo && !model.ItineraryModelV2.IsTotalPending) {
            return "PayFarelockFull";
        }

        if (model.IsCugOnHold || model.ItineraryModelV2.IsLastPaymentDeclined) {
            return "Pay";
        }

        if (model.ItineraryModelV2.IsTotalPending) {
            return "None";
        }

        if (model.HasBalanceDue) {
            return "Pay";
        }

        // Failed to pay farelock
        if (model.IsFarelockAmountNotFullyPaid) {
            return "Pay";
        }

        return "None";
    };

    return { visiblePaymentButtonType };
};
