import { AmericanAirlinesTier, UserContext } from "../component-models/app/UserContext";
import { FlightPageViewModel } from "../component-models/flight/FlightPageViewModel";
import { toBoolean } from "../shared/common";
import { URL_VARS } from "../shared/commonConstants";
import { useReduxState } from "../shared/redux/useReduxState";

export const useUserContextBuilder = () => {
    const [_, setUserContext] = useReduxState("userContext");

    const init = (model?: FlightPageViewModel) => {
        setUserContext(getInitialUserData(model));
        return getInitialUserData(model);
    };

    const getBancoEstadoCategory = () => (window.UserContext ? Number(window.UserContext.bancoEstado.category) : 0);

    const isBancoEstadoCategory = (category: number | number[]) =>
        Array.isArray(category)
            ? category.indexOf(getBancoEstadoCategory()) > -1
            : getBancoEstadoCategory() === category;

    const hasDcGroupMembership = () => toBoolean(window.UserContext.dc.hasGroupMembership);
    const hasDcStandardMembership = () => toBoolean(window.UserContext.dc.hasStandardMembership);
    const hasDcMembership = () => hasDcGroupMembership() || hasDcStandardMembership();

    // DEVNOTE JET-6962 Get user from URL
    const getUrlUserData = (): UserContext => {
        try {
            const parsedUrlUserData = JSON.parse(
                atob(
                    window.location.href
                        .split("?")[1]
                        .split("&")
                        .find((elem) => elem.split("=")[0] === URL_VARS.CURRENCY_CHANGE_USER)
                        .split("=")[1],
                ),
            ) as UserContext;
            return parsedUrlUserData;
        } catch (e) {
            // commonDebug.info("No user.");
            return undefined;
        }
    };

    const getInitialUserData = (model?: FlightPageViewModel): UserContext => {
        const userData = getUrlUserData();
        return userData
            ? {
                  ...userData,
                  dc: {
                      ...userData.dc,
                      standardPriceFormatted:
                          model?.DiscountClubViewModel.StandardPrice || userData.dc.standardPriceFormatted,
                      groupPriceFormatted: model?.DiscountClubViewModel.GroupPrice || userData.dc.groupPriceFormatted,
                      upgradePriceFormatted:
                          model?.DiscountClubViewModel.UpgradePrice || userData.dc.upgradePriceFormatted,
                  },
              }
            : getDefaultUserData(model);
    };

    const getRemainingFreeSeats = () =>
        isBancoEstadoCategory(6) ? Number(window.UserContext.bancoEstado.numberOfRemainingFreeSeats) : 0;

    const isCugMember = () => toBoolean(window.UserContext.cug.isMember);

    const isStaff = () => toBoolean(window.UserContext.isStaff);

    const getChileCompraAvailableAmount = () =>
        window.UserContext.chileCompra.availableAmount ? Number(window.UserContext.chileCompra.availableAmount) : 0;

    const getPeruCompraRole = () =>
        toBoolean(window.UserContext.peruCompra.isAdmin)
            ? "admin"
            : toBoolean(window.UserContext.peruCompra.isMember)
              ? "member"
              : "none";

    const getChileCompraRole = () =>
        toBoolean(window.UserContext.chileCompra.isObserver)
            ? "observer"
            : toBoolean(window.UserContext.chileCompra.isSupervisor)
              ? "supervisor"
              : "none";

    const getPeruCompraAvailableAmount = () =>
        window.UserContext.peruCompra.availableAmount ? Number(window.UserContext.peruCompra.availableAmount) : 0;

    const canBuyMembership = (model?: FlightPageViewModel) =>
        !toBoolean(window.UserContext.american.americanAirlinesDisplayName?.length > 0) &&
        (model?.DiscountClubViewModel.CanBuyMembership ||
            (!model &&
                getChileCompraRole() === "none" &&
                !hasDcGroupMembership() &&
                !isCugMember() &&
                !isStaff() &&
                getPeruCompraRole() === "none"));

    const getAmericanAvailableMiles = () =>
        window.UserContext.american.miles ? Number(window.UserContext.american.miles) : 0;

    const isFarelockDisabled = (model?: FlightPageViewModel) =>
        (model?.FarelockViewModel && !model.FarelockViewModel.IsFarelockShown) ||
        toBoolean(window.UserContext.isFarelockDisabled) ||
        toBoolean(window.UserContext.american.americanAirlinesDisplayName?.length > 0);

    const getDefaultUserData = (model?: FlightPageViewModel): UserContext => {
        return {
            bancoEstado: {
                category: getBancoEstadoCategory(),
                remainingFreeSeats: getRemainingFreeSeats(),
            },
            american: {
                americanAirlinesDisplayName: window.UserContext.american.americanAirlinesDisplayName,
                tier: window.UserContext.american.tier as AmericanAirlinesTier,
                miles: getAmericanAvailableMiles(),
                eligibleForAmericanAirlinesRedemption: toBoolean(
                    window.UserContext.american.eligibleForAmericanAirlinesRedemption,
                ),
            },
            chileCompra: {
                role: getChileCompraRole(),
                availableAmount: getChileCompraAvailableAmount(),
            },
            cug: {
                isAdminOrSupervisor:
                    model?.IsCugAdminOrSupervisor ||
                    toBoolean(window.UserContext.cug.isAdmin) ||
                    toBoolean(window.UserContext.cug.isSupervisor),
                isAgent: toBoolean(window.UserContext.cug.isAgent),
                isMember: isCugMember(),
                orgCode: window.UserContext.cug.orgCode,
                orgCurrency: window.UserContext.cug.orgCurrency,
                orgName: window.UserContext.cug.orgName,
            },
            dc: {
                canBuyMembership: canBuyMembership(model),
                dcLevel: window.UserContext.dc.dcLevel,
                groupPriceFormatted:
                    model?.DiscountClubViewModel.GroupPrice || window.UserContext.dc.groupPriceFormatted,
                standardPriceFormatted:
                    model?.DiscountClubViewModel.StandardPrice || window.UserContext.dc.standardPriceFormatted,
                upgradePriceFormatted:
                    model?.DiscountClubViewModel.UpgradePrice || window.UserContext.dc.upgradePriceFormatted,
                hasMembership: model?.DiscountClubViewModel.IsDcEligible || hasDcMembership(),
                hasGroupMembership: model?.DiscountClubViewModel.HasGroupDcMembership || hasDcGroupMembership(),
                hasStandardMembership:
                    model?.DiscountClubViewModel.HasStandardDcMembership || hasDcStandardMembership(),
                membershipDaysRemaining:
                    model?.DiscountClubViewModel.MembershipDaysRemaining ||
                    Number(window.UserContext.dc.membershipDaysRemaining),
                programNumber: model?.DiscountClubViewModel.ProgramNumber || window.UserContext.dc.programNumber,
                showWarning15:
                    model?.DiscountClubViewModel.ShowWarning15 || toBoolean(window.UserContext.dc.showWarning15),
                showWarning30:
                    model?.DiscountClubViewModel.ShowWarning30 || toBoolean(window.UserContext.dc.showWarning30),
            },
            peruCompra: {
                role: getPeruCompraRole(),
                availableAmount: getPeruCompraAvailableAmount(),
            },
            isFarelockDisabled: isFarelockDisabled(),
            isLoggedIn: toBoolean(window.UserContext.isLoggedIn),
            isStaff: isStaff(),
            userFirstName: window.UserContext.userFirstName || "",
            userLastName: window.UserContext.userLastName || "",
            userUserName: window.UserContext.userUserName || "",
            userProgram: window.UserContext.userProgram || "",
            userRole: window.UserContext.userRole || "",
        };
    };

    return { init };
};
