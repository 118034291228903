import { TestIdDictionary } from "../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import { useState } from "../../shared/haunted/CustomHooks";
import { html, useRef } from "haunted";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { sanitizeInputFieldValue } from "../../component-helpers/InputSanitizerHelper";
import { classMap } from "lit-html/directives/class-map";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import {
    voucherHeaderTemplate,
    voucherMobileHeaderTemplate,
    voucherTooltipTemplate,
} from "../../common-templates/PaymentCommonTemplates";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { ref } from "../../directives/ref";
import { useBookingContext } from "../../managers/useBookingContext";
import { useAppContext } from "../../managers/useAppContext";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    isButtonDisabled: boolean;
    model: PaymentPageViewModel;
    handleGiftcardVsPromoCodeModal: () => void;
    handleKeydown: (e: KeyboardEvent) => Promise<boolean>;
    handleVoucherClick: (value: string) => void;
}

export const useVoucherForWebAnonymous = (props: Props) => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();

    const { triggers } = usePubSub();

    const [userContext] = useReduxState("userContext");

    const opener = useRef<HTMLInputElement>(null);

    const [code, setCode] = useState<string>("");

    const handleLogin = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        triggers.login.openLoginModal.publish({});
    };

    const handleInput = (e: KeyboardEvent) => {
        const value = sanitizeInputFieldValue(e, "numeric");
        setCode(value);
    };

    // DEVNOTE For WA GC
    const toggleOpen = (e: MouseEvent) => {
        if (bookingContext.promoCode) {
            const elem = e.target as HTMLInputElement;

            if (elem.checked) {
                props.handleGiftcardVsPromoCodeModal();
            }
        }
    };

    const inputCodeTemplate = () => html`
        <input
            class="mdl-textfield__input js-input"
            name="voucherPayment.VoucherCode"
            autocomplete="off"
            data-test-id="voucher-code-input"
            @keydown=${props.handleKeydown}
            @input=${handleInput}
        />
    `;

    const submitButtonTemplate = () => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "disabled": props.isButtonDisabled,
        });

        return html`
            <div class="giftcard-button-container">
                <div
                    class=${tempClassMap}
                    data-test-id="voucher-gift-card-button"
                    @click=${() => props.handleVoucherClick(code)}
                >
                    ${i18next.t("V2-Add")}
                </div>
            </div>
        `;
    };

    const titleWithoutGiftcardLogoTemplate = () => html`
        <h2 class="main-title" data-test-id="voucher-gift-card-title">${i18next.t("V2-PayGiftCard")}</h2>
    `;

    const titleTemplate = () =>
        appContext.isFeatureSwitchActive("GiftCard")
            ? html` ${voucherHeaderTemplate()} ${voucherMobileHeaderTemplate()} `
            : titleWithoutGiftcardLogoTemplate();

    // DEVNOTE We use mousedown because it's a tooltip
    const loginForGiftcardWarningTemplate = () =>
        !userContext.isLoggedIn && appContext.isFeatureSwitchActive("GiftCard")
            ? html`
                  <div class="new-giftcard-input-tooltip" @mousedown=${handleLogin}>
                      ${unsafeHTML(
                          i18next.t("Gift-CardNumberTooltip {{-spanStart}}{{-spanEnd}}", {
                              spanStart: "<span>",
                              spanEnd: "</span>",
                          }),
                      )}
                  </div>
              `
            : "";

    const openerTemplate = () => html`
        <input id="giftcardOpener" type="checkbox" class="ts-giftcard-opener" ref=${ref(opener)} @click=${toggleOpen} />
        <label for="giftcardOpener" class="giftcard-opener" data-test-id=${TestIdDictionary.PAYMENT.GIFTCARD_OPENER}>
            <header>
                <span class="js-gift-card js-icon title-icon"></span>
                <div class="title">${titleTemplate()} ${voucherTooltipTemplate(false)}</div>
            </header>

            <span class="open-icon">
                <i class="js-circle-chevron-right js-icon"></i>
            </span>
        </label>
    `;

    const dropdownTemplate = () => html`
        <div class="giftcard-content">
            <div class="giftcard-content-inside">
                <div class="row">
                    <div class="col-xs-1 col-sm-1-2 col-md-3-5 col-xl-4-5">
                        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                            <label class="mdl-textfield__label">${i18next.t("V2-Payment-VoucherCode")}</label>

                            ${inputCodeTemplate()} ${loginForGiftcardWarningTemplate()}
                        </div>
                    </div>
                    <div class="col-xs-1 col-sm-1-2 col-md-2-5 col-xl-1-5">${submitButtonTemplate()}</div>
                </div>
            </div>
        </div>
    `;

    const htmlTemplate = () => html` <div class="giftcard-wrapper">${openerTemplate()} ${dropdownTemplate()}</div> `;

    return { htmlTemplate, onPromoCodeSelection: () => (opener.current.checked = false) };
};
