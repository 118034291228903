import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import classNames from "classnames";
import { useModal } from "../shared/useModal";
import { useEffect as hauntedUseEffect, useCallback } from "haunted";
import i18next from "i18next";
import { browserTabIdKey } from "./app-init";
import { isMobile } from "../../shared/common";

export const name = "ac-multiple-tab-modal";

export const Component: HauntedFunc<{}> = () => {
    const headerTemplate = () => html`
        <i class="js-icon js-triangle-exclamation absolute"></i>
        <span>${i18next.t("Advertencia")}</span>
    `;

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="leading-6">
                ${i18next.t(
                    "Hemos observado que ya tiene una reserva incompleta en otra pestaña del navegador. Para evitar una posible doble reserva, cierre esta ventana.",
                )}
            </div>
            <div class="mt-3 leading-6">
                ${i18next.t(
                    "Si fuera necesario tener otra pestaña, por favor utilice otro navegador o el modo incógnito.",
                )}
            </div>
        </div>
    `;

    const modal = useModal({
        content: { classNames: classNames("booking-modal-content"), template: htmlTemplate },
        header: { template: headerTemplate },
        overlay: { classNames: "primary-modal" },
    });

    const checkPresenceOfMultipleTabs = useCallback(() => {
        window.setTimeout(() => {
            const cookies = document.cookie.split("; ");
            const browserTabIdCookies = cookies.filter((cookie) => cookie.startsWith(browserTabIdKey));

            if (browserTabIdCookies.length > 1) {
                modal.open();
            } else {
                modal.close();
            }

            checkPresenceOfMultipleTabs();
        }, 2000);
    }, []);

    const init = () => {
        // DEVNOTE: The implementation does not work on mobile devices
        if (isMobile()) return;
        checkPresenceOfMultipleTabs();
    };

    hauntedUseEffect(init, []);

    return html`${modal.htmlTemplate()}`;
};
