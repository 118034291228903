import {
    BundleData,
    BundleOfferDetails,
    RedemptionBundleData,
} from "../../component-models/flight/BundleOffersV2Model";
import { ExtendedTripModel } from "../../component-models/flight/ExtendedTripModel";
import { FlightPageViewModel } from "../../component-models/flight/FlightPageViewModel";
import { LOADER_CLASS_NAMES } from "../../shared/LoaderClassNames";
import { ROUTES } from "../../shared/apiRoutes";
import { commonDebug } from "../../bootstrap";
import { getRequestBodyFromInputs, hideLoader, showLoader } from "../../shared/common";
import { mapBundleOffers, mapRedemptionBundleOffers } from "../../component-mappers/BundleOffersMapper";
import { useBookingManager } from "../../managers/useBookingManager";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { FarelockType } from "./farelock/useFarelock";
import { BundleHelper } from "../../component-helpers/flight/BundleHelper";
import { ApiFarelockPriceResponse } from "../../V2/booking/flight/ApiFarelockPriceResponse";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { useFlowContext } from "../../managers/useFlowContext";
import { LoadSidebarEventData } from "../../component-models/sidebar/LoadSidebarEventData";
import { URL_VARS } from "../../shared/commonConstants";
import { useFlightHttpContextHandler } from "./useFlightHttpContextHandler";
import { useSidebarAjaxHandler } from "../sidebar/useSidebarAjaxHandler";
import { useReduxState } from "../../shared/redux/useReduxState";
import { ApiCountry } from "../../component-models/ApiCountry";
import { normalizeCulture } from "../../shared/localeHelper";

export const useFlightPageAjaxHandler = () => {
    const bookingManager = useBookingManager();
    const flowContext = useFlowContext();
    const [userContext] = useReduxState("userContext");

    const { ajaxJsonRequest, ajaxRequest } = useAjax();
    const { getSellKeysAndBundlesUrlQueryParams } = useFlightHttpContextHandler({ model: undefined });
    const { reloadSidebarViewModel } = useSidebarAjaxHandler();

    const sellKeysAndBundleCodesQuery = (data: LoadSidebarEventData) =>
        data.flightState && data.bundleState
            ? getSellKeysAndBundlesUrlQueryParams(
                  [data.flightState?.selectedOutboundFlight?.SellKey, data.flightState?.selectedInboundFlight?.SellKey],
                  [
                      data.bundleState.selectedOutboundBundle?.BundleCode,
                      data.bundleState.selectedInboundBundle?.BundleCode,
                  ],
              )
            : "";

    const addFarelockToBooking = async (
        type: FarelockType,
        viewModel: FlightPageViewModel,
        rootElement: HTMLElement,
    ) => {
        const loader = showLoader({ name: LOADER_CLASS_NAMES.CommonLoaderWrapper });
        const body = {
            fareLockCode:
                type === "Duration24"
                    ? viewModel.FarelockViewModel.SellKeyForFareLock24
                    : viewModel.FarelockViewModel.SellKeyForFareLock48,
            timeZoneOffsetMin: (new Date().getTimezoneOffset() * -1).toString(),
        };

        const container = rootElement.parentElement;

        await bookingManager.postAddFarelock(body, container, loader);
    };

    const removeFarelockFromBooking = async (rootElement: HTMLElement) => {
        const container = rootElement.parentElement;
        const loader = showLoader({ name: LOADER_CLASS_NAMES.CommonLoaderWrapper });

        await bookingManager.postCancelFarelock(container, loader);
    };

    // TODO SBBD
    const submitDcForm = async (form: HTMLFormElement, rootElement: HTMLElement) => {
        try {
            await ajaxRequest({
                body: getRequestBodyFromInputs(form),
                container: rootElement.parentElement,
                form,
                url: ROUTES.ApplyMembership,
            });
        } catch (e) {
            commonDebug.error(e);
        }
    };

    const getTotal = async (
        sellKeyOut: string,
        sellKeyBack: string,
        bundleTypeOut: string,
        bundleTypeBack: string,
        isOneWay: boolean,
    ) => {
        const body = {
            SellKeyOut: sellKeyOut,
            BundleTypeOut: bundleTypeOut,
            SellKeyBack: !isOneWay ? sellKeyBack : undefined,
            BundleTypeBack: !isOneWay ? bundleTypeBack : undefined,
        };

        const response = await ajaxJsonRequest<BundleOfferDetails>({
            url: ROUTES.ApiRoutes.BundleForBanner,
            isJsonData: true,
            body,
            method: "POST",
        });

        return response?.statusCode === 200 || response?.statusCode === 204 ? response.data : undefined;
    };

    const updateFarelockPrices = async (
        sellKeys: string[],
        rootElement: HTMLElement,
        viewModel: FlightPageViewModel,
    ) => {
        if (!sellKeys || sellKeys.filter((key) => key).length === 0) {
            return [];
        }

        try {
            const body = {
                SellKeys: sellKeys.filter((key) => key),
            };

            const container = DomCrawlingHelper.findParentByClass(rootElement, LOADER_CLASS_NAMES.CommonLoaderWrapper);
            const response = await ajaxJsonRequest<ApiFarelockPriceResponse>({
                container,
                body,
                isJsonData: true,
                url: ROUTES.ApiRoutes.FarelockOffers,
                method: "POST",
            });

            if (response?.statusCode === 200 || response?.statusCode === 204) {
                const fareLockCode24 = viewModel.FarelockViewModel.SellKeyForFareLock24;
                const fareLockCode48 = viewModel.FarelockViewModel.SellKeyForFareLock48;
                const newPrice24 = response.data.FareLockPrices.find((np) => np.Code === fareLockCode24);
                const newPrice48 = response.data.FareLockPrices.find((np) => np.Code === fareLockCode48);

                return [newPrice24.Amount.FormattedAmount, newPrice48.Amount.FormattedAmount];
            } else {
                commonDebug.error("Could not update Fare Lock prices.");
            }
        } catch (e) {
            commonDebug.error("Could not update Fare Lock prices.");
        }

        return [];
    };

    // TODO SBBD
    const getCustomizedBundleOffers = async (loader: JsLoader, journeys: ExtendedTripModel[], culture: string) => {
        const keys = BundleHelper.getAllAvailableSellKeys(journeys);

        if (flowContext.isRedemptionFlow) {
            const customizedRedemptionBundleOffers = await getCustomizedRedemptionBundleOffers(
                loader,
                journeys,
                culture,
            );

            return {
                customizedBundleOffers: customizedRedemptionBundleOffers,
                shouldConcatenateBagSsrsInBundles: false,
            };
        }

        const bundleDataResponse =
            keys.length > 0
                ? await ajaxJsonRequest<BundleData>({
                      nonCancellable: true,
                      loader,
                      method: "POST",
                      url:
                          userContext.chileCompra?.role !== "none"
                              ? ROUTES.ApiRoutes.Cug3BundleData
                              : ROUTES.ApiRoutes.BundleData,
                      body: { SellKeys: keys },
                      isJsonData: true,
                  })
                : undefined;

        if (!bundleDataResponse) {
            hideLoader(loader);
        }

        const bundleOffers =
            bundleDataResponse?.statusCode === 200 || bundleDataResponse?.statusCode === 204
                ? bundleDataResponse.data
                : { BundleOffersCollection: [], BundleInfoCollection: [] };

        const customizedBundleOffers = mapBundleOffers(bundleOffers, culture);

        const shouldConcatenateBagSsrsInBundles = BundleHelper.shouldConcatenateBagsInBundleSsrList(
            bundleOffers.BundleOffersCollection,
        );

        return { customizedBundleOffers, shouldConcatenateBagSsrsInBundles };
    };

    const getCustomizedRedemptionBundleOffers = async (
        loader: JsLoader,
        journeys: ExtendedTripModel[],
        culture: string,
    ) => {
        const keys = BundleHelper.getAllAvailableSellKeys(journeys);

        const bundleDataResponse =
            keys.length > 0
                ? await ajaxJsonRequest<RedemptionBundleData>({
                      nonCancellable: true,
                      loader,
                      method: "POST",
                      url: ROUTES.ApiRoutes.RedemptionBundleData,
                      body: { SellKeys: keys },
                      isJsonData: true,
                  })
                : undefined;

        if (!bundleDataResponse) {
            hideLoader(loader);
        }

        const bundleOffers =
            bundleDataResponse?.statusCode === 200 ? bundleDataResponse.data : { Offers: [], Info: [] };

        const customizedRedemptionBundleOffers = mapRedemptionBundleOffers(bundleOffers, culture);

        return customizedRedemptionBundleOffers;
    };

    const reloadFlightPageSidebar = async (loader: JsLoader, data: LoadSidebarEventData) => {
        let query = `?${URL_VARS.CURRENCY}=${data.selectedCurrency}&${sellKeysAndBundleCodesQuery(
            data,
        )}&jetSmartPassengerDiscount.ApplyPaxDiscount=${data.dcState.applyDiscount}`;

        if (data.dcState.selectedMembershipType) {
            if (data.dcState.selectedMembershipType === "Group") {
                query += "&jetSmartPassengerDiscount.ClubMembershipLevel=group";
            }

            if (data.dcState.selectedMembershipType === "Standard") {
                query += "&jetSmartPassengerDiscount.ClubMembershipLevel=standard";
            }

            if (data.dcState.selectedMembershipType === "Upgrade") {
                query += "&jetSmartPassengerDiscount.ClubMembershipLevel=upgrade";
            }
        }

        return reloadSidebarViewModel(loader, false, ROUTES.PriceBreakdownFlightSelect + query);
    };

    const reloadFlightPageSidebarNonCancellable = async (loader: JsLoader, data: LoadSidebarEventData) => {
        const query = `?${URL_VARS.CURRENCY}=${data.selectedCurrency}&${sellKeysAndBundleCodesQuery(
            data,
        )}&jetSmartPassengerDiscount.ApplyPaxDiscount=${false}`;

        return reloadSidebarViewModel(loader, true, ROUTES.PriceBreakdownFlightSelect + query);
    };

    const getApiCountries = async (culture: string): Promise<ApiCountry[]> => {
        const headers = new Headers({ Accept: "application/json" });
        const url = `${ROUTES.SuperStations}?culture=${normalizeCulture(culture)}&activeOnly=true`;

        const response = await fetch(url, { headers });

        if (response.ok) return response.json();

        throw new Error("Could not download stations.");
    };

    return {
        addFarelockToBooking,
        getApiCountries,
        getCustomizedBundleOffers,
        getTotal,
        reloadFlightPageSidebar,
        reloadFlightPageSidebarNonCancellable,
        removeFarelockFromBooking,
        submitDcForm,
        updateFarelockPrices,
    };
};
