import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { ItineraryGiftcardPageViewModel } from "../../component-models/itinerary/ItineraryGiftcardPageViewModel";
import { html } from "lit-html";
import { useGiftcardObtainer } from "../giftcard/useGiftcardObtainer";
import { useItineraryGiftcardSummary } from "./useItineraryGiftcardSummary";
import { usePendingPaymentBanner } from "../payment/usePendingPaymentBanner";
import { getParsedProperty } from "../../shared/common";

export const name = "ac-itinerary-giftcard-page";

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token", "model"];

export interface Attributes {
    "anti-forgery-token": string;
    "model": string;
}

export interface Props {
    antiForgeryToken: string;
    model: ItineraryGiftcardPageViewModel;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        antiForgeryToken: host.antiForgeryToken,
        model: getParsedProperty<ItineraryGiftcardPageViewModel>(host.model),
    };

    // HELPERS

    // EVENT HANDLERS

    // COMPONENTS

    const itineraryGiftcardSummary = useItineraryGiftcardSummary({
        firstName: props.model.FirstName,
        paymentResults: props.model.PaymentResults,
        tealiumProperties: props.model.TealiumProperties,
    });

    const giftcardObtainer = useGiftcardObtainer({
        antiForgeryToken: props.antiForgeryToken,
        balanceDue: props.model.BalanceDue,
        giftcardFullHtml: props.model.GiftcardFullHtml,
        giftcardMobileHtml: props.model.GiftcardMobileHtml,
        giftcardModel: props.model.GiftcardModel,
        hasSecondaryContact: props.model.HasSecondaryContact,
    });

    const pendingPaymentBanner = usePendingPaymentBanner({
        isActive: !props.model.IsLastPaymentInFinalStatus,
        isDeclined: props.model.IsDeclined,
        isCugOnHold: false,
        isFlightless: true,
        paymentDescription: props.model.PaymentDescription,
        retrieveUrl: props.model.RetrieveUrl,
    });

    // TEMPLATES

    return html` <div class="for-loader">
        ${props.model.IsPaymentSuccessful
            ? html`${itineraryGiftcardSummary.htmlTemplate()} ${giftcardObtainer.htmlTemplate()}`
            : html`${pendingPaymentBanner.htmlTemplate()}`}
    </div>`;
};
