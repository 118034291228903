import PdfDownloader from "../../shared/pdf-downloader";

export function mediaObtainer() {
    const simplePrint = () => {
        window.print();
    };

    const download = async (getArea: () => HTMLDivElement, filename: string) => {
        if (!getArea() || !filename) {
            return;
        }

        await PdfDownloader.downloadDiv(getArea(), filename);
    };

    // https://stackoverflow.com/questions/2255291/print-the-contents-of-a-div
    const contentPrint = (content: string) => {
        const mywindow = window.open("", "PRINT", "height=600,width=800");

        mywindow.document.write("<html><head><title>" + document.title + "</title>");
        mywindow.document.write("<style>.to-print {opacity: 0;} @media only print {.to-print {opacity: 1;}}</style>");
        mywindow.document.write("</head><body class='to-print'>");
        mywindow.document.write(content);
        mywindow.document.write("</body></html>");
        mywindow.document.close(); // necessary for IE >= 10

        mywindow.onload = () => {
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            window.setTimeout(() => mywindow.close(), 1);
        };
    };

    return {
        contentPrint,
        download,
        simplePrint,
    };
}
