import { CLASS_NAMES } from "./../../classNames";
import { TemplateResult } from "lit-html";
import { useEffect } from "./../../haunted/CustomHooks";
import { getTestId, TestIdDictionary as T } from "./../../../testing-helpers/TestIdHelper";
import { commonDebug } from "../../../bootstrap";
import { clone, getCoords, getHtmlElementAttributeNames, getRenderString, toBoolean } from "../../common";
import DomCrawlingHelper from "../../DomCrawlingHelper";
import { useState } from "../../haunted/CustomHooks";
import { InputFieldProps } from "./InputFieldProps";
import { FormError } from "./FormError";
import { FormOptions } from "./FormOptions";
import { UDF_ATTR_REQUIRED } from "./InputFieldAttribute";
import { InputFieldAttributeValidator } from "./InputFieldAttributeValidator";
import { exactLength } from "./custom-attributes/exactLength";
import { emailFormat } from "./custom-attributes/emailFormat";
import { maxLength } from "./custom-attributes/maxLength";
import { minLength } from "./custom-attributes/minLength";
import { noSpecialCharacters } from "./custom-attributes/noSpecialCharacters";
import { required } from "./custom-attributes/required";
import { useTealiumManager } from "../../../managers/Tealium/useTealiumManager";

export const UDF_FIELD_ERROR_CLASS = "ts-udf-error";
export const UDF_FORM_ERROR_CONTAINER_CLASS = "ts-common-error-container";

export type InputElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

export type InputMap = Map<InputElement, InputFieldProps>;

export function useForm(props: FormOptions) {
    const options: FormOptions = {
        customAttributes: props.customAttributes || [],
        errorMessageContainerClass: props.errorMessageContainerClass || CLASS_NAMES.ErrorMessageContainer,
        fieldErrorMessageClass: props.fieldErrorMessageClass || CLASS_NAMES.ErrorMessageContainer,
        invalidFieldClass: props.invalidFieldClass || CLASS_NAMES.invalid,
        noScroll: toBoolean(props.noScroll),
    };

    // HELPERS

    const init = (formElem?: HTMLElement) => {
        if (!(formElem instanceof HTMLElement)) {
            return;
        }

        setForm(formElem);

        if (isInitialized) {
            return;
        }

        setIsInitialized(true);

        formElem.setAttribute("novalidate", "novalidate");

        let newFields = new Map<InputElement, InputFieldProps>();

        (Array.from(formElem.querySelectorAll("select, input, textarea")) as InputElement[]).forEach((input) => {
            newFields = resetField(newFields, input);
        });

        setFields(newFields);

        // DEVNOTE We don't need double validation, esp. ajax
        preventOnBlurOnSubmitButton(formElem);
    };

    const getCustomAttributes = (input: InputElement) => [
        exactLength(input),
        emailFormat(),
        maxLength(input),
        minLength(input),
        noSpecialCharacters(),
        required(),
        ...options.customAttributes,
    ];

    const getGoverningRelations = (fields: InputMap, input: InputElement) => {
        const fieldsGoverningThis = Array.from(fields.keys()).filter(
            (otherInput) => otherInput !== input && isFieldInRightRelation(otherInput, "governs", input),
        );

        const fieldsGovernedByThis = Array.from(fields.keys()).filter(
            (otherInput) => otherInput !== input && isFieldInRightRelation(otherInput, "isGovernedBy", input),
        );

        return { fieldsGoverningThis, fieldsGovernedByThis };
    };

    const preventSubmit = (e: MouseEvent) => e.preventDefault();

    const preventOnBlurOnSubmitButton = (form: HTMLElement): void => {
        let submitButton = form.querySelector("[type=submit]") as HTMLButtonElement;

        if (!submitButton) {
            const buttons = Array.from(form.querySelectorAll("button")) || [];
            if (buttons.length === 1) {
                submitButton = buttons[0];
            }
        }

        if (submitButton) {
            submitButton.removeEventListener("mousedown", preventSubmit);
            submitButton.addEventListener("mousedown", preventSubmit);
        }
    };

    const handleBlur = async (input: InputElement): Promise<void> => {
        if (!hasBeenValidated) {
            return;
        }

        if (input.value.trim() !== fields.get(input).lastCheckedValue) {
            let newFields = new Map(fields);
            newFields = await updateField(newFields, input);
            newFields = await validateGovernedFields(newFields, input);
            newFields = await revalidateRequiredFields(newFields);
            newFields = resetUndisplayedFields(newFields);
            reRenderFormErrors(newFields);
            setFields(newFields);
        }
    };

    const resetField = (fields: InputMap, input: InputElement): InputMap => {
        const newFields = new Map(fields);

        newFields.set(input, {
            errorMessages: [],
            isFieldValid: true,
            lastCheckedValue: undefined,
        });

        return newFields;
    };

    const resetUndisplayedFields = (fields: InputMap): InputMap => {
        let newFields = new Map(fields);

        for (const input of newFields.keys()) {
            if (input.type !== "checkbox" && input.offsetHeight === 0) {
                newFields = resetField(newFields, input);
            }
        }

        return newFields;
    };

    const revalidateRequiredFields = async (fields: InputMap): Promise<InputMap> => {
        let newFields = new Map(fields);

        for (const input of newFields.keys()) {
            if (input.hasAttribute(UDF_ATTR_REQUIRED) && !shouldSkipValidation(input)) {
                newFields = await validateForOneAttribute(
                    newFields,
                    input,
                    getCustomAttributes(input).find((a) => a.name === UDF_ATTR_REQUIRED).validators[0],
                );
            }
        }

        return newFields;
    };

    const shouldSkipValidation = (input: InputElement): boolean => {
        return !isVisible(input) || isDisabled(input);
    };

    const isVisible = (input: InputElement): boolean => {
        return DomCrawlingHelper.isElementVisible(input);
    };

    const isDisabled = (input: InputElement): boolean => {
        return DomCrawlingHelper.isElementDisabled(input);
    };

    const validateForOneAttribute = async (
        fields: InputMap,
        input: InputElement,
        validator: InputFieldAttributeValidator,
    ): Promise<InputMap> => {
        if (shouldSkipValidation(input)) {
            return fields;
        }

        let newFields = new Map(fields);
        let newField = clone(newFields.get(input));

        if (hasBeenValidated) {
            newField = removeValidatorErrorMessages(newField, validator, true);
            newFields.set(input, newField);
            newFields = await validateForValidator(newFields, input, validator);
        }

        return newFields;
    };

    const isFieldInRightRelation = (
        subject: InputElement,
        verb: "governs" | "isGovernedBy",
        object: InputElement,
    ): boolean => {
        const mainInput = verb === "governs" ? subject : object;
        const subordinateInput = verb === "governs" ? object : subject;

        return getHtmlElementAttributeNames(mainInput).some((superiorAttribute) =>
            getGoverningAttributeNames(subordinateInput).some(
                (subordinateAttribute) =>
                    superiorAttribute === subordinateAttribute &&
                    getSiblingInputFieldWithAttribute(
                        subordinateInput,
                        verb === "governs" ? superiorAttribute : subordinateAttribute,
                    ) === mainInput,
            ),
        );
    };

    const getGoverningAttributeNames = (input: InputElement): string[] => {
        const attributeNames = getHtmlElementAttributeNames(input);
        return getCustomAttributes(input)
            .filter((a) => attributeNames.includes(a.name))
            .map((customAttribute) => customAttribute.governingFieldAttributeName)
            .filter((item) => item);
    };

    const renderFormErrors = (): void => {
        if (!form) {
            return;
        }

        removeFormErrors();
        addFormErrors();
    };

    const addFormErrors = (): void => {
        setErrorIds(formErrorMessages.reduce((aggr, field) => aggr.concat([field.id]), []));

        if (!form.parentElement) {
            return;
        }

        const distinctFormErrorMessages: string[] = getDistinctFormErrorMessages();

        if (distinctFormErrorMessages.length > 0) {
            const newErrorContainer = addFormErrorContainerToDom();
            distinctFormErrorMessages.forEach((errorMessage) => {
                addFormErrorMessageToDom(newErrorContainer, errorMessage);
            });
        }
    };

    const addFormErrorMessageToDom = (errorContainer: HTMLElement, errorMessage: string): void => {
        const newError = document.createElement("SPAN");
        newError.textContent = errorMessage;
        errorContainer.appendChild(newError);
    };

    const addFormErrorContainerToDom = (): HTMLDivElement => {
        const newErrorContainer = document.createElement("DIV") as HTMLDivElement;
        newErrorContainer.classList.add(UDF_FORM_ERROR_CONTAINER_CLASS);
        newErrorContainer.classList.add(options.errorMessageContainerClass);
        newErrorContainer.dataset.testId = T.COMMON.FORM_ERROR;
        insertAfter(newErrorContainer, form);

        return newErrorContainer;
    };

    const insertAfter = (newElement: HTMLElement, afterElement: HTMLElement): void => {
        afterElement.parentNode.insertBefore(newElement, afterElement.nextSibling);
    };

    const getDistinctFormErrorMessages = (): string[] => {
        const distinctErrorMessages = new Set<string>(
            formErrorMessages.reduce((aggr, curr) => aggr.concat([curr.message]), []),
        );

        return Array.from(distinctErrorMessages.values());
    };

    const getSiblingInputFieldWithAttribute = (input: InputElement, htmlAttributeName: string): InputElement => {
        let parent = input.parentElement;

        while (!hasOneSiblingWithAttribute(parent, htmlAttributeName)) {
            parent = nextParentInDOM(parent);

            if (!parent) {
                commonDebug.error(
                    `There is NOT exactly ONE sibling element with the attribute "${htmlAttributeName}".`,
                );
                return undefined;
            }
        }

        return getSiblingWithAttribute(parent, htmlAttributeName);
    };

    const getSiblingWithAttribute = (parent: HTMLElement, htmlAttributeName: string): InputElement => {
        return parent.querySelector(querySelectorStringForAttribute(htmlAttributeName)) as InputElement;
    };

    const querySelectorStringForAttribute = (htmlAttributeName: string): string => {
        return `input[${htmlAttributeName}], select[${htmlAttributeName}], textarea[${htmlAttributeName}]`;
    };

    const nextParentInDOM = (element: HTMLElement): HTMLElement => {
        if (element !== form && element.parentElement && element.parentElement !== document.body) {
            return element.parentElement;
        }

        return undefined;
    };

    const hasOneSiblingWithAttribute = (element: HTMLElement, htmlAttribute: string): boolean => {
        return Array.from(element.querySelectorAll(querySelectorStringForAttribute(htmlAttribute))).length === 1;
    };

    const removeFormErrors = (): void => {
        if (!form.parentElement) {
            return;
        }

        const toRemove = DomCrawlingHelper.getElemByClass(form.parentElement, UDF_FORM_ERROR_CONTAINER_CLASS);

        if (toRemove) {
            toRemove.remove();
        }
    };

    const scrollToFirstFormError = (): void => {
        if (options.noScroll) {
            return;
        }

        window.setTimeout(() => {
            const firstError = form.querySelector(
                `.${CLASS_NAMES.invalid}, .${CLASS_NAMES.stickyInvalid}, .${CLASS_NAMES.error}`,
            ) as HTMLInputElement;

            if (firstError) {
                const topOfElement = getCoords(firstError).top - 250;
                window.scroll({
                    top: topOfElement,
                    behavior: "smooth",
                });
            }
        }, 0);
    };

    const validateGovernedFields = async (fields: InputMap, input: InputElement): Promise<InputMap> => {
        let newFields = new Map(fields);

        for (const governedField of getGoverningRelations(fields, input).fieldsGovernedByThis) {
            newFields = await updateField(fields, governedField);
        }

        return newFields;
    };

    const validateForValidator = async (
        fields: InputMap,
        input: InputElement,
        validator: InputFieldAttributeValidator,
        governingFieldAttributeName?: string,
    ): Promise<InputMap> => {
        const newFields = new Map(fields);
        let newField = clone(fields.get(input));

        const governingField = governingFieldAttributeName
            ? getGoverningRelations(fields, input).fieldsGoverningThis.find((governingField) =>
                  getHtmlElementAttributeNames(governingField).includes(governingFieldAttributeName),
              )
            : undefined;

        const isValid = await validator.validate(input, governingField);

        if (isValid) {
            newField = removeValidatorErrorMessages(newField, validator);
        } else {
            newField.errorMessages.push(validator.errorMessage);
        }

        newField.isFieldValid = newField.isFieldValid && isValid;

        newFields.set(input, newField);

        return newFields;
    };

    const removeValidatorErrorMessages = (
        field: InputFieldProps,
        validator: InputFieldAttributeValidator,
        all?: boolean,
    ): InputFieldProps => {
        const newField = clone(field);
        newField.errorMessages = newField.errorMessages.filter(
            (errorMessage) =>
                !(validator.errorMessage.id === errorMessage.id && (all || errorMessage.scope === "field")),
        );

        return newField;
    };

    const addFieldErrors = (fields: InputMap, input: InputElement): void =>
        fields
            .get(input)
            .errorMessages.filter((message) => message.scope === "field")
            .forEach((message) => {
                const newError = document.createElement("DIV");
                newError.classList.add(UDF_FIELD_ERROR_CLASS);
                if (message.message instanceof TemplateResult) {
                    newError.innerHTML = getRenderString(message.message);
                } else {
                    newError.classList.add(options.fieldErrorMessageClass);

                    const newSpan = document.createElement("SPAN");
                    newSpan.dataset.testId = getTestId(T.COMMON.FORM_FIELD_ERROR, { c: message.id });
                    newSpan.textContent = message.message as string;

                    newError.appendChild(newSpan);
                }

                if (input.type === "checkbox") {
                    input.parentElement.parentElement.appendChild(newError);
                } else {
                    input.parentElement.appendChild(newError);
                }
            });

    const removeFieldErrors = (input?: InputElement): void => {
        if (input?.type === "hidden") {
            return;
        }

        const errorsToRemove = DomCrawlingHelper.getArrayOfClass(
            input ? (input.type === "checkbox" ? input.parentElement.parentElement : input.parentElement) : form,
            UDF_FIELD_ERROR_CLASS,
        );

        errorsToRemove.forEach((e) => e.remove());
    };

    const addInvalidClass = (input: InputElement): void =>
        (input.type === "checkbox" ? input.parentElement : input).classList.add(options.invalidFieldClass);

    const removeInvalidClass = (input: InputElement): void =>
        (input.type === "checkbox" ? input.parentElement : input).classList.remove(options.invalidFieldClass);

    const getCustomAttributeByName = (input: InputElement, attribute: string) =>
        getCustomAttributes(input).find((a) => a.name === attribute);

    const updateField = async (fields: InputMap, input: InputElement, forceValidate = false): Promise<InputMap> => {
        if (shouldSkipValidation(input)) {
            return fields;
        }

        removeFieldErrors(input);

        let newFields = new Map(fields);

        if ((!hasBeenValidated && !forceValidate) || (input.offsetHeight === 0 && input.type !== "checkbox")) {
            newFields.set(input, {
                errorMessages: [],
                isFieldValid: true,
                lastCheckedValue: undefined,
            });

            return newFields;
        }

        newFields.set(input, {
            errorMessages: [],
            isFieldValid: true,
            lastCheckedValue: input.type === "checkbox" ? (input as HTMLInputElement).checked : input.value.trim(),
        });

        for (const attributeName of getHtmlElementAttributeNames(input)) {
            const customAttribute = getCustomAttributeByName(input, attributeName);
            const validators = customAttribute?.validators || [];

            for (const validator of validators) {
                newFields = await validateForValidator(
                    newFields,
                    input,
                    validator,
                    customAttribute.governingFieldAttributeName,
                );
            }
        }

        if (newFields.get(input).isFieldValid) {
            removeInvalidClass(input);
        } else {
            addFieldErrors(newFields, input);
            addInvalidClass(input);
        }

        return newFields;
    };

    const reRenderFormErrors = (fields: InputMap) => {
        setFormErrorMessages([
            ...Array.from(fields.values()).reduce(
                (aggr, curr) => aggr.concat(curr.errorMessages.filter((e) => e.scope === "form")),
                [],
            ),
        ]);
    };

    const reAddBlurHandler = () => {
        for (const input of fields.keys()) {
            input.onblur = () => handleBlur(input);
        }
    };

    // EVENT HANDLERS

    const validate = async (fieldsToValidate = fields): Promise<boolean> => {
        if (!fieldsToValidate || !form) {
            throw new Error("Form not initialized, no fields to validate.");
        }

        setHasBeenValidated(true);
        setFormErrorMessages([]);

        let newFields = new Map(fieldsToValidate);

        for (const input of newFields.keys()) {
            newFields = await updateField(newFields, input, true);
        }

        reRenderFormErrors(newFields);

        window.setTimeout(() => scrollToFirstFormError(), 0);

        const messages = [
            ...new Set(
                Array.from(newFields.values()).reduce(
                    (aggr, curr) => aggr.concat(curr.errorMessages.map((e) => e.message)),
                    [],
                ),
            ),
        ];

        if (messages.length > 0) {
            await tealiumManager.logValidationError(messages);
        }

        setFields(newFields);

        return Array.from(newFields.entries()).every(
            ([input, field]) => shouldSkipValidation(input) || field.isFieldValid,
        );
    };

    const reset = (): void => {
        if (!fields || !form) {
            commonDebug.error("Form not initialized, no fields to reset.");
            return;
        }

        setHasBeenValidated(false);
        removeFieldErrors();
        setFormErrorMessages([]);
        setErrorIds([]);

        let newFields = new Map(fields);

        (Array.from(form.querySelectorAll("select, input, textarea")) as InputElement[]).forEach((input) => {
            newFields = resetField(newFields, input);
        });

        setFields(newFields);
    };

    // COMPONENT

    const [form, setForm] = useState<HTMLElement>(undefined);
    const [errorIds, setErrorIds] = useState<string[]>([]);
    const [fields, setFields] = useState<InputMap>(new Map<InputElement, InputFieldProps>());
    const [hasBeenValidated, setHasBeenValidated] = useState<boolean>(false);
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [formErrorMessages, setFormErrorMessages] = useState<FormError[]>([]);

    const tealiumManager = useTealiumManager();

    useEffect(renderFormErrors, [JSON.stringify(formErrorMessages)]);

    useEffect(reAddBlurHandler, [fields]);

    return { init, validate, reset, errorIds };
}
