import { useEffect, useMemo } from "../../../shared/haunted/CustomHooks";
import { OUTBOUND } from "../../../shared/commonConstants";
import i18next from "i18next";
import { html } from "haunted";
import { baggageIllustrations } from "./common/baggageIllustrations";
import { BaggagePageState } from "../../../component-models/baggage/BaggagePageState";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { ApiBaggageViewModel } from "../../../component-models/baggage/ApiBaggageViewModel";
import { usePerBookingBundle } from "./perBookingView/usePerBookingBundle";
import { perBookingViewSwitch } from "./perBookingView/perBookingViewSwitch";
import { usePerJourneyPerPaxTable } from "./perJourneyPerPaxView/usePerJourneyPerPaxTable";
import { useMobilePerJourneyPerPaxTable } from "./perJourneyPerPaxView/useMobilePerJourneyPerPaxTable";
import { StaffBaggage } from "./common/useStaffBaggage";
import { perBookingValidationError } from "./perBookingView/perBookingValidationError";
import { useBookingContext } from "../../../managers/useBookingContext";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { baggageEditButton } from "./common/baggageEditButton";
import { paxAmount, paxLabel } from "../../../component-helpers/BaggageHelper";
import { useMobilePerBookingPaidCabin } from "./perBookingView/useMobilePerBookingPaidCabin";
import { useMobilePerBookingFreeCabin } from "./perBookingView/useMobilePerBookingFreeCabin";
import { useBaggageSection } from "./common/useBaggageSection";
import { BaggageSectionJourney } from "../../../component-models/baggage/BaggageSectionJourney";
import { usePerBookingFreeCabin } from "./perBookingView/usePerBookingFreeCabin";
import { usePerBookingPaidCabin } from "./perBookingView/usePerBookingPaidCabin";
import { BancoEstadoBaggage } from "./common/useBancoEstadoBaggage";

export interface Props {
    baggageSectionJourneys: BaggageSectionJourney[];
    bancoEstadoBaggage: BancoEstadoBaggage;
    classToScrollToOnClose: string;
    classToScrollToOnOpen: string;
    model: ApiBaggageViewModel;
    pageState: BaggagePageState;
    staffBaggage: StaffBaggage;
    setBaggageModel: (model: ApiBaggageViewModel) => void;
    setPageState: (updater: (newState: BaggagePageState) => BaggagePageState) => void;
}

export const useCabinBags = (props: Props) => {
    const bookingContext = useBookingContext();

    const [userContext] = useReduxState("userContext");

    const cabinBag = useBaggageSection({
        baggageSectionJourneys: props.baggageSectionJourneys,
        bagType: "cabinBaggage",
        bancoEstadoBaggage: props.bancoEstadoBaggage,
        classToScrollToOnClose: props.classToScrollToOnClose,
        classToScrollToOnOpen: props.classToScrollToOnOpen,
        model: props.model,
        staffBaggage: props.staffBaggage,
        setModel: props.setBaggageModel,
        setPageState: props.setPageState,
    });

    const validationError = perBookingValidationError({
        baggageSection: cabinBag,
        bagType: "cabinBaggage",
        pageState: props.pageState,
    });

    const { perBookingDcTemplate, tooManyPaxForDcTemplate } = baggageIllustrations();

    const perBookingBundle = usePerBookingBundle({
        baggageSectionJourneys: props.baggageSectionJourneys,
        baggageState: cabinBag.sectionUi.uiState,
        bagType: "cabinBaggage",
    });

    const viewSwitch = perBookingViewSwitch({
        baggageSection: cabinBag,
        bagType: "cabinBaggage",
    });

    const perJourneyPerPaxTable = usePerJourneyPerPaxTable({
        baggageSection: cabinBag,
        bagType: "cabinBaggage",
        bancoEstadoBaggage: props.bancoEstadoBaggage,
        classToScrollToOnOpen: props.classToScrollToOnOpen,
        pageState: props.pageState,
        staffBaggage: props.staffBaggage,
    });

    const mobilePerJourneyPerPaxTable = useMobilePerJourneyPerPaxTable({
        baggageSection: cabinBag,
        bagType: "cabinBaggage",
        bancoEstadoBaggage: props.bancoEstadoBaggage,
        pageState: props.pageState,
        staffBaggage: props.staffBaggage,
    });

    const perBookingFreeOption = usePerBookingFreeCabin({
        baggageSection: cabinBag,
        pageState: props.pageState,
    });

    const perBookingPaidOption = usePerBookingPaidCabin({
        baggageSection: cabinBag,
        bancoEstadoBaggage: props.bancoEstadoBaggage,
        pageState: props.pageState,
        staffBaggage: props.staffBaggage,
    });

    const mobilePerBookingFreeOption = useMobilePerBookingFreeCabin({
        baggageSection: cabinBag,
    });

    const mobilePerBookingPaidOption = useMobilePerBookingPaidCabin({
        baggageSection: cabinBag,
        bancoEstadoBaggage: props.bancoEstadoBaggage,
        staffBaggage: props.staffBaggage,
    });

    const isViewSwitchDisabled = useMemo(
        () => cabinBag.sectionUi.uiState.journeys.length === 1 && paxAmount(cabinBag.sectionUi.uiState) === 1,
        [cabinBag.sectionUi.uiState],
    );

    const editButton = baggageEditButton({
        dataTestId: getTestId(T.BAGGAGE.OPEN_PER_JOURNEY_PER_PAX_VIEW_BUTTON, {
            c: "cabinBaggage",
            m: true,
        }),
        isDisabled: isViewSwitchDisabled,
        onClick: cabinBag.sectionUi.openPerJourneyPerPaxView,
    });

    const updateValidation = () => {
        if (!props.pageState.lastValidationTimestamp) return;
        cabinBag.updateCompulsoryValidation();
    };

    useEffect(updateValidation, [props.pageState.lastValidationTimestamp]);

    const headerTemplate = () => html`
        <header
            class="b2-section-header ${props.classToScrollToOnClose}"
            data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION_HEADER, { c: "cabinBaggage" })}
        >
            <div class="flex items-center">
                <span
                    class="b2-section-number"
                    data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION_NUMBER, { c: "cabinBaggage" })}
                    >1</span
                >
                <div>
                    <h3>${i18next.t("Selecciona tu equipaje de cabina")}</h3>
                    <h4>${i18next.t("Es el equipaje que llevas a bordo del avión.")}</h4>
                </div>
            </div>
            <div class="flex flex-col items-start">
                ${bookingContext.isDiscountBooking ? perBookingDcTemplate("cabinBaggage") : ""}
                ${perBookingBundle.htmlTemplate()}
            </div>
        </header>
    `;

    const mobilePerBookingViewSwitchTemplate = () => {
        const infoText = (() => paxAmount(cabinBag.sectionUi.uiState) === 1)()
            ? i18next.t("Estás agregando para todos los tramos")
            : i18next.t("Estás agregando para todos los pasajeros y tramos");

        return cabinBag.sectionUi.uiState.perJourneyPerPaxState !== "open"
            ? html`
                  <div class="b2m-view">
                      <div class="b2m-info-amount">
                          <div class="b2m-view-info">${infoText}</div>
                          <div class="b2m-pax-amount">
                              ${paxAmount(cabinBag.sectionUi.uiState)}
                              ${paxLabel(paxAmount(cabinBag.sectionUi.uiState))}
                          </div>
                      </div>
                      ${editButton.htmlTemplate()}
                  </div>
              `
            : "";
    };

    const htmlTemplate = () => html`
        <div data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION, { c: "cabinBaggage" })}>
            ${headerTemplate()}
            <div
                class="b2-bag-grid b2-cabin-grid hidden-xs"
                data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION_DT, { c: "cabinBaggage" })}
            >
                ${viewSwitch.htmlTemplate()}
                <div class="ac-per-booking-free-option">${perBookingFreeOption.htmlTemplate()}</div>
                <div class="ac-per-booking-paid-option">${perBookingPaidOption.htmlTemplate()}</div>
                <div class="ac-per-journey-per-pax">${perJourneyPerPaxTable.htmlTemplate()}</div>
                ${validationError.htmlTemplate(true)}
            </div>
            <div
                class="b2m-checked-bag-container hidden-sm-up"
                data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION_MB, { c: "cabinBaggage" })}
            >
                ${mobilePerBookingFreeOption.htmlTemplate()} ${mobilePerBookingPaidOption.htmlTemplate()}
                ${mobilePerBookingViewSwitchTemplate()} ${mobilePerJourneyPerPaxTable.htmlTemplate()}
                ${validationError.htmlTemplate(false)}
            </div>
            ${tooManyPaxForDcTemplate(
                cabinBag.sectionUi.uiState.journeys[OUTBOUND]?.passengers.length || 0,
                "cabinBaggage",
                userContext,
            )}
        </div>
    `;

    return {
        state: cabinBag.sectionUi.uiState,
        htmlTemplate,
        isPerJourneyPerPaxAddAvailable: cabinBag.perJourneyPerPax.isAddAvailable,
    };
};
