import { html } from "lit-html";
import {
    ItineraryPax,
    ItineraryJourney,
    ItineraryJourneyPaxSsr,
} from "../../../component-models/itinerary/ApiItineraryViewModelV2";
import { INBOUND, OUTBOUND } from "../../../shared/commonConstants";
import i18next from "i18next";
import { classMap } from "lit-html/directives/class-map";
import classNames from "classnames";
import { useFlowContext } from "../../../managers/useFlowContext";
import { unsafeHTML } from "lit-html/directives/unsafe-html";

export interface Props {
    journeys: ItineraryJourney[];
    isMobile: boolean;
}

export const useItineraryDetailsPaxSsrs = (props: Props) => {
    const flowContext = useFlowContext();

    // TEMPLATES

    const paxJourneySsrsTemplate = (journey: ItineraryJourney, pax: ItineraryPax) => {
        const journeyPassenger = journey.JourneyPassengers.find((p) => p.PassengerNumber === pax.PassengerNumber);

        if (!journeyPassenger || !journeyPassenger.Optionals || journeyPassenger.Optionals.length === 0) {
            return "";
        }

        const ssrClassMap = classMap({
            "flex": true,
            "justify-between": true,
            "mt-2": journey.JourneyIndex === INBOUND,
        });

        return html`
            <div class=${ssrClassMap}>
                <div class="i2-fare-journey">
                    ${journey.JourneyIndex === OUTBOUND ? i18next.t("Ida") : i18next.t("Vuelta")}
                </div>
                <div class="w-full">${journeyPassenger.Optionals.map(paxSsrItemTemplate)}</div>
            </div>
        `;
    };

    // FIXME Should not be based on ScreenName
    const paxSsrItemTemplate = (ssr: ItineraryJourneyPaxSsr) => html`
        <div class=${classNames("i2-ssr-item", { "redemption-details-ssr-item": flowContext.isAaPostBooking })}>
            <span
                >${ssr.Quantity} -
                ${ssr.ScreenName?.includes("AAdvantage")
                    ? unsafeHTML(
                          i18next.t("Millas AAdvantage {{-reg}}", {
                              reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
                          }),
                      )
                    : ssr.ScreenName || ssr.ChargeCode}</span
            >
            ${ssr.Note ? html` <span>${ssr.Note}</span> ` : ""}
            <span>${ssr.FormattedPrice}</span>
        </div>
    `;

    const htmlTemplate = (pax: ItineraryPax) => {
        if (
            !pax.PerBookingOptionals ||
            !Array.isArray(pax.PerBookingOptionals) ||
            pax.PerBookingOptionals.length === 0
        ) {
            return props.journeys.map((journey) => paxJourneySsrsTemplate(journey, pax));
        }

        return html`
            <div class="mb-2 flex justify-between">
                <div class="i2-fare-journey"></div>
                <div class="w-full">${pax.PerBookingOptionals.map((ssr) => paxSsrItemTemplate(ssr))}</div>
            </div>

            ${props.journeys.map((journey) => paxJourneySsrsTemplate(journey, pax))}
        `;
    };

    return { htmlTemplate };
};
