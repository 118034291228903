import { OUTBOUND, WEBANONYMOUS } from "../../shared/commonConstants";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useBookingContext } from "../useBookingContext";
import { useTealiumManager } from "./useTealiumManager";

export const useExtrasTealiumManager = () => {
    const bookingContext = useBookingContext();
    const tealiumManager = useTealiumManager();

    const [userContext] = useReduxState("userContext");
    const [currency] = useReduxState("booking.currency");
    const [ssrData] = useReduxState("booking.ssrData");

    const logExtrasPageLoad = async (flatBookingData: string) => {
        const [ssrsCodeAvailableOutbound, ssrsCodeIncludedOutbound, ssrsCodeAvailableInbound, ssrsCodeIncludedInbound] =
            ssrData;

        await tealiumManager.logDomLoaded("extras", {
            UdoParams: {
                ssrs_code_available_inbound: ssrsCodeAvailableInbound,
                ssrs_code_available_outbound: ssrsCodeAvailableOutbound,
                ssrs_code_included_inbound: ssrsCodeIncludedInbound,
                ssrs_code_included_outbound: ssrsCodeIncludedOutbound,
                ...JSON.parse(flatBookingData),
            },
            UpdateCommonUdo: true,
            UpdateRealUdo: true,
        });
    };

    const logBinaryExtraSelected = (
        type: "priorityBoarding" | "airportCheckin" | "flexiFee" | "pet" | "insurance",
        passengerType: "A" | "C" | "N/A",
        passengerNumber: number | "N/A",
        journeyIndex: number | "N/A",
        changeType: "add" | "remove",
        price: number,
    ) =>
        tealiumManager.log({
            EventName: "extra_selected",
            EventParams: {
                company_identifier:
                    userContext.cug.orgName?.toLowerCase() !== WEBANONYMOUS.toLowerCase()
                        ? userContext.cug.orgName || "N/A"
                        : "N/A",
                currency,
                direction: journeyIndex === OUTBOUND ? "O" : "I",
                paxindex: passengerNumber,
                paxtype: passengerType,
                previous_currency: currency,
                previous_price: changeType === "add" ? 0 : price,
                previous_quantity: changeType === "add" ? 0 : 1,
                price: changeType === "add" ? price : 0,
                quantity: changeType === "add" ? 1 : 0,
                type,
            },
            UpdateCommonUdo: true,
        });

    const logMultiOptionExtraSelected = (
        type: "checkedBaggageInsurance" | "oversizedBaggageInsurance",
        passengerType: "A" | "C" | "N/A",
        passengerNumber: number | "N/A",
        price: number,
        previousPrice: number,
        quantity: number,
        previousQuantity: number,
    ) =>
        tealiumManager.log({
            EventName: "extra_selected",
            EventParams: {
                company_identifier:
                    userContext.cug.orgName?.toLowerCase() !== WEBANONYMOUS.toLowerCase()
                        ? userContext.cug.orgName || "N/A"
                        : "N/A",
                currency,
                direction: "N/A",
                pax_index: passengerNumber,
                pax_type: passengerType,
                previous_price: previousPrice,
                previous_quantity: previousQuantity,
                price,
                promocode: bookingContext.promoCode,
                quantity,
                type,
            },
            UpdateCommonUdo: true,
        });

    return {
        logBinaryExtraSelected,
        logContinueClicked: tealiumManager.logContinueClicked,
        logExtrasPageLoad,
        logMultiOptionExtraSelected,
    };
};
