import { html, useEffect, useMemo } from "haunted";
import i18next from "i18next";
import { classMap } from "lit-html/directives/class-map";
import { SelectOption, getAntiforgerySegment, sanitizeRutFieldValue } from "../../../shared/common";
import { PRIORITISED_COUNTRIES } from "../../itinerary/RefundBankInfoHelper";
import { CHILE_COUNTRY_CODE } from "../../../shared/commonConstants";
import { RefundBankValidatorPartialMethods, WidgetSectionType } from "./useProfilePersonWidget";
import { ProfilePersonWidgetViewModel } from "../../../component-models/profile/ProfilePersonWidgetViewModel";
import { sanitizeInputFieldValue } from "../../../component-helpers/InputSanitizerHelper";
import { useErrorMessage } from "../../ui/error-message/useErrorMessage";
import { useAppContext } from "../../../managers/useAppContext";

export interface RefundBankVM {
    firstName: string;
    lastName: string;
    country: string;
    documentType: string;
    documentNumber: string;
    bank: string;
    accountType: string;
    accountNumber: string;
}

export interface Props {
    model: ProfilePersonWidgetViewModel;
    openedSection: string;
    isValidated: boolean;
    vm: RefundBankVM;
    antiForgeryToken: string;
    validatorPartialMethods: RefundBankValidatorPartialMethods;
    refundAccountTypes: string[];
    refundBankNames: string[];
    refundDocTypes: SelectOption[];
    toggleSections: (section: WidgetSectionType) => void;
    setVm: (vm: RefundBankVM) => void;
    handleSubmit: (e: MouseEvent, formElemType: WidgetSectionType) => Promise<void>;
    mapAccountTypeArray: (countryCode: string) => void;
    mapBankNameArray: (countryCode: string) => void;
    mapDocTypesArray: (countryCode: string) => void;
}

export const useRefundBankSection = (props: Props) => {
    const appContext = useAppContext();

    const formErrors = useErrorMessage({ errorMessage: props.validatorPartialMethods.getFormMessages() });

    const customInputClasses = [
        "peer",
        "m-0",
        "block",
        "h-[42px]",
        "w-full",
        "appearance-none",
        "rounded-[5px]",
        "border",
        "border-solid",
        "pb-[3px]",
        "pl-[14px]",
        "pr-[4px]",
        "text-left",
        "text-[13px]",
        "leading-[52px]",
        "text-brand-secondary",
        "common-transition",
        "placeholder:text-[#818a91]",
        "focus:border-be-cyan",
        "focus:text-brand-secondary",
    ];

    const customSelectClasses = [
        "peer",
        "m-0",
        "block",
        "h-[42px]",
        "w-full",
        "cursor-pointer",
        "appearance-none",
        "rounded-[5px]",
        "border",
        "border-solid",
        "pb-[3px]",
        "pl-[14px]",
        "pr-[4px]",
        "pt-[8px]",
        "text-left",
        "text-[13px]",
        "leading-[42px]",
        "text-brand-secondary",
        "common-transition",
        "placeholder:text-[#818a91]",
        "focus:border-be-cyan",
        "focus:text-brand-secondary",
    ];

    const isSelectedCountryPrioritised = (): boolean => {
        return props.vm.country ? PRIORITISED_COUNTRIES.includes(props.vm.country) : false;
    };

    const isDocTypeRut = (): boolean => {
        return props.vm.country === CHILE_COUNTRY_CODE && props.vm.documentType === "4";
    };

    const accountTypeOptions = useMemo(
        () => [
            { Text: "", Value: "", IsSelected: !props.vm.accountType },
            ...props.refundAccountTypes.map((accountType) => ({
                Text: accountType,
                Value: accountType,
                IsSelected: accountType === props.vm.accountType,
            })),
        ],
        [props.refundAccountTypes, props.vm.accountType],
    );

    const bankNameOptions = useMemo(
        () => [
            { Text: "", Value: "", IsSelected: !props.vm.bank },
            ...props.refundBankNames.map((bankName) => ({
                Text: bankName,
                Value: bankName,
                IsSelected: bankName === props.vm.bank,
            })),
        ],
        [props.refundBankNames, props.vm.bank],
    );

    const documentTypeOptions = useMemo(
        () => [
            {
                Value: "",
                Text: "",
                IsSelected: !props.model.ProfileRefundViewModel.IsRefundDataProvided && !props.vm.documentType,
            },
            ...props.refundDocTypes?.map((docType: SelectOption) => ({
                IsSelected: docType.Value === props.vm.documentType,
                Text: docType.Text,
                Value: docType.Value,
            })),
        ],
        [props.refundDocTypes, props.vm.documentType, props.model.ProfileRefundViewModel.IsRefundDataProvided],
    );

    const countryOptions = useMemo(
        () => [
            { Value: "", Text: "", IsSelected: !props.vm.country },
            ...appContext.Countries?.map((c: SelectOption) => ({
                IsSelected: c.Value === props.vm.country,
                Text: c.Text,
                Value: c.Value,
            })),
        ],
        [appContext.Countries, props.vm.country],
    );

    useEffect(() => {
        if (props.vm.country) {
            props.mapAccountTypeArray(props.vm.country);
            props.mapBankNameArray(props.vm.country);
            props.mapDocTypesArray(props.vm.country);
        }
    }, [props.vm.country]);

    // TEMPLATES

    const bankDataTemplate = () => html`
        <div class="bank-data-description">
            ${i18next.t(
                "Solicitamos esta información, para que en caso de que tengas alguna reserva donde aplique la devolución de las tasas de embarque, podamos dar cumplimiento de esta obligación oportunamente.",
            )}
        </div>
        <div class="row">
            <div class="col-xs-1 col-sm-1-2">
                <div class="mt-[20px]">
                    <ac-input
                        .customClasses=${customInputClasses}
                        .isLabelSmaller=${true}
                        .errorMessage=${props.validatorPartialMethods.getMessage("firstName")}
                        .isInvalid=${!props.validatorPartialMethods.isValid("firstName")}
                        .isReadonly=${true}
                        .autoComplete=${"cc-number"}
                        .tabIndexAttr=${-1}
                        .label=${i18next.t("Nombres")}
                        .testId=${"bank-data-first-name"}
                        .value=${props.vm.firstName}
                    ></ac-input>
                </div>
            </div>
            <div class="col-xs-1 col-sm-1-2">
                <div class="mt-[20px]">
                    <ac-input
                        .customClasses=${customInputClasses}
                        .isLabelSmaller=${true}
                        .errorMessage=${props.validatorPartialMethods.getMessage("lastName")}
                        .isInvalid=${!props.validatorPartialMethods.isValid("lastName")}
                        .isReadonly=${true}
                        .autoComplete=${"cc-number"}
                        .tabIndexAttr=${-1}
                        .label=${i18next.t("Apellidos")}
                        .testId=${"bank-data-last-name"}
                        .value=${props.vm.lastName}
                    ></ac-input>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1 col-sm-1-2">
                <div class="mt-[20px]">
                    <ac-select
                        .customClasses=${customSelectClasses}
                        .isLabelSmaller=${true}
                        .errorMessage=${props.validatorPartialMethods.getMessage("country")}
                        .isInvalid=${!props.validatorPartialMethods.isValid("country")}
                        .label=${i18next.t("País")}
                        .autoComplete=${"off"}
                        .name="countryCode"
                        .options=${countryOptions}
                        .tabIndexAttr=${-1}
                        .testId=${"refund-country-field"}
                        .onSelect=${(value: string) =>
                            props.setVm({
                                ...props.vm,
                                country: value,
                                documentType: "",
                                bank: "",
                                accountType: "",
                                documentNumber: "",
                            })}
                    >
                    </ac-select>
                </div>
            </div>
            ${isSelectedCountryPrioritised()
                ? html`
                      <div class="col-xs-1 col-sm-1-2">
                          <div class="mt-[20px]">
                              <ac-select
                                  .customClasses=${customSelectClasses}
                                  .isLabelSmaller=${true}
                                  .errorMessage=${props.validatorPartialMethods.getMessage("documentType")}
                                  .isInvalid=${!props.validatorPartialMethods.isValid("documentType")}
                                  .label=${i18next.t("Document type")}
                                  .name="documentType"
                                  .options=${documentTypeOptions}
                                  .tabIndexAttr=${-1}
                                  .testId=${"refund-doctype-field"}
                                  .onSelect=${(value: string) =>
                                      props.setVm({ ...props.vm, documentType: value, documentNumber: "" })}
                              >
                              </ac-select>
                          </div>
                      </div>
                  `
                : html`
                      <div class="col-xs-1 col-sm-1-2">
                          <div class="mt-[20px]">
                              <ac-input
                                  .customClasses=${customInputClasses}
                                  .isLabelSmaller=${true}
                                  .errorMessage=${props.validatorPartialMethods.getMessage("documentType")}
                                  .isInvalid=${!props.validatorPartialMethods.isValid("documentType")}
                                  .name=${"documentType"}
                                  .maxLength=${32}
                                  .tabIndexAttr=${-1}
                                  .label=${i18next.t("Document type")}
                                  .testId=${"refund-doctype-field"}
                                  .value=${props.vm.documentType}
                                  .onInput=${(value: string) => props.setVm({ ...props.vm, documentType: value })}
                              ></ac-input>
                          </div>
                      </div>
                  `}
        </div>
        <div class="row">
            ${isDocTypeRut()
                ? html`
                      <div class="col-xs-1 col-sm-1-2">
                          <div class="mt-[20px]">
                              <ac-input
                                  .customClasses=${customInputClasses}
                                  .isLabelSmaller=${true}
                                  .errorMessage=${props.validatorPartialMethods.getMessage("documentNumber")}
                                  .isInvalid=${!props.validatorPartialMethods.isValid("documentNumber")}
                                  .name=${"documentNumberField"}
                                  .tabIndexAttr=${-1}
                                  .label=${i18next.t("Document number")}
                                  .testId=${"refund-docnumber-field"}
                                  .sanitizer=${(e: KeyboardEvent) =>
                                      sanitizeRutFieldValue(e.target as HTMLInputElement)}
                                  .value=${props.vm.documentNumber}
                                  .onInput=${(value: string) => props.setVm({ ...props.vm, documentNumber: value })}
                              ></ac-input>
                          </div>
                      </div>
                  `
                : html`
                      <div class="col-xs-1 col-sm-1-2">
                          <div class="mt-[20px]">
                              <ac-input
                                  .customClasses=${customInputClasses}
                                  .isLabelSmaller=${true}
                                  .errorMessage=${props.validatorPartialMethods.getMessage("documentNumber")}
                                  .isInvalid=${!props.validatorPartialMethods.isValid("documentNumber")}
                                  .name=${"documentNumberField"}
                                  .tabIndexAttr=${-1}
                                  .label=${i18next.t("Document number")}
                                  .testId=${"refund-docnumber-field"}
                                  .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "alphanumeric")}
                                  .value=${props.vm.documentNumber}
                                  .onInput=${(value: string) => props.setVm({ ...props.vm, documentNumber: value })}
                              ></ac-input>
                          </div>
                      </div>
                  `}
            ${isSelectedCountryPrioritised()
                ? html`
                      <div class="col-xs-1 col-sm-1-2">
                          <div class="mt-[20px]">
                              <ac-select
                                  .customClasses=${customSelectClasses}
                                  .isLabelSmaller=${true}
                                  .errorMessage=${props.validatorPartialMethods.getMessage("bank")}
                                  .isInvalid=${!props.validatorPartialMethods.isValid("bank")}
                                  .label=${i18next.t("Banco")}
                                  .name="bankNameField"
                                  .options=${bankNameOptions}
                                  .tabIndexAttr=${-1}
                                  .testId=${"refund-bank-name-field"}
                                  .onSelect=${(value: string) => props.setVm({ ...props.vm, bank: value })}
                              >
                              </ac-select>
                          </div>
                      </div>
                  `
                : html`
                      <div class="col-xs-1 col-sm-1-2">
                          <div class="mt-[20px]">
                              <ac-input
                                  .customClasses=${customInputClasses}
                                  .isLabelSmaller=${true}
                                  .errorMessage=${props.validatorPartialMethods.getMessage("bank")}
                                  .isInvalid=${!props.validatorPartialMethods.isValid("bank")}
                                  .name=${"bankNameField"}
                                  .maxLength=${32}
                                  .tabIndexAttr=${-1}
                                  .label=${i18next.t("Banco")}
                                  .testId=${"refund-bank-name-field"}
                                  .value=${props.vm.bank}
                                  .onInput=${(value: string) => props.setVm({ ...props.vm, bank: value })}
                              ></ac-input>
                          </div>
                      </div>
                  `}
        </div>
        <div class="row">
            ${isSelectedCountryPrioritised()
                ? html`
                      <div class="col-xs-1 col-sm-1-2">
                          <div class="mt-[20px]">
                              <ac-select
                                  .customClasses=${customSelectClasses}
                                  .isLabelSmaller=${true}
                                  .errorMessage=${props.validatorPartialMethods.getMessage("accountType")}
                                  .isInvalid=${!props.validatorPartialMethods.isValid("accountType")}
                                  .label=${i18next.t("Tipo de cuenta")}
                                  .name="cardTypeField"
                                  .options=${accountTypeOptions}
                                  .tabIndexAttr=${-1}
                                  .testId=${"refund-card-type-field"}
                                  .onSelect=${(value: string) => props.setVm({ ...props.vm, accountType: value })}
                              >
                              </ac-select>
                          </div>
                      </div>
                  `
                : html`
                      <div class="col-xs-1 col-sm-1-2">
                          <div class="mt-[20px]">
                              <ac-input
                                  .customClasses=${customInputClasses}
                                  .isLabelSmaller=${true}
                                  .errorMessage=${props.validatorPartialMethods.getMessage("accountType")}
                                  .isInvalid=${!props.validatorPartialMethods.isValid("accountType")}
                                  .name=${"cardTypeField"}
                                  .maxLength=${32}
                                  .tabIndexAttr=${-1}
                                  .label=${i18next.t("Tipo de cuenta")}
                                  .testId=${"refund-card-type-field"}
                                  .value=${props.vm.accountType}
                                  .onInput=${(value: string) => props.setVm({ ...props.vm, accountType: value })}
                              ></ac-input>
                          </div>
                      </div>
                  `}
            <div class="col-xs-1 col-sm-1-2">
                <div class="mt-[20px]">
                    <ac-input
                        .customClasses=${customInputClasses}
                        .isLabelSmaller=${true}
                        .errorMessage=${props.validatorPartialMethods.getMessage("accountNumber")}
                        .isInvalid=${!props.validatorPartialMethods.isValid("accountNumber")}
                        .name=${"accountNumberField"}
                        .maxLength=${32}
                        .label=${i18next.t("Número de cuenta")}
                        .testId=${"bank-data-account-number-field"}
                        .value=${props.vm.accountNumber}
                        .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "bank-account-number")}
                        .onInput=${(value: string) => props.setVm({ ...props.vm, accountNumber: value })}
                    ></ac-input>
                </div>
            </div>
        </div>
    `;

    const submitButtonTemplate = () => {
        const dataTestId = `profile-edit-submit-bank-button`;

        return html`
            <div class="my-2 text-right md:my-4 ">
                <button
                    class="pw-cta"
                    data-test-id=${dataTestId}
                    @click=${(e: MouseEvent) => props.handleSubmit(e, "bank")}
                >
                    ${i18next.t("Guardar")} <i class="js-icon js-circle-chevron-right"></i>
                </button>
            </div>
        `;
    };

    const sectionHeaderTemplate = () => {
        const tmpClassMap = classMap({
            "js-icon": true,
            "js-circle-chevron-right": true,
            "pw-section-opener": true,
            "open": props.openedSection === "bank",
        });
        return html`
            <div class="pw-editor-title mt-4" @click=${() => props.toggleSections("bank")}>
                <i class="${["js-icon-refund", "js-refund-rw-bank-info"].join(" ")}"></i>${i18next.t("Datos Bancarios")}
                <i class=${tmpClassMap}></i>
            </div>
        `;
    };

    const htmlTemplate = () => {
        const accordionClassMap = classMap({
            "pw-section": true,
            "pw-section-open": props.openedSection === "bank",
        });

        return html`${sectionHeaderTemplate()}
            <div class=${accordionClassMap}>
                <div>
                    ${getAntiforgerySegment(props.antiForgeryToken)} ${bankDataTemplate()} ${formErrors.htmlTemplate()}
                    ${submitButtonTemplate()}
                </div>
            </div>`;
    };

    return { htmlTemplate };
};
