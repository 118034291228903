import { ChangeSearchViewModel } from "./../../component-models/ChangeSearchViewModel";
import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import {
    ACTION_NAMES,
    CHILEAN_PESO_CODE,
    DEFAULT_DATE_FORMAT,
    MIN_NUMBER_FOR_GROUP_BOOKINGS,
    PERU_COMPRA_CURRENCY,
} from "../../shared/commonConstants";
import { useState } from "../../shared/haunted/CustomHooks";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { html, useRef, useEffect as hauntedUseEffect } from "haunted";
import { getParsedProperty, redoSearch, showLoader } from "../../shared/common";
import { handleFieldBlur, validate } from "../../shared/form-validation";
import i18next from "i18next";
import { ref } from "../../directives/ref";
import { classMap } from "lit-html/directives/class-map";
import dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { useRouteAndDateSelector } from "../route-and-date-selector/useRouteAndDateSelector";
import { useBookingDataManager } from "../../managers/useBookingDataManager";
import { useChangeSearchTealiumManager } from "../../managers/Tealium/useChangeSearchTealiumManager";
import { useAppContext } from "../../managers/useAppContext";
import { useBasicCheckbox } from "../ui/basic-checkbox/useBasicCheckbox";
import { ApiCurrency } from "../../component-models/ApiCurrency";
import { ROUTES } from "../../shared/apiRoutes";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useBookingContext } from "../../managers/useBookingContext";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useFlowContext } from "../../managers/useFlowContext";

export const name = "ac-change-search";

export const loaderClass = "change-search-loader";

export const observedAttributes: (keyof Attributes)[] = ["model"];

export interface Attributes {
    model: string;
}

export interface Props {
    model: ChangeSearchViewModel;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        model: getParsedProperty<ChangeSearchViewModel>(host.model),
    };

    const flowContext = useFlowContext();

    const init = () => {
        bookingDataManager.eraseBookingData();

        const doIt = async () => {
            await loadAvailableCurrencies();
        };

        void doIt();

        const handler1 = triggers.flight.cultureChanged.subscribe(handleCultureChangeOnFlightPage);
        const handler2 = triggers.login.setFixedCurrency.subscribe((e) => {
            setSelectedCurrency(e);
            void doIt();
        });

        return () => {
            handler1.unsubscribe();
            handler2.unsubscribe();
        };
    };

    const loadAvailableCurrencies = async () => {
        const loader = showLoader({ container: document.body, name: loaderClass });
        const response = await ajaxJsonRequest<ApiCurrency[]>({
            url: ROUTES.AvailableCurrencies,
            method: "GET",
            loader,
        });
        setCurrencies(response?.data);
    };

    const handleCultureChangeOnFlightPage = (culture: string) => {
        redoSearch({
            isOneWay,
            origin: props.model.Origin,
            destination: props.model.Destination,
            unformattedOutboundDate: props.model.FromDate,
            unformattedInboundDate: props.model.ToDate,
            adults: props.model.AdultNumber.toString(),
            children: props.model.ChildNumber.toString(),
            infants: props.model.InfantNumber.toString(),
            culture,
            // DEVNOTE Important! JET-6367 Issue with currency when we change culture
            currency:
                appContext.LocalCurrencyDisplaySettings.CultureSpecificDisplaySettings.find(
                    (csds) => csds.CultureCode.toLowerCase() === culture.toLowerCase(),
                )?.DefaultCurrency || "USD",
            // DEVNOTE Important! JET-6222 Clear promo code on culture change
            promoCode: undefined,
            isRedemption: isRedemptionSearchSelected || flowContext.isRedemptionFlow,
        });
    };

    const canHaveMorePax = () => {
        return numberOfAdults + numberOfChildren < MIN_NUMBER_FOR_GROUP_BOOKINGS - 1;
    };

    const handleDecreaseAdults = () => {
        const newValue = numberOfAdults - 1;
        setNumberOfAdults(newValue);
        if (newValue < numberOfInfants) {
            setNumberOfInfants(newValue);
        }
    };

    const handleRoundTrip = () => {
        setIsOneWay(false);
    };

    const handleOneWayTrip = () => {
        setIsOneWay(true);
    };

    const submitForm = async (e: MouseEvent, pc?: string) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const isValid = validate(root.current);

        if (isValid) {
            await tealiumManager.logSearchClicked({
                departureJourneyDate: routeAndDateSelector.selectedOutboundDate,
                destinationIata: routeAndDateSelector.selectedDestination || props.model.Destination,
                market:
                    routeAndDateSelector.selectedOriginCountry !== routeAndDateSelector.selectedDestinationCountry
                        ? "INTER"
                        : `DOM-${routeAndDateSelector.selectedOriginCountry}`,
                numberOfAdults,
                numberOfChildren,
                numberOfInfants,
                originIata: routeAndDateSelector.selectedOrigin || props.model.Origin,
                promoCode: props.model.PromoCode,
                returnJourneyDate: routeAndDateSelector.selectedInboundDate,
            });

            window.setTimeout(() => {
                redoSearch({
                    isOneWay,
                    origin: routeAndDateSelector.selectedOrigin || props.model.Origin,
                    destination: routeAndDateSelector.selectedDestination || props.model.Destination,
                    unformattedOutboundDate: routeAndDateSelector.selectedOutboundDate
                        ? routeAndDateSelector.selectedOutboundDate.format(DEFAULT_DATE_FORMAT)
                        : props.model.FromDate,
                    unformattedInboundDate: routeAndDateSelector.selectedInboundDate
                        ? routeAndDateSelector.selectedInboundDate.format(DEFAULT_DATE_FORMAT)
                        : props.model.ToDate
                          ? props.model.ToDate
                          : "",
                    adults: numberOfAdults.toString(),
                    children: numberOfChildren.toString(),
                    infants: numberOfInfants.toString(),
                    promoCode: pc ? pc : bookingContext.promoCode,
                    culture: appContext.Culture,
                    currency: selectedCurrency,
                    isRedemption: isRedemptionSearchSelected || flowContext.isRedemptionFlow,
                });
            }, 100);
        }
    };

    const handleChange = (
        _origin: string,
        _destination: string,
        outboundDate: dayjs.Dayjs,
        inboundDate: dayjs.Dayjs,
    ) => {
        setCurrentInboundDate(inboundDate ? dayjs(inboundDate) : undefined);
        setCurrentOutboundDate(outboundDate ? dayjs(outboundDate) : undefined);
    };

    const getInitialOutboundDate = () => (props.model.FromDate ? dayjs(props.model.FromDate) : undefined);
    const getInitialInboundDate = () => (props.model.ToDate ? dayjs(props.model.ToDate) : undefined);

    const isRestrictedToClp = () => userContext.bancoEstado.category !== 0 || userContext.chileCompra.role !== "none";

    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const tealiumManager = useChangeSearchTealiumManager();
    const bookingDataManager = useBookingDataManager();

    const { ajaxJsonRequest } = useAjax();

    const [userContext] = useReduxState("userContext");

    const root = useRef<HTMLDivElement>(undefined);

    const [isOneWay, setIsOneWay] = useState<boolean>(props.model.IsOneWay);
    const [numberOfAdults, setNumberOfAdults] = useState<number>(props.model.AdultNumber || 1);
    const [numberOfChildren, setNumberOfChildren] = useState<number>(props.model.ChildNumber);
    const [numberOfInfants, setNumberOfInfants] = useState<number>(props.model.InfantNumber);
    const [selectedCurrency, setSelectedCurrency] = useState<string>(props.model.InitialCurrency);
    const [currencies, setCurrencies] = useState<ApiCurrency[]>(undefined);
    const [currentOutboundDate, setCurrentOutboundDate] = useState<dayjs.Dayjs>(getInitialOutboundDate());
    const [currentInboundDate, setCurrentInboundDate] = useState<dayjs.Dayjs>(getInitialInboundDate());
    const [isRedemptionSearchSelected, setIsRedemptionSearchSelected] = useState<boolean>(false);

    const { triggers } = usePubSub();

    const roundTripCheckbox = useBasicCheckbox({
        isChecked: !isOneWay,
        customWrapperClass: ["col-xs-1-2", "col-sm-1-3", "pl-2"],
        inputTestId: T.SEARCH.ROUND_TRIP_INPUT,
        labelTestId: T.SEARCH.ROUND_TRIP,
        labelText: i18next.t("V2-ReturnJourneyLabel"),
        onClick: handleRoundTrip,
    });

    const oneWayTripCheckbox = useBasicCheckbox({
        isChecked: isOneWay,
        customWrapperClass: ["col-xs-1-2", "col-sm-1-3"],
        inputTestId: T.SEARCH.ONE_WAY_TRIP_INPUT,
        labelTestId: T.SEARCH.ONE_WAY_TRIP,
        labelText: i18next.t("V2-OneWayJourneyLabel"),
        onClick: handleOneWayTrip,
    });

    const redemptionSearchCheckbox = useBasicCheckbox({
        isChecked: isRedemptionSearchSelected,
        inputTestId: T.SEARCH.REDEMPTION_SEARCH_INPUT,
        labelTestId: T.SEARCH.REDEMPTION_SEARCH,
        labelText: i18next.t("RedemptionSearchLabel {{-reg}}", {
            reg: '<span class="relative font-body top-[-1px] mr-[2px]">&reg;</span>',
        }),
        onClick: () => setIsRedemptionSearchSelected(!isRedemptionSearchSelected),
    });

    const routeAndDateSelector = useRouteAndDateSelector({
        culture: appContext.Culture,
        currency: selectedCurrency,
        destination: props.model.Destination,
        fixedInboundDate: false,
        fixedOutboundDate: false,
        inboundDate: currentInboundDate,
        isOneWay,
        maxInboundDate: undefined,
        maxOutboundDate: undefined,
        minInboundDate: undefined,
        minOutboundDate: undefined,
        origin: props.model.Origin,
        outboundDate: currentOutboundDate,
        sameCitiesOnly: false,
        stationSettings: props.model.StationSettings,
        useDatepickerCaptions: false,
        onChange: handleChange,
    });

    hauntedUseEffect(init, []);

    const tripNumberTemplate = () => html` ${roundTripCheckbox.htmlTemplate()} ${oneWayTripCheckbox.htmlTemplate()} `;

    const currencyOptionsForPeruCompraTemplate = () => {
        const peruCompraCurrency = currencies?.find((c) => c.CurrencyCode === PERU_COMPRA_CURRENCY);

        return userContext.peruCompra.role !== "none" || bookingContext.isPeruCompraBooking
            ? html`
                  <option disabled selected value=${peruCompraCurrency?.CurrencyCode}>
                      ${peruCompraCurrency?.Description}
                  </option>
              `
            : "";
    };

    const currencyOptionsForClpOnlyTemplate = () => {
        const clpCurrency = currencies?.find((c) => c.CurrencyCode === CHILEAN_PESO_CODE);

        return isRestrictedToClp()
            ? html`
                  <option disabled selected value=${clpCurrency?.CurrencyCode || ""}>
                      ${clpCurrency?.Description || ""}
                  </option>
              `
            : "";
    };

    const regularCurrencyOptionsTemplate = () => {
        return !isRestrictedToClp() && userContext.peruCompra.role === "none" && !bookingContext.isPeruCompraBooking
            ? html`
                  ${currencies?.map(
                      (c) => html`
                          <option
                              value=${c.CurrencyCode}
                              ?selected=${c.CurrencyCode === selectedCurrency}
                              data-test-id=${getTestId(T.SEARCH.CURRENCY_ITEM, { c: c.CurrencyCode })}
                          >
                              ${c.Description}
                          </option>
                      `,
                  )}
              `
            : "";
    };

    const currencyTemplate = () => html`
        <div class="col-xs-1 col-sm-1-3">
            <div
                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow ${currencies
                    ? "is-dirty"
                    : ""}"
            >
                <label class="mdl-textfield__label"> ${i18next.t("V2-CurrencyLabel")} </label>
                <select
                    class="mdl-textfield__input js-input js-select"
                    data-required
                    data-test-id=${T.SEARCH.CURRENCY_SELECTOR}
                    @change=${(e: MouseEvent) => setSelectedCurrency((e.target as HTMLSelectElement).value)}
                    @blur=${(e: MouseEvent) => handleFieldBlur(e.target as HTMLInputElement, root.current)}
                >
                    ${currencyOptionsForClpOnlyTemplate()} ${regularCurrencyOptionsTemplate()}
                    ${currencyOptionsForPeruCompraTemplate()}
                </select>
            </div>
        </div>
    `;

    const adultsTemplate = () => {
        const decreaseClassMap = classMap({
            "minus-btn": true,
            "disabled": numberOfAdults === 1,
        });

        const increaseClassMap = classMap({
            "plus-btn": true,
            "disabled": !canHaveMorePax(),
        });

        return html`
            <div class="col-xs-1 col-sm-1-3 col-md-1-4 number-selector-container">
                <label class="change-search-label condensed">${i18next.t("V2-AdultsLabel")}</label>
                <div class="jetsmart-number-selector float-center">
                    <span
                        @click=${handleDecreaseAdults}
                        class=${decreaseClassMap}
                        data-test-id=${T.SEARCH.ADULTS_DECREASE}
                    >
                        -
                    </span>
                    <span
                        class=${increaseClassMap}
                        data-test-id=${T.SEARCH.ADULTS_INCREASE}
                        @click=${() => setNumberOfAdults(numberOfAdults + 1)}
                    >
                        +
                    </span>
                    <span data-test-id=${T.SEARCH.ADULT_AMOUNT}>${numberOfAdults}</span>
                </div>
            </div>
        `;
    };

    const childrenTemplate = () => {
        const decreaseClassMap = classMap({
            "minus-btn": true,
            "disabled": numberOfChildren === 0,
        });

        const increaseClassMap = classMap({
            "plus-btn": true,
            "disabled": !canHaveMorePax(),
        });

        return html`
            <div class="col-xs-1 col-sm-1-3 col-md-1-4">
                <label class="change-search-label">${i18next.t("V2-ChildrenLabel")}</label>
                <div class="jetsmart-number-selector float-center">
                    <span
                        class=${decreaseClassMap}
                        data-test-id=${T.SEARCH.CHILDREN_DECREASE}
                        @click=${() => setNumberOfChildren(numberOfChildren - 1)}
                    >
                        -
                    </span>
                    <span
                        class=${increaseClassMap}
                        data-test-id=${T.SEARCH.CHILDREN_INCREASE}
                        @click=${() => setNumberOfChildren(numberOfChildren + 1)}
                    >
                        +
                    </span>
                    <span data-test-id=${T.SEARCH.CHILD_AMOUNT}>${numberOfChildren}</span>
                </div>
            </div>
        `;
    };

    const infantsTemplate = () => {
        const decreaseClassMap = classMap({
            "minus-btn": true,
            "disabled": numberOfInfants === 0,
        });

        const increaseClassMap = classMap({
            "plus-btn": true,
            "disabled": numberOfInfants >= numberOfAdults,
        });

        return html`
            <div class="col-xs-1 col-sm-1-3 col-md-1-4">
                <label class="change-search-label">${i18next.t("V2-InfantsLabel")}</label>
                <div class="jetsmart-number-selector float-center">
                    <span
                        class=${decreaseClassMap}
                        data-test-id=${T.SEARCH.INFANTS_DECREASE}
                        @click=${() => setNumberOfInfants(numberOfInfants - 1)}
                    >
                        -
                    </span>
                    <span
                        class=${increaseClassMap}
                        data-test-id=${T.SEARCH.INFANTS_INCREASE}
                        @click=${() => setNumberOfInfants(numberOfInfants + 1)}
                    >
                        +
                    </span>
                    <span data-test-id=${T.SEARCH.INFANT_AMOUNT}>${numberOfInfants}</span>
                </div>
            </div>
        `;
    };

    const redemptionSearchCheckboxTemplate = () =>
        userContext?.american.americanAirlinesDisplayName && flowContext?.action === ACTION_NAMES.INDEX
            ? html`<div class="mt-5 flex w-full items-center justify-end">
                  ${redemptionSearchCheckbox.htmlTemplate()}
              </div>`
            : "";

    const paxNumberTemplate = () => html`
        <div class="row">
            ${adultsTemplate()} ${childrenTemplate()} ${infantsTemplate()}
            <div class="col-xs-1 col-md-1-4">
                <div class="flight-change-search-button-container">
                    <button @click=${submitForm} class="rounded-primary-btn" data-test-id=${T.SEARCH.SUBMIT_BUTTON}>
                        ${i18next.t("V2-Continue")}
                    </button>
                </div>
            </div>
        </div>
    `;

    const errorTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="error-message-container">
                    <div class="form-error-message ts-required-error-message hidden">
                        ${i18next.t("V2-RequiredFieldsWarning")}
                    </div>
                </div>
            </div>
        </div>
    `;

    return html`
        <div
            class="change-search ts-error-container ts-error-parent ${loaderClass}"
            ref=${ref(root)}
            data-test-id=${T.SEARCH.CONTAINER}
        >
            <form novalidate>
                <header>${i18next.t("V2-ChangeSearchTitle")}</header>
                <div class="row">
                    <div class="col-xs-1 col-sm-2-5 col-md-2-5 col-lg-1-2">
                        <h2>${i18next.t("V2-ChangeSearchSlogan")}</h2>
                    </div>
                    <div class="col-xs-1 col-sm-3-5 col-md-3-5 col-lg-1-2 text-right">
                        <div class="row">${tripNumberTemplate()} ${currencyTemplate()}</div>
                    </div>
                </div>
                ${routeAndDateSelector.htmlTemplate} ${paxNumberTemplate()} ${redemptionSearchCheckboxTemplate()}
                ${errorTemplate()}
            </form>
        </div>
    `;
};
