import i18next from "i18next";
import { html, useEffect as hauntedUseEffect } from "haunted";
import { TestIdDictionary as T } from "../testing-helpers/TestIdHelper";
import { useState } from "../shared/haunted/CustomHooks";
import { useModal } from "./shared/useModal";
import { CultureChangeInBooking, usePubSub } from "../pub-sub-service/usePubSub";
import { ROUTES } from "../shared/apiRoutes";
import { useAjax } from "../shared/customHooks/useAjax/useAjax";
import { useReduxState } from "../shared/redux/useReduxState";

export const useRemoveInsuranceModal = () => {
    const { triggers } = usePubSub();
    const { ajaxRequest } = useAjax();
    const [hasUnpaidInsurance] = useReduxState("booking.hasUnpaidInsurance");

    const [onContinue, setOnContinue] = useState<() => () => {}>(undefined);

    const handleTrigger = (e: CultureChangeInBooking) => {
        if (!hasUnpaidInsurance) {
            e.callback();
            return;
        }

        setOnContinue(() => e.callback);
        modal.open();
    };

    const init = () => {
        const handler = triggers.shared.cultureChangedInBooking.subscribe(handleTrigger);
        return () => handler.unsubscribe();
    };

    const handleYes = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (onContinue) {
            await ajaxRequest({
                method: "DELETE",
                url: ROUTES.RemoveInsurances,
            });
            onContinue();
        }
        modal.close();
    };

    const handleNo = () => {
        modal.close();
    };

    hauntedUseEffect(init, [hasUnpaidInsurance]);

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="flex flex-col text-center text-lg sm:text-xl">
                <span>${i18next.t("RemoveInsuranceInfo-Line1")}</span>
                <span>${i18next.t("RemoveInsuranceInfo-Line2")}</span>
            </div>
            <div class="mb-6 mt-6 flex flex-col items-center sm:mb-0">
                <a
                    @click=${handleYes}
                    class="rounded-primary-btn"
                    data-test-id=${T.COMMON.MODIFY_BOOKING_MODAL_MODIFY_BUTTON}
                >
                    ${i18next.t("Continuar")}
                </a>
                <a
                    @click=${handleNo}
                    class="mt-4 cursor-pointer select-none text-brand-secondary underline text-medium"
                >
                    ${i18next.t("V2-NoThanks")}
                </a>
            </div>
        </div>
    `;

    const modal = useModal({
        overlay: { classNames: "primary-modal" },
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: {
            classNames: "text-center",
            template: () => html` <div class="py-2 font-black">${i18next.t("Importante")}</div> `,
        },
    });

    return { htmlTemplate: modal.htmlTemplate };
};
