import { ROUTES } from "../../../shared/apiRoutes";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { useState } from "../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { BRASILIAN_CULTURE_CODE, USA_CULTURE_CODE } from "../../../shared/commonConstants";
import { html } from "haunted";
import { classMap } from "lit-html/directives/class-map";
import { updateMdl } from "../../../shared/common";
import { ExtendedLoginResult } from "../../../component-models/LoginResult";
import { useLogin } from "../../login/useLogin";
import { useAppContext } from "../../../managers/useAppContext";
import { DEFAULT_LOGIN_VM, LoginVM } from "../../login/login-model";
import { useFluentValidator } from "../../../validator/FluentValidator";
import { Validation } from "../../../validator/Validation";
import { useErrorMessage } from "../../ui/error-message/useErrorMessage";
import { isPasswordLengthValid } from "../../../validator/validation-helper";
import { useModal } from "../../shared/useModal";
import { useReduxState } from "../../../shared/redux/useReduxState";

type FieldNames = keyof LoginVM;

export interface Props {
    onLogin: (result: ExtendedLoginResult) => Promise<void>;
}

export const useFlightLoginModal = (props: Props) => {
    const appContext = useAppContext();

    const [userContext, setUserContext] = useReduxState("userContext");

    const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState<string>(undefined);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [vm, setVm] = useState<LoginVM>(DEFAULT_LOGIN_VM);

    const authenticator = useLogin();

    const submitForm = async () => {
        setShowProgressBar(true);

        const result = await login();

        if (result && result.LoginStatus === "loggedIn") {
            await handleLoggedInUser(result);
        } else if (!result || result.LoginStatus === "notLoggedIn") {
            setLoginErrorMessage(
                result?.ErrorMessage === "InvalidLoginCredentials"
                    ? i18next.t("V2-InvalidEmailOrPassword")
                    : result?.ErrorMessage,
            );
        }

        setShowProgressBar(false);
    };

    const handleLoggedInUser = async (result: ExtendedLoginResult) => {
        modal.close();
        setUserContext({
            ...userContext,
            isLoggedIn: true,
            dc: {
                ...userContext.dc,
                hasMembership: result.DC.IsMember,
            },
        });

        await props.onLogin(result);
    };

    const login = async () => {
        const loginType = "JA";
        const result = await authenticator.login({
            Username: vm.username,
            Password: vm.password,
            LoginType: loginType,
            Redirect: false,
        });

        return result;
    };

    const handleKeyup = async (e: KeyboardEvent) => {
        if (e.key === "Enter") await handleLoginButton();
        if (e.key === "Escape") modal.close();
    };

    const handleLoginButton = async (e?: MouseEvent) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        setIsValidated(true);

        setLoginErrorMessage(undefined);

        const isValid = await validator.validate();

        if (isValid) {
            await submitForm();
        }
    };

    const validator = useFluentValidator<FieldNames, LoginVM>({
        vm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("username", (vm: LoginVM) => vm.username).isRequired(),
            Validation.ruleFor("password", (vm: LoginVM) => vm.password)
                .isRequired()
                .fulfils(
                    async (password: string) => isPasswordLengthValid(password),
                    i18next.t("La contraseña debe incluir entre 8 y 16 caracteres y, debe contener letras y números."),
                ),
        ],
    });

    const formErrors = useErrorMessage({ errorMessage: validator.getFormMessages() });

    const progressBarTemplate = () =>
        showProgressBar
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <p>${i18next.t("Header-LoggingIn")}</p>
                          <div class="mdl-progress mdl-js-progress mdl-progress__indeterminate progress-bar"></div>
                      </div>
                  </div>
              `
            : "";

    const formTemplate = () => html`
        <div class="row">
            <div class="col-xs-1 col-md-3-5 col-md-offset-1-5">
                <div class="mt-[20px]">
                    <ac-input
                        .errorMessage=${validator.getMessage("username")}
                        .isInvalid=${!validator.isValid("username")}
                        .label=${i18next.t("V2-EmailLabel")}
                        .autoComplete=${"cc-exp"}
                        .testId=${T.FLIGHT_LOGIN_MODAL.EMAIL}
                        .value=${vm.username}
                        .onInput=${(value: string) => setVm({ ...vm, username: value })}
                        .onKeyUp=${(e: KeyboardEvent) => handleKeyup(e)}
                    ></ac-input>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1 col-md-3-5 col-md-offset-1-5">
                <div class="mt-[20px]">
                    <ac-input
                        .errorMessage=${validator.getMessage("password")}
                        .isInvalid=${!validator.isValid("password")}
                        .type=${"password"}
                        .label=${i18next.t("V2-PasswordLabel")}
                        .autoComplete=${"new-password"}
                        .testId=${T.FLIGHT_LOGIN_MODAL.PASSWORD}
                        .value=${vm.password}
                        .onInput=${(value: string) => setVm({ ...vm, password: value })}
                        .onKeyUp=${(e: KeyboardEvent) => handleKeyup(e)}
                    ></ac-input>
                </div>
            </div>
        </div>
        ${formErrors.htmlTemplate()}
        <div class="row">
            <div class="col-xs-1 col-md-3-5 col-md-offset-1-5">
                <div class="forgotten-pw-link-container">
                    <a href=${ROUTES.ForgotPassword}>${i18next.t("V2-ForgottenPassword")}</a>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-1 col-md-3-5 col-md-offset-1-5">
                <div class="flight-login-button-container">
                    <button
                        @click=${modal.close}
                        class="rounded-secondary-btn"
                        data-test-id=${T.FLIGHT_LOGIN_MODAL.RETURN_BUTTON}
                    >
                        ${i18next.t("V2-ReturnLabel")}
                    </button>
                    <button
                        @click=${handleLoginButton}
                        class="rounded-primary-btn"
                        data-test-id=${T.FLIGHT_LOGIN_MODAL.CONTINUE_BUTTON}
                    >
                        ${i18next.t("V2-Continue")}
                    </button>
                </div>
            </div>
        </div>
    `;

    const sidebarClassMap = classMap({
        "flight-modal-sidebar": true,
        "english": appContext.Culture === USA_CULTURE_CODE,
        "portuguese": appContext.Culture === BRASILIAN_CULTURE_CODE,
    });

    const htmlTemplate = () => html`
        <div class=${sidebarClassMap}></div>
        <div class="flight-scroller">
            <div class="modal-header">${i18next.t("V2DC-LoginModalTitle")}</div>
            <div class="modal-body bordered">
                <div class="modal-body-inner-box">
                    ${progressBarTemplate()}
                    ${loginErrorMessage ? html` <div class="login-error">${loginErrorMessage}</div> ` : ""}
                    ${formTemplate()}
                </div>
            </div>
        </div>
        ${updateMdl()}
    `;

    const modal = useModal({
        closing: { buttonClassNames: "flight-modal-close", isClosable: true },
        content: { classNames: "flight-modal-content login-modal with-sidebar", template: htmlTemplate },
        overlay: { classNames: "ts-error-parent" },
    });

    return { htmlTemplate: () => html` ${modal.htmlTemplate()} ${authenticator.htmlTemplate()} `, open: modal.open };
};
