import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { PetInCabinJourneyPassengerModel } from "../../../../component-models/extras/pet-in-cabin/PetInCabinJourneyPassengerModel";
import { PetInCabinJourneyModel } from "../../../../component-models/extras/pet-in-cabin/PetInCabinJourneyModel";
import { OUTBOUND } from "../../../../shared/commonConstants";
import { useRef } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import { CLASS_NAMES } from "../../../../shared/classNames";
import { getPassengerName, showLoader } from "../../../../shared/common";
import DomCrawlingHelper from "../../../../shared/DomCrawlingHelper";
import { HauntedFunc } from "../../../../shared/haunted/HooksHelpers";
import { LOADER_CLASS_NAMES } from "../../../../shared/LoaderClassNames";
import { ref } from "../../../../directives/ref";
import { useBookingManager } from "../../../../managers/useBookingManager";
import { useExtrasTealiumManager } from "../../../../managers/Tealium/useExtrasTealiumManager";
import { ApiExtrasModel } from "../../../../component-models/extras/ApiExtrasModel";
import BookingData from "../../../../shared/BookingData";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import classNames from "classnames";

export const name = "ac-pet-in-cabin-passenger";

export interface Props {
    journey: PetInCabinJourneyModel;
    passengerOption: PetInCabinJourneyPassengerModel;
    setExtrasModel: (model: ApiExtrasModel) => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        journey: host.journey,
        passengerOption: host.passengerOption,
        setExtrasModel: host.setExtrasModel,
    };

    const bookingContext = useBookingContext();

    const bookingManager = useBookingManager();
    const tealiumManager = useExtrasTealiumManager();

    const [passengerNames] = useReduxState("booking.passengerNames");

    const root = useRef<HTMLDivElement>(undefined);

    const isPassengerDisabled = () => props.journey.JourneyIndex === OUTBOUND && bookingContext.isCheckinClosedOutbound;

    const isButtonDisabled = () =>
        props.passengerOption.IsDisabled ||
        (props.journey.Passengers?.filter((ps) => ps.IsSold).length >= props.journey.MaxCount &&
            !props.passengerOption.IsSold);

    const tealiumLogSelection = async (type: "add" | "remove") => {
        await tealiumManager.logBinaryExtraSelected(
            "pet",
            props.passengerOption.Type === "ADT" ? "A" : "C",
            props.passengerOption.PassengerNumber,
            props.journey.JourneyIndex,
            type,
            props.passengerOption.Amount,
        );
    };

    const handlePetInCabinButtonClick = async () => {
        if (!props.passengerOption.SsrAddKey) return;

        const parent = DomCrawlingHelper.findParentByClass(root.current, CLASS_NAMES.petInCabin);
        const loader = showLoader({
            name: LOADER_CLASS_NAMES.Pet,
            container: parent.parentElement,
            noPlane: true,
        });

        const container = DomCrawlingHelper.getElemByClass(parent, CLASS_NAMES.errorContainer);

        const body = {
            "selectedJourneySsrs[0]": !props.passengerOption.IsSold
                ? props.passengerOption.SsrAddKey
                : props.passengerOption.SsrDeleteKey,
        };

        const response = await bookingManager.postExtrasPageSsrUpdate<{
            BookingSummary: BookingData;
            ExtrasModel: ApiExtrasModel;
        }>(body, container, loader);

        props.setExtrasModel(response.ExtrasModel);

        await tealiumLogSelection(!props.passengerOption.IsSold ? "add" : "remove");
    };

    const buttonTemplate = () => {
        const tempClassMap = classNames("btn-boarding select-dependent-text", {
            selected: props.passengerOption.IsSold,
            disabled: isButtonDisabled(),
        });

        return html`
            <div class="col-xs-1-3">
                <div
                    class=${tempClassMap}
                    data-test-id=${getTestId(T.EXTRAS_PET_IN_CABIN.BUTTON, {
                        p: props.passengerOption.PassengerNumber,
                        j: props.journey.JourneyIndex,
                    })}
                    @click=${handlePetInCabinButtonClick}
                >
                    <span>${i18next.t("V2-Add")}</span>
                    <span>${i18next.t("V2-Remove")}</span>
                </div>
            </div>
        `;
    };

    const labelTemplate = () => html`
        <div class="col-xs-2-3">
            <div class="pet-container">
                ${warningTemplate()}
                <div
                    class="extras-binary-name pet"
                    data-test-value=${props.passengerOption.Amount}
                    data-test-id=${getTestId(T.EXTRAS_PET_IN_CABIN.BUTTON_CAPTION, {
                        p: props.passengerOption.PassengerNumber,
                        j: props.journey.JourneyIndex,
                    })}
                >
                    ${getPassengerName(passengerNames, props.passengerOption.PassengerNumber)}
                    ${props.passengerOption.FormattedAmount}
                </div>
            </div>
        </div>
    `;

    const warningTemplate = () =>
        !props.passengerOption.IsSold && isButtonDisabled()
            ? html` <div class="no-more-pets-warning">${i18next.t("PetInCabin-Full")}</div> `
            : "";

    return html`
        <div
            ref=${ref(root)}
            class="inner-border-box"
            data-test-id=${getTestId(T.EXTRAS_PET_IN_CABIN.PASSENGER_OPTION, {
                p: props.passengerOption.PassengerNumber,
                j: props.journey.JourneyIndex,
            })}
        >
            <div class=${isPassengerDisabled() ? "is-past" : ""}>
                <div class="row">${labelTemplate()} ${buttonTemplate()}</div>
            </div>
        </div>
    `;
};
