import { html, useRef, useState } from "haunted";
import i18next from "i18next";
import { classMap } from "lit-html/directives/class-map";
import { ref } from "../../directives/ref";
import { ROUTES } from "../../shared/apiRoutes";
import { hideLoader, showLoader } from "../../shared/common";
import { useEffect } from "../../shared/haunted/CustomHooks";
import { TestIdDictionary as T, getTestId } from "../../testing-helpers/TestIdHelper";
import { GIFTCARD_ACTIVATION_STEPS } from "../../shared/commonConstants";
import { useLogin } from "../login/useLogin";
import { usePubSub } from "../../pub-sub-service/usePubSub";

export interface Props {
    bancoEstadoUserLoginType: string;
    emailElement: HTMLInputElement;
    isActive: boolean;
    isBancoEstadoUser: boolean;
    personalUserLoginType: string;
    rutElement: HTMLInputElement;
    handleOnBancoEstado: () => void;
    handleOnPasswordBack: () => void;
}

export const useGiftcardActivationPassword = (props: Props) => {
    // HELPERS
    const isPasswordValid = () => Boolean(pw.current.value);

    // EVENT HANDLERS
    const handlePasswordInput = async (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            await handlePasswordSubmit();
        }
    };

    const handlePasswordSubmit = async () => {
        setShowPasswordError(false);

        if (isPasswordValid()) {
            await handleValidPasswordSubmit();
        } else {
            setShowPasswordError(true);
        }
    };

    const handleValidPasswordSubmit = async () => {
        setShowLoginError(false);
        const loader = showLoader({});
        const result = await authenticator.login({
            Username: props.isBancoEstadoUser ? props.rutElement.value : props.emailElement.value,
            Password: pw.current?.value,
            LoginType: props.isBancoEstadoUser ? "BE" : "JA",
            Redirect: false,
        });

        if (result && result.LoginStatus === "loggedIn") {
            triggers.login.reloadPageOnLogin.publish({});
        }

        if (!result || result.LoginStatus === "notLoggedIn") {
            setShowLoginError(true);
            hideLoader(loader);
        }
    };

    const handleForgottenPassword = () => {
        window.location.href = props.isBancoEstadoUser ? ROUTES.ForgotPasswordBancoEstado : ROUTES.ForgotPassword;
    };

    const handlePasswordBack = () => {
        setShowLoginError(false);
        setShowPasswordError(false);
        props.handleOnPasswordBack();
    };

    // COMPONENT
    const root = useRef<HTMLDivElement>(undefined);
    const pw = useRef<HTMLInputElement>(undefined);

    const [showLoginError, setShowLoginError] = useState<boolean>(false);
    const [showPasswordError, setShowPasswordError] = useState<boolean>(false);

    const authenticator = useLogin();

    const { triggers } = usePubSub();

    useEffect(() => (props.isActive ? pw.current?.focus() : null), [props.isActive]);

    // TEMPLATES
    const loginFailedNonBancoEstadoUserTemplate = () =>
        !props.isBancoEstadoUser
            ? html`
                  <div class="gca-error" data-test-id="login-failed">
                      ${i18next.t("V2-InvalidEmailOrPassword")} ${i18next.t("G-NoActivationForCug2")}
                  </div>
              `
            : "";

    const loginFailedBancoEstadoUserTemplate = () =>
        props.isBancoEstadoUser
            ? html` <div class="gca-error" data-test-id="login-failed">${i18next.t("BE-InvalidRutOrPassword")}</div> `
            : "";

    const loginFailedTemplate = () =>
        showLoginError
            ? html` ${loginFailedNonBancoEstadoUserTemplate()} ${loginFailedBancoEstadoUserTemplate()} `
            : "";

    const passwordErrorTemplate = () => html` <div class="gca-error">${i18next.t("Gift-PleaseFillTheField")}</div> `;

    const forgottenPasswordTemplate = () => html`
        <div class="gca-container">
            <div class="gca-password-link" @click=${handleForgottenPassword}>${i18next.t("V2-ForgottenPassword")}</div>
        </div>
    `;

    const buttonContainerTemplate = () => html`
        <div class="gca-container">
            <div class="gca-btn-container">
                <button
                    class="rounded-secondary-btn mr-2"
                    data-test-id=${getTestId(T.GIFTCARD.ACTIVATION_BACK_BUTTON, {
                        c: GIFTCARD_ACTIVATION_STEPS.PASSWORD,
                    })}
                    @click=${handlePasswordBack}
                >
                    ${i18next.t("Gift-Cancel")}
                </button>
                <button
                    class="rounded-primary-btn"
                    data-test-id=${getTestId(T.GIFTCARD.ACTIVATION_NEXT_BUTTON, {
                        c: GIFTCARD_ACTIVATION_STEPS.PASSWORD,
                    })}
                    @click=${handlePasswordSubmit}
                >
                    ${i18next.t("Gift-Continue")}
                </button>
            </div>
        </div>
    `;

    const htmlTemplate = () => {
        const mainClassMap = classMap({
            "step-form": true,
            "gca-enter-password": true,
            "active": props.isActive,
        });

        return html`
            <div ref=${ref(root)} class=${mainClassMap}>
                <div class="giftcard-activation-step-info">${i18next.t("Gift-StepInfo-3B")}</div>
                <div class="gca-container">
                    <div class="gca-enter-code-label visible-xs">${i18next.t("Gift-EnterPassword")}</div>
                    <input
                        ref=${ref(pw)}
                        type="password"
                        placeholder=${i18next.t("V2-PasswordLabel")}
                        data-test-id=${T.GIFTCARD.ACTIVATION_PASSWORD}
                        @keydown=${handlePasswordInput}
                    />

                    ${loginFailedTemplate()}
                </div>

                ${showPasswordError ? passwordErrorTemplate() : ""} ${forgottenPasswordTemplate()}
                ${buttonContainerTemplate()}
            </div>
        `;
    };

    return { htmlTemplate, pw: pw.current };
};
