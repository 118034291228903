import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import { html } from "lit-html";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { useEffect, useMemo, useState } from "../../../../shared/haunted/CustomHooks";
import { WITHOUT_CHECKED_BAGGAGE_IMG } from "../../spa-container";
import classNames from "classnames";
import { BaggagePageState } from "../../../../component-models/baggage/BaggagePageState";
import { useBookingContext } from "../../../../managers/useBookingContext";

export interface Props {
    baggageSection: BaggageSection;
    pageState: BaggagePageState;
}

export const usePerBookingFreeChecked = (props: Props) => {
    const bookingContext = useBookingContext();

    const [showWarning, setShowWarning] = useState<boolean>(false);

    const isPerJourneyPerPaxViewOpen = props.baggageSection.sectionUi.uiState.perJourneyPerPaxState === "open";

    const noBagSelected = useMemo(
        () => !props.pageState.isLoading && props.baggageSection.perBooking.isNoBagSelected,
        [props.pageState.isLoading, props.baggageSection.perBooking.isNoBagSelected],
    );

    const paidBagSelected = useMemo(
        () =>
            !props.pageState.isLoading &&
            !isPerJourneyPerPaxViewOpen &&
            props.baggageSection.perBooking.isPaidBagSelected,
        [props.pageState.isLoading, isPerJourneyPerPaxViewOpen, props.baggageSection.perBooking.isPaidBagSelected],
    );

    const isRemoveAllAvailable = useMemo(
        () =>
            props.baggageSection.perBooking.isRemoveAllAvailable({
                baggageSectionJourneys: props.baggageSection.journeys,
                bagType: "checkedBaggage",
                canGetFreeBancoEstadoBaggage: props.baggageSection.canGetFreeBancoEstadoBaggage,
                isCheckinClosedInbound: bookingContext.isCheckinClosedInbound,
                isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
                paxCount: bookingContext.adultsCount + bookingContext.childrenCount,
            }),
        [props.baggageSection.journeys, bookingContext],
    );

    const startWarningCountdown = () => {
        if (!showWarning) window.setTimeout(() => setShowWarning(true), 3000);
    };

    // Event handlers

    const handleRemoveAll = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!isPerJourneyPerPaxViewOpen && isRemoveAllAvailable) {
            startWarningCountdown();
            await props.baggageSection.reset();
        }
    };

    useEffect(() => {
        if (isPerJourneyPerPaxViewOpen) setShowWarning(false);
    }, [isPerJourneyPerPaxViewOpen]);

    useEffect(() => {
        if (props.baggageSection.perBooking.quantity(props.baggageSection.journeys) > 0) setShowWarning(false);
    }, [props.baggageSection.journeys]);

    // Templates

    const removeAllButtonTemplate = () =>
        !noBagSelected
            ? html`
                  <button
                      class="b2-secondary-button"
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_RESET_BUTTON, { c: "checkedBaggage" })}
                      .disabled=${!isRemoveAllAvailable}
                      @click=${handleRemoveAll}
                  >
                      ${i18next.t("Baggage-RemoveAllPerBookingButtonText")}
                  </button>
              `
            : "";

    const noBagSelectedButtonTemplate = () =>
        noBagSelected ? html` <button disabled class="b2-secondary-button">${i18next.t("Agregado")}</button> ` : "";

    const illustrationTemplate = () => html`
        <img
            src=${WITHOUT_CHECKED_BAGGAGE_IMG}
            class="b2-free-checked-img"
            data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_IMAGE, { c: "checkedBaggage" })}
        />
        <div class="b2-weight">&nbsp;</div>
        <div
            class="b2-bag-name bigger"
            data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_NAME, { c: "checkedBaggage" })}
        >
            ${i18next.t("No quiero llevar equipaje facturado")}
        </div>
    `;

    const htmlTemplate = () => {
        const mainClassMap = classNames("b2-free-bag-option", {
            "selected": noBagSelected,
            "unselected": paidBagSelected,
            "per-pax": isPerJourneyPerPaxViewOpen,
            "hoverable": !isPerJourneyPerPaxViewOpen && !noBagSelected && isRemoveAllAvailable,
        });

        const buttonClassMap = classNames("b2-free-option-btn-container b2-openable taller", {
            "b2-opened": !isPerJourneyPerPaxViewOpen,
        });

        return html`
            <div
                class=${mainClassMap}
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION, { c: "checkedBaggage" })}
                @click=${handleRemoveAll}
            >
                <div class="b2-illustration taller">${illustrationTemplate()}</div>
                <div class=${buttonClassMap}>${noBagSelectedButtonTemplate()} ${removeAllButtonTemplate()}</div>
            </div>
        `;
    };

    return { htmlTemplate };
};
