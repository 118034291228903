import i18next from "i18next";
import { usePubSub } from "../pub-sub-service/usePubSub";
import DomCrawlingHelper from "../shared/DomCrawlingHelper";
import { CLASS_NAMES } from "../shared/classNames";
import { COOKIE_NAMES } from "../shared/commonConstants";
import { getCookie } from "../shared/cookieHandling";
import esCLPo from "../../../locale/translated/es-cl.client.json";
import enUSPo from "../../../locale/translated/en-us.client.json";
import ptBRPo from "../../../locale/translated/pt-br.client.json";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import "dayjs/locale/en";
import "dayjs/locale/es";
import "dayjs/locale/pt-br";

interface LocalizationEmbeddedLink {
    url: string;
    title: string;
}

let didScroll: boolean;
let lastScrollTop = 0;
const delta = 0; // unused
const navbarHeight = 160;

export const initGlobalScrollListener = () => {
    window.watchScroll = true;

    // https://medium.com/@mariusc23/hide-header-on-scroll-down-show-on-scroll-up-67bbaae9a78c
    window.addEventListener("scroll", () => (didScroll = true), false);

    window.setInterval(() => {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    window.addEventListener("scroll", () => usePubSub().triggers.shared.windowWasScrolled.publish({}));
    window.addEventListener("resize", () => usePubSub().triggers.shared.windowWasScrolled.publish({}));
};

export const handleAmplitudeTealiumCookies = () => {
    try {
        let cookieDeviceId = getCookie(COOKIE_NAMES.Amplitude);

        if (!cookieDeviceId) return;

        if (window.amplitude && typeof window.amplitude.setDeviceId === "function") {
            window.amplitude.setDeviceId(cookieDeviceId);
        } else {
            const amplitudeInterval = window.setInterval(() => {
                if (
                    window.amplitude?.getInstance()?._isInitialized &&
                    typeof window.amplitude.setDeviceId === "function"
                ) {
                    cookieDeviceId = getCookie(COOKIE_NAMES.Amplitude);
                    window.clearInterval(amplitudeInterval);
                    window.clearTimeout(amplitudeTimeLimit);
                    window.amplitude.setDeviceId(cookieDeviceId);
                }
            }, 100);

            const amplitudeTimeLimit = window.setTimeout(() => {
                window.clearInterval(amplitudeInterval);
                // eslint-disable-next-line no-console
                console.error("Could not access Amplitude.");
            }, 10000);
        }
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Unknown Amplitude/Tealium cookie problem.");
    }
};

export const initLocalization = (i18initCallback: () => void) => {
    const culture =
        window.JetSmart?.AppContext?.culture?.toLowerCase() ||
        window.JetSmart?.Cug2AppContext?.culture?.toLowerCase() ||
        "";

    void i18next.init(
        {
            initImmediate: true,
            nsSeparator: false,
            keySeparator: false,
            lng: culture.indexOf("en") > -1 ? "enus" : culture.indexOf("pt") > -1 ? "ptbr" : "escl",
            fallbackLng: ["escl"],
            returnEmptyString: false,
            resources: { escl: { translation: esCLPo }, enus: { translation: enUSPo }, ptbr: { translation: ptBRPo } },
            interpolation: {
                format: (value: any, format?: string) => {
                    if (format === "custom" && (value as LocalizationEmbeddedLink)) {
                        const linkObject = value as LocalizationEmbeddedLink;
                        return `<a target="blank" href="${linkObject.url}">${linkObject.title}</a>`;
                    }
                    return value;
                },
            },
        },
        i18initCallback,
    );

    dayjs.locale(culture.indexOf("en") > -1 ? "en" : culture.indexOf("pt") > -1 ? "pt-br" : "es");
};

const hasScrolled = () => {
    const navbar = DomCrawlingHelper.getElemByClass(document.body, CLASS_NAMES.mainHeader);

    const st = window.scrollY;

    if (Math.abs(lastScrollTop - st) <= delta) return;

    const menuOpener = document.querySelector("[ref='opener']") as HTMLInputElement;

    if (!navbar || !window.watchScroll) {
        lastScrollTop = st;
        return;
    }

    if (st > lastScrollTop && st > navbarHeight && (!menuOpener || !menuOpener.checked)) {
        navbar.classList.add(CLASS_NAMES.mainHeaderCollapsed);

        const mobileMenuOpeners = DomCrawlingHelper.getArrayOfClass<HTMLInputElement>(
            document.body,
            CLASS_NAMES.mobileNavigation,
        );

        for (const opener of mobileMenuOpeners) {
            if (opener.checked) opener.click();
        }
    } else {
        navbar.classList.remove(CLASS_NAMES.mainHeaderCollapsed);
    }

    lastScrollTop = st;
};
