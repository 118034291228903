import { html, useRef } from "haunted";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import { Partner } from "../../../component-models/CUG2b/Partner";
import i18next from "i18next";
import { useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { ref } from "../../../directives/ref";
import { ROUTES } from "../../../shared/apiRoutes";
import { TravelPartnerHelper } from "../../../component-helpers/TravelPartnerHelper";
import { handleCugLoader } from "../../../shared/common";
import {
    CUG_GROUP_NAME_MAX_LENGTH,
    FAKE_CUG2_GROUP_NAME,
    URI_DIVIDER_CHARACTER,
    URL_VARS,
} from "../../../shared/commonConstants";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { GridHelper } from "../../../component-helpers/GridHelper";
import { sanitizeInputFieldValue } from "../../../component-helpers/InputSanitizerHelper";
import { Group } from "../../../component-models/CUG2b/Group";
import {
    GridState,
    PageChangeEvent,
    SortChangeEvent,
    RowsSelectedEvent,
    ViewModel,
    Column,
} from "../../../dc-components/dc-table-models";
import { useFluentValidator } from "../../../validator/FluentValidator";
import { Validation } from "../../../validator/Validation";
import classNames from "classnames";
import { usePortalTravelPartnersUtils } from "./usePortalTravelPartnersUtils";

export interface GroupNameVm {
    Name: string;
}

export const useCugAddTravelGroupPage = () => {
    const root = useRef<HTMLDivElement>(undefined);

    const { getTravelPartnerInfo, postTravelPartnerInfo } = useAjax();
    const { orderedModel } = usePortalTravelPartnersUtils();

    const initialGridState = useMemo<GridState<keyof Partner>>(
        () => ({
            pageIndex: 0,
            appliedFilters: [],
            pageSize: 10,
            orderBy: "LastName",
            orderDir: "asc",
            globalFilterExpression: "",
        }),
        [],
    );

    const [group, setGroup] = useState<Group>(undefined);
    const [groupNameVm, setGroupNameVm] = useState<GroupNameVm>({ Name: "" });
    const [isValidated, setIsValidated] = useState<boolean>(undefined);
    const [travelPartnerInfo, setTravelPartnerInfo] = useState<TravelPartnerInfo>(undefined);

    const [gridState, setGridState] = useState<GridState<keyof Partner>>(initialGridState);
    const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set<number>());

    const partners = useMemo(() => travelPartnerInfo?.Partners, [travelPartnerInfo]);

    // HELPERS

    const init = () => {
        const urlParams = new URLSearchParams(window.location.search);

        const doIt = async () => {
            const result = await getTravelPartnerInfo();

            setTravelPartnerInfo(result);

            const idsInUrl = urlParams
                .get(URL_VARS.FFLYER_IDS)
                ?.split(URI_DIVIDER_CHARACTER)
                .map((id) => Number(id));
            setSelectedIds(idsInUrl ? new Set<number>(idsInUrl) : new Set<number>());

            const groupNameInUrl =
                window.location.href.indexOf(URL_VARS.FFLYER_GROUP_NAME) > -1
                    ? decodeURIComponent(urlParams.get(URL_VARS.FFLYER_GROUP_NAME))
                    : undefined;

            if (groupNameInUrl) {
                setGroup(result.Groups.find((g) => g.Name === groupNameInUrl));
                setGroupNameVm({ Name: groupNameInUrl });

                return;
            }

            setGroupNameVm({ Name: FAKE_CUG2_GROUP_NAME });
        };

        void doIt();
    };

    // EVENT HANDLERS

    const onPageChange = (e: PageChangeEvent) => {
        setGridState({
            ...gridState,
            pageIndex: e.detail.selectedPageIndex,
            pageSize: e.detail.selectedPageSize,
        });
    };

    const onSortChange = (e: SortChangeEvent) => {
        setGridState({
            ...gridState,
            orderBy: e.detail.orderBy as keyof Partner,
            orderDir: e.detail.orderBy !== gridState.orderBy ? "asc" : e.detail.orderDir,
        });
    };

    const onRowsSelect = (event: RowsSelectedEvent) => {
        setSelectedIds(
            GridHelper.getSelectedIds(
                event,
                selectedIds,
                orderedModel(travelPartnerInfo, gridState, partners),
            ) as Set<number>,
        );
    };

    const handleSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsValidated(true);

        const isValid = await validator.validate();

        if (!isValid) return;

        let newTravelPartnerInfo = travelPartnerInfo;
        let editedGroup = group;

        if (!editedGroup) {
            newTravelPartnerInfo = TravelPartnerHelper.updateGroup(newTravelPartnerInfo, {
                Id: undefined,
                Name: groupNameVm.Name,
            });

            handleCugLoader(root.current, "createGroup");
            newTravelPartnerInfo = await postTravelPartnerInfo(newTravelPartnerInfo);
            handleCugLoader(root.current, "createGroup");

            editedGroup = newTravelPartnerInfo.Groups.find((g) => g.Name === groupNameVm.Name);
        }

        newTravelPartnerInfo = TravelPartnerHelper.addPartnersToGroup(
            newTravelPartnerInfo,
            editedGroup.Id,
            Array.from(selectedIds.values()),
        );

        newTravelPartnerInfo = TravelPartnerHelper.updateGroup(newTravelPartnerInfo, {
            ...editedGroup,
            Name: groupNameVm.Name,
        });

        handleCugLoader(root.current, "loadData");

        await postTravelPartnerInfo(newTravelPartnerInfo);

        window.location.href = ROUTES.Cug2BAdminTravelGroupsPage;
    };

    // COMPONENT

    const validator = useFluentValidator<keyof GroupNameVm, GroupNameVm>({
        vm: groupNameVm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("Name", (vm: GroupNameVm) => vm.Name)
                .isRequired(i18next.t("Este campo es requerido."), "field")
                .fulfils(
                    () =>
                        Promise.resolve(
                            !travelPartnerInfo.Groups.some(
                                (g) =>
                                    g.Name.toLowerCase().trim() === groupNameVm.Name.toLowerCase().trim() &&
                                    g.Id !== group?.Id,
                            ),
                        ),
                    i18next.t("Este nombre de grupo ya existe."),
                ),
        ],
    });

    // TEMPLATES

    const staticStuffTemplate = () => html`
        <div class="travel-partner-breadcrumb-container">
            <div
                class="travel-partner-breadcrumb past"
                @click=${() => (window.location.href = ROUTES.Cug2BTravelPartnersPage)}
            >
                ${i18next.t("Pasajeros frecuentes")}
            </div>
            <div class="travel-partner-breadcrumb divider">/</div>
            <div
                class="travel-partner-breadcrumb past"
                @click=${() => (window.location.href = ROUTES.Cug2BTravelGroupsPage)}
            >
                ${i18next.t("Grupos")}
            </div>
            <div class="travel-partner-breadcrumb divider">/</div>
            <div class="travel-partner-breadcrumb">${i18next.t("Crear un grupo")}</div>
        </div>
        <div class="travel-partner-title">
            <i class="js-icon-cug js-cug-grouping"></i>
            <h2>${i18next.t("Crear un Grupo")}</h2>
        </div>
    `;

    const groupNameTemplate = () => html`
        <div class="travel-partner-input-and-button pull-up">
            <div class="py-[15px]">
                <ac-input
                    .errorMessage=${validator.getMessage("Name")}
                    .isInvalid=${!validator.isValid("Name")}
                    .label=${i18next.t("Nombre del grupo")}
                    .maxLength=${CUG_GROUP_NAME_MAX_LENGTH}
                    .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "alphanumeric")}
                    .value=${group?.Name || groupNameVm.Name}
                    .onInput=${(value: string) => setGroupNameVm({ Name: value })}
                ></ac-input>
            </div>
        </div>
    `;

    const backButtonTemplate = () => html`
        <div class="travel-partner-back-btn-container" @click=${() => window.history.back()}>
            <i class="js-icon js-circle-chevron-right"></i>
            <span>${i18next.t("Volver")}</span>
        </div>
    `;

    const submitButtonTemplate = () => html`
        <button
            class=${classNames("rounded-primary-btn", { disabled: !groupNameVm.Name })}
            data-test-id="create-group-submit-btn"
            @click=${handleSubmit}
        >
            ${i18next.t("Agregar al grupo")}
        </button>
    `;

    const vm: ViewModel<keyof Partner> = {
        columns: [
            {
                field: "LastName",
                columnType: "string",
                label: i18next.t("Nombres y Apellidos"),
                sortable: true,
                columnClass: "",
                cellTemplate: (index: number) =>
                    `${orderedModel(travelPartnerInfo, gridState, partners)[index].FirstName} ${orderedModel(travelPartnerInfo, gridState, partners)[index].LastName}`,
            } as Column<keyof Partner>,
            {
                field: "DocumentId",
                columnType: "string",
                label: i18next.t("RUT / DNI / Pasaporte"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
            } as Column<keyof Partner>,
            {
                field: "Alias",
                columnType: "string",
                label: i18next.t("Alias"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
            } as Column<keyof Partner>,
        ],
        data: orderedModel(travelPartnerInfo, gridState, partners),
        paging: {
            pageable: true,
            pageIndex: gridState.pageIndex,
            pageSize: gridState.pageSize,
            buttonCount: 5,
            pageSizes: [10],
            itemCount: travelPartnerInfo?.Partners?.length,
            showInfo: false,
        },
        sorting: {
            orderBy: gridState.orderBy,
            orderDir: gridState.orderDir,
            showSorterArrow: false,
        },
        selection: {
            selectable: true,
            selectedIndices: Array.from(selectedIds.values())
                .filter((id) => orderedModel(travelPartnerInfo, gridState, partners).some((item) => item.Id === id))
                .map((id) =>
                    orderedModel(travelPartnerInfo, gridState, partners)
                        .map((i) => i.Id)
                        .indexOf(id),
                ),
        },
        rowCustomization: [],
        appliedFilters: [],
        useEllipsis: false,
    };

    const htmlTemplate = () =>
        groupNameVm.Name?.length >= 0 && travelPartnerInfo
            ? html`
                  <div ref=${ref(root)}>
                      ${staticStuffTemplate()}
                      <form class="travel-partner-form">${groupNameTemplate()}</form>
                      <div class="travel-partner-stripe">
                          ${i18next.t("Selecciona los pasajeros que desear agregar a este grupo")}
                      </div>
                      <dc-table
                          .vm=${vm}
                          @onSortChange=${onSortChange}
                          @onPageChange=${onPageChange}
                          @onRowsSelect=${onRowsSelect}
                      ></dc-table>

                      <div class="mt-8 flex w-full flex-col justify-end sm:flex-row md:mt-16">
                          ${submitButtonTemplate()}
                      </div>
                      <div class="hidden-xs mt-16 flex w-full justify-end sm:mt-32">${backButtonTemplate()}</div>
                  </div>
              `
            : html``;

    return { init, htmlTemplate };
};
