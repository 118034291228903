import { classMap } from "lit-html/directives/class-map";
import i18next from "i18next";
import { FlexiFeeJourneyModel } from "../../../../component-models/extras/flexi-fee/FlexiFeeJourneyModel";
import { useRef } from "haunted";
import { html } from "lit-html";
import { HauntedFunc } from "../../../../shared/haunted/HooksHelpers";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { journeyBundleInfoTemplate, journeyRouteTemplate } from "../../../../common-templates/ExtrasCommonTemplates";
import { CLASS_NAMES } from "../../../../shared/classNames";
import { showLoader } from "../../../../shared/common";
import DomCrawlingHelper from "../../../../shared/DomCrawlingHelper";
import { LOADER_CLASS_NAMES } from "../../../../shared/LoaderClassNames";
import { ref } from "../../../../directives/ref";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useBookingManager } from "../../../../managers/useBookingManager";
import { useExtrasTealiumManager } from "../../../../managers/Tealium/useExtrasTealiumManager";
import BookingData from "../../../../shared/BookingData";
import { useAppContext } from "../../../../managers/useAppContext";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";
import { ApiExtrasSpaSectionViewModel } from "../../../../component-models/spa/ExtrasSpaSectionViewModel";

export const name = "ac-flexi-fee";

export interface Props {
    isSelected: boolean;
    journey: FlexiFeeJourneyModel;
    displayedPrice: string;
    setExtrasModel: (model: ApiExtrasSpaSectionViewModel) => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        isSelected: host.isSelected,
        journey: host.journey,
        displayedPrice: host.displayedPrice,
        setExtrasModel: host.setExtrasModel,
    };

    const tealiumLogSelection = async (type: "add" | "remove") => {
        await tealiumManager.logBinaryExtraSelected(
            "flexiFee",
            "N/A",
            "N/A",
            props.journey.JourneyIndex,
            type,
            props.journey.Amount,
        );
    };

    const handleFlexiFeeButtonClick = async () => {
        if (appContext.isFeatureSwitchActive("ForceFlexiFeeForAll")) {
            return;
        }

        const loader = showLoader({ name: LOADER_CLASS_NAMES.ExtrasFlexi, noPlane: true });
        const container = DomCrawlingHelper.findParentByClass(root.current, CLASS_NAMES.flexiFee);
        const body = { journeyIndex: props.journey.JourneyIndex.toString() };

        if (props.isSelected) {
            const response = await bookingManager.postRemoveFlexiFee<{
                BookingSummary: BookingData;
                ExtrasModel: ApiExtrasSpaSectionViewModel;
            }>(body, container, loader);
            props.setExtrasModel(response.ExtrasModel);
        } else {
            const response = await bookingManager.postAddFlexiFee<{
                BookingSummary: BookingData;
                ExtrasModel: ApiExtrasSpaSectionViewModel;
            }>(body, container, loader);
            props.setExtrasModel(response.ExtrasModel);
        }

        await tealiumLogSelection(!props.isSelected ? "add" : "remove");
    };

    const root = useRef<HTMLDivElement>(undefined);

    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const bookingManager = useBookingManager();
    const tealiumManager = useExtrasTealiumManager();

    const { getCurrencySign } = useNumberFormatter();

    const [currency] = useReduxState("booking.currency");

    const mobileBundleInfoTemplate = () =>
        props.journey.IsInBundle
            ? journeyBundleInfoTemplate(
                  props.journey.BundleColor,
                  props.journey.BundleImg,
                  T.EXTRAS_FLEXI_FEE.JOURNEY_BUNDLE,
                  undefined,
                  props.journey.JourneyIndex,
                  true,
                  bookingContext.isGdsBooking,
              )
            : "";

    const mobileRouteTemplate = () => html`
        <h3 class="package-title visible-xs">${journeyRouteTemplate(props.journey)}</h3>
    `;

    const labelTemplate = () => {
        const currencySymbol = getCurrencySign(currency);

        return html`
            <div class="col-xs-2-3">
                <div
                    class="extras-binary-name"
                    data-test-id=${getTestId(T.EXTRAS_FLEXI_FEE.BUTTON_CAPTION, { j: props.journey.JourneyIndex })}
                >
                    ${appContext.isFeatureSwitchActive("ForceFlexiFeeForAll")
                        ? unsafeHTML(
                              i18next.t("V2-ForcedFlexiFeePriceInfo {{-currencySymbol}} {{-break}} {{-price}}", {
                                  currencySymbol,
                                  break: "<br />",
                                  price: props.displayedPrice,
                              }),
                          )
                        : unsafeHTML(
                              i18next.t("V2-FlexiFeePriceInfo {{-price}}", {
                                  price: props.displayedPrice,
                              }),
                          )}
                </div>
            </div>
        `;
    };

    const buttonTemplate = () => html` ${forcedFlexiFeeButtonTemplate()} ${regularButtonTemplate()} `;

    const forcedFlexiFeeButtonTemplate = () =>
        appContext.isFeatureSwitchActive("ForceFlexiFeeForAll")
            ? html`
                  <div class="col-xs-1-3">
                      <div
                          class="btn-boarding select-dependent-text selected disabled"
                          data-test-id=${getTestId(T.EXTRAS_FLEXI_FEE.BUTTON, {
                              j: props.journey.JourneyIndex,
                          })}
                      >
                          ${i18next.t("V2-Included")}
                      </div>
                  </div>
              `
            : "";

    const regularButtonTemplate = () => {
        const tempClassMap = classMap({
            "btn-boarding": true,
            "select-dependent-text": true,
            "disabled": props.journey.IsDisabled,
            "selected": props.isSelected,
        });

        return !appContext.isFeatureSwitchActive("ForceFlexiFeeForAll")
            ? html`
                  <div class="col-xs-1-3">
                      <div
                          class=${tempClassMap}
                          data-test-id=${getTestId(T.EXTRAS_FLEXI_FEE.BUTTON, {
                              j: props.journey.JourneyIndex,
                          })}
                          @click=${handleFlexiFeeButtonClick}
                      >
                          <span>${i18next.t("V2-Add")}</span>
                          <span>${i18next.t("V2-Remove")}</span>
                      </div>
                  </div>
              `
            : "";
    };

    return html`
        ${mobileBundleInfoTemplate()}

        <div
            ref=${ref(root)}
            class="inner-box bottom-half padded-bottom-half ts-error-parent ts-error-container"
            data-test-id=${getTestId(T.EXTRAS_FLEXI_FEE.JOURNEY, { j: props.journey.JourneyIndex })}
        >
            ${mobileRouteTemplate()}

            <div class="inner-border-box">
                <div class="row">${labelTemplate()} ${buttonTemplate()}</div>
            </div>
        </div>
    `;
};
