import { html } from "haunted";
import i18next from "i18next";
import { ROUTES } from "../../../shared/apiRoutes";

export interface Props {
    canEditCompany: boolean;
}

export const useOrganizationEdit = (props: Props) => {

    // TEMPLATES

    const htmlTemplate = () =>
        props.canEditCompany
            ? html`
                  <div
                      class="cug2b-profile-edit cursor-pointer"
                      @click=${() => (window.location.href = ROUTES.PageEditAgency)}
                  >
                      <i class="js-icon-cug js-cug-edit"></i>
                      <span>${i18next.t("Editar")}</span>
                  </div>
              `
            : "";

    return { htmlTemplate };
};
