import { ApiBaggageViewModel } from "../component-models/baggage/ApiBaggageViewModel";
import { ApiPassengerBaggage } from "../component-models/baggage/ApiPassengerBaggage";
import { BaggageSectionJourneyPassenger } from "../component-models/baggage/BaggageSectionJourneyPassenger";
import { BaggageSectionJourney } from "../component-models/baggage/BaggageSectionJourney";
import { BagType } from "../components/spa/baggage/useBaggagePage";
import { OUTBOUND } from "../shared/commonConstants";
import { ApiBaggageJourney, BundleUserType } from "./../component-models/baggage/ApiBaggageJourney";
import { BaggageSectionStateJourney } from "../component-models/baggage/BaggageSectionStateJourney";
import { BaggageSectionStateJourneyPassenger } from "../component-models/baggage/BaggageSectionStateJourneyPassenger";

export const mapToBaggageContextSectionJourney = (data: {
    bagType: BagType;
    inboundBundleUrl: string;
    journey: ApiBaggageJourney;
    model: ApiBaggageViewModel;
    outboundBundleUrl: string;
}): BaggageSectionJourney => {
    const available =
        data.bagType === "cabinBaggage"
            ? data.journey.AvailableCabinBaggageQuantity
            : data.bagType === "checkedBaggage"
              ? data.journey.AvailableCheckedInBaggageQuantity
              : data.journey.AvailableOversizedBaggageQuantity;

    const bundleUserType =
        data.journey.BundleUserType === BundleUserType.Normal
            ? "Normal"
            : data.journey.BundleUserType === BundleUserType.GDS
              ? "GDS"
              : data.journey.BundleUserType === BundleUserType.BancoEstado
                ? "BancoEstado"
                : undefined;

    const bundleType =
        data.bagType === "cabinBaggage"
            ? data.journey.IsCabinBagInBundle
                ? data.journey.BundleType
                : "None"
            : data.bagType === "checkedBaggage"
              ? data.journey.IsCheckedBagInBundle
                  ? data.journey.BundleType
                  : "None"
              : "None";

    return {
        arrivalStationName: data.journey.ArrivalStationName,
        available,
        bundleImageUrl: data.journey.JourneyIndex === OUTBOUND ? data.outboundBundleUrl : data.inboundBundleUrl,
        bundleType,
        bundleUserType,
        departureStationName: data.journey.DepartureStationName,
        index: data.journey.JourneyIndex,
        passengers: data.journey.Passengers.map((passenger) =>
            mapToBaggageContextSectionJourneyPassenger({ ...data, passenger }),
        ),
    };
};

export const mapToBaggageContextSectionJourneyPassenger = (data: {
    bagType: BagType;
    inboundBundleUrl: string;
    journey: ApiBaggageJourney;
    model: ApiBaggageViewModel;
    outboundBundleUrl: string;
    passenger: ApiPassengerBaggage;
}): BaggageSectionJourneyPassenger => {
    const baggage =
        data.bagType === "cabinBaggage"
            ? data.passenger.CabinBaggage
            : data.bagType === "checkedBaggage"
              ? data.passenger.CheckedBaggage
              : data.passenger.OversizedBaggage;

    const min =
        data.bagType === "cabinBaggage"
            ? Number(data.passenger.CabinBaggage.MinCount)
            : data.bagType === "checkedBaggage"
              ? Number(data.passenger.CheckedBaggage.MinCount)
              : Number(data.passenger.OversizedBaggage.MinCount);

    return {
        index: data.passenger.PaxIndex,
        isSoldOut: baggage.IsSoldOut,
        max: Number(baggage.MaxCount),
        min,
        originalPrices: baggage.OriginalAmount?.split("|").map((i) => Number(i)) || [],
        prices: baggage.Amount?.split("|").map((i) => Number(i)) || [],
        quantity: Number(baggage.Quantity),
        ssrAddKey: baggage.SsrAddKey,
        ssrRemoveKey: baggage.SsrDeleteKey,
    };
};

export const mapToBaggageJourney = (journey: ApiBaggageJourney, bagType: BagType): BaggageSectionStateJourney => ({
    index: journey.JourneyIndex,
    isOpen: journey.JourneyIndex === OUTBOUND,
    passengers: [
        ...journey.Passengers.map((p): BaggageSectionStateJourneyPassenger => {
            const baggage =
                p[
                    bagType === "cabinBaggage"
                        ? "CabinBaggage"
                        : bagType === "checkedBaggage"
                          ? "CheckedBaggage"
                          : "OversizedBaggage"
                ];

            return {
                hasSelected:
                    baggage.HasSelected ||
                    Number(baggage.Quantity) > 0 ||
                    Number(baggage.MinCount) > 0 ||
                    baggage.IsSoldOut,
                isOpen:
                    journey.JourneyIndex === OUTBOUND
                        ? p.PaxIndex === getInitialOpenJourneyPax(journey, bagType)
                        : false,
                index: p.PaxIndex,
            };
        }),
    ],
});

const getInitialOpenJourneyPax = (journey: ApiBaggageJourney, bagType: BagType) => {
    const paxWithNoSelection = journey.Passengers.find((p) => {
        switch (bagType) {
            case "cabinBaggage":
                return (
                    (!p.CabinBaggage.HasSelected || p.CabinBaggage.Quantity === 0) &&
                    journey.AvailableCabinBaggageQuantity > 0 &&
                    !p.CabinBaggage.IsSoldOut
                );
            case "checkedBaggage":
                return (
                    (!p.CheckedBaggage.HasSelected || p.CheckedBaggage.Quantity === 0) &&
                    journey.AvailableCheckedInBaggageQuantity > 0 &&
                    !p.CheckedBaggage.IsSoldOut
                );
            case "oversizedBaggage":
                return (
                    (!p.OversizedBaggage.HasSelected || p.OversizedBaggage.Quantity === 0) &&
                    journey.AvailableOversizedBaggageQuantity > 0 &&
                    !p.OversizedBaggage.IsSoldOut
                );
        }
    });

    return paxWithNoSelection ? paxWithNoSelection.PaxIndex : 0;
};
