import { CugUser, CugUserData } from "../../../component-models/CUG2b/CugUserData";
import { PeruCompraUserData } from "../../../component-models/CUG2b/PeruCompraUserData";
import { ApiAvailableRole } from "../../../component-models/CUG2b/portal-spa/PortalSpaUsersViewModel";
import { GridState } from "../../../dc-components/dc-table-models";
import { ROUTES } from "../../../shared/apiRoutes";
import { SelectOption } from "../../../shared/common";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";

export const usePortalUsersUtils = () => {
    const { ajaxJsonRequest } = useAjax();

    // EXPORTS

    const getRoles = (availableRoles: ApiAvailableRole[]): SelectOption[] =>
        availableRoles.map(
            (item: ApiAvailableRole): SelectOption => ({ Text: item.FriendlyName, Value: item.Code }),
        );

    const loadData = async (gridState: GridState<keyof CugUser>, container: HTMLDivElement) => {
        const result = await ajaxJsonRequest<CugUserData | PeruCompraUserData>({
            container,
            method: "GET",
            url:
                `${ROUTES.ApiRoutes.AgencyUsers}/` +
                `?pageSize=1000` +
                `&page=0` +
                `&orderBy=${gridState.orderBy}` +
                `&orderDirection=${gridState.orderDir !== "none" ? gridState.orderDir : "asc"}`,
        });

        return {
            totalCount: result?.data.TotalCount,
            newData: result?.data.Users.map((item) => {
                return { ...item, id: item.Username };
            }),
        };
    };

    const getFilteredUsers = (gridState: GridState<keyof CugUser>, data: CugUser[]): CugUser[] => {
        if (!gridState.globalFilterExpression?.trim()) {
            return data;
        }

        const filterExpression = gridState.globalFilterExpression.toLowerCase().trim();

        return data?.filter(
            (user) =>
                user.FirstName.toLowerCase().indexOf(filterExpression) > -1 ||
                user.LastName.toLowerCase().indexOf(filterExpression) > -1 ||
                user.Username.toLowerCase().indexOf(filterExpression) > -1,
        );
    };

    const getUsersInOnePage = (gridState: GridState<keyof CugUser>, data: CugUser[]) =>
        getFilteredUsers(gridState, data)?.slice(
            gridState.pageIndex * gridState.pageSize,
            (gridState.pageIndex + 1) * gridState.pageSize,
        );

    return { loadData, getFilteredUsers, getUsersInOnePage, getRoles };
};
