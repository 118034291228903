import { useCallback } from "haunted";
import LoginResult from "../../component-models/LoginResult";
import { CustomizedBundleOffers } from "../../component-models/flight/BundleOffersV2Model";
import { convertLoginResultToUserContext as convertLoginResultToUserContext } from "../../shared/common";
import { ARGENTINA_COUNTRY_CODE, COOKIE_NAMES, PERU_COUNTRY_CODE } from "../../shared/commonConstants";
import { getCookie } from "../../shared/cookieHandling";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useMemo } from "../../shared/haunted/CustomHooks";
import { FlightPageViewModel } from "../../component-models/flight/FlightPageViewModel";
import { flightOnlyInCountries } from "../../component-helpers/flight/FlightHelper";
import { ApiTripModel } from "../../component-models/flight/api/ApiTripModel";
import { ROUTES } from "../../shared/apiRoutes";
import { ExtendedTripModel } from "../../component-models/flight/ExtendedTripModel";
import { FlightContext } from "../../component-models/flight/contexts/FlightContext";
import { BundleContext } from "../../component-models/flight/contexts/BundleContext";
import { FarelockContext } from "../../component-models/flight/contexts/FarelockContext";
import { DcContext } from "../../component-models/flight/contexts/DcContext";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { useReduxState } from "../../shared/redux/useReduxState";
import { mapToExtendedTripModel } from "../../component-mappers/FlightPagePropsMapper";

export interface FlightPageLoginHandlerProps {
    bundleContext: BundleContext;
    dcContext: DcContext;
    farelockContext: FarelockContext;
    flightContext: FlightContext;
    model: FlightPageViewModel;
    setJourneys: (journeys: ExtendedTripModel[]) => void;
}

export const useFlightPageLoginHandler = (props: FlightPageLoginHandlerProps) => {
    const { triggers } = usePubSub();
    const { ajaxJsonRequest } = useAjax();

    const [_, setUserContext] = useReduxState("userContext");
    const [currency, setCurrency] = useReduxState("booking.currency");

    const currentFeeType = props.flightContext.state?.selectedFeeType;
    const currentInboundSellKey = props.flightContext.state?.selectedInboundFlight?.SellKey;
    const currentMembershipType = props.dcContext.state?.selectedMembershipType;
    const currentOutboundSellKey = props.flightContext.state?.selectedOutboundFlight?.SellKey;

    const isPeruCompraFlight = useMemo(
        () => flightOnlyInCountries(props.model, [PERU_COUNTRY_CODE, ARGENTINA_COUNTRY_CODE]),
        [props.model],
    );

    // DEVNOTE JET-8003 Reload carousel for CUG2 users because of potential currency change
    const reloadApiJourneys = () => ajaxJsonRequest<ApiTripModel[]>({ method: "GET", url: ROUTES.GetTrips });

    const getSelectedFlightFeeTypeAfterLogin = (result: LoginResult) =>
        !result.IsStaffMember &&
        !result.Cug.IsMember &&
        (result.DC.IsMember ||
            result.BancoEstado.Category === 6 ||
            props.flightContext.state.selectedFeeType === "Club")
            ? "Club"
            : "Smart";

    // EXPORTS

    const handleLogin = useCallback(
        async (result: LoginResult) => {
            const newUserContext = convertLoginResultToUserContext(result);
            const peruCompraLoginCookie = getCookie(COOKIE_NAMES.PeruCompraLogin);

            if (newUserContext.cug.isMember && newUserContext.cug.orgCurrency) {
                setCurrency(newUserContext.cug.orgCurrency);
            }

            if (peruCompraLoginCookie === "true") {
                window.setTimeout(() => triggers.peruCompra.openLoginInfoForPCRA.publish({}), 500);
            }

            setUserContext(newUserContext);

            if (
                (result.PeruCompra.IsMember || result.PeruCompra.IsAdmin) &&
                (currency !== "USD" || !isPeruCompraFlight)
            ) {
                window.location.href = "/";
            }

            props.flightContext.selectFlightFeeType(getSelectedFlightFeeTypeAfterLogin(result));

            let journeys = props.model.Journeys;
            // DEVNOTE JET-8003 Reload carousel for CUG2 users because of potential currency change
            if (result.Cug.IsMember) {
                const newJourneys = await reloadApiJourneys();
                journeys = newJourneys.data.map(mapToExtendedTripModel);
                props.setJourneys(journeys);
            }

            await props.bundleContext.handleLogin({
                flightState: props.flightContext.state,
                journeys,
                newUserContext,
                callback: async (
                    outboundBundleCode: string,
                    inboundBundleCode: string,
                    bundles: CustomizedBundleOffers[],
                ) => {
                    props.dcContext.handleLogin({
                        bannerDto: {
                            bundles,
                            currency,
                            inboundBundleCode,
                            inboundSellKey: currentInboundSellKey,
                            outboundBundleCode,
                            outboundSellKey: currentOutboundSellKey,
                            selectedDcMembershipType: currentMembershipType,
                            selectedFlightFeeType: currentFeeType,
                        },
                        newUserContext,
                    });
                },
            });

            if (result.Cug.IsAgent) props.farelockContext.reset();
        },
        [
            currency,
            isPeruCompraFlight,
            props.bundleContext?.state,
            props.dcContext,
            props.farelockContext,
            props.flightContext,
            props.model,
        ],
    );

    return { handleLogin };
};
