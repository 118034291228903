import { html, useEffect, useRef } from "haunted";
import { ApiPortalSpaViewModel } from "../../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import { useMemo, useState } from "../../../shared/haunted/CustomHooks";
import dayjs from "dayjs";
import i18next from "i18next";
import { GridState } from "../../../dc-components/dc-table-models";
import { ref } from "../../../directives/ref";
import { usePortalReservationsUtils } from "./usePortalReservationsUtils";
import { PeruCompraCancelledReservationModel } from "../../../component-models/peru-compra/PeruCompraCancelledReservationModel";
import { PeruCompraReservationModel } from "../../../component-models/peru-compra/PeruCompraReservationModel";
import { ROUTES } from "../../../shared/apiRoutes";
import { CLASS_NAMES } from "../../../shared/classNames";
import { handleCugLoader, SelectOption } from "../../../shared/common";
import classNames from "classnames";
import { usePeruCompraCancelledReservations } from "./usePeruCompraCancelledReservations";
import { usePeruCompraConfirmedReservations } from "./usePeruCompraConfirmedReservations";
import { OrganizationAgent } from "../../../component-models/CUG2b/portal-spa/PortalSpaReservationsViewModel";

export interface Props {
    model: ApiPortalSpaViewModel;
}

export const usePeruCompraReservationsPage = (props: Props) => {
    const { loadPeruCompraData } = usePortalReservationsUtils();

    const root = useRef<HTMLDivElement>(undefined);
    const confirmedGrid = useRef<HTMLDivElement>(undefined);
    const cancelledGrid = useRef<HTMLDivElement>(undefined);

    const initialConfirmedGridState = useMemo<GridState<keyof PeruCompraReservationModel>>(
        () => ({
            pageIndex: 0,
            appliedFilters: [],
            pageSize: 10,
            orderBy: "CreatedDate",
            orderDir: "desc",
            globalFilterExpression: "",
        }),
        [],
    );

    const initialCancelledGridState = useMemo<GridState<keyof PeruCompraCancelledReservationModel>>(
        () => ({
            pageIndex: 0,
            appliedFilters: [],
            pageSize: 10,
            orderBy: "CreatedDate",
            orderDir: "desc",
            globalFilterExpression: "",
        }),
        [],
    );

    const agentsInOrganization = useMemo<SelectOption[]>(
        () =>
            props.model.ReservationsViewModel.AgentsInOrganization
                ? props.model.ReservationsViewModel.AgentsInOrganization.map((agent: OrganizationAgent) => ({
                      Text: agent.Name,
                      Value: agent.Id.toString(),
                      IsSelected: false,
                  }))
                : [],
        [],
    );

    const [confirmedGridState, setConfirmedGridState] =
        useState<GridState<keyof PeruCompraReservationModel>>(initialConfirmedGridState);
    const [selectedConfirmedIds, setSelectedConfirmedIds] = useState<Set<string>>(new Set<string>());
    const [confirmedData, setConfirmedData] = useState<PeruCompraReservationModel[]>(undefined);
    const [totalConfirmedItemCount, setTotalConfirmedItemCount] = useState<number>(0);

    const [cancelledGridState, setCancelledGridState] =
        useState<GridState<keyof PeruCompraCancelledReservationModel>>(initialCancelledGridState);
    const [selectedCancelledIds, setSelectedCancelledIds] = useState<Set<string>>(new Set<string>());
    const [cancelledData, setCancelledData] = useState<PeruCompraCancelledReservationModel[]>(undefined);
    const [totalCancelledItemCount, setTotalCancelledItemCount] = useState<number>(0);

    const [filterFlightDateFrom, setFilterFlightDateFrom] = useState<dayjs.Dayjs>(undefined);
    const [filterFlightDateTo, setFilterFlightDateTo] = useState<dayjs.Dayjs>(undefined);
    const [filterUserId, setFilterUserId] = useState<number>(-1);

    const [detailsModalData, setDetailsModalData] = useState<
        PeruCompraReservationModel | PeruCompraCancelledReservationModel
    >(undefined);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);

    // DEVNOTE Only submit filter params to BE if the user has at least once clicked Search next to the datepickers
    const [hasSubmittedDateFiltering, setHasSubmittedDateFiltering] = useState<boolean>(false);
    const [hasSubmittedUserFiltering, setHasSubmittedUserFiltering] = useState<boolean>(false);

    // HELPERS

    const init = async () => {
        await Promise.all([loadConfirmedData(), loadCancelledData()]);
    };

    const loadConfirmedData = async (
        filterForDates = hasSubmittedDateFiltering,
        filterForUser = hasSubmittedUserFiltering,
    ) => {
        handleCugLoader(confirmedGrid.current, "loadConfirmedData");

        const { Journeys, TotalCount } = await loadPeruCompraData(
            { bookingStatus: "Confirmed", gridState: confirmedGridState },
            root.current,
            filterForDates,
            filterForUser,
            filterUserId,
            filterFlightDateFrom,
            filterFlightDateTo,
        );

        setConfirmedData(Journeys as PeruCompraReservationModel[]);
        setTotalConfirmedItemCount(TotalCount);

        handleCugLoader(confirmedGrid.current, "loadConfirmedData");
    };

    const loadCancelledData = async () => {
        handleCugLoader(cancelledGrid.current, "loadCancelledData");

        const { Journeys, TotalCount } = await loadPeruCompraData(
            { bookingStatus: "Cancelled", gridState: cancelledGridState },
            root.current,
            false,
            false,
        );

        setCancelledData(Journeys as PeruCompraCancelledReservationModel[]);
        setTotalCancelledItemCount(TotalCount);

        handleCugLoader(cancelledGrid.current, "loadCancelledData");
    };

    // DEVNOTE We return a number based on the number of flights
    // that have the same TicketNumber. 1 means one way, 2 means round trip
    const getFlightType = (reservation: PeruCompraReservationModel) =>
        confirmedData.filter((currentReservation) => currentReservation.TicketNumber === reservation?.TicketNumber)
            .length;

    // COMPONENT

    const cancelledReservations = usePeruCompraCancelledReservations({
        data: cancelledData,
        gridState: cancelledGridState,
        selectedIds: Array.from(selectedCancelledIds.values()),
        totalItemCount: totalCancelledItemCount,
        selectedCancelledIds,
        setGridState: setCancelledGridState,
        setSelectedCancelledIds,
        setIsDetailsModalOpen,
        setDetailsModalData,
    });

    const confirmedReservations = usePeruCompraConfirmedReservations({
        agentsInOrganization,
        canExport: props.model.ReservationsViewModel.CanExport,
        data: confirmedData,
        filterFlightDateFrom,
        filterFlightDateTo,
        filterUserId,
        gridState: confirmedGridState,
        selectedIds: Array.from(selectedConfirmedIds.values()),
        totalItemCount: totalConfirmedItemCount,
        selectedConfirmedIds,
        container: confirmedGrid.current,
        setSelectedConfirmedIds,
        setGridState: setConfirmedGridState,
        setHasSubmittedDateFiltering,
        setHasSubmittedUserFiltering,
        setFilterFlightDateFrom,
        setFilterFlightDateTo,
        setFilterUserId,
        setIsDetailsModalOpen,
        setDetailsModalData,
        loadData: loadConfirmedData,
    });

    useEffect(() => (confirmedData ? loadConfirmedData() : null), [confirmedGridState]);
    useEffect(() => (cancelledData ? loadCancelledData() : null), [cancelledGridState]);

    // FIXME There is a bug with the no scroll class, this is a quick fix for the demo
    useEffect(() => {
        if (isDetailsModalOpen) {
            document.body.classList.add(CLASS_NAMES.cugNoScroll);
            document.getElementsByTagName("html")[0].classList.add(CLASS_NAMES.cugNoScroll);
        } else {
            document.body.classList.remove(CLASS_NAMES.cugNoScroll);
            document.getElementsByTagName("html")[0].classList.remove(CLASS_NAMES.cugNoScroll);
        }
    }, [isDetailsModalOpen]);

    // EVENT-HANDLERS

    const handleDownload = () => {
        window.location.href = ROUTES.ApiRoutes.PeruCompraReservationsCsv;
    };

    // TEMPLATES

    const confirmedReservationsGridTemplate = () => html`
        <div ref=${ref(confirmedGrid)} class=${classNames({ "relative min-h-1/4 w-full": !confirmedData })}>
            ${confirmedReservations.htmlTemplate()}
        </div>
    `;

    const cancelledReservationsGridTemplate = () => html`
        <div
            ref=${ref(cancelledGrid)}
            class=${classNames("pcra-hide-select-box", { "relative min-h-1/4 w-full": !cancelledData })}
        >
            ${cancelledReservations.htmlTemplate()}
        </div>
    `;

    const detailsModalTemplate = () =>
        html`<ac-peru-compra-details-modal
            .isOpen=${isDetailsModalOpen}
            .data=${detailsModalData}
            .setIsDetailsModalOpen=${setIsDetailsModalOpen}
            .getFlightType=${getFlightType}
            @close=${() => setIsDetailsModalOpen(false)}
        ></ac-peru-compra-details-modal>`;

    const exportTemplate = () =>
        props.model.ReservationsViewModel.CanExport
            ? html`
                  <div class="mt-8 flex flex-col items-center justify-end sm:flex-row">
                      <button
                          @click=${() => handleDownload()}
                          class="rounded-primary-btn cug2b-btn mb-4 sm:mb-0 sm:mr-3"
                      >
                          ${i18next.t("CUG-Download")}
                      </button>
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        <div ref=${ref(root)} class="w-full">
            <div class="cug2b-title-container">
                <i class="js-icon-cug js-cug-finger"></i>
                <div>
                    <h1>${i18next.t("Registro de movimientos")}</h1>

                    <h2>${i18next.t("Reservas confirmadas")}</h2>
                </div>
            </div>

            ${confirmedReservationsGridTemplate()} ${cancelledReservationsGridTemplate()} ${exportTemplate()}
            ${detailsModalTemplate()}
        </div>
    `;

    return { init, htmlTemplate };
};
