import { html } from "haunted";
import { UserInfo } from "../../../component-models/UserInfo";
import { PeruCompraMemberData } from "../../../component-models/PeruCompraMemberData";
import { useUserInfoFields } from "./useUserInfoFields";

export interface Props {
    model: UserInfo;
    peruCompraModel: PeruCompraMemberData;
}

export const usePeruCompraUserInfo = (props: Props) => {
    const userInfo = useUserInfoFields({
        model: props.model,
        peruCompraModel: props.peruCompraModel,
    });

    // TEMPLATES

    const htmlTemplate = () => html`
        <div class="cug2b-profile-table">
            <div class="cug2b-profile-line">${userInfo.fullNameTemplate()} ${userInfo.dniTemplate()}</div>
            <div class="cug2b-profile-line">${userInfo.officePhoneTemplate()} ${userInfo.mobilePhoneTemplate()}</div>
            <div class="cug2b-profile-line">${userInfo.emailTemplate()} ${userInfo.passwordTemplate()}</div>
        </div>
    `;

    return { htmlTemplate };
};
