import { html, useRef } from "haunted";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import i18next from "i18next";
import { useState } from "../../../shared/haunted/CustomHooks";
import { ref } from "../../../directives/ref";
import { ROUTES } from "../../../shared/apiRoutes";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { handleCugLoader } from "../../../shared/common";
import { TravelPartnerHelper } from "../../../component-helpers/TravelPartnerHelper";
import { URL_VARS } from "../../../shared/commonConstants";
import classNames from "classnames";
import { ChangeEvent } from "../../../dc-components/dc-checkbox";
import { PagerChangeEvent } from "../../../dc-components/dc-pager";
import { useGroupDeletionModeModal } from "./useGroupDeletionModeModal";
import { useGroupDeletionConfirmationModal } from "./useGroupDeletionConfirmationModal";
import { usePortalTravelPartnersUtils } from "./usePortalTravelPartnersUtils";

const GROUPS_PER_PAGE = 8;

export type DeletionMode = "none" | "groupsOnly" | "groupsAndPartners";

export const useCugAdminTravelGroupsPage = () => {
    const root = useRef<HTMLDivElement>(undefined);

    const { getTravelPartnerInfo, postTravelPartnerInfo } = useAjax();
    const { isAnythingSelected } = usePortalTravelPartnersUtils();

    const [travelPartnerInfo, setTravelPartnerInfo] = useState<TravelPartnerInfo>(undefined);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set<number>());
    const [groupsToDelete, setGroupsToDelete] = useState<number[]>(undefined);
    const [deletionMode, setDeletionMode] = useState<DeletionMode>("none");

    // HELPERS

    const init = () => {
        const doIt = async () => {
            const result = await getTravelPartnerInfo();

            setTravelPartnerInfo(result);
        };

        void doIt();
    };

    // EVENT HANDLERS

    const handleDeleteGroups = async () => {
        setGroupsToDelete(undefined);

        const newTravelPartnerInfo = TravelPartnerHelper.deleteGroups(
            travelPartnerInfo,
            Array.from(selectedIds.values()),
            deletionMode === "groupsAndPartners",
        );

        handleCugLoader(root.current, "loadData");

        const result = await postTravelPartnerInfo(newTravelPartnerInfo);
        setTravelPartnerInfo(result);

        handleCugLoader(root.current, "loadData");
    };

    const handleMultiDeleteButtonClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setGroupsToDelete(Array.from(selectedIds.values()));
    };

    const handleEditGroup = (id: number) => {
        const searchExpression = `${URL_VARS.FFLYER_IDS}=${encodeURIComponent(id)}`;
        window.location.href = `${ROUTES.Cug2BEditTravelGroupPage}?${searchExpression}`;
    };

    const handlePagerChange = (e: PagerChangeEvent) => {
        setCurrentPage(e.detail.selectedPageIndex);
    };

    const handleSelection = (e: ChangeEvent, id: number) => {
        const clonedSet = new Set(selectedIds);

        if (e.detail.checked) {
            clonedSet.add(id);
        } else {
            clonedSet.delete(id);
        }

        setSelectedIds(clonedSet);
    };

    const handleModalClose = () => {
        setGroupsToDelete(undefined);
        setDeletionMode("none");
    };

    // COMPONENT

    const deletionModeModal = useGroupDeletionModeModal({
        deletionMode,
        groupsToDelete,
        setDeletionMode,
        handleModalClose,
    });

    const deletionConfirmationModal = useGroupDeletionConfirmationModal({
        deletionMode,
        travelPartnerInfo,
        groupsToDelete,
        setDeletionMode,
        handleModalClose,
        handleDeleteGroups,
    });

    // TEMPLATES

    const staticStuffTemplate = () => html`
        <div class="travel-partner-breadcrumb-container">
            <div
                class="travel-partner-breadcrumb past"
                @click=${() => (window.location.href = ROUTES.Cug2BTravelPartnersPage)}
            >
                ${i18next.t("Pasajeros frecuentes")}
            </div>
            <div class="travel-partner-breadcrumb divider">/</div>
            <div
                class="travel-partner-breadcrumb past"
                @click=${() => (window.location.href = ROUTES.Cug2BTravelGroupsPage)}
            >
                ${i18next.t("Grupos")}
            </div>
            <div class="travel-partner-breadcrumb divider">/</div>
            <div class="travel-partner-breadcrumb">${i18next.t("Administrar grupos")}</div>
        </div>
        <div class="travel-partner-title pull-up">
            <h2>${i18next.t("Administrar grupos")}</h2>
        </div>
        <div class="travel-partner-text-and-button">
            <div>
                ${i18next.t(
                    "Aquí podrás editar tus grupos existentes, modificar los pasajeros asignados a cada grupo, agregar o eliminar pasajeros y eliminar grupos.",
                )}
            </div>
            <button
                class=${classNames("cug2b-pill warning", { disabled: !isAnythingSelected(selectedIds) })}
                @click=${handleMultiDeleteButtonClick}
            >
                <i class="js-icon-cug js-cug-bin"></i>${i18next.t("Eliminar Grupo(s)")}
            </button>
        </div>
    `;

    const currentPageTemplate = () => {
        const pageItems = TravelPartnerHelper.getAdminGroupPageContent(travelPartnerInfo, currentPage, GROUPS_PER_PAGE);

        return html`
            <div class="cug2b-group-admin-group-container">
                ${pageItems.map((group) => {
                    return html`
                        <div class="cug2b-group-admin-group">
                            <div class="cug2b-group-admin-checkbox">
                                <dc-checkbox
                                    .checked=${selectedIds.has(group.Id)}
                                    @change=${(e: ChangeEvent) => handleSelection(e, group.Id)}
                                ></dc-checkbox>
                            </div>
                            <h1>${group.Name}</h1>
                            <h2>
                                ${i18next.t("Cantidad de pasajeros")}:
                                <span>${TravelPartnerHelper.getNumberOfPartners(travelPartnerInfo, group.Id)}</span>
                            </h2>
                            <div class="cug2b-group-admin-link" @click=${() => handleEditGroup(group.Id)}>
                                ${i18next.t("Ver listado de personas")} <span>&rsaquo;</span>
                            </div>
                            <i class="js-icon-cug js-cug-edit" @click=${() => handleEditGroup(group.Id)}></i>
                        </div>
                    `;
                })}
            </div>
        `;
    };

    const paginatorTemplate = () => html`
        <dc-pager
            .pageSize=${GROUPS_PER_PAGE}
            .pageIndex=${currentPage}
            .itemCount=${travelPartnerInfo.Groups.length}
            .pageButtonCount=${3}
            .showInfo=${false}
            .showPreviousNext=${true}
            @change=${handlePagerChange}
        ></dc-pager>
    `;

    const backButtonTemplate = () => html`
        <div class="travel-partner-back-btn-container" @click=${() => window.history.back()}>
            <i class="js-icon js-circle-chevron-right"></i>
            <span>${i18next.t("Volver")}</span>
        </div>
    `;

    const htmlTemplate = () =>
        travelPartnerInfo
            ? html`
                  <div ref=${ref(root)}>
                      ${staticStuffTemplate()} ${currentPageTemplate()} ${paginatorTemplate()} ${backButtonTemplate()}
                      ${deletionModeModal.htmlTemplate()} ${deletionConfirmationModal.htmlTemplate()}
                  </div>
              `
            : html``;

    return { init, htmlTemplate };
};
