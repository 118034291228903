import { CLASS_NAMES } from "./classNames";
import { getCoords, isMobile } from "./common";
import DomCrawlingHelper from "./DomCrawlingHelper";
import { FlightPageBundleState } from "../component-models/flight/contexts/FlightPageBundleState";
import { usePubSub } from "../pub-sub-service/usePubSub";
import PerfectScrollbar from "perfect-scrollbar";

const defaultScrollTimeOffsetBundle = 400;
const bundleSelectorButtonClassName = "bundle-button";
const mercadoPagoPaymentWarningClassName = "payment-mm-warning";
export const itineraryTransactionsTabClassName = "itinerary-transactions-tab";
export const itineraryTransactionsAccordionClassName = "itinerary-transactions-accordion";

// DEVNOTE: All the setTimeouts are needed for the animations to finish
export class ScrollHelper {
    private static scrollInterval: number;

    public static scrollToChildElement(parent: HTMLElement, childClassName: string, index: number): void {
        window.clearInterval(this.scrollInterval);
        const scrollSpeed = 3;
        let scrollStep = ScrollHelper.calculateScrollStep(parent, childClassName, index, scrollSpeed);
        this.scrollInterval = window.setInterval(() => {
            if (scrollStep > 0) {
                scrollStep--;
                ScrollHelper.adjustScrollTop(parent, childClassName, index, scrollSpeed);
            } else {
                window.clearInterval(this.scrollInterval);
            }
        }, 15);
    }

    public static scrollToCardData(paymentForm: HTMLDivElement): void {
        const element =
            (document.body.querySelector(`.${mercadoPagoPaymentWarningClassName}`) as HTMLDivElement) || paymentForm;

        if (element) {
            ScrollHelper.scrollToElementAndHideNav({
                element,
                yOffset: 0,
                timeOffset: 0,
            });
        }
    }

    public static scrollToItineraryTransactions(isMobile: boolean): void {
        let element;
        if (!isMobile) {
            element = document.body.querySelector(`.${itineraryTransactionsTabClassName}`) as HTMLDivElement;
            if (element) {
                this.scrollToElement(element, -25, false);
            }
            return;
        }
        window.setTimeout(() => {
            element = document.body.querySelector(`.${itineraryTransactionsAccordionClassName}`) as HTMLDivElement;
            if (element) {
                ScrollHelper.scrollToElementAndHideNav({
                    element,
                    timeOffset: 0,
                    yOffset: 300,
                });
            }
        }, defaultScrollTimeOffsetBundle);
    }

    public static scrollToContinueButton(): void {
        // DEVNOTE Settimeouts are needed here and not in the helper because of animations and rendering issues
        window.setTimeout(() => {
            const element = document.querySelector(".rounded-primary-btn.booking") as HTMLButtonElement;

            if (element) {
                ScrollHelper.scrollToElementAndHideNav({
                    element,
                    yOffset: 0,
                    timeOffset: 0,
                    scrollToBottom: true,
                });
            }
        }, defaultScrollTimeOffsetBundle);
    }

    public static scrollToFareLock(): void {
        // DEVNOTE Settimeouts are needed here and not in the helper because of animations and rendering issues
        // If there is no farelock, let's scroll to the submit button
        window.setTimeout(() => {
            const element =
                DomCrawlingHelper.getElemByClass(document.body, "flight-farelock-button-container") ||
                // FIXME multiple classes are not allowed
                DomCrawlingHelper.getElemByClass(document.body, "rounded-primary-btn booking");

            if (element) {
                ScrollHelper.scrollToElementAndHideNav({
                    element,
                    yOffset: 0,
                    timeOffset: 0,
                });
            }
        }, defaultScrollTimeOffsetBundle);
    }

    public static scrollToBundlesBottom(bubu: HTMLElement): void {
        // DEVNOTE Settimeouts are needed here and not in the helper because of animations and rendering issues
        window.setTimeout(() => {
            const button = this.getFirstVisibleBundleSelectorButtonForJourney(bubu);

            if (!button) {
                return;
            }

            ScrollHelper.scrollToElementAndHideNav({
                element: button,
                yOffset: isMobile() ? -75 : -25,
                timeOffset: 0,
                scrollToBottom: true,
            });
        }, defaultScrollTimeOffsetBundle);
    }

    public static scrollToSelectedBundle(rootElem: HTMLElement, isMobileOnly: boolean): void {
        const isMobileResolution = () => ScrollHelper.getWindowWidth() < 768;

        if (isMobileOnly && !isMobileResolution()) {
            return;
        }

        // DEVNOTE Settimeouts are needed here and not in the helper because of animations and rendering issues
        window.setTimeout(() => {
            const parent = DomCrawlingHelper.getElemByClass(rootElem, CLASS_NAMES.flightSelectedFlightContainer);
            ScrollHelper.scrollToElementAndHideNav({
                element: parent,
                yOffset: 25,
                timeOffset: 0,
            });
        }, defaultScrollTimeOffsetBundle);
    }

    public static scrollToBanner(): void {
        // DEVNOTE Settimeouts are needed here and not in the helper because of animations and rendering issues
        window.setTimeout(() => {
            const element = DomCrawlingHelper.getElemByClass(document.body, "dc-banner-container");
            if (element) {
                ScrollHelper.scrollToElementAndHideNav({
                    element,
                    yOffset: 25,
                    timeOffset: 0,
                });
            }
        }, defaultScrollTimeOffsetBundle);
    }

    public static scrollToWeekSelector(
        bundleState: FlightPageBundleState,
        parentElement: HTMLElement,
        yOffset?: number,
        timeOffset?: number,
    ): void {
        if (bundleState.bundlesMode === "PerLeg") {
            if (!parentElement) {
                return;
            }

            const weekSelector = DomCrawlingHelper.getElemByClass(parentElement, CLASS_NAMES.flightWeekSelector);
            ScrollHelper.scrollToElementAndHideNav({ element: weekSelector, yOffset, timeOffset });
        }
    }

    // DEVNOTE Settimeout is needed for the error messages to render
    public static scrollToFirstError(): void {
        window.setTimeout(() => {
            const firstError = (
                DomCrawlingHelper.getArrayOfClass(document.body, CLASS_NAMES.ErrorMessageContainer) as HTMLElement[]
            ).filter((elem) => elem.offsetHeight > 0);
            if (firstError.length > 0) {
                const topOfElement = getCoords(firstError[0].parentElement).top;
                window.scroll({ top: topOfElement, behavior: "smooth" });
            }
        }, 400);
    }

    // DEVNOTE Settimeout is needed for the error messages to render
    public static scrollToFirstFlightPageError(): void {
        window.setTimeout(() => {
            const firstError = (
                DomCrawlingHelper.getArrayOfClass(document.body, CLASS_NAMES.flightSelectError) as HTMLElement[]
            ).filter((elem) => elem.offsetHeight > 0);
            if (firstError.length > 0) {
                const topOfElement = getCoords(firstError[0]).top;
                window.scroll({ top: topOfElement, behavior: "smooth" });
            }
        }, 400);
    }

    public static scrollToElementAndHideNav(data: {
        element: HTMLElement;
        yOffset?: number;
        timeOffset?: number;
        scrollToBottom?: boolean;
    }): void {
        if (!data.element) {
            return;
        }

        const yOffset = data.yOffset !== undefined ? data.yOffset : this.DEFAULT_Y_OFFSET;
        const timeOffset = data.timeOffset !== undefined ? data.timeOffset : this.DEFAULT_TIME_OFFSET;

        this.hideNavbar();
        window.setTimeout(() => {
            this.scrollToElement(data.element, yOffset, data.scrollToBottom);
        }, timeOffset);
    }

    public static getWindowWidth(): number {
        return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    }

    public static getWindowHeight(): number {
        return window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    }

    public static addPerfectScrollbar(elemClassName: string, options?: PerfectScrollbar.Options): PerfectScrollbar[] {
        const scrollers = DomCrawlingHelper.getArrayOfClass(document.body, elemClassName);

        const retVal = scrollers.map((scroller) =>
            scroller
                ? new PerfectScrollbar(
                      scroller,
                      options
                          ? options
                          : {
                                wheelPropagation: false,
                                wheelSpeed: 1,
                                swipeEasing: true,
                                suppressScrollX: true,
                            },
                  )
                : undefined,
        );

        scrollers.forEach((scroller) =>
            scroller?.addEventListener("ps-scroll-y", () => usePubSub().triggers.shared.windowWasScrolled.publish({})),
        );

        return retVal;
    }

    public static scrollToTop(): void {
        this.scrollToElement(document.body, 0, false);
    }

    private static readonly DEFAULT_Y_OFFSET = 20;
    private static readonly DEFAULT_TIME_OFFSET = 100;

    private static scrollToElement(element: HTMLElement, yOffset: number, scrollToBottom: boolean) {
        const topOfElement = getCoords(element).top - yOffset;
        let scrollTo = topOfElement;

        if (scrollToBottom) {
            const bottomOfElement = topOfElement + element.offsetHeight;
            scrollTo = bottomOfElement - this.getWindowHeight();
        }

        window.scroll({
            top: scrollTo,
            behavior: "smooth",
        });
        window.setTimeout(() => {
            window.watchScroll = true;
        }, 1000);
    }

    private static hideNavbar(): void {
        const navbar = document.body.querySelector(`.${CLASS_NAMES.mainHeader}`);
        if (navbar) {
            window.watchScroll = false;
            navbar.classList.add(CLASS_NAMES.mainHeaderCollapsed);

            const mobileMenuOpeners = DomCrawlingHelper.getArrayOfClass(
                document.body,
                CLASS_NAMES.mobileNavigation,
            ) as HTMLInputElement[];

            for (const opener of mobileMenuOpeners) {
                if (opener.checked) {
                    opener.click();
                }
            }
        }
    }

    private static getFirstVisibleBundleSelectorButtonForJourney(bubu: HTMLElement): HTMLButtonElement {
        if (!bubu) return undefined;

        const bundleSelectorButtons = Array.from(
            bubu.querySelectorAll(`.${bundleSelectorButtonClassName}`),
        ) as HTMLButtonElement[];

        return bundleSelectorButtons.find((e) => e.offsetHeight > 0);
    }

    private static adjustScrollTop(parent: HTMLElement, childClassName: string, index: number, scrollSpeed: number) {
        if (ScrollHelper.nthChildElemScrollTop(parent, childClassName, index) > 0) {
            parent.scrollTop += scrollSpeed;
        } else {
            parent.scrollTop -= scrollSpeed;
        }
    }

    private static calculateScrollStep(
        parent: HTMLElement,
        childClassName: string,
        index: number,
        scrollSpeed: number,
    ) {
        return Math.ceil(Math.abs(ScrollHelper.nthChildElemScrollTop(parent, childClassName, index)) / scrollSpeed);
    }

    private static nthChildElemScrollTop(parent: HTMLElement, childClassName: string, index: number) {
        const listOfChildObjects = DomCrawlingHelper.getArrayOfClass(parent, childClassName);
        const elem = listOfChildObjects.length > index ? listOfChildObjects[index] : undefined;

        return elem ? (elem.getBoundingClientRect().height + 10) * index - parent.scrollTop : 0;
    }
}
