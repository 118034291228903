import { html } from "haunted";
import i18next from "i18next";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { CugUser } from "../../../component-models/CUG2b/CugUserData";
import { ROUTES } from "../../../shared/apiRoutes";
import { handleCugLoader } from "../../../shared/common";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";

export interface Props {
    rootElement: HTMLElement;
    gridElement: HTMLElement;
    userToChangeStatus: CugUser;
    updateDataState: () => Promise<void>;
    setUserToChangeStatus: (user: CugUser) => void;
}

export const useChangeStatusModal = (props: Props) => {
    const { ajaxRequest } = useAjax();

    // EVENT-HANDLERS
    const onStatusChange = async (deactivate: boolean) => {
        const userId = props.userToChangeStatus?.Username;
        props.setUserToChangeStatus(undefined);
        handleCugLoader(props.gridElement, "loadData");
        await ajaxRequest({
            body: { userId },
            container: props.rootElement,
            url: deactivate ? ROUTES.ApiRoutes.AgencyDisableUser : ROUTES.ApiRoutes.AgencyEnableUser,
        });
        handleCugLoader(props.gridElement, "loadData");
        await props.updateDataState();
    };

    // TEMPLATES
    const modalHeaderTemplate = () => html`
        <i class="js-icon js-question-broken-circle big-modal-icon absolute left-0 ml-8 vertical-center"></i>
        <span>${i18next.t("¿Estás seguro?")}</span>
    `;

    const changeStatusModalContentTemplate = () => {
        const deactivate = props.userToChangeStatus?.IsInActiveStatus;
        const questionText = deactivate
            ? i18next.t("¿Estás seguro de que quieres {{-spanStart}}deactivar{{-spanEnd}} al usuario?", {
                  spanStart: "<span>",
                  spanEnd: "</span>",
              })
            : i18next.t("¿Estás seguro de que quieres {{-spanStart}}activar{{-spanEnd}} al usuario?", {
                  spanStart: "<span>",
                  spanEnd: "</span>",
              });

        return html`
            <div class="p-8">
                <div class="cug2b-modal-question mb-4 sm:mb-8">${unsafeHTML(questionText)}</div>
                <div class="cug2b-modal-question">${i18next.t("User Name")}: ${props.userToChangeStatus?.Username}</div>
                <div class="cug2b-modal-question">
                    ${i18next.t("Nombre y apellido")}: ${props.userToChangeStatus?.FirstName}
                    ${props.userToChangeStatus?.LastName}
                </div>
                <div class="mt-8 flex flex-col items-center justify-center sm:mt-16 sm:flex-row">
                    <button
                        class="rounded-secondary-btn mb-4 sm:mb-0 sm:mr-8"
                        @click=${() => props.setUserToChangeStatus(undefined)}
                    >
                        ${i18next.t("Cancelar")}
                    </button>
                    <button class="rounded-primary-btn" @click=${() => onStatusChange(deactivate)}>
                        ${i18next.t("Aceptar")}
                    </button>
                </div>
            </div>
        `;
    };

    const htmlTemplate = () => html`
        <ac-cug-modal
            .canBeClosed=${true}
            .content=${changeStatusModalContentTemplate()}
            .customClass=${"cug2b-change-status-modal"}
            .header=${modalHeaderTemplate()}
            .isOpen=${Boolean(props.userToChangeStatus)}
            @close=${() => props.setUserToChangeStatus(undefined)}
        ></ac-cug-modal>
    `;

    return { htmlTemplate };
};
