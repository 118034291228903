import { FlightPageContext } from "../component-models/flight/contexts/FlightPageContext";
import { ItineraryJourney } from "../component-models/itinerary/ApiItineraryViewModelV2";

export const mapToChileCompraSummaryModalJourneys = (context: FlightPageContext) => {
    const outboundJourney: ItineraryJourney =
        context.flightState?.selectedOutboundFlight && context.bundleState?.selectedOutboundBundle
            ? {
                  AmountOfUncheckedPax: 0,
                  ArrivalStationCode: context.flightState.selectedOutboundFlight.FlightSelectModel.DestinationIata,
                  ArrivalStationName: context.flightState.selectedOutboundFlight.FlightSelectModel.DestinationCity,
                  BundleCode: context.bundleState.selectedOutboundBundle?.BundleCode,
                  BundleInfo: {
                      BundleCode: context.bundleState.selectedOutboundBundle?.BundleCode,
                      Color: context.bundleState.selectedOutboundBundle?.Color,
                      ContainsFreeAnySeat: false,
                      ContainsFreeRegularSeat: false,
                      DisplayName: {
                          En: context.bundleState.selectedOutboundBundle?.DisplayName,
                          Es: context.bundleState.selectedOutboundBundle?.DisplayName,
                          Pt: context.bundleState.selectedOutboundBundle?.DisplayName,
                      },
                      HeaderImgUrl: {
                          En: context.bundleState.selectedOutboundBundle?.HeaderImgUrl,
                          Es: context.bundleState.selectedOutboundBundle?.HeaderImgUrl,
                          Pt: context.bundleState.selectedOutboundBundle?.HeaderImgUrl,
                      },
                      SsrInfoImgUrl: {
                          En: context.bundleState.selectedOutboundBundle?.SsrInfoImgUrl,
                          Es: context.bundleState.selectedOutboundBundle?.SsrInfoImgUrl,
                          Pt: context.bundleState.selectedOutboundBundle?.SsrInfoImgUrl,
                      },
                      FooterImgUrl: {
                          En: context.bundleState.selectedOutboundBundle?.FooterImgUrl,
                          Es: context.bundleState.selectedOutboundBundle?.FooterImgUrl,
                          Pt: context.bundleState.selectedOutboundBundle?.FooterImgUrl,
                      },
                      UpgradeImgUrl: {
                          En: context.bundleState.selectedOutboundBundle?.UpgradeImgUrl,
                          Es: context.bundleState.selectedOutboundBundle?.UpgradeImgUrl,
                          Pt: context.bundleState.selectedOutboundBundle?.UpgradeImgUrl,
                      },
                      UpgradeImgMobileUrl: {
                          En: context.bundleState.selectedOutboundBundle?.UpgradeImgMobileUrl,
                          Es: context.bundleState.selectedOutboundBundle?.UpgradeImgMobileUrl,
                          Pt: context.bundleState.selectedOutboundBundle?.UpgradeImgMobileUrl,
                      },
                  },
                  BundleType: context.bundleState.selectedOutboundBundle?.BundleType,
                  CheckinBeforeTime: "",
                  DepartureStationCode: context.flightState.selectedOutboundFlight.FlightSelectModel.OriginIata,
                  DepartureStationName: context.flightState.selectedOutboundFlight.FlightSelectModel.OriginCity,
                  EncodedSellKey: "",
                  FlightNumber: context.flightState.selectedOutboundFlight.FlightSelectModel.FlightNumber,
                  CheckinWindowOpenDateUtc: "",
                  IsFlown: false,
                  IsModificationPossible: false,
                  JourneyIndex: 0,
                  JourneyPassengers: [],
                  Segments: context.flightState.selectedOutboundFlight.FlightSelectModel.Segments,
                  CheckinStatus: "NotAvailable",
              }
            : undefined;

    const inboundJourney: ItineraryJourney =
        context.flightState?.selectedInboundFlight && context.bundleState?.selectedInboundBundle
            ? {
                  AmountOfUncheckedPax: 0,
                  ArrivalStationCode: context.flightState.selectedInboundFlight.FlightSelectModel.DestinationIata,
                  ArrivalStationName: context.flightState.selectedInboundFlight.FlightSelectModel.DestinationCity,
                  BundleCode: context.bundleState.selectedInboundBundle?.BundleCode,
                  BundleInfo: {
                      BundleCode: context.bundleState.selectedInboundBundle?.BundleCode,
                      Color: context.bundleState.selectedInboundBundle?.Color,
                      ContainsFreeAnySeat: false,
                      ContainsFreeRegularSeat: false,
                      DisplayName: {
                          En: context.bundleState.selectedInboundBundle?.DisplayName,
                          Es: context.bundleState.selectedInboundBundle?.DisplayName,
                          Pt: context.bundleState.selectedInboundBundle?.DisplayName,
                      },
                      HeaderImgUrl: {
                          En: context.bundleState.selectedInboundBundle?.HeaderImgUrl,
                          Es: context.bundleState.selectedInboundBundle?.HeaderImgUrl,
                          Pt: context.bundleState.selectedInboundBundle?.HeaderImgUrl,
                      },
                      SsrInfoImgUrl: {
                          En: context.bundleState.selectedInboundBundle?.SsrInfoImgUrl,
                          Es: context.bundleState.selectedInboundBundle?.SsrInfoImgUrl,
                          Pt: context.bundleState.selectedInboundBundle?.SsrInfoImgUrl,
                      },
                      FooterImgUrl: {
                          En: context.bundleState.selectedInboundBundle?.FooterImgUrl,
                          Es: context.bundleState.selectedInboundBundle?.FooterImgUrl,
                          Pt: context.bundleState.selectedInboundBundle?.FooterImgUrl,
                      },
                      UpgradeImgUrl: {
                          En: context.bundleState.selectedInboundBundle?.UpgradeImgUrl,
                          Es: context.bundleState.selectedInboundBundle?.UpgradeImgUrl,
                          Pt: context.bundleState.selectedInboundBundle?.UpgradeImgUrl,
                      },
                      UpgradeImgMobileUrl: {
                          En: context.bundleState.selectedInboundBundle?.UpgradeImgMobileUrl,
                          Es: context.bundleState.selectedInboundBundle?.UpgradeImgMobileUrl,
                          Pt: context.bundleState.selectedInboundBundle?.UpgradeImgMobileUrl,
                      },
                  },
                  BundleType: context.bundleState.selectedInboundBundle?.BundleType,
                  CheckinBeforeTime: "",
                  DepartureStationCode: context.flightState.selectedInboundFlight.FlightSelectModel.OriginIata,
                  DepartureStationName: context.flightState.selectedInboundFlight.FlightSelectModel.OriginCity,
                  EncodedSellKey: "",
                  FlightNumber: context.flightState.selectedInboundFlight.FlightSelectModel.FlightNumber,
                  CheckinWindowOpenDateUtc: "",
                  IsFlown: false,
                  IsModificationPossible: false,
                  JourneyIndex: 0,
                  JourneyPassengers: [],
                  Segments: context.flightState.selectedInboundFlight.FlightSelectModel.Segments,
                  CheckinStatus: "NotAvailable",
              }
            : undefined;

    return { outboundJourney, inboundJourney };
};
