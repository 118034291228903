import { classMap } from "lit-html/directives/class-map";
import i18next from "i18next";
import { useEffect, useMemo, useState } from "../../shared/haunted/CustomHooks";
import { INBOUND, OUTBOUND } from "../../shared/commonConstants";
import { html } from "lit-html";
import { useRef } from "haunted";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import * as IsSameOrBefore from "dayjs/plugin/isSameOrBefore";
import * as IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(CustomParseFormat);
dayjs.extend(IsSameOrBefore);
dayjs.extend(IsSameOrAfter);
import { MaterialLabelElement, isMobile } from "../../shared/common";
import { FlightMovePageState } from "./flight-move-page";
import { StationSettings } from "../../component-models/StationSettings";
import { useBasicCheckbox } from "../ui/basic-checkbox/useBasicCheckbox";
import { TestIdDictionary } from "../../testing-helpers/TestIdHelper";
import { FlightOptionModel } from "../../component-models/flight/FlightOptionModel";
import { useFlightJourney } from "../flight-select/useFlightJourney";
import { useBundleSelectedPerLeg } from "../flight-select/bundles/useBundleSelectedPerLeg";
import { FlightPageContext } from "../../component-models/flight/contexts/FlightPageContext";
import { FlightSelectDTO } from "../flight-select/contexts/useFlightPageContext";

export const displayedCarouselDatesToTheLeft = isMobile() ? 1 : 3;
export const displayedCarouselDatesToTheRight = isMobile() ? 1 : 2;

export interface Props {
    bundleColor: string;
    bundleImg: string;
    context: FlightPageContext;
    disabledInboundDates: dayjs.Dayjs[];
    disabledOutboundDates: dayjs.Dayjs[];
    firstAvailableInboundDate: dayjs.Dayjs;
    firstAvailableOutboundDate: dayjs.Dayjs;
    isBrazilianStationSelected: boolean;
    isValidated: boolean;
    lastAvailableInboundDate: dayjs.Dayjs;
    lastAvailableOutboundDate: dayjs.Dayjs;
    pageHistory: FlightMovePageState[];
    root: HTMLFormElement;
    selectedInboundDate: dayjs.Dayjs;
    selectedJourneys: number[];
    selectedOutboundDate: dayjs.Dayjs;
    stationSettings: StationSettings;
    handleBundleSelect: () => void;
    handleBundleUpgrade: () => void;
    handleFlightSubmit: () => void;
    handleResetPerJourney: (
        journeyIndex: number,
        outboundWeekSelector: HTMLDivElement,
        inboundWeekSelector: HTMLDivElement,
    ) => void;
    handleJourneySelect: (
        flightOption: FlightOptionModel,
        outboundWeekSelector: HTMLDivElement,
        inboundWeekSelector: HTMLDivElement,
    ) => void;
    handleWeekdayClick: (day: dayjs.Dayjs, journeyIndex: number) => void;
    setCurrentInboundWeekday: (day: dayjs.Dayjs) => void;
    setCurrentOutboundWeekday: (day: dayjs.Dayjs) => void;
}

export const useFlightMoveFlightSelector = (props: Props) => {
    const checkbox = useRef<MaterialLabelElement>(undefined);
    const inboundWeekSelector = useRef<HTMLDivElement>(undefined);
    const outboundWeekSelector = useRef<HTMLDivElement>(undefined);

    const [acceptanceChecked, setAcceptanceChecked] = useState<boolean>(false);
    const [showAcceptanceError, setShowAcceptanceError] = useState<boolean>(false);

    const confirmCheckbox = useBasicCheckbox({
        customLabelClass: "cb-title",
        inputTestId: TestIdDictionary.FLIGHT_MOVE.FLIGHT_MOVE_TERMS_CHECKBOX,
        isChecked: acceptanceChecked,
        labelTestId: TestIdDictionary.FLIGHT_MOVE.FLIGHT_MOVE_TERMS_CHECKBOX_LABEL,
        labelText: i18next.t("Confirmo que estoy de acuerdo con mi nuevo itinerario."),
        onClick: () => handleCheckboxClick(),
    });

    const selectedOutboundBundle = useBundleSelectedPerLeg({
        context: props.context,
        isBrazilianStationSelected: props.isBrazilianStationSelected,
        isInFlightMove: true,
        journeyIndex: OUTBOUND,
        onReset: () => handleResetJourney(OUTBOUND),
    });

    const selectedInboundBundle = useBundleSelectedPerLeg({
        context: props.context,
        isBrazilianStationSelected: props.isBrazilianStationSelected,
        isInFlightMove: true,
        journeyIndex: INBOUND,
        onReset: () => handleResetJourney(INBOUND),
    });

    const outboundJourney = useFlightJourney({
        context: props.context,
        inboundDate: props.selectedInboundDate ? props.selectedInboundDate : undefined,
        isBrazilianStationSelected: props.isBrazilianStationSelected,
        isInFlightMove: true,
        isValidated: props.isValidated,
        journeyIndex: OUTBOUND,
        outboundDate: props.selectedOutboundDate,
        onSelect: (data: FlightSelectDTO) => handleFlightClick(data),
        onWeekdayClick: (dayDate: dayjs.Dayjs, journeyIndex: number) => handleWeekdayClick(dayDate, journeyIndex),
    });

    const inboundJourney = useFlightJourney({
        context: props.context,
        inboundDate: props.selectedInboundDate ? props.selectedInboundDate : undefined,
        isBrazilianStationSelected: props.isBrazilianStationSelected,
        isInFlightMove: true,
        isValidated: props.isValidated,
        journeyIndex: INBOUND,
        outboundDate: props.selectedOutboundDate,
        onSelect: (data: FlightSelectDTO) => handleFlightClick(data),
        onWeekdayClick: (dayDate: dayjs.Dayjs, journeyIndex: number) => handleWeekdayClick(dayDate, journeyIndex),
    });

    const isEnabled = useMemo(
        () => props.pageHistory[props.pageHistory.length - 1] === "FlightSelect",
        [props.pageHistory],
    );

    const showAllBundlesTemplate = () =>
        props.context.model &&
        props.context?.bundleState?.bundlesMode === "PerLeg" &&
        (props.context.flightState.selectedOutboundFlight || !props.selectedJourneys.includes(OUTBOUND)) &&
        (props.context.flightState.selectedInboundFlight || !props.selectedJourneys.includes(INBOUND));

    const handleCheckboxClick = () => {
        setAcceptanceChecked(!acceptanceChecked);
        setShowAcceptanceError(false);
    };

    const handleResetJourney = (journeyIndex: number) => {
        setAcceptanceChecked(false);
        setShowAcceptanceError(false);
        props.handleResetPerJourney(journeyIndex, outboundWeekSelector.current, inboundWeekSelector.current);
    };

    const handleFlightClick = async (data: FlightSelectDTO) => {
        // DEVNOTE Unused properties because reusing flight select page stuff
        setAcceptanceChecked(false);
        setShowAcceptanceError(false);
        props.handleJourneySelect(data.flightOption, outboundWeekSelector.current, inboundWeekSelector.current);
    };

    const showTermsTemplate = () =>
        (!props.selectedJourneys.includes(OUTBOUND) ||
            (props.context.flightState.selectedOutboundFlight && props.context?.bundleState.selectedOutboundBundle)) &&
        (!props.selectedJourneys.includes(INBOUND) ||
            (props.context.flightState.selectedInboundFlight && props.context?.bundleState.selectedInboundBundle));

    const handleSubmit = () => {
        if (acceptanceChecked || !showTermsTemplate()) {
            props.handleFlightSubmit();
            setShowAcceptanceError(false);
        } else {
            setShowAcceptanceError(true);
        }
    };

    const handleWeekdayClick = (dayDate: dayjs.Dayjs, journeyIndex: number) => {
        if (journeyIndex === OUTBOUND) {
            props.setCurrentOutboundWeekday(dayDate);
        } else {
            props.setCurrentInboundWeekday(dayDate);
        }

        props.handleWeekdayClick(dayDate, journeyIndex);
    };

    useEffect(() => {
        if (!isEnabled || !props.context.journeys) return;
        if (props.context.journeys.length > 1) {
            props.handleResetPerJourney(OUTBOUND, outboundWeekSelector.current, inboundWeekSelector.current);
            props.handleResetPerJourney(INBOUND, outboundWeekSelector.current, inboundWeekSelector.current);
        } else {
            props.handleResetPerJourney(
                props.context?.journeys[0]?.TripIndex || 0,
                outboundWeekSelector.current,
                inboundWeekSelector.current,
            );
        }
    }, [isEnabled, props.context.journeys.length]);

    useEffect(() => {
        if (acceptanceChecked) {
            checkbox.current?.MaterialCheckbox?.check();
        } else {
            checkbox.current?.MaterialCheckbox?.uncheck();
        }
    }, [acceptanceChecked]);

    const selectedAllBundlesTemplate = () => {
        return showAllBundlesTemplate()
            ? html`
                  <div class="mx-auto w-[1005px] max-w-[90%] sm:max-w-[95%]">
                      <div class="row">
                          ${props.context.model.Journeys?.map((journey) => {
                              return html`
                                  <div class="col-xs-1 col-sm-1-2">
                                      ${journey.TripIndex === OUTBOUND ? selectedOutboundBundle.htmlTemplate() : ""}
                                      ${journey.TripIndex === INBOUND ? selectedInboundBundle.htmlTemplate() : ""}
                                  </div>
                              `;
                          })}
                      </div>
                  </div>
              `
            : "";
    };

    const errorTemplate = () =>
        showAcceptanceError
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container elevated-error text-left">
                              <div class="form-error-message">${i18next.t("Please confirm the new itinerary")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const termsTemplate = () =>
        showTermsTemplate()
            ? html`
                  <div class="flight-move-terms-container">${confirmCheckbox.htmlTemplate()} ${errorTemplate()}</div>
              `
            : "";

    const buttonTemplate = () => {
        const isDisabled = props.selectedJourneys.length === 0 || !isEnabled || !acceptanceChecked;

        return html`
            <div class="flight-move-flight-selector-buttons">
                <button
                    class=${`rounded-primary-btn ${isDisabled ? "disabled" : ""}`}
                    data-test-id="flight-move-flight-submit"
                    @click=${handleSubmit}
                >
                    ${i18next.t("Realizar cambio")}
                </button>
            </div>
        `;
    };

    const contentTemplate = () =>
        props.context.model
            ? html`
                  ${outboundJourney.htmlTemplate()} ${inboundJourney.htmlTemplate()} ${selectedAllBundlesTemplate()}
                  ${termsTemplate()}
              `
            : "";

    const mainClassMap = classMap({
        "booking-wrapper": true,
        "flight-move-page": true,
        "active": isEnabled,
        "inactive": !isEnabled,
    });

    const htmlTemplate = () => html`
        <section class=${mainClassMap}>
            <header class="b2-section-header">
                <div class="flex items-center">
                    <span class="b2-section-number">3</span>
                    <div>
                        <h3>${i18next.t("Selección de nuevo itinerario")}</h3>
                    </div>
                </div>
            </header>
            <div class="flight-move-flight-selector">${contentTemplate()}</div>
        </section>
        ${buttonTemplate()}
    `;

    return { htmlTemplate };
};
