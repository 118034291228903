import { FIRST_BAGGAGE_PRICE_FOR_STAFF_USERS, OUTBOUND } from "../../shared/commonConstants";
import { TealiumLog, useTealiumManager } from "./useTealiumManager";
import { BagType } from "../../components/spa/baggage/useBaggagePage";
import { StaffBaggage } from "../../components/spa/baggage/common/useStaffBaggage";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../shared/useNumberFormatter";
import { useBookingContext } from "../useBookingContext";
import { BaggageSectionJourney } from "../../component-models/baggage/BaggageSectionJourney";
import { BaggageSectionStateJourneyPassenger } from "../../component-models/baggage/BaggageSectionStateJourneyPassenger";
import { BaggageSectionUiState } from "../../component-models/baggage/BaggageSectionUi";
import { BancoEstadoBaggage } from "../../components/spa/baggage/common/useBancoEstadoBaggage";

export interface Props {
    baggageSectionJourneys: BaggageSectionJourney[];
    bagType: BagType;
    bancoEstadoBaggage: BancoEstadoBaggage;
    staffBaggage: StaffBaggage;
    state: BaggageSectionUiState;
}

export const useBaggageTealiumManager = (props: Props) => {
    const bookingContext = useBookingContext();

    const tealiumManager = useTealiumManager();

    const { formatNumber } = useNumberFormatter();

    const [currency] = useReduxState("booking.currency");

    const logBaggageReset = (data: { journeyIndices: number[]; paxIndices: number[] }) => {
        data.journeyIndices.forEach((journeyIndex) => {
            data.paxIndices.forEach((passengerIndex) => {
                const contextJourneyPassengerBaggage = props.baggageSectionJourneys
                    .find((j) => j.index === journeyIndex)
                    .passengers.find((p) => p.index === passengerIndex);

                const commonObject = commonTealiumLogObject({
                    bagType: props.bagType,
                    baggageSectionJourneys: props.baggageSectionJourneys,
                    journeyIndex,
                    paxIndex: passengerIndex,
                });
                void tealiumManager.log({
                    ...commonObject,
                    EventParams: {
                        ...commonObject.EventParams,
                        quantity: contextJourneyPassengerBaggage.min,
                        price: 0,
                    },
                });
            });
        });
    };

    const getBagPrice = (data: {
        journeyIndex: number;
        passenger: BaggageSectionStateJourneyPassenger;
        passengerIndex: number;
        type: "add" | "remove";
    }) => {
        const canGetDiscount = props.staffBaggage.canGetFirstBaggageStaffDiscount({
            journeyIndex: data.journeyIndex,
            paxIndex: data.passengerIndex,
            bagType: props.bagType,
        });

        const canGetFreeBaggage =
            data.passengerIndex === 0 &&
            props.bancoEstadoBaggage.canGetFirstBaggageBancoEstadoDiscount({
                bagType: props.bagType,
                journeyIndex: data.journeyIndex,
            });

        const contextJourneyPassengerBaggage = props.baggageSectionJourneys
            .find((j) => j.index === data.journeyIndex)
            .passengers.find((p) => p.index === data.passengerIndex);

        // DEVNOTE Staff members get the first bag for $1
        const getRelatedStaffBaggage = props.staffBaggage.state.filter(
            (b) => b.passengerNumber === data.passengerIndex && b.journeyIndex === data.journeyIndex,
        );

        // DEVNOTE BancoEstado members get the first bag for $0
        const getRelatedBancoEstadoBaggage = props.bancoEstadoBaggage.state.filter(
            (b) => b.journeyIndex === data.journeyIndex,
        );

        return contextJourneyPassengerBaggage.prices.reduce((aggr, curr, i) => {
            if (
                i === 0 &&
                (canGetDiscount ||
                    getRelatedStaffBaggage.some(
                        (b) =>
                            (props.bagType === "cabinBaggage" && b.usedBaggageType === "cabin") ||
                            (props.bagType === "checkedBaggage" && b.usedBaggageType === "checked"),
                    ))
            ) {
                return aggr + FIRST_BAGGAGE_PRICE_FOR_STAFF_USERS;
            }

            if (
                i === 0 &&
                (canGetFreeBaggage ||
                    getRelatedBancoEstadoBaggage.some(
                        (b) =>
                            (props.bagType === "cabinBaggage" && b.usedBaggageType === "cabin") ||
                            (props.bagType === "checkedBaggage" && b.usedBaggageType === "checked"),
                    ))
            ) {
                return aggr;
            }

            const contextJourneyPassengerBaggage = props.baggageSectionJourneys
                .find((j) => j.index === data.journeyIndex)
                .passengers.find((p) => p.index === data.passengerIndex);

            const quantity = contextJourneyPassengerBaggage.quantity;

            return i < quantity + (data.type === "add" ? 1 : -1) ? aggr + curr : aggr;
        }, 0);
    };

    const logBaggageAdd = (data: {
        bagType: BagType;
        baggageSectionJourneys: BaggageSectionJourney[];
        journeyIndices: number[];
        paxIndices: number[];
    }) => {
        data.journeyIndices.forEach((journeyIndex) => {
            data.paxIndices.forEach((passengerIndex) => {
                const passenger = props.state.journeys[journeyIndex].passengers[passengerIndex];
                const commonObject = commonTealiumLogObject({
                    ...data,
                    journeyIndex,
                    paxIndex: passengerIndex,
                });

                const amount = getBagPrice({
                    ...data,
                    journeyIndex,
                    passenger,
                    passengerIndex,
                    type: "add",
                });

                const contextJourneyPassengerBaggage = props.baggageSectionJourneys
                    .find((j) => j.index === journeyIndex)
                    .passengers.find((p) => p.index === passengerIndex);

                const quantity = contextJourneyPassengerBaggage.quantity;

                void tealiumManager.log({
                    ...commonObject,
                    EventParams: {
                        ...commonObject.EventParams,
                        quantity: quantity + 1,
                        price: formatNumber({ amount, leadingSign: false, omitGrouping: true }),
                    },
                });
            });
        });
    };

    const logBaggageRemove = (data: { journeyIndices: number[]; paxIndices: number[] }) => {
        data.journeyIndices.forEach((journeyIndex) => {
            data.paxIndices.forEach((passengerIndex) => {
                const passenger = props.state.journeys[journeyIndex].passengers[passengerIndex];
                const commonObject = commonTealiumLogObject({
                    bagType: props.bagType,
                    baggageSectionJourneys: props.baggageSectionJourneys,
                    journeyIndex,
                    paxIndex: passengerIndex,
                });

                const amount = getBagPrice({
                    journeyIndex,
                    passenger,
                    passengerIndex,
                    type: "remove",
                });

                const contextJourneyPassengerBaggage = props.baggageSectionJourneys
                    .find((j) => j.index === journeyIndex)
                    .passengers.find((p) => p.index === passengerIndex);

                const quantity = contextJourneyPassengerBaggage.quantity;

                void tealiumManager.log({
                    ...commonObject,
                    EventParams: {
                        ...commonObject.EventParams,
                        quantity: quantity - 1,
                        price: formatNumber({ amount, leadingSign: false, omitGrouping: true }),
                    },
                });
            });
        });
    };

    const commonTealiumLogObject = (data: {
        bagType: BagType;
        baggageSectionJourneys: BaggageSectionJourney[];
        journeyIndex: number;
        paxIndex: number;
    }): TealiumLog => {
        return {
            EventName: "baggage_selected",
            EventParams: {
                currency,
                direction: data.journeyIndex === OUTBOUND ? "O" : "I",
                paxindex: data.paxIndex,
                paxtype: data.paxIndex < bookingContext.adultsCount ? "A" : "C",
                previous_quantity: props.baggageSectionJourneys[data.journeyIndex].passengers[data.paxIndex].quantity,
                type: props.bagType === "cabinBaggage" ? "C" : props.bagType === "checkedBaggage" ? "B" : "S",
            },
            UpdateCommonUdo: true,
        };
    };

    return {
        logBaggageAdd,
        logBaggageRemove,
        logBaggageReset,
        logContinueClicked: tealiumManager.logContinueClicked,
    };
};
