import i18next from "i18next";
import { TemplateResult, html } from "lit-html";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { useAppContext } from "../../managers/useAppContext";
import { COLOMBIAN_CULTURE_CODE } from "../../shared/commonConstants";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { useBasicCheckbox } from "../ui/basic-checkbox/useBasicCheckbox";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useReduxState } from "../../shared/redux/useReduxState";
import classNames from "classnames";
import { useEffect as hauntedUseEffect } from "haunted";
import { unsafeHTML } from "lit-html/directives/unsafe-html";

const DATA_HANDLE_TERMS_LINK = "https://jetsmart.com/co/es/tyc/datos-personales/";
const DATA_AMERICAN_AIRLINES_TERMS_LINK =
    "https://www.aa.com/i18n/aadvantage-program/aadvantage-terms-and-conditions.jsp";
const DATA_AMERICAN_AIRLINES_PRIVACY_POLICY_LINK =
    "https://www.aa.com/i18n/customer-service/support/privacy-policy.jsp";

export interface Props {
    anyPaxWithAcaa: boolean;
    forcePreventTermsValidation: boolean;
    isValidated: boolean;
    model: PaymentPageViewModel;
    wompiTermsUrl?: string;
}

export interface TermsAcceptance {
    isValid: boolean;
    htmlTemplate: () => TemplateResult | string;
}

export const useTermsAcceptance = (props: Props): TermsAcceptance => {
    const appContext = useAppContext();

    const { triggers } = usePubSub();

    const [userContext] = useReduxState("userContext");
    const [selectedMethod] = useReduxState("payment.paymentMethod");

    const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);

    const init = () => {
        const handler = triggers.payment.removeTermsAcceptance.subscribe(() => setIsTermsAccepted(false));
        return () => handler.unsubscribe();
    };

    const showCheckboxWarning = () =>
        props.isValidated &&
        props.model.IsTermsAcceptanceNeeded &&
        !props.forcePreventTermsValidation &&
        !isTermsAccepted;

    const handleOpenTermsLinkClick = (e: MouseEvent, url: string) => {
        e.preventDefault();
        e.stopPropagation();

        window.open(url, "_blank");
    };

    const termsLabelTemplate = (id: string) => html`
        <label for=${id} data-test-id=${T.PAYMENT.TERMS_CHECKBOX_LABEL}>
            ${cug2TermsAcceptanceTemplate()} ${nonCug2TermsAcceptanceTemplate()}
        </label>
    `;

    useEffect(() => setIsTermsAccepted(false), [selectedMethod?.PaymentMethodCode]);

    hauntedUseEffect(init, []);

    const wompiTermsAcceptanceTemplate = () =>
        props.wompiTermsUrl
            ? html`
                  <a target="_blank" @click=${(e: MouseEvent) => handleOpenTermsLinkClick(e, props.wompiTermsUrl)}>
                      ${i18next.t("Condiciones Wompi")}
                  </a>
                  ${i18next.t("y")}
              `
            : "";

    const colombiaDataTermsAcceptanceTemplate = () =>
        appContext.Culture === COLOMBIAN_CULTURE_CODE
            ? html`
                  <a target="_blank" @click=${(e: MouseEvent) => handleOpenTermsLinkClick(e, DATA_HANDLE_TERMS_LINK)}>
                      ${i18next.t("tratamiento de datos personales")}
                  </a>
              `
            : "";

    const americanAirlinesTermsAcceptanceTemplate = () =>
        props.anyPaxWithAcaa
            ? html`
                  <a
                      target="_blank"
                      @click=${(e: MouseEvent) => handleOpenTermsLinkClick(e, DATA_AMERICAN_AIRLINES_TERMS_LINK)}
                  >
                      ${unsafeHTML(
                          i18next.t("Términos y Condiciones programa AAdvantage{{-reg}}", {
                              reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
                          }),
                      )} </a
                  >,&nbsp;
              `
            : "";

    const americanAirlinesPrivacyPolicyTemplate = () =>
        props.anyPaxWithAcaa
            ? html`
                  <a
                      target="_blank"
                      @click=${(e: MouseEvent) =>
                          handleOpenTermsLinkClick(e, DATA_AMERICAN_AIRLINES_PRIVACY_POLICY_LINK)}
                  >
                      ${i18next.t("Política de Privacidad American Airlines")} </a
                  >,&nbsp;
              `
            : "";

    const nonCug2AdditionalTermsAcceptanceTemplate = () =>
        props.wompiTermsUrl || appContext.Culture === COLOMBIAN_CULTURE_CODE
            ? html`, ${wompiTermsAcceptanceTemplate()} ${colombiaDataTermsAcceptanceTemplate()},`
            : "";

    const teenTermsAcceptanceTemplate = () =>
        !props.model.BookingViewModel.IsFlightlessPnr
            ? props.anyPaxWithAcaa
                ? i18next.t("americanAirlinesPaymentAcceptTermsTeens")
                : i18next.t("PaymentAcceptTermsTeens")
            : "";

    const nonCug2TermsAcceptanceTemplate = () =>
        !userContext.cug.isMember
            ? html`
                  <span class="cb-title">
                      ${i18next.t("Acepto los")}
                      <a target="_blank" @click=${(e: MouseEvent) => handleOpenTermsLinkClick(e, props.model.TermsUrl)}>
                          ${props.anyPaxWithAcaa
                              ? i18next.t("Términos y Condiciones JetSMART Airlines")
                              : i18next.t("Términos y Condiciones")} </a
                      >,&nbsp;
                      <a
                          target="_blank"
                          @click=${(e: MouseEvent) => handleOpenTermsLinkClick(e, props.model.PrivacyUrl)}
                      >
                          ${props.anyPaxWithAcaa
                              ? i18next.t("Política de Privacidad JetSMART Airlines")
                              : i18next.t("Política de Privacidad")} </a
                      >,&nbsp;
                      ${americanAirlinesTermsAcceptanceTemplate()}${americanAirlinesPrivacyPolicyTemplate()}${nonCug2AdditionalTermsAcceptanceTemplate()}
                      ${i18next.t(
                          "y declaro que soy mayor de edad o cuento con el consentimiento expreso de quién tiene capacidad legal para representarme.",
                      )}
                      ${teenTermsAcceptanceTemplate()}
                  </span>
              `
            : "";

    const cug2AdditionalTermsAcceptanceTemplate = () =>
        props.wompiTermsUrl || appContext.Culture === COLOMBIAN_CULTURE_CODE
            ? html`, ${wompiTermsAcceptanceTemplate()} ${colombiaDataTermsAcceptanceTemplate()}. `
            : html`.`;

    const cug2TermsAcceptanceTemplate = () =>
        userContext.cug.isMember
            ? html`
                  <span class="cb-title">
                      ${i18next.t("Acepto los")}
                      <a target="_blank" @click=${(e: MouseEvent) => handleOpenTermsLinkClick(e, props.model.TermsUrl)}>
                          ${i18next.t("Términos y Condiciones Generales")} </a
                      >,&nbsp;
                      <a
                          target="_blank"
                          @click=${(e: MouseEvent) =>
                              handleOpenTermsLinkClick(e, props.model.AgencyViewModel.CugTermsUrl)}
                      >
                          ${i18next.t("Términos y Condiciones de Empresas y Agencias")}
                      </a>
                      ,&nbsp; ${americanAirlinesTermsAcceptanceTemplate()}${americanAirlinesPrivacyPolicyTemplate()}
                      <a
                          target="_blank"
                          @click=${(e: MouseEvent) => handleOpenTermsLinkClick(e, props.model.PrivacyUrl)}
                      >
                          ${i18next.t("Política de Privacidad")}
                      </a>
                      ${cug2AdditionalTermsAcceptanceTemplate()} ${teenTermsAcceptanceTemplate()}
                  </span>
              `
            : "";

    const termsAcceptanceWarningTemplate = () =>
        showCheckboxWarning()
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container elevated-error text-left">
                              <div class="form-error-message">${i18next.t("Breakdown-AcceptTermsFirst")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const termsAcceptanceCheckbox = useBasicCheckbox({
        isChecked: isTermsAccepted,
        customWrapperClass: "payment-terms-accept-checkbox",
        labelTemplate: termsLabelTemplate,
        onClick: () => setIsTermsAccepted(!isTermsAccepted),
    });

    const mainClassMap = classNames("payment-checkbox-terms-wrapper payment terms", {
        "payment-wider": userContext.cug.isMember,
        "limited": props.model?.BookingViewModel.IsFundingCug,
    });

    const htmlTemplate = () =>
        props.model?.IsTermsAcceptanceNeeded
            ? html`
                  <div class="terms-container payment-footer-grid-terms">
                      <div class=${mainClassMap}>${termsAcceptanceCheckbox.htmlTemplate()}</div>
                      ${termsAcceptanceWarningTemplate()}
                  </div>
              `
            : "";
    return {
        isValid: !props.model?.IsTermsAcceptanceNeeded || isTermsAccepted,
        htmlTemplate,
    };
};
