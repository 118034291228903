import { useMemo, useState } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "haunted";
import { CreditShellEvent } from "../../component-models/ApiCreditHistoryViewModel";
import { FAKE_NAVITAIRE_DATE_CHUNK } from "../../shared/commonConstants";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { PaymentMode } from "../../component-models/payment/PaymentMode";
import classNames from "classnames";
import { useNumberFormatter } from "../../shared/useNumberFormatter";

export interface Props {
    model: PaymentPageViewModel;
    paymentMode: PaymentMode;
    handlePaymentIntent: () => Promise<boolean>;
}

export const useCreditShellCat56 = (props: Props) => {
    const { formatNumber } = useNumberFormatter();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const isDisabled = useMemo(
        () =>
            !props.model ||
            Number(props.model.CreditHistoryViewModel.AvailableAmount) === 0 ||
            props.paymentMode === "cat56CreditCoversBalance" ||
            props.model.CreditShellViewModel.Cat56CreditShellIsUsed ||
            props.model.CreditShellViewModel.AutoCreditFileAmount > 0,
        [props],
    );

    const handleClick = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        await props.handlePaymentIntent();
    };

    const remainingCreditTemplate = () =>
        props.model.CreditHistoryViewModel.HasRemainingCredit &&
        (props.model.CreditShellViewModel.Cat56CreditShellIsUsed || props.paymentMode === "cat56CreditCoversBalance")
            ? html`
                  <div class="credit-shell-remainder">
                      ${i18next.t("¡Te quedan disponible")}
                      <span>${props.model.CreditHistoryViewModel.CreditRemainderFormatted}</span>
                      ${i18next.t("de tus Beneficios BancoEstado para tus próximas compras!")}
                  </div>
              `
            : "";

    const expirationTemplate = (item: CreditShellEvent) =>
        item.Expiry.indexOf(FAKE_NAVITAIRE_DATE_CHUNK) === -1
            ? html` <div class="bcsdl-body">${i18next.t("Válido hasta")} ${item.Expiry}</div> `
            : "";

    const creditShellHistoryItemTemplate = (item: CreditShellEvent) => html`
        <li class="flex flex-col items-start px-2 py-2 text-be-blue sm:flex-row sm:items-center md:px-4 md:py-4">
            <div class="flex items-center sm:w-1/2">
                <i class="js-icon-be2 js-be2-circle-star"></i>
                <div>
                    <div class="bcsdl-title">${item.Description}</div>
                    ${expirationTemplate(item)}
                </div>
            </div>
            <div class="flex w-full items-center justify-center sm:w-1/4">
                <div class="bcsdl-amount">${item.AvailableAmount}</div>
            </div>
        </li>
    `;

    const buttonTemplate = () =>
        isDisabled
            ? html` <button class="bcs-used">${i18next.t("Utilizado")}</button> `
            : html`
                  <button class="bcs-use" @click=${handleClick}>${i18next.t("Canjear")}</button>
                  <a class="bcs-details hidden-xs" @click=${() => setIsOpen(!isOpen)}>${i18next.t("Ver detalle")}</a>
              `;

    const openerTemplate = () =>
        !isDisabled
            ? html`
                  <a class=${classNames("bcs-details visible-xs", { open: isOpen })} @click=${() => setIsOpen(!isOpen)}>
                      <i class="js-icon js-circle-chevron-right"></i>
                  </a>
              `
            : "";

    const headerTemplate = () => {
        const amount = isDisabled
            ? props.model.CreditHistoryViewModel.UsedCreditFileAmountFormatted
            : props.model.CreditHistoryViewModel.AvailableAmountFormatted;

        return html`
            <div class="bcs-header">
                <div class="bcs-logo-caption">
                    <img src="/Images/BancoEstado/be-logo-w.svg" />
                    ${i18next.t("Beneficios BancoEstado")}
                </div>
                ${openerTemplate()}
                <div class="bcs-total-container">
                    <span class="bcs-total">${i18next.t("Total")}</span>
                    <span class="bcs-total-amount">${amount}</span>
                </div>
                <div class="bcs-button-container">${buttonTemplate()}</div>
            </div>
        `;
    };

    const autoCreditWarningTemplate = () =>
        props.model.CreditShellViewModel.AutoCreditFileAmount > 0
            ? html`
                  <div class="auto-cf-warning">
                      <i class="js-icon-be2 js-be2-circle-tick"></i>
                      <div>
                          ${i18next.t("¡Hemos aplicado en esta reserva, tu")}
                          <span class="font-black">
                              ${i18next.t("Beneficio BancoEstado de {{amount}}", {
                                  amount: `$${formatNumber({
                                      amount: props.model.CreditHistoryViewModel.AvailableAmount,
                                      leadingSign: false,
                                  })}`,
                              })}
                          </span>
                          ${i18next.t("y se ha descontado automáticamente del total a pagar!")}
                      </div>
                  </div>
              `
            : "";

    const creditItemListTemplate = () => html`
        <ul class="bcs-detail-list">
            ${props.model.CreditHistoryViewModel.AccountCredits.map(creditShellHistoryItemTemplate)}
        </ul>
    `;

    const htmlTemplate = () => html`
        <div class="inner-deep-box">
            <div class=${classNames("be-credit-shell", { open: isOpen })}>
                ${headerTemplate()} ${autoCreditWarningTemplate()} ${creditItemListTemplate()}
            </div>
            ${remainingCreditTemplate()}
        </div>
    `;

    return { htmlTemplate };
};
