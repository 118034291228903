import i18next from "i18next";
import { FlowType } from "../../../managers/useFlowContext";
import {
    COLOMBIAN_CULTURE_CODE,
    COLOMBIA_COUNTRY_CODE,
    ECUADORIAN_CULTURE_CODE,
    PERUVIAN_CULTURE_CODE,
    PERU_COUNTRY_CODE,
} from "../../../shared/commonConstants";
import { validateDni, validateRut } from "../../../shared/form-validation";
import { Validation } from "../../../validator/Validation";
import { frequentFlyerNumberValidator } from "../../../validator/frequentFlyerNumberValidator";
import { sanitizeInputFieldValue } from "../../../component-helpers/InputSanitizerHelper";
import { sanitizeRutFieldValue } from "../../../shared/common";
import { TravelDocumentType } from "../../../component-models/checkin/TravelDocument";
import { html, TemplateResult } from "lit-html";
import { frequentFlyerHelper } from "../../../component-helpers/frequentFlyerHelper";
import { Dayjs } from "dayjs";
import { PassengerFormVM } from "../../../component-models/passengers/PassengerFormVM";
import { useAppContext } from "../../../managers/useAppContext";
import { unsafeHTML } from "lit-html/directives/unsafe-html";

export type FieldGroupId =
    | "document"
    | "phone"
    | "officePhone"
    | "contactPhone"
    | "contactOfficePhone"
    | "chileCompraSupervisor";

export interface PassengerField {
    groupId?: FieldGroupId;
    isContactField?: boolean;
    index: number;
    key: keyof PassengerFormVM;
    label: string | TemplateResult;
    type: "text" | "select" | "date";
    validators: Validation<keyof PassengerFormVM, PassengerFormVM>[];
    isReadonly: (model: PassengerFormVM) => boolean;
    isShown: (model: PassengerFormVM) => boolean;
    sanitizer?: (e: KeyboardEvent, docType?: TravelDocumentType) => string;
    tooltip?: () => TemplateResult;
}

// DEVNOTE: JET-10182, I had to remove the colombian post booking restrictions
export const passengerFormFactory = () => {
    const appContext = useAppContext();

    const getPassengerFormFields = (props: {
        culture: string;
        flowType: FlowType;
        hasColombianStations: boolean;
        isDc: boolean;
        isInternationalFlight: boolean;
        isPeruCompra: boolean;
        isChileCompra: boolean;
        isStaff: boolean;
        passengerIndicesWithAcaa: number[];
        needsDocumentsForInsurance: boolean;
    }): PassengerField[] => {
        const isPeru = props.culture === PERUVIAN_CULTURE_CODE;
        const isEcuador = props.culture === ECUADORIAN_CULTURE_CODE;

        const { validateFrequentFlyerNumber } = frequentFlyerNumberValidator();
        const { frequentFlyerNumberTooltip } = frequentFlyerHelper();

        const forPeruvianAdults = (vm: PassengerFormVM) => isPeru && vm.type === "ADT";
        const forEcuadorianAdultsAndChildren = (vm: PassengerFormVM) => isEcuador && vm.type !== "INF";
        const forPeruCompraAdultsAndChildren = (vm: PassengerFormVM) => props.isPeruCompra && vm.type !== "INF";
        const forColombianStationAdults = (vm: PassengerFormVM) => props.hasColombianStations && vm.type === "ADT";
        const isNameReadonly = (vm: PassengerFormVM) => props.isStaff || vm.isNameReadonly;
        const isDocumentReadonly = (vm: PassengerFormVM) =>
            vm.isDocumentReadonly || (props.isStaff && vm.uniqueIndex !== 0);
        const isFarelockDummy = (vm: PassengerFormVM) => vm.uniqueIndex > 0 && props.flowType === "FarelockRoundOne";
        const isStaff = () => props.isStaff;

        const ruleMap = new Map<(keyof PassengerFormVM)[], (vm: PassengerFormVM) => boolean>([
            [["addressAddressLine1"], (vm) => !isFarelockDummy(vm) && forColombianStationAdults(vm)],
            [["firstName", "lastName"], (vm) => !isFarelockDummy(vm)],
            [
                ["dateOfBirth"],
                (vm) =>
                    !isFarelockDummy(vm) &&
                    (props.hasColombianStations || props.isInternationalFlight || vm.type !== "ADT" || props.isStaff),
            ],
            [
                ["documentType", "documentNumber"],
                (vm) =>
                    !isFarelockDummy(vm) &&
                    (isStaff() ||
                        props.isChileCompra ||
                        props.needsDocumentsForInsurance ||
                        props.hasColombianStations ||
                        forPeruCompraAdultsAndChildren(vm) ||
                        forEcuadorianAdultsAndChildren(vm) ||
                        forPeruvianAdults(vm)),
            ],
            [
                ["email", "phonePrefix", "phoneNumber"],
                (vm) =>
                    (!isFarelockDummy(vm) && forColombianStationAdults(vm)) ||
                    forPeruCompraAdultsAndChildren(vm) ||
                    (props.isChileCompra && vm.type === "ADT"),
            ],
            [
                ["country"],
                (vm) => (!isFarelockDummy(vm) && forColombianStationAdults(vm)) || forPeruCompraAdultsAndChildren(vm),
            ],
            [
                ["frequentFlyerNumber"],
                (vm) =>
                    !props.isChileCompra &&
                    !isFarelockDummy(vm) &&
                    props.passengerIndicesWithAcaa.includes(vm.uniqueIndex) &&
                    appContext.isFeatureSwitchActive("MileageAccrual") &&
                    vm.type !== "INF",
            ],
            [
                [
                    "contactEmail",
                    "contactFirstName",
                    "contactLastName",
                    "contactOfficePhoneNumber",
                    "contactOfficePhonePrefix",
                    "contactPhoneNumber",
                    "contactPhonePrefix",
                    "officePhoneNumber",
                    "officePhonePrefix",
                ],
                (vm) => !isFarelockDummy(vm) && forPeruCompraAdultsAndChildren(vm),
            ],
            [["chileCompraPaxType"], () => props.isChileCompra],
            [["chileCompraReason"], (vm) => props.isChileCompra && vm.uniqueIndex === 0],
        ]);

        const getRule = (key: keyof PassengerFormVM) =>
            Array.from(ruleMap.entries()).find(([keys]) => keys.includes(key))?.[1];

        return [
            {
                index: 0,
                key: "firstName",
                label: i18next.t("Nombres"),
                type: "text",
                validators: [
                    Validation.ruleFor("firstName", (vm: PassengerFormVM) => vm.firstName)
                        .when(getRule("firstName"))
                        .isRequired()
                        .max(32),
                ],
                isReadonly: isNameReadonly,
                isShown: getRule("firstName"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars"),
            },
            {
                index: 1,
                key: "lastName",
                label: i18next.t("Apellidos"),
                type: "text",
                validators: [
                    Validation.ruleFor("lastName", (vm: PassengerFormVM) => vm.lastName)
                        .when(getRule("lastName"))
                        .isRequired()
                        .max(32),
                ],
                isReadonly: isNameReadonly,
                isShown: getRule("lastName"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars"),
            },
            {
                index: 2,
                key: "dateOfBirth",
                label: i18next.t("Fecha de nacimiento"),
                type: "date",
                validators: [
                    Validation.ruleFor("dateOfBirth", (vm: PassengerFormVM) => vm.dateOfBirth)
                        .when(getRule("dateOfBirth"))
                        .isRequired(),
                ],
                isReadonly: (vm) => vm.isPassengerCheckedIn,
                isShown: getRule("dateOfBirth"),
            },
            {
                groupId: "document",
                index: 3,
                key: "documentType",
                label: i18next.t("Tipo de documento"),
                type: "select",
                validators: [
                    Validation.ruleFor("documentType", (vm: PassengerFormVM) => vm.documentType)
                        .when(getRule("documentType"))
                        .isRequired(),
                ],
                isReadonly: isDocumentReadonly,
                isShown: getRule("documentType"),
            },
            {
                groupId: "document",
                index: 4,
                key: "documentNumber",
                label: i18next.t("Número de documento"),
                type: "text",
                validators: [
                    Validation.ruleFor("documentNumber", (vm: PassengerFormVM) => vm.documentNumber)
                        .when(getRule("documentNumber"))
                        .isRequired(),
                    Validation.ruleFor("documentNumber", (vm: PassengerFormVM) => vm.documentNumber)
                        .when(() => props.isChileCompra)
                        .max(15),
                    Validation.ruleFor("documentNumber", (vm: PassengerFormVM) => vm.documentNumber)
                        .when(
                            (vm: PassengerFormVM) =>
                                !isFarelockDummy(vm) && vm.documentType === "N" && getRule("documentNumber")(vm),
                        )
                        .fulfils(
                            (documentNumber: string) => Promise.resolve(validateRut(documentNumber)),
                            i18next.t(
                                "Rut inválido, por favor verifica que el rut es correcto y que el formato sea válido. (Ej: 76472472-0)",
                            ),
                        ),
                    Validation.ruleFor("documentNumber", (vm: PassengerFormVM) => vm.documentNumber)
                        .when((vm: PassengerFormVM) => getRule("documentNumber")(vm) && vm.documentType === "DNI")
                        .fulfils(
                            (documentNumber: string) =>
                                Promise.resolve(
                                    documentNumber.length === 0 || validateDni(documentNumber, props.culture),
                                ),
                            i18next.t("DNI invalido"),
                            "field",
                            true,
                        ),
                ],
                sanitizer: (e: KeyboardEvent, docType: TravelDocumentType) => {
                    if (docType === "DNI" && props.culture === COLOMBIAN_CULTURE_CODE) {
                        return sanitizeInputFieldValue(e, "numeric").substring(0, 11);
                    }
                    if (docType === "DNI") return sanitizeInputFieldValue(e, "numeric");
                    if (docType === "P") return sanitizeInputFieldValue(e, "alphanumeric");
                    return sanitizeRutFieldValue(e.target as HTMLInputElement);
                },
                isReadonly: isDocumentReadonly,
                isShown: getRule("documentNumber"),
            },
            {
                index: 5,
                key: "country",
                label: i18next.t("País de residencia"),
                type: "select",
                validators: [
                    Validation.ruleFor("country", (vm: PassengerFormVM) => vm.country)
                        .when(getRule("country"))
                        .isRequired(),
                ],
                isReadonly: (vm) => vm.isPassengerCheckedIn,
                isShown: getRule("country"),
            },
            {
                index: 6,
                key: "addressAddressLine1",
                label: i18next.t("Dirección"),
                type: "text",
                validators: [
                    Validation.ruleFor("addressAddressLine1", (vm: PassengerFormVM) => vm.addressAddressLine1)
                        .when(getRule("addressAddressLine1"))
                        .isRequired()
                        .max(52),
                ],
                isReadonly: (vm) => vm.isPassengerCheckedIn,
                isShown: getRule("addressAddressLine1"),
            },
            {
                index: 7,
                key: "email",
                label: i18next.t("Correo electrónico"),
                type: "text",
                validators: [
                    Validation.ruleFor("email", (vm: PassengerFormVM) => vm.email)
                        .when(getRule("email"))
                        .isRequired()
                        .max(52)
                        .isEmail(),
                ],
                isReadonly: (vm) => vm.isPassengerCheckedIn,
                isShown: getRule("email"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "e-mail"),
            },
            {
                groupId: "phone",
                index: 8,
                key: "phonePrefix",
                label: i18next.t("Código país"),
                type: "select",
                validators: [
                    Validation.ruleFor("phonePrefix", (vm: PassengerFormVM) => vm.phonePrefix)
                        .when(getRule("phonePrefix"))
                        .isRequired(),
                ],
                isReadonly: (vm) => vm.isPassengerCheckedIn,
                isShown: getRule("phonePrefix"),
            },
            {
                index: 9,
                groupId: "phone",
                key: "phoneNumber",
                type: "text",
                label: props.isPeruCompra ? i18next.t("Mobile Phone") : i18next.t("Teléfono"),
                validators: [
                    Validation.ruleFor("phoneNumber", (vm: PassengerFormVM) => vm.phoneNumber)
                        .when(getRule("phoneNumber"))
                        .isRequired()
                        .max(12),
                ],
                isReadonly: (vm) => vm.isPassengerCheckedIn,
                isShown: getRule("phoneNumber"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "numeric"),
            },
            {
                groupId: "officePhone",
                index: 10,
                key: "officePhonePrefix",
                label: i18next.t("Código país"),
                type: "select",
                validators: [
                    Validation.ruleFor("officePhoneNumber", (vm: PassengerFormVM) => vm.officePhoneNumber)
                        .when(getRule("officePhonePrefix"))
                        .isRequired(),
                ],
                isReadonly: (vm) => vm.isPassengerCheckedIn,
                isShown: getRule("officePhonePrefix"),
            },
            {
                groupId: "officePhone",
                index: 11,
                key: "officePhoneNumber",
                label: i18next.t("Office Phone"),
                type: "text",
                validators: [
                    Validation.ruleFor("officePhoneNumber", (vm: PassengerFormVM) => vm.officePhoneNumber)
                        .when(getRule("officePhoneNumber"))
                        .isRequired()
                        .max(12),
                ],
                isReadonly: (vm) => vm.isPassengerCheckedIn,
                isShown: getRule("officePhoneNumber"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "numeric"),
            },
            {
                index: 12,
                key: "frequentFlyerNumber",
                label: html`${unsafeHTML(
                    i18next.t("Número AAdvantage {{-reg}} (Opcional)", {
                        reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
                    }),
                )}`,
                type: "text",
                validators: [
                    Validation.ruleFor("frequentFlyerNumber", (vm: PassengerFormVM) => vm.frequentFlyerNumber).fulfils(
                        (frequentFlyerNumber: string) =>
                            Promise.resolve(
                                !frequentFlyerNumber?.trim() || validateFrequentFlyerNumber(frequentFlyerNumber),
                            ),
                        html`${unsafeHTML(
                            i18next.t("El número AAdvantage ingresado no es válido. {{-reg}}", {
                                reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
                            }),
                        )}`,
                    ),
                ],
                isReadonly: (vm) => vm.isFrequentFlyerNumberReadonly,
                isShown: getRule("frequentFlyerNumber"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "frequentFlyerNumber"),
                tooltip: frequentFlyerNumberTooltip,
            },
            {
                index: 13,
                isContactField: true,
                key: "contactFirstName",
                label: i18next.t("Nombres"),
                type: "text",
                validators: [
                    Validation.ruleFor("contactFirstName", (vm: PassengerFormVM) => vm.contactFirstName)
                        .when(getRule("contactFirstName"))
                        .isRequired()
                        .max(32),
                ], // more
                isReadonly: (vm) => vm.isPassengerCheckedIn,
                isShown: getRule("contactFirstName"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars"),
            },
            {
                index: 14,
                isContactField: true,
                key: "contactLastName",
                label: i18next.t("Apellidos"),
                type: "text",
                validators: [
                    Validation.ruleFor("contactLastName", (vm: PassengerFormVM) => vm.contactLastName)
                        .when(getRule("contactLastName"))
                        .isRequired()
                        .max(32),
                ],
                isReadonly: (vm) => vm.isPassengerCheckedIn,
                isShown: getRule("contactLastName"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "accepted-text-chars"),
            },
            {
                index: 15,
                isContactField: true,
                key: "contactEmail",
                label: i18next.t("Correo electrónico"),
                type: "text",
                validators: [
                    Validation.ruleFor("contactEmail", (vm: PassengerFormVM) => vm.contactEmail)
                        .when(getRule("contactEmail"))
                        .isRequired()
                        .max(52)
                        .isEmail(),
                ],
                isReadonly: (vm) => vm.isPassengerCheckedIn,
                isShown: getRule("contactEmail"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "e-mail"),
            },
            {
                groupId: "contactPhone",
                index: 16,
                isContactField: true,
                key: "contactPhonePrefix",
                type: "select",
                label: i18next.t("Código país"),
                validators: [
                    Validation.ruleFor("contactPhoneNumber", (vm: PassengerFormVM) => vm.contactPhoneNumber)
                        .when(getRule("contactPhonePrefix"))
                        .isRequired()
                        .max(12),
                ],
                isReadonly: (vm) => vm.isPassengerCheckedIn,
                isShown: getRule("contactPhonePrefix"),
            },
            {
                groupId: "contactPhone",
                index: 17,
                isContactField: true,
                key: "contactPhoneNumber",
                label: i18next.t("Mobile Phone"),
                type: "text",
                validators: [
                    Validation.ruleFor("contactPhoneNumber", (vm: PassengerFormVM) => vm.contactPhoneNumber)
                        .when(getRule("contactPhoneNumber"))
                        .isRequired()
                        .max(12),
                ],
                isReadonly: (vm) => vm.isPassengerCheckedIn,
                isShown: getRule("contactPhoneNumber"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "numeric"),
            },
            {
                groupId: "contactOfficePhone",
                index: 18,
                key: "contactOfficePhonePrefix",
                label: i18next.t("Código país"),
                type: "select",
                validators: [
                    Validation.ruleFor("contactOfficePhonePrefix", (vm: PassengerFormVM) => vm.contactOfficePhonePrefix)
                        .when(getRule("contactOfficePhonePrefix"))
                        .isRequired(),
                ],
                isReadonly: (vm) => vm.isPassengerCheckedIn,
                isShown: getRule("contactOfficePhonePrefix"),
            },
            {
                groupId: "contactOfficePhone",
                index: 19,
                isContactField: true,
                key: "contactOfficePhoneNumber",
                label: i18next.t("Office Phone"),
                type: "text",
                validators: [
                    Validation.ruleFor("contactOfficePhoneNumber", (vm: PassengerFormVM) => vm.contactOfficePhoneNumber)
                        .when(getRule("contactOfficePhoneNumber"))
                        .isRequired()
                        .max(12),
                ],
                isReadonly: (vm) => vm.isPassengerCheckedIn,
                isShown: getRule("contactOfficePhoneNumber"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "numeric"),
            },
            {
                groupId: "chileCompraSupervisor",
                index: 20,
                key: "chileCompraPaxType",
                label: i18next.t("Tipo viajero"),
                type: "select",
                validators: [
                    Validation.ruleFor("chileCompraPaxType", (vm: PassengerFormVM) => vm.chileCompraPaxType)
                        .when(getRule("chileCompraPaxType"))
                        .isRequired(),
                ],
                isReadonly: () => false,
                isShown: getRule("chileCompraPaxType"),
            },
            {
                groupId: "chileCompraSupervisor",
                index: 21,
                key: "chileCompraReason",
                label: i18next.t("Motivo del viaje"),
                type: "text",
                validators: [
                    Validation.ruleFor("chileCompraReason", (vm: PassengerFormVM) => vm.chileCompraReason)
                        .when(getRule("chileCompraReason"))
                        .isRequired(),
                ],
                isReadonly: () => false,
                isShown: getRule("chileCompraReason"),
            },
        ];
    };

    const getCheckinPassengerFormFields = (props: {
        culture: string;
        departureDate: Dayjs;
        destinationCountry: string;
        isChileCompra: boolean;
        isInternationalFlight: boolean;
        isFirstBancoEstadoPassengerRut: boolean;
        originCountry: string;
        isStaff: boolean;
        passengerIndicesWithAcaa: number[];
    }): PassengerField[] => {
        const isStaff = props.isStaff;

        const { validateFrequentFlyerNumber } = frequentFlyerNumberValidator();
        const { frequentFlyerNumberTooltip } = frequentFlyerHelper();

        const isForInfantOrInternationalFlight = (vm: PassengerFormVM) =>
            vm.type === "INF" || props.isInternationalFlight;

        const hasPeruvianStations =
            props.originCountry === PERU_COUNTRY_CODE || props.destinationCountry === PERU_COUNTRY_CODE;

        const isInternationalToColombia =
            props.originCountry !== COLOMBIA_COUNTRY_CODE && props.destinationCountry === COLOMBIA_COUNTRY_CODE;

        const ruleMap = new Map<(keyof PassengerFormVM)[], (vm: PassengerFormVM) => boolean>([
            [["documentType", "documentNumber", "dateOfBirth", "gender"], () => true],
            [["documentIssuer", "documentExpiry", "country"], isForInfantOrInternationalFlight],
            [
                ["phonePrefix", "phoneNumber", "addressCountry"],
                (vm) => vm.type === "ADT" && hasPeruvianStations && !isInternationalToColombia,
            ],
            [
                ["addressCity", "addressAddressLine1", "addressPostalCode"],
                (vm) => vm.type === "ADT" && hasPeruvianStations,
            ],
            [
                ["contactAddressCity", "contactAddressAddressLine1", "contactAddressPostalCode"],
                (vm) => vm.type === "ADT" && isInternationalToColombia,
            ],
            [
                ["frequentFlyerNumber"],
                (vm) =>
                    !props.isChileCompra &&
                    props.passengerIndicesWithAcaa.includes(vm.uniqueIndex) &&
                    appContext.isFeatureSwitchActive("MileageAccrual") &&
                    vm.type !== "INF",
            ],
        ]);

        const getRule = (key: keyof PassengerFormVM) =>
            Array.from(ruleMap.entries()).find(([keys]) => keys.includes(key))?.[1];

        return [
            {
                groupId: "document",
                index: 0,
                key: "documentType",
                label: i18next.t("Tipo de documento"),
                type: "select",
                validators: [Validation.ruleFor("documentType", (vm: PassengerFormVM) => vm.documentType).isRequired()],
                isReadonly: (vm) => vm.isDocumentReadonly || (isStaff && vm.uniqueIndex !== 0),
                isShown: getRule("documentType"),
            },
            {
                groupId: "document",
                index: 1,
                key: "documentNumber",
                label: i18next.t("Número de documento"),
                type: "text",
                validators: [
                    Validation.ruleFor("documentNumber", (vm: PassengerFormVM) => vm.documentNumber).isRequired(),
                    Validation.ruleFor("documentNumber", (vm: PassengerFormVM) => vm.documentNumber)
                        .when(() => props.isChileCompra)
                        .max(15),
                    Validation.ruleFor("documentNumber", (vm: PassengerFormVM) => vm.documentNumber)
                        .when((vm: PassengerFormVM) => vm.documentType === "N")
                        .fulfils(
                            (documentNumber: string) => Promise.resolve(validateRut(documentNumber)),
                            i18next.t(
                                "Rut inválido, por favor verifica que el rut es correcto y que el formato sea válido. (Ej: 76472472-0)",
                            ),
                        ),
                    Validation.ruleFor("documentNumber", (vm: PassengerFormVM) => vm.documentNumber)
                        .when((vm: PassengerFormVM) => vm.documentType === "DNI")
                        .fulfils(
                            (documentNumber: string) =>
                                Promise.resolve(
                                    documentNumber.length === 0 || validateDni(documentNumber, props.culture),
                                ),
                            i18next.t("DNI invalido"),
                            "field",
                            true,
                        ),
                ],
                sanitizer: (e: KeyboardEvent, docType: TravelDocumentType) => {
                    if (docType === "DNI" && props.culture === COLOMBIAN_CULTURE_CODE) {
                        return sanitizeInputFieldValue(e, "numeric").substring(0, 11);
                    }
                    if (docType === "DNI") return sanitizeInputFieldValue(e, "numeric");
                    if (docType === "P") return sanitizeInputFieldValue(e, "alphanumeric");
                    return sanitizeRutFieldValue(e.target as HTMLInputElement);
                },
                isReadonly: (vm) => vm.isDocumentReadonly || (isStaff && vm.uniqueIndex !== 0),
                isShown: getRule("documentNumber"),
            },
            {
                index: 2,
                key: "documentIssuer",
                label: i18next.t("V2-Checkin-PassengersIssuedBy"),
                type: "select",
                validators: [
                    Validation.ruleFor("documentIssuer", (vm: PassengerFormVM) => vm.documentIssuer)
                        .when(getRule("documentIssuer"))
                        .isRequired(),
                ],
                isReadonly: () => false,
                isShown: getRule("documentIssuer"),
            },
            {
                index: 3,
                key: "documentExpiry",
                label: i18next.t("ExpirationLabel"),
                type: "date",
                validators: [
                    Validation.ruleFor("documentExpiry", (vm: PassengerFormVM) => vm.documentExpiry)
                        .when(getRule("documentExpiry"))
                        .isDateAfter(props.departureDate)
                        .isRequired(),
                ],
                isReadonly: () => false,
                isShown: getRule("documentExpiry"),
            },
            {
                index: 4,
                key: "dateOfBirth",
                label: i18next.t("Fecha de nacimiento"),
                type: "date",
                validators: [Validation.ruleFor("dateOfBirth", (vm: PassengerFormVM) => vm.dateOfBirth).isRequired()],
                isReadonly: (vm) => vm.type === "INF" || vm.type === "CHD",
                isShown: getRule("dateOfBirth"),
            },
            {
                index: 5,
                key: "gender",
                label: i18next.t("V2-GenderLabel"),
                type: "select",
                validators: [Validation.ruleFor("gender", (vm: PassengerFormVM) => vm.gender).isRequired()],
                isReadonly: () => false,
                isShown: getRule("gender"),
            },
            {
                index: 6,
                key: "country",
                label: i18next.t("V2-Checkin-PassengersNationality"),
                type: "select",
                validators: [
                    Validation.ruleFor("country", (vm: PassengerFormVM) => vm.country)
                        .when(getRule("country"))
                        .isRequired(),
                ],
                isReadonly: () => false,
                isShown: getRule("country"),
            },
            {
                groupId: "phone",
                index: 7,
                key: "phonePrefix",
                label: i18next.t("Código país"),
                type: "select",
                validators: [
                    Validation.ruleFor("phonePrefix", (vm: PassengerFormVM) => vm.phonePrefix)
                        .when(getRule("phonePrefix"))
                        .isRequired(),
                ],
                isReadonly: () => false,
                isShown: getRule("phonePrefix"),
            },
            {
                index: 8,
                groupId: "phone",
                key: "phoneNumber",
                type: "text",
                label: i18next.t("V2-PhoneLabel"),
                validators: [
                    Validation.ruleFor("phoneNumber", (vm: PassengerFormVM) => vm.phoneNumber)
                        .when(getRule("phoneNumber"))
                        .isRequired(),
                    Validation.ruleFor("phoneNumber", (vm: PassengerFormVM) => vm.phoneNumber)
                        .when((vm) => vm.phonePrefix !== "56")
                        .max(20),
                    Validation.ruleFor("phoneNumber", (vm: PassengerFormVM) => vm.phoneNumber)
                        .when((vm) => vm.phonePrefix === "56")
                        .max(9),
                ],
                isReadonly: () => false,
                isShown: getRule("phoneNumber"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "numeric"),
            },
            {
                index: 9,
                key: "addressCountry",
                label: i18next.t("V2-CountryLabel"),
                type: "select",
                validators: [
                    Validation.ruleFor("addressCountry", (vm: PassengerFormVM) => vm.addressCountry)
                        .when(getRule("addressCountry"))
                        .isRequired(),
                ],
                isReadonly: () => false,
                isShown: getRule("addressCountry"),
            },

            {
                index: 10,
                key: "addressPostalCode",
                type: "text",
                label: i18next.t("Código postal"),
                validators: [
                    Validation.ruleFor("addressPostalCode", (vm: PassengerFormVM) => vm.addressPostalCode)
                        .when(getRule("addressPostalCode"))
                        .max(10)
                        .isRequired(),
                ],
                isReadonly: () => false,
                isShown: getRule("addressPostalCode"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "postal-code"),
            },
            {
                index: 11,
                key: "addressCity",
                type: "text",
                label: i18next.t("Ciudad"),
                validators: [
                    Validation.ruleFor("addressCity", (vm: PassengerFormVM) => vm.addressCity)
                        .when(getRule("addressCity"))
                        .isRequired(),
                ],
                isReadonly: () => false,
                isShown: getRule("addressCity"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "city"),
            },
            {
                index: 12,
                key: "addressAddressLine1",
                type: "text",
                label: i18next.t("Dirección"),
                validators: [
                    Validation.ruleFor("addressAddressLine1", (vm: PassengerFormVM) => vm.addressAddressLine1)
                        .when(getRule("addressAddressLine1"))
                        .isRequired(),
                ],
                isReadonly: () => false,
                isShown: getRule("addressAddressLine1"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "address"),
            },
            {
                index: 13,
                key: "contactAddressPostalCode",
                type: "text",
                label: i18next.t("Código postal destino"),
                validators: [
                    Validation.ruleFor("contactAddressPostalCode", (vm: PassengerFormVM) => vm.contactAddressPostalCode)
                        .when(getRule("contactAddressPostalCode"))
                        .max(10)
                        .isRequired(),
                ],
                isReadonly: () => false,
                isShown: getRule("contactAddressPostalCode"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "postal-code"),
            },
            {
                index: 14,
                key: "contactAddressCity",
                type: "text",
                label: i18next.t("Ciudad en destino"),
                validators: [
                    Validation.ruleFor("contactAddressCity", (vm: PassengerFormVM) => vm.contactAddressCity)
                        .when(getRule("contactAddressCity"))
                        .isRequired(),
                ],
                isReadonly: () => false,
                isShown: getRule("contactAddressCity"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "city"),
            },
            {
                index: 15,
                key: "contactAddressAddressLine1",
                type: "text",
                label: i18next.t("Dirección en destino"),
                validators: [
                    Validation.ruleFor(
                        "contactAddressAddressLine1",
                        (vm: PassengerFormVM) => vm.contactAddressAddressLine1,
                    )
                        .when(getRule("contactAddressAddressLine1"))
                        .isRequired(),
                ],
                isReadonly: () => false,
                isShown: getRule("contactAddressAddressLine1"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "address"),
            },
            {
                index: 16,
                key: "frequentFlyerNumber",
                label: html`${unsafeHTML(
                    i18next.t("Número AAdvantage {{-reg}} (Opcional)", {
                        reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
                    }),
                )}`,
                type: "text",
                validators: [
                    Validation.ruleFor("frequentFlyerNumber", (vm: PassengerFormVM) => vm.frequentFlyerNumber).fulfils(
                        (frequentFlyerNumber: string) =>
                            Promise.resolve(
                                !frequentFlyerNumber?.trim() || validateFrequentFlyerNumber(frequentFlyerNumber),
                            ),
                        html`${unsafeHTML(
                            i18next.t("El número AAdvantage ingresado no es válido. {{-reg}}", {
                                reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
                            }),
                        )}`,
                    ),
                ],
                isReadonly: (vm) => vm.isFrequentFlyerNumberReadonly,
                isShown: getRule("frequentFlyerNumber"),
                sanitizer: (e: KeyboardEvent) => sanitizeInputFieldValue(e, "frequentFlyerNumber"),
                tooltip: frequentFlyerNumberTooltip,
            },
        ];
    };

    return { getCheckinPassengerFormFields, getPassengerFormFields };
};
