import { INBOUND } from "../../../../shared/commonConstants";
import i18next from "i18next";
import { html } from "lit-html";
import { PriorityBoardingModel } from "../../../../component-models/extras/priority-boarding/PriorityBoardingModel";
import { PriorityBoardingJourneyModel } from "../../../../component-models/extras/priority-boarding/PriorityBoardingJourneyModel";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import {
    desktopJourneyRoutesTemplate,
    journeyBundleInfoTemplate,
    journeyRouteTemplate,
} from "../../../../common-templates/ExtrasCommonTemplates";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";
import { ApiExtrasSpaSectionViewModel } from "../../../../component-models/spa/ExtrasSpaSectionViewModel";
import classNames from "classnames";
import { PriorityBoardingJourneyPassengerModel } from "../../../../component-models/extras/priority-boarding/PriorityBoardingJourneyPassengerModel";

export interface Props {
    model: PriorityBoardingModel;
    bancoEstadoSavedAmountPriorityBoarding: number;
    setExtrasModel: (model: ApiExtrasSpaSectionViewModel) => void;
}

export const usePriorityBoarding = (props: Props) => {
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const { formatNumber } = useNumberFormatter();

    const [userContext] = useReduxState("userContext");

    const showTemplate = useMemo(() => !props.model?.Journeys.every((j) => j.IsInBundle), [props.model]);

    const desktopBancoEstadoHeadingTemplate = () =>
        [5, 6].includes(userContext.bancoEstado.category)
            ? html`
                  <div class="row hidden-xs" data-test-id=${T.EXTRAS_PRIORITY_BOARDING.BANCO_ESTADO_HEADING}>
                      ${props.model?.Journeys.map((journey) => {
                          const tempClassMap = classNames("col-xs-1 col-md-1-2", {
                              "hidden-sm": journey.JourneyIndex === INBOUND,
                              "col-sm-offset-1-4": props.model?.Journeys.length === 1,
                          });

                          return html`
                              <div class=${tempClassMap}>
                                  ${bancoEstado6HeadingTemplate()} ${bancoEstado5HeadingTemplate()}
                              </div>
                          `;
                      })}
                  </div>
              `
            : "";

    const bundleHeadingJourneyTemplate = (journey: PriorityBoardingJourneyModel) => html`
        <div
            class=${classNames("col-xs-1 col-sm-1-2", { "col-sm-offset-1-4": props.model?.Journeys.length === 1 })}
            data-test-id=${getTestId(T.EXTRAS_PRIORITY_BOARDING.BUNDLE_HEADING, { j: journey.JourneyIndex })}
        >
            ${desktopBundleHeadingContentTemplate(journey)}
        </div>
    `;

    const desktopBundleHeadingTemplate = () =>
        props.model?.Journeys.some((journey) => journey.IsInBundle) &&
        ![5, 6].includes(userContext.bancoEstado.category)
            ? html` <div class="row hidden-xs">${props.model?.Journeys.map(bundleHeadingJourneyTemplate)}</div> `
            : "";

    const bundleInfoContentTemplate = (journey: PriorityBoardingJourneyModel) =>
        journeyBundleInfoTemplate(
            journey.BundleColor,
            journey.BundleImg,
            T.EXTRAS_PRIORITY_BOARDING.JOURNEY_BUNDLE,
            undefined,
            journey.JourneyIndex,
            false,
            bookingContext.isGdsBooking,
        );

    const desktopBundleHeadingContentTemplate = (journey: PriorityBoardingJourneyModel) =>
        journey.IsInBundle && ![5, 6].includes(userContext.bancoEstado.category)
            ? bundleInfoContentTemplate(journey)
            : "";

    const bancoEstado6HeadingTemplate = () =>
        userContext.bancoEstado.category === 6
            ? html`
                  <div class="bancoe-included-optional bg-be-gray-3">
                      <img src="/Images/BancoEstado/be-smart-plus-white-text-gray-bg.svg" />
                      <span>${i18next.t("BE2-BancoEstadoIncludedOptional")}</span>
                      <img src="/Images/BancoEstado/be-smart-plus-text-logo-white.svg" />
                  </div>
              `
            : "";

    const bancoEstado5HeadingTemplate = () =>
        userContext.bancoEstado.category === 5
            ? html`
                  <div class="bancoe-included-optional bg-be-orange">
                      <img src="/Images/BancoEstado/be-smart-white-text-orange-bg.svg" />
                      <span>${i18next.t("BE2-BancoEstadoIncludedOptional")}</span>
                      <img src="/Images/BancoEstado/be-smart-text-logo-white.svg" />
                  </div>
              `
            : "";

    const mobileBancoEstadoHeadingTemplate = () =>
        [5, 6].includes(userContext.bancoEstado.category)
            ? html`
                  <div
                      class="row hidden-sm-up"
                      data-test-id=${getTestId(T.EXTRAS_PRIORITY_BOARDING.BANCO_ESTADO_HEADING, {
                          m: true,
                      })}
                  >
                      <div class="col-xs-1">${bancoEstado6HeadingTemplate()} ${bancoEstado5HeadingTemplate()}</div>
                  </div>
              `
            : "";

    const mobileBundleHeadingTemplate = (journey: PriorityBoardingJourneyModel) =>
        journey.IsInBundle && ![5, 6].includes(userContext.bancoEstado.category)
            ? html`
                  <div
                      class="row hidden-sm-up"
                      data-test-id=${getTestId(T.EXTRAS_PRIORITY_BOARDING.BUNDLE_HEADING, {
                          j: journey.JourneyIndex,
                          m: true,
                      })}
                  >
                      <div class="col-xs-1">${bundleInfoContentTemplate(journey)}</div>
                  </div>
              `
            : "";

    const bancoEstadoFooterTemplate = () =>
        [1, 2].includes(userContext.bancoEstado.category)
            ? html`
                  <div
                      class="priority-boarding-banco-estado-info"
                      data-test-id=${T.EXTRAS_PRIORITY_BOARDING.BANCO_ESTADO_FOOTER}
                  >
                      ${flowContext.isChangeFlow
                          ? i18next.t("BE-PriorityBoardingChangeFlowInfo")
                          : i18next.t("BE-PriorityBoardingInfo {{-saving}}", {
                                saving: formatNumber({
                                    amount: props.bancoEstadoSavedAmountPriorityBoarding * 2,
                                    leadingSign: true,
                                }),
                            })}
                  </div>
              `
            : "";

    const passengerTemplate = (
        journey: PriorityBoardingJourneyModel,
        passenger: PriorityBoardingJourneyPassengerModel,
    ) => {
        const amount = formatNumber({
            amount: props.bancoEstadoSavedAmountPriorityBoarding,
            leadingSign: true,
        });

        return html`
            <ac-priority-boarding-passenger
                .journey=${journey}
                .passengerOption=${passenger}
                .formattedBancoEstadoSavedAmountPriorityBoarding=${amount}
                .setExtrasModel=${props.setExtrasModel}
            >
            </ac-priority-boarding-passenger>
        `;
    };

    const passengersTemplate = (journey: PriorityBoardingJourneyModel) =>
        journey.Passengers.map((pax) => passengerTemplate(journey, pax));

    const journeyTemplate = (journey: PriorityBoardingJourneyModel) => html`
        ${mobileBancoEstadoHeadingTemplate()} ${mobileBundleHeadingTemplate(journey)}

        <div
            class=${classNames("col-xs-1 col-sm-1-2", {
                "col-sm-offset-1-4": props.model?.Journeys.length === 1,
            })}
            data-test-id=${getTestId(T.EXTRAS_PRIORITY_BOARDING.JOURNEY, { j: journey.JourneyIndex })}
        >
            <div class="inner-box bottom-half padded-bottom-half ts-error-parent ts-error-container">
                <h3 class="package-title visible-xs">${journeyRouteTemplate(journey)}</h3>

                ${passengersTemplate(journey)}
            </div>
        </div>
    `;

    const journeysTemplate = () => props.model?.Journeys.map(journeyTemplate);

    const headerTemplate = () => html`
        <header>
            <i class="js-boarding-man js-icon title-icon"></i>
            <div class="title">
                <h2 class="main-title" data-test-id=${T.EXTRAS_PRIORITY_BOARDING.TITLE}>
                    ${i18next.t("V2-PriorityBoarding")}
                </h2>
                <div class="subtitle" data-test-id=${T.EXTRAS_PRIORITY_BOARDING.SUBTITLE}>
                    ${i18next.t("V2-PriorityBoardingInfo")}
                </div>
            </div>
        </header>
    `;

    const htmlTemplate = () =>
        showTemplate
            ? html`
                  <section
                      class="booking-wrapper extras-step ts-priority-boarding ts-error-container"
                      data-test-id=${T.EXTRAS_PRIORITY_BOARDING.CONTAINER}
                  >
                      ${headerTemplate()} ${desktopBancoEstadoHeadingTemplate()} ${desktopBundleHeadingTemplate()}

                      <div class="row hidden-xs">${desktopJourneyRoutesTemplate(props.model?.Journeys)}</div>
                      <div class="row">${journeysTemplate()}</div>
                      ${bancoEstadoFooterTemplate()}
                  </section>
              `
            : "";

    return { htmlTemplate };
};
