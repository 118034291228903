import { html } from "haunted";
import { ApiPortalSpaViewModel } from "../../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import i18next from "i18next";
import { useEditOrganizationForm } from "./useEditOrganizationForm";
import { useEditUnrestrictedUserForm } from "./useEditUnrestrictedUserForm";
import { useEditPasswordForm } from "./useEditPasswordForm";

export interface Props {
    model: ApiPortalSpaViewModel;
    antiForgeryToken: string;
}

export const useCugEditProfilePage = (props: Props) => {
    // COMPONENT

    const editOrganizationForm = useEditOrganizationForm({
        model: props.model,
    });

    const editUserForm = useEditUnrestrictedUserForm({
        model: props.model,
        antiForgeryToken: props.antiForgeryToken,
    });

    const passwordForm = useEditPasswordForm({
        model: props.model,
        antiForgeryToken: props.antiForgeryToken,
    });

    // TEMPLATES

    const headerTemplate = () => html`
        <div class="cug2b-title-container">
            <i class="js-icon-cug js-cug-man-and-plane"></i>
            <div>
                <h1>${i18next.t("Mi Perfil")}</h1>
                <h2>
                    ${i18next.t(
                        "Revisa, edita y actualiza toda la información referente a tu empresa/agencia y a tu cuenta de usuario.",
                    )}
                </h2>
            </div>
        </div>
    `;

    const htmlTemplate = () => html`
        ${headerTemplate()} ${editOrganizationForm.htmlTemplate()} ${editUserForm.htmlTemplate()}
        ${passwordForm.htmlTemplate()}
    `;

    return { htmlTemplate };
};
