import dayjs from "dayjs";
import {
    AMERICAN_FREQUENT_FLYER_NUMBER_DOCISSUER,
    AMERICAN_FREQUENT_FLYER_NUMBER_DOCTYPE,
    TravelDocument,
} from "../component-models/checkin/TravelDocument";
import { ApiNewPassenger, ApiPassengerTravelDocument } from "../component-models/passengers/ApiNewPassengersModel";
import {
    DEFAULT_DATE_FORMAT,
    FAKE_COUNTRY_CODE,
    FAKE_DOCUMENT_EXPIRATION_DATE,
    FAKE_NAVITAIRE_DATE_CHUNK,
} from "../shared/commonConstants";
import { PassengerFormVM } from "../component-models/passengers/PassengerFormVM";

export const mapToTravelDocument = (data: {
    document: ApiPassengerTravelDocument;
    isBancoEstado: boolean;
    isBookingFlow: boolean;
    passenger: ApiNewPassenger;
}): TravelDocument => {
    const expirationDate =
        data.document.ExpirationDate &&
        !data.document.ExpirationDate.startsWith(FAKE_DOCUMENT_EXPIRATION_DATE) &&
        !data.document.ExpirationDate.includes(FAKE_NAVITAIRE_DATE_CHUNK)
            ? data.document.ExpirationDate
            : "";

    const isReadonly =
        data.document.DocumentNumber &&
        data.document.DocumentType &&
        ((data.isBancoEstado && data.passenger.PassengerNumber === 0) || !data.isBookingFlow);

    return {
        DocSuffix: data.document.DocSuffix,
        ExpirationDate: expirationDate,
        IsReadonly: isReadonly,
        IssuedByCode: data.document.IssuedBy || FAKE_COUNTRY_CODE,
        Number: data.document.DocumentNumber,
        PassengerNumber: data.passenger.PassengerNumber,
        TypeCode: data.document.DocumentType,
    };
};

export const mapToFrequentFlyerDocument = (data: {
    document: ApiPassengerTravelDocument;
    isBookingFlow: boolean;
    passenger: ApiNewPassenger;
}): TravelDocument => {
    return {
        DocSuffix: " ",
        ExpirationDate: FAKE_DOCUMENT_EXPIRATION_DATE,
        IsReadonly: data.document.DocumentNumber && data.document.DocumentType && !data.isBookingFlow,
        IssuedByCode: AMERICAN_FREQUENT_FLYER_NUMBER_DOCISSUER,
        Number: data.document.DocumentNumber,
        PassengerNumber: data.passenger.PassengerNumber,
        TypeCode: AMERICAN_FREQUENT_FLYER_NUMBER_DOCTYPE,
    };
};

export const mapToApiPassengerTravelDocument = (data: {
    document: TravelDocument;
    passenger: PassengerFormVM;
    passengers: PassengerFormVM[];
}): ApiPassengerTravelDocument => ({
    DocSuffix: data.document.DocSuffix,
    DocumentNumber: data.document.Number,
    DocumentType: data.document.TypeCode,
    ExpirationDate: data.document.ExpirationDate || FAKE_DOCUMENT_EXPIRATION_DATE,
    IssuedBy:
        data.document.TypeCode === AMERICAN_FREQUENT_FLYER_NUMBER_DOCTYPE
            ? AMERICAN_FREQUENT_FLYER_NUMBER_DOCISSUER
            : data.document.IssuedByCode || FAKE_COUNTRY_CODE,
    IssuedDate: dayjs().format(DEFAULT_DATE_FORMAT),
});
