import { useState } from "haunted";
import { BannerDTO } from "../../../component-models/flight/BannerDTO";
import { FlightPageViewModel } from "../../../component-models/flight/FlightPageViewModel";
import { clone } from "../../../shared/common";
import { SelectedDcMembershipType, TermsAcceptance } from "../dc-banner/useDiscountClubBanner";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import { useEffect, useMemo } from "../../../shared/haunted/CustomHooks";
import { DcBannerState } from "../../../component-models/flight/contexts/DcBannerState";
import { DcBannerContext } from "../../../component-models/flight/contexts/DcBannerContext";
import { BundleHelper } from "../../../component-helpers/flight/BundleHelper";
import { UserContext } from "../../../component-models/app/UserContext";
import { DcBannerViewModel } from "../../../component-models/DcBannerViewModel";
import { MAX_PAX_IN_STANDARD_DC_MEMBERSHIP } from "../../../shared/commonConstants";
import { CustomizedBundleOffers } from "../../../component-models/flight/BundleOffersV2Model";
import { FlightPageFarelockState } from "../../../component-models/flight/contexts/FlightPageFarelockState";
import { useDcMembershipExtensionHandler } from "../useDcMembershipExtensionHandler";
import { useFlightHttpContextHandler } from "../useFlightHttpContextHandler";
import { useFlightPageAjaxHandler } from "../useFlightPageAjaxHandler";
import { useReduxState } from "../../../shared/redux/useReduxState";

export interface DcBannerContextProps {
    model: FlightPageViewModel;
    onChange: () => void;
    setIsLoading: (isLoading: boolean) => void;
}

export const useDcBannerContext = (props: DcBannerContextProps): DcBannerContext => {
    const { getTotal } = useFlightPageAjaxHandler();

    const [userContext] = useReduxState("userContext");

    const [state, setState] = useState<DcBannerState>({
        bannerVM: undefined,
        selectedOption: undefined,
        termsAcceptance: undefined,
    });

    const { isCurrentlyChangingCurrency } = useFlightHttpContextHandler({ model: props.model });

    const { displayExtensionWarning, showWarning15, showWarning30 } = useDcMembershipExtensionHandler({
        model: props.model,
    });

    // HELPERS

    const shouldUpdateBannerAfterLogin = (data: {
        newUserContext: UserContext;
        selectedDcMembershipType: SelectedDcMembershipType;
    }) =>
        data.newUserContext.american.americanAirlinesDisplayName?.length > 0 ||
        data.newUserContext.dc.showWarning15 ||
        data.newUserContext.dc.showWarning30 ||
        ((!BundleHelper.canGetDcBundles(data.newUserContext, props.model) || showExtendMembership(data)) &&
            !data.newUserContext.cug.isMember &&
            !data.newUserContext.isStaff);

    const getSelectedBannerOptionAfterLogin = (data: {
        newUserContext: UserContext;
        selectedDcMembershipType: SelectedDcMembershipType;
        selectedBannerOption: SelectedDcMembershipType;
    }) => {
        if (
            data.newUserContext.isStaff ||
            data.newUserContext.cug.isMember ||
            data.newUserContext.american.americanAirlinesDisplayName?.length > 0
        ) {
            return "None";
        }

        return BundleHelper.canGetDcBundles(data.newUserContext, props.model) && !showExtendMembership(data)
            ? "None"
            : data.selectedBannerOption;
    };

    const shouldUpdateBannerType = (data: BannerDTO) => {
        const hasBundles = data.bundles.some((b) => b.DCOffers.length > 1);
        const selectedAllOutbound = data.outboundSellKey && (data.outboundBundleCode || !hasBundles);
        const selectedAllInbound =
            props.model.FlightDataViewModel.IsOneWay ||
            (data.inboundSellKey && (data.inboundBundleCode || !hasBundles));

        return selectedAllOutbound && selectedAllInbound;
    };

    const showUpgradeMembership = (userData: UserContext) => {
        const paxNumberWithinLimit = props.model.FlightDataViewModel.NumberOfPax <= MAX_PAX_IN_STANDARD_DC_MEMBERSHIP;
        const noBancoEstado56User = [5, 6].indexOf(userData.bancoEstado.category) === -1;

        return userData.dc.hasStandardMembership && !paxNumberWithinLimit && noBancoEstado56User;
    };

    const showExtendMembership = (data: {
        selectedDcMembershipType: SelectedDcMembershipType;
        newUserContext: UserContext;
    }) => {
        const hasGroupMembership = data.newUserContext?.dc.hasGroupMembership;
        const needsWarning =
            ([1, 6].indexOf(data.newUserContext?.bancoEstado.category) === -1 || hasGroupMembership) &&
            (showWarning15(data.newUserContext) ||
                showWarning30(data.newUserContext) ||
                data.newUserContext.dc.showWarning15 ||
                data.newUserContext.dc.showWarning30);

        return (
            data.newUserContext?.dc.hasMembership &&
            needsWarning &&
            data.selectedDcMembershipType !== "Upgrade" &&
            displayExtensionWarning(data.newUserContext)
        );
    };

    const showGetMembership = (data: {
        isBuyingMembership: boolean;
        userContext: UserContext;
        bundles: CustomizedBundleOffers[];
    }) =>
        data.userContext.chileCompra.role === "none" &&
        !(data.userContext?.american.americanAirlinesDisplayName?.length > 0) &&
        !data.userContext?.dc.hasMembership &&
        !data.userContext?.dc.hasStandardMembership &&
        !data.userContext?.dc.hasGroupMembership &&
        data.bundles.some((b) => b.DCOffers.length > 1) &&
        (!data.isBuyingMembership || isCurrentlyChangingCurrency());

    const getBannerViewModel = (data: BannerDTO, newUserContext: UserContext): DcBannerViewModel => {
        const commonModelBase: DcBannerViewModel = {
            bannerType: undefined,
            currency: data.currency,
            currentTotal: undefined,
            flightPageViewModel: props.model,
            groupPrice: newUserContext.dc.groupPriceFormatted
                ? newUserContext.dc.groupPriceFormatted
                : props.model.DiscountClubViewModel.GroupPrice,
            membershipDaysRemaining: newUserContext.dc.membershipDaysRemaining
                ? newUserContext.dc.membershipDaysRemaining
                : props.model.DiscountClubViewModel.MembershipDaysRemaining,
            showPricesInBanner: undefined,
            standardPrice: newUserContext.dc.standardPriceFormatted
                ? newUserContext.dc.standardPriceFormatted
                : props.model.DiscountClubViewModel.StandardPrice,
            upgradePrice: newUserContext.dc.upgradePriceFormatted
                ? newUserContext.dc.upgradePriceFormatted
                : props.model.DiscountClubViewModel.UpgradePrice,
        };

        // DEVNOTE Upgrade is stronger than extend, if both are valid
        if (showUpgradeMembership(newUserContext)) {
            if (data.selectedFlightFeeType !== "Club") return state.bannerVM;

            return {
                ...commonModelBase,
                bannerType: "UpgradeMembership",
                showPricesInBanner: false,
            };
        }

        if (
            showExtendMembership({
                newUserContext,
                selectedDcMembershipType: data.selectedDcMembershipType,
            })
        ) {
            return {
                ...commonModelBase,
                bannerType: "ExtendMembership",
                showPricesInBanner: false,
            };
        }

        if (
            showGetMembership({
                isBuyingMembership: Boolean(data.selectedDcMembershipType),
                userContext: newUserContext,
                bundles: data.bundles,
            })
        ) {
            return {
                ...commonModelBase,
                bannerType: newUserContext?.isLoggedIn ? "GetMembershipForMember" : "GetMembershipForWA",
                showPricesInBanner: data.selectedFlightFeeType === "Smart",
            };
        }

        return state.bannerVM;
    };

    // DEVNOTE We must look at bundles, after browser "back" the flight prices are always the same
    const arePricesTheSame = (data: {
        outboundSellKey: string;
        inboundSellKey: string;
        bundles: CustomizedBundleOffers[];
    }) => {
        // DEVNOTE The sell keys are changing when we change the currency, that is why we slice
        const outboundSmartPrice = data.bundles
            .find((b) => b.SellKey.slice(-45) === data.outboundSellKey.slice(-45))
            ?.NormalOffers.find((b) => b.BundleType === "None")?.UnFormattedPrice;

        const outboundClubPrice = data.bundles
            .find((b) => b.SellKey.slice(-45) === data.outboundSellKey.slice(-45))
            ?.DCOffers.find((b) => b.BundleType === "None")?.UnFormattedPrice;

        const inboundSmartPrice = data.bundles
            .find((b) => b.SellKey.slice(-45) === data.inboundSellKey?.slice(-45))
            ?.NormalOffers.find((b) => b.BundleType === "None")?.UnFormattedPrice;

        const inboundClubPrice = data.bundles
            .find((b) => b.SellKey.slice(-45) === data.inboundSellKey?.slice(-45))
            ?.DCOffers.find((b) => b.BundleType === "None")?.UnFormattedPrice;

        const areOutboundPricesTheSame = outboundSmartPrice === outboundClubPrice;
        const areInboundPricesTheSame =
            props.model.FlightDataViewModel.IsOneWay || inboundSmartPrice === inboundClubPrice;

        return areOutboundPricesTheSame && areInboundPricesTheSame;
    };

    const updateBanner = async (data: BannerDTO, newUserContext: UserContext): Promise<DcBannerViewModel> => {
        if (!shouldUpdateBannerType(data)) {
            if (data.shouldScrollToBundles) {
                ScrollHelper.scrollToSelectedBundle(document.body, false);
            }

            return undefined;
        }

        const newBannerViewModel = getBannerViewModel(data, newUserContext);

        let banner: DcBannerViewModel;

        if (!newBannerViewModel) {
            if (data.shouldScrollToBundles) {
                ScrollHelper.scrollToSelectedBundle(document.body, false);
            }
        } else if (
            (newBannerViewModel.bannerType === "GetMembershipForMember" ||
                newBannerViewModel.bannerType === "GetMembershipForWA") &&
            data.selectedFlightFeeType !== "Club" &&
            arePricesTheSame({
                outboundSellKey: data.outboundSellKey,
                inboundSellKey: data.inboundSellKey,
                bundles: data.bundles,
            })
        ) {
            banner = undefined;

            if (data.shouldScrollToBundles) {
                ScrollHelper.scrollToSelectedBundle(document.body, false);
            }
        } else if (
            newBannerViewModel.bannerType === "GetMembershipForMember" ||
            newBannerViewModel.bannerType === "GetMembershipForWA"
        ) {
            if (newUserContext.dc.hasGroupMembership || newUserContext.dc.hasStandardMembership) {
                banner = undefined;
            } else {
                props.setIsLoading(true);

                const outboundBundleSet = data.bundles.find((b) => b.SellKey === data.outboundSellKey);
                const inboundBundleSet = data.inboundSellKey
                    ? data.bundles.find((b) => b.SellKey === data.inboundSellKey)
                    : undefined;

                const outboundBundleType = [...outboundBundleSet.NormalOffers, ...outboundBundleSet.DCOffers].find(
                    (b) => b.BundleCode === data.outboundBundleCode,
                )?.BundleType;

                const inboundBundleType = inboundBundleSet
                    ? [...inboundBundleSet.NormalOffers, ...inboundBundleSet.DCOffers].find(
                          (b) => b.BundleCode === data.inboundBundleCode,
                      )?.BundleType
                    : undefined;

                const currentTotal = await getTotal(
                    data.outboundSellKey,
                    data.inboundSellKey,
                    outboundBundleType,
                    inboundBundleType,
                    props.model.FlightDataViewModel.IsOneWay,
                );

                banner = { ...newBannerViewModel, currentTotal };

                props.setIsLoading(false);
            }
        } else {
            banner = newBannerViewModel;
        }

        return banner;
    };

    // EXPORTS

    const showTermsError = useMemo(
        () => state?.selectedOption && state.selectedOption !== "None" && state.termsAcceptance === "NotAccepted",
        [state?.selectedOption, state?.termsAcceptance],
    );

    const showBanner = (data: { farelockState: FlightPageFarelockState; isLoading: boolean }) => {
        if (!state) {
            return false;
        }

        const showTheLoader = data.isLoading;
        const hasViewModel = Boolean(state.bannerVM);
        const farelockIsNotOpen =
            (data.farelockState.selectedType === "None" || data.farelockState.selectedType === "Unselected") &&
            data.farelockState.selectorState !== "Open";
        const notWebAnonymousAfterSelectionAndModal =
            userContext?.isLoggedIn ||
            !hasViewModel ||
            state.bannerVM.bannerType !== "GetMembershipForWA" ||
            !state.selectedOption ||
            (state.selectedOption !== "None" && !userContext?.isLoggedIn);

        return showTheLoader || (hasViewModel && notWebAnonymousAfterSelectionAndModal && farelockIsNotOpen);
    };

    const initBanner = async (data: BannerDTO) => {
        const newState = clone(state);
        newState.bannerVM = await updateBanner(data, userContext);
        setState(newState);
    };

    const handleBancoEstadoDcUpgrade = async () => {
        const newState = clone(state);
        newState.selectedOption = "Upgrade";
        setState(newState);
    };

    const handleRegister = async (membershipType: SelectedDcMembershipType) => {
        const newState = clone(state);
        newState.bannerVM = undefined;
        newState.selectedOption = membershipType === "Standard" ? "Standard" : "Group";
        setState(newState);
    };

    const reset = () => {
        const newState = clone(state);
        newState.bannerVM = undefined;
        newState.selectedOption = undefined;
        setState(newState);
    };

    const changeTermsAcceptance = (acceptance: TermsAcceptance, option: SelectedDcMembershipType) => {
        const newState = clone(state);
        newState.termsAcceptance = acceptance;
        newState.selectedOption = option;
        setState(newState);
    };

    const selectDcMembershipOption = async (data: {
        acceptance: TermsAcceptance;
        selectedBannerOption: SelectedDcMembershipType;
    }) => {
        const newState = clone(state);
        newState.selectedOption = data.selectedBannerOption;
        newState.termsAcceptance = data.acceptance;

        if (data.selectedBannerOption === "None" && newState.bannerVM.bannerType === "GetMembershipForWA") {
            newState.bannerVM = undefined;
        }

        setState(newState);
    };

    const closeDcMembershipModal = async (data: BannerDTO) => {
        const newState = clone(state);
        newState.selectedOption = undefined;
        newState.bannerVM = await updateBanner({ ...data, selectedDcMembershipType: undefined }, userContext);
        setState(newState);
    };

    const selectBundle = async (data: BannerDTO) => {
        const newState = clone(state);
        newState.bannerVM = await updateBanner(data, userContext);
        setState(newState);

        if (!data.isLastBundleSelected && !newState.bannerVM) {
            ScrollHelper.scrollToSelectedBundle(data.rootElem, false);
        } else {
            ScrollHelper.scrollToContinueButton();
        }
    };

    const upgradeBundle = async (data: BannerDTO) => {
        const newState = clone(state);
        newState.bannerVM = await updateBanner(data, userContext);
        setState(newState);
    };

    const handleLogin = async (data: { bannerDto: BannerDTO; newUserContext: UserContext }) => {
        const newState = clone(state);

        newState.bannerVM = undefined;

        if (
            shouldUpdateBannerAfterLogin({
                newUserContext: data.newUserContext,
                selectedDcMembershipType: data.bannerDto.selectedDcMembershipType,
            })
        ) {
            newState.bannerVM = await updateBanner(data.bannerDto, data.newUserContext);
        }

        newState.selectedOption = getSelectedBannerOptionAfterLogin({
            newUserContext: data.newUserContext,
            selectedDcMembershipType: data.bannerDto.selectedDcMembershipType,
            selectedBannerOption: newState.selectedOption,
        });

        setState(newState);
    };

    useEffect(props.onChange, [state?.bannerVM, state?.selectedOption]);

    useEffect(() => {
        if (state?.bannerVM?.bannerType) ScrollHelper.scrollToBanner();
    }, [state?.bannerVM?.bannerType]);

    return {
        showTermsError,
        state,
        changeTermsAcceptance,
        closeDcMembershipModal,
        initBanner,
        handleBancoEstadoDcUpgrade,
        handleLogin,
        handleRegister,
        reset,
        selectBundle,
        selectDcMembershipOption,
        showBanner,
        updateBanner,
        upgradeBundle,
    };
};
