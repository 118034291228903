import { html, useRef, useState } from "haunted";
import i18next from "i18next";
import { classMap } from "lit-html/directives/class-map";
import { ref } from "../../directives/ref";
import { ROUTES } from "../../shared/apiRoutes";
import { showLoader } from "../../shared/common";
import { BRASILIAN_CULTURE_CODE, GIFTCARD_ACTIVATION_STEPS, USA_CULTURE_CODE } from "../../shared/commonConstants";
import { useEffect } from "../../shared/haunted/CustomHooks";
import { TestIdDictionary as T, getTestId } from "../../testing-helpers/TestIdHelper";
import { useTealiumManager } from "../../managers/Tealium/useTealiumManager";
import { useAppContext } from "../../managers/useAppContext";
import { useGiftcardActivationModal } from "./useGiftcardActivationModal";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    gRecaptchaSiteKey: string;
    isActive: boolean;
    locatorAndPin: string;
    parentRoot: HTMLDivElement;
    handleOnCodeSubmit: () => void;
    handleLogout: () => void;
}

export const useGiftcardActivationCode = (props: Props) => {
    const init = () => {
        if (!appContext.isFeatureSwitchActive("Captcha")) {
            return;
        }

        const grecaptchaInterval = window.setInterval(() => {
            const captchaContainer = captcha.current;

            if (window.grecaptcha?.render && captchaContainer) {
                setCaptchaId(
                    window.grecaptcha.render(captchaContainer, {
                        sitekey: props.gRecaptchaSiteKey,
                    }),
                );

                window.clearInterval(grecaptchaInterval);
                window.clearTimeout(grecaptchaTimeLimit);
            }
        }, 100);

        const grecaptchaTimeLimit = window.setTimeout(() => {
            window.clearInterval(grecaptchaInterval);
            setShowCaptchaLoadError(true);
        }, 3000);
    };

    const resetCaptcha = () => {
        if (window.grecaptcha?.reset) {
            window.grecaptcha.reset();
        }
    };

    const activate = async (captchaResponse: any) => {
        const loader = showLoader({});

        const response = await ajaxJsonRequest<{ Activation: boolean }>({
            loader,
            url: ROUTES.ApiRoutes.GiftcardActivate,
            container: props.parentRoot,
            body: { "locatorAndPin": code.current?.value, "g-recaptcha-response": captchaResponse ?? null },
        });

        const isValid = response.data.Activation;
        setShowCodeError(!isValid);

        return isValid;
    };

    const isValidCaptchaResponse = async (captchaResponse: any) => {
        if (captchaResponse.length === 0) {
            setShowCaptchaError(true);

            await tealiumManager.logValidationError(["captcha not validated"]);

            return false;
        }

        setShowCaptchaError(false);
        return true;
    };

    // EVENT HANDLERS
    const handleCodeBack = () => (window.location.href = "/");

    const handleCodeInput = async (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            await handleCodeSubmit();
        }
    };

    const handleCodeSubmit = async () => {
        setShowCodeError(false);

        let isValid = true;

        const captchaResponse =
            appContext.isFeatureSwitchActive("Captcha") && captchaId !== undefined
                ? window.grecaptcha.getResponse(captchaId)
                : undefined;

        if (appContext.isFeatureSwitchActive("Captcha") && captchaId !== undefined) {
            isValid = await isValidCaptchaResponse(captchaResponse);
        }

        if (isValid) {
            isValid = await activate(captchaResponse);
        }

        if (!isValid) {
            resetCaptcha();
            return;
        }

        if (userContext.isLoggedIn) {
            activationModal.open();
        } else {
            resetCaptcha();
            window.setTimeout(props.handleOnCodeSubmit, 0);
        }
    };

    // COMPONENT
    const appContext = useAppContext();
    const tealiumManager = useTealiumManager();

    const { ajaxJsonRequest } = useAjax();

    const [userContext] = useReduxState("userContext");

    const root = useRef<HTMLDivElement>(undefined);
    const code = useRef<HTMLInputElement>(undefined);
    const captcha = useRef<HTMLDivElement>(undefined);

    const [showCodeError, setShowCodeError] = useState<boolean>(false);
    const [captchaId, setCaptchaId] = useState<number>(undefined);
    const [showCaptchaError, setShowCaptchaError] = useState<boolean>(false);
    const [showCaptchaLoadError, setShowCaptchaLoadError] = useState<boolean>(false);

    const activationModal = useGiftcardActivationModal({
        onLogout: () => props.handleLogout(),
        onCancel: () => resetCaptcha(),
    });

    useEffect(init, []);
    useEffect(() => (props.isActive ? code.current?.focus() : null), [props.isActive]);

    // TEMPLATES
    const containerTemplate = () => html`
        <div class="gca-container">
            ${labelTemplate()}
            <input
                ref=${ref(code)}
                type="text"
                value=${props.locatorAndPin || ""}
                placeholder=${i18next.t("Gift-EnterActivationCodePlaceholder")}
                data-test-id=${T.GIFTCARD.ACTIVATION_CODE}
                @keydown=${handleCodeInput}
            />
        </div>
    `;

    const errorTemplate = () => html` <div class="gca-error">${i18next.t("Gift-InvalidCode")}</div> `;

    const labelTemplate = () => html`
        <div class="gca-enter-code-label">
            ${i18next.t("Gift-EnterActivationCode")}
            <div class="gca-enter-code-tooltip-trigger">?</div>
            <div class="gca-enter-code-tooltip">
                ${appContext.Culture === USA_CULTURE_CODE
                    ? html` <img src="/Images/Giftcard/tooltip-en.png" /> `
                    : appContext.Culture === BRASILIAN_CULTURE_CODE
                      ? html` <img src="/Images/Giftcard/tooltip-pt.png" /> `
                      : html` <img src="/Images/Giftcard/tooltip-es.png" /> `}
            </div>
        </div>
    `;

    const captchaLoadErrorTemplate = () => html`
        <div class="captcha-load-error">${i18next.t("CaptchaLoadError")}</div>
    `;

    const captchaErrorTemplate = () =>
        showCaptchaError
            ? html`
                  <div class="col-xs-1">
                      <div class="error-message-container elevated-error text-left">
                          <div class="form-error-message">${i18next.t("CUG2-CaptchaError")}</div>
                      </div>
                  </div>
              `
            : "";

    const captchaTemplate = () => html`
        <div class="gca-container">
            <div class="row">
                <div class="col-xs-1">
                    <div class="form-captcha-container">
                        <div ref=${ref(captcha)}></div>
                    </div>
                </div>
                ${captchaErrorTemplate()}
            </div>

            <div class="row">
                <div class="col-xs-1">${showCaptchaLoadError ? captchaLoadErrorTemplate() : ""}</div>
            </div>
        </div>
    `;

    const buttonContainerTemplate = () => html`
        <div class="gca-container">
            <div class="gca-btn-container">
                <button
                    class="rounded-secondary-btn mr-2"
                    data-test-id=${getTestId(T.GIFTCARD.ACTIVATION_BACK_BUTTON, { c: GIFTCARD_ACTIVATION_STEPS.CODE })}
                    @click=${handleCodeBack}
                >
                    ${i18next.t("Gift-Cancel")}
                </button>
                <button
                    class="rounded-primary-btn"
                    data-test-id=${getTestId(T.GIFTCARD.ACTIVATION_NEXT_BUTTON, { c: GIFTCARD_ACTIVATION_STEPS.CODE })}
                    @click=${handleCodeSubmit}
                >
                    ${i18next.t("Gift-Continue")}
                </button>
            </div>
        </div>
    `;

    const htmlTemplate = () => {
        const mainClassMap = classMap({
            "step-form": true,
            "gca-enter-code": true,
            "active": props.isActive,
        });

        return html`
            <div ref=${ref(root)} class=${mainClassMap}>
                <div class="giftcard-activation-step-info">${i18next.t("Gift-StepInfo-1")}</div>
                ${containerTemplate()} ${showCodeError ? errorTemplate() : ""}
                ${appContext.isFeatureSwitchActive("Captcha") ? captchaTemplate() : ""} ${buttonContainerTemplate()}
            </div>
            ${activationModal.htmlTemplate()}
        `;
    };

    return { htmlTemplate, code: code.current };
};
