import i18next from "i18next";
import { html } from "lit-html";
import { DeletionMode } from "./useCugAdminTravelGroupsPage";

interface Props {
    deletionMode: DeletionMode;
    groupsToDelete: number[];
    setDeletionMode: (deletionMode: DeletionMode) => void;
    handleModalClose: () => void;
}

export const useGroupDeletionModeModal = (props: Props) => {
    // TEMPLATES

    const headerTemplate = () => html`
        <span>${i18next.t("¿Quieres eliminar también a los pasajeros del grupo?")}</span>
    `;

    const contentTemplate = () => {
        return html`
            <div class="p-8">
                <div class="cug2b-modal-question mb-4 text-center sm:mb-8">
                    ${i18next.t("¿Quieres eliminar solo el grupo o también todos los pasajeros del grupo?")}
                </div>
                <div class="mt-8 flex flex-col items-center justify-center sm:mt-16 sm:flex-row">
                    <button
                        class="rounded-secondary-btn with-arrow mb-4 sm:mb-0 sm:mr-4"
                        @click=${() => props.setDeletionMode("groupsOnly")}
                    >
                        ${i18next.t("Eliminar grupo, mantener pasajeros")}
                    </button>
                    <button class="rounded-primary-btn" @click=${() => props.setDeletionMode("groupsAndPartners")}>
                        ${i18next.t("Eliminar grupo y todos los pasajeros")}
                    </button>
                </div>
            </div>
        `;
    };

    const htmlTemplate = () =>
        props.groupsToDelete?.length > 0 && props.deletionMode === "none"
            ? html`
                  <ac-cug-modal
                      .canBeClosed=${true}
                      .content=${contentTemplate()}
                      .customClass=${["cug2b-are-you-sure-group-modal", "delete-only-group-or-also-members"]}
                      .header=${headerTemplate()}
                      .isOpen=${props.groupsToDelete && props.deletionMode === "none"}
                      @close=${props.handleModalClose}
                  ></ac-cug-modal>
              `
            : "";

    return { htmlTemplate };
};
