import i18next from "i18next";
import { BANCOESTADO_LOGIN_TYPE } from "../../shared/commonConstants";
import { CLASS_NAMES } from "../../shared/classNames";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { ROUTES } from "../../shared/apiRoutes";
import { classMap } from "lit-html/directives/class-map";
import { commonDebug } from "../../bootstrap";
import { html, useRef, useState } from "haunted";
import { ref } from "../../directives/ref";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { validate } from "../../shared/form-validation";
import {
    getCoords,
    getParsedProperty,
    hideLoader,
    sanitizeRutFieldValue,
    showLoader,
    toBoolean,
} from "../../shared/common";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { useLogin } from "../login/useLogin";
import { ApiGiftcardSelectPageViewModel } from "../../component-models/giftcard/ApiGiftcardSelectPageViewModel";
import { useBasicCheckbox } from "../ui/basic-checkbox/useBasicCheckbox";
import { createFormHtmlElement } from "../../component-helpers/FormHelper";
import { useGiftcardDesigner } from "./useGiftcardDesigner";
import { useGiftcardFlowBlockedModal } from "./useGiftcardFlowBlockedModal";
import { useGiftcardSessionErrorModal } from "./useGiftcardSessionErrorModal";
import { ANTI_FORGERY_TOKEN_PROPERTY_NAME, useAjax } from "../../shared/customHooks/useAjax/useAjax";

export const name = "ac-giftcard-select-page";

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token", "model"];

export interface Attributes {
    "anti-forgery-token": string;
    "model": string;
}

export interface Props {
    antiForgeryToken: string;
    model: ApiGiftcardSelectPageViewModel;
}

interface GiftcardSelectionRequest {
    [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: string;
    Currency: string;
    DesignType: string;
    ForGift: boolean;
    Index: string;
    Message: string;
    Quantity: number;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        antiForgeryToken: host.antiForgeryToken,
        model: getParsedProperty<ApiGiftcardSelectPageViewModel>(host.model),
    };

    // HELPERS

    const createForm = () => {
        // DEVNOTE Anti-forgery token is a HTML snippet provided by the backend as is
        const body: GiftcardSelectionRequest = {
            [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: props.antiForgeryToken.split('value="')[1].split('"')[0],
            Currency: selectedCurrency,
            DesignType: designer.selectedDesignCode,
            ForGift: designer.isDesignerOpen,
            Index: props.model.CardOptions.find((card) => card.OptionName === selectedAmount).Index.toString(),
            Message: designer.message,
            Quantity: selectedQuantity + 1,
        };

        return createFormHtmlElement(ROUTES.GiftcardSelect, body);
    };

    const getWindowHeight = () =>
        window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    const getInitialAmount = () =>
        props.model.CardOptions.filter((card) => card.Currency === props.model.InitialCurrency)[0].OptionName;

    const scrollToFirstError = () => {
        const errors = Array.from(root.current.querySelectorAll(`.${CLASS_NAMES.giftcardError}`)) as HTMLElement[];
        const firstError = errors.find((error) => error.offsetHeight > 0);

        if (firstError) {
            const topOfElement = getCoords(firstError).top + 200;
            const bottomOfElement = topOfElement + firstError.offsetHeight;
            const scrollTo = bottomOfElement - getWindowHeight();

            window.scroll({ top: scrollTo, behavior: "smooth" });
        }
    };

    // EVENT LISTENERS

    const handleRutInput = (e: KeyboardEvent) => {
        const input = e.target as HTMLInputElement;
        sanitizeRutFieldValue(input);
        setRut(input?.value);
    };

    const handlePasswordInput = (e: KeyboardEvent) => {
        const input = e.target as HTMLInputElement;
        setPassword(input?.value);
    };

    const handleKeyup = async (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            await handleLoginClick();
        }
    };

    const handleCurrencyChange = (e: MouseEvent) => {
        setSelectedCurrency((e.target as HTMLSelectElement).value);
    };

    const handleAmountChange = (e: MouseEvent) => {
        setSelectedAmount((e.target as HTMLSelectElement).value);
    };

    const handleQuantityChange = (e: MouseEvent) => {
        setSelectedQuantity(Number((e.target as HTMLSelectElement).value));
    };

    const handleTermsCheckboxChange = () => {
        setIsTermsAccepted(!isTermsAccepted);
    };

    const handleForgottenPassword = () => (window.location.href = ROUTES.ForgotPasswordBancoEstado);

    const handleCancelLoginClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsForcedBancoEstadoFlow(false);
    };

    const handleLoginClick = async () => {
        setShowLoginError(false);

        const isValid = validate(root.current);

        if (isValid) {
            await doLogin();
        }
    };

    const doLogin = async () => {
        const loader = showLoader({});
        const result = await authenticator.login({
            Username: rut,
            Password: password,
            LoginType: BANCOESTADO_LOGIN_TYPE,
            Redirect: false,
        });

        if (result && result.LoginStatus === "loggedIn") {
            window.location.reload();
        } else if (!result || result.LoginStatus === "notLoggedIn") {
            setShowLoginError(true);
            hideLoader(loader);
        }
    };

    const handleSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsValidated(true);

        if (designer.isValid && isTermsAccepted) {
            const loader = showLoader({});
            try {
                const multipleGiftCardsCheckResult = await ajaxJsonRequest<any>({
                    loader,
                    container: root.current,
                    method: "GET",
                    url: ROUTES.ApiRoutes.GiftCardMultipleCheck,
                });

                if (multipleGiftCardsCheckResult.data.HasAlreadyGiftCardData) {
                    giftcardSessionErrorModal.open();
                    hideLoader(loader);
                    return;
                }
            } catch {
                commonDebug.error("Multiple Giftcard Check failed");
                hideLoader(loader);
                return;
            }

            setAreButtonsDisabled(true);
            createForm().submit();
        } else {
            scrollToFirstError();
        }
    };

    // COMPONENTS
    const root = useRef<HTMLFormElement>(undefined);

    const { ajaxJsonRequest } = useAjax();

    const [rut, setRut] = useState<string>("");
    const [password, setPassword] = useState<string>("");

    const [isForcedBancoEstadoFlow, setIsForcedBancoEstadoFlow] = useState<boolean>(toBoolean(props.model.ForceLogin));
    const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [areButtonsDisabled, setAreButtonsDisabled] = useState<boolean>(false);

    const [selectedAmount, setSelectedAmount] = useState<string>(getInitialAmount());
    const [selectedCurrency, setSelectedCurrency] = useState<string>(props.model.InitialCurrency);
    const [selectedQuantity, setSelectedQuantity] = useState<number>(props.model.Quantity);

    // for BancoEstado user
    const [showLoginError, setShowLoginError] = useState<boolean>(false);

    const authenticator = useLogin();

    const designer = useGiftcardDesigner({ isValidated, templates: props.model.Templates });

    const giftcardSessionErrorModal = useGiftcardSessionErrorModal();

    const termsCheckboxLabelTemplate = (id: string) => html`
        <label for=${id} data-test-id=${T.GIFTCARD.SELECT_ACCEPT_TERMS_LABEL}>
            <span class="giftcard-terms-and-conditions-text">${i18next.t("Gift-TermsAndConditionLabel-TextPart")}</span>
            &nbsp;
            <a
                href=${props.model.TermsLink}
                target="_blank"
                class="giftcard-terms-and-conditions-link"
                @click=${(e: MouseEvent) => e.stopPropagation()}
            >
                ${i18next.t("Gift-TermsAndConditionLabel-LinkPart")}
            </a>
        </label>
    `;

    const termsCheckbox = useBasicCheckbox({
        customWrapperClass: "giftcard",
        isChecked: isTermsAccepted,
        labelTemplate: termsCheckboxLabelTemplate,
        onClick: handleTermsCheckboxChange,
    });

    const blockedModal = useGiftcardFlowBlockedModal();

    // TEMPLATES

    const giftcardHeaderTemplate = () => html`
        <div class="giftcard-header">
            <i class="js-icon js-gift-card1"></i>
            ${i18next.t("Gift-Title-1")}
            <span>${i18next.t("Gift-Title-2")}</span>
            <div class="giftcard-header-logo">
                <img src="/Images/Header/jetsmart-logo-colored.svg" />
            </div>
        </div>
    `;

    const currencySelectorTemplate = () => html`
        <div class="col-xs-1-2 col-sm-1-2 col-md-1-2 col-lg-1-4 col-xl-2-7 xs-order-2">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                <label class="mdl-textfield__label">${i18next.t("Gift-Currency")}</label>
                <select
                    class="mdl-textfield__input js-input js-select"
                    name="Currency"
                    data-required
                    data-test-id=${T.GIFTCARD.SELECT_CURRENCY}
                    @change=${handleCurrencyChange}
                >
                    ${props.model.Currencies.map(
                        (currency) => html`
                            <option .value=${currency} .selected=${currency === selectedCurrency}>${currency}</option>
                        `,
                    )}
                </select>
            </div>
        </div>
    `;

    const amountSelectorTemplate = () => html`
        <div class="col-xs-1 col-sm-1 col-md-1 col-lg-1-2 col-xl-3-7 xs-order-1">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                <label class="mdl-textfield__label">${i18next.t("Gift-Amount")}</label>
                <select
                    class="mdl-textfield__input js-input js-select"
                    name="Index"
                    data-required
                    data-test-id=${T.GIFTCARD.SELECT_AMOUNT}
                    @change=${handleAmountChange}
                >
                    ${props.model.CardOptions.filter((card) => card.Currency === selectedCurrency).map(
                        (card) => html`
                            <option
                                class="gift-card-amount-option"
                                .value=${card.OptionName}
                                .selected=${card.OptionName === selectedAmount}
                            >
                                ${card.OptionName}
                            </option>
                        `,
                    )}
                </select>
            </div>
        </div>
    `;

    const quantitySelectorTemplate = () => html`
        <div class="col-xs-1-2 col-sm-1-2 col-md-1-2 col-lg-1-4 col-xl-2-7 xs-order-3">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                <label class="mdl-textfield__label">${i18next.t("Gift-Quantity")}</label>
                <select
                    class="mdl-textfield__input js-input js-select"
                    name="Quantity"
                    data-required
                    data-test-id=${T.GIFTCARD.SELECT_QUANTITY}
                    @change=${handleQuantityChange}
                >
                    ${[...Array(props.model.MaxAmountOfCards)].map(
                        (_, quantity) => html`
                            <option .value=${quantity + 1} .selected=${quantity === selectedQuantity}>
                                ${quantity + 1}
                            </option>
                        `,
                    )}
                </select>
            </div>
        </div>
    `;

    const formTemplate = () => html`
        <div class="giftcard-selection-form-inputs">
            <div class="row">
                ${currencySelectorTemplate()} ${amountSelectorTemplate()} ${quantitySelectorTemplate()}
            </div>
        </div>
    `;

    const quantityWarningTemplate = () =>
        isValidated && selectedQuantity > 1
            ? html` <div class="giftcard-amount-warning">${i18next.t("Gift-AmountWarning")}</div> `
            : "";

    const termsErrorTemplate = () =>
        isValidated && !isTermsAccepted
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="giftcard-error">${i18next.t("Gift-AcceptTermsError")}</div>
                      </div>
                  </div>
              `
            : "";

    const nonForcedBancoEstadoFlowTemplate = () =>
        !isForcedBancoEstadoFlow
            ? html`
                  <div>
                      <div class="giftcard-section-header">
                          <i class="js-icon js-payment"></i>
                          <div>
                              <div class="giftcard-section-title">${i18next.t("Gift-SelectGiftcardTitle")}</div>
                              <div class="giftcard-section-subtitle">${i18next.t("Gift-SelectGiftcardSubtitle")}</div>
                          </div>
                      </div>
                      ${formTemplate()} ${quantityWarningTemplate()} ${designer.htmlTemplate()}
                      ${termsCheckbox.htmlTemplate()} ${termsErrorTemplate()}
                  </div>
              `
            : html``;

    const rutInputTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label float-center">
                    <label class="mdl-textfield__label" for="loginRUT">${i18next.t("Header-RUTPlaceholder")}</label>
                    <input
                        id="loginRUT"
                        class="mdl-textfield__input js-input ts-login-rut"
                        data-required
                        .value=${rut}
                        @input=${handleRutInput}
                        @keyup=${handleKeyup}
                    />
                </div>
            </div>
        </div>
    `;

    const passwordInputTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label float-center">
                    <label class="mdl-textfield__label" for="loginPassword">${i18next.t("Header-PasswordLabel")}</label>
                    <input
                        id="loginPassword"
                        class="mdl-textfield__input js-input ts-login-pw"
                        type="password"
                        data-required
                        .value=${password}
                        @input=${handlePasswordInput}
                        @keyup=${handleKeyup}
                    />
                </div>
            </div>
        </div>
    `;

    const loginErrorTemplate = () =>
        showLoginError
            ? html` <div class="ts-login-error login-error">${i18next.t("BE-InvalidRutOrPassword")}</div> `
            : "";

    const forgottenPasswordTemplate = () => html`
        <div @click=${handleForgottenPassword} class="fgb-password-link">${i18next.t("V2-ForgottenPassword")}</div>
    `;

    const cancelLoginButtonTemplate = () => html`
        <a class="rounded-secondary-btn banco-estado-color" href="/" @click=${handleCancelLoginClick}
            >${i18next.t("Gift-Cancel")}</a
        >
    `;

    const loginButtonTemplate = () => html`
        <a class="rounded-primary-btn banco-estado-color" @click=${handleLoginClick}>${i18next.t("Gift-Login")}</a>
    `;

    const forcedBancoEstadoFlowTemplate = () =>
        isForcedBancoEstadoFlow
            ? html`
                  <div class="forced-gc-be">
                      <div class="forced-gc-be-info">${i18next.t("Gift-ForcedBeInfo")}</div>
                      ${rutInputTemplate()} ${passwordInputTemplate()}
                      <div class="fgb-container">
                          ${loginErrorTemplate()} ${forgottenPasswordTemplate()}

                          <div class="fgb-cancel-link">
                              ${unsafeHTML(
                                  i18next.t("Gift-CancelBancoEstadoLogin {{-spanStart}} {{-spanEnd}}", {
                                      spanStart: "<span @click=$>",
                                      spanEnd: "</span>",
                                  }),
                              )}
                          </div>
                      </div>
                      <div class="fgb-btn-container">${cancelLoginButtonTemplate()} ${loginButtonTemplate()}</div>
                  </div>
              `
            : html``;

    const buttonsTemplate = () => {
        const tempClassMap = classMap({
            "giftcard-section-btn-container": true,
            "disabled": areButtonsDisabled,
        });

        return html`
            ${!isForcedBancoEstadoFlow
                ? html`
                      <div class=${tempClassMap}>
                          <a class="rounded-secondary-btn" href="/"> ${i18next.t("Gift-Cancel")}</a>
                          <a
                              class="rounded-primary-btn"
                              data-test-id=${T.GIFTCARD.SELECT_SUBMIT}
                              @click=${handleSubmit}
                          >
                              ${i18next.t("V2-ApplyLabel")}
                          </a>
                      </div>
                  `
                : ""}
        `;
    };

    return html`
        <form ref=${ref(root)} autocomplete="off">
            <div id="mainContentWrapper">
                <section class="booking-wrapper giftcard-wrapper no-breadcrumb ts-error-parent">
                    ${giftcardHeaderTemplate()} ${forcedBancoEstadoFlowTemplate()} ${nonForcedBancoEstadoFlowTemplate()}
                </section>
                ${buttonsTemplate()}
            </div>
        </form>
        ${blockedModal.htmlTemplate()} ${giftcardSessionErrorModal.htmlTemplate()}
    `;
};
