import i18next from "i18next";
import { html } from "lit-html";
import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import classNames from "classnames";
import { BagType } from "../useBaggagePage";
import { BaggageSectionJourney } from "../../../../component-models/baggage/BaggageSectionJourney";

export interface Props {
    baggageSectionJourneys: BaggageSectionJourney[];
    bagType: BagType;
    isMobile: boolean;
}

export const usePerJourneyPerPaxSoldOut = (props: Props) => {
    const anySoldOut = (journeyIndex: number) =>
        props.baggageSectionJourneys.some(
            (journey) => journey.index === journeyIndex && journey.passengers.some((passenger) => passenger.isSoldOut),
        );

    const soldOutIcon = () =>
        props.bagType === "cabinBaggage"
            ? "js-bag-regular-baggage"
            : props.bagType === "checkedBaggage"
              ? "js-bag-zipper-baggage"
              : "";

    const soldOutText = () =>
        props.bagType === "cabinBaggage"
            ? i18next.t(
                  "Lo sentimos, la capacidad de equipaje de mano ya ha sido vendida en su totalidad para este tramo.",
              )
            : props.bagType === "checkedBaggage"
              ? i18next.t(
                    "Lo sentimos, la capacidad de equipaje facturado ya ha sido vendida en su totalidad para este tramo.",
                )
              : i18next.t(
                    "Lo sentimos, la capacidad de equipaje sobredimensionado ya ha sido vendida en su totalidad para este tramo.",
                );

    const htmlTemplate = (journeyIndex: number) =>
        anySoldOut(journeyIndex)
            ? html`
                  <div
                      class=${classNames("b2-baggage-sold-out-per-pax-info", {
                          "rounded-b-xl": props.baggageSectionJourneys.length === 1,
                      })}
                      data-test-id=${getTestId(T.BAGGAGE.SOLD_OUT_MESSAGE, {
                          c: props.bagType,
                          j: journeyIndex,
                          m: props.isMobile,
                      })}
                  >
                      <i class="js-icon-bag ${soldOutIcon()}"></i>
                      <span>
                          ${soldOutText()}
                          <span class="font-semibold">${i18next.t("Este opcional NO")}</span>
                          ${i18next.t("estará disponible para la venta en aeropuerto.")}
                      </span>
                  </div>
              `
            : "";

    return { htmlTemplate };
};
