import { html, useRef } from "haunted";
import { useState } from "../../../shared/haunted/CustomHooks";
import { ROUTES } from "../../../shared/apiRoutes";
import i18next from "i18next";
import { CugReservation } from "../../../component-models/CUG2b/CugBookings";
import {
    Column,
    GridState,
    PageChangeEvent,
    RowsSelectedEvent,
    SortChangeEvent,
    ViewModel,
} from "../../../dc-components/dc-table-models";
import { ref } from "../../../directives/ref";
import classNames from "classnames";
import { GridHelper } from "../../../component-helpers/GridHelper";

const actionCellClass = ["pinned"];
const actionCellContentClass = ["action-cell"];

export interface Props {
    data: CugReservation[];
    totalItemCount: number;
    gridState: GridState<keyof CugReservation>;
    canExport: boolean;
    containerElement: HTMLElement;
    gridElement: HTMLElement;
    selectedPendingIds: Set<string>;
    setSelectedPendingIds: (selectedIds: Set<string>) => void;
    setGridState: (gridState: GridState<keyof CugReservation>) => void;
    loadPendingData: () => Promise<void>;
}

export const useChileCompraOnHoldReservations = (props: Props) => {
    const inputField = useRef<HTMLInputElement>(undefined);

    const [filterExpression, setFilterExpression] = useState<string>("");

    // HELPERS

    const isDownloadSelectionDisabled = () => Array.from(props.selectedPendingIds.values()).length === 0;

    // EVENT LISTENERS

    const handleFilterByExpression = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            inputField.current?.blur();
            props.setGridState({
                ...props.gridState,
                pageIndex: 0,
                globalFilterExpression: filterExpression,
            });
        }
    };

    const handleFilterExpressionChange = (e: Event) => {
        setFilterExpression((e.target as HTMLInputElement).value.trim());
    };

    const handleDownload = (selectedOnly: boolean) => {
        window.location.href =
            ROUTES.ApiRoutes.AgencyReservationsCsv +
            `?bookingStatus=Hold` +
            `&pageSize=1000` +
            `&page=0` +
            `&orderBy=FlightDate` +
            `&orderDirection=asc` +
            `&TimeZoneOffsetMin=${(new Date().getTimezoneOffset() * -1).toString()}` +
            `&IsCsvRequested=true` +
            (selectedOnly ? `&spnrs=${Array.from(props.selectedPendingIds.values()).join("|")}` : "");
    };

    const onPageChange = (e: PageChangeEvent) => {
        props.setGridState({
            ...props.gridState,
            pageIndex: e.detail.selectedPageIndex,
            pageSize: e.detail.selectedPageSize,
        });
    };

    const onSortChange = (e: SortChangeEvent) => {
        props.setGridState({
            ...props.gridState,
            orderBy: e.detail.orderBy as keyof CugReservation,
            orderDir: e.detail.orderDir,
        });
    };

    const onRowsSelect = (event: RowsSelectedEvent) => {
        props.setSelectedPendingIds(
            GridHelper.getSelectedIds(event, props.selectedPendingIds, props.data) as Set<string>,
        );
    };

    // COMPONENTS

    // TEMPLATES

    const cellTemplate = (index: number, field: keyof CugReservation) => {
        if (!field) return "";

        const row = props.data[index];
        return html` <span title=${row[field].toString()}>${row[field].toString()}</span> `;
    };

    const filterTemplate = () => html`
        <div class="cug2b-search-and-title">
            <div class="cug2b-page-subtitle">${i18next.t("Reservas con aprobación de OC pendiente")}</div>
            <div class="cug2b-searchbox">
                <input
                    placeholder=${i18next.t("Buscar")}
                    ref=${ref(inputField)}
                    value=${props.gridState.globalFilterExpression}
                    autocomplete="cc-exp"
                    type="text"
                    name="filter"
                    @keydown=${handleFilterByExpression}
                    @input=${handleFilterExpressionChange}
                />
                <i class="js-icon-cug js-cug-search"></i>
            </div>
        </div>
    `;

    const exportTemplate = () =>
        props.canExport
            ? html`
                  <div class="mt-8 flex flex-col items-center justify-end sm:flex-row">
                      <button
                          @click=${() => handleDownload(false)}
                          class=${classNames("rounded-primary-btn cug2b-btn sm:mr-3", {
                              disabled: props.data.length === 0,
                          })}
                      >
                          ${i18next.t("CUG-Download")}
                      </button>
                      <button
                          @click=${() => handleDownload(true)}
                          class=${classNames("rounded-primary-btn cug2b-btn mt-4 sm:mt-0", {
                              disabled: isDownloadSelectionDisabled(),
                          })}
                      >
                          ${i18next.t("CUG-DownloadSelected")}
                      </button>
                  </div>
              `
            : "";

    const vm: ViewModel<keyof CugReservation> = {
        columns: [
            {
                field: "ReservationCode",
                columnType: "string",
                label: i18next.t("Código reserva"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "ReservationCode"),
            } as Column<keyof CugReservation>,
            {
                field: "Route",
                columnType: "string",
                label: i18next.t("Ruta"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "Route"),
            } as Column<keyof CugReservation>,
            {
                field: "FormattedFlightDate",
                columnType: "string",
                label: i18next.t("Fecha de vuelo"),
                sortable: true,
                headerTemplate: html`
                    <div class="flex items-center">
                        ${i18next.t("Fecha de vuelo")}
                        <ac-tooltip
                            class="hidden-xs"
                            .icon=${"?"}
                            .tooltip=${i18next.t("CUG2-FlightDateTooltip")}
                            onclick="event.stopPropagation();"
                        ></ac-tooltip>
                    </div>
                `,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "FormattedFlightDate"),
            } as Column<keyof CugReservation>,
            {
                field: "FormattedExpiryDate",
                columnType: "string",
                label: i18next.t("Fecha expiración"),
                sortable: true,
                headerTemplate: html`
                    <div class="flex items-center">
                        ${i18next.t("Fecha expiración")}
                        <ac-tooltip
                            class="hidden-xs"
                            .icon=${"?"}
                            .tooltip=${i18next.t("CUG3-ExpiryDateTooltip")}
                            onclick="event.stopPropagation();"
                        ></ac-tooltip>
                    </div>
                `,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "FormattedExpiryDate"),
            } as Column<keyof CugReservation>,
            {
                field: "CreatedByAgent",
                columnType: "string",
                label: i18next.t("Usuario"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "CreatedByAgent"),
            } as Column<keyof CugReservation>,
            {
                field: "FormattedTotalAmount",
                columnType: "string",
                label: i18next.t("Monto"),
                sortable: true,
                columnClass: "last-column",
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "FormattedTotalAmount"),
            } as Column<keyof CugReservation>,
        ],
        data: props.data,
        paging: {
            pageable: true,
            pageIndex: props.gridState.pageIndex,
            pageSize: props.gridState.pageSize,
            buttonCount: 5,
            pageSizes: [10],
            itemCount: props.totalItemCount,
            showInfo: false,
        },
        sorting: {
            orderBy: props.gridState.orderBy,
            orderDir: props.gridState.orderDir,
            showSorterArrow: false,
        },
        selection: {
            selectable: true,
            selectedIndices: Array.from(props.selectedPendingIds.values()).map((id) =>
                props.data.map((i) => i.Id).indexOf(id),
            ),
        },
        rowCustomization: [],
        appliedFilters: [],
        actionCellClass,
        actionCellContentClass,
        withPinnedColumn: false,
        useEllipsis: false,
    };

    const htmlTemplate = () =>
        props.gridState && props.data
            ? html`
                  ${filterTemplate()}
                  <dc-table-grid
                      .vm=${vm}
                      @onSortChange=${onSortChange}
                      @onRowsSelect=${onRowsSelect}
                      @onPageChange=${onPageChange}
                  ></dc-table-grid>
                  ${exportTemplate()}
              `
            : "";

    return { htmlTemplate };
};
