import { html } from "haunted";
import i18next from "i18next";

export interface Props {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
}

export const usePartnerSaveSuccessfulModal = (props: Props) => {
    // HELPERS

    // EVENT HANDLERS

    // COMPONENT

    // TEMPLATES

    const headerTemplate = () => html`
        <i class="js-icon js-broken-circle-exclamation big-modal-icon absolute left-0 ml-8 vertical-center"></i>
        <span>${i18next.t("Importante")}</span>
    `;

    const contentTemplate = () => html`
        <div class="p-8">
            <div class="cug2b-modal-question mb-4 sm:mb-8">
                ${i18next.t("La información ha sido guardada exitosamente.")}
            </div>
            <div class="cug2-button-container-in-success-modal">
                <button class="rounded-primary-btn" @click=${() => props.setIsOpen(false)}>
                    ${i18next.t("OK")}
                </button>
            </div>
        </div>
    `;

    const htmlTemplate = () => html`
        <ac-cug-modal
            .canBeClosed=${true}
            .content=${contentTemplate()}
            .customClass=${"cug2b-save-success-modal"}
            .header=${headerTemplate()}
            .isOpen=${props.isOpen}
            @close=${() => props.setIsOpen(false)}
        ></ac-cug-modal>
    `;

    return { htmlTemplate };
};
