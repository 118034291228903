import { html } from "haunted";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import { Partner } from "../../../component-models/CUG2b/Partner";
import i18next from "i18next";

export interface Props {
    partner: Partner;
    travelPartnerInfo: TravelPartnerInfo;
    setPartnerToEdit: (partner: Partner | undefined) => void;
    handleEditSubmit: (partner: Partner) => Promise<void>;
}

export const useEditPartnerModal = (props: Props) => {
    // HELPERS

    // EVENT HANDLERS

    // COMPONENT

    // TEMPLATES

    const headerTemplate = () => html`
        <span class="cug2b-header-edit-travel-partner-modal"
            >${i18next.t("Editar información pasajero frecuente")}</span
        >
    `;

    const contentTemplate = () => html`
        <div class="cug2b-travel-partner-container">
            <ac-travel-partner
                .model=${props.travelPartnerInfo}
                .showCancelButton=${false}
                .vm=${props.partner}
                .handleEditSubmit=${props.handleEditSubmit}
            ></ac-travel-partner>
        </div>
    `;

    const htmlTemplate = () => html`
        <ac-cug-modal
            .canBeClosed=${true}
            .content=${contentTemplate()}
            .customClass=${"cug2b-edit-travel-partner"}
            .header=${headerTemplate()}
            .isOpen=${Boolean(props.partner)}
            @close=${() => props.setPartnerToEdit(undefined)}
        ></ac-cug-modal>
    `;

    return { htmlTemplate };
};
