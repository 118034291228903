import { handleCugLoader, getRequestBodyFromNamedInputs } from "../../../shared/common";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";

export const usePortalProfileUtils = () => {
    const { ajaxRequest } = useAjax();

    // EXPORTS

    const handleUserSubmit = async (formElement: HTMLFormElement, setEditUserMode: (editUserMode: boolean) => void) => {
        handleCugLoader(formElement, "editUser");

        await ajaxRequest({
            form: formElement,
            body: getRequestBodyFromNamedInputs(formElement),
            onResponseCode: {
                200: () => setEditUserMode(false),
            },
        });

        handleCugLoader(formElement, "editUser");
    };

    return { handleUserSubmit };
};
