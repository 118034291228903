import { html } from "lit-html";
import { useModal } from "../../shared/useModal";
import i18next from "i18next";

export const useChileCompraNoSeatsModal = () => {
    const contentTemplate = () => html`
        <div class="flex flex-col items-center pt-6 text-center text-base leading-normal text-be-blue">
            <div>${i18next.t("No hemos logrado asignarte un asiento en estos momentos.")}</div>
            <div>${i18next.t("Por favor, contacte a soporte.")}</div>
            <a class="text-be-blue hover:text-brand-primary" href="mailto:soporte.chilecompra@jetsmart.com">
                soporte.chilecompra@jetsmart.com
            </a>
        </div>
        <div class="my-6 flex w-full justify-center">
            <button class="rounded-primary-btn" @click=${modal.close}>${i18next.t("Entendido")}</button>
        </div>
    `;

    const modal = useModal({
        content: {
            classNames: "",
            template: contentTemplate,
        },
        header: {
            classNames: "text-center",
            template: () => html`
                <div class="relative flex h-14 items-center justify-center rounded-t-lg bg-be-red text-white">
                    <span class="font-emphasis text-2xl">${i18next.t("Importante")}</span>
                    <button
                        class="absolute -right-4 -top-4 m-0 flex h-8 w-8 items-center justify-center rounded-full border-none bg-[#484848] p-0 text-2xl text-white"
                        @click=${modal.close}
                    >
                        &times;
                    </button>
                </div>
            `,
        },
        overlay: { classNames: "primary-modal" },
    });
    return modal;
};
