import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import { html } from "lit-html";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { BACKPACK_AND_CABIN_BAG_IMG, BACKPACK_IMG } from "../../spa-container";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useBaggagePriceBreakdown } from "./useBaggagePriceBreakdown";
import { baggageIllustrations } from "../common/baggageIllustrations";
import { StaffBaggage } from "../common/useStaffBaggage";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import classNames from "classnames";
import { BaggagePageState } from "../../../../component-models/baggage/BaggagePageState";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useCallback } from "haunted";
import { useBaggageUtils } from "../common/useBaggageUtils";
import { BancoEstadoBaggage } from "../common/useBancoEstadoBaggage";

export interface Props {
    baggageSection: BaggageSection;
    bancoEstadoBaggage: BancoEstadoBaggage;
    pageState: BaggagePageState;
    staffBaggage: StaffBaggage;
}

export const usePerBookingPaidCabin = (props: Props) => {
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const { formattedLowestNextPrice } = useBaggageUtils();

    const isPerJourneyPerPaxViewOpen = props.baggageSection.sectionUi.uiState.perJourneyPerPaxState === "open";

    const isPaidBagSelected = props.baggageSection.perBooking.isPaidBagSelected;

    const priceBreakdown = useBaggagePriceBreakdown({
        baggageSectionJourneys: props.baggageSection.journeys,
        isShown: isPaidBagSelected && !props.bancoEstadoBaggage?.state?.length,
        staffBaggage: props.staffBaggage,
    });

    const { backpackTooltipTemplate, regularBagTooltipTemplate } = baggageIllustrations();

    const unformattedLowestNextPrice = useCallback(
        (isOriginal: boolean, isMinimum: boolean) =>
            props.baggageSection.perBooking.nextPrice({
                baggageSectionJourneys: props.baggageSection.journeys,
                bagType: "cabinBaggage",
                bancoEstadoBaggage: props.bancoEstadoBaggage,
                isMinimum,
                isOriginal,
                staffBaggage: props.staffBaggage,
            }),
        [props.baggageSection.journeys, props.staffBaggage, props.bancoEstadoBaggage],
    );

    const showOriginalMinimumPrice = useMemo(
        () =>
            flowContext.isBookingFlow &&
            bookingContext.isDiscountBooking &&
            !isPaidBagSelected &&
            unformattedLowestNextPrice(true, true),
        [bookingContext.isDiscountBooking, flowContext.isBookingFlow, isPaidBagSelected, unformattedLowestNextPrice],
    );

    const handleAdd = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!isPerJourneyPerPaxViewOpen && !isPaidBagSelected) await props.baggageSection.add();
    };

    const priceTemplate = () =>
        props.baggageSection.journeys.every((journey) => journey.bundleType === "None")
            ? html` ${noBundleOriginalPriceTemplate()} ${noBundlePriceTemplate()} `
            : "";

    const noBundleOriginalPriceTemplate = () =>
        showOriginalMinimumPrice
            ? html`
                  <div class="b2-per-booking-next-price">
                      <span
                          class="line-through"
                          data-test-value=${unformattedLowestNextPrice(true, true)}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_CROSSED_OUT_MINIMUM_PRICE, {
                              c: "cabinBaggage",
                          })}
                      >
                          ${formattedLowestNextPrice(unformattedLowestNextPrice(true, true))}
                      </span>
                  </div>
              `
            : "";

    const noBundlePriceTemplate = () => {
        const unformattedAmount = unformattedLowestNextPrice(false, true);

        return !isPaidBagSelected && unformattedAmount >= 0
            ? html`
                  <div class="b2-per-booking-next-price">
                      ${i18next.t("Desde")}
                      <span
                          class="b2-amount"
                          data-test-value=${unformattedAmount}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_MINIMUM_PRICE, {
                              c: "cabinBaggage",
                          })}
                      >
                          ${formattedLowestNextPrice(unformattedAmount)}
                      </span>
                  </div>
              `
            : "";
    };

    const addButtonDisabledTemplate = () =>
        isPaidBagSelected
            ? html`
                  <button disabled class="b2-primary-button">${i18next.t("Agregado")}</button>
                  ${priceBreakdown.htmlTemplate()}
              `
            : "";

    const addButtonEnabledTemplate = () =>
        !isPaidBagSelected
            ? html`
                  <button
                      class="b2-primary-button"
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_ADD_FIRST_BUTTON, { c: "cabinBaggage", m: false })}
                      @click=${handleAdd}
                  >
                      ${i18next.t("Agregar")}
                  </button>
              `
            : "";

    const footerTemplate = () => {
        const tempClassMap = classNames("b2-paid-option-btn-container b2-openable", {
            "b2-opened": !isPerJourneyPerPaxViewOpen,
        });

        return html`
            <div
                class=${tempClassMap}
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_BUTTON_CONTAINER, { c: "cabinBaggage" })}
            >
                ${priceTemplate()} ${addButtonEnabledTemplate()} ${addButtonDisabledTemplate()}
            </div>
        `;
    };

    const illustrationTemplate = () => html`
        <div class="b2-illustration">
            <div
                class="b2-illustration-ribbon"
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_RIBBON, {
                    c: "cabinBaggage",
                })}
            >
                ${i18next.t("¡El más vendido!")}
            </div>
            <div class="flex w-full items-center justify-center">
                <img class="b2-paid-cabin-img" src=${BACKPACK_IMG} />
                <img class="b2-paid-cabin-img" src=${BACKPACK_AND_CABIN_BAG_IMG} />
            </div>
            <div class="b2-weight-container-dt">
                <div
                    class="b2-weight"
                    data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_WEIGHT, {
                        c: "cabinBaggage",
                    })}
                >
                    10kg.
                </div>
                <div
                    class="b2-weight"
                    data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_WEIGHT, {
                        c: "cabinBaggage",
                    })}
                >
                    10kg.
                </div>
            </div>
            <div class="b2-dimension-container-dt">
                <div class="b2-dimensions">35cm &times; 25cm &times; 45cm ${backpackTooltipTemplate()}</div>
                <div class="b2-dimensions">35cm &times; 25cm &times; 55cm ${regularBagTooltipTemplate()}</div>
            </div>
            <div
                class="b2-bag-names-container "
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_NAME, { c: "cabinBaggage" })}
            >
                <div class="b2-bag-names">
                    ${unsafeHTML(i18next.t("Mochila o artículo {{-newLine}} personal", { newLine: "<br />" }))}
                </div>
                <div class="b2-bag-names">
                    ${unsafeHTML(i18next.t("Equipaje de {{-newLine}} mano", { newLine: "<br />" }))}
                </div>
            </div>
        </div>
    `;

    const htmlTemplate = () => {
        const containerClassMap = classNames("b2-paid-bag-option", {
            "selected": isPaidBagSelected,
            "per-pax": isPerJourneyPerPaxViewOpen,
            "hoverable": !isPerJourneyPerPaxViewOpen && !isPaidBagSelected,
        });

        return html`
            <div
                class=${containerClassMap}
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION, { c: "cabinBaggage" })}
                @click=${handleAdd}
            >
                ${illustrationTemplate()} ${footerTemplate()}
            </div>
        `;
    };

    return { htmlTemplate };
};
