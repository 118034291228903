import { useBundleSelectedMobile } from "./bundles/useBundleSelectedMobile";
import { TestIdDictionary as T, getTestId } from "../../testing-helpers/TestIdHelper";
import { FlightOptionModel } from "../../component-models/flight/FlightOptionModel";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { html, useRef } from "haunted";
import i18next from "i18next";
import { INBOUND, OUTBOUND } from "../../shared/commonConstants";
import { ref } from "../../directives/ref";
import { CLASS_NAMES } from "../../shared/classNames";
import { StationSettings } from "../../component-models/StationSettings";
import { useBundlesSelector } from "./bundles/useBundlesSelector";
import { useFlightInfo } from "./useFlightInfo";
import { useBundleSelected } from "./bundles/useBundleSelected";
import { useBundleUpgradeOffer } from "./bundles/useBundleUpgradeOffer";
import { FlightPageContext } from "../../component-models/flight/contexts/FlightPageContext";
import { FlightSelectDTO } from "./contexts/useFlightPageContext";
import classNames from "classnames";
import { useFlightOption } from "./useFlightOption";
import { useMemo } from "../../shared/haunted/CustomHooks";
import dayjs from "dayjs";
import { useTooManyPaxModal } from "./flight-page-modals/useTooManyPaxModal";
import { BundleType } from "../../component-models/flight/BundleOffersV2Model";
import { useFlowContext } from "../../managers/useFlowContext";

export const name = "ac-animated-container";

export interface Props {
    context: FlightPageContext;
    flightOption: FlightOptionModel;
    isBrazilianStationSelected: boolean;
    isInFlightMove: boolean;
    isValidated: boolean;
    journeyIndex: number;
    referenceObject: HTMLElement;
    stationSettings: StationSettings;
    flightSelect: (data: FlightSelectDTO) => Promise<void>;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        context: host.context,
        flightOption: host.flightOption,
        isBrazilianStationSelected: host.isBrazilianStationSelected,
        isInFlightMove: host.isInFlightMove,
        isValidated: host.isValidated,
        journeyIndex: host.journeyIndex,
        referenceObject: host.referenceObject,
        stationSettings: host.stationSettings,
        flightSelect: host.flightSelect,
    };

    const flowContext = useFlowContext();

    const rootElem = useRef<HTMLDivElement>(undefined);

    const tooManyPaxModal = useTooManyPaxModal();

    const bundleUpgradeOffer = useBundleUpgradeOffer({ context: props.context, journeyIndex: props.journeyIndex });

    const bundleSelected = useBundleSelected({
        allBundlesSelected: false,
        context: props.context,
        isUpgradingBundle: bundleUpgradeOffer.isSizeReduced,
        journeyIndex: props.journeyIndex,
    });

    const bundleSelectedMobile = useBundleSelectedMobile({
        allBundlesSelected: false,
        context: props.context,
        isUpgradingBundle: bundleUpgradeOffer.isSizeReduced,
        journeyIndex: props.journeyIndex,
    });

    const flightInfo = useFlightInfo({
        context: props.context,
        flightOption: props.flightOption,
        isBrazilianStationSelected: props.isBrazilianStationSelected,
        isInFlightMove: props.isInFlightMove,
    });

    const bundlesSelector = useBundlesSelector({
        bundles: props.context?.bundleState?.bundleOffers.find((b) => b.SellKey === props.flightOption.SellKey),
        context: props.context,
        journeyIndex: props.flightOption.JourneyIndex,
        onBundleSelect: (type: BundleType) => handleBundleSelect(type),
    });

    const isFlightCompatibleWithOtherJourney = useMemo(() => {
        if (!props.context?.flightState) return true;

        const outbound = props.context.flightState.selectedOutboundFlight;
        const inbound = props.context.flightState.selectedInboundFlight;
        const journeyIndex = props.flightOption.JourneyIndex;

        if (journeyIndex === OUTBOUND && inbound) {
            const [arrivalHour, arrivalMinute] = props.flightOption.FlightSelectModel.Sta24H.split(":");
            const fullArrivalDate = dayjs(props.flightOption.ArrivalDate)
                .add(Number(arrivalHour), "hour")
                .add(Number(arrivalMinute), "minute");

            const [departureHour, departureMinute] = inbound.FlightSelectModel.Std24H.split(":");
            const minTime = dayjs(inbound.DepartureDate)
                .add(Number(departureHour) - 1, "hour")
                .add(Number(departureMinute), "minute");

            return fullArrivalDate.isSameOrBefore(minTime, "minute");
        }

        if (journeyIndex === INBOUND && outbound) {
            const [departureHour, departureMinute] = props.flightOption.FlightSelectModel.Std24H.split(":");
            const fullDepartureDate = dayjs(props.flightOption.DepartureDate)
                .add(Number(departureHour), "hour")
                .add(Number(departureMinute), "minute");

            const [arrivalHour, arrivalMinute] = outbound.FlightSelectModel.Sta24H.split(":");
            const minTime = dayjs(outbound.ArrivalDate)
                .add(Number(arrivalHour) + 1, "hour")
                .add(Number(arrivalMinute), "minute");

            return fullDepartureDate.isSameOrAfter(minTime, "minute");
        }

        return true;
    }, [props.context, props.flightOption]);

    const flightOption = useFlightOption({
        context: props.context,
        disabled: !isFlightCompatibleWithOtherJourney,
        flightOption: props.flightOption,
        isBrazilianStationSelected: props.isBrazilianStationSelected,
        isInFlightMove: props.isInFlightMove,
        journeyIndex: props.journeyIndex,
        referenceObject: props.referenceObject,
        rootElem: rootElem.current,
        flightSelect: props.flightSelect,
    });

    const flightSelectionDone = useMemo(
        () =>
            props.flightOption.JourneyIndex === OUTBOUND
                ? props.context.flightState?.selectedOutboundFlight
                : props.context.flightState?.selectedInboundFlight,
        [
            props.flightOption.JourneyIndex,
            props.context.flightState,
            props.context.flightState?.selectedOutboundFlight,
            props.context.flightState?.selectedInboundFlight,
        ],
    );

    const bundleSelectionDone = useMemo(
        () =>
            props.flightOption.JourneyIndex === OUTBOUND
                ? props.context.bundleState?.selectedOutboundBundle
                : props.context.bundleState?.selectedInboundBundle,
        [
            props.flightOption.JourneyIndex,
            props.context.bundleState,
            props.context.bundleState?.selectedOutboundBundle,
            props.context.bundleState?.selectedInboundBundle,
        ],
    );

    const isBundleSelectorOpen = useMemo(() => {
        if (props.context.bundleState?.bundlesMode !== "PerLeg") return false;
        return flightSelectionDone && !bundleSelectionDone;
    }, [props.context.bundleState, flightSelectionDone, bundleSelectionDone]);

    const showSelectedView = useMemo(
        () => props.context.showSelectedView(props.flightOption),
        [props.flightOption, props.context.showSelectedView],
    );

    const showAlternativeFlights = useMemo(() => {
        if (!props.context?.bundleState) return true;

        if (props.context.bundleState.bundlesMode !== "PerLeg") return !showSelectedView;

        return !flightSelectionDone || !bundleSelectionDone;
    }, [props.context.bundleState, flightSelectionDone, bundleSelectionDone, showSelectedView]);

    const showThisFee = useMemo(
        () => props.flightOption && (showAlternativeFlights || props.context.isThisFeeSelected(props.flightOption)),
        [props.flightOption, props.context, props.context.isThisFeeSelected],
    );

    const showBundleSummary = useMemo(() => {
        if (props.context.bundleState?.bundlesMode !== "PerLeg") return false;
        return bundleSelectionDone;
    }, [bundleSelectionDone, props.context]);

    const showOneBundleSummary = useMemo(() => {
        if (props.context.bundleState?.bundlesMode !== "PerLeg") return false;

        const isOnlyOneBundleSelected =
            [props.context.bundleState.selectedOutboundBundle, props.context.bundleState.selectedInboundBundle].filter(
                (b) => b,
            ).length === 1;

        return isOnlyOneBundleSelected && bundleSelectionDone;
    }, [props.context.bundleState, bundleSelectionDone]);

    const showBundlesSelectorInSelection = useMemo(() => {
        if (props.context.bundleState?.bundlesMode !== "PerLeg") return false;
        return props.context.isThisFeeSelected(props.flightOption) && !bundleSelectionDone;
    }, [props.flightOption, props.context.bundleState, bundleSelectionDone, props.context.isThisFeeSelected]);

    const showBundleSelectionError = useMemo(() => {
        if (props.context.bundleState?.bundlesMode !== "PerLeg") return false;
        return (
            props.isValidated &&
            !props.context.showFlightSelectError(props.journeyIndex, props.isValidated) &&
            showBundlesSelectorInSelection
        );
    }, [props.context.bundleState, props.isValidated, props.journeyIndex, showBundlesSelectorInSelection]);

    // HELPERS

    const shouldShowTooManyPaxModal = (type: BundleType) =>
        (type === "Full" || type === "Simple") &&
        props.context.model.DiscountClubViewModel.ShowTooManyPaxModal &&
        props.context.flightState.selectedFeeType === "Club" &&
        !props.context.dcState.isTooManyPaxModalAlreadyShown;

    // EVENT LISTENERS

    const handleEdit = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.context.resetFlight(props.journeyIndex, rootElem.current);
    };

    const handleBundleSelect = (type: BundleType) => {
        if (shouldShowTooManyPaxModal(type)) {
            tooManyPaxModal.open();
            props.context.showTooManyPaxModal();
        }
    };

    // TEMPLATES

    const bundleErrorTemplate = () =>
        showBundleSelectionError
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message ${CLASS_NAMES.flightSelectError}">
                                  ${i18next.t("V2-SelectBundlesError")}
                              </div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const flightInfoForBundlesTemplate = () => {
        const tempClassNames = classNames("col-xs-1 py-2", {
            "col-lg-offset-1-6 col-lg-2-3 pb-4": showOneBundleSummary,
            "col-lg-2-5": showSelectedView && !showOneBundleSummary && !showBundlesSelectorInSelection,
            "col-lg-3-5 col-lg-offset-1-5": showBundlesSelectorInSelection,
        });

        return showBundlesSelectorInSelection || showOneBundleSummary
            ? html`
                  <div
                      class=${tempClassNames}
                      data-test-id=${getTestId(T.FLIGHT.FLIGHT_INFO, {
                          j: props.flightOption.JourneyIndex,
                          i: props.flightOption.Index,
                      })}
                  >
                      ${flightInfo.htmlTemplate()}
                  </div>
              `
            : "";
    };

    const bundleSelectorTemplate = () =>
        showBundlesSelectorInSelection
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div
                              data-test-id=${getTestId(T.BUNDLE.SELECTOR, { j: props.flightOption.JourneyIndex })}
                              class=${classNames("overflow-hidden common-transition", {
                                  "max-h-0 opacity-0": !isBundleSelectorOpen,
                                  "max-height-[1070px] opacity-100": isBundleSelectorOpen,
                              })}
                          >
                              ${bundlesSelector.htmlTemplate()}
                          </div>
                      </div>
                  </div>
              `
            : "";

    const selectedBundleTemplate = () =>
        showBundleSummary
            ? html`
                  ${bundleSelected.htmlTemplate()} ${bundleSelectedMobile.htmlTemplate()}
                  ${bundleUpgradeOffer.htmlTemplate()}
              `
            : "";

    const editBundleTemplate = () =>
        showBundleSummary
            ? html`
                  <i
                      class="js-icon js-edit absolute right-[10px] top-[10px] z-[1] cursor-pointer text-lg text-brand-secondary hover:text-brand-primary"
                      data-test-id=${getTestId(T.BUNDLE.EDIT_SELECTED, { j: props.flightOption.JourneyIndex })}
                      @click=${handleEdit}
                  ></i>
              `
            : "";

    const flightOptionTemplate = () => html`
        <div class=${classNames("row", { disabled: !isFlightCompatibleWithOtherJourney })}>
            ${!showBundlesSelectorInSelection && !showOneBundleSummary
                ? flightOption.htmlTemplate()
                : flightInfoForBundlesTemplate()}
        </div>
    `;

    const contentTemplate = () => {
        const tempClassNames = classNames("selected-flight", {
            "dc": props.context?.flightState?.selectedFeeType === "Club",
            "tall": isBundleSelectorOpen,
            "show-offers":
                !flowContext.isFlightMove &&
                props.context.showBundleUpgradeOffer(
                    props.journeyIndex === OUTBOUND
                        ? props.context?.bundleState?.selectedOutboundBundle
                        : props.context?.bundleState?.selectedInboundBundle,
                ),
        });

        return html`
            <div class=${tempClassNames}>
                ${editBundleTemplate()} ${flightOptionTemplate()} ${bundleSelectorTemplate()}
                ${selectedBundleTemplate()}
            </div>
        `;
    };

    const containerTemplate = () => html`
        <div
            class="${CLASS_NAMES.flightSelectedFlightContainer} mx-auto mb-5 h-full w-full longer-transition"
            data-test-id=${getTestId(T.FLIGHT.FEE_OPTION, {
                j: props.flightOption.JourneyIndex,
                i: props.flightOption.Index,
            })}
        >
            <div class="row">
                <div class=${classNames("col-xs-1", { "col-sm-1-2": showOneBundleSummary })}>${contentTemplate()}</div>
            </div>
        </div>
    `;

    return showThisFee
        ? html`
              <li
                  class=${classNames({
                      wide: showSelectedView,
                      tall: showBundlesSelectorInSelection || showBundleSummary,
                      disabled: !isFlightCompatibleWithOtherJourney,
                  })}
                  ref=${ref(rootElem)}
              >
                  ${bundleErrorTemplate()} ${containerTemplate()} ${tooManyPaxModal.htmlTemplate()}
              </li>
          `
        : html``;
};
