import { html, useRef, useState } from "haunted";
import { Partner } from "../../../component-models/CUG2b/Partner";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import {
    Column,
    GridState,
    PageChangeEvent,
    RowsSelectedEvent,
    SortChangeEvent,
    ViewModel,
} from "../../../dc-components/dc-table-models";
import { ref } from "../../../directives/ref";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import i18next from "i18next";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useDeletePartnerModal } from "./useDeletePartnerModal";
import { GridHelper } from "../../../component-helpers/GridHelper";
import { TravelPartnerHelper } from "../../../component-helpers/TravelPartnerHelper";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { ROUTES } from "../../../shared/apiRoutes";
import { handleCugLoader } from "../../../shared/common";
import { URL_VARS, URI_DIVIDER_CHARACTER } from "../../../shared/commonConstants";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import { usePartnerSaveSuccessfulModal } from "./usePartnerSaveSuccessfulModal";
import { useEditPartnerModal } from "./useEditPartnerModal";
import { useMemo } from "../../../shared/haunted/CustomHooks";
import classNames from "classnames";
import { usePortalTravelPartnersUtils } from "./usePortalTravelPartnersUtils";

const actionCellClass = ["w-16", "sm:w-16", "md:w-32", "p-0", "pinned-on-mobile"];
const actionCellContentClass = ["w-16", "sm:w-16", "md:w-32", "action-cell-user"];

export const useCugTravelPartnersPage = () => {
    const root = useRef<HTMLDivElement>(undefined);

    const { getTravelPartnerInfo, postTravelPartnerInfo } = useAjax();
    const { orderedModel, isAnythingSelected } = usePortalTravelPartnersUtils();

    const initialGridState = useMemo<GridState<keyof Partner>>(
        () => ({
            pageIndex: 0,
            appliedFilters: [],
            pageSize: 10,
            orderBy: "LastName",
            orderDir: "asc",
            globalFilterExpression: "",
        }),
        [],
    );

    const [travelPartnerInfo, setTravelPartnerInfo] = useState<TravelPartnerInfo>(undefined);
    const [gridState, setGridState] = useState<GridState<keyof Partner>>(initialGridState);
    const [filterExpression, setFilterExpression] = useState<string>("");
    const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set<number>());

    const [partnerToEdit, setPartnerToEdit] = useState<Partner>(undefined);
    const [partnersToDelete, setPartnersToDelete] = useState<number[]>(undefined);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);

    const partners = useMemo(() => travelPartnerInfo?.Partners, [travelPartnerInfo]);

    // HELPERS

    const init = () => {
        const doIt = async () => {
            const result = await getTravelPartnerInfo();

            setTravelPartnerInfo(result);
        };

        void doIt();
    };

    // EVENT HANDLERS

    const deletePartners = async () => {
        const newTravelPartnerInfo = TravelPartnerHelper.deletePartners(travelPartnerInfo, partnersToDelete);

        setPartnersToDelete(undefined);
        handleCugLoader(root.current, "loadData");

        const result = await postTravelPartnerInfo(newTravelPartnerInfo);

        setTravelPartnerInfo(result);

        handleCugLoader(root.current, "loadData");
    };

    const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            setGridState({
                ...gridState,
                pageIndex: 0,
                globalFilterExpression: filterExpression,
            });
        }
    };

    const handleFilterChange = (e: Event) => {
        setFilterExpression((e.target as HTMLInputElement).value.trim());
    };

    const onPageChange = (e: PageChangeEvent) => {
        setGridState({
            ...gridState,
            pageIndex: e.detail.selectedPageIndex,
            pageSize: e.detail.selectedPageSize,
        });

        ScrollHelper.scrollToTop();
    };

    const onSortChange = (e: SortChangeEvent) => {
        setGridState({
            ...gridState,
            orderBy: e.detail.orderBy as keyof Partner,
            orderDir: e.detail.orderBy !== gridState.orderBy ? "asc" : e.detail.orderDir,
        });
    };

    const onRowsSelect = (event: RowsSelectedEvent) => {
        setSelectedIds(
            GridHelper.getSelectedIds(
                event,
                selectedIds,
                orderedModel(travelPartnerInfo, gridState, partners),
            ) as Set<number>,
        );
    };

    const handleSingleDelete = async (e: MouseEvent, row: Partner) => {
        e.preventDefault();
        e.stopPropagation();

        setPartnersToDelete([row.Id]);
    };

    const handleMultiDelete = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setPartnersToDelete(Array.from(selectedIds.values()));
    };

    const handleEditSubmit = async (partner: Partner) => {
        const newTravelPartnerInfo = TravelPartnerHelper.updatePartner(travelPartnerInfo, partner);

        handleCugLoader(root.current, "loadData");

        const result = await postTravelPartnerInfo(newTravelPartnerInfo);

        setTravelPartnerInfo(result);

        handleCugLoader(root.current, "loadData");

        if (result.Partners?.length > 0) {
            setIsSuccessModalOpen(true);
        }

        setPartnerToEdit(undefined);
    };

    const handleGrouping = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        handleCugLoader(root.current, "loadData");

        const uriParams = `${URL_VARS.FFLYER_IDS}=${Array.from(selectedIds.values()).join(URI_DIVIDER_CHARACTER)}`;
        const newUrl = `${ROUTES.Cug2BAddGroupPage}?${uriParams}`;

        window.location.href = newUrl;
    };

    // COMPONENT

    const deletePartnerModal = useDeletePartnerModal({
        partnersToDelete,
        travelPartnerInfo,
        handleDelete: deletePartners,
        setPartnersToDelete,
    });

    const editPartnerModal = useEditPartnerModal({
        partner: partnerToEdit,
        travelPartnerInfo,
        setPartnerToEdit,
        handleEditSubmit,
    });

    const saveSuccessModal = usePartnerSaveSuccessfulModal({
        isOpen: isSuccessModalOpen,
        setIsOpen: setIsSuccessModalOpen,
    });

    // TEMPLATES

    const staticStuffTemplate = () => html`
        <div class="cug2b-title-container">
            <i class="js-icon-cug js-cug-man-and-star"></i>
            <div>
                <h1>${i18next.t("Pasajeros frecuentes")}</h1>
                <h2>
                    ${unsafeHTML(
                        i18next.t("frequent-flyers-subtitle {{-spanStart}} {{-spanEnd}}", {
                            spanStart: "<span>",
                            spanEnd: "</span>",
                        }),
                    )}
                    <div class="mt-4 text-xs md:text-base">
                        * ${i18next.t("Recuerda que tus pasajeros frecuentes deben ser adultos (mayores de 18 años).")}
                    </div>
                </h2>
            </div>
        </div>
    `;

    const searchAndButtonsTemplate = () => html`
        <div class="cug2b-search-and-buttons">
            <div class="cug2b-searchbox">
                <input
                    value=${gridState.globalFilterExpression}
                    autocomplete="cc-exp"
                    placeholder=${i18next.t("Buscar")}
                    @keydown=${handleKeyDown}
                    @input=${handleFilterChange}
                />
                <i class="js-icon-cug js-cug-search"></i>
            </div>
            <div class="flex w-full flex-wrap items-center justify-center sm:w-auto">
                <div
                    class="cug2b-pill"
                    data-test-id=${T.CUG2_TRAVEL_PARTNERS.ADD_PASSENGER_BUTTON}
                    @click=${() => (window.location.href = `${ROUTES.Cug2BAddPartnerPage}`)}
                >
                    <i class="js-icon-cug js-cug-man-and-plus"></i>${i18next.t("Agregar Pasajero")}
                </div>
                <div
                    class="cug2b-pill hidden-sm-up"
                    @click=${() => (window.location.href = `${ROUTES.Cug2BTravelGroupsPage}`)}
                >
                    <i class="js-icon-cug js-cug-big-group" data-test-id=${T.CUG2_TRAVEL_PARTNERS.BIG_GROUP_BUTTON}></i
                    >${i18next.t("Grupos")}
                </div>
                <div
                    class=${classNames("cug2b-pill", {
                        disabled: !isAnythingSelected(selectedIds),
                    })}
                    @click=${handleGrouping}
                >
                    <i
                        class="js-icon-cug js-cug-grouping"
                        data-test-id=${T.CUG2_TRAVEL_PARTNERS.GROUP_PASSENGERS_BUTTON}
                    ></i
                    >${i18next.t("Agrupar pasajeros")}
                </div>
                <div
                    class=${classNames("cug2b-pill warning", {
                        disabled: !isAnythingSelected(selectedIds),
                    })}
                    @click=${handleMultiDelete}
                >
                    <i class="js-icon-cug js-cug-bin" data-test-id=${T.CUG2_TRAVEL_PARTNERS.REMOVE_BUTTON}></i
                    >${i18next.t("Eliminar")}
                </div>
            </div>
        </div>
    `;

    const rowEditTemplate = (index: number) => {
        const row = orderedModel(travelPartnerInfo, gridState, partners)[index];

        return html`
            <td class=${GridHelper.getClassMap(actionCellClass)}>
                <div class=${GridHelper.getClassMap(actionCellContentClass)}>
                    <i class="js-icon-cug js-cug-edit relative" @click=${() => setPartnerToEdit(row)}> </i>
                    <i class="js-icon-cug js-cug-bin ml-2" @click=${(e: MouseEvent) => handleSingleDelete(e, row)}></i>
                </div>
            </td>
        `;
    };

    const vm: ViewModel<keyof Partner> = {
        columns: [
            {
                field: "LastName",
                columnType: "string",
                label: i18next.t("Nombres y Apellidos"),
                sortable: true,
                columnClass: "",
                cellTemplate: (index: number) =>
                    `${orderedModel(travelPartnerInfo, gridState, partners)[index].FirstName} ${orderedModel(travelPartnerInfo, gridState, partners)[index].LastName}`,
            } as Column<keyof Partner>,
            {
                field: "DocumentId",
                columnType: "string",
                label: i18next.t("RUT / DNI / Pasaporte"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
            } as Column<keyof Partner>,
            {
                field: "DOB",
                columnType: "string",
                label: i18next.t("Fecha de Nacimiento"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
                cellTemplate: (index: number) =>
                    orderedModel(travelPartnerInfo, gridState, partners)[index].DOB.format("DD-MM-YYYY"),
            } as Column<keyof Partner>,
            {
                field: "Alias",
                columnType: "string",
                label: i18next.t("Alias"),
                sortable: true,
                columnClass: "",
            } as Column<keyof Partner>,
            {
                field: "GroupId",
                columnType: "string",
                label: i18next.t("Grupo"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
                cellTemplate: (index: number) =>
                    orderedModel(travelPartnerInfo, gridState, partners)[index].GroupId !== undefined
                        ? travelPartnerInfo.Groups.find(
                              (g) => g.Id === orderedModel(travelPartnerInfo, gridState, partners)[index].GroupId,
                          )?.Name
                        : "",
            } as Column<keyof Partner>,
        ],
        data: orderedModel(travelPartnerInfo, gridState, partners),
        paging: {
            pageable: true,
            pageIndex: gridState.pageIndex,
            pageSize: gridState.pageSize,
            buttonCount: 5,
            pageSizes: [10],
            itemCount: travelPartnerInfo?.Partners.length,
            showInfo: false,
        },
        sorting: {
            orderBy: gridState.orderBy,
            orderDir: gridState.orderDir,
            showSorterArrow: false,
        },
        selection: {
            selectable: true,
            selectedIndices: Array.from(selectedIds.values())
                .filter((id) => orderedModel(travelPartnerInfo, gridState, partners).some((item) => item.Id === id))
                .map((id) =>
                    orderedModel(travelPartnerInfo, gridState, partners)
                        .map((i) => i.Id)
                        .indexOf(id),
                ),
        },
        rowCustomization: [],
        appliedFilters: [],
        useEllipsis: true,
        rowEditTemplate,
        actionCellClass,
        actionCellContentClass,
    };

    const htmlTemplate = () =>
        travelPartnerInfo
            ? html`
                  <div ref=${ref(root)}>
                      ${staticStuffTemplate()} ${searchAndButtonsTemplate()}
                      <dc-table
                          .vm=${vm}
                          @onSortChange=${onSortChange}
                          @onPageChange=${onPageChange}
                          @onRowsSelect=${onRowsSelect}
                      ></dc-table>
                      ${editPartnerModal.htmlTemplate()} ${deletePartnerModal.htmlTemplate()}
                  </div>
                  ${saveSuccessModal.htmlTemplate()}
              `
            : html``;

    return { init, htmlTemplate };
};
