import { html } from "haunted";
import i18next from "i18next";

export interface Props {
    reservationToDelete: string;
    setReservationToDelete: (reservationToDelete: string | undefined) => void;
    handleDeleteBooking: () => Promise<void>;
}

export const useDeletePendingBookingConfirmationModal = (props: Props) => {
    // TEMPLATES

    const headerTemplate = () => html` <span>${i18next.t("¿Seguro que quieres eliminar la reserva?")}</span> `;

    const contentTemplate = () => html`
        <div class="cug2b-are-you-sure-modal-info">
            ${i18next.t("Recuerda que al eliminar esta reserva, no podrá ser recuperada.")}
        </div>
        <div class="my-4 flex flex-col items-center justify-center sm:my-8 sm:flex-row">
            <button
                class="rounded-secondary-btn with-arrow mb-4 sm:mb-0 sm:mr-4"
                @click=${() => props.setReservationToDelete(undefined)}
            >
                ${i18next.t("Cancelar")}
            </button>
            <button class="rounded-primary-btn" @click=${props.handleDeleteBooking}>${i18next.t("Aceptar")}</button>
        </div>
    `;

    const htmlTemplate = () => html`
        <ac-cug-modal
            .canBeClosed=${true}
            .content=${contentTemplate()}
            .customClass=${"cug2b-are-you-sure-modal"}
            .header=${headerTemplate()}
            .isOpen=${props.reservationToDelete}
            @close=${() => props.setReservationToDelete(undefined)}
        ></ac-cug-modal>
    `;

    return { htmlTemplate };
};
