import { html } from "haunted";
import { ApiPortalSpaViewModel } from "../../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import { ApiInitAutoTopUpCreditModel } from "../../../component-models/payment/ApiInitAutoTopUpCredit";
import { useOrganizationInfoBar } from "./useOrganizationInfoBar";
import { useSummaryWidgets } from "./useSummaryWidgets";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import { useOrganizationUsersBox } from "./useOrganizationUsersBox";
import { useOrganizationConfirmedBookingsBox } from "./useOrganizationConfirmedBookingsBox";
import { useOrganizationPendingBookingsBox } from "./useOrganizationPendingBookingsBox";
import { useState } from "../../../shared/haunted/CustomHooks";
import { useTopupModal } from "./useTopupModal";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { ROUTES } from "../../../shared/apiRoutes";

export interface Props {
    antiForgeryToken: string;
    model: ApiPortalSpaViewModel;
}

export const useCugSummaryPage = (props: Props) => {
    const cug2AppContext = useCug2AppContext();
    const { ajaxJsonRequest } = useAjax();

    const [isToppingUp, setIsToppingUp] = useState<boolean>(false);
    const [autoTopUpCredit, setAutoTopUpCredit] = useState<ApiInitAutoTopUpCreditModel>(undefined);

    const organizationInfo = useOrganizationInfoBar({
        model: props.model,
        canTopUp: props.model.SummaryViewModel.CanTopUp,
        canEditCompany: props.model.SummaryViewModel.CanEditCompany,
        autoTopUpCredit,
        setIsToppingUp,
    });

    const organizationUsers = useOrganizationUsersBox({
        model: props.model,
    });

    const organizationConfirmedBookings = useOrganizationConfirmedBookingsBox({
        model: props.model,
    });

    const organizationPendingBookings = useOrganizationPendingBookingsBox({
        model: props.model,
    });

    const widgets = useSummaryWidgets({
        cugType:
            cug2AppContext.Type === "agency"
                ? cug2AppContext.Cug2BSettings.AgencySummaryWidgets
                : cug2AppContext.Cug2BSettings.CompanySummaryWidgets,
    });

    const topupModal = useTopupModal({
        model: props.model,
        antiForgeryToken: props.antiForgeryToken,
        isToppingUp,
        setIsToppingUp,
    });

    // HELPERS

    const init = () => {
        const doIt = async () => {
            const response = await ajaxJsonRequest<ApiInitAutoTopUpCreditModel>({
                url: ROUTES.ApiRoutes.InitialTopUpCredit,
                method: "GET",
            });

            if (response.statusCode === 200) {
                setAutoTopUpCredit(response?.data);
            }
        };

        void doIt();
    };

    // COMPONENT

    // TEMPLATES

    const dataBarTemplate = () => html`
        <div class="cug2b-summary-data-bar">
            ${organizationUsers.htmlTemplate()} ${organizationConfirmedBookings.htmlTemplate()}
            ${organizationPendingBookings.htmlTemplate()}
        </div>
    `;

    const htmlTemplate = () => html`
        ${organizationInfo.htmlTemplate()} ${dataBarTemplate()} ${widgets.htmlTemplate()} ${topupModal.htmlTemplate()}
    `;

    return { init, htmlTemplate };
};
