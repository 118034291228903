import { TravelPartnerHelper } from "../../../component-helpers/TravelPartnerHelper";
import { GridState } from "../../../dc-components/dc-table-models";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import { Partner } from "../../../component-models/CUG2b/Partner";

export const usePortalTravelPartnersUtils = () => {
    // EXPORTS

    const orderedModel = (travelPartnerInfo: TravelPartnerInfo, gridState: GridState<keyof Partner>, partners: Partner[]) =>
        travelPartnerInfo ? TravelPartnerHelper.getOrderedFilteredModel(partners, gridState) : [];

    const isAnythingSelected = (selectedIds: Set<number>) => Array.from(selectedIds?.values()).length > 0;

    return { orderedModel, isAnythingSelected };
};
