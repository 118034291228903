import { html } from "lit-html";
import { FlightPageContext } from "../../../component-models/flight/contexts/FlightPageContext";
import { BundleType, CustomizedBundle } from "../../../component-models/flight/BundleOffersV2Model";
import { useBundleSsrItems } from "./useBundleSsrItems";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import classNames from "classnames";
import { useMemo } from "../../../shared/haunted/CustomHooks";
import { OUTBOUND, REDEMPTION_MILES_CODE } from "../../../shared/commonConstants";
import { useNumberFormatter } from "../../../shared/useNumberFormatter";
import { useFlowContext } from "../../../managers/useFlowContext";

export interface BundlesSelectorDesktopItemProps {
    bundle: CustomizedBundle;
    context: FlightPageContext;
    journeyIndex: number;
    onSelect: (e: MouseEvent, bundleType: BundleType) => void;
}

export const useBundlesSelectorDesktopItem = (props: BundlesSelectorDesktopItemProps) => {
    const { formatNumber } = useNumberFormatter();

    const bundleSsrItems = useBundleSsrItems({
        bundle: props.bundle,
        context: props.context,
        isSelected: false,
        journeyIndex: props.journeyIndex,
    });

    const flowContext = useFlowContext();

    const flight = useMemo(
        () =>
            props.journeyIndex === OUTBOUND
                ? props.context?.flightState?.selectedOutboundFlight
                : props.context?.flightState?.selectedInboundFlight,
        [props.context?.flightState, props.journeyIndex],
    );

    const lowestBundlePriceForCurrentRedemptionOffer = useMemo(
        () =>
            flight && flowContext.isRedemptionFlow
                ? props.context.bundleState?.bundleOffers
                      .find((o) => o.SellKey === flight?.SellKey)
                      .RedemptionOffers.find((b) => b.BundleType === "None").UnFormattedPrice
                : 0,
        [flight?.SellKey, props.context.bundleState?.bundleOffers, flowContext.isRedemptionFlow],
    );

    const lineTemplate = () => html`
        <div class="line-container">
            <div class="line"></div>
        </div>
    `;

    const priceAmountTemplate = () =>
        flowContext.isRedemptionFlow
            ? html`
                  +${formatNumber({
                      amount: props.bundle.UnFormattedPrice - lowestBundlePriceForCurrentRedemptionOffer,
                      leadingSign: false,
                      currency: REDEMPTION_MILES_CODE,
                  })}
                  <span> ${i18next.t("Miles")} </span>
              `
            : html`${props.bundle.FormattedPrice}`;

    const priceInfoTemplate = () =>
        !flowContext.isRedemptionFlow
            ? html`
                  <div
                      class="mt-1 text-center text-xs leading-none text-brand-secondary"
                      data-test-id=${getTestId(T.BUNDLE.PRICE_INFO, {
                          j: props.journeyIndex,
                          c: props.bundle.BundleType.toLowerCase(),
                      })}
                  >
                      ${i18next.t("B-PerPersonInfo")}
                  </div>
              `
            : "";

    const bestPriceTemplate = () =>
        props.bundle.BundleType === "Full"
            ? html`
                  <div class="best-price-label">
                      <i class="js-icon js-flight-tick"></i>
                      ${i18next.t("B-BestPrice")}
                  </div>
              `
            : "";

    const headerTemplate = () => html`
        <div
            class=${classNames("bundle-header", { full: props.bundle.BundleType === "Full" })}
            data-test-value=${props.bundle.BundleCode}
            style="background-color: ${props.bundle.Color};"
            data-test-id=${getTestId(T.BUNDLE.HEADER, {
                j: props.journeyIndex,
                c: props.bundle.BundleType.toLowerCase(),
            })}
        >
            <img
                class="header-img"
                src=${props.bundle.HeaderImgUrl}
                data-test-id=${getTestId(T.BUNDLE.HEADER_IMAGE, {
                    j: props.journeyIndex,
                    c: props.bundle.BundleType.toLowerCase(),
                })}
            />
            ${bestPriceTemplate()}
        </div>
    `;

    const footerButtonTemplate = () => {
        const buttonDataTestId = getTestId(T.BUNDLE.SELECT_BUTTON, {
            j: props.journeyIndex,
            c: props.bundle.BundleType.toLowerCase(),
        });

        const buttonText =
            props.bundle.BundleType === "None"
                ? i18next.t("B-BtnNoThanks")
                : props.bundle.BundleType === "Simple"
                  ? i18next.t("B-BtnSimpleBundle")
                  : i18next.t("B-BtnFullBundle");

        return html`
            <button
                class="bundle-button"
                data-test-id=${buttonDataTestId}
                data-test-value=${props.bundle.BundleCode}
                @click=${(e: MouseEvent) => props.onSelect(e, props.bundle.BundleType)}
            >
                ${buttonText}
                <i class="js-icon js-chevron-right"></i>
            </button>
        `;
    };

    const footerTemplate = () => html`
        <div class="bundle-footer">
            <div
                class=${classNames("bundle-price", { "american-price": flowContext.isRedemptionFlow })}
                data-test-value=${props.bundle.UnFormattedPrice}
                data-test-id=${getTestId(T.BUNDLE.PRICE, {
                    j: props.journeyIndex,
                    c: props.bundle.BundleType.toLowerCase(),
                })}
            >
                ${priceAmountTemplate()}
            </div>
            ${priceInfoTemplate()} ${footerButtonTemplate()}
        </div>
    `;

    const htmlTemplate = () => html`
        <div
            class="bundle-container"
            data-test-value=${props.bundle.BundleCode}
            data-test-id=${getTestId(T.BUNDLE.SELECTOR_OPTION, {
                j: props.journeyIndex,
                c: props.bundle.BundleType.toLowerCase(),
            })}
            @click=${(e: MouseEvent) => props.onSelect(e, props.bundle.BundleType)}
        >
            ${headerTemplate()} ${bundleSsrItems.htmlTemplate()} ${lineTemplate()} ${footerTemplate()}
        </div>
    `;

    return { htmlTemplate };
};
