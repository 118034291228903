import { html } from "haunted";
import i18next from "i18next";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import { TravelPartnerHelper } from "../../../component-helpers/TravelPartnerHelper";

export interface Props {
    partnersToDelete: number[];
    travelPartnerInfo: TravelPartnerInfo;
    handleDelete: () => Promise<void>;
    setPartnersToDelete: (partnersToDelete: number[] | undefined) => void;
}

export const useDeletePartnerModal = (props: Props) => {
    // EVENT-HANDLERS

    const handleModalClose = () => {
        props.setPartnersToDelete(undefined);
    };

    // TEMPLATES

    const headerTemplate = () => html`
        <span>
            ${props.partnersToDelete?.length > 1
                ? i18next.t("¿Estás seguro que quieres eliminar a estos pasajeros?")
                : i18next.t("¿Estás seguro que quieres eliminar a este pasajero?")}
        </span>
    `;

    const warningTemplate = () =>
        props.partnersToDelete?.length > 1
            ? i18next.t(
                  "Estos pasajeros se eliminaran de forma permanente, por lo que no podrás encontrarlos en tus listados, ni grupos.",
              )
            : i18next.t(
                  "Este pasajero se eliminaran de forma permanente, por lo que no podrás encontrarlo en tus listados, ni grupos.",
              );

    const contentTemplate = () => html`
        <div class="cug2b-are-you-sure-modal-content">
            <div class="cug2b-are-you-sure-modal-table-header">
                <span>${i18next.t("Nombre y Apellidos")}</span>
                <span>${i18next.t("RUT / DNI / Pasaporte")}</span>
                <span>${i18next.t("Fecha de nacimiento")}</span>
                <span>${i18next.t("Alias")}</span>
                <span>${i18next.t("Grupo")}</span>
            </div>
            ${props.partnersToDelete?.map((id) => {
                const partner = props.travelPartnerInfo.Partners.find((p) => p.Id === id);

                return html`
                    <div class="cug2b-are-you-sure-modal-table-row">
                        <span>${partner.FirstName} ${partner.LastName}</span>
                        <span>${partner.DocumentId}</span>
                        <span>${partner.DOB.format("DD-MM-YYYY")}</span>
                        <span>${partner.Alias}</span>
                        <span>${TravelPartnerHelper.getGroupNameForPartner(props.travelPartnerInfo, partner.Id)}</span>
                    </div>
                `;
            })}
            <div class="cug2b-are-you-sure-modal-info">${warningTemplate()}</div>
            <div class="mt-4 flex flex-col items-center justify-center sm:mt-8 sm:flex-row">
                <button
                    class="rounded-secondary-btn with-arrow cug2b-white mb-4 sm:mb-0 sm:mr-4"
                    @click=${handleModalClose}
                >
                    ${i18next.t("No, no quiero eliminarlos")}
                </button>
                <button class="rounded-primary-btn" @click=${props.handleDelete}>
                    ${i18next.t("Si, estoy seguro")}
                </button>
            </div>
        </div>
    `;

    const htmlTemplate = () => html`
        <ac-cug-modal
            .canBeClosed=${true}
            .content=${contentTemplate()}
            .customClass=${"cug2b-are-you-sure-group-modal"}
            .header=${headerTemplate()}
            .isOpen=${Boolean(props.partnersToDelete)}
            @close=${handleModalClose}
        ></ac-cug-modal>
    `;

    return { htmlTemplate };
};
