import i18next from "i18next";
import { html } from "lit-html";
import { CLASS_NAMES } from "../../../shared/classNames";
import { useFarelockDurationSelector } from "./useFarelockDurationSelector";
import { useFarelockSelector } from "./useFarelockSelector";
import { useFarelockSummary } from "./useFarelockSummary";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import { useAppContext } from "../../../managers/useAppContext";
import { amplitudeDontForceDcBannerSelection } from "../../../shared/aBTesting/amplitudeHandler";
import { FlightPageContext } from "../../../component-models/flight/contexts/FlightPageContext";
import { useMemo } from "haunted";
import { useFlightContinueButton } from "../useFlightContinueButton";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { useFlightPageAjaxHandler } from "../useFlightPageAjaxHandler";
import { useReduxState } from "../../../shared/redux/useReduxState";

export type FarelockSelectorState = "Initial" | "Open" | "Closed";
export type FarelockType = "Unselected" | "None" | "Duration24" | "Duration48";

export const mobileFarelockSelectorYOffsetLarge = -70;
export const mobileFarelockSelectorYOffsetSmall = -50;

export interface FarelockProps {
    context: FlightPageContext;
    isBannerLoading: boolean;
}

export const useFarelock = (props: FarelockProps) => {
    const appContext = useAppContext();

    const [userContext] = useReduxState("userContext");
    const [total] = useReduxState("booking.total");

    const { updateFarelockPrices } = useFlightPageAjaxHandler();

    const [fareLock24FormattedPrice, setFarelock24FormattedPrice] = useState<string>(undefined);
    const [fareLock48FormattedPrice, setFarelock48FormattedPrice] = useState<string>(undefined);

    const showSelectorType = (amount: number) =>
        Number(total) >= amount &&
        props.context?.flightState?.selectedOutboundFlight &&
        props.context?.flightState.selectedOutboundFlight.FlightSelectModel.Is24hFareLockEnabled;

    const show24HourFarelockSelector = useMemo(
        () => props.context && showSelectorType(props.context?.farelockState.minimumPriceFor24),
        [props.context],
    );

    const show48HourFarelockSelector = useMemo(
        () => props.context && showSelectorType(props.context?.farelockState.minimumPriceFor48),
        [props.context],
    );

    const showFarelockDurationSelector = useMemo(() => {
        if (props.context?.farelockState.selectorState !== "Open") return false;
        if (!show24HourFarelockSelector && !show48HourFarelockSelector) return false;
        if (props.context?.bundleState.bundlesMode === "Legacy") return true;

        return props.context.areAllBundlesSelected;
    }, [props.context]);

    const farelockDurationSelector = useFarelockDurationSelector({
        context: props.context,
        fareLock24FormattedPrice,
        fareLock48FormattedPrice,
        show24HourFarelockSelector,
        show48HourFarelockSelector,
        showFarelockDurationSelector,
    });

    const farelockSummary = useFarelockSummary({
        context: props.context,
        isPerLeg: true,
    });

    const farelockSelector = useFarelockSelector({
        context: props.context,
        fareLock24FormattedPrice,
        showFarelockDurationSelector,
    });

    const continueButton = useFlightContinueButton({
        dataTestId: T.FARELOCK.CONTINUE_BUTTON,
        onClick: props.context.submitFarelockType,
    });

    const amplitudeDontForce = useMemo(
        () => amplitudeDontForceDcBannerSelection(appContext.AbTestSettings, appContext.variants),
        [appContext.AbTestSettings, appContext.variants],
    );

    const flightAndBundleSelectionComplete = useMemo(
        () =>
            props.context.bundleState &&
            props.context.flightState &&
            (props.context.bundleState.bundlesMode === "Legacy"
                ? props.context.areAllFlightsSelected
                : props.context.areAllFlightsSelected && props.context.areAllBundlesSelected),
        [props.context?.bundleState, props.context?.flightState],
    );

    const bannerSelectionComplete = useMemo(
        () =>
            !props.isBannerLoading &&
            (amplitudeDontForce || props.context.bannerState.selectedOption || !props.context.bannerState.bannerVM),
        [props.context.bannerState, amplitudeDontForce, props.isBannerLoading],
    );

    const showSummary = useMemo(() => {
        if (!bannerSelectionComplete || !flightAndBundleSelectionComplete) return false;
        if (!props.context?.bundleState?.bundlesMode || props.context?.farelockState?.selectorState !== "Closed") {
            return false;
        }

        return true;
    }, [
        bannerSelectionComplete,
        flightAndBundleSelectionComplete,
        props.context?.bundleState?.bundlesMode,
        props.context?.farelockState?.selectorState,
    ]);

    const showSelector = useMemo(() => {
        if (!props.context?.dcState || !userContext) return false;
        if (userContext.isFarelockDisabled || (!userContext.cug.isAdminOrSupervisor && userContext.cug.isMember)) {
            return false;
        }
        if (userContext.chileCompra.role !== "none") return false;
        if (props.context.farelockState.selectorState === "Closed") return false;
        if (!["Unselected", "None"].includes(props.context.farelockState.selectedType)) return false;
        if (props.context.dcPreventFarelock()) return false;
        if (!show24HourFarelockSelector && !show48HourFarelockSelector) return false;
        if (!bannerSelectionComplete || !flightAndBundleSelectionComplete) return false;

        return true;
    }, [
        bannerSelectionComplete,
        flightAndBundleSelectionComplete,
        props.context?.bannerState,
        props.context?.bundleState,
        props.context?.farelockState,
        show24HourFarelockSelector,
        show48HourFarelockSelector,
        userContext,
    ]);

    // HELPERS

    const updateFarelock = async () => {
        const [newPrice24, newPrice48] = await updateFarelockPrices(
            [
                props.context?.flightState.selectedOutboundFlight?.SellKey,
                props.context?.flightState.selectedInboundFlight?.SellKey,
            ],
            props.context?.container,
            props.context?.model,
        );

        setFarelock24FormattedPrice(newPrice24);
        setFarelock48FormattedPrice(newPrice48);
    };

    useEffect(async () => {
        if (showSelector) await updateFarelock();
    }, [showSelector]);

    useEffect(() => {
        if (!flightAndBundleSelectionComplete) {
            setFarelock24FormattedPrice(undefined);
            setFarelock48FormattedPrice(undefined);
        }
    }, [flightAndBundleSelectionComplete]);

    const farelockErrorTemplate = () =>
        props.context?.farelockState?.showError
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message ${CLASS_NAMES.flightSelectError}">
                                  ${i18next.t("V2-SelectFareLockError")}
                              </div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const htmlTemplate = () =>
        props.context.model.FarelockViewModel.IsFarelockShown
            ? html`
                  ${showSelector || showFarelockDurationSelector ? farelockSelector.htmlTemplate() : ""}
                  ${showSummary ? farelockSummary.htmlTemplate() : ""}
                  ${showFarelockDurationSelector ? farelockDurationSelector.htmlTemplate() : ""}
                  ${farelockErrorTemplate()} ${showFarelockDurationSelector ? continueButton.htmlTemplate() : ""}
              `
            : "";

    return { htmlTemplate };
};
