import { BagType } from "../useBaggagePage";
import { FIRST_BAGGAGE_PRICE_FOR_STAFF_USERS, OUTBOUND } from "../../../../shared/commonConstants";
import { useCallback } from "haunted";
import { BaggageSectionJourney } from "../../../../component-models/baggage/BaggageSectionJourney";
import { StaffBaggage } from "../common/useStaffBaggage";
import { BancoEstadoBaggage } from "../common/useBancoEstadoBaggage";

export interface PerBookingChangeDto {
    baggageSectionJourneys: BaggageSectionJourney[];
    bagType: BagType;
    canGetFreeBancoEstadoBaggage: boolean;
    isCheckinClosedInbound: boolean;
    isCheckinClosedOutbound: boolean;
    paxCount: number;
}

export interface PerBookingChangeHandler {
    isPerBookingAddAvailable: (data: PerBookingChangeDto) => boolean;
    isPerBookingRemoveAllAvailable: (data: PerBookingChangeDto) => boolean;
    isPerBookingRemoveAvailable: (bagType: BagType, baggageSectionJourneys: BaggageSectionJourney[]) => boolean;
    isPerBookingResetAvailable: (data: PerBookingChangeDto) => boolean;
    perBookingQuantity: (baggageSectionJourneys: BaggageSectionJourney[]) => number;
    getLowestNextPricePerBooking: (data: {
        baggageSectionJourneys: BaggageSectionJourney[];
        bagType: BagType;
        bancoEstadoBaggage: BancoEstadoBaggage;
        isMinimum: boolean;
        isOriginal: boolean;
        staffBaggage: StaffBaggage;
    }) => number;
}

export const perBookingChangeHandler = (): PerBookingChangeHandler => {
    const firstPaxBag = (baggageSectionJourneys: BaggageSectionJourney[]) =>
        baggageSectionJourneys.find((j) => j.index === OUTBOUND).passengers.find((p) => p.index === 0);

    const isOversizedOnePax = (bagType: BagType, paxCount: number) => bagType === "oversizedBaggage" && paxCount === 1;

    const isAnySoldOut = (baggageSectionJourneys: BaggageSectionJourney[]) =>
        baggageSectionJourneys.some((journey) => journey.passengers.some((passenger) => passenger.isSoldOut));

    const doAllPaxHaveTheSameQuantity = (baggageSectionJourneys: BaggageSectionJourney[]) =>
        baggageSectionJourneys.every((journey) =>
            journey.passengers.every(
                (passenger) => passenger.quantity === firstPaxBag(baggageSectionJourneys).quantity,
            ),
        );

    const isNotEnoughOversizedForAllJourneysAllPax = (data: {
        baggageSectionJourneys: BaggageSectionJourney[];
        bagType: BagType;
        paxCount: number;
    }) =>
        data.bagType === "oversizedBaggage" && !data.baggageSectionJourneys.every((j) => j.available >= data.paxCount);

    const doAllPaxHaveSameMinAndMax = (baggageSectionJourneys: BaggageSectionJourney[]) =>
        baggageSectionJourneys.every((journey) =>
            journey.passengers.every(
                (passenger) =>
                    passenger.max === firstPaxBag(baggageSectionJourneys).max &&
                    passenger.min === firstPaxBag(baggageSectionJourneys).min,
            ),
        );

    const isPerBookingChangeAvailable = (data: PerBookingChangeDto) => {
        if (data.canGetFreeBancoEstadoBaggage && data.bagType !== "oversizedBaggage") return false;
        if (data.isCheckinClosedOutbound || data.isCheckinClosedInbound) return false;
        if (!doAllPaxHaveSameMinAndMax(data.baggageSectionJourneys)) return false;
        if (!doAllPaxHaveTheSameQuantity(data.baggageSectionJourneys)) return false;
        if (isAnySoldOut(data.baggageSectionJourneys)) return false;
        if (isNotEnoughOversizedForAllJourneysAllPax(data)) return false;

        return true;
    };

    // EXPORTS

    const isPerBookingAddAvailable = (data: PerBookingChangeDto) =>
        isPerBookingChangeAvailable(data) &&
        firstPaxBag(data.baggageSectionJourneys).quantity < firstPaxBag(data.baggageSectionJourneys).max &&
        data.baggageSectionJourneys.every((j) => j.available >= data.paxCount);

    const isPerBookingRemoveAvailable = (bagType: BagType, baggageSectionJourneys: BaggageSectionJourney[]) =>
        isPerBookingChangeAvailable &&
        firstPaxBag(baggageSectionJourneys).quantity >
            Math.max(bagType === "oversizedBaggage" ? 0 : 1, firstPaxBag(baggageSectionJourneys).min);

    const isPerBookingResetAvailable = (data: PerBookingChangeDto) => {
        if (data.canGetFreeBancoEstadoBaggage && data.bagType !== "oversizedBaggage") return false;
        if (data.isCheckinClosedOutbound || data.isCheckinClosedInbound) return false;
        if (!doAllPaxHaveSameMinAndMax(data.baggageSectionJourneys)) return false;
        if (isAnySoldOut(data.baggageSectionJourneys)) return false;
        if (isNotEnoughOversizedForAllJourneysAllPax(data)) return false;

        return !isOversizedOnePax(data.bagType, data.paxCount);
    };

    const getLowestNextPricePerBooking = useCallback(
        (data: {
            baggageSectionJourneys: BaggageSectionJourney[];
            bagType: BagType;
            bancoEstadoBaggage: BancoEstadoBaggage;
            isMinimum: boolean;
            isOriginal: boolean;
            staffBaggage: StaffBaggage;
        }) => {
            const retVal = data.baggageSectionJourneys.reduce((lowestPrice, contextJourney) => {
                const lowestJourneyPrice = contextJourney.passengers.reduce((lowestJourneyPrice, contextPassenger) => {
                    // DEVNOTE Staff members get the first bag for $1
                    if (
                        data.staffBaggage.canGetFirstBaggageStaffDiscount({
                            bagType: data.bagType,
                            journeyIndex: contextJourney.index,
                            paxIndex: contextPassenger.index,
                        })
                    ) {
                        return FIRST_BAGGAGE_PRICE_FOR_STAFF_USERS;
                    }

                    // DEVNOTE BancoEstado members get the first bag for $0
                    if (
                        data.bancoEstadoBaggage.canGetFirstBaggageBancoEstadoDiscount({
                            bagType: data.bagType,
                            journeyIndex: contextJourney.index,
                        })
                    ) {
                        return 0;
                    }

                    const paxBagPrices = contextPassenger[data.isOriginal ? "originalPrices" : "prices"];

                    const index = data.isMinimum
                        ? 0
                        : contextPassenger.quantity < contextPassenger.max
                          ? contextPassenger.quantity
                          : contextPassenger.quantity - 1;
                    return paxBagPrices[index] < lowestJourneyPrice ? paxBagPrices[index] : lowestJourneyPrice;
                }, Number.POSITIVE_INFINITY);

                return lowestJourneyPrice < lowestPrice ? lowestJourneyPrice : lowestPrice;
            }, Number.POSITIVE_INFINITY);

            return retVal < 99999999 ? retVal : -1;
        },
        [],
    );

    return {
        isPerBookingAddAvailable,
        isPerBookingRemoveAllAvailable: (data: PerBookingChangeDto) =>
            isPerBookingResetAvailable(data) && firstPaxBag(data.baggageSectionJourneys).min === 0,
        isPerBookingRemoveAvailable,
        isPerBookingResetAvailable,
        perBookingQuantity: (baggageSectionJourneys: BaggageSectionJourney[]) =>
            firstPaxBag(baggageSectionJourneys).quantity,
        getLowestNextPricePerBooking,
    };
};
