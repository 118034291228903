import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { useEffect, useMemo, useState } from "../../../../shared/haunted/CustomHooks";
import { BagType } from "../useBaggagePage";
import DomCrawlingHelper from "../../../../shared/DomCrawlingHelper";
import { useBookingManager } from "../../../../managers/useBookingManager";
import { useBaggageTealiumManager } from "../../../../managers/Tealium/useBaggageTealiumManager";
import { ApiBaggageViewModel } from "../../../../component-models/baggage/ApiBaggageViewModel";
import { mapToBaggageJourney } from "../../../../component-mappers/BaggageMappers";
import { perJourneyPerPaxChangeHandler } from "../perJourneyPerPaxView/perJourneyPerPaxChangeHandler";
import { perBookingChangeHandler } from "../perBookingView/perBookingChangeHandler";
import { StaffBaggage } from "./useStaffBaggage";
import { ApiBaggageJourney } from "../../../../component-models/baggage/ApiBaggageJourney";
import { OUTBOUND, INBOUND } from "../../../../shared/commonConstants";
import { BaggagePageState } from "../../../../component-models/baggage/BaggagePageState";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { BaggageSectionJourney } from "../../../../component-models/baggage/BaggageSectionJourney";
import { BaggageSectionStateUpdater } from "../../../../component-models/baggage/BaggageSectionStateUpdater";
import { BaggageSectionStateJourney } from "../../../../component-models/baggage/BaggageSectionStateJourney";
import { BaggageSectionStateJourneyPassenger } from "../../../../component-models/baggage/BaggageSectionStateJourneyPassenger";
import { BaggageSectionUiState } from "../../../../component-models/baggage/BaggageSectionUi";
import { ScrollHelper } from "../../../../shared/ScrollHelper";
import { BaggageSectionJourneyPassenger } from "../../../../component-models/baggage/BaggageSectionJourneyPassenger";
import { useBaggageAjaxHandler } from "./useBaggageAjaxHandler";
import { BancoEstadoBaggage } from "./useBancoEstadoBaggage";

export interface Props {
    baggageSectionJourneys: BaggageSectionJourney[];
    bagType: BagType;
    bancoEstadoBaggage: BancoEstadoBaggage;
    classToScrollToOnClose: string;
    classToScrollToOnOpen: string;
    model: ApiBaggageViewModel;
    staffBaggage: StaffBaggage;
    setModel: (model: ApiBaggageViewModel) => void;
    setPageState: (updater: (newState: BaggagePageState) => BaggagePageState) => void;
}

export const useBaggageSection = (props: Props): BaggageSection => {
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const bookingManager = useBookingManager();

    const isThereNotEnoughOversizedForAllPaxOneJourney = useMemo(
        () =>
            !props.baggageSectionJourneys.every(
                (j) => j.available >= bookingContext.adultsCount + bookingContext.childrenCount,
            ),
        [],
    );

    const isOnlyOneJourneyInBundle = (journeys: ApiBaggageJourney[]) => {
        if (journeys.length < 2) return false;

        return (
            (journeys[OUTBOUND].BundleType && !journeys[INBOUND].BundleType) ||
            (journeys[INBOUND].BundleType && !journeys[OUTBOUND].BundleType)
        );
    };

    const getInitialPerJourneyPerPaxState = (bagType: BagType): "open" | "close" => {
        if (
            bagType !== "oversizedBaggage" &&
            (props.model.BancoEstadoFreeBaggages.CabinBaggage || props.model.BancoEstadoFreeBaggages.CheckedBaggage)
        ) {
            return "open";
        }

        if (
            bookingContext.isCheckinClosedInbound ||
            bookingContext.isCheckinClosedOutbound ||
            (isOnlyOneJourneyInBundle(props.model.Journeys) && bagType !== "oversizedBaggage")
        ) {
            return "open";
        }

        const isOnePaxOneJourney =
            bookingContext.isOneWay && bookingContext.adultsCount + bookingContext.childrenCount === 1;

        if (bagType === "oversizedBaggage" && isOnePaxOneJourney) return "open";

        if (bagType === "oversizedBaggage" && isThereNotEnoughOversizedForAllPaxOneJourney) return "open";

        const firstPaxBaggage = props.baggageSectionJourneys[0].passengers[0];

        return props.baggageSectionJourneys.some((journey) =>
            journey.passengers.some((passenger) => {
                const baggage = props.baggageSectionJourneys
                    .find((j) => j.index === journey.index)
                    .passengers.find((p) => p.index === passenger.index);

                return baggage.quantity !== firstPaxBaggage.quantity || baggage.isSoldOut;
            }),
        )
            ? "open"
            : "close";
    };

    const [uiState, setUiState] = useState<BaggageSectionUiState>({
        hasPerJourneyPerPaxStateChanged: false,
        journeys: props.model.Journeys.map((j) => mapToBaggageJourney(j, props.bagType)),
        perJourneyPerPaxState: getInitialPerJourneyPerPaxState(props.bagType),
    });

    const { sendSsrRequest } = useBaggageAjaxHandler({
        baggageSectionJourneys: props.baggageSectionJourneys,
        uiState,
        setModel: props.setModel,
        setPageState: props.setPageState,
    });

    const [resetInsuranceOnChange, setResetInsuranceOnChange] = useState<boolean>(true);

    const tealiumManager = useBaggageTealiumManager({
        baggageSectionJourneys: props.baggageSectionJourneys,
        bagType: props.bagType,
        bancoEstadoBaggage: props.bancoEstadoBaggage,
        staffBaggage: props.staffBaggage,
        state: uiState,
    });

    const {
        isPerBookingAddAvailable,
        isPerBookingRemoveAllAvailable,
        isPerBookingRemoveAvailable,
        isPerBookingResetAvailable,
        perBookingQuantity,
        getLowestNextPricePerBooking,
    } = perBookingChangeHandler();

    const {
        isPerJourneyPerPaxAddAvailable,
        isPerJourneyPerPaxRemoveAvailable,
        isPerJourneyPerPaxResetAvailable,
        getNextPerJourneyPerPaxPrice,
    } = perJourneyPerPaxChangeHandler();

    const getIndices = (updater?: BaggageSectionStateUpdater) => {
        const journeyIndices = updater ? updater.journeyIndices : uiState.journeys.map((journey) => journey.index);
        const paxIndices = updater
            ? updater.paxIndices
            : uiState.journeys[0].passengers.map((passenger) => passenger.index);

        return { journeyIndices, paxIndices };
    };

    const resetBaggageInsurance = async () => {
        if (resetInsuranceOnChange && flowContext.isBookingFlow && bookingContext.isBaggageInsuranceAvailable) {
            setResetInsuranceOnChange(false);
            await bookingManager.postResetBaggageInsurance();
        }
    };

    const updateUiState = (journeyIndices: number[], paxIndices: number[]) =>
        setUiState((state) => ({
            ...state,
            journeys: [
                ...state.journeys.map(
                    (journey): BaggageSectionStateJourney =>
                        journeyIndices.includes(journey.index)
                            ? {
                                  ...journey,
                                  passengers: [
                                      ...journey.passengers.map(
                                          (pax): BaggageSectionStateJourneyPassenger =>
                                              paxIndices.includes(pax.index) ? { ...pax, hasSelected: true } : pax,
                                      ),
                                  ],
                              }
                            : journey,
                ),
            ],
        }));

    const add = async (updater?: BaggageSectionStateUpdater) => {
        const { journeyIndices, paxIndices } = getIndices(updater);

        await sendSsrRequest(journeyIndices, paxIndices, "add");

        updateUiState(journeyIndices, paxIndices);

        tealiumManager.logBaggageAdd({
            baggageSectionJourneys: props.baggageSectionJourneys,
            bagType: props.bagType,
            journeyIndices,
            paxIndices,
        });

        await resetBaggageInsurance();

        props.staffBaggage.update({ type: "add", journeyIndices, paxIndices, bagType: props.bagType });
        props.bancoEstadoBaggage.update({ type: "add", journeyIndices, bagType: props.bagType });
    };

    const remove = async (updater?: BaggageSectionStateUpdater) => {
        const { journeyIndices, paxIndices } = getIndices(updater);

        await sendSsrRequest(journeyIndices, paxIndices, "remove");

        updateUiState(journeyIndices, paxIndices);

        tealiumManager.logBaggageRemove({ journeyIndices, paxIndices });

        await resetBaggageInsurance();

        props.staffBaggage.update({ type: "remove", journeyIndices, paxIndices, bagType: props.bagType });
        props.bancoEstadoBaggage.update({ type: "remove", journeyIndices, bagType: props.bagType });
    };

    const reset = async (updater?: BaggageSectionStateUpdater) => {
        const { journeyIndices, paxIndices } = getIndices(updater);

        await sendSsrRequest(journeyIndices, paxIndices, "reset");

        updateUiState(journeyIndices, paxIndices);

        tealiumManager.logBaggageReset({ journeyIndices, paxIndices });

        await resetBaggageInsurance();

        props.staffBaggage.update({ type: "reset", journeyIndices, paxIndices, bagType: props.bagType });
        props.bancoEstadoBaggage.update({ type: "reset", journeyIndices, bagType: props.bagType });
    };

    const openPerJourneyPerPaxView = () =>
        setUiState((state) => ({ ...state, perJourneyPerPaxState: "open", hasPerJourneyPerPaxStateChanged: true }));

    const openPassengers = (updater: BaggageSectionStateUpdater, toggle?: boolean) =>
        setUiState((state) => ({
            ...state,
            journeys: state.journeys.map(
                (journey): BaggageSectionStateJourney =>
                    updater.journeyIndices.includes(journey.index)
                        ? {
                              ...journey,
                              isOpen: true,
                              passengers: journey.passengers.map(
                                  (passenger): BaggageSectionStateJourneyPassenger =>
                                      updater.paxIndices.includes(passenger.index)
                                          ? { ...passenger, isOpen: true }
                                          : { ...passenger, isOpen: toggle ? false : passenger.isOpen },
                              ),
                          }
                        : journey,
            ),
        }));

    const open = (updater?: BaggageSectionStateUpdater, toggle?: boolean) => {
        if (!updater) {
            openPerJourneyPerPaxView();
        } else {
            openPassengers(updater, toggle);
        }
    };

    const closePerJourneyPerPaxView = () =>
        setUiState((state) => ({ ...state, perJourneyPerPaxState: "close", hasPerJourneyPerPaxStateChanged: true }));

    const closePassengers = (updater: BaggageSectionStateUpdater) =>
        setUiState((state) => ({
            ...state,
            journeys: state.journeys.map(
                (journey): BaggageSectionStateJourney =>
                    updater.journeyIndices.includes(journey.index)
                        ? {
                              ...journey,
                              isOpen: updater.paxIndices.length === 0 ? false : journey.isOpen,
                              passengers: journey.passengers.map(
                                  (passenger): BaggageSectionStateJourneyPassenger =>
                                      updater.paxIndices.includes(passenger.index)
                                          ? { ...passenger, isOpen: false }
                                          : {
                                                ...passenger,
                                                isOpen: updater.paxIndices.length === 0 ? false : passenger.isOpen,
                                            },
                              ),
                          }
                        : journey,
            ),
        }));

    const close = (updater?: BaggageSectionStateUpdater) => {
        if (!updater) {
            closePerJourneyPerPaxView();
        } else {
            closePassengers(updater);
        }
    };

    const isPassengerValid = (data: {
        journey: BaggageSectionJourney;
        passenger: BaggageSectionJourneyPassenger;
    }): boolean => {
        const statePassenger = uiState.journeys
            ?.find((j) => j.index === data.journey.index)
            ?.passengers.find((p) => p.index === data.passenger.index);

        return (
            statePassenger?.hasSelected ||
            data.passenger.quantity > 0 ||
            !isPerJourneyPerPaxAddAvailable({ bookingContext, journey: data.journey, passenger: data.passenger })
        );
    };

    const findJourneyAndPaxWithError = () => {
        const journeyWithError = props.baggageSectionJourneys.find((journey) =>
            journey.passengers.some((passenger) => !isPassengerValid({ journey, passenger })),
        );

        if (journeyWithError) {
            const paxWithError = journeyWithError.passengers.find(
                (passenger) => !isPassengerValid({ journey: journeyWithError, passenger }),
            );

            if (paxWithError) return { journeyIndex: journeyWithError.index, paxIndex: paxWithError.index };
        }

        return undefined;
    };

    const isPerJourneyPerPaxNoBagSelected = useMemo(
        () =>
            props.baggageSectionJourneys.every((contextJourney) =>
                contextJourney.passengers.every((contextPassenger) => {
                    const isAddPossible = isPerJourneyPerPaxAddAvailable({
                        bookingContext,
                        journey: contextJourney,
                        passenger: contextPassenger,
                    });

                    const hasAdded = Boolean(contextPassenger.quantity);

                    const statePassenger = uiState.journeys
                        ?.find((j) => j.index === contextJourney.index)
                        ?.passengers.find((p) => p.index === contextPassenger.index);

                    return !hasAdded && (statePassenger?.hasSelected || !isAddPossible);
                }),
            ),
        [bookingContext, uiState.journeys, props.baggageSectionJourneys],
    );

    const isPerJourneyPerPaxPaidBagSelected = useMemo(
        () =>
            props.baggageSectionJourneys.every((contextJourney) =>
                contextJourney.passengers.every((contextPassenger) => {
                    const statePassenger = uiState.journeys
                        ?.find((stateJourney) => stateJourney.index === contextJourney.index)
                        ?.passengers.find((statePassenger) => statePassenger.index === contextPassenger.index);

                    return statePassenger?.hasSelected && contextPassenger.quantity > 0;
                }),
            ),
        [uiState.journeys, props.baggageSectionJourneys],
    );

    const isPerBookingPaidBagSelected = useMemo(
        () => perBookingQuantity(props.baggageSectionJourneys) > 0 && uiState.perJourneyPerPaxState !== "open",
        [perBookingQuantity, uiState.perJourneyPerPaxState],
    );

    const isPerBookingNoBagSelected = useMemo(
        () =>
            perBookingQuantity(props.baggageSectionJourneys) === 0 &&
            uiState.journeys.every((journey) => journey.passengers.every((passenger) => passenger.hasSelected)),
        [perBookingQuantity, uiState.journeys],
    );

    const updateCompulsoryValidation = () => {
        const journeyAndPaxWithError = findJourneyAndPaxWithError();

        if (!journeyAndPaxWithError) return;

        open({ journeyIndices: [journeyAndPaxWithError.journeyIndex], paxIndices: [journeyAndPaxWithError.paxIndex] });
    };

    useEffect(() => {
        if (!uiState.hasPerJourneyPerPaxStateChanged) return;

        const element = DomCrawlingHelper.getElemByClass(
            document.body,
            uiState.perJourneyPerPaxState === "open" ? props.classToScrollToOnOpen : props.classToScrollToOnClose,
        );

        ScrollHelper.scrollToElementAndHideNav({ element, yOffset: 0, timeOffset: 500 });
    }, [uiState.perJourneyPerPaxState]);

    return {
        canGetFreeBancoEstadoBaggage:
            props.bagType !== "oversizedBaggage" &&
            (props.model.BancoEstadoFreeBaggages.CabinBaggage || props.model.BancoEstadoFreeBaggages.CheckedBaggage),
        journeys: props.baggageSectionJourneys,
        perBooking: {
            isNoBagSelected: isPerBookingNoBagSelected,
            isPaidBagSelected: isPerBookingPaidBagSelected,
            isAddAvailable: isPerBookingAddAvailable,
            isRemoveAllAvailable: isPerBookingRemoveAllAvailable,
            isRemoveAvailable: isPerBookingRemoveAvailable,
            isResetAvailable: isPerBookingResetAvailable,
            quantity: perBookingQuantity,
            nextPrice: getLowestNextPricePerBooking,
        },
        perJourneyPerPax: {
            isNoBagSelected: isPerJourneyPerPaxNoBagSelected,
            isPaidBagSelected: isPerJourneyPerPaxPaidBagSelected,
            isAddAvailable: isPerJourneyPerPaxAddAvailable,
            isRemoveAvailable: isPerJourneyPerPaxRemoveAvailable,
            isResetAvailable: isPerJourneyPerPaxResetAvailable,
            nextPrice: getNextPerJourneyPerPaxPrice,
        },
        sectionUi: {
            classToScrollToOnClose: props.classToScrollToOnClose,
            classToScrollToOnOpen: props.classToScrollToOnOpen,
            uiState,
            closeJourney: (journeyIndex: number) => close({ journeyIndices: [journeyIndex], paxIndices: [] }),
            closePax: (updater: BaggageSectionStateUpdater) => close(updater),
            closePerJourneyPerPaxView: () => close(),
            openJourney: (journeyIndex: number, toggle?: boolean) =>
                open({ journeyIndices: [journeyIndex], paxIndices: [] }, toggle),
            openPax: (updater: BaggageSectionStateUpdater) => open(updater, true),
            openPerJourneyPerPaxView: () => open(),
        },
        add,
        findJourneyAndPaxWithError,
        isPassengerValid,
        remove,
        reset,
        setResetInsuranceOnChange,
        updateCompulsoryValidation,
    };
};
