import { html } from "lit-html";
import { useBookingContext } from "../../managers/useBookingContext";
import { useMemo } from "../../shared/haunted/CustomHooks";
import { useReduxState } from "../../shared/redux/useReduxState";
import i18next from "i18next";
import { CHILEAN_PESO_CODE } from "../../shared/commonConstants";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useNumberFormatter } from "../../shared/useNumberFormatter";
import { getRenderString } from "../../shared/common";

export const useChileCompraBalance = () => {
    const bookingContext = useBookingContext();

    const { formatNumber } = useNumberFormatter();

    const [userContext] = useReduxState("userContext");
    const [total] = useReduxState("booking.total");

    const isChileCompra = useMemo(
        () => userContext?.chileCompra.role !== "none" || bookingContext.isChileCompraBooking,
        [userContext, bookingContext],
    );

    const formattedTotal = useMemo(
        () =>
            formatNumber({
                amount: Number(total),
                currency: CHILEAN_PESO_CODE,
                leadingSign: true,
                omitSpace: true,
            }),
        [total],
    );

    const formattedBalance = useMemo(
        () =>
            formatNumber({
                amount: userContext?.chileCompra.availableAmount || 0,
                currency: CHILEAN_PESO_CODE,
                leadingSign: true,
                omitSpace: true,
            }),
        [userContext?.chileCompra.availableAmount],
    );

    const formattedRemainder = useMemo(
        () =>
            formatNumber({
                amount: userContext?.chileCompra.availableAmount - Number(total) || 0,
                currency: CHILEAN_PESO_CODE,
                leadingSign: true,
                omitSpace: true,
            }),
        [userContext?.chileCompra.availableAmount, total],
    );

    const insufficientFundsTemplate = () =>
        userContext?.chileCompra.availableAmount < Number(total)
            ? html`
                  <div
                      class="mt-4 flex items-center rounded-md border border-solid border-be-red-2 bg-[#fef0f0] p-4 sm:h-[72px]"
                  >
                      <i class="js-new js-n-no-balance mr-2 text-[40px] text-be-red-2 sm:mr-4"></i>
                      <div class="text-sm font-bold text-be-red-2">
                          ${unsafeHTML(
                              i18next.t(
                                  "No tienes saldo suficiente. Por favor contacta a {{-link}} para agregar saldo.",
                                  {
                                      link: getRenderString(html`
                                          <a
                                              class="break-all font-bold !text-be-red-2 sm:break-normal"
                                              href="mailto:soporte.chilecompra@jetsmart.com"
                                          >
                                              soporte.chilecompra@jetsmart.com
                                          </a>
                                      `),
                                  },
                              ),
                          )}
                      </div>
                  </div>
              `
            : "";

    const remainderTemplate = () =>
        userContext?.chileCompra.availableAmount >= Number(total)
            ? html`
                  <div class="mt-3 text-sm text-be-blue-2 sm:text-right">
                      ${unsafeHTML(
                          i18next.t(
                              "Al finalizar este compra, tu saldo como usuario de {{-spanStart}}Chilecompra{{-spanEnd}} será de",
                              { spanStart: "<span class='font-bold'>", spanEnd: "</span>" },
                          ),
                      )}:&nbsp;<span class="font-bold">${formattedRemainder}</span>
                  </div>
              `
            : "";

    const headerTemplate = () => html`
        <div class="mb-3 font-extra text-lg leading-snug text-be-blue-2 sm:mb-6 md:text-xl">
            ${i18next.t("Paga con tu saldo de")}&nbsp;
            <span class="block font-emphasis sm:inline">${i18next.t("Chilecompra")}</span>
        </div>
    `;

    const htmlTemplate = () =>
        isChileCompra && total
            ? html`
                  <div class="inner-deep-box">
                      <div class="rounded-md border border-solid border-[#B4C2D7] p-3 sm:p-6">
                          ${headerTemplate()}
                          <div
                              class="items-center justify-between rounded-md border-2 border-solid border-be-blue-2 p-3 text-be-blue-2 sm:flex"
                          >
                              <div class="text-lg sm:text-xl">
                                  ${i18next.t("Estas usando")}:&nbsp;<span class="font-bold">${formattedTotal}</span>
                              </div>
                              <div class="text-sm">
                                  ${i18next.t("Saldo disponible")}:&nbsp;<span class="font-bold">
                                      ${formattedBalance}
                                  </span>
                              </div>
                          </div>
                          ${remainderTemplate()} ${insufficientFundsTemplate()}
                      </div>
                  </div>
              `
            : "";

    return { htmlTemplate };
};
