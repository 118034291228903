import { useRef } from "haunted";
import { useState } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { getRequestBodyFromNamedInputs, updateMdl } from "../../shared/common";
import { ref } from "../../directives/ref";
import { validate } from "../../shared/form-validation";
import { useBookingManager } from "../../managers/useBookingManager";
import { useTealiumManager } from "../../managers/Tealium/useTealiumManager";
import { useBasicCheckbox } from "../ui/basic-checkbox/useBasicCheckbox";
import { useModal } from "../shared/useModal";
import classNames from "classnames";

type ApiCommissionType = "NoComission" | "AmountComission" | "PercentComission";

export interface Props {
    onSubmit: () => void;
}

export const useCommissionModal = (props: Props) => {
    const bookingManager = useBookingManager();
    const tealiumManager = useTealiumManager();

    const root = useRef<HTMLDivElement>(undefined);
    const form = useRef<HTMLFormElement>(undefined);

    const [showAmountError, setShowAmountError] = useState<boolean>(false);
    const [showCheckboxError, setShowCheckboxError] = useState<boolean>(false);
    const [showAmount, setShowAmount] = useState<boolean>(false);
    const [hasBeenValidated, setHasBeenValidated] = useState<boolean>(false);
    const [currentType, setCurrentType] = useState<ApiCommissionType>(undefined);
    const [amountValue, setAmountValue] = useState<string>("");

    const handleSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const isValid = validateForm();

        if (isValid) {
            const body = getRequestBodyFromNamedInputs(form.current);
            await bookingManager.postAddCommission(body, root.current);
            props.onSubmit();
        }
    };

    const handleCommission = (type: ApiCommissionType) => {
        setShowAmount(type !== "NoComission");
        setCurrentType(type);
        setAmountValue("");
        setShowCheckboxError(false);
        setShowAmountError(false);
    };

    const sendTealiumLog = async (errors: string[]) => {
        const filteredErrors = errors.filter((err) => err);

        if (!filteredErrors) return;

        await tealiumManager.logValidationError(filteredErrors);
    };

    const validateForm = async () => {
        setShowAmountError(false);
        setShowCheckboxError(false);

        let errors: string[] = [];
        let isValid = validate(form.current);

        const checked =
            currentType === "NoComission" || currentType === "AmountComission" || currentType === "PercentComission";

        if (!checked) {
            isValid = false;
            setShowCheckboxError(true);
            errors = [...errors, !checked ? "[commission type not selected]" : ""];
        }

        if (currentType === "AmountComission") {
            const isAmountValid = amountValue && Number(amountValue) > 0 && Number.isInteger(Number(amountValue));
            setShowAmountError(!isAmountValid);
            isValid = isAmountValid && isValid;
            errors = [...errors, !isAmountValid ? "[invalid commission amount]" : ""];
        }

        if (currentType === "PercentComission") {
            const isPercentValid = amountValue && Number(amountValue) > 1 && Number.isInteger(Number(amountValue));
            setShowAmountError(!isPercentValid);
            isValid = isPercentValid && isValid;
            errors = [...errors, isPercentValid ? "[invalid commission percentage]" : ""];
        }

        setHasBeenValidated(true);
        await sendTealiumLog(errors);

        return isValid;
    };

    const handleBlur = async () => {
        if (hasBeenValidated) await validateForm();
    };

    const noCommissionLabelTemplate = (id: string) => html`
        <label for=${id}>
            <span class="cb-title"> ${i18next.t("V2-NoCommission")}</span>
        </label>
    `;

    const amountCommissionLabelTemplate = (id: string) => html`
        <label for=${id}>
            <span class="cb-title"> ${i18next.t("V2-AmountCommission")}</span>
        </label>
    `;

    const percentCommissionLabelTemplate = (id: string) => html`
        <label for=${id}>
            <span class="cb-title"> ${i18next.t("V2-PercentCommission")}</span>
        </label>
    `;

    const noCommissionCheckbox = useBasicCheckbox({
        isChecked: currentType === "NoComission",
        customWrapperClass: "commission-option-wrapper",
        labelTemplate: noCommissionLabelTemplate,
        onClick: () => handleCommission("NoComission"),
    });
    const amountCommissionCheckbox = useBasicCheckbox({
        isChecked: currentType === "AmountComission",
        customWrapperClass: "commission-option-wrapper",
        labelTemplate: amountCommissionLabelTemplate,
        onClick: () => handleCommission("AmountComission"),
    });
    const percentCommissionCheckbox = useBasicCheckbox({
        isChecked: currentType === "PercentComission",
        customWrapperClass: "commission-option-wrapper",
        labelTemplate: percentCommissionLabelTemplate,
        onClick: () => handleCommission("PercentComission"),
    });

    // TEMPLATES

    const commissionModalInfo = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="commission-info">${i18next.t("CommissionInfo")}</div>
            </div>
        </div>
    `;

    const commissionFormTemplate = () => html`
        <div class="row">
            <div class="col-xs-1 col-md-4-5 col-md-offset-1-10">
                <div class="ts-error-container ts-error-parent">
                    <form ref=${ref(form)}>
                        <input type="hidden" name="Type" .value=${currentType} />

                        ${noCommissionTemplate()} ${amountCommissionTemplate()} ${percentCommissionTemplate()}
                        ${commissionAmountOrPercentInputTemplate()} ${emptyMandatoryInputErrorTemplate()}
                        ${amountErrorTemplate()} ${checkboxErrorTemplate()} ${formSubmitTemplate()}
                    </form>
                </div>
            </div>
        </div>
    `;

    const noCommissionTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">${noCommissionCheckbox.htmlTemplate()}</div>
        </div>
    `;

    const amountCommissionTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">${amountCommissionCheckbox.htmlTemplate()}</div>
        </div>
    `;

    const percentCommissionTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">${percentCommissionCheckbox.htmlTemplate()}</div>
        </div>
    `;

    const setCommissionAmountTemplate = () =>
        currentType === "AmountComission"
            ? html`
                  <label class="mdl-textfield__label">${i18next.t("V2-Amount")}</label>
                  <div class="amount-footnote">${i18next.t("AmountFootnote")}</div>
              `
            : "";

    const setCommissionPercentTemplate = () =>
        currentType !== "AmountComission"
            ? html`
                  <label class="mdl-textfield__label">${i18next.t("V2-Percentage")}</label>
                  <div class="amount-footnote">${i18next.t("PercentFootnote")}</div>
                  <div class="cug-info-icon cug-register">
                      <ac-tooltip .icon="?" .tooltip="${i18next.t("CUG-PercentageTooltip")}"></ac-tooltip>
                  </div>
              `
            : "";

    const commissionAmountOrPercentInputTemplate = () => {
        const showPercentClassMap = classNames("mdl-textfield mdl-js-textfield mdl-textfield--floating-label", {
            "percent-sign": currentType !== "AmountComission",
        });
        const showErrorClassMap = classNames("mdl-textfield__input js-input", {
            invalid: showAmountError,
        });

        return showAmount
            ? html`
                  <div class=${showPercentClassMap}>
                      <input
                          name="Amount"
                          autocomplete="off"
                          class=${showErrorClassMap}
                          .value=${amountValue}
                          @input=${(e: KeyboardEvent) => setAmountValue((e.target as HTMLInputElement).value)}
                          @blur=${handleBlur}
                      />

                      ${setCommissionAmountTemplate()} ${setCommissionPercentTemplate()}
                  </div>
                  ${updateMdl()}
              `
            : "";
    };

    const emptyMandatoryInputErrorTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <div class="error-message-container">
                    <div class="form-error-message ts-required-error-message hidden">
                        ${i18next.t("V2-PleaseFillAllFields")}
                    </div>
                </div>
            </div>
        </div>
    `;

    const amountErrorTemplate = () =>
        showAmountError
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${i18next.t("V2-AmountError")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const checkboxErrorTemplate = () =>
        showCheckboxError
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${i18next.t("V2-PleaseSelectAnOption")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const formSubmitTemplate = () => html`
        <div class="payment-modal-button-container">
            <button class="rounded-primary-btn" data-test-id="fee-cargo-continue" @click=${handleSubmit}>
                ${i18next.t("V2-Continue")}
            </button>
        </div>
    `;

    const htmlTemplate = () => html`
        <div class="payment-modal-close" @click=${modal.close}>&times;</div>
        <div class="payment-modal-title">${i18next.t("Payment-CommissionModalTitle")}</div>
        <div class="payment-modal-content-box">${commissionModalInfo()} ${commissionFormTemplate()}</div>
    `;

    const modal = useModal({
        content: { classNames: "payment-modal-content", template: htmlTemplate },
        overlay: { classNames: "payment-modal" },
        closing: {
            isClosable: false,
            onClose: () => {
                setAmountValue("");
                updateMdl();
            },
        },
        onOpen: () => {
            setHasBeenValidated(false);
        },
    });

    return modal;
};
