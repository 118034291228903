import { html } from "lit-html";
import { PerJourneyPerPaxPassengerDto } from "./per-journey-per-pax-passenger";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import classNames from "classnames";
import { getPassengerName } from "../../../../shared/common";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import i18next from "i18next";
import { baggageErrorClass, baggagePerJourneyErrorClass } from "../useBaggagePage";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";
import { useQuantityChanger } from "./useQuantityChanger";
import { useFreeOptionCell } from "./useFreeOptionCell";
import { usePaidOptionCellInfo } from "./usePaidOptionCellInfo";
import { usePaidOptionCell } from "./usePaidOptionCell";
import { useBookingContext } from "../../../../managers/useBookingContext";

export interface Props {
    canAdd: boolean;
    dto: PerJourneyPerPaxPassengerDto;
    paxTypeLabel: string;
    showError: boolean;
    onAdd: (e: MouseEvent) => void;
    onRemove: (e: MouseEvent) => void;
    onRemoveAll: (e: MouseEvent) => void;
}

export const useDesktopBaggagePassenger = (props: Props) => {
    const bookingContext = useBookingContext();

    const { formatNumber } = useNumberFormatter();

    const [passengerNames] = useReduxState("booking.passengerNames");

    const quantityChanger = useQuantityChanger({
        canAdd: props.canAdd,
        dto: props.dto,
        isMobile: false,
        onRemove: props.onRemove,
        onAdd: props.onAdd,
    });

    const freeCell = useFreeOptionCell({ canAdd: props.canAdd, dto: props.dto, onRemoveAll: props.onRemoveAll });

    const paidCellInfo = usePaidOptionCellInfo({
        dto: props.dto,
    });

    const paidCell = usePaidOptionCell({ canAdd: props.canAdd, dto: props.dto, onAdd: props.onAdd });

    const unformattedNextPrice = () =>
        props.dto.baggageSection.perJourneyPerPax.nextPrice({
            canGetFirstBaggageBancoEstadoDiscount: props.dto.canGetFirstBaggageBancoEstadoDiscount,
            canGetFirstBaggageStaffDiscount: props.dto.canGetFirstBaggageStaffDiscount,
            isOriginal: false,
            passenger: props.dto.passenger,
        });

    const formattedNextPrice = () =>
        formatNumber({ amount: unformattedNextPrice(), leadingSign: true }).replace(" ", "");

    const validationTemplate = () =>
        props.showError
            ? html`
                  <div
                      class=${classNames(baggageErrorClass, baggagePerJourneyErrorClass)}
                      data-test-id=${getTestId(T.BAGGAGE.ERROR_MESSAGE, {
                          c: props.dto.bagType,
                          j: props.dto.journey.index,
                          p: props.dto.passenger.index,
                      })}
                  >
                      ${i18next.t("Antes de continuar, debes seleccionar tu equipaje")}
                  </div>
              `
            : "";

    const freeIncludedTemplate = () =>
        props.dto.bagType === "cabinBaggage"
            ? html` <div class="b2-free-option-info">${i18next.t("Incluido con tu tarifa")}</div> `
            : "";

    const addAnotherButtonTemplate = () =>
        props.dto.bagType !== "cabinBaggage" && props.dto.passenger.quantity > 0 && props.canAdd
            ? html`
                  <div class="b2-add-another">
                      ${i18next.t("Agrega otro por")}
                      <span
                          data-test-value=${unformattedNextPrice()}
                          data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_NEXT_PRICE, {
                              j: props.dto.journey.index,
                              p: props.dto.passenger.index,
                              c: props.dto.bagType,
                          })}
                      >
                          ${formattedNextPrice()}
                      </span>
                  </div>
              `
            : "";

    const htmlTemplate = () => {
        const isResetAvailable = props.dto.baggageSection.perJourneyPerPax.isResetAvailable({
            bookingContext,
            canGetFirstBaggageBancoEstadoDiscount: props.dto.canGetFirstBaggageBancoEstadoDiscount,
            passenger: props.dto.passenger,
            journeyIndex: props.dto.journey.index,
        });

        return html`
            <div
                class="b2-pax-grid hidden-xs"
                data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_PASSENGER, {
                    c: props.dto.bagType,
                    j: props.dto.journey.index,
                    p: props.dto.passenger.index,
                })}
            >
                <div class="b2-pax-cell">
                    <div class="b2-pax-info">${props.paxTypeLabel}</div>
                    <div class="b2-pax-name">${getPassengerName(passengerNames, props.dto.passenger.index)}</div>
                </div>
                <div class=${classNames("b2-free-cell relative", { "b2-sold-out": !isResetAvailable })}>
                    ${freeIncludedTemplate()}
                    <div class="b2-pax-option">${freeCell.htmlTemplate()}</div>
                </div>
                <div class=${classNames("b2-paid-cell relative", { "b2-sold-out": !props.canAdd })}>
                    ${paidCellInfo.htmlTemplate()}
                    <div class="text-right">
                        <div class="b2-pax-option">${paidCell.htmlTemplate()} ${quantityChanger.htmlTemplate()}</div>
                        ${addAnotherButtonTemplate()}
                    </div>
                </div>
                ${validationTemplate()}
            </div>
        `;
    };

    return { htmlTemplate };
};
