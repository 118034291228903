import { html } from "haunted";
import { ApiPortalSpaViewModel } from "../../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import i18next from "i18next";
import { ROUTES } from "../../../shared/apiRoutes";
import classNames from "classnames";
import { useOrganizationEdit } from "./useOrganizationEdit";
import { useUnrestrictedUserInfo } from "./useUnrestrictedUserInfo";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import { useCugOrgInfo } from "./useCugOrgInfo";

export interface Props {
    model: ApiPortalSpaViewModel;
}

export const useCugProfilePage = (props: Props) => {
    const cug2AppContext = useCug2AppContext();

    // HELPERS

    const getHeaderTitleText = () =>
        cug2AppContext.Type === "agency" ? i18next.t("Mi Agencia") : i18next.t("Mi Empresa");

    // COMPONENT

    const editOrganizationOpener = useOrganizationEdit({
        canEditCompany: props.model.ProfileViewModel?.CanEditCompany,
    });

    const organizationInfo = useCugOrgInfo({
        model: props.model.ProfileViewModel?.CompanyModel,
    });

    const userInfo = useUnrestrictedUserInfo({
        model: props.model.ProfileViewModel?.UserModel,
    });

    // TEMPLATES

    const headerTemplate = () => html`
        <div class="cug2b-title-container">
            <i class="js-icon-cug js-cug-man-and-plane"></i>
            <div>
                <h1>${i18next.t("Mi Perfil")}</h1>
                <h2>
                    ${i18next.t(
                        "Revisa, edita y actualiza toda la información referente a tu empresa/agencia y a tu cuenta de usuario.",
                    )}
                </h2>
            </div>
        </div>
    `;

    const organizationTemplate = () =>
        props.model.ProfileViewModel.CompanyModel
            ? html`
                  <div class="relative">
                      <div class="cug2b-profile-title">
                          <i class="js-icon-cug js-cug-company"></i>
                          <span>${getHeaderTitleText()}</span>
                      </div>
                      ${editOrganizationOpener.htmlTemplate()} ${organizationInfo.htmlTemplate()}
                  </div>
              `
            : "";

    const userTemplate = () => {
        return props.model.ProfileViewModel.UserModel
            ? html`
                  <div class="relative">
                      <div
                          class=${classNames("cug2b-profile-title", {
                              "push-down": Boolean(props.model.ProfileViewModel.CompanyModel),
                          })}
                      >
                          <i class="js-icon-cug js-cug-user"></i>
                          <span>${i18next.t("Usuario")}</span>
                      </div>
                      <div
                          class="cug2b-profile-edit cursor-pointer"
                          @click=${() => (window.location.href = ROUTES.PageEditAgency)}
                      >
                          <i class="js-icon-cug js-cug-edit"></i>
                          <span>${i18next.t("Editar")}</span>
                      </div>
                      ${userInfo.htmlTemplate()}
                  </div>
              `
            : "";
    };

    const htmlTemplate = () => html`
        ${headerTemplate()}
        <div class="cug2b-profile-box">${organizationTemplate()} ${userTemplate()}</div>
    `;

    return { htmlTemplate };
};
