import {
    ACTION_NAMES,
    COOKIE_NAMES,
    MAX_PAX_IN_STANDARD_DC_MEMBERSHIP,
    LINKS,
    ACTIONS_WITH_BANCO_ESTADO_BAR,
    ACTIONS_TO_DISPLAY_BANCO_ESTADO_BAR_SEAT_CHECK,
} from "../../shared/commonConstants";
import { useEffect, useMemo, useState } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html, useEffect as hauntedUseEffect } from "haunted";
import { showLoader } from "../../shared/common";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { getCookie, setSessionCookie } from "../../shared/cookieHandling";
import { useBasicCheckbox } from "../ui/basic-checkbox/useBasicCheckbox";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { SelectedDcMembershipType } from "../flight-select/dc-banner/useDiscountClubBanner";
import classNames from "classnames";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";

export interface Props {
    apiFreeSeatsInBooking: number;
    dcTermsUrl: string;
    hasGroupMembershipOnBooking: boolean;
    hasStandardMembershipOnBooking: boolean;
    membershipUpgradePrice: string;
    numberOfPax: number;
    showBancoEstadoPriorityBoardingTick: boolean;
    showSidebar: boolean;
}

export const useBancoEstadoBar = (props: Props) => {
    const [userContext] = useReduxState("userContext");
    const flowContext = useFlowContext();

    const [currentSpaSection] = useReduxState("spa.activeSection");

    const { triggers } = usePubSub();
    const { getBundleStatus } = useAjax();

    const [isBancoEstadoBarOpened] = useReduxState("isBancoEstadoBarOpened");

    const [upgradeBubbleShown, setUpgradeBubbleShown] = useState<"Membership1" | "Membership2" | "None">("None");
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
    const [showAcceptanceError, setShowAcceptanceError] = useState<boolean>(false);
    const [boughtMembership, setBoughtMembership] = useState<SelectedDcMembershipType>(undefined);
    const [isExtrasPageLoaded, setIsExtrasPageLoaded] = useState<boolean>(false);
    const [isMobileNoMembershipTooltipOpen, setIsMobileNoMembershipTooltipOpen] = useState<boolean>(false);
    const [isMobileStandardMembershipTooltipOpen, setIsMobileStandardMembershipTooltipOpen] = useState<boolean>(false);
    const [isMobileGroupMembershipTooltipOpen, setIsMobileGroupMembershipTooltipOpen] = useState<boolean>(false);
    const [numberOfFreeSeatsInBooking, setNumberOfFreeSeatsInBooking] = useState<number>(0);
    const [numberOfDiscountedSeatsInBooking, setNumberOfDiscountedSeatsInBooking] = useState<number>(0);
    const [openSectionForTablet, setOpenSectionForTablet] = useState<"Membership" | "Seat" | "Priority" | "Payment">(
        undefined,
    );

    const init = () => {
        // DEVNOTE Clear BancoEstado seat cookies, as this is a new booking for sure
        if (flowContext.action === ACTION_NAMES.FLIGHT) {
            setSessionCookie(COOKIE_NAMES.DiscountedSeatsUsedInBooking, "");
            setSessionCookie(COOKIE_NAMES.FreeSeatsUsedInBooking, "");
        }

        handleDiscountedSeats();
        handleFreeSeats();

        const handler1 = triggers.bancoEstado.openUpgradeBubble.subscribe(() => setUpgradeBubbleShown("Membership1"));
        const handler2 = triggers.bancoEstado.closeUpgradeBubble.subscribe(() => setUpgradeBubbleShown("None"));
        const handler3 = triggers.extras.pageLoaded.subscribe(() => setIsExtrasPageLoaded(true));
        const handler4 = triggers.seatmap.updateDiscountedSeatsUsed.subscribe(setNumberOfDiscountedSeatsInBooking);
        const handler5 = triggers.seatmap.updateFreeSeatsUsed.subscribe(setNumberOfFreeSeatsInBooking);

        return () => {
            handler1.unsubscribe();
            handler2.unsubscribe();
            handler3.unsubscribe();
            handler4.unsubscribe();
            handler5.unsubscribe();
        };
    };

    const isCurrentActionForBancoEstadoBanners = () => ACTIONS_WITH_BANCO_ESTADO_BAR.indexOf(flowContext.action) > -1;

    // DEVNOTE This only works AFTER the flight page, because on the flight page they can log in, and AppContext might not be up
    const isBancoEstadoBarDisplayed = () =>
        [5, 6].includes(userContext.bancoEstado.category) &&
        (flowContext.action === ACTION_NAMES.PROFILE ||
            (isCurrentActionForBancoEstadoBanners() && flowContext.isBookingFlow));

    const handleDiscountedSeats = () => {
        const currentBancoEstadoDiscountedSeatCookie = getCookie(COOKIE_NAMES.DiscountedSeatsUsedInBooking);

        if (!currentBancoEstadoDiscountedSeatCookie) return;

        const [bsid, seats] = currentBancoEstadoDiscountedSeatCookie.split("||");

        if (!hasSeatsInSession(bsid, seats)) return;

        if (shouldDisplaySeatCheck()) {
            setNumberOfDiscountedSeatsInBooking(Number(seats));
            return;
        }

        setSessionCookie(COOKIE_NAMES.DiscountedSeatsUsedInBooking, "");
    };

    const handleFreeSeats = () => {
        const currentBancoEstadoFreeSeatCookie = getCookie(COOKIE_NAMES.FreeSeatsUsedInBooking);

        if (!currentBancoEstadoFreeSeatCookie) return;

        const [bsid, seats] = currentBancoEstadoFreeSeatCookie.split("||");

        if (!hasSeatsInSession(bsid, seats)) return;

        if (shouldDisplaySeatCheck()) {
            setNumberOfFreeSeatsInBooking(Number(seats));
            return;
        }

        setSessionCookie(COOKIE_NAMES.FreeSeatsUsedInBooking, "");
    };

    const hasStandardMembership = useMemo(
        () => userContext?.dc.hasStandardMembership || props.hasStandardMembershipOnBooking,
        [userContext],
    );
    const hasGroupMembership = useMemo(
        () => userContext?.dc.hasGroupMembership || props.hasGroupMembershipOnBooking,
        [userContext],
    );

    const showNoMembershipSection = useMemo(
        () => !hasStandardMembership && !hasGroupMembership && !boughtMembership,
        [hasStandardMembership, hasGroupMembership, boughtMembership],
    );

    const showStandardMembershipSection = useMemo(
        () =>
            (hasStandardMembership || boughtMembership === "Standard") &&
            boughtMembership !== "Upgrade" &&
            !hasGroupMembership,
        [boughtMembership, hasStandardMembership, hasGroupMembership],
    );

    const showGroupMembershipSection = useMemo(
        () => hasGroupMembership || boughtMembership === "Group" || boughtMembership === "Upgrade",
        [boughtMembership, hasGroupMembership],
    );

    const membershipUpgradePrice = useMemo(
        () =>
            userContext?.isLoggedIn && userContext.bancoEstado.category !== 0 && userContext.dc.upgradePriceFormatted
                ? userContext.dc.upgradePriceFormatted
                : props.membershipUpgradePrice,
        [userContext, props.membershipUpgradePrice],
    );

    const freeSeats = useMemo(() => {
        const remainingFreeSeats = userContext?.bancoEstado.remainingFreeSeats || 0;
        return flowContext.action === ACTION_NAMES.PROFILE
            ? remainingFreeSeats
            : remainingFreeSeats + props.apiFreeSeatsInBooking - numberOfFreeSeatsInBooking;
    }, [userContext, props.apiFreeSeatsInBooking, numberOfFreeSeatsInBooking]);

    const showBancoEstadoBar = () =>
        (ACTIONS_WITH_BANCO_ESTADO_BAR.indexOf(flowContext.action) > -1 &&
            isCurrentBancoEstadoCategory([5, 6]) &&
            flowContext.isBookingFlow) ||
        (flowContext.action === ACTION_NAMES.PROFILE && isCurrentBancoEstadoCategory([5, 6]));

    const isCurrentBancoEstadoCategory = (cats: number[]) =>
        userContext.bancoEstado.category !== undefined && cats.includes(userContext.bancoEstado.category);

    const addMembership = async () => {
        if (!termsAccepted) {
            setShowAcceptanceError(true);
            return;
        }

        setShowAcceptanceError(false);
        const loader = showLoader({});
        triggers.flight.upgradeBancoEstadoDc.publish({});

        const bundleStatus = await getBundleStatus(
            DomCrawlingHelper.getElemByClass(document.body, "be-upgrade-membership-bubble"),
            loader,
        );

        setUpgradeBubbleShown("None");
        setBoughtMembership("Upgrade");

        if (!bundleStatus) return;
    };

    const changeCheckbox = () => {
        setTermsAccepted(!termsAccepted);
        setShowAcceptanceError(false);
    };

    const hasSeatsInSession = (bsid: string, seats: string) =>
        bsid && bsid === flowContext.bsid && seats && Number(seats) > 0;

    const shouldDisplaySeatCheck = (): boolean =>
        ACTIONS_TO_DISPLAY_BANCO_ESTADO_BAR_SEAT_CHECK.indexOf(flowContext.action) > -1;

    const checkboxLabelTemplate = (id: string) => html`
        <label class="bancoe-bar-terms-label" for=${id}>
            ${i18next.t("Acepto los")}
            <a href="${props.dcTermsUrl}" target="_blank" @click=${(e: MouseEvent) => e.stopPropagation()}>
                ${i18next.t("Términos y Condiciones del Club de descuentos.")}
            </a>
        </label>
    `;

    const termsCheckbox = useBasicCheckbox({
        isChecked: termsAccepted,
        inputTestId: T.BANCOE_ESTADO_BAR.UPGRADE_MEMBERSHIP_INPUT,
        labelTemplate: checkboxLabelTemplate,
        onClick: changeCheckbox,
    });

    // DEVNOTE This is done this way so that it works with SPA
    const handleUrlChange = () => {
        if (window.location.pathname.toLowerCase().indexOf(ACTION_NAMES.PAYMENT) > -1) {
            setOpenSectionForTablet("Payment");
            return;
        }

        if (
            window.location.pathname.toLowerCase().indexOf(ACTION_NAMES.EXTRAS) > -1 ||
            currentSpaSection === "Extras"
        ) {
            setOpenSectionForTablet("Priority");
            return;
        }

        if (
            window.location.pathname.toLowerCase().indexOf(ACTION_NAMES.SEATMAP) > -1 ||
            currentSpaSection === "Seatmap"
        ) {
            setOpenSectionForTablet("Seat");
            return;
        }

        setOpenSectionForTablet("Membership");
    };

    const handleBancoEstadoCategory = () => {
        if (
            [5, 6].includes(userContext.bancoEstado.category) &&
            userContext?.dc.hasStandardMembership &&
            props.numberOfPax > MAX_PAX_IN_STANDARD_DC_MEMBERSHIP
        ) {
            setUpgradeBubbleShown("Membership1");
        }
    };

    useEffect(handleUrlChange, [window.location.pathname, currentSpaSection]);

    useEffect(handleBancoEstadoCategory, [userContext.bancoEstado.category]);

    hauntedUseEffect(init, []);

    const upgradeMembershipBubble1 = () =>
        upgradeBubbleShown === "Membership1"
            ? html`
                  <div class="be-upgrade-membership-bubble">
                      <div class="bumb-close-btn" @click=${() => setUpgradeBubbleShown("None")}>&times;</div>
                      <i class="js-icon-be2 js-be2-circle-group"></i>
                      <div class="mt-4">
                          ${i18next.t("¡Haz un Upgrade a")}
                          <span class="font-black">
                              ${i18next.t("Membresía Grupal por solo {{price}}", {
                                  price: membershipUpgradePrice,
                              })}
                          </span>
                          ${i18next.t("y todos los pasajeros de tu reserva tendrán los beneficios del")}
                          <span class="font-black">${i18next.t("Club de Descuentos")}</span>!
                      </div>
                      <div class="mt-4">
                          ${i18next.t("Recuerda que tu membresía actual, aplica a ti y hasta un acompañante.")}
                      </div>
                      <div class="mt-4">
                          <a class="be-tooltip-btn" @click=${() => setUpgradeBubbleShown("Membership2")}>
                              ${i18next.t("¡Lo quiero!")}
                              <i class="js-icon js-circle-chevron-right"></i>
                          </a>
                      </div>
                  </div>
              `
            : "";

    const acceptanceErrorTemplate = () =>
        showAcceptanceError
            ? html`
                  <div class="mt-1 inline-block bg-white p-1 text-be-red">
                      ${i18next.t("Por favor acepte los términos y condiciones.")}
                  </div>
              `
            : "";

    const upgradeMembershipBubble2 = () =>
        upgradeBubbleShown === "Membership2"
            ? html`
                  <div class="be-upgrade-membership-bubble">
                      <div class="bumb-close-btn" @click=${() => setUpgradeBubbleShown("None")}>&times;</div>
                      <i class="js-icon-be2 js-be2-circle-group"></i>
                      <div class="mt-4">
                          ${i18next.t("Upgrade a")}
                          <span class="font-black">${i18next.t("Membresía Grupal ")}</span>
                          ${i18next.t("La membresía  y sus beneficios aplicarán para ti y hasta 5 acompañantes.")}
                      </div>
                      <div class="mt-4">${termsCheckbox.htmlTemplate()}</div>
                      ${acceptanceErrorTemplate()}
                      <div class="mt-4">
                          <a class="be-tooltip-btn" @click=${addMembership}>
                              ${i18next.t("Agregar")}
                              <i class="js-icon js-circle-chevron-right"></i>
                          </a>
                      </div>
                  </div>
              `
            : "";


    const noMembershipContent = () => html`
        <div class="beb-tooltip bg-cyan">
            <div class="beb-tooltip-close-btn" @click=${() => setIsMobileNoMembershipTooltipOpen(false)}>&times;</div>
            <div class="bebtt-membership-info">
                <div class="text-center">
                    ${i18next.t("¿Aún no eres parte del")}
                    <br />
                    <span>${i18next.t("Club de Descuentos")}</span> ${i18next.t("de")}
                    <span>${i18next.t("JetSMART")}</span>?
                </div>
                <div class="mt-4 text-left">
                    ${i18next.t("Aprovecha tu precio preferente con tu")}
                    <br />
                    <span>${i18next.t("Tarjeta SMART")}</span> ${i18next.t("y obtén éstos beneficios")}:
                </div>
                <div class="bebtt-list-plus mt-4 text-left">
                    <i class="js-icon-be2 js-be2-circle-plus"></i>
                    ${i18next.t("Ahorra hasta")}
                    <span class="emphasis">${i18next.t("$5.000")}</span>
                    ${i18next.t("por tramo para tarifas sobre $15.000.")}
                </div>
                <div class="bebtt-list-plus mt-2 text-left">
                    <i class="js-icon-be2 js-be2-circle-plus"></i>
                    ${i18next.t("Ahorra")}
                    <span class="emphasis">${i18next.t("$1.000")}</span>
                    ${i18next.t("por maleta por cada tramo de tu viaje.")}
                </div>
                <div class="bebtt-list-plus mt-2 text-left">
                    <i class="js-icon-be2 js-be2-circle-plus"></i>
                    ${i18next.t("Descuentos aplican para ti, y uno o 5 acompañantes, según membresía.")}
                </div>
                <div class="bebtt-list-plus mt-2 text-left">
                    <i class="js-icon-be2 js-be2-circle-plus"></i>
                    ${i18next.t("Acceso a ofertas exclusivas del")}
                    <span class="emphasis">${i18next.t("Club de Descuentos.")}</span>
                </div>
            </div>
            ${buyButtonTemplate(false)}
        </div>
    `;

    const buyButtonTemplate = (onlyStandard: boolean) =>
        flowContext.action === ACTION_NAMES.PROFILE && (!onlyStandard || !hasStandardMembership)
            ? html`
                  <div class="mt-4">
                      <a class="be-tooltip-btn" href=${LINKS.DC} target="_blank">
                          ${i18next.t("¡Lo quiero!")}
                          <i class="js-icon js-circle-chevron-right"></i>
                      </a>
                  </div>
              `
            : "";

    const standardMembershipContent = () => html`
        <div class="beb-tooltip bg-cyan">
            <div class="beb-tooltip-close-btn" @click=${() => setIsMobileStandardMembershipTooltipOpen(false)}>
                &times;
            </div>
            <div class="bebtt-membership-info">
                <div class="bebtt-list-plus mt-2 text-left">
                    <i class="js-icon-be2 js-be2-circle-tick"></i>
                    ${i18next.t("Ahorra hasta")}
                    <span class="emphasis">${i18next.t("$5.000")}</span>
                    ${i18next.t("por tramo para tarifas sobre $15.000.")}
                </div>
                <div class="bebtt-list-plus mt-2 text-left">
                    <i class="js-icon-be2 js-be2-circle-tick"></i>
                    ${i18next.t("Ahorra")}
                    <span class="emphasis">${i18next.t("$1.000")}</span>
                    ${i18next.t("por maleta por cada tramo de tu viaje.")}
                </div>
                <div class="bebtt-list-plus mt-2 text-left">
                    <i class="js-icon-be2 js-be2-circle-tick"></i>
                    ${i18next.t("Descuentos aplican para ti, y un acompañante.")}
                </div>
                <div class="bebtt-list-plus mt-2 text-left">
                    <i class="js-icon-be2 js-be2-circle-tick"></i>
                    ${i18next.t("Acceso a ofertas exclusivas del")}
                    <span class="emphasis">${i18next.t("Club de Descuentos.")}</span>
                </div>
            </div>
            ${buyButtonTemplate(true)}
        </div>
    `;

    const groupMembershipContent = () => html`
        <div class="beb-tooltip bg-cyan">
            <div class="beb-tooltip-close-btn" @click=${() => setIsMobileGroupMembershipTooltipOpen(false)}>
                &times;
            </div>
            <div class="bebtt-membership-info">
                <div class="bebtt-list-plus mt-2 text-left">
                    <i class="js-icon-be2 js-be2-circle-tick"></i>
                    ${i18next.t("Ahorra hasta")}
                    <span class="emphasis">${i18next.t("$5.000")}</span>
                    ${i18next.t("por tramo para tarifas sobre $15.000.")}
                </div>
                <div class="bebtt-list-plus mt-2 text-left">
                    <i class="js-icon-be2 js-be2-circle-tick"></i>
                    ${i18next.t("Ahorra")}
                    <span class="emphasis">${i18next.t("$1.000")}</span>
                    ${i18next.t("por maleta por cada tramo de tu viaje.")}
                </div>
                <div class="bebtt-list-plus mt-2 text-left">
                    <i class="js-icon-be2 js-be2-circle-tick"></i>
                    ${i18next.t("Descuentos aplican para ti, y hasta 5 acompañantes.")}
                </div>
                <div class="bebtt-list-plus mt-2 text-left">
                    <i class="js-icon-be2 js-be2-circle-tick"></i>
                    ${i18next.t("Acceso a ofertas exclusivas del")}
                    <span class="emphasis">${i18next.t("Club de Descuentos.")}</span>
                </div>
            </div>
        </div>
    `;

    const noMembershipTemplate = () => {
        const iconClassNames = classNames("js-icon js-flight-help beb-tooltip-opener", {
            "hidden-sm": openSectionForTablet !== "Membership",
        });

        return showNoMembershipSection
            ? html`
                  <i class=${iconClassNames} @click=${() => setIsMobileNoMembershipTooltipOpen(true)}>
                      ${noMembershipContent()}
                  </i>
              `
            : "";
    };

    const standardMembershipTemplate = () => {
        const iconClassNames = classNames("js-icon js-flight-help beb-tooltip-opener", {
            "hidden-sm": openSectionForTablet !== "Membership",
        });

        return showStandardMembershipSection
            ? html`
                  <img
                      class=${classNames("beb-section-tick", { "push-down": openSectionForTablet === "Membership" })}
                      src="/Images/BancoEstado/bar-tick-color.png"
                  />
                  <i class=${iconClassNames} @click=${() => setIsMobileStandardMembershipTooltipOpen(true)}>
                      ${standardMembershipContent()}
                  </i>
                  ${upgradeMembershipBubble1()} ${upgradeMembershipBubble2()}
              `
            : "";
    };

    const groupMembershipTemplate = () => {
        const iconClassNames = classNames("js-icon js-flight-help beb-tooltip-opener", {
            "hidden-sm": openSectionForTablet !== "Membership",
        });

        return showGroupMembershipSection
            ? html`
                  <img
                      class=${classNames("beb-section-tick", { "push-down": openSectionForTablet === "Membership" })}
                      src="/Images/BancoEstado/bar-tick-color.png"
                  />
                  <i class=${iconClassNames} @click=${() => setIsMobileGroupMembershipTooltipOpen(true)}>
                      ${groupMembershipContent()}
                  </i>
              `
            : "";
    };

    const membershipSection = () => {
        const tempClassNames = classNames("beb-section beb-section-3", { open: openSectionForTablet === "Membership" });

        const label = showNoMembershipSection
            ? i18next.t("Precio Preferente")
            : showStandardMembershipSection
              ? i18next.t("Membresía Estándar")
              : showGroupMembershipSection
                ? i18next.t("Membresía Grupal")
                : "";

        return html`
            <div class=${tempClassNames}>
                <i class="beb-section-icon js-icon-be2 js-be2-money"></i>
                <div class=${classNames({ "hidden-sm": openSectionForTablet !== "Membership" })}>${label}</div>
                <span class=${classNames({ "hidden-sm": openSectionForTablet !== "Membership" })}>
                    ${i18next.t("Club de Descuentos")}
                </span>
                ${noMembershipTemplate()} ${standardMembershipTemplate()} ${groupMembershipTemplate()}
            </div>
        `;
    };

    const seatSection = () =>
        freeSeats === 0 || isCurrentBancoEstadoCategory([5]) ? seat40Section() : freeSeatsSection();

    const seatTickTemplate = () => html`
        <img
            class=${classNames("beb-section-tick", { "push-down": openSectionForTablet === "Seat" })}
            src="/Images/BancoEstado/bar-tick-color.png"
        />
    `;

    const seat40Section = () => html`
        <div class="beb-section beb-section-4 ${openSectionForTablet === "Seat" ? "open" : ""}">
            <i class="beb-section-icon js-icon-be2 js-be2-seat"></i>
            <div class=${classNames({ "hidden-sm": openSectionForTablet !== "Seat" })}>
                ${i18next.t("Desde")} <span>${i18next.t("40% descuento")}</span>
            </div>
            <div class=${classNames({ "hidden-sm": openSectionForTablet !== "Seat" })}>
                <span>${i18next.t("asientos")}</span> ${i18next.t("Zona BancoEstado")}
            </div>
            ${numberOfDiscountedSeatsInBooking > 0 ? seatTickTemplate() : ""}
        </div>
    `;

    const freeSeatsSection = () => {
        const starClassNames = classNames("beb-section-icon js-icon-be2", {
            "js-be2-star-1": freeSeats === 1,
            "js-be2-star-2": freeSeats === 2,
            "js-be2-star-3": freeSeats === 3,
            "js-be2-star-4": freeSeats === 4,
            "js-be2-stars-plus": freeSeats > 4,
        });

        return html`
            <div class=${classNames("beb-section beb-section-4", { open: openSectionForTablet === "Seat" })}>
                <i class=${starClassNames}></i>
                <div class=${classNames({ "hidden-sm": openSectionForTablet !== "Seat" })}>
                    <span>${i18next.t("Tienes")} ${freeSeats}</span>
                </div>
                <div class=${classNames({ "hidden-sm": openSectionForTablet !== "Seat" })}>
                    ${i18next.t("asiento{{plural}} gratis", { plural: freeSeats > 1 ? "s" : "" })}
                </div>
                ${numberOfFreeSeatsInBooking > 0 && flowContext.action !== ACTION_NAMES.PROFILE
                    ? seatTickTemplate()
                    : ""}
            </div>
        `;
    };

    const priorityBoardingTick = () => {
        const isStartOfPayingFullFarelockBooking =
            !isExtrasPageLoaded &&
            flowContext.isFarelockRoundTwo &&
            [ACTION_NAMES.EXTRAS.toLowerCase(), ACTION_NAMES.PAYMENT.toLowerCase()].indexOf(flowContext.action) === -1;

        if ((!isExtrasPageLoaded && !props.showBancoEstadoPriorityBoardingTick) || isStartOfPayingFullFarelockBooking) {
            return "";
        }

        return html`
            <img
                class=${classNames("beb-section-tick", { "push-down": openSectionForTablet === "Priority" })}
                src="/Images/BancoEstado/bar-tick-color.png"
            />
        `;
    };

    const sixOrTwelveQuotasTick = () =>
        flowContext.action === ACTION_NAMES.GIFTCARD_ITINERARY.toLowerCase() ||
        flowContext.action === ACTION_NAMES.PAYMENT.toLowerCase()
            ? html`
                  <img
                      class=${classNames("beb-section-tick", { "push-down": openSectionForTablet === "Payment" })}
                      src="/Images/BancoEstado/bar-tick-color.png"
                  />
              `
            : "";


    const upgradeMembershipBubble = () =>
        upgradeBubbleShown !== "None"
            ? html`
                  <div class="modal"></div>
                  ${upgradeMembershipBubble1()} ${upgradeMembershipBubble2()}
              `
            : "";

    const priorityBoardingSection = () => html`
        <div class=${classNames("beb-section beb-section-6", { open: openSectionForTablet === "Priority" })}>
            <i class="beb-section-icon js-icon-be2 js-be2-priority"></i>
            <div class=${classNames({ "hidden-sm": openSectionForTablet !== "Priority" })}>
                ${i18next.t("Embarque")}
            </div>
            <div class=${classNames({ "hidden-sm": openSectionForTablet !== "Priority" })}>
                <span>${i18next.t("Prioritario")}</span>
            </div>
            ${priorityBoardingTick()}
        </div>
    `;

    const paymentSection = () => html`
        <div class=${classNames("beb-section beb-section-5", { open: openSectionForTablet === "Payment" })}>
            <i class="beb-section-icon js-icon-be2 js-be2-percent"></i>
            <div class=${classNames({ "hidden-sm": openSectionForTablet !== "Payment" })}>
                <span>${i18next.t("6 o 12 cuotas")}</span>
            </div>
            <div class=${classNames({ "hidden-sm": openSectionForTablet !== "Payment" })}>
                ${i18next.t("sin interés")}
            </div>
            ${sixOrTwelveQuotasTick()}
        </div>
    `;

    const htmlTemplate = () => {
        const tempClassNames = classNames("be-bar", {
            "bg-be-gray": isCurrentBancoEstadoCategory([6]),
            "bg-be-orange": !isCurrentBancoEstadoCategory([6]),
            "closed": !isBancoEstadoBarOpened,
        });

        const imgSrc = isCurrentBancoEstadoCategory([6])
            ? "/Images/BancoEstado/bar-logo-smart-plus.png"
            : "/Images/BancoEstado/bar-logo-smart.png";

        return showBancoEstadoBar()
            ? html`
                  <div class=${classNames({ "full-width": !props.showSidebar })}>
                      <div class=${tempClassNames}>
                          <div class="be-bar-container">
                              <img src="/Images/BancoEstado/topbar-logo-2.svg" />
                              <div class="beb-section beb-section-1">
                                  ${i18next.t("Tus beneficios")}
                                  <span>${i18next.t("Tarjeta")}</span>
                                  <img src=${imgSrc} />
                              </div>
                              <div class="beb-divider"></div>
                               ${membershipSection()}
                              <div class="beb-divider"></div>
                              ${seatSection()}
                              <div class="beb-divider"></div>
                              ${priorityBoardingSection()}
                              <div class="beb-divider"></div>
                              ${paymentSection()}
                          </div>
                      </div>
                      <div class="be-bar-mobile-tooltip ${isMobileNoMembershipTooltipOpen ? "open" : ""}">
                          <div class="modal"></div>
                          ${noMembershipContent()}
                      </div>
                      <div class="be-bar-mobile-tooltip ${isMobileStandardMembershipTooltipOpen ? "open" : ""}">
                          <div class="modal"></div>
                          ${standardMembershipContent()}
                      </div>
                      <div class="be-bar-mobile-tooltip ${isMobileGroupMembershipTooltipOpen ? "open" : ""}">
                          <div class="modal"></div>
                          ${groupMembershipContent()}
                      </div>
                      <div class="hidden-sm-up">${upgradeMembershipBubble()}</div>
                  </div>
              `
            : "";
    };

    return { htmlTemplate, isBancoEstadoBarDisplayed };
};
