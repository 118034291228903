import dayjs from "dayjs";
import { ApiTealiumInfo } from "../../component-models/ApiTealiumInfo";
import { PaymentResult } from "../../component-models/PaymentResult";
import BookingData from "../../shared/BookingData";
import { useTealiumManager } from "./useTealiumManager";
import { useBookingDataManager } from "../useBookingDataManager";
import { useAppContext } from "../useAppContext";
import { useFlowContext } from "../useFlowContext";
import { normalizeCulture } from "../../shared/localeHelper";
import { useBookingContext } from "../useBookingContext";

export const useItineraryTealiumManager = () => {
    const bookingContext = useBookingContext();

    const logItineraryPageLoad = () =>
        tealiumManager.logDomLoaded("itinerary", {
            UpdateCommonUdo: true,
            UpdateRealUdo: true,
        });

    const logAncillaryBannerClicked = (bannerType: string) =>
        tealiumManager.log({
            EventName: "ancillary_clicked",
            EventParams: {
                type: bannerType,
            },
        });

    const logItineraryCheckinClicked = (journeyIndex: number) =>
        tealiumManager.log({
            EventName: "check_in_clicked",
            EventParams: {
                leg: journeyIndex,
                device: tealiumManager.getTealiumDeviceType(),
            },
        });

    const logItineraryPaymentConfirmationLoaded = (
        paymentResultData: PaymentResult,
        tealiumProperties: ApiTealiumInfo,
    ) => {
        const offset = () => new Date().getTimezoneOffset() * -1;

        bookingDataManager.handleBookingDataCallback(appContext.Culture, async (bookingData: BookingData) => {
            await tealiumManager.log({
                EventName: "payment_confirmation_loaded",
                EventParams: {
                    adult: bookingContext.adultsCount,
                    arrival_date: tealiumProperties.ArrivalDate
                        ? dayjs(tealiumProperties.ArrivalDate).format("YYYY-MM-DD")
                        : "",
                    authorization_code: paymentResultData.AuthorizationCode,
                    booking_channel_type: bookingData.BookingChannelType || "n/a",
                    booking_currency: tealiumProperties.BookingCurrency,
                    booking_total_amount: tealiumProperties.BookingTotalAmount,
                    booking_total_amount_usd: tealiumProperties.TotalAmountUsd,
                    booking_total_ancillaries: tealiumProperties.BookingTotalAncillary,
                    booking_total_ancillaries_usd: tealiumProperties.TotalAncillariesUsd,
                    booking_total_tax: tealiumProperties.BookingTotalTax,
                    cabing_bag: tealiumProperties.CabinBag,
                    card_type: paymentResultData.CardType,
                    checked_bag: tealiumProperties.CheckedBag,
                    child: bookingContext.childrenCount,
                    contact_email: bookingData.Contact.Email || "n/a",
                    country: tealiumProperties.Country?.slice(-2) || "",
                    culture: normalizeCulture(appContext.Culture),
                    currency_code: paymentResultData.CurrencyCode,
                    departure_date: tealiumProperties.DepartureDate
                        ? dayjs(tealiumProperties.DepartureDate).format("YYYY-MM-DD")
                        : "",
                    destination_iata: bookingContext.destinationStationCode,
                    device: tealiumManager.getTealiumDeviceType(),
                    flexismart: tealiumProperties.FlexiSmart,
                    fop_code: paymentResultData.FopCode,
                    inbound_bundle: tealiumProperties.InboundBundle || "n/a",
                    inbound_fare_arrival_date: bookingData.ReturnJourney?.ArrivalDate
                        ? dayjs.utc(bookingData.ReturnJourney?.ArrivalDate).format("YYYY-MM-DD")
                        : undefined,
                    inbound_fare_arrival_iata: bookingData.ReturnJourney?.ArrivalStationCode,
                    inbound_fare_departure_date: bookingData.ReturnJourney?.DepartureDate
                        ? dayjs.utc(bookingData.ReturnJourney?.DepartureDate).format("YYYY-MM-DD")
                        : undefined,
                    inbound_fare_departure_iata: bookingData.ReturnJourney?.DepartureStationCode,
                    inbound_fare_price: bookingData.ReturnJourney?.FareAmount,
                    infant: bookingContext.infantsCount,
                    last_4_digits: paymentResultData.Last4Digits,
                    load_type: flowContext.loadType,
                    number_of_installments: paymentResultData.NumberOfInstallments,
                    order_number: paymentResultData.OrderNumber,
                    origin_iata: bookingContext.originStationCode,
                    outbound_bundle: tealiumProperties.OutboundBundle || "n/a",
                    outbound_fare_arrival_date: bookingData.OutboundJourney?.ArrivalDate
                        ? dayjs.utc(bookingData.OutboundJourney?.ArrivalDate).format("YYYY-MM-DD")
                        : undefined,
                    outbound_fare_arrival_iata: bookingData.OutboundJourney?.ArrivalStationCode,
                    outbound_fare_departure_date: bookingData.OutboundJourney?.DepartureDate
                        ? dayjs.utc(bookingData.OutboundJourney?.DepartureDate).format("YYYY-MM-DD")
                        : undefined,
                    outbound_fare_departure_iata: bookingData.OutboundJourney?.DepartureStationCode,
                    outbound_fare_price: bookingData.OutboundJourney?.FareAmount,
                    oversize_baggage: tealiumProperties.OverSizeBaggage,
                    pax_adult_count: bookingContext.adultsCount.toString(),
                    pax_children_count: bookingContext.childrenCount.toString(),
                    pax_infant_count: bookingContext.infantsCount.toString(),
                    payment_currency: tealiumProperties.PaymentCurrency,
                    payment_id: paymentResultData.PaymentId,
                    payment_plan: paymentResultData.PaymentPlan,
                    payment_total_amount: tealiumProperties.PaymentTotalAmount,
                    pet: tealiumProperties.PetInCabin,
                    q_pax: tealiumProperties.QPax,
                    seats: tealiumProperties.Seats,
                    total_fare: tealiumProperties.TotalFare || 0,
                    total_transaction_amount: bookingData?.TotalTransactionAmountLocal?.toString() || "n/a",
                    total_transaction_amount_usd: bookingData?.TotalTransactionAmountUSD?.toString() || "n/a",
                    total_transaction_tax: bookingData?.TotalTransactionTaxLocal
                        ? bookingData.TotalTransactionTaxLocal.toString()
                        : "n/a",
                    transaction_date: tealiumProperties.TransactionDateUtc
                        ? dayjs
                              .utc(tealiumProperties.TransactionDateUtc, "YYYY-MM-DD HH:mm")
                              .utcOffset(offset())
                              .format("YYYY-MM-DD HH:mm")
                        : "",
                    transaction_date_utc: tealiumProperties.TransactionDateUtc,
                    transaction_id: paymentResultData.TransactionId,
                    trip_type: tealiumProperties.TripType === "OW" ? 0 : 1,
                    voucher_code: paymentResultData.VoucherCode,
                },
            });
        });
    };

    const appContext = useAppContext();
    const flowContext = useFlowContext();

    const bookingDataManager = useBookingDataManager();
    const tealiumManager = useTealiumManager();

    return {
        logAncillaryBannerClicked,
        logItineraryCheckinClicked,
        logItineraryPageLoad,
        logItineraryPaymentConfirmationLoaded,
    };
};
