import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { useAppContext } from "../../managers/useAppContext";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useAuthenticationTealiumManager } from "../../managers/Tealium/useAuthenticationTealiumManager";
import { CHILEAN_CULTURE_CODE, COOKIE_NAMES, URL_VARS } from "../../shared/commonConstants";
import { useEffect, useMemo } from "../../shared/haunted/CustomHooks";
import {
    getAntiForgeryTokenFromHtml,
    getGuid,
    getUrlVarsAsQueryString,
    hideLoader,
    showLoader,
} from "../../shared/common";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { CLASS_NAMES } from "../../shared/classNames";
import { getCookie } from "../../shared/cookieHandling";
import { FLIGHT_CLASS_NAMES } from "../../V2/booking/flight/FlightClassNames";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { ROUTES } from "../../shared/apiRoutes";
import { useUserContextBuilder } from "../../managers/useUserContextBuilder";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";

export const name = "ac-app-init";

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token"];

export const browserTabIdKey = "browserTabId";

export interface Attributes {
    "anti-forgery-token": string;
}

export interface Props {
    antiForgeryToken: string;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        antiForgeryToken: host.antiForgeryToken,
    };

    const flowContext = useFlowContext();
    const tabId = useMemo<string>(() => `${flowContext.bsid}:${getGuid()}`, []);

    const startWorker = (tabId: string, intervalTime = 1000, cookieLifetime = 2) => {
        const worker = new Worker("/Service_Workers/TabCookieWorker.js");
        worker.postMessage({ tabId, intervalTime, cookieLifetime });
        worker.onmessage = function (e) {
            const { tabId, cookieLifetime } = e.data;
            setTabCookie(browserTabIdKey, tabId, cookieLifetime);
        };

        return worker;
    };

    const appContext = useAppContext();
    const userContextManager = useUserContextBuilder();
    const bookingContext = useBookingContext();
    const tealiumManager = useAuthenticationTealiumManager();

    const { triggers } = usePubSub();
    const { ajaxRequest, checkPromoCodeAvailable } = useAjax();

    const [userContext] = useReduxState("userContext");
    const [antiForgeryToken, setAntiForgeryToken] = useReduxState("antiForgeryToken");
    const [_, setIsBancoEstadoBarOpened] = useReduxState("isBancoEstadoBarOpened");

    const setTabCookie = (name: string, value: string, seconds: number) => {
        const expires = new Date(Date.now() + seconds * 1000).toUTCString();
        const cookieName = `${name}:${value}`;
        document.cookie = `${cookieName}=${value}; expires=${expires}; path=/`;
    };

    const init = () => {
        const worker = startWorker(tabId, 1000, 2);
        const handler = triggers.flight.currencyChanged.subscribe(handleCurrencyChange);

        const newUserContext = userContextManager.init();

        const doIt = async () => {
            if (
                bookingContext.promoCode &&
                window.location.href.toLowerCase().indexOf(URL_VARS.CULTURE.toLowerCase()) > -1
            ) {
                await checkPromoCodeAvailable("");
                removeCultureFromUrl();
            }

            if (newUserContext.bancoEstado.category > 0 && appContext.Culture !== CHILEAN_CULTURE_CODE) {
                await ajaxRequest({
                    url: ROUTES.ApiRoutes.ChangeCulture,
                    method: "POST",
                    body: { cultureCode: CHILEAN_CULTURE_CODE },
                });

                window.location.reload();
            } else {
                if (!antiForgeryToken && props.antiForgeryToken) {
                    setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));
                }

                await tealiumManager.logUserLoginRegister();

                document.body.style.removeProperty("opacity");
            }
        };

        void doIt();

        return () => {
            worker.terminate();
            handler.unsubscribe();
        };
    };

    const initBancoEstadoBar = () => {
        const currentBancoEstadoBarCookie = getCookie(COOKIE_NAMES.IsBancoEstadoBarOpen);

        if (
            currentBancoEstadoBarCookie === "0" ||
            ![5, 6].includes(userContext.bancoEstado.category) ||
            flowContext.isCheckinFlow
        ) {
            setIsBancoEstadoBarOpened(false);
            document.body.classList.remove(CLASS_NAMES.bancoEstadoBarOpen);
        } else {
            setIsBancoEstadoBarOpened(true);
            document.body.classList.add(CLASS_NAMES.bancoEstadoBarOpen);
        }
    };

    const handleCurrencyChange = async (currencyCode: string) =>
        ajaxRequest({
            body: { selectedCurrencyCode: currencyCode },
            container: DomCrawlingHelper.getElemByClass(document.body, FLIGHT_CLASS_NAMES.ErrorParent),
            onResponseCode: {
                204: async () => {
                    await reloadPartials();
                },
            },
            url: ROUTES.FlightStoreState,
        });

    const reloadPartials = async () => {
        if (flowContext.isDcStandaloneFlow) return;

        const loader = showLoader({ name: CLASS_NAMES.sidebarLoader, noPlane: true });
        const loader2 = showLoader({});

        const baseUrl = window.location.href.split("?")[0];
        history.replaceState({}, null, baseUrl + "?" + getUrlVarsAsQueryString([]));

        await ajaxRequest({
            loader,
            container: DomCrawlingHelper.getElemByClass(document.body, FLIGHT_CLASS_NAMES.ErrorParent),
            method: "GET",
            url: ROUTES.AjaxPageFlight,
            onResponseCode: {
                200: async (data: string) => {
                    const container = DomCrawlingHelper.getElemByClass(document.body, FLIGHT_CLASS_NAMES.FlightPage);
                    container.innerHTML = data;
                },
            },
        });

        hideLoader(loader2);
    };

    const removeCultureFromUrl = () => {
        const [baseUrl, queryString] = window.location.href.split("?");

        const newQuery = queryString
            .split("&")
            .filter((elem) => elem.toLowerCase().indexOf(URL_VARS.CULTURE.toLowerCase()) === -1)
            .join("&");

        const newUrl = newQuery ? `${baseUrl}?${newQuery}` : baseUrl;

        history.replaceState({}, null, newUrl);
    };

    useEffect(initBancoEstadoBar, [userContext?.bancoEstado.category]);

    useEffect(init, []);

    return html``;
};
