import { html } from "lit-html";
import i18next from "i18next";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { useEffect } from "haunted";
import { COOKIE_NAMES } from "../../shared/commonConstants";
import { deleteCookie } from "../../shared/cookieHandling";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useModal } from "../shared/useModal";

export const name = "ac-chile-compra-login-info-modal";

export const Component: HauntedFunc<{}> = () => {
    const { triggers } = usePubSub();

    const [userContext] = useReduxState("userContext");

    const init = () => {
        const handler = triggers.chileCompra.openLoginInfoForChileCompra.subscribe(() => {
            if (userContext.chileCompra.role === "supervisor") modal.open();
        });

        return () => handler.unsubscribe();
    };

    const handleAcceptButton = () => {
        deleteCookie(COOKIE_NAMES.ChileCompraLogin);
        modal.close();
    };

    useEffect(init, [userContext]);

    const chileCompraLoginInfoModalContentTemplate = () => html`
        <div class="modal-body">
            <div class="flex w-full justify-center py-3">
                <i class="js-icon js-broken-circle-exclamation text-[100px] text-brand-tertiary"></i>
            </div>
            <div class="px-6 font-body leading-snug text-be-blue-2">${i18next.t("ChileCompraModalText")}</div>
        </div>
        <div class="flex w-full justify-center p-6">
            <button class="rounded-secondary-btn with-arrow" @click=${handleAcceptButton}>
                ${i18next.t("Aceptar")}
            </button>
        </div>
    `;

    const modal = useModal({
        content: {
            classNames: "w-[568px] max-w-[95%]",
            template: () => chileCompraLoginInfoModalContentTemplate(),
        },
    });

    return modal.htmlTemplate();
};
