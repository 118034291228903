import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import * as UTC from "dayjs/plugin/utc";
import dayjs from "dayjs";
import i18next from "i18next";
import { ApiTealiumInfo } from "../component-models/ApiTealiumInfo";
import { HauntedFunc } from "../shared/haunted/HooksHelpers";
import { PaymentResult } from "../component-models/PaymentResult";
import { TRANSACTION_LOGGER_TABLE_COLUMN } from "../shared/commonConstants";
import { TemplateResult } from "lit-html";
import { TestIdDictionary, getTestId } from "../testing-helpers/TestIdHelper";
import { html } from "haunted";
import { useEffect } from "./../shared/haunted/CustomHooks";
import { useItineraryTealiumManager } from "../managers/Tealium/useItineraryTealiumManager";
import { maskCurrenciesForDisplay } from "../shared/common";
import { useBookingContext } from "../managers/useBookingContext";
import { useNumberFormatter } from "../shared/useNumberFormatter";
dayjs.extend(CustomParseFormat);
dayjs.extend(UTC);

export const name = "ac-transaction-logger";

export const observedAttributes: (keyof Attributes)[] = ["payment-result-data", "tealium-properties"];

type TransactionAttribute = string | number | TemplateResult;

type TransactionMap = Map<TransactionAttribute, string>;
export interface Attributes {
    "payment-result-data": string;
    "tealium-properties": string;
}

export interface Props {
    logWithTealium: boolean;
    paymentResultData: PaymentResult;
    tealiumProperties: ApiTealiumInfo;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        logWithTealium: host.logWithTealium,
        paymentResultData: host.paymentResultData,
        tealiumProperties: host.tealiumProperties,
    };

    //HELPERS:

    const init = () => {
        if (props.logWithTealium) {
            tealiumManager.logItineraryPaymentConfirmationLoaded(props.paymentResultData, props.tealiumProperties);
        }
    };

    const paymentResultTemplateMapColumn1 = () =>
        new Map<TransactionAttribute, string>([
            [props.paymentResultData.VoucherCode, i18next.t("ConfPayment-VoucherCode")],
            [props.paymentResultData.AuthorizationCode, i18next.t("ConfPayment-AuthCode")],
            [
                !bookingContext.isPeruCompraBooking ? props.paymentResultData.Last4Digits : undefined,
                i18next.t("ConfPayment-CreditDigits"),
            ],
            [
                !bookingContext.isPeruCompraBooking ? props.paymentResultData.NumberOfInstallments : undefined,
                i18next.t("ConfPayment-InstNumber"),
            ],
            [props.paymentResultData.PaymentPlan, i18next.t("ConfPayment-InstType")],
            [
                props.paymentResultData.OrderNumber,
                bookingContext.isPeruCompraBooking
                    ? i18next.t("Código de reserva")
                    : i18next.t("ConfPayment-OrderNumber"),
            ],
        ]);

    const paymentResultTemplateMapColumn2 = () =>
        new Map<TransactionAttribute, string>([
            [props.paymentResultData.CardType, i18next.t("ConfPayment-PaymentMethod")],
            [
                formatNumber({
                    amount: props.paymentResultData.Amount,
                    currency: props.paymentResultData.CurrencyCode,
                    leadingSign: true,
                }),
                i18next.t("ConfPayment-TransactionAmount"),
            ],
            [maskCurrenciesForDisplay(props.paymentResultData.CurrencyCode), i18next.t("ConfPayment-Currency")],
            [
                props.paymentResultData.TransactionDateUtc
                    ? html`
                          <ac-time-zone-adapter
                              .time=${props.paymentResultData.TransactionDateUtc}
                          ></ac-time-zone-adapter>
                      `
                    : undefined,
                i18next.t("ConfPayment-TransDate"),
            ],
            [props.paymentResultData.TransactionId, i18next.t("ConfPayment-TransId")],
        ]);

    //COMPONENTS:

    const bookingContext = useBookingContext();

    const tealiumManager = useItineraryTealiumManager();

    const { formatNumber } = useNumberFormatter();

    useEffect(init, []);

    //EVENT-HANDLERS:

    //TEMPLATES:

    const universalTableRowTemplate = (attribute: TransactionAttribute, translation: string) =>
        attribute
            ? html`
                  <tr>
                      <td class="ts-download-item-title">${translation}:</td>
                      <td class="ts-download-item-content">${attribute}</td>
                  </tr>
              `
            : "";

    const tableColumnTemplate = (paymentResultMap: TransactionMap) =>
        Array.from(paymentResultMap.entries()).map(([key, value]) => universalTableRowTemplate(key, value));

    return html`
        <div class="transaction-container" data-test-id="transactionContainer">
            <div class="row">
                <div class="col-xs-1">
                    <div class="transaction-table-container">
                        <div>
                            <table class="payment-confirm-table">
                                ${tableColumnTemplate(paymentResultTemplateMapColumn1())}
                            </table>
                        </div>

                        <div>
                            <table
                                class="payment-confirm-table"
                                data-test-id=${getTestId(TestIdDictionary.ITINERARY.TRANSACTION_LOGGER_TABLE, {
                                    c: TRANSACTION_LOGGER_TABLE_COLUMN.PAYMENT_INFORMATION,
                                })}
                            >
                                ${tableColumnTemplate(paymentResultTemplateMapColumn2())}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `;
};
