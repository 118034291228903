import { useEffect, useState } from "../../../../shared/haunted/CustomHooks";
import { SeatmapState } from "../../../../component-models/seatmap/SeatmapState";
import { html } from "lit-html";
import { SeatmapContext } from "../../../../component-models/seatmap/SeatmapContext";
import { SeatmapHelper } from "../../../../component-helpers/seatmap/SeatmapHelper";
import { useRef } from "haunted";
import { ref } from "../../../../directives/ref";
import { MaterialLabelElement, updateMdl } from "../../../../shared/common";
import { getTestId, TestIdDictionary as T, TestIdDictionary } from "../../../../testing-helpers/TestIdHelper";
import { ExtendedApiSeat } from "../../../../component-models/seatmap/ApiSeat";
import { usePubSub } from "../../../../pub-sub-service/usePubSub";

export interface Props {
    context: SeatmapContext;
    currentSeats: ExtendedApiSeat[];
    isMobile: boolean;
    seatmapState: SeatmapState;
}

export const useSeatmapWarning = (props: Props) => {
    // HELPERS

    const isEmergencyWarningShown = () => SeatmapHelper.isAnySelectedSeatInExitRow(props.currentSeats);

    const showAcceptanceWarning = () => setIsAcceptanceWarningShown(true);

    const handleCheckboxChange = () => {
        if (!checkbox?.current) {
            return;
        }

        if (isTermsAcceptanceCheckboxChecked) {
            checkbox.current.MaterialCheckbox?.check();
            setIsAcceptanceWarningShown(false);
        } else {
            checkbox.current.MaterialCheckbox?.uncheck();
        }
    };

    const handleSeatedPaxNumberChange = () => {
        setIsAcceptanceWarningShown(false);
        setIsTermsAcceptanceCheckboxChecked(false);
    };

    // COMPONENT

    const checkbox = useRef<MaterialLabelElement>(undefined);

    const [isTermsAcceptanceCheckboxChecked, setIsTermsAcceptanceCheckboxChecked] = useState<boolean>(false);
    const [isAcceptanceWarningShown, setIsAcceptanceWarningShown] = useState<boolean>(false);

    const { triggers } = usePubSub();

    useEffect(handleCheckboxChange, [isTermsAcceptanceCheckboxChecked]);

    useEffect(handleSeatedPaxNumberChange, [
        props.currentSeats.filter((s) => s.PassengerNumber >= 0 && s.IsInExitRow).length,
    ]);

    useEffect(() => setIsAcceptanceWarningShown(false), [props.seatmapState?.CurrentJourney.JourneyIndex]);

    // EVENT LISTENERS

    const showEmergencyExitSeatCriteria = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        triggers.seatmap.showEmergencyRowWarningModal.publish({});
    };

    const handleClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsTermsAcceptanceCheckboxChecked(!isTermsAcceptanceCheckboxChecked);
    };

    // TEMPLATES

    const acceptanceWarningTemplate = () =>
        isAcceptanceWarningShown
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div
                                  class="form-error-message ts-seat-warning-error"
                                  data-test-id=${getTestId(TestIdDictionary.SEATMAP.EMERGENCY_SEAT_CHECKBOX_WARNING, {
                                      m: props.isMobile,
                                  })}
                              >
                                  ${window.seatmapResources.warningText3}
                              </div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const htmlTemplate = () =>
        isEmergencyWarningShown()
            ? html`
                  <div class="emergency-warning">
                      <div class="terms-container desktop-container invoice-select">
                          <div class="mdl-checkbox-wrapper seatmap">
                              <label class="mdl-checkbox mdl-js-checkbox" ref=${ref(checkbox)} @click=${handleClick}>
                                  <input
                                      type="checkbox"
                                      tabindex="-1"
                                      class="mdl-checkbox__input"
                                      data-test-id=${getTestId(T.SEATMAP.EMERGENCY_SEAT_CHECKBOX, {
                                          m: props.isMobile,
                                      })}
                                  />
                                  <span class="mdl-checkbox__label">
                                      <span
                                          class="cb-title"
                                          data-test-id=${T.SEATMAP.EMERGENCY_WARNING_ACCEPT_TERMS_TEXT}
                                      >
                                          ${window.seatmapResources.warningText1}
                                          <a
                                              @click=${showEmergencyExitSeatCriteria}
                                              data-test-id=${T.SEATMAP.EMERGENCY_WARNING_LINK}
                                          >
                                              ${window.seatmapResources.warningText2}
                                          </a>
                                      </span>
                                  </span>
                              </label>
                          </div>
                          ${acceptanceWarningTemplate()}
                      </div>
                  </div>
                  ${updateMdl()}
              `
            : "";

    return {
        isAcceptanceWarningShown,
        isTermsAcceptanceCheckboxChecked,
        htmlTemplate,
        showAcceptanceWarning,
    };
};
