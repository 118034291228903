import { useCallback, useEffect as hauntedUseEffect } from "haunted";
import { ExtendedTripModel } from "../../../component-models/flight/ExtendedTripModel";
import { FlightPageViewModel } from "../../../component-models/flight/FlightPageViewModel";
import { useFlightTealiumManager } from "../../../managers/Tealium/useFlightTealiumManager";
import { useAppContext } from "../../../managers/useAppContext";
import { useFlightSelectionManager } from "../../../managers/useFlightSelectionManager";
import { usePubSub } from "../../../pub-sub-service/usePubSub";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import { getUrlVars } from "../../../shared/common";
import { INBOUND, OUTBOUND, URL_VARS } from "../../../shared/commonConstants";
import { useEffect, useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { SelectedFlightFeeType, defaultScrollTimeOffsetA } from "../flight-page";
import { FlightOptionModel } from "../../../component-models/flight/FlightOptionModel";
import { SelectedDcMembershipType, TermsAcceptance } from "../dc-banner/useDiscountClubBanner";
import { BundleType } from "../../../component-models/flight/BundleOffersV2Model";
import { useFarelockContext } from "./useFarelockContext";
import { useFlightContext } from "./useFlightContext";
import { useBundleContext } from "./useBundleContext";
import { useDcContext } from "./useDcContext";
import { FlightPageContext } from "../../../component-models/flight/contexts/FlightPageContext";
import { useDcMembershipExtensionHandler } from "../useDcMembershipExtensionHandler";
import { useFlightHttpContextHandler } from "../useFlightHttpContextHandler";
import { useFlightPageLoginHandler } from "../useFlightPageLoginHandler";
import { BannerDTO } from "../../../component-models/flight/BannerDTO";
import { useBookingContext } from "../../../managers/useBookingContext";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useUserContextBuilder } from "../../../managers/useUserContextBuilder";

const defaultSelectorYOffsetB = 25;

export interface FlightSelectDTO {
    feeType: SelectedFlightFeeType;
    flightOption: FlightOptionModel;
    referenceObject: HTMLElement;
}

export interface FlightPageContextProps {
    container: HTMLFormElement;
    model: FlightPageViewModel;
    scrollToButton: () => void;
    setIsLoading: (isLoading: boolean) => void;
}

export const useFlightPageContext = (props: FlightPageContextProps): FlightPageContext => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flightSelectionManager = useFlightSelectionManager();
    const tealiumManager = useFlightTealiumManager();
    const userContextManager = useUserContextBuilder();

    const { triggers } = usePubSub();
    const { checkPromoCodeAvailable } = useAjax();

    const { isCurrentlyChangingCurrency, removeCurrencyChangingMarkFromUrl, updateUrl, updatePromoCodeCookie } =
        useFlightHttpContextHandler({ model: props.model });

    const { handleExtensionWarning } = useDcMembershipExtensionHandler({ model: props.model });

    const [userContext] = useReduxState("userContext");
    const [bookingCurrency, setBookingCurrency] = useReduxState("booking.currency");

    const [isInitialized, setIsInitialized] = useState(false);
    const [journeys, setJourneys] = useState<ExtendedTripModel[]>(props.model.Journeys);

    const flightContext = useFlightContext({
        model: props.model,
        onChange: async () => {
            await handleFlightChange();
            handleUrlAndCookies();
            handleScrollToFarelock();
        },
    });

    const bundleContext = useBundleContext({
        model: props.model,
        onChange: async () => {
            await handleFlightChange();
            handleUrlAndCookies();
            handleScrollToFarelock();
        },
    });

    const dcContext = useDcContext({
        model: props.model,
        onBannerChange: () => handleUrlAndCookies(),
        onChange: () => handleFlightChange(),
        setIsLoading: props.setIsLoading,
    });

    const farelockContext = useFarelockContext({
        container: props.container,
        model: props.model,
        onChange: () => handleFlightChange(),
    });

    const { handleLogin } = useFlightPageLoginHandler({
        bundleContext,
        dcContext,
        farelockContext,
        flightContext,
        model: props.model,
        setJourneys,
    });

    const currentFeeType = flightContext.state?.selectedFeeType;
    const currentInboundBundleCode = bundleContext.state?.selectedInboundBundle?.BundleCode;
    const currentInboundSellKey = flightContext.state?.selectedInboundFlight?.SellKey;
    const currentMembershipType = dcContext.state?.selectedMembershipType;
    const currentOutboundBundleCode = bundleContext.state?.selectedOutboundBundle?.BundleCode;
    const currentOutboundSellKey = flightContext.state?.selectedOutboundFlight?.SellKey;

    const baseBannerDto = (currency: string): BannerDTO => ({
        bundles: bundleContext.state.bundleOffers,
        currency,
        inboundBundleCode: currentInboundBundleCode,
        inboundSellKey: currentInboundSellKey,
        outboundBundleCode: currentOutboundBundleCode,
        outboundSellKey: currentOutboundSellKey,
        selectedDcMembershipType: currentMembershipType,
        selectedFlightFeeType: currentFeeType,
    });

    const init = () => {
        setIsInitialized(true);

        userContextManager.init(props.model);

        const currency = getUrlVars()[URL_VARS.CURRENCY]?.[0] || bookingCurrency;
        setBookingCurrency(currency);

        const waitForSidebarBeforeLoggingDomLoad =
            (flightContext.areAllFlightsSelected && bundleContext.state.bundlesMode !== "PerLeg") ||
            bundleContext.state.selectedOutboundBundle?.BundleCode ||
            bundleContext.state.selectedInboundBundle?.BundleCode;

        // DEVNOTE This is for Tealium logging to work. That's that. JET-6459
        if (!waitForSidebarBeforeLoggingDomLoad && !isCurrentlyChangingCurrency()) tealiumManager.logFlightPageLoaded();

        dcContext.initBanner(baseBannerDto(currency));

        if (
            userContext?.bancoEstado.category === 6 &&
            props.model.DiscountClubViewModel.ShowBancoEstadoUpgradeBubble &&
            currentMembershipType !== "Group" &&
            currentMembershipType !== "Upgrade" &&
            !bundleContext.state.selectedOutboundBundle &&
            !bundleContext.state.selectedInboundBundle
        ) {
            triggers.bancoEstado.openUpgradeBubble.publish({});
        }

        const doIt = async () => {
            if (waitForSidebarBeforeLoggingDomLoad || isCurrentlyChangingCurrency()) {
                await flightSelectionManager.handleFlightSelectChange({
                    bundleState: bundleContext.state,
                    dcState: dcContext.state,
                    flightState: flightContext.state,
                    selectedCurrency: currency,
                });

                // DEVNOTE This is for Tealium logging to work. That's that. JET-6459
                window.setTimeout(tealiumManager.logFlightPageLoaded, 0);

                if (isCurrentlyChangingCurrency()) {
                    removeCurrencyChangingMarkFromUrl();

                    if (bookingContext.promoCode) await checkPromoCodeAvailable("");
                }
            }
        };

        void doIt();
    };

    const selectFlight = async (data: FlightSelectDTO) => {
        flightContext.selectFlight({
            bundleState: bundleContext.state,
            feeType: data.feeType,
            flightOption: data.flightOption,
        });

        if (data.feeType !== currentFeeType) {
            bundleContext.reset([OUTBOUND, INBOUND]);
            dcContext.reset();
        }

        const outboundBundleCode = data.feeType === currentFeeType ? currentOutboundBundleCode : undefined;
        const inboundBundleCode = data.feeType === currentFeeType ? currentInboundBundleCode : undefined;
        const outboundSellKey =
            data.flightOption.JourneyIndex === OUTBOUND ? data.flightOption.SellKey : currentOutboundSellKey;
        const inboundSellKey =
            data.flightOption.JourneyIndex === INBOUND ? data.flightOption.SellKey : currentInboundSellKey;

        dcContext.selectFlight({
            ...baseBannerDto(bookingCurrency),
            inboundBundleCode,
            inboundSellKey,
            outboundBundleCode,
            outboundSellKey,
            selectedDcMembershipType: currentMembershipType,
            selectedFlightFeeType: data.feeType,
        });

        window.setTimeout(() => ScrollHelper.scrollToBundlesBottom(data.referenceObject), 100);
    };

    const resetFlight = async (journeyIndex: number, referenceObject: HTMLDivElement) => {
        bundleContext.reset([journeyIndex]);
        dcContext.reset();
        await farelockContext.removeFarelock();
        farelockContext.reset();
        flightContext.reset(journeyIndex);

        // DEVNOTE: setTimeout is needed for the animations to finish
        window.setTimeout(
            () => ScrollHelper.scrollToWeekSelector(bundleContext.state, referenceObject, defaultSelectorYOffsetB),
            defaultScrollTimeOffsetA,
        );
    };

    const handleRegister = async (membershipType: SelectedDcMembershipType) => {
        flightContext.selectFlightFeeType("Club");
        dcContext.handleRegister(membershipType);
        bundleContext.selectDcMembershipType({
            outboundSellKey: currentOutboundSellKey,
            inboundSellKey: currentInboundSellKey,
            type: "upgrade",
        });

        ScrollHelper.scrollToFareLock();
    };

    // DEVNOTE If user already checked it, then removed it, we have to update
    // the sidebar, but there is no separate call for removing upgrade and
    // keeping the discount for 2 pax. So we remove all, then add 2 pax
    // discount with useEffect if applicable. Not ideal.
    const removeDcMembership = async () =>
        flightSelectionManager.handleFlightSelectChange({
            nonCancellable: true,
            flightState: flightContext.state,
            selectedCurrency: bookingCurrency,
            bundleState: bundleContext.state,
            dcState: { ...dcContext.state, applyDiscount: false, selectedMembershipType: undefined },
        });

    const handleUnselectDc = async () => {
        switch (dcContext.bannerState.bannerVM.bannerType) {
            case "GetMembershipForMember":
            case "GetMembershipForWA":
                flightContext.selectFlightFeeType("Smart");
                break;
            case "ExtendMembership":
                handleExtensionWarning(userContext);
                if (["Standard", "Group"].includes(currentMembershipType)) await removeDcMembership();
                break;
            case "UpgradeMembership":
                if (currentMembershipType === "Upgrade") await removeDcMembership();
                break;
        }
    };

    const selectDcOption = async (selectedOption: SelectedDcMembershipType, acceptance: TermsAcceptance) => {
        if (!selectedOption) return;

        if (dcContext.shouldUpgrade(selectedOption)) {
            bundleContext.selectDcMembershipType({
                outboundSellKey: currentOutboundSellKey,
                inboundSellKey: currentInboundSellKey,
                type: "upgrade",
            });

            flightContext.selectFlightFeeType("Club");
        }

        if (dcContext.shouldDowngrade(selectedOption)) {
            bundleContext.selectDcMembershipType({
                outboundSellKey: currentOutboundSellKey,
                inboundSellKey: currentInboundSellKey,
                type: "downgrade",
            });
        }

        dcContext.selectDcMembershipOption({
            selectedBannerOption: selectedOption,
            acceptance,
        });

        if (selectedOption === "None") {
            await handleUnselectDc();

            if (dcContext.bannerState.bannerVM?.bannerType !== "GetMembershipForWA") {
                window.setTimeout(props.scrollToButton, 200);
            }
        }
    };

    const resetDcMembership = async () => {
        flightContext.selectFlightFeeType("Smart");

        bundleContext.selectDcMembershipType({
            inboundSellKey: currentInboundSellKey,
            outboundSellKey: currentOutboundSellKey,
            type: "downgrade",
            callback: async (outboundBundleCode: string, inboundBundleCode: string) => {
                dcContext.closeDcMembershipModal({
                    ...baseBannerDto(bookingCurrency),
                    inboundBundleCode,
                    outboundBundleCode,
                    selectedDcMembershipType: "None",
                    selectedFlightFeeType: "Smart",
                });
            },
        });
    };

    const selectBundle = async (type: BundleType, journeyIndex: number, sellKey: string, rootElem: HTMLElement) => {
        await bundleContext.selectBundle({
            journeyIndex,
            selectedFlightFeeType: currentFeeType,
            sellKey,
            tooManyPax: dcContext.tooManyPax,
            type,
            callback: (outboundBundleCode: string, inboundBundleCode: string, isLast: boolean) =>
                window.setTimeout(async () => {
                    dcContext.selectBundle({
                        ...baseBannerDto(bookingCurrency),
                        inboundBundleCode,
                        isLastBundleSelected: isLast,
                        outboundBundleCode,
                        rootElem,
                    });
                }, 0),
        });

        triggers.bancoEstado.closeUpgradeBubble.publish({});
    };

    const upgradeBundle = (journeyIndex: number) =>
        bundleContext.upgradeBundle({
            flightState: flightContext.state,
            journeyIndex,
            callback: async (outboundBundleCode: string, inboundBundleCode: string) => {
                dcContext.upgradeBundle({
                    ...baseBannerDto(bookingCurrency),
                    inboundBundleCode,
                    outboundBundleCode,
                });
            },
        });

    const subscribeToLogin = () => {
        const handler = triggers.flight.flightPageOpenLoginInMenubar.subscribe(() =>
            triggers.login.openLoginModal.publish({
                isForced: false,
                callback: (result) => handleLogin(result),
            }),
        );

        return () => handler.unsubscribe();
    };

    const subscribeToBancoEstadoDcUpgrade = () => {
        const handler = triggers.flight.upgradeBancoEstadoDc.subscribe(async () => {
            await handleBancoEstadoDcUpgrade();
            handler.unsubscribe();
        });

        return () => handler.unsubscribe();
    };

    const handleFlightChange = useCallback(async () => {
        if (!dcContext.state || !bundleContext.state || !flightContext.state) return;

        await flightSelectionManager.handleFlightSelectChange({
            bundleState: bundleContext.state,
            dcState: dcContext.state,
            flightState: flightContext.state,
            selectedCurrency: bookingCurrency,
        });
    }, [dcContext, flightContext, bundleContext, bookingCurrency]);

    const updateFarelock = () =>
        farelockContext.removeFarelockIfPriceDrops(
            currentMembershipType,
            flightContext.state?.selectedOutboundFlight,
            dcContext.dcPreventFarelock(),
        );

    const handleUrlAndCookies = useCallback(() => {
        if (!flightContext?.state || !dcContext?.state || !bundleContext?.state) return;

        updateUrl({
            bundleState: bundleContext.state,
            dcState: dcContext.state,
            flightState: flightContext.state,
        });

        if (!bookingContext.promoCode && (currentOutboundSellKey || currentInboundSellKey)) {
            updatePromoCodeCookie(flightContext.state, bundleContext.state);
        }
    }, [flightContext.state, bundleContext.state, bookingCurrency, appContext, dcContext.state]);

    const shouldScrollToFarelock = useMemo(
        () =>
            bundleContext.areAllBundlesSelected &&
            flightContext.areAllFlightsSelected &&
            dcContext.bannerState?.selectedOption &&
            !dcContext.showTermsError,
        [bundleContext.state, flightContext.state, dcContext.showTermsError, dcContext.bannerState],
    );

    const handleScrollToFarelock = useCallback(() => {
        if (shouldScrollToFarelock) ScrollHelper.scrollToFareLock();
    }, [shouldScrollToFarelock]);

    const handleBancoEstadoDcUpgrade = useCallback(async () => {
        if (currentMembershipType === "Upgrade") return;

        dcContext.handleBancoEstadoDcUpgrade();
        flightContext.selectFlightFeeType("Club");
        bundleContext.selectDcMembershipType({
            outboundSellKey: currentOutboundSellKey,
            inboundSellKey: currentInboundSellKey,
            type: "upgrade",
        });
    }, [dcContext, flightContext, bundleContext]);

    const showSelectedView = useCallback(
        (flightOption: FlightOptionModel) => {
            if (!bundleContext.state || !flightContext.state) return false;

            if (bundleContext.state.bundlesMode !== "PerLeg") {
                return flightOption.JourneyIndex === OUTBOUND
                    ? Boolean(flightContext.state.selectedOutboundFlight)
                    : Boolean(flightContext.state.selectedInboundFlight);
            }

            return (
                flightContext.isThisFeeSelected(flightOption) &&
                (!bundleContext.state.selectedOutboundBundle || !bundleContext.state.selectedInboundBundle)
            );
        },
        [
            bundleContext.state?.selectedOutboundBundle,
            bundleContext.state?.selectedInboundBundle,
            flightContext.state?.selectedOutboundFlight,
            flightContext.state?.selectedInboundFlight,
            flightContext.isThisFeeSelected,
        ],
    );

    useEffect(updateFarelock, [dcContext, flightContext.state, farelockContext]);

    useEffect(handleUrlAndCookies, [bookingCurrency]);

    useEffect(handleScrollToFarelock, [dcContext.showTermsError, dcContext.bannerState?.selectedOption]);

    hauntedUseEffect(subscribeToLogin, [handleLogin]);

    hauntedUseEffect(subscribeToBancoEstadoDcUpgrade, [handleBancoEstadoDcUpgrade]);

    useEffect(() => {
        if (!isInitialized && flightContext.state && dcContext.state && bundleContext.state && userContext?.userRole) {
            init();
        }
    }, [flightContext.state, isInitialized, bundleContext.state, dcContext.state, userContext?.userRole]);

    return {
        areAllBundlesSelected: bundleContext.areAllBundlesSelected,
        areAllFlightsSelected: flightContext.areAllFlightsSelected,
        bannerState: dcContext.bannerState,
        bundleState: bundleContext.state,
        container: props.container,
        dcState: dcContext.state,
        farelockState: farelockContext.state,
        flightState: flightContext.state,
        journeys,
        model: props.model,
        showTermsError: dcContext.showTermsError,
        ssrIdsInOrder: bundleContext.ssrIdsInOrder,
        tooManyPax: dcContext.tooManyPax,
        changeTermsAcceptance: dcContext.changeTermsAcceptance,
        dcPreventFarelock: dcContext.dcPreventFarelock,
        getUpgradedBundle: bundleContext.getUpgradedBundle,
        handleRegister,
        handleLogin,
        isThisFeeSelected: flightContext.isThisFeeSelected,
        openFarelockSelector: farelockContext.openFarelockSelector,
        removeFarelock: farelockContext.removeFarelock,
        resetDcMembership,
        resetFlight,
        selectBundle,
        selectDcOption,
        selectFarelockType: farelockContext.selectFarelockType,
        selectFlight,
        showBanner: dcContext.showBanner,
        showBundleUpgradeOffer: bundleContext.showBundleUpgradeOffer,
        showFlightSelectError: flightContext.showFlightSelectError,
        showSelectedView,
        showTooManyPaxModal: dcContext.showTooManyPaxModal,
        submitFarelockType: farelockContext.submitFarelockType,
        upgradeBundle,
    };
};
