import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { ItineraryPax } from "../../../component-models/itinerary/ApiItineraryViewModelV2";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { ItineraryPageViewModel } from "../../../component-models/itinerary/ItineraryPageViewModel";
import { itineraryDetailsUtils } from "./itineraryDetailsHelper";
import { useItineraryDetailsPaxFaresAndFees } from "./useItineraryDetailsPaxFaresAndFees";
import { useItineraryDetailsPaxSsrs } from "./useItineraryDetailsPaxSsrs";
import { useFlowContext } from "../../../managers/useFlowContext";

export interface Props {
    model: ItineraryPageViewModel;
    isMobile: boolean;
}

export const useItineraryTabsDetails = (props: Props) => {
    const accordionContentClassName = "i2-details-accordion-content";
    const scrollbarName = "itinerary-details-scrollbar";

    // HELPERS

    const init = () => {
        if (!window.acPerfectScrollbars) {
            window.acPerfectScrollbars = {};
        }

        window.acPerfectScrollbars[scrollbarName] = ScrollHelper.addPerfectScrollbar(accordionContentClassName) as any;
    };

    const updateScrollbar = () => {
        if (window.acPerfectScrollbars[scrollbarName]) {
            window.acPerfectScrollbars[scrollbarName].forEach((scroller) => {
                scroller.update();
            });
        }
    };

    // EVENT HANDLERS

    const handleChangePassenger = (pax: ItineraryPax, paxIndex: number) => {
        if (pax.Type !== "INFANT") {
            const newIndex = paxIndex === openDetailsAccordion ? undefined : paxIndex;
            setOpenDetailsAccordion(newIndex);
            setOpenBreakdownTab("fares");
        }
    };

    // COMPONENT

    const flowContext = useFlowContext();
    const [openDetailsAccordion, setOpenDetailsAccordion] = useState<number>(0);
    const [openBreakdownTab, setOpenBreakdownTab] = useState<"fares" | "extras">("fares");
    const { orderedPassengers } = itineraryDetailsUtils();

    const paxFaresAndFees = useItineraryDetailsPaxFaresAndFees({
        isMobile: props.isMobile,
    });

    const paxSsrs = useItineraryDetailsPaxSsrs({
        journeys: props.model.ItineraryModelV2.Journeys,
        isMobile: props.isMobile,
    });

    useEffect(updateScrollbar, [openDetailsAccordion, openBreakdownTab]);

    useEffect(init, []);

    // TEMPLATES

    const paxHeaderTemplate = (pax: ItineraryPax, i: number) => {
        const headerClassMap = classMap({
            "i2-details-accordion-header": true,
            "cursordefault": pax.Type === "INFANT",
            "open": i === openDetailsAccordion,
        });

        return html`
            <div
                class=${headerClassMap}
                data-test-id=${getTestId(T.ITINERARY.DETAILS_ACCORDION_PER_PAX, {
                    p: pax.PassengerNumber,
                    m: props.isMobile,
                })}
                @click=${() => handleChangePassenger(pax, i)}
            >
                <div class="flex flex-col items-start sm:flex-row sm:items-center">
                    <span class="i2-details-pax-header-number">${i18next.t("Pasajero")} ${i + 1}</span>
                    <span
                        class="i2-details-pax-header-name"
                        data-test-id=${getTestId(T.ITINERARY.DETAILS_PAX_NAME, {
                            p: pax.PassengerNumber,
                            m: props.isMobile,
                        })}
                    >
                        ${pax.FirstName} ${pax.LastName}
                    </span>
                </div>
                <div class="i2-details-pax-header-total">
                    <div class="flex flex-col sm:block">
                        <span>${i18next.t("Total")}</span>
                        <span
                            data-test-value=${pax.Type === "INFANT" ? "0" : pax.Total.toString()}
                            data-test-id=${getTestId(T.ITINERARY.TOTAL_PRICE_PER_PAX, {
                                p: pax.PassengerNumber,
                                m: props.isMobile,
                            })}
                        >
                            ${pax.Type === "INFANT" ? "$0" : pax.FormattedTotal}
                        </span>
                    </div>
                    <i class="js-icon js-circle-chevron-right"></i>
                </div>
            </div>
        `;
    };

    const paxLeftPaneTemplate = (pax: ItineraryPax) => {
        const paneClassMap = classMap({
            "i2-details-left-pane": true,
            "dotted-right": true,
            "hidden-xs": openBreakdownTab !== "fares",
        });

        return html`
            <div class=${paneClassMap}>
                <div class="hidden-xs i2-details-pane-title">${i18next.t("Viaje")}</div>
                ${paxFaresAndFees.htmlTemplate(pax)}
                <div class="i2-fare-total">
                    <span>${i18next.t("Total")}</span>
                    <span>${i18next.t(pax.FormattedFaresAndTaxesTotal)}</span>
                </div>
            </div>
        `;
    };

    const paxRightPaneTemplate = (pax: ItineraryPax) => {
        const paneClassMap = classMap({
            "i2-details-right-pane": true,
            "hidden-xs": openBreakdownTab !== "extras",
        });

        return html`
            <div class=${paneClassMap}>
                <div class="hidden-xs i2-details-pane-title">${i18next.t("Opcionales")}</div>
                ${paxSsrs.htmlTemplate(pax)}
                <div class="flex justify-between">
                    <div class="i2-fare-journey">&nbsp;</div>
                    <div class="i2-ssr-total">
                        <span>${i18next.t("Total")}</span>
                        <span>${pax.FormattedOptionalsTotal || "$0"}</span>
                    </div>
                </div>
            </div>
        `;
    };

    const mobilePaxPaneSwitcher = () => {
        const faresClassMap = classMap({
            "i2-details-pane-title": true,
            "active": openBreakdownTab === "fares",
        });

        const extrasClassMap = classMap({
            "i2-details-pane-title": true,
            "active": openBreakdownTab === "extras",
        });

        return html`
            <div class="hidden-sm-up flex items-center">
                <div class=${faresClassMap} @click=${() => setOpenBreakdownTab("fares")}>${i18next.t("Viaje")}</div>
                <div class=${extrasClassMap} @click=${() => setOpenBreakdownTab("extras")}>
                    ${i18next.t("Opcionales")}
                </div>
            </div>
        `;
    };

    const paxContentTemplate = (pax: ItineraryPax, i: number) => {
        const contentClassMap = classMap({
            [accordionContentClassName]: true,
            last: i === props.model.ItineraryModelV2.Passengers.length - 1,
        });

        const paneClassMap = classMap({
            "flex": true,
            "items-stretch": true,
            "hidden": pax.Type === "INFANT",
        });

        return !flowContext.isAaPostBooking
            ? html`
                  <div class=${contentClassMap}>
                      ${mobilePaxPaneSwitcher()}
                      <div class=${paneClassMap}>${paxLeftPaneTemplate(pax)} ${paxRightPaneTemplate(pax)}</div>
                  </div>
              `
            : "";
    };

    const detailsPassengerTemplate = (pax: ItineraryPax, i: number) => html`
        ${paxHeaderTemplate(pax, i)} ${paxContentTemplate(pax, i)}
    `;

    const htmlTemplate = () => html`
        <div class="no-print">
            <div class="i2-tab-title">
                <i class="js-icon-it js-it-money"></i>
                <div>${i18next.t("Detalle de tu reserva")}</div>
            </div>
            ${orderedPassengers(props.model).map((pax, i) => detailsPassengerTemplate(pax, i))}
        </div>
    `;

    return { htmlTemplate };
};
