import { html, useEffect, useRef, useState } from "haunted";
import { ApiPortalSpaViewModel } from "../../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import i18next from "i18next";
import classNames from "classnames";
import { ref } from "../../../directives/ref";
import { ROUTES } from "../../../shared/apiRoutes";
import { getAntiforgerySegment, isKeyNumeric, sanitizePhoneNumber } from "../../../shared/common";
import { CugUserPhoneType, FAKE_DATE_OF_BIRTH } from "../../../shared/commonConstants";
import { commonValidationRules } from "../../../shared/commonValidationRules";
import { useForm } from "../../../shared/customHooks/useForm/useForm";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { sanitizeInputFieldValue } from "../../../component-helpers/InputSanitizerHelper";
import { usePortalProfileUtils } from "./usePortalProfileUtils";

export interface Props {
    model: ApiPortalSpaViewModel;
    antiForgeryToken: string;
}

export const useEditRestrictedUserForm = (props: Props) => {
    const { handleUserSubmit } = usePortalProfileUtils();

    const userFormElement = useRef<HTMLFormElement>(undefined);

    const [editUserMode, setEditUserMode] = useState<boolean>(true);

    // TODO Replace form validation with fluent validator!
    const form = useForm({
        customAttributes: [...commonValidationRules()],
        noScroll: true,
    });

    // EVENT-HANDLERS

    const handleUserEditorClick = () => {
        if (editUserMode) return;
        setEditUserMode(true);
    };

    const handleUserSubmitClick = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const isValid = await form.validate();

        if (!isValid) return;
        await handleUserSubmit(userFormElement.current, setEditUserMode);
    };

    const handlePhoneInput = (e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement;
        target.value = sanitizePhoneNumber(target.value, true);
    };

    const handlePhoneChange = (e: KeyboardEvent) => {
        if (isKeyNumeric(e, true)) {
            return true;
        } else {
            e.preventDefault();
            return false;
        }
    };

    const handleNameInput = (e: KeyboardEvent) => {
        sanitizeInputFieldValue(e, "accepted-text-chars");
    };

    const handleEmailInput = (e: KeyboardEvent) => {
        sanitizeInputFieldValue(e, "e-mail");
    };

    const handleDniInput = (e: KeyboardEvent) => {
        sanitizeInputFieldValue(e, "user-name");
    };

    // COMPONENT

    useEffect(() => form.init(userFormElement.current), [userFormElement.current]);

    // TEMPLATES
    const userEditorTemplate = () =>
        props.model.EditOrgOrProfileViewModel.CanEditUser
            ? html`
                  <div
                      class=${classNames("cug2b-profile-edit", {
                          "cursor-default": editUserMode,
                          "cursor-pointer": !editUserMode,
                      })}
                      @click=${handleUserEditorClick}
                  >
                      <i class="js-icon-cug js-cug-edit"></i>
                      <span>${i18next.t("Editar")}</span>
                  </div>
              `
            : "";

    const saveMessageTemplate = () =>
        !editUserMode
            ? html`
                  <div class="cug2b-save-message" data-test-id=${T.CUG2_USER_EDIT.SAVE_MESSAGE}>
                      <i class="js-icon-cug js-cug-tick"></i>
                      ${i18next.t("¡Los cambios fueron guardados exitosamente!")}
                  </div>
              `
            : "";

    const inputTemplate = (
        data: {
            label: string;
            name: string;
            value: string;
            isDisabled?: boolean;
            validationRule?: string;
            testId?: string;
        },
        onInput: (e: KeyboardEvent) => void,
    ) => {
        const tabIndex = data.isDisabled ? "-1" : null;
        const validationRule = data.validationRule ? data.validationRule : null;
        const dataTestId = data.testId ? data.testId : null;

        return html`
            <div
                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty is-upgraded"
                data-upgraded=",MaterialTextfield"
            >
                <label class="mdl-textfield__label">${data.label}</label>
                <input
                    type="text"
                    class=${classNames("mdl-textfield__input js-input", {
                        disabled: data.isDisabled,
                    })}
                    tabindex=${tabIndex}
                    autocomplete="cc-exp"
                    name=${data.name}
                    value=${data.value}
                    data-required
                    data-validation=${validationRule}
                    data-test-id=${dataTestId}
                    @input=${(e: KeyboardEvent) => onInput(e)}
                    @blur=${(e: KeyboardEvent) => onInput(e)}
                />
            </div>
        `;
    };

    const firstNameTemplate = () =>
        inputTemplate(
            {
                label: i18next.t("Nombres"),
                name: "updateAgencyProfile.Member.Name.First",
                value: props.model.EditOrgOrProfileViewModel.UpdateOrganizationUserViewModel.Member.Name.First,
                isDisabled: true,
                testId: T.CUG2_USER_EDIT.FIRST_NAME_INPUT_FIELD,
            },
            handleNameInput,
        );

    const lastNameTemplate = () =>
        inputTemplate(
            {
                label: i18next.t("Apellidos"),
                name: "updateAgencyProfile.Member.Name.Last",
                value: props.model.EditOrgOrProfileViewModel.UpdateOrganizationUserViewModel.Member.Name.Last,
                isDisabled: true,
                testId: T.CUG2_USER_EDIT.LAST_NAME_INPUT_FIELD,
            },
            handleNameInput,
        );

    const dniTemplate = () =>
        inputTemplate(
            {
                label: i18next.t("DNI"),
                name: "updateAgencyProfile.Member.UserName",
                value: props.model.EditOrgOrProfileViewModel.UpdateOrganizationUserViewModel.Member.Username,
                isDisabled: true,
                testId: T.PERU_COMPRA_USER_EDIT.DNI,
            },
            handleDniInput,
        );

    const phoneTemplate = (label: string, type: CugUserPhoneType) => {
        let inputName;
        let value;
        let dataTestId;

        switch (type) {
            case "office":
                inputName = "updateAgencyProfile.Member.HomePhoneNumber.Number";
                value =
                    props.model.EditOrgOrProfileViewModel.UpdateOrganizationUserViewModel.Member.HomePhoneNumber.Number;
                dataTestId = T.PERU_COMPRA_USER_EDIT.OFFICE_PHONE_INPUT_FIELD;
                break;
            case "mobile":
                inputName = "updateAgencyProfile.Member.MobilePhoneNumber.Number";
                value =
                    props.model.EditOrgOrProfileViewModel.UpdateOrganizationUserViewModel.Member.MobilePhoneNumber
                        .Number;
                dataTestId = T.PERU_COMPRA_USER_EDIT.MOBILE_PHONE_INPUT_FIELD;
                break;
        }

        return html`
            <div
                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty is-upgraded"
                data-upgraded=",MaterialTextfield"
            >
                <label class="mdl-textfield__label">${label}</label>
                <input
                    type="text"
                    name=${inputName}
                    class=${classNames("mdl-textfield__input js-input", {
                        disabled: !editUserMode || !props.model.EditOrgOrProfileViewModel.CanEditUser,
                    })}
                    autocomplete="cc-exp"
                    data-required
                    data-validation="AdminForm.Phone"
                    value=${value}
                    data-test-id=${dataTestId}
                    @input=${handlePhoneInput}
                    @keydown=${handlePhoneChange}
                />
            </div>
        `;
    };

    const userEmailTemplate = () =>
        inputTemplate(
            {
                label: i18next.t("Correo electrónico"),
                name: "updateAgencyProfile.Member.PersonalEmailAddress.EmailAddress",
                value: props.model.EditOrgOrProfileViewModel.UpdateOrganizationUserViewModel.Member.PersonalEmailAddress
                    .EmailAddress,
                validationRule: "AdminForm.Email",
                isDisabled: !editUserMode || !props.model.EditOrgOrProfileViewModel.CanEditUser,
                testId: T.CUG2_USER_EDIT.EMAIL_INPUT_FIELD,
            },
            handleEmailInput,
        );

    const peruCompraUserTemplate = () => html`
        <div class="row">
            <div class="col-xs-1 col-sm-1-2">${firstNameTemplate()}</div>
            <div class="col-xs-1 col-sm-1-2">${lastNameTemplate()}</div>
            <div class="col-xs-1 col-sm-1-2">${dniTemplate()}</div>
            <div class="col-xs-1 col-sm-1-2">${phoneTemplate(i18next.t("Office Phone"), "office")}</div>
            <div class="col-xs-1 col-sm-1-2">${phoneTemplate(i18next.t("Teléfono móvil"), "mobile")}</div>
            <div class="col-xs-1 col-sm-1-2">${userEmailTemplate()}</div>
        </div>
    `;

    const submitButtonTemplate = () => html`
        <div class="mt-8 flex w-full justify-end">
            <button
                @click=${handleUserSubmitClick}
                class=${classNames("rounded-primary-btn", {
                    disabled: !editUserMode || !props.model.EditOrgOrProfileViewModel.CanEditUser,
                })}
                data-test-id=${T.CUG2_USER_EDIT.SAVE_BUTTON}
            >
                ${i18next.t("Guardar")}
            </button>
        </div>
    `;

    const htmlTemplate = () =>
        props.model.ProfileViewModel.UserModel
            ? html`
                  <form
                      ref=${ref(userFormElement)}
                      action=${ROUTES.Cug2BUserProfile}
                      class=${classNames("cug2b-edit-profile-form ts-error-parent", {
                          "mt-8 md:mt-16": props.model.ProfileViewModel.CompanyModel,
                      })}
                      method="post"
                      novalidate="novalidate"
                  >
                      <h1>${i18next.t("Usuario")} ${userEditorTemplate()}</h1>
                      ${saveMessageTemplate()} ${getAntiforgerySegment(props.antiForgeryToken)}
                      ${peruCompraUserTemplate()}

                      <input
                          value=${props.model.EditOrgOrProfileViewModel.UpdateOrganizationUserViewModel.Member
                              .DateOfBirth || FAKE_DATE_OF_BIRTH}
                          type="hidden"
                          name="updateAgencyProfile.Member.DateOfBirth"
                      />
                      <input type="hidden" name="updateAgencyProfile.Member.Gender" value="1" />

                      ${submitButtonTemplate()}
                  </form>
              `
            : "";

    return { htmlTemplate };
};
