import { html } from "haunted";
import i18next from "i18next";
import { GridHelper } from "../../../component-helpers/GridHelper";
import {
    Column,
    GridState,
    PageChangeEvent,
    RowsSelectedEvent,
    SortChangeEvent,
    ViewModel,
} from "../../../dc-components/dc-table-models";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import { handleCugLoader, SelectOption, updateMdl } from "../../../shared/common";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { PeruCompraReservationModel } from "../../../component-models/peru-compra/PeruCompraReservationModel";
import { ChangeDateEvent } from "../../../dc-components/dc-datepicker";
import { DEFAULT_DATE_FORMAT } from "../../../shared/commonConstants";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useDatepicker } from "../../ui/useDatepicker/useDatepicker";
import { ROUTES } from "../../../shared/apiRoutes";
dayjs.extend(CustomParseFormat);

const actionCellClass = ["pinned"];
const actionCellContentClass = ["action-cell", "pcra-action-cell"];

export interface Props {
    agentsInOrganization: SelectOption[];
    canExport: boolean;
    data: PeruCompraReservationModel[];
    filterFlightDateFrom: dayjs.Dayjs;
    filterFlightDateTo: dayjs.Dayjs;
    filterUserId: number;
    gridState: GridState<keyof PeruCompraReservationModel>;
    selectedIds: string[];
    totalItemCount: number;
    selectedConfirmedIds: Set<string>;
    container: HTMLElement;
    setSelectedConfirmedIds: (newState: Set<string>) => void;
    setGridState: (newState: GridState<keyof PeruCompraReservationModel>) => void;
    setHasSubmittedDateFiltering: (newState: boolean) => void;
    setHasSubmittedUserFiltering: (newState: boolean) => void;
    setFilterFlightDateFrom: (newState: dayjs.Dayjs) => void;
    setFilterFlightDateTo: (newState: dayjs.Dayjs) => void;
    setFilterUserId: (newState: number) => void;
    setIsDetailsModalOpen: (newState: boolean) => void;
    setDetailsModalData: (newState: PeruCompraReservationModel) => void;
    loadData: (filterForDates?: boolean, filterForUser?: boolean) => Promise<void>;
}

export const usePeruCompraConfirmedReservations = (props: Props) => {
    const [userContext] = useReduxState("userContext");

    // HELPERS

    const getToDate = () =>
        !props.filterFlightDateFrom ||
        !props.filterFlightDateTo ||
        props.filterFlightDateFrom.isBefore(props.filterFlightDateTo, "day")
            ? props.filterFlightDateTo
            : props.filterFlightDateFrom;

    // EVENT-HANDLERS

    const handleDetailsClick = (e: MouseEvent, index: number) => {
        e.preventDefault();
        props.setIsDetailsModalOpen(true);
        props.setDetailsModalData(props.data[index]);
    };

    const handleEyeIconClick = (e: MouseEvent, index: number) => {
        e.preventDefault();

        handleCugLoader(props.container, "toItinerary");
        const url = `${ROUTES.BookingRetrieve}/?em=${props.data[index].PrimaryContact}&rl=${props.data[index].PNR}`;
        window.location.href = url;
    };

    const handleFilterClick = () => {
        const filterForDates = Boolean(props.filterFlightDateFrom) || Boolean(props.filterFlightDateTo);
        const filterForUsers = Boolean(props.filterUserId) && props.filterUserId !== -1;

        // DEVNOTE Only submit filter params to BE if the user has at least once clicked Search next to the datepickers
        props.setHasSubmittedDateFiltering(filterForDates);
        props.setHasSubmittedUserFiltering(filterForUsers);

        props.setGridState({ ...props.gridState, pageIndex: 0 });
    };

    const handleEmptyDateFiltersClick = async () => {
        props.setFilterFlightDateFrom(undefined);
        props.setFilterFlightDateTo(undefined);
        props.setFilterUserId(-1);
        await props.loadData(false, false);
        props.setHasSubmittedDateFiltering(false);
        props.setHasSubmittedUserFiltering(false);
    };

    const handleUserFilterChange = (e: MouseEvent) => {
        props.setFilterUserId(Number((e.target as HTMLSelectElement).value));
    };

    const handleCancelBooking = (index: number) => {
        handleCugLoader(props.container, "toItinerary");
        const url = `${ROUTES.BookingRetrieve}/?em=${props.data[index].PrimaryContact}&rl=${props.data[index].PNR}&tab=Modify`;
        window.location.href = url;
    };

    const onPageChange = (e: PageChangeEvent) => {
        props.setGridState({
            ...props.gridState,
            pageIndex: e.detail.selectedPageIndex,
            pageSize: e.detail.selectedPageSize,
        });
    };

    const onSortChange = (e: SortChangeEvent) => {
        props.setGridState({
            ...props.gridState,
            orderBy: e.detail.orderBy as keyof PeruCompraReservationModel,
            orderDir: e.detail.orderDir,
        });
    };

    const onRowsSelect = (event: RowsSelectedEvent) => {
        props.setSelectedConfirmedIds(
            GridHelper.getSelectedIds(event, props.selectedConfirmedIds, props.data) as Set<string>,
        );
    };

    // COMPONENT

    const fromDatePicker = useDatepicker({
        classes: [],
        inputClasses: ["cug2-filter-date-input", "cug2-filter-date-from"],
        placeHolder: i18next.t("Desde"),
        value: props.filterFlightDateFrom,
        onChange: (e: ChangeDateEvent) => props.setFilterFlightDateFrom(e.detail.date),
    });

    const toDatePicker = useDatepicker({
        classes: ["sm-align-right", "xs-align-right"],
        inputClasses: ["cug2-filter-date-input", "cug2-filter-date-to"],
        placeHolder: i18next.t("Hasta"),
        value: props.filterFlightDateFrom
            ? [
                  props.filterFlightDateFrom.format(DEFAULT_DATE_FORMAT) || "",
                  getToDate()?.format(DEFAULT_DATE_FORMAT) || "",
              ]
            : getToDate()?.format(DEFAULT_DATE_FORMAT),
        min: props.filterFlightDateFrom || undefined,
        isRange: Boolean(props.filterFlightDateFrom),
        onChange: (e: ChangeDateEvent) =>
            props.setFilterFlightDateTo(Boolean(props.filterFlightDateFrom) ? e.detail.toDate : e.detail.date),
    });

    // TEMPLATES

    const eyeIconTemplate = (index: number) => {
        return html`<i
            class="js-icon-covid js-cv-eye-icon"
            @click=${(e: MouseEvent) => handleEyeIconClick(e, index)}
        ></i>`;
    };

    const detailsIconTemplate = (index: number) => {
        return html`<i
            class="js-icon-covid js-cv-file-icon"
            @click=${(e: MouseEvent) => handleDetailsClick(e, index)}
        ></i>`;
    };

    const actionIconsTemplate = (index: number) => html`
        <div class=${GridHelper.getClassMap([...actionCellClass, "dctg-body-cell"])}>
            <div class=${GridHelper.getClassMap(actionCellContentClass)}>
                ${eyeIconTemplate(index)} ${detailsIconTemplate(index)}
            </div>
        </div>
    `;

    const cellTemplate = (index: number, field: keyof PeruCompraReservationModel) => {
        const row = props.data[index];
        const rowFieldString = row[field]?.toString() || "";

        if (field) {
            return html` <span title=${rowFieldString}>${rowFieldString}</span> `;
        }

        return "";
    };

    const cancelBookingCellTemplate = (index: number) => {
        return html`
            <span
                class="pcra-cancel-booking-icon"
                title=${i18next.t("Anular boleto")}
                @click=${() => handleCancelBooking(index)}
            >
                <i class="js-icon-cug js-cug-edit"></i>
            </span>
        `;
    };

    const dayJsCellTemplate = (index: number, field: keyof PeruCompraReservationModel) => {
        const row = props.data[index];
        const cellValue = row[field] as dayjs.Dayjs;
        const formattedDate = cellValue ? dayjs(cellValue).format("DD/MM/YYYY") : "";

        return field ? html` <span title=${formattedDate}>${formattedDate}</span> ` : "";
    };

    const descriptionTemplate = () => {
        return html`
            <div class="cug2b-search-and-title push-down">
                <div class="cug2b-page-subtitle">
                    ${i18next.t("Reservas confirmadas")}
                    <div class="block font-body text-sm font-normal text-brand-secondary">
                        ${i18next.t(
                            "La información entregada corresponde a las últimas 10 reservas realizadas. Para ver un rango de tiempo determinado, por favor utilizar los filtros de fecha",
                        )}
                    </div>
                </div>
            </div>
        `;
    };

    const usersOptionsTemplate = () =>
        props.agentsInOrganization.map(
            (agent) => html`
                <option value=${agent.Value} ?selected=${Number(agent.Value) === props.filterUserId}>
                    ${agent.Text}
                </option>
            `,
        );

    const userFilterTemplate = () =>
        userContext.peruCompra.role === "admin"
            ? html`
                  <div class="cug-label-and-filter">
                      <label>
                          ${unsafeHTML(
                              i18next.t("Filtrar por {{-spanStart}}usuario{{-spanEnd}}", {
                                  spanStart: '<span class="font-bold">',
                                  spanEnd: "</span>",
                              }),
                          )}
                      </label>
                      <div class="cug-filter-input-fields">
                          <select class="cug-filter-user-input" @change=${handleUserFilterChange}>
                              <option ?selected=${props.filterUserId === -1} value="-1"></option>
                              ${usersOptionsTemplate()}
                          </select>
                      </div>
                  </div>
              `
            : "";

    const dateFilterTemplate = () => html`
        <div class="cug-label-and-filter">
            <label>
                ${unsafeHTML(
                    i18next.t("Filtrar por {{-spanStart}}fecha de vuelo:{{-spanEnd}}", {
                        spanStart: '<span class="font-bold">',
                        spanEnd: "</span>",
                    }),
                )}
            </label>
            <div class="cug-filter-input-fields">${fromDatePicker.htmlTemplate()} ${toDatePicker.htmlTemplate()}</div>
        </div>
    `;

    const filterTemplate = () => {
        return html`
            <div class="cug-filter-container">
                <div class="cug-filters push-down">
                    ${dateFilterTemplate()} ${userFilterTemplate()}
                    <div class="cug2-filter-button-container">
                        <button class="rounded-primary-btn cug-blue" @click=${handleFilterClick}>
                            ${i18next.t("Buscar")}
                        </button>
                    </div>
                </div>
                <div class="flex justify-end">
                    <div class="cug2-empty-filters" @click=${handleEmptyDateFiltersClick}>
                        ${i18next.t("Limpiar Filtros")}
                    </div>
                </div>
            </div>
        `;
    };

    const vm: ViewModel<keyof PeruCompraReservationModel> = {
        columns: [
            {
                field: "PNR",
                columnType: "string",
                label: i18next.t("Código de reserva"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "PNR"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "RUC",
                columnType: "string",
                label: i18next.t("RUC Entidad"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "RUC"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "AdminId",
                columnType: "string",
                label: i18next.t("Gestor"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "AdminId"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "MemberId",
                columnType: "string",
                label: i18next.t("Delegado"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "MemberId"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "OperationType",
                columnType: "string",
                label: i18next.t("Tipo Oper."),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "OperationType"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "InvoiceQuantity",
                columnType: "number",
                label: i18next.t("C. Boletos"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "InvoiceQuantity"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "ServiceType",
                columnType: "string",
                label: i18next.t("Tipo Serv."),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "ServiceType"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "CreatedDate",
                columnType: "string",
                label: i18next.t("Fec. Hora Oper."),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => dayJsCellTemplate(index, "CreatedDate"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "TicketNumber",
                columnType: "string",
                label: i18next.t("Boleto"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "TicketNumber"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "NumberOfLegs",
                columnType: "string",
                label: i18next.t("Tramo"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "NumberOfLegs"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "FlightNumber",
                columnType: "string",
                label: i18next.t("N° Vuelo"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "FlightNumber"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "Origin",
                columnType: "string",
                label: i18next.t("Origen"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "Origin"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "DepartureDate",
                columnType: "string",
                label: i18next.t("Fec. Hora Origen"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => dayJsCellTemplate(index, "DepartureDate"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "Destination",
                columnType: "string",
                label: i18next.t("Destino"),
                sortable: true,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "Destination"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "ArrivalDate",
                columnType: "string",
                label: i18next.t("Fec. Hora Destino"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => dayJsCellTemplate(index, "ArrivalDate"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "PassengerDocumentId",
                columnType: "string",
                label: i18next.t("Doc. Id. Pasajero"),
                sortable: true,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "PassengerDocumentId"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "LastName",
                columnType: "string",
                label: i18next.t("Apellidos"),
                sortable: true,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "LastName"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "FirstName",
                columnType: "string",
                label: i18next.t("Nombre"),
                sortable: true,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "FirstName"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "TotalAmount",
                columnType: "string",
                label: i18next.t("Importe Tramo"),
                sortable: true,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "TotalAmount"),
            } as Column<keyof PeruCompraReservationModel>,
            {
                field: "PNR",
                columnType: "string",
                label: i18next.t("Anular boleto"),
                sortable: false,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cancelBookingCellTemplate(index),
            } as Column<keyof PeruCompraReservationModel>,
        ],
        data: props.data,
        paging: {
            pageable: true,
            pageIndex: props.gridState.pageIndex,
            pageSize: props.gridState.pageSize,
            buttonCount: 5,
            pageSizes: [10],
            itemCount: props.totalItemCount,
            showInfo: false,
        },
        sorting: {
            orderBy: props.gridState.orderBy,
            orderDir: props.gridState.orderDir,
            showSorterArrow: false,
        },
        selection: {
            selectable: false,
        },
        rowCustomization: [],
        appliedFilters: [],
        rowEditTemplate: actionIconsTemplate,
        actionCellClass,
        actionCellContentClass,
        useEllipsis: false,
    };

    const htmlTemplate = () =>
        props.gridState && props.data
            ? html`
                  ${updateMdl()} ${descriptionTemplate()} ${filterTemplate()}
                  <div class="cug2b-confirmed-reservation-scrolling-tip">
                      ${i18next.t("Deslizando hacia la derecha en la tabla podrás visualizar más campos.")}
                  </div>
                  <dc-table-grid
                      .vm=${vm}
                      @onSortChange=${onSortChange}
                      @onRowsSelect=${onRowsSelect}
                      @onPageChange=${onPageChange}
                  ></dc-table-grid>
              `
            : "";

    return { htmlTemplate };
};
