import { html, useRef } from "haunted";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import { Partner } from "../../../component-models/CUG2b/Partner";
import i18next from "i18next";
import { useState } from "../../../shared/haunted/CustomHooks";
import { ref } from "../../../directives/ref";
import { ROUTES } from "../../../shared/apiRoutes";
import { TravelPartnerHelper } from "../../../component-helpers/TravelPartnerHelper";
import { handleCugLoader } from "../../../shared/common";
import { URL_VARS } from "../../../shared/commonConstants";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";

export const useCugAddTravelPartnerPage = () => {
    const root = useRef<HTMLDivElement>(undefined);

    const { getTravelPartnerInfo, postTravelPartnerInfo } = useAjax();

    const [travelPartnerInfo, setTravelPartnerInfo] = useState<TravelPartnerInfo>(undefined);

    // HELPERS

    const init = () => {
        const doIt = async () => {
            const result = await getTravelPartnerInfo();
            setTravelPartnerInfo(result);
        };

        void doIt();
    };

    // EVENT HANDLERS

    const handleSave = async (partner: Partner) => {
        const newTravelPartnerInfo = TravelPartnerHelper.updatePartner(travelPartnerInfo, {
            ...partner,
        });

        handleCugLoader(root.current, "loadData");

        await postTravelPartnerInfo(newTravelPartnerInfo);

        window.location.href =
            partner.GroupId > -1
                ? `${ROUTES.Cug2BEditTravelGroupPage}?${URL_VARS.FFLYER_IDS}=${encodeURIComponent(partner.GroupId)}`
                : ROUTES.Cug2BTravelPartnersPage;
    };

    // COMPONENT

    // TEMPLATES

    const staticStuffTemplate = () => html`
        <div class="travel-partner-breadcrumb-container">
            <div
                class="travel-partner-breadcrumb past"
                @click=${() => (window.location.href = ROUTES.Cug2BTravelPartnersPage)}
            >
                ${i18next.t("Pasajeros frecuentes")}
            </div>
            <div class="travel-partner-breadcrumb divider">/</div>
            <div class="travel-partner-breadcrumb">${i18next.t("Agregar pasajeros")}</div>
        </div>
        <div class="travel-partner-hero">
            <h1>${i18next.t("Pasajeros Nuevos")}</h1>
            <div>
                ${i18next.t(
                    "Aquí deberás ingresar la información del o los nuevos pasajeros frecuentes. ¡No te preocupes! esta información se podrá editar en cualquier momento.",
                )}
            </div>
            <div class="mt-4 text-xs md:text-base">
                * ${i18next.t("Recuerda que tus pasajeros frecuentes deben ser adultos (mayores de 18 años).")}
            </div>
        </div>
        <div class="travel-partner-title">
            <i class="js-icon-cug js-cug-man-and-plus"></i>
            <h2>${i18next.t("Agregar pasajero")}</h2>
        </div>
    `;

    const formTemplate = () => html`
        <ac-travel-partner
            .model=${travelPartnerInfo}
            .showCancelButton=${true}
            .showBackButton=${true}
            .vm=${undefined}
            .handleEditSubmit=${handleSave}
        ></ac-travel-partner>
    `;

    const htmlTemplate = () =>
        travelPartnerInfo ? html` <div ref=${ref(root)}>${staticStuffTemplate()} ${formTemplate()}</div> ` : html``;

    return { init, htmlTemplate };
};
