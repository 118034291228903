import { component } from "haunted";

import "./dc-components";
import * as Baggage from "./components/spa/baggage/baggage-components";
import * as BookingOpener from "./components/booking-opener";
import * as ChangeSearch from "./components/change-search/change-search";
import * as Checkin from "./components/checkin/checkin-components";
import * as CugFooter from "./components/cug-footer/cug-footer";
import * as CugHeader from "./components/cug-header/cug-header";
import * as PcraHeader from "./components/cug-header/pcra-header";
import * as CugPortal from "./components/cug-portal/cug-portal-components";
import * as CugRegistration from "./components/cug-register/cug-register-components";
import * as Extras from "./components/spa/extras/extras-components";
import * as Flight from "./components/flight-select/flight-components";
import * as FlightCalendar from "./components/flight-calendar/flight-calendar-components";
import * as FlightMove from "./components/flight-move/flight-move-components";
import * as ForgotPassword from "./components/forgot-password";
import * as Giftcard from "./components/giftcard/giftcard-components";
import * as Insurance from "./components/spa/extras/insurance/insurance-components";
import * as Itinerary from "./components/itinerary/itinerary-components";
import * as Layout from "./components/layout/layout-components";
import * as LogBancoEstadoRegister from "./components/register/log-banco-estado-register";
import * as Login from "./components/login/login-components";
import * as NoPeruCompraDc from "./components/register/no-peru-compra-dc";
import * as PageReloader from "./components/page-reloader";
import * as Passengers from "./components/spa/passengers/passengers-components";
import * as PasswordReset from "./components/password-reset/password-reset";
import * as Payment from "./components/payment/payment-components";
import * as ProfilePage from "./components/profile/profile-page";
import * as PromoCodeInvalidModal from "./components/promo-code-invalid-modal";
import * as Register from "./components/register/register";
import * as SeatMap from "./components/spa/seatmap/seatmap-components";
import * as SessionKeepAlive from "./components/session-keep-alive";
import * as SessionModal from "./components/session-modal/session-modal";
import * as Sidebar from "./components/sidebar/sidebar-components";
import * as Spa from "./components/spa/spa-components";
import * as StaffBenefit from "./components/staff-benefit/staff-benefit";
import * as StaffFamilyMembers from "./components/staff-benefit/staff-family-members";
import * as StandaloneLoader from "./components/standalone-loader";
import * as TimeZoneAdapter from "./components/time-zone-adapter";
import * as TransactionLogger from "./components/transaction-logger";
import * as Ui from "./components/ui/ui-components";
import { BoardingPassImageDto } from "./components/checkin/boarding-passes/useBoardingPassObtainer";
import * as LoginModal from "./components/layout/login-modal";
import * as EmergencyRowWarningModal from "./components/spa/seatmap/modals/emergency-row-warning-modal";

export function initCustomElements() {
    customElements.define(
        LoginModal.name,
        component<HTMLElement & LoginModal.Props & LoginModal.Attributes>(LoginModal.Component, {
            useShadowDOM: false,
            observedAttributes: LoginModal.observedAttributes,
        }),
    );

    customElements.define(
        EmergencyRowWarningModal.name,
        component<HTMLElement>(EmergencyRowWarningModal.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        LogBancoEstadoRegister.name,
        component<HTMLElement & LogBancoEstadoRegister.Props & LogBancoEstadoRegister.Attributes>(
            LogBancoEstadoRegister.Component,
            {
                useShadowDOM: false,
                observedAttributes: LogBancoEstadoRegister.observedAttributes,
            },
        ),
    );

    customElements.define(
        PasswordReset.name,
        component<HTMLElement & PasswordReset.Props & PasswordReset.Attributes>(PasswordReset.Component, {
            useShadowDOM: false,
            observedAttributes: PasswordReset.observedAttributes,
        }),
    );

    customElements.define(
        Ui.DatepickerTimeUnit.name,
        component<HTMLElement & Ui.DatepickerTimeUnit.Props>(Ui.DatepickerTimeUnit.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Ui.Dropdown.name,
        component<HTMLElement & Ui.Dropdown.Props>(Ui.Dropdown.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Ui.Tooltip.name,
        component<HTMLElement & Ui.Tooltip.Props & Ui.Tooltip.Attributes>(Ui.Tooltip.Component, {
            useShadowDOM: false,
            observedAttributes: Ui.Tooltip.observedAttributes,
        }),
    );

    customElements.define(
        Ui.TooltipBalloon.name,
        component<HTMLElement & Ui.TooltipBalloon.TooltipBalloonProps>(Ui.TooltipBalloon.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Ui.Select.name,
        component<HTMLElement & Ui.Select.Props>(Ui.Select.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Ui.Input.name,
        component<HTMLElement & Ui.Input.Props>(Ui.Input.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        FlightCalendar.FlightCalendarPage.name,
        component<
            HTMLElement &
                FlightCalendar.FlightCalendarPage.FlightCalendarProps &
                FlightCalendar.FlightCalendarPage.Attributes
        >(FlightCalendar.FlightCalendarPage.Component, {
            useShadowDOM: false,
            observedAttributes: FlightCalendar.FlightCalendarPage.observedAttributes,
        }),
    );

    customElements.define(
        FlightCalendar.FlightCalendarJourney.name,
        component<HTMLElement & FlightCalendar.FlightCalendarJourney.Props>(
            FlightCalendar.FlightCalendarJourney.Component,
            {
                useShadowDOM: false,
                observedAttributes: [],
            },
        ),
    );

    customElements.define(
        FlightCalendar.FlightCalendarDay.name,
        component<HTMLElement & FlightCalendar.FlightCalendarDay.Props>(FlightCalendar.FlightCalendarDay.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        FlightMove.FlightMovePage.name,
        component<HTMLElement & FlightMove.FlightMovePage.Props & FlightMove.FlightMovePage.Attributes>(
            FlightMove.FlightMovePage.Component,
            {
                useShadowDOM: false,
                observedAttributes: FlightMove.FlightMovePage.observedAttributes,
            },
        ),
    );

    customElements.define(
        PromoCodeInvalidModal.name,
        component<HTMLElement>(PromoCodeInvalidModal.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Passengers.Passenger.name,
        component<HTMLElement & Passengers.Passenger.Props>(Passengers.Passenger.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Passengers.DiscountSelector.name,
        component<HTMLElement & Passengers.DiscountSelector.Props>(Passengers.DiscountSelector.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Passengers.AssistanceOption.name,
        component<HTMLElement & Passengers.AssistanceOption.Properties>(Passengers.AssistanceOption.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Insurance.InsurancePassengerInsuranceSelector.name,
        component<HTMLElement & Insurance.InsurancePassengerInsuranceSelector.Props>(
            Insurance.InsurancePassengerInsuranceSelector.Component,
            {
                useShadowDOM: false,
                observedAttributes: [],
            },
        ),
    );

    customElements.define(
        Extras.PriorityBoardingPassenger.name,
        component<HTMLElement & Extras.PriorityBoardingPassenger.Props>(Extras.PriorityBoardingPassenger.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Insurance.InsuranceBaggage.name,
        component<HTMLElement & Insurance.InsuranceBaggage.Props>(Insurance.InsuranceBaggage.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Insurance.InsuranceBaggageContainer.name,
        component<HTMLElement & Insurance.InsuranceBaggageContainer.Props>(
            Insurance.InsuranceBaggageContainer.Component,
            {
                useShadowDOM: false,
                observedAttributes: [],
            },
        ),
    );

    customElements.define(
        Extras.CheckinTypePassenger.name,
        component<HTMLElement & Extras.CheckinTypePassenger.Props>(Extras.CheckinTypePassenger.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Extras.CheckinTypePassengerJourney.name,
        component<HTMLElement & Extras.CheckinTypePassengerJourney.Props>(
            Extras.CheckinTypePassengerJourney.Component,
            {
                useShadowDOM: false,
                observedAttributes: [],
            },
        ),
    );

    customElements.define(
        Passengers.AddTravelPartnersToBooking.name,
        component<HTMLElement & Passengers.AddTravelPartnersToBooking.Properties>(
            Passengers.AddTravelPartnersToBooking.Component,
            {
                useShadowDOM: false,
                observedAttributes: [],
            },
        ),
    );

    customElements.define(
        BookingOpener.name,
        component<HTMLElement>(BookingOpener.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Checkin.CheckinPassenger.name,
        component<HTMLElement & Checkin.CheckinPassenger.Props>(Checkin.CheckinPassenger.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Checkin.CheckinPassengerContainer.name,
        component<HTMLElement & Checkin.CheckinPassengerContainer.Props & Checkin.CheckinPassengerContainer.Attributes>(
            Checkin.CheckinPassengerContainer.Component,
            {
                useShadowDOM: false,
                observedAttributes: Checkin.CheckinPassengerContainer.observedAttributes,
            },
        ),
    );

    customElements.define(
        Checkin.CheckinAdditionalInfo.name,
        component<HTMLElement & Checkin.CheckinAdditionalInfo.Props & Checkin.CheckinAdditionalInfo.Attributes>(
            Checkin.CheckinAdditionalInfo.Component,
            {
                useShadowDOM: false,
                observedAttributes: Checkin.CheckinAdditionalInfo.observedAttributes,
            },
        ),
    );

    customElements.define(
        Checkin.CheckinPassengerDocument.name,
        component<HTMLElement & Checkin.CheckinPassengerDocument.Props>(Checkin.CheckinPassengerDocument.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Checkin.BoardingPassPrintPage.name,
        component<HTMLElement & Checkin.BoardingPassPrintPage.Props>(Checkin.BoardingPassPrintPage.Component, {
            useShadowDOM: false,
            observedAttributes: Checkin.BoardingPassPrintPage.observedAttributes,
        }),
    );

    customElements.define(
        Checkin.BoardingPass.name,
        component<HTMLElement & BoardingPassImageDto>(Checkin.BoardingPass.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        StandaloneLoader.name,
        component<HTMLElement>(StandaloneLoader.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        ForgotPassword.name,
        component<HTMLElement & ForgotPassword.Attributes & ForgotPassword.Props>(ForgotPassword.Component, {
            useShadowDOM: false,
            observedAttributes: ForgotPassword.observedAttributes,
        }),
    );

    customElements.define(
        Payment.PaymentPage.name,
        component<HTMLElement>(Payment.PaymentPage.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        ChangeSearch.name,
        component<HTMLElement & ChangeSearch.Attributes & ChangeSearch.Props>(ChangeSearch.Component, {
            useShadowDOM: false,
            observedAttributes: ChangeSearch.observedAttributes,
        }),
    );

    customElements.define(
        PageReloader.name,
        component<HTMLElement & PageReloader.Attributes & PageReloader.Props>(PageReloader.Component, {
            useShadowDOM: false,
            observedAttributes: PageReloader.observedAttributes,
        }),
    );

    customElements.define(
        TimeZoneAdapter.name,
        component<HTMLElement & TimeZoneAdapter.Attributes & TimeZoneAdapter.Props>(TimeZoneAdapter.Component, {
            useShadowDOM: false,
            observedAttributes: TimeZoneAdapter.observedAttributes,
        }),
    );

    customElements.define(
        Login.LoginPage.name,
        component<HTMLElement & Login.LoginPage.Attributes & Login.LoginPage.Properties>(Login.LoginPage.Component, {
            useShadowDOM: false,
            observedAttributes: Login.LoginPage.observedAttributes,
        }),
    );

    customElements.define(
        Login.AgencyLoginPage.name,
        component<HTMLElement & Login.AgencyLoginPage.Attributes & Login.AgencyLoginPage.Properties>(
            Login.AgencyLoginPage.Component,
            {
                useShadowDOM: false,
                observedAttributes: Login.AgencyLoginPage.observedAttributes,
            },
        ),
    );

    customElements.define(
        Login.LoginTypeSelector.name,
        component<HTMLElement & Login.LoginTypeSelector.Properties>(Login.LoginTypeSelector.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Login.CustomerLogin.name,
        component<HTMLElement & Login.CustomerLogin.Properties>(Login.CustomerLogin.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Login.PeruCompraLogin.name,
        component<HTMLElement & Login.PeruCompraLogin.Properties>(Login.PeruCompraLogin.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Login.AgencyLogin.name,
        component<HTMLElement & Login.AgencyLogin.Properties>(Login.AgencyLogin.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        CugRegistration.CugRegister.name,
        component<HTMLElement & CugRegistration.CugRegister.Attributes & CugRegistration.CugRegister.Properties>(
            CugRegistration.CugRegister.Component,
            {
                useShadowDOM: false,
                observedAttributes: CugRegistration.CugRegister.observedAttributes,
            },
        ),
    );

    customElements.define(
        CugRegistration.CugRegisterBar.name,
        component<HTMLElement & CugRegistration.CugRegisterBar.Properties>(CugRegistration.CugRegisterBar.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        CugRegistration.CugRegisterCompany.name,
        component<HTMLElement & CugRegistration.CugRegisterCompany.Properties>(
            CugRegistration.CugRegisterCompany.Component,
            {
                useShadowDOM: false,
                observedAttributes: [],
            },
        ),
    );

    customElements.define(
        CugRegistration.CugRegisterAdmin.name,
        component<HTMLElement & CugRegistration.CugRegisterAdmin.Properties>(
            CugRegistration.CugRegisterAdmin.Component,
            {
                useShadowDOM: false,
                observedAttributes: [],
            },
        ),
    );

    customElements.define(
        CugRegistration.CugRegisterSummary.name,
        component<HTMLElement & CugRegistration.CugRegisterSummary.Properties>(
            CugRegistration.CugRegisterSummary.Component,
            {
                useShadowDOM: false,
                observedAttributes: [],
            },
        ),
    );

    customElements.define(
        CugRegistration.CugRegisterResult.name,
        component<HTMLElement & CugRegistration.CugRegisterResult.Properties>(
            CugRegistration.CugRegisterResult.Component,
            {
                useShadowDOM: false,
                observedAttributes: [],
            },
        ),
    );

    customElements.define(
        SeatMap.Passenger.name,
        component<HTMLElement & SeatMap.Passenger.Props>(SeatMap.Passenger.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        SeatMap.BE56Passenger.name,
        component<HTMLElement & SeatMap.BE56Passenger.Props>(SeatMap.BE56Passenger.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        SeatMap.MobilePassenger.name,
        component<HTMLElement & SeatMap.MobilePassenger.Props>(SeatMap.MobilePassenger.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        SeatMap.Seat.name,
        component<HTMLElement & SeatMap.Seat.Props>(SeatMap.Seat.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        SeatMap.SeatCategoriesLegend.name,
        component<HTMLElement & SeatMap.SeatCategoriesLegend.Props>(SeatMap.SeatCategoriesLegend.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        CugHeader.name,
        component<HTMLElement & CugHeader.Attributes & CugHeader.Properties>(CugHeader.Component, {
            useShadowDOM: false,
            observedAttributes: CugHeader.observedAttributes,
        }),
    );
    customElements.define(
        PcraHeader.name,
        component<HTMLElement & PcraHeader.Attributes & PcraHeader.Properties>(PcraHeader.Component, {
            useShadowDOM: false,
            observedAttributes: PcraHeader.observedAttributes,
        }),
    );

    customElements.define(
        CugFooter.name,
        component<HTMLElement>(CugFooter.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        CugPortal.PortalSpaContainer.name,
        component<HTMLElement & CugPortal.PortalSpaContainer.Attributes & CugPortal.PortalSpaContainer.Props>(
            CugPortal.PortalSpaContainer.Component,
            {
                useShadowDOM: false,
                observedAttributes: CugPortal.PortalSpaContainer.observedAttributes,
            },
        ),
    );

    customElements.define(
        CugPortal.Notification.name,
        component<HTMLElement & CugPortal.Notification.Properties>(CugPortal.Notification.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        CugPortal.WidgetCarousel.name,
        component<HTMLElement & CugPortal.WidgetCarousel.Properties>(CugPortal.WidgetCarousel.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        CugPortal.CugSidebar.name,
        component<HTMLElement & CugPortal.CugSidebar.Attributes & CugPortal.CugSidebar.Properties>(
            CugPortal.CugSidebar.Component,
            {
                useShadowDOM: false,
                observedAttributes: CugPortal.CugSidebar.observedAttributes,
            },
        ),
    );

    customElements.define(
        CugPortal.Modal.name,
        component<HTMLElement & CugPortal.Modal.Properties>(CugPortal.Modal.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        CugPortal.UpdatePartner.name,
        component<HTMLElement & CugPortal.UpdatePartner.Properties>(CugPortal.UpdatePartner.Component, {
            useShadowDOM: false,
            observedAttributes: CugPortal.UpdatePartner.observedAttributes,
        }),
    );

    customElements.define(
        CugPortal.UsersGrid.name,
        component<HTMLElement & CugPortal.UsersGrid.Properties>(CugPortal.UsersGrid.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        CugPortal.PeruCompraUsersGrid.name,
        component<HTMLElement & CugPortal.PeruCompraUsersGrid.Properties>(CugPortal.PeruCompraUsersGrid.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        CugPortal.AddUser.name,
        component<HTMLElement & CugPortal.AddUser.Properties>(CugPortal.AddUser.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        CugPortal.EditUser.name,
        component<HTMLElement & CugPortal.EditUser.Properties>(CugPortal.EditUser.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Sidebar.SidebarBooking.name,
        component<HTMLElement & Sidebar.SidebarBooking.Attributes & Sidebar.SidebarBooking.Props>(
            Sidebar.SidebarBooking.Component,
            {
                useShadowDOM: false,
                observedAttributes: Sidebar.SidebarBooking.observedAttributes,
            },
        ),
    );

    customElements.define(
        Sidebar.SidebarCheckin.name,
        component<HTMLElement & Sidebar.SidebarCheckin.Attributes & Sidebar.SidebarCheckin.Props>(
            Sidebar.SidebarCheckin.Component,
            {
                useShadowDOM: false,
                observedAttributes: Sidebar.SidebarCheckin.observedAttributes,
            },
        ),
    );

    customElements.define(
        Layout.AppInit.name,
        component<HTMLElement & Layout.AppInit.Attributes & Layout.AppInit.Props>(Layout.AppInit.Component, {
            useShadowDOM: false,
            observedAttributes: Layout.AppInit.observedAttributes,
        }),
    );

    customElements.define(
        Layout.MultipleTabModal.name,
        component<HTMLElement>(Layout.MultipleTabModal.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Layout.Breadcrumbs.name,
        component<HTMLElement>(Layout.Breadcrumbs.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Layout.CultureSelector.name,
        component<HTMLElement>(Layout.CultureSelector.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Layout.AppHeader.name,
        component<HTMLElement & Layout.AppHeader.Attributes & Layout.AppHeader.Props>(Layout.AppHeader.Component, {
            useShadowDOM: false,
            observedAttributes: Layout.AppHeader.observedAttributes,
        }),
    );

    customElements.define(
        Sidebar.BreadcrumbsCheckin.name,
        component<HTMLElement & Sidebar.BreadcrumbsCheckin.Props>(Sidebar.BreadcrumbsCheckin.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Sidebar.BreakdownLine.name,
        component<HTMLElement & Sidebar.BreakdownLine.Props>(Sidebar.BreakdownLine.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Sidebar.BreadcrumbsBooking.name,
        component<HTMLElement>(Sidebar.BreadcrumbsBooking.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Sidebar.PromoCodeBox.name,
        component<HTMLElement & Sidebar.PromoCodeBox.Props>(Sidebar.PromoCodeBox.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Itinerary.Itinerary.name,
        component<HTMLElement & Itinerary.Itinerary.Attributes & Itinerary.Itinerary.Props>(
            Itinerary.Itinerary.Component,
            {
                useShadowDOM: false,
                observedAttributes: Itinerary.Itinerary.observedAttributes,
            },
        ),
    );

    customElements.define(
        Baggage.BaggagePerJourneyPerPaxPassenger.name,
        component<HTMLElement & Baggage.BaggagePerJourneyPerPaxPassenger.Props>(
            Baggage.BaggagePerJourneyPerPaxPassenger.Component,
            {
                useShadowDOM: false,
                observedAttributes: [],
            },
        ),
    );

    customElements.define(
        Flight.FlightFeeOption.name,
        component<HTMLElement & Flight.FlightFeeOption.Props>(Flight.FlightFeeOption.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Flight.DcInfoModal.name,
        component<HTMLElement & Flight.DcInfoModal.Props>(Flight.DcInfoModal.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Flight.FlightPage.name,
        component<HTMLElement & Flight.FlightPage.Attributes & Flight.FlightPage.Props>(Flight.FlightPage.Component, {
            useShadowDOM: false,
            observedAttributes: Flight.FlightPage.observedAttributes,
        }),
    );

    customElements.define(
        Flight.FlightItinerary.name,
        component<HTMLElement & Flight.FlightItinerary.Attributes & Flight.FlightItinerary.Props>(
            Flight.FlightItinerary.Component,
            {
                useShadowDOM: false,
                observedAttributes: Flight.FlightItinerary.observedAttributes,
            },
        ),
    );

    customElements.define(
        NoPeruCompraDc.name,
        component<HTMLElement>(NoPeruCompraDc.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Register.name,
        component<HTMLElement & Register.Attributes & Register.Properties>(Register.Component, {
            useShadowDOM: false,
            observedAttributes: Register.observedAttributes,
        }),
    );

    customElements.define(
        Extras.PetInCabinPassenger.name,
        component<HTMLElement & Extras.PetInCabinPassenger.Props>(Extras.PetInCabinPassenger.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Extras.FlexiFee.name,
        component<HTMLElement & Extras.FlexiFee.Props>(Extras.FlexiFee.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Spa.SpaContainer.name,
        component<HTMLElement & Spa.SpaContainer.Props & Spa.SpaContainer.Attributes>(Spa.SpaContainer.Component, {
            useShadowDOM: false,
            observedAttributes: Spa.SpaContainer.observedAttributes,
        }),
    );

    customElements.define(
        StaffBenefit.name,
        component<HTMLElement & StaffBenefit.Props & StaffBenefit.Attributes>(StaffBenefit.Component, {
            useShadowDOM: false,
            observedAttributes: StaffBenefit.observedAttributes,
        }),
    );

    customElements.define(
        StaffFamilyMembers.name,
        component<HTMLElement & StaffFamilyMembers.Props & StaffFamilyMembers.Attributes>(
            StaffFamilyMembers.Component,
            {
                useShadowDOM: false,
                observedAttributes: StaffFamilyMembers.observedAttributes,
            },
        ),
    );

    customElements.define(
        Giftcard.GiftcardActivationContainer.name,
        component<
            HTMLElement & Giftcard.GiftcardActivationContainer.Props & Giftcard.GiftcardActivationContainer.Attributes
        >(Giftcard.GiftcardActivationContainer.Component, {
            useShadowDOM: false,
            observedAttributes: Giftcard.GiftcardActivationContainer.observedAttributes,
        }),
    );

    customElements.define(
        Giftcard.GiftcardSelectPage.name,
        component<HTMLElement & Giftcard.GiftcardSelectPage.Props & Giftcard.GiftcardSelectPage.Attributes>(
            Giftcard.GiftcardSelectPage.Component,
            {
                useShadowDOM: false,
                observedAttributes: Giftcard.GiftcardSelectPage.observedAttributes,
            },
        ),
    );

    customElements.define(
        TransactionLogger.name,
        component<HTMLElement & TransactionLogger.Props & TransactionLogger.Attributes>(TransactionLogger.Component, {
            useShadowDOM: false,
            observedAttributes: TransactionLogger.observedAttributes,
        }),
    );

    customElements.define(
        SessionModal.name,
        component<HTMLElement & SessionModal.Props & SessionModal.Attributes>(SessionModal.Component, {
            useShadowDOM: false,
            observedAttributes: SessionModal.observedAttributes,
        }),
    );

    customElements.define(
        CugPortal.PeruCompraReservationDetailsModal.name,
        component<HTMLElement & CugPortal.PeruCompraReservationDetailsModal.Properties>(
            CugPortal.PeruCompraReservationDetailsModal.Component,
            {
                useShadowDOM: false,
                observedAttributes: [],
            },
        ),
    );

    customElements.define(
        SessionKeepAlive.name,
        component<HTMLElement & SessionKeepAlive.Props & SessionKeepAlive.Attributes>(SessionKeepAlive.Component, {
            useShadowDOM: false,
            observedAttributes: SessionKeepAlive.observedAttributes,
        }),
    );

    customElements.define(
        CugPortal.ChileCompraLoginInfoModal.name,
        component<HTMLElement>(CugPortal.ChileCompraLoginInfoModal.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        CugPortal.PeruCompraLoginInfoModal.name,
        component<HTMLElement>(CugPortal.PeruCompraLoginInfoModal.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Itinerary.StandaloneDCPage.name,
        component<HTMLElement & Itinerary.StandaloneDCPage.Props & Itinerary.StandaloneDCPage.Attributes>(
            Itinerary.StandaloneDCPage.Component,
            {
                useShadowDOM: false,
                observedAttributes: Itinerary.StandaloneDCPage.observedAttributes,
            },
        ),
    );

    customElements.define(
        Itinerary.FlightlessPage.name,
        component<HTMLElement & Itinerary.FlightlessPage.Props & Itinerary.FlightlessPage.Attributes>(
            Itinerary.FlightlessPage.Component,
            {
                useShadowDOM: false,
                observedAttributes: Itinerary.FlightlessPage.observedAttributes,
            },
        ),
    );

    customElements.define(
        Itinerary.OfflinePaymentPage.name,
        component<HTMLElement>(Itinerary.OfflinePaymentPage.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );

    customElements.define(
        Itinerary.GiftcardPage.name,
        component<HTMLElement & Itinerary.GiftcardPage.Props & Itinerary.GiftcardPage.Attributes>(
            Itinerary.GiftcardPage.Component,
            {
                useShadowDOM: false,
                observedAttributes: Itinerary.GiftcardPage.observedAttributes,
            },
        ),
    );

    customElements.define(
        ProfilePage.name,
        component<HTMLElement & ProfilePage.Props & ProfilePage.Attributes>(ProfilePage.Component, {
            useShadowDOM: false,
            observedAttributes: ProfilePage.observedAttributes,
        }),
    );

    customElements.define(
        CugPortal.SummaryPageWidget.name,
        component<HTMLElement & CugPortal.SummaryPageWidget.Properties>(CugPortal.SummaryPageWidget.Component, {
            useShadowDOM: false,
            observedAttributes: [],
        }),
    );
}
