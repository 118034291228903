import i18next from "i18next";
import { html } from "haunted";
import { showLoader } from "../../../shared/common";
import { useState } from "../../../shared/haunted/CustomHooks";
import { useModal } from "../../shared/useModal";

export interface Props {
    onYes: (journeyIndex: number) => void;
    onNo: (journeyIndex: number) => void;
}

export const useQuickCheckinModal = (props: Props) => {
    const [journeyIndex, setJourneyIndex] = useState<number>(0);

    const open = (journeyIndex: number) => {
        setJourneyIndex(journeyIndex);
        modal.open();
    };

    const handleYes = () => {
        modal.close();
        showLoader({});
        props.onYes(journeyIndex);
    };

    const handleNo = () => {
        modal.close();
        showLoader({});
        props.onNo(journeyIndex);
    };

    const headerTemplate = () => html`
        <i class="fas fa-exclamation-circle notification-icon"></i>
        ${i18next.t("CheckinModalTitle")}
    `;

    const htmlTemplate = () => html`
        <div class="modal-body checkin">
            <div class="row">
                <div class="col-xs-1">${i18next.t("CheckinModalText1")}</div>
            </div>

            <div class="checkin-modal-buttons">
                <a @click=${handleYes} class="rounded-primary-btn">${i18next.t("Yes")}</a>
                <a @click=${handleNo} class="checkin-modal-no"> ${i18next.t("V2-NoThanks")} </a>
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: true },
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { classNames: "checkin", template: headerTemplate },
    });

    return { open, htmlTemplate: modal.htmlTemplate };
};
