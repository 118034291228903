import { html } from "lit-html";
import i18next from "i18next";
import {
    USA_DOLLAR_CODE,
    BRASILIAN_CULTURE_CODE,
    PERU_TRANSFER_FOP_CODE,
    CURRENT_DISPLAYED_PAYMENT_FORM_ID,
} from "../../shared/commonConstants";
import { useCurrencySelector } from "./useCurrencySelector";
import { useEffect, useMemo, useState } from "../../shared/haunted/CustomHooks";
import { CardIssuerType } from "../../component-models/PaymentDescriptors";
import { useCard } from "./useCard";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import {
    peruBankTransferLogosTemplate,
    peruOfflineBankLogosTemplate,
} from "../../common-templates/PaymentCommonTemplates";
import { useWorldpayMcp } from "./useWorldpayMcp";
import { useAppContext } from "../../managers/useAppContext";
import { useWompiPse } from "./useWompiPse";
import { useBookingContext } from "../../managers/useBookingContext";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    isValidated: boolean;
    model: PaymentPageViewModel;
}

export const useXmlPaymentMethodForm = (props: Props) => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();

    const { checkPromoCodeAvailable } = useAjax();

    const [_, setDisplayedTotal] = useReduxState("payment.displayedTotal");
    const [cardData] = useReduxState("payment.cardData");
    const [currency] = useReduxState("booking.currency");
    const [selectedCurrency, setSelectedCurrency] = useReduxState("payment.selectedCurrency");
    const [selectedPaymentMethod] = useReduxState("payment.paymentMethod");

    const [unformattedTotalIncreasedWithInstallments, setUnformattedTotalIncreasedWithInstallments] =
        useState<number>(undefined);

    const isMcpOn = useMemo(
        () =>
            appContext.Culture &&
            (!props.model?.MethodsViewModel.WorldpayMcpSettings?.CultureBlackList?.length ||
                !props.model?.MethodsViewModel.WorldpayMcpSettings.CultureBlackList.map((c) =>
                    c.toLowerCase(),
                ).includes(appContext.Culture)),
        [appContext.Culture, props.model],
    );

    const worldpayMcp = useWorldpayMcp({ isMcpOn });

    const card = useCard({
        isValidated: props.isValidated,
        model: props.model,
        handleInstallmentNumberChange: (newTotal: number) => setUnformattedTotalIncreasedWithInstallments(newTotal),
    });

    const showIncreasedTotalWithXmlInstallments = () =>
        cardData?.FieldRequirements?.InstallmentType === "XML" &&
        cardData?.SelectedInstallmentsNumber > 1 &&
        Boolean(props.model.MethodsViewModel.InstallmentsXml);

    const showIncreasedTotalWithMercadoPagoAggregatorInstallments = () =>
        selectedPaymentMethod?.MercadoPagoApiNodeType === "Aggregator" &&
        cardData?.FieldRequirements?.InstallmentType === "MercadoPago" &&
        cardData?.SelectedInstallmentsNumber > 1 &&
        props.model.MethodsViewModel.PaymentSettings.MercadoPagoAggregatorInstallments?.length > 0;

    const currencySelector = useCurrencySelector({
        isMcpOn,
        model: props.model,
        totalIncreasedWithInstallments:
            showIncreasedTotalWithXmlInstallments() || showIncreasedTotalWithMercadoPagoAggregatorInstallments()
                ? unformattedTotalIncreasedWithInstallments
                : undefined,
        worldpayMcpCurrencyList: isMcpOn ? worldpayMcp.currencyList : undefined,
        worldpayMcpPrice: isMcpOn ? worldpayMcp.priceInfo : undefined,
    });

    const wompiPseFinancialInstitutions = useWompiPse();

    const isPeruBankTransfer = () => selectedPaymentMethod?.PaymentMethodCode === PERU_TRANSFER_FOP_CODE;

    const showBrasilUsdMessage = () =>
        selectedCurrency.toLowerCase() === USA_DOLLAR_CODE.toLowerCase() &&
        appContext.Culture === BRASILIAN_CULTURE_CODE.toLowerCase();

    const handleCardChange = async () => {
        if (!needToCheckPromoCodeOnCardChange()) return;

        const code = cardTypeCode(cardData.CardType);
        await checkPromoCodeAvailable(code);
    };

    const handlePaymentMethodChange = async () => {
        // DEVNOTE This displays in the bottom left corner the currently selected FOP code
        const testElem = document.getElementById(CURRENT_DISPLAYED_PAYMENT_FORM_ID);

        if (testElem) {
            testElem.textContent = `FOP: ${selectedPaymentMethod?.PaymentMethodCode || "n/a"}`;
        }

        if (!selectedPaymentMethod) {
            return;
        }

        if (needToCheckPromoCodeOnPaymentMethodChange()) {
            await checkPromoCodeAvailable(selectedPaymentMethod.PaymentMethodCode);
        }
    };

    const needToCheckPromoCodeOnCardChange = () =>
        cardData?.CardType &&
        cardData.CardValidationStatus === "valid" &&
        bookingContext.promoCode &&
        selectedPaymentMethod?.SubmitCardCodeInsteadOfPaymentMethodCode;

    const needToCheckPromoCodeOnPaymentMethodChange = () =>
        bookingContext.promoCode &&
        selectedPaymentMethod?.PaymentMethodCode &&
        !selectedPaymentMethod.SubmitCardCodeInsteadOfPaymentMethodCode;

    const cardTypeCode = (type: CardIssuerType) => allowedCards().find((card) => card.Type === type)?.Code;

    const allowedCards = () => selectedPaymentMethod?.AllowedCards || [];

    const handleTotalChange = () => {
        if (unformattedTotalIncreasedWithInstallments > 0) {
            setDisplayedTotal(unformattedTotalIncreasedWithInstallments);
        }

        if (isMcpOn && worldpayMcp.priceInfo?.UnformattedPrice) {
            setDisplayedTotal(worldpayMcp.priceInfo.UnformattedPrice);
        }

        setDisplayedTotal(currencySelector.displayedTotal);
    };

    useEffect(handlePaymentMethodChange, [selectedPaymentMethod?.PaymentMethodCode]);

    useEffect(handleCardChange, [cardData, selectedPaymentMethod?.SubmitCardCodeInsteadOfPaymentMethodCode]);

    useEffect(() => setSelectedCurrency(currency), [currency]);

    useEffect(handleTotalChange, [
        currencySelector.displayedTotal,
        unformattedTotalIncreasedWithInstallments,
        worldpayMcp.priceInfo?.UnformattedPrice,
        isMcpOn,
    ]);

    // TEMPLATES

    const brasilUsdTemplate = () =>
        showBrasilUsdMessage()
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="brasil-usd-message">${i18next.t("BrasilUsdInfo")}</div>
                      </div>
                  </div>
              `
            : "";

    const htmlTemplate = () =>
        selectedPaymentMethod && cardData?.FieldRequirements
            ? html`
                      ${currencySelector.htmlTemplate()} ${card.htmlTemplate()}
                      ${brasilUsdTemplate()}
                      ${peruOfflineBankLogosTemplate(selectedPaymentMethod?.IsOffline, appContext.Culture)}
                      ${peruBankTransferLogosTemplate(isPeruBankTransfer(), appContext.Culture)}
                      ${wompiPseFinancialInstitutions.htmlTemplate()}
                  </form>
              `
            : "";

    return {
        card,
        htmlTemplate,
        validate: card.validate,
    };
};
