import { useCallback } from "haunted";
import { useMemo, useState } from "../../../../shared/haunted/CustomHooks";
import { BagType } from "../useBaggagePage";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { BaggageSectionJourney } from "../../../../component-models/baggage/BaggageSectionJourney";

interface StaffBaggagePerJourneyPerPax {
    journeyIndex: number;
    passengerNumber: number;
    usedBaggageType: "none" | "cabin" | "checked";
}

export interface StaffBaggage {
    state: StaffBaggagePerJourneyPerPax[];
    canGetFirstBaggageStaffDiscount: (data: { bagType: BagType; journeyIndex: number; paxIndex: number }) => boolean;
    update: (data: {
        bagType: BagType;
        journeyIndices: number[];
        paxIndices: number[];
        type: "add" | "remove" | "reset";
    }) => void;
}

export interface Props {
    cabinBaggageJourneys: BaggageSectionJourney[];
    checkedBaggageJourneys: BaggageSectionJourney[];
}

export const useStaffBaggage = (props: Props): StaffBaggage => {
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const [userContext] = useReduxState("userContext");

    const initialStaffBaggage = useMemo(
        () =>
            props.cabinBaggageJourneys.flatMap((cabinJourney): StaffBaggagePerJourneyPerPax[] =>
                cabinJourney.passengers.flatMap((cabinPassenger): StaffBaggagePerJourneyPerPax => {
                    const checkedBaggageQuantity =
                        props.checkedBaggageJourneys
                            .find((checkedJourney) => checkedJourney.index === cabinJourney.index)
                            ?.passengers.find((checkedPassenger) => checkedPassenger.index === cabinPassenger.index)
                            ?.quantity ?? 0;

                    const usedBaggageType =
                        cabinPassenger?.quantity > 0 ? "cabin" : checkedBaggageQuantity > 0 ? "checked" : "none";

                    return { journeyIndex: cabinJourney.index, passengerNumber: cabinPassenger.index, usedBaggageType };
                }),
            ),
        [],
    );

    const [state, setState] = useState<StaffBaggagePerJourneyPerPax[]>(initialStaffBaggage);

    const updateCabinItem = (
        oldItem: StaffBaggagePerJourneyPerPax,
        type: "add" | "remove" | "reset",
    ): StaffBaggagePerJourneyPerPax => {
        const checkedBaggageQuantity = props.checkedBaggageJourneys
            .find((journey) => journey.index === oldItem.journeyIndex)
            .passengers.find((pax) => pax.index === oldItem.passengerNumber).quantity;

        switch (type) {
            case "add":
                return {
                    ...oldItem,
                    usedBaggageType: oldItem.usedBaggageType === "none" ? "cabin" : oldItem.usedBaggageType,
                };
            case "remove":
            case "reset":
                return {
                    ...oldItem,
                    usedBaggageType: checkedBaggageQuantity > 0 ? "checked" : "none",
                };
        }
    };

    const updateCheckedItem = (
        oldItem: StaffBaggagePerJourneyPerPax,
        type: "add" | "remove" | "reset",
    ): StaffBaggagePerJourneyPerPax => {
        const cabinBaggageQuantity = props.cabinBaggageJourneys
            .find((journey) => journey.index === oldItem.journeyIndex)
            .passengers.find((pax) => pax.index === oldItem.passengerNumber).quantity;

        const checkedBaggageQuantity = props.checkedBaggageJourneys
            .find((journey) => journey.index === oldItem.journeyIndex)
            .passengers.find((pax) => pax.index === oldItem.passengerNumber).quantity;

        switch (type) {
            case "add":
                return {
                    ...oldItem,
                    usedBaggageType: oldItem.usedBaggageType === "none" ? "checked" : oldItem.usedBaggageType,
                };
            case "remove":
                return {
                    ...oldItem,
                    usedBaggageType:
                        checkedBaggageQuantity > 1
                            ? oldItem.usedBaggageType
                            : cabinBaggageQuantity > 0
                              ? "cabin"
                              : "none",
                };
            case "reset":
                return { ...oldItem, usedBaggageType: cabinBaggageQuantity > 0 ? "cabin" : "none" };
        }
    };

    const update = (data: {
        bagType: BagType;
        journeyIndices: number[];
        paxIndices: number[];
        type: "add" | "remove" | "reset";
    }) => {
        setState(
            state.map((oldItem): StaffBaggagePerJourneyPerPax => {
                const toUpdate =
                    data.journeyIndices.includes(oldItem.journeyIndex) &&
                    data.paxIndices.includes(oldItem.passengerNumber);

                if (!toUpdate) return oldItem;

                switch (data.bagType) {
                    case "cabinBaggage":
                        return updateCabinItem(oldItem, data.type);
                    case "checkedBaggage":
                        return updateCheckedItem(oldItem, data.type);
                    default:
                        return oldItem;
                }
            }),
        );
    };

    const canGetFirstBaggageStaffDiscount = useCallback(
        (data: { bagType: BagType; journeyIndex: number; paxIndex: number }) => {
            if (!userContext.isStaff) return false;
            if (!flowContext.isBookingFlow && !bookingContext.canGetStaffDiscount) return false;
            if (data.bagType === "oversizedBaggage") return false;

            const usedStaffBaggage = state.find(
                (usb) => usb.journeyIndex === data.journeyIndex && usb.passengerNumber === data.paxIndex,
            );

            return usedStaffBaggage?.usedBaggageType === "none";
        },
        [userContext.isStaff, flowContext.isBookingFlow, bookingContext.canGetStaffDiscount, state],
    );

    return {
        state,
        canGetFirstBaggageStaffDiscount,
        update,
    };
};
