import { useRef } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import { ref } from "../../../../directives/ref";
import { CLASS_NAMES } from "../../../../shared/classNames";
import { getPassengerName, showLoader } from "../../../../shared/common";
import DomCrawlingHelper from "../../../../shared/DomCrawlingHelper";
import { HauntedFunc } from "../../../../shared/haunted/HooksHelpers";
import { LOADER_CLASS_NAMES } from "../../../../shared/LoaderClassNames";
import { PriorityBoardingJourneyPassengerModel } from "../../../../component-models/extras/priority-boarding/PriorityBoardingJourneyPassengerModel";
import { PriorityBoardingJourneyModel } from "../../../../component-models/extras/priority-boarding/PriorityBoardingJourneyModel";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useBookingManager } from "../../../../managers/useBookingManager";
import { useExtrasTealiumManager } from "../../../../managers/Tealium/useExtrasTealiumManager";
import { ApiExtrasModel } from "../../../../component-models/extras/ApiExtrasModel";
import BookingData from "../../../../shared/BookingData";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { OUTBOUND } from "../../../../shared/commonConstants";
import classNames from "classnames";

export const name = "ac-priority-boarding-passenger";

export interface Props {
    journey: PriorityBoardingJourneyModel;
    passengerOption: PriorityBoardingJourneyPassengerModel;
    formattedBancoEstadoSavedAmountPriorityBoarding: string;
    setExtrasModel: (model: ApiExtrasModel) => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        journey: host.journey,
        passengerOption: host.passengerOption,
        formattedBancoEstadoSavedAmountPriorityBoarding: host.formattedBancoEstadoSavedAmountPriorityBoarding,
        setExtrasModel: host.setExtrasModel,
    };

    const bookingContext = useBookingContext();
    const [userContext] = useReduxState("userContext");

    const tealiumManager = useExtrasTealiumManager();
    const bookingManager = useBookingManager();

    const [passengerNames] = useReduxState("booking.passengerNames");

    const root = useRef<HTMLDivElement>(undefined);

    const isFreeForBancoEstado12 = () =>
        [1, 2].includes(userContext.bancoEstado.category) && props.passengerOption.Amount === 0;

    const notFree = () =>
        ![1, 2, 5, 6].includes(userContext.bancoEstado.category) || props.passengerOption.Amount !== 0;

    const displayAmount = () => notFree() && !props.journey.IsInBundle;

    const isPassengerDisabled = () => props.journey.JourneyIndex === OUTBOUND && bookingContext.isCheckinClosedOutbound;

    const tealiumLogSelection = async (type: "add" | "remove") => {
        await tealiumManager.logBinaryExtraSelected(
            "priorityBoarding",
            props.passengerOption.Type === "ADT" ? "A" : "C",
            props.passengerOption.PassengerNumber,
            props.journey.JourneyIndex,
            type,
            props.passengerOption.Amount,
        );
    };

    const handlePriorityBoardingButtonClick = async () => {
        if (!props.passengerOption.SsrAddKey) {
            return;
        }

        const parent = DomCrawlingHelper.findParentByClass(root.current, CLASS_NAMES.priorityBoarding);
        const loader = showLoader({
            name: LOADER_CLASS_NAMES.ExtrasPriorityBoarding,
            container: parent.parentElement,
            noPlane: true,
        });

        const container = DomCrawlingHelper.getElemByClass(parent, CLASS_NAMES.errorContainer);
        const body = {
            "selectedJourneySsrs[0]": !props.passengerOption.IsSold
                ? props.passengerOption.SsrAddKey
                : props.passengerOption.SsrDeleteKey,
        };

        const response = await bookingManager.postExtrasPageSsrUpdate<{
            BookingSummary: BookingData;
            ExtrasModel: ApiExtrasModel;
        }>(body, container, loader);

        props.setExtrasModel(response.ExtrasModel);

        await tealiumLogSelection(!props.passengerOption.IsSold ? "add" : "remove");
    };

    const labelTemplate = () => {
        const tempClassMap = classNames("col-md-2-3", {
            "col-xs-1-2": isFreeForBancoEstado12() || [5, 6].includes(userContext.bancoEstado.category),
            "col-xs-2-3": !isFreeForBancoEstado12() && ![5, 6].includes(userContext.bancoEstado.category),
        });

        const label = isFreeForBancoEstado12()
            ? props.formattedBancoEstadoSavedAmountPriorityBoarding
            : displayAmount()
              ? props.passengerOption.FormattedAmount
              : "";

        return html`
            <div
                class=${tempClassMap}
                data-test-value=${props.passengerOption.Amount}
                data-test-id=${getTestId(T.EXTRAS_PRIORITY_BOARDING.BUTTON_CAPTION, {
                    p: props.passengerOption.PassengerNumber,
                    j: props.journey.JourneyIndex,
                })}
            >
                <div class="extras-binary-name">
                    ${getPassengerName(passengerNames, props.passengerOption.PassengerNumber)} ${label}
                </div>
            </div>
        `;
    };

    const nonBancoEstadoButtonTemplate = () => {
        const tempClassMap = classNames("btn-boarding select-dependent-text", {
            disabled: props.passengerOption.IsDisabled,
            selected: props.passengerOption.IsSold,
        });

        return ![5, 6].includes(userContext.bancoEstado.category) && !isFreeForBancoEstado12()
            ? html`
                  <div
                      class=${tempClassMap}
                      data-test-id=${getTestId(T.EXTRAS_PRIORITY_BOARDING.BUTTON, {
                          p: props.passengerOption.PassengerNumber,
                          j: props.journey.JourneyIndex,
                      })}
                      @click=${handlePriorityBoardingButtonClick}
                  >
                      <span>${i18next.t("V2-Add")}</span>
                      <span>${i18next.t("V2-Remove")}</span>
                  </div>
              `
            : "";
    };

    const bancoEstadoButtonTemplate = () =>
        isFreeForBancoEstado12() || [5, 6].includes(userContext.bancoEstado.category)
            ? html`
                  <div
                      class=${classNames("btn-boarding selected disabled", {
                          "banco-estado": isFreeForBancoEstado12(),
                      })}
                      data-test-id=${getTestId(T.EXTRAS_PRIORITY_BOARDING.BANCO_ESTADO_INCLUDED_LABEL, {
                          p: props.passengerOption.PassengerNumber,
                          j: props.journey.JourneyIndex,
                      })}
                  >
                      <span>
                          ${[1, 2].includes(userContext.bancoEstado.category)
                              ? i18next.t("BE-IncludedLabel")
                              : i18next.t("V2-Included")}
                      </span>
                  </div>
              `
            : "";

    const buttonTemplate = () => {
        const tempClassMap = classNames("col-md-1-3", {
            "col-xs-1-2": isFreeForBancoEstado12() || [5, 6].includes(userContext.bancoEstado.category),
            "col-xs-1-3": !isFreeForBancoEstado12() && ![5, 6].includes(userContext.bancoEstado.category),
        });

        return html`
            <div class=${tempClassMap}>${bancoEstadoButtonTemplate()} ${nonBancoEstadoButtonTemplate()}</div>
        `;
    };

    return html`
        <div
            class="inner-border-box"
            ref=${ref(root)}
            data-test-id=${getTestId(T.EXTRAS_PRIORITY_BOARDING.PASSENGER_OPTION, {
                p: props.passengerOption.PassengerNumber,
                j: props.journey.JourneyIndex,
            })}
        >
            <div class=${isPassengerDisabled() ? "is-past" : ""}>
                <div class="row">${labelTemplate()} ${buttonTemplate()}</div>
            </div>
        </div>
    `;
};
