import { TemplateResult, html } from "lit-html";
import { ApiJourneyServiceInfo, ApiPassengerServiceInfo, ApiReview } from "../../../component-models/review/ApiReview";
import i18next from "i18next";
import { reviewTableTemplate } from "./reviewTableTemplate";
import { commonReviewTemplates } from "./commonReviewTemplates";
import { unsafeHTML } from "lit-html/directives/unsafe-html";

export const extrasReview = (model: ApiReview) => {
    const { desktopReviewTable, mobileReviewTable } = reviewTableTemplate();
    const { paxLabelTemplate, paxNameTemplate, previousTemplate, currentTemplate, routeTemplate } =
        commonReviewTemplates();

    const updateSsrDisplayTexts = (paxChange: ApiPassengerServiceInfo) => {
        const updatedPaxChange = { ...paxChange };

        updatedPaxChange.PreviousSsrs = updatedPaxChange.PreviousSsrs.map((ssr) =>
            ssr.SsrCode === "ACAA"
                ? {
                      ...ssr,
                      DisplayText: html`
                          ${unsafeHTML(
                              i18next.t("Millas AAdvantage {{-reg}}", {
                                  reg: '<div class="relative font-body top-[-1px] inline">&reg;</div>',
                              }),
                          )}
                      `,
                  }
                : ssr,
        );

        updatedPaxChange.ActualSsrs = updatedPaxChange.ActualSsrs.map((ssr) =>
            ssr.SsrCode === "ACAA"
                ? {
                      ...ssr,
                      DisplayText: html`
                          ${unsafeHTML(
                              i18next.t("Millas AAdvantage {{-reg}}", {
                                  reg: '<div class="relative font-body top-[-1px] inline">&reg;</div>',
                              }),
                          )}
                      `,
                  }
                : ssr,
        );

        return updatedPaxChange;
    };

    const getTable = (paxChange: ApiPassengerServiceInfo) =>
        new Map<string, (string | TemplateResult)[]>([
            [paxLabelTemplate(paxChange), [paxNameTemplate(paxChange)]],
            [i18next.t("ReviewChanges-PreviousServices"), [previousTemplate(paxChange, i18next.t("Review-NoExtras"))]],
            [i18next.t("ReviewChanges-ActualServices"), [currentTemplate(paxChange, i18next.t("Review-NoExtras"))]],
        ]);

    const desktopTemplate = (paxChange: ApiPassengerServiceInfo) => html`${desktopReviewTable(getTable(paxChange))}`;

    const mobileTemplate = (paxChange: ApiPassengerServiceInfo) => html`${mobileReviewTable(getTable(paxChange))}`;

    const journeyTemplate = (journey: ApiJourneyServiceInfo) => html`
        <div class="inner-deep-box">
            <div class="flight-container hidden-xs">
                <div class="flight-container-title flex items-center gap-x-2">
                    ${i18next.t("ReviewChanges-TitleSpecialService")}
                    <span class="station-names flex items-center gap-x-2">${routeTemplate(journey.Journey)}</span>
                </div>
                ${journey.PassengerChanges.map((paxChange) => desktopTemplate(updateSsrDisplayTexts(paxChange)))}
            </div>

            <div class="flight-container visible-xs">
                <div class="flight-container-title flex items-center gap-x-2">
                    ${i18next.t("ReviewChanges-TitleSpecialService")}
                    <span class="station-names flex items-center gap-x-2">${routeTemplate(journey.Journey)}</span>
                </div>
                ${journey.PassengerChanges.map((paxChange) => mobileTemplate(updateSsrDisplayTexts(paxChange)))}
            </div>
        </div>
    `;

    const htmlTemplate = () =>
        model.ServiceChanges?.length
            ? html`
                  <div>
                      <div class="inner-deep-box-header">${i18next.t("ChangesInExtras")}</div>
                  </div>
                  ${model.ServiceChanges.map(journeyTemplate)}
              `
            : "";

    return { htmlTemplate };
};
