import i18next from "i18next";
import { html } from "lit-html";
import { finishedSelection } from "../../../../component-helpers/BaggageHelper";
import { BaggagePageState } from "../../../../component-models/baggage/BaggagePageState";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { baggageErrorClass, BagType } from "../useBaggagePage";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";

export interface Props {
    baggageSection: BaggageSection;
    bagType: BagType;
    pageState: BaggagePageState;
}

export const perBookingValidationError = (props: Props) => {
    const isValid = useMemo(
        () =>
            props.baggageSection.journeys.every((journey) =>
                journey.passengers.every((passenger) => props.baggageSection.isPassengerValid({ journey, passenger })),
            ),
        [props.baggageSection.journeys],
    );

    const isJourneyValid = useMemo(
        () =>
            props.bagType === "oversizedBaggage" ? finishedSelection(props.baggageSection.sectionUi.uiState) : isValid,
        [props.bagType, props.baggageSection.sectionUi.uiState, isValid],
    );

    const showError =
        props.baggageSection.sectionUi.uiState.perJourneyPerPaxState !== "open" &&
        props.pageState.lastValidationTimestamp &&
        !isJourneyValid;

    const htmlTemplate = (isMobile: boolean) =>
        showError
            ? html`
                  <div
                      class=${baggageErrorClass}
                      data-test-id=${getTestId(T.BAGGAGE.ERROR_MESSAGE, {
                          c: props.bagType,
                          m: isMobile,
                      })}
                  >
                      ${i18next.t("Antes de continuar, debes seleccionar tu equipaje")}
                  </div>
              `
            : "";

    return { htmlTemplate };
};
