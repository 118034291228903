import { FlightOptionModel } from "../../component-models/flight/FlightOptionModel";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import BookingFlowHandler from "../../shared/BookingFlowHandler";
import { JetSmartEvent } from "../../shared/eventbus/JetSmartEvent";
import { UserContext } from "../../component-models/app/UserContext";
import BookingData from "../../shared/BookingData";
import { FlightPageViewModel } from "../../component-models/flight/FlightPageViewModel";
import { FlightPageContext } from "../../component-models/flight/contexts/FlightPageContext";
import { REDEMPTION_MILES_CODE } from "../../shared/commonConstants";

export interface WarningStatus {
    programNumber: string;
    longTermWarningShown: number;
    shortTermWarningShown: number;
}

export class FlightHelper {
    public static smartPrice(data: {
        context: FlightPageContext;
        flightOption: FlightOptionModel;
        currency: string;
        culture: string;
        formatNumber: (data: {
            amount: number;
            leadingSign?: boolean;
            omitGrouping?: boolean;
            currency?: string;
        }) => string;
    }): string {
        return FlightHelper.getFormattedPrice({
            amount: data.context.bundleState?.bundleOffers
                ?.find((b) => b.SellKey === data.flightOption.SellKey)
                ?.NormalOffers.find((o) => o.BundleType === "None")?.UnFormattedPrice,
            currency: data.currency,
            culture: data.culture,
            formatNumber: data.formatNumber,
        });
    }

    public static clubPrice(data: {
        context: FlightPageContext;
        culture: string;
        currency: string;
        flightOption: FlightOptionModel;
        userData: UserContext;
        formatNumber: (data: {
            amount: number;
            leadingSign?: boolean;
            omitGrouping?: boolean;
            currency?: string;
        }) => string;
    }): string {
        const amount = data.context.bundleState?.bundleOffers
            ?.find((b) => b.SellKey === data.flightOption.SellKey)
            ?.DCOffers.find((o) => o.BundleType === "None")?.UnFormattedPrice;

        return amount
            ? FlightHelper.getFormattedPrice({
                  amount,
                  currency: data.currency,
                  culture: data.culture,
                  formatNumber: data.formatNumber,
              })
            : "";
    }

    public static redemptionPrice(data: {
        context: FlightPageContext;
        flightOption: FlightOptionModel;
        currency: string;
        culture: string;
        formatNumber: (data: {
            amount: number;
            leadingSign?: boolean;
            omitGrouping?: boolean;
            currency?: string;
        }) => string;
    }): string {
        return FlightHelper.getFormattedPrice({
            amount: data.context.bundleState?.bundleOffers
                ?.find((b) => b.SellKey === data.flightOption.SellKey)
                ?.RedemptionOffers.find((o) => o.BundleType === "None")?.UnFormattedPrice,
            currency: data.currency,
            culture: data.culture,
            formatNumber: data.formatNumber,
        });
    }

    public static async raisePromoCodeAddedEvent(bookingData: BookingData): Promise<void> {
        window.eventBus.raiseEvent({
            name: JetSmartEvent.PromoCodeAdded,
            params: {
                current_step: BookingFlowHandler.getCurrentStep(),
                previous_step: BookingFlowHandler.getPreviousStep(),
                id: "promocode",
                event: "add",
                status: "success",
                data: {
                    code: bookingData.PromotionCode,
                    rules: {
                        currency: bookingData.PromotionDiscountCurrency,
                        value: bookingData.PromotionDiscount,
                    },
                    errors: [],
                },
            },
        });
    }

    private static getFormattedPrice(data: {
        amount: number;
        currency: string;
        culture: string;
        formatNumber: (data: {
            amount: number;
            leadingSign?: boolean;
            omitGrouping?: boolean;
            currency?: string;
        }) => string;
    }): string {
        if (data.amount === undefined) return "";

        return data.formatNumber({
            amount: data.amount,
            leadingSign: data.currency !== REDEMPTION_MILES_CODE,
            currency: data.currency,
        });
    }
}

export const flightOnlyInCountries = (flight: FlightPageViewModel, countries: string[]) =>
    !flight.FlightDataViewModel.Journeys.some((journey) =>
        journey.FlightDetails.some((flightDetail) =>
            flightDetail.Segments.some((segment) =>
                segment.Legs.some(
                    (leg) => !countries.includes(leg.OriginCountry) || !countries.includes(leg.DestinationCountry),
                ),
            ),
        ),
    );
