import { PaymentIntentFlowHelper } from "../../component-helpers/payment/PaymentIntentFlowHelper";
import { PaymentIntentFlowType } from "../../component-models/payment/PaymentIntentFlowType";
import { usePaymentTealiumManager } from "../../managers/Tealium/usePaymentTealiumManager";
import { useBookingContext } from "../../managers/useBookingContext";
import { raiseBookingFlowContinueEvent } from "../../shared/eventbus/raiseBookingFlowContinueEvent";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useBookingCommitter } from "./useBookingCommitter";
import { PaymentFlowFinishCallbackType } from "../../component-models/payment/PaymentFlowFinishCallbackType";
import { PaymentIntentFlow } from "../../component-models/payment/PaymentIntentFlow";
import { ROUTES } from "../../shared/apiRoutes";
import { AGENCY_PAYMENT_FOP_CODE, CREDIT_SHELL_PAYMENT_FOP_CODE } from "../../shared/commonConstants";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { useFlowContext } from "../../managers/useFlowContext";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { ApiPaymentPageViewModel } from "../../component-models/payment/ApiPaymentPageViewModel";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { PaymentMode } from "../../component-models/payment/PaymentMode";
import { Payer } from "./usePayer";
import { ApiVoucherResult } from "../../component-models/payment/VoucherResult";
import BookingData from "../../shared/BookingData";
import { paymentHelper } from "../../component-helpers/payment/PaymentHelper";

export interface Props {
    antiForgeryToken: string;
    antifraudIdNumber: string;
    antifraudIdType: "DNI" | "N" | "Passport";
    isInvoiceFormShown: boolean;
    isTermsAcceptanceValid: boolean;
    model: ApiPaymentPageViewModel;
    payer: Payer;
    paymentMode: PaymentMode;
    selectedAgencyPaymentAmount: string;
    vm: PaymentPageViewModel;
    setPaymentMode: (paymentMode: PaymentMode) => void;
    openCreditShellModal: () => void;
    openFareClassFullModal: () => void;
    openGiftcardSessionErrorModal: () => void;
    openPromoCodeBlockModal: () => void;
    removeErrorMessages: () => void;
    scrollToFirstError: () => void;
    scrollToSubmitButton: () => void;
    setAntifraudRestrictionOn: () => void;
    setContactFormValidated: () => void;
    setForcePreventTermsValidation: (forcePreventTermsValidation: boolean) => void;
    setPageValidated: () => void;
    startLoad: () => JsLoader;
    stopLoad: (loader: JsLoader) => void;
    submitInvoiceForm: () => Promise<{ BookingSummary: BookingData }>;
    submitPaymentContactForm: () => Promise<boolean>;
    validateInvoiceForm: () => Promise<boolean>;
    validateMethodsContainer: () => Promise<boolean>;
    validatePaymentContactForm: () => Promise<boolean>;
}

export const usePaymentProcessor = (props: Props) => {
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const tealiumManager = usePaymentTealiumManager();

    const { ajaxJsonRequest, checkPromoCodeAvailable } = useAjax();
    const { canOnlyHold, showBilling } = paymentHelper();
    const { commitBooking } = useBookingCommitter({
        antiForgeryToken: props.antiForgeryToken,
        antifraudIdNumber: props.antifraudIdNumber,
        antifraudIdType: props.antifraudIdType,
        model: props.vm,
        selectedAgencyPaymentAmount: props.selectedAgencyPaymentAmount,
        openFareClassFullModal: props.openFareClassFullModal,
    });

    const [cardData] = useReduxState("payment.cardData");
    const [selectedMethod] = useReduxState("payment.paymentMethod");
    const [userContext] = useReduxState("userContext");

    const [errorMessage, setErrorMessage] = useState<string>("");
    const [voucherResult] = useReduxState("payment.voucherResult");

    const validateAllForms = async (flow: PaymentIntentFlow): Promise<boolean> => {
        const loader = props.startLoad();
        if (flow.ValidateForms !== "none") {
            props.setPageValidated();
            setErrorMessage("");
        }

        let isValid = await validateContactAndInvoiceForms(flow);
        isValid = validateTermsAcceptance(flow) && isValid;
        isValid = (await validateXmlPaymentMethod(flow)) && isValid;

        props.stopLoad(loader);
        return isValid;
    };

    const shouldAbortPaymentIntentFlowAsInvalid = (
        flow: PaymentIntentFlow,
        isValid: boolean,
        canUsePromoCode: boolean,
    ) => {
        let shouldAbortFlow = false;

        switch (flow.StopIfInvalid) {
            case "always":
                shouldAbortFlow = !canUsePromoCode || !isValid;
                break;
            case "conditional":
                shouldAbortFlow =
                    !canUsePromoCode ||
                    (!props.vm.CreditShellViewModel.Cat12347CreditShellCoversBalanceDue && !isValid);
                break;
        }

        if (shouldAbortFlow) {
            props.scrollToFirstError();
            raiseBookingFlowContinueEvent(false);
        }

        return shouldAbortFlow;
    };

    const submitContactAndInvoiceForm = async (flow: PaymentIntentFlow) => {
        if (flow.SubmitForms === "none") return true;

        const errors = new Array<string>();

        const contactResult = await props.submitPaymentContactForm();

        if (!contactResult) errors.push("Contact form server error.");

        // TODO Write the cug funding flow properly
        if (flowContext.isCugFundingFlow) {
            setErrorMessage(!contactResult ? "Contact form server error." : "");
            return contactResult;
        }

        switch (flow.SubmitForms) {
            case "contactOnly":
                setErrorMessage(!contactResult ? "Contact form server error." : "");
                return contactResult;
            case "contactAndInvoice":
                const submitInvoice =
                    showBilling(props.vm, flowContext.isFlightlessPurchaseFlow) &&
                    (props.isInvoiceFormShown ||
                        userContext.chileCompra.role !== "none" ||
                        bookingContext.isChileCompraBooking ||
                        userContext.peruCompra.role !== "none" ||
                        bookingContext.isPeruCompraBooking);

                if (!submitInvoice) {
                    setErrorMessage(!contactResult ? "Contact form server error." : "");
                    return contactResult;
                }

                const invoiceResult = await props.submitInvoiceForm();

                if (!invoiceResult) errors.push("Invoice form server error.");

                setErrorMessage(errors.join(" "));
                return invoiceResult && contactResult;
        }
    };

    const commitBookingWithLogAndAntifraud = (flowType: PaymentIntentFlowType, loader: JsLoader) => {
        window.setTimeout(async () => {
            const commitResult = await commitBooking({ type: flowType });

            if (commitResult !== "success") props.stopLoad(loader);

            if (commitResult === "fraud") props.setAntifraudRestrictionOn();
        }, 2000);
    };

    const finishCat1234CreditShellGetInfoFlow = () => {
        if (props.vm.CreditShellViewModel.Cat12347CreditShellCoversBalanceDue) {
            props.setPaymentMode("cat1234CreditCoversBalance");
            props.scrollToSubmitButton();
        } else {
            props.openCreditShellModal();
        }
    };

    const finishCat56CreditShellGetInfoFlow = async () => {
        if (props.vm.CreditShellViewModel.Cat56CreditShellCoversBalanceDue) {
            props.setPaymentMode("cat56CreditCoversBalance");
            props.scrollToSubmitButton();
        } else {
            await handlePaymentIntent({ type: "cat56CreditShellPay" });
        }
    };

    const validateContactAndInvoiceForms = async (flow: PaymentIntentFlow): Promise<boolean> => {
        const isCuitValid = props.payer.IsCuitValid && props.payer.IsCuitLengthValid;
        props.setContactFormValidated();
        const isContactValid =
            flow.ValidateForms === "cuitOnly" ||
            flow.ValidateForms === "none" ||
            (await props.validatePaymentContactForm());

        switch (flow.ValidateForms) {
            case "contactAndInvoiceAndCuit":
                const isInvoinceValid = await props.validateInvoiceForm();
                return isContactValid && isInvoinceValid && isCuitValid;
            case "contactOnly":
                return isContactValid;
            case "cuitOnly":
                return isCuitValid;
            default:
                return true;
        }
    };

    const validateTermsAcceptance = (flow: PaymentIntentFlow) => {
        switch (flow.ValidateTerms) {
            case "always":
                return props.isTermsAcceptanceValid;
            case "conditional":
                return !props.model.CreditShellViewModel.CheckTermsBeforeCreditShell || props.isTermsAcceptanceValid;
            default:
                return true;
        }
    };

    const validateXmlPaymentMethod = async (flow: PaymentIntentFlow) => {
        if (
            !flow.ValidateXmlPayment ||
            (selectedMethod?.PaymentMethodCode !== AGENCY_PAYMENT_FOP_CODE &&
                selectedMethod?.AllowedCards?.length === 0)
        ) {
            return true;
        }

        let isValid = await props.validateMethodsContainer();
        isValid =
            (selectedMethod?.PaymentMethodCode === AGENCY_PAYMENT_FOP_CODE ||
                cardData?.CardValidationStatus === "valid") &&
            isValid;

        return isValid;
    };

    const checkGiftcardSession = async (flow: PaymentIntentFlow) => {
        if (!flow.CheckGiftcardSession) return true;

        const loader = props.startLoad();
        let isValid;

        try {
            const response = await ajaxJsonRequest<{ IsDataCorrect: boolean }>({
                method: "GET",
                url: ROUTES.ApiRoutes.GiftCardConsistencyCheck,
            });
            isValid = response.data.IsDataCorrect;
        } catch (e) {
            isValid = true;
        }

        if (!isValid) props.openGiftcardSessionErrorModal();

        props.stopLoad(loader);
        return isValid;
    };

    const checkPromoCode = async (flow: PaymentIntentFlow) => {
        if (!bookingContext.promoCode || !flow.CheckPromoCode) return true;

        const loader = props.startLoad();
        const canUsePromoCode = await checkPromoCodeAvailable(CREDIT_SHELL_PAYMENT_FOP_CODE, false, true);
        props.stopLoad(loader);

        if (canUsePromoCode) return true;

        props.openPromoCodeBlockModal();

        return false;
    };

    const paymentIntentFlowCallbackMap = () =>
        new Map<
            PaymentFlowFinishCallbackType,
            (data?: { type?: PaymentIntentFlowType; loader?: JsLoader; voucherResult?: ApiVoucherResult }) => void
        >([
            ["commit1234CreditShell", () => finishCat1234CreditShellGetInfoFlow()],
            ["commit56CreditShell", () => finishCat56CreditShellGetInfoFlow()],
            ["commit", (data) => commitBooking({ type: data.type })],
            [
                "commitPeruCompra",
                () =>
                    window.setTimeout(async () => {
                        await commitBooking({ type: "peruCompra" });
                    }, 2000),
            ],
            ["commitVoucher", (data) => commitBooking({ type: "voucherPay", voucherResult: data.voucherResult })],
            [
                "commitWithLog",
                (data) =>
                    window.setTimeout(async () => {
                        await commitBooking({ type: data.type });
                    }, 2000),
            ],
            ["commitWithLogAndAntifraud", (data) => commitBookingWithLogAndAntifraud(data.type, data.loader)],
        ]);

    const finishPaymentIntentFlow = async (data: {
        type: PaymentIntentFlowType;
        loader: JsLoader;
        voucherResult?: ApiVoucherResult;
    }) => {
        const callback = PaymentIntentFlowHelper.getPaymentIntentFlow(
            data.type,
            paymentIntentFlowCallbackMap(),
        ).CallbackOnFinish;

        if (typeof callback === "function") callback(data);

        if (!PaymentIntentFlowHelper.getPaymentIntentFlow(data.type, paymentIntentFlowCallbackMap()).KeepLoaderAtEnd) {
            props.stopLoad(data.loader);
        }
    };

    // EXPORTS

    const handlePaymentIntent = async (data: { type: PaymentIntentFlowType; voucherResult?: ApiVoucherResult }) => {
        const flow = PaymentIntentFlowHelper.getPaymentIntentFlow(data.type, paymentIntentFlowCallbackMap());

        // 0. LOG

        if (flow.ValidateXmlPayment) void tealiumManager.logContinueClicked(true);

        // 1. VALIDATE

        props.setForcePreventTermsValidation(flow.ValidateTerms === "never");

        const isValid = await validateAllForms(flow);
        const canUsePromoCode = await checkPromoCode(flow);

        if (shouldAbortPaymentIntentFlowAsInvalid(flow, isValid, canUsePromoCode)) {
            props.setForcePreventTermsValidation(false);
            return false;
        }

        const isGiftcardSessionValid = await checkGiftcardSession(flow);

        if (!isGiftcardSessionValid) {
            props.setForcePreventTermsValidation(false);
            return false;
        }

        // 2. SUBMIT INVOICE AND CONTACT

        if (flow.SubmitForms !== "none") props.removeErrorMessages();

        const loader = props.startLoad();

        const contactAndInvoiceSubmitResult = await submitContactAndInvoiceForm(flow);

        if (!contactAndInvoiceSubmitResult) {
            props.stopLoad(loader);
            raiseBookingFlowContinueEvent(false);
            props.setForcePreventTermsValidation(false);
            return false;
        }

        // 3. COMMIT BOOKING OR FINISH FLOW

        await finishPaymentIntentFlow({ ...data, loader, voucherResult: data.voucherResult });
        return true;
    };

    const handlePageSubmitClick = async () => {
        if (flowContext.isRedemptionFlow) {
            return handlePaymentIntent({ type: "redemption" });
        }

        if (userContext.peruCompra.role !== "none" || bookingContext.isPeruCompraBooking) {
            return handlePaymentIntent({ type: "peruCompra" });
        }

        if (userContext.chileCompra.role === "supervisor") return handlePaymentIntent({ type: "chileCompra" });

        if (props.paymentMode === "voucherCoversBalance" || props.paymentMode === "giftcardCoversBalance") {
            return handlePaymentIntent({ type: "voucherCoversBalance", voucherResult });
        }

        if (props.paymentMode === "cat1234CreditCoversBalance" || props.paymentMode === "cat56CreditCoversBalance") {
            return handlePaymentIntent({ type: "creditShellCoversBalance" });
        }

        if (selectedMethod?.PaymentMethodCode === AGENCY_PAYMENT_FOP_CODE) {
            return handlePaymentIntent({ type: "agency" });
        }

        const isClientSideMethodSelected = selectedMethod?.IsClientSide;
        const clientSideMethod = selectedMethod?.ClientSideMethod;

        if (!isClientSideMethodSelected) return handlePaymentIntent({ type: "xml" });

        if (clientSideMethod === "Bn") return handlePaymentIntent({ type: "bn" });

        if (clientSideMethod === "Bp") return handlePaymentIntent({ type: "bp" });

        if (clientSideMethod === "CheckoutPro") return handlePaymentIntent({ type: "checkoutPro" });

        if (clientSideMethod === "BancoEstado") return handlePaymentIntent({ type: "compraqui" });

        if (clientSideMethod === "Et") return handlePaymentIntent({ type: "et" });

        if (clientSideMethod === "SafetyPay") return handlePaymentIntent({ type: "safetyPay" });

        return handlePaymentIntent({ type: "xml" });
    };

    useEffect(() => {
        if (!props.vm || props.paymentMode) return;
        props.setPaymentMode(canOnlyHold(props.vm) ? "none" : "regular");
    }, [props.vm]);

    return {
        errorMessage,
        handlePageSubmitClick,
        handlePaymentIntent,
    };
};
