import { html } from "haunted";
import i18next from "i18next";
import { unsafeHTML } from "lit-html/directives/unsafe-html";

export const usePeruCompraContactPage = () => {
    // TEMPLATES

    const headerTemplate = () => html`
        <div class="cug2b-contact-header">
            <div class="cug2b-title-container peru-compra">
                <i class="js-icon-cug js-cug-phone"></i>
                <div>
                    <h1>${i18next.t("Comunícate a nuestro Contact Center para clientes Perú Compras")}</h1>
                    <h2>
                        <span>Teléfono : +51 (01) 311-0005 Opción 3 </span> /
                        <span>Mail : perucompras@jetsmart.com</span>
                    </h2>
                </div>
            </div>
        </div>
    `;

    const contentTemplate = () => html`
        <div class="cug2b-contact-content">
            <div class="cug2b-contact-img peru-compra"></div>
            <div class="cug2b-contact-info peru-compra rounded-[10px]">
                <h2>
                    ${unsafeHTML(
                        i18next.t(
                            "<span> Si tienes dudas adicionales y deseas comunicarte directamente con Perú Compras, </span> aquí tienes los siguientes medios de contacto para que puedas resolver tus inquietudes:",
                        ),
                    )}
                </h2>
                <ul>
                    <li><span>Teléfono:</span> +51 (01) 643-0000</li>
                    <li><span>Mail:</span> acuerdosmarco@perucompras.gob.pe</li>
                </ul>
            </div>
        </div>
    `;

    const htmlTemplate = () => html` ${headerTemplate()}${contentTemplate()} `;

    return { htmlTemplate };
};
