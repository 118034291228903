import { TestIdDictionary as T, getTestId } from "../../testing-helpers/TestIdHelper";
import { html, useRef } from "haunted";
import i18next from "i18next";
import { OUTBOUND } from "../../shared/commonConstants";
import { useMemo } from "../../shared/haunted/CustomHooks";
import { mapToFlightOption, mapToCarouselDates } from "../../component-mappers/FlightOptionMappers";
import { capitalizeAndRemoveDot } from "../../shared/common";
import { CLASS_NAMES } from "../../shared/classNames";
import dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import * as IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import * as IsSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(IsSameOrAfter);
dayjs.extend(IsSameOrBefore);
dayjs.extend(CustomParseFormat);
import { useFlightDateCarousel } from "./useFlightDateCarousel";
import { useFlightOptionOrdering } from "./useFlightOptionOrdering";
import { ref } from "../../directives/ref";
import { FlightPageContext } from "../../component-models/flight/contexts/FlightPageContext";
import { FlightSelectDTO } from "./contexts/useFlightPageContext";
import classNames from "classnames";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useFlowContext } from "../../managers/useFlowContext";

export interface Props {
    context: FlightPageContext;
    inboundDate: dayjs.Dayjs;
    isBrazilianStationSelected: boolean;
    isInFlightMove?: boolean;
    isValidated: boolean;
    journeyIndex: number;
    outboundDate: dayjs.Dayjs;
    onSelect: (data: FlightSelectDTO) => Promise<void>;
    onWeekdayClick?: (date: dayjs.Dayjs, journeyIndex: number) => void;
}

export const useFlightJourney = (props: Props) => {
    const rootElem = useRef<HTMLDivElement>(null);
    const flowContext = useFlowContext();
    const [userContext] = useReduxState("userContext");

    const journey = useMemo(
        () =>
            props.context?.journeys.length > 1
                ? props.context?.journeys[props.journeyIndex]
                : props.context?.journeys[0],
        [props.context?.journeys, props.journeyIndex],
    );

    const carouselDates = useMemo(() => mapToCarouselDates(journey), [journey]);

    const carousel = useFlightDateCarousel({
        carousel: { Dates: carouselDates, JourneyIndex: props.journeyIndex },
        context: props.context,
        inboundDate: props.inboundDate,
        outboundDate: props.outboundDate,
        onClick: props.onWeekdayClick,
    });

    const ordering = useFlightOptionOrdering();

    const mappedOptions = useMemo(
        () =>
            journey
                ? journey.FlightDetails.map((flightDetails, index) =>
                      mapToFlightOption(flightDetails, index, journey),
                  ).sort(ordering.sorter)
                : [],
        [journey, ordering.sorter],
    );

    const showFlightSelectorHeader = useMemo(() => {
        if (!props.context?.bundleState || !props.context.flightState) return true;

        if (props.context.bundleState.bundlesMode === "Legacy") {
            return props.journeyIndex === OUTBOUND
                ? !props.context.flightState.selectedOutboundFlight
                : !props.context.flightState.selectedInboundFlight;
        }

        return props.journeyIndex === OUTBOUND
            ? !props.context.flightState.selectedOutboundFlight && !props.context.bundleState.selectedOutboundBundle
            : !props.context.flightState.selectedInboundFlight && !props.context.bundleState.selectedInboundBundle;
    }, [props.context?.bundleState, props.context?.flightState, props.journeyIndex]);

    const isAmerican = () => userContext?.isLoggedIn && flowContext.isRedemptionFlow;

    // TEMPLATES

    const optionsListTemplate = () =>
        journey
            ? mappedOptions.map(
                  (option) => html`
                      <ac-animated-container
                          .context=${props.context}
                          .flightOption=${option}
                          .flightSelect=${props.onSelect}
                          .isBrazilianStationSelected=${props.isBrazilianStationSelected}
                          .isInFlightMove=${props.isInFlightMove}
                          .isValidated=${props.isValidated}
                          .journeyIndex=${props.journeyIndex}
                          .referenceObject=${rootElem.current}
                      >
                      </ac-animated-container>
                  `,
              )
            : "";

    const headerDateTemplate = () =>
        journey?.TripDate ? capitalizeAndRemoveDot(dayjs(journey.TripDate).format("ddd DD-MM")) : "";

    const americanSelectorHeaderTemplate = () =>
        isAmerican()
            ? html`
                  <div class="col-sm-1-2 bg-brand-secondary">
                      <label class="text-white">${i18next.t("Miles")}</label>
                  </div>
              `
            : "";

    const selectorHeaderTemplate = () => html`
        <header
            class=${classNames("fee-selector-header", { open: showFlightSelectorHeader })}
            data-test-id=${getTestId(T.FLIGHT.HEADER, { j: props.journeyIndex || 0 })}
        >
            <div class="row">
                <div class=${classNames("col-xs-1", { "col-sm-1-2": isAmerican() })}>
                    <label
                        class="text-brand-secondary"
                        data-test-id=${getTestId(T.FLIGHT.HEADER_LABEL, { j: props.journeyIndex || 0 })}
                    >
                        ${props.journeyIndex === OUTBOUND
                            ? i18next.t("PriceDisplay-DepartingFlight")
                            : i18next.t("PriceDisplay-ReturningFlight")}
                        <span> ${headerDateTemplate()} </span>
                    </label>
                </div>
                ${americanSelectorHeaderTemplate()}
            </div>
        </header>
    `;

    const flightErrorTemplate = () =>
        props.context.showFlightSelectError(props.journeyIndex, props.isValidated)
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message ${CLASS_NAMES.flightSelectError}">
                                  ${i18next.t("V2-SelectFlightsError")}
                              </div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const noFlightsInformationTemplate = () => {
        return html` <div class="row">
            <div class="col-xs-1">
                <div class="full-width-information no-flights">
                    <i class="fas fa-exclamation-circle notification-icon custom-alert"></i>
                    <span>
                        ${props.journeyIndex === OUTBOUND
                            ? i18next.t("No hay vuelos de ida disponibles para esta fecha.")
                            : i18next.t("No hay vuelos de vuelta disponibles para esta fecha.")}
                    </span>
                </div>
            </div>
        </div>`;
    };

    const flightSelectorTemplate = () =>
        journey?.FlightsAvailable
            ? html`
                  <div class="fee-selector">
                      ${selectorHeaderTemplate()}
                      <ul>
                          ${optionsListTemplate()}
                      </ul>
                  </div>
              `
            : noFlightsInformationTemplate();

    const orderingTemplate = () => (showFlightSelectorHeader ? ordering.htmlTemplate() : "");

    const htmlTemplate = () => {
        if (journey?.TripIndex !== props.journeyIndex || props.context.areAllBundlesSelected) return "";

        return html`
            <div class="ts-fee-selector" ref=${ref(rootElem)}>
                <div data-test-id=${getTestId(T.FLIGHT.OPTIONS_LIST, { j: props.journeyIndex || 0 })}>
                    ${carousel.htmlTemplate()} ${flightErrorTemplate()} ${orderingTemplate()}
                    ${flightSelectorTemplate()}
                </div>
            </div>
        `;
    };

    return { rootElem, htmlTemplate };
};
