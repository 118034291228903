import { ItineraryPageViewModel } from "../../../component-models/itinerary/ItineraryPageViewModel";

export const itineraryDetailsUtils = () => {
    // EXPORTS
    const orderedPassengers = (model: ItineraryPageViewModel) => {
        const nonInfants = model.ItineraryModelV2.Passengers.filter((p) => p.Type !== "INFANT").sort(
            (a, b) => a.PassengerNumber - b.PassengerNumber,
        );
        const infants = model.ItineraryModelV2.Passengers.filter((p) => p.Type === "INFANT").sort(
            (a, b) => a.RelatedPassengerNumber - b.RelatedPassengerNumber,
        );

        return [...nonInfants, ...infants];
    };

    return {
        orderedPassengers,
    };
};
