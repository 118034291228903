import { ApiInsurancePassenger } from "../component-models/extras/insurance/ApiInsurancePassenger";
import { InsurancePassengerModel } from "../component-models/extras/insurance/InsurancePassengerModel";
import { BagType } from "../components/spa/baggage/useBaggagePage";

export function mapToInsurancePassengerModel(
    pax: ApiInsurancePassenger,
    nonInfantLength: number,
): InsurancePassengerModel {
    return {
        attachedPassengerNumber: pax.AttachedPassengerNumber,
        displayedIndex: pax.Type === "INF" ? pax.AttachedPassengerNumber + nonInfantLength : pax.PassengerNumber,
        isBaggageInsuranceOpened: pax.CheckedBaggageWithInsurance > 0 || pax.OversizedBaggageWithInsurance > 0,
        passengerNumber: pax.PassengerNumber,
        type: pax.Type,

        checkedBaggageWithInsurance: pax.CheckedBaggageWithInsurance || 0,
        isCheckedBaggageEnabled: pax.IsCheckedBaggageEnabled,
        isOversizedBaggageEnabled: pax.IsOversizedBaggageEnabled,
        maxCheckedBaggage: pax.MaxCheckedBaggage,
        oversizedBaggageWithInsurance: pax.OversizedBaggageWithInsurance || 0,
        maxOversizedBaggage: pax.MaxOversizedBaggage,
    };
}

export function mapChangeOneBaggage(
    passengers: InsurancePassengerModel[],
    paxIndexToChange: number,
    bagType: BagType,
    newAmount: number,
): InsurancePassengerModel[] {
    return passengers.map((p): InsurancePassengerModel => {
        if (p.displayedIndex === paxIndexToChange) {
            return {
                ...p,
                checkedBaggageWithInsurance: bagType === "checkedBaggage" ? newAmount : p.checkedBaggageWithInsurance,
                oversizedBaggageWithInsurance:
                    bagType === "oversizedBaggage" ? newAmount : p.oversizedBaggageWithInsurance,
            };
        } else {
            return p;
        }
    });
}

export function mapChangeAllBaggage(
    passengers: InsurancePassengerModel[],
    passengerIndicesToChange: number[],
    type: "add" | "remove",
): InsurancePassengerModel[] {
    return passengers.map((newPassenger): InsurancePassengerModel => {
        return (newPassenger.isCheckedBaggageEnabled || newPassenger.isOversizedBaggageEnabled) &&
            passengerIndicesToChange.includes(newPassenger.displayedIndex)
            ? {
                  ...newPassenger,
                  isBaggageInsuranceOpened: type === "add",
                  checkedBaggageWithInsurance: type === "add" ? newPassenger.maxCheckedBaggage : 0,
                  oversizedBaggageWithInsurance: type === "add" ? newPassenger.maxOversizedBaggage : 0,
              }
            : newPassenger;
    });
}
