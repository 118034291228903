import { html } from "haunted";
import { UserInfo } from "../../../component-models/UserInfo";
import { useUserInfoFields } from "./useUserInfoFields";

export interface Props {
    model: UserInfo;
}

export const useChileCompraUserInfo = (props: Props) => {
    const userInfo = useUserInfoFields({
        model: props.model,
    });

    // TEMPLATES

    const htmlTemplate = () => html`
        <div class="cug2b-profile-table">
            <div class="cug2b-profile-line">
                ${userInfo.fullNameTemplate()} ${userInfo.docNumberTemplate()} ${userInfo.roleNameTemplate()}
            </div>
            <div class="cug2b-profile-line">${userInfo.emailTemplate()}</div>
        </div>
    `;

    return { htmlTemplate };
};
