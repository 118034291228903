import { html } from "lit-html";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { PerJourneyPerPaxPassengerDto } from "./per-journey-per-pax-passenger";
import i18next from "i18next";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";
import { useMemo } from "../../../../shared/haunted/CustomHooks";

export interface Props {
    dto: PerJourneyPerPaxPassengerDto;
}

export const usePaidOptionCellInfo = (props: Props) => {
    const { formatNumber } = useNumberFormatter();

    const alreadyAdded = useMemo(
        () => props.dto.bagType !== "cabinBaggage" && props.dto.passenger.quantity > 0,
        [props.dto.bagType, props.dto.passenger.quantity],
    );

    const unformattedNextPrice = () =>
        props.dto.baggageSection.perJourneyPerPax.nextPrice({
            canGetFirstBaggageStaffDiscount: props.dto.canGetFirstBaggageStaffDiscount,
            canGetFirstBaggageBancoEstadoDiscount: props.dto.canGetFirstBaggageBancoEstadoDiscount,
            isOriginal: false,
            passenger: props.dto.passenger,
        });

    const formattedNextPrice = () =>
        formatNumber({ amount: unformattedNextPrice(), leadingSign: true }).replace(" ", "");

    const htmlTemplate = () => html`
        <div class="b2-pax-option-info ${alreadyAdded ? "hidden-sm-up" : ""}">
            ${alreadyAdded ? i18next.t("Agregar otro por") : i18next.t("Agregar por")}
            <span
                data-test-value=${unformattedNextPrice()}
                data-test-id=${getTestId(
                    alreadyAdded
                        ? T.BAGGAGE.PER_JOURNEY_PER_PAX_NEXT_PRICE
                        : T.BAGGAGE.PER_JOURNEY_PER_PAX_MINIMUM_PRICE,
                    {
                        m: props.dto.isMobile,
                        j: props.dto.journey.index,
                        p: props.dto.passenger.index,
                        c: props.dto.bagType,
                    },
                )}
            >
                ${formattedNextPrice()}
            </span>
        </div>
    `;

    return { htmlTemplate };
};
