import { ApiItineraryContactPassenger } from "../component-models/itinerary/ApiItineraryContactPassenger";
import { ApiItineraryPageViewModel } from "../component-models/itinerary/ApiItineraryPageViewModel";
import { ContactPassenger } from "../component-models/itinerary/ContactPassenger";
import { ItineraryPageViewModel } from "../component-models/itinerary/ItineraryPageViewModel";

export function mapItineraryPageViewModel(model: ApiItineraryPageViewModel): ItineraryPageViewModel {
    const contactPassengers = model.ContactPassengers.map(
        (item: ApiItineraryContactPassenger): ContactPassenger => ({
            Name: item.Name,
            PaxIndex: item.PaxIndex,
            PaxAddress: item.PaxAddress
                ? {
                      Address: item.PaxAddress.AddressLine1,
                      City: item.PaxAddress.City,
                      Country: item.PaxAddress.CountryCode,
                      Email: item.PaxAddress.EmailAddress,
                      PhoneNumber: item.PaxAddress.Phone ? item.PaxAddress.Phone.split(" ")[1] || "" : "",
                      PhonePrefix: item.PaxAddress.Phone ? item.PaxAddress.Phone.split(" ")[0] || "" : "",
                  }
                : undefined,
            FrequentFlyer: item.FrequentFlyer ? item.FrequentFlyer : "",
            IsFrequentFlyerProvided: Boolean(item.FrequentFlyer),
        }),
    );

    return {
        ...model,
        CancelledJourneyDepartureStations: model.CancelledJourneyDepartureStations ?? [],
        ContactPassengers: contactPassengers,
        ItineraryPayments: model.ItineraryPayments ?? [],
        RedemptionItineraryPayments: model.RedemptionItineraryPayments ?? [],
    };
}
