import { html } from "lit-html";
import i18next from "i18next";
import classNames from "classnames";
import { useRef } from "haunted";
import { useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { hideLoader, SelectOption, showLoader } from "../../../shared/common";
import { useModal } from "../../shared/useModal";
import { ref } from "../../../directives/ref";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { ROUTES } from "../../../shared/apiRoutes";

const loaderClassName = "ts-chile-compra-justification-modal";

export const useChileCompraNearFlightJustificationModal = () => {
    const { ajaxRequest } = useAjax();

    const initial = useRef<HTMLDivElement>(null);
    const selector = useRef<HTMLDivElement>(null);

    const [reason, setReason] = useState<string>("");
    const [view, setView] = useState<"initial" | "selector">("initial");
    const [height, setHeight] = useState<number>(0);

    const postResponse = async () => {
        const loader = showLoader({
            name: loaderClassName,
            container: selector.current,
            noPlane: true,
        });

        const body = { Justification: reason };

        await ajaxRequest({
            body,
            method: "PUT",
            url: ROUTES.ApiRoutes.ChileCompraNearFlightJustification,
        });

        hideLoader(loader);
    };

    const handleJustifyButton = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // DEVNOTE This is so that the modal does not shrink vertically
        setHeight(initial.current?.clientHeight || 0);

        setView("selector");
    };

    const handleConfirmButton = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        await postResponse();

        modal.close();
    };

    const initialViewTemplate = () => html`
        <div ref=${ref(initial)}>
            <div class=${loaderClassName}>
                <div class="modal-body">
                    <div class="flex w-full justify-center px-6 py-3">
                        <i class="js-new js-n-triangle-exclamation-thin text-[100px] text-brand-tertiary"></i>
                    </div>
                    <div class="w-full px-6 font-body leading-snug text-be-blue-2">
                        ${i18next.t("ChileCompraNearFlightJustificationModalText")}
                    </div>
                </div>
                <div class="flex w-full flex-col items-center justify-center gap-6 p-6 sm:flex-row">
                    <button class="rounded-secondary-btn" @click=${handleJustifyButton}>
                        ${i18next.t("Continuar")}
                    </button>
                </div>
            </div>
        </div>
    `;

    const reasonOptions = useMemo(() => {
        const reasons = [
            "",
            i18next.t("Salud del pasajero"),
            i18next.t("En comisión de servicio o relocalización"),
            i18next.t("Visitas urgentes"),
            i18next.t("Equipaje adicional por evento"),
            i18next.t("Cambio o cancelación del evento o itinerario"),
            i18next.t("Viaje por largo periodo"),
            i18next.t("Seguridad del pasajero"),
            i18next.t("Asistencia a juicio"),
            i18next.t("Agenda de la autoridad"),
            i18next.t("Indisponibilidad de Mercado Publico"),
        ];

        return reasons.map(
            (item): SelectOption => ({
                Text: item,
                Value: item,
                IsSelected: item === reason,
            }),
        );
    }, [reason]);

    const selectorViewTemplate = () => html`
        <div ref=${ref(selector)}>
            <div class=${loaderClassName}>
                <div class="modal-body" style=${`height: ${height}px;`}>
                    <div class="flex w-full justify-center px-6 py-3 text-center text-xl font-bold text-be-blue-2">
                        ${i18next.t("Ingresa tu justificación")}
                    </div>
                    <div class="w-full px-6 pt-6 font-body text-sm leading-snug text-new-ui-gray">
                        ${i18next.t("Selecciona justificación de compra fuera de política")}
                    </div>
                    <div class="w-full px-6 pt-2">
                        <ac-select
                            .label=${i18next.t("Selecciona el tramo que quieres modificar")}
                            .options=${reasonOptions}
                            .onSelect=${(value: string) => setReason(value)}
                        ></ac-select>
                    </div>
                    <div class="flex w-full items-center justify-center p-6">
                        <button
                            class=${classNames("rounded-secondary-btn with-arrow", { disabled: !reason })}
                            @click=${handleConfirmButton}
                        >
                            ${i18next.t("Continuar")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    `;

    const modal = useModal({
        content: {
            classNames: "w-[510px] max-w-[95%]",
            template: view === "selector" ? selectorViewTemplate : initialViewTemplate,
        },
        onOpen: () => {
            setView("initial");
            setReason("");
        },
    });

    return {
        reason,
        htmlTemplate: modal.htmlTemplate,
        open: modal.open,
    };
};
