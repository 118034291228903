import { CHILEAN_CULTURE_CODE, LINKS, URL_VARS } from "../../shared/commonConstants";
import { ROUTES } from "../../shared/apiRoutes";
import i18next from "i18next";
import { html } from "haunted";
import { ApiItineraryViewModelV2 } from "../../component-models/itinerary/ApiItineraryViewModelV2";
import { SearchInsuranceResult } from "../../component-models/spa/SearchInsuranceResult";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { useAppContext } from "../../managers/useAppContext";
import { useItineraryTealiumManager } from "../../managers/Tealium/useItineraryTealiumManager";
import { CLASS_NAMES } from "../../shared/classNames";
import { isGroupBooking, maskCurrenciesForDisplay } from "../../shared/common";
import { useBookingContext } from "../../managers/useBookingContext";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../shared/useNumberFormatter";

export interface Props {
    model: ApiItineraryViewModelV2;
}

export const useItineraryBanners = (props: Props) => {
    // HELPERS

    const init = () => {
        if (
            !bookingContext.isInsuranceAvailable ||
            props.model.Insurance.ExistingInsuranceDetails ||
            !showPostBookingInsuranceBanner()
        ) {
            return;
        }

        const doIt = async () => {
            const insurance = await searchInsurance();
            setInsurance(insurance);
        };

        void doIt();
    };

    const showPostBookingExtrasBanners = () =>
        !isGroupBooking(bookingContext) &&
        (!bookingContext.isCheckinClosedOutbound ||
            (!bookingContext.isCheckinClosedInbound && props.model.Journeys.length > 1));

    const canBuyInsuranceBasedOnCheckinStatus = () =>
        !bookingContext.isCheckinClosedOutbound &&
        (!bookingContext.isCheckinClosedInbound || props.model.Journeys.length === 1);

    const canBuyInsuranceBasedOnRole = () => userContext?.chileCompra.role === "none";

    const showPostBookingInsuranceBanner = () =>
        props.model.Insurance.CanBuyInsurance && canBuyInsuranceBasedOnCheckinStatus() && canBuyInsuranceBasedOnRole();

    const showBancoEstadoBanner = () =>
        appContext.Culture === CHILEAN_CULTURE_CODE &&
        !userContext.cug.isMember &&
        userContext.bancoEstado.category !== 6;

    // FIXME This is based on the assumption that ssr.Note only exists on seats
    const showGdsSeatBanner = () =>
        bookingContext.isGdsBooking &&
        props.model.Journeys.some(
            (journey) =>
                (journey.BundleInfo?.ContainsFreeAnySeat || journey.BundleInfo?.ContainsFreeRegularSeat) &&
                journey.JourneyPassengers.some((passenger) => !passenger.Ssrs.some((ssr) => ssr.Note)),
        );

    // EVENT HANDLERS

    const logAndSubmit = async (e: MouseEvent, type: string) => {
        e.preventDefault();
        e.stopPropagation();

        const url = (e.target as HTMLAnchorElement).getAttribute("href");

        await tealiumManager.logAncillaryBannerClicked(type);

        window.setTimeout(() => (window.location.href = url), 0);
    };

    // COMPONENT

    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const tealiumManager = useItineraryTealiumManager();

    const [userContext] = useReduxState("userContext");

    const { searchInsurance } = useAjax();
    const { formatNumber } = useNumberFormatter();

    const [insurance, setInsurance] = useState<SearchInsuranceResult>(undefined);

    useEffect(init, []);

    // TEMPLATES

    const bancoEstadoNonMobileBannerTemplate = () => {
        const imgUrl =
            userContext.bancoEstado.category === 5 ? props.model.BannerUrls.Smart : props.model.BannerUrls.NonSmart;

        return html`
            <a href="${LINKS.SmartPlus}" target="_blank"><img class="hidden-xs max-w-full" src="${imgUrl}" /></a>
        `;
    };

    const bancoEstadoMobileBannerTemplate = () => html`
        <div class="hidden-sm-up">
            <div class="i2-be-banner-title">
                ${userContext.bancoEstado.category === 5
                    ? i18next.t("¡cambia tu tarjeta a smart+!")
                    : i18next.t("Y tú, ¿ya conociste la nueva tarjeta SMART?")}
            </div>
            <div class="i2-be-banner-text">
                ${userContext.bancoEstado.category === 5
                    ? i18next.t("Y conoce todos los beneficios que esta tarjeta tiene para tí")
                    : i18next.t("Conoce los increíbles beneficios que BancoEstado y JetSMART tienen para tí")}
            </div>
            <a href="${LINKS.SmartPlus}" target="_blank" class="i2-be-banner-button">
                ${i18next.t("La quiero")}
                <i class="js-icon js-circle-chevron-right"></i>
            </a>
        </div>
    `;

    const bancoEstadoBannerTemplate = () => {
        if (!showBancoEstadoBanner()) {
            return "";
        }

        return html`
            <div class="i2-itinerary-section i2-bancoestado">
                ${bancoEstadoNonMobileBannerTemplate()} ${bancoEstadoMobileBannerTemplate()}
            </div>
        `;
    };

    const extrasBannerTemplate = () =>
        showPostBookingExtrasBanners()
            ? html`
                  <div class="i2-itinerary-section i2-optionals-banner">
                      <img
                          class="hidden-xs"
                          src="/Images/BancoEstado/i2-extras-bag-banner-${appContext.Language}-dt.png"
                      />
                      <img
                          class="hidden-sm-up"
                          src="/Images/BancoEstado/i2-extras-bag-banner-${appContext.Language}-mb.png"
                      />
                  </div>
              `
            : "";

    const cabinBaggageBannerTemplate = () =>
        props.model.CanBuyMoreCabinBag
            ? html`
                  <div class="i2-itinerary-section i2-cabin-bags">
                      <img class="visible-lg-up" src="/Images/BancoEstado/i2-cabin-bag-banner-dt.png" />
                      <img class="hidden-xs hidden-lg-up" src="/Images/BancoEstado/i2-cabin-bag-banner-tb.png" />
                      <img class="visible-xs" src="/Images/BancoEstado/i2-cabin-bag-banner-mb.png" />
                      <header>${i18next.t("Compra hoy y ahorra hasta 50% en la compra de tu equipaje de mano")}</header>
                      <article>${i18next.t("y lleva lo que necesites en la cabina del avión")}</article>
                      <a
                          href="${ROUTES.PageBaggage}"
                          class="rounded-primary-btn i2-btn"
                          @click=${(e: MouseEvent) => logAndSubmit(e, "baggage")}
                      >
                          ${i18next.t("Lo quiero")}
                      </a>
                  </div>
              `
            : "";

    const checkedBaggageBannerTemplate = () =>
        props.model.CanBuyMoreCheckedInBag
            ? html`
                  <div class="i2-itinerary-section i2-checked-bags">
                      <img class="visible-md-up" src="/Images/BancoEstado/i2-checked-bag-banner-dt.png" />
                      <img class="visible-sm" src="/Images/BancoEstado/i2-checked-bag-banner-tb.png" />
                      <img class="visible-xs" src="/Images/BancoEstado/i2-checked-bag-banner-mb.png" />
                      <header>${i18next.t("Ahorra hasta 50% en la compra de tu equipaje facturado")}</header>
                      <article>${i18next.t("y lleva todo lo que necesites")}</article>
                      <a
                          href="${ROUTES.PageBaggage}"
                          data-test-id="itinerary-baggage-a"
                          class="rounded-primary-btn i2-btn"
                          @click=${(e: MouseEvent) => logAndSubmit(e, "baggage")}
                      >
                          ${i18next.t("Lo quiero")}
                      </a>
                  </div>
              `
            : "";

    const insuranceBannerTemplate = () =>
        bookingContext.isInsuranceAvailable &&
        !props.model.Insurance.ExistingInsuranceDetails &&
        insurance?.Cost &&
        insurance?.CostCurrency &&
        showPostBookingInsuranceBanner()
            ? html`
                  <div class="i2-itinerary-section i2-pax-insurance">
                      <div class="post-booking-insurance-image-container">
                          <img class="visible-md-up" src="/Images/Insurance/post-booking-insurance-dt.png" />
                          <img class="visible-sm" src="/Images/Insurance/post-booking-insurance-tb.png" />
                          <img class="visible-xs" src="/Images/Insurance/post-booking-insurance-mb.png" />
                          <div class="post-booking-insurance-ribbon">${i18next.t("Seguro de Viaje")}</div>
                      </div>
                      <header>
                          ${i18next.t("Disfruta en tu destino sin preocupaciones con tu seguro de viajes CHUBB")}
                      </header>
                      <article>${i18next.t("Mira los beneficios de tu seguro aquí.")}</article>
                      <a
                          href=${`${ROUTES.PageExtras}?${URL_VARS.POST_BOOKING_INSURANCE}`}
                          data-test-id="itinerary-extras-a"
                          class="rounded-primary-btn i2-btn"
                      >
                          ${i18next.t("Lo quiero por")}
                          ${formatNumber({
                              amount: insurance?.CostAmount,
                              currency: insurance?.CostCurrency,
                              leadingSign: true,
                          }).replace(" ", "")}
                          ${maskCurrenciesForDisplay(insurance?.CostCurrency)}
                      </a>
                  </div>
              `
            : "";

    const nonMobileSeatImagesTemplate = () => html`
        <img class="visible-md-up" src="/Images/BancoEstado/i2-seat-banner-dt.png" />
        <img class="visible-sm" src="/Images/BancoEstado/i2-seat-banner-tb.png" />
    `;

    const seatNonBe56Template = () => {
        const caption = showGdsSeatBanner()
            ? i18next.t(
                  "Selecciona hoy el asiento que quieras. Recuerda que dependerá de tu tarifa si tu asiento tiene costo o no.",
              )
            : i18next.t("Selecciona hoy el asiento que quieras y ahorra hasta un 30%");

        const buttonText = showGdsSeatBanner() ? i18next.t("Elegir asientos") : i18next.t("Comprar asiento");

        return html`
            <div class="i2-itinerary-section i2-seats">
                ${nonMobileSeatImagesTemplate()}
                <img class="visible-xs" src="/Images/BancoEstado/i2-seat-banner-mb.png" />
                <header>${i18next.t("Elige tu asiento y viaja junto a los tuyos")}</header>
                <article>${caption}</article>
                <a
                    class="rounded-primary-btn i2-btn"
                    href=${ROUTES.PageSeatmap}
                    @click=${(e: MouseEvent) => logAndSubmit(e, "seat")}
                >
                    ${buttonText}
                </a>
            </div>
        `;
    };

    const seatCategory5Template = () => html`
        <div class="i2-itinerary-section i2-seats banco-estado">
            ${nonMobileSeatImagesTemplate()}
            <img class="visible-xs" src="/Images/BancoEstado/i2-seat-banner-mb-be.png" />
            <header>
                ${i18next.t("Elige tu asiento y viaja junto a los tuyos")}
                <div class="i2-benefit bg-be-old-orange">
                    <img class="smart-logo" src="/Images/BancoEstado/be-smart-white-text-orange-bg.svg" />
                    <span
                        >${i18next.t("Beneficio")}&nbsp;<br />${i18next.t("tarjeta")}
                        <img src="/Images/BancoEstado/be-smart-text-logo-white.svg"
                    /></span>
                </div>
            </header>
            <article>${i18next.t("Tienes desde un 40% DCTO en asientos de la zona BancoEstado.")}</article>
            <a
                href="${ROUTES.PageSeatmap}"
                class="rounded-primary-btn i2-btn"
                @click=${(e: MouseEvent) => logAndSubmit(e, "seat")}
            >
                ${i18next.t("Comprar asiento")}
            </a>
        </div>
    `;

    const freeSeatsTemplate = () =>
        userContext?.bancoEstado.remainingFreeSeats > 0
            ? html`
                  <article>
                      ${i18next.t("Tienes {{amount}} asiento{{plural}} gratis", {
                          amount: userContext?.bancoEstado.remainingFreeSeats,
                          plural: userContext?.bancoEstado.remainingFreeSeats === 1 ? "" : "s",
                      })}
                      <span>${i18next.t("y además desde un")}</span>
                      ${i18next.t("40% DCTO")}
                      <span>${i18next.t("en la zona BancoEstado cuando tus asientos gratis se acaben.")}</span>
                  </article>
              `
            : html`
                  <article>
                      <span>${i18next.t("Tienes desde un")}</span>
                      ${i18next.t("40% DCTO")}
                      <span>${i18next.t("en la zona BancoEstado.")}</span>
                  </article>
              `;

    const seatCategory6Template = () => html`
        <div class="i2-itinerary-section i2-seats banco-estado">
            ${nonMobileSeatImagesTemplate()}
            <img class="visible-xs" src="/Images/BancoEstado/i2-seat-banner-mb-be.png" />
            <header>
                ${i18next.t("Elige tu asiento y viaja junto a los tuyos")}
                <div class="i2-benefit bg-be-gray-3">
                    <img class="smart-logo" src="/Images/BancoEstado/be-smart-plus-white-text-gray-bg.svg" />
                    <span
                        >${i18next.t("Beneficio")}<br />${i18next.t("tarjeta")}
                        <img src="/Images/BancoEstado/be-smart-plus-text-logo-white.svg"
                    /></span>
                </div>
            </header>
            ${freeSeatsTemplate()}
            <a
                href="${ROUTES.PageSeatmap}"
                class="rounded-primary-btn i2-btn"
                @click=${(e: MouseEvent) => logAndSubmit(e, "seat")}
            >
                ${i18next.t("Elegir asiento")}
            </a>
        </div>
    `;

    const seatBannerTemplate = () => {
        if (!props.model.CanBuyMoreSeatSelection) {
            return "";
        }

        return userContext.bancoEstado.category === 6
            ? seatCategory6Template()
            : userContext.bancoEstado.category === 5
              ? seatCategory5Template()
              : seatNonBe56Template();
    };

    const htmlTemplate = () => html`
        <div class="${CLASS_NAMES.itineraryBannersContainer} no-print">
            ${bancoEstadoBannerTemplate()} ${extrasBannerTemplate()} ${cabinBaggageBannerTemplate()}
            ${insuranceBannerTemplate()} ${checkedBaggageBannerTemplate()} ${seatBannerTemplate()}
        </div>
    `;

    return { htmlTemplate };
};
