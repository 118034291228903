import { INBOUND, OUTBOUND, PaxType } from "../../../../shared/commonConstants";
import { BaggagePageState } from "../../../../component-models/baggage/BaggagePageState";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../../../shared/haunted/HooksHelpers";
import { BagType } from "../useBaggagePage";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import { paxAmount } from "../../../../component-helpers/BaggageHelper";
import { getPaxLabel } from "../../../../shared/common";
import { useCallback } from "haunted";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useMobileBaggagePassenger } from "./useMobileBaggagePassenger";
import { useDesktopBaggagePassenger } from "./useDesktopBaggagePassenger";
import { BaggageSectionJourney } from "../../../../component-models/baggage/BaggageSectionJourney";
import { BaggageSectionJourneyPassenger } from "../../../../component-models/baggage/BaggageSectionJourneyPassenger";

export const name = "ac-per-journey-per-pax-passenger";

export interface PerJourneyPerPaxPassengerDto {
    baggageSection: BaggageSection;
    bagType: BagType;
    canGetFirstBaggageBancoEstadoDiscount: boolean;
    canGetFirstBaggageStaffDiscount: boolean;
    isMobile: boolean;
    journey: BaggageSectionJourney;
    pageState: BaggagePageState;
    passenger: BaggageSectionJourneyPassenger;
}

export interface Props {
    dto: PerJourneyPerPaxPassengerDto;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = { dto: host.dto };

    const bookingContext = useBookingContext();

    const canAdd = useMemo(
        () =>
            props.dto.baggageSection.perJourneyPerPax.isAddAvailable({
                bookingContext,
                journey: props.dto.journey,
                passenger: props.dto.passenger,
            }),
        [props.dto.baggageSection.perJourneyPerPax.isAddAvailable, props.dto.journey.index, props.dto.passenger.index],
    );

    const currentStatePassenger = useMemo(
        () =>
            props.dto.baggageSection.sectionUi.uiState.journeys
                .find((stateJourney) => stateJourney.index === props.dto.journey.index)
                ?.passengers.find((statePassenger) => statePassenger.index === props.dto.passenger.index),
        [props.dto.passenger.index, props.dto.baggageSection.sectionUi.uiState.journeys],
    );

    const hasUnfinishedSelectionForPax = useMemo((): boolean => {
        if (props.dto.bagType !== "oversizedBaggage") return false;
        if (!props.dto.baggageSection.journeys?.length) return false;
        if (props.dto.journey.passengers[0].isSoldOut) return false;
        if (currentStatePassenger?.hasSelected) return false;

        return props.dto.baggageSection.sectionUi.uiState.journeys.some((journey) =>
            journey.passengers.some((passenger) => passenger.hasSelected),
        );
    }, [props.dto.baggageSection.journeys, props.dto.baggageSection.sectionUi.uiState, currentStatePassenger]);

    const isJourneyValid = useMemo(
        () =>
            props.dto.bagType === "oversizedBaggage"
                ? !hasUnfinishedSelectionForPax
                : props.dto.baggageSection.isPassengerValid({
                      journey: props.dto.journey,
                      passenger: props.dto.passenger,
                  }),
        [
            hasUnfinishedSelectionForPax,
            props.dto.baggageSection.isPassengerValid,
            props.dto.bagType,
            props.dto.journey,
            props.dto.passenger,
        ],
    );

    const showError =
        props.dto.baggageSection.sectionUi.uiState.perJourneyPerPaxState === "open" &&
        props.dto.pageState.lastValidationTimestamp &&
        !isJourneyValid;

    const paxTypeLabel = () => {
        const type: PaxType = props.dto.passenger.index < bookingContext.adultsCount ? "ADT" : "CHD";

        return ` ${i18next.t("Pasajero")} ${props.dto.passenger.index + 1} - ${getPaxLabel(type)} `;
    };

    const mobilePassenger = useMobileBaggagePassenger({
        canAdd,
        dto: props.dto,
        hasSelected: currentStatePassenger?.hasSelected,
        isOpen: currentStatePassenger?.isOpen,
        paxTypeLabel: paxTypeLabel(),
        showError,
        onAdd: (e) => handleAdd(e),
        onRemove: (e) => handleRemove(e),
        onRemoveAll: (e) => handleRemoveAll(e),
    });

    const desktopPassenger = useDesktopBaggagePassenger({
        canAdd,
        dto: props.dto,
        paxTypeLabel: paxTypeLabel(),
        showError,
        onAdd: (e) => handleAdd(e),
        onRemove: (e) => handleRemove(e),
        onRemoveAll: (e) => handleRemoveAll(e),
    });

    const moveOnToNextPax = useCallback(
        (isRemoveAll: boolean) => {
            if (props.dto.bagType !== "cabinBaggage" && !isRemoveAll) return;

            const numberOfPax = paxAmount(props.dto.baggageSection.sectionUi.uiState);
            const canMoveOnInSameJourney = props.dto.passenger.index < numberOfPax - 1;

            props.dto.baggageSection.sectionUi.closePax({
                journeyIndices: [props.dto.journey.index],
                paxIndices: [props.dto.passenger.index],
            });

            const journeyToOpen = canMoveOnInSameJourney
                ? props.dto.journey.index
                : props.dto.journey.index === OUTBOUND && props.dto.baggageSection.journeys.length > 1
                  ? INBOUND
                  : undefined;

            if (journeyToOpen !== undefined) {
                props.dto.baggageSection.sectionUi.openPax({
                    journeyIndices: [journeyToOpen],
                    paxIndices: [canMoveOnInSameJourney ? props.dto.passenger.index + 1 : 0],
                });
            }
        },
        [
            props.dto.baggageSection.journeys,
            props.dto.baggageSection.sectionUi.closePax,
            props.dto.baggageSection.sectionUi.openPax,
            props.dto.baggageSection.sectionUi.uiState,
            props.dto.bagType,
            props.dto.journey.index,
            props.dto.passenger.index,
        ],
    );

    const handleRemoveAll = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        await props.dto.baggageSection.reset({
            paxIndices: [props.dto.passenger.index],
            journeyIndices: [props.dto.journey.index],
        });
        moveOnToNextPax(true);
    };

    const handleRemove = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        await props.dto.baggageSection.remove({
            paxIndices: [props.dto.passenger.index],
            journeyIndices: [props.dto.journey.index],
        });
        moveOnToNextPax(false);
    };

    const handleAdd = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        await props.dto.baggageSection.add({
            paxIndices: [props.dto.passenger.index],
            journeyIndices: [props.dto.journey.index],
        });
        moveOnToNextPax(false);
    };

    return !props.dto.passenger.isSoldOut
        ? html` ${props.dto.isMobile ? mobilePassenger.htmlTemplate() : desktopPassenger.htmlTemplate()} `
        : html``;
};
