import { useRef } from "haunted";
import { html } from "lit-html";
import { useEffect } from "../../shared/haunted/CustomHooks";
import { CLASS_NAMES } from "../../shared/classNames";
import { ACTION_NAMES } from "../../shared/commonConstants";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { ref } from "../../directives/ref";
import { SpaSection } from "../../component-models/spa/SpaSection";
import { useFlowContext } from "../../managers/useFlowContext";
import { SpaSectionType } from "./spa-container";
import { BookingSteps } from "../../shared/BookingSteps";
import classNames from "classnames";
import { useReduxState } from "../../shared/redux/useReduxState";
import { SpaContent } from "../../component-models/spa/SpaContent";
import { ScrollHelper } from "../../shared/ScrollHelper";

export interface Props {
    bookingFlowStep: BookingSteps;
    content: SpaContent;
    showCheckinBanner: boolean;
    type: SpaSectionType;
    onClick: () => void;
}

export const useSpaSection = (props: Props): SpaSection => {
    const flowContext = useFlowContext();

    const [activeSection] = useReduxState("spa.activeSection");
    const [isLoading] = useReduxState("spa.isLoading");
    const [displayedSections] = useReduxState("spa.displayedSections");

    const content = useRef<HTMLDivElement>(undefined);

    const scrollToContent = () => {
        const showBackendErrors =
            DomCrawlingHelper.getElemByClass(document.body, CLASS_NAMES.BackendErrorsContanier) &&
            window.location.href.toLowerCase().indexOf(ACTION_NAMES.PASSENGERS) > -1;

        if (showBackendErrors) return;

        window.setTimeout(() => {
            ScrollHelper.scrollToElementAndHideNav({ element: content.current, yOffset: 80 });
        }, 200);
    };

    const handleClick = (e: MouseEvent) => {
        if (activeSection === props.type || flowContext.isCheckinFlow) return true;

        e.preventDefault();
        e.stopPropagation();

        props.onClick();

        return false;
    };

    useEffect(() => {
        if (activeSection === props.type) window.setTimeout(() => scrollToContent(), 200);
    }, [activeSection]);

    const htmlTemplate = () => {
        const isDisplayed = displayedSections?.includes(props.type);

        const isIntro = activeSection === props.type && isLoading;
        const isVisible = isDisplayed && !isIntro;

        return html`
            <div
                ref=${ref(content)}
                class=${classNames("spa-content", {
                    "content-intro": isIntro,
                    "hidden": !isDisplayed,
                    "passed-content": isDisplayed && activeSection !== props.type,
                })}
                @click=${handleClick}
            >
                ${isVisible ? props.content.htmlTemplate() : ""}
            </div>
        `;
    };

    return {
        bookingFlowStep: props.bookingFlowStep,
        content: props.content,
        isLoading,
        type: props.type,
        htmlTemplate,
        scrollToContent,
    };
};
