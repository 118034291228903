import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { PetInCabinJourneyModel } from "../../../../component-models/extras/pet-in-cabin/PetInCabinJourneyModel";
import { PetInCabinModel } from "../../../../component-models/extras/pet-in-cabin/PetInCabinModel";
import i18next from "i18next";
import { html } from "lit-html";
import { desktopJourneyRoutesTemplate, journeyRouteTemplate } from "../../../../common-templates/ExtrasCommonTemplates";
import { LOADER_CLASS_NAMES } from "../../../../shared/LoaderClassNames";
import { ApiExtrasSpaSectionViewModel } from "../../../../component-models/spa/ExtrasSpaSectionViewModel";
import classNames from "classnames";
import { PetInCabinJourneyPassengerModel } from "../../../../component-models/extras/pet-in-cabin/PetInCabinJourneyPassengerModel";

export interface Props {
    model: PetInCabinModel;
    termsUrl: string;
    setExtrasModel: (model: ApiExtrasSpaSectionViewModel) => void;
}

export const usePetInCabin = (props: Props) => {
    const headerTemplate = () => html`
        <header>
            <i class="js-pet js-icon title-icon"></i>
            <div class="title">
                <h2 class="main-title" data-test-id=${T.EXTRAS_PET_IN_CABIN.TITLE}>
                    ${i18next.t("V2-PetInCabinTitle")}
                </h2>
                <div class="subtitle" data-test-id=${T.EXTRAS_PET_IN_CABIN.SUBTITLE}>
                    ${i18next.t("V2-PetInCabinInfo")}
                </div>
            </div>
        </header>
    `;

    const journeyTemplate = (journey: PetInCabinJourneyModel) => html`
        <div
            class=${classNames("col-xs-1 col-sm-1-2", {
                "col-sm-offset-1-4": props.model?.Journeys.length === 1,
            })}
            data-test-id=${getTestId(T.EXTRAS_PET_IN_CABIN.JOURNEY, { j: journey.JourneyIndex })}
        >
            <div class="inner-box bottom-half padded-bottom-half ts-error-parent ts-error-container">
                <h3 class="package-title visible-xs">${journeyRouteTemplate(journey)}</h3>

                ${passengersTemplate(journey)}
            </div>
        </div>
    `;

    const journeysTemplate = () => props.model?.Journeys.map(journeyTemplate);

    const passengerTemplate = (journey: PetInCabinJourneyModel, passenger: PetInCabinJourneyPassengerModel) => html`
        <ac-pet-in-cabin-passenger
            .journey=${journey}
            .passengerOption=${passenger}
            .setExtrasModel=${props.setExtrasModel}
        ></ac-pet-in-cabin-passenger>
    `;

    const passengersTemplate = (journey: PetInCabinJourneyModel) =>
        journey.Passengers.map((pax) => passengerTemplate(journey, pax));

    const footerTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <a
                    class="pet-terms-link"
                    href=${props.termsUrl}
                    target="_blank"
                    data-test-id=${T.EXTRAS_PET_IN_CABIN.TERMS_LINK}
                >
                    ${i18next.t("PetTermsLink")}
                </a>
            </div>
        </div>
    `;

    const htmlTemplate = () => html`
        <section
            class="booking-wrapper extras-step ts-pet-in-cabin ts-error-container ${LOADER_CLASS_NAMES.Pet}"
            data-test-id=${T.EXTRAS_PET_IN_CABIN.CONTAINER}
        >
            ${headerTemplate()}

            <div class="row hidden-xs">${desktopJourneyRoutesTemplate(props.model?.Journeys)}</div>
            <div class="row">${journeysTemplate()}</div>
            ${footerTemplate()}
        </section>
    `;

    return { htmlTemplate };
};
