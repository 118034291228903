import { html } from "lit-html";
import { useModal } from "../shared/useModal";
import i18next from "i18next";

export const useNotEnoughChileCompraFundsModal = () => {
    const htmlTemplate = () =>
        html` <div class="relative flex min-w-80 flex-col items-center p-3">
            <div
                class="absolute right-2 top-2 flex h-8 w-8 cursor-pointer select-none appearance-none items-center justify-center rounded-full border-none bg-cug-dark-gray pb-px text-2xl leading-none text-white hover:bg-brand-secondary hover:text-white"
                @click=${modal.close}
            >
                &times;
            </div>
            <i class="js-new js-n-no-balance text-[90px] text-brand-tertiary"></i>
            <div class="mt-4 font-emphasis text-xl text-be-blue-2">${i18next.t("Saldo Insuficiente")}</div>
            <div class="mt-1 max-w-80 text-base text-be-blue-2">
                ${i18next.t(
                    "No tienes saldo disponible para completar tu compra. Por favor, contáctate con nosotros para agregar saldo.",
                )}
            </div>
            <a class="rounded-secondary-btn mt-6" href="mailto:soporte.chilecompra@jetsmart.com">
                ${i18next.t("Contactar")}
            </a>
        </div>`;

    const modal = useModal({
        content: { template: htmlTemplate },
    });

    return modal;
};
