import i18next from "i18next";
import { validateDni } from "../../../form-validation";
import { InputElement } from "../useForm";
import { InputFieldAttribute } from "./../InputFieldAttribute";

export const dni = (): InputFieldAttribute => ({
    name: "dni",
    validators: [
        {
            errorMessage: {
                id: "Dni",
                message: i18next.t("DNI invalido"),
                scope: "field",
            },

            validate: (field: InputElement) => {
                const culture =
                    window.JetSmart.AppContext?.culture.toLowerCase() ||
                    window.JetSmart.Cug2AppContext?.culture.toLowerCase();
                const result = validateDni(field.value, culture);

                return Promise.resolve(result);
            },
        },
    ],
});
