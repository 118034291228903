import { FlightContext } from "../../../component-models/flight/contexts/FlightContext";
import { FlightOptionModel } from "../../../component-models/flight/FlightOptionModel";
import { FlightPageBundleState } from "../../../component-models/flight/contexts/FlightPageBundleState";
import { FlightPageFlightState } from "../../../component-models/flight/contexts/FlightPageFlightState";
import { FlightPageViewModel } from "../../../component-models/flight/FlightPageViewModel";
import { useFlightTealiumManager } from "../../../managers/Tealium/useFlightTealiumManager";
import { clone } from "../../../shared/common";
import { INBOUND, OUTBOUND } from "../../../shared/commonConstants";
import { useEffect, useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { SelectedFlightFeeType } from "../flight-page";
import { mapToFlightOption } from "../../../component-mappers/FlightOptionMappers";
import { useFlightHttpContextHandler } from "../useFlightHttpContextHandler";
import { useCallback } from "haunted";

export interface FlightContextProps {
    model: FlightPageViewModel;
    onChange: () => void;
}

export const useFlightContext = (props: FlightContextProps): FlightContext => {
    const tealiumManager = useFlightTealiumManager();

    const [state, setState] = useState<FlightPageFlightState>(undefined);

    const { getPreselectedFlightFeeType, getPreselectedSellKeys } = useFlightHttpContextHandler({ model: props.model });

    const areAllFlightsSelected = useMemo(
        () =>
            Boolean(
                state?.selectedOutboundFlight &&
                    (state?.selectedInboundFlight || props.model.FlightDataViewModel.IsOneWay),
            ),
        [state?.selectedInboundFlight, state?.selectedOutboundFlight, props.model.FlightDataViewModel.IsOneWay],
    );

    const init = () => {
        const newState: FlightPageFlightState = {
            selectedFeeType: props.model.DiscountClubViewModel.IsDcEligible ? "Club" : undefined,
            selectedInboundFlight: undefined,
            selectedOutboundFlight: undefined,
        };

        const selectedFlightFeeType = getPreselectedFlightFeeType();

        const { selectedOutboundFlight, selectedInboundFlight } = preselectFlights();

        newState.selectedFeeType = selectedFlightFeeType;
        newState.selectedOutboundFlight = selectedOutboundFlight;
        newState.selectedInboundFlight = selectedInboundFlight;

        setState(newState);
    };

    const preselectFlight = (data: { journeyIndex: number; sellKey: string }): FlightOptionModel => {
        if (!data.sellKey) return undefined;

        const tripModel = props.model.Journeys.find((journey) => journey.TripIndex === data.journeyIndex);

        // DEVNOTE The sell keys are changing when we change the currency, that is why we substr
        const flight = tripModel?.FlightDetails.find(
            (fd) =>
                fd.FaresPerProductClass.xyz.Fare.DiscountedFare.FlightFareKey.substr(-45) === data.sellKey.substr(-45),
        );

        let flightOption;

        if (flight) {
            // DEVNOTE Index is not 0, but it is no problem, as there will be no id for this, it is not displayed.
            flightOption = mapToFlightOption(flight, 0, tripModel);
        }

        return flightOption;
    };

    const preselectFlights = () => {
        let [outboundSellKey, inboundSellKey] = getPreselectedSellKeys();

        const outboundFlightOption = preselectFlight({
            journeyIndex: OUTBOUND,
            sellKey: outboundSellKey,
        });
        const inboundFlightOption = preselectFlight({
            journeyIndex: INBOUND,
            sellKey: inboundSellKey,
        });

        let selectedOutboundFlight;
        let selectedInboundFlight;

        if (outboundFlightOption) {
            selectedOutboundFlight = clone(outboundFlightOption);
            // DEVNOTE The sell keys are changing when we change the currency, that is why we need this magic
            outboundSellKey = outboundFlightOption.SellKey;
        }

        if (inboundFlightOption) {
            selectedInboundFlight = clone(inboundFlightOption);
            // DEVNOTE The sell keys are changing when we change the currency, that is why we need this magic
            inboundSellKey = inboundFlightOption.SellKey;
        }

        return { selectedOutboundFlight, selectedInboundFlight };
    };

    const selectFlight = async (data: {
        bundleState: FlightPageBundleState;
        feeType: SelectedFlightFeeType;
        flightOption: FlightOptionModel;
    }) => {
        if (!data.flightOption) return;

        const newState = clone(state);

        if (data.flightOption.JourneyIndex === OUTBOUND) {
            newState.selectedOutboundFlight = clone(data.flightOption);

            await  tealiumManager.logOutboundFlightSelected(
                data.bundleState,
                data.feeType === "Smart" ? "N" : "D",
                data.flightOption,
                newState,
            );
        } else {
            newState.selectedInboundFlight = clone(data.flightOption);
            await tealiumManager.logInboundFlightSelected(
                data.bundleState,
                data.feeType === "Smart" ? "N" : "D",
                data.flightOption,
                data.feeType,
            );
        }

        newState.selectedFeeType = data.feeType;

        setState(newState);
    };

    const selectFlightFeeType = (type: SelectedFlightFeeType) => {
        const newState = clone(state);
        newState.selectedFeeType = type;
        setState(newState);
    };

    const reset = (journeyIndex: number) => {
        const newState = clone(state);

        if (journeyIndex === OUTBOUND) {
            newState.selectedOutboundFlight = undefined;
        } else {
            newState.selectedInboundFlight = undefined;
        }

        setState(newState);
    };

    const isThisFeeSelected = useCallback(
        (flightOption: FlightOptionModel) => {
            if (!state) return false;

            return flightOption.JourneyIndex === OUTBOUND
                ? state.selectedOutboundFlight && state.selectedOutboundFlight.SellKey === flightOption.SellKey
                : state.selectedInboundFlight && state.selectedInboundFlight.SellKey === flightOption.SellKey;
        },
        [state, state?.selectedInboundFlight, state?.selectedOutboundFlight],
    );

    const showFlightSelectError = (journeyIndex: number, isValidated: boolean) =>
        isValidated && (journeyIndex === OUTBOUND ? !state.selectedOutboundFlight : !state.selectedInboundFlight);

    useEffect(props.onChange, [
        state?.selectedFeeType,
        state?.selectedInboundFlight?.SellKey,
        state?.selectedOutboundFlight?.SellKey,
    ]);

    useEffect(init, []);

    return {
        areAllFlightsSelected,
        state,
        isThisFeeSelected,
        reset,
        selectFlight,
        selectFlightFeeType,
        showFlightSelectError,
    };
};
