import i18next from "i18next";
import { html } from "lit-html";
import { PAID_CABIN_MOBILE_IMG } from "../../spa-container";
import { baggageIllustrations } from "../common/baggageIllustrations";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";

export interface Props {
    formattedNextPrice: string;
    formattedOriginalPrice: string;
    isDiscountBooking: boolean;
    isInBundle: boolean;
    unformattedNextPrice: number;
    unformattedOriginalPrice: number;
}

export const perJourneyPerPaxPaidCabin = (props: Props) => {
    const { backpackTooltipTemplate, regularBagTooltipTemplate } = baggageIllustrations();

    const cabinIncludedTemplate = () => (props.isInBundle ? i18next.t("V2-Included") : "");

    const cabinPriceTemplate = () =>
        !props.isInBundle && props.unformattedNextPrice >= 0
            ? html`
                  ${i18next.t("Desde")}
                  <span
                      class="b2-amount"
                      data-test-value=${props.unformattedNextPrice}
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_MINIMUM_PRICE, {
                          c: "cabinBaggage",
                          m: true,
                      })}
                  >
                      ${props.formattedNextPrice}
                  </span>
              `
            : "";

    const minimumOriginalCabinPriceTemplate = () =>
        props.isDiscountBooking && props.unformattedOriginalPrice >= 0
            ? html`
                  <div class="b2-per-booking-next-price reduced">
                      <span
                          class="line-through"
                          data-test-value=${props.unformattedOriginalPrice}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_CROSSED_OUT_MINIMUM_PRICE, {
                              c: "cabinBaggage",
                              m: true,
                          })}
                      >
                          ${props.formattedOriginalPrice}
                      </span>
                  </div>
              `
            : "";

    const cabinBagPricesTemplate = () => html`
        ${minimumOriginalCabinPriceTemplate()}
        <div class="b2-per-booking-next-price">${cabinPriceTemplate()} ${cabinIncludedTemplate()}</div>
    `;

    const htmlTemplate = () => html`
        <div class="b2m-per-pax-section">
            <div class="b2-illustration-ribbon">${i18next.t("¡El más vendido!")}</div>
            <div class="">
                <div>
                    <img class="b2-paid-cabin-img-mb" src=${PAID_CABIN_MOBILE_IMG} />
                </div>
                <div class="weight-container">
                    <div class="b2m-weight">10kg.</div>
                    <div class="b2m-weight">10kg.</div>
                </div>
                <div class="b2m-tooltips backpack-and-bag-tooltips">
                    ${backpackTooltipTemplate()} ${regularBagTooltipTemplate()}
                </div>
            </div>
            <div class="b2m-bag-name">${i18next.t("Mochila o artículo personal y Equipaje de mano")}</div>
            ${cabinBagPricesTemplate()}
        </div>
    `;

    return { htmlTemplate };
};
