import { useCug2AppContext } from "../../managers/useCug2AppContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { PortalUserType } from "./usePortalSectionContent";

export const useSpaUserType = () => {
    const cug2AppContext = useCug2AppContext();
    const [userContext] = useReduxState("userContext");

    // EXPORTS

    const getUserType = (): PortalUserType => {
        if (userContext?.peruCompra.role !== "none") return "PeruCompra";

        if (userContext?.chileCompra.role !== "none") return "ChileCompra";

        if (cug2AppContext?.Type === "agency") return "Agency";

        return "Company";
    };

    return { getUserType };
};
