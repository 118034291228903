import { useCallback } from "haunted";
import { ApiBaggageViewModel } from "../../../../component-models/baggage/ApiBaggageViewModel";
import { BaggagePageState } from "../../../../component-models/baggage/BaggagePageState";
import BookingData from "../../../../shared/BookingData";
import { CLASS_NAMES } from "../../../../shared/classNames";
import DomCrawlingHelper from "../../../../shared/DomCrawlingHelper";
import { pageClass } from "../useBaggagePage";
import { useBookingManager } from "../../../../managers/useBookingManager";
import { BaggageSectionUiState } from "../../../../component-models/baggage/BaggageSectionUi";
import { BaggageSectionStateJourneyPassenger } from "../../../../component-models/baggage/BaggageSectionStateJourneyPassenger";
import { BaggageSectionJourney } from "../../../../component-models/baggage/BaggageSectionJourney";

export interface Props {
    baggageSectionJourneys: BaggageSectionJourney[];
    uiState: BaggageSectionUiState;
    setModel: (model: ApiBaggageViewModel) => void;
    setPageState: (updater: (newState: BaggagePageState) => BaggagePageState) => void;
}

export const useBaggageAjaxHandler = (props: Props) => {
    const bookingManager = useBookingManager();

    const getSsrKeysForResetPax = useCallback(
        (passengers: BaggageSectionStateJourneyPassenger[], journeyIndex: number) =>
            passengers.reduce((paxSsrKeys, passenger) => {
                const baggage = props.baggageSectionJourneys
                    .find((j) => j.index === journeyIndex)
                    .passengers.find((p) => p.index === passenger.index);

                return paxSsrKeys.concat([...Array(baggage.quantity - baggage.min)].map((_) => baggage.ssrRemoveKey));
            }, []),
        [props.baggageSectionJourneys],
    );

    const getSsrKeysForAddOrRemove = useCallback(
        (passengers: BaggageSectionStateJourneyPassenger[], journeyIndex: number, type: "add" | "remove") =>
            passengers.map((passenger): string => {
                const baggage = props.baggageSectionJourneys
                    .find((j) => j.index === journeyIndex)
                    .passengers.find((p) => p.index === passenger.index);

                return type === "add" ? baggage.ssrAddKey : baggage.ssrRemoveKey;
            }),
        [props.baggageSectionJourneys],
    );

    const getSsrKeys = useCallback(
        (journeyIndices: number[], paxIndices: number[], type: "add" | "remove" | "reset") => {
            const journeys = props.uiState.journeys.filter((journey) => journeyIndices.includes(journey.index));
            const ssrKeys = journeys.reduce((journeySsrKeys, journey) => {
                const passengers = journey.passengers.filter((passenger) => paxIndices.includes(passenger.index));

                return journeySsrKeys.concat(
                    type === "reset"
                        ? getSsrKeysForResetPax(passengers, journey.index)
                        : getSsrKeysForAddOrRemove(passengers, journey.index, type),
                );
            }, []);

            return ssrKeys.filter((key) => key);
        },
        [props.uiState.journeys, getSsrKeysForResetPax, getSsrKeysForAddOrRemove],
    );

    // EXPORTS

    const sendSsrRequest = useCallback(
        async (journeyIndices: number[], paxIndices: number[], type: "add" | "remove" | "reset") => {
            const ssrKeys = getSsrKeys(journeyIndices, paxIndices, type);

            if (ssrKeys.length > 0) {
                const parentElement = DomCrawlingHelper.getElemByClass(document.body, pageClass);
                const body = ssrKeys.reduce(
                    (bodyObj, key, i) => {
                        bodyObj[`selectedJourneySsrs[${i}]`] = key;
                        return bodyObj;
                    },
                    {} as { [key: string]: string },
                );

                const container = DomCrawlingHelper.getElemByClass(parentElement, CLASS_NAMES.errorContainer);
                props.setPageState((currentState: BaggagePageState) => ({ ...currentState, isLoading: true }));
                const response = await bookingManager.postSsrUpdate<{
                    BookingSummary: BookingData;
                    BaggageModel: ApiBaggageViewModel;
                }>(body, container, undefined);

                if (response?.BaggageModel) {
                    props.setModel(response.BaggageModel);
                }

                props.setPageState((currentState: BaggagePageState) => ({ ...currentState, isLoading: false }));
            }
        },
        [getSsrKeys, props.setModel, props.setPageState],
    );

    return { sendSsrRequest };
};
