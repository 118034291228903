import { ApiSidebarViewModel } from "../../component-models/sidebar/ApiSidebarViewModel";
import { ROUTES } from "../../shared/apiRoutes";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";

export const useSidebarAjaxHandler = () => {
    const { ajaxJsonRequest } = useAjax();

    const reloadSidebarViewModel = async (loader: JsLoader, nonCancellable = false, url?: string) => {
        const result = await ajaxJsonRequest<ApiSidebarViewModel>({
            loader,
            method: "GET",
            noCors: true,
            nonCancellable,
            url: url ? url : ROUTES.PriceBreakdown,
        });

        return result?.data;
    };

    return { reloadSidebarViewModel };
};
