import { useCompactDatepicker } from "../../ui/useCompactDatepicker/useCompactDatepicker";
import { CheckinPaxOxyChangeEvent } from "../../../component-models/checkin/CheckinEvents";
import { useEffect, useMemo } from "../../../shared/haunted/CustomHooks";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { html } from "haunted";
import i18next from "i18next";
import { SelectOption, ageDictionary, groupBy, mapCountryCallingCodeListToSelectOptions } from "../../../shared/common";
import dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { useSpecialAssistance } from "../../spa/passengers/useSpecialAssistance";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { ApiAdditionalInfoViewModel } from "../../../component-models/checkin/ApiAdditionalInfoViewModel";
import { ApiSpecialAssistanceSsrModel } from "../../../component-models/passengers/ApiSpecialAssistanceSsrModel";
import { PassengerUpdateEvent } from "../../spa/passengers/usePassengersPage";
import { PassengerField } from "../../spa/passengers/passengerFormFactory";
import { FluentValidatorMethodsPartial } from "../../../validator/Validation";
import { useAppContext } from "../../../managers/useAppContext";
import { useWindowSize } from "../../../shared/customHooks/useWindowSize";
import { PassengerFormVM } from "../../../component-models/passengers/PassengerFormVM";
import { FAKE_COUNTRY_CODE } from "../../../shared/commonConstants";

export const name = "ac-checkin-passenger-document";

export interface Props {
    arrivalDate: dayjs.Dayjs;
    departureDate: dayjs.Dayjs;
    formFields: PassengerField[];
    isDocNumberUnique: boolean;
    isFrequentFlyerNumberUnique: boolean;
    model: ApiAdditionalInfoViewModel;
    passenger: PassengerFormVM;
    rootElem: HTMLDivElement;
    validator: FluentValidatorMethodsPartial;
    handleChange: (e: PassengerUpdateEvent) => void;
    onDocumentBlur: () => void;
    onFrequentFlyerBlur: () => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        arrivalDate: host.arrivalDate,
        departureDate: host.departureDate,
        formFields: host.formFields,
        isDocNumberUnique: host.isDocNumberUnique,
        isFrequentFlyerNumberUnique: host.isFrequentFlyerNumberUnique,
        model: host.model,
        passenger: host.passenger,
        rootElem: host.rootElem,
        validator: host.validator,
        handleChange: host.handleChange,
        onDocumentBlur: host.onDocumentBlur,
        onFrequentFlyerBlur: host.onFrequentFlyerBlur,
    };

    const appContext = useAppContext();

    const { width } = useWindowSize();

    // DEVNOTE see JET-10352, subtract max years and adjust the date by almost a year (364 days) + 1 day
    const minDate = useMemo(
        () =>
            dayjs(props.arrivalDate).subtract(ageDictionary.get(props.passenger.type).max, "year").subtract(363, "day"),
        [],
    );
    const maxDate = useMemo(
        () => dayjs(props.departureDate).subtract(ageDictionary.get(props.passenger.type).min, "year"),
        [],
    );

    const getFieldName = (fieldName: keyof PassengerFormVM) => `passengers.${props.passenger.uniqueIndex}.${fieldName}`;

    const birthDate = useCompactDatepicker({
        disabled: props.formFields.find((field) => field.key === "dateOfBirth").isReadonly(props.passenger),
        index: props.passenger.uniqueIndex,
        isInvalid: !props.validator.isFieldValid(getFieldName("dateOfBirth")),
        label: i18next.t("DateOfBirthLabel"),
        maxDate,
        minDate,
        type: "date-of-birth",
        value: props.passenger.dateOfBirth,
        onChange: (value) =>
            props.handleChange({ passengerUniqueIndex: props.passenger.uniqueIndex, key: "dateOfBirth", value }),
    });

    const expiryDate = useCompactDatepicker({
        firstYearIsDefault: true,
        index: props.passenger.uniqueIndex,
        isInvalid: !props.validator.isFieldValid(getFieldName("documentExpiry")),
        label: i18next.t("ExpirationLabel"),
        maxDate: dayjs().add(15, "year"),
        minDate: props.departureDate,
        type: "expiration",
        value: props.passenger.documentExpiry,
        onChange: (value) =>
            props.handleChange({ passengerUniqueIndex: props.passenger.uniqueIndex, key: "documentExpiry", value }),
    });

    const getAssistanceOptionPaxNumber = (option: ApiSpecialAssistanceSsrModel) =>
        Number(option.SsrAddKeys[0].SsrKey.split("|")[0]);

    const assistanceOptions = () =>
        props.model.AssistanceOptions.filter(
            (o) => getAssistanceOptionPaxNumber(o) === props.passenger.passengerNumber,
        );

    const oxyOptions = () =>
        props.model.OxyOptions.filter((o) => getAssistanceOptionPaxNumber(o) === props.passenger.passengerNumber);

    const specialAssistance = useSpecialAssistance({
        assistanceOptions: assistanceOptions(),
        oxyOptions: oxyOptions(),
        passengerNumber: props.passenger.passengerNumber,
        passengerType: props.passenger.type,
        parentRoot: props.rootElem,
    });

    const orderedFilteredFields = useMemo(
        () => props.formFields.filter((field) => field.isShown(props.passenger)).sort((a, b) => a.index - b.index),
        [props.formFields],
    );

    const groups = useMemo(
        () =>
            groupBy(
                orderedFilteredFields.filter((field) => field.groupId),
                "groupId",
            ),
        [orderedFilteredFields],
    );

    const dispatchOxyChange = () => {
        host.dispatchEvent(
            new CheckinPaxOxyChangeEvent({
                hasOxy: specialAssistance.hasAnyOxy,
                paxNumber: props.passenger.passengerNumber,
            }),
        );
    };

    useEffect(dispatchOxyChange as () => void, [specialAssistance.hasAnyOxy]);

    const textInputTemplate = (field: PassengerField) => html`
        <div class="relative">
            <ac-input
                .errorMessage=${props.validator.getMessage(getFieldName(field.key))}
                .isDisabled=${field.isReadonly(props.passenger)}
                .isInvalid=${!props.validator.isFieldValid(getFieldName(field.key))}
                .isReadonly=${field.isReadonly(props.passenger)}
                .label=${field.label}
                .sanitizer=${field.sanitizer
                    ? (e: KeyboardEvent) => field.sanitizer(e, props.passenger.documentType)
                    : undefined}
                .value=${props.passenger[field.key]}
                .onBlur=${field.key === "documentNumber"
                    ? props.onDocumentBlur
                    : field.key === "frequentFlyerNumber"
                      ? props.onFrequentFlyerBlur
                      : undefined}
                .onInput=${(value: string) =>
                    !field.isReadonly(props.passenger) &&
                    props.handleChange({ passengerUniqueIndex: props.passenger.uniqueIndex, key: field.key, value })}
            ></ac-input>
            ${field.tooltip ? html`<div class="absolute right-3 top-4">${field.tooltip()}</div>` : ""}
        </div>
    `;

    const selectTemplate = (field: PassengerField, options: SelectOption[]) => html`
        <div>
            <ac-select
                .errorMessage=${props.validator.getMessage(getFieldName(field.key))}
                .isDisabled=${field.isReadonly(props.passenger)}
                .isInvalid=${!props.validator.isFieldValid(getFieldName(field.key))}
                .label=${field.label}
                .options=${options}
                .onBlur=${field.key === "documentType" ? props.onDocumentBlur : undefined}
                .onSelect=${(value: string) =>
                    props.handleChange({
                        passengerUniqueIndex: props.passenger.uniqueIndex,
                        key: field.key,
                        value,
                    })}
            ></ac-select>
        </div>
    `;

    const documentTypeOptions: SelectOption[] = [
        {
            IsSelected: props.passenger.documentType === "N",
            Text: i18next.t("RUT"),
            Value: "N",
        },
        {
            IsSelected: props.passenger.documentType === "P",
            Text: i18next.t("Pasaporte"),
            Value: "P",
        },
        {
            IsSelected: props.passenger.documentType === "DNI",
            Text: i18next.t("DNI/C.I."),
            Value: "DNI",
        },
    ];

    const genderOptions: SelectOption[] = [
        {
            IsSelected: !props.passenger?.gender,
            Text: "",
            Value: "",
        },
        {
            IsSelected: props.passenger.gender?.toString() === "Male",
            Text: i18next.t("Masculino"),
            Value: "Male",
        },
        {
            IsSelected: props.passenger.gender?.toString() === "Female",
            Text: i18next.t("Femenino"),
            Value: "Female",
        },
        appContext.isFeatureSwitchActive("OtherGender")
            ? {
                  IsSelected: props.passenger.gender?.toString() === "Unknown",
                  Text: i18next.t("Otro"),
                  Value: "Unknown",
              }
            : undefined,
    ].filter((item) => item);

    const phonePrefixList = (selected: string) =>
        mapCountryCallingCodeListToSelectOptions(appContext.PhonePrefixes, appContext.Culture, selected);

    const nonUniqueDocumentNumberTemplate = () =>
        !props.isDocNumberUnique
            ? html`
                  <div
                      class="row"
                      data-test-id=${getTestId(T.PASSENGERS.DOC_UNIQUENESS_ERROR, { p: props.passenger.uniqueIndex })}
                  >
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${i18next.t("Passengers-UniquenessError")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const nonUniqueFrequentFlyerNumberTemplate = () =>
        !props.isFrequentFlyerNumberUnique
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${i18next.t("Passengers-UniquenessError")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const inputFieldTemplate = (field: PassengerField) => {
        if (field.type === "text") {
            return html`
                <div>
                    ${textInputTemplate(field)}
                    ${field.key === "documentNumber" ? nonUniqueDocumentNumberTemplate() : ""}
                    ${field.key === "frequentFlyerNumber" ? nonUniqueFrequentFlyerNumberTemplate() : ""}
                </div>
            `;
        }

        if (field.type === "date") {
            switch (field.key) {
                case "dateOfBirth":
                    return birthDate.htmlTemplate();
                case "documentExpiry":
                    return expiryDate.htmlTemplate();
                default:
                    return "";
            }
        }

        if (field.type === "select") {
            switch (field.key) {
                case "documentType":
                    return selectTemplate(field, documentTypeOptions);
                case "country":
                    return selectTemplate(field, [
                        {
                            IsSelected: !props.passenger.country,
                            Text: "",
                            Value: "",
                        },
                        ...appContext.Countries.map((item) => ({
                            ...item,
                            IsSelected: item.Value === props.passenger.country,
                        })),
                    ]);
                case "phonePrefix":
                    return selectTemplate(field, phonePrefixList(props.passenger.phonePrefix));
                case "addressCountry":
                    return selectTemplate(field, [
                        {
                            IsSelected: !props.passenger.addressCountry,
                            Text: "",
                            Value: "",
                        },
                        ...appContext.Countries.map((item) => ({
                            ...item,
                            IsSelected: item.Value === props.passenger.addressCountry,
                        })),
                    ]);
                case "documentIssuer":
                    return selectTemplate(field, [
                        {
                            IsSelected:
                                !props.passenger.documentIssuer || props.passenger.documentIssuer === FAKE_COUNTRY_CODE,
                            Text: "",
                            Value: "",
                        },
                        ...appContext.Countries.map((item) => ({
                            ...item,
                            IsSelected: item.Value === props.passenger.documentIssuer,
                        })),
                    ]);
                case "gender":
                    return selectTemplate(field, genderOptions);

                default:
                    return "";
            }
        }

        return "";
    };

    const desktopInputGroupTemplate = (group: PassengerField[]) => html`
        <div class="grid grid-cols-5 gap-4">
            ${group.map(
                (field) => html`
                    <div class="!p-0 md:first:col-span-2 md:last:col-span-3">${inputFieldTemplate(field)}</div>
                `,
            )}
        </div>
    `;

    const tabletInputGroupTemplate = (group: PassengerField[]) => html`
        <div class="col-span-2 grid grid-cols-2 gap-4 !p-0">${group.map((field) => inputFieldTemplate(field))}</div>
    `;

    const mobileInputGroupTemplate = (group: PassengerField[]) => group.map((field) => inputFieldTemplate(field));

    const inputGroupTemplate = (id: string) =>
        width < 768
            ? mobileInputGroupTemplate(groups[id])
            : width < 1024
              ? tabletInputGroupTemplate(groups[id])
              : desktopInputGroupTemplate(groups[id]);

    const inputFieldsTemplate = () => {
        const groupsDisplayed: Set<string> = new Set();

        return orderedFilteredFields.flatMap((field) => {
            if (groupsDisplayed.has(field.groupId)) return "";

            if (field.groupId) {
                groupsDisplayed.add(field.groupId);
                return inputGroupTemplate(field.groupId);
            }

            return inputFieldTemplate(field);
        });
    };

    return html`
        <div data-test-id=${getTestId(T.CHECKIN.PASSENGER_DOCUMENT, { p: props.passenger.passengerNumber })}>
            <div class="mt-2 flex flex-col gap-2 sm:mt-4 sm:grid sm:grid-cols-2 sm:gap-4">${inputFieldsTemplate()}</div>
            ${props.passenger.type !== "INF" ? html` ${specialAssistance.htmlTemplate()} ` : ""}
        </div>
    `;
};
