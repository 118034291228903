import i18next from "i18next";
import { html } from "haunted";
import { ROUTES } from "../../../shared/apiRoutes";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import PerfectScrollbar from "perfect-scrollbar";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { ItineraryPageViewModel } from "../../../component-models/itinerary/ItineraryPageViewModel";
import { useTransactionDetailsModal } from "./useTransactionDetailsModal";
import { useBookingContext } from "../../../managers/useBookingContext";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { spaSectionOrderFactory } from "../../spa/spaSectionOrderFactory";
import { useItineraryTransactionsNormalTable } from "./useItineraryTransactionsNormalTable";
import { itineraryTransactionsUtils } from "./itineraryTransactionsUtils";

export interface Props {
    model: ItineraryPageViewModel;
    togglePrintingTransaction: () => void;
}

export const useItineraryTabsTransactions = (props: Props) => {
    const bookingContext = useBookingContext();

    const { getSpaSectionOrder } = spaSectionOrderFactory();

    const [userContext] = useReduxState("userContext");

    const [scrollbar, setScrollbar] = useState<PerfectScrollbar>(undefined);

    const handleOpenPaymentDetails = (index: number) => {
        props.togglePrintingTransaction();
        setOpenedTransactionDetailsIndex(index);
        modal.open();
    };

    const transactionsTable = useItineraryTransactionsNormalTable({
        model: props.model,
        scrollBar: scrollbar,
        handleOpenPaymentDetails,
    });

    const [openedTransactionDetailsIndex, setOpenedTransactionDetailsIndex] = useState<number>(-1);

    const modal = useTransactionDetailsModal({
        farelockExpiry: props.model.FarelockExpiry,
        filename: "transaction-details",
        paymentResult:
            openedTransactionDetailsIndex >= 0
                ? props.model.ItineraryPayments[openedTransactionDetailsIndex].PaymentResult
                : undefined,
        tealiumProperties: props.model.TealiumProperties,
        showTransactionBox: props.model.ItineraryModelV2.ShowTransactionBox,
        onClose: () => {
            props.togglePrintingTransaction();
            setOpenedTransactionDetailsIndex(-1);
        },
    });

    const { visiblePaymentButtonType } = itineraryTransactionsUtils();
    // HELPERS

    const addScrollBar = () => {
        const x = ScrollHelper.getWindowWidth();
        if (x < 768) {
            const scrollBar = ScrollHelper.addPerfectScrollbar("i2-transaction-container", {
                wheelPropagation: false,
                wheelSpeed: 1,
                swipeEasing: true,
                suppressScrollX: false,
                useBothWheelAxes: true,
            })[0];
            setScrollbar(scrollBar);
        }
    };

    // EVENT HANDLERS

    const handlePayClick = () => {
        window.location.href = ROUTES.PagePayment;
    };

    const handlePayFarelockFullClick = () => {
        const order = getSpaSectionOrder({
            flowType: "FarelockRoundTwo",
            hasBundle: props.model.ItineraryModelV2.Journeys.some((journey) => journey.BundleType !== "None"),
            isStaff: userContext.isStaff || bookingContext.isStaffBooking,
            isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
            isChileCompraBookingForNonChileCompra:
                bookingContext.isChileCompraBooking && userContext.chileCompra.role !== "supervisor",
            chileCompraRole: userContext.chileCompra.role,
        });
        const firstPageIndex = Math.min(...order.map((x) => x.index));

        window.location.href = `/${order.find((o) => o.index === firstPageIndex).section.uri}`;
    };

    useEffect(addScrollBar, []);

    // TEMPLATES

    const payButtonTemplate = () =>
        visiblePaymentButtonType(props.model) === "Pay" && !bookingContext.isChileCompraBooking
            ? html`
                  <div class="m-4 flex items-center justify-end">
                      <a @click=${handlePayClick} class="rounded-primary-btn">${i18next.t("Pagar")}</a>
                  </div>
              `
            : "";

    const payFarelockFullAmountButtonTemplate = () =>
        visiblePaymentButtonType(props.model) === "PayFarelockFull"
            ? html`
                  <div class="m-4 flex items-center justify-end">
                      <a @click=${handlePayFarelockFullClick} class="rounded-primary-btn"
                          >${i18next.t("Pagar monto total")}</a
                      >
                  </div>
              `
            : "";

    const gdsOnHoldWarningMessageTemplate = () =>
        props.model.IsGdsOnHold && bookingContext.isGdsBooking
            ? html`
                  <div
                      class="mb-3.5 mt-7 w-full bg-brand-primary px-5 py-6 font-body text-sm text-white sm:mb-0 sm:rounded-xl sm:text-base"
                  >
                      <div>
                          <span class="font-bold">${i18next.t("Atención")}:</span>
                          ${unsafeHTML(
                              i18next.t("gds-itinerary-warning-line-one {{-spanStart}} {{-spanEnd}}", {
                                  spanStart: "<span class='underline'>",
                                  spanEnd: "</span>",
                              }),
                          )}
                      </div>
                      <div class="mt-4">${i18next.t("gds-itinerary-warning-line-two")}</div>
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        <div class="no-print">
            <div class="i2-tab-title">
                <i class="js-icon-it js-it-money-list"></i>
                <div>${i18next.t("Transacciones")}</div>
            </div>
            <div class="i2-transaction-container">${transactionsTable.htmlTemplate()}</div>
            ${gdsOnHoldWarningMessageTemplate()} ${payButtonTemplate()} ${payFarelockFullAmountButtonTemplate()}
        </div>
        ${modal.htmlTemplate()}
    `;

    return { htmlTemplate };
};
