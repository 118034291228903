import { html, useRef } from "haunted";
import { ApiPortalSpaViewModel } from "../../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import { useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { CugUser } from "../../../component-models/CUG2b/CugUserData";
import { User } from "../../../component-models/CUG2b/User";
import { GridState, PageChangeEvent, SortChangeEvent } from "../../../dc-components/dc-table-models";
import { ref } from "../../../directives/ref";
import classNames from "classnames";
import i18next from "i18next";
import { handleCugLoader } from "../../../shared/common";
import { usePortalUsersUtils } from "./usePortalUsersUtils";
import { getStringForCulture } from "../../../component-helpers/stringHelper";
import { useSaveSuccessModal } from "./useSaveSuccessModal";
import { useChangeStatusModal } from "./useChangeStatusModal";
import { useResetPasswordModal } from "./useResetPasswordModal";

export interface Props {
    model: ApiPortalSpaViewModel;
}

export const useCugUsersPage = (props: Props) => {
    const cug2AppContext = useCug2AppContext();

    const { loadData, getFilteredUsers, getUsersInOnePage, getRoles } = usePortalUsersUtils();

    const initialGridState = useMemo<GridState<keyof CugUser>>(
        () => ({
            pageIndex: 0,
            appliedFilters: [],
            pageSize: 10,
            orderBy: "LastName",
            orderDir: "desc",
            globalFilterExpression: "",
        }),
        [],
    );

    const availableRoles = useMemo(
        () => getRoles(props.model.UsersViewModel.AvailableRoles),
        [props.model.UsersViewModel.AvailableRoles],
    );

    const root = useRef<HTMLDivElement>(undefined);
    const grid = useRef<HTMLDivElement>(undefined);

    const [gridState, setGridState] = useState<GridState<keyof CugUser>>(initialGridState);
    const [data, setData] = useState<CugUser[]>(undefined);

    const [userToEdit, setUserToEdit] = useState<User>(undefined);
    const [userToChangeStatus, setUserToChangeStatus] = useState<CugUser>(undefined);
    const [userToResetPassword, setUserToResetPassword] = useState<CugUser>(undefined);

    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);

    // HELPERS

    const updateDataState = async () => {
        handleCugLoader(grid.current, "loadData");

        const { newData } = await loadData(gridState, root.current);

        setData(newData);

        handleCugLoader(grid.current, "loadData");
    };

    const init = () => {
        if (props.model.ProfileViewModel.PeruCompraData) return;

        void updateDataState();
    };

    // EVENT-HANDLERS

    const onPageChange = (e: PageChangeEvent) => {
        setGridState({
            ...gridState,
            pageIndex: e.detail.selectedPageIndex,
            pageSize: e.detail.selectedPageSize,
        });
    };

    const onSortChange = (e: SortChangeEvent) => {
        setGridState({
            ...gridState,
            orderBy: e.detail.orderBy as keyof CugUser,
            orderDir: e.detail.orderDir,
        });
    };

    // COMPONENT

    const successModal = useSaveSuccessModal({
        isOpen: isSuccessModalOpen,
        setIsOpen: setIsSuccessModalOpen,
    });

    const changeStatusModal = useChangeStatusModal({
        rootElement: root.current,
        gridElement: grid.current,
        userToChangeStatus,
        updateDataState,
        setUserToChangeStatus,
    });

    const resetPasswordModal = useResetPasswordModal({
        rootElement: root.current,
        gridElement: grid.current,
        userToResetPassword,
        setUserToResetPassword,
        updateDataState,
    });

    // TEMPLATES

    const headerTemplate = () => html`
        <div class="cug2b-title-container">
            <i class="js-icon-cug js-cug-group"></i>
            <div>
                <h1>${i18next.t("Usuarios")}</h1>
                <h2>${i18next.t("Agrega y edita aquí todos los usuarios de tu empresa o agencia")}</h2>
            </div>
        </div>
    `;

    const usersGridTemplate = () => html`
        <ac-cug-users-grid
            .availableRoles=${availableRoles}
            .canAdd=${props.model.UsersViewModel.CanAdd}
            .currentUser=${props.model.UsersViewModel.CurrentUser}
            .data=${getUsersInOnePage(gridState, data)}
            .gridState=${gridState}
            .totalItemCount=${getFilteredUsers(gridState, data)?.length}
            .userToEdit=${userToEdit}
            .rootElement=${root.current}
            .gridElement=${grid.current}
            .setGridState=${setGridState}
            .setUserToEdit=${setUserToEdit}
            .setUserToChangeStatus=${setUserToChangeStatus}
            .setUserToResetPassword=${setUserToResetPassword}
            .setIsSuccessModalOpen=${setIsSuccessModalOpen}
            .updateDataState=${updateDataState}
            @close=${setUserToEdit(undefined)}
            @onSortChange=${onSortChange}
            @onPageChange=${onPageChange}
        ></ac-cug-users-grid>
    `;

    const userRolesInfoTemplate = () => html`
        <div class="cug2b-users-info">
            <h1>${i18next.t("Conoce aquí el detalle de las tareas que puede realizar cada rol de la organización")}</h1>
            <div>
                <input type="checkbox" id="agent" />
                <label for="agent">${i18next.t("Agente")}</label>
                <p>
                    ${getStringForCulture(
                        cug2AppContext.Cug2BSettings.RoleDescriptions.AgentDescription,
                        cug2AppContext.Culture,
                    )}
                </p>
            </div>
            <div>
                <input type="checkbox" id="supervisor" />
                <label for="supervisor">${i18next.t("Supervisor")}</label>
                <p>
                    ${getStringForCulture(
                        cug2AppContext.Cug2BSettings.RoleDescriptions.SupervisorDescription,
                        cug2AppContext.Culture,
                    )}
                </p>
            </div>
            <div>
                <input type="checkbox" id="admin" />
                <label for="admin">${i18next.t("Administrador")}</label>
                <p>
                    ${getStringForCulture(
                        cug2AppContext.Cug2BSettings.RoleDescriptions.AdministratorDescription,
                        cug2AppContext.Culture,
                    )}
                </p>
            </div>
        </div>
    `;

    const htmlTemplate = () => html`
        <div
            ref=${ref(grid)}
            class=${classNames({
                "min-h-1/4 w-full": !data,
            })}
        >
            ${headerTemplate()} ${usersGridTemplate()}
        </div>
        ${userRolesInfoTemplate()} ${changeStatusModal.htmlTemplate()} ${resetPasswordModal.htmlTemplate()}
        ${successModal.htmlTemplate()}
    `;

    return { init, htmlTemplate };
};
