import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import {
    ItineraryPax,
    RedemptionPaxFareAndFees,
    RedemptionPaxFareOrFee,
} from "../../../component-models/itinerary/ApiItineraryViewModelV2";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { ItineraryPageViewModel } from "../../../component-models/itinerary/ItineraryPageViewModel";
import { useBookingContext } from "../../../managers/useBookingContext";
import classNames from "classnames";
import { itineraryDetailsUtils } from "./itineraryDetailsHelper";
import { useItineraryDetailsPaxFaresAndFees } from "./useItineraryDetailsPaxFaresAndFees";
import { useItineraryDetailsPaxSsrs } from "./useItineraryDetailsPaxSsrs";
import { useFlowContext } from "../../../managers/useFlowContext";
import { unsafeHTML } from "lit-html/directives/unsafe-html";

export interface Props {
    model: ItineraryPageViewModel;
    isMobile: boolean;
}

export const useItineraryTabsRedemptionDetails = (props: Props) => {
    const accordionContentClassName = "i2-details-accordion-content";
    const scrollbarName = "itinerary-details-scrollbar";

    // HELPERS

    const init = () => {
        if (!window.acPerfectScrollbars) {
            window.acPerfectScrollbars = {};
        }

        window.acPerfectScrollbars[scrollbarName] = ScrollHelper.addPerfectScrollbar(accordionContentClassName) as any;
    };

    const updateScrollbar = () => {
        if (window.acPerfectScrollbars[scrollbarName]) {
            window.acPerfectScrollbars[scrollbarName].forEach((scroller) => {
                scroller.update();
            });
        }
    };

    // EVENT HANDLERS

    const handleChangePassenger = (paxIndex: number) => {
        const newIndex = paxIndex === openDetailsAccordion ? undefined : paxIndex;
        setOpenDetailsAccordion(newIndex);
        setOpenBreakdownTab("fares");
    };

    // COMPONENT

    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();
    const [openDetailsAccordion, setOpenDetailsAccordion] = useState<number>(0);
    const [openBreakdownTab, setOpenBreakdownTab] = useState<"fares" | "extras">("fares");

    const paxFaresAndFeesNormal = useItineraryDetailsPaxFaresAndFees({
        isMobile: props.isMobile,
    });

    const paxSsrs = useItineraryDetailsPaxSsrs({
        journeys: props.model.ItineraryModelV2.Journeys,
        isMobile: props.isMobile,
    });

    const { orderedPassengers } = itineraryDetailsUtils();

    useEffect(updateScrollbar, [openDetailsAccordion, openBreakdownTab]);

    useEffect(init, []);

    // TEMPLATES

    const paxHeaderTemplate = (pax: ItineraryPax, i: number) => {
        const headerClassMap = classMap({
            "i2-details-accordion-header": true,
            "open": i === openDetailsAccordion,
        });

        return html`
            <div
                class=${headerClassMap}
                data-test-id=${getTestId(T.ITINERARY.DETAILS_ACCORDION_PER_PAX, {
                    p: pax.PassengerNumber,
                    m: props.isMobile,
                })}
                @click=${() => handleChangePassenger(i)}
            >
                <div class="flex flex-col items-start sm:flex-row sm:items-center">
                    <span class="i2-details-pax-header-number">${i18next.t("Pasajero")} ${i + 1}</span>
                    <span
                        class="i2-details-pax-header-name"
                        data-test-id=${getTestId(T.ITINERARY.DETAILS_PAX_NAME, {
                            p: pax.PassengerNumber,
                            m: props.isMobile,
                        })}
                    >
                        ${pax.FirstName} ${pax.LastName}
                    </span>
                </div>
                <div class="i2-details-pax-header-total">
                    <i class="js-icon js-circle-chevron-right"></i>
                </div>
            </div>
        `;
    };

    const paxContentRedemptionTitleTemplate = (pax: ItineraryPax) =>
        html`<div
            class="-mx-10 flex h-[32px] items-center justify-between border-y border-solid border-be-gray-16 bg-be-light-gray px-10 text-[11px] text-brand-secondary sm:h-[36px] md:h-[45px] md:text-[15px] lg:h-[60px] lg:text-[19px]"
        >
            <span class="font-semibold"
                >${unsafeHTML(
                    i18next.t("Total AAdvantage miles {{-reg}}", {
                        reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
                    }),
                )}</span
            >
            <span class="font-extrabold">${pax.MilesTotal} ${i18next.t("Miles")}</span>
        </div>`;

    const paxFaresAndFeesRedemptionTemplate = (pax: ItineraryPax) =>
        pax.RedemptionFaresAndTaxes?.map((fareGroup) =>
            paxFareAndFeeGroupRedemptionTemplate(fareGroup, pax.PassengerNumber),
        ) || "";

    const paxFareAndFeeGroupRedemptionTemplate = (fareGroup: RedemptionPaxFareAndFees, paxNumber: number) => html`
        <div class=${classNames("i2-fare-group", { "miles-fare-group": flowContext.isAaPostBooking })}>
            <span data-test-id=${getTestId(T.ITINERARY.BUNDLE_NAME_PER_PAX, { p: paxNumber, m: props.isMobile })}>
                ${fareGroup.GroupScreenName ? fareGroup.GroupScreenName : i18next.t("Tarifa aérea")}
            </span>
            <span
                data-test-id=${getTestId(T.ITINERARY.BUNDLE_PRICE_PER_PAX, { p: paxNumber, m: props.isMobile })}
                data-test-value=${fareGroup.GroupMiles.toString()}
            >
                ${fareGroup.GroupMiles} ${i18next.t("Miles")}
            </span>
        </div>
        ${paxFareAndFeeItemsRedemptionTemplate(fareGroup)}
    `;

    const paxFareAndFeeItemsRedemptionTemplate = (fareGroup: RedemptionPaxFareAndFees) =>
        fareGroup.Items?.length > 1 ? fareGroup.Items.map((item) => paxFareAndFeeItemRedemptionTemplate(item)) : "";

    const paxFareAndFeeItemRedemptionValueTemplate = (item: RedemptionPaxFareOrFee) =>
        item.Miles
            ? html` <span>${item.Miles} ${i18next.t("Miles")}</span> `
            : html`<span>${i18next.t("V2-Included")}</span>`;

    const paxFareAndFeeItemRedemptionTemplate = (item: RedemptionPaxFareOrFee) => html`
        <div class=${classNames("i2-fare-item", { "miles-fare-item": bookingContext })}>
            <span>${item.ScreenName || item.ChargeCode}</span>
            ${item.Note ? html` <span>${item.Note}</span> ` : paxFareAndFeeItemRedemptionValueTemplate(item)}
        </div>
    `;

    const paxContentRedemptionTemplate = (pax: ItineraryPax) =>
        html` ${paxContentRedemptionTitleTemplate(pax)} ${paxFaresAndFeesRedemptionTemplate(pax)}`;

    const paxContentNormalTitleTemplate = (pax: ItineraryPax) =>
        html` <div
            class=${classNames(
                "-mx-10 flex h-[32px] items-center justify-between border-solid border-be-gray-16 bg-be-light-gray px-10 text-[11px] text-brand-secondary sm:h-[36px] md:h-[45px] md:text-[15px] lg:h-[60px] lg:text-[19px]",
                { "border-y": pax.Type !== "INFANT", "border-b": pax.Type === "INFANT" },
            )}
        >
            <span class="font-semibold">${i18next.t("Total")}</span>
            <span
                class="font-extrabold"
                data-test-value=${pax.Total.toString()}
                data-test-id=${getTestId(T.ITINERARY.TOTAL_PRICE_PER_PAX, {
                    p: pax.PassengerNumber,
                    m: props.isMobile,
                })}
            >
                ${pax.FormattedTotal}</span
            >
        </div>`;

    const paxContentNormalItemsTemplate = (pax: ItineraryPax) =>
        pax.Type !== "INFANT"
            ? html` <div class="i2-details-pane-title redemption-details-title mt-5">${i18next.t("Viaje")}</div>
                  ${paxFaresAndFeesNormal.htmlTemplate(pax)}
                  <div class="i2-fare-total miles-fare-total">
                      <span>${i18next.t("Total")}</span>
                      <span>${i18next.t(pax.FormattedFaresAndTaxesTotal)}</span>
                  </div>
                  <div class="i2-details-pane-title redemption-details-title mt-6 md:mt-8">
                      ${i18next.t("Opcionales")}
                  </div>
                  ${paxSsrs.htmlTemplate(pax)}
                  <div class="flex justify-between">
                      <div class="i2-ssr-total redemption-details-ssr-total">
                          <span>${i18next.t("Total")}</span>
                          <span>${pax.FormattedOptionalsTotal || "$0"}</span>
                      </div>
                  </div>`
            : "";

    const paxContentNormalTemplate = (pax: ItineraryPax) => html`
        <div class=${classNames({ "mt-0": pax.Type === "INFANT", "mt-[10px] md:mt-5": pax.Type !== "INFANT" })}>
            ${paxContentNormalTitleTemplate(pax)} ${paxContentNormalItemsTemplate(pax)}
        </div>
    `;

    const paxContentTemplate = (pax: ItineraryPax, i: number) => {
        const contentClassMap = classMap({
            [accordionContentClassName]: true,
            "i2-redemption-details": true,
            "last": i === props.model.ItineraryModelV2.Passengers.length - 1,
        });

        return html`<div class=${contentClassMap}>
            <div class="flex w-full flex-col">
                ${paxContentRedemptionTemplate(pax)} ${paxContentNormalTemplate(pax)}
            </div>
        </div> `;
    };

    const detailsPassengerTemplate = (pax: ItineraryPax, i: number) => html`
        ${paxHeaderTemplate(pax, i)} ${paxContentTemplate(pax, i)}
    `;

    const htmlTemplate = () => html`
        <div class="no-print">
            <div class="i2-tab-title">
                <i class="js-icon-it js-it-money"></i>
                <div>${i18next.t("Detalle de tu reserva")}</div>
            </div>
            ${orderedPassengers(props.model).map((pax, i) => detailsPassengerTemplate(pax, i))}
        </div>
    `;

    return { htmlTemplate };
};
