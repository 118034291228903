import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import i18next from "i18next";
import { html } from "lit-html";
import { FlightPageContext } from "../../../component-models/flight/contexts/FlightPageContext";
import classNames from "classnames";

export interface Props {
    context: FlightPageContext;
    isPerLeg: boolean;
}

export const useFarelockSummary = (props: Props) => {
    // EVENT LISTENERS

    const handleReset = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        await props.context.removeFarelock();
    };

    // TEMPLATES

    const buttonTemplate = () => html`
        <button class="flpls-cancel-button" data-test-id=${T.FARELOCK.BUTTON_NO} @click=${handleReset}>
            ${i18next.t("V2-Remove")}
            <i class="js-icon js-circle-chevron-right"></i>
        </button>
    `;

    const iconTemplate = () => {
        const tempClassNames = classNames("js-icon hidden-xs", {
            "js-fare-lock-24": props.context.farelockState.selectedType === "Duration24",
            "js-fare-lock-48": props.context.farelockState.selectedType === "Duration48",
        });

        return html` <i class=${tempClassNames} data-test-id=${T.FARELOCK.SELECTED_TIME}></i> `;
    };

    const summaryInfoTemplate = () =>
        unsafeHTML(
            i18next.t("FareLockPerBookingSummaryInfo {{-code1}}{{-code2}}", {
                code1: "<span>",
                code2: "</span>",
            }),
        );

    const htmlTemplate = () => html`
        <div class="fare-lock-per-leg-summary" data-test-id=${T.FARELOCK.SUMMARY_CONTAINER}>
            <i class="js-icon js-fare-lock" data-test-id=${T.FARELOCK.ICON}></i>
            <div class="fare-lock-per-leg-info" data-test-id=${T.FARELOCK.INFO}>
                ${summaryInfoTemplate()}
                <span
                    class="mobile-fare-lock-time hidden-sm-up"
                    data-test-id=${getTestId(T.FARELOCK.SELECTED_TIME, { m: true })}
                >
                    ${props.context.farelockState.selectedType === "Duration24" ? "24" : "48"}
                    ${i18next.t("FareLockHours")}.
                </span>
            </div>
            ${iconTemplate()} ${buttonTemplate()}
        </div>
    `;

    return { htmlTemplate };
};
