import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { html } from "lit-html";
import { CurrentSeatings } from "../../../component-models/seatmap/CurrentSeatings";
import { ExtendedApiSeat } from "../../../component-models/seatmap/ApiSeat";
import { SeatRecommendator } from "../../../component-models/seatmap/SeatRecommendator";
import { OUTBOUND, INBOUND } from "../../../shared/commonConstants";
import { areAllPlanesIdentical } from "../../../shared/common";
import { useState } from "../../../shared/haunted/CustomHooks";
import { useReduxState } from "../../../shared/redux/useReduxState";

export interface Props {
    currentSeatings: CurrentSeatings;
    oneSegmentsOnly: boolean;
    planeTypes: string[];
    visibleRecommendator: () => SeatRecommendator;
    dispatchScrollToSeatmap: () => void;
}

export const useReturnSeatRecommendator = (props: Props) => {
    // TODO CFS Only works with one segment

    const [userContext] = useReduxState("userContext");

    const hasSelectedOutboundSeat = (seats: ExtendedApiSeat[]) =>
        seats?.some((seat) => seat.JourneyIndex === OUTBOUND && seat.PassengerNumber >= 0);

    const hasSelectedInboundSeat = (seats: ExtendedApiSeat[]) =>
        seats?.some((seat) => seat.JourneyIndex === INBOUND && seat.PassengerNumber >= 0);

    // TODO CFS Only works with one segment
    const areMatchingInboundSeatsAvailable = (seats: ExtendedApiSeat[]) =>
        seats
            .filter((seat) => seat.JourneyIndex === OUTBOUND && seat.PassengerNumber >= 0)
            .every(
                (outboundSeat) =>
                    seats.find(
                        (seat) =>
                            seat.JourneyIndex === INBOUND &&
                            seat.Column === outboundSeat.Column &&
                            seat.Row === outboundSeat.Row,
                    )?.IsAvailableByPax[outboundSeat.PassengerNumber],
            );

    const offerSameSeatsOnReturnJourney = (seats: ExtendedApiSeat[]) => {
        const canGetReturnSeats =
            // TODO CFS Only works with one segment
            props.oneSegmentsOnly &&
            userContext.chileCompra.role === "none" &&
            areAllPlanesIdentical(props.planeTypes) &&
            hasSelectedOutboundSeat(seats) &&
            !hasSelectedInboundSeat(seats) &&
            areMatchingInboundSeatsAvailable(seats) &&
            !props.visibleRecommendator().isRecommendatorOn;

        if (!canGetReturnSeats) {
            setShowAutoReturnSeatSelectionBubble(false);
            return;
        }

        setShowAutoReturnSeatSelectionBubble(true);
        props.dispatchScrollToSeatmap();
    };

    const getSameSeatsForReturnJourney = () => {
        props.currentSeatings.updateOnTakeSameForReturn();
        setShowAutoReturnSeatSelectionBubble(false);
    };

    const [showAutoReturnSeatSelectionBubble, setShowAutoReturnSeatSelectionBubble] = useState<boolean>(undefined);

    const htmlTemplate = () => {
        if (showAutoReturnSeatSelectionBubble !== undefined) {
            return html`<div
                data-test-id=${T.SEATMAP.SAME_RETURN_SEATS_SHOW_BUBBLE}
                ?data-show-bubble=${showAutoReturnSeatSelectionBubble}
            >
                ${showAutoReturnSeatSelectionBubble
                    ? html`
                          <div class="return-seats-balloon">
                              <span>${window.seatmapResources.sameSeatsText}</span>
                              <button
                                  class="rounded-primary-btn return-seats"
                                  data-test-id=${T.SEATMAP.SAME_RETURN_SEATS_BUTTON}
                                  @click=${getSameSeatsForReturnJourney}
                              >
                                  ${window.seatmapResources.yesText}
                              </button>
                              <button
                                  class="cancel-return-seats"
                                  data-test-id=${T.SEATMAP.SAME_RETURN_SEATS_CANCEL_BUTTON}
                                  @click=${() => setShowAutoReturnSeatSelectionBubble(false)}
                              >
                                  &times;
                              </button>
                          </div>
                      `
                    : ""}
            </div>`;
        } else {
            return "";
        }
    };
    return { htmlTemplate, offerSameSeatsOnReturnJourney, showAutoReturnSeatSelectionBubble };
};
