import { html } from "lit-html";
import { useModal } from "../../shared/useModal";
import { FlightPageContext } from "../../../component-models/flight/contexts/FlightPageContext";
import { useFlightJourneySummary } from "../../shared/flight-journey-summary/useFlightJourneySummary";
import { useRef } from "haunted";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import PerfectScrollbar from "perfect-scrollbar";
import { ref } from "../../../directives/ref";
import { ApiSidebarViewModel } from "../../../component-models/sidebar/ApiSidebarViewModel";
import { useFlightPageAjaxHandler } from "../useFlightPageAjaxHandler";
import { showLoader } from "../../../shared/common";
import { useReduxState } from "../../../shared/redux/useReduxState";
import i18next from "i18next";
import { useBreakdown } from "../../sidebar/useBreakdown";
import { mapToChileCompraSummaryModalJourneys } from "../../../component-mappers/ChileCompraMappers";
import { useNumberFormatter } from "../../../shared/useNumberFormatter";

const LOADER_CLASS = "chile-compra-summary-loader-container";

export interface Props {
    context: FlightPageContext;
}

export const useChileCompraSummaryModal = (props: Props) => {
    const { formatNumber } = useNumberFormatter();

    const [selectedCurrency] = useReduxState("booking.currency");

    const { reloadFlightPageSidebar } = useFlightPageAjaxHandler();

    const [scrollbar, setScrollbar] = useState<PerfectScrollbar>(undefined);
    const [sidebarModel, setSidebarModel] = useState<ApiSidebarViewModel>(undefined);

    const scroller = useRef<HTMLDivElement>(null);

    const { outboundJourney, inboundJourney } = mapToChileCompraSummaryModalJourneys(props.context);

    const summaryOutbound = useFlightJourneySummary({
        journey: outboundJourney,
        showBundleImage: true,
        showPill: true,
        showTimeChange: false,
    });

    const summaryInbound = useFlightJourneySummary({
        journey: inboundJourney,
        showBundleImage: true,
        showPill: true,
        showTimeChange: false,
    });

    const uncommittedChapterBreakdown = useBreakdown({
        chapter: sidebarModel?.BreakdownModel.UncommittedChapter,
        model: sidebarModel?.BreakdownModel,
        nonInfantPaxNumber: sidebarModel?.NonInfantPaxNumber,
        paxNumber: sidebarModel?.PaxNumber,
    });

    const initScrollbar = () => {
        if (!modal.isOpen) return;

        if (scrollbar) scrollbar.destroy();

        setScrollbar(
            new PerfectScrollbar(scroller.current, {
                wheelPropagation: false,
                wheelSpeed: 2,
                swipeEasing: true,
                suppressScrollX: true,
            }),
        );
    };

    const initSidebarModel = async () => {
        if (!modal.isOpen) return;

        const loader = showLoader({ name: LOADER_CLASS });
        const result = await reloadFlightPageSidebar(loader, {
            bundleState: props.context.bundleState,
            dcState: props.context.dcState,
            flightState: props.context.flightState,
            selectedCurrency,
        });

        setSidebarModel(result);
    };

    const closeButtonTemplate = () => html`
        <button
            class="absolute right-4 top-4 m-0 flex h-[30px] w-[30px] items-center justify-center rounded-full border-none bg-[#484848] p-0 text-2xl text-white"
            @click=${() => modal.close()}
        >
            &times;
        </button>
    `;

    const headerTemplate = () => html`
        <div class="relative flex h-[72px] items-center px-2 font-emphasis text-xl text-be-blue-2 shadow-lg sm:px-4">
            <i class="js-new js-n-broken-circle-writing mr-2 text-[40px] text-be-blue-2 sm:mr-4"></i>
            ${i18next.t("Detalles de la reserva")} ${closeButtonTemplate()}
        </div>
    `;

    const flightsHeaderTemplate = () => html`
        <div
            class="flex h-12 items-center border-b border-solid border-[#a1a1a1] px-2 text-xl font-bold sm:h-14 sm:px-4"
        >
            <i class="js-new js-n-broken-circle-plane mr-2 text-[40px] text-be-blue-2 sm:mr-4"></i> ${i18next.t("Vuelos")}
        </div>
    `;

    const outboundFlightTemplate = () => html` <div>${summaryOutbound.htmlTemplate()}</div>`;

    const inboundFlightTemplate = () =>
        props.context.flightState.selectedInboundFlight
            ? html`
                  <div class="mt-8 w-full border-t-2 border-dotted border-brand-secondary pt-8">
                      ${summaryInbound.htmlTemplate()}
                  </div>
              `
            : "";

    const flightTemplate = () => html`
        ${flightsHeaderTemplate()}
        <div class="px-0 py-2 sm:px-6 sm:py-4">${outboundFlightTemplate()} ${inboundFlightTemplate()}</div>
    `;

    const passengerNumberTemplate = () => html`
        <p
            class="mb-4 w-full border-b-2 border-dotted border-brand-secondary pb-4 text-sm font-black leading-[26px] text-be-blue-2"
        >
            <i class="far fa-user"></i> ${i18next.t("PriceDisplay-PassengersSectionTitle")}: ${sidebarModel?.PaxNumber}
        </p>
    `;

    const breakdownTemplate = () => html`
        ${passengerNumberTemplate()}
        <div>${uncommittedChapterBreakdown.normalBreakdownHtmlTemplate()}</div>
    `;

    const bodyTemplate = () => html`
        <div
            ref=${ref(scroller)}
            class="relative h-[420px] max-h-[75vh] overflow-hidden overflow-y-scroll p-4 sm:p-6 md:overflow-hidden"
        >
            <div class="mt-0 rounded-md border border-solid border-[#a1a1a1]">${flightTemplate()}</div>
            <div class="mt-6 rounded-md border border-solid border-[#a1a1a1] px-6 py-4">${breakdownTemplate()}</div>
        </div>
    `;

    const footerTemplate = () => html`
        <div
            class="relative flex h-28 flex-col justify-center px-4 sm:h-[72px] sm:flex-row sm:items-center sm:justify-between"
        >
            <div class="mb-4 text-base text-be-blue-2 sm:mb-0 sm:font-news sm:text-xl">
                ${i18next.t("Total a pagar")}:
                <span class="font-bold sm:font-emphasis sm:font-normal">
                    ${formatNumber({
                        amount: sidebarModel?.QuotedTotal,
                        currency: sidebarModel?.Currency,
                        omitSpace: true,
                    })}
                </span>
            </div>
            <button class="rounded-primary-btn no-default-icon w-full sm:w-auto" @click=${modal.close}>
                ${i18next.t("Editar cotización")}<i class="js-new js-n-pen-edit absolute right-2 vertical-center"></i>
            </button>
        </div>
    `;

    const htmlTemplate = () =>
        html`<div class=${LOADER_CLASS}>${headerTemplate()} ${bodyTemplate()} ${footerTemplate()}</div>`;

    const modal = useModal({
        closing: {
            isClosable: true,
            buttonClassNames: "hidden",
        },
        content: { classNames: "chile-compra-summary", template: htmlTemplate },
    });

    useEffect(initScrollbar, [modal.isOpen]);
    useEffect(initSidebarModel, [modal.isOpen]);

    return modal;
};
