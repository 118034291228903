import { BRASILIAN_CULTURE_CODE, PaxType } from "../../../shared/commonConstants";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { ApiSpecialAssistanceSsrModel } from "../../../component-models/passengers/ApiSpecialAssistanceSsrModel";
import { areArraysTheSame } from "../../../shared/common";
import { CLASS_NAMES } from "../../../shared/classNames";
import DomCrawlingHelper from "../../../shared/DomCrawlingHelper";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { useBookingManager } from "../../../managers/useBookingManager";
import { useBookingBlockedModal } from "./useBookingBlockedModal";
import { useAppContext } from "../../../managers/useAppContext";
import { usePubSub } from "../../../pub-sub-service/usePubSub";
import { useEffect as hauntedUseEffect } from "haunted";

export interface Properties {
    assistanceOptions: ApiSpecialAssistanceSsrModel[];
    isPassengerPageInitialized?: boolean;
    oxyOptions: ApiSpecialAssistanceSsrModel[];
    parentRoot: HTMLElement;
    passengerNumber: number;
    passengerType: PaxType;
}

export function useSpecialAssistance(props: Properties) {
    // HELPERS

    const prepareRequest = async (
        selectedOption: ApiSpecialAssistanceSsrModel,
        selectedSellKeys: string[],
        selectedSellKeysSetter: (newState: string[]) => void,
    ) => {
        setIsSsrLoading(true);

        const isRemoving = areArraysTheSame(selectedSellKeys, getKeys(selectedOption));
        const body = isRemoving
            ? mapToRequestBody(getDeleteKeys(getKeys(selectedOption)))
            : mapToRequestBody(getKeys(selectedOption).concat(selectedSellKeys ? getDeleteKeys(selectedSellKeys) : []));

        await bookingManager.postSpecialAssistanceSsrUpdate(
            body,
            DomCrawlingHelper.getElemByClass(props.parentRoot, CLASS_NAMES.errorContainer),
        );

        if (isRemoving) {
            selectedSellKeysSetter([]);
        } else {
            selectedSellKeysSetter(getKeys(selectedOption));
        }

        setIsSsrLoading(false);
    };

    const mapToRequestBody = (sellKeys: string[]) =>
        sellKeys.reduce(
            (body, sellKey, index) => ({ ...body, [`selectedJourneySsrs[${index}]`]: sellKey }),
            {} as { [key: string]: string },
        );

    const getDeleteKeys = (keys: string[]) => keys.map((key) => `${key}|XX`);

    const getKeys = (option: ApiSpecialAssistanceSsrModel) => option?.SsrAddKeys.map((item) => item.SsrKey);

    const isAccordionOpen = () =>
        [...props.assistanceOptions, ...props.oxyOptions].some((o) => o.IsSold || o.IsSoldPreviously);

    const removeAllSelections = async () => {
        if ([...selectedAssistanceKeys, ...selectedOxyKeys].length === 0) {
            return;
        }

        setIsSsrLoading(true);
        const body = mapToRequestBody(getDeleteKeys([...selectedOxyKeys, ...selectedAssistanceKeys]));

        await bookingManager.postSpecialAssistanceSsrUpdate(
            body,
            DomCrawlingHelper.getElemByClass(props.parentRoot, CLASS_NAMES.errorContainer),
        );

        setSelectedAssistanceKeys([]);
        setSelectedOxyKeys([]);

        setIsSsrLoading(false);
    };

    const checkBrasilReload = async () => {
        if (
            window.location.href.toLowerCase().includes(BRASILIAN_CULTURE_CODE) &&
            (selectedAssistanceKeys?.length > 0 || selectedOxyKeys?.length > 0)
        ) {
            await removeAllSelections();
            blockBookingModal.open();
        }
    };

    // COMPONENT

    const bookingManager = useBookingManager();
    const appContext = useAppContext();

    const { triggers } = usePubSub();

    const [isSsrLoading, setIsSsrLoading] = useState<boolean>(false);
    const [selectedAssistanceKeys, setSelectedAssistanceKeys] = useState<string[]>(
        getKeys(props.assistanceOptions.find((o) => o.IsSold || o.IsSoldPreviously)) || [],
    );
    const [selectedOxyKeys, setSelectedOxyKeys] = useState<string[]>(
        getKeys(props.oxyOptions.find((o) => o.IsSold || o.IsSoldPreviously)) || [],
    );

    const blockBookingModal = useBookingBlockedModal({
        onClose: () => (window.location.href = `${appContext.CorporateBaseUrl}/br/pt`),
    });

    const subscribeToResetAssistance = () => {
        const handler = triggers.passengers.resetSpecialAssistance.subscribe(removeAllSelections);

        return () => handler.unsubscribe();
    };

    hauntedUseEffect(subscribeToResetAssistance, []);

    useEffect(checkBrasilReload, []);

    // EVENT LISTENERS

    const handleCheckboxChange = async (selectedOption: ApiSpecialAssistanceSsrModel) =>
        prepareRequest(selectedOption, selectedAssistanceKeys, setSelectedAssistanceKeys);

    const handleOxyChange = async (selectedOption: ApiSpecialAssistanceSsrModel) =>
        prepareRequest(selectedOption, selectedOxyKeys, setSelectedOxyKeys);

    // TEMPLATES

    const asistanceOptionsTemplate = () =>
        props.assistanceOptions.map(
            (option) => html`
                <ac-assistance-option
                    .isChecked=${areArraysTheSame(selectedAssistanceKeys, getKeys(option))}
                    .isLoading=${isSsrLoading}
                    .option=${option}
                    .paxIndex=${props.passengerNumber}
                    .onBlockBooking=${() => blockBookingModal.open()}
                    @checkboxChange=${() => handleCheckboxChange(option)}
                ></ac-assistance-option>
            `,
        );

    const oxyOptionsTemplate = () =>
        props.oxyOptions.map(
            (option) => html`
                <ac-assistance-option
                    .isChecked=${areArraysTheSame(selectedOxyKeys, getKeys(option))}
                    .isLoading=${isSsrLoading}
                    .option=${option}
                    .paxIndex=${props.passengerNumber}
                    .onBlockBooking=${() => blockBookingModal.open()}
                    @checkboxChange=${() => handleOxyChange(option)}
                ></ac-assistance-option>
            `,
        );

    const id = `assistance-tab-${props.passengerNumber}`;

    const htmlTemplate = () => html`
        <div class="js-accordion mt-6">
            <div class="tab">
                <input id=${id} type="checkbox" tabindex="-1" ?checked=${isAccordionOpen()} />
                <label
                    for=${id}
                    class="special-assistance-accordion-label"
                    data-test-value=${isAccordionOpen() ? "1" : "0"}
                    data-test-id=${getTestId(T.PASSENGERS.ASSISTANCE_OPENER, { p: props.passengerNumber })}
                >
                    <span
                        class="label-inner"
                        data-test-id=${getTestId(T.PASSENGERS.ASSISTANCE_ACCORDION_LABEL, {
                            p: props.passengerNumber,
                        })}
                    >
                        ${i18next.t("V2-SpecialAssistanceLabel")}
                        <span class="js-wheelchair js-icon icon-spaced"></span>
                    </span>
                    <span
                        class="js-circle-chevron-right js-icon chevron-down special-pax float-right"
                        data-test-id=${getTestId(T.PASSENGERS.ASSISTANCE_ACCORDION_ICON, {
                            p: props.passengerNumber,
                        })}
                    ></span>
                </label>
                <div class="tab-content">${asistanceOptionsTemplate()} ${oxyOptionsTemplate()}</div>
            </div>
        </div>
        ${blockBookingModal.htmlTemplate()}
    `;

    return { htmlTemplate, removeAllSelections, hasAnyOxy: selectedOxyKeys.length > 0 };
}
