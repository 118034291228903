import { useEffect } from "../../shared/haunted/CustomHooks";
import { INBOUND, OUTBOUND } from "../../shared/commonConstants";
import { html } from "lit-html";
import dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import * as IsSameOrBefore from "dayjs/plugin/isSameOrBefore";
import * as IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(CustomParseFormat);
dayjs.extend(IsSameOrBefore);
dayjs.extend(IsSameOrAfter);
import i18next from "i18next";
import { StationSettings } from "../../component-models/StationSettings";
import { useRouteAndDateSelector } from "../route-and-date-selector/useRouteAndDateSelector";
import { FlightMovePageState } from "./flight-move-page";
import { classMap } from "lit-html/directives/class-map";
import { useAppContext } from "../../managers/useAppContext";

export interface DateSelectorProps {
    arrivalDate: dayjs.Dayjs;
    arrivalStationCode: string;
    canInboundJourneyBeMoved: boolean;
    canOutboundJourneyBeMoved: boolean;
    departureDate: dayjs.Dayjs;
    departureStationCode: string;
    moveBackInboundJourney: dayjs.Dayjs;
    moveBackOutboundJourney: dayjs.Dayjs;
    moveForwardInboundJourney: dayjs.Dayjs;
    moveForwardOutboundJourney: dayjs.Dayjs;
    numberOfJourneys: number;
    pageHistory: FlightMovePageState[];
    root: HTMLElement;
    selectedJourneys: number[];
    stationSettings: StationSettings;
    handleActivate: () => void;
    handleDateSubmit: (selectedOutboundDate: dayjs.Dayjs, selectedInboundDate: dayjs.Dayjs) => void;
}

export const useFlightMoveDateSelector = (props: DateSelectorProps) => {
    // DEVNOTE See JET-8871
    const maxOutboundDate = () =>
        props.arrivalDate && props.arrivalDate.isBefore(props.moveForwardOutboundJourney)
            ? props.arrivalDate.add(-1, "day")
            : props.moveForwardOutboundJourney;

    // DEVNOTE See JET-10265
    const minInboundDate = () =>
        props.departureDate && props.departureDate.isAfter(props.moveBackInboundJourney)
            ? props.departureDate.add(1, "day")
            : props.moveBackInboundJourney;

    const appContext = useAppContext();

    const routeAndDateSelector = useRouteAndDateSelector({
        culture: appContext.Culture,
        destination: props.arrivalStationCode,
        fixedInboundDate: !props.canInboundJourneyBeMoved || !props.selectedJourneys.includes(INBOUND),
        fixedOutboundDate: !props.canOutboundJourneyBeMoved || !props.selectedJourneys.includes(OUTBOUND),
        onChange: undefined,
        inboundDate: props.arrivalDate ? props.arrivalDate : undefined,
        isOneWay: props.numberOfJourneys === 1,
        maxInboundDate: props.moveForwardInboundJourney,
        maxOutboundDate: maxOutboundDate(),
        minInboundDate: minInboundDate(),
        minOutboundDate: props.moveBackOutboundJourney,
        origin: props.departureStationCode,
        outboundDate: props.departureDate ? props.departureDate : undefined,
        sameCitiesOnly: true,
        stationSettings: props.stationSettings,
        useDatepickerCaptions: true,
    });

    const isEnabled = () => props.pageHistory[props.pageHistory.length - 1] === "DateSelect";

    // FIXME This should not be needed, prop changes should be detected automatically. They aren't...
    useEffect(() => {
        if (
            !isEnabled() &&
            (!routeAndDateSelector.selectedOutboundDate?.isSame(props.departureDate, "day") ||
                (props.arrivalDate && !routeAndDateSelector.selectedInboundDate.isSame(props.arrivalDate, "day")))
        ) {
            routeAndDateSelector.reset();
        }
    }, [isEnabled()]);

    const handleSectionClick = (e: MouseEvent) => {
        if (!isEnabled()) {
            e.preventDefault();
            e.stopPropagation();

            props.handleActivate();
        }
    };

    const buttonTemplate = () => {
        const isDisabled = props.selectedJourneys.length === 0 || !isEnabled();
        const onClick = () =>
            props.handleDateSubmit(routeAndDateSelector.selectedOutboundDate, routeAndDateSelector.selectedInboundDate);

        return html`
            <div class="flight-move-flight-selector-buttons">
                <button
                    class="rounded-primary-btn ${!isEnabled() ? "disabled" : ""}"
                    data-test-id="flight-move-date-submit"
                    ?disabled=${isDisabled}
                    @click=${onClick}
                >
                    ${i18next.t("Siguiente")}
                </button>
            </div>
        `;
    };

    const mainClassMap = classMap({
        "booking-wrapper": true,
        "flight-move-page": true,
        "active": isEnabled(),
        "inactive": !isEnabled(),
    });

    const htmlTemplate = () =>
        props.pageHistory.includes("DateSelect")
            ? html`
                  <section class=${mainClassMap} @click=${handleSectionClick}>
                      <header class="b2-section-header">
                          <div class="flex items-center">
                              <span class="b2-section-number">2</span>
                              <div>
                                  <h3>${i18next.t("Selección de nuevas fechas")}</h3>
                                  <h4>${i18next.t("Por favor selecciona las nuevas fechas de vuelo:")}</h4>
                              </div>
                          </div>
                      </header>
                      <div class="flight-move-route-date-selector">${routeAndDateSelector.htmlTemplate}</div>
                  </section>
                  ${buttonTemplate()}
              `
            : "";

    return {
        disabledInboundDates: routeAndDateSelector.disabledInboundDates,
        disabledOutboundDates: routeAndDateSelector.disabledOutboundDates,
        htmlTemplate,
        maxInboundDate: routeAndDateSelector.maxInboundDate,
        maxOutboundDate:
            !props.selectedJourneys || props.selectedJourneys.includes(INBOUND) || !props.arrivalDate
                ? props.moveForwardOutboundJourney
                : props.arrivalDate,
        minInboundDate: routeAndDateSelector.minInboundDate,
        minOutboundDate: routeAndDateSelector.minOutboundDate,
        selectedDestination: routeAndDateSelector.selectedDestination,
        selectedInboundDate: routeAndDateSelector.selectedInboundDate,
        selectedOrigin: routeAndDateSelector.selectedOrigin,
        selectedOutboundDate: routeAndDateSelector.selectedOutboundDate,
        setSelectedInboundDate: routeAndDateSelector.setSelectedInboundDate,
        setSelectedOutboundDate: routeAndDateSelector.setSelectedOutboundDate,
    };
};
