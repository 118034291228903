import { InputFieldAttribute } from "./../../../shared/customHooks/useForm/InputFieldAttribute";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { useRef } from "haunted";
import { ref } from "../../../directives/ref";
import {
    SelectOption,
    getRequestBodyFromNamedInputs,
    getTaxnumberCodeByCountry,
    handleCugLoader,
    isKeyNumeric,
    mapCountryCallingCodeListToHtmlOptions,
    sanitizePhoneNumber,
    sanitizeRutFieldValue,
    showLoader,
    updateMdl,
} from "../../../shared/common";
import { normalizeCulture } from "../../../shared/localeHelper";
import { commonValidationRules } from "../../../shared/commonValidationRules";
import { LOADER_CLASS_NAMES } from "../../../shared/LoaderClassNames";
import { ROUTES } from "../../../shared/apiRoutes";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import DomCrawlingHelper from "../../../shared/DomCrawlingHelper";
import { sanitizeInputFieldValue } from "../../../component-helpers/InputSanitizerHelper";
import { InputElement, useForm } from "../../../shared/customHooks/useForm/useForm";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { unconditionalRut } from "../../../shared/customHooks/useForm/custom-attributes/unconditionalRut";
import { CugUserPhoneType, USER_ROLE_CODE } from "../../../shared/commonConstants";
import { classMap } from "lit-html/directives/class-map";
import PerfectScrollbar from "perfect-scrollbar";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { User } from "../../../component-models/CUG2b/User";
import { dni } from "../../../shared/customHooks/useForm/custom-attributes/dni";

export const name = "ac-cug-add-user-modal";

const rootClass = "cug2b-add-user-modal-root";

export interface Properties {
    availableRoles: SelectOption[];
    gridElement: HTMLElement;
    rootElement: HTMLElement;
    setIsAddingUser: (isAddingUser: boolean) => void;
    setUserToEdit: (user: User) => void;
    updateDataState: () => Promise<void>;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        availableRoles: host.availableRoles,
        gridElement: host.gridElement,
        rootElement: host.rootElement,
        setIsAddingUser: host.setIsAddingUser,
        setUserToEdit: host.setUserToEdit,
        updateDataState: host.updateDataState,
    };

    // HELPERS

    const initScrollbar = () => {
        if (scrollbar) {
            scrollbar.destroy();
        }

        setScrollbar(
            new PerfectScrollbar(scroller.current, {
                wheelPropagation: false,
                wheelSpeed: 2,
                swipeEasing: true,
                suppressScrollX: true,
            }),
        );
    };

    const isPeruCompraRolePreselected = (role: SelectOption) => role.Value === USER_ROLE_CODE.PCRA_DELEGADO;

    const getInitialPhonePrefix = () =>
        cug2AppContext.PhonePrefixes.find((p) => p.CountryCode === cug2AppContext.Country)?.Code;

    const customRules = (): InputFieldAttribute[] => {
        return [
            unconditionalRut(),
            dni(),
            {
                name: "username-available",
                validators: [
                    {
                        errorMessage: {
                            id: "UserName_Availability",
                            message: i18next.t("This username is not available."),
                            scope: "field",
                        },

                        validate: async (field: InputElement) => {
                            const rootElem = DomCrawlingHelper.getElemByClass(document.body, rootClass);
                            handleCugLoader(rootElem, "addUser");
                            const result = !field.value || (await checkUserNameAvailability(field.value));

                            handleCugLoader(rootElem, "addUser");
                            return Promise.resolve(result);
                        },
                    },
                ],
            },
        ];
    };

    const checkUserNameAvailability = async (userName: string) => {
        const loader = showLoader({ name: LOADER_CLASS_NAMES.CugModal2, container: formElem.current, noPlane: true });

        let isAvailable = false;
        await ajaxRequest({
            loader,
            container: formElem.current,
            method: "POST",
            url: `${ROUTES.ApiRoutes.CheckUserExist}`,
            body: {
                UserName: userName,
            },
            isJsonData: true,
            onResponseCode: {
                200: (result) => {
                    isAvailable = !JSON.parse(result).IsUserExist;
                },
            },
        });

        return isAvailable;
    };

    // EVENT LISTENERS

    const handleTaxNumberInput = (e?: KeyboardEvent) => {
        if (!taxNumberField.current) {
            return;
        }

        if (getTaxnumberCodeByCountry(country, true) === "RUT") {
            sanitizeRutFieldValue(taxNumberField.current);
        } else if (e) {
            sanitizeInputFieldValue(e, "travel-document-id");
        }
    };

    const handlePhoneInput = (type: CugUserPhoneType, e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement;
        target.value = sanitizePhoneNumber(target.value, false);

        if (type === "office") {
            setOfficePhoneNumber(target.value);
        }

        if (type === "mobile") {
            setMobilePhoneNumber(target.value);
        }
    };

    const handlePhoneChange = (e: KeyboardEvent) => {
        if (isKeyNumeric(e, false)) {
            return true;
        } else {
            e.preventDefault();
            return false;
        }
    };

    const handleSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const isFormValid = await form.validate();

        if (isFormValid) {
            handleCugLoader(props.gridElement, "loadData");

            await ajaxRequest({
                body: getRequestBodyFromNamedInputs(formElem.current),
                container: props.rootElement,
                url: ROUTES.ApiRoutes.Cug2BCreateUser,
            });

            handleCugLoader(props.gridElement, "loadData");

            props.setIsAddingUser(false);

            await props.updateDataState();
        }
    };

    const handleClose = () => {
        props.setUserToEdit(undefined);
        props.setIsAddingUser(false);
    };

    const handleNameInput = (e: KeyboardEvent) => {
        sanitizeInputFieldValue(e, "accepted-text-chars");
    };

    const handleEmailInput = (e: KeyboardEvent) => {
        sanitizeInputFieldValue(e, "e-mail");
    };

    const handleUserNameInput = (e: KeyboardEvent) => {
        if (userContext.peruCompra.role !== "none") {
            return sanitizeInputFieldValue(e, "numeric");
        }

        return sanitizeInputFieldValue(e, "user-name");
    };

    // COMPONENT

    const formElem = useRef<HTMLFormElement>(undefined);
    const taxNumberField = useRef<HTMLInputElement>(undefined);
    const scroller = useRef<HTMLDivElement>(undefined);

    const cug2AppContext = useCug2AppContext();

    const { ajaxRequest } = useAjax();

    const [userContext] = useReduxState("userContext");

    const [scrollbar, setScrollbar] = useState<PerfectScrollbar>(undefined);
    const [country, setCountry] = useState<string>(cug2AppContext.Country);
    const [officePhonePrefix, setOfficePhonePrefix] = useState<string>(getInitialPhonePrefix());
    const [officePhoneNumber, setOfficePhoneNumber] = useState<string>("");
    const [mobilePhonePrefix, setMobilePhonePrefix] = useState<string>(getInitialPhonePrefix());
    const [mobilePhoneNumber, setMobilePhoneNumber] = useState<string>("");

    const form = useForm({
        customAttributes: [...commonValidationRules(), ...customRules()],
        noScroll: true,
    });

    useEffect(() => form.init(formElem.current), [formElem.current]);
    useEffect(handleTaxNumberInput, [country]);

    useEffect(() => {
        if (scroller.current) {
            initScrollbar();
        }
    }, [scroller.current]);

    // TEMPLATES

    const firstNameTemplate = () => html`
        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
            <label class="mdl-textfield__label">${i18next.t("V2-FirstNameLabel")}</label>
            <input
                name="Name.First"
                class="mdl-textfield__input js-input"
                data-test-id=${T.CUG2_USERS.ADD_USER_MODAL_FIRST_NAME_INPUT_FIELD}
                data-required
                no-spec
                data-validation="UserForm.FirstName"
                @input=${handleNameInput}
                @blur=${handleNameInput}
            />
        </div>
    `;

    const lastNameTemplate = () => html`
        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
            <label class="mdl-textfield__label">${i18next.t("V2-LastNameLabel")}</label>
            <input
                name="Name.Last"
                class="mdl-textfield__input js-input"
                data-test-id=${T.CUG2_USERS.ADD_USER_MODAL_LAST_NAME_INPUT_FIELD}
                data-required
                no-spec
                data-validation="UserForm.LastName"
                @input=${handleNameInput}
                @blur=${handleNameInput}
            />
        </div>
    `;

    const userNameTemplate = () => html`
        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
            <label class="mdl-textfield__label"
                >${userContext.peruCompra.role !== "none" ? i18next.t("DNI") : i18next.t("User Name")}</label
            >
            <input
                name="UserName"
                class="mdl-textfield__input js-input"
                data-test-id=${T.CUG2_USERS.ADD_USER_MODAL_USER_NAME_INPUT_FIELD}
                data-validation=${userContext.peruCompra.role === "none" ? "UserForm.UserName" : ""}
                data-required
                username-available
                ?dni=${userContext.peruCompra.role !== "none"}
                @input=${handleUserNameInput}
                @blur=${handleUserNameInput}
            />
        </div>
    `;
    const emailTemplate = () => html`
        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
            <label class="mdl-textfield__label">${i18next.t("V2-EmailLabel")}</label>
            <input
                name="Person.PersonalEmailAddress.EmailAddress"
                class="mdl-textfield__input js-input"
                data-test-id=${T.CUG2_USERS.ADD_USER_MODAL_EMAIL_INPUT_FIELD}
                data-required
                data-validation="UserForm.Email"
                @input=${handleEmailInput}
            />
        </div>
    `;

    const roleOptionsTemplate = () =>
        props.availableRoles.map(
            (role) => html`
                <option ?selected=${isPeruCompraRolePreselected(role)} value=${role.Value}>${role.Text}</option>
            `,
        );

    const roleTemplate = () => {
        const roleClassMap = classMap({
            "mdl-textfield__input": true,
            "js-input": true,
            "js-select": true,
            "disabled": userContext.peruCompra.role !== "none",
        });

        return html`
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                <label class="mdl-textfield__label">${i18next.t("CUG-RoleLabel")}</label>
                <select
                    name="Person.Role"
                    class=${roleClassMap}
                    data-test-id=${T.CUG2_USERS.ADD_USER_MODAL_ROLE_OPTION_SELECTOR}
                    data-required
                >
                    <option value="" selected disabled></option>
                    ${roleOptionsTemplate()}
                </select>
            </div>
        `;
    };

    const nationalityOptionsTemplate = () =>
        cug2AppContext.Countries.map(
            (c) => html` <option value=${c.Value} ?selected=${c.Value === country}>${c.Text}</option> `,
        );

    const nationalityTemplate = () =>
        userContext.peruCompra.role !== "none"
            ? html`<input type="hidden" name="Nationality" value="PE" />`
            : html`
                  <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                      <label class="mdl-textfield__label">${i18next.t("CUG-CountryCode")}</label>
                      <select
                          name="Nationality"
                          class="mdl-textfield__input js-input js-select"
                          data-test-id=${T.CUG2_USERS.ADD_USER_MODAL_NATIONALITY_SELECTOR}
                          data-required
                          value=${country}
                          @change=${(e: MouseEvent) => setCountry((e.target as HTMLSelectElement).value)}
                      >
                          <option value=""></option>
                          ${nationalityOptionsTemplate()}
                      </select>
                  </div>
              `;

    const hiddenOfficePhoneInputTemplate = () =>
        html`<input
            type="hidden"
            name="Person.HomePhoneNumber.Number"
            .value=${`${officePhonePrefix} ${officePhoneNumber}`}
        />`;

    const officePhonePrefixOptionsTemplate = () =>
        mapCountryCallingCodeListToHtmlOptions(cug2AppContext.PhonePrefixes, cug2AppContext.Culture, officePhonePrefix);

    const officePhonePrefixTemplate = () => html`
        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label ">
            <select
                class="mdl-textfield__input js-input js-select"
                .value=${officePhonePrefix}
                @change=${(e: MouseEvent) => setOfficePhonePrefix((e.target as HTMLSelectElement).value)}
            >
                ${officePhonePrefixOptionsTemplate()}
            </select>
            <label class="mdl-textfield__label">${i18next.t("V2-PhoneCountryLabel")}</label>
        </div>
    `;

    const hiddenMobilePhoneInputTemplate = () =>
        html`<input
            type="hidden"
            name="Person.MobileNumber.Number"
            .value=${`${mobilePhonePrefix} ${mobilePhoneNumber}`}
        />`;

    const mobilePhonePrefixOptionsTemplate = () =>
        mapCountryCallingCodeListToHtmlOptions(cug2AppContext.PhonePrefixes, cug2AppContext.Culture, mobilePhonePrefix);

    const mobilePhonePrefixTemplate = () => html`
        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label ">
            <select
                class="mdl-textfield__input js-input js-select"
                .value=${mobilePhonePrefix}
                @change=${(e: MouseEvent) => setMobilePhonePrefix((e.target as HTMLSelectElement).value)}
            >
                ${mobilePhonePrefixOptionsTemplate()}
            </select>
            <label class="mdl-textfield__label">${i18next.t("V2-PhoneCountryLabel")}</label>
        </div>
    `;

    const phoneTemplate = (type: CugUserPhoneType) => {
        let label;
        let dataTestId;

        switch (type) {
            case "general":
                label = i18next.t("V2-PhoneLabel");
                dataTestId = T.CUG2_USERS.ADD_USER_MODAL_PHONE_INPUT_FIELD;
                break;
            case "office":
                label = i18next.t("Office phone");
                dataTestId = T.PERU_COMPRA_USER_ADD.OFFICE_PHONE_INPUT_FIELD;
                break;
            case "mobile":
                label = i18next.t("Teléfono móvil");
                dataTestId = T.CUG2_USERS.ADD_USER_MODAL_MOBILE_PHONE_INPUT_FIELD;
                break;
        }

        return html`
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                <label class="mdl-textfield__label">${label}</label>
                ${userContext.peruCompra.role !== "none"
                    ? html` <input
                          class="mdl-textfield__input js-input"
                          data-test-id=${dataTestId}
                          data-required
                          data-validation="UserForm.Phone"
                          @keydown=${handlePhoneChange}
                          @input=${(e: KeyboardEvent) => {
                              handlePhoneInput(type, e);
                          }}
                      />`
                    : html` <input
                          class="mdl-textfield__input js-input"
                          name="Person.HomePhoneNumber.Number"
                          data-test-id=${dataTestId}
                          data-required
                          data-validation="UserForm.Phone"
                          @keydown=${handlePhoneChange}
                          @input=${(e: KeyboardEvent) => {
                              handlePhoneInput(type, e);
                          }}
                      />`}
            </div>
        `;
    };

    const taxNumberTemplate = () => html`
        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
            <label class="mdl-textfield__label">
                ${getTaxnumberCodeByCountry(country, true) || i18next.t("CUG-TaxNumber")}
            </label>
            <input
                ref=${ref(taxNumberField)}
                name="Person.NationalIdNumber"
                class="mdl-textfield__input js-input"
                data-test-id=${T.CUG2_USERS.ADD_USER_MODAL_TAX_NUMBER_INPUT_FIELD}
                data-required
                data-validation="UserForm.UserId"
                ?rut=${getTaxnumberCodeByCountry(country, true) === "RUT"}
                @input=${handleTaxNumberInput}
                @blur=${handleTaxNumberInput}
            />
        </div>
    `;

    const submitButtonTemplate = () => html`
        <button
            class="rounded-primary-btn"
            data-test-id=${T.CUG2_USERS.ADD_USER_MODAL_SUBMIT_BUTTON}
            @click=${handleSubmit}
        >
            ${i18next.t("Guardar")}
        </button>
    `;

    const headerTemplate = () => html`
        <header>
            <i class="js-icon-cug js-cug-man-and-plus"></i>
            <h1>${i18next.t("Nuevo usuario")}</h1>
        </header>
    `;

    return html`
        <div class="cug2b-add-user-modal" @click=${(e: MouseEvent) => e.stopPropagation()}>
            <div class="cug2b-add-user-modal-scroller" ref=${ref(scroller)}>
                <button class="cug2b-modal-closer" @click=${handleClose}>&times;</button>
                ${headerTemplate()}
                <form ref=${ref(formElem)} class=${rootClass} data-test-id=${T.CUG2_USERS.ADD_USER_MODAL_FORM}>
                    <input type="hidden" name="CultureCode" value=${normalizeCulture(cug2AppContext.Culture)} />
                    <div class="row">
                        ${userContext.peruCompra.role !== "none"
                            ? html` <div class="col-xs-1 col-sm-1-2">${firstNameTemplate()}</div>
                                  <div class="col-xs-1 col-sm-1-2">${lastNameTemplate()}</div>
                                  <div class="col-xs-1 col-sm-1-2">${emailTemplate()}</div>
                                  <div class="col-xs-1 col-sm-1-2">${userNameTemplate()}</div>
                                  <div class="col-xs-1 col-sm-1-4">${officePhonePrefixTemplate()}</div>
                                  <div class="col-xs-1 col-sm-1-4">${phoneTemplate("office")}</div>
                                  <div class="col-xs-1 col-sm-1-4">${mobilePhonePrefixTemplate()}</div>
                                  <div class="col-xs-1 col-sm-1-4">${phoneTemplate("mobile")}</div>
                                  <div class="col-xs-1 col-sm-1-2">${roleTemplate()}</div>
                                  <div>${nationalityTemplate()}</div>
                                  ${hiddenOfficePhoneInputTemplate()} ${hiddenMobilePhoneInputTemplate()}`
                            : html` <div class="col-xs-1 col-sm-1-2">${firstNameTemplate()}</div>
                                  <div class="col-xs-1 col-sm-1-2">${lastNameTemplate()}</div>
                                  <div class="col-xs-1 col-sm-1-2">${emailTemplate()}</div>
                                  <div class="col-xs-1 col-sm-1-2">${userNameTemplate()}</div>
                                  <div class="col-xs-1 col-sm-1-2">${roleTemplate()}</div>
                                  <div class="col-xs-1 col-sm-1-2">${nationalityTemplate()}</div>
                                  <div class="col-xs-1 col-sm-1-2">${phoneTemplate("general")}</div>
                                  <div class="col-xs-1 col-sm-1-2">${taxNumberTemplate()}</div>`}
                    </div>
                    <div class="mt-4 flex w-full justify-end sm:mt-8">${submitButtonTemplate()}</div>
                </form>
            </div>
        </div>
        ${updateMdl()}
    `;
};
