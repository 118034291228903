import { classMap } from "lit-html/directives/class-map";
import { html } from "lit-html";
import { SeatmapContext } from "../../../../component-models/seatmap/SeatmapContext";
import { SeatmapState } from "../../../../component-models/seatmap/SeatmapState";
import { ApiSeatmapPassenger } from "../../../../component-models/seatmap/ApiSeatmapPassenger";
import DomCrawlingHelper from "../../../../shared/DomCrawlingHelper";
import { useEffect } from "../../../../shared/haunted/CustomHooks";
import { useMobileSegmentSelector } from "./useMobileSegmentSelector";
import { passengerContainerClassName, useMobileJourneySelector } from "./useMobileJourneySelector";
import { ScrollHelper } from "../../../../shared/ScrollHelper";
import { ExtendedApiSeat } from "../../../../component-models/seatmap/ApiSeat";
import { SeatmapStateUpdater } from "../../../../component-models/seatmap/SeatmapStateUpdater";
import { bannerClassName } from "../recommendator/useSeatRecommendator";
import { mobileSeatPaxContainerClass, mobileSeatPaxClass } from "../useSeatmap";
import { useChileCompraInfoTemplate } from "./useChileCompraInfoTemplate";

export interface Props {
    context: SeatmapContext;
    currentSeats: ExtendedApiSeat[];
    seatmapState: SeatmapState;
    handleInboundSelect: () => void;
    handleOutboundSelect: () => void;
    handleSeatDelete: (passenger: ApiSeatmapPassenger) => void;
    handleSegmentClick: (segmentIndex: number) => void;
    updateState: (newState: SeatmapStateUpdater) => void;
}

export const useSeatPassengerMobileContainer = (props: Props) => {
    const segmentSelector = useMobileSegmentSelector({
        seatmapState: props.seatmapState,
        handleSegmentClick: props.handleSegmentClick,
    });

    const journeySelector = useMobileJourneySelector({
        context: props.context,
        seatmapState: props.seatmapState,
        handleInboundSelect: props.handleInboundSelect,
        handleOutboundSelect: props.handleOutboundSelect,
    });

    const chileCompraInfo = useChileCompraInfoTemplate({
        context: props.context,
        seatmapState: props.seatmapState,
    });

    const scrollToJourneyInfoMobile = () => {
        if (!props.seatmapState) return;

        const bannerElem = DomCrawlingHelper.getElemByClass(document.body, bannerClassName);
        const journeyContainerElem = DomCrawlingHelper.getElemByClass(document.body, passengerContainerClassName);

        if (journeyContainerElem?.offsetHeight === 0) {
            return;
        }

        ScrollHelper.scrollToElementAndHideNav({ element: journeyContainerElem, timeOffset: 500 });

        // DEVNOTE: It scrolls to the seat recommendator banner if it exists
        if (props.seatmapState.CurrentPassenger?.PassengerNumber === 0) {
            ScrollHelper.scrollToElementAndHideNav({ element: bannerElem });
        }
    };

    const scrollInsidePaxContainerHorizontally = () => {
        if (!journeySelector.root.current || props.seatmapState) return;

        const journeyContainerElem = DomCrawlingHelper.getElemByClass(document.body, passengerContainerClassName);
        const paxContainerElem = DomCrawlingHelper.getElemByClass(
            journeySelector.root.current.parentElement,
            mobileSeatPaxContainerClass,
        );

        const currentJourneyElem = Array.from(journeyContainerElem.children)[
            props.seatmapState.CurrentJourney?.JourneyIndex || 0
        ] as HTMLElement;

        const passengers = paxContainerElem
            ? DomCrawlingHelper.getArrayOfClass(paxContainerElem, mobileSeatPaxClass)
            : [];
        const currentPassengerElem = passengers[props.seatmapState.CurrentPassenger?.PassengerNumber || 0];

        if (currentJourneyElem) {
            journeyContainerElem.scrollTo({
                left: currentJourneyElem.offsetLeft,
                top: 0,
                behavior: "smooth",
            });
        }

        if (currentPassengerElem) {
            paxContainerElem.scrollTo({
                left: currentPassengerElem.offsetLeft,
                top: 0,
                behavior: "smooth",
            });
        }
    };

    const handleSelectPassenger = (passengerNumber: number) => {
        props.updateState({
            PassengerIndex: passengerNumber,
        });
    };

    useEffect(scrollToJourneyInfoMobile, [
        props.seatmapState?.CurrentJourney?.JourneyIndex,
        props.seatmapState?.CurrentSegment?.SegmentIndex,
    ]);

    useEffect(scrollInsidePaxContainerHorizontally, [
        props.seatmapState?.CurrentPassenger?.PassengerNumber,
        props.seatmapState?.CurrentJourney?.JourneyIndex,
    ]);

    // TEMPLATES

    const passengerListTemplate = () => {
        const tempClassMap = classMap({
            "mobile-passengers": true,
            "division-1": props.context.Model.Passengers.length === 1,
            "division-2": props.context.Model.Passengers.length === 2,
            [mobileSeatPaxContainerClass]: true,
        });

        return html`
            <div class=${tempClassMap}>
                ${props.context.Model.Passengers.map(
                    (passenger) => html`
                        <ac-mobile-seat-passenger
                            class=${mobileSeatPaxClass}
                            .apiPassenger=${passenger}
                            .context=${props.context}
                            .currentSeats=${props.currentSeats}
                            .journeyIndex=${props.seatmapState?.CurrentJourney?.JourneyIndex}
                            .seatmapState=${props.seatmapState}
                            .segmentIndex=${props.seatmapState?.CurrentSegment?.SegmentIndex}
                            .handleSeatDelete=${props.handleSeatDelete}
                            .onPassengerSelect=${handleSelectPassenger}
                        >
                        </ac-mobile-seat-passenger>
                    `,
                )}
            </div>
        `;
    };

    const htmlTemplate = () => html`
        ${journeySelector.htmlTemplate()} ${segmentSelector.htmlTemplate()} ${passengerListTemplate()}
        ${chileCompraInfo.htmlTemplate()}
    `;

    return { htmlTemplate, scrollToJourneyInfoMobile };
};
