import { FlowContext } from "../component-models/app/FlowContext";
import { toBoolean } from "../shared/common";
import { ACTIONS_FOR_FLIGHT_MOVE } from "../shared/commonConstants";
import { useMemo } from "../shared/haunted/CustomHooks";

export type FlowType =
    | "Booking"
    | "Change"
    | "Checkin"
    | "CugFunding"
    | "DcStandalone"
    | "FarelockRoundOne"
    | "FarelockRoundTwo"
    | "GiftcardPurchase"
    | "PeruCompraAnonymousCheckin";

export const useFlowContext = () => {
    const getFlowType = (): FlowType => {
        const isChangeFlow = toBoolean(window.JetSmart.FlowContext.isChangeFlow);
        const isCheckinFlow = toBoolean(window.JetSmart.FlowContext.isCheckinFlow);
        const hasFarelockFeeInSession = toBoolean(window.JetSmart.FlowContext.hasFarelockFeeInSession);
        const hasFarelockFeeNotTotallyPaid = toBoolean(window.JetSmart.FlowContext.hasFarelockFeeNotTotallyPaid);
        const isOnFarelockQueue = toBoolean(window.JetSmart.FlowContext.isOnFarelockQueue);
        const isPeruCompraAnonymousCheckinFlow = toBoolean(
            window.JetSmart.FlowContext.isPeruCompraAnonymousCheckinFlow,
        );

        // DEVNOTE If we don't have fee in session, we could still have a farelock fee not totally paid.
        // eg.: In the case of voucher and partial payments
        if ((hasFarelockFeeInSession || hasFarelockFeeNotTotallyPaid) && !isChangeFlow && !isCheckinFlow) {
            return "FarelockRoundOne";
        }

        if (isOnFarelockQueue && !hasFarelockFeeInSession) {
            return "FarelockRoundTwo";
        }

        if (isPeruCompraAnonymousCheckinFlow) {
            return "PeruCompraAnonymousCheckin";
        }

        if (isCheckinFlow) {
            return "Checkin";
        }

        if (toBoolean(window.JetSmart.FlowContext.isDcStandaloneFlow)) {
            return "DcStandalone";
        }

        if (toBoolean(window.JetSmart.FlowContext.isGiftcardPurchaseFlow)) {
            return "GiftcardPurchase";
        }

        if (toBoolean(window.JetSmart.FlowContext.isFundingCug)) {
            return "CugFunding";
        }

        if (isChangeFlow) {
            return "Change";
        }

        return "Booking";
    };

    const getAction = () => window.JetSmart.FlowContext.action.toLowerCase();

    const getLoadType = () =>
        getFlowType() === "Checkin" ? "checkin" : getFlowType() === "Change" ? "post-booking" : "booking";

    const isFlightMove = () => (getAction() ? ACTIONS_FOR_FLIGHT_MOVE.includes(getAction()) : false);

    const isFarelockRoundOne = () => getFlowType() === "FarelockRoundOne";
    const isFarelockRoundTwo = () => getFlowType() === "FarelockRoundTwo";
    const isGiftcardPurchaseFlow = () => getFlowType() === "GiftcardPurchase";
    const isDcStandaloneFlow = () => getFlowType() === "DcStandalone";
    const isFlightlessPurchaseFlow = () => isGiftcardPurchaseFlow() || isDcStandaloneFlow();
    const isCugFundingFlow = () => getFlowType() === "CugFunding";
    const isFlightlessFlow = () => isFlightlessPurchaseFlow() || isCugFundingFlow();
    const isPeruCompraAnonymousCheckinFlow = () => getFlowType() === "PeruCompraAnonymousCheckin";

    const context = useMemo<FlowContext>(
        () =>
            window.JetSmart.FlowContext
                ? {
                      action: getAction(),
                      bsid: window.JetSmart.FlowContext.bsid,
                      controller: window.JetSmart.FlowContext.controller.toLowerCase(),
                      flowType: getFlowType(),
                      isAaPostBooking: toBoolean(window.JetSmart.FlowContext.isAaPostBooking),
                      isBookingFlow: getFlowType() === "Booking",
                      isChangeFlow: getFlowType() === "Change",
                      isCheckinFlow: getFlowType() === "Checkin",
                      isCugFundingFlow: isCugFundingFlow(),
                      isDcStandaloneFlow: isDcStandaloneFlow(),
                      isFarelockRoundOne: isFarelockRoundOne(),
                      isFarelockRoundTwo: isFarelockRoundTwo(),
                      isFlightlessFlow: isFlightlessFlow(),
                      isFlightlessPurchaseFlow: isFlightlessPurchaseFlow(),
                      isFlightMove: isFlightMove(),
                      isGiftcardPurchaseFlow: isGiftcardPurchaseFlow(),
                      isPeruCompraAnonymousCheckinFlow: isPeruCompraAnonymousCheckinFlow(),
                      isPostBookingFlow: getFlowType() === "Change" || getFlowType() === "Checkin",
                      isRedemptionFlow: toBoolean(window.JetSmart.FlowContext.isRedemptionFlow),
                      loadType: getLoadType(),
                  }
                : ({} as FlowContext),
        [],
    );

    return context;
};
