import {
    AGENCY_URL_PARTIAL_STRING,
    COMPANY_URL_PARTIAL_STRING,
    PERU_COMPRA_PARTIAL_STRING,
} from "./../shared/commonConstants";
import { ROUTES } from "./../shared/apiRoutes";
import { useEffect, useState } from "./../shared/haunted/CustomHooks";
import { HauntedFunc } from "../shared/haunted/HooksHelpers";
import { html } from "haunted";
import { getAntiforgerySegment, getParsedProperty, sanitizeRutFieldValue } from "../shared/common";
import i18next from "i18next";
import { BANCOESTADO_LOGIN_TYPE } from "../shared/commonConstants";
import { validateRut } from "../shared/form-validation";
import { classMap } from "lit-html/directives/class-map";
import { TestIdDictionary as T } from "../testing-helpers/TestIdHelper";
import { ApiForgotPasswordViewModel } from "../component-models/ApiForgotPasswordViewModel";
import { useTealiumManager } from "../managers/Tealium/useTealiumManager";
import { useFluentValidator } from "../validator/FluentValidator";
import { Validation } from "../validator/Validation";
import { useErrorMessage } from "./ui/error-message/useErrorMessage";
import { createFormHtmlElement } from "../component-helpers/FormHelper";
import { useAppContext } from "../managers/useAppContext";
import { ANTI_FORGERY_TOKEN_PROPERTY_NAME } from "../shared/customHooks/useAjax/useAjax";

export const name = "ac-forgot-password";

const captchaContainerClass = "ac-captcha-container";

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token", "model"];

export interface Attributes {
    "anti-forgery-token": string;
    "model": string;
}

export interface Props {
    antiForgeryToken: string;
    model: ApiForgotPasswordViewModel;
}

interface ForgotPasswordVM {
    username: string;
}

const DEFAULT_VM: ForgotPasswordVM = {
    username: "",
};

type FieldNames = keyof ForgotPasswordVM;

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        antiForgeryToken: host.antiForgeryToken,
        model: getParsedProperty<ApiForgotPasswordViewModel>(host.model),
    };

    const appContext = useAppContext();

    const initCaptcha = () => {
        if (!appContext.isFeatureSwitchActive("Captcha")) {
            return;
        }

        const grecaptchaInterval = window.setInterval(() => {
            const captchaContainer = document.body.querySelector(`.${captchaContainerClass} div`) as HTMLDivElement;

            if (window.grecaptcha?.render && captchaContainer) {
                setCaptchaId(
                    window.grecaptcha.render(captchaContainer, {
                        sitekey: props.model.GRecaptchaSiteKey,
                    }),
                );

                window.clearInterval(grecaptchaInterval);
                window.clearTimeout(grecaptchaTimeLimit);
            }
        }, 100);

        const grecaptchaTimeLimit = window.setTimeout(() => {
            window.clearInterval(grecaptchaInterval);
            setShowCaptchaLoadError(true);
        }, 3000);
    };

    const showCugLabels = () =>
        [AGENCY_URL_PARTIAL_STRING, COMPANY_URL_PARTIAL_STRING].some(
            (str) => window.location.href.toLowerCase().indexOf(str.toLowerCase()) > -1,
        );

    const showPcraLabels = () =>
        window.location.href.toLowerCase().indexOf(PERU_COMPRA_PARTIAL_STRING.toLowerCase()) > -1;

    const getInstructionTextByUserType = () =>
        props.model.IsBancoEstado
            ? i18next.t("ForgotPassword-Instructions-BancoEstado")
            : showCugLabels()
              ? i18next.t(
                    "Por favor, ingresa tu correo electrónico o nombre de usuario (con el que te registraste) y te enviaremos un correo electrónico con una contraseña temporal e instrucciones.",
                )
              : showPcraLabels()
                ? i18next.t("Ingresa tu DNI y te enviaremos un correo con una contraseña temporal e instrucciones.")
                : i18next.t("ForgotPassword-Instructions");

    const handleSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsValidated(true);

        let isValid = await validator.validate();

        if (appContext.isFeatureSwitchActive("Captcha") && captchaId !== undefined) {
            if (window.grecaptcha.getResponse(captchaId).length === 0) {
                setShowCaptchaError(true);
                isValid = false;
                await tealiumManager.logValidationError(["invalid captcha"]);
            } else {
                setShowCaptchaError(false);
            }
        }

        if (isValid) {
            const captchaResponse = appContext.isFeatureSwitchActive("Captcha")
                ? window.grecaptcha.getResponse(captchaId)
                : "";

            const formToPost = createFormHtmlElement(ROUTES.ForgotPassword, {
                [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: props.antiForgeryToken.split('value="')[1].split('"')[0],
                "g-recaptcha-response": captchaResponse,
                "forgotMemberPassword.Username": vm.username,
                "forgotMemberPassword.LoginType": props.model.IsBancoEstado ? BANCOESTADO_LOGIN_TYPE : null,
            });

            formToPost.submit();
        }
    };

    const sanitizeUsername = (e: KeyboardEvent) => {
        if (props.model.IsBancoEstado) {
            return sanitizeRutFieldValue(e.target as HTMLInputElement);
        }

        return (e.target as HTMLInputElement).value;
    };

    const usernameInputLabel = () =>
        props.model.IsBancoEstado
            ? `${i18next.t("V2-Rut")} (${i18next.t("BE-RutExample")})`
            : showCugLabels()
              ? i18next.t("Correo electrónico o nombre de usuario")
              : showPcraLabels()
                ? i18next.t("DNI")
                : i18next.t("V2-EmailLabel");

    const [captchaId, setCaptchaId] = useState<any>(undefined);
    const [showCaptchaError, setShowCaptchaError] = useState<boolean>(undefined);
    const [showCaptchaLoadError, setShowCaptchaLoadError] = useState<boolean>(undefined);
    const [vm, setVm] = useState<ForgotPasswordVM>(DEFAULT_VM);
    const [isValidated, setIsValidated] = useState<boolean>(false);

    const tealiumManager = useTealiumManager();

    const validator = useFluentValidator<FieldNames, ForgotPasswordVM>({
        vm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("username", (vm: ForgotPasswordVM) => vm.username).isRequired(),
            Validation.ruleFor("username", (vm: ForgotPasswordVM) => vm.username)
                .when(() => props.model.IsBancoEstado)
                .fulfils((rut: string) => Promise.resolve(validateRut(rut)), i18next.t("Passengers-RutError")),
            Validation.ruleFor("username", (vm: ForgotPasswordVM) => vm.username)
                .when(() => !props.model.IsBancoEstado && !showCugLabels() && !showPcraLabels())
                .isEmail(),
        ],
    });

    const formErrors = useErrorMessage({ errorMessage: validator.getFormMessages() });

    useEffect(initCaptcha, []);

    const titleTemplate = () => html`
        <div class="row">
            <div class="col-xs-1">
                <header>
                    <div class="title full-width-title">
                        <h2 class="main-title" data-test-id=${T.FORGOT_PASSWORD.TITLE}>
                            ${i18next.t("ForgotPasswordTitle")}
                        </h2>
                    </div>
                </header>
            </div>
        </div>
    `;

    const formTemplate = () => html`
        <div class="inner-box">
            <div class="row">
                <div class="col-xs-1">
                    ${getAntiforgerySegment(props.antiForgeryToken)} ${instructionsTemplate()} ${usernameTemplate()}
                </div>
            </div>
        </div>
        ${captchaTemplate()} ${formErrors.htmlTemplate()}
    `;

    const instructionsTemplate = () => html`
        <div class="pw-instructions mb-5" data-test-id=${T.FORGOT_PASSWORD.INSTRUCTIONS}>
            ${getInstructionTextByUserType()}
        </div>
    `;

    const usernameTemplate = () => html`
        <ac-input
            .errorMessage=${validator.getMessage("username")}
            .isInvalid=${!validator.isValid("username")}
            .label=${usernameInputLabel()}
            .testId=${T.FORGOT_PASSWORD.EMAIL_INPUT_FIELD}
            .value=${vm.username ?? ""}
            .onInput=${(value: string) => setVm({ ...vm, username: value })}
            .sanitizer=${sanitizeUsername}
        ></ac-input>
    `;

    const captchaTemplate = () =>
        appContext.isFeatureSwitchActive("Captcha")
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class=${captchaContainerClass} data-test-id=${T.FORGOT_PASSWORD.FORM_CAPTCHA}>
                              <div></div>
                          </div>
                      </div>
                      ${captchaErrorTemplate()}
                  </div>
              `
            : "";

    const captchaErrorTemplate = () =>
        showCaptchaError
            ? html`
                  <div class="col-xs-1">
                      <div class="error-message-container elevated-error text-left">
                          <div class="form-error-message">${i18next.t("CUG2-CaptchaError")}</div>
                      </div>
                  </div>
              `
            : "";

    const buttonsTemplate = () => {
        const cancelClassMap = classMap({
            "rounded-secondary-btn": true,
            "mr-3": true,
            "banco-estado-color": props.model.IsBancoEstado,
        });

        const continueClassMap = classMap({
            "rounded-primary-btn": true,
            "banco-estado-color": props.model.IsBancoEstado,
        });

        return html`
            <div class="forgotpw-button-container" data-test-id=${T.FORGOT_PASSWORD.BUTTON_CONTAINER}>
                <a href=${ROUTES.PageHome} class=${cancelClassMap} data-test-id=${T.FORGOT_PASSWORD.CANCEL_BUTTON}>
                    ${i18next.t("V2-Cancel")}
                </a>
                <a @click=${handleSubmit} class=${continueClassMap} data-test-id=${T.FORGOT_PASSWORD.CONTINUE_BUTTON}>
                    ${i18next.t("V2-Continue")}
                </a>
            </div>
        `;
    };

    const captchaLoadErrorTemplate = () =>
        showCaptchaLoadError
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="captcha-load-error">${i18next.t("CaptchaLoadError")}</div>
                      </div>
                  </div>
              `
            : "";

    return html`
        <div id="mainContentWrapper">
            <section class="booking-wrapper no-breadcrumb forgot-password ts-error-container">
                ${titleTemplate()} ${formTemplate()} ${buttonsTemplate()}
            </section>
        </div>
        ${captchaLoadErrorTemplate()}
    `;
};
