import i18next from "i18next";
import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import { getInputId, getInputName } from "../../../../component-helpers/BaggageHelper";
import { html } from "lit-html";
import { PAID_CABIN_MOBILE_IMG } from "../../spa-container";
import { useBaggagePriceBreakdown } from "./useBaggagePriceBreakdown";
import { StaffBaggage } from "../common/useStaffBaggage";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import classNames from "classnames";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { useBaggageUtils } from "../common/useBaggageUtils";
import { BancoEstadoBaggage } from "../common/useBancoEstadoBaggage";

export interface Props {
    baggageSection: BaggageSection;
    bancoEstadoBaggage: BancoEstadoBaggage;
    staffBaggage: StaffBaggage;
}

export const useMobilePerBookingPaidCabin = (props: Props) => {
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const { formattedLowestNextPrice } = useBaggageUtils();

    const priceBreakdown = useBaggagePriceBreakdown({
        baggageSectionJourneys: props.baggageSection.journeys,
        isShown: props.baggageSection.perBooking.isPaidBagSelected && !props.bancoEstadoBaggage?.state?.length,
        staffBaggage: props.staffBaggage,
    });

    const nextMinimumOriginalPrice = useMemo(
        () =>
            props.baggageSection.perBooking.nextPrice({
                baggageSectionJourneys: props.baggageSection.journeys,
                bagType: "cabinBaggage",
                bancoEstadoBaggage: props.bancoEstadoBaggage,
                isMinimum: true,
                isOriginal: true,
                staffBaggage: props.staffBaggage,
            }),
        [props.baggageSection.journeys, props.staffBaggage, props.bancoEstadoBaggage],
    );

    const showMinimumOriginalPrice = useMemo(
        () => flowContext.isBookingFlow && bookingContext.isDiscountBooking && nextMinimumOriginalPrice,
        [bookingContext.isDiscountBooking, flowContext.isBookingFlow, nextMinimumOriginalPrice],
    );

    const containsNoBundle = useMemo(
        () => props.baggageSection.journeys.every((journey) => journey.bundleType === "None"),
        [props.baggageSection.journeys],
    );

    const isAddAvailable = useMemo(
        () =>
            props.baggageSection.perBooking.isAddAvailable({
                baggageSectionJourneys: props.baggageSection.journeys,
                bagType: "cabinBaggage",
                canGetFreeBancoEstadoBaggage: props.baggageSection.canGetFreeBancoEstadoBaggage,
                isCheckinClosedInbound: bookingContext.isCheckinClosedInbound,
                isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
                paxCount: bookingContext.adultsCount + bookingContext.childrenCount,
            }),
        [props.baggageSection.journeys, bookingContext],
    );

    const unformattedLowestNextPrice = (isOriginal: boolean, isMinimum: boolean) =>
        props.baggageSection.perBooking.nextPrice({
            baggageSectionJourneys: props.baggageSection.journeys,
            bagType: "cabinBaggage",
            bancoEstadoBaggage: props.bancoEstadoBaggage,
            isMinimum,
            isOriginal,
            staffBaggage: props.staffBaggage,
        });

    // Event handlers

    const handleAdd = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (isAddAvailable) await props.baggageSection.add();
    };

    // Templates

    const illustrationTemplate = () => html`
        <div class="b2m-illustration">
            <div
                class="b2m-ribbon"
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_RIBBON, {
                    c: "cabinBaggage",
                    m: true,
                })}
            >
                <span>&star;</span> ${i18next.t("¡El más vendido!")}
            </div>
            <img class="b2-paid-cabin-img-mb" src=${PAID_CABIN_MOBILE_IMG} />
            <div class="flex w-full items-center justify-center">
                <div
                    class="b2m-weight wider"
                    data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_WEIGHT, {
                        c: "cabinBaggage",
                        m: true,
                    })}
                >
                    <span>10kg.</span>
                    <span>10kg.</span>
                </div>
            </div>
        </div>
    `;

    const noBundleOriginalPriceTemplate = () =>
        showMinimumOriginalPrice
            ? html`
                  <span class="b2m-crossed-out-price">
                      <span
                          class="line-through"
                          data-test-value=${unformattedLowestNextPrice(true, false)}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_CROSSED_OUT_MINIMUM_PRICE, {
                              c: "cabinBaggage",
                              m: true,
                          })}
                      >
                          ${formattedLowestNextPrice(unformattedLowestNextPrice(true, false))}
                      </span>
                  </span>
              `
            : "";

    const noBundlePriceTemplate = () => {
        const unformattedAmount = unformattedLowestNextPrice(false, true);

        return containsNoBundle && unformattedAmount >= 0
            ? html`
                  ${noBundleOriginalPriceTemplate()}
                  <span class=${classNames({ reduced: bookingContext.isDiscountBooking })}>
                      ${i18next.t("Desde")}
                      <span
                          data-test-value=${unformattedAmount}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_MINIMUM_PRICE, {
                              c: "cabinBaggage",
                              m: true,
                          })}
                      >
                          ${formattedLowestNextPrice(unformattedAmount)}
                      </span>
                  </span>
              `
            : "";
    };

    const htmlTemplate = () => {
        const cabinId = getInputId("cabinBaggage", undefined, undefined, true, false);
        const cabinInputName = getInputName("cabinBaggage", undefined, undefined, true);

        const tempClassMap = classNames("b2m-per-booking-section padded", {
            selected: props.baggageSection.perBooking.isPaidBagSelected,
        });

        return props.baggageSection.sectionUi.uiState.perJourneyPerPaxState !== "open"
            ? html`
                  <div class=${tempClassMap} @click=${handleAdd}>
                      ${illustrationTemplate()}
                      <div class="b2m-per-booking-selector">
                          ${noBundlePriceTemplate()}
                          <input
                              type="radio"
                              id=${cabinId}
                              name=${cabinInputName}
                              .checked=${props.baggageSection.perBooking.isPaidBagSelected &&
                              !props.baggageSection.perBooking.isNoBagSelected}
                          />
                          <label
                              for=${cabinId}
                              data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_ADD_FIRST_BUTTON, {
                                  c: "cabinBaggage",
                                  m: true,
                              })}
                          >
                              <div class="b2m-label">
                                  ${i18next.t("Mochila o artículo personal")}
                                  <div
                                      class="b2m-dimensions smaller"
                                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_DIMENSIONS, {
                                          c: "cabinBaggage",
                                          m: true,
                                      })}
                                  >
                                      35cm &times; 25cm &times; 45cm
                                  </div>
                              </div>
                              <div class="b2m-label">
                                  ${i18next.t("Equipaje de mano")}
                                  <div
                                      class="b2m-dimensions smaller"
                                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_DIMENSIONS, {
                                          c: "cabinBaggage",
                                          m: true,
                                      })}
                                  >
                                      35cm &times; 25cm &times; 55cm
                                  </div>
                              </div>
                          </label>
                      </div>
                      ${priceBreakdown.htmlTemplate()}
                  </div>
              `
            : "";
    };

    return { htmlTemplate };
};
