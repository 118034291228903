import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import {
    ACTION_NAMES,
    BRASIL_REAL_CODE,
    CHANGING_CURRENCY_URL_ELEMENT,
    PERUVIAN_CULTURE_CODE,
    REDEMPTION_MILES_CODE,
    URL_VARS,
    USA_DOLLAR_CODE,
} from "../../shared/commonConstants";
import i18next from "i18next";
import { html } from "haunted";
import { classMap } from "lit-html/directives/class-map";
import { ChargeGroupViewModel } from "../../component-models/sidebar/BreakdownViewModel";
import { ApiSidebarViewModel } from "../../component-models/sidebar/ApiSidebarViewModel";
import { useTealiumManager } from "../../managers/Tealium/useTealiumManager";
import { useAppContext } from "../../managers/useAppContext";
import { getUrlVars, maskCurrenciesForDisplay } from "../../shared/common";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../shared/useNumberFormatter";

export interface Props {
    farelock: ChargeGroupViewModel;
    hideTotalOnMobile: boolean;
    isMobile: boolean;
    isTotalPending: boolean;
    model: ApiSidebarViewModel;
    showCurrencySelector: boolean;
    isRedemptionTotal?: boolean;
    setIsOpen: (state: boolean) => void;
}

export const useTotal = (props: Props) => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const tealiumManager = useTealiumManager();
    const { triggers } = usePubSub();
    const { formatNumber } = useNumberFormatter();

    const [userContext] = useReduxState("userContext");
    const [currency, setCurrency] = useReduxState("booking.currency");

    // Helpers

    const showBookingTotal = () => {
        if (props.isRedemptionTotal) return false;

        if (flowContext.isChangeFlow || flowContext.isCheckinFlow) return true;

        if (!props.farelock) return true;

        return flowContext.isFarelockRoundTwo && props.model?.IsFareLockFullyPaid;
    };

    const isCurrentlyChangingCurrency = () => window.location.href.indexOf(CHANGING_CURRENCY_URL_ELEMENT) > -1;

    const shouldDisplayTotalInSol = () => {
        const isNotPeruCompra = userContext.peruCompra.role === "none" && !bookingContext.isPeruCompraBooking;

        const isBuyingDcStandaloneInPeru =
            flowContext.action === ACTION_NAMES.DC_REGISTER &&
            appContext.Culture === PERUVIAN_CULTURE_CODE.toLowerCase() &&
            props.model?.QuotedTotal === 0;

        return (
            isNotPeruCompra &&
            currency === USA_DOLLAR_CODE &&
            (isBuyingDcStandaloneInPeru || (props.model?.TotalInSol && showBookingTotal()))
        );
    };

    const showInstallmentsInfo = () =>
        flowContext.isBookingFlow && props.model?.BreakdownModel.InstallmentInfo && !props.isRedemptionTotal;

    const currencyOptions = () =>
        props.model?.Currencies.map(
            (currencyOpt) => html`
                <option value=${currencyOpt} .selected=${currencyOpt === currency}>
                    ${maskCurrenciesForDisplay(currencyOpt)}
                </option>
            `,
        );

    const getBookingCurrency = () => (props.showCurrencySelector && currency ? currency : props.model?.Currency);

    // Event handlers

    const handleCurrencyChange = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.setIsOpen(false);
        document.body.classList.remove("mobile-sidebar-opened");

        const currency = (e.target as HTMLSelectElement).value;

        triggers.shared.currencyChanged.publish(currency);
        triggers.flight.currencyChanged.publish(currency);

        setCurrency(currency);

        await tealiumManager.logCurrencyChanged(currency);

        if (flowContext.action === ACTION_NAMES.FLIGHT) {
            markUrlForCurrencyChange(currency);
        }
    };

    // DEVNOTE JET-6962 User data needs to be passed on, otherwise the reloaded flight will not see the user
    // if they logged in on the flight page
    const markUrlForCurrencyChange = (currency: string) => {
        const [baseUrl] = window.location.href.split("?");
        const currentUrlVars = getUrlVars();

        currentUrlVars[URL_VARS.CURRENCY] = [currency];

        const newQueryString = Object.keys(currentUrlVars)
            .flatMap((key) => currentUrlVars[key].flatMap((value: any) => `${key}=${value}`))
            .join("&");

        const userDataUri = userContext?.isLoggedIn
            ? `${URL_VARS.CURRENCY_CHANGE_USER}=${btoa(JSON.stringify(userContext))}`
            : undefined;

        const newQuery = [newQueryString, CHANGING_CURRENCY_URL_ELEMENT, userDataUri].filter((i) => i).join("&");

        history.replaceState({}, null, `${baseUrl}?${newQuery}`);
    };

    // Templates

    const farelockTotalTemplate = () => {
        const tempClassMap = classMap({
            "fare-lock-sidebar-total": true,
            "hidden-sm-down": flowContext.isFarelockRoundTwo,
        });

        return props.farelock && props.model?.ShowFareLockSeparate && !flowContext.isPostBookingFlow
            ? html`
                  <div class=${tempClassMap} data-test-id=${T.SIDEBAR.FARELOCK}>
                      <div class="row">
                          <div class="col-xs-1-2">
                              <div class="fare-lock-sidebar-total-left">
                                  <i class="js-icon js-fare-lock"></i>
                                  <span data-test-id=${T.SIDEBAR.FARELOCK_PRICE_LABEL}>
                                      ${i18next.t("SidebarFareLockSummary {{duration}}", {
                                          duration: props.model?.FareLockDuration,
                                      })}
                                  </span>
                              </div>
                          </div>
                          <div class="col-xs-1-2">
                              <div class="fare-lock-sidebar-total-right text-right">
                                  <span data-test-id=${T.SIDEBAR.FARELOCK_PRICE}>
                                      ${props.farelock.FormattedPrice}
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
              `
            : "";
    };

    const redemptionMilesTotalTemplate = () =>
        props.isRedemptionTotal
            ? html`
                  <div class="total-final-row hidden-sm-down">
                      <div>
                          <p class="total-left pad-left" data-test-id=${T.SIDEBAR.TOTAL_MILES_LABEL}>
                              ${i18next.t("Total Miles")}
                          </p>
                      </div>
                      <div>
                          <p
                              class="total-right pad-right text-right"
                              data-test-id=${T.SIDEBAR.TOTAL_AMOUNT_VALUE}
                              data-test-value=${props.model?.BreakdownModel.RedemptionTotalMiles}
                          >
                              ${totalMilesTemplate()}
                          </p>
                      </div>
                  </div>
              `
            : "";

    const bookingTotalTemplate = () =>
        showBookingTotal()
            ? html`
                  <div class="total-final-row hidden-sm-down">
                      <div>
                          <p
                              class="total-left pad-left ${shouldDisplayTotalInSol() ? "pull-up-bottom" : ""}"
                              data-test-id=${T.SIDEBAR.TOTAL_AMOUNT_LABEL}
                          >
                              ${i18next.t("PriceDisplay-TotalSectionTitle")}
                          </p>
                          ${installmentsInfoTemplate()}
                      </div>
                      <div>
                          <p
                              class="total-right pad-right ${shouldDisplayTotalInSol()
                                  ? "pull-up-bottom"
                                  : ""} text-right"
                              data-test-id=${T.SIDEBAR.TOTAL_AMOUNT_VALUE}
                              data-test-value=${props.model?.QuotedTotal}
                          >
                              ${totalAmountTemplate()}
                          </p>
                      </div>
                  </div>
              `
            : "";

    // DEVNOTE There is a mobile version for this in sidebar-booking.ts
    const bookingTotalInSolTemplate = () => {
        return shouldDisplayTotalInSol()
            ? html`
                  <div class="total-final-row hidden-sm-down">
                      <div>
                          <p class="total-left pad-left pull-up-top">${i18next.t("PriceDisplay-TotalInSol")}</p>
                      </div>
                      <div>
                          <p class="total-right pad-right pull-up-top text-right">${props.model?.TotalInSol || "0"}</p>
                      </div>
                  </div>
              `
            : "";
    };

    const totalAmountTemplate = () => {
        const tempClassMap = classMap({
            "total-currency": true,
            "underlined": props.showCurrencySelector,
        });

        return props.isTotalPending || isCurrentlyChangingCurrency()
            ? html` <span class="sidebar-total-loader"></span> `
            : props.model
              ? html`
                    <span class=${tempClassMap} data-test-id=${T.SIDEBAR.TOTAL_CURRENCY}>
                        ${currencySelectorTemplate()}
                    </span>
                    <span
                        class="total-amount"
                        data-test-id=${T.SIDEBAR.TOTAL_AMOUNT_VALUE_WITH_CURRENCY_SIGN}
                        datat-test-value=${props.model.QuotedTotal}
                    >
                        ${formatNumber({ amount: props.model.QuotedTotal, currency: getBookingCurrency() })}
                    </span>
                `
              : "";
    };

    const totalMilesTemplate = () =>
        props.isTotalPending || isCurrentlyChangingCurrency()
            ? html` <span class="sidebar-total-loader"></span> `
            : props.model
              ? html`
                    <span
                        class="font-black"
                        data-test-id=${T.SIDEBAR.TOTAL_MILES_VALUE}
                        datat-test-value=${props.model.BreakdownModel.RedemptionTotalMiles}
                    >
                        ${formatNumber({
                            amount: props.model.BreakdownModel.RedemptionTotalMiles,
                            leadingSign: false,
                            currency: REDEMPTION_MILES_CODE,
                        })}
                        <span class="ml-[2px] font-normal"> ${i18next.t("Miles")} </span>
                    </span>
                `
              : "";

    const currencySelectorTemplate = () =>
        props.isTotalPending || isCurrentlyChangingCurrency() || !currency
            ? ""
            : props.showCurrencySelector &&
                userContext.bancoEstado.category === 0 &&
                userContext.peruCompra.role === "none" &&
                !bookingContext.isPeruCompraBooking
              ? html`
                    <select
                        @change=${handleCurrencyChange}
                        class="flight-currency-select"
                        data-test-id=${T.SIDEBAR.CURRENCY_SWITCH}
                        data-test-value=${currency}
                    >
                        ${currencyOptions()}
                    </select>
                    <span class="currency-arrow">&#10095;</span>
                `
              : props.model?.Currency !== BRASIL_REAL_CODE
                ? html`<span class="" data-test-id=${T.SIDEBAR.CURRENCY_SWITCH} data-test-value=${props.model?.Currency}
                      >${maskCurrenciesForDisplay(props.model?.Currency)}</span
                  >`
                : "";

    const installmentsInfoTemplate = () =>
        showInstallmentsInfo()
            ? html` <p class="breakdown-installments-info">${props.model?.BreakdownModel.InstallmentInfo}</p> `
            : "";

    const containerClassMap = classMap({
        "hidden-sm-down": (props.farelock && !flowContext.isDcStandaloneFlow) || props.hideTotalOnMobile,
    });

    const contentClassMap = classMap({
        "total-final": true,
        "redemption-miles": props.isRedemptionTotal,
        "padded": showInstallmentsInfo() && !props.farelock,
    });

    const htmlTemplate = () => html`
        <div class=${containerClassMap} data-test-id=${getTestId(T.SIDEBAR.TOTAL, { m: props.isMobile })}>
            <div class=${contentClassMap}>
                ${farelockTotalTemplate()} ${bookingTotalTemplate()} ${redemptionMilesTotalTemplate()}
                ${bookingTotalInSolTemplate()}
            </div>
        </div>
    `;

    return { htmlTemplate };
};
