import { TestIdDictionary as T, getTestId } from "../../../testing-helpers/TestIdHelper";
import { useMemo, useVolatileState } from "../../../shared/haunted/CustomHooks";
import { OUTBOUND, REDEMPTION_MILES_CODE, USA_CULTURE_CODE } from "../../../shared/commonConstants";
import { html } from "haunted";
import i18next from "i18next";
import { useAppContext } from "../../../managers/useAppContext";
import { FlightPageContext } from "../../../component-models/flight/contexts/FlightPageContext";
import { useFlowContext } from "../../../managers/useFlowContext";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../shared/useNumberFormatter";

export interface Props {
    context: FlightPageContext;
    journeyIndex: number;
}

export const useBundleUpgradeOffer = (props: Props) => {
    const appContext = useAppContext();
    const flowContext = useFlowContext();

    const { formatNumber, getCurrencySign } = useNumberFormatter();

    const [currency] = useReduxState("booking.currency");

    const [isSizeReduced, setIsSizeReduced] = useVolatileState<boolean>(350);

    const flight = useMemo(
        () =>
            props.journeyIndex === OUTBOUND
                ? props.context?.flightState?.selectedOutboundFlight
                : props.context?.flightState?.selectedInboundFlight,
        [props.context?.flightState, props.journeyIndex],
    );

    const bundle = useMemo(
        () =>
            props.journeyIndex === OUTBOUND
                ? props.context?.bundleState?.selectedOutboundBundle
                : props.context?.bundleState?.selectedInboundBundle,
        [props.context?.bundleState, props.journeyIndex],
    );

    const upgradedBundle = useMemo(
        () => props.context.getUpgradedBundle(flight?.SellKey, bundle),
        [bundle, flight, props.context.getUpgradedBundle],
    );

    const bundleUpgradePriceDifference = useMemo(() => {
        if (!flight || !bundle || !upgradedBundle) return "";

        const currentCurrency = flowContext.isRedemptionFlow ? REDEMPTION_MILES_CODE : currency;

        return formatNumber({
            amount: upgradedBundle.UnFormattedPrice - bundle.UnFormattedPrice,
            leadingSign: false,
            currency: currentCurrency,
        });
    }, [props.context, upgradedBundle, bundle, currency, appContext.Culture]);

    const showUpgradeOffer = useMemo(
        () => !flowContext.isFlightMove && props.context.showBundleUpgradeOffer(bundle),
        [flowContext.isFlightMove, bundle, props.context?.bundleState],
    );

    // EVENT LISTENERS

    const handleBundleUpgrade = () => {
        setIsSizeReduced(true);
        props.context?.upgradeBundle(props.journeyIndex);
    };

    // TEMPLATES

    const mobileLogoTemplate = () => html`
        <img
            class="hidden-sm-up"
            src=${upgradedBundle?.UpgradeImgMobileUrl}
            data-test-id=${getTestId(T.BUNDLE.UPGRADE_IMAGE, { j: props.journeyIndex, m: true })}
        />
    `;

    const upgradeButtonTemplate = () => html`
        <div
            class="bundle-upgrade-button"
            data-test-value=${bundle.BundleCode}
            data-test-id=${getTestId(T.BUNDLE.UPGRADE_BUTTON, {
                j: props.journeyIndex,
            })}
            @click=${handleBundleUpgrade}
        >
            ${i18next.t("B-ChangeBundle")}<i class="js-icon js-circle-chevron-right"></i>
        </div>
    `;

    const usaTemplate = () =>
        appContext.Culture === USA_CULTURE_CODE
            ? html`
                  <div class="bup-text" style="color: ${upgradedBundle.Color};">&nbsp;${i18next.t("B-Additional")}</div>
              `
            : "";

    const nonUsaTemplate = () =>
        appContext.Culture !== USA_CULTURE_CODE
            ? html` <div class="bup-text" style="color: ${upgradedBundle.Color};">${i18next.t("B-Additional")}</div> `
            : "";

    const currencySignTemplate = () =>
        !flowContext.isRedemptionFlow ? html` <span class="bup-currency"> ${getCurrencySign(currency)} </span>` : "";

    const redemptionCurrencyTemplate = () =>
        flowContext.isRedemptionFlow ? html`<span class="bup-miles">${i18next.t("miles")}</span>` : "";

    const priceDifferenceTemplate = () =>
        bundleUpgradePriceDifference
            ? html`
                  <div
                      class="bup-value"
                      style="color: ${upgradedBundle.Color};"
                      data-test-id=${getTestId(T.BUNDLE.UPGRADE_PRICE_CONTAINER, { j: props.journeyIndex })}
                  >
                      ${currencySignTemplate()}
                      <span
                          class="bup-difference"
                          data-test-id=${getTestId(T.BUNDLE.UPGRADE_PRICE, { j: props.journeyIndex })}
                          >${bundleUpgradePriceDifference} ${redemptionCurrencyTemplate()}
                      </span>
                  </div>
              `
            : "";

    const priceTemplate = () => html`
        <div class="bundle-upgrade-price">
            <div class="bup-text" style="color: ${upgradedBundle.Color};">${i18next.t("B-ForOnly")}</div>
            ${usaTemplate()} ${priceDifferenceTemplate()} ${nonUsaTemplate()}
        </div>
    `;

    const upgradeTemplate = () => html`
        <div class="bundle-upgrade-box" style="border-color: ${upgradedBundle.Color};">
            <div class="bundle-upgrade-box-corner" style="background-color: ${upgradedBundle.Color};"></div>
            <div
                class="bundle-upgrade-title hidden-xs"
                data-test-id=${getTestId(T.BUNDLE.UPGRADE_TITLE, { j: props.journeyIndex })}
            >
                <span style="color: ${upgradedBundle.Color};">${i18next.t("B-UpgradeTitle01")}</span>
                <span style="color: ${upgradedBundle.Color};">${i18next.t("B-UpgradeTitle02")}</span>
            </div>

            ${mobileLogoTemplate()} ${upgradeButtonTemplate()} ${priceTemplate()}
        </div>
    `;

    const logoTemplate = () => html`
        <div
            class="bundle-upgrade-logo"
            style="background-color: ${upgradedBundle.Color}; border-color: ${upgradedBundle.Color};"
        >
            <img
                class="hidden-xs"
                src=${upgradedBundle?.UpgradeImgUrl}
                data-test-id=${getTestId(T.BUNDLE.UPGRADE_IMAGE, { j: props.journeyIndex })}
            />
            <div
                class="bundle-upgrade-title hidden-sm-up"
                data-test-id=${getTestId(T.BUNDLE.UPGRADE_TITLE, { j: props.journeyIndex, m: true })}
            >
                <span>${i18next.t("B-UpgradeTitle01")}</span>
                <span>${i18next.t("B-UpgradeTitle02")}</span>
            </div>
        </div>
    `;

    const htmlTemplate = () =>
        showUpgradeOffer
            ? html`
                  <div
                      class="bundle-upgrade-offer-container"
                      data-test-id=${getTestId(T.BUNDLE.UPGRADE_CONTAINER, { j: props.journeyIndex })}
                  >
                      ${upgradeTemplate()} ${logoTemplate()}
                  </div>
              `
            : "";

    return { htmlTemplate, isSizeReduced };
};
