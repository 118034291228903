import { BookingContext } from "../component-models/app/BookingContext";
import { toBoolean } from "../shared/common";
import { useMemo } from "../shared/haunted/CustomHooks";

export const useBookingContext = () => {
    const context: BookingContext = useMemo(
        () =>
            window.JetSmart.BookingContext
                ? {
                      adultsCount:
                          window.JetSmart.BookingContext.adults !== undefined
                              ? Number(window.JetSmart.BookingContext.adults)
                              : 0,
                      canGetStaffDiscount: toBoolean(window.JetSmart.BookingContext.canGetStaffDiscount),
                      childrenCount:
                          window.JetSmart.BookingContext.children !== undefined
                              ? Number(window.JetSmart.BookingContext.children)
                              : 0,
                      destinationCountryCode: window.JetSmart.BookingContext.destinationCountry ?? "",
                      destinationStationCode: window.JetSmart.BookingContext.destination ?? "",
                      hasBundleOutbound: toBoolean(window.JetSmart.BookingContext.hasBundleOutbound),
                      hasBundleInbound: toBoolean(window.JetSmart.BookingContext.hasBundleInbound),
                      infantsCount:
                          window.JetSmart.BookingContext.infants !== undefined
                              ? Number(window.JetSmart.BookingContext.infants)
                              : 0,
                      isBaggageInsuranceAvailable: toBoolean(
                          window.JetSmart.BookingContext.isBaggageInsuranceAvailable,
                      ),
                      isBancoEstadoBooking: toBoolean(window.JetSmart.BookingContext.isBancoEstadoBooking),
                      isCheckinClosedInbound: toBoolean(window.JetSmart.BookingContext.isCheckinClosedInbound),
                      isCheckinClosedOutbound: toBoolean(window.JetSmart.BookingContext.isCheckinClosedOutbound),
                      isDiscountBooking: toBoolean(window.JetSmart.BookingContext.isDiscountBooking),
                      isGdsBooking: toBoolean(window.JetSmart.BookingContext.isGdsBooking),
                      isInsuranceAvailable: toBoolean(window.JetSmart.BookingContext.isInsuranceAvailable),
                      isOneWay: toBoolean(window.JetSmart.BookingContext.isOneWay),
                      isPeruCompraBooking: toBoolean(window.JetSmart.BookingContext.isPeruCompraBooking),
                      isStaffBooking: toBoolean(window.JetSmart.BookingContext.isStaffBooking),
                      isChileCompraBooking: toBoolean(window.JetSmart.BookingContext.isChileCompraBooking),
                      originCountryCode: window.JetSmart.BookingContext.originCountry ?? "",
                      originStationCode: window.JetSmart.BookingContext.origin ?? "",
                      pnr: window.JetSmart.BookingContext.pnr ?? "",
                      promoCode: window.JetSmart.BookingContext.promoCode ?? "",
                      unformattedInboundDate: window.JetSmart.BookingContext.unformattedInboundDate ?? "",
                      unformattedOutboundDate: window.JetSmart.BookingContext.unformattedOutboundDate ?? "",
                  }
                : ({} as BookingContext),
        [],
    );

    return context;
};
