import i18next from "i18next";
import { html } from "lit-html";
import { WITHOUT_CHECKED_BAGGAGE_IMG } from "../../spa-container";

export const perJourneyPerPaxFreeChecked = () => {
    const htmlTemplate = () => html`
        <div class="b2m-per-pax-section padded">
            <div class="b2m-illustration">
                <img class="b2-free-checked-img" src=${WITHOUT_CHECKED_BAGGAGE_IMG} />
            </div>
            <div class="b2m-bag-name longer">${i18next.t("No quiero llevar equipaje facturado")}</div>
        </div>
    `;

    return { htmlTemplate };
};
