import { useEffect as hauntedUseEffect } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import { usePubSub } from "../../../../pub-sub-service/usePubSub";
import { HauntedFunc } from "../../../../shared/haunted/HooksHelpers";
import { useModal } from "../../../shared/useModal";
import { TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";

export const name = "ac-emergency-row-warning-modal";

export const Component: HauntedFunc<{}> = () => {
    const init = () => {
        const handler = triggers.seatmap.showEmergencyRowWarningModal.subscribe(() => {
            modal.open();
        });

        return () => handler.unsubscribe();
    };

    const { triggers } = usePubSub();

    hauntedUseEffect(init, []);

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div style="margin-bottom:5px;">${i18next.t("EmergencySeatRequirements")}</div>

            <ul class="terms-modal-list">
                <li>${i18next.t("EmergencySeatRequirement-01")}</li>
                <li>${i18next.t("EmergencySeatRequirement-02")}</li>
                <li>${i18next.t("EmergencySeatRequirement-03")}</li>
                <li>${i18next.t("EmergencySeatRequirement-04")}</li>
                <li>${i18next.t("EmergencySeatRequirement-05")}</li>
                <li>${i18next.t("EmergencySeatRequirement-06")}</li>
                <li>${i18next.t("EmergencySeatRequirement-07")}</li>
            </ul>

            <div>${i18next.t("EmergencySeatRequirement-08")}</div>
            <div
                class="modal-button-container modification-buttons-container"
                data-test-id=${T.SEATMAP.EMERGENCY_WARNING_MODAL_CLOSE_BUTTON}
            >
                <a @click=${() => modal.close()} class="rounded-primary-btn">${i18next.t("Common-Close")}</a>
            </div>
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content h-[720px]", template: htmlTemplate },
        header: { classNames: "text-center", template: () => i18next.t("EmergencyWarningTitle") },
        overlay: { classNames: "seatmap-warning-modal", testId: T.SEATMAP.EMERGENCY_WARNING_MODAL },
        scrolling: { isScrollable: true },
    });

    return modal.htmlTemplate();
};
