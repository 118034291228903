import { unsafeHTML } from "lit-html/directives/unsafe-html";
import i18next from "i18next";
import { ItineraryJourney } from "../../../component-models/itinerary/ApiItineraryViewModelV2";
import { showLoader } from "../../../shared/common";
import { SPANISH_LANGUAGE_CODE, OUTBOUND, INBOUND } from "../../../shared/commonConstants";
import dayjs from "dayjs";
import { useItineraryTealiumManager } from "../../../managers/Tealium/useItineraryTealiumManager";
import { useAppContext } from "../../../managers/useAppContext";
import { ItineraryPageViewModel } from "../../../component-models/itinerary/ItineraryPageViewModel";
import classNames from "classnames";
import { useAdckModal } from "../itinerary-modals/useAdckModal";
import { useCancelledFlightsModal } from "../itinerary-modals/useCancelledFlightsModal";
import { useDeclinedPaymentModal } from "../itinerary-modals/useDeclinedPaymentModal";
import { useQuickCheckinModal } from "../itinerary-modals/useQuickCheckinModal";
import { useFlowContext } from "../../../managers/useFlowContext";
import { html, useEffect } from "haunted";
import { useFlightJourneySummary } from "../../shared/flight-journey-summary/useFlightJourneySummary";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import * as UTC from "dayjs/plugin/utc";
import * as IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { useResendItineraryConfirmation } from "../useResendItineraryConfirmation";
dayjs.extend(CustomParseFormat);
dayjs.extend(UTC);
dayjs.extend(IsSameOrAfter);

export interface Props {
    model: ItineraryPageViewModel;
    cancelledJourneyIndices: number[];
}

export const useItineraryTabsOverview = (props: Props) => {
    const appContext = useAppContext();
    const flowContext = useFlowContext();

    const tealiumManager = useItineraryTealiumManager();

    const resendConfirmationEmail = useResendItineraryConfirmation({ model: props.model });

    const quickCheckinModal = useQuickCheckinModal({
        onYes: (journeyIndex: number) => handleCheckin(journeyIndex, true),
        onNo: (journeyIndex: number) => handleCheckin(journeyIndex, false),
    });

    const cancellationModal = useCancelledFlightsModal({ flightDesignators: props.model.CancelledFlightDesignators });

    const adckModal = useAdckModal();

    const declinedModal = useDeclinedPaymentModal({ isFlightless: !props.model.ItineraryModelV2.Journeys.length });

    const showTimeChangeBanner = (journey: ItineraryJourney) =>
        journey &&
        !flowContext.isBookingFlow &&
        journey.IsModificationPossible &&
        journey.Segments.some(
            (segment) =>
                (segment.FormattedEstimatedDepartureTime &&
                    dayjs(segment.FormattedEstimatedDepartureTime).format("HH:mm") !==
                        segment.FormattedDepartureTime) ||
                (segment.FormattedEstimatedArrivalTime &&
                    dayjs(segment.FormattedEstimatedArrivalTime).format("HH:mm") !== segment.FormattedArrivalTime),
        );

    const summaryOutbound = useFlightJourneySummary({
        journey: props.model.ItineraryModelV2.Journeys.find((j) => j.JourneyIndex === OUTBOUND),
        showBundleImage: false,
        showPill: true,
        showTimeChange: showTimeChangeBanner(
            props.model.ItineraryModelV2.Journeys.find((j) => j.JourneyIndex === OUTBOUND),
        ),
    });

    const summaryInbound = useFlightJourneySummary({
        journey: props.model.ItineraryModelV2.Journeys.find((j) => j.JourneyIndex === INBOUND),
        showBundleImage: false,
        showPill: true,
        showTimeChange: showTimeChangeBanner(
            props.model.ItineraryModelV2.Journeys.find((j) => j.JourneyIndex === INBOUND),
        ),
    });

    // HELPERS

    const init = () => {
        const hasUpcomingCancelledFlight = props.model.ItineraryModelV2.Journeys.some(
            (journey) =>
                props.cancelledJourneyIndices?.includes(journey.JourneyIndex) &&
                dayjs(journey.Segments[0].DepartureDate).isSameOrAfter(dayjs(), "day"),
        );

        if (hasUpcomingCancelledFlight) cancellationModal.open();
    };

    const getCheckinURL = (journeyIndex: number, purchaseFlow: boolean) =>
        `/V2/Checkin?rl=${props.model.ItineraryModelV2.Pnr}&em=${props.model.ItineraryModelV2.UserEmail}&ln=${props.model.ItineraryModelV2.UserLastName}&ji=${journeyIndex}&pf=${purchaseFlow}`;

    const logClick = (journeyIndex: number) => tealiumManager.logItineraryCheckinClicked(journeyIndex);

    // EVENT HANDLERS

    const handleCheckin = async (journeyIndex: number, purchaseFlow: boolean) => {
        await logClick(journeyIndex);
        const url = getCheckinURL(journeyIndex, purchaseFlow);
        showLoader({});
        window.location.href = url;
    };

    const openCheckinModal = async (journeyIndex: number) => {
        await logClick(journeyIndex);
        quickCheckinModal.open(journeyIndex);
    };

    const handleBoardingCards = async (journey: ItineraryJourney) => {
        await logClick(journey.JourneyIndex);
        const url = `/V2Checkin/Passengers?sk=${journey.EncodedSellKey}`;
        showLoader({});
        window.location.href = url;
    };

    useEffect(init, []);

    // TEMPLATES

    const checkinButton = (
        journeyIndex: number,
        onclick?: () => void,
        isDisabled = false,
        text: string = i18next.t("Check in"),
    ) => {
        const btnClassNames = classNames("rounded-primary-btn i2-btn i2-checkin-btn", {
            disabled: isDisabled,
        });

        const clickAction = props.cancelledJourneyIndices?.includes(journeyIndex)
            ? cancellationModal.open
            : onclick
              ? () => onclick()
              : undefined;

        return html` <a @click=${clickAction} class=${btnClassNames} data-test-id="checkin-button"> ${text} </a> `;
    };

    const departedWithNoPaxTemplate = (journeyIndex: number) => html`
        <span class="departured-nopax-button-container">
            ${checkinButton(journeyIndex, undefined, true, i18next.t("Check in cerrado"))}
            <ac-tooltip
                .icon=${"?"}
                .tooltip=${i18next.t("Tu vuelo ya se realizó o se hará dentro de los próximos {{time}} minutos.", {
                    time: props.model.ItineraryModelV2.Journeys[journeyIndex].CheckinBeforeTime,
                })}
                .customClass=${"i2-button-tooltip-opener"}
            ></ac-tooltip>
        </span>
    `;

    const pendingCheckinPaxTemplate = (paxAmount: number) => html`
        <div class="m-auto xl:w-3/4">
            ${i18next.t("{{paxAmount}} pasajero{{plural}} pendiente{{verbPlural}} de realizar su check in", {
                paxAmount,
                plural: paxAmount !== 1 ? "s" : "",
                verbPlural: paxAmount !== 1 && appContext.Language === SPANISH_LANGUAGE_CODE ? "s" : "",
            })}
        </div>
    `;

    const disabledSsrsCheckinButtonTemplate = (journey: ItineraryJourney) => html`
        <span class="disabled-button-container">
            ${checkinButton(journey.JourneyIndex, () => handleCheckin(journey.JourneyIndex, true), true)}
            <ac-tooltip
                .icon=${"?"}
                .customClass=${"i2-button-tooltip-opener"}
                .tooltip=${i18next.t(
                    "No es posible realizar su check in en linea dado que tu reserva tiene un requerimiento especial, por favor diríjase al counter del aeropuerto y haremos el check in por usted sin costo adicional.",
                )}
            ></ac-tooltip>
        </span>
    `;

    const unopenedCheckinButtonTemplate = (journeyIndex: number, date: string) => html`
        ${checkinButton(journeyIndex, undefined, true)}
        <div>
            ${i18next.t("Disponible el {{- date}}", {
                date,
            })}
        </div>
    `;

    const enabledCheckinButtonTemplate = (journey: ItineraryJourney) => html`
        ${checkinButton(journey.JourneyIndex, () => handleCheckin(journey.JourneyIndex, true))}
        ${pendingCheckinPaxTemplate(journey.AmountOfUncheckedPax)}
    `;

    const departureTooCloseCheckinButtonTemplate = (journey: ItineraryJourney) => html`
        ${checkinButton(journey.JourneyIndex, () => openCheckinModal(journey.JourneyIndex))}
        ${pendingCheckinPaxTemplate(journey.AmountOfUncheckedPax)}
    `;

    const quickCheckinButtonTemplate = (journey: ItineraryJourney) => html`
        ${checkinButton(journey.JourneyIndex, () => handleCheckin(journey.JourneyIndex, false))}
        ${pendingCheckinPaxTemplate(journey.AmountOfUncheckedPax)}
    `;

    const checkinButtonContainerTemplate = (journey: ItineraryJourney) => html`
        <div class="w-full text-center sm:w-1/3 lg:w-2/5 xl:w-1/3">${checkinButtonTemplate(journey)}</div>
    `;

    const checkinButtonTemplate = (journey: ItineraryJourney) => {
        switch (journey.CheckinStatus) {
            case "NotAvailable":
                return "";
            case "Enabled":
                return enabledCheckinButtonTemplate(journey);
            case "DepartedWithCheckedInPax":
                return checkinButton(
                    journey.JourneyIndex,
                    () => handleBoardingCards(journey),
                    false,
                    i18next.t("Tarjeta de embarque"),
                );
            case "DepartedWithNoPax":
                return departedWithNoPaxTemplate(journey.JourneyIndex);
            case "AllCheckedIn":
                return checkinButton(
                    journey.JourneyIndex,
                    () => handleCheckin(journey.JourneyIndex, true),
                    false,
                    i18next.t("Tarjeta de embarque"),
                );
            case "AdckNeeded":
                return checkinButton(journey.JourneyIndex, () => adckModal.open());
            case "DepartureTooClose":
                return departureTooCloseCheckinButtonTemplate(journey);
            case "SkipPostBooking":
                return quickCheckinButtonTemplate(journey);
            case "PendingPayment":
                return checkinButton(journey.JourneyIndex, () => declinedModal.open(), true);
            case "DisabledSsrs":
                return disabledSsrsCheckinButtonTemplate(journey);
            case "DepartureTooFar":
                return journey.CheckinWindowOpenDateUtc
                    ? unopenedCheckinButtonTemplate(
                          journey.JourneyIndex,
                          dayjs
                              .utc(journey.CheckinWindowOpenDateUtc, "DD-MM-YYYY HH:mm:ss")
                              .utcOffset(new Date().getTimezoneOffset() * -1)
                              .format("DD MMM. YY"),
                      )
                    : checkinButton(journey.JourneyIndex, undefined, true);
            default:
                return checkinButton(journey.JourneyIndex, undefined, true);
        }
    };

    const overviewDividerTemplate = (journeyIndex: number) =>
        journeyIndex === OUTBOUND && props.model.ItineraryModelV2.Journeys.length === 2
            ? html` <div class="i2-journey-divider"></div> `
            : "";

    const overviewJourneyTemplate = (journey: ItineraryJourney) => html`
        ${journeyTemplate(journey)} ${overviewDividerTemplate(journey.JourneyIndex)}
    `;

    const oneSegmentJourneyTemplate = (journey: ItineraryJourney) =>
        journey.Segments.length === 1
            ? html`
                  <div class="i2-journey-container">
                      ${journey.JourneyIndex === OUTBOUND
                          ? summaryOutbound.htmlTemplate()
                          : summaryInbound.htmlTemplate()}
                      ${checkinButtonContainerTemplate(journey)}
                  </div>
              `
            : "";

    const multisegmentJourneyTemplate = (journey: ItineraryJourney) =>
        journey.Segments.length > 1
            ? html`
                  <div class="i2-multisegment-journey-container">
                      ${journey.JourneyIndex === OUTBOUND
                          ? summaryOutbound.htmlTemplate()
                          : summaryInbound.htmlTemplate()}
                  </div>
                  <div class="flex w-full justify-end">${checkinButtonContainerTemplate(journey)}</div>
              `
            : "";

    const journeyTemplate = (journey: ItineraryJourney) => html`
        ${timeChangeBannerTemplate(journey)} ${oneSegmentJourneyTemplate(journey)}
        ${multisegmentJourneyTemplate(journey)}
    `;

    const timeChangeBannerTemplate = (journey: ItineraryJourney) =>
        showTimeChangeBanner(journey)
            ? html`
                  <div class="etd-banner">
                      <i class="js-icon-covid js-cv-timer"></i>
                      <div>
                          ${unsafeHTML(
                              i18next.t(
                                  "Etd-Banner {{-span1Start}}{{-span1End}}{{-depStation}}{{-arrStation}}{{-span2Start}}{{-span2End}}",
                                  {
                                      span1Start: "<span>",
                                      span1End: "</span>",
                                      depStation: journey.DepartureStationName,
                                      arrStation: journey.ArrivalStationName,
                                      span2Start: "<span>",
                                      span2End: "</span>",
                                  },
                              ),
                          )}
                      </div>
                  </div>
              `
            : "";

    const mainClassMap = classNames("i2-tab-title no-print", {
        "pull-up": showTimeChangeBanner(props.model.ItineraryModelV2.Journeys[0]),
    });

    const htmlTemplate = () => html`
        <div class=${mainClassMap}>
            <i class="js-icon-it js-it-landing-plane"></i>
            <div>${i18next.t("Itinerario de viaje")}</div>
        </div>
        <div class="no-print">${props.model.ItineraryModelV2.Journeys.map(overviewJourneyTemplate)}</div>
        ${resendConfirmationEmail.htmlTemplate()} ${adckModal.htmlTemplate()} ${cancellationModal.htmlTemplate()}
        ${declinedModal.htmlTemplate()} ${quickCheckinModal.htmlTemplate()}
    `;

    return { htmlTemplate };
};
