import classNames from "classnames";
import i18next from "i18next";
import { html } from "lit-html";
import { CHECKED_BAGGAGE_IMG } from "../../spa-container";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { baggageIllustrations } from "../common/baggageIllustrations";

export interface Props {
    formattedNextPrice: string;
    formattedOriginalPrice: string;
    isDiscountBooking: boolean;
    unformattedNextPrice: number;
    unformattedOriginalPrice: number;
}

export const perJourneyPerPaxPaidChecked = (props: Props) => {
    const { zippedBagTooltipTemplate } = baggageIllustrations();

    const minimumCheckedOriginalPriceTemplate = () =>
        props.isDiscountBooking && props.unformattedOriginalPrice >= 0
            ? html`
                  <div class="b2-per-booking-next-price reduced">
                      <span
                          class="line-through"
                          data-test-value=${props.unformattedNextPrice}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_CROSSED_OUT_MINIMUM_PRICE, {
                              c: "checkedBaggage",
                              m: true,
                          })}
                      >
                          ${props.formattedNextPrice}
                      </span>
                  </div>
              `
            : "";

    const checkedBagPricesTemplate = () => {
        const tempClassMap = classNames("b2-per-booking-next-price", {
            dc: props.isDiscountBooking && props.unformattedOriginalPrice,
        });

        return html`
            ${minimumCheckedOriginalPriceTemplate()}
            <div class=${tempClassMap}>
                ${i18next.t("Desde")}
                <span
                    class="b2-amount"
                    data-test-value=${props.unformattedOriginalPrice}
                    data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_MINIMUM_PRICE, {
                        c: "checkedBaggage",
                        m: true,
                    })}
                >
                    ${props.formattedOriginalPrice}
                </span>
            </div>
        `;
    };

    const htmlTemplate = () => {
        const tempClassMap = classNames("b2m-bag-name longer", {
            dc: props.isDiscountBooking && props.unformattedOriginalPrice,
        });

        return html`
            <div class="b2m-per-pax-section">
                <div class="b2-illustration-ribbon">${i18next.t("¡Recomendado para ti!")}</div>
                <div class="b2m-illustration">
                    <img class="b2-paid-checked-img" src=${CHECKED_BAGGAGE_IMG} />
                    <div class="b2m-weight">23kg.</div>
                    <div class="b2m-tooltip checked">${zippedBagTooltipTemplate()}</div>
                </div>
                <div class=${tempClassMap}>${i18next.t("Equipaje facturado")}</div>
                ${checkedBagPricesTemplate()}
            </div>
        `;
    };

    return { htmlTemplate };
};
