import { html, useRef } from "haunted";
import { useState } from "../../../shared/haunted/CustomHooks";
import { ROUTES } from "../../../shared/apiRoutes";
import i18next from "i18next";
import { GridHelper } from "../../../component-helpers/GridHelper";
import { CugReservation } from "../../../component-models/CUG2b/CugBookings";
import {
    Column,
    GridState,
    PageChangeEvent,
    RowsSelectedEvent,
    SortChangeEvent,
    ViewModel,
} from "../../../dc-components/dc-table-models";
import { ref } from "../../../directives/ref";
import { handleCugLoader, updateMdl } from "../../../shared/common";
import dayjs from "dayjs";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { ChangeDateEvent } from "../../../dc-components/dc-datepicker";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import { DEFAULT_DATE_FORMAT, CHILEAN_CULTURE_CODE } from "../../../shared/commonConstants";
import { useDatepicker } from "../../ui/useDatepicker/useDatepicker";
import classNames from "classnames";

export interface Props {
    data: CugReservation[];
    gridState: GridState<keyof CugReservation>;
    totalItemCount: number;
    canExport: boolean;
    url: string;
    containerElement: HTMLElement;
    gridElement: HTMLElement;
    filterFlightDateFrom: dayjs.Dayjs;
    filterFlightDateTo: dayjs.Dayjs;
    filterPurchaseDateFrom: dayjs.Dayjs;
    filterPurchaseDateTo: dayjs.Dayjs;
    selectedConfirmedIds: Set<string>;
    setSelectedConfirmedIds: (selectedIds: Set<string>) => void;
    setFilterFlightDateFrom: (newState: dayjs.Dayjs) => void;
    setFilterFlightDateTo: (newState: dayjs.Dayjs) => void;
    setFilterPurchaseDateFrom: (newState: dayjs.Dayjs) => void;
    setFilterPurchaseDateTo: (newState: dayjs.Dayjs) => void;
    setHasSubmittedDateFiltering: (newState: boolean) => void;
    setGridState: (newState: GridState<keyof CugReservation>) => void;
    loadConfirmedData: (value: boolean) => Promise<void>;
}

const actionCellClass = ["pinned"];
const actionCellContentClass = ["action-cell"];

// DEVNOTE ChileCompra: This component is not final. We are waiting for the final business requirements for the columns of the table.

export const useChileCompraConfirmedReservations = (props: Props) => {
    const inputField = useRef<HTMLInputElement>(undefined);
    const cug2AppContext = useCug2AppContext();

    const [filterExpression, setFilterExpression] = useState<string>(props.gridState.globalFilterExpression);

    // HELPERS

    const getToDate = () =>
        !props.filterFlightDateFrom ||
        !props.filterFlightDateTo ||
        props.filterFlightDateFrom.isBefore(props.filterFlightDateTo, "day")
            ? props.filterFlightDateTo
            : props.filterFlightDateFrom;

    const getToPurchaseDate = () =>
        !props.filterPurchaseDateFrom ||
        !props.filterPurchaseDateTo ||
        props.filterPurchaseDateFrom.isBefore(props.filterPurchaseDateTo, "day")
            ? props.filterPurchaseDateTo
            : props.filterPurchaseDateFrom;

    // EVENT LISTENERS

    const handleReport = () => {
        if (!props.url) return;

        window.open(props.url);
    };

    const handleFilterByExpression = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            inputField.current?.blur();
            props.setGridState({
                ...props.gridState,
                pageIndex: 0,
                globalFilterExpression: filterExpression,
            });
        }
    };

    const handleFilterByDateClick = () => {
        const filterForDates =
            Boolean(props.filterFlightDateFrom) ||
            Boolean(props.filterFlightDateTo) ||
            Boolean(props.filterPurchaseDateFrom) ||
            Boolean(props.filterPurchaseDateTo);

        // DEVNOTE Only submit filter params to BE if the user has at least once clicked Search next to the datepickers
        props.setHasSubmittedDateFiltering(filterForDates);
        props.setGridState({ ...props.gridState, pageIndex: 0 });
    };

    const handleEmptyDateFilters = async () => {
        props.setFilterFlightDateFrom(undefined);
        props.setFilterFlightDateTo(undefined);
        props.setFilterPurchaseDateFrom(undefined);
        props.setFilterPurchaseDateTo(undefined);
        props.setHasSubmittedDateFiltering(false);

        await props.loadConfirmedData(false);
    };

    const handleFilterExpressionChange = (e: Event) => {
        setFilterExpression((e.target as HTMLInputElement).value.trim());
    };

    const handleEditClick = (index: number, toExtras = false) => {
        handleCugLoader(props.containerElement, "toItinerary");
        const url = `${ROUTES.BookingRetrieve}/?em=${props.data[index].Contact}&rl=${
            props.data[index].ReservationCode
        }${toExtras ? "&toExtras=true" : ""}`;
        window.location.href = url;
    };

    const handleDownload = (selectedOnly: boolean) => {
        window.location.href =
            ROUTES.ApiRoutes.AgencyReservationsCsv +
            `?bookingStatus=Confirmed` +
            `&pageSize=1000` +
            `&page=0` +
            `&orderBy=FlightDate` +
            `&orderDirection=asc` +
            `&TimeZoneOffsetMin=${(new Date().getTimezoneOffset() * -1).toString()}` +
            `&IsCsvRequested=true` +
            (selectedOnly ? `&spnrs=${Array.from(props.selectedConfirmedIds.values()).join("|")}` : "");
    };

    const onConfirmedPageChange = (e: PageChangeEvent) => {
        props.setGridState({
            ...props.gridState,
            pageIndex: e.detail.selectedPageIndex,
            pageSize: e.detail.selectedPageSize,
        });
    };

    const onConfirmedRowsSelect = (event: RowsSelectedEvent) => {
        props.setSelectedConfirmedIds(
            GridHelper.getSelectedIds(event, props.selectedConfirmedIds, props.data) as Set<string>,
        );
    };

    const onConfirmedSortChange = (e: SortChangeEvent) => {
        props.setGridState({
            ...props.gridState,
            orderBy: e.detail.orderBy as keyof CugReservation,
            orderDir: e.detail.orderDir,
        });
    };

    // COMPONENT

    const fromDatePicker = useDatepicker({
        classes: [],
        inputClasses: ["cug2-filter-date-input", "cug2-filter-date-from"],
        placeHolder: i18next.t("Desde"),
        value: props.filterFlightDateFrom,
        onChange: (e: ChangeDateEvent) => props.setFilterFlightDateFrom(e.detail.date),
    });

    const toDatePicker = useDatepicker({
        classes: ["sm-align-right", "xs-align-right"],
        inputClasses: ["cug2-filter-date-input", "cug2-filter-date-to"],
        placeHolder: i18next.t("Hasta"),
        value: props.filterFlightDateFrom
            ? [
                  props.filterFlightDateFrom.format(DEFAULT_DATE_FORMAT) || "",
                  getToDate()?.format(DEFAULT_DATE_FORMAT) || "",
              ]
            : getToDate()?.format(DEFAULT_DATE_FORMAT),
        min: props.filterFlightDateFrom || undefined,
        isRange: Boolean(props.filterFlightDateFrom),
        onChange: (e: ChangeDateEvent) =>
            props.setFilterFlightDateTo(Boolean(props.filterFlightDateFrom) ? e.detail.toDate : e.detail.date),
    });

    const purchaseFromDatePicker = useDatepicker({
        classes: ["align-right", "sm-align-left", "xs-align-left"],
        inputClasses: ["cug2-filter-date-input", "cug2-filter-date-from"],
        placeHolder: i18next.t("Desde"),
        value: props.filterPurchaseDateFrom,
        onChange: (e: ChangeDateEvent) => props.setFilterPurchaseDateFrom(e.detail.date),
    });

    const purchaseToDatePicker = useDatepicker({
        classes: ["align-right", "sm-align-right", "xs-align-right"],
        inputClasses: ["cug2-filter-date-input", "cug2-filter-date-to"],
        placeHolder: i18next.t("Hasta"),
        value: props.filterPurchaseDateFrom
            ? [
                  props.filterPurchaseDateFrom.format(DEFAULT_DATE_FORMAT) || "",
                  getToPurchaseDate()?.format(DEFAULT_DATE_FORMAT) || "",
              ]
            : getToPurchaseDate()?.format(DEFAULT_DATE_FORMAT),
        min: props.filterPurchaseDateFrom || undefined,
        isRange: Boolean(props.filterPurchaseDateFrom),
        onChange: (e: ChangeDateEvent) =>
            props.setFilterPurchaseDateTo(Boolean(props.filterPurchaseDateFrom) ? e.detail.toDate : e.detail.date),
    });

    // TEMPLATES

    const rowEditTemplate = (index: number) => html`
        <div class=${GridHelper.getClassMap([...actionCellClass, "dctg-body-cell"])}>
            <div class=${GridHelper.getClassMap(actionCellContentClass)}>
                <i class="js-icon-cug js-cug-edit" @click=${() => handleEditClick(index)}></i>
            </div>
        </div>
    `;

    const cellTemplate = (index: number, field: keyof CugReservation) => {
        const row = props.data[index];

        if (field) {
            return html` <span title=${row[field]?.toString() || ""}>${row[field]?.toString() || ""}</span> `;
        }

        return "";
    };

    const filterTemplate = () => {
        return html`
            <div class="cug2b-search-and-title push-down">
                <div class="cug2b-page-subtitle">${i18next.t("Reservas confirmadas")}</div>
                <div class="cug2b-searchbox">
                    <input
                        placeholder=${i18next.t("Buscar")}
                        ref=${ref(inputField)}
                        value=${filterExpression}
                        autocomplete="cc-exp"
                        type="text"
                        name="filter"
                        @keydown=${handleFilterByExpression}
                        @input=${handleFilterExpressionChange}
                    />
                    <i class="js-icon-cug js-cug-search"></i>
                </div>
            </div>
        `;
    };

    const filterByDateTemplate = () => {
        return html`
            <div class="cug2-filter-by-date-container">
                <div class="cug2-filter-by-date push-down">
                    <div class="cug2-label-and-datepickers">
                        <label>
                            ${unsafeHTML(
                                i18next.t("Filtrar por {{-spanStart}}fecha de vuelo:{{-spanEnd}}", {
                                    spanStart: '<span class="font-bold">',
                                    spanEnd: "</span>",
                                }),
                            )}
                        </label>
                        <div class="cug2-datepickers">
                            ${fromDatePicker.htmlTemplate()} ${toDatePicker.htmlTemplate()}
                        </div>
                    </div>
                    <div class="cug2-label-and-datepickers">
                        <label>
                            ${unsafeHTML(
                                i18next.t("Filtrar por {{-spanStart}}fecha de compra:{{-spanEnd}}", {
                                    spanStart: '<span class="font-bold">',
                                    spanEnd: "</span>",
                                }),
                            )}
                        </label>
                        <div class="cug2-datepickers">
                            ${purchaseFromDatePicker.htmlTemplate()} ${purchaseToDatePicker.htmlTemplate()}
                        </div>
                    </div>
                    <div class="cug2-filter-button-container">
                        <button class="rounded-primary-btn cug2b-btn" @click=${handleFilterByDateClick}>
                            ${i18next.t("Buscar")}
                        </button>
                    </div>
                </div>
                <div class="flex justify-end">
                    <div class="cug2-empty-filters" @click=${handleEmptyDateFilters}>
                        ${i18next.t("Limpiar Filtros")}
                    </div>
                </div>
            </div>
        `;
    };

    const exportInvoicesTemplate = () =>
        props.url && cug2AppContext.Culture === CHILEAN_CULTURE_CODE
            ? html`
                  <button
                      @click=${handleReport}
                      class=${classNames("rounded-primary-btn cug2b-btn sm:mr-3", {
                          "mb-4 sm:mb-0": props.canExport,
                      })}
                  >
                      ${i18next.t("Ver facturas")}
                  </button>
              `
            : "";

    const exportReservationsTemplate = () =>
        props.canExport
            ? html`
                  <button
                      @click=${() => handleDownload(false)}
                      class="rounded-primary-btn cug2b-btn mb-4 sm:mb-0 sm:mr-3"
                  >
                      ${i18next.t("CUG-Download")}
                  </button>
                  <button
                      @click=${() => handleDownload(true)}
                      class=${classNames("rounded-primary-btn cug2b-btn", {
                          disabled: Array.from(props.selectedConfirmedIds.values()).length === 0,
                      })}
                  >
                      ${i18next.t("CUG-DownloadSelected")}
                  </button>
              `
            : "";

    const exportTemplate = () =>
        props.canExport
            ? html`
                  <div class="mt-8 flex flex-col items-center justify-end sm:flex-row">
                      ${exportInvoicesTemplate()} ${exportReservationsTemplate()}
                  </div>
              `
            : "";

    const vm: ViewModel<keyof CugReservation> = {
        columns: [
            {
                field: "ReservationCode",
                columnType: "string",
                label: i18next.t("Código reserva"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "ReservationCode"),
            } as Column<keyof CugReservation>,
            {
                field: "Route",
                columnType: "string",
                label: i18next.t("Ruta"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "Route"),
            } as Column<keyof CugReservation>,
            {
                field: "FormattedFlightDate",
                columnType: "string",
                label: i18next.t("Fecha de vuelo"),
                sortable: true,
                headerTemplate: html`
                    <div class="flex items-center">
                        ${i18next.t("Fecha de vuelo")}
                        <ac-tooltip
                            class="hidden-xs"
                            .icon=${"?"}
                            .tooltip=${i18next.t("CUG2-FlightDateTooltip")}
                            onclick="event.stopPropagation();"
                        ></ac-tooltip>
                    </div>
                `,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "FormattedFlightDate"),
            } as Column<keyof CugReservation>,
            {
                field: "FormattedPassengers",
                columnType: "string",
                label: i18next.t("Cant. de pasajeros"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "FormattedPassengers"),
            } as Column<keyof CugReservation>,
            {
                field: "CreatedBy",
                columnType: "string",
                label: i18next.t("Creado por"),
                sortable: true,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "CreatedBy"),
            } as Column<keyof CugReservation>,
            {
                field: "LatestPaymentAddedBy",
                columnType: "string",
                label: i18next.t("Pagado por"),
                sortable: true,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "LatestPaymentAddedBy"),
            } as Column<keyof CugReservation>,
            {
                field: "CreatedByAgent",
                columnType: "string",
                label: i18next.t("Usuario"),
                sortable: true,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "CreatedByAgent"),
            } as Column<keyof CugReservation>,
            {
                field: "Contact",
                columnType: "string",
                label: i18next.t("Contacto"),
                sortable: true,
                cellClass: () => "text-center cug2b-cell-ellipsis",
                cellTemplate: (index: number) => cellTemplate(index, "Contact"),
            } as Column<keyof CugReservation>,
            {
                field: "FormattedTotalAmount",
                columnType: "string",
                label: i18next.t("Monto pagado"),
                sortable: true,
                cellClass: () => "text-center",
                cellTemplate: (index: number) => cellTemplate(index, "FormattedTotalAmount"),
            } as Column<keyof CugReservation>,
        ],
        data: props.data,
        paging: {
            pageable: true,
            pageIndex: props.gridState.pageIndex,
            pageSize: props.gridState.pageSize,
            buttonCount: 5,
            pageSizes: [10],
            itemCount: props.totalItemCount,
            showInfo: false,
        },
        sorting: {
            orderBy: props.gridState.orderBy,
            orderDir: props.gridState.orderDir,
            showSorterArrow: false,
        },
        selection: {
            selectable: true,
            selectedIndices: Array.from(props.selectedConfirmedIds.values()).map((id) =>
                props.data.map((i) => i.Id).indexOf(id),
            ),
        },
        rowCustomization: [],
        appliedFilters: [],
        rowEditTemplate,
        actionCellClass,
        actionCellContentClass,
        useEllipsis: false,
    };

    const htmlTemplate = () =>
        props.gridState && props.data
            ? html`
                  ${updateMdl()} ${filterTemplate()} ${filterByDateTemplate()}
                  <div class="cug2b-confirmed-reservation-scrolling-tip">
                      ${i18next.t("Deslizando hacia la derecha en la tabla podrás visualizar más campos.")}
                  </div>
                  <dc-table-grid
                      .vm=${vm}
                      @onSortChange=${onConfirmedSortChange}
                      @onRowsSelect=${onConfirmedRowsSelect}
                      @onPageChange=${onConfirmedPageChange}
                  ></dc-table-grid
                  >${exportTemplate()}
              `
            : "";

    return { htmlTemplate };
};
