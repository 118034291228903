import i18next from "i18next";
import { useMemo } from "../../../shared/haunted/CustomHooks";
import { html } from "haunted";
import { useAppContext } from "../../../managers/useAppContext";
import { ROUTES } from "../../../shared/apiRoutes";
import { useModal } from "../../shared/useModal";
import { useFlowContext } from "../../../managers/useFlowContext";

export interface Props {
    isFlightless: boolean;
}

export const useDeclinedPaymentModal = (props: Props) => {
    const appContext = useAppContext();
    const flowContext = useFlowContext();

    const showCloseButton = useMemo(
        () => !flowContext.isDcStandaloneFlow && !flowContext.isGiftcardPurchaseFlow && !flowContext.isCugFundingFlow,
        [appContext],
    );

    const headerTemplate = () => html`
        <i class="js-icon js-triangle-exclamation"></i>
        <span>${i18next.t("V2-DeclinedPaymentTitle")}</span>
    `;

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="declined-modal-description-container">
                <div class="declined-modal-payment-subtitle">${i18next.t("DeclinedPaymentInfo-1")}</div>
                <div>1. ${i18next.t("DeclinedPaymentInfo-2")}</div>
                <div>2. ${i18next.t("DeclinedPaymentInfo-3")}</div>
                <div>3. ${i18next.t("DeclinedPaymentInfo-4")}</div>
            </div>
            <div class="primary-modal-button-container">
                <a
                    href=${props.isFlightless ? ROUTES.FlightlessPayment : ROUTES.PagePayment}
                    class="rounded-primary-btn"
                >
                    ${i18next.t("DeclinedPaymentBtn")}
                </a>
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: showCloseButton },
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { template: headerTemplate },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};
