import { ROUTES } from "./../../shared/apiRoutes";
import { ACTION_NAMES, AGENCY_TYPE } from "./../../shared/commonConstants";
import i18next from "i18next";
import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { classMap } from "lit-html/directives/class-map";
import { handleCugLoader, toBoolean } from "../../shared/common";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { useCug2AppContext } from "../../managers/useCug2AppContext";
import { useReduxState } from "../../shared/redux/useReduxState";

export const observedAttributes: (keyof Attributes)[] = [
    "can-see-company",
    "can-see-profile",
    "can-see-reservations",
    "can-see-users",
    "cug-report-url",
    "type",
    "is-perucompra-user",
];
export const name = "ac-cug-sidebar";
export interface Attributes {
    "can-see-company": string;
    "can-see-profile": string;
    "can-see-reservations": string;
    "can-see-users": string;
    "cug-report-url": string;
    "type": string;
    "is-perucompra-user": string;
}
export interface Properties {
    canSeeCompany: boolean;
    canSeeProfile: boolean;
    canSeeReservations: boolean;
    canSeeUsers: boolean;
    cugReportUrl: string;
    type: "Agency" | "Company";
    isPerucompraUser: boolean;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        canSeeCompany: toBoolean(host.canSeeCompany),
        canSeeProfile: toBoolean(host.canSeeProfile),
        canSeeReservations: toBoolean(host.canSeeReservations),
        canSeeUsers: toBoolean(host.canSeeUsers),
        cugReportUrl: host.cugReportUrl,
        type:
            typeof host.type === "string" && host.type.toLowerCase().startsWith(AGENCY_TYPE.toLowerCase())
                ? "Agency"
                : "Company",
        isPerucompraUser: toBoolean(host.isPerucompraUser),
    };

    // HELPERS

    // EVENT LISTENERS

    const handleClick = (e: MouseEvent, href: string) => {
        e.preventDefault();
        e.stopPropagation();

        handleCugLoader(DomCrawlingHelper.getElemByClass(document.body, "cug2b-frame"), "loadOtherPage");

        window.location.href = href;
    };

    // COMPONENT

    const cug2AppContext = useCug2AppContext();

    const [userContext] = useReduxState("userContext");

    // TEMPLATES

    const summaryTemplate = () => {
        const tempClassMap = classMap({
            "cug2b-sidebar-item": true,
            "active": cug2AppContext.Action === ACTION_NAMES.SUMMARY.toLowerCase(),
            "pcompra-org-label": userContext.peruCompra.role !== "none" || userContext.chileCompra.role !== "none",
        });

        const label = props.isPerucompraUser
            ? i18next.t("Mi Organización")
            : props.type === "Agency"
              ? i18next.t("Mi Agencia")
              : props.type === "Company"
                ? i18next.t("Mi Empresa")
                : "";

        return props.canSeeCompany
            ? html`
                  <a
                      class=${tempClassMap}
                      data-test-id=${T.CUG2_SIDEBAR.SUMMARY}
                      @click=${(e: MouseEvent) => handleClick(e, ROUTES.Cug2BSummaryPage)}
                  >
                      <i class="js-icon-cug js-cug-company"></i>
                      <span>${label}</span>
                  </a>
              `
            : "";
    };

    const profileTemplate = () => {
        const tempClassMap = classMap({
            "cug2b-sidebar-item": true,
            "active":
                cug2AppContext.Action === ACTION_NAMES.CUGPROFILE.toLowerCase() ||
                cug2AppContext.Action === ACTION_NAMES.EDITCUGPROFILE.toLowerCase(),
        });

        return props.canSeeProfile
            ? html`
                  <a
                      class=${tempClassMap}
                      data-test-id=${T.CUG2_SIDEBAR.PROFILE}
                      @click=${(e: MouseEvent) => handleClick(e, ROUTES.Cug2BProfilePage)}
                  >
                      <i class="js-icon-cug js-cug-man-and-plane"></i>
                      <span>${i18next.t("Mi Perfil")}</span>
                  </a>
              `
            : "";
    };

    const usersTemplate = () => {
        const tempClassMap = classMap({
            "cug2b-sidebar-item": true,
            "active": cug2AppContext.Action === ACTION_NAMES.USERS.toLowerCase(),
        });

        return props.canSeeUsers || userContext.peruCompra.role === "admin"
            ? html`
                  <a
                      class=${tempClassMap}
                      data-test-id=${T.CUG2_SIDEBAR.USERS}
                      @click=${(e: MouseEvent) => handleClick(e, ROUTES.Cug2BUsersPage)}
                  >
                      <i class="js-icon-cug js-cug-group"></i>
                      <span>${i18next.t("Usuarios")}</span>
                  </a>
              `
            : "";
    };

    const travelPartnersTemplate = () => {
        if (userContext.peruCompra.role !== "none" || userContext.chileCompra.role !== "none") {
            return "";
        }

        const isPageActive = [
            ACTION_NAMES.FFLYERS,
            ACTION_NAMES.FFLYERS_ADD,
            ACTION_NAMES.FFLYERS_ADMIN_GROUP,
            ACTION_NAMES.FFLYERS_CREATE_GROUP,
            ACTION_NAMES.FFLYERS_EDIT_GROUP,
            ACTION_NAMES.FFLYERS_GROUPS,
        ].some((action) => cug2AppContext.Action === action.toLowerCase());

        const isAddPaxActive = cug2AppContext.Action === ACTION_NAMES.FFLYERS_ADD.toLowerCase();

        const isGroupsActive = [
            ACTION_NAMES.FFLYERS_ADMIN_GROUP,
            ACTION_NAMES.FFLYERS_CREATE_GROUP,
            ACTION_NAMES.FFLYERS_EDIT_GROUP,
            ACTION_NAMES.FFLYERS_GROUPS,
        ].some((action) => cug2AppContext.Action === action.toLowerCase());

        const travelPartnersPageClassMap = classMap({
            "cug2b-sidebar-item": true,
            "can-click-active": isAddPaxActive || isGroupsActive,
            "active": isPageActive,
        });

        const AddTravelPartnerClassMap = classMap({
            "cug2b-sidebar-sub-item": true,
            "active": isAddPaxActive,
        });

        const TravelGroupsPageClassMap = classMap({
            "cug2b-sidebar-sub-item": true,
            "no-border": true,
            "active": isGroupsActive,
        });

        return html`
            <a
                class=${travelPartnersPageClassMap}
                data-test-id=${T.CUG2_SIDEBAR.FREQUENT_USERS}
                @click=${(e: MouseEvent) => handleClick(e, ROUTES.Cug2BTravelPartnersPage)}
            >
                <i class="js-icon-cug js-cug-man-and-star"></i>
                <span>${i18next.t("Pasajeros Frecuentes")}</span>
            </a>
            ${isPageActive
                ? html`
                      <a
                          class=${AddTravelPartnerClassMap}
                          @click=${(e: MouseEvent) => handleClick(e, ROUTES.Cug2BAddPartnerPage)}
                      >
                          <span>${i18next.t("Agregar pasajeros")}</span>
                      </a>
                      <a
                          class=${TravelGroupsPageClassMap}
                          @click=${(e: MouseEvent) => handleClick(e, ROUTES.Cug2BTravelGroupsPage)}
                      >
                          <span>${i18next.t("Grupos")}</span>
                      </a>
                  `
                : ""}
        `;
    };

    const reservationsTemplate = () => {
        const tempClassMap = classMap({
            "cug2b-sidebar-item": true,
            "active": cug2AppContext.Action === ACTION_NAMES.RESERVATIONS.toLowerCase(),
            "condensed": userContext.peruCompra.role !== "none",
        });

        return props.canSeeReservations
            ? html`
                  <a
                      class=${tempClassMap}
                      data-test-id=${T.CUG2_SIDEBAR.RESERVATIONS}
                      @click=${(e: MouseEvent) => handleClick(e, ROUTES.Cug2BReservationsPage)}
                  >
                      <i class="js-icon-cug js-cug-finger"></i>
                      <span
                          >${userContext.peruCompra.role !== "none"
                              ? i18next.t("Movimientos")
                              : i18next.t("Reservas")}</span
                      >
                  </a>
              `
            : "";
    };

    const reportTemplate = () =>
        props.cugReportUrl
            ? html`
                  <a
                      class="cug2b-sidebar-item hidden-xs"
                      @click=${(e: MouseEvent) => handleClick(e, props.cugReportUrl)}
                  >
                      <i class="js-icon-cug js-cug-chart"></i>
                      <span>${i18next.t("Reporte")}</span>
                  </a>
              `
            : "";

    const contactTemplate = () => {
        const tempClassMap = classMap({
            "cug2b-sidebar-item": true,
            "active": cug2AppContext.Action === ACTION_NAMES.CONTACT.toLowerCase(),
        });

        return html`
            <a
                class=${tempClassMap}
                data-test-id=${T.CUG2_SIDEBAR.CONTACT}
                @click=${(e: MouseEvent) => handleClick(e, ROUTES.Cug2BContactPage)}
            >
                <i class="js-icon-cug js-cug-phone"></i>
                <span>${i18next.t("Contacto")}</span>
            </a>
        `;
    };

    const mobileButtonsTemplate = () => html`
        <div class="w-full">
            ${props.cugReportUrl
                ? html`
                      <a
                          class="rounded-primary-btn mobile-cug-sidebar mt-4"
                          @click=${(e: MouseEvent) => handleClick(e, props.cugReportUrl)}
                      >
                          ${i18next.t("Reporte")}
                      </a>
                  `
                : ""}
            ${goHomeButtonTemplate()}
        </div>
    `;

    const goHomeButtonTemplate = () => html`
        <a
            class="rounded-primary-btn mt-4 sm:mt-0"
            data-test-id=${T.CUG2_SIDEBAR.GO_HOME_BUTTON}
            @click=${(e: MouseEvent) => handleClick(e, "/")}
        >
            ${userContext.peruCompra.role !== "none" ? i18next.t("Emite tu ticket") : i18next.t("Haz tu reserva")}
        </a>
    `;

    const mainClassMap = classMap({
        "cug2b-sidebar": true,
        "rounded": cug2AppContext.Action !== ACTION_NAMES.SUMMARY && userContext.chileCompra.role === "none",
    });

    return userContext?.userRole
        ? html`
              <div class=${mainClassMap}>
                  ${summaryTemplate()} ${profileTemplate()} ${usersTemplate()} ${travelPartnersTemplate()}
                  ${reservationsTemplate()} ${reportTemplate()} ${contactTemplate()}
                  <div class="hidden-xs mt-16 inline-block">${goHomeButtonTemplate()}</div>
              </div>
              <div class="cug2b-sidebar-mobile-buttons hidden-sm-up">${mobileButtonsTemplate()}</div>
          `
        : html``;
};
