import { html, useEffect, useRef } from "haunted";
import { ApiPortalSpaViewModel } from "../../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import { useMemo, useState } from "../../../shared/haunted/CustomHooks";
import dayjs from "dayjs";
import i18next from "i18next";
import { CugReservation } from "../../../component-models/CUG2b/CugBookings";
import { GridState } from "../../../dc-components/dc-table-models";
import { ref } from "../../../directives/ref";
import { handleCugLoader } from "../../../shared/common";
import { usePortalReservationsUtils } from "./usePortalReservationsUtils";
import classNames from "classnames";
import { useChileCompraConfirmedReservations } from "./useChileCompraConfirmedReservations";
import { useChileCompraOnHoldReservations } from "./useChileCompraOnHoldReservations";

export interface Props {
    model: ApiPortalSpaViewModel;
}

export const useChileCompraReservationsPage = (props: Props) => {
    const { getReportUrl, loadCugData } = usePortalReservationsUtils();

    const initialGridState = useMemo<GridState<keyof CugReservation>>(
        () => ({
            pageIndex: 0,
            appliedFilters: [],
            pageSize: 10,
            orderBy: "FormattedFlightDate",
            orderDir: "desc",
            globalFilterExpression: "",
        }),
        [],
    );

    const root = useRef<HTMLDivElement>(undefined);
    const confirmedGrid = useRef<HTMLDivElement>(undefined);
    const pendingGrid = useRef<HTMLDivElement>(undefined);

    const [confirmedGridState, setConfirmedGridState] = useState<GridState<keyof CugReservation>>(initialGridState);
    const [selectedConfirmedIds, setSelectedConfirmedIds] = useState<Set<string>>(new Set<string>());
    const [confirmedData, setConfirmedData] = useState<CugReservation[]>(undefined);
    const [totalConfirmedItemCount, setTotalConfirmedItemCount] = useState<number>(0);

    const [pendingGridState, setPendingGridState] = useState<GridState<keyof CugReservation>>(initialGridState);
    const [selectedPendingIds, setSelectedPendingIds] = useState<Set<string>>(new Set<string>());
    const [pendingData, setPendingData] = useState<CugReservation[]>(undefined);
    const [totalPendingItemCount, setTotalPendingItemCount] = useState<number>(0);

    const [filterFlightDateFrom, setFilterFlightDateFrom] = useState<dayjs.Dayjs>(undefined);
    const [filterFlightDateTo, setFilterFlightDateTo] = useState<dayjs.Dayjs>(undefined);
    const [filterPurchaseDateFrom, setFilterPurchaseDateFrom] = useState<dayjs.Dayjs>(undefined);
    const [filterPurchaseDateTo, setFilterPurchaseDateTo] = useState<dayjs.Dayjs>(undefined);
    // DEVNOTE Only submit filter params to BE if the user has at least once clicked Search next to the datepickers
    const [hasSubmittedDateFiltering, setHasSubmittedDateFiltering] = useState<boolean>(false);

    // HELPERS

    const init = async () => {
        await Promise.all([loadPendingData(), loadConfirmedData()]);
    };

    const loadPendingData = async () => {
        handleCugLoader(pendingGrid.current, "loadPendingData");

        const { newData, totalCount } = await loadCugData(
            { bookingStatus: "Hold", gridState: pendingGridState },
            root.current,
            false,
        );

        setPendingData(newData);
        setTotalPendingItemCount(totalCount);

        handleCugLoader(pendingGrid.current, "loadPendingData");
    };

    const loadConfirmedData = async (filterForDates = hasSubmittedDateFiltering) => {
        handleCugLoader(confirmedGrid.current, "loadConfirmedData");

        const { newData, totalCount } = await loadCugData(
            { bookingStatus: "Confirmed", gridState: confirmedGridState },
            root.current,
            filterForDates,
            filterFlightDateFrom,
            filterFlightDateTo,
            filterPurchaseDateFrom,
            filterPurchaseDateTo,
        );

        setConfirmedData(newData);
        setTotalConfirmedItemCount(totalCount);

        handleCugLoader(confirmedGrid.current, "loadConfirmedData");
    };

    // COMPONENT

    const pendingReservations = useChileCompraOnHoldReservations({
        data: pendingData,
        totalItemCount: totalPendingItemCount,
        gridState: pendingGridState,
        canExport: props.model.ReservationsViewModel.CanExport,
        containerElement: root.current,
        gridElement: pendingGrid.current,
        selectedPendingIds,
        setSelectedPendingIds,
        setGridState: setPendingGridState,
        loadPendingData,
    });

    const confirmedReservations = useChileCompraConfirmedReservations({
        data: confirmedData,
        gridState: confirmedGridState,
        totalItemCount: totalConfirmedItemCount,
        canExport: props.model.ReservationsViewModel.CanExport,
        url: props.model.ReservationsViewModel.CanSeeReports
            ? getReportUrl(props.model.ReservationsViewModel.Url, props.model.ReservationsViewModel.OrgCode)
            : "",
        containerElement: root.current,
        gridElement: confirmedGrid.current,
        filterFlightDateFrom,
        filterFlightDateTo,
        filterPurchaseDateFrom,
        filterPurchaseDateTo,
        selectedConfirmedIds,
        setSelectedConfirmedIds,
        setFilterFlightDateFrom,
        setFilterFlightDateTo,
        setFilterPurchaseDateFrom,
        setFilterPurchaseDateTo,
        setHasSubmittedDateFiltering,
        setGridState: setConfirmedGridState,
        loadConfirmedData,
    });

    useEffect(() => (confirmedData ? loadConfirmedData() : null), [confirmedGridState]);
    useEffect(() => (pendingData ? loadPendingData() : null), [pendingGridState]);


    // TEMPLATES

    const pendingReservationsGridTemplate = () => html`
        <div ref=${ref(pendingGrid)} class=${classNames({ "relative min-h-1/4 w-full": !pendingData })}>
            ${pendingReservations.htmlTemplate()}
        </div>
    `;

    const confirmedReservationsGridTemplate = () => html`
        <div ref=${ref(confirmedGrid)} class=${classNames({ "relative min-h-1/4 w-full": !confirmedData })}>
            ${confirmedReservations.htmlTemplate()}
        </div>
    `;

    const htmlTemplate = () => html`
        <div ref=${ref(root)} class="w-full">
            <div class="cug2b-title-container">
                <i class="js-icon-cug js-cug-finger"></i>
                <div>
                    <h1>${i18next.t("Reservas")}</h1>
                    <h2>${i18next.t("Reservas con pago pendiente / Reservas confirmadas")}</h2>
                </div>
            </div>
            ${pendingReservationsGridTemplate()} ${confirmedReservationsGridTemplate()}
        </div>
    `;

    return { init, htmlTemplate };
};
