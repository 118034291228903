import i18next from "i18next";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { SeatmapHelper } from "../../../../component-helpers/seatmap/SeatmapHelper";
import { ApiSeatmapPassenger } from "../../../../component-models/seatmap/ApiSeatmapPassenger";
import { SeatmapContext } from "../../../../component-models/seatmap/SeatmapContext";
import { SeatmapState } from "../../../../component-models/seatmap/SeatmapState";
import { SeatmapStateUpdater } from "../../../../component-models/seatmap/SeatmapStateUpdater";
import { OUTBOUND } from "../../../../shared/commonConstants";
import { useEffect } from "../../../../shared/haunted/CustomHooks";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useSeatPassengerSegment } from "./useSeatPassengerSegment";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { ExtendedApiSeat } from "../../../../component-models/seatmap/ApiSeat";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";
import { useSeatmapWarning } from "../side-panel/useSeatmapWarning";
import { useChileCompraInfoTemplate } from "./useChileCompraInfoTemplate";

export interface Props {
    canAddSameSeatsToNextSegment: boolean;
    context: SeatmapContext;
    currentSeats: ExtendedApiSeat[];
    seatmapState: SeatmapState;
    showAutoReturnSeatSelectionBubble: boolean;
    handleContinue: (e: MouseEvent, fromModal: boolean) => void;
    handleResetSelection: (e: MouseEvent) => void;
    handleSeatAutoAssignment: (journeyIndex: number, segmentIndex: number) => Promise<void>;
    handleSeatDelete: (passenger: ApiSeatmapPassenger) => void;
    handleSegmentClick: (segmentIndex: number) => void;
    handleNoSelection: () => void;
    updateState: (newState: SeatmapStateUpdater) => void;
    showNoSeatsButton: () => boolean;
}

export function useSeatPassengerJourney(props: Props) {
    const journeyName = () =>
        journeyIndex() === OUTBOUND ? window.seatmapResources.outboundText : window.seatmapResources.inboundText;

    const journeyTotalPrice = () => {
        const price = props.currentSeats
            ? props.currentSeats
                  .filter((seat) => seat.JourneyIndex === journeyIndex() && !seat.IsFree && seat.PassengerNumber >= 0)
                  .reduce((aggr, curr) => aggr + curr.Price, 0)
            : 0;

        return price;
    };

    const formattedJourneyTotalPrice = () => {
        if (areSeatsFreeForJourney()) {
            return formatNumber({ amount: 0 });
        }

        return formatNumber({ amount: journeyTotalPrice() });
    };

    const journeyTotalPriceDifference = () => {
        const priceDifference = SeatmapHelper.journeyTotalPriceDifference(
            props.currentSeats,
            props.seatmapState?.CurrentJourney,
            userContext?.bancoEstado.category === 6,
        );

        return formatNumber({ amount: priceDifference });
    };

    const journeyIndex = () => props.seatmapState?.CurrentJourney.JourneyIndex;

    const areSeatsFreeForJourney = () => props.seatmapState?.CurrentJourney.AreAllSeatsInBundle;

    const scrollToTop = (segmentIndex: number) =>
        segmentIndex === 0 ? passengerContainerSegment1.scrollToTop() : passengerContainerSegment2.scrollToTop();

    const scrollToPassenger = (segmentIndex: number) =>
        segmentIndex === 0
            ? passengerContainerSegment1.scrollToPassenger()
            : passengerContainerSegment2.scrollToPassenger();

    const flowContext = useFlowContext();

    const { formatNumber } = useNumberFormatter();

    const [userContext] = useReduxState("userContext");

    const passengerContainerSegment1 = useSeatPassengerSegment({
        context: props.context,
        currentSeats: props.currentSeats,
        seatmapState: props.seatmapState,
        segment: props.seatmapState?.CurrentJourney.Segments[0],
        handleSeatDelete: props.handleSeatDelete,
        handleSegmentClick: props.handleSegmentClick,
        updateState: props.updateState,
    });

    const passengerContainerSegment2 = useSeatPassengerSegment({
        context: props.context,
        currentSeats: props.currentSeats,
        seatmapState: props.seatmapState,
        segment: props.seatmapState?.CurrentJourney.Segments[1],
        handleSeatDelete: props.handleSeatDelete,
        handleSegmentClick: props.handleSegmentClick,
        updateState: props.updateState,
    });

    const seatmapWarning = useSeatmapWarning({
        context: props.context,
        currentSeats: props.currentSeats,
        isMobile: false,
        seatmapState: props.seatmapState,
    });

    const chileCompraInfo = useChileCompraInfoTemplate({
        context: props.context,
        seatmapState: props.seatmapState,
    });

    useEffect(() => {
        scrollToPassenger(props.seatmapState?.CurrentSegment.SegmentIndex);
    }, [
        props.seatmapState?.CurrentPassenger?.PassengerNumber,
        props.seatmapState?.CurrentJourney?.JourneyIndex,
        props.seatmapState?.CurrentSegment.SegmentIndex,
    ]);

    const warningTemplate = () => (!props.context.Model?.IsReadOnly ? seatmapWarning.htmlTemplate() : "");

    const priceTemplate = () =>
        !flowContext.isPostBookingFlow && userContext?.chileCompra.role === "none"
            ? html`
                  <div
                      class="seatmap-journey-price"
                      data-test-id="${getTestId(T.SEATMAP.JOURNEY_PRICE, { j: journeyIndex() })}"
                      data-test-value=${journeyTotalPrice()}
                  >
                      ${window.seatmapResources.seatCostsText} ${journeyName()}: ${formattedJourneyTotalPrice()}
                  </div>
              `
            : "";

    const priceDifferenceTemplate = () =>
        flowContext.isChangeFlow && userContext?.chileCompra.role === "none"
            ? html`
                  <div
                      class="seatmap-journey-price"
                      data-test-id="${getTestId(T.SEATMAP.JOURNEY_PRICE_DIFFERENCE, { j: journeyIndex() })}"
                      data-test-value=${journeyTotalPriceDifference()}
                  >
                      ${window.seatmapResources.seatCostsText} ${journeyName()}: ${journeyTotalPriceDifference()}
                  </div>
              `
            : "";

    const transferTemplate = () => html`
        <div class="transfer-information-container w-full">
            <i class="far fa-clock"></i>
            <span
                >${i18next.t("Tiempo estimado de escala en {{arrivalStation}} {{hours}}h {{minutes}}min.", {
                    // DEVNOTE Here the waiting time is listed as BEFORE the segment
                    hours: props.seatmapState?.CurrentJourney.Segments[1].StopDurationHours,
                    minutes: props.seatmapState?.CurrentJourney.Segments[1].StopDurationMinutes,
                    arrivalStation: props.seatmapState?.CurrentJourney.Segments[0].ArrivalStationName,
                })}</span
            >
        </div>
    `;

    const secondSegmentTemplate = () =>
        props.seatmapState?.CurrentJourney.Segments.length > 1
            ? html` ${transferTemplate()} ${passengerContainerSegment2.htmlTemplate()} `
            : "";

    const continueButtonsTemplate = () => {
        if (props.context.Model?.IsReadOnly) return "";

        const hasChanges = SeatmapHelper.hasSeatChangesOnLastLeg(props.context, props.seatmapState, props.currentSeats);
        const buttonText =
            hasChanges && !flowContext.isCheckinFlow
                ? window.seatmapResources.addSeatsText
                : window.seatmapResources.continueText;

        return SeatmapHelper.isContinueBtnDisabledForCheckin(props.context, props.seatmapState, props.currentSeats)
            ? html`
                  <ac-tooltip
                      .icon=${continueButtonTemplate(buttonText)}
                      .tooltip=${window.seatmapResources.checkinTooltip}
                      .customClass=${"dummy"}
                  ></ac-tooltip>
              `
            : continueButtonTemplate(buttonText);
    };

    const continueButtonTemplate = (buttonText: string) => {
        const disabledForChileCompra = SeatmapHelper.isContinueBtnDisabledForChileCompra(
            props.context,
            props.seatmapState,
            props.currentSeats,
            userContext,
        );

        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "seat-select": true,
            "no-click":
                SeatmapHelper.isContinueBtnDisabledForCheckin(props.context, props.seatmapState, props.currentSeats) ||
                disabledForChileCompra,
            "disabled": props.showAutoReturnSeatSelectionBubble || disabledForChileCompra,
        });

        return html`
            <button class=${tempClassMap} data-test-id="${T.SEATMAP.CONTINUE_BUTTON}" @click=${props.handleContinue}>
                ${buttonText}
            </button>
        `;
    };

    const autoAssignmentButtonTemplate = () => {
        const tempClassMap = classMap({
            "rounded-secondary-btn": true,
            "seat-select": true,
            "disabled":
                props.showAutoReturnSeatSelectionBubble ||
                SeatmapHelper.doAllPaxHaveSeats(
                    props.currentSeats,
                    props.seatmapState?.CurrentJourney,
                    props.context.Model?.Passengers,
                ),
        });

        return SeatmapHelper.showSeatAutoAssignmentButton(props.context, props.seatmapState)
            ? html`
                  <button
                      class=${tempClassMap}
                      @click=${() =>
                          props.handleSeatAutoAssignment(
                              props.seatmapState.CurrentJourney.JourneyIndex,
                              props.seatmapState.CurrentSegment.SegmentIndex,
                          )}
                  >
                      ${window.seatmapResources.seatAutoAssignText}
                  </button>
              `
            : "";
    };

    const resetSeatsButtonTemplate = () => {
        const tempClassMap = classMap({
            "rounded-secondary-btn": true,
            "seat-select": true,
            "disabled": props.showAutoReturnSeatSelectionBubble,
        });

        return SeatmapHelper.showResetSeatsButton(props.context, props.currentSeats)
            ? html`
                  <button
                      class=${tempClassMap}
                      data-test-id="${T.SEATMAP.RESET_SEATS_BUTTON}"
                      @click=${props.handleResetSelection}
                  >
                      ${window.seatmapResources.resetSeatText}
                  </button>
              `
            : "";
    };

    const selectNoSeatTemplate = () =>
        props.showNoSeatsButton() && userContext?.chileCompra.role === "none"
            ? html`
                  <button
                      class="rounded-secondary-btn select-no-seat with-arrow xs"
                      data-test-id=${getTestId(T.SEATMAP.NO_SEAT_SELECTION_BUTTON, {
                          j: props.seatmapState?.CurrentJourney?.JourneyIndex,
                          s: props.seatmapState?.CurrentSegment?.SegmentIndex,
                      })}
                      @click=${props.handleNoSelection}
                  >
                      ${window.seatmapResources.noSeatText}
                  </button>
              `
            : "";

    const htmlTemplate = () => html`
        <div class="seatmap-pax-journey-container hidden-md-down">
            ${passengerContainerSegment1.htmlTemplate()} ${secondSegmentTemplate()} ${priceTemplate()}
            ${chileCompraInfo.htmlTemplate()}
            <div class="mt-4 flex w-full items-center justify-between">
                ${autoAssignmentButtonTemplate()} ${resetSeatsButtonTemplate()}
            </div>
            <div class="seatmap-sidepanel-button-container">${selectNoSeatTemplate()}${continueButtonsTemplate()}</div>
        </div>
        ${priceDifferenceTemplate()} ${warningTemplate()}
    `;

    return {
        isTermsAcceptanceCheckboxChecked: seatmapWarning.isTermsAcceptanceCheckboxChecked,
        htmlTemplate,
        scrollToPassenger,
        scrollToTop,
        showAcceptanceWarning: seatmapWarning.showAcceptanceWarning,
    };
}
