import i18next from "i18next";
import { html } from "haunted";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useModal } from "../../../shared/useModal";

export const name = "ac-punctuality-performance-modal";

export interface Props {
    cancelPercentage: string;
    delayPercentage: string;
    flightNumber: string;
    operator: string;
}

export const usePunctualityPerformanceModal = (props: Props) => {
    const htmlTemplate = () => html`
        <div class="modal-body punctuality-performance">
            <div class="flight-number">
                <span>${`${i18next.t("Vuelo")} ${props.flightNumber}`}</span>
            </div>
            <div class="ppm-list-elem">
                <div class="ppm-icon-container">
                    <i class="js-icon js-flight-plane plane-icon"></i>
                </div>
                <div class="punctuality-info-container">
                    <p>${i18next.t("Atrasado").toUpperCase()}</p>
                    <span class="ppm-data-row"> ${`${i18next.t("30 minutos o más:")} ${props.delayPercentage}%`} </span>
                    <span>
                        ${unsafeHTML(
                            i18next.t("Vuelo operado por {{-operatingCarrier}}", {
                                operatingCarrier: props.operator,
                            }),
                        )}
                    </span>
                </div>
            </div>
            <div class="ppm-list-elem">
                <div class="ppm-icon-container">
                    <i class="js-icon js-flight-plane plane-icon"></i>
                </div>
                <div class="punctuality-info-container">
                    <p>${i18next.t("Cancelado-PPM").toUpperCase()}</p>
                    <span class="ppm-data-row">
                        ${`${i18next.t("Cantidad de vuelos cancelados:")} ${props.cancelPercentage}%`}
                    </span>
                    <span>
                        ${unsafeHTML(
                            i18next.t("Vuelo operado por {{-operatingCarrier}}", {
                                operatingCarrier: props.operator,
                            }),
                        )}
                    </span>
                </div>
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: true },
        content: { template: htmlTemplate },
        header: { template: () => i18next.t("Desempeño de puntualidad") },
        overlay: { classNames: "punctuality-performance-modal" },
    });

    return modal;
};
