import { html } from "lit-html";
import { useCreditShellCat1234 } from "./useCreditShellCat12347";
import { useCreditShellCat56 } from "./useCreditShellCat56";
import { useMemo } from "../../shared/haunted/CustomHooks";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { PaymentMode } from "../../component-models/payment/PaymentMode";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    model: PaymentPageViewModel;
    paymentMode: PaymentMode;
    handleCat1234PaymentIntent: () => Promise<boolean>;
    handleCat56PaymentIntent: () => Promise<boolean>;
}

export const useCreditShell = (props: Props) => {
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const [userContext] = useReduxState("userContext");

    const isAvailable = () =>
        !flowContext.isGiftcardPurchaseFlow &&
        !flowContext.isFarelockRoundOne &&
        props.paymentMode !== "voucherCoversBalance" &&
        props.paymentMode !== "giftcardCoversBalance" &&
        userContext.peruCompra.role === "none" &&
        !bookingContext.isPeruCompraBooking;

    const showCat1234Template = useMemo(
        () =>
            [1, 2, 3, 4, 7].includes(userContext.bancoEstado.category) &&
            (props.model?.CreditShellViewModel.Cat12347CreditShellAvailableAmount > 0 ||
                props.model?.CreditShellViewModel.AutoCreditFileAmount !== 0 ||
                props.model?.CreditShellViewModel.Cat12347CreditShellIsUsed) &&
            (flowContext.isBookingFlow || flowContext.isDcStandaloneFlow),
        [props.model, userContext],
    );

    const showCat56Template = useMemo(
        () =>
            [5, 6].includes(userContext.bancoEstado.category) &&
            props.model?.CreditHistoryViewModel?.AccountCredits &&
            !flowContext.isFarelockRoundOne,
        [props.model, userContext],
    );

    const cat1234CreditShell = useCreditShellCat1234({
        model: props.model,
        handlePaymentIntent: props.handleCat1234PaymentIntent,
    });

    const cat56CreditShell = useCreditShellCat56({
        model: props.model,
        paymentMode: props.paymentMode,
        handlePaymentIntent: props.handleCat56PaymentIntent,
    });

    const cat1234Template = () => (showCat1234Template ? cat1234CreditShell.htmlTemplate() : "");

    const cat56Template = () => (showCat56Template ? cat56CreditShell.htmlTemplate() : "");

    const htmlTemplate = () => (isAvailable() ? html`<div>${cat1234Template()} ${cat56Template()}</div>` : "");

    return { htmlTemplate };
};
