import { html } from "haunted";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { useBundleSsrItems } from "./useBundleSsrItems";
import { useMemo } from "../../../shared/haunted/CustomHooks";
import { FlightPageContext } from "../../../component-models/flight/contexts/FlightPageContext";
import { OUTBOUND, REDEMPTION_MILES_CODE } from "../../../shared/commonConstants";
import classNames from "classnames";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../shared/useNumberFormatter";
import i18next from "i18next";
import { useFlowContext } from "../../../managers/useFlowContext";

export interface Props {
    allBundlesSelected: boolean;
    context: FlightPageContext;
    isUpgradingBundle: boolean;
    journeyIndex: number;
}

export const useBundleSelected = (props: Props) => {
    const { formatNumber, getCurrencySign } = useNumberFormatter();

    const [currency] = useReduxState("booking.currency");
    const flowContext = useFlowContext();

    const bundle = useMemo(
        () =>
            props.journeyIndex === OUTBOUND
                ? props.context?.bundleState?.selectedOutboundBundle
                : props.context?.bundleState?.selectedInboundBundle,
        [props.context?.bundleState, props.journeyIndex],
    );

    const bundleSsrItems = useBundleSsrItems({
        bundle,
        context: props.context,
        isSelected: true,
        journeyIndex: props.journeyIndex,
    });

    // TEMPLATES

    const currencySignTemplate = () =>
        !flowContext.isRedemptionFlow
            ? html`<span
                  class="relative text-xl sm:top-[-2px] sm:text-xs md:top-[-3px] md:text-xl"
                  data-test-id=${getTestId(T.BUNDLE.SELECTED_PRICE_CURRENCY_SIGN, { j: props.journeyIndex })}
                  data-test-value=${getCurrencySign(currency)}
              >
                  ${getCurrencySign(currency)}
              </span>`
            : "";

    const redemptionCurrencyTemplate = () =>
        flowContext.isRedemptionFlow ? html`<span class="text-xl font-semibold">${i18next.t("Miles")}</span>` : "";

    const priceTemplate = () => {
        const currentCurrency = flowContext.isRedemptionFlow ? REDEMPTION_MILES_CODE : currency;
        const price = formatNumber({ amount: bundle.UnFormattedPrice, leadingSign: false, currency: currentCurrency });

        return html`
            <div
                class="text-3xl font-semibold sm:text-lg md:text-3xl"
                style="color: ${bundle.Color.toLowerCase() === "#ffffff" ? "#1c355e" : "#ffffff"};"
            >
                ${currencySignTemplate()}
                <span
                    class="font-semibold"
                    data-test-id=${getTestId(T.BUNDLE.SELECTED_PRICE, { j: props.journeyIndex })}
                    data-test-value=${bundle.UnFormattedPrice.toString()}
                >
                    ${price} ${redemptionCurrencyTemplate()}
                </span>
            </div>
        `;
    };

    const headerTemplate = () => html`
        <div
            class="relative flex h-[29px] flex-row items-center justify-around overflow-hidden border-b border-solid border-be-gray-14 pl-10 md:h-20 md:pl-5 lg:pl-8 xl:pl-0"
            style="background-color: ${bundle.Color};"
            data-test-id=${getTestId(T.BUNDLE.SELECTED_HEADER, {
                c: bundle.BundleType,
                j: props.journeyIndex,
            })}
        >
            <img
                class="max-h-[80%] md:w-[150px] lg:w-[unset]"
                src=${bundle.HeaderImgUrl}
                data-test-id=${getTestId(T.BUNDLE.SELECTED_IMAGE, { j: props.journeyIndex })}
            />
            ${priceTemplate()}
        </div>
    `;

    const htmlTemplate = () => {
        const tempClassNames = classNames("hidden-xs selected-bundle-container", {
            shorter: props.context.showBundleUpgradeOffer(bundle),
            reduced: props.isUpgradingBundle,
        });

        return html`
            <div
                class=${tempClassNames}
                data-test-id=${getTestId(T.BUNDLE.SELECTED_CONTAINER, { j: props.journeyIndex })}
                data-test-value=${bundle.BundleType}
            >
                ${headerTemplate()} ${bundleSsrItems.htmlTemplate()}
            </div>
        `;
    };

    return { htmlTemplate };
};
