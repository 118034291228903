import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import { html } from "lit-html";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { CHECKED_BAGGAGE_IMG } from "../../spa-container";
import { perBookingQuantityButtons } from "./perBookingQuantityButtons";
import { baggageIllustrations } from "../common/baggageIllustrations";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import classNames from "classnames";
import { BaggagePageState } from "../../../../component-models/baggage/BaggagePageState";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useCallback } from "haunted";
import { StaffBaggage } from "../common/useStaffBaggage";
import { useBaggageUtils } from "../common/useBaggageUtils";
import { BancoEstadoBaggage } from "../common/useBancoEstadoBaggage";

export interface Props {
    baggageSection: BaggageSection;
    bancoEstadoBaggage: BancoEstadoBaggage;
    pageState: BaggagePageState;
    staffBaggage: StaffBaggage;
}

export const usePerBookingPaidChecked = (props: Props) => {
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const { formattedLowestNextPrice } = useBaggageUtils();

    const isPerJourneyPerPaxViewOpen = props.baggageSection.sectionUi.uiState.perJourneyPerPaxState === "open";

    const isPaidBagSelected = props.baggageSection.perBooking.isPaidBagSelected;

    const noBagSelected = useMemo(
        () => !props.pageState.isLoading && props.baggageSection.perBooking.isNoBagSelected,
        [props.baggageSection.perBooking.isNoBagSelected, props.pageState.isLoading],
    );

    const quantityButtons = perBookingQuantityButtons({
        baggageSection: props.baggageSection,
        bagType: "checkedBaggage",
        isCheckinClosedInbound: bookingContext.isCheckinClosedInbound,
        isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
        isMobile: false,
        paxCount: bookingContext.adultsCount + bookingContext.childrenCount,
    });

    const isAddAvailable = useMemo(
        () =>
            props.baggageSection.perBooking.isAddAvailable({
                baggageSectionJourneys: props.baggageSection.journeys,
                bagType: "checkedBaggage",
                canGetFreeBancoEstadoBaggage: props.baggageSection.canGetFreeBancoEstadoBaggage,
                isCheckinClosedInbound: bookingContext.isCheckinClosedInbound,
                isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
                paxCount: bookingContext.adultsCount + bookingContext.childrenCount,
            }),
        [props.baggageSection.journeys, bookingContext],
    );

    const { zippedBagTooltipTemplate } = baggageIllustrations();

    const unformattedLowestNextPrice = useCallback(
        (isOriginal: boolean, isMinimum: boolean) =>
            props.baggageSection.perBooking.nextPrice({
                baggageSectionJourneys: props.baggageSection.journeys,
                bagType: "checkedBaggage",
                bancoEstadoBaggage: props.bancoEstadoBaggage,
                isMinimum,
                isOriginal,
                staffBaggage: props.staffBaggage,
            }),
        [
            props.baggageSection.perBooking.nextPrice,
            props.baggageSection.journeys,
            props.bancoEstadoBaggage,
            props.staffBaggage,
        ],
    );

    const showOriginalMinimumPrice = useMemo(
        () =>
            flowContext.isBookingFlow &&
            bookingContext.isDiscountBooking &&
            !isPaidBagSelected &&
            unformattedLowestNextPrice(true, true),
        [bookingContext.isDiscountBooking, flowContext.isBookingFlow, isPaidBagSelected, unformattedLowestNextPrice],
    );

    const handleAdd = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!isPerJourneyPerPaxViewOpen && !isPaidBagSelected) await props.baggageSection.add();
    };

    const priceTemplate = () =>
        props.baggageSection.journeys.every((j) => j.bundleType === "None")
            ? html` ${noBundleOriginalPriceTemplate()} ${noBundlePriceTemplate()} `
            : "";

    const noBundleOriginalPriceTemplate = () =>
        showOriginalMinimumPrice
            ? html`
                  <div class="b2-per-booking-next-price">
                      <span
                          class="line-through"
                          data-test-value=${unformattedLowestNextPrice(true, true)}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_CROSSED_OUT_MINIMUM_PRICE, {
                              c: "checkedBaggage",
                          })}
                      >
                          ${formattedLowestNextPrice(unformattedLowestNextPrice(true, true))}
                      </span>
                  </div>
              `
            : "";

    const noBundlePriceTemplate = () => {
        const unformattedAmount = unformattedLowestNextPrice(false, true);

        return !isPaidBagSelected && unformattedAmount >= 0
            ? html`
                  <div class="b2-per-booking-next-price">
                      ${i18next.t("Desde")}
                      <span
                          class="b2-amount"
                          data-test-value=${unformattedAmount}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_MINIMUM_PRICE, { c: "checkedBaggage" })}
                      >
                          ${formattedLowestNextPrice(unformattedAmount)}
                      </span>
                  </div>
              `
            : "";
    };

    const addButtonEnabledTemplate = () =>
        noBagSelected || !isPaidBagSelected
            ? html`
                  <button
                      class="b2-primary-button"
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_ADD_FIRST_BUTTON, { c: "checkedBaggage" })}
                      @click=${handleAdd}
                  >
                      ${i18next.t("Agregar")}
                  </button>
              `
            : "";

    const addButtonDisabledTemplate = () =>
        isPaidBagSelected
            ? html`
                  <div class="flex w-full items-center justify-around">
                      <button disabled class="b2-primary-button">${i18next.t("Agregado")}</button>
                      ${quantityButtons.htmlTemplate()}
                  </div>

                  ${addAnotherButtonTemplate()}
              `
            : "";

    const addAnotherButtonTemplate = () => {
        const addClassMap = classNames("b2-add-another", {
            "padded-top": bookingContext.isDiscountBooking && flowContext.isBookingFlow,
        });

        return isAddAvailable
            ? html`
                  <div class=${addClassMap}>
                      ${i18next.t("Agrega otro desde")}
                      <span class="relative">
                          ${originalPriceTemplate()}
                          <span
                              class="b2-amount"
                              data-test-value=${unformattedLowestNextPrice(false, false)}
                              data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_NEXT_PRICE, {
                                  c: "checkedBaggage",
                              })}
                          >
                              ${formattedLowestNextPrice(unformattedLowestNextPrice(false, false))}
                          </span>
                      </span>
                  </div>
              `
            : "";
    };

    const originalPriceTemplate = () =>
        bookingContext.isDiscountBooking && flowContext.isBookingFlow
            ? html`
                  <div
                      class="b2-per-booking-next-price original-price non-breaking-price"
                      data-test-value=${unformattedLowestNextPrice(true, false)}
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_CROSSED_OUT_NEXT_PRICE, { c: "checkedBaggage" })}
                  >
                      ${formattedLowestNextPrice(unformattedLowestNextPrice(true, false))}
                  </div>
              `
            : "";

    const footerTemplate = () => {
        const tempClassMap = classNames("b2-paid-option-btn-container b2-openable taller", {
            "b2-opened": !isPerJourneyPerPaxViewOpen,
        });

        return html`
            <div
                class=${tempClassMap}
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_BUTTON_CONTAINER, { c: "checkedBaggage" })}
            >
                ${priceTemplate()} ${addButtonEnabledTemplate()} ${addButtonDisabledTemplate()}
            </div>
        `;
    };

    const illustrationTemplate = () => html`
        <div class=${"b2-illustration taller"}>
            <div
                class="b2-illustration-ribbon"
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_RIBBON, { c: "checkedBaggage" })}
            >
                ${i18next.t("¡Recomendado para ti!")}
            </div>
            <img
                class="b2-paid-checked-img"
                src=${CHECKED_BAGGAGE_IMG}
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_IMAGE, { c: "checkedBaggage" })}
            />
            <div
                class="b2-weight"
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_WEIGHT, { c: "checkedBaggage" })}
            >
                23kg.
            </div>
            <div
                class="b2-dimensions"
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_DIMENSIONS, { c: "checkedBaggage" })}
            >
                158cm ${i18next.t("lineales")} ${zippedBagTooltipTemplate()}
            </div>
            <div
                class="b2-bag-name"
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_NAME, { c: "checkedBaggage" })}
            >
                ${i18next.t("Equipaje facturado")}
            </div>
        </div>
    `;

    const htmlTemplate = () => {
        const containerClassMap = classNames("b2-paid-bag-option", {
            "selected": isPaidBagSelected,
            "per-pax": isPerJourneyPerPaxViewOpen,
            "hoverable": !isPerJourneyPerPaxViewOpen && !isPaidBagSelected,
        });

        return html`
            <div
                class=${containerClassMap}
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION, { c: "checkedBaggage" })}
                @click=${handleAdd}
            >
                ${illustrationTemplate()} ${footerTemplate()}
            </div>
        `;
    };

    return { htmlTemplate };
};
