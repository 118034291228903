import { ApiPortalSpaViewModel } from "../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import { usePeruCompraSummaryPage } from "./summary-page/usePeruCompraSummaryPage";
import { useCugProfilePage } from "./profile/useCugProfilePage";
import { usePeruCompraProfilePage } from "./profile/usePeruCompraProfilePage";
import { useCugEditProfilePage } from "./profile/useCugEditProfilePage";
import { useRestrictedEditProfilePage } from "./profile/useRestrictedEditProfilePage";
import { useCugUsersPage } from "./users/useCugUsersPage";
import { usePeruCompraUsersPage } from "./users/usePeruCompraUsersPage";
import { TemplateResult } from "lit-html";
import { useCugTravelPartnersPage } from "./travel-partners/useCugTravelPartnersPage";
import { useCugAddTravelPartnerPage } from "./travel-partners/useCugAddTravelPartnerPage";
import { useCugAddTravelGroupPage } from "./travel-partners/useCugAddTravelGroupPage";
import { useCugTravelGroupsPage } from "./travel-partners/useCugTravelGroupsPage";
import { useCugAdminTravelGroupsPage } from "./travel-partners/useCugAdminTravelGroupsPage";
import { useCugEditTravelGroupPage } from "./travel-partners/useCugEditTravelGroupPage";
import { useCugContactPage } from "./contact/useCugContactPage";
import { usePeruCompraContactPage } from "./contact/usePeruCompraContactPage";
import { useCugReservationsPage } from "./reservations/useCugReservationsPage";
import { usePeruCompraReservationsPage } from "./reservations/usePeruCompraReservationsPage";
import { useCugSummaryPage } from "./summary-page/useCugSummaryPage";
import { useChileCompraProfilePage } from "./profile/useChileCompraProfilePage";
import { useChileCompraContactPage } from "./contact/useChileCompraContactPage";
import { useChileCompraReservationsPage } from "./reservations/useChileCompraReservationsPage";

export type PortalSpaSection =
    | "Summary"
    | "Profile"
    | "EditProfile"
    | "TravelPartners"
    | "AddTravelPartner"
    | "TravelGroups"
    | "AddTravelGroup"
    | "EditTravelGroup"
    | "AdminTravelGroups"
    | "Users"
    | "Reservations"
    | "Contact";

export type PortalUserType = "Agency" | "Company" | "PeruCompra" | "ChileCompra";

interface PortalSpaSectionContentDictionaryItem {
    userType: PortalUserType;
    section: PortalSpaSection;
    content: PortalSpaSectionContent;
}

export interface PortalSpaSectionContent {
    init?: () => void;
    htmlTemplate: () => TemplateResult;
}

export interface Props {
    model: ApiPortalSpaViewModel;
    antiForgeryToken: string;
}

export const usePortalSectionContent = (props: Props) => {
    // Summary

    const cugSummaryContent = useCugSummaryPage({
        model: props.model,
        antiForgeryToken: props.antiForgeryToken,
    });

    const peruCompraSummaryContent = usePeruCompraSummaryPage({
        model: props.model,
        antiForgeryToken: props.antiForgeryToken,
    });

    // Profile (info and edit)

    const cugProfileContent = useCugProfilePage({
        model: props.model,
    });

    const peruCompraProfileContent = usePeruCompraProfilePage({
        model: props.model,
    });

    const chileCompraProfileContent = useChileCompraProfilePage({
        model: props.model,
    });

    const cugEditProfileContent = useCugEditProfilePage({
        model: props.model,
        antiForgeryToken: props.antiForgeryToken,
    });

    const restrictedEditProfileContent = useRestrictedEditProfilePage({
        model: props.model,
        antiForgeryToken: props.antiForgeryToken,
    });

    // Users

    const cugUsersContent = useCugUsersPage({
        model: props.model,
    });

    const peruCompraUsersContent = usePeruCompraUsersPage({
        model: props.model,
    });

    // Travel Partners

    const cugTravelPartnersContent = useCugTravelPartnersPage();

    const cugAddTravelPartnerContent = useCugAddTravelPartnerPage();

    // Travel Groups

    const cugTravelGroupsContent = useCugTravelGroupsPage();

    const cugAddTravelGroupContent = useCugAddTravelGroupPage();

    const editTravelGroupContent = useCugEditTravelGroupPage();

    const adminTravelGroupsContent = useCugAdminTravelGroupsPage();

    // Reservations

    const cugReservationsContent = useCugReservationsPage({ model: props.model });

    const peruCompraReservationsContent = usePeruCompraReservationsPage({ model: props.model });

    const chileCompraReservationsContent = useChileCompraReservationsPage({ model: props.model });

    // Contact

    const cugContactContent = useCugContactPage();

    const peruCompraContactContent = usePeruCompraContactPage();

    const chileCompraContactContent = useChileCompraContactPage();

    // HELPERS

    const portalSpaSectionContentDictionary: PortalSpaSectionContentDictionaryItem[] = [
        { userType: "Agency", section: "Summary", content: cugSummaryContent },
        { userType: "Company", section: "Summary", content: cugSummaryContent },
        { userType: "PeruCompra", section: "Summary", content: peruCompraSummaryContent },
        { userType: "Agency", section: "Profile", content: cugProfileContent },
        { userType: "Company", section: "Profile", content: cugProfileContent },
        { userType: "PeruCompra", section: "Profile", content: peruCompraProfileContent },
        { userType: "ChileCompra", section: "Profile", content: chileCompraProfileContent },
        { userType: "Agency", section: "EditProfile", content: cugEditProfileContent },
        { userType: "Company", section: "EditProfile", content: cugEditProfileContent },
        { userType: "PeruCompra", section: "EditProfile", content: restrictedEditProfileContent },
        { userType: "Agency", section: "Users", content: cugUsersContent },
        { userType: "Company", section: "Users", content: cugUsersContent },
        { userType: "PeruCompra", section: "Users", content: peruCompraUsersContent },
        { userType: "Agency", section: "TravelPartners", content: cugTravelPartnersContent },
        { userType: "Company", section: "TravelPartners", content: cugTravelPartnersContent },
        { userType: "Agency", section: "AddTravelPartner", content: cugAddTravelPartnerContent },
        { userType: "Company", section: "AddTravelPartner", content: cugAddTravelPartnerContent },
        { userType: "Agency", section: "TravelGroups", content: cugTravelGroupsContent },
        { userType: "Company", section: "TravelGroups", content: cugTravelGroupsContent },
        { userType: "Agency", section: "AddTravelGroup", content: cugAddTravelGroupContent },
        { userType: "Company", section: "AddTravelGroup", content: cugAddTravelGroupContent },
        { userType: "Agency", section: "EditTravelGroup", content: editTravelGroupContent },
        { userType: "Company", section: "EditTravelGroup", content: editTravelGroupContent },
        { userType: "Agency", section: "AdminTravelGroups", content: adminTravelGroupsContent },
        { userType: "Company", section: "AdminTravelGroups", content: adminTravelGroupsContent },
        { userType: "Agency", section: "Reservations", content: cugReservationsContent },
        { userType: "Company", section: "Reservations", content: cugReservationsContent },
        { userType: "PeruCompra", section: "Reservations", content: peruCompraReservationsContent },
        { userType: "ChileCompra", section: "Reservations", content: chileCompraReservationsContent },
        { userType: "Agency", section: "Contact", content: cugContactContent },
        { userType: "Company", section: "Contact", content: cugContactContent },
        { userType: "PeruCompra", section: "Contact", content: peruCompraContactContent },
        { userType: "ChileCompra", section: "Contact", content: chileCompraContactContent },
    ];

    // EXPORTS

    const getContent = (userType: string, section: PortalSpaSection) => {
        const content = portalSpaSectionContentDictionary.find(
            (x) => x.userType === userType && x.section === section,
        ).content;

        return {
            init: content.init,
            htmlTemplate: content.htmlTemplate,
        };
    };

    return { getContent };
};
