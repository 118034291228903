import { html } from "haunted";
import i18next from "i18next";
import { ApiPortalSpaViewModel } from "../../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { ROUTES } from "../../../shared/apiRoutes";
import classNames from "classnames";

export interface Props {
    model: ApiPortalSpaViewModel;
}

export const useOrganizationUsersBox = (props: Props) => {
    const [userContext] = useReduxState("userContext");

    // EVENT HANDLERS
    const onClick = () =>
        props.model.SummaryViewModel.CanViewUsersPage ? (window.location.href = ROUTES.Cug2BUsersPage) : null;

    // COMPONENT

    // TEMPLATES

    const htmlTemplate = () => html`
        <div
            class=${classNames("cug2b-summary-data", {
                "peru-compra": userContext.peruCompra?.role !== "none",
            })}
            @click=${onClick}
        >
            <h2>${i18next.t("Usuarios Activos")}</h2>
            <ac-tooltip
                .icon=${"?"}
                .tooltip=${i18next.t("CUG-TT-UsersTip")}
                onclick=${(e: Event) => e.stopPropagation()}
            ></ac-tooltip>

            <span class="cug2b-summary-data-number">${props.model.SummaryViewModel.ActiveUserCount}</span>
        </div>
    `;

    return { htmlTemplate };
};
