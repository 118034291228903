import i18next from "i18next";
import { html } from "lit-html";
import { useEffect } from "../../shared/haunted/CustomHooks";
import { AGENCY_PAYMENT_FOP_CODE, WORLDPAY_PAYMENT_METHOD_CODES } from "../../shared/commonConstants";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { WorldpayPriceInformation } from "../../component-models/payment/WorldpayResponse";
import { useAppContext } from "../../managers/useAppContext";
import { maskCurrenciesForDisplay } from "../../shared/common";
import { useReduxState } from "../../shared/redux/useReduxState";
import { paymentHelper } from "../../component-helpers/payment/PaymentHelper";
import classNames from "classnames";
import { useNumberFormatter } from "../../shared/useNumberFormatter";

export interface Props {
    isMcpOn: boolean;
    model: PaymentPageViewModel;
    totalIncreasedWithInstallments: number;
    worldpayMcpCurrencyList?: string[];
    worldpayMcpPrice?: WorldpayPriceInformation;
}

export const useCurrencySelector = (props: Props) => {
    const appContext = useAppContext();

    const { getAvailableCurrencies, getCultureForCountry } = paymentHelper();
    const { formatNumber } = useNumberFormatter();

    const [cardData] = useReduxState("payment.cardData");
    const [userContext] = useReduxState("userContext");
    const [payerData] = useReduxState("payment.payer");
    const [paymentMethod] = useReduxState("payment.paymentMethod");
    const [selectedCurrency, setSelectedCurrency] = useReduxState("payment.selectedCurrency");
    const [currency] = useReduxState("booking.currency");

    // HELPERS

    const getDisplayedTotal = () => {
        if (!paymentMethod || userContext?.chileCompra.role === "supervisor") return 0;

        return (
            props.worldpayMcpPrice?.UnformattedPrice ||
            (props.totalIncreasedWithInstallments && props.totalIncreasedWithInstallments > 0
                ? props.totalIncreasedWithInstallments
                : props.model.AmountPerCurrency[selectedSelectableCurrency(selectedCurrency)].Amount)
        );
    };

    const getCultureBasedDefaultCurrency = () => {
        const culture = getCultureForCountry(payerData.CurrentCardIssuerCountry);

        const selectableCurrencies = getAvailableCurrencies(paymentMethod, payerData.CurrentCardIssuerCountry);

        const defaultCurrency =
            appContext.LocalCurrencyDisplaySettings.CultureSpecificDisplaySettings.find(
                (setting) => setting.CultureCode.toLowerCase() === culture?.toLowerCase(),
            )?.DefaultCurrency || "USD";

        return defaultCurrency && selectableCurrencies && selectableCurrencies.includes(defaultCurrency)
            ? defaultCurrency
            : undefined;
    };

    const selectedSelectableCurrency = (forcedCurrency?: string) => {
        if (
            (props.worldpayMcpCurrencyList && isWorldpayMcpSelected()) ||
            userContext.chileCompra.role === "supervisor"
        ) {
            return selectedCurrency;
        }

        const availableCurrencies = getAvailableCurrencies(paymentMethod, payerData.CurrentCardIssuerCountry);

        // DEVNOTE Order decided by JS
        // #1 If an alternate code is used that has a forced currency (like Amex forced through FT in CLP)
        const alternateCodeCurrencies = cardData?.AlternateCode?.ForcedCurrencies || [];
        const forcedAlternateCodeCurrency =
            alternateCodeCurrencies?.length > 0 ? alternateCodeCurrencies[0] : undefined;

        // #2 Any other forced currency (like the booking currency on init)
        const isForcedCurrencyAvailable =
            availableCurrencies.includes(forcedCurrency) ||
            (forcedCurrency && paymentMethod?.PaymentMethodCode === AGENCY_PAYMENT_FOP_CODE);

        // #3 The user selected currency
        const isCurrentCurrencyAvailable = availableCurrencies.includes(selectedCurrency);

        // #4 The default currency for the selected card issuer country's culture
        const cultureBasedCurrency = getCultureBasedDefaultCurrency();

        // #5 The first option in the currency selector dropdown
        const firstCurrency = availableCurrencies[0];

        const retVal = forcedAlternateCodeCurrency
            ? forcedAlternateCodeCurrency
            : isForcedCurrencyAvailable
              ? forcedCurrency
              : isCurrentCurrencyAvailable
                ? selectedCurrency
                : cultureBasedCurrency
                  ? cultureBasedCurrency
                  : firstCurrency;

        if (!retVal) {
            throw new Error("No possible currency can be selected in the dropdown.");
        }

        return retVal;
    };

    const selectableCurrencies = () => {
        if (!paymentMethod) return [];

        return props.worldpayMcpCurrencyList
            ? props.worldpayMcpCurrencyList
            : cardData?.AlternateCode?.ForcedCurrencies || []?.length > 0
              ? cardData?.AlternateCode?.ForcedCurrencies
              : getAvailableCurrencies(paymentMethod, payerData.CurrentCardIssuerCountry);
    };

    const isWorldpayMcpSelected = () =>
        props.isMcpOn && WORLDPAY_PAYMENT_METHOD_CODES.includes(paymentMethod?.PaymentMethodCode);

    const handleCurrencyChange = async (e: KeyboardEvent) => {
        const newCurrency = (e.target as HTMLInputElement).value;
        setSelectedCurrency(newCurrency);
    };

    useEffect(() => {
        if (!isWorldpayMcpSelected()) {
            setSelectedCurrency(paymentMethod?.PaymentMethodCode ? selectedSelectableCurrency(currency) : currency);
        }
    }, [paymentMethod?.PaymentMethodCode]);

    const disabledCurrencyChangeInputTemplate = () => html`
        <input
            class="mdl-textfield__input  js-input"
            readonly
            data-test-id=${`selected-currency-${paymentMethod?.PaymentMethodCode}`}
            value=${maskCurrenciesForDisplay(selectedSelectableCurrency())}
        />
    `;

    const enabledCurrencyChangeInputTemplate = () => html`
        <select
            class="mdl-textfield__input  js-input js-select"
            data-test-id=${`selected-currency-${paymentMethod?.PaymentMethodCode}`}
            value=${selectedSelectableCurrency()}
            @change=${handleCurrencyChange}
        >
            ${selectableCurrencies().map(
                (curr) => html`
                    <option value=${curr} ?selected=${curr === selectedSelectableCurrency()}>
                        ${maskCurrenciesForDisplay(curr)}
                    </option>
                `,
            )}
        </select>
    `;

    const htmlTemplate = () => {
        const mainClassMap = classNames("mdl-textfield mdl-js-textfield mdl-textfield--floating-label", {
            "js-select-arrow": selectableCurrencies().length > 1,
        });

        return html`
            <div class="row">
                <div class="col-xs-1 col-sm-1-3 col-md-1-3">
                    <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                        <label class="mdl-textfield__label">${i18next.t("Monto")}</label>
                        <input
                            class="mdl-textfield__input js-input"
                            autocomplete="off"
                            readonly
                            data-test-id=${`selected-currency-amount-${paymentMethod?.PaymentMethodCode}`}
                            value=${formatNumber({
                                amount: props.totalIncreasedWithInstallments || getDisplayedTotal(),
                                currency: selectedCurrency,
                                leadingSign: false,
                            })}
                        />
                    </div>
                </div>
                <div class="col-xs-1 col-sm-1-3 col-md-1-3">
                    <div class=${mainClassMap}>
                        <label class="mdl-textfield__label">${i18next.t("Moneda de cargo")}</label>
                        ${selectableCurrencies().length === 1
                            ? disabledCurrencyChangeInputTemplate()
                            : enabledCurrencyChangeInputTemplate()}
                    </div>
                </div>
            </div>
        `;
    };

    return {
        displayedTotal: getDisplayedTotal(),
        htmlTemplate,
    };
};
