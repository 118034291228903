import { SpaContainerViewModel } from "../../component-models/spa/SpaContainerViewModel";
import {
    AIRPORT_CHECKIN_CODES,
    CABIN_BAG_CODES,
    CHECKED_BAG_CODES,
    FLEXI_FEE_CODES,
    OVERSIZED_BAG_CODES,
    PET_IN_CABIN_CODES,
    PRIORITY_BOARDING_CODES,
} from "../../shared/commonConstants";
import { useBookingContext } from "../../managers/useBookingContext";
import { SpaSectionType } from "./spa-container";

type SsrType =
    | "CabinBag"
    | "CheckedBag"
    | "OversizedBag"
    | "PriorityBoarding"
    | "AirportCheckIn"
    | "FlexiFee"
    | "PetInCabin";

interface SsrCollectionModel {
    codes: string[];
    spaSectionType: SpaSectionType;
    getPrice: () => number;
    getSsrAddKeys: () => string[];
}

interface Props {
    model: SpaContainerViewModel;
}

export const useSsrHelper = (props: Props) => {
    const bookingContext = useBookingContext();

    const formatPrice = (amount: string | number) => {
        if (typeof amount === "number") {
            return amount;
        }

        return Number(amount?.split("|")[0].trim());
    };

    const ssrDictionary = new Map<SsrType, SsrCollectionModel>([
        [
            "CabinBag",
            {
                codes: CABIN_BAG_CODES,
                spaSectionType: "Baggage",
                getPrice: () => formatPrice(props.model.BaggageModel.Journeys[0].Passengers[0].CabinBaggage.Amount),
                getSsrAddKeys: () => {
                    return bookingContext.isOneWay
                        ? [props.model.BaggageModel.Journeys[0].Passengers[0].CabinBaggage.SsrAddKey.substring(1)]
                        : [
                              props.model.BaggageModel.Journeys[0].Passengers[0].CabinBaggage.SsrAddKey.substring(1),
                              props.model.BaggageModel.Journeys[1].Passengers[0].CabinBaggage.SsrAddKey.substring(1),
                          ];
                },
            },
        ],
        [
            "CheckedBag",
            {
                codes: CHECKED_BAG_CODES,
                spaSectionType: "Baggage",
                getPrice: () => formatPrice(props.model.BaggageModel.Journeys[0].Passengers[0].CheckedBaggage.Amount),
                getSsrAddKeys: () => {
                    return bookingContext.isOneWay
                        ? [props.model.BaggageModel.Journeys[0].Passengers[0].CheckedBaggage.SsrAddKey.substring(1)]
                        : [
                              props.model.BaggageModel.Journeys[0].Passengers[0].CheckedBaggage.SsrAddKey.substring(1),
                              props.model.BaggageModel.Journeys[1].Passengers[0].CheckedBaggage.SsrAddKey.substring(1),
                          ];
                },
            },
        ],
        [
            "OversizedBag",
            {
                codes: OVERSIZED_BAG_CODES,
                spaSectionType: "Baggage",
                getPrice: () => formatPrice(props.model.BaggageModel.Journeys[0].Passengers[0].OversizedBaggage.Amount),
                getSsrAddKeys: () => {
                    return bookingContext.isOneWay
                        ? [props.model.BaggageModel.Journeys[0].Passengers[0].OversizedBaggage.SsrAddKey.substring(1)]
                        : [
                              props.model.BaggageModel.Journeys[0].Passengers[0].OversizedBaggage.SsrAddKey.substring(
                                  1,
                              ),
                              props.model.BaggageModel.Journeys[1].Passengers[0].OversizedBaggage.SsrAddKey.substring(
                                  1,
                              ),
                          ];
                },
            },
        ],
        [
            "PriorityBoarding",
            {
                codes: PRIORITY_BOARDING_CODES,
                spaSectionType: "Extras",
                getPrice: () =>
                    formatPrice(props.model.ExtrasModel.PriorityBoardingJourneys[0].Passengers[0].Amount),
                getSsrAddKeys: () => {
                    return bookingContext.isOneWay
                        ? [
                              props.model.ExtrasModel.PriorityBoardingJourneys[0].Passengers[0].SsrAddKey.substring(
                                  1,
                              ),
                          ]
                        : [
                              props.model.ExtrasModel.PriorityBoardingJourneys[0].Passengers[0].SsrAddKey.substring(
                                  1,
                              ),
                              props.model.ExtrasModel.PriorityBoardingJourneys[1].Passengers[0].SsrAddKey.substring(
                                  1,
                              ),
                          ];
                },
            },
        ],
        [
            "AirportCheckIn",
            {
                codes: AIRPORT_CHECKIN_CODES,
                spaSectionType: "Extras",
                getPrice: () =>
                    formatPrice(
                        props.model.ExtrasModel.CheckinTypePassengers[0].CheckinTypeJourneys[0].PaidOption.Amount,
                    ),

                getSsrAddKeys: () => {
                    return bookingContext.isOneWay
                        ? [
                              props.model.ExtrasModel.CheckinTypePassengers[0].CheckinTypeJourneys[0].PaidOption.SsrAddKey.substring(
                                  1,
                              ),
                          ]
                        : [
                              props.model.ExtrasModel.CheckinTypePassengers[0].CheckinTypeJourneys[0].PaidOption.SsrAddKey.substring(
                                  1,
                              ),
                              props.model.ExtrasModel.CheckinTypePassengers[0].CheckinTypeJourneys[1].PaidOption.SsrAddKey.substring(
                                  1,
                              ),
                          ];
                },
            },
        ],
        [
            "FlexiFee",
            {
                codes: FLEXI_FEE_CODES,
                spaSectionType: "Extras",
                getPrice: () => formatPrice(props.model.ExtrasModel.FlexiFeeJourneys[0].Amount),
                getSsrAddKeys: () => [],
            },
        ],
        [
            "PetInCabin",
            {
                codes: PET_IN_CABIN_CODES,
                spaSectionType: "Extras",
                getPrice: () => formatPrice(props.model.ExtrasModel.PetInCabinJourneys[0].Passengers[0].Amount),
                getSsrAddKeys: () => {
                    return bookingContext.isOneWay
                        ? [props.model.ExtrasModel.PetInCabinJourneys[0].Passengers[0].SsrAddKey.substring(1)]
                        : [
                              props.model.ExtrasModel.PetInCabinJourneys[0].Passengers[0].SsrAddKey.substring(1),
                              props.model.ExtrasModel.PetInCabinJourneys[1].Passengers[0].SsrAddKey.substring(1),
                          ];
                },
            },
        ],
    ]);

    const getSsrType = (ssrCode: string) =>
        [...ssrDictionary.keys()].find((type) => ssrDictionary.get(type).codes?.includes(ssrCode));

    const getSsrKeysForAllPax = (ssrAddKeys: string[]) =>
        props.model.BaggageModel.Journeys[0].Passengers.flatMap((_, paxIndex) =>
            ssrAddKeys.flatMap((key) => `${paxIndex}${key}`),
        );

    const getSsrAmount = (ssrCode: string) => ssrDictionary.get(getSsrType(ssrCode))?.getPrice();

    const generateSsrKeys = (ssrCode: string) => {
        const ssrType = getSsrType(ssrCode);
        if (!ssrType) return [];

        return getSsrKeysForAllPax(ssrDictionary.get(ssrType).getSsrAddKeys());
    };

    const getSsrCodesByType = (ssrType: SsrType) => ssrDictionary.get(ssrType).codes;

    return { getSsrCodesByType, getSsrType, getSsrKeysForAllPax, getSsrAmount, generateSsrKeys };
};
