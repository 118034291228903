export const ROUTES = {
    ApiRoutes: {
        AbTestDefaultOptions: "/api/ABTest/GetDefaultOptions",
        AddGiftcardEmail: "/api/GiftCard/SendToOtherAddress",
        AgencyDisableUser: "/api/Agency/DisableUser",
        AgencyEnableUser: "/api/Agency/EnableUser",
        AgencyGetUserDetails: "/api/Agency/GetUserDetails",
        AgencyReservations: "/api/Agency/Reservations",
        AgencyReservationsCsv: "/api/Agency/ReservationsCsv",
        AgencyUsers: "/api/Agency/Users",
        AmplitudeExpose: "/api/ABTest/Expose",
        BookingCancel: "/api/Booking/Cancel",
        BookingCommit: "/api/Booking/Commit",
        BookingIsLatestPaymentPending: "/api/Booking/IsLatestPaymentPending",
        BookingRedemptionCommit: "/api/Booking/CommitRedemption",
        BundleData: "/api/BundleOffer/BundleData",
        Cug3BundleData: "/api/BundleOffer/Cug3BundleData",
        RedemptionBundleData: "/api/BundleOffer/RedemptionBundleData",
        BundleForBanner: "/api/BundleOffer/BundleOfferDetailsByType",
        ChangeCulture: "/api/Application/ChangeCulture",
        CheckBeUserExist: "/api/Agency/CheckRutExist",
        CheckOrganizationName: "/api/Agency/CheckOrganizationNameExist",
        CheckoutProPaymentIntent: "/api/HostedPayment/CheckoutProPaymentIntent",
        CheckPromoCode: "/api/PromotionCode/IsPromoCodeApplicable",
        CheckUserExist: "/api/Agency/CheckUserExist",
        ChileCompraSeatingStatus: "/api/seat/GetCug3SeatAvailability",
        ChileCompraBookingSelectReason: "/api/booking/Cug3BookingSelectReason",
        ChileCompraNearFlightJustification: "/api/booking/Cug3NearFlightJustification",
        ChileCompraTravelDetails: "/api/booking/Cug3TravelDetails",
        CompraquiPaymentIntent: "/api/HostedPayment/CompraquiPaymentIntent",
        Cug2BChangePassword: "/api/Cug2b/ChangePassword",
        Cug2BCreateAgency: "/api/Agency/Create",
        Cug2BCreateUser: "/api/Agency/CreateUser",
        Cug2BGetChangedFlights: "/api/Agency/GetChangedFlights",
        Cug2BTravelPartnerInfo: "/api/Cug2b/TravelPartnerInfo",
        Cug2BUpdateUser: "/api/Agency/UpdateUserDetails",
        EmergencyContact: "/api/Contacts/GetEmergencyContact",
        FarelockOffers: "/api/BundleOffer/GetFareLockBundleOffers",
        GetAllPassengers: "/api/Passengers/GetAll",
        GetAutoAssignedSeats: "/api/Seat/AutoAssign",
        GiftcardActivate: "/api/GiftCard/Activate",
        GiftCardConsistencyCheck: "/api/GiftCard/GetDataConsistencyCheckResult",
        GiftCardMultipleCheck: "/api/GiftCard/GetGiftCardDataResult",
        InitialTopUpCredit: "/api/Agency/InitialTopUpCredit",
        InitWompiPayment: "/api//HostedPayment/InitWompiPayment",
        Payment: "/api/Payment/Payment",
        PeruCompraCancelledReservations: "/api/Agency/PeruCompraCancelledReservations",
        PeruCompraReservations: "/api/Agency/PeruCompraReservations",
        PeruCompraReservationsCsv: "/api/Agency/PeruCompraReservationsCsv",
        PostUpdatePassengers: "/api/Passengers/Update",
        RetrieveSchedule: "/api/Flight/RetrieveSchedule",
        Review: "/api/Review/Review",
        WompiBancolumbiaButtonIntent: "/api/HostedPayment/WompiBancolumbiaButtonIntent",
        WompiPseFinancialInstitutions: "/api/HostedPayment/WompiPseFinancialInstitutions",
        WompiPseInstituions: "/api/HostedPayment/WompiPseFinancialInstitutions",
        WompiPseIntent: "/api/HostedPayment/WompiPseIntent",
        SessionKeepAlive: "/api/Session/KeepAlive",
        ResendItinerary: "/api/Booking/ResendItinerary",
    },
    AjaxBaggageModel: "/V2XHR/Baggage",
    AjaxExtrasModel: "/V2XHR/Extras",
    AjaxPageFlight: "/V2XHR/Flight",
    AjaxPassengersModel: "/V2XHR/Passengers",
    AjaxReadonlySeatmapModel: "/V2XHR/ReadonlySeatmap",
    AjaxSeatmapModel: "/V2XHR/Seatmap",
    ApplyMembership: "/Fees/ApplyMembership",
    ApplyPromotion: "/V2XHR/ApplyPromotion",
    AvailableCurrencies: "/V2XHR/AvailableCurrencies",
    BookingFinalizeChanges: "/Booking/FinalizeChanges",
    BookingReset: "/Booking/Reset",
    BookingRetrieve: "/Booking/Retrieve",
    BookingRetrieveInline: "/Booking/RetrieveInline",
    CheckinAdditionalInfo: "/V2Checkin/AdditionalInfo",
    ClearInsuranceSearchCache: "/ChubbXHR/ClearInsuranceSearchCache",
    ContactPassengers: "/V2XHR/ContactPassengers",
    Cug2BAddGroupPage: "/V2Agency/AddTravelGroup",
    Cug2BAddPartnerPage: "/V2Agency/AddTravelPartner",
    Cug2BAdminTravelGroupsPage: "/V2Agency/AdminTravelGroups",
    Cug2BContactPage: "/V2Agency/Contact",
    Cug2BEditTravelGroupPage: "/V2Agency/EditTravelGroup",
    Cug2BPostEditOrganization: "/V2Agency/OrganizationUpdate",
    Cug2BProfilePage: "/V2Agency/AgencyProfile",
    Cug2BRegisterPage: "/V2Agency/Register",
    Cug2BReservationsPage: "/V2Agency/Reservations",
    Cug2BStandaloneAgencyLoginPage: "/V2/Login?agency=1",
    Cug2BStandaloneCompanyLoginPage: "/V2/Login?company=1",
    Cug2BSummaryPage: "/V2Agency/Summary",
    Cug2BTopup: "/V2Agency/PayTopUp",
    Cug2BTravelGroupsPage: "/V2Agency/TravelGroups",
    Cug2BTravelPartnersPage: "/V2Agency/TravelPartners",
    Cug2BUserProfile: "/V2Agency/Profile",
    Cug2BUsersPage: "/V2Agency/Users",
    DeselectWorldpay: "/Payment/DeselectWorldpay",
    DiscountBundleState: "/SsrXHR/GetDiscountBundleState",
    EtPaymentIntent: "/HostedPayment/EtPaymentIntent",
    FlightlessPayment: "/Payment/FlightlessBookingDeclined",
    FlightMoveFakeSelectDate: "/FlightMove/SelectDate",
    FlightMoveFakeSelectFlight: "/FlightMove/SelectNewItinerary",
    FlightMoveSelectDatePost: "/FlightMove/SelectDatePost",
    FlightMoveSelectFlightPost: "/FlightMove/SelectFlightPost",
    FlightMoveSelectLegs: "/FlightMove/SelectLegs",
    FlightStoreState: "/V2XHR/StoreSearchState",
    ForgotPassword: "/V2/ForgotPassword",
    ForgotPasswordBancoEstado: "/V2/ForgotPasswordBancoEstado",
    GetBookingData: "/V2XHR/GetBookingData",
    GetInsurancePassengers: "/V2XHR/GetInsurancePassengers",
    GetMercadoPagoInstallments: "/Payment/GetMercadoPagoInstallments",
    GetTrips: "/V2XHR/GetTrips",
    GetWorldpayCurrencies: "/Payment/GetWorldpayCurrencies",
    GiftcardActivate: "/GiftCard/Activate",
    GiftcardSelect: "/GiftCard/Select",
    InsuranceSearch: "/ChubbXHR/SearchInsurance",
    InternalSelect: "/Flight/InternalSelect",
    LoginBancoEstado: "/Member/LoginBancoEstado",
    Logout: "/Member/Logout",
    LogoutInline: "/Member/LogoutInline",
    PageBaggage: "/V2/Baggage",
    PageBoardingCards: "/V2Checkin/Print",
    PageEditAgency: "/V2Agency/EditAgency",
    PageExtras: "/V2/Extras",
    PageFlight: "/V2/Flight",
    PageHome: "/V2/Index",
    PageItinerary: "/V2/Itinerary",
    PageLogin: "/Login/Login",
    PagePassengers: "/V2/Passengers",
    PagePayment: "/V2/Payment",
    PageProfile: "/V2/Profile",
    PageReview: "/V2/Review",
    PageSeatmap: "/Seat/Map",
    PageStaffBenefit: "/V2/StaffBenefit",
    PageStaffFamilyMembers: "/V2/StaffFamilyMembers",
    PassengersCheckin: "/V2Checkin/Passengers",
    PostCalendar: "/Flight/CalendarSelect",
    PostCommit: "/Booking/PostCommit",
    PriceBreakdown: "/V2XHR/PriceBreakdown",
    PriceBreakdownFlightSelect: "/V2XHR/PriceBreakdownFlightSelect",
    PrimaryContactEmail: "/V2XHR/PrimaryContactEmail",
    ProfileCreateAddress: "/MemberAddress/Create",
    ProfileLegacyUpdateAddress: "/MemberAddress/Update",
    ProfileLegacyUpdateBasicInfo: "/Member/BasicInfo",
    ProfileUpdateAddress: "/MemberAddress/UpdateV2",
    ProfileUpdateBankData: "/V2XHR/SetRefundDataAgentOnly",
    ProfileUpdateBasicInfo: "/Member/BasicInfoV2",
    Regions: "/RegionXHR/GetRegionsAndComunas",
    Register: "/Member/Register",
    RegisterDc: "/Member/RegisterDc",
    RegisterDcPost: "/Member/RegisterDCSubmit",
    RegisterInline: "/Member/RegisterInline",
    RegisterInRefundWidget: "/Member/RegisterInlineProCons",
    RemoveInsurances: "/ChubbXHR/Insurance",
    ResetPasswordView: "/Member/ResetPasswordView",
    SafetyPayPaymentIntent: "/HostedPayment/SafetyPayIntent",
    SelectWorldpay: "/Payment/SelectWorldpay",
    SendForgetPasswordMail: "/V2/SendForgetPasswordMail",
    StartSpa: "/V2XHR/StartSpa",
    SubmitVoucher: "/Payment/Voucher",
    SuperStations: "/Resource/SuperStationsWithCountries",
    VoucherAvailability: "/Payment/VoucherAvailabilityForFarelock",
    WaiveUnpaidInterestFees: "/Payment/WaiveUnpaidInterestFees",
};
