import { TestIdDictionary as T, getTestId } from "../../../testing-helpers/TestIdHelper";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { BundleType, CustomizedBundleOffers } from "../../../component-models/flight/BundleOffersV2Model";
import { useEffect, useState, useMemo } from "../../../shared/haunted/CustomHooks";
import { html, useRef } from "haunted";
import i18next from "i18next";
import { classMap } from "lit-html/directives/class-map";
import { ref } from "../../../directives/ref";
import { useBundlesBar } from "../../useBundlesBar";
import { FlightPageContext } from "../../../component-models/flight/contexts/FlightPageContext";
import { useBundlesSelectorDesktopItem } from "./useBundlesSelectorDesktopItem";
import { useBundlesSelectorMobileItem } from "./useBundlesSelectorMobileItem";
import classNames from "classnames";
import { OUTBOUND } from "../../../shared/commonConstants";
import { SelectedFlightFeeType } from "../flight-page";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../shared/useNumberFormatter";
import { useFlowContext } from "../../../managers/useFlowContext";

const sensitivity = 0.33;

export interface Props {
    bundles: CustomizedBundleOffers;
    context: FlightPageContext;
    journeyIndex: number;
    onBundleSelect: (type: BundleType) => void;
}

export const useBundlesSelector = (props: Props) => {
    const { formatNumber } = useNumberFormatter();

    const flowContext = useFlowContext();

    const [userContext] = useReduxState("userContext");
    const [currency] = useReduxState("booking.currency");

    const bundlesRow = useRef<HTMLDivElement>(undefined);
    const container = useRef<HTMLDivElement>(undefined);
    const header = useRef<HTMLDivElement>(undefined);

    const [activeBundle, setActiveBundle] = useState<BundleType>("Full");
    const [containerDeviation, setContainerDeviation] = useState<number>(0);
    const [isFaded, setIsFaded] = useState<boolean>(true);
    const [touchStartTime, setTouchStartTime] = useState<number>(0);

    const bundlesBar = useBundlesBar();

    const bundleOffers = useMemo(() => {
        if (flowContext.isRedemptionFlow) return props.bundles?.RedemptionOffers;

        return props.context.flightState?.selectedFeeType === "Smart" || props.context.tooManyPax
            ? props.bundles?.NormalOffers
            : props.bundles?.DCOffers;
    }, [props.bundles, props.context.flightState, props.context.dcState, props.context.model, userContext]);

    const getBundleByOrder = (orderNumber: number, mobile: boolean) =>
        bundleOffers?.find((bo) => (mobile ? bo.MobilePosition === orderNumber : bo.DesktopPosition === orderNumber));

    const desktopItem1 = useBundlesSelectorDesktopItem({
        bundle: getBundleByOrder(1, false),
        context: props.context,
        journeyIndex: props.journeyIndex,
        onSelect: (e: MouseEvent, type: BundleType) => handleSelect(e, type),
    });

    const desktopItem2 = useBundlesSelectorDesktopItem({
        bundle: getBundleByOrder(2, false),
        context: props.context,
        journeyIndex: props.journeyIndex,
        onSelect: (e: MouseEvent, type: BundleType) => handleSelect(e, type),
    });

    const desktopItem3 = useBundlesSelectorDesktopItem({
        bundle: getBundleByOrder(3, false),
        context: props.context,
        journeyIndex: props.journeyIndex,
        onSelect: (e: MouseEvent, type: BundleType) => handleSelect(e, type),
    });

    const mobileItem1 = useBundlesSelectorMobileItem({
        bundle: getBundleByOrder(1, true),
        context: props.context,
        isActive: activeBundle === getBundleByOrder(1, true)?.BundleType,
        journeyIndex: props.journeyIndex,
        onSelect: (e: MouseEvent, type: BundleType) => handleSelect(e, type),
        onScroll: () => scrollToLeft(),
    });

    const mobileItem2 = useBundlesSelectorMobileItem({
        bundle: getBundleByOrder(2, true),
        context: props.context,
        isActive: activeBundle === getBundleByOrder(2, true)?.BundleType,
        journeyIndex: props.journeyIndex,
        onSelect: (e: MouseEvent, type: BundleType) => handleSelect(e, type),
        onScroll: () => scrollToCenter(true),
    });

    const mobileItem3 = useBundlesSelectorMobileItem({
        bundle: getBundleByOrder(3, true),
        context: props.context,
        isActive: activeBundle === getBundleByOrder(3, true)?.BundleType,
        journeyIndex: props.journeyIndex,
        onSelect: (e: MouseEvent, type: BundleType) => handleSelect(e, type),
        onScroll: () => scrollToRight(),
    });

    const showBundlesBar = useMemo(() => [5, 6].indexOf(userContext?.bancoEstado.category) > -1, [userContext]);

    const footerBundleImages = useMemo(() => {
        const sellKey =
            props.journeyIndex === OUTBOUND
                ? props.context.flightState?.selectedOutboundFlight?.SellKey
                : props.context.flightState?.selectedInboundFlight?.SellKey;

        const bundle1 = props.context.bundleState?.bundleOffers
            .find((b) => b.SellKey === sellKey)
            ?.DCOffers.find((b) => b.BundleType === "Simple");

        const bundle2 = props.context.bundleState?.bundleOffers
            .find((b) => b.SellKey === sellKey)
            ?.DCOffers.find((b) => b.BundleType === "Full");

        return { bundle1Img: bundle1?.FooterImgUrl, bundle2Img: bundle2?.FooterImgUrl };
    }, [
        props.context.bundleState?.bundleOffers,
        props.context.flightState?.selectedInboundFlight?.SellKey,
        props.context.flightState?.selectedOutboundFlight?.SellKey,
    ]);

    const getBundlePrice = (bundles: CustomizedBundleOffers, type: BundleType, feeType: SelectedFlightFeeType) => {
        switch (feeType) {
            case "Smart":
                return bundles.NormalOffers.find((o) => o.BundleType === type).UnFormattedPrice;
            case "Club":
                return bundles.DCOffers.find((o) => o.BundleType === type).UnFormattedPrice;
            case "Redemption":
                return bundles.RedemptionOffers.find((o) => o.BundleType === type).UnFormattedPrice;
        }
    };

    const getLowestPriceDifferenceForFooter = (bundles: CustomizedBundleOffers) => {
        try {
            const simpleBundlePrice = getBundlePrice(bundles, "Simple", "Smart");
            const fullBundlePrice = getBundlePrice(bundles, "Full", "Smart");

            const simpleDcBundlePrice = getBundlePrice(bundles, "Simple", "Club");
            const fullDcBundlePrice = getBundlePrice(bundles, "Full", "Club");

            const priceDifferences = [
                simpleBundlePrice - simpleDcBundlePrice,
                fullBundlePrice - fullDcBundlePrice,
            ].sort((a, b) => a - b);

            return priceDifferences[0];
        } catch (e) {
            return 0;
        }
    };

    const bundleFooterPrice = useMemo(() => {
        if (!props.bundles) return "";

        const lowestPriceDifference = getLowestPriceDifferenceForFooter(props.bundles);

        return !Number.isNaN(lowestPriceDifference)
            ? formatNumber({ amount: lowestPriceDifference, leadingSign: true })
            : "";
    }, [props.bundles, currency]);

    // HELPERS

    const mobileScrollProps = () => {
        if (!container.current || !bundlesRow.current) return { offset: undefined, deviation: undefined };

        const containerBounding = container.current.getBoundingClientRect();
        const contentBounding = bundlesRow.current.getBoundingClientRect();

        const offset = contentBounding.width - containerBounding.width;
        const containerMiddle = containerBounding.left + containerBounding.width / 2;
        const contentMiddle = contentBounding.left + contentBounding.width / 2;
        const deviation = contentMiddle - containerMiddle;

        return { offset, deviation };
    };

    const scrollArea = (type: BundleType, left: number, isSmooth: boolean) => {
        setActiveBundle(type);
        if (isSmooth) {
            window.setTimeout(() => {
                container.current.scrollTo({ left, behavior: "smooth" });
            }, 250);
        } else {
            container.current.scrollTo({ left });
        }
    };

    const scrollToCenter = (isSmooth: boolean) => {
        const { offset } = mobileScrollProps();
        const bundle = getBundleByOrder(2, true);

        if (offset !== undefined && bundle) scrollArea(bundle.BundleType, offset / 2, isSmooth);
    };

    const scrollToLeft = () => {
        scrollArea(getBundleByOrder(1, true).BundleType, 0, true);
    };

    const scrollToRight = () => {
        const { offset } = mobileScrollProps();
        if (offset !== undefined) scrollArea(getBundleByOrder(3, true).BundleType, offset, true);
    };

    // EVENT LISTENERS

    const handleTouchStart = () => {
        const { deviation } = mobileScrollProps();
        setContainerDeviation(deviation);
        setTouchStartTime(Date.now());
    };

    const handleTouchEnd = () => {
        const { offset, deviation } = mobileScrollProps();

        if (offset !== undefined) {
            const touchEndTime = Date.now();
            // DEVNOTE Speed is needed to avoid jerky scrolling, 0.6 as a limit is an empirical value
            const speed = (deviation - containerDeviation) / (touchEndTime - touchStartTime);
            const speedLimitForSwipe = 0.6;

            setContainerDeviation(deviation);
            setTouchStartTime(0);

            if (speed > speedLimitForSwipe) {
                scrollToLeft();
                return;
            }

            if (speed < -speedLimitForSwipe) {
                scrollToRight();
                return;
            }

            if (deviation < offset * -sensitivity) {
                scrollToRight();
            } else if (deviation > offset * sensitivity) {
                scrollToLeft();
            } else {
                scrollToCenter(true);
            }
        }
    };

    const handleSelect = (e: MouseEvent, type: BundleType) => {
        e.preventDefault();
        e.stopPropagation();

        props.onBundleSelect(type);
        props.context.selectBundle(type, props.journeyIndex, props.bundles.SellKey, props.context.container);
    };

    // EFFECTS

    // DEVNOTE This is to scroll the new mobile bundle selector invisibly to the middle. As we use
    // "scroll" to position the bundles child div (we have to, because of touch manipulation)
    // instead of a negative "margin", we can only calculate the appropriate scroll after displaying
    // the component itself. So to avoid an initial scroll, we set opacity to 0 while calculating.

    // TODO FS It does not work if we reload the page with the selector open, but that is a very edge case.

    useEffect(() => {
        window.setTimeout(() => {
            scrollToCenter(false);
            setIsFaded(false);
        }, 100);
    }, [
        props.context.flightState?.selectedOutboundFlight?.SellKey,
        props.context.flightState?.selectedInboundFlight?.SellKey,
    ]);

    // TEMPLATES

    const desktopHeaderTemplate = () => {
        const titleDataTestId = getTestId(T.BUNDLE.SELECTOR_TITLE, { j: props.journeyIndex });

        return html`
            <div class="bundles-header" ref=${ref(header)}>
                <h2 class="bundles-title" data-test-id=${titleDataTestId}>
                    ${unsafeHTML(
                        i18next.t("BundlesTitle {{-image}}", { image: "<img src='/Images/Bundles/pack.png' />" }),
                    )}
                </h2>
            </div>
        `;
    };

    const footerTemplate = () => {
        const { bundle1Img, bundle2Img } = footerBundleImages;
        const imageClassName = classNames(
            "top-[1px] h-[11px]",
            "md:top-px md:h-[15px]",
            "lg:relative lg:top-[2px] lg:h-[18px]",
        );
        const footerObj = {
            image1: `<img src='${bundle1Img}' class="${imageClassName}" />`,
            image2: `<img src='${bundle2Img}' class="${imageClassName}" /><br class="hidden-xs" />`,
            footer: `<span>${bundleFooterPrice}</span>`,
        };

        return props.context.flightState.selectedFeeType === "Club"
            ? html`
                  <div
                      class=${classNames(
                          "mx-auto mb-[35px] w-[920px] max-w-[95%] rounded-3xl text-center font-extra text-xs leading-4 text-be-blue",
                          "sm:bg-[#f6a318] sm:pb-[8px] sm:pt-[10px] sm:text-[10px] sm:leading-[15px]",
                          "md:text-[13px]",
                          "lg:py-[10px] lg:text-[15px] lg:leading-[23px]",
                      )}
                  >
                      ${unsafeHTML(i18next.t("B-BundleFooterText {{-image1}} {{-image2}} {{-footer}}", footerObj))}
                  </div>
              `
            : "";
    };

    const mobileDotTemplate = (orderNumber: number, onClick: () => void) => {
        const isActive = activeBundle === getBundleByOrder(orderNumber, true).BundleType;

        return html`
            <i
                class="selector-buttons js-icon-bundle ${isActive ? "js-bundle-circle-full" : "js-bundle-circle-empty"}"
                @click=${onClick}
            ></i>
        `;
    };

    const mobileNavigationTemplate = () => html`
        <div class="selector-buttons-container hidden-sm-up">
            ${mobileDotTemplate(1, () => scrollToLeft())} ${mobileDotTemplate(2, () => scrollToCenter(true))}
            ${mobileDotTemplate(3, () => scrollToRight())}
        </div>
    `;

    const mobileTemplate = () => {
        const tempClassMap = classMap({
            "bundles-row": true,
            "faded": isFaded,
        });

        return html`
            <div class="relative pb-4">
                <div
                    class="bundles-container hidden-sm-up"
                    ref=${ref(container)}
                    @touchstart=${handleTouchStart}
                    @touchend=${handleTouchEnd}
                >
                    <div class=${tempClassMap} ref=${ref(bundlesRow)}>
                        ${mobileItem1.htmlTemplate()} ${mobileItem2.htmlTemplate()} ${mobileItem3.htmlTemplate()}
                    </div>
                </div>
                ${mobileNavigationTemplate()}
            </div>
        `;
    };

    const desktopTemplate = () =>
        html`<div class="bundles-container hidden-xs">
            ${desktopItem1.htmlTemplate()} ${desktopItem2.htmlTemplate()} ${desktopItem3.htmlTemplate()}
        </div>`;

    const htmlTemplate = () =>
        props.bundles
            ? html`
                  ${desktopHeaderTemplate()} ${showBundlesBar ? bundlesBar.htmlTemplate() : ""} ${desktopTemplate()}
                  ${mobileTemplate()} ${footerTemplate()}
              `
            : html``;

    return { htmlTemplate };
};
