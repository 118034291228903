import { ItineraryPageViewModel } from "../../component-models/itinerary/ItineraryPageViewModel";
import { useFlowContext } from "../../managers/useFlowContext";
import { useBookingContext } from "../../managers/useBookingContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useAppContext } from "../../managers/useAppContext";

export const tabNames = [
    "Details",
    "Modify",
    "Optionals",
    "Overview",
    "Passengers",
    "RedemptionDetails",
    "RedemptionTransactions",
    "Transactions",
] as const;
export type TabName = (typeof tabNames)[number];

export interface Props {
    model: ItineraryPageViewModel;
}

export const useItineraryTabFactory = (props: Props) => {
    const flowContext = useFlowContext();
    const bookingContext = useBookingContext();
    const appContext = useAppContext();

    const [userContext] = useReduxState("userContext");

    const tabMap = new Map<TabName, { index: number; show: () => boolean }>([
        [
            "Overview",
            {
                index: 0,
                show: () => !flowContext.isFarelockRoundTwo && !props.model.IsFarelockAmountNotFullyPaid,
            },
        ],
        [
            "RedemptionDetails",
            {
                index: 1,
                show: () => flowContext.isAaPostBooking,
            },
        ],
        [
            "Optionals",
            {
                index: 2,
                show: () =>
                    !flowContext.isAaPostBooking &&
                    (props.model.IsGds || props.model.IsDigitalApi) &&
                    !flowContext.isFarelockRoundTwo &&
                    !props.model.IsFarelockAmountNotFullyPaid,
            },
        ],
        [
            "Details",
            {
                index: 3,
                show: () =>
                    !flowContext.isAaPostBooking &&
                    !props.model.IsGds &&
                    !props.model.IsDigitalApi &&
                    !flowContext.isFarelockRoundTwo &&
                    !props.model.IsFarelockAmountNotFullyPaid,
            },
        ],
        [
            "RedemptionTransactions",
            {
                index: 4,
                show: () => flowContext.isAaPostBooking,
            },
        ],
        [
            "Transactions",
            {
                index: 5,
                show: () => !flowContext.isAaPostBooking,
            },
        ],
        [
            "Modify",
            {
                index: 6,
                show: () =>
                    !flowContext.isFarelockRoundTwo &&
                    !props.model.IsFarelockAmountNotFullyPaid &&
                    (!bookingContext.isChileCompraBooking || userContext?.chileCompra.role !== "none"),
            },
        ],
        [
            "Passengers",
            {
                index: 7,
                show: () =>
                    appContext.isFeatureSwitchActive("ProConsumidor") &&
                    !flowContext.isFarelockRoundTwo &&
                    !props.model.IsFarelockAmountNotFullyPaid,
            },
        ],
    ]);

    const getTabs = (): TabName[] =>
        tabNames
            .filter((tabName) => tabMap.get(tabName)?.show())
            .sort((a, b) => tabMap.get(a)!.index - tabMap.get(b)!.index);

    return { getTabs };
};
