import { PaymentPageViewModel } from "./../../component-models/payment/PaymentPageViewModel";
import { html } from "haunted";
import { useInstallmentsCompraqui } from "./useInstallmentsCompraqui";
import { useInstallmentsMercadoPagoAggregator } from "./useInstallmentsMercadoPagoAggregator";
import { useInstallmentsMercadoPagoGateway } from "./useInstallmentsMercadoPagoGateway";
import { useInstallmentsMercadoPagoOld } from "./useInstallmentsMercadoPagoOld";
import { useInstallmentsXml } from "./useInstallmentsXml";
import { InstallmentType } from "../../component-models/PaymentMethodDescriptors";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { XmlInstallmentDropdownOption } from "../../component-models/XmlInstallmentOption";
import { usePaymentTealiumManager } from "../../managers/Tealium/usePaymentTealiumManager";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { ROUTES } from "../../shared/apiRoutes";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    amount: number;
    installmentType: InstallmentType;
    model: PaymentPageViewModel;
    handleInstallmentNumberChange: (newTotal: number) => void;
}

export const useInstallments = (props: Props) => {
    const tealiumManager = usePaymentTealiumManager();

    const { ajaxJsonRequest, getMPApiResponse } = useAjax();

    const { triggers } = usePubSub();

    const [paymentMethod] = useReduxState("payment.paymentMethod");
    const [cardData] = useReduxState("payment.cardData");
    const [payerData] = useReduxState("payment.payer");

    const [merchantAccountId, setMerchantAccountId] = useState<string>("");
    const [paymentMethodOptionId, setPaymentMethodOptionId] = useState<string>("");
    const [selectedInstallmentsNumber, setSelectedInstallmentsNumber] = useState<number>(1);
    const [tealiumInstallmentNumber, setTealiumInstallmentNumber] = useState<number>(undefined);
    const [tealiumInstallmentType, setTealiumInstallmentType] = useState<"xml" | "mercadoPago" | "compraqui">(
        undefined,
    );

    const isMercadoPagoType = () => props.installmentType === "MercadoPago";

    const installmentsMercadoPagoOld = useInstallmentsMercadoPagoOld({
        isActive: isMercadoPagoType() && paymentMethod?.MercadoPagoApiNodeType === "None",
        selectedInstallmentsNumber,
        getApiResponse: () =>
            getMPApiResponse(cardData?.CardNumber, props.amount.toString(), payerData?.CurrentCardIssuerCountry),
        handleInstallmentChange: (selectedInstallments, newTotal) =>
            handleInstallmentChange(selectedInstallments, newTotal),
        handleInstallmentInfoChange: (account, option) => handleMercadoPagoInfoChange(account, option),
        tealiumLogMercadoPagoInstallmentChange: (value) => tealiumLogMercadoPagoInstallmentChange(value),
    });

    const installmentsMercadoPagoAggregator = useInstallmentsMercadoPagoAggregator({
        amount: props.amount,
        isActive: isMercadoPagoType() && paymentMethod?.MercadoPagoApiNodeType === "Aggregator",
        model: props.model,
        selectedInstallmentsNumber,
        getApiResponse: () =>
            getMPApiResponse(cardData?.CardNumber, props.amount.toString(), payerData?.CurrentCardIssuerCountry),
        handleInstallmentChange: (selectedInstallments, newTotal) =>
            handleInstallmentChange(selectedInstallments, newTotal),
        tealiumLogMercadoPagoInstallmentChange: (value) => tealiumLogMercadoPagoInstallmentChange(value),
    });

    const installmentsMercadoPagoGateway = useInstallmentsMercadoPagoGateway({
        isActive: isMercadoPagoType() && paymentMethod?.MercadoPagoApiNodeType === "Gateway",
        model: props.model,
        selectedInstallmentsNumber,
        getApiResponse: () =>
            getMPApiResponse(cardData?.CardNumber, props.amount.toString(), payerData?.CurrentCardIssuerCountry),
        handleInstallmentInfoChange: (account, option) => handleMercadoPagoInfoChange(account, option),
        handleInstallmentChange: (selectedInstallments, newTotal) =>
            handleInstallmentChange(selectedInstallments, newTotal),
        tealiumLogMercadoPagoInstallmentChange: (value) => tealiumLogMercadoPagoInstallmentChange(value),
    });

    const installmentsXml = useInstallmentsXml({
        isActive: props.installmentType === "XML",
        model: props.model,
        selectedInstallmentsNumber,
        handleInstallmentChange: (selectedInstallments, newTotal) =>
            handleInstallmentChange(selectedInstallments, newTotal),
        tealiumLogXmlInstallmentChange: (options, value) => tealiumLogXmlInstallmentChange(options, value),
    });

    const installmentsCompraqui = useInstallmentsCompraqui({
        isActive:
            cardData?.CardValidationStatus === "valid" &&
            paymentMethod?.InputFieldRequirements?.InstallmentType === "Compraqui",
        selectedInstallmentsNumber,
        handleInstallmentChange: (selectedInstallments, newTotal) =>
            handleInstallmentChange(selectedInstallments, newTotal),
        tealiumLogCompraquiInstallmentChange: (value) => tealiumLogCompraquiInstallmentChange(value),
    });

    // HELPERS

    const isAnyActive = () =>
        (cardData.CardValidationStatus === "valid" &&
            paymentMethod?.InputFieldRequirements?.InstallmentType === "Compraqui") ||
        (isMercadoPagoType() && paymentMethod?.MercadoPagoApiNodeType === "Aggregator") ||
        (isMercadoPagoType() && paymentMethod?.MercadoPagoApiNodeType === "Gateway") ||
        (isMercadoPagoType() && paymentMethod?.MercadoPagoApiNodeType === "None") ||
        props.installmentType === "XML";

    const tealiumLogXmlInstallmentChange = async (options: XmlInstallmentDropdownOption[], value: number) => {
        if (tealiumInstallmentNumber !== value || tealiumInstallmentType !== "xml") {
            setTealiumInstallmentNumber(value);
            setTealiumInstallmentType("xml");

            await tealiumManager.logXmlInstallmentNumberChange(options, value);
        }
    };

    const tealiumLogMercadoPagoInstallmentChange = async (value: number) => {
        if (tealiumInstallmentNumber !== value || tealiumInstallmentType !== "mercadoPago") {
            setTealiumInstallmentNumber(value);
            setTealiumInstallmentType("mercadoPago");
            await tealiumManager.logMercadoPagoInstallmentNumberChange(value, "n/a");
        }
    };

    const tealiumLogCompraquiInstallmentChange = async (value: number) => {
        if (tealiumInstallmentNumber !== value || tealiumInstallmentType !== "compraqui") {
            setTealiumInstallmentNumber(value);
            setTealiumInstallmentType("compraqui");
            await tealiumManager.logMercadoPagoInstallmentNumberChange(value, "n/a");
        }
    };

    const handleMercadoPagoInfoChange = (merchantAccountId: string, paymentMethodOptionId: string) => {
        setMerchantAccountId(merchantAccountId);
        setPaymentMethodOptionId(paymentMethodOptionId);
    };

    const reset = () => {
        setMerchantAccountId("");
        setPaymentMethodOptionId("");
    };

    const handleInstallmentChange = (selectedInstallmentNumber: number, newTotal: number) => {
        setSelectedInstallmentsNumber(selectedInstallmentNumber);
        props.handleInstallmentNumberChange(newTotal);
    };

    const waiveUnpaidInterestFee = async () => {
        await ajaxJsonRequest({
            method: "POST",
            url: ROUTES.WaiveUnpaidInterestFees,
            body: { commit: "false" },
        });

        triggers.sidebar.bookingChanged.publish({});
    };

    useEffect(reset, [cardData?.CardNumber]);
    useEffect(() => setSelectedInstallmentsNumber(1), [paymentMethod?.PaymentMethodCode]);

    useEffect(async () => {
        if (selectedInstallmentsNumber === 1) {
            await waiveUnpaidInterestFee();
        }
    }, [selectedInstallmentsNumber, paymentMethod?.PaymentMethodCode]);

    // TEMPLATES

    const mercadoPagoGatewayInstallmentsTemplate = () =>
        isMercadoPagoType() && paymentMethod?.MercadoPagoApiNodeType === "Gateway"
            ? installmentsMercadoPagoGateway.htmlTemplate()
            : "";

    const mercadoPagoAggregatorInstallmentsTemplate = () =>
        isMercadoPagoType() && paymentMethod?.MercadoPagoApiNodeType === "Aggregator"
            ? installmentsMercadoPagoAggregator.htmlTemplate()
            : "";

    const mercadoPagoOldInstallmentsTemplate = () =>
        isMercadoPagoType() && paymentMethod?.MercadoPagoApiNodeType === "None"
            ? installmentsMercadoPagoOld.htmlTemplate()
            : "";

    const xmlInstallmentsTemplate = () => (props.installmentType === "XML" ? installmentsXml.htmlTemplate() : "");

    const compraquiInstallmentsTemplate = () =>
        cardData.CardValidationStatus === "valid" &&
        paymentMethod?.InputFieldRequirements?.InstallmentType === "Compraqui"
            ? installmentsCompraqui.htmlTemplate()
            : "";

    const htmlTemplate = () =>
        isAnyActive()
            ? html`
                  <div class="col-xs-1 col-md-1-2">
                      ${mercadoPagoAggregatorInstallmentsTemplate()} ${mercadoPagoOldInstallmentsTemplate()}
                      ${mercadoPagoGatewayInstallmentsTemplate()} ${xmlInstallmentsTemplate()}
                      ${compraquiInstallmentsTemplate()}
                  </div>
              `
            : html``;

    return { merchantAccountId, paymentMethodOptionId, selectedInstallmentsNumber, htmlTemplate };
};
