import {
    AMERICAN_AIRLINES_FREQUENT_FLYER_NUMBER_FEE_CODES,
    CABIN_BAG_CODES,
    CHECKED_BAG_CODES,
} from "./../shared/commonConstants";
import i18next from "i18next";
import {
    ALWAYS_AVAILABLE_SSR_CODE,
    ForcedFlexiFeePlaceholderCode,
    FLEXI_FEE_CODES,
    PRIORITY_BOARDING_CODES,
    StandardSeatsInBundlePlaceholder,
    AllSeatsInBundlePlaceholder,
    AIRPORT_CHECKIN_CODES,
} from "../shared/commonConstants";
import { html, TemplateResult } from "lit-html";
import { BundleType } from "./flight/BundleOffersV2Model";
import { unsafeHTML } from "lit-html/directives/unsafe-html";

export interface BundleSsrViewModel {
    Id: string;
    SsrCodes: string[];
    IconName: string;
    TooltipInfo?: string | { [key in BundleType]: TemplateResult };
    SsrName: string | { [key in BundleType]: TemplateResult };
    IsFee?: boolean;
}

export const allPossibleBundleSsrs = (
    isFlexiFeeForced: boolean,
    concatenateBagTypes: boolean,
): BundleSsrViewModel[] => {
    const list = [
        {
            Id: "Base Fare",
            SsrCodes: [ALWAYS_AVAILABLE_SSR_CODE],
            IconName: "js-icon js-cr-cards",
            SsrName: i18next.t("Tarifa Aérea"),
            IsFee: true,
        },
        {
            Id: "Small Cabin Bag",
            SsrCodes: [ALWAYS_AVAILABLE_SSR_CODE],
            IconName: "js-icon-bag js-bag-backpack",
            TooltipInfo: i18next.t(
                "Es el equipaje que puedes llevar a bordo durante el viaje y que está incluido en tu tarifa SMART.",
            ),
            SsrName: i18next.t("Mochila o artículo personal"),
        },
        {
            Id: "Miles Accrual",
            SsrCodes: AMERICAN_AIRLINES_FREQUENT_FLYER_NUMBER_FEE_CODES,
            IconName: "js-new js-n-american-airlines",
            TooltipInfo: {
                None: html`${unsafeHTML(
                    i18next.t("AaNoBundleSsrTooltip {{-reg}}", {
                        reg: '<span class="relative font-body top-[-2px]">&reg;</span>',
                    }),
                )}`,
                Simple: html`${unsafeHTML(
                    i18next.t("AaBundleSsrTooltip {{-reg}}", {
                        reg: '<span class="relative font-body top-[-2px]">&reg;</span>',
                    }),
                )}`,
                Full: html`${unsafeHTML(
                    i18next.t("AaBundleSsrTooltip {{-reg}}", {
                        reg: '<span class="relative font-body top-[-2px]">&reg;</span>',
                    }),
                )}`,
            },
            SsrName: {
                None: html`${unsafeHTML(
                    i18next.t("Millas AAdvantage &times;2 {{-reg}}", {
                        reg: '<span class="relative font-body top-[-2px]">&reg;</span>',
                    }),
                )}`,
                Simple: html`${unsafeHTML(
                    i18next.t("Millas AAdvantage &times;5 {{-reg}}", {
                        reg: '<span class="relative font-body top-[-2px]">&reg;</span>',
                    }),
                )}`,
                Full: html`${unsafeHTML(
                    i18next.t("Millas AAdvantage &times;5 {{-reg}}", {
                        reg: '<span class="relative font-body top-[-2px]">&reg;</span>',
                    }),
                )}`,
            },
        },
        {
            Id: "Flexi Fee",
            SsrCodes: isFlexiFeeForced ? [ForcedFlexiFeePlaceholderCode] : FLEXI_FEE_CODES,
            IconName: "js-icon-bundle js-bundle-flexi-smart-no-border",
            TooltipInfo: i18next.t(
                "Con este opcional, podrás modificar tu reserva por una vez hasta una hora antes del vuelo sin pagar el cargo por cambio. *Aplica diferencia tarifaria si corresponde.",
            ),
            SsrName: i18next.t("FlexiSMART"),
        },
        ...baggageSsrs(concatenateBagTypes),
        {
            Id: "Priority Boarding",
            SsrCodes: PRIORITY_BOARDING_CODES,
            IconName: "js-icon js-priority",
            TooltipInfo: i18next.t("Te ahorras la fila y embarcas el avión antes que todos los demás pasajeros."),
            SsrName: i18next.t("Embarque prioritario"),
        },
        {
            Id: "Standard Seats",
            SsrCodes: [StandardSeatsInBundlePlaceholder],
            IconName: "js-icon js-cr-seats1",
            TooltipInfo: i18next.t("Filas 15 a 32"),
            SsrName: i18next.t("Asientos filas 15-32"),
        },
        {
            Id: "All Seats",
            SsrCodes: [AllSeatsInBundlePlaceholder],
            IconName: "js-icon js-seats-all",
            TooltipInfo: i18next.t("Puedes seleccionar el asiento que quieras."),
            SsrName: i18next.t("Asiento primera fila"),
        },
        {
            Id: "Airport Check-in",
            SsrCodes: AIRPORT_CHECKIN_CODES,
            IconName: "js-icon js-boarding-card",
            TooltipInfo: i18next.t("Imprime tu tarjeta de embarque en el counter de aeropuerto."),
            SsrName: i18next.t("Impresión tarjeta de embarque"),
        },
    ];

    return list;
};

const baggageSsrs = (concatenate: boolean) =>
    concatenate
        ? [
              {
                  Id: "Cabin and Checked Bags",
                  SsrCodes: [...CABIN_BAG_CODES, ...CHECKED_BAG_CODES],
                  IconName: "js-icon-bundle js-bundle-cabin-and-checked-bags",
                  TooltipInfo: i18next.t("Es el equipaje que puedes llevar en la cabina y bodega del avión."),
                  SsrName: i18next.t("Equipaje de Mano y Facturado"),
              },
          ]
        : [
              {
                  Id: "Large Cabin Bag",
                  SsrCodes: CABIN_BAG_CODES,
                  IconName: "js-icon-bag js-bag-regular-baggage",
                  TooltipInfo: i18next.t(
                      "Es el equipaje que puedes llevar a bordo durante el viaje en la cabina del avión.",
                  ),
                  SsrName: i18next.t("Equipaje de mano"),
              },
              {
                  Id: "Checked Bag",
                  SsrCodes: CHECKED_BAG_CODES,
                  IconName: "js-icon-bag js-bag-zipper-baggage",
                  TooltipInfo: i18next.t("Es el equipaje que llevas en la bodega del avión."),
                  SsrName: i18next.t("Equipaje Facturado"),
              },
          ];
