import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { ApiPortalSpaViewModel } from "../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import { getAntiForgeryTokenFromHtml, getParsedProperty } from "../../shared/common";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useEffect } from "haunted";
import { PortalSpaSection, usePortalSectionContent } from "./usePortalSectionContent";
import { useSpaUserType } from "./useSpaUserType";

export const name = "ac-portal-spa-container";

export const observedAttributes: (keyof Attributes)[] = ["model", "anti-forgery-token", "active-section"];

export interface Attributes {
    "anti-forgery-token": string;
    "active-section": string;
    "model": string;
}

export interface Props {
    antiForgeryToken: string;
    activeSection: PortalSpaSection;
    model: ApiPortalSpaViewModel;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        antiForgeryToken: host.antiForgeryToken,
        activeSection: host.activeSection,
        model: getParsedProperty<ApiPortalSpaViewModel>(host.model),
    };

    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");
    const [userContext] = useReduxState("userContext");
    const { getUserType } = useSpaUserType();

    const contentComponent = usePortalSectionContent({
        model: props.model,
        antiForgeryToken: props.antiForgeryToken,
    });

    const currentContent = userContext?.userRole
        ? contentComponent.getContent(getUserType(), props.activeSection)
        : undefined;

    // HELPERS

    const init = () => {
        if (!userContext?.userRole) return;

        if (typeof currentContent.init === "function") currentContent.init();
    };

    // COMPONENT

    useEffect(init, [userContext?.userRole]);

    useEffect(() => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));
    }, []);

    // TEMPLATES

    return currentContent ? html`${currentContent.htmlTemplate()}` : html``;
};
