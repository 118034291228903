import { useState } from "./../../shared/haunted/CustomHooks";
import { useEffect } from "../../shared/haunted/CustomHooks";
import { ROUTES } from "../../shared/apiRoutes";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useFlowContext } from "../../managers/useFlowContext";

// DEVNOTE Ping every 5 minutes
const pingFrequencyInMs = 300000;

export const useSessionKeepAlive = (keepAlivePeriodInMs: number, paxNumber: number) => {
    const init = () => {
        if (flowContext.isPostBookingFlow && paxNumber < 9) {
            setTimer(
                window.setTimeout(() => {
                    window.clearInterval(pingTimer);
                    window.clearTimeout(timer);
                }, keepAlivePeriodInMs),
            );

            setPingTimer(
                window.setInterval(() => {
                    void ping();
                    triggers.shared.resetSessionTimer.publish({});
                }, pingFrequencyInMs),
            );
        }

        return () => {
            window.clearInterval(pingTimer);
            window.clearTimeout(timer);
        };
    };

    const ping = () => ajaxRequest({ method: "GET", url: ROUTES.ApiRoutes.SessionKeepAlive });

    const flowContext = useFlowContext();

    const { triggers } = usePubSub();

    const { ajaxRequest } = useAjax();

    const [timer, setTimer] = useState<any>(undefined);
    const [pingTimer, setPingTimer] = useState<any>(undefined);

    useEffect(init, [keepAlivePeriodInMs, paxNumber]);
};
