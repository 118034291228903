import { classMap } from "lit-html/directives/class-map";
import { useEffect, useVolatileState } from "../../../../shared/haunted/CustomHooks";
import { html } from "lit-html";
import { CLASS_NAMES } from "../../../../shared/classNames";
import { ExtendedApiSeat } from "../../../../component-models/seatmap/ApiSeat";
import { SeatmapHelper } from "../../../../component-helpers/seatmap/SeatmapHelper";
import { SeatmapContext } from "../../../../component-models/seatmap/SeatmapContext";
import { ref } from "../../../../directives/ref";
import { useRef } from "haunted";
import { ROUTES } from "../../../../shared/apiRoutes";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { SeatmapStateUpdater } from "../../../../component-models/seatmap/SeatmapStateUpdater";
import { useSeatmapSegment } from "./useSeatmapSegment";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { useSeatsForAllModal } from "../modals/useSeatsForAllModal";
import { SeatmapState } from "../../../../component-models/seatmap/SeatmapState";

export interface Props {
    context: SeatmapContext;
    currentSeats: ExtendedApiSeat[];
    seatmapState: SeatmapState;
    showAutoReturnSeatSelectionBubble: boolean;
    isMobileRecommendatorOn: boolean;
    confirmMobileSeats: () => void;
    handleContinue: (e: MouseEvent, fromModal: boolean) => void;
    handleFreeSeatModal: (journeyIndex: number, segmentIndex: number, callback: () => void) => void;
    handleMobileNoSeats: (e: MouseEvent) => void;
    handleResetSelection: (e: MouseEvent) => void;
    handleSeatAutoAssignment: (journeyIndex: number, segmentIndex: number) => Promise<void>;
    handleSeatClick: (seat: ExtendedApiSeat) => void;
    mobileSeatReviewClose: () => void;
    showMobileReview: () => void;
    updateState: (newState: SeatmapStateUpdater) => void;
}

export const usePlane = (props: Props) => {
    // HELPERS

    const areAllSeated = () =>
        SeatmapHelper.doAllPaxHaveSeats(
            props.currentSeats,
            props.seatmapState.CurrentJourney,
            props.context.Model.Passengers,
        );

    const handleAnimationToggle = () => {
        if (animate) {
            planeBody.current?.classList.add(CLASS_NAMES.faded);
        } else {
            planeBody.current?.classList.remove(CLASS_NAMES.faded);
        }
    };

    // COMPONENT

    const flowContext = useFlowContext();

    const [userContext] = useReduxState("userContext");

    const planeBody = useRef<HTMLDivElement>(undefined);

    const [animate, setAnimate] = useVolatileState<boolean>(500);

    const seatsForAllModal = useSeatsForAllModal();

    const segment = useSeatmapSegment({
        context: props.context,
        currentSeats: props.currentSeats,
        seatmapState: props.seatmapState,
        handleSeatClick: props.handleSeatClick,
        updateState: props.updateState,
    });

    useEffect(handleAnimationToggle, [animate]);

    // EVENT LISTENERS

    const handleMobileReviewSeats = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (SeatmapHelper.isContinueBtnDisabledForCheckin(props.context, props.seatmapState, props.currentSeats)) {
            return;
        }

        if (areAllSeated() || userContext?.chileCompra.role !== "none") {
            props.handleFreeSeatModal(
                props.seatmapState.CurrentJourney.JourneyIndex,
                props.seatmapState.CurrentSegment.SegmentIndex,
                props.showMobileReview,
            );
        } else {
            seatsForAllModal.open(props.showMobileReview);
        }
    };

    // TEMPLATES

    const mobileButtonsContainerTemplate = () =>
        !props.context.Model?.IsReadOnly
            ? html`
                  <div class="mobile-seatmap-button-container">
                      ${mobileAutoAssignmentButtonTemplate()} ${mobileResetSeatsButtonTemplate()}
                      ${mobileSelectNoSeatButtonTemplate()}${mobileContinueButtonsTemplate()}
                  </div>
              `
            : "";

    const mobileAutoAssignmentButtonTemplate = () => {
        const tempClassMap = classMap({
            "rounded-secondary-btn": true,
            "mobile-seatmap-direction-btn": true,
            "elevated": true,
            "hidden":
                props.showAutoReturnSeatSelectionBubble ||
                SeatmapHelper.doAllPaxHaveSeats(
                    props.currentSeats,
                    props.seatmapState?.CurrentJourney,
                    props.context.Model?.Passengers,
                ),
        });

        return SeatmapHelper.showSeatAutoAssignmentButton(props.context, props.seatmapState)
            ? html`
                  <button
                      @click=${() =>
                          props.handleSeatAutoAssignment(
                              props.seatmapState.CurrentJourney.JourneyIndex,
                              props.seatmapState.CurrentSegment.SegmentIndex,
                          )}
                      class=${tempClassMap}
                  >
                      ${window.seatmapResources.seatAutoAssignText}
                  </button>
              `
            : "";
    };

    const mobileSelectNoSeatButtonTemplate = () =>
        !props.isMobileRecommendatorOn &&
        !flowContext.isPostBookingFlow &&
        !SeatmapHelper.showResetSeatsButton(props.context, props.currentSeats) &&
        userContext?.chileCompra.role === "none"
            ? html`
                  <button
                      @click=${props.handleMobileNoSeats}
                      class="rounded-secondary-btn mobile-seatmap-direction-btn elevated"
                  >
                      ${window.seatmapResources.noSeatText}
                  </button>
              `
            : "";

    const mobileResetSeatsButtonTemplate = () =>
        SeatmapHelper.showResetSeatsButton(props.context, props.currentSeats) && userContext?.chileCompra.role === "none"
            ? html`
                  <button
                      @click=${props.handleResetSelection}
                      class="rounded-secondary-btn mobile-seatmap-direction-btn elevated"
                  >
                      ${window.seatmapResources.resetSeatText}
                  </button>
              `
            : "";

    const mobileContinueButtonsTemplate = () => {
        return SeatmapHelper.isContinueBtnDisabledForCheckin(props.context, props.seatmapState, props.currentSeats)
            ? html`
                  <ac-tooltip
                      .customClass=${"mobile-seatmap-continue-tooltip-container"}
                      .icon=${mobileContinueButtonTemplate()}
                      .tooltip=${window.seatmapResources.checkinTooltip}
                  ></ac-tooltip>
              `
            : mobileContinueButtonTemplate();
    };

    const mobileContinueButtonTemplate = () => {
        const hasChanges = SeatmapHelper.hasSeatChangesOnLastLeg(props.context, props.seatmapState, props.currentSeats);

        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "mobile-seatmap-direction-btn": true,
            "no-click":
                SeatmapHelper.isContinueBtnDisabledForCheckin(props.context, props.seatmapState, props.currentSeats) ||
                SeatmapHelper.isContinueBtnDisabledForChileCompra(
                    props.context,
                    props.seatmapState,
                    props.currentSeats,
                    userContext,
                ),
        });

        return html`
            <button
                class=${tempClassMap}
                data-test-id="${hasChanges
                    ? T.SEATMAP.REVIEW_BUTTON_MOBILE
                    : getTestId(T.SEATMAP.CONTINUE_BUTTON, { m: true })}"
                @click=${hasChanges ? handleMobileReviewSeats : props.handleContinue}
            >
                ${hasChanges ? window.seatmapResources.reviewText : window.seatmapResources.continueText}
            </button>
        `;
    };

    const mobileReadOnlyContinueButtonTemplate = () =>
        props.context.Model?.IsReadOnly
            ? html`
                  <a
                      href=${`${ROUTES.PassengersCheckin}?sk=${props.context.Model.SellKeyForCheckinUrl}`}
                      class="rounded-secondary-btn mobile-seatmap-direction-btn"
                      data-test-id="${getTestId(T.SEATMAP.CONTINUE_BUTTON, { m: true })}"
                  >
                      ${window.seatmapResources.continueText}
                  </a>
              `
            : "";

    const mainClassMap = classMap({
        "cf-plane-body": true,
        "banco-estado-56": [5, 6].includes(userContext.bancoEstado.category),
        "disabled": props.showAutoReturnSeatSelectionBubble,
        "grayscale-faded": props.showAutoReturnSeatSelectionBubble,
        "grayscale": props.showAutoReturnSeatSelectionBubble,
    });

    const htmlTemplate = () => html`
        <div class=${mainClassMap} ref=${ref(planeBody)}>
            <div class="cf-plane-inside" data-test-id=${T.SEATMAP.PLANE_INSIDE}>
                ${segment.htmlTemplate()} ${mobileButtonsContainerTemplate()} ${mobileReadOnlyContinueButtonTemplate()}
            </div>
        </div>
        ${seatsForAllModal.htmlTemplate()}
    `;

    return {
        animate: () => setAnimate(true),
        htmlTemplate,
    };
};
