import i18next from "i18next";
import { TemplateResult, html } from "lit-html";
import {
    getRequestBodyFromInputs,
    isKeyNumeric,
    mapCountryCallingCodeListToHtmlOptions,
    sanitizePhoneNumber,
    sanitizeRuc,
    sanitizeRutFieldValue,
    updateMdl,
} from "../../shared/common";
import {
    ARGENTINIAN_CULTURE_CODE,
    CHILEAN_CULTURE_CODE,
    COLOMBIAN_CULTURE_CODE,
    ECUADORIAN_CULTURE_CODE,
    PERUVIAN_CULTURE_CODE,
    PREFIX_CODES,
} from "../../shared/commonConstants";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { useRef } from "haunted";
import { ref } from "../../directives/ref";
import { resetErrorMessages } from "../../shared/errorHandler";
import { useBillingTypeSelector } from "./useBillingTypeSelector";
import { useCuitInBilling } from "./useCuitInBilling";
import { useCuitInInvoice } from "./useCuitInInvoice";
import { Payer } from "./usePayer";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { commonValidationRules } from "../../shared/commonValidationRules";
import { unconditionalRut } from "../../shared/customHooks/useForm/custom-attributes/unconditionalRut";
import { PaymentMode } from "../../component-models/payment/PaymentMode";
import { TestIdDictionary as T, getTestId } from "../../testing-helpers/TestIdHelper";
import { useBookingManager } from "../../managers/useBookingManager";
import { useAppContext } from "../../managers/useAppContext";
import { startsWithLetter } from "../../component-helpers/stringHelper";
import { useSuperForm } from "../../shared/customHooks/useSuperForm/useSuperForm";
import { sanitizeChileanPhoneInput, sanitizeInputFieldValue } from "../../component-helpers/InputSanitizerHelper";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import BookingData from "../../shared/BookingData";
import { paymentHelper } from "../../component-helpers/payment/PaymentHelper";
import classNames from "classnames";
import { ANTI_FORGERY_TOKEN_PROPERTY_NAME } from "../../shared/customHooks/useAjax/useAjax";

export interface Props {
    contactEmail: string;
    isValidated: boolean;
    model: PaymentPageViewModel;
    payer: Payer;
    paymentMode: PaymentMode;
}

export interface Invoicing {
    isInvoiceFormShown: boolean;
    htmlTemplate: () => TemplateResult | string;
    submitForm: () => Promise<{ BookingSummary: BookingData }>;
    validateForm: () => Promise<boolean>;
}

export const useInvoicing = (props: Props): Invoicing => {
    const basicModel = props.model?.BillingViewModel.BasicInvoiceModel;
    const extendedModel = props.model?.BillingViewModel.ExtendedInvoiceModel;
    const chileCompraModel = props.model?.BillingViewModel.ChileCompraInvoiceModel;

    const presetPhone = () => {
        const prefixFromCountryList =
            appContext.PhonePrefixes.find(
                (countryWithCallingCode) => countryWithCallingCode.CountryCode === appContext.Country,
            )?.Code ?? "";

        if (extendedModel.HomePhone?.split(" ")[1] === undefined) {
            return {
                number: "",
                prefix: prefixFromCountryList,
            };
        }

        const number = extendedModel.HomePhone?.split(" ")[1] ?? "";
        const prefixFromStoredNumber =
            extendedModel.HomePhone?.split(" ")[0]
                .replace(/[^0-9.]+/g, "")
                .trim() ?? "";

        return {
            number,
            prefix: prefixFromStoredNumber || prefixFromCountryList,
        };
    };

    const initialCompanyName = () =>
        userContext?.chileCompra.role !== "none"
            ? `${chileCompraModel.Name.Last || ""}${chileCompraModel.Name.Middle || ""}`
            : props.model.BillingViewModel.ShowExtendedInvoice
              ? `${extendedModel.Name.Last || ""}${extendedModel.Name.Middle || ""}`
              : `${basicModel.Name.Last || ""}${basicModel.Name.Middle || ""}`;

    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const bookingManager = useBookingManager();

    const { showBilling } = paymentHelper();

    const [antiForgeryToken] = useReduxState("antiForgeryToken");
    const [userContext] = useReduxState("userContext");

    const root = useRef<HTMLDivElement>(undefined);
    const formElem = useRef<HTMLFormElement>(undefined);
    const phonePrefixElem = useRef<HTMLDivElement>(undefined);
    const phoneNumberElem = useRef<HTMLDivElement>(undefined);

    const [phoneNumber, setPhoneNumber] = useState<string>(undefined);
    const [phonePrefix, setPhonePrefix] = useState<string>(undefined);
    const [companyName, setCompanyName] = useState<string>(undefined);
    const [ruc, setRuc] = useState<string>(undefined);
    const [rut, setRut] = useState<string>(undefined);

    const billingTypeSelector = useBillingTypeSelector({
        model: props.model,
        onSelect: () => resetErrorMessages(root.current),
    });

    const cuitInInvoice = useCuitInInvoice({
        isValidated: props.isValidated,
        payer: props.payer,
    });

    const cuitInBilling = useCuitInBilling({
        isValidated: props.isValidated,
        payer: props.payer,
    });

    const form = useSuperForm({
        formElem: formElem.current,
        customAttributes: [...commonValidationRules(), unconditionalRut()],
    });

    // DEVNOTE ChileCompra requires Invoice to be selected but no form displayed
    const isInvoiceFormShown = billingTypeSelector.isInvoiceSelected && userContext.chileCompra.role === "none";

    const isEcuador = () => appContext.Culture === ECUADORIAN_CULTURE_CODE;

    const isColombia = () => appContext.Culture === COLOMBIAN_CULTURE_CODE;

    const getPostedPhoneNumber = () =>
        isEcuador() ? `${phonePrefix || ""} ${phoneNumber || ""}`.trim() : extendedModel.HomePhone;

    const companyIdLabel = () => {
        switch (appContext.Culture) {
            case ECUADORIAN_CULTURE_CODE:
                return i18next.t("Número de identificación");
            case ARGENTINIAN_CULTURE_CODE:
                return i18next.t("CUIT / CUIL");
            case PERUVIAN_CULTURE_CODE:
                return i18next.t("Ruc empresa");
            case COLOMBIAN_CULTURE_CODE:
                return i18next.t("NIT");
            default:
                return i18next.t("Rut empresa (Ej: 16756872-6)");
        }
    };

    const companyNameLabel = () =>
        isEcuador()
            ? i18next.t("Nombre persona natural o jurídica")
            : props.model.BillingViewModel.ShowArgentinianLabels
              ? i18next.t("CUG-ArInvoice-CompanyName")
              : i18next.t("Contact-CompanyName");

    const giroLabel = () =>
        props.model.BillingViewModel.ShowArgentinianLabels
            ? i18next.t("CUG-ArInvoice-Giro")
            : i18next.t("Contact-Giro");

    const emailLabel = () => (isEcuador() ? i18next.t("Correo electrónico") : i18next.t("Invoice-Email"));

    const regionLabel = () =>
        props.model.BillingViewModel.ShowArgentinianLabels
            ? i18next.t("CUG-ArInvoice-Region")
            : i18next.t("Contact-Area");

    const validateForm = async () => {
        if (
            !showBilling(props.model, flowContext.isFlightlessPurchaseFlow) ||
            !billingTypeSelector.isInvoiceSelected ||
            userContext.peruCompra.role !== "none" ||
            bookingContext.isPeruCompraBooking ||
            flowContext.isCugFundingFlow
        ) {
            return true;
        }

        const isFormValid = await form.validate();
        return isFormValid;
    };

    const companyNamePartials = () => {
        if (companyName.length <= 32) return [companyName, ""];

        // Split the company name in two parts, leave 4 extra space characters in part1 for special characters
        let part1 = companyName.slice(0, 28);
        let part2 = companyName.slice(28);

        // If part2 starts with a special character or number, move one character to part1
        while (!startsWithLetter(part2)) {
            part1 += part2.charAt(0);
            part2 = part2.slice(1);
        }

        return [part1, part2];
    };

    const submitForm = async () => {
        const [lastName, middleName] = companyNamePartials();

        const body =
            userContext.chileCompra.role !== "none" || bookingContext.isChileCompraBooking
                ? {
                      [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: antiForgeryToken,
                      "isInvoiceNeeded": "true",
                      "jetSmartInvoiceDomestic.AddressLine1": chileCompraModel.Address,
                      "jetSmartInvoiceDomestic.AddressLine2": chileCompraModel.CompanyId,
                      "jetSmartInvoiceDomestic.AddressLine3": chileCompraModel.Area,
                      "jetSmartInvoiceDomestic.City": chileCompraModel.City,
                      "jetSmartInvoiceDomestic.CompanyName": chileCompraModel.Giro,
                      "jetSmartInvoiceDomestic.CostCenter": chileCompraModel.CostCenter,
                      "jetSmartInvoiceDomestic.CustomerNumber": chileCompraModel.CustomerNumber,
                      "jetSmartInvoiceDomestic.DistributionOption": chileCompraModel.DistributionOption.toString(),
                      "jetSmartInvoiceDomestic.EmailAddress": props.contactEmail,
                      "jetSmartInvoiceDomestic.HomePhone": chileCompraModel.HomePhone,
                      "jetSmartInvoiceDomestic.Name.First": chileCompraModel.Name.First,
                      "jetSmartInvoiceDomestic.Name.Last": lastName,
                      "jetSmartInvoiceDomestic.Name.Middle": middleName,
                      "jetSmartInvoiceDomestic.NameOfCompany": companyName,
                      "jetSmartInvoiceDomestic.OtherPhone": chileCompraModel.OtherPhone,
                      "jetSmartInvoiceDomestic.PostalCode": chileCompraModel.PostalCode,
                      "jetSmartInvoiceDomestic.TypeCode": chileCompraModel.TypeCode,
                  }
                : {
                      ...getRequestBodyFromInputs(formElem.current),
                      [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: antiForgeryToken,
                  };
        const container = root.current.parentElement;

        return bookingManager.postInvoice(body, container);
    };

    const handleInput = (e: KeyboardEvent) => sanitizeInputFieldValue(e, "alphanumeric");

    const handleEmailInput = (e: KeyboardEvent) => sanitizeInputFieldValue(e, "e-mail");

    const handleCompanyNameInput = (e: KeyboardEvent) => {
        setCompanyName(sanitizeInputFieldValue(e, "no-special-character"));
    };

    const handleRutDash = (e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement;
        sanitizeRutFieldValue(target);
        setRut(target.value);
    };

    const handleRuc = (e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement;
        sanitizeRuc(target);
        setRuc(target.value);
    };

    const handlePhoneInput = (e: KeyboardEvent) => {
        const value = (e.target as HTMLInputElement).value;

        const sanitizedValue =
            phonePrefix === PREFIX_CODES.get(CHILEAN_CULTURE_CODE)
                ? sanitizeChileanPhoneInput(e)
                : sanitizePhoneNumber(value, false);

        setPhoneNumber(sanitizedValue);
    };

    const handlePhonePrefixChange = (e: MouseEvent) => setPhonePrefix((e.target as HTMLSelectElement).value);

    const handlePhoneChange = (e: KeyboardEvent) => {
        if (isKeyNumeric(e, false)) {
            return true;
        } else {
            e.preventDefault();
            return false;
        }
    };

    useEffect(() => {
        if (!props.model) return;
        setCompanyName(initialCompanyName());
        setRuc(basicModel.CompanyId);
        setRut(basicModel.CompanyId);
    }, [billingTypeSelector.isInvoiceSelected, props.model, basicModel]);

    useEffect(() => {
        if (!props.model || phoneNumber || phonePrefix) return;
        setPhoneNumber(presetPhone().number);
        setPhonePrefix(presetPhone().prefix);
    }, [props.model]);

    const commonInputClassMap = classNames("mdl-textfield__input js-input", {
        disabled: props.model?.BillingViewModel.ReadonlyInvoice,
    });

    const cug2InputClassMap = classNames("mdl-textfield__input js-input", {
        disabled: props.model?.BillingViewModel.ReadonlyInvoice || userContext.cug.isMember,
    });

    const commonTabIndex = props.model?.BillingViewModel.ReadonlyInvoice ? -1 : null;
    const cug2TabIndex = props.model?.BillingViewModel.ReadonlyInvoice || userContext.cug.isMember ? -1 : null;

    const argentinaInvoiceInfoTemplate = () =>
        props.model.BillingViewModel.ShowArgentinianLabels
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="ar-invoice-information">${i18next.t("ARInvoiceInformation")}</div>
                      </div>
                  </div>
              `
            : "";

    const companyIdReadonlyTemplate = () =>
        props.model.BillingViewModel.ReadonlyInvoice || userContext.cug.isMember
            ? inputFieldTemplate({
                  classNames: "mdl-textfield__input  js-input disabled",
                  label: companyIdLabel(),
                  name: "jetSmartInvoiceDomestic.AddressLine2",
                  isReadonly: true,
                  tabIndex: -1,
                  testId: "readonly-invoice-address-line-2",
                  value: extendedModel.CompanyId,
              })
            : "";

    const companyIdEcuadorTemplate = () =>
        isEcuador() && !props.model.BillingViewModel.ReadonlyInvoice && !userContext.cug.isMember
            ? inputFieldTemplate({
                  classNames: "mdl-textfield__input js-input",
                  label: companyIdLabel(),
                  max: 52,
                  name: "jetSmartInvoiceDomestic.AddressLine2",
                  testId: T.PAYMENT_INVOICE_FORM.ECUADOR_COMPANY_ID,
                  value: extendedModel.CompanyId,
              })
            : "";

    const companyIdRutTemplate = () =>
        !isEcuador() &&
        !props.model.BillingViewModel.ReadonlyInvoice &&
        !userContext.cug.isMember &&
        props.model.BillingViewModel.ShowRut
            ? inputFieldTemplate({
                  classNames: "mdl-textfield__input js-input",
                  label: companyIdLabel(),
                  max: 52,
                  name: "jetSmartInvoiceDomestic.AddressLine2",
                  testId: T.PAYMENT_INVOICE_FORM.RUT_COMPANY,
                  validators: !isColombia() ? ["rut"] : [],
                  value: rut,
                  onInput: !isColombia() ? handleRutDash : null,
              })
            : "";

    const companyIdRucTemplate = () =>
        !props.model.BillingViewModel.ReadonlyInvoice &&
        !userContext.cug.isMember &&
        props.model.BillingViewModel.ShowRuc
            ? inputFieldTemplate({
                  classNames: "mdl-textfield__input js-input",
                  label: companyIdLabel(),
                  max: 11,
                  name: "jetSmartInvoiceDomestic.AddressLine2",
                  testId: "ruc-invoice-address-line-2",
                  value: ruc,
                  onInput: handleRuc,
              })
            : "";

    const companyIdCuitTemplate = () =>
        !props.model.BillingViewModel.ReadonlyInvoice &&
        !userContext.cug.isMember &&
        !props.model.BillingViewModel.ShowRut &&
        !props.model.BillingViewModel.ShowRuc &&
        appContext.Culture === ARGENTINIAN_CULTURE_CODE
            ? html`
                  <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                      <label class="mdl-textfield__label">${companyIdLabel()} *</label>
                      ${cuitInInvoice.htmlTemplate()}
                  </div>
              `
            : "";

    const companyNameTemplate = () => {
        const dataTestId =
            props.model.BillingViewModel.ReadonlyInvoice || userContext.cug.isMember
                ? getTestId(T.PAYMENT_INVOICE_FORM.COMPANY_NAME, { c: "readonly" })
                : getTestId(T.PAYMENT_INVOICE_FORM.COMPANY_NAME, { c: "regular" });

        return inputFieldTemplate({
            classNames: cug2InputClassMap,
            label: companyNameLabel(),
            max: 60,
            name: "jetSmartInvoiceDomestic.NameOfCompany",
            isReadonly: props.model.BillingViewModel.ReadonlyInvoice || userContext.cug.isMember,
            tabIndex: cug2TabIndex,
            testId: dataTestId,
            value: companyName,
            onInput: handleCompanyNameInput,
        });
    };

    const giroTemplate = () => {
        const dataTestId =
            props.model.BillingViewModel.ReadonlyInvoice || userContext.cug.isMember
                ? getTestId(T.PAYMENT_INVOICE_FORM.GIRO, { c: "readonly" })
                : getTestId(T.PAYMENT_INVOICE_FORM.GIRO, { c: "regular" });

        return appContext.Culture !== ECUADORIAN_CULTURE_CODE
            ? inputFieldTemplate({
                  classNames: cug2InputClassMap,
                  label: giroLabel(),
                  max: 64,
                  name: "jetSmartInvoiceDomestic.CompanyName",
                  tabIndex: cug2TabIndex,
                  testId: dataTestId,
                  value: extendedModel.Giro,
              })
            : "";
    };

    const addressTemplate = () =>
        inputFieldTemplate({
            label: i18next.t("V2-Address"),
            max: 52,
            name: "jetSmartInvoiceDomestic.AddressLine1",
            tabIndex: commonTabIndex,
            testId: T.PAYMENT_INVOICE_FORM.ADDRESS,
            value: extendedModel.Address,
        });

    const regionTemplate = () =>
        inputFieldTemplate({
            label: regionLabel(),
            max: 52,
            name: "jetSmartInvoiceDomestic.AddressLine3",
            tabIndex: commonTabIndex,
            testId: T.PAYMENT_INVOICE_FORM.REGION,
            value: extendedModel.Area,
        });

    const cityTemplate = () =>
        inputFieldTemplate({
            label: i18next.t("V2-City"),
            max: 32,
            name: "jetSmartInvoiceDomestic.City",
            tabIndex: commonTabIndex,
            testId: T.PAYMENT_INVOICE_FORM.CITY,
            value: extendedModel.City,
        });

    const emailTemplate = (isExtended: boolean) =>
        inputFieldTemplate({
            label: emailLabel(),
            max: 266,
            name: isExtended ? "jetSmartInvoiceDomestic.EmailAddress" : "jetSmartInvoiceInternational.EmailAddress",
            tabIndex: commonTabIndex,
            testId: T.PAYMENT_INVOICE_FORM.EMAIL,
            validators: ["email"],
            value:
                userContext.peruCompra.role !== "none" || bookingContext.isPeruCompraBooking
                    ? props.contactEmail
                    : isExtended
                      ? extendedModel.EmailAddress
                      : basicModel.EmailAddress,
            onInput: handleEmailInput,
        });

    const postalCodeTemplate = () =>
        isEcuador()
            ? inputFieldTemplate({
                  label: i18next.t("Código postal"),
                  name: "jetSmartInvoiceDomestic.PostalCode",
                  tabIndex: commonTabIndex,
                  testId: T.PAYMENT_INVOICE_FORM.POSTAL_CODE,
                  value: extendedModel.PostalCode,
              })
            : "";

    const costCenterTemplate = () =>
        appContext.Culture === CHILEAN_CULTURE_CODE
            ? html`
                  <div class="col-xs-1 col-md-1-2">
                      ${inputFieldTemplate({
                          isOptional: true,
                          label: i18next.t("Invoice-CostCenter"),
                          name: "jetSmartInvoiceDomestic.CostCenter",
                          tabIndex: commonTabIndex,
                          testId: T.PAYMENT_INVOICE_FORM.EMAIL_COST_CENTER,
                          value: extendedModel.CostCenter,
                      })}
                  </div>
              `
            : "";

    const phoneTemplate = () =>
        isEcuador()
            ? html`
                  <div class="col-xs-1 col-md-1-2">
                      <div class="row">
                          <div class="col-xs-1 col-sm-1-2">${phonePrefixTemplate()}</div>
                          <div class="col-xs-1 col-sm-1-2">${phoneNumberTemplate()}</div>
                      </div>
                  </div>
              `
            : "";

    const phonePrefixOptionsTemplate = () =>
        mapCountryCallingCodeListToHtmlOptions(appContext.PhonePrefixes, appContext.Culture, phonePrefix);

    const phonePrefixTemplate = () => html`
        <div ref=${ref(phonePrefixElem)} class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label ">
            <select
                class="mdl-textfield__input js-input js-select"
                data-required
                phone-prefix
                data-test-id=${T.PAYMENT_CONTACT_FORM.HOMEPHONE_PREFIX}
                .value=${phonePrefix}
                @change=${handlePhonePrefixChange}
            >
                ${phonePrefixOptionsTemplate()}
            </select>
            <label class="mdl-textfield__label">${i18next.t("V2-PhoneCountryLabel")} *</label>
        </div>
    `;

    const phoneNumberTemplate = () => html`
        <div ref=${ref(phoneNumberElem)} class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
            <input
                class="mdl-textfield__input js-input ts-contact-phone-number"
                autocomplete="off"
                data-required
                data-test-id=${T.PAYMENT_CONTACT_FORM.HOMEPHONE_NUMBER}
                chile-length
                max-non-chile
                .value=${phoneNumber}
                @input=${handlePhoneInput}
                @keydown=${handlePhoneChange}
            />
            <label class="mdl-textfield__label">${i18next.t("V2-PhoneLabel")} *</label>
        </div>
    `;

    const basicTemplateBusinessNameTemplate = () =>
        inputFieldTemplate({
            label: i18next.t("Passengers-BusinessName"),
            max: 64,
            name: "jetSmartInvoiceInternational.Name.Last",
            tabIndex: commonTabIndex,
            testId: "last-name",
            value: basicModel.Name.Last,
        });

    const rucTemplate = () =>
        inputFieldTemplate({
            label: i18next.t("Passengers-RUC"),
            max: 52,
            name: "jetSmartInvoiceInternational.AddressLine2",
            tabIndex: commonTabIndex,
            testId: "address-line-2",
            value: basicModel.CompanyId,
        });

    const basicInvoiceTemplate = () => html`
        <div id="invoceDataContainer">
            ${argentinaInvoiceInfoTemplate()}

            <div class="row">
                <div class="col-xs-1 col-md-1-2">${basicTemplateBusinessNameTemplate()}</div>
                <div class="col-xs-1 col-md-1-2">${rucTemplate()}</div>
            </div>
            <div class="row">
                <div class="col-xs-1 col-md-1-2">${emailTemplate(false)}</div>
            </div>
        </div>
        ${hiddenInputTemplate("jetSmartInvoiceInternational.AddressLine1", basicModel.Address)}
        ${hiddenInputTemplate("jetSmartInvoiceInternational.CustomerNumber", basicModel.CustomerNumber)}
        ${hiddenInputTemplate("jetSmartInvoiceInternational.HomePhone", basicModel.HomePhone)}
        ${hiddenInputTemplate("jetSmartInvoiceInternational.Name.First", basicModel.Name.First)}
        ${hiddenInputTemplate("jetSmartInvoiceInternational.TypeCode", basicModel.TypeCode)}
    `;

    const regionAndCityTemplate = () =>
        appContext.Culture !== ECUADORIAN_CULTURE_CODE
            ? html` <div class="row">
                  <div class="col-xs-1 col-md-1-2">${regionTemplate()}</div>
                  <div class="col-xs-1 col-md-1-2">${cityTemplate()}</div>
              </div>`
            : "";

    const extendedInvoiceTemplate = () => {
        const [lastName, middleName] = companyNamePartials();

        return html`
            <div
                id="invoceDataContainer"
                class=${userContext.peruCompra.role !== "none" || bookingContext.isPeruCompraBooking ? "hidden" : ""}
            >
                ${argentinaInvoiceInfoTemplate()}

                <div class="row">
                    <div class="col-xs-1 col-md-1-2">
                        ${companyIdRutTemplate()} ${companyIdEcuadorTemplate()} ${companyIdReadonlyTemplate()}
                        ${companyIdCuitTemplate()} ${companyIdRucTemplate()}
                    </div>
                    <div class="col-xs-1 col-md-1-2">${companyNameTemplate()}</div>
                </div>

                <div class="row">
                    <div class="col-xs-1 col-md-1-2">${giroTemplate()} ${postalCodeTemplate()}</div>
                    <div class="col-xs-1 col-md-1-2">${addressTemplate()}</div>
                </div>

                ${regionAndCityTemplate()}

                <div class="row">
                    <div class="col-xs-1 col-md-1-2">${emailTemplate(true)}</div>
                    ${costCenterTemplate()} ${phoneTemplate()}
                </div>
            </div>
            ${hiddenInputTemplate("jetSmartInvoiceDomestic.CustomerNumber", extendedModel.CustomerNumber)}
            ${hiddenInputTemplate("jetSmartInvoiceDomestic.HomePhone", getPostedPhoneNumber())}
            ${hiddenInputTemplate("jetSmartInvoiceDomestic.Name.First", extendedModel.Name.First)}
            ${hiddenInputTemplate("jetSmartInvoiceDomestic.Name.Last", lastName)}
            ${hiddenInputTemplate("jetSmartInvoiceDomestic.Name.Middle", middleName)}
            ${hiddenInputTemplate("jetSmartInvoiceDomestic.OtherPhone", extendedModel.OtherPhone)}
            ${hiddenInputTemplate("jetSmartInvoiceDomestic.TypeCode", extendedModel.TypeCode)}
            ${hiddenInputTemplate(
                "jetSmartInvoiceDomestic.DistributionOption",
                extendedModel.DistributionOption.toString(),
            )}
        `;
    };

    const invoiceWarningTemplate = () =>
        props.model.BillingViewModel.ShowInvoiceWarning && !flowContext.isFlightlessPurchaseFlow
            ? html`
                  <div class="payment-message" data-test-id="payment-billing-message">
                      * ${i18next.t("Payment-InvoiceWarning")}
                  </div>
              `
            : "";

    const invoiceTemplate = () =>
        props.model.BillingViewModel.CanGetInvoice &&
        !flowContext.isFlightlessPurchaseFlow &&
        billingTypeSelector.isInvoiceSelected &&
        userContext.chileCompra.role === "none"
            ? props.model.BillingViewModel.ShowExtendedInvoice
                ? extendedInvoiceTemplate()
                : basicInvoiceTemplate()
            : "";

    const cuitHeaderTemplate = () => html`
        <header class="payment-header-pushdown">
            <span class="js-circle-percent-document js-icon title-icon"></span>
            <div class="title">
                <h2 class="main-title">${i18next.t("CUIT-CuitSectionTitle")}</h2>
            </div>
        </header>
    `;

    const billingTemplate = () => html`
        <form ref=${ref(formElem)}>
            ${invoiceWarningTemplate()}
            <div class="invoice-select ts-error-parent">${billingTypeSelector.htmlTemplate()} ${invoiceTemplate()}</div>
            ${hiddenBoolInputTemplate("isInvoiceNeeded", billingTypeSelector.isInvoiceSelected)}
        </form>
    `;

    const cuitAndBillingTemplate = () => html`
        ${cuitHeaderTemplate()}

        <section class="inner-deep-box ts-error-parent">${cuitInBilling.htmlTemplate()} ${billingTemplate()}</section>
    `;

    const peruCompraTemplate = () => html`
        <form ref=${ref(formElem)}>
            ${extendedInvoiceTemplate()} ${hiddenBoolInputTemplate("isInvoiceNeeded", true)}
        </form>
    `;

    const inputFieldTemplate = (data: {
        classNames?: string;
        isOptional?: boolean;
        label: string;
        max?: number;
        name: string;
        isReadonly?: boolean;
        tabIndex?: number;
        testId: string;
        validators?: string[];
        value: string;
        onInput?: (e: KeyboardEvent) => void;
    }) => html`
        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
            <label class="mdl-textfield__label">${data.label} ${!data.isOptional ? "*" : ""}</label>
            <input
                class=${data.classNames || commonInputClassMap}
                name=${data.name}
                autocomplete="off"
                data-test-id=${data.testId}
                max=${data.max?.toString() || "1000"}
                tabindex=${data.tabIndex?.toString() || null}
                ?email=${data.validators?.includes("email") ?? false}
                ?rut=${data.validators?.includes("rut") ?? false}
                ?data-required=${!data.isOptional}
                ?readonly=${data.isReadonly}
                value=${data.value || ""}
                @input=${data.onInput || handleInput}
            />
        </div>
    `;

    const hiddenInputTemplate = (name: string, value: string) => html`
        <input type="hidden" name=${name} value=${value} />
    `;

    const hiddenBoolInputTemplate = (name: string, value: boolean) => html`
        <input type="hidden" name=${name} value=${value} />
    `;

    const htmlTemplate = () =>
        (props.paymentMode === "none" || props.paymentMode === "regular") &&
        showBilling(props.model, flowContext.isFlightlessPurchaseFlow)
            ? html`
                  <div ref=${ref(root)}>
                      ${userContext.peruCompra.role !== "none" || bookingContext.isPeruCompraBooking
                          ? peruCompraTemplate()
                          : props.model.BillingViewModel.IsCuitFieldNeeded
                            ? cuitAndBillingTemplate()
                            : billingTemplate()}
                  </div>
                  ${updateMdl()}
              `
            : "";

    return {
        isInvoiceFormShown,
        htmlTemplate,
        submitForm,
        validateForm,
    };
};
