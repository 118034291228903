import classNames from "classnames";
import i18next from "i18next";
import { html } from "lit-html";
import { getInputId, getInputName } from "../../../../component-helpers/BaggageHelper";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { PerJourneyPerPaxPassengerDto } from "./per-journey-per-pax-passenger";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import { useBookingContext } from "../../../../managers/useBookingContext";

export interface Props {
    canAdd: boolean;
    dto: PerJourneyPerPaxPassengerDto;
    onRemoveAll: (e: MouseEvent) => void;
}

export const useFreeOptionCell = (props: Props) => {
    const bookingContext = useBookingContext();

    const currentStateJourney = useMemo(
        () => props.dto.baggageSection.sectionUi.uiState.journeys.find((j) => j.index === props.dto.journey.index),
        [props.dto.baggageSection.sectionUi.uiState.journeys, props.dto.journey.index],
    );

    const currentStatePassenger = useMemo(
        () => currentStateJourney?.passengers.find((p) => p.index === props.dto.passenger.index),
        [currentStateJourney, props.dto.passenger.index],
    );

    const id = getInputId(
        props.dto.bagType,
        props.dto.journey.index,
        props.dto.passenger.index,
        props.dto.isMobile,
        true,
    );
    const inputName = getInputName(
        props.dto.bagType,
        props.dto.journey.index,
        props.dto.passenger.index,
        props.dto.isMobile,
    );

    const freeBagName =
        props.dto.bagType === "cabinBaggage"
            ? i18next.t("Mochila o artículo personal")
            : props.dto.bagType === "checkedBaggage"
              ? i18next.t("No quiero llevar equipaje facturado")
              : i18next.t("No quiero llevar equipaje sobredimensionado");

    const isChecked = props.dto.passenger.quantity === 0 && (!props.canAdd || currentStatePassenger?.hasSelected);

    const isDisabled =
        props.dto.passenger.quantity > 0 &&
        !props.dto.baggageSection.perJourneyPerPax.isResetAvailable({
            bookingContext,
            canGetFirstBaggageBancoEstadoDiscount: props.dto.canGetFirstBaggageBancoEstadoDiscount,
            journeyIndex: props.dto.journey.index,
            passenger: props.dto.passenger,
        });

    const htmlTemplate = () => html`
        <input
            type="radio"
            name=${inputName}
            id=${id}
            ?disabled=${isDisabled}
            ?checked=${isChecked}
            .checked=${isChecked}
        />
        <label
            for=${id}
            class=${classNames({ checked: isChecked, disabled: isDisabled })}
            data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_RESET_BUTTON, {
                c: props.dto.bagType,
                j: props.dto.journey.index,
                m: props.dto.isMobile,
                p: props.dto.passenger.index,
            })}
            @click=${props.onRemoveAll}
        >
            ${freeBagName}
        </label>
    `;

    return { htmlTemplate };
};
