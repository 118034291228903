import { BancoEstadoBaggage } from "./common/useBancoEstadoBaggage";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { html, useEffect } from "haunted";
import i18next from "i18next";
import { BaggagePageState } from "../../../component-models/baggage/BaggagePageState";
import { finishedSelection } from "../../../component-helpers/BaggageHelper";
import { isMobile } from "../../../shared/common";
import { ApiBaggageViewModel } from "../../../component-models/baggage/ApiBaggageViewModel";
import { usePerJourneyPerPaxTable } from "./perJourneyPerPaxView/usePerJourneyPerPaxTable";
import { useMobilePerJourneyPerPaxTable } from "./perJourneyPerPaxView/useMobilePerJourneyPerPaxTable";
import { useMobilePerBookingOversized } from "./perBookingView/useMobilePerBookingOversized";
import { useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { StaffBaggage } from "./common/useStaffBaggage";
import classNames from "classnames";
import { useBookingContext } from "../../../managers/useBookingContext";
import { useBaggageSection } from "./common/useBaggageSection";
import { BaggageSectionJourney } from "../../../component-models/baggage/BaggageSectionJourney";
import { usePerBookingOversized } from "./perBookingView/usePerBookingOversized";
import { useBaggageUtils } from "./common/useBaggageUtils";

export interface Props {
    baggageSectionJourneys: BaggageSectionJourney[];
    bancoEstadoBaggage: BancoEstadoBaggage;
    classToScrollToOnClose: string;
    classToScrollToOnOpen: string;
    model: ApiBaggageViewModel;
    pageState: BaggagePageState;
    staffBaggage: StaffBaggage;
    setBaggageModel: (model: ApiBaggageViewModel) => void;
    setPageState: (updater: (newState: BaggagePageState) => BaggagePageState) => void;
}

export const useOversizedBags = (props: Props) => {
    const bookingContext = useBookingContext();
    const { formattedLowestNextPrice } = useBaggageUtils();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const oversizedBag = useBaggageSection({
        baggageSectionJourneys: props.baggageSectionJourneys,
        bagType: "oversizedBaggage",
        bancoEstadoBaggage: props.bancoEstadoBaggage,
        classToScrollToOnClose: props.classToScrollToOnClose,
        classToScrollToOnOpen: props.classToScrollToOnOpen,
        model: props.model,
        staffBaggage: props.staffBaggage,
        setModel: props.setBaggageModel,
        setPageState: props.setPageState,
    });

    const perJourneyPerPaxTable = usePerJourneyPerPaxTable({
        baggageSection: oversizedBag,
        bagType: "oversizedBaggage",
        bancoEstadoBaggage: props.bancoEstadoBaggage,
        classToScrollToOnOpen: props.classToScrollToOnOpen,
        pageState: props.pageState,
        staffBaggage: props.staffBaggage,
    });

    const mobilePerJourneyPerPaxTable = useMobilePerJourneyPerPaxTable({
        baggageSection: oversizedBag,
        bagType: "oversizedBaggage",
        bancoEstadoBaggage: props.bancoEstadoBaggage,
        pageState: props.pageState,
        staffBaggage: props.staffBaggage,
    });

    const mobilePerBookingOversized = useMobilePerBookingOversized({
        baggageSection: oversizedBag,
        bancoEstadoBaggage: props.bancoEstadoBaggage,
        staffBaggage: props.staffBaggage,
        setIsOversizedBaggageOpen: setIsOpen,
    });

    const showOversizedErrors = useMemo(
        () => isOpen && !finishedSelection(oversizedBag.sectionUi.uiState),
        [isOpen, oversizedBag.sectionUi.uiState],
    );

    const handlePerJourneyPerPaxViewOpen = () => {
        oversizedBag.sectionUi.openPerJourneyPerPaxView();
        setIsOpen(true);
    };

    const perBookingOversized = usePerBookingOversized({
        baggageSectionJourneys: props.baggageSectionJourneys,
        bancoEstadoBaggage: props.bancoEstadoBaggage,
        oversizedBag,
        staffBaggage: props.staffBaggage,
        handlePerJourneyPerPaxViewOpen,
    });

    const isOversizedSoldOutAndOneJourney = useMemo(
        () =>
            bookingContext.isOneWay &&
            props.baggageSectionJourneys[0].passengers.some((passenger) => passenger.isSoldOut),
        [bookingContext.isOneWay, props.baggageSectionJourneys],
    );

    const isOversizedBought = useMemo(
        () =>
            props.baggageSectionJourneys.some((journey) =>
                journey.passengers.some((passenger) => passenger.quantity > 0),
            ),
        [props.baggageSectionJourneys],
    );

    const reset = () => setIsOpen(isOversizedBought || isOversizedSoldOutAndOneJourney);

    const unformattedLowestNextPrice = (isMinimum: boolean) =>
        oversizedBag.perBooking.nextPrice({
            baggageSectionJourneys: props.baggageSectionJourneys,
            bagType: "oversizedBaggage",
            bancoEstadoBaggage: props.bancoEstadoBaggage,
            isMinimum,
            isOriginal: false,
            staffBaggage: props.staffBaggage,
        });

    const updateValidation = () => {
        if (!props.pageState.lastValidationTimestamp) return;

        const journeyWithError = oversizedBag.journeys.find((journey) =>
            journey.passengers.some((passenger) => !oversizedBag.isPassengerValid({ journey, passenger })),
        );

        if (
            journeyWithError &&
            oversizedBag.sectionUi.uiState.perJourneyPerPaxState === "open" &&
            !finishedSelection(oversizedBag.sectionUi.uiState)
        ) {
            const paxWithError = journeyWithError.passengers.find(
                (passenger) => !oversizedBag.isPassengerValid({ journey: journeyWithError, passenger }),
            );

            if (paxWithError) {
                oversizedBag.sectionUi.openPax({
                    journeyIndices: [journeyWithError.index],
                    paxIndices: [paxWithError.index],
                });
            }
        }
    };

    // Event handlers

    const handleAccordionToggle = () => setIsOpen(!isOpen);

    useEffect(updateValidation, [props.pageState.lastValidationTimestamp]);

    useEffect(reset, [props.model]);

    // Template

    const headerTemplate = () => {
        const tempClassMap = classNames("b2-oversized-top", {
            "per-pax": oversizedBag.sectionUi.uiState.perJourneyPerPaxState === "open" && isOpen,
        });

        const openerClassMap = classNames("b2-oversized-top-amount", {
            "hidden-sm-up": oversizedBag.sectionUi.uiState.perJourneyPerPaxState === "open" && isOpen,
        });

        const iconClassMap = classNames("js-icon js-circle-chevron-right", {
            rotated: isOpen,
        });

        return html`
            <div
                class=${tempClassMap}
                data-test-id=${T.BAGGAGE.OVERSIZED_ACCORDION_OPENER}
                @click=${handleAccordionToggle}
            >
                <div class="b2-oversized-title" data-test-id=${T.BAGGAGE.OVERSIZED_TITLE}>
                    <i class="js-icon-bag js-bag-guitar-surf-golf"></i>
                    <span>${i18next.t("¿Viajas con equipaje sobredimensionado?")}</span>
                </div>
                <div class="b2-oversized-opener">
                    <div class=${openerClassMap}>
                        ${unformattedLowestNextPrice(true) >= 0
                            ? html`
                                  ${i18next.t("Agrégalo desde")}
                                  <span
                                      data-test-value=${unformattedLowestNextPrice(true)}
                                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_MINIMUM_PRICE, {
                                          c: "oversizedBaggage",
                                          m: isMobile(),
                                      })}
                                  >
                                      ${formattedLowestNextPrice(unformattedLowestNextPrice(true))}
                                  </span>
                              `
                            : ""}
                    </div>
                    <i class=${iconClassMap}></i>
                </div>
            </div>
        `;
    };

    const accordionHeaderTemplate = () => {
        const tempClassMap = classNames("b2-oversize-open-top", {
            bordered: oversizedBag.sectionUi.uiState.perJourneyPerPaxState === "open",
        });

        return html`
            <div class=${tempClassMap}>
                <div class="b2-oversize-info push-down">
                    ${i18next.t(
                        "Artículo deportivo o instrumento musical con un peso máximo de 23 KG y medidas entre 158 y 230 cm lineales.",
                    )}
                </div>
                <div class="b2-oversize-info">${i18next.t("*Máximo 3 equipajes sobredimensionados por persona.")}</div>
                ${perBookingOversized.htmlTemplate()}
            </div>
        `;
    };

    const accordionTemplate = () =>
        isOpen
            ? html`
                  <div class="b2-oversized-grid" data-test-id=${T.BAGGAGE.OVERSIZED_ACCORDION}>
                      ${accordionHeaderTemplate()}
                      <div class="ac-per-journey-per-pax">${perJourneyPerPaxTable.htmlTemplate()}</div>
                  </div>
                  <div
                      class="b2m-oversized-bag-container hidden-sm-up"
                      data-test-id=${getTestId(T.BAGGAGE.OVERSIZED_ACCORDION, { m: true })}
                  >
                      ${mobilePerBookingOversized.htmlTemplate()} ${mobilePerJourneyPerPaxTable.htmlTemplate()}
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        <div data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION, { c: "oversizedBaggage" })}>
            <header class="b2-oversized-header ${props.classToScrollToOnClose}">
                ${headerTemplate()} ${accordionTemplate()}
            </header>
        </div>
    `;

    return {
        showOversizedErrors,
        htmlTemplate,
        setResetInsuranceOnChange: oversizedBag.setResetInsuranceOnChange,
    };
};
