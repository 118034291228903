import i18next from "i18next";
import { html } from "haunted";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { ROUTES } from "../../shared/apiRoutes";
import { ApiLatestPaymentStatusResponse } from "../../component-models/itinerary/LatestPaymentStatusResponse";
import { useDeclinedPaymentModal } from "../itinerary/itinerary-modals/useDeclinedPaymentModal";

export interface Props {
    isActive: boolean;
    isDeclined: boolean;
    isCugOnHold: boolean;
    isFlightless: boolean;
    paymentDescription: string;
    retrieveUrl: string;
}

export const usePendingPaymentBanner = (props: Props) => {
    const [isPending, setIsPending] = useState<boolean>(false);

    const declinedModal = useDeclinedPaymentModal({ isFlightless: props.isFlightless });

    //HELPERS:

    const init = () => {
        if (props.isActive) {
            setIsPending(!props.isDeclined);
            void checkIsPending(false);
        }

        if (props.isDeclined) declinedModal.open();
    };

    const timeout = (ms: number) => new Promise((resolve) => window.setTimeout(resolve, ms));

    const handleManualRefreshClick = async () => {
        await checkIsPending(true);
    };

    const checkIsPending = async (isManual: boolean) => {
        const response = await fetch(
            `${ROUTES.ApiRoutes.BookingIsLatestPaymentPending}${isManual ? "?manual=true" : ""}`,
        );

        if (response.ok) {
            const data = (await response.json()) as ApiLatestPaymentStatusResponse;

            if (data.IsPending) {
                setIsPending(true);
                await init();
            } else {
                window.location.href = props.retrieveUrl;
            }
            return;
        }

        if (response.status === 429) {
            const retryAfter = Number(response.headers.get("Retry-After"));
            await timeout(retryAfter * 1000);
            await init();
            return;
        }
    };

    useEffect(init, [props.isActive]);

    // TEMPLATES:

    const paymentDescriptionTemplate = () => (isPending ? html` <span>${props.paymentDescription}</span> ` : html``);

    const pendingTemplate = () => (isPending ? html` <div>${i18next.t("Payment-PendingMessage")}</div> ` : html``);

    const manualRefreshButtonTemplate = () =>
        isPending && !props.isCugOnHold
            ? html`
                  <div class="flex items-center justify-center">
                      <button class="rounded-primary-btn" @click=${handleManualRefreshClick}>
                          ${i18next.t("Payment-PendingManualUpdate")}
                      </button>
                  </div>
              `
            : html``;

    const htmlTemplate = () =>
        props.isActive && !props.isDeclined
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="full-width-information">
                              <i class="fas fa-exclamation-circle notification-icon custom-alert"></i>
                              ${paymentDescriptionTemplate()}&nbsp;${pendingTemplate()}
                          </div>
                          <div>${manualRefreshButtonTemplate()}</div>
                      </div>
                  </div>
                  ${declinedModal.htmlTemplate()}
              `
            : declinedModal.htmlTemplate();

    return { htmlTemplate };
};
