import { SeatmapContext } from "../../component-models/seatmap/SeatmapContext";
import { ApiSeatmapPassenger } from "../../component-models/seatmap/ApiSeatmapPassenger";
import { OUTBOUND } from "../../shared/commonConstants";
import { SeatmapJourneySegment } from "../../component-models/seatmap/SeatmapJourneySegment";
import { ExtendedApiSeat } from "../../component-models/seatmap/ApiSeat";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { SeatmapState } from "../../component-models/seatmap/SeatmapState";

export interface Props {
    context: SeatmapContext;
    currentSeats: ExtendedApiSeat[];
    seatmapState: SeatmapState;
}

export const useSeatPassengerHelper = (props: Props) => {
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const isSeatDisabled = () =>
        bookingContext.isCheckinClosedOutbound && props.seatmapState?.CurrentJourney?.JourneyIndex === OUTBOUND;

    const isSeatSelected = (apiPassenger: ApiSeatmapPassenger) =>
        props.seatmapState?.CurrentPassenger?.PassengerNumber === apiPassenger.PassengerNumber;

    const canShowSeatDeleteButton = (apiPassenger: ApiSeatmapPassenger) =>
        getSeatNumberForPaxForSegment(apiPassenger) &&
        (showDeleteButtonInBookingFlow() ||
            showDeleteButtonInChangeFlow() ||
            showDeleteButtonInCheckinFlow(apiPassenger));

    const getSeatNumberForPaxForSegment = (apiPassenger: ApiSeatmapPassenger, segment?: SeatmapJourneySegment) => {
        const segmentIndex = segment ? segment.SegmentIndex : props.seatmapState?.CurrentSegment?.SegmentIndex;

        if (segmentIndex === undefined) return "";

        const seat = props.currentSeats.find(
            (s) =>
                s.PassengerNumber === apiPassenger.PassengerNumber &&
                s.JourneyIndex === props.seatmapState.CurrentJourney.JourneyIndex &&
                s.SegmentIndex === segmentIndex,
        );

        return seat ? `${seat.Row}${seat.Column}` : "";
    };

    const showDeleteButtonInBookingFlow = () =>
        !flowContext.isChangeFlow && !flowContext.isCheckinFlow && !props.context.Model.IsReadOnly;

    const showDeleteButtonInChangeFlow = () =>
        flowContext.isChangeFlow &&
        props.currentSeats.some(
            (s) =>
                s.PassengerNumber === props.seatmapState.CurrentPassenger.PassengerNumber &&
                s.JourneyIndex === props.seatmapState.CurrentJourney.JourneyIndex &&
                s.SegmentIndex === props.seatmapState.CurrentSegment.SegmentIndex &&
                s.OriginalPassengerNumber !== props.seatmapState.CurrentPassenger.PassengerNumber,
        );

    const showDeleteButtonInCheckinFlow = (apiPassenger: ApiSeatmapPassenger) =>
        props.context.CheckinFlowType === "notAllSeated" && noOriginalSeatForDisplayedSegment(apiPassenger);

    const noOriginalSeatForDisplayedSegment = (apiPassenger: ApiSeatmapPassenger) =>
        !props.currentSeats.find(
            (s) =>
                s.OriginalPassengerNumber === apiPassenger.PassengerNumber &&
                s.JourneyIndex === props.seatmapState.CurrentJourney.JourneyIndex &&
                s.SegmentIndex === props.seatmapState.CurrentSegment.SegmentIndex,
        );

    return {
        canShowSeatDeleteButton,
        getSeatNumberForPaxForSegment,
        isSeatDisabled,
        isSeatSelected,
    };
};
