import { html } from "lit-html";
import i18next from "i18next";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { useEffect as hauntedUseEffect } from "haunted";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { COOKIE_NAMES, USER_ROLE_CODE } from "../../shared/commonConstants";
import { deleteCookie } from "../../shared/cookieHandling";
import { TestIdDictionary as T, getTestId } from "../../testing-helpers/TestIdHelper";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useBookingContext } from "../../managers/useBookingContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useState } from "../../shared/haunted/CustomHooks";

export const name = "ac-peru-compra-login-info-modal";

export const observedAttributes: (keyof Attributes)[] = ["role"];

export interface Attributes {
    role: string;
}

export interface Props {
    role: string;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        role: host.role,
    };

    const bookingContext = useBookingContext();

    const { triggers } = usePubSub();

    const [userContext] = useReduxState("userContext");

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const init = () => {
        const handler = triggers.peruCompra.openLoginInfoForPCRA.subscribe(() => {
            if (bookingContext.isPeruCompraBooking || userContext.peruCompra.role !== "none") setIsOpen(true);
        });

        return () => handler.unsubscribe();
    };

    const getUserTitle = () => (props.role === USER_ROLE_CODE.PCRA_GESTOR ? "Gestor" : "Delegado");

    //HANDLERS:
    const handleAcceptButton = () => {
        deleteCookie(COOKIE_NAMES.PeruCompraLogin);
        setIsOpen(false);
    };

    hauntedUseEffect(init, [userContext?.userRole]);

    const peruCompraLoginInfoModalHeaderTemplate = () =>
        html` <div
            class="modal-header pcra-modal-header"
            data-test-id=${getTestId(T.PERU_COMPRA_MODAL.LOGIN_INFO_MODAL, { c: props.role })}
        >
            ${unsafeHTML(
                i18next.t("Sr. {{-userType}}", {
                    userType: getUserTitle(),
                }),
            )}
        </div>`;

    const loginInfoModalMessageTemplate = () =>
        props.role === USER_ROLE_CODE.PCRA_GESTOR
            ? html`
                  <p>
                      ${i18next.t(
                          "Se le recuerda que usted ha sido acreditado por el funcionario responsable del Órgano Encargado de las Contrataciones (OEC) de su Entidad, para gestionar operaciones a través del Módulo de Convenio Marco de Emisión de Boletos Electrónicos para Transporte Aéreo Nacional de Pasajeros.",
                      )}
                  </p>
                  <p>
                      ${i18next.t(
                          "Por lo tanto, todas las acciones que registre (incluyendo la que registren los delegados que haya creado) serán de absoluta responsabilidad del funcionario responsable del Órgano Encargado de las Contrataciones de su Entidad (OEC).",
                      )}
                  </p>
              `
            : html`
                  <p>
                      ${i18next.t(
                          "Se le recuerda que usted ha sido delegado por el gestor acreditado por el funcionario responsable del Órgano Encargado de las Contrataciones (OEC) de su Entidad, para gestionar operaciones a través del Módulo de Convenio Marco de Emisión de Boletos Electrónicos para Transporte Aéreo Nacional de Pasajeros.",
                      )}
                  </p>
                  <p>
                      ${i18next.t(
                          "Por lo tanto, todas las acciones que registre serán de absoluta responsabilidad del funcionario responsable del Órgano Encargado de las Contrataciones de su Entidad (OEC).",
                      )}
                  </p>
              `;

    const peruCompraLoginInfoModalContentTemplate = () => html`
        <div class="modal-body">
            <div class="pcra-login-info-content">${loginInfoModalMessageTemplate()}</div>
        </div>
        <div class="pcra-modal-footer">
            <button
                class="rounded-primary-btn"
                data-test-id=${T.PERU_COMPRA_MODAL.LOGIN_INFO_MODAL_ACCEPT_BUTTON}
                @click=${handleAcceptButton}
            >
                ${i18next.t("Aceptar")}
            </button>
        </div>
    `;

    return html` <ac-cug-modal
        .canBeClosed=${false}
        .header=${peruCompraLoginInfoModalHeaderTemplate()}
        .content=${peruCompraLoginInfoModalContentTemplate()}
        .isOpen=${isOpen}
        .customClass=${"pcra-login-info-modal"}
    ></ac-cug-modal>`;
};
