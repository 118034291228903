import { html } from "haunted";
import { ApiPortalSpaViewModel } from "../../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import { useOrganizationInfoBar } from "./useOrganizationInfoBar";
import { useSummaryWidgets } from "./useSummaryWidgets";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import { useOrganizationUsersBox } from "./useOrganizationUsersBox";
import { useOrganizationConfirmedBookingsBox } from "./useOrganizationConfirmedBookingsBox";

export interface Props {
    antiForgeryToken: string;
    model: ApiPortalSpaViewModel;
}

export const usePeruCompraSummaryPage = (props: Props) => {
    const cug2AppContext = useCug2AppContext();

    const organizationInfo = useOrganizationInfoBar({
        model: props.model,
        canTopUp: false,
        canEditCompany: false,
    });

    const organizationUsers = useOrganizationUsersBox({
        model: props.model,
    });

    const organizationConfirmedBookings = useOrganizationConfirmedBookingsBox({
        model: props.model,
    });

    const widgets = useSummaryWidgets({
        cugType: cug2AppContext.Cug2BSettings.PeruCompraSummaryWidgets,
    });

    // HELPERS

    // COMPONENT

    // TEMPLATES

    const dataBarTemplate = () => html`
        <div class="cug2b-summary-data-bar">
            ${organizationUsers.htmlTemplate()} ${organizationConfirmedBookings.htmlTemplate()}
        </div>
    `;

    const htmlTemplate = () => html`
        ${organizationInfo.htmlTemplate()} ${dataBarTemplate()} ${widgets.htmlTemplate()}
    `;

    return { htmlTemplate };
};
