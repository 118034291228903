import i18next from "i18next";
import { useState } from "../../shared/haunted/CustomHooks";
import { html } from "haunted";
import { classMap } from "lit-html/directives/class-map";
import { isMobile } from "../../shared/common";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import {
    voucherHeaderTemplate,
    voucherMobileHeaderTemplate,
    voucherTooltipTemplate,
} from "../../common-templates/PaymentCommonTemplates";
import { useAppContext } from "../../managers/useAppContext";

export interface Props {
    isButtonDisabled: boolean;
    model: PaymentPageViewModel;
    handleKeydown: (e: KeyboardEvent) => Promise<boolean>;
    handleVoucherClick: (code: string) => void;
    setShowGiftcardSelector: (newState: boolean) => void;
}

export const useVoucherForMembers = (props: Props) => {
    const appContext = useAppContext();

    const [code, setCode] = useState<string>("");

    const toggleToNewVoucherType = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.setShowGiftcardSelector(true);
    };

    const linkToGiftcardSelectorTemplate = () =>
        props.model.VoucherAndGiftcardViewModel.UserHasGiftcards
            ? html`
                  <div class="new-giftcard-form-info-container">
                      <div class="new-giftcard-form-info">${i18next.t("Gift-NewGiftcardFormInfo")}</div>
                      <div @click=${toggleToNewVoucherType} class="gift-card-opener-toggle small">
                          ${i18next.t("Gift-ShowNewGiftcardForm {{-amount}}{{-plural}}", {
                              amount: props.model.VoucherAndGiftcardViewModel.NumberOfGiftcards,
                              plural: props.model.VoucherAndGiftcardViewModel.NumberOfGiftcards !== 1 ? "s" : "",
                          })}
                      </div>
                  </div>
              `
            : "";

    const giftcardTooltipTemplate = () =>
        appContext.isFeatureSwitchActive("GiftCard")
            ? html` <div class="new-giftcard-input-tooltip">${i18next.t("Gift-CardNumberTooltipNew")}</div> `
            : "";

    const formTemplate = () => html`
        <div class="mobile-gc-tooltip-container">
            <div class="row">
                <div class="col-xs-1 col-sm-3-4 col-md-4-5">${inputCodeTemplate()}</div>
                <div class="col-xs-1 col-sm-1-4 col-md-1-5 text-center-xs">${submitButtonTemplate()}</div>
            </div>
        </div>
    `;

    const inputCodeTemplate = () => html`
        <input
            name="voucherPayment.VoucherCode"
            class="new-giftcard-input"
            autocomplete="off"
            data-test-id="voucher-code-input"
            @keydown=${props.handleKeydown}
            @input=${(e: KeyboardEvent) => setCode((e.target as HTMLInputElement).value)}
        />

        ${giftcardTooltipTemplate()}
    `;

    const submitButtonTemplate = () => {
        const tempClassMap = classMap({
            "new-giftcard-btn": true,
            "disabled": props.isButtonDisabled,
        });

        return html`
            <div
                class=${tempClassMap}
                data-test-id="voucher-gift-card-button"
                @click=${() => props.handleVoucherClick(code)}
            >
                ${i18next.t("V2-Add")}
                <i class="js-circle-chevron-right js-icon"></i>
            </div>
        `;
    };

    const headerTemplate = () => html`
        <header class="new-gc-mobile">
            <span class="js-gift-card js-icon title-icon reduced"></span>
            <div class="title reduced">
                ${voucherHeaderTemplate()} ${voucherMobileHeaderTemplate()} ${voucherTooltipTemplate(true)}
            </div>

            ${linkToGiftcardSelectorTemplate()}
        </header>
    `;

    const htmlTemplate = () => html`
        <div class="giftcard-wrapper">${headerTemplate()} ${!isMobile() ? formTemplate() : ""}</div>

        ${isMobile() ? formTemplate() : ""}
    `;

    return { htmlTemplate };
};
