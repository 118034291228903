import { html } from "lit-html";
import { ApiPaymentPageViewModel } from "../../../component-models/payment/ApiPaymentPageViewModel";
import { useEffect, useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { ROUTES } from "../../../shared/apiRoutes";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { GetBuildPageResult, SpaContent } from "../../../component-models/spa/SpaContent";
import classNames from "classnames";
import { useRef, useEffect as hauntedUseEffect } from "haunted";
import i18next from "i18next";
import { paymentHelper } from "../../../component-helpers/payment/PaymentHelper";
import { mapToPaymentPageViewModel } from "../../../component-mappers/PaymentMappers";
import { PaymentMode } from "../../../component-models/payment/PaymentMode";
import { ApiVoucherResult } from "../../../component-models/payment/VoucherResult";
import { ref } from "../../../directives/ref";
import { usePaymentTealiumManager } from "../../../managers/Tealium/usePaymentTealiumManager";
import { useAppContext } from "../../../managers/useAppContext";
import { useBookingContext } from "../../../managers/useBookingContext";
import { useBookingDataManager } from "../../../managers/useBookingDataManager";
import { useFlowContext } from "../../../managers/useFlowContext";
import { usePubSub } from "../../../pub-sub-service/usePubSub";
import BookingFlowHandler from "../../../shared/BookingFlowHandler";
import { BookingSteps } from "../../../shared/BookingSteps";
import { LOADER_CLASS_NAMES } from "../../../shared/LoaderClassNames";
import { CLASS_NAMES } from "../../../shared/classNames";
import { getCoords, showLoader, hideLoader } from "../../../shared/common";
import {
    COOKIE_NAMES,
    CREDIT_SHELL_PAYMENT_FOP_CODE,
    AGENCY_PAYMENT_FOP_CODE,
    BRASILIAN_CULTURE_CODE,
    COLOMBIAN_CULTURE_CODE,
} from "../../../shared/commonConstants";
import { getCookie } from "../../../shared/cookieHandling";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../shared/useNumberFormatter";
import { useDcInconsistencyModal } from "../../discount-club-modals/useDcInconsistencyModal";
import { useGiftcardSessionErrorModal } from "../../giftcard/useGiftcardSessionErrorModal";
import { useCommissionModal } from "../../payment/useCommissionModal";
import { useCreditShell } from "../../payment/useCreditShell";
import { useCreditShellConfirmationModal } from "../../payment/useCreditShellConfirmationModal";
import { useCreditShellModal } from "../../payment/useCreditShellModal";
import { useDcBlockedForCugModal } from "../../payment/useDcBlockedForCugModal";
import { useDgCash } from "../../payment/useDgCash";
import { useFareClassFullModal } from "../../payment/useFareClassFullModal";
import { useHoldBooking } from "../../payment/useHoldBooking";
import { useInvoicing } from "../../payment/useInvoicing";
import { usePayer } from "../../payment/usePayer";
import { usePaymentCancelsPromoCodeModal } from "../../payment/usePaymentCancelsPromoCodeModal";
import { usePaymentContactForm } from "../../payment/usePaymentContactForm";
import { usePaymentMethodsContainer } from "../../payment/usePaymentMethodsContainer";
import { usePaymentProcessor } from "../../payment/usePaymentProcessor";
import { usePeruCompraLowBalanceModal } from "../../payment/usePeruCompraLowBalanceModal";
import { useTermsAcceptance } from "../../payment/useTermsAcceptance";
import { useVoucher } from "../../payment/useVoucher";
import { useWompiInitializer } from "../../payment/useWompiInitializer";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { useAmericanBookingCancellationModal } from "../../payment/useAmericanBookingCancellationModal";
import { useAmericanMilesRedemption } from "../../payment/useAmericanMilesRedemption";
import { ApiNewPassengersModel } from "../../../component-models/passengers/ApiNewPassengersModel";
import { useRunOnce } from "../../useRunOnce";
import { useChileCompraBalance } from "../../payment/useChileCompraBalance";
import { useNotEnoughChileCompraFundsModal } from "../../payment/useNotEnoughChileCompraFundsModal";
import { unsafeHTML } from "lit-html/directives/unsafe-html";

export const usePaymentPage = (): SpaContent => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const bookingDataManager = useBookingDataManager();
    const tealiumManager = usePaymentTealiumManager();

    const { triggers } = usePubSub();
    const { ajaxJsonRequest, checkPromoCodeAvailable, removeAjaxErrorMessage } = useAjax();
    const { hasXmlPaymentMethods, canOnlyHold } = paymentHelper();
    const { formatNumber } = useNumberFormatter();

    const runOnce = useRunOnce();

    const [antiForgeryToken] = useReduxState("antiForgeryToken");
    const [cardData] = useReduxState("payment.cardData");
    const [currency] = useReduxState("booking.currency");
    const [selectedMethod] = useReduxState("payment.paymentMethod");
    const [total] = useReduxState("booking.total");
    const [userContext] = useReduxState("userContext");
    const [_, setHasUnpaidInsurance] = useReduxState("booking.hasUnpaidInsurance");
    const [currentSpaSection] = useReduxState("spa.activeSection");
    const [isSpaLoading] = useReduxState("spa.isLoading");

    const root = useRef<HTMLDivElement>(undefined);
    const submitButton = useRef<HTMLButtonElement>(undefined);

    const [model, setModel] = useState<ApiPaymentPageViewModel>(undefined);

    const vm = useMemo(() => (model ? mapToPaymentPageViewModel(model, appContext.Culture) : undefined), [model]);

    const [isPaymentButtonDisabled, setIsPaymentButtonDisabled] = useState<boolean>(false);
    const [forcePreventTermsValidation, setForcePreventTermsValidation] = useState<boolean>(false);
    const [paymentMode, setPaymentMode] = useState<PaymentMode>(undefined);
    const [isValidated, setIsValidated] = useState<boolean>(false);
    const [isAntifraudRestrictionOn, setIsAntifraudRestrictionOn] = useState<boolean>(
        getCookie(COOKIE_NAMES.AntifraudFailed) === flowContext.bsid,
    );
    const [isMilesRedemtionStepSubmitted, setIsMilesRedemtionStepSubmitted] = useState<boolean>(false);
    const [passengers, setPassengers] = useState<ApiNewPassengersModel>(undefined);

    const dcInconsistencyModal = useDcInconsistencyModal();

    const paymentContactForm = usePaymentContactForm({
        antiForgeryToken,
        firstPassenger: passengers?.Passengers?.at(0),
        model: vm,
    });

    const payer = usePayer({ model: vm });

    const invoiceForm = useInvoicing({
        contactEmail: paymentContactForm?.vm?.email || "",
        isValidated,
        model: vm,
        payer,
        paymentMode,
    });

    const americanMilesRedemption = useAmericanMilesRedemption({
        isMilesRedemtionStepSubmitted,
        setIsMilesRedemtionStepSubmitted,
        openRedemtionCancellationModal: () => americanBookingCancellationModal.open(),
    });

    const methodsContainer = usePaymentMethodsContainer({
        isValidated,
        model: vm,
        isAntifraudRestrictionOn,
    });

    const wompiInitializer = useWompiInitializer();

    const termsAcceptance = useTermsAcceptance({
        anyPaxWithAcaa: passengers?.Passengers?.some((passenger) =>
            passenger.TravelDocuments.some((doc) => doc.DocumentType === "OAFF"),
        ),
        model: vm,
        forcePreventTermsValidation,
        isValidated,
        wompiTermsUrl: wompiInitializer.wompiTermsUrl,
    });

    const voucher = useVoucher({
        model: vm,
        paymentMode,
        getVoucherInfo: (result: ApiVoucherResult) =>
            paymentProcessor.handlePaymentIntent({ type: "voucherGetInfo", voucherResult: result }),
        payWithGiftcardThatCoversBalance: () => payWithGiftcardThatCoversBalance(),
        payWithVoucherLessThanBalance: (voucherResult: ApiVoucherResult) =>
            paymentProcessor.handlePaymentIntent({ type: "voucherPay", voucherResult }),
        payWithVoucherThatCoversBalance: () => payWithVoucherThatCoversBalance(),
    });

    const paymentProcessor = usePaymentProcessor({
        antiForgeryToken,
        antifraudIdNumber: cardData?.AntifraudIdNumber || "",
        antifraudIdType: cardData?.AntifraudIdType || undefined,
        isInvoiceFormShown: invoiceForm.isInvoiceFormShown,
        isTermsAcceptanceValid: termsAcceptance.isValid,
        model,
        payer,
        paymentMode,
        selectedAgencyPaymentAmount: methodsContainer.selectedAgencyPaymentAmount,
        vm,
        openCreditShellModal: () => creditShellModal.open(),
        openFareClassFullModal: () => fareClassFullModal.open(),
        openGiftcardSessionErrorModal: () => giftcardSessionErrorModal.open(),
        openPromoCodeBlockModal: () => blockPromoCodeModal.open(),
        removeErrorMessages: () => removeAjaxErrorMessage({ container: root.current }),
        scrollToFirstError: () => scrollToFirstError(),
        scrollToSubmitButton: () => scrollToSubmitButton(),
        setContactFormValidated: () => paymentContactForm.setIsContactFormValidated(true),
        setForcePreventTermsValidation,
        setPageValidated: () => setIsValidated(true),
        setPaymentMode,
        startLoad: () => startLoad(),
        stopLoad: (loader: JsLoader) => stopLoad(loader),
        submitInvoiceForm: () => invoiceForm.submitForm(),
        submitPaymentContactForm: () => paymentContactForm.submitForm(),
        setAntifraudRestrictionOn: () => setIsAntifraudRestrictionOn(true),
        validateInvoiceForm: () => invoiceForm.validateForm(),
        validateMethodsContainer: () => methodsContainer.validate(),
        validatePaymentContactForm: () => paymentContactForm.validateForm(),
    });

    const creditShell = useCreditShell({
        model: vm,
        paymentMode,
        handleCat1234PaymentIntent: () => paymentProcessor.handlePaymentIntent({ type: "cat1234CreditShellGetInfo" }),
        handleCat56PaymentIntent: () => paymentProcessor.handlePaymentIntent({ type: "cat56CreditShellGetInfo" }),
    });

    const holdBooking = useHoldBooking({
        model: vm,
        paymentMode,
        handleHoldBookingToggle: (e) => handleHoldBookingToggle(e),
    });

    const fareClassFullModal = useFareClassFullModal();

    const commissionModal = useCommissionModal({
        onSubmit: async () => {
            commissionModal.hide();
            startLoad();
            await paymentProcessor.handlePaymentIntent({ type: "afterCommission" });
            window.location.reload();
        },
    });

    const blockDcModal = useDcBlockedForCugModal();

    const blockPromoCodeModal = usePaymentCancelsPromoCodeModal({
        body: i18next.t(
            "It's not possible to use your promo code and the Banco Estado benefit in the same booking. Please select the option you want to use:",
        ),
        promoCodeBtnText: i18next.t("Promotional Code"),
        nonPromoCodeBtnText: i18next.t("Banco Estado Benefit"),
        nonPromoCodeCallback: async () => {
            const loader = startLoad();
            await checkPromoCodeAvailable(CREDIT_SHELL_PAYMENT_FOP_CODE, true, true);
            stopLoad(loader);
        },
    });

    const giftcardSessionErrorModal = useGiftcardSessionErrorModal();

    const creditShellConfirmModal = useCreditShellConfirmationModal({
        privacyUrl: model?.PrivacyUrl,
        termsUrl: model?.TermsUrl,
    });

    const creditShellModal = useCreditShellModal({
        bancoEstadoTermsUrl: model?.BancoEstadoTermsUrl,
        formattedAmount: model
            ? formatNumber({
                  amount: model.CreditShellViewModel.Cat12347CreditShellAvailableAmount,
              })
            : "",
        onYes: () => paymentProcessor.handlePaymentIntent({ type: "cat1234CreditShellPay" }),
    });

    const peruCompraLowBalanceModal = usePeruCompraLowBalanceModal();

    const notEnoughChileCompraFundsModal = useNotEnoughChileCompraFundsModal();

    const isPeruCompra = () => userContext.peruCompra.role !== "none" || bookingContext.isPeruCompraBooking;

    const americanBookingCancellationModal = useAmericanBookingCancellationModal();

    const chileCompraBalance = useChileCompraBalance();

    const isChileCompra = useMemo(
        () => userContext.chileCompra.role !== "none" || bookingContext.isChileCompraBooking,
        [userContext, bookingContext],
    );

    const notEnoughChileCompraFunds = useMemo(
        () => userContext.chileCompra.role !== "none" && userContext.chileCompra.availableAmount < Number(total),
        [userContext?.userRole, total],
    );

    // HELPERS

    const init = () => {
        if (!vm || !model || paymentMode || !userContext?.userRole) return undefined;

        const doIt = async () => {
            if (bookingContext.promoCode) await checkPromoCodeAvailable("", true, false, true);

            await bookingDataManager.fetchAndUpdate(ajaxJsonRequest);
        };

        void doIt();

        setPaymentMode(canOnlyHold(vm) ? "none" : "regular");

        if (isPeruCompra() && isPeruCompraBalanceTooLow()) peruCompraLowBalanceModal.open();

        if (userContext.cug.isMember && flowContext.isDcStandaloneFlow) blockDcModal.open();

        BookingFlowHandler.storeCurrentStep(BookingSteps.Payment);

        if (model.CreditShellViewModel.IsBookingCoveredFully && !isUserWronglyInDcFlow()) {
            creditShellConfirmModal.open();
        }

        if (isUserWronglyInDcFlow()) dcInconsistencyModal.open();

        const handler1 = triggers.sidebar.promoCodeAccepted.subscribe(({ preventReload }) => {
            if (!preventReload) {
                startLoad();
                window.location.reload();
            }
        });

        const handler2 = triggers.payment.confirmationModalContinue.subscribe(async () => {
            await paymentProcessor.handlePaymentIntent({ type: "creditShellCoversBalance" });
        });

        return () => {
            handler1.unsubscribe();
            handler2.unsubscribe();
        };
    };

    const scrollToFirstError = () => {
        // DEVNOTE Settimeout is needed because the error message is not yet visible when the validation is triggered
        window.setTimeout(() => {
            const errors = Array.from(
                root.current.querySelectorAll(
                    `.${CLASS_NAMES.error}, .${CLASS_NAMES.invalid}, .${CLASS_NAMES.ErrorMessageContainer}`,
                ),
            ) as HTMLElement[];

            const firstError = errors.find((err) => err.offsetHeight > 0);

            if (firstError) {
                const topOfElement = getCoords(firstError).top - 120;
                window.scroll({ top: topOfElement, behavior: "smooth" });
            }
        }, 100);
    };

    const isUserWronglyInDcFlow = () =>
        flowContext.isDcStandaloneFlow && userContext.isLoggedIn && userContext.dc.hasMembership;

    const startLoad = () => {
        setIsPaymentButtonDisabled(true);
        return showLoader({ name: LOADER_CLASS_NAMES.Generic, container: root.current });
    };

    const stopLoad = (loader: JsLoader) => {
        setIsPaymentButtonDisabled(false);
        hideLoader(loader);
    };

    const isPeruCompraBalanceTooLow = () =>
        vm && userContext.peruCompra.availableAmount < vm.AmountPerCurrency[currency].Amount;

    const showPaymentMethodsAndBilling = () =>
        vm &&
        (hasXmlPaymentMethods(vm) || vm.AgencyViewModel.ShowAgencyPayment) &&
        paymentMode === "regular" &&
        !isPeruCompra();

    const scrollToSubmitButton = () => {
        const topOfElement = getCoords(submitButton.current)?.top - 80 || 0;
        window.scroll({ top: topOfElement, behavior: "smooth" });
    };

    const isBancoEstadoBarDisplayed = () =>
        [5, 6].includes(userContext.bancoEstado.category) && flowContext.isBookingFlow;

    const isBancoEstadoRibbonDisplayed = () =>
        !flowContext.isCheckinFlow && [1, 2, 3, 4, 7].includes(userContext.bancoEstado.category);

    const submitDgCashPayment = () =>
        flowContext.isRedemptionFlow
            ? paymentProcessor.handlePaymentIntent({ type: "redemptionTest" })
            : paymentProcessor.handlePaymentIntent({ type: "test" });

    // EVENT LISTENERS

    const payWithVoucherThatCoversBalance = () => {
        setPaymentMode("voucherCoversBalance");
        scrollToSubmitButton();
    };

    const payWithGiftcardThatCoversBalance = () => {
        setIsValidated(true);
        setPaymentMode("giftcardCoversBalance");
        scrollToSubmitButton();
    };

    const handleHoldBookingToggle = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (canOnlyHold(vm)) return;

        setPaymentMode(paymentMode === "none" ? "regular" : "none");
    };

    const openCommissionModal = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const result = await paymentProcessor.handlePaymentIntent({ type: "beforeCommission" });

        if (result) commissionModal.open();
    };

    const handlePageSubmitClick = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        await paymentProcessor.handlePageSubmitClick();
    };

    const handleMethodChange = async () => {
        if (!selectedMethod?.PaymentMethodCode) return;

        const code = selectedMethod.SubmitCardCodeInsteadOfPaymentMethodCode
            ? cardData.PaymentMethodCodeToSubmit
            : selectedMethod.PaymentMethodCode;

        const brand = selectedMethod.SubmitCardCodeInsteadOfPaymentMethodCode ? cardData.FopBrand : "n/a";

        if (code || brand) {
            void tealiumManager.handlePaymentMethodChange(code, brand);
        }
    };

    const handleHoldClick = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        await paymentProcessor.handlePaymentIntent({ type: "hold" });
    };

    // Component

    const dgCash = useDgCash({
        paymentMode,
        vm,
        onSubmit: submitDgCashPayment,
    });

    useEffect(() => setIsValidated(false), [selectedMethod?.PaymentMethodCode]);

    useEffect(() => {
        if (!flowContext.isRedemptionFlow) return;

        setIsPaymentButtonDisabled(!isMilesRedemtionStepSubmitted);
    }, [isMilesRedemtionStepSubmitted]);

    useEffect(handleMethodChange, [selectedMethod?.PaymentMethodCode, cardData?.PaymentMethodCodeToSubmit]);

    useEffect(() => {
        if (currentSpaSection === "Payment" && total && vm && userContext?.userRole && !isSpaLoading) {
            runOnce.run(() => {
                void tealiumManager.logPaymentPageLoad(vm);
            });
        }
        if (currentSpaSection !== "Payment") runOnce.reset();
    }, [total, currentSpaSection, isSpaLoading, vm, userContext?.userRole]);

    hauntedUseEffect(init, [vm, model, paymentMode, userContext?.userRole]);

    useEffect(() => {
        if (currentSpaSection === "Payment" && notEnoughChileCompraFunds) notEnoughChileCompraFundsModal.open();
    }, [notEnoughChileCompraFunds, currentSpaSection]);

    // TEMPLATES

    const farelockHeaderTemplate = () => {
        const tempClassMap = classNames("booking-wrapper payment-fare-lock", {
            "be-push-down": userContext.bancoEstado.category !== 0,
        });

        return flowContext.isFarelockRoundOne && vm
            ? html`
                  <section class=${tempClassMap}>
                      <header data-test-id="payment-farelock-message-header">
                          <i class="js-icon js-fare-lock"></i>
                          <span>${i18next.t("PaymentFareLockBannerTitle")}</span>
                          ${vm.BookingViewModel.FareLockTime === 24
                              ? html` <i class="js-icon js-fare-lock-24"></i>`
                              : html` <i class="js-icon js-fare-lock-48"></i>`}
                      </header>
                  </section>
              `
            : "";
    };

    const notFullyPaidWarningTemplate = () =>
        vm?.ShowNotFullyPaidWarning
            ? html`
                  <div class="all-flights-past error">
                      <div data-test-id=${T.PAYMENT.MUST_COMPLETE_MESSAGE}>${i18next.t("MustCompletePayment")}</div>
                  </div>
              `
            : "";

    const cugDeadEndTemplate = () =>
        !vm?.ShowNotFullyPaidWarning && vm?.AgencyViewModel.AgentCannotHoldNorPay
            ? html`
                  <div class="all-flights-past error">
                      <div>${i18next.t("CUG2-AgentCannotHoldOrPay")}</div>
                  </div>
              `
            : "";

    const contentTemplate = () => {
        const title = flowContext.isGiftcardPurchaseFlow
            ? i18next.t("Gift-PaymentTitle")
            : i18next.t("V2-PaymentTitle");

        return vm?.MethodsViewModel.AnyPaymentAvailable || vm?.AgencyViewModel.ShowHoldBooking
            ? html`
                  <header class="payment-header">
                      <div class="title" data-test-id="payment-header-title">
                          <h2 class="main-title">${title}</h2>
                      </div>
                      ${journeyEndpointsTemplate()}
                  </header>
                  <hr />
                  ${rejectedCreditShellErrorTemplate()} ${paymentContactForm.htmlTemplate()}
                  ${holdBooking.htmlTemplate()} ${paymentMethodsHeaderTemplate()} ${americanMilesRedemptionTemplate()}
                  ${voucher.htmlTemplate()} ${dgCash.htmlTemplate()} ${chileCompraBalance.htmlTemplate()}
                  ${creditShell.htmlTemplate()} ${paymentMethodsAndBillingTemplateWithCuit()}
                  ${paymentMethodsAndBillingTemplateWithoutCuit()} ${hiddenPeruCompraInvoiceTemplate()}
              `
            : "";
    };

    const journeyEndpointsTemplate = () =>
        vm && !vm?.BookingViewModel.IsFlightlessPnr
            ? html`
                  <div class="segments" data-test-id="payment-header-route">
                      <span>
                          ${vm.BookingViewModel.OutboundJourneyDeparture} -
                          ${vm.BookingViewModel.OutboundJourneyArrival}
                      </span>
                      ${vm.BookingViewModel.IsReturnJourney
                          ? html`
                                <span>
                                    ${vm.BookingViewModel.InboundJourneyDeparture} -
                                    ${vm.BookingViewModel.InboundJourneyArrival}
                                </span>
                            `
                          : ""}
                  </div>
              `
            : "";

    const rejectedCreditShellErrorTemplate = () =>
        vm?.CreditUsedButLastPaymentFailed
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="full-width-information credit-shell">
                              <i class="fas fa-exclamation-circle notification-icon custom-alert"></i>
                              <div>${i18next.t("RejectedPaymentCreditShellInfo")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const paymentMethodsHeaderTemplate = () => {
        const title = isChileCompra
            ? i18next.t("Método de pago")
            : flowContext.isRedemptionFlow
              ? i18next.t("Pay for your flight")
              : i18next.t("Payment-ChoosePaymentMethodTitle");

        return hasXmlPaymentMethods(vm) && paymentMode !== "none" && !isPeruCompra()
            ? html`
                  <header class="payment-header-pushdown">
                      <span class="js-circle-payment js-icon title-icon"></span>
                      <div class="title" data-test-id="payment-method-container-title">
                          <h2 class="main-title">${title}</h2>
                      </div>
                  </header>
              `
            : "";
    };

    const americanMilesRedemptionTemplate = () =>
        flowContext.isRedemptionFlow
            ? html` <section class="inner-deep-box">
                  ${americanMilesRedemption.htmlTemplate()} ${americanInlinePaymentMethodsTemplate()}
              </section>`
            : "";

    const americanInlinePaymentMethodsTemplate = () =>
        flowContext.isRedemptionFlow && isMilesRedemtionStepSubmitted
            ? html`<div
                  class="relative mt-5 flex h-full w-full flex-col rounded-lg border border-solid border-be-gray-16 p-5"
              >
                  <div
                      class=${classNames(
                          "absolute right-[6px] top-[6px] flex h-[30px] w-[30px] items-center justify-center rounded-full bg-be-gray-16",
                          { "bg-white": true },
                      )}
                  >
                      <i
                          class=${classNames("js-icon js-flight-tick text-3xl text-new-ui-blue", {
                              hidden: false,
                          })}
                      ></i>
                  </div>
                  <div class="mb-1 text-lg/[20px] font-bold text-brand-secondary sm:text-xl">
                      <span class="mr-2 font-extrabold">${i18next.t("Step")} 2:</span>${i18next.t(
                          "Now you must select your payment method to pay your fees and services",
                      )}
                  </div>
                  <div class="mb-5 text-sm/[18px] text-brand-secondary sm:text-base/[20px]">
                      ${unsafeHTML(
                          i18next.t("Miles-Redemption-Note2 {{-reg}}", {
                              reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
                          }),
                      )}
                  </div>
                  ${methodsContainer.htmlTemplate()} ${invoiceForm.htmlTemplate()}
              </div>`
            : "";

    const paymentMethodsAndBillingTemplateWithCuit = () =>
        showPaymentMethodsAndBilling() && vm?.BillingViewModel.IsCuitFieldNeeded && !flowContext.isRedemptionFlow
            ? html`
                  <section class="inner-deep-box">${methodsContainer.htmlTemplate()}</section>
                  ${invoiceForm.htmlTemplate()}
              `
            : "";

    const paymentMethodsAndBillingTemplateWithoutCuit = () =>
        showPaymentMethodsAndBilling() && !vm?.BillingViewModel.IsCuitFieldNeeded && !flowContext.isRedemptionFlow
            ? html`
                  <section class="inner-deep-box">
                      ${methodsContainer.htmlTemplate()} ${invoiceForm.htmlTemplate()}
                  </section>
              `
            : "";

    const hiddenPeruCompraInvoiceTemplate = () =>
        isPeruCompra() ? html`${methodsContainer.htmlTemplate()} ${invoiceForm.htmlTemplate()}` : "";

    const noPaymentAvailableTemplate = () =>
        vm && !vm.MethodsViewModel.AnyPaymentAvailable && !vm.AgencyViewModel.ShowHoldBooking
            ? html`
                  <div class="all-flights-past error">
                      <div>${i18next.t("CUG2-NoPaymentMethodsAvailable")}</div>
                  </div>
              `
            : "";

    const submissionErrorTemplate = () =>
        paymentProcessor.errorMessage
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${paymentProcessor.errorMessage}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const agencyPaymentWarningTemplate = () =>
        vm?.ShowAgencyPaymentWarning && selectedMethod?.PaymentMethodCode === AGENCY_PAYMENT_FOP_CODE
            ? isPeruCompra() || isChileCompra
                ? html`<div class="payment-message text-right">
                      ${i18next.t("PeruCompraAndChileCompraAgencyPaymentWarning")}
                  </div>`
                : html`<div class="payment-message text-right">${i18next.t("AgencyPaymentWarning")}</div>`
            : "";

    const maxPaymentExceededTemplate = () =>
        vm?.IsPaymentAmountOverMaximum
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container elevated-error text-right">
                              <div class="form-error-message">
                                  ${i18next.t("Payment-OverpayWarning {{-amount}}", {
                                      amount: vm.MaxPaymentAmountFormatted,
                                  })}
                              </div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const regularPaymentFooterTemplate = () => {
        const tempClassMap = classNames("payment-footer-grid", {
            "with-fee": vm?.AgencyViewModel.CanAddCommission,
            "without-teen": vm?.BookingViewModel.IsFlightlessPnr,
        });

        return paymentMode !== "none"
            ? html`
                  <div class=${tempClassMap}>
                      ${termsAcceptance.htmlTemplate()} ${commissionButtonTemplate()}

                      <div class="payment-footer-grid-button-2">${regularPaymentButtonTemplate()}</div>
                  </div>
              `
            : "";
    };

    const commissionButtonTemplate = () => {
        const dataTestId = isPaymentButtonDisabled ? "payment-commission-button-faded" : "payment-commission-button";

        return vm?.AgencyViewModel.CanAddCommission && paymentMode === "regular"
            ? html`
                  <div class="payment-footer-grid-button-1">
                      <button
                          class=${classNames("rounded-secondary-btn", { faded: isPaymentButtonDisabled })}
                          data-test-id=${dataTestId}
                          @click=${openCommissionModal}
                      >
                          ${i18next.t("V2-AddCommission")}
                      </button>
                  </div>
              `
            : "";
    };

    const regularPaymentButtonTemplate = () => {
        const label = isPeruCompra() || isChileCompra ? i18next.t("Emitir Reserva") : i18next.t("V2-PayBtnLabel");
        const dataTestId = isPaymentButtonDisabled ? "submit-payment-button-faded" : "payment-submit-payment-button";

        const tempClassMap = classNames("rounded-primary-btn locked-btn", {
            faded: isPaymentButtonDisabled,
            disabled:
                vm?.AgencyViewModel.AgentCannotHoldNorPay ||
                vm?.IsPaymentAmountOverMaximum ||
                notEnoughChileCompraFunds,
        });

        return paymentMode !== "none"
            ? html`
                  <button
                      ref=${ref(submitButton)}
                      class=${tempClassMap}
                      data-test-id=${dataTestId}
                      @click=${handlePageSubmitClick}
                  >
                      ${label}
                  </button>
              `
            : "";
    };

    const heldBookingButtonTemplate = () => {
        const tempClassMap = classNames("rounded-primary-btn locked-btn", {
            faded: isPaymentButtonDisabled,
            disabled: vm?.AgencyViewModel.AgentCannotHoldNorPay || vm?.IsPaymentAmountOverMaximum,
        });

        const dataTestId = isPaymentButtonDisabled ? "handle-put-on-hold-button-faded" : "handle-put-on-hold-button";

        return paymentMode === "none"
            ? html`
                  <div class="payment-button-container">
                      <button class=${tempClassMap} data-test-id=${dataTestId} @click=${handleHoldClick}>
                          ${i18next.t("V2-Continue")}
                      </button>
                  </div>
              `
            : "";
    };

    const footerTemplate = () =>
        vm?.MethodsViewModel.AnyPaymentAvailable || vm?.AgencyViewModel.ShowHoldBooking
            ? html`
                  ${agencyPaymentWarningTemplate()} ${maxPaymentExceededTemplate()} ${regularPaymentFooterTemplate()}
                  ${heldBookingButtonTemplate()}
              `
            : "";

    const withdrawalRightsMessageTemplate = () =>
        appContext.Culture !== BRASILIAN_CULTURE_CODE
            ? html`
                  <div class="payment-message" data-test-id="payment-footer-message">
                      ${appContext.Culture === COLOMBIAN_CULTURE_CODE
                          ? i18next.t("Payment-ColumbianFooter")
                          : i18next.t("Payment-WithdrawalMessage")}
                  </div>
              `
            : "";

    // EXPORTS

    const build = async (): Promise<GetBuildPageResult> => {
        try {
            const result = await ajaxJsonRequest<ApiPaymentPageViewModel>({
                method: "GET",
                url: ROUTES.ApiRoutes.Payment,
            });

            if (result.redirectionUrl) return { type: "redirect", redirectionUrl: result.redirectionUrl };

            setModel(result.data);
            setHasUnpaidInsurance(result.data.BookingViewModel.HasNonPaidInsurance);

            const passengersResult = await ajaxJsonRequest<ApiNewPassengersModel>({
                url: ROUTES.ApiRoutes.GetAllPassengers,
                method: "GET",
            });

            setPassengers(passengersResult.data);

            return { type: "success" };
        } catch (error) {
            return { type: "error" };
        }
    };

    const htmlTemplate = () => {
        const mainClassMap = classNames({
            "be-bar-push-down": isBancoEstadoBarDisplayed(),
            "content-push-down": isBancoEstadoRibbonDisplayed(),
        });

        return html`
            <div id="mainContentPayment" data-test-id="payment-page" ref=${ref(root)}>
                ${farelockHeaderTemplate()}
                <div id="mainContentWrapper" class=${mainClassMap}>
                    <section class="booking-wrapper ${LOADER_CLASS_NAMES.Generic}">
                        <div class="inner-box">
                            ${notFullyPaidWarningTemplate()} ${cugDeadEndTemplate()} ${contentTemplate()}
                            ${noPaymentAvailableTemplate()} ${submissionErrorTemplate()}
                        </div>
                    </section>
                    ${footerTemplate()}
                </div>
            </div>
            ${withdrawalRightsMessageTemplate()} ${commissionModal.htmlTemplate()}
            ${dcInconsistencyModal.htmlTemplate()} ${blockDcModal.htmlTemplate()} ${blockPromoCodeModal.htmlTemplate()}
            ${fareClassFullModal.htmlTemplate()} ${giftcardSessionErrorModal.htmlTemplate()}
            ${creditShellConfirmModal.htmlTemplate()} ${creditShellModal.htmlTemplate()}
            ${peruCompraLowBalanceModal.htmlTemplate()} ${americanBookingCancellationModal.htmlTemplate()}
            ${notEnoughChileCompraFundsModal.htmlTemplate()}
        `;
    };

    return { build, htmlTemplate };
};
