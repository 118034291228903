import { ApiBundleOffers } from "../../component-models/flight/BundleOffersV2Model";
import { CABIN_BAG_CODES, CHECKED_BAG_CODES, MAX_PAX_IN_STANDARD_DC_MEMBERSHIP } from "../../shared/commonConstants";
import { ExtendedTripModel } from "../../component-models/flight/ExtendedTripModel";
import { FlightPageViewModel } from "../../component-models/flight/FlightPageViewModel";
import { UserContext } from "../../component-models/app/UserContext";

export class BundleHelper {
    public static canGetDcBundles(data: UserContext, viewModel: FlightPageViewModel) {
        return (
            data.chileCompra.role === "none" &&
            (data.bancoEstado.category === 6 ||
                data.dc.hasGroupMembership ||
                (data.dc.hasStandardMembership &&
                    viewModel.FlightDataViewModel.NumberOfPax <= MAX_PAX_IN_STANDARD_DC_MEMBERSHIP))
        );
    }

    public static shouldConcatenateBagsInBundleSsrList(bundleOffers: ApiBundleOffers[]): boolean {
        // DEVNOTE If any bundle contains only cabin OR checked bag but not the other, we should not display
        // cabin and checked bag as one item in the bundle ssr list. If all bundles contain either both types
        // or neither, we can display the two bag types on one line.
        return !bundleOffers.some((bundles) =>
            [...bundles.DCOffers, ...bundles.NormalOffers].some((bundle) => {
                const containsCabinBag = CABIN_BAG_CODES.some((code) => bundle.BundleSSRs.includes(code));
                const containsCheckedBag = CHECKED_BAG_CODES.some((code) => bundle.BundleSSRs.includes(code));
                return containsCabinBag !== containsCheckedBag;
            }),
        );
    }

    public static getAllAvailableSellKeys(journeys: ExtendedTripModel[]) {
        return journeys.reduce(
            (allKeys, trip) =>
                allKeys.concat(
                    trip.FlightDetails.reduce(
                        (tripKeys, detail) =>
                            tripKeys.concat([`${detail.FaresPerProductClass.xyz.Fare.DiscountedFare.FlightFareKey}`]),
                        [],
                    ),
                ),
            [],
        );
    }
}
