import { html } from "lit-html";
import { useMemo, useState } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { useBasicCheckbox } from "../ui/basic-checkbox/useBasicCheckbox";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../shared/useNumberFormatter";
import classNames from "classnames";
import { REDEMPTION_MILES_CODE } from "../../shared/commonConstants";
import { unsafeHTML } from "lit-html/directives/unsafe-html";

interface Props {
    isMilesRedemtionStepSubmitted: boolean;
    setIsMilesRedemtionStepSubmitted: (isSubmitted: boolean) => void;
    openRedemtionCancellationModal: () => void;
}

export const useAmericanMilesRedemption = (props: Props) => {
    const [userContext] = useReduxState("userContext");
    const { formatNumber } = useNumberFormatter();
    const [isRedemptionSelected, setIsRedemptionSelected] = useState<boolean>(false);
    const [totalMiles] = useReduxState("booking.totalMiles");

    const milesToPay = useMemo(() => Number(totalMiles) || 0, [totalMiles]);

    const handleBoxClick = (e: MouseEvent) => {
        if (!props.isMilesRedemtionStepSubmitted) {
            return;
        }

        e.preventDefault();
        e.stopPropagation();

        props.setIsMilesRedemtionStepSubmitted(false);
    };

    const handleSubmit = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.setIsMilesRedemtionStepSubmitted(true);
    };

    const redemptionLabelTemplate = (id: string) => html`
        <label for=${id} data-test-id=${"payment-select-redemption-label"}>
            <div class="ml-2 flex w-full flex-col text-brand-secondary sm:flex-row">
                <div class="flex w-full flex-col items-start justify-center sm:w-1/2">
                    <div class="text-sm/[17px]">
                        ${unsafeHTML(
                            i18next.t("Millas AAdvantage {{-reg}}", {
                                reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
                            }),
                        )}
                    </div>
                    <div class="text-base/[17px] font-bold sm:text-lg">
                        ${i18next.t("To use")}:
                        <span class="ml-1 font-extrabold"
                            >${formatNumber({
                                amount: milesToPay,
                                leadingSign: false,
                                currency: "MILES",
                            })}
                            ${i18next.t("Miles")}</span
                        >
                    </div>
                </div>
                <div class="mt-2 flex w-full items-center text-[13px] sm:mt-0 sm:w-1/2 sm:justify-end sm:text-base">
                    ${i18next.t("Available")}:
                    <span class="ml-1 font-bold"
                        >${formatNumber({
                            amount: userContext.american.miles,
                            leadingSign: false,
                            currency: REDEMPTION_MILES_CODE,
                        })}
                        ${i18next.t("Miles")}</span
                    >
                </div>
            </div>
        </label>
    `;

    const redemptionCheckbox = useBasicCheckbox({
        isChecked: isRedemptionSelected,
        customWrapperClass: "payment-terms-accept-checkbox",
        labelTemplate: redemptionLabelTemplate,
        onClick: () => setIsRedemptionSelected(!isRedemptionSelected),
    });

    const stepTickTemplate = () => html`
        <div
            class=${classNames(
                "absolute right-[6px] top-[6px] flex h-[30px] w-[30px] items-center justify-center rounded-full bg-be-gray-16",
                { "bg-white": props.isMilesRedemtionStepSubmitted },
            )}
        >
            <i
                class=${classNames("js-icon js-flight-tick text-3xl text-new-ui-blue", {
                    hidden: !props.isMilesRedemtionStepSubmitted,
                })}
            ></i>
        </div>
    `;

    const stepHeaderTemplate = () => html`
        <div class="mb-1 text-lg/[20px] font-bold text-brand-secondary sm:text-xl">
            <span class="mr-2 font-extrabold">${i18next.t("Step")} 1:</span>${unsafeHTML(
                i18next.t("You must first pay for your flight with AAdvantage miles {{-reg}}", {
                    reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
                }),
            )}
        </div>
        <div class="mb-4 text-sm/[18px] text-brand-secondary sm:text-base/[20px]">
            ${unsafeHTML(
                i18next.t("Miles-Redemption-Note {{-reg}}", {
                    reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
                }),
            )}
        </div>
    `;

    const buttonsTemplate = () =>
        html` <div class="mb-1">
                <span
                    class="text-medium/[1rem] cursor-pointer font-bold text-brand-primary underline hover:text-brand-secondary hover:no-underline sm:text-medium"
                    @click=${props.openRedemtionCancellationModal}
                    >${i18next.t("I don't want to use my miles")}
                </span>
            </div>
            <div class="mt-2 flex items-center justify-end sm:mt-0">
                <button
                    class=${classNames("rounded-primary-btn", { disabled: !isRedemptionSelected })}
                    data-test-id="payment-submit-redemption-button"
                    ?disabled=${!isRedemptionSelected}
                    @click=${handleSubmit}
                >
                    ${i18next.t("Continue")}
                </button>
            </div>`;

    const htmlTemplate = () =>
        html` <div
            class="relative flex h-full w-full flex-col rounded-lg border border-solid border-be-gray-16 p-5"
            @click=${handleBoxClick}
        >
            ${stepTickTemplate()}
            <div
                class=${classNames("h-full w-full", {
                    "pointer-events-none opacity-50": props.isMilesRedemtionStepSubmitted,
                })}
            >
                ${stepHeaderTemplate()}
                <div class="mb-1 rounded-lg border-2 border-solid border-brand-secondary bg-white p-5">
                    ${redemptionCheckbox.htmlTemplate()}
                </div>
                <div
                    class=${classNames(
                        "mb-4 w-full overflow-hidden text-start text-sm/[17px] text-brand-secondary common-transition sm:text-end sm:text-base/[20px]",
                        { "h-9 sm:h-5": isRedemptionSelected, "h-0": !isRedemptionSelected },
                    )}
                >
                    ${i18next.t("Miles-Redemption-Remainder")}
                    <span class="ml-1 font-bold"
                        >${formatNumber({
                            amount: userContext.american.miles - milesToPay,
                            leadingSign: false,
                            currency: REDEMPTION_MILES_CODE,
                        })}
                        ${i18next.t("Miles")}.</span
                    >
                </div>
                ${buttonsTemplate()}
            </div>
        </div>`;

    return { htmlTemplate };
};
