import { SpaSection } from "./../../component-models/spa/SpaSection";
import { useSessionKeepAlive } from "./use-session-keep-alive";
import { useMemo } from "./../../shared/haunted/CustomHooks";
import { html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { useSpaSection } from "./useSpaSection";
import { getAntiForgeryTokenFromHtml, getParsedProperty } from "../../shared/common";
import { useEffect } from "../../shared/haunted/CustomHooks";
import { mapSpaContainerViewModel } from "../../component-mappers/SpaMappers";
import useImagePreloader from "../../shared/customHooks/useImagePreloader/useImagePreloader";
import { useAppContext } from "../../managers/useAppContext";
import { COLOMBIA_COUNTRY_CODE, FARE_LOCK_DUMMY_PAX_FIRST_NAME, INBOUND, OUTBOUND } from "../../shared/commonConstants";
import { SpaContainerViewModel } from "../../component-models/spa/SpaContainerViewModel";
import { ApiSpaContainerViewModel } from "../../component-models/spa/ApiSpaContainerViewModel";
import { useSpaModal } from "./useSpaModal";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useSpaNavigation } from "./useSpaNavigation";
import { BookingSteps } from "../../shared/BookingSteps";
import { useBaggagePage } from "./baggage/useBaggagePage";
import { useSeatmap } from "./seatmap/useSeatmap";
import { useExtrasPage } from "./extras/useExtrasPage";
import { useModifyBookingModal } from "../useModifyBookingModal";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { useReviewPage } from "./review/useReviewPage";
import { usePaymentPage } from "./payment/usePaymentPage";
import { useBookingDataManager } from "../../managers/useBookingDataManager";
import { ROUTES } from "../../shared/apiRoutes";
import { usePassengersPage } from "./passengers/usePassengersPage";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import classNames from "classnames";
import { passengerHelpers } from "../../component-helpers/passengers/passengerHelpers";
import { useEtdChangeModal } from "./passengers/etd-during-booking-modal/useEtdChangeModal";
import { useRemoveInsuranceModal } from "../useRemoveInsuranceModal";

export const name = "ac-spa-container";

export const observedAttributes: (keyof Attributes)[] = ["model", "anti-forgery-token"];

export type SpaSectionType = "Passengers" | "Baggage" | "Seatmap" | "Extras" | "Review" | "Payment";

export interface Attributes {
    "anti-forgery-token": string;
    "model": string;
}

export interface Props {
    antiForgeryToken: string;
    model: SpaContainerViewModel;
}

export const BACKPACK_IMG = "/Images/Baggages/backpack.png";
export const BACKPACK_MOBILE_IMG = "/Images/Baggages/backpack-mobile.png";
export const PAID_CABIN_MOBILE_IMG = "/Images/Baggages/paid-cabin-mobile.png";
export const BACKPACK_AND_CABIN_BAG_IMG = "/Images/Baggages/backpack-and-cabin-bag.png";
export const WITHOUT_CHECKED_BAGGAGE_IMG = "/Images/Baggages/crossed-out-baggage.png";
export const CHECKED_BAGGAGE_IMG = "/Images/Baggages/checked-baggage.png";

export const Component: HauntedFunc<Props> = (host) => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();
    const bookingDataManager = useBookingDataManager();

    const { ajaxRequest, ajaxJsonRequest } = useAjax();
    const { triggers } = usePubSub();
    const { getGenericPaxName } = passengerHelpers();

    const [_b, setAntiForgeryToken] = useReduxState("antiForgeryToken");
    const [passengerNames, setPassengerNames] = useReduxState("booking.passengerNames");
    const [currentSpaSection] = useReduxState("spa.activeSection");
    const [userContext] = useReduxState("userContext");
    const [_, setHasUnpaidInsurance] = useReduxState("booking.hasUnpaidInsurance");

    const props: Props = {
        antiForgeryToken: host.antiForgeryToken,
        model: mapSpaContainerViewModel({
            flowContext,
            isBancoEstado56: [5, 6].includes(userContext.bancoEstado.category),
            model: getParsedProperty<ApiSpaContainerViewModel>(host.model),
        }),
    };

    const modificationModal = useModifyBookingModal();

    const hasJourneySegmentChanged = useMemo(
        () =>
            props.model?.Segments.some(
                (segment) =>
                    (!flowContext.isBookingFlow && segment.FormattedEstimatedArrivalTime) ||
                    (segment.FormattedEstimatedDepartureTime &&
                        ((!bookingContext.isCheckinClosedOutbound && segment.JourneyIndex === OUTBOUND) ||
                            (!bookingContext.isCheckinClosedInbound && segment.JourneyIndex === INBOUND))),
            ),
        [props.model.Segments],
    );

    const hasFlightColombianStation = useMemo(
        () =>
            props.model.Segments.some((segment) =>
                segment.Legs.some(
                    (leg) =>
                        leg.OriginCountry === COLOMBIA_COUNTRY_CODE || leg.DestinationCountry === COLOMBIA_COUNTRY_CODE,
                ),
            ),
        [props.model.Segments],
    );

    const removeInsuranceModal = useRemoveInsuranceModal();

    const seatmapPage = useSeatmap({
        hasFlightColombianStation,
        spaGlobalDataModel: props.model.SpaGlobalDataModel,
        onForward: () => navigation.forward(),
        triggerScroll: () => getSectionByType("Seatmap").scrollToContent(),
        spaModalStart: (cb) => spaModal.startDisplayingSpaModals(cb),
    });

    const seatmapSection = useSpaSection({
        bookingFlowStep: BookingSteps.SeatMap,
        content: seatmapPage,
        showCheckinBanner: false,
        type: "Seatmap",
        onClick: () => navigation.handleSectionClick("Seatmap"),
    });

    const reviewPage = useReviewPage({
        onForward: () => navigation.forward(),
    });

    const reviewSection = useSpaSection({
        bookingFlowStep: BookingSteps.Review,
        content: reviewPage,
        showCheckinBanner: false,
        type: "Review",
        onClick: () => navigation.handleSectionClick("Extras"),
    });

    const paymentPage = usePaymentPage();

    const paymentSection = useSpaSection({
        bookingFlowStep: BookingSteps.Payment,
        content: paymentPage,
        showCheckinBanner: false,
        type: "Payment",
        onClick: () => navigation.handleSectionClick("Payment"),
    });

    const extrasPage = useExtrasPage({
        onForward: () => navigation.forward(),
        spaModalStart: (cb) => spaModal.startDisplayingSpaModals(cb),
    });

    const extrasSection = useSpaSection({
        bookingFlowStep: BookingSteps.Extras,
        content: extrasPage,
        showCheckinBanner: false,
        type: "Extras",
        onClick: () => navigation.handleSectionClick("Extras"),
    });

    const newPassengersPage = usePassengersPage({
        hasFlightColombianStation,
        model: props.model,
        onForward: async () => navigation.forward(),
        spaModalStart: (cb) => spaModal.startDisplayingSpaModals(cb),
    });

    const newPassengerSection = useSpaSection({
        bookingFlowStep: BookingSteps.Passengers,
        content: newPassengersPage,
        showCheckinBanner: false,
        type: "Passengers",
        onClick: () => navigation.handleSectionClick("Passengers"),
    });

    const baggagePage = useBaggagePage({
        model: props.model,
        onForward: () => navigation.forward(),
        spaModalStart: (cb) => spaModal.startDisplayingSpaModals(cb),
    });

    const baggageSection = useSpaSection({
        bookingFlowStep: BookingSteps.Baggage,
        content: baggagePage,
        showCheckinBanner: true,
        type: "Baggage",
        onClick: () => navigation.handleSectionClick("Baggage"),
    });

    const sections: SpaSection[] = [
        newPassengerSection,
        baggageSection,
        seatmapSection,
        extrasSection,
        reviewSection,
        paymentSection,
    ];

    const navigation = useSpaNavigation({ modificationModal, sections });

    useSessionKeepAlive(
        props.model.SpaGlobalDataModel.ExtendedKeepAlivePeriod,
        bookingContext.adultsCount + bookingContext.childrenCount + bookingContext.infantsCount,
    );

    const spaModal = useSpaModal({
        model: props.model,
        extrasViewModel: extrasPage.extrasModel,
        setExtrasModel: (newModel) => extrasPage.setExtrasModel(newModel),
        setBaggageModel: (newModel) => baggagePage.setBaggageModel(newModel),
        setSeatmapModel: (newModel) => seatmapPage.setSeatmapModel(newModel),
    });

    const etdChangeModal = useEtdChangeModal({ flightSegments: props.model?.Segments || [] });

    const getSectionByType = (type: SpaSectionType) => sections.find((s) => s.type === type);

    const init = () => {
        // DEVNOTE We scroll on back button, not Chrome...
        if ("scrollRestoration" in history) history.scrollRestoration = "manual";

        // DEVNOTE I'm sorry. See JET-7421.
        if (bookingContext.promoCode) {
            triggers.sidebar.promoCodeEntered.publish({ code: bookingContext.promoCode, preventReload: true });
        }

        bookingDataManager.eraseBookingData();

        setHasUnpaidInsurance(props.model.ExtrasModel.HasUnpaidInsuranceFee);

        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));

        const doIt = async () => {
            const result = await ajaxRequest({ url: ROUTES.StartSpa, method: "GET" });

            if (result.redirectionUrl) window.location.href = result.redirectionUrl;
        };

        void doIt();
    };

    const showBancoEstadoRibbon = () =>
        !flowContext.isCheckinFlow && [1, 2, 3, 4, 7].includes(userContext.bancoEstado.category);

    const showBancoEstadoBar = () => [5, 6].includes(userContext.bancoEstado.category) && flowContext.isBookingFlow;

    const getTotalPaxCount = () =>
        bookingContext.adultsCount + bookingContext.childrenCount + bookingContext.infantsCount;

    const getGenericPaxNamesFromContext = () => {
        const genericPassengerNames = [
            ...[...Array(bookingContext.adultsCount + bookingContext.childrenCount)].reduce(
                (genericPaxNames, _, i) => genericPaxNames.concat(getGenericPaxName(i, false)),
                [],
            ),
            ...[...Array(bookingContext.infantsCount)].reduce(
                (genericPaxNames, _, i) =>
                    genericPaxNames.concat(
                        getGenericPaxName(bookingContext.adultsCount + bookingContext.childrenCount + i, true),
                    ),
                [],
            ),
        ];

        return genericPassengerNames;
    };

    const getPaxNamesFromModel = () =>
        props.model.SpaGlobalDataModel.PaxNames.map((name, i) =>
            name.startsWith(FARE_LOCK_DUMMY_PAX_FIRST_NAME)
                ? getGenericPaxName(i, i >= bookingContext.adultsCount + bookingContext.childrenCount)
                : name,
        );

    const mergePaxNames = (modelNames: string[], genericNames: string[]) =>
        [...Array(getTotalPaxCount())].map((_, i) => modelNames[i] || genericNames[i]);

    const initPassengerNames = () => {
        if (
            newPassengersPage.passengersModel ||
            (Array.isArray(passengerNames) && passengerNames.length === getTotalPaxCount())
        ) {
            return;
        }

        setPassengerNames(mergePaxNames(getPaxNamesFromModel(), getGenericPaxNamesFromContext()));
    };

    useEffect(async () => {
        if (currentSpaSection) await bookingDataManager.fetchAndUpdate(ajaxJsonRequest, appContext.Culture);
    }, [currentSpaSection]);

    useEffect(() => {
        if (currentSpaSection === "Baggage" && hasJourneySegmentChanged && flowContext.isBookingFlow) {
            etdChangeModal.open();
        }
    }, [currentSpaSection]);

    useEffect(initPassengerNames, [newPassengersPage.passengersModel, passengerNames]);

    useEffect(async () => {
        if (userContext?.userRole) init();
    }, [userContext?.userRole]);

    //DEVNOTE: We need to preload baggage images BEFORE arriving to baggage page
    useImagePreloader([
        BACKPACK_MOBILE_IMG,
        PAID_CABIN_MOBILE_IMG,
        BACKPACK_IMG,
        BACKPACK_AND_CABIN_BAG_IMG,
        WITHOUT_CHECKED_BAGGAGE_IMG,
        CHECKED_BAGGAGE_IMG,
    ]);

    const sectionsTemplate = () => navigation.sectionsInOrder.map((section) => section.htmlTemplate());

    return html`
        <div
            id="mainContentWrapper"
            class=${classNames("default-loader-wrapper min-h-[50vh]", {
                "content-push-down": showBancoEstadoRibbon(),
                "be-bar-push-down": showBancoEstadoBar(),
            })}
        >
            ${sectionsTemplate()}
        </div>
        ${modificationModal.htmlTemplate()} ${spaModal.htmlTemplate()} ${etdChangeModal.htmlTemplate()}
        ${removeInsuranceModal.htmlTemplate()}
    `;
};
