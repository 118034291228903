import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import { html } from "lit-html";
import { OUTBOUND } from "../../../../shared/commonConstants";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { BaggagePageState } from "../../../../component-models/baggage/BaggagePageState";
import { perJourneyPerPaxViewPaxCaption, paxLabel, paxAmount } from "../../../../component-helpers/BaggageHelper";
import { baggageEditButton } from "../common/baggageEditButton";
import { PerJourneyPerPaxPassengerDto } from "./per-journey-per-pax-passenger";
import { usePerJourneyPerPaxSoldOut } from "./usePerJourneyPerPaxSoldOut";
import { usePerJourneyPerPaxBundle } from "./usePerJourneyPerPaxBundle";
import classNames from "classnames";
import { BagType } from "../useBaggagePage";
import { BaggageSectionJourney } from "../../../../component-models/baggage/BaggageSectionJourney";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import { StaffBaggage } from "../common/useStaffBaggage";
import { BancoEstadoBaggage } from "../common/useBancoEstadoBaggage";

export interface Props {
    baggageSection: BaggageSection;
    bagType: BagType;
    bancoEstadoBaggage: BancoEstadoBaggage;
    classToScrollToOnOpen: string;
    pageState: BaggagePageState;
    staffBaggage: StaffBaggage;
}

export const usePerJourneyPerPaxTable = (props: Props) => {
    const bookingContext = useBookingContext();

    const bundle = usePerJourneyPerPaxBundle({
        baggageSection: props.baggageSection,
        bagType: props.bagType,
        isMobile: false,
    });

    const soldOut = usePerJourneyPerPaxSoldOut({
        baggageSectionJourneys: props.baggageSection.journeys,
        bagType: props.bagType,
        isMobile: false,
    });

    const editButton = baggageEditButton({
        dataTestId: "",
        isDisabled: true,
        onClick: () => null,
    });

    const isResetAvailable = useMemo(
        () =>
            !props.baggageSection.canGetFreeBancoEstadoBaggage &&
            props.baggageSection.perBooking.isResetAvailable({
                baggageSectionJourneys: props.baggageSection.journeys,
                bagType: props.bagType,
                canGetFreeBancoEstadoBaggage: props.baggageSection.canGetFreeBancoEstadoBaggage,
                isCheckinClosedInbound: bookingContext.isCheckinClosedInbound,
                isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
                paxCount: bookingContext.adultsCount + bookingContext.childrenCount,
            }),
        [props.bagType, props.baggageSection.journeys, bookingContext],
    );

    const handleViewSwitch = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // FIXME reset is async, so the order is important here
        await props.baggageSection.reset();
        props.baggageSection.sectionUi.closePerJourneyPerPaxView();
    };

    const journeyTemplate = (journey: BaggageSectionJourney) => {
        const directionText = journey.index === OUTBOUND ? i18next.t("Ida") : i18next.t("Vuelta");

        return html`
            <div class="b2-journey-heading">
                <i class="js-icon-bag js-bag-plane-take-off"></i>
                <span class="uppercase">${directionText}</span>
                <span>&rarr;</span>
                <span>${journey.departureStationName} - ${journey.arrivalStationName}</span>
            </div>
            ${soldOut.htmlTemplate(journey.index)} ${bundle.htmlTemplate(journey.index)}
            <div
                class="b2-journey-pax-grid-container"
                data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_PASSENGERS, {
                    c: props.bagType,
                    j: journey.index,
                })}
            >
                ${passengersTemplate(journey)}
            </div>
        `;
    };

    const passengersTemplate = (journey: BaggageSectionJourney) =>
        journey.passengers.map((pax) => {
            const dto: PerJourneyPerPaxPassengerDto = {
                baggageSection: props.baggageSection,
                bagType: props.bagType,
                canGetFirstBaggageStaffDiscount: props.staffBaggage.canGetFirstBaggageStaffDiscount({
                    bagType: props.bagType,
                    journeyIndex: journey.index,
                    paxIndex: pax.index,
                }),
                canGetFirstBaggageBancoEstadoDiscount: props.bancoEstadoBaggage.canGetFirstBaggageBancoEstadoDiscount({
                    bagType: props.bagType,
                    journeyIndex: journey.index,
                }),
                isMobile: false,
                journey,
                pageState: props.pageState,
                passenger: pax,
            };

            return html` <ac-per-journey-per-pax-passenger .dto=${dto}></ac-per-journey-per-pax-passenger> `;
        });

    const resetButtonTemplate = () => {
        if (!isResetAvailable) return "";

        const buttonText =
            paxAmount(props.baggageSection.sectionUi.uiState) === 1
                ? i18next.t("Volver a la selección para todos los tramos")
                : i18next.t("Volver a la selección para todos los pasajeros");

        return html`
            <div
                class="b2-reset-button"
                data-test-id=${getTestId(T.BAGGAGE.CLOSE_PER_JOURNEY_PER_PAX_VIEW_BUTTON, {
                    c: props.bagType,
                })}
                @click=${handleViewSwitch}
            >
                ${buttonText}
            </div>
        `;
    };

    const htmlTemplate = () => {
        const tempClassNames = classNames("b2-per-pax-grid b2-openable hidden-xs", props.classToScrollToOnOpen, {
            "b2-opened": props.baggageSection.sectionUi.uiState.perJourneyPerPaxState === "open",
        });

        return html`
            <div
                class=${tempClassNames}
                data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_VIEW, { c: props.bagType })}
            >
                <div class="b2-per-pax-view-switch b2-view-switch">
                    <div class="b2-view-info">
                        ${perJourneyPerPaxViewPaxCaption(paxAmount(props.baggageSection.sectionUi.uiState))}
                    </div>
                    <div class="b2-pax-amount">
                        ${paxAmount(props.baggageSection.sectionUi.uiState)}
                        ${paxLabel(paxAmount(props.baggageSection.sectionUi.uiState))}
                    </div>
                    ${editButton.htmlTemplate()}
                </div>
                <div class="b2-per-pax-reset">${resetButtonTemplate()}</div>
                <div class="b2-per-pax">${props.baggageSection.journeys.map(journeyTemplate)}</div>
            </div>
        `;
    };

    return { htmlTemplate };
};
