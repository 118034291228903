import { html, useEffect, useRef, useState } from "haunted";
import { ApiPortalSpaViewModel } from "../../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import i18next from "i18next";
import classNames from "classnames";
import { ref } from "../../../directives/ref";
import { ROUTES } from "../../../shared/apiRoutes";
import { getAntiforgerySegment, isKeyNumeric, sanitizePhoneNumber } from "../../../shared/common";
import { FAKE_DATE_OF_BIRTH } from "../../../shared/commonConstants";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import { commonValidationRules } from "../../../shared/commonValidationRules";
import { useForm } from "../../../shared/customHooks/useForm/useForm";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { sanitizeInputFieldValue } from "../../../component-helpers/InputSanitizerHelper";
import { usePortalProfileUtils } from "./usePortalProfileUtils";

export interface Props {
    model: ApiPortalSpaViewModel;
    antiForgeryToken: string;
}

export const useEditUnrestrictedUserForm = (props: Props) => {
    const cug2AppContext = useCug2AppContext();
    const { handleUserSubmit } = usePortalProfileUtils();

    const userFormElement = useRef<HTMLFormElement>(undefined);

    const [editUserMode, setEditUserMode] = useState<boolean>(true);

    // TODO Replace form validation with fluent validator!
    const form = useForm({
        customAttributes: [...commonValidationRules()],
        noScroll: true,
    });

    // EVENT-HANDLERS

    const handleUserEditorClick = () => {
        if (editUserMode) return;
        setEditUserMode(true);
    };

    const handlePhoneInput = (e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement;
        target.value = sanitizePhoneNumber(target.value, true);
    };

    const handlePhoneChange = (e: KeyboardEvent) => {
        if (isKeyNumeric(e, true)) {
            return true;
        } else {
            e.preventDefault();
            return false;
        }
    };

    const handleUserSubmitClick = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const isValid = await form.validate();

        if (!isValid) return;
        await handleUserSubmit(userFormElement.current, setEditUserMode);
    };

    const handleNameInput = (e: KeyboardEvent) => {
        sanitizeInputFieldValue(e, "accepted-text-chars");
    };

    const handleEmailInput = (e: KeyboardEvent) => {
        sanitizeInputFieldValue(e, "e-mail");
    };

    const handleUserNameInput = (e: KeyboardEvent) => {
        sanitizeInputFieldValue(e, "user-name");
    };

    // COMPONENT

    useEffect(() => form.init(userFormElement.current), [userFormElement.current]);

    // TEMPLATES
    const userEditorTemplate = () =>
        props.model.EditOrgOrProfileViewModel.CanEditUser
            ? html`
                  <div
                      class=${classNames("cug2b-profile-edit", {
                          "cursor-default": editUserMode,
                          "cursor-pointer": !editUserMode,
                      })}
                      @click=${handleUserEditorClick}
                  >
                      <i class="js-icon-cug js-cug-edit"></i>
                      <span>${i18next.t("Editar")}</span>
                  </div>
              `
            : "";

    const saveMessageTemplate = () =>
        !editUserMode
            ? html`
                  <div class="cug2b-save-message" data-test-id=${T.CUG2_USER_EDIT.SAVE_MESSAGE}>
                      <i class="js-icon-cug js-cug-tick"></i>
                      ${i18next.t("¡Los cambios fueron guardados exitosamente!")}
                  </div>
              `
            : "";

    const inputTemplate = (
        data: {
            label: string;
            name: string;
            value: string;
            isDisabled?: boolean;
            validationRule?: string;
            testId?: string;
        },
        onInput: (e: KeyboardEvent) => void,
    ) => {
        const tabIndex = data.isDisabled ? "-1" : null;
        const validationRule = data.validationRule ? data.validationRule : null;
        const dataTestId = data.testId ? data.testId : null;

        return html`
            <div
                class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty is-upgraded"
                data-upgraded=",MaterialTextfield"
            >
                <label class="mdl-textfield__label">${data.label}</label>
                <input
                    type="text"
                    class=${classNames("mdl-textfield__input js-input", {
                        disabled: data.isDisabled,
                    })}
                    tabindex=${tabIndex}
                    autocomplete="cc-exp"
                    name=${data.name}
                    value=${data.value}
                    data-required
                    data-validation=${validationRule}
                    data-test-id=${dataTestId}
                    @input=${(e: KeyboardEvent) => onInput(e)}
                    @blur=${(e: KeyboardEvent) => onInput(e)}
                />
            </div>
        `;
    };

    const firstNameTemplate = () =>
        inputTemplate(
            {
                label: i18next.t("Nombres"),
                name: "updateAgencyProfile.Member.Name.First",
                value: props.model.EditOrgOrProfileViewModel.UpdateOrganizationUserViewModel.Member.Name.First,
                isDisabled: true,
                testId: T.CUG2_USER_EDIT.FIRST_NAME_INPUT_FIELD,
            },
            handleNameInput,
        );

    const lastNameTemplate = () =>
        inputTemplate(
            {
                label: i18next.t("Apellidos"),
                name: "updateAgencyProfile.Member.Name.Last",
                value: props.model.EditOrgOrProfileViewModel.UpdateOrganizationUserViewModel.Member.Name.Last,
                isDisabled: true,
                testId: T.CUG2_USER_EDIT.LAST_NAME_INPUT_FIELD,
            },
            handleNameInput,
        );

    const countryOptionsTemplate = (selectedCountry: string) =>
        cug2AppContext.Countries.map(
            (country) => html`
                <option value=${country.Value} .selected=${country.Value === selectedCountry}>${country.Text}</option>
            `,
        );

    const nationalityTemplate = () => html`
        <div
            class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow is-dirty is-upgraded"
            data-upgraded=",MaterialTextfield"
        >
            <label class="mdl-textfield__label">${i18next.t("Código país")}</label>
            <select
                name="updateAgencyProfile.Member.Nationality"
                class="mdl-textfield__input js-input js-select disabled"
                data-required
                value=${props.model.EditOrgOrProfileViewModel.UpdateOrganizationUserViewModel.Member.Nationality}
                data-test-id=${T.CUG2_USER_EDIT.COUNTRY_SELECTOR}
            >
                <option value=""></option>
                ${countryOptionsTemplate(
                    props.model.EditOrgOrProfileViewModel.UpdateOrganizationUserViewModel.Member.Nationality,
                )}
            </select>
        </div>
    `;

    const phoneTemplate = (label: string) => html`
        <div
            class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label is-dirty is-upgraded"
            data-upgraded=",MaterialTextfield"
        >
            <label class="mdl-textfield__label">${label}</label>
            <input
                type="text"
                name="updateAgencyProfile.Member.HomePhoneNumber.Number"
                class=${classNames("mdl-textfield__input js-input", {
                    disabled: !editUserMode || !props.model.EditOrgOrProfileViewModel.CanEditUser,
                })}
                autocomplete="cc-exp"
                data-required
                data-validation="AdminForm.Phone"
                value=${props.model.EditOrgOrProfileViewModel.UpdateOrganizationUserViewModel.Member.HomePhoneNumber
                    .Number}
                data-test-id=${T.CUG2_USER_EDIT.PHONE_INPUT_FIELD}
                @input=${handlePhoneInput}
                @keydown=${handlePhoneChange}
            />
        </div>
    `;

    const userEmailTemplate = () =>
        inputTemplate(
            {
                label: i18next.t("Correo electrónico"),
                name: "updateAgencyProfile.Member.PersonalEmailAddress.EmailAddress",
                value: props.model.EditOrgOrProfileViewModel.UpdateOrganizationUserViewModel.Member.PersonalEmailAddress
                    .EmailAddress,
                validationRule: "AdminForm.Email",
                isDisabled: !editUserMode || !props.model.EditOrgOrProfileViewModel.CanEditUser,
                testId: T.CUG2_USER_EDIT.EMAIL_INPUT_FIELD,
            },
            handleEmailInput,
        );

    const userNameTemplate = () =>
        inputTemplate(
            {
                label: i18next.t("User Name"),
                name: "updateAgencyProfile.Member.UserName",
                value: props.model.EditOrgOrProfileViewModel.UpdateOrganizationUserViewModel.Member.Username,
                isDisabled: true,
                testId: T.CUG2_USER_EDIT.USER_NAME,
            },
            handleUserNameInput,
        );

    // TODO Replace these with fluent validator and custom input components
    const cugUserTemplate = () => html`
        <div class="row">
            <div class="col-xs-1 col-sm-1-2">${firstNameTemplate()}</div>
            <div class="col-xs-1 col-sm-1-2">${lastNameTemplate()}</div>
            <div class="col-xs-1 col-sm-1-2">${nationalityTemplate()}</div>
            <div class="col-xs-1 col-sm-1-2">${phoneTemplate(i18next.t("Teléfono"))}</div>
            <div class="col-xs-1">${userEmailTemplate()}</div>
            <div class="col-xs-1">${userNameTemplate()}</div>
        </div>
    `;

    const submitButtonTemplate = () => html`
        <div class="mt-8 flex w-full justify-end">
            <button
                @click=${handleUserSubmitClick}
                class=${classNames("rounded-primary-btn", {
                    disabled: !editUserMode || !props.model.EditOrgOrProfileViewModel.CanEditUser,
                })}
                data-test-id=${T.CUG2_USER_EDIT.SAVE_BUTTON}
            >
                ${i18next.t("Guardar")}
            </button>
        </div>
    `;

    const htmlTemplate = () =>
        props.model.ProfileViewModel.UserModel
            ? html`
                  <form
                      ref=${ref(userFormElement)}
                      action=${ROUTES.Cug2BUserProfile}
                      class=${classNames("cug2b-edit-profile-form ts-error-parent", {
                          "mt-8 md:mt-16": props.model.ProfileViewModel.CompanyModel,
                      })}
                      method="post"
                      novalidate="novalidate"
                  >
                      <h1>${i18next.t("Usuario")} ${userEditorTemplate()}</h1>
                      ${saveMessageTemplate()} ${getAntiforgerySegment(props.antiForgeryToken)} ${cugUserTemplate()}

                      <input
                          value=${props.model.EditOrgOrProfileViewModel.UpdateOrganizationUserViewModel.Member
                              .DateOfBirth || FAKE_DATE_OF_BIRTH}
                          type="hidden"
                          name="updateAgencyProfile.Member.DateOfBirth"
                      />
                      <input type="hidden" name="updateAgencyProfile.Member.Gender" value="1" />

                      ${submitButtonTemplate()}
                  </form>
              `
            : "";

    return { htmlTemplate };
};
