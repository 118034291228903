import i18next from "i18next";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import { getInputId, getInputName } from "../../../../component-helpers/BaggageHelper";
import { html } from "lit-html";
import { BACKPACK_MOBILE_IMG } from "../../spa-container";
import classNames from "classnames";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useMemo } from "../../../../shared/haunted/CustomHooks";

export interface Props {
    baggageSection: BaggageSection;
}

export const useMobilePerBookingFreeCabin = (props: Props) => {
    const bookingContext = useBookingContext();

    const isRemoveAllAvailable = useMemo(
        () =>
            props.baggageSection.perBooking.isRemoveAllAvailable({
                baggageSectionJourneys: props.baggageSection.journeys,
                bagType: "checkedBaggage",
                canGetFreeBancoEstadoBaggage: props.baggageSection.canGetFreeBancoEstadoBaggage,
                isCheckinClosedInbound: bookingContext.isCheckinClosedInbound,
                isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
                paxCount: bookingContext.adultsCount + bookingContext.childrenCount,
            }),
        [props.baggageSection.journeys, bookingContext],
    );

    const handleRemoveAll = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (isRemoveAllAvailable) await props.baggageSection.reset();
    };

    const illustrationTemplate = () => html`
        <img
            class="b2-free-cabin-img"
            src=${BACKPACK_MOBILE_IMG}
            data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_IMAGE, {
                c: "cabinBaggage",
                m: true,
            })}
        />
        <span
            class="b2m-weight"
            data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_WEIGHT, {
                c: "cabinBaggage",
                m: true,
            })}
        >
            10kg.
        </span>
    `;

    const mobileIncludedTemplate = () => html`
        <span data-test-id=${getTestId(T.BAGGAGE.IS_INCLUDED, { c: "cabinBaggage", m: true })}>
            ${i18next.t("Incluido con tu tarifa")}
        </span>
    `;

    const htmlTemplate = () => {
        const id = getInputId("cabinBaggage", undefined, undefined, true, true);
        const inputName = getInputName("cabinBaggage", undefined, undefined, true);

        const freeClassMap = classNames("b2m-per-booking-section free", {
            selected: props.baggageSection.perBooking.isNoBagSelected,
        });

        const onClick = isRemoveAllAvailable ? handleRemoveAll : null;

        return props.baggageSection.sectionUi.uiState.perJourneyPerPaxState !== "open"
            ? html`
                  <div class=${freeClassMap} @click=${onClick}>
                      <div class="b2m-illustration">${illustrationTemplate()}</div>
                      <div class="b2m-per-booking-selector">
                          ${mobileIncludedTemplate()}
                          <input
                              type="radio"
                              name=${inputName}
                              id=${id}
                              .checked=${props.baggageSection.perBooking.isNoBagSelected &&
                              !props.baggageSection.perBooking.isPaidBagSelected}
                              .disabled=${!isRemoveAllAvailable}
                          />
                          <label
                              for=${id}
                              data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_RESET_BUTTON, {
                                  m: true,
                                  c: "cabinBaggage",
                              })}
                          >
                              <div class="b2m-label">
                                  ${i18next.t("Mochila o artículo personal")}
                                  <div
                                      class="b2m-dimensions smaller"
                                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_DIMENSIONS, {
                                          c: "cabinBaggage",
                                      })}
                                  >
                                      35cm &times; 25cm &times; 45cm
                                  </div>
                              </div>
                          </label>
                      </div>
                  </div>
              `
            : "";
    };

    return { htmlTemplate };
};
