import { html } from "haunted";
import { CompanyInfo } from "../../../component-models/CompanyInfo";
import { useOrganizationInfoFields } from "./useOrganizationInfoFields";

export interface Props {
    model: CompanyInfo;
}

export const useChileCompraOrgInfo = (props: Props) => {
    const orgInfo = useOrganizationInfoFields({
        model: props.model,
    });

    // TEMPLATES

    const htmlTemplate = () => html`
        <div class="cug2b-profile-table">
            <div class="cug2b-profile-line chile-compra">
                ${orgInfo.businessName()}
                ${orgInfo.organizationDocumentNumberTemplate()}
            </div>
        </div>
    `;

    return { htmlTemplate };
};
