import * as Immutable from "immutable";
import * as redux from "redux";
import { SimpleActionCreator, createAction, createReducer } from "redux-act";
import { combineReducers } from "redux-immutable";
import { UserContext, DEFAULT_USER_CONTEXT } from "../../component-models/app/UserContext";
import { PickLiteral, NestedKeyOf } from "./ReducerTypes";
import { Country } from "../../component-models/RoutePickerModel";
import { PaymentMethod } from "../../component-models/PaymentMethodDescriptors";
import { CardData, DEFAULT_CARD_DATA } from "../../component-models/payment/CardData";
import { ApiVoucherResult } from "../../component-models/payment/VoucherResult";
import { PayerData } from "../../component-models/payment/PaymentUserData";
import { WompiPseFormData } from "../../component-models/payment/WompiPseFormData";
import { SpaNavigationState } from "../../component-models/spa/SpaNavigationState";

interface AppStore extends redux.Store<Immutable.Map<any, any>> {}

export class ReduxContext {
    public static store: AppStore;

    public static dispatch(action: redux.Action) {
        ReduxContext.store.dispatch(action);
    }

    public static registerReducer(key: string, reducer: redux.Reducer<Immutable.Map<any, any>>) {
        ReduxContext.reducerRegistry[key] = reducer;
    }

    public static createStore() {
        const w: any = window as any;
        const rootReducer = combineReducers<Immutable.Map<any, any>, string>(ReduxContext.reducerRegistry);
        ReduxContext.store = redux.createStore(
            rootReducer,
            w.__REDUX_DEVTOOLS_EXTENSION__ && w.__REDUX_DEVTOOLS_EXTENSION__(),
        );
    }

    private static readonly reducerRegistry: redux.ReducersMapObject = {};
}

export const REDUCER_NAME = "js-reducer";

export interface StateMap {
    antiForgeryToken: string;
    booking: {
        currency: string;
        hasUnpaidInsurance: boolean;
        passengerNames: string[];
        ssrData: string[];
        total: string;
        totalMiles: string;
    };
    countries: Country[];
    isBancoEstadoBarOpened: boolean;
    isSidebarLoaded: boolean;
    payment: {
        cardData: CardData;
        displayedTotal: number;
        payer: PayerData;
        paymentMethod: PaymentMethod;
        selectedCurrency: string;
        voucherResult: ApiVoucherResult;
        wompiPseFormData: WompiPseFormData;
    };
    spa: SpaNavigationState;
    userContext: UserContext;
}

export type ReducerAction =
    | {
          action: SimpleActionCreator<StateMap["antiForgeryToken"]>;
          initialValue: StateMap["antiForgeryToken"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "antiForgeryToken">;
      }
    | {
          action: SimpleActionCreator<StateMap["booking"]["currency"]>;
          initialValue: StateMap["booking"]["currency"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "booking.currency">;
      }
    | {
          action: SimpleActionCreator<StateMap["booking"]["hasUnpaidInsurance"]>;
          initialValue: StateMap["booking"]["hasUnpaidInsurance"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "booking.hasUnpaidInsurance">;
      }
    | {
          action: SimpleActionCreator<StateMap["booking"]["passengerNames"]>;
          initialValue: StateMap["booking"]["passengerNames"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "booking.passengerNames">;
      }
    | {
          action: SimpleActionCreator<StateMap["booking"]["ssrData"]>;
          initialValue: StateMap["booking"]["ssrData"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "booking.ssrData">;
      }
    | {
          action: SimpleActionCreator<StateMap["booking"]["total"]>;
          initialValue: StateMap["booking"]["total"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "booking.total">;
      }
    | {
          action: SimpleActionCreator<StateMap["booking"]["totalMiles"]>;
          initialValue: StateMap["booking"]["totalMiles"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "booking.totalMiles">;
      }
    | {
          action: SimpleActionCreator<StateMap["countries"]>;
          initialValue: StateMap["countries"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "countries">;
      }
    | {
          action: SimpleActionCreator<StateMap["isBancoEstadoBarOpened"]>;
          initialValue: StateMap["isBancoEstadoBarOpened"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "isBancoEstadoBarOpened">;
      }
    | {
          action: SimpleActionCreator<StateMap["isSidebarLoaded"]>;
          initialValue: StateMap["isSidebarLoaded"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "isSidebarLoaded">;
      }
    | {
          action: SimpleActionCreator<StateMap["payment"]["displayedTotal"]>;
          initialValue: StateMap["payment"]["displayedTotal"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "payment.displayedTotal">;
      }
    | {
          action: SimpleActionCreator<StateMap["payment"]["payer"]>;
          initialValue: StateMap["payment"]["payer"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "payment.payer">;
      }
    | {
          action: SimpleActionCreator<StateMap["payment"]["selectedCurrency"]>;
          initialValue: StateMap["payment"]["selectedCurrency"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "payment.selectedCurrency">;
      }
    | {
          action: SimpleActionCreator<StateMap["payment"]["cardData"]>;
          initialValue: StateMap["payment"]["cardData"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "payment.cardData">;
      }
    | {
          action: SimpleActionCreator<StateMap["payment"]["paymentMethod"]>;
          initialValue: StateMap["payment"]["paymentMethod"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "payment.paymentMethod">;
      }
    | {
          action: SimpleActionCreator<StateMap["payment"]["voucherResult"]>;
          initialValue: StateMap["payment"]["voucherResult"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "payment.voucherResult">;
      }
    | {
          action: SimpleActionCreator<StateMap["payment"]["wompiPseFormData"]>;
          initialValue: StateMap["payment"]["wompiPseFormData"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "payment.wompiPseFormData">;
      }
    | {
          action: SimpleActionCreator<StateMap["spa"]>;
          initialValue: StateMap["spa"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "spa">;
      }
    | {
          action: SimpleActionCreator<StateMap["userContext"]>;
          initialValue: StateMap["userContext"];
          propertyName: PickLiteral<NestedKeyOf<StateMap>, "userContext">;
      };

const addPropertyByDotNotation = (object: any, pathName: string, value: any) => {
    const path = pathName.split(".");
    const last = path.pop();
    const lastObj = path.reduce((obj, key) => (obj[key] = obj[key] || {}), object);
    lastObj[last] = value;

    return object;
};

export const reducerActions: ReducerAction[] = [
    {
        action: createAction<string>("antiForgeryToken"),
        initialValue: "",
        propertyName: "antiForgeryToken",
    },
    {
        action: createAction<string>("currency"),
        initialValue: window.JetSmart.BookingContext?.currency || window.JetSmart.Cug2AppContext?.currency,
        propertyName: "booking.currency",
    },
    {
        action: createAction<boolean>("hasUnpaidInsurance"),
        initialValue: false,
        propertyName: "booking.hasUnpaidInsurance",
    },
    {
        action: createAction<string[]>("passengerNames"),
        initialValue: [],
        propertyName: "booking.passengerNames",
    },
    {
        action: createAction<string[]>("ssrData"),
        initialValue: window.JetSmart.BookingContext?.ssrData?.split("|") || [],
        propertyName: "booking.ssrData",
    },
    {
        action: createAction<string>("total"),
        initialValue: window.JetSmart.BookingContext?.total || "0",
        propertyName: "booking.total",
    },
    {
        action: createAction<string>("totalMiles"),
        initialValue: window.JetSmart.BookingContext?.totalMiles || "0",
        propertyName: "booking.totalMiles",
    },
    {
        action: createAction<Country[]>("countries"),
        initialValue: [],
        propertyName: "countries",
    },
    {
        action: createAction<boolean>("isBancoEstadoBarOpened"),
        initialValue: true,
        propertyName: "isBancoEstadoBarOpened",
    },
    {
        action: createAction<boolean>("isSidebarLoaded"),
        initialValue: false,
        propertyName: "isSidebarLoaded",
    },
    {
        action: createAction<CardData>("cardData"),
        initialValue: DEFAULT_CARD_DATA,
        propertyName: "payment.cardData",
    },
    {
        action: createAction<number>("displayedTotal"),
        initialValue: window.JetSmart.BookingContext?.total ? Number(window.JetSmart.BookingContext.total) : 0,
        propertyName: "payment.displayedTotal",
    },
    {
        action: createAction<PayerData>("payer"),
        initialValue: undefined,
        propertyName: "payment.payer",
    },
    {
        action: createAction<string>("selectedCurrency"),
        initialValue: "",
        propertyName: "payment.selectedCurrency",
    },
    {
        action: createAction<PaymentMethod>("paymentMethod"),
        initialValue: undefined,
        propertyName: "payment.paymentMethod",
    },
    {
        action: createAction<ApiVoucherResult>("voucherResult"),
        initialValue: undefined,
        propertyName: "payment.voucherResult",
    },
    {
        action: createAction<WompiPseFormData>("wompiPseFormData"),
        initialValue: undefined,
        propertyName: "payment.wompiPseFormData",
    },
    {
        action: createAction<SpaNavigationState>("spa"),
        initialValue: undefined,
        propertyName: "spa",
    },
    {
        action: createAction<UserContext>("userContext"),
        initialValue: DEFAULT_USER_CONTEXT,
        propertyName: "userContext",
    },
];

const mappedActions = reducerActions.reduce(
    (actions, { propertyName, action }) => ({
        ...actions,
        [action.toString()]: (state: Immutable.Map<any, any>, data: any) =>
            state.updateIn([...propertyName.split(".")], (_) => Immutable.fromJS(data)),
    }),
    {} as any,
);

const mappedInitialState: StateMap = reducerActions.reduce(
    (state, { propertyName, initialValue }) => addPropertyByDotNotation(state, propertyName, initialValue),
    {} as StateMap,
);

ReduxContext.registerReducer(
    REDUCER_NAME,
    createReducer<Immutable.Map<any, any>>(mappedActions, Immutable.fromJS(mappedInitialState)),
);

ReduxContext.createStore();
