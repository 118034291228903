import i18next from "i18next";
import { html } from "haunted";
import { useModal } from "../../shared/useModal";

export interface Props {
    url: string;
}

export const useCarryOnCheckinModal = (props: Props) => {
    const headerTemplate = () => html`
        <i class="js-icon js-triangle-exclamation"></i>
        <span>${i18next.t("I2-RedirectionModalTitle")}</span>
    `;

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="description-container">
                <span>${i18next.t("I2-RedirectionModalContent")}</span>
            </div>
            <div class="primary-modal-button-container">
                <a href=${props.url} class="rounded-primary-btn"> ${i18next.t("V2-Continue")} </a>
            </div>
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { template: headerTemplate },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};
