import i18next from "i18next";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import { getInputId, getInputName } from "../../../../component-helpers/BaggageHelper";
import { html } from "lit-html";
import { CHECKED_BAGGAGE_IMG } from "../../spa-container";
import { perBookingQuantityButtons } from "./perBookingQuantityButtons";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import classNames from "classnames";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { StaffBaggage } from "../common/useStaffBaggage";
import { useCallback } from "haunted";
import { useBaggageUtils } from "../common/useBaggageUtils";
import { BancoEstadoBaggage } from "../common/useBancoEstadoBaggage";

export interface Props {
    baggageSection: BaggageSection;
    bancoEstadoBaggage: BancoEstadoBaggage;
    staffBaggage: StaffBaggage;
}

export const useMobilePerBookingPaidChecked = (props: Props) => {
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const { formattedLowestNextPrice } = useBaggageUtils();

    const quantityButtons = perBookingQuantityButtons({
        baggageSection: props.baggageSection,
        bagType: "checkedBaggage",
        isCheckinClosedInbound: bookingContext.isCheckinClosedInbound,
        isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
        isMobile: true,
        paxCount: bookingContext.adultsCount + bookingContext.childrenCount,
    });

    const showOriginalMinimumPrice = useMemo(
        () =>
            flowContext.isBookingFlow &&
            bookingContext.isDiscountBooking &&
            props.baggageSection.perBooking.nextPrice({
                baggageSectionJourneys: props.baggageSection.journeys,
                bagType: "checkedBaggage",
                bancoEstadoBaggage: props.bancoEstadoBaggage,
                isMinimum: true,
                isOriginal: true,
                staffBaggage: props.staffBaggage,
            }),
        [
            bookingContext.isDiscountBooking,
            flowContext.isBookingFlow,
            props.baggageSection.journeys,
            props.bancoEstadoBaggage,
            props.staffBaggage,
        ],
    );

    const isAddAvailable = useMemo(
        () =>
            props.baggageSection.perBooking.isAddAvailable({
                baggageSectionJourneys: props.baggageSection.journeys,
                bagType: "checkedBaggage",
                canGetFreeBancoEstadoBaggage: props.baggageSection.canGetFreeBancoEstadoBaggage,
                isCheckinClosedInbound: bookingContext.isCheckinClosedInbound,
                isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
                paxCount: bookingContext.adultsCount + bookingContext.childrenCount,
            }),
        [props.baggageSection.journeys, bookingContext],
    );

    const unformattedLowestNextPrice = useCallback(
        (isOriginal: boolean, isMinimum: boolean) =>
            props.baggageSection.perBooking.nextPrice({
                baggageSectionJourneys: props.baggageSection.journeys,
                bagType: "checkedBaggage",
                bancoEstadoBaggage: props.bancoEstadoBaggage,
                isMinimum,
                isOriginal,
                staffBaggage: props.staffBaggage,
            }),
        [props.baggageSection.journeys, props.staffBaggage, props.bancoEstadoBaggage],
    );

    const handleAdd = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (isAddAvailable) await props.baggageSection.add();
    };

    const illustrationTemplate = () => html`
        <div class="b2m-illustration">
            <div
                class="b2m-ribbon"
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_RIBBON, {
                    c: "checkedBaggage",
                    m: true,
                })}
            >
                <span>&#9825;</span> ${i18next.t("¡Recomendado para ti!")}
            </div>
            <div>
                <img class="b2-paid-checked-img" src=${CHECKED_BAGGAGE_IMG} />
            </div>
            <div class="flex items-center">
                <div
                    class="b2m-weight"
                    data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_PAID_ILLUSTRATION_WEIGHT, {
                        c: "checkedBaggage",
                        m: true,
                    })}
                >
                    23kg.
                </div>
            </div>
        </div>
    `;

    const originalPriceTemplate = () =>
        showOriginalMinimumPrice
            ? html`
                  <span class="b2m-crossed-out-price">
                      <span
                          class="line-through"
                          data-test-value=${unformattedLowestNextPrice(true, false)}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_CROSSED_OUT_MINIMUM_PRICE, {
                              c: "checkedBaggage",
                              m: true,
                          })}
                          >${formattedLowestNextPrice(unformattedLowestNextPrice(true, false))}</span
                      >
                  </span>
              `
            : "";

    const nextPriceTemplate = () => {
        const unformattedAmount = unformattedLowestNextPrice(false, false);

        return unformattedAmount >= 0
            ? html`
                  <span class=${classNames({ reduced: bookingContext.isDiscountBooking })}>
                      ${i18next.t("Desde")}
                      <span
                          data-test-value=${unformattedAmount}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_MINIMUM_PRICE, {
                              c: "checkedBaggage",
                              m: true,
                          })}
                          >${formattedLowestNextPrice(unformattedAmount)}</span
                      >
                  </span>
              `
            : "";
    };

    const quantityButtonsTemplate = () =>
        props.baggageSection.perBooking.isPaidBagSelected ? quantityButtons.htmlTemplate() : "";

    const htmlTemplate = () => {
        const checkedId = getInputId("checkedBaggage", undefined, undefined, true, false);
        const checkedInputName = getInputName("checkedBaggage", undefined, undefined, true);

        const tempClassMap = classNames("b2m-per-booking-section padded", {
            selected: props.baggageSection.perBooking.isPaidBagSelected,
        });

        const selectorClassMap = classNames("b2m-per-booking-selector", {
            padded: props.baggageSection.perBooking.isPaidBagSelected,
        });

        return props.baggageSection.sectionUi.uiState.perJourneyPerPaxState !== "open"
            ? html`
                  <div class=${tempClassMap}>
                      ${illustrationTemplate()}
                      <div class=${selectorClassMap}>
                          ${originalPriceTemplate()} ${nextPriceTemplate()}
                          <input
                              type="radio"
                              name=${checkedInputName}
                              id=${checkedId}
                              .checked=${props.baggageSection.perBooking.isPaidBagSelected}
                          />
                          <label
                              for=${checkedId}
                              data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_ADD_FIRST_BUTTON, {
                                  c: "checkedBaggage",
                                  m: true,
                              })}
                              @click=${handleAdd}
                          >
                              <div class="b2m-label">
                                  ${i18next.t("Equipaje facturado")}
                                  <div
                                      class="b2m-dimensions"
                                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_FREE_ILLUSTRATION_DIMENSIONS, {
                                          c: "checkedBaggage",
                                      })}
                                  >
                                      158cm ${i18next.t("Baggage-Length")}
                                  </div>
                              </div>
                              ${quantityButtonsTemplate()}
                          </label>
                      </div>
                  </div>
              `
            : "";
    };

    return { htmlTemplate };
};
