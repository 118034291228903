import { html } from "haunted";
import i18next from "i18next";
import { MAX_PAX_IN_GROUP_DC_MEMBERSHIP, MAX_PAX_IN_STANDARD_DC_MEMBERSHIP } from "../../../../shared/commonConstants";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { BagType } from "../useBaggagePage";
import { UserContext } from "../../../../component-models/app/UserContext";

export const baggageIllustrations = () => {
    const tooManyPaxForDc = (paxNumber: number, userContext: UserContext): boolean =>
        (!userContext.dc.hasGroupMembership &&
            userContext.dc.hasStandardMembership &&
            paxNumber > MAX_PAX_IN_STANDARD_DC_MEMBERSHIP) ||
        (userContext.dc.hasGroupMembership && paxNumber > MAX_PAX_IN_GROUP_DC_MEMBERSHIP);

    // EXPORTS

    const backpackTooltipTemplate = () => html`
        <span class="b2-tooltip-opener backpack-tooltip-opener" data-test-id=${T.BAGGAGE.BACKPACK_TOOLTIP_OPENER}
            >?
            <span class="b2-tooltip b2-tooltip-free" data-test-id=${T.BAGGAGE.BACKPACK_TOOLTIP}>
                <i
                    class="js-icon-bag js-bag-backpack-with-dimensions"
                    data-test-id=${T.BAGGAGE.BACKPACK_TOOLTIP_DIMENSIONS}
                ></i>
                <span>25cm</span>
                <span>35cm</span>
                <span>45cm</span>
            </span></span
        >
    `;

    const regularBagTooltipTemplate = () => html`
        <span class="b2-tooltip-opener regular-bag-tooltip-opener" data-test-id=${T.BAGGAGE.REGULAR_BAG_TOOLTIP_OPENER}
            >?
            <span class="b2-tooltip b2-tooltip-paid" data-test-id=${T.BAGGAGE.REGULAR_BAG_TOOLTIP}
                ><i
                    class="js-icon-bag js-bag-regular-baggage-with-dimensions"
                    data-test-id=${T.BAGGAGE.REGULAR_BAG_TOOLTIP_DIMENSIONS}
                ></i>
                <span>25cm</span>
                <span>35cm</span>
                <span>55cm</span></span
            ></span
        >
    `;

    const zippedBagTooltipTemplate = () => html`
        <span class="b2-tooltip-opener zipped-bag-tooltip-opener" data-test-id=${T.BAGGAGE.ZIPPED_BAG_TOOLTIP_OPENER}
            >?
            <span
                class="b2-tooltip b2-tooltip-free one-dimension-container"
                data-test-id=${T.BAGGAGE.ZIPPED_BAG_TOOLTIP}
            >
                <i
                    class="js-icon-bag js-bag-zipper-bag-one-dimension"
                    data-test-id=${T.BAGGAGE.ZIPPED_BAG_TOOLTIP_DIMENSIONS}
                ></i>
                <span class="one-dimension">${i18next.t("158 cm lineales")}</span>
            </span></span
        >
    `;

    const tooManyPaxForDcTemplate = (paxNumber: number, bagType: BagType, userContext: UserContext) =>
        tooManyPaxForDc(paxNumber, userContext)
            ? html`
                  <div
                      class="my-4 font-body font-semibold italic text-be-blue md:leading-loose lg:text-lg xl:text-xl"
                      data-test-id=${getTestId(T.BAGGAGE.TOO_MANY_PAX_FOR_DC, { c: bagType })}
                  >
                      ${i18next.t(
                          "¡Recuerda! El descuento en equipaje solo se aplicará a la cantidad de pasajeros que tu membresía cubre.",
                      )}
                  </div>
              `
            : "";

    const perBookingDcTemplate = (bagType: BagType) => html`
        <div class="b2-baggage-dc-info" data-test-id=${getTestId(T.BAGGAGE.DC_INFO, { c: bagType })}>
            <i class="js-icon-be2 js-be2-money"></i>
            <span
                >${i18next.t(
                    "Por ser Miembro del Club de Descuentos te descontamos $1.000 CLP o $1 USD en tu equipaje",
                )}</span
            >
        </div>
    `;

    return {
        backpackTooltipTemplate,
        perBookingDcTemplate,
        regularBagTooltipTemplate,
        tooManyPaxForDcTemplate,
        zippedBagTooltipTemplate,
    };
};
