import i18next from "i18next";
import { html } from "lit-html";
import {
    ApiItineraryViewModelV2,
    ItineraryAncillaryType,
} from "../../component-models/itinerary/ApiItineraryViewModelV2";
import { ROUTES } from "../../shared/apiRoutes";
import { useState } from "../../shared/haunted/CustomHooks";
import { useItineraryTealiumManager } from "../../managers/Tealium/useItineraryTealiumManager";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    model: ApiItineraryViewModelV2;
}

type OptionalTemplateId = "giftcard" | "pet" | "priorityBoarding" | "airportCheckin" | "oversizedBag";

interface OptionalTemplate {
    buttonText: string;
    iconClass: string;
    id: OptionalTemplateId;
    mainText: string;
    route: string;
    secondaryText: string;
    showBancoEstado?: boolean;
    tertiaryText: string;
    title: string;
}

const optionalParentClassName = "i2-itinerary-optional";

export const useItineraryPageOptionals = (props: Props) => {
    // HELPERS

    const isMobileOptionalOpen = (id: OptionalTemplateId) => openCheckbox === id;

    const toggleMobileOptional = (id: OptionalTemplateId) => {
        setOpenCheckbox(openCheckbox === id ? undefined : id);
    };

    // DEVNOTE If not even number of optionals, padding is needed
    const isPaddingNeeded = () => {
        const displayedOptionals = [
            !userContext.cug.isMember && props.model.IsGiftCardAvailable, // DEVNOTE No GC for CUG users
            props.model.CanBuyMorePriorityBoarding,
            props.model.CanBuyMoreAirportCheckin,
            props.model.CanBuyMorePetInCabin,
            props.model.CanBuyMoreOversizedBag,
        ].filter((item) => item);

        return displayedOptionals.length % 2 !== 0;
    };

    const getMinimumPrice = (type: ItineraryAncillaryType) => {
        const item =
            props.model.MinimumPrices && Array.isArray(props.model.MinimumPrices)
                ? props.model.MinimumPrices.find((mp) => mp.Type === type)
                : undefined;

        return item?.FormattedPrice || "$0";
    };

    // EVENT HANDLERS

    const logAndSubmit = async (e: MouseEvent, type: string) => {
        e.preventDefault();
        e.stopPropagation();

        const url = (e.target as HTMLAnchorElement).getAttribute("href");
        await tealiumManager.logAncillaryBannerClicked(type);

        window.setTimeout(() => (window.location.href = url), 0);
    };

    // COMPONENT

    const tealiumManager = useItineraryTealiumManager();

    const [userContext] = useReduxState("userContext");

    const [openCheckbox, setOpenCheckbox] = useState<string>(undefined);

    // TEMPLATES

    const bancoEstadoRibbonTemplate = () => {
        switch (userContext.bancoEstado.category) {
            case 6:
                return html`
                    <div class="i2-benefit bg-be-gray-3">
                        <img class="smart-logo" src="/Images/BancoEstado/be-smart-plus-white-text-gray-bg.svg" />
                        <span>
                            ${i18next.t("Beneficio")}<br />${i18next.t("tarjeta")}
                            <img src="/Images/BancoEstado/be-smart-plus-text-logo-white.svg" />
                        </span>
                    </div>
                `;
            case 5:
                return html`
                    <div class="i2-benefit bg-be-old-orange">
                        <img class="smart-logo" src="/Images/BancoEstado/be-smart-white-text-orange-bg.svg" />
                        <span>
                            ${i18next.t("Beneficio")}&nbsp;<br />${i18next.t("tarjeta")}
                            <img src="/Images/BancoEstado/be-smart-text-logo-white.svg" />
                        </span>
                    </div>
                `;
            default:
                return "";
        }
    };

    const optionalTextTemplate = (data: OptionalTemplate) => html`
        <article>
            <span class="main-emphasis">${data.mainText}</span>
            ${data.secondaryText}
            <span class="emphasis whitespace-nowrap">${data.tertiaryText}</span>
        </article>
    `;

    const optionalNonMobileTemplate = (data: OptionalTemplate) => html`
        <div class="${optionalParentClassName} hidden-xs">
            ${data.showBancoEstado ? bancoEstadoRibbonTemplate() : ""}
            <i class="js-icon-it ${data.iconClass}"></i>
            <div>
                <header
                    class="${[5, 6].includes(userContext.bancoEstado.category) && data.showBancoEstado
                        ? "push-down"
                        : ""}"
                >
                    ${data.title}
                </header>
                ${optionalTextTemplate(data)}
                <a
                    class="rounded-primary-btn i2-btn"
                    @click=${(e: MouseEvent) => logAndSubmit(e, data.id)}
                    href="${data.route}"
                >
                    ${data.buttonText}
                </a>
            </div>
        </div>
    `;

    const optionalMobileTemplate = (data: OptionalTemplate) => {
        return html`
            <!-- DEVNOTE We use "section" so that we can target the first one with a :first-of-type css selector -->
            <section class="${optionalParentClassName} hidden-sm-up" @click=${() => toggleMobileOptional(data.id)}>
                <input type="checkbox" class="mobile-checkbox opacity-0" .checked=${isMobileOptionalOpen(data.id)} />
                <div class="i2-optional-mobile-header">
                    <header>
                        <i class="js-icon-it ${data.iconClass}"></i>
                        ${data.title}
                        <span class="opener">&plus;</span>
                        <span class="closer">&times;</span>
                        <a
                            class="rounded-primary-btn i2-btn"
                            @click=${(e: MouseEvent) => logAndSubmit(e, data.id)}
                            href="${data.route}"
                        >
                            ${data.buttonText}
                        </a>
                    </header>
                </div>
                <div class="i2-optional-mobile-content">
                    ${data.showBancoEstado ? bancoEstadoRibbonTemplate() : ""} ${optionalTextTemplate(data)}
                </div>
            </section>
        `;
    };

    const optionalTemplate = (data: OptionalTemplate) => html`
        ${optionalMobileTemplate(data)} ${optionalNonMobileTemplate(data)}
    `;

    const paddingTemplate = () =>
        isPaddingNeeded()
            ? html`
                  <!-- DEVNOTE Padding is not needed on mobile, which is one column -->
                  <div class="${optionalParentClassName} hidden-xs"></div>
              `
            : "";

    const htmlTemplate = () => html`
        <div class="i2-itinerary-section i2-optionals no-print">
            ${!userContext.cug.isMember && props.model.IsGiftCardAvailable
                ? optionalTemplate({
                      buttonText: i18next.t("Agregar"),
                      iconClass: "js-it-giftcard",
                      id: "giftcard",
                      mainText: i18next.t("¡Vuela cuando quieras, ahorrando aún más!"),
                      route: ROUTES.GiftcardSelect,
                      secondaryText: i18next.t(
                          "Aprovecha tus precios especiales y cómprala para tí o regala a quién tú quieras ",
                      ),
                      showBancoEstado: true,
                      tertiaryText: i18next.t("desde {{- amount}}", {
                          amount: getMinimumPrice(ItineraryAncillaryType.GiftCard),
                      }),
                      title: i18next.t("Gift Card"),
                  })
                : ""}
            ${props.model.CanBuyMorePriorityBoarding
                ? optionalTemplate({
                      buttonText: i18next.t("Agregar"),
                      iconClass: "js-it-priority",
                      id: "priorityBoarding",
                      mainText: i18next.t("Si eres de los que no tiene tiempo que perder, ¡este opcional es para ti!"),
                      route: ROUTES.PageExtras,
                      secondaryText: i18next.t("Ahorra tiempo en embarcar y desembarcar del avión"),
                      tertiaryText: i18next.t("desde {{- amount}}", {
                          amount: getMinimumPrice(ItineraryAncillaryType.PriorityBoarding),
                      }),
                      title: i18next.t("Embarque prioritario"),
                  })
                : ""}
            ${props.model.CanBuyMoreAirportCheckin
                ? optionalTemplate({
                      buttonText: i18next.t("Agregar"),
                      iconClass: "js-it-boarding-card",
                      id: "airportCheckin",
                      mainText: i18next.t("¡No te preocupes nosotros lo hacemos por tí!"),
                      route: ROUTES.PageExtras,
                      secondaryText: i18next.t("Acércate al counter y retira tu tarjeta de embarque"),
                      tertiaryText: i18next.t("desde {{- amount}}", {
                          amount: getMinimumPrice(ItineraryAncillaryType.AirportCheckin),
                      }),
                      title: i18next.t("Imprime tu tarjeta de embarque en aeropuerto"),
                  })
                : ""}
            ${props.model.CanBuyMorePetInCabin
                ? optionalTemplate({
                      buttonText: i18next.t("Agregar"),
                      iconClass: "js-it-pets",
                      id: "pet",
                      mainText: i18next.t("¡Porque es uno más de la familia, llévalo a todas partes contigo!"),
                      route: ROUTES.PageExtras,
                      secondaryText: i18next.t("Aprovecha y cómpralo hoy"),
                      tertiaryText: i18next.t("desde {{- amount}}", {
                          amount: getMinimumPrice(ItineraryAncillaryType.PetInCabin),
                      }),
                      title: i18next.t("Mascota a bordo"),
                  })
                : ""}
            ${props.model.CanBuyMoreOversizedBag
                ? optionalTemplate({
                      buttonText: i18next.t("Agregar"),
                      iconClass: "js-it-guitar-surf-golf",
                      id: "oversizedBag",
                      mainText: i18next.t(
                          "Si viajas con bicicletas, tablas de surf, bolsos de palos de golf o de pesca",
                      ),
                      route: ROUTES.PageBaggage,
                      secondaryText: i18next.t("este opcional es para ti, llévalo"),
                      tertiaryText: i18next.t("desde {{- amount}}", {
                          amount: getMinimumPrice(ItineraryAncillaryType.OversizedBag),
                      }),
                      title: i18next.t("Equipaje sobredimensionado"),
                  })
                : ""}
            ${paddingTemplate()}
        </div>
    `;

    return { htmlTemplate };
};
