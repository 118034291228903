import { TestIdDictionary as T, getTestId } from "../../../testing-helpers/TestIdHelper";
import { classMap } from "lit-html/directives/class-map";
import { INBOUND, OUTBOUND } from "../../../shared/commonConstants";
import { html, useMemo, useRef } from "haunted";
import i18next from "i18next";
import { ref } from "../../../directives/ref";
import { useFlightItineraryInfo } from "../../shared/flight-itinerary-info/useFlightItineraryInfo";
import { useBundleSelected } from "./useBundleSelected";
import { useBundleSelectedMobile } from "./useBundleSelectedMobile";
import { useBundleUpgradeOffer } from "./useBundleUpgradeOffer";
import { FlightPageContext } from "../../../component-models/flight/contexts/FlightPageContext";
import classNames from "classnames";
import { CLASS_NAMES } from "../../../shared/classNames";
import { useFlowContext } from "../../../managers/useFlowContext";

export interface Props {
    context: FlightPageContext;
    isBrazilianStationSelected: boolean;
    isInFlightMove?: boolean;
    journeyIndex: number;
    onReset: () => void;
}

export const useBundleSelectedPerLeg = (props: Props) => {
    const flowContext = useFlowContext();

    const rootElem = useRef<HTMLDivElement>(undefined);

    const flight = useMemo(
        () =>
            props.journeyIndex === OUTBOUND
                ? props.context?.flightState?.selectedOutboundFlight
                : props.context?.flightState?.selectedInboundFlight,
        [props.context?.flightState, props.journeyIndex],
    );

    const bundle = useMemo(
        () =>
            props.journeyIndex === OUTBOUND
                ? props.context?.bundleState?.selectedOutboundBundle
                : props.context?.bundleState?.selectedInboundBundle,
        [props.context?.bundleState, props.journeyIndex],
    );

    const flightItineraryInfo = useFlightItineraryInfo({
        isBundleSelected: true,
        isForCheckin: false,
        isInFlightMove: props.isInFlightMove,
        itineraryData: {
            ArrivalDate: flight?.ArrivalDate,
            ArrivalTime: flight?.FlightSelectModel.Sta24H,
            DepartureDate: flight?.DepartureDate,
            DepartureTime: flight?.FlightSelectModel.Std24H,
            DestinationAirport: flight?.FlightSelectModel.DestinationAirport,
            DestinationCity: flight?.FlightSelectModel.DestinationCity,
            DestinationIata: flight?.FlightSelectModel.DestinationIata,
            FlightIndex: flight?.Index,
            FlightNumber: flight?.FlightSelectModel.FlightNumber,
            Hours: flight?.Hours,
            JourneyIndex: flight?.JourneyIndex,
            LegDetails: flight?.LegDetails,
            Minutes: flight?.Minutes,
            OriginAirport: flight?.FlightSelectModel.OriginAirport,
            OriginCity: flight?.FlightSelectModel.OriginCity,
            OriginIata: flight?.FlightSelectModel.OriginIata,
            PunctualityPerformance: flight?.FlightSelectModel.PunctualityPerformance,
            Segments: flight?.FlightSelectModel.Segments,
        },
        isBrazilianStationSelected: props.isBrazilianStationSelected,
    });

    const bundleUpgradeOffer = useBundleUpgradeOffer({ context: props.context, journeyIndex: props.journeyIndex });

    const bundleSelected = useBundleSelected({
        allBundlesSelected: true,
        context: props.context,
        isUpgradingBundle: bundleUpgradeOffer.isSizeReduced,
        journeyIndex: props.journeyIndex,
    });

    const bundleSelectedMobile = useBundleSelectedMobile({
        allBundlesSelected: true,
        context: props.context,
        isUpgradingBundle: bundleUpgradeOffer.isSizeReduced,
        journeyIndex: props.journeyIndex,
    });

    // EVENT LISTENERS

    const handleEdit = (e?: MouseEvent) => {
        e?.preventDefault();
        e?.stopPropagation();

        props.onReset();
    };

    // TEMPLATES

    const selectedBundleTemplate = () => html`
        ${bundleSelected.htmlTemplate()} ${bundleSelectedMobile.htmlTemplate()} ${bundleUpgradeOffer.htmlTemplate()}
    `;

    const directionTemplate = () => html`
        <div
            class="direction pb-4"
            data-test-id=${getTestId(T.FLIGHT.FLIGHT_INFO_CONTENT, {
                j: props.journeyIndex,
                i: flight?.Index,
            })}
        >
            <span>
                ${props.journeyIndex === OUTBOUND
                    ? i18next.t("PriceDisplay-DepartingFlight")
                    : i18next.t("PriceDisplay-ReturningFlight")}
            </span>
            ${flight?.FormattedFlightDate}
        </div>
    `;

    const contentTemplate = () => {
        const tempClassMap = classMap({
            "selected-flight": true,
            "discount-club": props.context?.flightState?.selectedFeeType === "Club",
            "show-offers": !flowContext.isFlightMove && props.context.showBundleUpgradeOffer(bundle),
        });

        return html`
            <div class=${tempClassMap}>
                <i
                    class="js-icon js-edit absolute right-[10px] top-[10px] z-[1] cursor-pointer text-lg text-brand-secondary hover:text-brand-primary"
                    data-test-id=${getTestId(T.BUNDLE.EDIT_SELECTED, { j: props.journeyIndex })}
                    @click=${handleEdit}
                ></i>
                <div class="row flex justify-center pb-4">
                    <div class="flex w-full flex-col">
                        ${directionTemplate()}
                        <div class="pt-2 sm:pb-4">${flightItineraryInfo.htmlTemplate()}</div>
                    </div>
                </div>

                ${selectedBundleTemplate()}
            </div>
        `;
    };

    const htmlTemplate = () =>
        flight
            ? html`
                  <div
                      ref=${ref(rootElem)}
                      data-test-id=${getTestId(T.BUNDLE.SELECTED_PER_LEG, { j: props.journeyIndex })}
                      class=${classNames("mx-auto h-full w-full longer-transition", {
                          "mt-[25px] sm:mt-0": props.journeyIndex === INBOUND,
                          [CLASS_NAMES.flightSelectedFlightContainer]: true,
                      })}
                  >
                      <div class="row sm:h-full">
                          <div class="col-xs-1">${contentTemplate()}</div>
                      </div>
                  </div>
              `
            : "";

    return { htmlTemplate };
};
