import { useRef } from "haunted";
import { html } from "lit-html";
import { ref } from "../../directives/ref";
import i18next from "i18next";
import { useEffect, useMemo } from "../../shared/haunted/CustomHooks";
import { DUMMY_PAYMENT_CODE_FOR_HOLD } from "../../shared/commonConstants";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { PaymentMode } from "../../component-models/payment/PaymentMode";
import { useBasicCheckbox } from "../ui/basic-checkbox/useBasicCheckbox";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    model: PaymentPageViewModel;
    paymentMode: PaymentMode;
    handleHoldBookingToggle: (e: MouseEvent) => void;
}

export const useHoldBooking = (props: Props) => {
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const { checkPromoCodeAvailable } = useAjax();

    const [userContext] = useReduxState("userContext");

    const root = useRef<HTMLFormElement>(undefined);

    const isAvailable = () =>
        props.model.AgencyViewModel.ShowHoldBooking &&
        !flowContext.isChangeFlow &&
        userContext.peruCompra.role === "none" &&
        userContext.chileCompra.role === "none" &&
        !bookingContext.isPeruCompraBooking &&
        (props.paymentMode === "regular" || props.paymentMode === "none");

    const onHoldCheckboxLabelTemplate = (id: string) => html`
        <label for=${id} class="payment-on-hold-checkbox-label" data-test-id="put-on-hold-label">
            <div class="payment-on-hold-checkbox-text">${i18next.t("V2-PutOnHold")}</div>
        </label>
    `;

    const isDisabled = useMemo(
        () =>
            props.model?.AgencyViewModel.ShowHoldBooking &&
            !props.model.AgencyViewModel.ShowAgencyPayment &&
            !(props.model.MethodsViewModel.PaymentMethods?.PaymentMethods?.length > 0),
        [props.model],
    );

    const onHoldCheckbox = useBasicCheckbox({
        customWrapperClass: "payment-on-hold-checkbox-wrapper",
        isChecked: isDisabled || props.paymentMode === "none",
        disabled: isDisabled,
        labelTemplate: onHoldCheckboxLabelTemplate,
        onClick: () => props.handleHoldBookingToggle(new MouseEvent("click")),
    });

    useEffect(async () => {
        if (props.paymentMode === "none" && bookingContext.promoCode) {
            await checkPromoCodeAvailable(DUMMY_PAYMENT_CODE_FOR_HOLD, true, false);
        }
    }, [props.paymentMode]);

    const htmlTemplate = () =>
        isAvailable()
            ? html`
                  <form ref=${ref(root)}>
                      <div class="on-hold">${onHoldCheckbox.htmlTemplate()}</div>
                  </form>
              `
            : "";

    return { htmlTemplate };
};
