import { unsafeHTML } from "lit-html/directives/unsafe-html";
import i18next from "i18next";
import { html } from "haunted";
import { useModal } from "../../shared/useModal";

export const useAmericanAirlinesRedirectionModal = () => {
    const htmlTemplate = () => html`
        <div class="modal-body aa-modal">
            ${unsafeHTML(
                i18next.t(
                    "Tu reserva fue comprada en American Airlines, por favor visita {{-linkStart}}www.AA.com{{-linkEnd}}.",
                    {
                        linkStart: "<a href='http://www.AA.com'>",
                        linkEnd: "</a>",
                    },
                ),
            )}
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { template: () => html`<span>${i18next.t("Importante")}</span>` },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};
