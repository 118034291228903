import { Cug2AppContext } from "../component-models/app/Cug2AppContext";
import { FeatureSwitch } from "../component-models/app/FeatureSwitches";
import { toBoolean } from "../shared/common";
import { FAKE_COUNTRY_CODE } from "../shared/commonConstants";
import { useMemo } from "../shared/haunted/CustomHooks";

export const useCug2AppContext = () => {
    const isFeatureSwitchActive = (featureSwitch: FeatureSwitch) => {
        return window.JetSmart.FeatureSwitch.some((fs) => fs.Name === featureSwitch && fs.IsActive);
    };

    const getCountries = () =>
        window.JetSmart.Cug2AppContext.countries?.filter((country) => country.Value !== FAKE_COUNTRY_CODE) || [];

    const context: Cug2AppContext = useMemo(
        () =>
            window.JetSmart.Cug2AppContext
                ? {
                      Action: window.JetSmart.Cug2AppContext.action.toLowerCase(),
                      AntiForgeryToken: window.JetSmart.Cug2AppContext.antiForgeryToken,
                      Bsid: window.JetSmart.Cug2AppContext.bsid,
                      Countries: getCountries(),
                      Country: window.JetSmart.Cug2AppContext.culture.substring(3, 5),
                      Cug2BSettings: window.JetSmart.Cug2AppContext.cug2BSettings,
                      Culture: window.JetSmart.Cug2AppContext.culture.toLowerCase(),
                      CultureSettings: window.JetSmart.Cug2AppContext.cultureSettings,
                      Currency: window.JetSmart.Cug2AppContext.currency,
                      Language: window.JetSmart.Cug2AppContext.culture.substring(0, 2).toLowerCase(),
                      PhonePrefixes: window.JetSmart.Cug2AppContext.phonePrefixes || [],
                      Type: toBoolean(window.JetSmart.Cug2AppContext.isCompany) ? "company" : "agency",
                  }
                : ({} as Cug2AppContext),
        [],
    );

    return { ...context, isFeatureSwitchActive };
};
