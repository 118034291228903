import { html } from "haunted";
import { ApiPortalSpaViewModel } from "../../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import i18next from "i18next";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { ROUTES } from "../../../shared/apiRoutes";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { classMap } from "lit-html/directives/class-map";
import { maskCurrenciesForDisplay } from "../../../shared/common";
import { ApiInitAutoTopUpCreditModel } from "../../../component-models/payment/ApiInitAutoTopUpCredit";

export interface Props {
    model: ApiPortalSpaViewModel;
    canEditCompany: boolean;
    canTopUp: boolean;
    autoTopUpCredit?: ApiInitAutoTopUpCreditModel;
    setIsToppingUp?: (isToppingUp: boolean) => void;
}

export const useOrganizationInfoBar = (props: Props) => {
    const [userContext] = useReduxState("userContext");

    // EVENT HANDLERS

    const handleTopupClick = () => {
        if (!props.setIsToppingUp) return;
        props.setIsToppingUp(true);
    };

    // COMPONENT

    // TEMPLATES

    const topUpButtonTemplate = () => {
        const buttonClassMap = classMap({
            "rounded-primary-btn": true,
            "cug-widget-white": true,
            "narrow": true,
            "initial-amount-ribbon":
                props.autoTopUpCredit?.Amount &&
                props.autoTopUpCredit.Amount > 0 &&
                props.autoTopUpCredit.RemainingAmount > 0,
        });

        return props.canTopUp
            ? html`
                  <button class=${buttonClassMap} data-test-id=${T.CUG2_SUMMARY.PAY_BUTTON} @click=${handleTopupClick}>
                      ${i18next.t("Abonar")}
                  </button>
              `
            : "";
    };

    const agencyInitialAmountRibbonTemplate = () =>
        userContext.peruCompra.role === "none" &&
        props.autoTopUpCredit?.Amount &&
        props.autoTopUpCredit.Amount > 0 &&
        props.autoTopUpCredit.RemainingAmount > 0
            ? html`
                  <div class="cug2b-initial-amount-ribbon">
                      <span class="js-icon-covid js-cv-dollar-sign"></span
                      ><span class="font-extrabold">${props.autoTopUpCredit?.Amount}</span>
                      <span>${i18next.t("abonados")}</span>
                  </div>
              `
            : "";

    const balanceTemplate = () => {
        const balanceContainerClassMap = classMap({
            "cug2b-summary-balance-container": true,
            "initial-amount-ribbon":
                props.autoTopUpCredit?.Amount &&
                props.autoTopUpCredit.Amount > 0 &&
                props.autoTopUpCredit.RemainingAmount > 0,
        });

        return html`
            <div class=${balanceContainerClassMap}>
                <div class="cug2b-summary-balance">
                    <div class="cug2b-summary-balance-label">${i18next.t("Saldo disponible")}</div>
                    <div class="cug2b-summary-balance-amount">
                        ${maskCurrenciesForDisplay(props.model.SummaryViewModel.CurrencyCode)}
                        ${props.model.SummaryViewModel.FormattedBalance}
                    </div>
                    ${topUpButtonTemplate()}
                </div>
                ${agencyInitialAmountRibbonTemplate()}
            </div>
        `;
    };

    const mobileCompanyEditorTemplate = () =>
        props.canEditCompany
            ? html`
                  <!-- DEVNOTE Beware of the mobile / non-mobile element duplications! -->
                  <span
                      class="hidden-sm-up"
                      data-test-id=${getTestId(T.CUG2_SUMMARY.EDIT_BUTTON, { m: true })}
                      @click=${() => (window.location.href = ROUTES.PageEditAgency)}
                  >
                      <i class="js-icon-cug js-cug-edit"></i> ${i18next.t("Editar")}
                  </span>
              `
            : "";

    const desktopCompanyEditorTemplate = () =>
        props.canEditCompany
            ? html`
                  <!-- DEVNOTE Beware of the mobile / non-mobile element duplications! -->
                  <div class="cug2b-summary-edit hidden-xs">
                      <span
                          @click=${() => (window.location.href = ROUTES.PageEditAgency)}
                          data-test-id=${T.CUG2_SUMMARY.EDIT_BUTTON}
                      >
                          <i class="js-icon-cug js-cug-edit"></i> ${i18next.t("Editar")}
                      </span>
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        <!-- DEVNOTE Beware of the mobile / non-mobile element duplications! -->
        <div class="cug2b-summary-company-info-bar">
            <div class="cug2b-summary-greeting-container">
                <i class="js-icon-cug js-cug-man-and-plane"></i>
                <div class="cug2b-summary-greeting">
                    <h1 class="hidden-xs">${i18next.t("¡Hola!")}</h1>
                    <h2>${props.model.SummaryViewModel.Name}</h2>
                    <h3>${props.model.SummaryViewModel.OrgName}</h3>
                    ${mobileCompanyEditorTemplate()}
                </div>
            </div>

            ${balanceTemplate()} ${desktopCompanyEditorTemplate()}
        </div>
    `;

    return { htmlTemplate };
};
