import { useNumberFormatter } from "../../../../shared/useNumberFormatter";

export const useBaggageUtils = () => {
    const { formatNumber } = useNumberFormatter();

    // EXPORTS
    const formattedLowestNextPrice = (amount: number) =>
        amount >= 0 ? formatNumber({ amount, leadingSign: true }) : "";

    return {
        formattedLowestNextPrice,
    };
};
