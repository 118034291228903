import { html } from "haunted";
import { ApiPortalSpaViewModel } from "../../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import { useReduxState } from "../../../shared/redux/useReduxState";
import i18next from "i18next";
import { ROUTES } from "../../../shared/apiRoutes";
import classNames from "classnames";

export interface Props {
    model: ApiPortalSpaViewModel;
}

export const useOrganizationConfirmedBookingsBox = (props: Props) => {
    const [userContext] = useReduxState("userContext");

    // EVENT HANDLERS

    // COMPONENT

    // TEMPLATES

    const htmlTemplate = () => html`
        <div
            class=${classNames("cug2b-summary-data", {
                "peru-compra": userContext.peruCompra.role !== "none",
            })}
            @click=${() => (window.location.href = ROUTES.Cug2BReservationsPage)}
        >
            <h2>${i18next.t("Reservas Confirmadas")}</h2>
            <ac-tooltip
                .icon=${"?"}
                .tooltip=${i18next.t("CUG-TT-ReservationsTip")}
                onclick="event.stopPropagation();"
            ></ac-tooltip>
            <span class="cug2b-summary-data-number">${props.model.SummaryViewModel.ConfirmedReservationsCount}</span>
        </div>
    `;

    return { htmlTemplate };
};
