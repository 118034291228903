import { html } from "lit-html";
import { PerJourneyPerPaxPassengerDto } from "./per-journey-per-pax-passenger";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import classNames from "classnames";
import i18next from "i18next";
import { getPassengerName } from "../../../../shared/common";
import { baggageErrorClass, baggagePerJourneyErrorClass } from "../useBaggagePage";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { useQuantityChanger } from "./useQuantityChanger";
import { useFreeOptionCell } from "./useFreeOptionCell";
import { usePaidOptionCellInfo } from "./usePaidOptionCellInfo";
import { usePaidOptionCell } from "./usePaidOptionCell";

export interface Props {
    canAdd: boolean;
    dto: PerJourneyPerPaxPassengerDto;
    hasSelected: boolean;
    isOpen: boolean;
    paxTypeLabel: string;
    showError: boolean;
    onAdd: (e: MouseEvent) => void;
    onRemove: (e: MouseEvent) => void;
    onRemoveAll: (e: MouseEvent) => void;
}

export const useMobileBaggagePassenger = (props: Props) => {
    const [passengerNames] = useReduxState("booking.passengerNames");

    const quantityChanger = useQuantityChanger({
        canAdd: props.canAdd,
        dto: props.dto,
        isMobile: true,
        onRemove: props.onRemove,
        onAdd: props.onAdd,
    });

    const freeCell = useFreeOptionCell({ canAdd: props.canAdd, dto: props.dto, onRemoveAll: props.onRemoveAll });

    const paidCellInfo = usePaidOptionCellInfo({ dto: props.dto });

    const paidCell = usePaidOptionCell({ canAdd: props.canAdd, dto: props.dto, onAdd: props.onAdd });

    const handlePaxClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (
            props.dto.baggageSection.sectionUi.uiState.journeys[props.dto.journey.index].passengers[
                props.dto.passenger.index
            ].isOpen
        ) {
            props.dto.baggageSection.sectionUi.closePax({
                journeyIndices: [props.dto.journey.index],
                paxIndices: [props.dto.passenger.index],
            });
        } else {
            props.dto.baggageSection.sectionUi.openPax({
                journeyIndices: [props.dto.journey.index],
                paxIndices: [props.dto.passenger.index],
            });
        }
    };

    const mobileSelectedPaxTemplate = () => {
        const paxClassMap = classNames("b2m-pax-opener selected", {
            open: props.isOpen,
        });

        return html`
            <div
                class=${paxClassMap}
                data-test-id=${getTestId(T.BAGGAGE.PAX_OPENER, {
                    j: props.dto.journey.index,
                    p: props.dto.passenger.index,
                    c: props.dto.bagType,
                })}
                @click=${handlePaxClick}
            >
                <div class="b2m-pax-selected-name">${getPassengerName(passengerNames, props.dto.passenger.index)}</div>
                <div class="b2m-pax-selected-bags">
                    ${props.dto.passenger.quantity > 0
                        ? mobileSelectedPaxPaidTemplate()
                        : mobileSelectedPaxFreeTemplate()}
                    <i class="js-icon js-circle-chevron-right"></i>
                </div>
            </div>
        `;
    };

    const mobileSelectedPaxFreeTemplate = () =>
        props.dto.bagType === "cabinBaggage"
            ? html` <i class="js-icon-bag js-bag-backpack solo"></i> `
            : props.dto.bagType === "checkedBaggage"
              ? html` <i class="js-icon-bag js-bag-crossed-out-baggage"></i> `
              : html``;

    const mobileSelectedPaxPaidTemplate = () =>
        props.dto.bagType === "cabinBaggage"
            ? html`
                  <i class="js-icon-bag js-bag-backpack"></i> <span class="b2m-plus">+</span>
                  <i class="js-icon-bag js-bag-regular-baggage"></i>
              `
            : props.dto.bagType === "checkedBaggage"
              ? html` <i class="js-icon-bag js-bag-zipper-baggage"></i> `
              : html` <i class="js-icon-bag js-bag-guitar-surf-golf"></i> `;

    const mobileNonSelectedPaxTemplate = () => {
        const paxClassMap = classNames("b2m-pax-opener", {
            open: props.isOpen,
        });

        return html`
            <div
                class=${paxClassMap}
                data-test-id=${getTestId(T.BAGGAGE.PAX_OPENER, {
                    j: props.dto.journey.index,
                    p: props.dto.passenger.index,
                    c: props.dto.bagType,
                })}
                @click=${handlePaxClick}
            >
                <div class="b2m-pax-type">${props.paxTypeLabel}</div>
                <div class="b2m-pax-name">
                    ${getPassengerName(passengerNames, props.dto.passenger.index)}
                    <i class="js-icon js-circle-chevron-right"></i>
                </div>
                ${props.isOpen ? mobileValidationTemplate() : ""}
            </div>
        `;
    };

    const mobileValidationTemplate = () =>
        props.showError
            ? html`
                  <div
                      class=${classNames(baggageErrorClass, baggagePerJourneyErrorClass)}
                      data-test-id=${getTestId(T.BAGGAGE.ERROR_MESSAGE, {
                          m: true,
                          c: props.dto.bagType,
                          j: props.dto.journey.index,
                          p: props.dto.passenger.index,
                      })}
                  >
                      ${i18next.t("Antes de continuar, debes seleccionar tu equipaje")}
                  </div>
              `
            : "";

    const mobilePaxOpenerTemplate = () =>
        props.hasSelected ? mobileSelectedPaxTemplate() : mobileNonSelectedPaxTemplate();

    const mobilePaxFreeCellTemplate = () => html`
        <div class="b2m-free-cell relative">
            ${mobileFreeIncludedTemplate()}
            <div class="b2-pax-option">${freeCell.htmlTemplate()}</div>
        </div>
    `;

    // DEVNOTE: JET-9259, This is a temporary solution (<div class="b2-pax-option-info opacity-0">.</div>), please refactor this later
    const mobileFreeIncludedTemplate = () =>
        props.dto.bagType === "cabinBaggage"
            ? html` <div class="b2-pax-option-info">${i18next.t("Incluido con tu tarifa")}</div> `
            : props.dto.bagType === "checkedBaggage"
              ? html` <div class="b2-pax-option-info opacity-0">.</div> `
              : "";

    const mobilePaxPaidCellTemplate = () => html`
        <div class=${classNames("b2m-paid-cell", { "b2-sold-out": !props.canAdd })}>
            ${paidCellInfo.htmlTemplate()}
            <div class="b2-pax-option">${paidCell.htmlTemplate()}</div>
            ${quantityChanger.htmlTemplate()}
        </div>
    `;

    const mobilePaxContentTemplate = () => html`
        <div class="b2m-pax-content">${mobilePaxFreeCellTemplate()} ${mobilePaxPaidCellTemplate()}</div>
    `;

    const htmlTemplate = () => html`
        <div
            class="b2m-pax-grid hidden-sm-up"
            data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_PASSENGER, {
                c: props.dto.bagType,
                j: props.dto.journey.index,
                p: props.dto.passenger.index,
                m: true,
            })}
        >
            ${mobilePaxOpenerTemplate()} ${mobilePaxContentTemplate()}
        </div>
    `;
    return { htmlTemplate };
};
