import i18next from "i18next";
import { InputElement } from "../useForm";
import { InputFieldAttribute } from "./../InputFieldAttribute";
import { html } from "lit-html";
import { frequentFlyerNumberValidator } from "../../../../validator/frequentFlyerNumberValidator";
import { ContactPassenger } from "../../../../component-models/itinerary/ContactPassenger";

export const frequentFlyerNumberUniqueness = (contactPassengers: ContactPassenger[]): InputFieldAttribute => ({
    name: "frequent-flyer-uniqueness",
    validators: [
        {
            errorMessage: {
                id: "frequent-flyer-uniqueness",
                message: html` <span> ${i18next.t("Passengers-UniquenessError")} </span> `,
                scope: "field",
            },

            validate: (field: InputElement) => {
                const frequenFlyerNumbers = contactPassengers.map((cp) => cp.FrequentFlyer);

                const isFrequentFlyerNumberInvalid = !field.value || !frequentFlyerNumberValidator().validateFrequentFlyerNumber(field.value);

                const result = isFrequentFlyerNumberInvalid || frequenFlyerNumbers.filter((num) => num === field.value).length <= 1;

                return Promise.resolve(result);
            },
        },
    ],
});
