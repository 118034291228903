import { html, useRef } from "haunted";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import i18next from "i18next";
import { useState } from "../../../shared/haunted/CustomHooks";
import { ref } from "../../../directives/ref";
import { ROUTES } from "../../../shared/apiRoutes";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { handleCugLoader } from "../../../shared/common";
import { sanitizeInputFieldValue } from "../../../component-helpers/InputSanitizerHelper";
import { TravelPartnerHelper } from "../../../component-helpers/TravelPartnerHelper";
import { CUG_GROUP_NAME_MAX_LENGTH, URL_VARS } from "../../../shared/commonConstants";
import { GroupNameVm } from "./useCugAddTravelGroupPage";
import { Validation } from "../../../validator/Validation";
import { useFluentValidator } from "../../../validator/FluentValidator";
import classNames from "classnames";
import { useErrorMessage } from "../../ui/error-message/useErrorMessage";

export const useCugTravelGroupsPage = () => {
    const root = useRef<HTMLDivElement>(undefined);

    const { getTravelPartnerInfo, postTravelPartnerInfo } = useAjax();

    const [travelPartnerInfo, setTravelPartnerInfo] = useState<TravelPartnerInfo>(undefined);
    const [groupNameVm, setGroupNameVm] = useState<GroupNameVm>({ Name: "" });
    const [isValidated, setIsValidated] = useState<boolean>(undefined);

    // HELPERS

    const init = () => {
        const doIt = async () => {
        const result = await getTravelPartnerInfo();
        setTravelPartnerInfo(result);
        };

        void doIt();
    };

    // EVENT HANDLERS

    const handleCreateGroup = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setIsValidated(true);

        const isValid = await validator.validate();

        if (!isValid) return;

        const newTravelPartnerInfo = TravelPartnerHelper.updateGroup(travelPartnerInfo, {
            Id: undefined,
            Name: groupNameVm.Name,
        });

        handleCugLoader(root.current, "loadData");

        await postTravelPartnerInfo(newTravelPartnerInfo);

        const searchExpression = `${URL_VARS.FFLYER_GROUP_NAME}=${encodeURIComponent(groupNameVm.Name)}`;
        window.location.href = `${ROUTES.Cug2BAddGroupPage}?${searchExpression}`;
    };

    // COMPONENT

    const validator = useFluentValidator<keyof GroupNameVm, GroupNameVm>({
        vm: groupNameVm,
        validated: isValidated,
        validations: [
            Validation.ruleFor("Name", (vm: GroupNameVm) => vm.Name)
                .isRequired(i18next.t("Este campo es requerido."))
                .fulfils(
                    () =>
                        Promise.resolve(
                            !travelPartnerInfo.Groups.some(
                                (g) => g.Name.toLowerCase() === groupNameVm.Name.toLowerCase(),
                            ),
                        ),
                    i18next.t("Este nombre de grupo ya existe."),
                    "form",
                ),
        ],
    });

    const formErrors = useErrorMessage({ errorMessage: validator.getFormMessages() });

    // TEMPLATES

    const staticStuffTemplate = () => html`
        <div class="travel-partner-breadcrumb-container">
            <div
                class="travel-partner-breadcrumb past"
                @click=${() => (window.location.href = ROUTES.Cug2BTravelPartnersPage)}
            >
                ${i18next.t("Pasajeros frecuentes")}
            </div>
            <div class="travel-partner-breadcrumb divider">/</div>
            <div class="travel-partner-breadcrumb">${i18next.t("Grupos")}</div>
        </div>
        <div class="travel-partner-hero">
            <h1>${i18next.t("Grupos")}</h1>
            <div>
                ${i18next.t(
                    "En ésta sección podrás agrupar tus pasajeros frecuentes como tu quieras. Además puedes crear nuevos grupos, editarlos, agregar pasajeros a un grupo existente y ¡mucho más!",
                )}
            </div>
        </div>
        <div class="travel-partner-title">
            <i class="js-icon-cug js-cug-grouping"></i>
            <h2>${i18next.t("Crear un Grupo")}</h2>
        </div>
    `;

    const addNewGroupTemplate = () => html`
        <div class="travel-partner-input-and-button">
            <div class="flex flex-col items-center sm:flex-row">
                <div class="w-full py-[15px]">
                    <ac-input
                        .errorMessage=${validator.getMessage("Name")}
                        .isInvalid=${!validator.isValid("Name")}
                        .label=${i18next.t("Nombre del grupo")}
                        .maxLength=${CUG_GROUP_NAME_MAX_LENGTH}
                        .sanitizer=${(e: KeyboardEvent) => sanitizeInputFieldValue(e, "alphanumeric")}
                        .value=${groupNameVm.Name}
                        .onInput=${(value: string) => setGroupNameVm({ Name: value })}
                    ></ac-input>
                </div>
                <div class="flex items-center">
                    <button
                        class=${classNames("rounded-primary-btn", { disabled: !groupNameVm.Name })}
                        @click=${handleCreateGroup}
                    >
                        ${i18next.t("Crear grupo")}
                    </button>
                </div>
            </div>
            ${formErrors.htmlTemplate()}
        </div>
    `;

    const backButtonTemplate = () => html`
        <div class="travel-partner-back-btn-container" @click=${() => window.history.back()}>
            <i class="js-icon js-circle-chevron-right"></i>
            <span>${i18next.t("Volver")}</span>
        </div>
    `;

    const htmlTemplate = () =>
        travelPartnerInfo
            ? html`
                  <div ref=${ref(root)}>
                      ${staticStuffTemplate()}
                      <form class="travel-partner-form">${addNewGroupTemplate()}</form>
                      <div class="travel-partner-title pull-up">
                          <h2>${i18next.t("Administrar grupos")}</h2>
                      </div>
                      <div class="travel-partner-text-and-button">
                          <div>
                              ${i18next.t(
                                  "Aquí podrás editar tus grupos existentes, modificar los pasajeros asignados a cada grupo, agregar o eliminar pasajeros y eliminar grupos. ",
                              )}
                          </div>
                          <button
                              class="rounded-primary-btn"
                              @click=${() => (window.location.href = ROUTES.Cug2BAdminTravelGroupsPage)}
                          >
                              ${i18next.t("Ver Grupos")}
                          </button>
                      </div>
                      <div class="hidden-xs mt-16 flex w-full justify-end sm:mt-32">${backButtonTemplate()}</div>
                  </div>
              `
            : html``;

    return { init, htmlTemplate };
};
