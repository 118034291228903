import { html } from "haunted";
import { COLOMBIAN_CULTURE_CODE, ECUADORIAN_CULTURE_CODE } from "../../../shared/commonConstants";
import { useAppContext } from "../../../managers/useAppContext";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { FlightItineraryModel } from "../../../component-models/flight/FlightItineraryModel";
import { getLegDetails } from "../../../component-mappers/MultilegFlightLegInfoMapper";
import * as dayjs from "dayjs";
import i18next from "i18next";
import classNames from "classnames";
import { useFlightTooltip } from "../../../common-templates/useFlightTooltipTemplate";
import { useMemo } from "../../../shared/haunted/CustomHooks";

export interface Props {
    fillSpace?: boolean;
    isBrazilianStationSelected: boolean;
    isBundleSelected: boolean;
    isForCheckin: boolean;
    isInFlightMove: boolean;
    itineraryData: FlightItineraryModel;
}

export function useFlightItineraryInfo(props: Props) {
    const appContext = useAppContext();

    const isANACInfoAvailable = useMemo(
        () =>
            props.itineraryData.PunctualityPerformance?.PercentOfCancellations ||
            props.itineraryData.PunctualityPerformance?.PercentOfDelaysGreaterThan30Min ||
            props.itineraryData.PunctualityPerformance?.PercentOfDelaysGreaterThan60Min,
        [props.itineraryData.PunctualityPerformance],
    );

    const flightTooltip = useFlightTooltip({
        flightIndex: props.itineraryData.FlightIndex,
        flightNumber: props.itineraryData.FlightNumber,
        hours: props.itineraryData.Hours,
        journeyIndex: props.itineraryData.JourneyIndex,
        legDetails: props.isForCheckin
            ? props.itineraryData.Segments[0].Legs.map((leg, i) =>
                  getLegDetails(props.itineraryData.Segments[0], leg, i),
              )
            : props.itineraryData.LegDetails,
        minutes: props.itineraryData.Minutes,
        segments: props.itineraryData.Segments,
        showAnacTooltip: props.isBrazilianStationSelected && !isANACInfoAvailable,
    });

    // HELPERS

    const culturesWithAirportNames = [COLOMBIAN_CULTURE_CODE, ECUADORIAN_CULTURE_CODE];

    const isNextDay = () =>
        !dayjs(props.itineraryData.DepartureDate).isSame(dayjs(props.itineraryData.ArrivalDate), "day");

    // TEMPLATES
    const airportNameTemplate = (airportName: string, side: "left" | "right") =>
        culturesWithAirportNames.includes(appContext.Culture) && !props.isForCheckin
            ? html`
                  <span
                      class=${classNames("itinerary-airport-name", {
                          right: side === "right" && props.fillSpace,
                          left: side === "left" && props.fillSpace,
                      })}
                      >${airportName}</span
                  >
              `
            : "";

    const oneMoreDayTemplate = () => {
        const oneMoreDayClassMap = classNames("one-more-day", {
            "per-leg-view": props.isBundleSelected,
            "in-checkin": props.isForCheckin,
        });

        return isNextDay() ? html` <span class=${oneMoreDayClassMap}>+1</span> ` : "";
    };

    const estimatedTimeTemplate = (estimatedTime: string) =>
        props.isForCheckin && estimatedTime
            ? html`
                  <div class="itinerary-new-time-container">
                      <span class="itinerary-new-time-text">${i18next.t("Etd-NewTime")}</span>
                      <div class="itinerary-new-time">
                          <i class="far fa-clock flight-itinerary-clock-icon"></i> <span>${estimatedTime}</span>
                      </div>
                  </div>
              `
            : "";

    const originTemplate = () => {
        const originClassMap = classNames("itinerary-station", {
            "items-start": props.fillSpace,
            "checkin-itinerary-station": props.isForCheckin,
            "crossed-time": props.isForCheckin && props.itineraryData.EstimatedDepartureTime,
        });

        const originTimeClassMap = classNames("itinerary-flight-time", {
            "crossed-time": props.isForCheckin && props.itineraryData.EstimatedDepartureTime,
        });

        return html`
            <div
                class=${originClassMap}
                data-test-id=${getTestId(T.FLIGHT.FLIGHT_INFO_ORIGIN, {
                    j: props.itineraryData.JourneyIndex,
                })}
            >
                <div class=${originTimeClassMap}>${props.itineraryData.DepartureTime}</div>
                <div class=${"itinerary-station-info"}>
                    <span class=${classNames("itinerary-station-name", { left: props.fillSpace })}>
                        ${props.itineraryData.OriginCity}
                    </span>
                    ${airportNameTemplate(props.itineraryData.OriginAirport, "left")}
                    ${estimatedTimeTemplate(props.itineraryData.EstimatedDepartureTime)}
                </div>
            </div>
        `;
    };

    const destinationTemplate = () => {
        const destinationClassMap = classNames("itinerary-station", {
            "items-end": props.fillSpace,
            "checkin-itinerary-station": props.isForCheckin,
        });

        const destinationTimeClassMap = classNames("itinerary-flight-time", {
            "with-one-more-day": isNextDay(),
            "crossed-time": props.isForCheckin && props.itineraryData.EstimatedArrivalTime,
        });

        return html`
            <div
                class=${destinationClassMap}
                data-test-id=${getTestId(T.FLIGHT.FLIGHT_INFO_DESTINATION, {
                    j: props.itineraryData.JourneyIndex,
                })}
            >
                <div class=${destinationTimeClassMap}>${props.itineraryData.ArrivalTime} ${oneMoreDayTemplate()}</div>
                <div class=${"itinerary-station-info"}>
                    <span class=${classNames("itinerary-station-name", { right: props.fillSpace })}>
                        ${props.itineraryData.DestinationCity}
                    </span>
                    ${airportNameTemplate(props.itineraryData.DestinationAirport, "right")}
                    ${estimatedTimeTemplate(props.itineraryData.EstimatedArrivalTime)}
                </div>
            </div>
        `;
    };

    const separatorTemplate = () => {
        const separatorClassMap = classNames("itinerary-separator", {
            "flex-1": props.fillSpace,
            "flight-move-separator": props.isInFlightMove,
        });

        const dividerClassMap = classNames({
            "flex-1": props.fillSpace,
            "itinerary-info-divider": true,
            "checkin-divider": props.isForCheckin,
            "flight-move-divider": props.isInFlightMove,
        });

        return html`
            <div class=${separatorClassMap}>
                <i class="js-icon js-flight-plane itinerary-info-plane-icon hidden-xs"></i>

                <div class=${dividerClassMap}></div>

                ${flightTooltip.htmlTemplate()}

                <div class=${dividerClassMap}></div>
            </div>
        `;
    };

    const htmlTemplate = () => {
        const containerClassMap = classNames({
            "itinerary-flight-info-container": true,
            "fill-space": props.fillSpace,
            "in-flight-move": props.isInFlightMove,
            "with-more-space":
                props.isForCheckin &&
                (props.itineraryData.EstimatedDepartureTime || props.itineraryData.EstimatedArrivalTime),
        });

        return html`
            <div class=${classNames({ "fligh-info-wrapper": !props.fillSpace })}>
                <div class=${containerClassMap}>
                    ${originTemplate()} ${separatorTemplate()} ${destinationTemplate()}
                </div>
            </div>
        `;
    };

    return { htmlTemplate };
}
