import { AppContext } from "../component-models/app/AppContext";
import { useMemo } from "../shared/haunted/CustomHooks";
import { FeatureSwitch } from "../component-models/app/FeatureSwitches";
import { FAKE_COUNTRY_CODE } from "../shared/commonConstants";

export const useAppContext = () => {
    const isFeatureSwitchActive = (featureSwitch: FeatureSwitch) => {
        return window.JetSmart.FeatureSwitch.some((fs) => fs.Name === featureSwitch && fs.IsActive);
    };

    const getCountries = () =>
        window.JetSmart.AppContext.countries?.filter((country) => country.Value !== FAKE_COUNTRY_CODE) || [];

    const getPhonePrefixes = () =>
        window.JetSmart.AppContext.phonePrefixes.filter((pp) => pp.CountryCode !== FAKE_COUNTRY_CODE) || [];

    const context: AppContext = useMemo(
        () =>
            window.JetSmart.AppContext
                ? {
                      AbTestSettings: window.JetSmart.AppContext.abTestSettings,
                      AntiForgeryToken: window.JetSmart.AppContext.antiForgeryToken,
                      BancoEstadoBins: window.JetSmart.AppContext.bancoEstadoBins?.split("|") || [],
                      CorporateBaseUrl: window.JetSmart.AppContext.corporateBaseUrl,
                      Countries: getCountries(),
                      Country: window.JetSmart.AppContext.culture.substring(3, 5),
                      Cug2BSettings: window.JetSmart.AppContext.cug2BSettings,
                      Culture: window.JetSmart.AppContext.culture.toLowerCase(),
                      CultureSettings: window.JetSmart.AppContext.cultureSettings,
                      IbeRelease: window.JetSmart.AppContext.ibeRelease,
                      Language: window.JetSmart.AppContext.culture.substring(0, 2).toLowerCase(),
                      LocalCurrencyDisplaySettings: window.JetSmart.AppContext.localCurrencyDisplaySettings,
                      PhonePrefixes: getPhonePrefixes(),
                      variants: window.JetSmart.AppContext.variants || [],
                  }
                : ({} as AppContext),
        [],
    );

    return { ...context, isFeatureSwitchActive };
};
