import { html } from "lit-html";
import { PerJourneyPerPaxPassengerDto } from "./per-journey-per-pax-passenger";
import { getInputId, getInputName } from "../../../../component-helpers/BaggageHelper";
import classNames from "classnames";
import i18next from "i18next";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";

export interface Props {
    canAdd: boolean;
    dto: PerJourneyPerPaxPassengerDto;
    onAdd: (e: MouseEvent) => void;
}

export const usePaidOptionCell = (props: Props) => {
    const id = getInputId(
        props.dto.bagType,
        props.dto.journey.index,
        props.dto.passenger.index,
        props.dto.isMobile,
        false,
    );
    const inputName = getInputName(
        props.dto.bagType,
        props.dto.journey.index,
        props.dto.passenger.index,
        props.dto.isMobile,
    );

    const paidBagName =
        props.dto.bagType === "cabinBaggage"
            ? i18next.t("Mochila o artículo personal y Equipaje de mano")
            : props.dto.bagType === "checkedBaggage"
              ? i18next.t("Equipaje facturado")
              : i18next.t("Equipaje sobredimensionado");

    const htmlTemplate = () => html`
        <input
            type="radio"
            name=${inputName}
            id=${id}
            ?disabled=${!props.canAdd}
            ?checked=${props.dto.passenger.quantity > 0}
            .checked=${props.dto.passenger.quantity > 0}
        />
        <label
            for=${id}
            class=${classNames({ disabled: !props.canAdd, checked: props.dto.passenger.quantity > 0 })}
            data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_ADD_FIRST_BUTTON, {
                c: props.dto.bagType,
                j: props.dto.journey.index,
                m: props.dto.isMobile,
                p: props.dto.passenger.index,
            })}
            @click=${props.onAdd}
        >
            ${paidBagName}
        </label>
    `;

    return { htmlTemplate };
};
