import { useEffect } from "haunted";
import { html, TemplateResult } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { ModalCloseEvent } from "../../component-models/CUG2b/Cug2PortalEvents";
import { CLASS_NAMES } from "../../shared/classNames";
import { isMobile } from "../../shared/common";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { ScrollHelper } from "../../shared/ScrollHelper";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";

export const name = "ac-cug-modal";

export interface Properties {
    canBeClosed: boolean;
    content: TemplateResult;
    customClass: string | string[];
    header: TemplateResult;
    isOpen: boolean;
    showDecor: boolean;
}

const modalContentClassName = "ts-cug2b-modal-scroller";

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        canBeClosed: host.canBeClosed,
        content: host.content,
        customClass: host.customClass,
        header: host.header,
        isOpen: host.isOpen,
        showDecor: host.showDecor,
    };

    // HELPERS

    const addScroller = () => {
        window.removeEventListener("resize", addScroller);

        if (!window.acPerfectScrollbars) {
            window.acPerfectScrollbars = {};
        }

        window.acPerfectScrollbars[name]?.forEach((scroller) => scroller.destroy());

        if (props.isOpen) {
            document.body.classList.add(CLASS_NAMES.cugNoScroll);
            document.getElementsByTagName("html")[0].classList.add(CLASS_NAMES.cugNoScroll);
            if (!isMobile()) {
                window.acPerfectScrollbars[name] = ScrollHelper.addPerfectScrollbar(modalContentClassName);
            }
        } else {
            document.body.classList.remove(CLASS_NAMES.cugNoScroll);
            document.getElementsByTagName("html")[0].classList.remove(CLASS_NAMES.cugNoScroll);
        }

        window.addEventListener("resize", addScroller);
    };

    // COMPONENT

    useEffect(() => {
        addScroller();
    }, [props.isOpen]);

    // EVENT LISTENERS

    const handleClose = () => {
        if (!props.canBeClosed) {
            return;
        }

        host.dispatchEvent(new ModalCloseEvent());
    };

    // TEMPLATES

    const closerTemplate = () =>
        props.canBeClosed
            ? html`
                  <button
                      class="cug2b-modal-closer"
                      data-test-id=${T.CUG2_COMMON.MODAL_CLOSE_BUTTON}
                      @click=${handleClose}
                  >
                      &times;
                  </button>
              `
            : "";

    const modalClassMap = () => {
        const classes = Array.isArray(props.customClass) ? props.customClass : [props.customClass];

        const retVal = classes
            .filter((e) => e)
            .reduce(
                (previousValue, currentValue) => {
                    previousValue[currentValue] = true;
                    return previousValue;
                },
                { "cug2b-modal": true } as any,
            );

        return classMap(retVal);
    };

    return props.isOpen
        ? html`
              <div class="cug2b-modal-layover">
                  <div class=${modalClassMap()}>
                      ${closerTemplate()}
                      <div class="cug2b-modal-scroller ${modalContentClassName}">
                          <header class="cug2b-modal-header">${props.header}</header>
                          <div class="cug2b-modal-content ${props.showDecor ? "cug2b-useless-modal-decor" : ""}">
                              ${props.content}
                          </div>
                      </div>
                  </div>
              </div>
          `
        : html``;
};
