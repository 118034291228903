import { TemplateResult, html } from "lit-html";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { usePaymentPage } from "../spa/payment/usePaymentPage";
import { hideLoader, showLoader } from "../../shared/common";

export const name = "ac-payment-page";

export const Component: HauntedFunc<{}> = () => {
    const paymentPage = usePaymentPage();

    const [loader, setLoader] = useState<JsLoader>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const init = () => {
        const doIt = async () => {
            setIsLoading(true);

            if ("scrollRestoration" in history) history.scrollRestoration = "manual";
            await paymentPage.build();
            setIsLoading(false);
        };

        void doIt();
    };

    useEffect(() => {
        if (isLoading) {
            setLoader(showLoader({}));
        } else {
            hideLoader(loader);
            setLoader(undefined);
        }
    }, [isLoading]);

    useEffect(init, []);

    return isLoading
        ? html`
              <div id="mainContentPayment" data-test-id="payment-page">
                  <div id="mainContentWrapper">
                      <section class="booking-wrapper">
                          <div class="inner-box" style="min-height: 100vh;"></div>
                      </section>
                  </div>
              </div>
          `
        : (paymentPage.htmlTemplate() as TemplateResult);
};
