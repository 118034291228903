import i18next from "i18next";
import { html } from "haunted";
import { useModal } from "../../shared/useModal";
import { ItineraryPageViewModel } from "../../../component-models/itinerary/ItineraryPageViewModel";

interface Props {
    model: ItineraryPageViewModel;
}

export const useResendItineraryReportModal = (props: Props) => {
    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="flex h-full w-full flex-col items-center">
                <div class="mb-[6px] text-xl font-bold text-brand-secondary md:text-2xl/7">
                    ${i18next.t("Correo enviado con éxito")}
                </div>
                <div class="text-center text-lg/5">
                    <div>
                        ${i18next.t("Se ha enviado la confirmación de itinerario al mail")}
                        <span class="ml-1 font-semibold underline">${props.model.ItineraryModelV2.UserEmail}</span>
                    </div>
                </div>
                <button
                    class="rounded-secondary-btn mb-[10px] mt-5 h-full sm:mb-0 sm:mt-[25px]"
                    @click=${() => {
                        modal.close();
                    }}
                >
                    ${i18next.t("Aceptar")}
                </button>
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { onClose: () => modal.hide(), isClosable: true },
        content: {
            classNames: "booking-modal-content resend-itinerary-report-modal-content",
            template: htmlTemplate,
        },
        header: {
            template: () => html`<i class="js-new js-n-email-with-tick"></i>`,
        },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};
