import i18next from "i18next";
import { html } from "lit-html";
import { DeletionMode } from "./useCugAdminTravelGroupsPage";
import classNames from "classnames";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import { TravelPartnerHelper } from "../../../component-helpers/TravelPartnerHelper";

interface Props {
    deletionMode: DeletionMode;
    travelPartnerInfo: TravelPartnerInfo;
    groupsToDelete: number[];
    setDeletionMode: (deletionMode: DeletionMode) => void;
    handleModalClose: () => void;
    handleDeleteGroups: () => Promise<void>;
}

export const useGroupDeletionConfirmationModal = (props: Props) => {
    // TEMPLATES

    const headerTemplate = () => html` <span>${i18next.t("¿Estás seguro que quieres eliminar este grupo?")}</span> `;

    const warningTemplate = () =>
        props.deletionMode === "groupsAndPartners"
            ? i18next.t(
                  "Esta grupo se eliminará de forma permanente incluyendo los pasajeros que son parte de él y no podrás volver atrás.",
              )
            : i18next.t("Este grupo se eliminará de forma permanente y no podrás volver atrás.");

    const groupsToDeleteTemplate = () =>
        props.groupsToDelete?.map((id) => {
            const group = props.travelPartnerInfo.Groups.find((g) => g.Id === id);

            return group
                ? html`
                      <div class="cug2b-are-you-sure-modal-group-list-item">
                          <h1>${group.Name}</h1>
                          <h2>
                              ${i18next.t("Cantidad de pasajeros")}:
                              <span class="font-bold">
                                  ${TravelPartnerHelper.getNumberOfPartners(props.travelPartnerInfo, id)}
                              </span>
                          </h2>
                      </div>
                  `
                : "";
        });

    const contentTemplate = () => html`
        <div class="cug2b-are-you-sure-modal-group-delete">
            <div
                class=${classNames("cug2b-are-you-sure-modal-group-list", {
                    "solo-item": props.groupsToDelete?.length === 1,
                })}
            >
                ${groupsToDeleteTemplate()}
            </div>
            <div class="cug2b-are-you-sure-modal-group-list-info">${warningTemplate()}</div>
            <div class="mt-4 flex flex-col items-center justify-center sm:mt-8 sm:flex-row">
                <button
                    class="rounded-secondary-btn with-arrow cug2b-white mb-4 sm:mb-0 sm:mr-4"
                    @click=${props.handleModalClose}
                >
                    ${i18next.t("No, no quiero eliminarlo")}
                </button>
                <button class="rounded-primary-btn" @click=${props.handleDeleteGroups}>
                    ${i18next.t("Si, eliminar grupo")}
                </button>
            </div>
        </div>
    `;

    const htmlTemplate = () =>
        props.groupsToDelete?.length > 0
            ? html`
                  <ac-cug-modal
                      .canBeClosed=${true}
                      .content=${contentTemplate()}
                      .customClass=${"cug2b-are-you-sure-group-modal"}
                      .header=${headerTemplate()}
                      .isOpen=${props.groupsToDelete && props.deletionMode !== "none"}
                      @close=${props.handleModalClose}
                  ></ac-cug-modal>
              `
            : "";

    return { htmlTemplate };
};
