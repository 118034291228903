import { html, useEffect, useRef } from "haunted";
import i18next from "i18next";
import { useState } from "../../../../shared/haunted/CustomHooks";
import { TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { useBasicCheckbox } from "../../../ui/basic-checkbox/useBasicCheckbox";
import { MaterialLabelElement } from "../../../../shared/common";

export const passengerListScrollerClassName = "ts-new-insurance-passenger-list-container";

export type InsuranceTermsToShow = "baggage" | "travel";

export interface Props {
    insuranceTermsToShow: InsuranceTermsToShow[];
    isValidated: boolean;
    handleBaggageTermsClick: () => void;
    handleTermsClick: () => void;
}

export const useInsuranceTerms = (props: Props) => {
    // HELPERS

    // EVENT HANDLERS

    const handleTermsClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.handleTermsClick();
    };

    const handleBaggageTermsClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.handleBaggageTermsClick();
    };

    const handleTermsCheckboxClick = () => {
        setIsPaxInsuranceTermsChecked(!isPaxInsuranceTermsChecked);
    };

    // COMPONENT

    const termsCheckbox = useRef<MaterialLabelElement>(undefined);
    const [isPaxInsuranceTermsChecked, setIsPaxInsuranceTermsChecked] = useState<boolean>(false);

    useEffect(() => {
        if (props.insuranceTermsToShow.length === 0) {
            setIsPaxInsuranceTermsChecked(false);
        }
    }, [props.insuranceTermsToShow.length]);

    useEffect(() => {
        if (isPaxInsuranceTermsChecked) {
            termsCheckbox.current?.MaterialCheckbox?.check();
        } else {
            termsCheckbox.current?.MaterialCheckbox?.uncheck();
        }
    }, [isPaxInsuranceTermsChecked]);

    const labelTemplate = (id: string) => html`
        <label
            for=${id}
            class="insurance-accept-checkbox-label"
            data-test-id=${T.EXTRAS_TRAVEL_INSURANCE.ACCEPT_CHECKBOX}
        >
            <div>
                <span class="cb-title"> ${i18next.t("Acepto Términos y Condiciones")} ${termsLinksTemplate()} </span>
            </div>
        </label>
    `;

    const acceptTermsCheckbox = useBasicCheckbox({
        isChecked: isPaxInsuranceTermsChecked,
        labelTemplate,
        onClick: handleTermsCheckboxClick,
    });

    // TEMPLATES

    const termsErrorTemplate = () =>
        !isPaxInsuranceTermsChecked && props.isValidated
            ? html`
                  <div class="error-message-container">
                      <div class="form-error-message">
                          ${i18next.t("Por favor acepta Términos y Condiciones antes de continuar.")}
                      </div>
                  </div>
              `
            : "";

    const termsLinksTemplate = () => html`
        ${props.insuranceTermsToShow.includes("travel")
            ? html` <a @click=${handleTermsClick} target="_blank">${i18next.t("Seguro de Viaje")}</a> `
            : ""}
        ${props.insuranceTermsToShow.length === 2 ? html` &nbsp;/&nbsp; ` : ""}
        ${props.insuranceTermsToShow.includes("baggage")
            ? html` <a @click=${handleBaggageTermsClick} target="_blank">${i18next.t("Seguro de Equipaje")}</a> `
            : ""}
    `;

    const termsTemplate = () => html`
        <div class="insurance-terms-container mt-4 flex w-full justify-end">${acceptTermsCheckbox.htmlTemplate()}</div>
    `;

    const htmlTemplate = () => html`
        <div
            class="insurance-content-container passenger-data-container ts-error-parent ${passengerListScrollerClassName}"
            data-test-id=${T.EXTRAS.NEW_INSURANCE_PASSENGER_LIST_CONTAINER}
        >
            ${termsTemplate()} ${termsErrorTemplate()}
        </div>
    `;
    return { htmlTemplate, isPaxInsuranceTermsChecked };
};
