import i18next from "i18next";
import { html } from "lit-html";
import { paxAmount, displayedBundleJourneyIndex } from "../../../../component-helpers/BaggageHelper";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { OUTBOUND } from "../../../../shared/commonConstants";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { BagType } from "../useBaggagePage";

export interface Props {
    baggageSection: BaggageSection;
    bagType: BagType;
    isMobile: boolean;
}

export const usePerJourneyPerPaxBundle = (props: Props) => {
    const isOnePax = paxAmount(props.baggageSection.sectionUi.uiState);

    const bundleText =
        props.bagType === "cabinBaggage"
            ? isOnePax
                ? i18next.t("Recuerda que tu llevas equipaje de mano porque compraste un")
                : i18next.t("Recuerda que llevan equipaje de mano porque compraron un")
            : props.bagType === "checkedBaggage"
              ? isOnePax
                  ? i18next.t("Recuerda que tu llevas equipaje facturado porque compraste un")
                  : i18next.t("Recuerda que llevan equipaje facturado porque compraron un")
              : "";

    const htmlTemplate = (journeyIndex: number) => {
        if (props.bagType === "oversizedBaggage") return "";

        const journey = props.baggageSection.journeys.find((journey) => journey.index === journeyIndex);

        const oneWayBundleDirection = displayedBundleJourneyIndex(props.baggageSection.journeys);
        const oneWayBundleText =
            journey.index === OUTBOUND ? i18next.t("para el vuelo de ida") : i18next.t("para el vuelo de vuelta");

        const imgSrc = journey.bundleImageUrl;

        return journey.bundleType !== "None"
            ? html`
                  <div
                      class="b2-baggage-bundle-per-pax-info"
                      data-test-value=${journey.bundleType}
                      data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_BUNDLE_MESSAGE, {
                          j: journey.index,
                          c: props.bagType,
                          m: props.isMobile,
                      })}
                  >
                      <i class="js-icon js-broken-circle-exclamation"></i>
                      <div>
                          <span>${bundleText}</span>
                          <img src=${imgSrc} />
                          ${oneWayBundleDirection !== undefined ? html` <span>${oneWayBundleText}</span> ` : ""}
                      </div>
                  </div>
              `
            : "";
    };

    return { htmlTemplate };
};
