import { HauntedFunc } from "../shared/haunted/HooksHelpers";
import { html, useEffect, useState } from "haunted";
import { ROUTES } from "../shared/apiRoutes";
import { usePubSub } from "../pub-sub-service/usePubSub";
import { useAjax } from "../shared/customHooks/useAjax/useAjax";

export const name = "ac-session-keep-alive";

// DEVNOTE Ping every 5 minutes
const PING_FREQUENCY_IN_MS = 300000;

export const observedAttributes: (keyof Attributes)[] = ["keep-alive-period"];

export interface Attributes {
    "keep-alive-period": string;
}

export interface Props {
    keepAlivePeriod: number;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        keepAlivePeriod: Number(host.keepAlivePeriod),
    };

    const init = () => {
        if (props.keepAlivePeriod) {
            startTimers();
        }

        return () => {
            window.clearInterval(pingTimerId);
            window.clearTimeout(timerId);
        };
    };

    const getKeepAlivePeriodInMs = () => Number(props.keepAlivePeriod) * 60000;

    const startTimers = () => {
        setTimerId(
            window.setTimeout(() => {
                window.clearInterval(timerId);
                window.clearTimeout(timerId.current);
            }, getKeepAlivePeriodInMs()),
        );

        setPingTimerId(
            window.setInterval(() => {
                void ping();
                triggers.shared.resetSessionTimer.publish({});
            }, PING_FREQUENCY_IN_MS),
        );
    };

    const ping = () => ajaxRequest({ container: document.body, method: "GET", url: ROUTES.ApiRoutes.SessionKeepAlive });

    const { triggers } = usePubSub();
    const { ajaxRequest } = useAjax();

    const [timerId, setTimerId] = useState<any>(undefined);
    const [pingTimerId, setPingTimerId] = useState<any>(undefined);

    useEffect(init, []);

    return html``;
};
