import { useCallback } from "haunted";
import { BaggageSectionJourney } from "../../../../component-models/baggage/BaggageSectionJourney";
import { useFlowContext } from "../../../../managers/useFlowContext";
import { useMemo, useState } from "../../../../shared/haunted/CustomHooks";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { BagType } from "../useBaggagePage";
import { BancoEstadoFreeBaggages } from "../../../../component-models/baggage/ApiBaggageViewModel";

interface BancoEstadoBaggagePerJourneyPerPax {
    journeyIndex: number;
    usedBaggageType: "none" | "cabin" | "checked";
}

export interface BancoEstadoBaggage {
    state: BancoEstadoBaggagePerJourneyPerPax[];
    canGetFirstBaggageBancoEstadoDiscount: (data: { bagType: BagType; journeyIndex: number }) => boolean;
    update: (data: { bagType: BagType; journeyIndices: number[]; type: "add" | "remove" | "reset" }) => void;
}

export interface Props {
    cabinBaggageJourneys: BaggageSectionJourney[];
    checkedBaggageJourneys: BaggageSectionJourney[];
    bancoEstadoFreeBaggages: BancoEstadoFreeBaggages;
}

export const useBancoEstadoBaggage = (props: Props): BancoEstadoBaggage => {
    const flowContext = useFlowContext();

    const [userContext] = useReduxState("userContext");

    const initialBancoEstadoBaggage = useMemo(
        () =>
            props.cabinBaggageJourneys.flatMap((cabinJourney): BancoEstadoBaggagePerJourneyPerPax => {
                const cabinBaggageQuantity =
                    cabinJourney.passengers.find(
                        (cabinPassenger) => cabinPassenger.min === 0 && cabinPassenger.index === 0,
                    )?.quantity ?? 0;

                const checkedBaggage = props.checkedBaggageJourneys
                    .find((checkedJourney) => checkedJourney.index === cabinJourney.index)
                    ?.passengers.find((checkedPassenger) => checkedPassenger.index === 0);

                const minCheckedBaggage = checkedBaggage?.min > 0 ? checkedBaggage.min : 0;

                const usedBaggageType =
                    cabinBaggageQuantity > 0
                        ? "cabin"
                        : (checkedBaggage?.quantity ?? 0) > minCheckedBaggage
                          ? "checked"
                          : "none";

                return { journeyIndex: cabinJourney.index, usedBaggageType };
            }),
        [],
    );

    const [state, setState] = useState<BancoEstadoBaggagePerJourneyPerPax[]>(initialBancoEstadoBaggage);

    const updateCabinItem = (
        oldItem: BancoEstadoBaggagePerJourneyPerPax,
        type: "add" | "remove" | "reset",
    ): BancoEstadoBaggagePerJourneyPerPax => {
        const checkedBaggageQuantity = props.checkedBaggageJourneys
            .find((journey) => journey.index === oldItem.journeyIndex)
            .passengers.find((pax) => pax.index === 0).quantity;

        switch (type) {
            case "add":
                return {
                    ...oldItem,
                    usedBaggageType: oldItem.usedBaggageType === "none" ? "cabin" : oldItem.usedBaggageType,
                };
            case "remove":
            case "reset":
                return {
                    ...oldItem,
                    usedBaggageType: checkedBaggageQuantity > 0 ? "checked" : "none",
                };
        }
    };

    const updateCheckedItem = (
        oldItem: BancoEstadoBaggagePerJourneyPerPax,
        type: "add" | "remove" | "reset",
    ): BancoEstadoBaggagePerJourneyPerPax => {
        const cabinBaggageQuantity = props.cabinBaggageJourneys
            .find((journey) => journey.index === oldItem.journeyIndex)
            .passengers.find((pax) => pax.index === 0).quantity;

        const checkedBaggageQuantity = props.checkedBaggageJourneys
            .find((journey) => journey.index === oldItem.journeyIndex)
            .passengers.find((pax) => pax.index === 0).quantity;

        switch (type) {
            case "add":
                return {
                    ...oldItem,
                    usedBaggageType: oldItem.usedBaggageType === "none" ? "checked" : oldItem.usedBaggageType,
                };
            case "remove":
                return {
                    ...oldItem,
                    usedBaggageType:
                        checkedBaggageQuantity > 1
                            ? oldItem.usedBaggageType
                            : cabinBaggageQuantity > 0
                              ? "cabin"
                              : "none",
                };
            case "reset":
                return { ...oldItem, usedBaggageType: cabinBaggageQuantity > 0 ? "cabin" : "none" };
        }
    };

    const update = (data: { bagType: BagType; journeyIndices: number[]; type: "add" | "remove" | "reset" }) => {
        setState(
            state.map((oldItem): BancoEstadoBaggagePerJourneyPerPax => {
                const toUpdate = data.journeyIndices.includes(oldItem.journeyIndex);

                if (!toUpdate) return oldItem;

                switch (data.bagType) {
                    case "cabinBaggage":
                        return updateCabinItem(oldItem, data.type);
                    case "checkedBaggage":
                        return updateCheckedItem(oldItem, data.type);
                    default:
                        return oldItem;
                }
            }),
        );
    };

    const canGetFirstBaggageBancoEstadoDiscount = useCallback(
        (data: { bagType: BagType; journeyIndex: number }) => {
            // TODO BEBAG Get proper categories
            if (userContext.bancoEstado.category === 0) return false;
            if (!flowContext.isBookingFlow) return false;
            if (data.bagType === "oversizedBaggage") return false;

            if (data.bagType === "cabinBaggage" && !props.bancoEstadoFreeBaggages.CabinBaggage) return false;
            if (data.bagType === "checkedBaggage" && !props.bancoEstadoFreeBaggages.CheckedBaggage) return false;

            return state.find((item) => item.journeyIndex === data.journeyIndex)?.usedBaggageType === "none";
        },
        [userContext.bancoEstado.category, flowContext.isBookingFlow, state],
    );

    return {
        state,
        canGetFirstBaggageBancoEstadoDiscount,
        update,
    };
};
