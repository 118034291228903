import i18next from "i18next";
import { html } from "haunted";
import PerfectScrollbar from "perfect-scrollbar";
import { ApiItineraryPayment } from "../../../component-models/itinerary/ApiItineraryPayment";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { ItineraryPageViewModel } from "../../../component-models/itinerary/ItineraryPageViewModel";
import { maskCurrenciesForDisplay } from "../../../shared/common";
import { useBookingContext } from "../../../managers/useBookingContext";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../shared/useNumberFormatter";
import { useMemo } from "../../../shared/haunted/CustomHooks";

export interface Props {
    model: ItineraryPageViewModel;
    scrollBar: PerfectScrollbar;
    handleOpenPaymentDetails: (index: number) => void;
}

export const useItineraryTransactionsNormalTable = (props: Props) => {
    // EVENT HANDLERS

    const handleClickOnTransaction = () => window.setTimeout(props.scrollBar?.update, 200);

    // COMPONENT

    const bookingContext = useBookingContext();
    const { formatNumber } = useNumberFormatter();
    const [userContext] = useReduxState("userContext");

    const isChileCompra = useMemo(
        () => userContext.chileCompra.role !== "none" || bookingContext.isChileCompraBooking,
        [userContext, bookingContext],
    );

    // TEMPLATES
    const transactionTemplate = (payment: ApiItineraryPayment, index: number) => html`
        <tr>
            <td><img src=${payment.ImgSource} /></td>
            <td>${unsafeHTML(payment.ExpiryHtml)}</td>
            <td>
                ${userContext.peruCompra.role !== "none" || bookingContext.isPeruCompraBooking
                    ? i18next.t("Cuenta Perú Compras")
                    : payment.PaymentMethodInfo}
            </td>
            <td>
                ${formatNumber({
                    amount: payment.UnformattedQuotedAmount,
                    currency: payment.CurrencyCode,
                    leadingSign: true,
                })}
                ${maskCurrenciesForDisplay(payment.CurrencyCode)}
            </td>
            <td>${unsafeHTML(payment.InstallmentsHtml)}</td>
            <td>
                <a class="cursor-pointer" @click=${() => props.handleOpenPaymentDetails(index)}>
                    ${i18next.t("Ver detalle")}
                </a>
            </td>
            <td><span>${payment.Status}</span></td>
        </tr>
    `;

    const htmlTemplate = () => html`
        <table class="i2-transactions-table" @click=${handleClickOnTransaction}>
            <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>${i18next.t("Fecha")}</th>
                    <th>${i18next.t("Medio de pago")}</th>
                    <th>${i18next.t("Monto")}</th>
                    <th>${i18next.t("Nr. Cuotas")}</th>
                    <th>${i18next.t("Detalle")}</th>
                    <th>${i18next.t("Estado")}</th>
                    ${isChileCompra ? html`<th></th>` : ""}
                </tr>
            </thead>
            <tbody>
                ${props.model.ItineraryPayments
                    ? props.model.ItineraryPayments.map((payment, index) => transactionTemplate(payment, index))
                    : ""}
            </tbody>
        </table>
    `;

    return { htmlTemplate };
};
