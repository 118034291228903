import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import { html } from "lit-html";
import { BagType } from "../useBaggagePage";

export interface Props {
    baggageSection: BaggageSection;
    bagType: BagType;
    isCheckinClosedInbound: boolean;
    isCheckinClosedOutbound: boolean;
    isMobile: boolean;
    paxCount: number;
}

export const perBookingQuantityButtons = (props: Props) => {
    const isAddAvailable = props.baggageSection.perBooking.isAddAvailable({
        baggageSectionJourneys: props.baggageSection.journeys,
        bagType: props.bagType,
        canGetFreeBancoEstadoBaggage: props.baggageSection.canGetFreeBancoEstadoBaggage,
        isCheckinClosedInbound: props.isCheckinClosedInbound,
        isCheckinClosedOutbound: props.isCheckinClosedOutbound,
        paxCount: props.paxCount,
    });

    const isRemoveAvailable = props.baggageSection.perBooking.isRemoveAvailable(
        props.bagType,
        props.baggageSection.journeys,
    );

    const handleAdd = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        await props.baggageSection.add();
    };

    const handleRemove = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        await props.baggageSection.remove();
    };

    const addButtonTemplate = () => html`
        <button
            class="b2-quantity-button"
            data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_ADD_BUTTON, { c: props.bagType, m: props.isMobile })}
            .disabled=${!isAddAvailable}
            @click=${handleAdd}
        >
            &plus;
        </button>
    `;

    const removeButtonTemplate = () => html`
        <button
            class="b2-quantity-button"
            data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_REMOVE_BUTTON, { c: props.bagType, m: props.isMobile })}
            .disabled=${!isRemoveAvailable}
            @click=${handleRemove}
        >
            &minus;
        </button>
    `;

    const htmlTemplate = () => html`
        <div class="b2-per-booking-quantity-container">
            ${removeButtonTemplate()}
            <span
                class="b2-per-booking-quantity"
                data-test-value=${props.baggageSection.perBooking.quantity(props.baggageSection.journeys)}
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_QUANTITY, { c: props.bagType, m: props.isMobile })}
            >
                ${props.baggageSection.perBooking.quantity(props.baggageSection.journeys)}
            </span>
            ${addButtonTemplate()}
        </div>
    `;

    return { htmlTemplate };
};
