import { html } from "haunted";
import { ApiPortalSpaViewModel } from "../../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import i18next from "i18next";
import classNames from "classnames";
import { useChileCompraUserInfo } from "./useChileCompraUserInfo";
import { useChileCompraOrgInfo } from "./useChileCompraOrgInfo";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../shared/useNumberFormatter";

export interface Props {
    model: ApiPortalSpaViewModel;
}

export const useChileCompraProfilePage = (props: Props) => {
    const [userContext] = useReduxState("userContext");
    const { formatNumber } = useNumberFormatter();

    // HELPERS

    // COMPONENT

    const organizationInfo = useChileCompraOrgInfo({
        model: props.model.ProfileViewModel?.CompanyModel,
    });

    const userInfo = useChileCompraUserInfo({
        model: props.model.ProfileViewModel?.UserModel,
    });

    // TEMPLATES

    const headerTemplate = () => html`
        <div class="cug2b-title-container">
            <i class="js-icon-cug js-cug-man-and-plane"></i>
            <div>
                <h1>${i18next.t("Mi Perfil")}</h1>
                <h2>${i18next.t("Revisa, edita y actualiza toda la información referente a tu cuenta de usuario.")}</h2>
            </div>
        </div>
    `;

    const balanceTemplate = () => html`
        <div class="relative flex flex-col justify-between sm:flex-row">
            <div class="cug2b-profile-title chile-compra">
                <i class="js-new js-n-money-in-hand"></i>
                <span>${i18next.t("Saldo disponible")}</span>
            </div>
            <div
                class="mr-[55px] flex items-center pl-[15px] pt-3 font-bold sm:pl-0 sm:pt-0 sm:text-[16px] sm:font-extrabold lg:text-[22px]"
            >
                ${formatNumber({
                    amount: userContext?.chileCompra.availableAmount || 0,
                    leadingSign: true,
                    currency: "CLP",
                    omitSpace: true,
                })}
                CLP
            </div>
        </div>
    `;

    const organizationTemplate = () => html`
        <div class="relative">
            <div class="cug2b-profile-title">
                <i class="js-icon-cug js-cug-company"></i>
                <span>${i18next.t("Mi Organización")}</span>
            </div>
            ${organizationInfo.htmlTemplate()}
        </div>
    `;

    const userTemplate = () => {
        return props.model.ProfileViewModel.UserModel
            ? html`
                  <div class="relative">
                      <div
                          class=${classNames("cug2b-profile-title", {
                              "push-down": Boolean(props.model.ProfileViewModel.CompanyModel),
                          })}
                      >
                          <i class="js-icon-cug js-cug-user"></i>
                          <span>${i18next.t("Mi Perfil")}</span>
                      </div>
                      ${userInfo.htmlTemplate()}
                  </div>
              `
            : "";
    };

    const htmlTemplate = () => html`
        ${headerTemplate()}
        <div class="cug2b-profile-box chile-compra-balance">${balanceTemplate()}</div>
        <div class="cug2b-profile-box">${organizationTemplate()} ${userTemplate()}</div>
    `;

    return { htmlTemplate };
};
