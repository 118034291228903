import { XmlInstallmentDropdownOption } from "../../component-models/XmlInstallmentOption";
import { CreditCardValidationResult } from "../../component-models/payment/CreditCardValidationResult";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { useTealiumManager } from "./useTealiumManager";
import { useAppContext } from "../useAppContext";
import { useState } from "../../shared/haunted/CustomHooks";
import { normalizeCulture } from "../../shared/localeHelper";
import { useBookingContext } from "../useBookingContext";
import { useNumberFormatter } from "../../shared/useNumberFormatter";

export const usePaymentTealiumManager = () => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const tealiumManager = useTealiumManager();

    const { formatNumber } = useNumberFormatter();

    const [tealiumLoggedFopBrand, setTealiumLoggedFopBrand] = useState<string>(undefined);
    const [tealiumLoggedFopCode, setTealiumLoggedFopCode] = useState<string>(undefined);

    const logXmlInstallmentNumberChange = async (
        xmlInstallmentOptions: XmlInstallmentDropdownOption[],
        value: number,
    ) => {
        const selectedOption = xmlInstallmentOptions.find((o) => o.Value === value);

        await tealiumManager.log({
            EventName: "installments_selected",
            EventParams: {
                installment_selected: value,
                interest_rate: formatNumber({
                    amount: selectedOption?.Rate,
                    currency: selectedOption?.Currency,
                    leadingSign: false,
                    omitGrouping: true,
                }),
                interest_fee: formatNumber({
                    amount: selectedOption?.Fee,
                    currency: selectedOption?.Currency,
                    leadingSign: false,
                    omitGrouping: true,
                }),
                interest_currency: selectedOption?.Currency,
            },
            UdoParams: {
                installment_availability: "True",
                installment_selected: value,
                interest_rate: formatNumber({
                    amount: selectedOption?.Rate,
                    currency: selectedOption?.Currency,
                    leadingSign: false,
                    omitGrouping: true,
                }),
                interest_fee: formatNumber({
                    amount: selectedOption?.Fee,
                    currency: selectedOption?.Currency,
                    leadingSign: false,
                    omitGrouping: true,
                }),
                interest_currency: selectedOption?.Currency,
            },
            UpdateCommonUdo: true,
        });
    };

    const logMercadoPagoInstallmentNumberChange = async (selectedInstallmentCount: number, formattedAmount: string) => {
        await tealiumManager.log({
            EventName: "installments_selected",
            EventParams: {
                installment_selected: selectedInstallmentCount,
                amount: formattedAmount,
            },
            UdoParams: {
                installment_availability: "True",
                installment_selected: selectedInstallmentCount,
            },
            UpdateCommonUdo: true,
        });
    };

    const logCardNumberInputBlur = async (response: CreditCardValidationResult, fopCode: string, fopBrand: string) => {
        if (!response.IsValid) {
            await tealiumManager.logValidationError(["invalid bank card number"]);
        } else {
            await tealiumManager.log({
                EventName: "payment_fill_card",
                EventParams: {
                    fop_brand: response.Card?.Type,
                },
                UdoParams: {
                    fop_code: fopCode,
                    fop_brand: fopBrand,
                },
            });
        }
    };

    const logPaymentMethodCodeSelected = async (fopCode: string) => {
        await tealiumManager.log({
            EventName: "payment_selected",
            EventParams: {
                culture: normalizeCulture(appContext.Culture),
                device: tealiumManager.getTealiumDeviceType(),
                origin_iata: bookingContext.originStationCode,
                destination_iata: bookingContext.destinationStationCode,
                fop_code: fopCode,
            },
            UpdateCommonUdo: true,
        });
    };

    const logPaymentPageLoad = async (model: PaymentPageViewModel) => {
        await tealiumManager.logDomLoaded("payment", {
            UpdateCommonUdo: true,
            UdoParams: JSON.parse(model.BookingViewModel.FlatBookingData),
            UpdateRealUdo: true,
        });
    };

    const handlePaymentMethodChange = async (paymentMethodCodeToSubmit: string, fopBrand: string) => {
        if (!paymentMethodCodeToSubmit && !fopBrand) return;

        const newFopBrand = fopBrand || "n/a";

        if (tealiumLoggedFopCode !== paymentMethodCodeToSubmit || tealiumLoggedFopBrand !== newFopBrand) {
            setTealiumLoggedFopCode(paymentMethodCodeToSubmit);
            setTealiumLoggedFopBrand(newFopBrand);
            await logPaymentMethodCodeSelected(paymentMethodCodeToSubmit);
        }
    };

    return {
        handlePaymentMethodChange,
        logCardNumberInputBlur,
        logContinueClicked: tealiumManager.logContinueClicked,
        logMercadoPagoInstallmentNumberChange,
        logPaymentPageLoad,
        logXmlInstallmentNumberChange,
    };
};
