import { html } from "haunted";
import i18next from "i18next";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import {
    ARGENTINIAN_CULTURE_CODE,
    PERUVIAN_CULTURE_CODE,
    PARAGUAYAN_CULTURE_CODE,
    URUGUAYAN_CULTURE_CODE,
    COLOMBIAN_CULTURE_CODE,
    BRASILIAN_CULTURE_CODE,
    USA_CULTURE_CODE,
} from "../../../shared/commonConstants";

export const useCugContactPage = () => {
    const cug2AppContext = useCug2AppContext();

    const [language, country] = cug2AppContext.Culture.split("-");

    // HELPERS

    const phoneNumber = () => {
        switch (cug2AppContext.Culture) {
            case ARGENTINIAN_CULTURE_CODE:
                return "Teléfono: 112 206 7799 OPCIÓN 4";
            case PERUVIAN_CULTURE_CODE:
                return "Teléfono: 01 311 0005 OPCIÓN 3";
            case PARAGUAYAN_CULTURE_CODE:
                return "Teléfono: 595 217 289 039 OPCIÓN 3";
            case URUGUAYAN_CULTURE_CODE:
                return "Teléfono: 000 413 598 5582 OPCIÓN 3";
            case COLOMBIAN_CULTURE_CODE:
                return "Teléfono: (601) 348 9581 OPCIÓN 3";
            case BRASILIAN_CULTURE_CODE:
                return "Telefone: 55 11 3042-1183 OPÇÃO 3";
            case USA_CULTURE_CODE:
                return "Phone: 600 600 1311 OPTION 4";
            default:
                return "Teléfono: 600 600 1311 OPCIÓN 4";
        }
    };

    const emailAddress = () => {
        switch (cug2AppContext.Culture) {
            case ARGENTINIAN_CULTURE_CODE:
                return "ventas.ar@jetsmart.com";
            case PERUVIAN_CULTURE_CODE:
                return "ventas.pe@jetsmart.com";
            case PARAGUAYAN_CULTURE_CODE:
                return "ventas.py@jetsmart.com";
            case URUGUAYAN_CULTURE_CODE:
                return "ventas.uy@jetsmart.com";
            case COLOMBIAN_CULTURE_CODE:
                return "ventas.co@jetsmart.com";
            case BRASILIAN_CULTURE_CODE:
                return "vendas.br@jetsmart.com";
            default:
                return "ventas.cl@jetsmart.com";
        }
    };

    // TEMPLATES

    const headerTemplate = () => html`
        <div class="cug2b-contact-header">
            <i class="js-icon-cug js-cug-phone"></i>
            <div>
                <h1>${i18next.t("Contact Center Empresas y Agencias")}</h1>
                <h1>${i18next.t("Disponible 24 hrs")}</h1>
                <h2>${phoneNumber()}</h2>
            </div>
        </div>
    `;

    const contentTemplate = () => html`
        <div class="cug2b-contact-content">
            <div class="cug2b-contact-img"></div>
            <div class="cug2b-contact-info">
                <h1>${i18next.t("Opciones de soporte")}</h1>

                <h2>${i18next.t("Post venta y funcionalidades Portal Empresas y Agencias")}</h2>
                <p><i class="js-icon-cug js-cug-letter"></i>${i18next.t("soporte.empresas@jetsmart.com")}</p>

                <h2>${i18next.t("Asuntos comerciales Empresas y Agencias")}</h2>
                <p><i class="js-icon-cug js-cug-letter"></i>${emailAddress()}</p>

                <h2>${i18next.t("Solicitudes de Grupos")}</h2>
                <p>
                    <i class="js-icon-cug js-cug-globe"></i>
                    ${`https://jetsmart.com/${country}/${language}/reservas-en-grupo/`}
                </p>

                <h2>${i18next.t("Solicitudes de Series Grupales")}</h2>
                <p><i class="js-icon-cug js-cug-letter"></i>${i18next.t("grupos@jetsmart.com")}</p>

                <h2>${i18next.t("Post venta Grupos y Series")}</h2>
                <p><i class="js-icon-cug js-cug-letter"></i>${i18next.t("soporte.grupos@jetsmart.com")}</p>

                <h2>${i18next.t("Solicitudes de chárters")}</h2>
                <p><i class="js-icon-cug js-cug-letter"></i>${i18next.t("charter@jetsmart.com")}</p>

                <h2>${i18next.t("Post venta chárters")}</h2>
                <p><i class="js-icon-cug js-cug-letter"></i>${i18next.t("soporte.charter@jetsmart.com")}</p>
            </div>
        </div>
    `;

    const htmlTemplate = () => html` ${headerTemplate()}${contentTemplate()} `;

    return { htmlTemplate };
};
