import i18next from "i18next";
import { ItineraryJourney, ApiItineraryViewModelV2 } from "../../component-models/itinerary/ApiItineraryViewModelV2";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import { FlightMovePageState } from "./flight-move-page";
import { useFlightJourneySummary } from "../shared/flight-journey-summary/useFlightJourneySummary";
import { INBOUND, OUTBOUND } from "../../shared/commonConstants";

export interface Props {
    itineraryModel: ApiItineraryViewModelV2;
    pageHistory: FlightMovePageState[];
    selectedJourneys: number[];
    handleActivate: () => void;
    handleJourneyClick: (index: number) => void;
    handleLegSubmit: () => void;
}

export const useLegSelector = (props: Props) => {
    const summaryOutbound = useFlightJourneySummary({
        journey: props.itineraryModel.Journeys.find((j) => j.JourneyIndex === OUTBOUND),
        isSelected: props.selectedJourneys.includes(OUTBOUND),
        showBundleImage: false,
        showTimeChange: false,
        showPill: false,
        onCheckboxClick: props.handleJourneyClick,
    });

    const summaryInbound = useFlightJourneySummary({
        journey: props.itineraryModel.Journeys.find((j) => j.JourneyIndex === INBOUND),
        isSelected: props.selectedJourneys.includes(INBOUND),
        showBundleImage: false,
        showTimeChange: false,
        showPill: false,
        onCheckboxClick: props.handleJourneyClick,
    });

    const isEnabled = () => props.pageHistory[props.pageHistory.length - 1] === "LegSelect";

    const handleSectionClick = (e: MouseEvent) => {
        if (!isEnabled()) {
            e.preventDefault();
            e.stopPropagation();

            props.handleActivate();
        }
    };

    const journeyTemplate = (journey: ItineraryJourney) => {
        const tempClassMap = classMap({
            "flight-move-journey-container": true,
            "one-segment": journey.Segments.length === 1,
            "active": props.selectedJourneys.includes(journey.JourneyIndex),
            "non-hoverable": summaryInbound.isModalOpen || summaryOutbound.isModalOpen,
        });

        const dataTestId = `flight-move-leg-${journey.JourneyIndex}`;

        return html`
            <div class="flight-move-journey">
                <div
                    class=${tempClassMap}
                    data-test-id=${dataTestId}
                    @click=${() => props.handleJourneyClick(journey.JourneyIndex)}
                >
                    ${journey.JourneyIndex === OUTBOUND
                        ? summaryOutbound.htmlTemplate()
                        : summaryInbound.htmlTemplate()}
                </div>
            </div>
        `;
    };

    const buttonTemplate = () => {
        const isDisabled = props.selectedJourneys.length === 0 || !isEnabled();

        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "disabled": props.selectedJourneys.length === 0 || !isEnabled(),
        });

        return html`
            <div class="flight-move-flight-selector-buttons">
                <button
                    class=${tempClassMap}
                    data-test-id="flight-move-submit-leg"
                    ?disabled=${isDisabled}
                    @click=${props.handleLegSubmit}
                >
                    ${i18next.t("Cambiar la fecha")}
                </button>
            </div>
        `;
    };

    const mainClassMap = classMap({
        "booking-wrapper": true,
        "flight-move-page": true,
        "active": isEnabled(),
        "inactive": !isEnabled(),
    });

    const htmlTemplate = () => html`
        <section class=${mainClassMap} @click=${handleSectionClick}>
            <header class="b2-section-header">
                <div class="flex items-center">
                    <span class="b2-section-number">1</span>
                    <div>
                        <h3>${i18next.t("Selección de tramos")}</h3>
                    </div>
                </div>
            </header>
            ${props.itineraryModel.Journeys.filter((j) => !j.IsFlown).map((journey) => journeyTemplate(journey))}
        </section>
        ${buttonTemplate()}
    `;

    return { htmlTemplate };
};
