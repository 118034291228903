import { PassengerFormVM } from "./../../component-models/passengers/PassengerFormVM";
import { mapToTravelDocument } from "../../component-mappers/TravelDocumentMapper";
import { AMERICAN_FREQUENT_FLYER_NUMBER_DOCTYPE, TravelDocument } from "../../component-models/checkin/TravelDocument";
import { ApiNewPassenger, ApiPassengerTravelDocument } from "../../component-models/passengers/ApiNewPassengersModel";
import { useFlowContext } from "../../managers/useFlowContext";
import { DEFAULT_DATE_FORMAT, FAKE_COUNTRY_CODE, FAKE_DOCUMENT_EXPIRATION_DATE } from "../../shared/commonConstants";
import { validateRut } from "../../shared/form-validation";
import { useState } from "../../shared/haunted/CustomHooks";
import { useReduxState } from "../../shared/redux/useReduxState";
import { clone } from "../../shared/common";

export const useDocumentCollectionHandler = () => {
    const flowContext = useFlowContext();

    const [userContext] = useReduxState("userContext");
    const [travelDocuments, setTravelDocuments] = useState<TravelDocument[]>([]);
    const [initialTravelDocuments, setInitialTravelDocuments] = useState<TravelDocument[]>([]);

    const shouldAddBancoEstadoDocument = (
        bancoEstadoDocument: ApiPassengerTravelDocument,
        passenger: ApiNewPassenger,
    ) =>
        bancoEstadoDocument &&
        passenger.Type === "ADT" &&
        passenger.PassengerNumber === 0 &&
        !passenger.TravelDocuments.some((d) => d.DocumentType === "N");

    const getRealDocuments = (passenger: ApiNewPassenger) =>
        passenger.TravelDocuments.filter((d) => d.DocumentType !== AMERICAN_FREQUENT_FLYER_NUMBER_DOCTYPE);

    const getInitialDocuments = (passengers: ApiNewPassenger[], bancoEstadoDocument: ApiPassengerTravelDocument) =>
        passengers.reduce((allDocuments, passenger) => {
            const realDocuments = getRealDocuments(passenger);

            const documentsToMap = shouldAddBancoEstadoDocument(bancoEstadoDocument, passenger)
                ? realDocuments.concat(bancoEstadoDocument)
                : realDocuments;

            const mappedPassengerDocuments = documentsToMap.map((document) => {
                return mapToTravelDocument({
                    document,
                    isBancoEstado: userContext.bancoEstado.category !== 0,
                    isBookingFlow: flowContext.isBookingFlow,
                    passenger,
                });
            });

            return allDocuments.concat(mappedPassengerDocuments);
        }, [] as TravelDocument[]);

    const getSuffix = (newDocumentPassenger: PassengerFormVM) => (newDocumentPassenger.type === "INF" ? "I" : " ");

    const getDocumentPassengerNumber = (newDocumentPassenger: PassengerFormVM) =>
        newDocumentPassenger.type === "INF"
            ? newDocumentPassenger.attachedPassengerNumber
            : newDocumentPassenger.passengerNumber;

    const isDocumentForPassenger = (document: TravelDocument, newDocumentPassenger: PassengerFormVM) =>
        document.DocSuffix === getSuffix(newDocumentPassenger) &&
        document.PassengerNumber === getDocumentPassengerNumber(newDocumentPassenger);

    const hasExistingDocument = (newDocumentPassenger: PassengerFormVM) =>
        travelDocuments.some(
            (doc) =>
                isDocumentForPassenger(doc, newDocumentPassenger) && doc.TypeCode === newDocumentPassenger.documentType,
        );

    const findInitialDocument = (doc: TravelDocument) =>
        initialTravelDocuments.find(
            (initialDoc) =>
                initialDoc.PassengerNumber === doc.PassengerNumber &&
                initialDoc.TypeCode === doc.TypeCode &&
                initialDoc.DocSuffix === doc.DocSuffix,
        );

    const getNewDocumentFromPaxForm = (newDocumentPassenger: PassengerFormVM): TravelDocument => ({
        DocSuffix: getSuffix(newDocumentPassenger),
        ExpirationDate:
            newDocumentPassenger.documentExpiry?.format(DEFAULT_DATE_FORMAT) || FAKE_DOCUMENT_EXPIRATION_DATE,
        IsReadonly: false,
        IssuedByCode: newDocumentPassenger.documentIssuer || FAKE_COUNTRY_CODE,
        Number: newDocumentPassenger.documentNumber,
        PassengerNumber: getDocumentPassengerNumber(newDocumentPassenger),
        TypeCode: newDocumentPassenger.documentType,
    });

    const addNewDocument = (newDocumentPassenger: PassengerFormVM) =>
        setTravelDocuments((travelDocuments) => [
            ...travelDocuments
                .map((doc) => {
                    if (!isDocumentForPassenger(doc, newDocumentPassenger)) return doc;

                    // DEVNOTE The idea is that if the passenger adds a certain type of
                    // document, we should restore the other type documents of the pax.
                    // When we post the documents, only one can be changed from the original
                    // documents.

                    return findInitialDocument(doc);
                })
                .filter((doc) => doc),
            getNewDocumentFromPaxForm(newDocumentPassenger),
        ]);

    const updateExistingDocument = (newDocumentPassenger: PassengerFormVM) =>
        setTravelDocuments((travelDocuments) =>
            travelDocuments
                .map((doc) => {
                    if (!isDocumentForPassenger(doc, newDocumentPassenger)) return doc;

                    // DEVNOTE The idea is that if the passenger changes a certain type of
                    // document, we should restore the other type documents of the pax.
                    // When we post the documents, only one can be changed from the original
                    // documents.

                    if (doc.TypeCode === newDocumentPassenger.documentType) {
                        return getNewDocumentFromPaxForm(newDocumentPassenger);
                    }

                    return findInitialDocument(doc);
                })
                .filter((doc) => doc),
        );

    // EXPORTS

    const init = (passengers: ApiNewPassenger[], bancoEstadoDocument: ApiPassengerTravelDocument) => {
        if (!userContext?.userRole) throw new Error("User role is not defined");

        const travelDocuments = getInitialDocuments(passengers, bancoEstadoDocument);

        setTravelDocuments(travelDocuments);
        setInitialTravelDocuments(clone(travelDocuments));
    };

    const resetTravelDocuments = (keepFirst?: boolean) =>
        setTravelDocuments(
            travelDocuments.map((document) => ({
                ...document,
                DocNumber: keepFirst && document.PassengerNumber === 0 ? document.Number : "",
            })),
        );

    const areAllDocumentNumbersUnique = () =>
        !travelDocuments.some((document) => !isDocumentNumberUnique(document.Number));

    const isDocumentNumberUnique = (documentNumber: string) =>
        travelDocuments.filter(
            (document) =>
                (document.TypeCode !== "N" || validateRut(document.Number)) &&
                document?.Number &&
                documentNumber &&
                document.Number === documentNumber,
        ).length < 2;

    const updateTravelDocument = (newDocumentPassenger: PassengerFormVM | PassengerFormVM[]) => {
        if (Array.isArray(newDocumentPassenger)) {
            newDocumentPassenger.forEach(updateTravelDocument);
            return;
        }

        if (!newDocumentPassenger.documentType || !newDocumentPassenger.documentNumber) return;

        if (hasExistingDocument(newDocumentPassenger)) {
            updateExistingDocument(newDocumentPassenger);
            return;
        }

        addNewDocument(newDocumentPassenger);
    };

    return {
        travelDocuments,
        areAllDocumentNumbersUnique,
        init,
        isDocumentNumberUnique,
        resetTravelDocuments,
        updateTravelDocument,
    };
};
