import { html } from "haunted";
import { SummaryWidgetsCugType } from "../../../component-models/CUG2b/SummaryWidgets";
import classNames from "classnames";

export interface Props {
    cugType: SummaryWidgetsCugType;
}

export const useSummaryWidgets = (props: Props) => {
    // EVENT HANDLERS

    // COMPONENT

    // TEMPLATES

    const htmlTemplate = () => html`
        <div>
            <div
                class=${classNames("cug-summary-widget-container", {
                    "first-inactive": !props.cugType.Widget_1.Enabled && props.cugType.Widget_2.Enabled,
                    "second-inactive": props.cugType.Widget_1.Enabled && !props.cugType.Widget_2.Enabled,
                })}
            >
                <ac-summary-page-widget
                    .model=${props.cugType.Widget_1}
                    .customClass=${"cug-summary-widget-1"}
                ></ac-summary-page-widget>
                <ac-summary-page-widget
                    .model=${props.cugType.Widget_2}
                    .customClass=${"cug-summary-widget-2"}
                ></ac-summary-page-widget>
                <ac-summary-page-widget
                    .model=${props.cugType.Widget_3}
                    .customClass=${"cug-summary-widget-3"}
                ></ac-summary-page-widget>
                <ac-summary-page-widget
                    .model=${props.cugType.Widget_4}
                    .customClass=${"cug-summary-widget-4"}
                ></ac-summary-page-widget>
            </div>
        </div>
    `;

    return { htmlTemplate };
};
