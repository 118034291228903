import { ApiCountry, ApiStation } from "../component-models/ApiCountry";
import { useReduxState } from "../shared/redux/useReduxState";
import { useAppContext } from "./useAppContext";
import { useBookingContext } from "./useBookingContext";
import { StationSettings, Country as XmlCountry } from "../component-models/StationSettings";
import { Country } from "../component-models/RoutePickerModel";
import { stationUtils } from "../components/route-and-date-selector/stationUtils";
import { mapXmlCountryToCountry } from "../component-mappers/StationMappers";
import { CHILE_COMPRA_COUNTRIES } from "../shared/commonConstants";
import { useFlightPageAjaxHandler } from "../components/flight-select/useFlightPageAjaxHandler";

// DEVNOTE The XML stuff comes from the settings JS can set in a Navitaire resource XML (StationSettings.xml)
// DEVNOTE The API stuff is the available stations as set up in Navitaire itself

// DEVNOTE A MAC is a Multi-Airport City, currently only Buenos Aires. A MAC has no api station data,
// only the attached airports (like AEP) have.

// DEVNOTE The XML and API stuff contains "stations", but as we process MACs and hide stations attached to MACs,
// we use "cities" with "substations" instead in this hook.

// DEVNOTE The key is a MAC code (like BUE) and the value is a set of airport codes in that MAC (like AEP).
//  Set is used to make airport list item codes unique/distinct
export type MacDictionary = Map<string, Set<string>>;

export interface Props {
    stationSettings: StationSettings;
}

export const useCountryManager = (props: Props) => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();

    const { getMacDictionary } = stationUtils();
    const { getApiCountries } = useFlightPageAjaxHandler();

    const [userContext] = useReduxState("userContext");
    const [_, setCountries] = useReduxState("countries");

    const getCultureDependentCountryOrder = (): string[] =>
        props.stationSettings.CountryOrdersPerCulture.find(
            (countryOrders) => countryOrders.Culture.toLowerCase() === appContext.Culture,
        ).Countries;

    const sortByCultureDependentCountryOrder = (a: Country, b: Country, culturalOrder: string[]) =>
        culturalOrder.indexOf(a.code) - culturalOrder.indexOf(b.code);

    const filterUnavailableCountries = (xmlCountry: XmlCountry): boolean => !xmlCountry.IsHidden;

    const getAllApiStations = (apiCountries: ApiCountry[]): ApiStation[] =>
        apiCountries
            .filter((country) => {
                if (userContext.chileCompra.role !== "none") {
                    return CHILE_COMPRA_COUNTRIES.includes(country.code);
                }

                const isAvailableForPeruCompras = props.stationSettings.Countries.find(
                    (c) => c.Code === country.code,
                )?.IsAvailableForPeruCompras;

                return (
                    (userContext.peruCompra.role === "none" && !bookingContext.isPeruCompraBooking) ||
                    isAvailableForPeruCompras
                );
            })
            .flatMap((country) => country.stations);

    // EXPORTS

    const initCountries = async () => {
        const apiCountries = await getApiCountries(appContext.Culture);
        const allApiStations = getAllApiStations(apiCountries);
        const culturalOrder = getCultureDependentCountryOrder();

        const mappedCountries = props.stationSettings.Countries.filter(filterUnavailableCountries)
            .map((xmlCountry) =>
                mapXmlCountryToCountry({
                    allApiStations,
                    apiCountries,
                    culture: appContext.Culture,
                    macDictionary: getMacDictionary(allApiStations),
                    xmlCountry,
                }),
            )
            .sort((a, b) => sortByCultureDependentCountryOrder(a, b, culturalOrder));

        setCountries(mappedCountries);
    };

    return { initCountries };
};
