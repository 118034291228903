import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import { html } from "lit-html";
import { INBOUND, OUTBOUND } from "../../../../shared/commonConstants";
import { paxAmount, displayedBundleJourneyIndex } from "../../../../component-helpers/BaggageHelper";
import { BagType } from "../useBaggagePage";
import classNames from "classnames";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import { BaggageSectionJourney } from "../../../../component-models/baggage/BaggageSectionJourney";
import { BaggageSectionUiState } from "../../../../component-models/baggage/BaggageSectionUi";

export interface Props {
    baggageSectionJourneys: BaggageSectionJourney[];
    baggageState: BaggageSectionUiState;
    bagType: BagType;
}

export const usePerBookingBundle = (props: Props) => {
    const bookingContext = useBookingContext();

    const oneWayBundleDirection = () => displayedBundleJourneyIndex(props.baggageSectionJourneys);

    const oneWayBundleText = () =>
        oneWayBundleDirection() === OUTBOUND ? i18next.t("para el vuelo de ida") : i18next.t("para el vuelo de vuelta");

    const bundleInfoText = () =>
        paxAmount(props.baggageState) === 1
            ? props.bagType === "checkedBaggage"
                ? i18next.t("Recuerda que tu llevas equipaje facturado porque compraste")
                : i18next.t("Recuerda que tu llevas equipaje de mano porque compraste")
            : props.bagType === "checkedBaggage"
              ? i18next.t("Recuerda que todos los pasajeros llevan equipaje facturado porque compraron el")
              : i18next.t("Recuerda que todos los pasajeros llevan equipaje de mano porque compraron el");

    const differentBundlesInfoText1 = () =>
        paxAmount(props.baggageState) === 1
            ? i18next.t("Recuerda que agregaste un")
            : i18next.t("Recuerda que todos los pasajeros agregaron un");

    const differentBundlesInfoText2 = () =>
        props.bagType === "checkedBaggage"
            ? i18next.t("que incluye equipaje facturado")
            : i18next.t("que incluye equipaje de mano");

    const showOutboundBundleImage =
        (props.baggageSectionJourneys[OUTBOUND].bundleImageUrl && props.baggageState.journeys.length === 1) ||
        !props.baggageSectionJourneys[INBOUND]?.bundleImageUrl ||
        props.baggageSectionJourneys[OUTBOUND].bundleImageUrl === props.baggageSectionJourneys[INBOUND].bundleImageUrl;

    const showInboundBundleImage =
        !props.baggageSectionJourneys[OUTBOUND].bundleImageUrl && props.baggageSectionJourneys[INBOUND]?.bundleImageUrl;

    const showFullBundle = useMemo(
        () => props.baggageSectionJourneys.every((journey) => journey.bundleType === "Full"),
        [props.baggageSectionJourneys],
    );

    const showSimpleBundle = useMemo(
        () => props.baggageSectionJourneys.every((journey) => journey.bundleType === "Smart"),
        [props.baggageSectionJourneys],
    );

    const showGenericBundle = useMemo(
        () =>
            props.baggageSectionJourneys.some(
                (journey1) =>
                    journey1.bundleUserType === "GDS" ||
                    props.baggageSectionJourneys.some((journey2) => journey2.bundleType !== journey1.bundleType),
            ),
        [props.baggageSectionJourneys],
    );

    const imgUrl = showOutboundBundleImage
        ? props.baggageSectionJourneys[OUTBOUND].bundleImageUrl
        : showInboundBundleImage
          ? props.baggageSectionJourneys[INBOUND].bundleImageUrl
          : "";

    const imgSrc =
        (showFullBundle || showSimpleBundle) && imgUrl
            ? imgUrl
            : showGenericBundle
              ? "/Images/Bundles/bundle-baggage.svg"
              : undefined;

    const showBundle = useMemo(
        () => props.baggageSectionJourneys.some((journey) => journey.bundleType !== "None"),
        [props.baggageSectionJourneys],
    );

    const dataTestValue = !showBundle
        ? "None"
        : showFullBundle
          ? "Full"
          : showSimpleBundle
            ? "Simple"
            : showGenericBundle
              ? "Mixed"
              : "n/a";

    const bundleContentTemplate = () =>
        showGenericBundle
            ? html`
                  <span>${differentBundlesInfoText1()}</span>
                  <img src=${imgSrc} />
                  <span>${differentBundlesInfoText2()}</span>
              `
            : html`
                  <span>${bundleInfoText()}</span>
                  ${specificBundleImageTemplate()} ${specificBundleOneWayTemplate()}
              `;

    const specificBundleOneWayTemplate = () =>
        oneWayBundleDirection() !== undefined ? html` <span>${oneWayBundleText()}</span> ` : "";

    const specificBundleImageTemplate = () => (imgSrc ? html` <img src=${imgSrc} /> ` : "");

    const htmlTemplate = () => {
        const mainClassNames = classNames("b2-baggage-bundle-info", {
            "push-down": !bookingContext.isDiscountBooking,
        });

        return showBundle
            ? html`
                  <div
                      class=${mainClassNames}
                      data-test-value=${dataTestValue}
                      data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_BUNDLE_MESSAGE, { c: props.bagType })}
                  >
                      <i class="js-icon js-broken-circle-exclamation"></i>
                      <div>${bundleContentTemplate()}</div>
                  </div>
              `
            : html``;
    };

    return { htmlTemplate };
};
