import { useEffect, useRef, useState } from "haunted";
import i18next from "i18next";
import { html } from "lit-html";
import { ref } from "../../../directives/ref";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import { classMap } from "lit-html/directives/class-map";
import { CLASS_NAMES } from "../../../shared/classNames";
import { ROUTES } from "../../../shared/apiRoutes";
import {
    ChangedFlightsModel,
    ChangedFlightsResponse,
    ChangedFlightType,
} from "../../../component-models/CUG2b/ChangedFlightsModel";
import { handleCugLoader } from "../../../shared/common";
import { getCookie, setSessionCookie } from "../../../shared/cookieHandling";
import { COOKIE_NAMES } from "../../../shared/commonConstants";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { useReduxState } from "../../../shared/redux/useReduxState";
import PerfectScrollbar from "perfect-scrollbar";

export const name = "ac-cug-notification";

export interface Properties {
    notificationCount: number | undefined;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        notificationCount: host.notificationCount,
    };

    // HELPERS
    const init = () => {
        document.addEventListener("click", onClickOutside, true);
        document.addEventListener("scroll", () => {
            setIsOpen(false);
        });
    };

    const getChangedFlights = async () => {
        if (isLoading) {
            return;
        }
        setIsLoading(true);
        handleCugLoader(notifications.current, "loadChangedFlights", true);
        const result = await ajaxJsonRequest<ChangedFlightsResponse>({
            url: ROUTES.ApiRoutes.Cug2BGetChangedFlights,
            method: "GET",
        });
        handleCugLoader(notifications.current, "loadChangedFlights");

        setChangedFlights(result.data.ChangedFlights);
        setIsLoading(false);
    };

    // EVENT LISTENERS
    const onClickOutside = (e: any) => {
        if (!root.current?.contains(e.target) || overlay.current?.contains(e.target)) {
            setIsOpen(false);
        }
    };

    // COMPONENT
    const { ajaxJsonRequest } = useAjax();

    const [userContext] = useReduxState("userContext");

    const root = useRef<HTMLDivElement>(undefined);
    const overlay = useRef<HTMLDivElement>(undefined);
    const notifications = useRef<HTMLDivElement>(undefined);

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [hasNewNotifications, sethasNewNotifications] = useState<boolean>(props.notificationCount > 0);
    const [scrollBar, setScrollbar] = useState<PerfectScrollbar>(undefined);
    const [changedFlights, setChangedFlights] = useState<ChangedFlightsModel[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(init, []);
    useEffect(() => {
        if (scrollBar) {
            scrollBar.destroy();
        }
        setScrollbar(
            ScrollHelper.addPerfectScrollbar(CLASS_NAMES.cugNotificationBoxScroller, {
                wheelPropagation: false,
                wheelSpeed: 1,
                swipeEasing: true,
                suppressScrollX: false,
                useBothWheelAxes: true,
            })[0],
        );
    }, []);
    useEffect(async () => {
        if (isOpen) {
            document.body.classList.add(CLASS_NAMES.cugNoScrollOnlyMobile);
            document.getElementsByTagName("html")[0].classList.add(CLASS_NAMES.cugNoScrollOnlyMobile);
            sethasNewNotifications(false);
            setSessionCookie(COOKIE_NAMES.IsNotificationBoxOpenedOnce, "1");

            if (changedFlights.length === 0) {
                await getChangedFlights();
            }
        } else {
            document.body.classList.remove(CLASS_NAMES.cugNoScrollOnlyMobile);
            document.getElementsByTagName("html")[0].classList.remove(CLASS_NAMES.cugNoScrollOnlyMobile);
            sethasNewNotifications(props.notificationCount > 0);
        }
    }, [isOpen]);

    // TEMPLATES
    const illustrationMessageTemplate = (flight: ChangedFlightsModel) =>
        flight.Type === ChangedFlightType.Cancellation
            ? html` <span class="notifications-message-flight-cancelled">${i18next.t("Cancelado")}</span> `
            : flight.ChangedJourneyIndex === 0
              ? html` <span class="font-bold">${i18next.t("Nuevo horario IDA")}</span> `
              : html` <span class="font-bold">${i18next.t("Nuevo horario VUELTA")}</span> `;

    const illustrationTemplate = (flight: ChangedFlightsModel) => {
        const illustrationTopRowClassMap = classMap({
            "illustration-grid": true,
            "notifications-flight-cancelled": flight.Type === ChangedFlightType.Cancellation,
        });
        const illustrationCenterRowClassMap = classMap({
            "illustration-row": true,
            "items-center": true,
            "notifications-flight-cancelled": flight.Type === ChangedFlightType.Cancellation,
        });
        const illustrationBottomRowClassMap = classMap({
            "illustration-row": true,
            "justify-between": true,
            "notifications-flight-cancelled": flight.Type === ChangedFlightType.Cancellation,
        });

        return html`
            <div class="illustration-container">
                <div class=${illustrationTopRowClassMap}>
                    <span class="font-bold">${flight.DepartureStation}</span>
                    <span class="js-icon-bag js-bag-plane-take-off text-xs font-bold"></span>
                    <span class="font-bold">${flight.DestinationStation}</span>
                </div>
                <div class=${illustrationCenterRowClassMap}>
                    <div class="notification-dot"></div>
                    <div class="notification-line"></div>
                    <div class="notification-dot"></div>
                </div>
                <div class=${illustrationBottomRowClassMap}>
                    <span class="font-bold">${flight.FormattedDepartureTime}</span>
                    ${illustrationMessageTemplate(flight)}
                    <span class="font-bold">${flight.FormattedArrivalTime}</span>
                </div>
            </div>
        `;
    };

    const changedFlightTemplate = (flight: ChangedFlightsModel) => {
        const urlToFlightInfo = `${ROUTES.BookingRetrieve}/?em=${flight.PrimaryContactEmail}&rl=${flight.Pnr}`;

        const changedFlightLabel = () => {
            return flight.Type === ChangedFlightType.Cancellation
                ? html` ${i18next.t("Vuelo cancelado")} `
                : html` ${i18next.t("Cambio de horario")} `;
        };

        const iconClassMap = classMap({
            "js-icon-cug-notification": true,
            "js-cn-circle-calendar": flight.Type === ChangedFlightType.Cancellation,
            "js-cn-circle-clock": flight.Type === ChangedFlightType.Change,
        });

        return html`
            <div class="notification-element-container">
                <div class="cn-icon-container">
                    <i class=${iconClassMap}></i>
                </div>
                <div class="notification-info-container">
                    <p class="font-bold leading-4">
                        ${i18next.t("Código da Reserva:")} ${flight.Pnr} / ${i18next.t("Vuelo")}
                        <span class="cug-flight-number">${flight.FlightNumber}</span>
                    </p>
                    <p class="font-bold leading-4">
                        ${changedFlightLabel()}
                        <span>
                            ${i18next.t(
                                "Si se generaron cambios en tu reserva entra aquí para ver las opciones que tienes",
                            )}
                        </span>
                    </p>
                    ${illustrationTemplate(flight)}
                    <a class="rounded-primary-btn cug-widget-cyan narrow" href=${urlToFlightInfo}>
                        ${i18next.t("Ver más")}
                    </a>
                </div>
            </div>
        `;
    };

    const notificationsBoxTemplate = () => {
        const dropdownClassMap = classMap({
            "notification-dropdown-container": true,
            "open": isOpen,
        });
        const layoverClassMap = classMap({
            "cug-layover": isOpen,
        });
        return html`
            <div class="${layoverClassMap}" ref=${ref(overlay)}></div>
            <div class="${dropdownClassMap}">
                <div class="close-dropdown" @click=${() => setIsOpen(false)}>X</div>
                <div class="notifications-box-label">${i18next.t("Notificaciones")}</div>
                <div class=${CLASS_NAMES.cugNotificationBoxScroller}>
                    <div class="h-full w-full" ref=${ref(notifications)}>
                        <div class="notifications-box-container">
                            ${changedFlights.map((flight) => changedFlightTemplate(flight))}
                        </div>
                    </div>
                </div>
            </div>
        `;
    };

    const hasNewNotificationTemplate = () =>
        hasNewNotifications && getCookie(COOKIE_NAMES.IsNotificationBoxOpenedOnce) !== "1"
            ? html`
                  <div class="new-notification-sign">
                      <span class="new-notification-count">${props.notificationCount}</span>
                  </div>
              `
            : html``;

    const notificationContainerClassMap = classMap({
        "notification-container": true,
        "disabled": !hasNewNotifications && !isOpen,
    });

    return userContext.isLoggedIn && props.notificationCount !== undefined
        ? html`
              <div
                  class=${notificationContainerClassMap}
                  ref=${ref(root)}
                  @click=${(e: MouseEvent) => e.stopPropagation()}
              >
                  <div class="bell-container" @click=${() => setIsOpen(!isOpen)}>
                      <i class="js-icon-cug-notification js-cn-circle-bell"> ${hasNewNotificationTemplate()} </i>
                  </div>
                  ${notificationsBoxTemplate()}
              </div>
          `
        : html``;
};
