import i18next from "i18next";
import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { getRenderString } from "../../../../shared/common";
import { OUTBOUND } from "../../../../shared/commonConstants";
import { SeatmapState } from "../../../../component-models/seatmap/SeatmapState";
import { SeatmapContext } from "../../../../component-models/seatmap/SeatmapContext";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useReduxState } from "../../../../shared/redux/useReduxState";

export interface Props {
    context: SeatmapContext;
    seatmapState: SeatmapState;
}

export const useChileCompraInfoTemplate = (props: Props) => {
    const bookingContext = useBookingContext();
    const [userContext] = useReduxState("userContext");

    const infoIconTemplate = () => html`
        <div
            class="mr-2 flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full border-2 border-solid border-be-blue text-lg font-black"
        >
            i
        </div>
    `;

    const chileCompraInfoCommonTemplate = (content: any) => html`
        <div class="font-be-blue mt-2 flex w-full items-center rounded-md bg-[#f7f7f7] p-2 text-xs">
            ${infoIconTemplate()}
            <div>${content}</div>
        </div>
    `;

    const chileCompraBundleInfoTemplate = (directionText: string) => {
        const bundleColor =
            props.seatmapState.CurrentJourney.JourneyIndex === OUTBOUND
                ? props.context.SpaGlobalDataModel.OutboundBundleColor
                : props.context.SpaGlobalDataModel.InboundBundleColor;
        const bundleImg =
            props.seatmapState.CurrentJourney.JourneyIndex === OUTBOUND
                ? props.context.SpaGlobalDataModel.OutboundBundleImgUrl
                : props.context.SpaGlobalDataModel.InboundBundleImgUrl;

        return chileCompraInfoCommonTemplate(
            unsafeHTML(
                i18next.t(
                    "Para tu vuelo de {{-direction}} seleccionaste {{-bundleInfo}}, por lo que podrás elegir el asiento que prefieras dentro de la sección habilitada.",
                    {
                        direction: getRenderString(html`<span class="uppercase">${directionText}</span>`),
                        bundleInfo: getRenderString(html`
                            <span
                                class="inline-block rounded-md px-1 pb-px pt-1"
                                style="background-color: ${bundleColor};"
                            >
                                <img class="relative h-3" src=${bundleImg} />
                            </span>
                        `),
                    },
                ),
            ),
        );
    };

    const chileCompraNonBundleInfoTemplate = (directionText: string) =>
        chileCompraInfoCommonTemplate(
            unsafeHTML(
                i18next.t(
                    "Para tu vuelo de {{-direction}} seleccionaste Vuela Ligero, por lo que tu asiento será asignado aleatoriamente cuando realices tu check-in.",
                    { direction: getRenderString(html`<span class="uppercase">${directionText}</span>`) },
                ),
            ),
        );

    const htmlTemplate = () => {
        if (userContext?.chileCompra.role === "none") return "";

        const hasBundle =
            props.seatmapState?.CurrentJourney?.JourneyIndex === OUTBOUND
                ? bookingContext.hasBundleOutbound
                : bookingContext.hasBundleInbound;

        const directionText =
            props.seatmapState?.CurrentJourney?.JourneyIndex === OUTBOUND ? i18next.t("Ida") : i18next.t("Vuelta");

        return hasBundle
            ? chileCompraBundleInfoTemplate(directionText)
            : chileCompraNonBundleInfoTemplate(directionText);
    };

    return { htmlTemplate };
};
