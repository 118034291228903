import classNames from "classnames";
import i18next from "i18next";
import { ContactPassenger } from "../../component-models/itinerary/ContactPassenger";
import { ItineraryPageViewModel } from "../../component-models/itinerary/ItineraryPageViewModel";
import { PREFIX_CODES } from "../../shared/commonConstants";
import { Tab, TabSelectEvent } from "../../dc-components/dc-tabs";
import { TabName, useItineraryTabFactory } from "./useItineraryTabFactory";
import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { html } from "lit-html";
import { isDataFullyProvided } from "../../component-helpers/itinerary/ItineraryHelper";
import { itineraryTransactionsAccordionClassName, itineraryTransactionsTabClassName } from "../../shared/ScrollHelper";
import { useAppContext } from "../../managers/useAppContext";
import { useBookingContext } from "../../managers/useBookingContext";
import { useEffect, useMemo, useState } from "../../shared/haunted/CustomHooks";
import { useFlowContext } from "../../managers/useFlowContext";
import { useItineraryPageTabsPassengersEditableForm } from "./passengers-tab/useItineraryPageTabsPassengersEditableForm";
import { useItineraryPageTabsPassengersNonEditableForm } from "./passengers-tab/useItineraryPageTabsPassengersNonEditableForm";
import { useItineraryTabsDetails } from "./details-tab/useItineraryTabsDetails";
import { useItineraryTabsModifications } from "./modification-tab/useItineraryTabsModifications";
import { useItineraryTabsOptionals } from "./details-tab/useItineraryTabsOptionals";
import { useItineraryTabsOverview } from "./overview-tab/useItineraryTabsOverview";
import { useItineraryTabsRedemptionDetails } from "./details-tab/useItineraryTabsRedemptionDetails";
import { useItineraryTabsTransactions } from "./transactions-tab/useItineraryTabsTransactions";
import { useItineraryTabsTransactionsRedemption } from "./transactions-tab/useItineraryTabsTransactionsRedemption";
import { useReduxState } from "../../shared/redux/useReduxState";

export const tabHeads = ["Overview", "Details", "Transactions", "Modify", "Passengers", "Optionals"];
export type TabHead = (typeof tabHeads)[number];

export interface Props {
    model: ItineraryPageViewModel;
    cancelledJourneyIndices: number[];
    togglePrintingTransaction: () => void;
}

export const useItineraryTabs = (props: Props) => {
    // HELPERS

    const selectedItineraryTab = (): TabHead => {
        if (props.model.IsGdsOnHold) {
            return "Transactions";
        }

        const urlParams = new URLSearchParams(window.location.search);
        const paxTabUrlParam = urlParams.get("tab");

        return tabHeads.includes(paxTabUrlParam) ? (paxTabUrlParam as TabHead) : undefined;
    };

    const getInitialContactPassengers = () =>
        props.model.ContactPassengers.map(
            (pax): ContactPassenger =>
                pax.PaxAddress
                    ? pax
                    : {
                          Name: pax.Name,
                          PaxIndex: pax.PaxIndex,
                          PaxAddress: {
                              Address: "",
                              City: "",
                              Country: "",
                              Email: "",
                              PhoneNumber: "",
                              PhonePrefix: PREFIX_CODES.get(appContext.Culture),
                          },
                          FrequentFlyer: pax.FrequentFlyer,
                          IsFrequentFlyerProvided: pax.IsFrequentFlyerProvided,
                      },
        );

    const getInitialSelectedTab = () =>
        flowContext.isFarelockRoundTwo || props.model.IsFarelockAmountNotFullyPaid
            ? "Transactions"
            : selectedItineraryTab() !== undefined
              ? selectedItineraryTab()
              : "Overview";

    // EVENT HANDLERS

    const handleAccordionOpen = (tabName: string) =>
        tabName === openMobileAccordion
            ? setOpenMobileAccordion(undefined)
            : setOpenMobileAccordion(tabName as TabHead);

    const handleTabSelect = (e: TabSelectEvent) => {
        setSelectedTab(e.detail.name as TabHead);
    };

    // COMPONENT

    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();
    const [userContext] = useReduxState("userContext");

    const [openMobileAccordion, setOpenMobileAccordion] = useState<TabHead>(undefined);
    const [isPassengerDataProvided, setIsPassengerDataProvided] = useState<boolean>(false);
    const [contactPassengers, setContactPassengers] = useState<ContactPassenger[]>(getInitialContactPassengers());
    const [selectedTab, setSelectedTab] = useState<TabHead>(getInitialSelectedTab());

    const mobileDetails = useItineraryTabsDetails({
        model: props.model,
        isMobile: true,
    });

    const details = useItineraryTabsDetails({
        model: props.model,
        isMobile: false,
    });

    const redemptionDetails = useItineraryTabsRedemptionDetails({
        model: props.model,
        isMobile: false,
    });

    const overview = useItineraryTabsOverview({
        model: props.model,
        cancelledJourneyIndices: props.cancelledJourneyIndices,
    });

    const optionals = useItineraryTabsOptionals({
        model: props.model,
    });

    const transactions = useItineraryTabsTransactions({
        model: props.model,
        togglePrintingTransaction: props.togglePrintingTransaction,
    });

    const redemptionTransactions = useItineraryTabsTransactionsRedemption({
        model: props.model,
        togglePrintingTransaction: props.togglePrintingTransaction,
    });

    const modifications = useItineraryTabsModifications({
        model: props.model,
    });

    const editableForm = useItineraryPageTabsPassengersEditableForm({
        model: props.model,
        contactPassengers,
        isDataProvided: isPassengerDataProvided,
        setContactPassengers,
    });

    // DEVNOTE: This is needed because otherwise the same form checkbox with
    // the same guid would be in the DOM twice.
    const mobileEditableForm = useItineraryPageTabsPassengersEditableForm({
        model: props.model,
        contactPassengers,
        isDataProvided: isPassengerDataProvided,
        setContactPassengers,
    });

    const nonEditableForm = useItineraryPageTabsPassengersNonEditableForm({
        model: props.model,
        contactPassengers,
        setIsDataProvided: setIsPassengerDataProvided,
    });

    const tabFactory = useItineraryTabFactory({ model: props.model });
    const tabFactoryResults = useMemo(() => tabFactory.getTabs(), [userContext?.userRole]);

    const displayedTabs = (isMobile?: boolean) => {
        const mappedTabs = tabs(isMobile);
        return tabFactoryResults.map((tabName) => mappedTabs.get(tabName));
    };

    useEffect(() => {
        setIsPassengerDataProvided(isDataFullyProvided(props.model.ContactPassengers));

        setOpenMobileAccordion(
            flowContext.isFarelockRoundTwo || props.model.IsFarelockAmountNotFullyPaid
                ? "Transactions"
                : selectedItineraryTab() !== undefined
                  ? selectedItineraryTab()
                  : "Overview",
        );
    }, []);

    // TEMPLATES

    const mobileAccordionItemTemplate = (tab: Tab, i: number) => {
        const accordionItemClassMap = classNames("i2-accordion-header no-print", {
            first: i === 0,
            last: i === tabFactoryResults.length - 1,
            open: tab.name === openMobileAccordion,
            highlighted: tab.name === "Passengers" && !isPassengerDataProvided,
        });

        return html`
            <div
                class=${accordionItemClassMap}
                data-test-id=${getTestId(T.ITINERARY.TAB, { c: tab.name, m: true })}
                @click=${() => handleAccordionOpen(tab.name)}
            >
                <span>
                    ${tab.label}
                    ${tab.name === "Passengers" && !isPassengerDataProvided
                        ? html`
                              <i
                                  class="js-icon-refund js-refund-rw-highlight-icon-mobile"
                                  data-test-id=${getTestId(T.ITINERARY.UNFILLED_FORM_HIGHLIGHT_ICON, { m: true })}
                              ></i>
                          `
                        : ""}
                </span>
                <i class="js-icon js-circle-chevron-right"></i>
            </div>
            <div class="i2-accordion-content ${tab.classToScrollTo ? tab.classToScrollTo : ""}">
                ${(tab.template as Function)()}
            </div>
        `;
    };

    const mobileAccordionTemplate = () => displayedTabs(true).map(mobileAccordionItemTemplate);

    const externallyMadeBookingInfoTemplate = () =>
        props.model.IsGds || props.model.IsDigitalApi
            ? html`
                  <div class="i2-gds-optionals-info no-print">
                      ${i18next.t(
                          "Si quieres revisar el listado de todos los opcionales (equipaje, asiento, entre otros) incluidos en tu reserva, haz click en “Opcionales”.",
                      )}
                  </div>
              `
            : "";

    const editableFormTemplate = (isMobile: boolean) =>
        isMobile ? mobileEditableForm.htmlTemplate() : editableForm.htmlTemplate();

    const tabs = (isMobile?: boolean) =>
        new Map<TabName, Tab>([
            [
                "Details",
                {
                    name: "Details",
                    label: i18next.t("Detalle de tu reserva"),
                    template: isMobile ? mobileDetails.htmlTemplate : details.htmlTemplate,
                },
            ],
            [
                "Modify",
                {
                    name: "Modify",
                    label: i18next.t("Modifica tu reserva"),
                    template: modifications.htmlTemplate,
                },
            ],
            ["Optionals", { name: "Optionals", label: i18next.t("Opcionales"), template: optionals.htmlTemplate }],
            [
                "Overview",
                {
                    name: "Overview",
                    label: i18next.t("Tu itinerario"),
                    template: overview.htmlTemplate,
                },
            ],
            [
                "Passengers",
                {
                    name: "Passengers",
                    label: i18next.t("Pasajeros"),
                    isHighlighted: !isPassengerDataProvided,
                    template: () =>
                        isPassengerDataProvided ? nonEditableForm.htmlTemplate() : editableFormTemplate(isMobile),
                },
            ],
            [
                "RedemptionDetails",
                {
                    name: "RedemptionDetails",
                    label: i18next.t("Detalles de tu reserva"),
                    template: redemptionDetails.htmlTemplate,
                },
            ],
            [
                "RedemptionTransactions",
                {
                    classToScrollTo: isMobile
                        ? itineraryTransactionsAccordionClassName
                        : itineraryTransactionsTabClassName,
                    name: "RedemptionTransactions",
                    label: i18next.t("Transacciones"),
                    withWarning: props.model.IsGdsOnHold && bookingContext.isGdsBooking,
                    template: redemptionTransactions.htmlTemplate,
                },
            ],
            [
                "Transactions",
                {
                    classToScrollTo: isMobile
                        ? itineraryTransactionsAccordionClassName
                        : itineraryTransactionsTabClassName,
                    name: "Transactions",
                    label: i18next.t("Transacciones"),
                    withWarning: props.model.IsGdsOnHold && bookingContext.isGdsBooking,
                    template: transactions.htmlTemplate,
                },
            ],
        ]);

    const htmlTemplate = () => {
        const tabClassMap = classNames("i2-itinerary-section i2-tabs", {
            "divide-four": tabFactoryResults.length === 4,
            "divide-five": tabFactoryResults.length === 5,
            "divide-six": tabFactoryResults.length === 6,
        });

        return html`
            ${externallyMadeBookingInfoTemplate()}
            <div class=${tabClassMap}>
                <dc-tabs
                    class="hidden-xs"
                    data-test-id="itinerary-data-tabs"
                    .data=${displayedTabs()}
                    .selectedTab=${selectedTab}
                    @tabSelect=${handleTabSelect}
                >
                </dc-tabs>
                <div class="visible-xs" data-test-id="itinerary-data-mobile-accordion">
                    ${mobileAccordionTemplate()}
                </div>
            </div>
        `;
    };

    return { htmlTemplate, setOpenMobileAccordion, setSelectedTab };
};
