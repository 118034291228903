import i18next from "i18next";
import { html } from "lit-html";
import { BundleType, CustomizedBundle } from "../../../component-models/flight/BundleOffersV2Model";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { useMemo } from "../../../shared/haunted/CustomHooks";
import { useBundleSsrItems } from "./useBundleSsrItems";
import { FlightPageContext } from "../../../component-models/flight/contexts/FlightPageContext";
import classNames from "classnames";
import { OUTBOUND, REDEMPTION_MILES_CODE } from "../../../shared/commonConstants";
import { useNumberFormatter } from "../../../shared/useNumberFormatter";
import { useFlowContext } from "../../../managers/useFlowContext";

export interface BundlesSelectorMobileItemProps {
    bundle: CustomizedBundle;
    context: FlightPageContext;
    isActive: boolean;
    journeyIndex: number;
    onScroll: () => void;
    onSelect: (e: MouseEvent, bundleType: BundleType) => void;
}

export const useBundlesSelectorMobileItem = (props: BundlesSelectorMobileItemProps) => {
    const bundleSsrItemsMobile = useBundleSsrItems({
        bundle: props.bundle,
        context: props.context,
        isSelected: false,
        journeyIndex: props.journeyIndex,
    });

    const { formatNumber } = useNumberFormatter();

    const flowContext = useFlowContext();

    const flight = useMemo(
        () =>
            props.journeyIndex === OUTBOUND
                ? props.context?.flightState?.selectedOutboundFlight
                : props.context?.flightState?.selectedInboundFlight,
        [props.context?.flightState, props.journeyIndex],
    );

    const lowestBundlePriceForCurrentRedemptionOffer = useMemo(
        () =>
            flight && flowContext.isRedemptionFlow
                ? props.context.bundleState?.bundleOffers
                      .find((o) => o.SellKey === flight?.SellKey)
                      .RedemptionOffers.find((b) => b.BundleType === "None").UnFormattedPrice
                : 0,
        [flight?.SellKey, props.context.bundleState?.bundleOffers, flowContext.isRedemptionFlow],
    );

    const showFlightFeeInBundles = useMemo(() => props.context?.bundleState?.bundlesMode === "PerLeg", [props.context]);

    const priceInfoTemplate = () =>
        !flowContext.isRedemptionFlow
            ? html`
                  <div class=${classNames("bundle-price-info", { "no-bundle": props.bundle.BundleType === "None" })}>
                      *${i18next.t("B-PerPersonInfo")}
                  </div>
              `
            : "";

    const redemptionPriceTemplate = () =>
        flowContext.isRedemptionFlow
            ? html`
                  <div class=${classNames("bundle-price mt-4", { "no-bundle": props.bundle.BundleType === "None" })}>
                      +${formatNumber({
                          amount: props.bundle.UnFormattedPrice - lowestBundlePriceForCurrentRedemptionOffer,
                          leadingSign: false,
                          currency: REDEMPTION_MILES_CODE,
                      })}
                      <span
                          class=${classNames("bundle-price-miles", { "no-bundle": props.bundle.BundleType === "None" })}
                      >
                          ${i18next.t("Miles")}
                      </span>
                  </div>
              `
            : "";

    const regularPriceTemplate = () =>
        !flowContext.isRedemptionFlow
            ? html`
                  <div class=${classNames("bundle-price", { "no-bundle": props.bundle.BundleType === "None" })}>
                      ${!showFlightFeeInBundles && !props.bundle.BundleDiscountPercentage
                          ? html` <span>&nbsp;</span> `
                          : props.bundle.FormattedPrice}
                  </div>
              `
            : "";

    const headerTemplate = () => html`
        <div
            class=${classNames("bundle-header", { full: props.bundle.BundleType === "Full" })}
            style="background-color: ${props.bundle.Color};"
        >
            <img
                class="header-img"
                src=${props.bundle.HeaderImgUrl}
                data-test-id=${getTestId(T.BUNDLE.HEADER_IMAGE, {
                    j: props.journeyIndex,
                    c: props.bundle.BundleType.toLowerCase(),
                    m: true,
                })}
            />

            ${redemptionPriceTemplate()} ${regularPriceTemplate()} ${priceInfoTemplate()}
        </div>
    `;

    const footerTemplate = () => {
        const dataTestId = getTestId(T.BUNDLE.BUTTON_MOBILE, { c: props.bundle.BundleType.toLowerCase() });

        const buttonText =
            props.bundle.BundleType === "None"
                ? i18next.t("B-BtnNoThanks")
                : props.bundle.BundleType === "Simple"
                  ? i18next.t("B-BtnSimpleBundle")
                  : i18next.t("B-BtnFullBundle");

        return html`
            <div class="bundle-footer">
                <button
                    class="bundle-button"
                    data-test-id=${dataTestId}
                    @click=${(e: MouseEvent) => props.onSelect(e, props.bundle.BundleType)}
                >
                    ${buttonText}
                    <i class="js-icon js-chevron-right"></i>
                </button>
            </div>
        `;
    };

    const bestPriceTemplate = () => html`
        <div class="best-price-container">
            <div class="best-price-background">
                <div class="best-price-logos">
                    <div class="star-container side-star">&starf;</div>
                    <div class="star-container">&starf;</div>
                    <div class="star-container side-star">&starf;</div>
                </div>
                <div class="best-price-text">${i18next.t("B-BestPrice")}</div>
            </div>
        </div>
    `;

    const htmlTemplate = () => html`
        <div
            class="bundle-container"
            data-test-id=${getTestId(T.BUNDLE.SELECTOR_OPTION, {
                j: props.journeyIndex,
                c: props.bundle.BundleType.toLowerCase(),
            })}
        >
            <div class=${classNames("bundle-content", { active: props.isActive })} @click=${props.onScroll}>
                ${props.bundle.BundleType === "Full" ? bestPriceTemplate() : ""} ${headerTemplate()}
                ${bundleSsrItemsMobile.htmlTemplate()} ${footerTemplate()}
            </div>
        </div>
    `;

    return { htmlTemplate };
};
