import i18next from "i18next";
import { html } from "lit-html";
import { useModal } from "../../shared/useModal";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { useReduxState } from "../../../shared/redux/useReduxState";

export const useNotEnoughMilesModal = () => {
    const [currency] = useReduxState("booking.currency");

    const handleRedirectButtonClick = () => (window.location.href = "/");

    const headerTemplate = () => html`
        <div class="flex h-full w-full flex-col items-center justify-center">
            <i class="js-new js-n-triangle-exclamation-thick"></i>
            <span>${i18next.t("Atención")}!</span>
        </div>
    `;

    const htmlTemplate = () => html`
        <div class="modal-body text-center leading-normal">
            <div class="text-medium">${i18next.t("not-enough-miles")}</div>
            <div class="mt-8 flex w-full flex-col items-center justify-around">
                <a class="rounded-primary-btn mb-4 sm:mb-0" @click=${handleRedirectButtonClick}>
                    ${unsafeHTML(
                        i18next.t("Quote in {{-currencyCode}}", {
                            currencyCode: currency.toUpperCase(),
                        }),
                    )}
                </a>
                <a
                    class="mt-3 cursor-pointer select-none text-center text-brand-secondary underline text-medium hover:no-underline sm:text-base md:text-medium"
                    @click=${() => modal.close()}
                >
                    ${i18next.t("Choose another flight")}
                </a>
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { onClose: () => modal.hide(), isClosable: true },
        content: { classNames: "booking-modal-content american-modal-content", template: htmlTemplate },
        header: { template: headerTemplate },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};
