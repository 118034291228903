import { html, useCallback, useState } from "haunted";
import { useModal } from "../shared/useModal";
import { SpaContainerViewModel } from "../../component-models/spa/SpaContainerViewModel";
import { useAppContext } from "../../managers/useAppContext";
import { getStringForCulture } from "../../component-helpers/stringHelper";
import { SpaModal } from "../../component-models/spa/ApiSpaModalSettings";
import { useBookingDataManager } from "../../managers/useBookingDataManager";
import BookingData from "../../shared/BookingData";
import i18next from "i18next";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { showLoader } from "../../shared/common";
import { useBookingManager } from "../../managers/useBookingManager";
import { ApiBaggageViewModel } from "../../component-models/baggage/ApiBaggageViewModel";
import { SeatmapModel } from "../../component-models/seatmap/SeatmapModel";
import { useSsrHelper } from "./useSsrHelper";
import { useFlowContext } from "../../managers/useFlowContext";
import { setTestCircuitDomValue } from "../../testing-helpers/TestCircuitHelper";
import { useReduxState } from "../../shared/redux/useReduxState";
import { SpaSectionType } from "./spa-container";
import { useNumberFormatter } from "../../shared/useNumberFormatter";
import { ApiExtrasSpaSectionViewModel } from "../../component-models/spa/ExtrasSpaSectionViewModel";

export interface Props {
    model: SpaContainerViewModel;
    extrasViewModel: ApiExtrasSpaSectionViewModel;
    setBaggageModel: (model: ApiBaggageViewModel) => void;
    setSeatmapModel: (model: SeatmapModel) => void;
    setExtrasModel: (model: ApiExtrasSpaSectionViewModel) => void;
}

export const useSpaModal = (props: Props) => {
    // COMPONENTS

    const appContext = useAppContext();
    const flowContext = useFlowContext();

    const bookingManager = useBookingManager();
    const ssrHelper = useSsrHelper(props);
    const { handleBookingDataCallback } = useBookingDataManager();
    const { formatNumber } = useNumberFormatter();

    const [onContinue, setOnContinue] = useState<() => () => Promise<void> | void>(undefined);
    const [modalsToDisplay, setModalsToDisplay] = useState<SpaModal[]>([]);
    const [currentModalIndex, setCurrentModalIndex] = useState<number>(-1);

    const [currentSpaSection] = useReduxState("spa.activeSection");

    // HELPERS

    const initModals = useCallback(
        (callback: () => Promise<void> | void) => {
            setOnContinue(() => callback);
            handleBookingDataCallback(appContext.Culture, async (bookingData) => {
                // DEVNOTE: Display any modal only if there is no bundle selected
                if ([bookingData.OutboundBundleCode, bookingData.ReturnBundleCode].some((bundle) => bundle)) {
                    setTestCircuitDomValue("SpaModalToBeOpened", "NO");
                    await callback();
                    return;
                }

                const modals = props.model.SpaModalSettings?.SpaModals?.map((modal) => modal) || [];
                const relevantModals = modals.filter((modal: SpaModal) => isModalRelevant(modal, bookingData));
                setModalsToDisplay(relevantModals);

                if (relevantModals.length > 0) {
                    setTestCircuitDomValue("SpaModalToBeOpened", "YES");
                    setCurrentModalIndex(0);
                    modal.open();
                } else {
                    setTestCircuitDomValue("SpaModalToBeOpened", "NO");
                    await callback();
                }
            });
        },
        [props.model, appContext.Culture],
    );

    const updateCurrentSpaModel = (response: any) => {
        if (response?.BaggageModel) {
            props.setBaggageModel(response.BaggageModel);
        }
        if (response?.ExtrasModel) {
            props.setExtrasModel(response.ExtrasModel);
        }
    };

    const sendApplyFlexiRequest = async (loader: JsLoader) => {
        if (props.extrasViewModel.ExtrasModel.FlexiFeeJourneys.length > 1) {
            await bookingManager.postAddFlexiFee({ journeyIndex: "0" }, undefined, loader);
            const response = await bookingManager.postAddFlexiFee({ journeyIndex: "1" }, undefined, loader);
            updateCurrentSpaModel(response);
            return;
        }
        const response = await bookingManager.postAddFlexiFee({ journeyIndex: "0" }, undefined, loader);
        updateCurrentSpaModel(response);
    };

    const sendSsrRequest = async () => {
        const ssrTypeOfCurrentRequest = ssrHelper.getSsrType(modalsToDisplay[currentModalIndex].SsrCodes[0]);
        const loader = showLoader({});

        if (ssrTypeOfCurrentRequest === "FlexiFee") {
            await sendApplyFlexiRequest(loader);
            return;
        }

        const ssrKeys = ssrHelper.generateSsrKeys(modalsToDisplay[currentModalIndex].SsrCodes[0]);
        if (ssrKeys.length === 0) return;

        const body = ssrKeys.reduce(
            (bodyObj, key, i) => {
                bodyObj[`selectedJourneySsrs[${i}]`] = key;
                return bodyObj;
            },
            {} as { [key: string]: string },
        );

        const response = await bookingManager.postSsrUpdate(body, undefined, loader);
        updateCurrentSpaModel(response);
    };

    const showModalInCurrentStep = (modal: SpaModal, currentStep: SpaSectionType) => {
        switch (currentStep) {
            case "Passengers":
                return modal.Steps.AfterPassenger;
            case "Baggage":
                return modal.Steps.AfterBaggage;
            case "Seatmap":
                return modal.Steps.AfterSeats;
            case "Extras":
                return modal.Steps.AfterExtras;
            default:
                return false;
        }
    };

    const showModalInCurrentFlow = (modal: SpaModal) => {
        if (flowContext.isCheckinFlow) return false;
        if (flowContext.isBookingFlow) return modal.AvailableDuringBooking;
        if (flowContext.isPostBookingFlow) return modal.AvailablePostBooking;

        return false;
    };

    const isRecommendedSsrAlreadySelected = (modal: SpaModal, bookingData: BookingData) =>
        modal.SsrCodes.some((ssrCode) => {
            const ssrType = ssrHelper.getSsrType(ssrCode);
            if (!ssrType) return false;

            return bookingData.Passengers.some((passenger) =>
                [...passenger.Ancillaries.Outbound.Selected, ...(passenger.Ancillaries.Return?.Selected || [])].some(
                    (ancillary) => ssrHelper.getSsrCodesByType(ssrType)?.includes(ancillary.ChargeCode),
                ),
            );
        });

    const isRecommendedSsrAvailable = (modal: SpaModal, bookingData: BookingData) =>
        modal.SsrCodes.some((ssrCode) => {
            const ssrType = ssrHelper.getSsrType(ssrCode);
            if (!ssrType) return false;

            return bookingData.Passengers.some((passenger) => {
                const isOutboundAvailable = passenger.Ancillaries.Outbound.NotSelected.some((ancillary) =>
                    ssrHelper.getSsrCodesByType(ssrType)?.includes(ancillary.ChargeCode),
                );

                const isInboundAvailable = passenger.Ancillaries.Return
                    ? passenger.Ancillaries.Return.NotSelected.some((ancillary) =>
                          ssrHelper.getSsrCodesByType(ssrType)?.includes(ancillary.ChargeCode),
                      )
                    : true;

                return isOutboundAvailable && isInboundAvailable;
            });
        });

    const isModalRelevant = (modal: SpaModal, bookingData: BookingData) => {
        if (!showModalInCurrentFlow(modal)) return false;
        if (!modal.CultureCodes.some((country) => country.toLowerCase() === appContext.Culture)) return false;
        if (!modal.RoleCodes?.some((role) => role === bookingData?.Role)) return false;
        if (isRecommendedSsrAlreadySelected(modal, bookingData)) return false;
        if (!isRecommendedSsrAvailable(modal, bookingData)) return false;
        if (!showModalInCurrentStep(modal, currentSpaSection)) return false;

        return true;
    };

    const openNextSpaModal = async () => {
        if (modalsToDisplay.length > currentModalIndex + 1) {
            window.setTimeout(() => {
                setTestCircuitDomValue("SpaModalToBeOpened", "YES");
                setCurrentModalIndex(currentModalIndex + 1);
                modal.open();
            }, 350);
        } else {
            setTestCircuitDomValue("SpaModalToBeOpened", "NO");
            await onContinue();
        }
    };

    // EVENT HANDLERS

    const handleAcceptOffer = async () => {
        modal.hide();
        await sendSsrRequest();

        await openNextSpaModal();
    };

    // TEMPLATES

    const headerTemplate = () => html`
        <img
            class="visible-xs"
            src=${getStringForCulture(modalsToDisplay[currentModalIndex].Images.DesktopImageUrl, appContext.Culture)}
        />
        <img
            class="visible-sm"
            src=${getStringForCulture(modalsToDisplay[currentModalIndex].Images.DesktopImageUrl, appContext.Culture)}
        />
        <img
            class="visible-md-up"
            src=${getStringForCulture(modalsToDisplay[currentModalIndex].Images.DesktopImageUrl, appContext.Culture)}
        />
    `;

    const htmlTemplate = () => html`
        <div class="flex flex-col items-center justify-center border-t-4 border-solid border-brand-primary p-5 pt-8">
            <button
                class="rounded-primary-btn with-arrow"
                data-test-id=${T.COMMON.SPA_MODAL_ACCEPT_BUTTON}
                @click=${handleAcceptOffer}
            >
                ${i18next.t("Lo quiero desde")}
                ${formatNumber({
                    amount: ssrHelper.getSsrAmount(modalsToDisplay[currentModalIndex].SsrCodes[0]),
                    leadingSign: true,
                })}
            </button>
            <span class="mt-2 text-sm sm:text-base">*${i18next.t("Precio desde, por pasajero por tramo")}</span>
            <a
                class="mt-4 cursor-pointer select-none text-center text-brand-secondary underline text-medium sm:text-lg"
                @click=${modal.close}
                >${i18next.t("No quiero")}</a
            >
        </div>
    `;

    const modal = useModal({
        closing: {
            buttonTestId: T.COMMON.SPA_MODAL_CLOSE_BUTTON,
            isClosable: true,
            onClose: openNextSpaModal,
        },
        header: { template: headerTemplate },
        content: { template: htmlTemplate },
        overlay: { classNames: "spa-modal", testId: T.COMMON.SPA_MODAL },
    });

    return {
        startDisplayingSpaModals: initModals,
        htmlTemplate: modal.htmlTemplate,
    };
};
