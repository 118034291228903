import { html } from "haunted";
import i18next from "i18next";
import { unsafeHTML } from "lit-html/directives/unsafe-html";

export const useChileCompraContactPage = () => {
    // TEMPLATES

    const headerTemplate = () => html`
        <div class="cug2b-contact-header chile-compra">
            <div class="cug2b-title-container chile-compra">
                <i class="js-icon-cug js-cug-phone"></i>
                <h1>${i18next.t("Comunícate a nuestro Contact Center para clientes Chile Compras")}</h1>
            </div>
        </div>
    `;

    const contentTemplate = () => html`
        <div class="cug2b-contact-content">
            <div class="cug2b-contact-img"></div>
            <div class="cug2b-contact-info peru-compra">
                <h2>
                    ${unsafeHTML(
                        i18next.t(
                            "<span> Si tienes dudas adicionales y deseas comunicarte directamente con Chile Compras, </span> aquí tienes los siguientes medios de contacto para que puedas resolver tus inquietudes:",
                        ),
                    )}
                </h2>
                <ul>
                    <li><span>Teléfono:</span> 600 600 1311</li>
                    <li><span>Mail:</span> soporte.chilecompra@jetsmart.com</li>
                </ul>
            </div>
        </div>
    `;

    const htmlTemplate = () => html` ${headerTemplate()}${contentTemplate()} `;

    return { htmlTemplate };
};
