import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import { html } from "lit-html";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { paxAmount, paxLabel } from "../../../../component-helpers/BaggageHelper";
import { baggageEditButton } from "../common/baggageEditButton";
import { perBookingQuantityButtons } from "./perBookingQuantityButtons";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import { StaffBaggage } from "../common/useStaffBaggage";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useCallback } from "haunted";
import { useBaggageUtils } from "../common/useBaggageUtils";
import { BancoEstadoBaggage } from "../common/useBancoEstadoBaggage";

export interface Props {
    baggageSection: BaggageSection;
    bancoEstadoBaggage: BancoEstadoBaggage;
    staffBaggage: StaffBaggage;
    setIsOversizedBaggageOpen: (isOpen: boolean) => void;
}

export const useMobilePerBookingOversized = (props: Props) => {
    const bookingContext = useBookingContext();

    const { formattedLowestNextPrice } = useBaggageUtils();

    const editButton = baggageEditButton({
        dataTestId: getTestId(T.BAGGAGE.OPEN_PER_JOURNEY_PER_PAX_VIEW_BUTTON, {
            c: "oversizedBaggage",
            m: true,
        }),
        isDisabled: props.baggageSection.perBooking.quantity(props.baggageSection.journeys) === undefined,
        onClick: () => handlePerJourneyPerPaxViewOpen(),
    });

    const quantityButtons = perBookingQuantityButtons({
        baggageSection: props.baggageSection,
        bagType: "oversizedBaggage",
        isCheckinClosedInbound: bookingContext.isCheckinClosedInbound,
        isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
        isMobile: true,
        paxCount: bookingContext.adultsCount + bookingContext.childrenCount,
    });

    const isAddAvailable = useMemo(
        () =>
            props.baggageSection.perBooking.isAddAvailable({
                baggageSectionJourneys: props.baggageSection.journeys,
                bagType: "oversizedBaggage",
                canGetFreeBancoEstadoBaggage: false,
                isCheckinClosedInbound: bookingContext.isCheckinClosedInbound,
                isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
                paxCount: bookingContext.adultsCount + bookingContext.childrenCount,
            }),
        [props.baggageSection.journeys, bookingContext],
    );

    const notBoughtPerBookingBag = useMemo(
        () =>
            isAddAvailable &&
            props.baggageSection.sectionUi.uiState.journeys.every((stateJourney) =>
                stateJourney.passengers.every((passenger) => {
                    const journeyPassenger = props.baggageSection.journeys
                        .find((journey) => journey.index === stateJourney.index)
                        .passengers.find((pax) => pax.index === passenger.index);

                    return journeyPassenger.quantity === 0;
                }),
            ),
        [props.baggageSection.journeys, isAddAvailable, props.baggageSection.sectionUi.uiState.journeys],
    );

    const canSwitchView = useMemo(
        () =>
            props.baggageSection.sectionUi.uiState.journeys.every((stateJourney): boolean =>
                stateJourney.passengers.every((statePassenger): boolean => {
                    const journeyPassenger = props.baggageSection.journeys
                        .find((journey) => journey.index === stateJourney.index)
                        .passengers.find((pax) => pax.index === statePassenger.index);

                    return statePassenger.hasSelected && journeyPassenger.quantity > 0;
                }),
            ),
        [props.baggageSection.journeys, props.baggageSection.sectionUi.uiState.journeys],
    );

    const unformattedLowestNextPrice = useCallback(
        (isMinimum: boolean) =>
            props.baggageSection.perBooking.nextPrice({
                baggageSectionJourneys: props.baggageSection.journeys,
                bagType: "oversizedBaggage",
                bancoEstadoBaggage: props.bancoEstadoBaggage,
                isMinimum,
                isOriginal: false,
                staffBaggage: props.staffBaggage,
            }),
        [props.baggageSection.journeys, props.staffBaggage, props.bancoEstadoBaggage],
    );

    // Event handlers

    const handlePerBookingAdd = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        await props.baggageSection.add();
    };

    const handlePerJourneyPerPaxViewOpen = () => {
        props.baggageSection.sectionUi.openPerJourneyPerPaxView();
        props.setIsOversizedBaggageOpen(true);
    };

    // Templates

    const perBookingNextPriceTemplate = () =>
        isAddAvailable
            ? html`
                  <div class="b2-add-another-oversized">
                      ${i18next.t("Agrega otro por")}
                      <span
                          class="b2-amount"
                          data-test-value=${unformattedLowestNextPrice(false)}
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_NEXT_PRICE, {
                              c: "oversizedBaggage",
                              m: true,
                          })}
                      >
                          ${formattedLowestNextPrice(unformattedLowestNextPrice(false))}
                      </span>
                  </div>
              `
            : "";

    const perBookingMobileFreeSelectedTemplate = () => html`
        <div class="b2m-add-oversized">
            ${i18next.t("Agregar por")}
            <span
                data-test-value=${unformattedLowestNextPrice(true)}
                data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_MINIMUM_PRICE, {
                    c: "oversizedBaggage",
                    m: true,
                })}
            >
                ${formattedLowestNextPrice(unformattedLowestNextPrice(true))}
            </span>
        </div>
        <button
            @click=${handlePerBookingAdd}
            data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_ADD_FIRST_BUTTON, {
                c: "oversizedBaggage",
                m: true,
            })}
            class="b2-primary-button"
        >
            ${i18next.t("Agregar")}
        </button>
    `;

    const perBookingMobilePaidSelectedTemplate = () => html`
        <div class="flex flex-col items-center justify-center">
            <div class="flex w-full items-center justify-around">
                <button disabled class="b2-primary-button ticked">${i18next.t("Agregado")}</button>
                <div class="b2-oversized-quantity">
                    <div class="ac-per-booking-quantity-buttons">${quantityButtons.htmlTemplate()}</div>
                </div>
            </div>
            ${perBookingNextPriceTemplate()}
        </div>
    `;

    const perBookingMobileViewSwitchTemplate = () => {
        const infoText = (() => paxAmount(props.baggageSection.sectionUi.uiState) === 1)()
            ? i18next.t("Estás agregando para todos los tramos")
            : i18next.t("Estás agregando para todos los pasajeros y tramos");

        return canSwitchView
            ? html`
                  <div class="b2m-view">
                      <div class="b2m-info-amount">
                          <div class="b2m-view-info">${infoText}</div>
                          <div class="b2m-pax-amount">
                              ${paxAmount(props.baggageSection.sectionUi.uiState)}
                              ${paxLabel(paxAmount(props.baggageSection.sectionUi.uiState))}
                          </div>
                      </div>
                      ${editButton.htmlTemplate()}
                  </div>
              `
            : "";
    };

    const htmlTemplate = () =>
        props.baggageSection.sectionUi.uiState.perJourneyPerPaxState !== "open"
            ? html`
                  <div class="mb-2 mt-4 flex items-center justify-around">
                      ${notBoughtPerBookingBag
                          ? perBookingMobileFreeSelectedTemplate()
                          : perBookingMobilePaidSelectedTemplate()}
                  </div>
                  ${perBookingMobileViewSwitchTemplate()}
              `
            : html``;

    return { htmlTemplate };
};
