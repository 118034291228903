import { ACTIONS_WITHOUT_LOGIN, ACTION_NAMES, COOKIE_NAMES } from "../../shared/commonConstants";
import { useState } from "../../shared/haunted/CustomHooks";
import { html, useEffect } from "haunted";
import i18next from "i18next";
import { getCookie } from "../../shared/cookieHandling";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useLoginStatus } from "./useLoginStatus";
import classNames from "classnames";
import { useFlowContext } from "../../managers/useFlowContext";

export interface Props {
    profileUrl: string;
    shouldReloadOnLogin: boolean;
}

export const useMainMenu = (props: Props) => {
    const flowContext = useFlowContext();
    const { triggers } = usePubSub();

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const loginStatus = useLoginStatus(props);

    // Helpers
    const init = () => {
        const peruCompraLoginCookie = getCookie(COOKIE_NAMES.PeruCompraLogin);

        if (peruCompraLoginCookie === "true") {
            window.setTimeout(() => triggers.peruCompra.openLoginInfoForPCRA.publish({}), 500);
        }

        const chileCompraLoginCookie = getCookie(COOKIE_NAMES.ChileCompraLogin);

        if (chileCompraLoginCookie === "true") {
            window.setTimeout(() => triggers.chileCompra.openLoginInfoForChileCompra.publish({}), 500);
        }
    };

    // DEVNOTE JET-6464, hide login but keep culture selector
    const shouldHideLoginAndRegister = () => flowContext.action === ACTION_NAMES.DC_REGISTER;

    const showLogin = () => !ACTIONS_WITHOUT_LOGIN.includes(flowContext.action);

    useEffect(init, []);

    // Templates

    const htmlTemplate = () => {
        const tempClassNames = classNames("menu-container", { "no-mobile-opener": shouldHideLoginAndRegister() });

        return showLogin()
            ? html`
                  <div class=${tempClassNames}>
                      ${!shouldHideLoginAndRegister()
                          ? html`
                                <input class="mobile-navigation" @click=${() => setIsOpen(!isOpen)} type="checkbox" />
                                <div class="right">${loginStatus.htmlTemplate()}</div>
                                ${isOpen
                                    ? html` <div class="mobile-navigation">${i18next.t("MobileMenuClose")}</div> `
                                    : html` <div class="mobile-navigation">${i18next.t("MobileMenuLabel")}</div> `}
                            `
                          : ""}

                      <ac-culture-selector></ac-culture-selector>
                  </div>
              `
            : "";
    };

    return { htmlTemplate };
};
