import { useRef } from "haunted";
import "./dc-select.scss";

import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";

import { ifDefined } from "lit-html/directives/if-defined";
import { HauntedFunc } from "../shared/haunted/HooksHelpers";
import { useEffect, useState } from "../shared/haunted/CustomHooks";
import { arrayEquals } from "../component-helpers/collectionHelper";
import { isEmpty } from "../component-helpers/stringHelper";

export const observedAttributes: (keyof Properties)[] = [];
export const name = "dc-select";
export type FilterType = "startsWith" | "includes";

export type DataSourceType =
    | (SelectItem | string | number | { label: string; value: any })[]
    | ((text: string) => (SelectItem | string | number)[] | string)
    | ((text: string) => Promise<(SelectItem | string | number)[] | string>);

const DEFAULTS: Properties = {
    multiSelect: false,
    dataSource: [] as SelectItem[],
    selectedValues: undefined as string[],
    selectedIndices: undefined as number[],
    filterable: false,
    debounceMs: 150,
    filterProps: { type: "startsWith", ignoreCase: true },
    readonly: false,
    tooltip: "",
    isButton: false,
    buttonText: "",
};

export interface Properties {
    multiSelect: boolean;
    label?: string;
    dataSource: DataSourceType;
    validationMessage?: string;
    selectedValues: number | string | (number | string)[];
    selectedIndices: number[];
    filterable: boolean;
    filterProps: {
        type: FilterType;
        ignoreCase: boolean;
    };
    readonly: false;
    tooltip?: string;
    debounceMs?: number;
    placeholder?: string;
    name?: string;
    isButton?: boolean;
    buttonText?: string;
}

interface ChangeEventDetail {
    selectedIndex?: number;
    selectedIndices?: number[];
    selectedValue?: string;
    selectedValues?: string[];
}

export interface SelectItem {
    label: string;
    value: string;
    pseudo?: boolean;
}

interface FilterableSelectItem extends SelectItem {
    enabled: boolean;
    keyboardSelected: boolean;
}

export class SelectChangeEvent extends CustomEvent<ChangeEventDetail> {
    constructor(detail: ChangeEventDetail) {
        super("change", { detail });
    }
}

// eslint-disable-next-line complexity
export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        multiSelect: host.multiSelect !== undefined ? host.multiSelect : DEFAULTS.multiSelect,
        label: host.label,
        filterable: host.filterable !== undefined ? host.filterable : DEFAULTS.filterable,
        dataSource: host.dataSource !== undefined ? host.dataSource : DEFAULTS.dataSource,
        validationMessage: host.validationMessage,
        selectedValues: host.selectedValues !== undefined ? host.selectedValues : DEFAULTS.selectedValues,
        selectedIndices: host.selectedIndices !== undefined ? host.selectedIndices : DEFAULTS.selectedIndices,
        filterProps:
            host.filterProps !== undefined
                ? {
                      type: host.filterProps.type !== undefined ? host.filterProps.type : DEFAULTS.filterProps.type,
                      ignoreCase:
                          host.filterProps.ignoreCase !== undefined
                              ? host.filterProps.ignoreCase
                              : DEFAULTS.filterProps.ignoreCase,
                  }
                : DEFAULTS.filterProps,
        readonly: host.readonly !== undefined ? host.readonly : DEFAULTS.readonly,
        tooltip: host.tooltip !== undefined ? host.tooltip : DEFAULTS.tooltip,
        debounceMs: host.debounceMs !== undefined ? host.debounceMs : DEFAULTS.debounceMs,
        placeholder: host.placeholder !== undefined ? host.placeholder : DEFAULTS.placeholder,
        name: host.name,
        isButton: host.isButton !== undefined ? host.isButton : DEFAULTS.isButton,
        buttonText: host.buttonText !== undefined ? host.buttonText : DEFAULTS.buttonText,
    };

    // Helper

    const getTextValue = () => {
        if (filterText) {
            return filterText;
        } else {
            return selectedIndices.some((selInd) => dataSourceResult[selInd] !== undefined)
                ? selectedIndices
                      .filter(
                          (selInd) => dataSourceResult[selInd].pseudo === undefined || !dataSourceResult[selInd].pseudo,
                      )
                      .map((selInd) => dataSourceResult[selInd].label)
                      .join(", ")
                : "";
        }
    };

    const getDataSourceResult = async (dataSource: DataSourceType, setSelectedIndexToo: boolean) => {
        const now = Date.now();
        lastCall.current.ts = now;
        let currentResult;
        if (typeof dataSource === "function") {
            const tempResult = dataSource(filterText);
            if (tempResult instanceof Promise) {
                const loadingTimer = window.setTimeout(() => {
                    if (lastCall.current.ts === now) {
                        setResultMessage("Loading...");
                    }
                }, 500);
                currentResult = await tempResult;
                window.clearTimeout(loadingTimer);
            } else {
                currentResult = tempResult;
            }
        } else {
            currentResult = dataSource;
        }

        if (lastCall.current.ts === now) {
            setSelectedIndices([]);
            let newResult = [] as FilterableSelectItem[];
            if (typeof currentResult === "string") {
                setResultMessage(currentResult);
            } else {
                newResult = currentResult.map((item: SelectItem | number | string | { label: string; value: any }) => {
                    let newItem: SelectItem;
                    if (typeof item === "string") {
                        newItem = { label: item, value: item };
                    } else if (typeof item === "number") {
                        newItem = { label: item.toString(), value: item.toString() };
                    } else if (item.hasOwnProperty("label")) {
                        newItem = { label: item.label, value: item.value };
                    } else {
                        newItem = item;
                    }
                    let enabled = true;
                    if (typeof dataSource !== "function" && filterText !== undefined) {
                        enabled = props.filterProps.ignoreCase
                            ? props.filterProps.type === "includes"
                                ? newItem.label.toLowerCase().includes(filterText.toLowerCase())
                                : newItem.label.toLowerCase().startsWith(filterText.toLowerCase())
                            : props.filterProps.type === "includes"
                              ? newItem.label.includes(filterText)
                              : newItem.label.startsWith(filterText);
                    }
                    return { ...newItem, enabled, keyboardSelected: false };
                });

                setResultMessage(undefined);
                if (props.filterable) {
                    // select first element automatically
                    newResult = newResult.map((item, i) => {
                        item.keyboardSelected = i === 0;
                        return item;
                    });
                }
                setDataSourceResult(newResult);
                if (setSelectedIndexToo) {
                    setSelectedIndicesByResult(newResult);
                }
            }
        }
    };

    const setSelectedIndicesByResult = (result: FilterableSelectItem[]) => {
        if (props.selectedValues) {
            let tempSelectedValues: string[];
            if (typeof props.selectedValues === "string") {
                tempSelectedValues = [props.selectedValues];
            } else if (typeof props.selectedValues === "number") {
                tempSelectedValues = [props.selectedValues.toString()];
            } else {
                tempSelectedValues = props.selectedValues.map((selVal) =>
                    typeof selVal === "number" ? selVal.toString() : selVal,
                );
            }
            setSelectedIndices(
                result.reduce((aggr: number[], item, currentIndex) => {
                    return aggr.concat(
                        tempSelectedValues.some((selVal) => selVal === item.value) ? [currentIndex] : [],
                    );
                }, []),
            );
        } else {
            setSelectedIndices(props.selectedIndices || []);
        }
    };

    // Component

    const [dataSourceResult, setDataSourceResult] = useState<FilterableSelectItem[]>([]);
    const [selectedIndices, setSelectedIndices] = useState<number[]>([]);
    const [opened, setOpened] = useState(false);
    const [filterText, setFilterText] = useState<string>(undefined);
    const [debounceTimer, setDebounceTimer] = useState<any>(undefined);
    const lastCall = useRef({ ts: undefined });
    const [resultMessage, setResultMessage] = useState<string>(undefined);
    const [mouseOverItem, setMouseOverItem] = useState<string>(undefined);

    const init = () => {
        const onClickedOutside = (e: Event) => {
            if (!host.contains(e.target as Node)) {
                setOpened(false);
            }
        };
        const onCloseOnEscKey = (e: KeyboardEvent) => {
            if (e.key === "Escape") {
                setOpened(false);
            }
        };
        document.addEventListener("keyup", onCloseOnEscKey, true);
        document.addEventListener("click", onClickedOutside, true);
        return () => {
            document.removeEventListener("keyup", onCloseOnEscKey);
            document.removeEventListener("click", onClickedOutside);
        };
    };

    useEffect(init, []);

    useEffect(() => {
        if (typeof props.dataSource !== "function" && !(props.dataSource instanceof Promise)) {
            void getDataSourceResult(props.dataSource, true);
        }
    }, [props.dataSource]);

    useEffect(() => {
        void getDataSourceResult(props.dataSource, true);
    }, [props.selectedIndices, props.selectedValues]);

    useEffect(() => {
        if (filterText !== undefined) {
            open();
            debounce(() => {
                void getDataSourceResult(props.dataSource, false);
            });
        }
    }, [filterText]);

    // Event Handlers

    const open = () => {
        if (!opened) {
            setOpened(true);
            setDataSourceResult(
                dataSourceResult.map((item, i) => {
                    if (props.filterable) {
                        item.keyboardSelected = i === 0;
                    }
                    return item;
                }),
            );
        }
    };

    // TODO: make this function simpler
    const handleKeyUp = (e: KeyboardEvent) => {
        if (props.readonly) {
            return;
        }

        if (e.key === "Escape") {
            setOpened(false);
        } else if (e.key === "ArrowUp" || e.key === "ArrowDown" || e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            const firstEnabledItemIndex = dataSourceResult.reduce(
                (index: number, currVal, i) => (currVal.enabled && index === -1 ? i : index),
                -1,
            );
            const lastEnabledItemIndex = dataSourceResult.reduce(
                (index: number, currVal, i) => (currVal.enabled && index < i ? i : index),
                -1,
            );
            const keyboardSelectedIndex = dataSourceResult.reduce(
                (index: number, currVal, i) => (currVal.keyboardSelected ? i : index),
                -1,
            );

            if (e.key === "Enter" && keyboardSelectedIndex > -1) {
                selectIndex(keyboardSelectedIndex);
                (e.target as any).blur();
            } else if (e.key === "Enter" && dataSourceResult.filter((item) => item.enabled).length === 1) {
                selectIndex(lastEnabledItemIndex);
                (e.target as any).blur();
            } else if (e.key === "ArrowDown" && keyboardSelectedIndex < lastEnabledItemIndex) {
                let alreadySet = false;
                setDataSourceResult(
                    dataSourceResult.map((item, i) => {
                        if (item.enabled && i > keyboardSelectedIndex && !alreadySet) {
                            item.keyboardSelected = true;
                            alreadySet = true;
                        } else {
                            item.keyboardSelected = false;
                        }
                        return item;
                    }),
                );
            } else if (e.key === "ArrowUp" && keyboardSelectedIndex > firstEnabledItemIndex) {
                let alreadySet = false;
                setDataSourceResult(
                    dataSourceResult
                        .reverse()
                        .map((item, i) => {
                            if (
                                item.enabled &&
                                i > dataSourceResult.length - keyboardSelectedIndex - 1 &&
                                !alreadySet
                            ) {
                                item.keyboardSelected = true;
                                alreadySet = true;
                            } else {
                                item.keyboardSelected = false;
                            }
                            return item;
                        })
                        .reverse(),
                );
            }
        } else {
            open();
            setFilterText((e.currentTarget as any).value);
        }
    };

    const selectIndex = (index: number) => {
        let newIndices: number[] = [];
        if (props.multiSelect) {
            newIndices = selectedIndices.some((i) => i === index)
                ? selectedIndices.filter((i) => i !== index)
                : selectedIndices.concat([index]);
            newIndices.sort((a: number, b: number) => {
                return a < b ? -1 : a > b ? 1 : 0;
            });
        } else {
            if (index === undefined) {
                newIndices = [];
            } else {
                newIndices = [index];
            }
        }

        setSelectedIndices(newIndices);
        if (!props.multiSelect) {
            setOpened(false);
        }
        setFilterText(undefined);

        if (!arrayEquals(newIndices, selectedIndices)) {
            if (props.multiSelect) {
                host.dispatchEvent(
                    new SelectChangeEvent({
                        selectedIndices: newIndices,
                        selectedValues: newIndices.map((i) => dataSourceResult[i].value),
                    }),
                );
            } else {
                host.dispatchEvent(
                    new SelectChangeEvent({
                        selectedIndex: index,
                        selectedValue: index !== undefined ? dataSourceResult[index].value : undefined,
                    }),
                );
            }
        }
    };

    const debounce = (func: () => void, wait = props.debounceMs): void => {
        window.clearTimeout(debounceTimer);
        setDebounceTimer(window.setTimeout(func, wait));
    };

    const labelTemplate = () =>
        props.label !== undefined
            ? html`
                  <label class="form-label">
                      ${props.label}${props.tooltip !== undefined && props.tooltip.length > 0
                          ? html` <dc-tooltip .label=${props.tooltip}></dc-tooltip> `
                          : ""}
                  </label>
              `
            : "";

    const inputTemplate = () =>
        props.isButton
            ? html` <button class="select-button" ?disabled=${props.readonly}>${props.buttonText}</button> `
            : html`
                  <input
                      class="select-input ${props.readonly ? "readonly" : ""} ${props.validationMessage
                          ? "invalid"
                          : ""}"
                      ?readonly=${props.readonly || !props.filterable}
                      .value=${getTextValue()}
                      name=${ifDefined(props.name)}
                      autocomplete="please_dont_autofill"
                      placeholder=${ifDefined(props.placeholder)}
                      @keyup=${handleKeyUp}
                      @focus=${(e: Event) => {
                          if (props.filterable) {
                              (e.target as any).select();
                              setFilterText(undefined);
                              setMouseOverItem(undefined);
                          }
                      }}
                      @blur=${() => {
                          // Only try setting one element at blur if user not clicked by a mouse, which can be only detected if the text on mouseover is saved
                          if (props.filterable && mouseOverItem === undefined) {
                              const enabledItems = dataSourceResult.filter((item) => item.enabled);
                              if (enabledItems.length === 1) {
                                  selectIndex(
                                      dataSourceResult.reduce(
                                          (index: number, currVal, i) => (currVal.enabled && index < i ? i : index),
                                          -1,
                                      ),
                                  );
                              }
                          }
                      }}
                      @change=${(e: Event) => {
                          e.stopPropagation();
                      }}
                  />
              `;

    // Template

    return html`
        ${labelTemplate()}
        <div
            class=${classMap({
                "dc-select": true,
                "button-select": props.isButton,
                "dropdown-select": !props.isButton,
                opened,
            })}
            @click=${() => {
                if (!props.readonly && (!props.filterable || isEmpty(filterText))) {
                    open();
                }
            }}
        >
            ${inputTemplate()}
            ${props.validationMessage ? html` <div class="validation-result">${props.validationMessage}</div> ` : ""}
            ${opened && !resultMessage
                ? html`
                      <div class="select-items" @mouseover=${() => setMouseOverItem(filterText)}>
                          ${dataSourceResult.map((item, index) =>
                              item.enabled
                                  ? html`
                                        <div
                                            @click=${(e: MouseEvent) => {
                                                e.stopPropagation();
                                                selectIndex(index);
                                            }}
                                            class=${classMap({
                                                "item": true,
                                                "selected": selectedIndices.some((i) => i === index),
                                                "keyboard-selected": item.keyboardSelected,
                                            })}
                                        >
                                            ${item.label}
                                        </div>
                                    `
                                  : "",
                          )}
                      </div>
                  `
                : ""}
            ${opened && resultMessage
                ? html`
                      <div class="select-items">
                          <div>${resultMessage}</div>
                      </div>
                  `
                : ""}
        </div>
    `;
};
