import i18next from "i18next";
import { html } from "lit-html";
import { classMap } from "lit-html/directives/class-map";
import {
    bancoEstadoFreeSeatsTemplate,
    bancoEstadoDiscountedSeatsInfoTemplate,
} from "../../../common-templates/SeatmapCommonTemplates";
import { SeatmapHelper } from "../../../component-helpers/seatmap/SeatmapHelper";
import { ExtendedApiSeat } from "../../../component-models/seatmap/ApiSeat";
import { ApiSeatmapPassenger } from "../../../component-models/seatmap/ApiSeatmapPassenger";
import { SeatmapContext } from "../../../component-models/seatmap/SeatmapContext";
import { SeatmapModel } from "../../../component-models/seatmap/SeatmapModel";
import { SeatmapState } from "../../../component-models/seatmap/SeatmapState";
import { SeatmapStateUpdater } from "../../../component-models/seatmap/SeatmapStateUpdater";
import { CheckinFlowType } from "../../../component-models/seatmap/SeatmapTypes";
import { ref } from "../../../directives/ref";
import { useSeatmapTealiumManager } from "../../../managers/Tealium/useSeatmapTealiumManager";
import { useBookingContext } from "../../../managers/useBookingContext";
import { useBookingManager } from "../../../managers/useBookingManager";
import { useFlowContext } from "../../../managers/useFlowContext";
import { usePubSub } from "../../../pub-sub-service/usePubSub";
import BookingFlowHandler from "../../../shared/BookingFlowHandler";
import { BookingSteps } from "../../../shared/BookingSteps";
import DomCrawlingHelper from "../../../shared/DomCrawlingHelper";
import { LOADER_CLASS_NAMES } from "../../../shared/LoaderClassNames";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import { CLASS_NAMES } from "../../../shared/classNames";
import { showLoader } from "../../../shared/common";
import { ACTION_NAMES, INBOUND, OUTBOUND } from "../../../shared/commonConstants";
import { raiseBookingFlowContinueEvent } from "../../../shared/eventbus/raiseBookingFlowContinueEvent";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useMobileReviewSeatmap } from "./mobile-review/useMobileReviewSeatmap";
import { useBancoEstadoAgentDifferenceModal } from "./modals/useBancoEstadoAgentDifferenceModal";
import { useBancoEstadoFreeSeatsModal } from "./modals/useBancoEstadoFreeSeatsModal";
import { useBlockedSeatingModal } from "./modals/useBlockedSeatingModal";
import { useCheckinSecondJourneyModal } from "./modals/useCheckinSecondJourneyModal";
import { useSeatSelectionProblemModal } from "./modals/useSeatSelectionProblemModal";
import { useSeatsForAllModal } from "./modals/useSeatsForAllModal";
import { useSeatPassengerMobileContainer } from "./passengers/useSeatPassengerMobileContainer";
import { usePlane } from "./plane/usePlane";
import { useSeatRecommendator } from "./recommendator/useSeatRecommendator";
import { useSidePanel } from "./side-panel/useSidePanel";
import { useAutoAssignedSeatsCache } from "./useAutoAssignedSeatsCache";
import { useCurrentSeatings } from "./useCurrentSeatings";
import { useReturnSeatRecommendator } from "./useReturnSeatRecommendator";
import { useSellKeyHandler } from "./useSellKeyHandler";
import { useRef, useEffect as hauntedUseEffect } from "haunted";
import { useEffect, useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { GetBuildPageResult, SpaContent } from "../../../component-models/spa/SpaContent";
import { mapToSeatmapModel } from "../../../component-mappers/SeatmapMappers";
import { ApiSeatmapModel } from "../../../component-models/seatmap/ApiSeatmapModel";
import { ROUTES } from "../../../shared/apiRoutes";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { useRunOnce } from "../../useRunOnce";
import { commonDebug } from "../../../bootstrap";
import { ChileCompraSeatingStatusResult } from "../../../component-models/seatmap/ChileCompraSeatingStatusResult";
import { useChileCompraNoSeatsModal } from "./useChileCompraNoSeatsModal";
import { ApiSpaGlobalDataModel } from "../../../component-models/spa/ApiSpaGlobalDataModel";

export const mobileSeatPaxClass = "mobile-seat-pax";
export const mobileSeatPaxContainerClass = "mobile-passengers-container";

export interface SeatmapPage extends SpaContent {
    setSeatmapModel: (model: SeatmapModel) => void;
}

export interface Props {
    hasFlightColombianStation: boolean;
    spaGlobalDataModel: ApiSpaGlobalDataModel;
    onForward: () => void;
    spaModalStart: (submitCallback: () => Promise<void> | void) => void;
    triggerScroll: () => void;
}

export const useSeatmap = (props: Props): SeatmapPage => {
    const root = useRef<HTMLDivElement>(undefined);

    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();
    const bookingManager = useBookingManager();
    const tealiumManager = useSeatmapTealiumManager();
    const runOnce = useRunOnce();

    const { triggers } = usePubSub();
    const { ajaxJsonRequest } = useAjax();

    const [userContext] = useReduxState("userContext");
    const [currentSpaSection] = useReduxState("spa.activeSection");
    const [isSpaLoading] = useReduxState("spa.isLoading");
    const [currency] = useReduxState("booking.currency");

    const [freeSeatWarningShown, setFreeSeatWarningShown] = useState<boolean>(undefined);
    const [isMobileReviewShown, setIsMobileReviewShown] = useState<boolean>(undefined);
    const [seatmapModel, setSeatmapModel] = useState<SeatmapModel>(undefined);

    const remainingFreeSeats = userContext?.bancoEstado.remainingFreeSeats || 0;

    const isActive = useMemo(() => currentSpaSection === "Seatmap", [currentSpaSection]);

    const getInitialJourney = () =>
        bookingContext.isCheckinClosedOutbound || (seatmapModel && seatmapModel.CheckedJourneyIndex === INBOUND)
            ? seatmapModel.Journeys[INBOUND]
            : seatmapModel.Journeys[OUTBOUND];

    const [seatmapState, setSeatmapState] = useState<SeatmapState>(undefined);

    const notAllAreSeatedInJourney = (seats: ExtendedApiSeat[], journeyIndex: number) =>
        seats.filter((ps) => ps.JourneyIndex === journeyIndex && ps.PassengerNumber >= 0).length !==
        seatmapModel?.Passengers.length * seatmapModel.Journeys[journeyIndex].Segments.length;

    const moreSeatingNeededForJourney = (seats: ExtendedApiSeat[], journeyIndex: number) =>
        seatmapModel?.CheckedJourneyIndex === journeyIndex &&
        seatmapModel.ShownJourneys.includes(journeyIndex) &&
        (!bookingContext.isCheckinClosedOutbound || journeyIndex === INBOUND) &&
        notAllAreSeatedInJourney(seats, journeyIndex);

    const isCheckinFlowWithAllSeatedAllJourneys = (seats: ExtendedApiSeat[]) =>
        flowContext.isCheckinFlow &&
        !moreSeatingNeededForJourney(seats, OUTBOUND) &&
        !moreSeatingNeededForJourney(seats, INBOUND);

    const isCheckinFlowWithNotAllSeatedAllJourneys = (seats: ExtendedApiSeat[]) =>
        flowContext.isCheckinFlow &&
        (moreSeatingNeededForJourney(seats, OUTBOUND) || moreSeatingNeededForJourney(seats, INBOUND));

    const getCheckinFlowType = (seats: ExtendedApiSeat[]): CheckinFlowType =>
        isCheckinFlowWithAllSeatedAllJourneys(seats)
            ? "allSeated"
            : isCheckinFlowWithNotAllSeatedAllJourneys(seats)
              ? "notAllSeated"
              : "none";

    const [checkinFlowType, setChekinFlowType] = useState<CheckinFlowType>("none");

    const context = (): SeatmapContext => ({
        CheckinFlowType: checkinFlowType,
        Model: seatmapModel,
        SpaGlobalDataModel: props.spaGlobalDataModel,
    });

    const currentSeatings = useCurrentSeatings({
        hasFlightColombianStation: props.hasFlightColombianStation,
        model: seatmapModel,
        seatmapState,
    });

    const blockedSeatingModal = useBlockedSeatingModal({ onClose: () => handleNoSelection() });

    const chileCompraNoSeatsModal = useChileCompraNoSeatsModal();

    const init = () => {
        BookingFlowHandler.storeCurrentStep(BookingSteps.SeatMap);

        setSeatmapState({
            CurrentJourney: getInitialJourney(),
            CurrentPassenger: seatmapModel?.Passengers[0],
            CurrentSegment: getInitialJourney().Segments[0],
            CurrentSeatMobileTooltip: undefined,
            IsSelectionBlocked: false,
        });

        if (flowContext.isCheckinFlow && seatmapModel?.UnseatableInfantPresent) {
            assignmentProblemModal.open();
        }

        const doIt = async () => {
            if (userContext.chileCompra.role !== "none" || bookingContext.isChileCompraBooking) {
                const chileCompraSeatingOkay = await getChileCompraSeatingStatus();

                if (!chileCompraSeatingOkay) chileCompraNoSeatsModal.open();
            }
        };

        void doIt();
    };

    const getChileCompraSeatingStatus = async () => {
        try {
            const result = await ajaxJsonRequest<ChileCompraSeatingStatusResult>({
                url: ROUTES.ApiRoutes.ChileCompraSeatingStatus,
                method: "GET",
            });

            return !result?.data.Title;
        } catch (error) {
            commonDebug.error("Error while fetching ChileCompra seating status", error);
            return true;
        }
    };

    const getPlaneTypes = () =>
        seatmapModel?.Journeys.reduce(
            (allPlaneTypes, journey) =>
                allPlaneTypes.concat(
                    journey.Segments.reduce(
                        (segmentPlaneTypes, segment) => segmentPlaneTypes.concat([segment.PlaneType]),
                        [],
                    ),
                ),
            [],
        );

    const visibleRecommendator = () => (isMobile() ? mobileRecommendator : sidePanel.desktopRecommendator);

    const handleSeatDelete = (passenger: ApiSeatmapPassenger) => {
        currentSeatings.updateOnDelete(passenger.PassengerNumber);
    };

    const findCurrentSeat = (seats: ExtendedApiSeat[]) =>
        seats.find(
            (s) =>
                s.PassengerNumber === seatmapState.CurrentPassenger.PassengerNumber &&
                s.JourneyIndex === seatmapState.CurrentJourney.JourneyIndex &&
                s.SegmentIndex === seatmapState.CurrentSegment.SegmentIndex,
        );

    const registerSeatClick = async (seat: ExtendedApiSeat) => {
        const currentSeat = findCurrentSeat(currentSeatings.value);

        currentSeatings.updateOnClick(seat);

        const tealiumOutboundSeats = currentSeatings.value
            .filter((s) => s.JourneyIndex === OUTBOUND && s.PassengerNumber >= 0)
            .map((s) => `${s.Row}${s.Column}|${s.Price}|${currency}`);

        const tealiumInboundSeats = currentSeatings.value
            .filter((s) => s.JourneyIndex === INBOUND && s.PassengerNumber >= 0)
            .map((s) => `${s.Row}${s.Column}|${s.Price}|${currency}`);

        await tealiumManager.logSeatSelected(
            seatmapState.CurrentPassenger.Type === "ADT" ? "A" : "C",
            seatmapState.CurrentPassenger.PassengerNumber,
            seat,
            currentSeat,
            tealiumOutboundSeats,
            tealiumInboundSeats,
        );

        nextPassenger();
    };

    const isCurrentPaxLast = () =>
        seatmapState.CurrentPassenger.PassengerNumber === seatmapModel?.Passengers.length - 1;

    const handleSeatClick = async (seat: ExtendedApiSeat) => {
        if (seatmapModel?.IsReadOnly) {
            return;
        }

        if (isMobile() && isCurrentPaxLast()) {
            seatPassengerMobileContainer.scrollToJourneyInfoMobile();
        }

        if (isDifferentAgentWarningModalNeeded(seat)) {
            setFreeSeatWarningShown(true);
            agentDifferenceModal.open(seat);
        } else {
            await registerSeatClick(seat);
        }
    };

    const isDifferentAgentWarningModalNeeded = (seat: ExtendedApiSeat) =>
        seatmapModel?.AreAgentsDifferent && !freeSeatWarningShown && isFreeSeatGivenUp(seat);

    const isFreeSeatGivenUp = (seat: ExtendedApiSeat) => {
        const currentSeat = findCurrentSeat(currentSeatings.value);

        return (
            (currentSeat?.IsBancoEstadoFreeSeatDuringBooking && !seat.IsBancoEstado && !seat.IsBancoEstadoSecondary) ||
            (currentSeat?.IsBancoEstadoFreeSeatDuringBooking && ![5, 6].includes(userContext.bancoEstado.category))
        );
    };

    const hasOneMoreSegment = () =>
        seatmapState.CurrentJourney.Segments.length > seatmapState.CurrentSegment.SegmentIndex + 1;

    const nextPassenger = () => {
        const hasOneMorePassenger =
            seatmapState.CurrentPassenger &&
            seatmapModel.Passengers.length > seatmapState.CurrentPassenger.PassengerNumber + 1;

        if (hasOneMorePassenger) {
            const nextPaxIndex = seatmapState.CurrentPassenger.PassengerNumber + 1;
            updateState({ PassengerIndex: nextPaxIndex });
            return;
        }

        if (hasOneMoreSegment()) {
            handleFreeSeatModal(
                seatmapState.CurrentJourney.JourneyIndex,
                seatmapState.CurrentSegment.SegmentIndex,
                () => doSegmentChange(seatmapState.CurrentSegment.SegmentIndex + 1),
            );
        }
    };

    const handleProgressing = async () => {
        await tealiumManager.logContinueClicked();
        props.onForward();
    };

    const handleSave = async (seats: ExtendedApiSeat[], moveForward: boolean) => {
        if (isEmergencyExitTermsCheckboxUnchecked(seats) && !isMobile()) {
            sidePanel.showAcceptanceWarning();
            mobileReviewSeatmap.showAcceptanceWarning();
            raiseBookingFlowContinueEvent(false, [".ts-seat-warning-error"]);
            return;
        }

        raiseBookingFlowContinueEvent(true);

        const values = sellKeyHandler.getAddAndDeleteKeys(seats);

        if (values?.length > 0) {
            const parent = DomCrawlingHelper.findParentByClass(root.current, LOADER_CLASS_NAMES.SpaSection);
            const loader = showLoader({ name: LOADER_CLASS_NAMES.CommonLoaderWrapper, container: parent });
            const body = values.reduce(
                (aggr, curr, i) => {
                    aggr[`jetSmartSeatMap.PassengerSeatKeys[${i}]`] = curr;
                    return aggr;
                },
                {} as { [key: string]: string },
            );
            await bookingManager.postSeatSelection(body, root.current, loader);
        }

        if (moveForward) {
            if (typeof props.spaModalStart === "function") {
                props.spaModalStart(handleProgressing);
            } else {
                await handleProgressing();
            }
        }
    };

    const isEmergencyExitTermsCheckboxUnchecked = (seats: ExtendedApiSeat[]) =>
        SeatmapHelper.isAnySelectedSeatInExitRow(seats) &&
        !sidePanel.isTermsAcceptanceCheckboxChecked &&
        !mobileReviewSeatmap.isTermsAcceptanceCheckboxChecked;

    const resetSeats = async () => {
        const newSeats = currentSeatings.updateOnReset();

        const isCheckingInOutbound =
            flowContext.isCheckinFlow &&
            seatmapModel.CheckedJourneyIndex === OUTBOUND &&
            seatmapState.CurrentJourney.JourneyIndex === OUTBOUND;
        const isCheckingInInbound =
            flowContext.isCheckinFlow &&
            seatmapModel.CheckedJourneyIndex === INBOUND &&
            seatmapState.CurrentJourney.JourneyIndex === INBOUND;

        if (isCheckingInOutbound && notAllAreSeatedInJourney(newSeats, OUTBOUND)) {
            switchViewToOutbound();
            return;
        }

        if (isCheckingInInbound && notAllAreSeatedInJourney(newSeats, INBOUND)) {
            switchViewToInbound();
            return;
        }

        if (!flowContext.isCheckinFlow) {
            await handleSave(newSeats, false);

            switchViewToOutbound();

            // DEVNOTE We flip the plane back, so scroll has to wait until animation is over
            window.setTimeout(async () => {
                if (typeof props.spaModalStart === "function") {
                    props.spaModalStart(handleProgressing);
                } else {
                    await handleProgressing();
                }
            }, 600);
        } else {
            switchViewToOutbound();
        }
    };

    const continueWithSeatingFinished = (seats: ExtendedApiSeat[]) => {
        if (isNextStepInbound()) {
            continueToSecondLeg();
        } else {
            handleFreeSeatModal(
                seatmapState.CurrentJourney.JourneyIndex,
                seatmapState.CurrentSegment.SegmentIndex,
                () => handleSave(seats, true),
            );
        }
    };

    const continueToSecondLeg = () => {
        const isCheckingInOutbound = flowContext.isCheckinFlow && seatmapModel.CheckedJourneyIndex === OUTBOUND;

        if (isCheckingInOutbound) {
            checkinSecondJourneyModal.open();
            return;
        }

        handleInboundSelect();
    };

    const isNextStepInbound = () =>
        seatmapModel.ShownJourneys.includes(INBOUND) && seatmapState.CurrentJourney.JourneyIndex === OUTBOUND;

    // DEVNOTE In fact we show the mobile view for tablets and netbooks too
    const isMobile = () => ScrollHelper.getWindowWidth() < 1200;

    const isInboundTabEnabled = () =>
        checkinFlowType !== "notAllSeated" ||
        SeatmapHelper.doAllPaxHaveSeats(
            currentSeatings.value,
            seatmapModel.Journeys[OUTBOUND],
            seatmapModel.Passengers,
        ) ||
        seatmapModel.CheckedJourneyIndex === INBOUND;

    const handleFreeSeatModal = (journeyIndex: number, segmentIndex: number, callback: () => void) => {
        const freeSeatsUsedForJourney = SeatmapHelper.freeSeatsUsedForSegment(
            currentSeatings.value,
            journeyIndex,
            segmentIndex,
        );

        const shouldOpen =
            freeSeatsUsedForJourney.length > 0 &&
            !seatmapModel.Journeys[journeyIndex].AreAllSeatsInBundle &&
            flowContext.isBookingFlow;

        if (shouldOpen) {
            freeSeatsModal.open(freeSeatsUsedForJourney, callback);
        } else {
            callback();
        }
    };

    const handleOutboundSelect = () => {
        if (seatmapModel?.IsReadOnly) {
            return;
        }

        return seatmapModel.Journeys[INBOUND]
            ? handleFreeSeatModal(INBOUND, seatmapModel.Journeys[INBOUND].Segments.length - 1, switchViewToOutbound)
            : switchViewToOutbound();
    };

    const handleInboundSelect = () =>
        !seatmapModel?.IsReadOnly &&
        isInboundTabEnabled() &&
        handleFreeSeatModal(OUTBOUND, seatmapModel?.Journeys[OUTBOUND].Segments.length - 1, switchViewToInbound);

    const switchViewToOutbound = () => {
        plane.animate();
        window.setTimeout(() => {
            sidePanel.scrollToTop(0);
            updateState({ JourneyIndex: OUTBOUND, PassengerIndex: 0, SegmentIndex: 0 });
        }, 500);
    };

    const switchViewToInbound = () => {
        plane.animate();
        window.setTimeout(() => {
            sidePanel.scrollToTop(0);
            updateState({ JourneyIndex: INBOUND, PassengerIndex: 0, SegmentIndex: 0 });

            if (!flowContext.isCheckinFlow) {
                returnSeatRecommendator.offerSameSeatsOnReturnJourney(currentSeatings?.value);
            }
        }, 500);
    };

    const updateState = (data: SeatmapStateUpdater) => {
        const currentJourney =
            data.JourneyIndex !== undefined
                ? seatmapModel.Journeys.find((j) => j.JourneyIndex === data.JourneyIndex)
                : seatmapState.CurrentJourney;

        const currentPassenger =
            data.PassengerIndex !== undefined
                ? seatmapModel.Passengers.find((p) => p.PassengerNumber === data.PassengerIndex)
                : seatmapState.CurrentPassenger;

        const currentSegment =
            data.SegmentIndex !== undefined
                ? currentJourney.Segments.find((s) => s.SegmentIndex === data.SegmentIndex)
                : seatmapState.CurrentSegment;

        const currentSeat =
            data.SeatDesignator &&
            data.JourneyIndex === undefined &&
            data.PassengerIndex === undefined &&
            data.SegmentIndex === undefined
                ? currentSegment.Seats.find(
                      (s) =>
                          s.JourneyIndex === currentJourney.JourneyIndex &&
                          s.SegmentIndex === currentSegment.SegmentIndex &&
                          data.SeatDesignator === `${s.Row}${s.Column}`,
                  )
                : undefined;

        const newState: SeatmapState = {
            CurrentJourney: currentJourney,
            CurrentPassenger: currentPassenger,
            CurrentSegment: currentSegment,
            CurrentSeatMobileTooltip: currentSeat,
            IsSelectionBlocked: currentSeatings.isSeatingBlocked,
        };

        setSeatmapState(newState);
    };

    const scrollToAutoAssignedSeats = () => {
        // DEVNOTE Settimeout is needed because scroll has to be after render
        window.setTimeout(() => {
            const firstSelectedSeat = (
                Array.from(root.current.querySelectorAll(".seat.selected")) as HTMLDivElement[]
            ).find((e) => e.offsetHeight > 0);
            ScrollHelper.scrollToElementAndHideNav({ element: firstSelectedSeat, yOffset: 100, timeOffset: 0 });
        }, 500);
    };

    const doSegmentChange = (segmentIndex: number) => {
        plane.animate();
        window.setTimeout(() => {
            sidePanel.scrollToTop(1);
            updateState({ SegmentIndex: segmentIndex, PassengerIndex: 0 });
        }, 500);
    };

    const handleMobileReviewToggle = () => {
        if (isMobileReviewShown) {
            document.body.classList.add(CLASS_NAMES.noScroll);
        } else {
            document.body.classList.remove(CLASS_NAMES.noScroll);
        }
    };

    // EVENT LISTENERS

    const showMobileReview = () => setIsMobileReviewShown(true);

    const hideMobileReview = () => setIsMobileReviewShown(false);

    const handleSegmentClick = (segmentIndex: number) => {
        if (segmentIndex === seatmapState.CurrentSegment.SegmentIndex) {
            return;
        }

        handleFreeSeatModal(seatmapState.CurrentJourney.JourneyIndex, seatmapState.CurrentSegment.SegmentIndex, () =>
            doSegmentChange(segmentIndex),
        );
    };

    const handleSeatAutoAssignment = async (journeyIndex: number, segmentIndex: number) => {
        const newValue = await autoAssignedSeatsCache.getValuesForSegment(journeyIndex, segmentIndex);

        if (newValue?.length >= seatmapModel.Passengers.length) {
            currentSeatings.updateOnAutoAssignment(newValue);
            scrollToAutoAssignedSeats();

            if (seatmapState.CurrentJourney.Segments.length > 1) {
                segmentIndex === 0 ? doSegmentChange(segmentIndex + 1) : doSegmentChange(segmentIndex - 1);
            }

            return;
        }

        assignmentProblemModal.open();
    };

    const handleNoSelection = async (e?: MouseEvent) => {
        e?.preventDefault();
        e?.stopPropagation();

        await resetSeats();
    };

    const handleResetSelection = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        await resetSeats();

        if (
            bookingContext.isCheckinClosedOutbound ||
            (flowContext.isCheckinFlow && seatmapModel.CheckedJourneyIndex === INBOUND)
        ) {
            return;
        }

        handleOutboundSelect();
    };

    const handleContinue = (e?: MouseEvent, fromModal?: boolean) => {
        if (SeatmapHelper.isContinueBtnDisabledForCheckin(context(), seatmapState, currentSeatings.value)) {
            return;
        }

        e?.preventDefault();
        e?.stopPropagation();

        const canContinueWithSeatingFinished =
            userContext.chileCompra.role !== "none" ||
            fromModal ||
            SeatmapHelper.doAllPaxHaveSeats(
                currentSeatings.value,
                seatmapState.CurrentJourney,
                seatmapModel.Passengers,
            );

        if (canContinueWithSeatingFinished) {
            continueWithSeatingFinished(currentSeatings.value);
            return;
        }

        const isNextStepReview = isMobile() && !isNextStepInbound();

        if (isNextStepReview) {
            seatsForAllModal.open(handleMobileReviewSeats);
            return;
        }

        seatsForAllModal.open(() => handleContinue(undefined, true));
    };

    const handleMobileReviewSeats = () => {
        if (SeatmapHelper.isContinueBtnDisabledForCheckin(context(), seatmapState, currentSeatings.value)) {
            return;
        }

        handleFreeSeatModal(
            seatmapState.CurrentJourney.JourneyIndex,
            seatmapState.CurrentSegment.SegmentIndex,
            showMobileReview,
        );
    };

    const confirmMobileSeats = async () => {
        hideMobileReview();
        await handleSave(currentSeatings.value, true);
    };

    const mobileSeatReviewClose = () => {
        hideMobileReview();
        props.triggerScroll();
    };

    const handleMobileNoSeats = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        hideMobileReview();
        await handleNoSelection();
    };

    const handleApplyRecommendedSeats = (e: MouseEvent, isMobile: boolean) => {
        e.preventDefault();
        e.stopPropagation();

        const resetRecommendationWithoutMovingOnToNextSegment = !currentSeatings.areRecommendationsSelected();

        const newSeats = currentSeatings.updateOnTakeRecommended();

        if (resetRecommendationWithoutMovingOnToNextSegment) {
            return;
        }

        if (hasOneMoreSegment()) {
            plane.animate();
            window.setTimeout(() => {
                sidePanel.scrollToTop(1);
                updateState({ SegmentIndex: seatmapState.CurrentSegment.SegmentIndex + 1, PassengerIndex: 0 });
            }, 500);

            return;
        }

        if (!isMobile || isNextStepInbound()) {
            continueWithSeatingFinished(newSeats);
        } else {
            showMobileReview();
        }
    };

    const handleResetSeatmap = () => {
        if (seatmapModel.ShownJourneys.includes(OUTBOUND) && !bookingContext.isCheckinClosedOutbound) {
            handleOutboundSelect();
        } else {
            handleInboundSelect();
        }
    };

    const sellKeyHandler = useSellKeyHandler({
        model: seatmapModel,
    });

    const autoAssignedSeatsCache = useAutoAssignedSeatsCache({
        rootElem: root.current,
    });

    const mobileRecommendator = useSeatRecommendator({
        context: context(),
        isForMobile: true,
        seatmapState,
        hasFlightColombianStation: props.hasFlightColombianStation,
        handleApplyRecommendedSeats,
        handleNoSelection,
    });

    const seatPassengerMobileContainer = useSeatPassengerMobileContainer({
        context: context(),
        currentSeats: currentSeatings.value,
        seatmapState,
        handleInboundSelect,
        handleOutboundSelect,
        handleSeatDelete,
        handleSegmentClick,
        updateState,
    });

    const returnSeatRecommendator = useReturnSeatRecommendator({
        currentSeatings,
        oneSegmentsOnly: seatmapModel?.Journeys.every((j) => j.Segments.length === 1),
        planeTypes: getPlaneTypes(),
        visibleRecommendator,
        dispatchScrollToSeatmap: props.triggerScroll,
    });

    const sidePanel = useSidePanel({
        canAddSameSeatsToNextSegment: currentSeatings.canAddSameSeatsToNextSegment,
        context: context(),
        currentSeats: currentSeatings.value,
        isActive,
        seatmapState,
        showAutoReturnSeatSelectionBubble: returnSeatRecommendator.showAutoReturnSeatSelectionBubble,
        hasFlightColombianStation: props.hasFlightColombianStation,
        handleApplyRecommendedSeats,
        handleContinue,
        handleInboundSelect,
        handleNoSelection,
        handleOutboundSelect,
        handleResetSelection,
        handleSeatAutoAssignment,
        handleSeatDelete,
        handleSegmentClick,
        updateState,
    });

    const plane = usePlane({
        context: context(),
        currentSeats: currentSeatings.value,
        seatmapState,
        showAutoReturnSeatSelectionBubble: returnSeatRecommendator.showAutoReturnSeatSelectionBubble,
        isMobileRecommendatorOn: mobileRecommendator.isRecommendatorOn,
        showMobileReview,
        confirmMobileSeats,
        handleContinue,
        handleFreeSeatModal,
        handleMobileNoSeats,
        handleResetSelection,
        handleSeatAutoAssignment,
        handleSeatClick,
        mobileSeatReviewClose,
        updateState,
    });

    const mobileReviewSeatmap = useMobileReviewSeatmap({
        context: context(),
        currentSeats: currentSeatings.value,
        seatmapState,
        confirmMobileSeats,
        handleMobileNoSeats,
        mobileSeatReviewClose,
        updateState,
    });

    const seatsForAllModal = useSeatsForAllModal();

    const checkinSecondJourneyModal = useCheckinSecondJourneyModal({
        onYes: handleInboundSelect,
        onNo: () => handleSave(currentSeatings.value, true),
    });

    const freeSeatsModal = useBancoEstadoFreeSeatsModal({
        freeSeatsAsBackendKnows: seatmapModel?.FreeSeatsInBooking,
        freeSeatsInBooking: SeatmapHelper.getFreeSeatsUsedInBooking(currentSeatings.value),
    });

    const assignmentProblemModal = useSeatSelectionProblemModal();

    const agentDifferenceModal = useBancoEstadoAgentDifferenceModal({
        onContinue: registerSeatClick,
        onCancel: () => setFreeSeatWarningShown(false),
    });

    const subscribeToCloseMobileReview = () => {
        const handler = triggers.seatmap.closeMobileReview.subscribe(hideMobileReview);

        return () => {
            handler.unsubscribe();
        };
    };

    hauntedUseEffect(subscribeToCloseMobileReview, [currentSpaSection]);

    useEffect(handleMobileReviewToggle, [isMobileReviewShown]);

    useEffect(() => {
        if (currentSeatings.initialPassengerSeats !== undefined) {
            setChekinFlowType(getCheckinFlowType(currentSeatings.initialPassengerSeats));
        }
    }, [currentSeatings.initialPassengerSeats]);

    useEffect(() => {
        if (!seatmapState) return;
        setSeatmapState({ ...seatmapState, IsSelectionBlocked: currentSeatings.isSeatingBlocked });
        if (currentSeatings.isSeatingBlocked) blockedSeatingModal.open();
    }, [currentSeatings?.isSeatingBlocked]);

    useEffect(async () => {
        if (seatmapModel) init();
    }, [seatmapModel]);

    useEffect(() => {
        if (currentSpaSection === "Seatmap" && !isSpaLoading && seatmapModel) {
            runOnce.run(async () => {
                await tealiumManager.logSeatmapPageLoad();
            });
        }
        if (currentSpaSection !== "Seatmap") runOnce.reset();
    }, [currentSpaSection, isSpaLoading, seatmapModel]);

    // TEMPLATES

    const reviewMobileTemplate = () => (isMobileReviewShown ? mobileReviewSeatmap.htmlTemplate() : "");

    const mobileRecommendatorBannerTemplate = () => html`
        <div class="hidden-lg-up">${mobileRecommendator.htmlTemplate()}</div>
    `;

    const mobileBancoEstadoAllSeatsTakenCat1Template = () =>
        isActive &&
        seatmapModel.IsBancoEstadoUserEligible &&
        SeatmapHelper.showAllBancoEstadoSeatsTaken(
            seatmapModel,
            seatmapState.CurrentJourney.JourneyIndex,
            seatmapState.CurrentSegment.SegmentIndex,
            userContext.bancoEstado.category === 6,
            [1, 2, 3, 5, 7].includes(userContext.bancoEstado.category),
            remainingFreeSeats,
        )
            ? html`
                  <div class="banco-estado-seats-taken-mobile hidden-lg-up">
                      ${window.seatmapResources.allSeatsTakenText}
                  </div>
              `
            : "";

    const mobileBancoEstadoAllSeatsTakenCat6Template = () =>
        seatmapState?.CurrentJourney &&
        isActive &&
        SeatmapHelper.showBancoEstadoCategory6SecondaryZoneInfo(
            seatmapModel,
            seatmapState.CurrentJourney.JourneyIndex,
            seatmapState.CurrentSegment.SegmentIndex,
            userContext.bancoEstado.category === 6,
            remainingFreeSeats,
        )
            ? html`
                  <div class="banco-estado-seats-taken-mobile hidden-lg-up">
                      <div>
                          ${i18next.t("¡Lo sentimos, no quedan asientos disponibles en la zona BancoEstado, pero")}
                          <span> ${i18next.t("hemos habilitado los asientos desde la fila 15 a la 32 GRATIS!")} </span>
                      </div>
                  </div>
              `
            : "";

    const mobileCFSubtitleTemplate = () =>
        seatmapModel?.Journeys.some((j) => j.Segments.length > 1)
            ? html`
                  <div class="cfs-subtitle hidden-md-up">
                      ${i18next.t("Debes seleccionar tu asiento para los 2 vuelos.")}
                  </div>
              `
            : "";

    const seatCategoriesTemplate = () => html`
        <ac-seat-categories-legend .seatmapSegment=${seatmapState?.CurrentSegment} .isMobile=${true}>
        </ac-seat-categories-legend>
    `;

    const mobilePassengersTemplate = () =>
        seatmapModel
            ? html`
                  <div class="hidden-lg-up">
                      <div class="seatmap-info">
                          ${bancoEstadoFreeSeatsTemplate({
                              context: context(),
                              currentSeats: currentSeatings.value,
                              freeSeatsNotOnBooking: remainingFreeSeats,
                              isBookingFlow: flowContext.isBookingFlow,
                              isCategory6: userContext.bancoEstado.category === 6,
                              seatmapState,
                          })}
                          ${bancoEstadoDiscountedSeatsInfoTemplate(
                              context(),
                              seatmapState,
                              flowContext.isBookingFlow,
                              userContext.bancoEstado.category === 6,
                              userContext.bancoEstado.category === 5,
                              remainingFreeSeats,
                          )}
                      </div>
                      <div>${seatPassengerMobileContainer.htmlTemplate()}</div>
                      ${seatCategoriesTemplate()}
                  </div>
              `
            : "";

    const containerClassMap = classMap({
        "booking-wrapper": true,
        "seatmap-page": true,
        "ts-error-container": true,
        "check-in-flow": flowContext.isCheckinFlow,
    });

    const mainClassMap = classMap({
        "seatmap-main-container": true,
        "outer-package-box": true,
        "banco-estado-56": [5, 6].includes(userContext.bancoEstado.category),
        "all-be-seats-sold": SeatmapHelper.showAllBancoEstadoSeatsTaken(
            seatmapModel,
            seatmapState?.CurrentJourney.JourneyIndex,
            seatmapState?.CurrentSegment.SegmentIndex,
            userContext.bancoEstado.category === 6,
            [1, 2, 3, 5, 7].includes(userContext.bancoEstado.category),
            remainingFreeSeats,
        ),
    });

    // EXPORTS

    const build = async (): Promise<GetBuildPageResult> => {
        try {
            const isReadonly = window.location.href.toLowerCase().includes(ACTION_NAMES.CHECKIN_SEATMAP.toLowerCase());

            const response = await ajaxJsonRequest<ApiSeatmapModel>({
                method: "GET",
                noCors: true,
                nonCancellable: true,
                url: isReadonly ? ROUTES.AjaxReadonlySeatmapModel : ROUTES.AjaxSeatmapModel,
            });

            setSeatmapModel(
                mapToSeatmapModel({
                    model: response.data,
                    isCategory56: [5, 6].includes(userContext.bancoEstado.category),
                    isBookingFlow: flowContext.isBookingFlow,
                }),
            );

            return { type: "success" };
        } catch (error) {
            return { type: "error" };
        }
    };

    const back = () => Promise.resolve(handleResetSeatmap());

    const htmlTemplate = () => html`
        <section ref=${ref(root)} class=${containerClassMap} data-test-id=${T.SEATMAP.PAGE}>
            <div class="seatmap-placeholder">
                <header>
                    <i class="js-seatmap js-icon title-icon"></i>
                    <div class="title">
                        <h2 class="main-title">${i18next.t("V2-SeatmapLabel")}</h2>
                    </div>
                </header>
                ${mobileCFSubtitleTemplate()} ${mobileRecommendatorBannerTemplate()}

                <div class="row">
                    <div class="col-xs-1 ts-error-parent ts-error-container">
                        <div class=${mainClassMap}>
                            <div class="cf-plane-container">
                                ${mobilePassengersTemplate()} ${plane.htmlTemplate()}
                                ${returnSeatRecommendator.htmlTemplate()}
                                ${mobileBancoEstadoAllSeatsTakenCat1Template()}
                                ${mobileBancoEstadoAllSeatsTakenCat6Template()}
                            </div>
                            ${sidePanel.htmlTemplate()}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        ${reviewMobileTemplate()} ${seatsForAllModal.htmlTemplate()} ${checkinSecondJourneyModal.htmlTemplate()}
        ${freeSeatsModal.htmlTemplate()} ${assignmentProblemModal.htmlTemplate()} ${agentDifferenceModal.htmlTemplate()}
        ${blockedSeatingModal.htmlTemplate()} ${chileCompraNoSeatsModal.htmlTemplate()}
    `;

    return { back, build, htmlTemplate, setSeatmapModel };
};
