import { WorldpayPriceInformation, WorldpayResponse } from "../../component-models/payment/WorldpayResponse";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { ROUTES } from "../../shared/apiRoutes";
import { hideLoader, showLoader } from "../../shared/common";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { WORLDPAY_PAYMENT_METHOD_CODES } from "../../shared/commonConstants";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { useReduxState } from "../../shared/redux/useReduxState";

export interface Props {
    isMcpOn: boolean;
}

export const useWorldpayMcp = (props: Props) => {
    const { ajaxRequest, ajaxJsonRequest } = useAjax();
    const { triggers } = usePubSub();

    const [paymentMethod] = useReduxState("payment.paymentMethod");
    const [payerData] = useReduxState("payment.payer");
    const [selectedCurrency, setSelectedCurrency] = useReduxState("payment.selectedCurrency");

    const [isWorldpayMcpSelected, setIsWorldpayMcpSelected] = useState<boolean>(false);
    const [worldpayMcpPrice, setWorldpayMcpPrice] = useState<WorldpayPriceInformation>(undefined);
    const [worldpayResponseCache, setWorldpayResponseCache] = useState<Map<string, WorldpayResponse>>(
        new Map<string, WorldpayResponse>(),
    );

    const isSelectedMethodAWorldpayMcpMethod = () =>
        props.isMcpOn && (isWorldpayMethod() || doesMethodHaveWorldpayCards());

    const isWorldpayMethod = () =>
        paymentMethod && WORLDPAY_PAYMENT_METHOD_CODES.includes(paymentMethod.PaymentMethodCode);

    const doesMethodHaveWorldpayCards = () =>
        paymentMethod?.AllowedCards?.some((card) => WORLDPAY_PAYMENT_METHOD_CODES.includes(card.Code));

    const removeWorldpayFee = async () => {
        const loader = showLoader({});
        await ajaxRequest({ url: ROUTES.DeselectWorldpay });
        triggers.sidebar.bookingChanged.publish({});
        hideLoader(loader);
    };

    const addNewCardIssuerToWorldpayResponseCache = async () => {
        const loader = showLoader({});
        const worldpayResponse = await ajaxJsonRequest<WorldpayResponse>({
            loader,
            method: "GET",
            url: `${ROUTES.GetWorldpayCurrencies}?issuer=${payerData?.CurrentCardIssuerCountry}&paymentMethodCode=${paymentMethod.PaymentMethodCode}`,
        });

        const newWorldpayResponseCache = new Map(worldpayResponseCache);
        newWorldpayResponseCache.set(payerData?.CurrentCardIssuerCountry, worldpayResponse.data);
        setWorldpayResponseCache(newWorldpayResponseCache);

        return newWorldpayResponseCache;
    };

    const handleCardIssuerCountryChange = async () => {
        if (!props.isMcpOn || !isWorldpayMcpSelected) return;

        await removeWorldpayFee();
        setIsWorldpayMcpSelected(false);
    };

    const handlePaymentMethodChange = async () => {
        if (!props.isMcpOn) return;

        if (isWorldpayMcpSelected && !isSelectedMethodAWorldpayMcpMethod()) {
            await removeWorldpayFee();
            setIsWorldpayMcpSelected(false);
        }

        if (!isWorldpayMcpSelected && isSelectedMethodAWorldpayMcpMethod()) {
            let selectedCurrency;

            if (worldpayResponseCache.has(payerData?.CurrentCardIssuerCountry)) {
                selectedCurrency = worldpayResponseCache.get(payerData?.CurrentCardIssuerCountry).DefaultCurrency;
            } else {
                const updatedCache = await addNewCardIssuerToWorldpayResponseCache();
                selectedCurrency = updatedCache.get(payerData?.CurrentCardIssuerCountry).DefaultCurrency;
            }

            setSelectedCurrency(selectedCurrency);
            await handleSelectedCurrencyChange(selectedCurrency);
            setIsWorldpayMcpSelected(true);
        }
    };

    const handleSelectedCurrencyChange = async (currency = selectedCurrency) => {
        const loader = showLoader({});
        const result = await ajaxJsonRequest<WorldpayPriceInformation>({
            body: { currency },
            url: ROUTES.SelectWorldpay,
        });

        triggers.sidebar.bookingChanged.publish({});

        hideLoader(loader);
        setWorldpayMcpPrice(result.data);
    };

    useEffect(handleCardIssuerCountryChange, [payerData?.CurrentCardIssuerCountry]);
    useEffect(handlePaymentMethodChange, [paymentMethod?.PaymentMethodCode]);
    useEffect(async () => {
        if (!props.isMcpOn || !isWorldpayMcpSelected) return;
        await handleSelectedCurrencyChange();
    }, [selectedCurrency]);

    return {
        currencyList: isWorldpayMcpSelected
            ? worldpayResponseCache.get(payerData?.CurrentCardIssuerCountry)?.Currencies
            : undefined,
        priceInfo: isWorldpayMcpSelected ? worldpayMcpPrice : undefined,
    };
};
