import { ChileCompraRole } from "../../component-models/app/UserContext";
import { FlowType } from "../../managers/useFlowContext";
import { ACTION_NAMES } from "../../shared/commonConstants";
import { SpaSectionType } from "./spa-container";

type SpaSectionOrderType =
    | "booking"
    | "checkin"
    | "chileCompraCheckin"
    | "chileCompraObserver"
    | "chileCompraSupervisorWithBundle"
    | "chileCompraSupervisorWithoutBundle"
    | "farelockRoundOne"
    | "farelockRoundTwo"
    | "peruCompraAnonymousCheckin"
    | "postBooking"
    | "quickCheckin"
    | "staffBooking";

interface SpaSecionOrderIndex {
    index: number;
    section: SpaTypeWithUri;
}

interface SpaTypeWithUri {
    type: SpaSectionType;
    uri: string;
}

const BAGGAGE_PAGE: SpaTypeWithUri = { type: "Baggage", uri: "V2/Baggage" };
const READONLY_SEATMAP_PAGE: SpaTypeWithUri = { type: "Seatmap", uri: "V2Checkin/CheckinSeatMap" };
const SEATMAP_PAGE: SpaTypeWithUri = { type: "Seatmap", uri: "Seat/Map" };
const EXTRAS_PAGE: SpaTypeWithUri = { type: "Extras", uri: "V2/Extras" };
const PASSENGERS_PAGE: SpaTypeWithUri = { type: "Passengers", uri: "V2/Passengers" };
const PAYMENT_PAGE: SpaTypeWithUri = { type: "Payment", uri: "V2/Payment" };
const REVIEW_PAGE: SpaTypeWithUri = { type: "Review", uri: "V2/Review" };

const spaSectionOrderMap = new Map<SpaSectionOrderType, SpaSecionOrderIndex[]>([
    ["quickCheckin", [{ index: 0, section: READONLY_SEATMAP_PAGE }]],
    [
        "booking",
        [
            { index: 0, section: BAGGAGE_PAGE },
            { index: 1, section: SEATMAP_PAGE },
            { index: 2, section: EXTRAS_PAGE },
            { index: 3, section: PASSENGERS_PAGE },
            { index: 4, section: PAYMENT_PAGE },
        ],
    ],
    [
        "checkin",
        [
            { index: 0, section: BAGGAGE_PAGE },
            { index: 1, section: SEATMAP_PAGE },
            { index: 2, section: EXTRAS_PAGE },
            { index: 3, section: PASSENGERS_PAGE },
            { index: 4, section: REVIEW_PAGE },
            { index: 5, section: PAYMENT_PAGE },
        ],
    ],
    ["chileCompraObserver", []],
    [
        "chileCompraSupervisorWithBundle",
        [
            { index: 0, section: SEATMAP_PAGE },
            { index: 1, section: PASSENGERS_PAGE },
            { index: 2, section: PAYMENT_PAGE },
        ],
    ],
    [
        "chileCompraSupervisorWithoutBundle",
        [
            { index: 0, section: PASSENGERS_PAGE },
            { index: 1, section: PAYMENT_PAGE },
        ],
    ],
    [
        "chileCompraCheckin",
        [
            { index: 0, section: SEATMAP_PAGE },
            { index: 1, section: REVIEW_PAGE },
        ],
    ],
    ["peruCompraAnonymousCheckin", [{ index: 0, section: READONLY_SEATMAP_PAGE }]],
    [
        "postBooking",
        [
            { index: 0, section: BAGGAGE_PAGE },
            { index: 1, section: SEATMAP_PAGE },
            { index: 2, section: EXTRAS_PAGE },
            { index: 3, section: PASSENGERS_PAGE },
            { index: 4, section: REVIEW_PAGE },
            { index: 5, section: PAYMENT_PAGE },
        ],
    ],
    [
        "farelockRoundOne",
        [
            { index: 0, section: PASSENGERS_PAGE },
            { index: 1, section: PAYMENT_PAGE },
        ],
    ],
    [
        "farelockRoundTwo",
        [
            { index: 0, section: BAGGAGE_PAGE },
            { index: 1, section: SEATMAP_PAGE },
            { index: 2, section: EXTRAS_PAGE },
            { index: 3, section: PASSENGERS_PAGE },
            { index: 4, section: PAYMENT_PAGE },
        ],
    ],
    [
        "staffBooking",
        [
            { index: 0, section: PASSENGERS_PAGE },
            { index: 1, section: BAGGAGE_PAGE },
            { index: 2, section: SEATMAP_PAGE },
            { index: 3, section: EXTRAS_PAGE },
            { index: 4, section: PAYMENT_PAGE },
        ],
    ],
]);

const filterPassengerSection = (data: {
    flowType: FlowType;
    isCheckinClosedOutbound: boolean;
    isStaff: boolean;
    section: SpaSecionOrderIndex;
    isChileCompraBookingForNonChileCompra: boolean;
}) => {
    if (data.section.section.type !== "Passengers") return true;
    if (data.flowType === "Checkin") return false;
    if (data.isStaff && data.flowType === "Change") return false;
    if (data.isChileCompraBookingForNonChileCompra) return false;

    return true;
};

export const spaSectionOrderFactory = () => {
    const getSpaSectionOrder = (data: {
        flowType: FlowType;
        hasBundle: boolean;
        isCheckinClosedOutbound: boolean;
        isChileCompraBookingForNonChileCompra: boolean;
        chileCompraRole: ChileCompraRole;
        isStaff: boolean;
    }) => {
        const isQuickCheckin = window.location.href.toLowerCase().includes(ACTION_NAMES.CHECKIN_SEATMAP.toLowerCase());

        // DEVNOTE If checkin is closed outbound, no passengers page
        const getOrderFor = (type: SpaSectionOrderType) =>
            spaSectionOrderMap
                .get(type)
                .sort((a, b) => a.index - b.index)
                .filter((section) => filterPassengerSection({ ...data, section }))
                .map((section, index) => ({ ...section, index }));

        if (isQuickCheckin) return getOrderFor("quickCheckin");

        if (data.flowType === "PeruCompraAnonymousCheckin") return getOrderFor("peruCompraAnonymousCheckin");

        if (data.chileCompraRole === "observer") return getOrderFor("chileCompraObserver");
        if (data.chileCompraRole === "supervisor" && data.flowType === "Checkin") {
            return getOrderFor("chileCompraCheckin");
        }
        if (data.chileCompraRole === "supervisor" && data.hasBundle) {
            return getOrderFor("chileCompraSupervisorWithBundle");
        }
        if (data.chileCompraRole === "supervisor" && !data.hasBundle) {
            return getOrderFor("chileCompraSupervisorWithoutBundle");
        }
        if (data.isStaff && data.flowType === "Booking") return getOrderFor("staffBooking");
        if (data.flowType === "FarelockRoundOne") return getOrderFor("farelockRoundOne");
        if (data.flowType === "FarelockRoundTwo") return getOrderFor("farelockRoundTwo");
        if (data.flowType === "Change") return getOrderFor("postBooking");
        if (data.flowType === "Checkin") return getOrderFor("checkin");

        return getOrderFor("booking");
    };

    return { getSpaSectionOrder };
};
