import { MercadoPagoPreference } from "../../component-models/payment/MercadoPagoPreference";
import { ROUTES } from "../../shared/apiRoutes";
import { getRequestBodyFromInputs } from "../../shared/common";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { raiseBookingFlowContinueEvent } from "../../shared/eventbus/raiseBookingFlowContinueEvent";
import { PaymentPageViewModel } from "../../component-models/payment/PaymentPageViewModel";
import { PaymentIntentFlowType } from "../../component-models/payment/PaymentIntentFlowType";
import { EtPayIntentResponse } from "../../component-models/payment/EtPayIntentResponse";
import { useBookingDataManager } from "../../managers/useBookingDataManager";
import { WompiRedirectResponse } from "../../component-models/payment/WompiRedirectResponse";
import { ErrorResponse } from "../../component-models/ErrorResponse";
import { SafetyPayRedirectResponse } from "../../component-models/payment/SafetyPayRedirectResponse";
import { SafetyPayIntentRequest } from "../../component-models/payment/SafetyPayIntentRequest";
import { useReduxState } from "../../shared/redux/useReduxState";
import { BookingCommitPostFactoryDto, bookingCommitPostFactory } from "./bookingCommitPostFactory";
import { ApiVoucherResult } from "../../component-models/payment/VoucherResult";

export interface Props {
    antiForgeryToken: string;
    antifraudIdNumber: string;
    antifraudIdType: "DNI" | "N" | "Passport";
    model: PaymentPageViewModel;
    selectedAgencyPaymentAmount: string;
    openFareClassFullModal: () => void;
}

export interface BookingCommitterDto {
    extraFieldValue?: string;
    type: PaymentIntentFlowType;
    voucherResult?: ApiVoucherResult;
}

export type BookingCommitResult = "fail" | "fraud" | "incomplete" | "success" | "redirected";

export const useBookingCommitter = (props: Props) => {
    const bookingDataManager = useBookingDataManager();

    const { ajaxRequest, ajaxJsonRequest } = useAjax();
    const { createFormPost } = bookingCommitPostFactory();

    const [cardData] = useReduxState("payment.cardData");
    const [displayedTotal] = useReduxState("payment.displayedTotal");
    const [payerData] = useReduxState("payment.payer");
    const [selectedCurrency] = useReduxState("payment.selectedCurrency");
    const [selectedPaymentMethod] = useReduxState("payment.paymentMethod");
    const [userContext] = useReduxState("userContext");
    const [wompiPseFormData] = useReduxState("payment.wompiPseFormData");
    const [currency] = useReduxState("booking.currency");

    const formPostDto = (data: {
        extraFieldValue?: string;
        type: PaymentIntentFlowType;
        voucherResult?: ApiVoucherResult;
    }): BookingCommitPostFactoryDto => ({
        antiForgeryToken: props.antiForgeryToken,
        antifraudIdNumber: props.antifraudIdNumber,
        antifraudIdType: props.antifraudIdType,
        bookingCurrency: currency,
        cardData,
        displayedTotal,
        extraFieldValue: data.extraFieldValue,
        model: props.model,
        payerData,
        selectedAgencyPaymentAmount: props.selectedAgencyPaymentAmount,
        selectedCurrency,
        selectedPaymentMethod,
        type: data.type,
        userContext,
        voucherResult: data.voucherResult,
    });

    const commitForm = async (
        form: HTMLFormElement,
        callbackOnSuccess: () => void,
        isRedemptionCommit = false,
    ): Promise<BookingCommitResult> => {
        const url = isRedemptionCommit ? ROUTES.ApiRoutes.BookingRedemptionCommit : ROUTES.ApiRoutes.BookingCommit;

        //TODO handle commit errors:
        //PaymentMethodCodeNotAllowed - V2/Payment
        //GiftCardError - GiftCard/Select
        //BenefitCheckFailed - V2/Payment
        //PassengersMissing - V2/Passengers
        //McpFeeValidationFailed - V2/Payment
        //BookingOutOfBalance - V2/Payment
        //PromoCodeNotApplicable - V2/Payment
        //success - Booking/PostCommit
        const commitResult = await ajaxRequest({
            body: getRequestBodyFromInputs(form),
            forceReturnResultOnError: true,
            url,
        });

        if (commitResult?.redirectionUrl) {
            window.location.href = commitResult.redirectionUrl;
            return "redirected";
        }

        if (commitResult?.statusCode === 204) {
            callbackOnSuccess();
            return "success";
        }

        const error = JSON.parse(commitResult?.data) as ErrorResponse;

        if (error?.Errors?.some((e) => e.ErrorCode === "FareClassFull")) {
            props.openFareClassFullModal();
            return "fail";
        }

        if (error?.Errors?.some((e) => e.ErrorCode === "FraudCheckFailed")) {
            return "fraud";
        }

        if (error?.Errors?.some((e) => e.ErrorCode === "GiftCardError")) {
            window.location.href = ROUTES.GiftcardSelect;
            return "redirected";
        }

        if (error?.Errors?.some((e) => e.ErrorCode === "PassengersMissing")) {
            window.location.href = ROUTES.PagePassengers;
            return "redirected";
        }

        if (
            error?.Errors?.some((e) =>
                [
                    "PaymentMethodCodeNotAllowed",
                    "BenefitCheckFailed",
                    "McpFeeValidationFailed",
                    "BookingOutOfBalance",
                    "PromoCodeNotApplicable",
                ].includes(e.ErrorCode),
            )
        ) {
            window.location.href = ROUTES.PagePayment;
            return "redirected";
        }

        throw new Error("Booking commit failed.");
    };

    /* CLIENT SIDE PAYMENT TYPES */

    // FIXME Add type
    const handleBechApiPayment = async (result: any) => {
        await (window as any).bechPay.initValidation(result);

        const type = await (window as any).bechPay.typeCard(cardData.CardNumber.substring(0, 6));

        const datos = {
            cuotas: cardData.SelectedInstallmentsNumber?.toString() || "1",
            cvv: cardData.Cvv,
            emisorTarjeta: type.result.emisor,
            fecha: cardData.Expiry.format("MM/YY"),
            marcaTarjeta: type.result.marca,
            ntarjeta: cardData.CardNumber.match(/.{1,6}/g).join(" "),
            tipoTarjeta: type.result.tipo,
        };

        await (window as any).bechPay.callPayment(datos);
    };

    const handleRedemptionPayment = async (data: BookingCommitterDto): Promise<BookingCommitResult> => {
        const form = createFormPost(formPostDto(data));

        return commitForm(
            form,
            () => {
                raiseBookingFlowContinueEvent(true);
                bookingDataManager.eraseBookingData();
                window.location.href = ROUTES.PostCommit;
            },
            true,
        );
    };

    const handleSafetyPayPayment = async (): Promise<BookingCommitResult> => {
        const body: SafetyPayIntentRequest = {
            Amount: props.model.AmountPerCurrency[selectedCurrency].Amount,
            CountryCode: payerData?.CurrentCardIssuerCountry || "",
            CurrencyCode: selectedCurrency,
            PaymentMethod: selectedPaymentMethod.PaymentMethodCode,
        };
        const result = await ajaxJsonRequest<SafetyPayRedirectResponse>({
            url: ROUTES.SafetyPayPaymentIntent,
            body,
        });

        if (result.statusCode !== 200 && result.statusCode !== 204) {
            alert("SafetyPay payment intent failed.");
            return "incomplete";
        }

        const form = createFormPost(formPostDto({ type: "safetyPay", extraFieldValue: result.data.OperationId }));

        return commitForm(form, () => (window.location.href = result.data.RedirectUrl));
    };

    const handleCompraquiPayment = async (): Promise<BookingCommitResult> => {
        // FIXME Add type
        const result = await ajaxJsonRequest<any>({
            url: ROUTES.ApiRoutes.CompraquiPaymentIntent,
            body: { amount: props.model.AmountPerCurrency?.CLP?.Amount.toString() },
        });

        const form = createFormPost(formPostDto({ type: "compraqui", extraFieldValue: result.data.token }));

        return commitForm(form, () => handleBechApiPayment(result.data));
    };

    const handleEtPayment = async (): Promise<BookingCommitResult> => {
        const result = await ajaxJsonRequest<EtPayIntentResponse>({
            url: ROUTES.EtPaymentIntent,
            body: { amount: props.model.AmountPerCurrency[selectedCurrency].Amount.toString() },
        });

        if (result.statusCode !== 200 && result.statusCode !== 204) {
            alert("ET payment intent failed.");
            return "incomplete";
        }

        const form = createFormPost(formPostDto({ type: "et" }));

        return commitForm(form, () => (window.location.href = result.data.InitPoint));
    };

    const handleCheckoutProPayment = async (): Promise<BookingCommitResult> => {
        const result = await ajaxJsonRequest<MercadoPagoPreference>({
            url: ROUTES.ApiRoutes.CheckoutProPaymentIntent,
            body: {
                Amount: props.model.AmountPerCurrency[selectedCurrency].Amount.toString(),
                CardIssuerCountry: payerData?.CurrentCardIssuerCountry || "",
                Currency: selectedCurrency,
                IsCashOnly: (selectedPaymentMethod?.ClientSidePaymentType === "Cash").toString(),
                PaymentMethodCode: cardData.PaymentMethodCodeToSubmit || selectedPaymentMethod.PaymentMethodCode,
            },
        });

        if (result.statusCode !== 200 && result.statusCode !== 204) {
            alert("Checkout Pro payment intent failed.");
            return "incomplete";
        }

        const form = createFormPost(formPostDto({ type: "checkoutPro", extraFieldValue: result.data.Id }));

        return commitForm(form, () => (window.location.href = result.data.InitPoint));
    };

    const handleBancolumbiaTransferButtonPayment = async (): Promise<BookingCommitResult> => {
        const result = await ajaxJsonRequest<WompiRedirectResponse>({
            url: ROUTES.ApiRoutes.WompiBancolumbiaButtonIntent,
            body: { amount: props.model.AmountPerCurrency[selectedCurrency].Amount.toString() },
        });

        if (result.statusCode !== 200 && result.statusCode !== 204) {
            alert("Bancolumbia Transfer Button payment intent failed.");
            return "incomplete";
        }

        const form = createFormPost(formPostDto({ type: "bn" }));

        return commitForm(form, () => (window.location.href = result.data.PaymentUrl));
    };

    const handleBancolumbiaPsePayment = async (): Promise<BookingCommitResult> => {
        const result = await ajaxJsonRequest<WompiRedirectResponse>({
            url: ROUTES.ApiRoutes.WompiPseIntent,
            body: {
                Amount: props.model.AmountPerCurrency[selectedCurrency].Amount.toString(),
                InstitutionCode: wompiPseFormData.instituionCode,
                LegalId: wompiPseFormData.legalId,
                LegalIdType: wompiPseFormData.legalIdType,
                UserType: wompiPseFormData.userType,
            },
        });

        if (result.statusCode !== 200 && result.statusCode !== 204) {
            alert("Bancolumbia PSE payment intent failed.");
            return "incomplete";
        }

        const form = createFormPost(formPostDto({ type: "bp" }));

        return commitForm(form, () => (window.location.href = result.data.PaymentUrl));
    };

    // EXPORTS

    const commitBooking = async (data: BookingCommitterDto): Promise<BookingCommitResult> => {
        const noCommitFlows: PaymentIntentFlowType[] = [
            "afterCommission",
            "beforeCommission",
            "cat1234CreditShellGetInfo",
            "cat56CreditShellGetInfo",
            "voucherGetInfo",
        ];

        if (noCommitFlows.includes(data.type)) throw new Error("This flow needs no booking commit.");

        if (data.type === "redemption" || data.type === "redemptionTest") return handleRedemptionPayment(data);

        if (data.type === "bn") return handleBancolumbiaTransferButtonPayment();

        if (data.type === "bp") return handleBancolumbiaPsePayment();

        if (data.type === "checkoutPro") return handleCheckoutProPayment();

        if (data.type === "et") return handleEtPayment();

        if (data.type === "compraqui") return handleCompraquiPayment();

        if (data.type === "safetyPay") return handleSafetyPayPayment();

        const form = createFormPost(formPostDto(data));

        return commitForm(form, () => {
            raiseBookingFlowContinueEvent(true);
            bookingDataManager.eraseBookingData();
            window.location.href = ROUTES.PostCommit;
        });
    };

    return { commitBooking };
};
