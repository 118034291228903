import { LocalizedString } from "../component-models/LocalizedString";
import {
    ApiBundle,
    ApiBundleOfferMiles,
    BundleData,
    BundleInfo,
    CustomizedBundle,
    CustomizedBundleOffers,
    RedemptionBundleData,
} from "../component-models/flight/BundleOffersV2Model";
import { getLocalizedStringValue } from "../shared/common";

export function mapBundleOffers(bundleData: BundleData, culture: string): CustomizedBundleOffers[] {
    return bundleData.BundleOffersCollection.map((offerGroup) => {
        return {
            DCOffers: offerGroup.DCOffers.map((offer) =>
                mapBundleOffer(offer, bundleData.BundleInfoCollection, culture),
            ),
            NormalOffers: offerGroup.NormalOffers.map((offer) =>
                mapBundleOffer(offer, bundleData.BundleInfoCollection, culture),
            ),
            RedemptionOffers: [],
            SellKey: offerGroup.SellKey,
        };
    });
}

export function mapRedemptionBundleOffers(
    redemptionBundleData: RedemptionBundleData,
    culture: string,
): CustomizedBundleOffers[] {
    return redemptionBundleData?.Offers?.map((offerGroup) => {
        return {
            DCOffers: [],
            NormalOffers: [],
            RedemptionOffers: offerGroup.RedemptionBundleOffers.map((offer) =>
                mapRedemptionBundleOfferToCustomized(offer, redemptionBundleData.Info, culture),
            ),
            SellKey: offerGroup.SellKey,
        };
    });
}

function getBundleLocalizedProperty(data: {
    bundleInfos: BundleInfo[];
    bundleCode: string;
    property: keyof BundleInfo;
    culture: string;
}): string {
    const bundle = data.bundleInfos.find((b) => b.BundleCode === data.bundleCode);
    return bundle ? getLocalizedStringValue(bundle[data.property] as LocalizedString, data.culture) : "";
}

function mapBundleOffer(offer: ApiBundle, bundleInfos: BundleInfo[], culture: string): CustomizedBundle {
    const info = bundleInfos.find((bi) => bi.BundleCode === offer.BundleCode);
    const notAvailableImage = "https://upload.wikimedia.org/wikipedia/commons/c/c4/No_icon_red.svg";

    const displayName = getBundleLocalizedProperty({
        bundleInfos,
        bundleCode: offer.BundleCode,
        property: "DisplayName",
        culture,
    });
    const headerImgUrl = getBundleLocalizedProperty({
        bundleInfos,
        bundleCode: offer.BundleCode,
        property: "HeaderImgUrl",
        culture,
    });
    const ssrInfoImgUrl = getBundleLocalizedProperty({
        bundleInfos,
        bundleCode: offer.BundleCode,
        property: "SsrInfoImgUrl",
        culture,
    });
    const footerImgUrl = getBundleLocalizedProperty({
        bundleInfos,
        bundleCode: offer.BundleCode,
        property: "FooterImgUrl",
        culture,
    });
    const upgradeImgUrl = getBundleLocalizedProperty({
        bundleInfos,
        bundleCode: offer.BundleCode,
        property: "UpgradeImgUrl",
        culture,
    });
    const upgradeImgMobileUrl = getBundleLocalizedProperty({
        bundleInfos,
        bundleCode: offer.BundleCode,
        property: "UpgradeImgMobileUrl",
        culture,
    });

    return {
        ...offer,
        Color: info?.Color ? info.Color : "hotpink",
        ContainsFreeAnySeat: info?.ContainsFreeAnySeat,
        ContainsFreeRegularSeat: info?.ContainsFreeRegularSeat,
        DisplayName: displayName ? displayName : notAvailableImage,
        HeaderImgUrl: headerImgUrl ? headerImgUrl : notAvailableImage,
        SsrInfoImgUrl: ssrInfoImgUrl ? ssrInfoImgUrl : notAvailableImage,
        FooterImgUrl: footerImgUrl ? footerImgUrl : notAvailableImage,
        UpgradeImgUrl: upgradeImgUrl ? upgradeImgUrl : notAvailableImage,
        UpgradeImgMobileUrl: upgradeImgMobileUrl ? upgradeImgMobileUrl : notAvailableImage,
    };
}

function mapRedemptionBundleOfferToCustomized(
    offer: ApiBundleOfferMiles,
    bundleInfos: BundleInfo[],
    culture: string,
): CustomizedBundle {
    const info = bundleInfos.find((bi) => bi.BundleCode === offer.BundleCode);
    const notAvailableImage = "https://upload.wikimedia.org/wikipedia/commons/c/c4/No_icon_red.svg";

    const displayName = getBundleLocalizedProperty({
        bundleInfos,
        bundleCode: offer.BundleCode,
        property: "DisplayName",
        culture,
    });
    const headerImgUrl = getBundleLocalizedProperty({
        bundleInfos,
        bundleCode: offer.BundleCode,
        property: "HeaderImgUrl",
        culture,
    });
    const ssrInfoImgUrl = getBundleLocalizedProperty({
        bundleInfos,
        bundleCode: offer.BundleCode,
        property: "SsrInfoImgUrl",
        culture,
    });
    const footerImgUrl = getBundleLocalizedProperty({
        bundleInfos,
        bundleCode: offer.BundleCode,
        property: "FooterImgUrl",
        culture,
    });
    const upgradeImgUrl = getBundleLocalizedProperty({
        bundleInfos,
        bundleCode: offer.BundleCode,
        property: "UpgradeImgUrl",
        culture,
    });
    const upgradeImgMobileUrl = getBundleLocalizedProperty({
        bundleInfos,
        bundleCode: offer.BundleCode,
        property: "UpgradeImgMobileUrl",
        culture,
    });

    return {
        BundleCode: offer.BundleCode,
        BundleType: offer.BundleType,
        BundleSSRs: offer.Ssrs,
        DesktopPosition: offer.DesktopPosition,
        MobilePosition: offer.MobilePosition,
        FormattedPrice: `${offer.Miles} miles`,
        UnFormattedPrice: offer.Miles,
        BundlePrice: offer.Miles,
        BundleDiscountPercentage: undefined,
        NonDiscountedPrice: undefined,
        SumOfSsrPricesWithoutBundle: undefined,

        Color: info?.Color ? info.Color : "hotpink",
        ContainsFreeAnySeat: info?.ContainsFreeAnySeat,
        ContainsFreeRegularSeat: info?.ContainsFreeRegularSeat,
        DisplayName: displayName ? displayName : notAvailableImage,
        HeaderImgUrl: headerImgUrl ? headerImgUrl : notAvailableImage,
        SsrInfoImgUrl: ssrInfoImgUrl ? ssrInfoImgUrl : notAvailableImage,
        FooterImgUrl: footerImgUrl ? footerImgUrl : notAvailableImage,
        UpgradeImgUrl: upgradeImgUrl ? upgradeImgUrl : notAvailableImage,
        UpgradeImgMobileUrl: upgradeImgMobileUrl ? upgradeImgMobileUrl : notAvailableImage,
    };
}
