import i18next from "i18next";
import { html, useRef } from "haunted";
import { ref } from "../../../directives/ref";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { PaymentResult } from "../../../component-models/PaymentResult";
import { ApiTealiumInfo } from "../../../component-models/ApiTealiumInfo";
import { getTransactionTitleText } from "../../../component-helpers/itinerary/ItineraryHelper";
import { useModal } from "../../shared/useModal";
import { useFlowContext } from "../../../managers/useFlowContext";
import { mediaObtainer } from "../../shared/mediaObtainer";

export interface Props {
    farelockExpiry: string;
    filename: string;
    paymentResult: PaymentResult;
    showTransactionBox: boolean;
    tealiumProperties: ApiTealiumInfo;
    onClose: () => void;
}

export const useTransactionDetailsModal = (props: Props) => {
    // HELPERS
    const getArea = () => printedArea?.current;

    // COMPONENT

    const printedArea = useRef<HTMLDivElement>(undefined);

    const obtainer = mediaObtainer();

    const flowContext = useFlowContext();

    //TEMPLATES

    const linksTemplate = () => {
        return html`
            <div class="transaction-button-container">
                <a
                    class="rounded-transaction-btn print light-blue"
                    data-test-id=${T.ITINERARY.TRANSACTIONS_PRINT_BUTTON}
                    @click=${() => obtainer?.simplePrint()}
                >
                    ${i18next.t("Print")}
                </a>
                <a
                    class="rounded-transaction-btn download light-blue"
                    data-test-id=${T.ITINERARY.TRANSACTIONS_DOWNLOAD_BUTTON}
                    @click=${() => obtainer?.download(getArea, props.filename)}
                >
                    ${i18next.t("Download")}
                </a>
            </div>
        `;
    };

    const fareLockTemplate = () =>
        flowContext.isFarelockRoundTwo
            ? html`
                  <div class="hidden">
                      <h2 class="main-title ts-download-title">
                          ${getTransactionTitleText(props?.paymentResult.PaymentType)}
                      </h2>
                  </div>
              `
            : "";

    const paymentTitleIconTemplate = () =>
        props?.paymentResult?.PaymentType === "Cash"
            ? html`<i class="js-icon-it js-it-money title-icon"></i>`
            : html`<i class="js-circle-payment js-icon title-icon"></i>`;

    const noFareLockTitleTemplate = () =>
        !flowContext.isFarelockRoundTwo
            ? html`
                  <div class="title full-width-title">
                      <div class="main-title ts-download-title mb-4 flex items-center leading-none">
                          ${paymentTitleIconTemplate()} ${getTransactionTitleText(props?.paymentResult.PaymentType)}
                      </div>
                  </div>
              `
            : "";

    const transactionContainerTemplate = () => html`
        <div class="row">
            <header class="transaction-header flex items-center gap-2">
                ${fareLockTemplate()} ${noFareLockTitleTemplate()}
            </header>
        </div>
    `;

    const paymentResultsTemplate = () => html`
        ${transactionContainerTemplate()}
        <ac-transaction-logger
            .logWithTealium=${false}
            .paymentResultData=${props?.paymentResult}
            .tealiumProperties=${props?.tealiumProperties}
        ></ac-transaction-logger>
    `;

    const htmlTemplate = () => html`
        <div class="scrollable-area w-full overflow-y-scroll md:overflow-y-hidden">
            <div class="itinerary-transaction-details-modal-header" data-test-id=${T.ITINERARY.SUCCESSFUL_TRAVEL}>
                <div class="title">
                    <h2 class="main-title">${i18next.t("Información de la transacción")}</h2>
                </div>
            </div>
            <div class="modal-body">
                <div ref=${ref(printedArea)} class=${!props?.showTransactionBox ? "ts-printed-area printed-area" : ""}>
                    ${paymentResultsTemplate()}
                </div>

                <div class="modal-button-container no-print">${linksTemplate()}</div>
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { isClosable: true, onClose: props.onClose, buttonClassNames: "transaction-details-closing no-print" },
        content: { classNames: "booking-modal-content flex", template: htmlTemplate },
        overlay: { classNames: "itinerary-transaction-details-modal", testId: T.ITINERARY.SUCCESSFUL_TRAVEL },
    });

    return modal;
};
