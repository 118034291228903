import { html } from "lit-html";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import {
    SelectOption,
    ageDictionary,
    getPaxLabel,
    groupBy,
    mapCountryCallingCodeListToSelectOptions,
} from "../../../shared/common";
import classNames from "classnames";
import { PassengerUpdateEvent } from "./usePassengersPage";
import { PassengerField } from "./passengerFormFactory";
import { useMemo } from "../../../shared/haunted/CustomHooks";
import { ref } from "../../../directives/ref";
import { useRef } from "haunted";
import i18next from "i18next";
import { useSpecialAssistance } from "./useSpecialAssistance";
import { ApiSpecialAssistanceSsrModel } from "../../../component-models/passengers/ApiSpecialAssistanceSsrModel";
import { StaffBenefitFamilyItem } from "../../../component-models/staff-benefit/StaffBenefitFamilyItem";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { useFamilyMemberSelectorModal } from "./useFamilyMemberSelectorModal";
import { DEFAULT_DATE_FORMAT, FAKE_DATE_OF_BIRTH } from "../../../shared/commonConstants";
import { useCompactDatepicker } from "../../ui/useCompactDatepicker/useCompactDatepicker";
import { useAppContext } from "../../../managers/useAppContext";
import { useWindowSize } from "../../../shared/customHooks/useWindowSize";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { FluentValidatorMethodsPartial } from "../../../validator/Validation";
import { useFlowContext } from "../../../managers/useFlowContext";
import { useDebouncedValue } from "../../../shared/haunted/useDebouncedValue";
import { PassengerFormVM } from "../../../component-models/passengers/PassengerFormVM";

export const name = "ac-passenger";

export interface Props {
    arrivalDate: string;
    assistanceOptions: ApiSpecialAssistanceSsrModel[];
    departureDate: string;
    familyMembers: StaffBenefitFamilyItem[];
    formFields: PassengerField[];
    iAmTravelling: boolean;
    isDocNumberUnique: boolean;
    isFrequentFlyerNumberUnique: boolean;
    isOpen: boolean;
    isPassengerPageInitialized: boolean;
    isPaxCheckedIn: boolean;
    isValid: boolean;
    oxyOptions: ApiSpecialAssistanceSsrModel[];
    paxData: PassengerFormVM;
    paxIndexByType: number;
    validator: FluentValidatorMethodsPartial;
    handleAccordionClick: (e: MouseEvent, paxIndex: number) => void;
    handleChange: (e: PassengerUpdateEvent) => void;
    handleFamilyMemberSelect: (pax: PassengerFormVM, familyMemberGuid: string) => void;
    handleSubmitPassenger: () => void;
    onDocumentBlur: () => void;
    onFrequentFlyerBlur: () => void;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        arrivalDate: host.arrivalDate,
        assistanceOptions: host.assistanceOptions,
        departureDate: host.departureDate,
        familyMembers: host.familyMembers,
        formFields: host.formFields,
        iAmTravelling: host.iAmTravelling,
        isDocNumberUnique: host.isDocNumberUnique,
        isFrequentFlyerNumberUnique: host.isFrequentFlyerNumberUnique,
        isOpen: host.isOpen,
        isPassengerPageInitialized: host.isPassengerPageInitialized,
        isPaxCheckedIn: host.isPaxCheckedIn,
        isValid: host.isValid,
        oxyOptions: host.oxyOptions,
        paxData: host.paxData,
        paxIndexByType: host.paxIndexByType,
        validator: host.validator,
        handleAccordionClick: host.handleAccordionClick,
        handleChange: host.handleChange,
        handleFamilyMemberSelect: host.handleFamilyMemberSelect,
        handleSubmitPassenger: host.handleSubmitPassenger,
        onDocumentBlur: host.onDocumentBlur,
        onFrequentFlyerBlur: host.onFrequentFlyerBlur,
    };

    const appContext = useAppContext();
    const flowContext = useFlowContext();

    const { width } = useWindowSize();

    const [userContext] = useReduxState("userContext");

    const content = useRef<HTMLFormElement>(undefined);

    const debouncedIsOpen = useDebouncedValue(props.isOpen, 500);

    const specialAssistance = useSpecialAssistance({
        assistanceOptions: props.assistanceOptions,
        isPassengerPageInitialized: props.isPassengerPageInitialized,
        oxyOptions: props.oxyOptions,
        parentRoot: content.current,
        passengerNumber: props.paxData.uniqueIndex,
        passengerType: props.paxData.type,
    });

    const orderedFilteredFields = useMemo(
        () => props.formFields.filter((field) => field.isShown(props.paxData)).sort((a, b) => a.index - b.index),
        [props.formFields],
    );

    const groups = useMemo(
        () =>
            groupBy(
                orderedFilteredFields.filter((field) => field.groupId),
                "groupId",
            ),
        [orderedFilteredFields],
    );

    const isPaxDateOfBirthFake = () =>
        props.paxData.dateOfBirth?.isSame(dayjs(FAKE_DATE_OF_BIRTH, DEFAULT_DATE_FORMAT), "date");

    const getFieldName = (fieldName: keyof PassengerFormVM) => `passengers.${props.paxData.uniqueIndex}.${fieldName}`;

    // DEVNOTE see JET-10352, subtract max years and adjust the date by almost a year (364 days) + 1 day
    const minDate = useMemo(
        () =>
            dayjs(props.arrivalDate, DEFAULT_DATE_FORMAT)
                .subtract(ageDictionary.get(props.paxData.type).max, "year")
                .subtract(363, "day"),
        [props.arrivalDate],
    );

    const maxDate = useMemo(
        () =>
            dayjs(props.departureDate, DEFAULT_DATE_FORMAT).subtract(ageDictionary.get(props.paxData.type).min, "year"),
        [props.departureDate],
    );

    const dateOfBirth = useCompactDatepicker({
        customClass: "compact",
        disabled: props.formFields.find((field) => field.key === "dateOfBirth").isReadonly(props.paxData),
        index: props.paxData.uniqueIndex,
        isInvalid: !props.validator.isFieldValid(getFieldName("dateOfBirth")),
        label: i18next.t("Fecha de nacimiento"),
        maxDate,
        minDate,
        type: "date-of-birth",
        value: !isPaxDateOfBirthFake() ? props.paxData.dateOfBirth : undefined,
        onChange: (value) =>
            props.handleChange({ passengerUniqueIndex: props.paxData.uniqueIndex, key: "dateOfBirth", value }),
    });

    const familyMemberModalSelectionCallback = async (member: StaffBenefitFamilyItem) => {
        await specialAssistance.removeAllSelections();
        props.handleFamilyMemberSelect(props.paxData, member.GUID);
    };

    const familyMemberModal = useFamilyMemberSelectorModal({
        familyMembers: props.familyMembers,
        onSelect: familyMemberModalSelectionCallback,
    });

    const handleFamilyButtonClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        familyMemberModal.open();
    };

    const handleSubmitPassenger = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        props.handleSubmitPassenger();
    };

    const handleAccordionClick = (e: MouseEvent) => {
        if (!flowContext.isFarelockRoundOne) props.handleAccordionClick(e, props.paxData.uniqueIndex);
    };

    const farelockInfoBarTemplate = () =>
        flowContext.isFarelockRoundOne && props.paxData.uniqueIndex === 1
            ? html`
                  <div class="passengers-fare-lock-pax-info" data-test-id=${T.PASSENGERS.FARELOCK_INFO}>
                      <span>!</span> ${i18next.t("PassengersFareLockPaxInfo")}
                  </div>
              `
            : "";

    const getTextInputDataTestId = (field: PassengerField) => {
        switch (field.key) {
            case "firstName":
                return getTestId(T.PASSENGERS.FIRST_NAME, { p: props.paxData.uniqueIndex });
            case "contactFirstName":
                return getTestId(T.PASSENGERS.CONTACT_FIRST_NAME, { p: props.paxData.uniqueIndex });
            case "lastName":
                return getTestId(T.PASSENGERS.LAST_NAME, { p: props.paxData.uniqueIndex });
            case "contactLastName":
                return getTestId(T.PASSENGERS.CONTACT_LAST_NAME, { p: props.paxData.uniqueIndex });
            case "documentNumber":
                return getTestId(T.PASSENGERS.DOC_NUMBER, { p: props.paxData.uniqueIndex });
            case "frequentFlyerNumber":
                return getTestId(T.PASSENGERS.FREQUENT_FLYER_NUMBER, { p: props.paxData.uniqueIndex });
            case "phoneNumber":
                return getTestId(T.PASSENGERS.PHONE, { p: props.paxData.uniqueIndex });
            case "officePhoneNumber":
                return getTestId(T.PASSENGERS.OFFICE_PHONE_NUMBER, { p: props.paxData.uniqueIndex });
            case "contactPhoneNumber":
                return getTestId(T.PASSENGERS.CONTACT_PHONE_NUMBER, { p: props.paxData.uniqueIndex });
            case "contactOfficePhoneNumber":
                return getTestId(T.PASSENGERS.CONTACT_OFFICE_PHONE_NUMBER, { p: props.paxData.uniqueIndex });
            case "email":
                return getTestId(T.PASSENGERS.EMAIL, { p: props.paxData.uniqueIndex });
            case "contactEmail":
                return getTestId(T.PASSENGERS.CONTACT_EMAIL, { p: props.paxData.uniqueIndex });
            default:
                return "";
        }
    };

    const textInputTemplate = (field: PassengerField) => html`
        <div class="relative">
            <ac-input
                .errorMessage=${props.validator.getMessage(getFieldName(field.key))}
                .isDisabled=${field.isReadonly(props.paxData)}
                .isInvalid=${!props.validator.isFieldValid(getFieldName(field.key))}
                .isReadonly=${field.isReadonly(props.paxData)}
                .label=${field.label}
                .testId=${getTextInputDataTestId(field)}
                .name=${field.key + "-" + props.paxData.uniqueIndex}
                .sanitizer=${field.sanitizer
                    ? (e: KeyboardEvent) => field.sanitizer(e, props.paxData.documentType)
                    : undefined}
                .value=${props.paxData[field.key]}
                .onBlur=${field.key === "documentNumber"
                    ? props.onDocumentBlur
                    : field.key === "frequentFlyerNumber"
                      ? props.onFrequentFlyerBlur
                      : undefined}
                .onInput=${(value: string) =>
                    props.handleChange({ passengerUniqueIndex: props.paxData.uniqueIndex, key: field.key, value })}
            ></ac-input>
            ${field.tooltip ? html`<div class="absolute right-3 top-4">${field.tooltip()}</div>` : ""}
        </div>
    `;

    const getSelectInputDataTestId = (field: PassengerField) => {
        switch (field.key) {
            case "documentType":
                return getTestId(T.PASSENGERS.DOC_TYPE, { p: props.paxData.uniqueIndex });
            case "country":
                return getTestId(T.PASSENGERS.COUNTRY, { p: props.paxData.uniqueIndex });
            case "phonePrefix":
                return getTestId(T.PASSENGERS.PHONE_PREFIX, { p: props.paxData.uniqueIndex });
            case "officePhonePrefix":
                return getTestId(T.PASSENGERS.OFFICE_PHONE_PREFIX, { p: props.paxData.uniqueIndex });
            case "contactPhonePrefix":
                return getTestId(T.PASSENGERS.CONTACT_PHONE_PREFIX, { p: props.paxData.uniqueIndex });
            case "contactOfficePhonePrefix":
                return getTestId(T.PASSENGERS.CONTACT_OFFICE_PHONE_PREFIX, { p: props.paxData.uniqueIndex });
            default:
                return "";
        }
    };

    const selectTemplate = (field: PassengerField, options: SelectOption[]) => html`
        <div>
            <ac-select
                .errorMessage=${props.validator.getMessage(getFieldName(field.key))}
                .isDisabled=${field.isReadonly(props.paxData)}
                .isInvalid=${!props.validator.isFieldValid(getFieldName(field.key))}
                .label=${field.label}
                .options=${options}
                .testId=${getSelectInputDataTestId(field)}
                .name=${field.key + "-" + props.paxData.uniqueIndex}
                .onBlur=${field.key === "documentType" ? props.onDocumentBlur : undefined}
                .onSelect=${(value: string) =>
                    props.handleChange({
                        passengerUniqueIndex: props.paxData.uniqueIndex,
                        key: field.key,
                        value,
                    })}
            ></ac-select>
        </div>
    `;

    const paxTypeOptions: SelectOption[] = [
        {
            IsSelected: !props.paxData.chileCompraPaxType,
            Text: "",
            Value: "",
        },
        {
            IsSelected: props.paxData.chileCompraPaxType === i18next.t("Funcionario"),
            Text: i18next.t("Funcionario"),
            Value: i18next.t("Funcionario"),
        },
        {
            IsSelected: props.paxData.chileCompraPaxType === i18next.t("Alta dirección"),
            Text: i18next.t("Alta dirección"),
            Value: i18next.t("Alta dirección"),
        },
        {
            IsSelected: props.paxData.chileCompraPaxType === i18next.t("Invitado"),
            Text: i18next.t("Invitado"),
            Value: i18next.t("Invitado"),
        },
    ];

    const documentTypeOptions: SelectOption[] = [
        {
            IsSelected: props.paxData.documentType === "N",
            Text: i18next.t("RUT"),
            Value: "N",
        },
        {
            IsSelected: props.paxData.documentType === "P",
            Text: i18next.t("Pasaporte"),
            Value: "P",
        },
        {
            IsSelected: props.paxData.documentType === "DNI",
            Text: i18next.t("DNI/C.I."),
            Value: "DNI",
        },
    ];

    const phonePrefixList = (selected: string) =>
        mapCountryCallingCodeListToSelectOptions(appContext.PhonePrefixes, appContext.Culture, selected);

    const inputFieldTemplate = (field: PassengerField) => {
        if (field.type === "text") {
            return html`
                <div>
                    ${textInputTemplate(field)}
                    ${field.key === "documentNumber" ? nonUniqueDocumentNumberTemplate() : ""}
                    ${field.key === "frequentFlyerNumber" ? nonUniqueFrequentFlyerNumberTemplate() : ""}
                </div>
            `;
        }

        if (field.type === "date") {
            switch (field.key) {
                case "dateOfBirth":
                    return dateOfBirth.htmlTemplate();
                default:
                    return "";
            }
        }

        if (field.type === "select") {
            switch (field.key) {
                case "documentType":
                    return selectTemplate(field, documentTypeOptions);
                case "country":
                    return selectTemplate(
                        field,
                        appContext.Countries.map((c) => ({
                            ...c,
                            IsSelected: props.paxData.country
                                ? c.Value === props.paxData.country
                                : c.Value === appContext.Country,
                        })),
                    );
                case "phonePrefix":
                    return selectTemplate(field, phonePrefixList(props.paxData.phonePrefix));
                case "officePhonePrefix":
                    return selectTemplate(field, phonePrefixList(props.paxData.officePhonePrefix));
                case "contactPhonePrefix":
                    return selectTemplate(field, phonePrefixList(props.paxData.contactPhonePrefix));
                case "contactOfficePhonePrefix":
                    return selectTemplate(field, phonePrefixList(props.paxData.contactOfficePhonePrefix));
                case "chileCompraPaxType":
                    return selectTemplate(field, paxTypeOptions);
                default:
                    return "";
            }
        }

        return "";
    };

    const desktopInputGroupTemplate = (group: PassengerField[]) => html`
        <div class="grid grid-cols-5 gap-4">
            ${group.map(
                (field) => html`
                    <div class="!p-0 md:first:col-span-2 md:last:col-span-3">${inputFieldTemplate(field)}</div>
                `,
            )}
        </div>
    `;

    const tabletInputGroupTemplate = (group: PassengerField[]) => html`
        <div class="col-span-2 grid grid-cols-2 gap-4 !p-0">${group.map((field) => inputFieldTemplate(field))}</div>
    `;

    const mobileInputGroupTemplate = (group: PassengerField[]) => group.map((field) => inputFieldTemplate(field));

    const inputGroupTemplate = (id: string) =>
        width < 768
            ? mobileInputGroupTemplate(groups[id])
            : width < 1024
              ? tabletInputGroupTemplate(groups[id])
              : desktopInputGroupTemplate(groups[id]);

    const inputFieldsTemplate = () => {
        let isContactInfoHeaderDisplayed = false;
        const groupsDisplayed: Set<string> = new Set();

        return orderedFilteredFields.flatMap((field) => {
            if (groupsDisplayed.has(field.groupId)) return "";

            if (field.groupId) {
                groupsDisplayed.add(field.groupId);
                return inputGroupTemplate(field.groupId);
            }

            if (!isContactInfoHeaderDisplayed && field.isContactField) {
                isContactInfoHeaderDisplayed = true;
                return [contactInfoHeaderTemplate(), inputFieldTemplate(field)];
            }

            return inputFieldTemplate(field);
        });
    };

    const specialAssistanceTemplate = () => (props.paxData.type !== "INF" ? specialAssistance.htmlTemplate() : "");

    const staffFamilyMemberSelectorTemplate = () =>
        userContext.isStaff &&
        props.familyMembers &&
        (!props.iAmTravelling || props.paxData.uniqueIndex !== 0 || props.paxData.type !== "ADT")
            ? html`
                  <div class="staff-family-button-container">
                      <button
                          class=${classNames("rounded-primary-btn white-blue", {
                              disabled: props.familyMembers.length === 0,
                          })}
                          @click=${handleFamilyButtonClick}
                      >
                          <i class="js-circle-user js-icon title-icon"></i>
                          ${props.paxData?.firstName && props.paxData?.lastName
                              ? i18next.t("Cambiar beneficiario")
                              : i18next.t("Seleccionar beneficiario")}
                      </button>
                  </div>
              `
            : html``;

    const contactInfoHeaderTemplate = () =>
        html`<div class="passengers-grid-contact-info">${i18next.t("Información de contacto")}</div>`;

    const nonUniqueDocumentNumberTemplate = () =>
        !props.isDocNumberUnique
            ? html`
                  <div
                      class="row"
                      data-test-id=${getTestId(T.PASSENGERS.DOC_UNIQUENESS_ERROR, { p: props.paxData.uniqueIndex })}
                  >
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${i18next.t("Passengers-UniquenessError")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const nonUniqueFrequentFlyerNumberTemplate = () =>
        !props.isFrequentFlyerNumberUnique
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${i18next.t("Passengers-UniquenessError")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const buttonTemplate = () =>
        !flowContext.isFarelockRoundOne
            ? html`
                  <div class="mt-4">
                      <button
                          class="rounded-primary-btn"
                          data-test-id=${getTestId(T.PASSENGERS.SAVE_BUTTON, { p: props.paxData.uniqueIndex })}
                          @click=${handleSubmitPassenger}
                      >
                          ${i18next.t("Guardar")}
                      </button>
                  </div>
              `
            : "";

    const formTemplate = () => html`
        <form
            ref=${ref(content)}
            style=${`max-height:${props.isOpen ? 2000 : 0}px`}
            class=${classNames("ts-error-container common-transition", {
                "overflow-hidden": !props.isOpen || !debouncedIsOpen,
            })}
        >
            <div class="mt-2 flex flex-col gap-2 sm:mt-4 sm:grid sm:grid-cols-2 sm:gap-4">
                ${inputFieldsTemplate()} ${staffFamilyMemberSelectorTemplate()}
            </div>
            ${specialAssistanceTemplate()} ${buttonTemplate()}
        </form>
    `;

    const tickTemplate = () =>
        props.isValid ? html`<i class="js-icon js-flight-tick ml-3 text-xl text-new-ui-blue"></i>` : "";

    const accordionArrowTemplate = () =>
        !flowContext.isFarelockRoundOne ? html`<div class="accordion-arrow"></div>` : "";

    const accordionHeaderTemplate = () => html`
        <div
            class=${classNames("passenger-page-accordion-header", {
                "open": props.isOpen,
                "cursor-pointer": !flowContext.isFarelockRoundOne,
                "bg-white": !flowContext.isFarelockRoundOne || props.paxData.uniqueIndex === 0,
                "bg-bg-gray-1": flowContext.isFarelockRoundOne && props.paxData.uniqueIndex !== 0,
            })}
            data-test-id=${getTestId(T.PASSENGERS.ACCORDION_HEADER, { p: props.paxData.uniqueIndex })}
            data-test-value=${props.isOpen}
            @click=${handleAccordionClick}
        >
            <i class="user-icon far fa-user"></i>
            <div class="relative flex items-center">
                ${getPaxLabel(props.paxData.type)} ${props.paxIndexByType + 1} ${tickTemplate()}
            </div>
            ${accordionArrowTemplate()}
        </div>
    `;

    return html`
        ${farelockInfoBarTemplate()}
        <div>${accordionHeaderTemplate()} ${formTemplate()}</div>
        ${familyMemberModal.htmlTemplate()}
    `;
};
