import i18next from "i18next";
import { html } from "lit-html";
import { BACKPACK_MOBILE_IMG } from "../../spa-container";
import { baggageIllustrations } from "../common/baggageIllustrations";

export interface Props {}

export const perJourneyPerPaxFreeCabin = () => {
    const { backpackTooltipTemplate } = baggageIllustrations();

    const htmlTemplate = () => html`
        <div class="b2m-per-pax-section">
            <div class="b2m-illustration">
                <img class="b2-free-cabin-img" src=${BACKPACK_MOBILE_IMG} />
                <div class="b2m-weight">10kg.</div>
                <div class="b2m-tooltip">${backpackTooltipTemplate()}</div>
            </div>
            <div class="b2m-bag-name">${i18next.t("Mochila o artículo personal")}</div>
            <div class="b2-per-booking-next-price">${i18next.t("Incluido con tu tarifa")}</div>
        </div>
    `;

    return { htmlTemplate };
};
