import { html, useEffect, useRef, useState } from "haunted";
import { ApiPortalSpaViewModel } from "../../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import i18next from "i18next";
import { ref } from "../../../directives/ref";
import { ROUTES } from "../../../shared/apiRoutes";
import { getAntiforgerySegment, getRequestBodyFromNamedInputs, handleCugLoader } from "../../../shared/common";
import { commonValidationRules } from "../../../shared/commonValidationRules";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { useForm } from "../../../shared/customHooks/useForm/useForm";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { passwordConfirm } from "../../../shared/customHooks/useForm/custom-attributes/passwordConfirm";
import { passwordFormat } from "../../../shared/customHooks/useForm/custom-attributes/passwordFormat";
import { InputFieldAttribute } from "../../../shared/customHooks/useForm/InputFieldAttribute";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import classNames from "classnames";

export interface Props {
    model: ApiPortalSpaViewModel;
    antiForgeryToken: string;
}

export const useEditPasswordForm = (props: Props) => {
    // HELPERS

    const customValidationRules = (): InputFieldAttribute[] => [passwordFormat(), passwordConfirm()];

    // COMPONENT

    const passwordFormElement = useRef<HTMLFormElement>(undefined);
    const passwordInputElement = useRef<HTMLInputElement>(undefined);
    const passwordConfirmInputElement = useRef<HTMLInputElement>(undefined);

    const [userContext] = useReduxState("userContext");

    const { ajaxRequest } = useAjax();

    const [editPasswordMode, setEditPasswordMode] = useState<boolean>(true);
    const [showPasswordSaveError, setShowPasswordSaveError] = useState<boolean>(false);

    const form = useForm({
        customAttributes: [...commonValidationRules(), ...customValidationRules()],
        noScroll: true,
    });

    useEffect(() => form.init(passwordFormElement.current), [passwordFormElement.current]);

    // EVENT LISTENERS

    const handlePasswordSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setShowPasswordSaveError(false);

        const isValid = await form.validate();

        if (isValid) {
            handleCugLoader(passwordFormElement.current, "editPassword");

            await ajaxRequest({
                body: getRequestBodyFromNamedInputs(passwordFormElement.current),
                form: passwordFormElement.current,
                onResponseCode: {
                    204: () => setEditPasswordMode(false),
                    400: () => setShowPasswordSaveError(true),
                },
            });

            handleCugLoader(passwordFormElement.current, "editPassword");
        }
    };

    // TEMPLATES

    const saveMessageTemplate = () =>
        !editPasswordMode
            ? html`
                  <div class="cug2b-save-message">
                      <i class="js-icon-cug js-cug-tick"></i>
                      ${i18next.t("¡Los cambios fueron guardados exitosamente!")}
                  </div>
              `
            : "";

    const passwordEditorTemplate = () => {
        const onClick = !editPasswordMode ? () => setEditPasswordMode(true) : null;

        return props.model.EditOrgOrProfileViewModel.CanEditPassword &&
            userContext.peruCompra.role === "none" &&
            userContext.chileCompra.role === "none"
            ? html`
                  <div
                      class=${classNames("cug2b-profile-edit", {
                          "cursor-default": editPasswordMode,
                          "cursor-pointer": !editPasswordMode,
                      })}
                      @click=${onClick}
                  >
                      <i class="js-icon-cug js-cug-edit"></i>
                      <span>${i18next.t("Editar")}</span>
                  </div>
              `
            : "";
    };

    const newPasswordTemplate = () => html`
        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
            <label class="mdl-textfield__label">${i18next.t("Contraseña nueva")}</label>
            <input
                ref=${ref(passwordInputElement)}
                type="password"
                class=${classNames("mdl-textfield__input js-input", {
                    disabled: !editPasswordMode || !props.model.EditOrgOrProfileViewModel.CanEditPassword,
                })}
                autocomplete="off"
                name="NewPassword"
                data-required
                data-test-id=${T.CUG2_CHANGE_PASSWORD.NEW_PASSWORD_INPUT_FIELD}
                password-format
            />
            <div class="cug-info-icon cug-register">
                <ac-tooltip
                    .icon=${"?"}
                    .tooltip=${i18next.t(
                        "La contraseña debe contener entre 8 y 16 caracteres e incluir letras y números.",
                    )}
                ></ac-tooltip>
            </div>
        </div>
    `;

    const confirmPasswordTemplate = () => html`
        <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
            <label class="mdl-textfield__label">${i18next.t("Confirmación de nueva contraseña")}</label>
            <input
                ref=${ref(passwordConfirmInputElement)}
                type="password"
                class=${classNames("mdl-textfield__input js-input", {
                    disabled: !editPasswordMode || !props.model.EditOrgOrProfileViewModel.CanEditPassword,
                })}
                autocomplete="off"
                data-required
                data-test-id=${T.CUG2_CHANGE_PASSWORD.CONFIRM_PASSWORD_INPUT_FIELD}
                password-confirm
            />
        </div>
    `;

    const submitButtonTemplate = () => html`
        <div class="mt-8 flex w-full justify-end">
            <button
                @click=${handlePasswordSubmit}
                class=${classNames("rounded-primary-btn", {
                    disabled: !editPasswordMode || !props.model.EditOrgOrProfileViewModel.CanEditPassword,
                })}
                data-test-id=${T.CUG2_CHANGE_PASSWORD.SAVE_BUTTON}
            >
                ${i18next.t("Guardar")}
            </button>
        </div>
    `;

    const passwordErrorTemplate = () =>
        showPasswordSaveError
            ? html`
                  <div class="row">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">${i18next.t("Password could not be changed.")}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        <form
            ref=${ref(passwordFormElement)}
            action=${ROUTES.ApiRoutes.Cug2BChangePassword}
            method="post"
            class=${classNames("cug2b-edit-profile-form ts-error-parent", {
                "mt-8 md:mt-16":
                    Boolean(props.model.ProfileViewModel.UserModel) ||
                    Boolean(props.model.ProfileViewModel.CompanyModel),
            })}
            novalidate="novalidate"
        >
            <h1>${i18next.t("Cambiar contraseña")} ${passwordEditorTemplate()}</h1>
            ${saveMessageTemplate()} ${getAntiforgerySegment(props.antiForgeryToken)}
            <div class="row">
                <div class="col-xs-1 col-sm-1-3">${newPasswordTemplate()}</div>
                <div class="col-xs-1 col-sm-1-3">${confirmPasswordTemplate()}</div>
            </div>
            ${passwordErrorTemplate()} ${submitButtonTemplate()}
        </form>
    `;

    return { htmlTemplate };
};
