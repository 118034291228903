import {
    ACTION_NAMES,
    BOLIVIAN_CULTURE_CODE,
    COSTA_RICAN_CULTURE_CODE,
    ECUADORIAN_CULTURE_CODE,
    GUATEMALAN_CULTURE_CODE,
    HONDURAN_CULTURE_CODE,
    MEXICAN_CULTURE_CODE,
    NICARAGUAN_CULTURE_CODE,
    PANAMANIAN_CULTURE_CODE,
    PARAGUAYAN_CULTURE_CODE,
    SALVADORAN_CULTURE_CODE,
    URUGUAYAN_CULTURE_CODE,
    VENEZUELAN_CULTURE_CODE,
    CHILEAN_CULTURE_CODE,
    ARGENTINIAN_CULTURE_CODE,
    PERUVIAN_CULTURE_CODE,
    COLOMBIAN_CULTURE_CODE,
    BRASILIAN_CULTURE_CODE,
    USA_CULTURE_CODE,
    INSURANCE_REMOVAL_NEEDED_ACTIONS,
} from "../../shared/commonConstants";
import { useEffect, useMemo, useState } from "../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import { html, useRef } from "haunted";
import { showLoader } from "../../shared/common";
import { CLASS_NAMES } from "../../shared/classNames";
import DomCrawlingHelper from "../../shared/DomCrawlingHelper";
import { ref } from "../../directives/ref";
import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { classMap } from "lit-html/directives/class-map";
import { useAppContext } from "../../managers/useAppContext";
import { useCug2AppContext } from "../../managers/useCug2AppContext";
import {
    getSelectableCulturesInOrder,
    isCultureEnabled,
    getCultureListItemByCode,
} from "../../component-helpers/CultureHelper";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useAjax } from "../../shared/customHooks/useAjax/useAjax";
import { ROUTES } from "../../shared/apiRoutes";

export const name = "ac-culture-selector";

export interface CultureListItem {
    culture: string;
    name: string;
}

export const Component: HauntedFunc<{}> = () => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();
    const cug2AppContext = useCug2AppContext();

    const { triggers } = usePubSub();
    const { ajaxRequest } = useAjax();

    const [userContext] = useReduxState("userContext");
    const [currency] = useReduxState("booking.currency");

    // Helpers

    const init = () => {
        document.addEventListener("click", onClickOutside, true);
        document.addEventListener("scroll", () => {
            setIsOpen(false);
        });
    };

    const isBancoEstadoMember = () =>
        (userContext && userContext.bancoEstado.category !== 0) || userContext.bancoEstado.category !== 0;

    const isChileCompra = () => userContext.chileCompra.role !== "none" || bookingContext.isChileCompraBooking;

    const isPeruCompra = () => userContext.peruCompra.role !== "none" || bookingContext.isPeruCompraBooking;

    const isDisabled = () =>
        isBancoEstadoMember() || isPeruCompra() || isChileCompra() || flowContext.isGiftcardPurchaseFlow;

    const onClickOutside = (e: any) => {
        if (!root.current.contains(e.target)) {
            setIsOpen(false);
        }
    };

    const isNewSearchNeeded = (newCulture: string) =>
        flowContext.action?.toLowerCase() === ACTION_NAMES.FLIGHT.toLowerCase() ||
        appContext.LocalCurrencyDisplaySettings?.CultureSpecificDisplaySettings?.some(
            (csds) =>
                csds.CultureCode?.toLowerCase() === newCulture.toLowerCase() &&
                csds.BlacklistedCurrencies?.includes(currency),
        );

    const isRemoveInsuranceModalNeededInCurrentFlow = () =>
        INSURANCE_REMOVAL_NEEDED_ACTIONS.includes(flowContext.action);

    // Event handlers

    const openCultures = () => {
        if (isDisabled()) return;

        setIsOpen(!isOpen);

        const mobileMenuOpeners = DomCrawlingHelper.getArrayOfClass(
            document.body,
            CLASS_NAMES.mobileNavigation,
        ) as HTMLInputElement[];

        for (const opener of mobileMenuOpeners) {
            if (opener.checked) {
                opener.click();
            }
        }
    };

    const selectCulture = async (cultureCode: string) => {
        showLoader({});

        await ajaxRequest({
            url: ROUTES.ApiRoutes.ChangeCulture,
            method: "POST",
            body: { cultureCode },
        });
        await ajaxRequest({ url: ROUTES.ClearInsuranceSearchCache });
        if (isNewSearchNeeded(cultureCode)) {
            cultureCode !== ARGENTINIAN_CULTURE_CODE && flowContext.action === ACTION_NAMES.FLIGHT
                ? triggers.flight.cultureChanged.publish(cultureCode)
                : (window.location.href = "/");
        } else {
            window.location.reload();
        }
    };

    const handleSelect = async (cultureCode: string) => {
        setIsOpen(false);

        if (isRemoveInsuranceModalNeededInCurrentFlow()) {
            triggers.shared.cultureChangedInBooking.publish({
                cultureCode,
                callback: () => selectCulture(cultureCode),
            });
            return;
        }

        await selectCulture(cultureCode);
    };

    // Component

    const root = useRef<HTMLDivElement>(undefined);
    const listElem = useRef<HTMLDivElement>(undefined);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(init, []);

    const currentCulture = useMemo(() => appContext.Culture || cug2AppContext.Culture, []);

    const cultureList: { culture: string; name: string }[] = useMemo(() => {
        const settings = appContext.CultureSettings || cug2AppContext.CultureSettings;
        const list = [
            { culture: ARGENTINIAN_CULTURE_CODE, name: i18next.t("V2-Argentina") },
            { culture: BOLIVIAN_CULTURE_CODE, name: i18next.t("Bolivia") },
            { culture: BRASILIAN_CULTURE_CODE, name: i18next.t("V2-Brasil") },
            { culture: CHILEAN_CULTURE_CODE, name: i18next.t("V2-Chile") },
            { culture: COLOMBIAN_CULTURE_CODE, name: i18next.t("V2-Colombia") },
            { culture: COSTA_RICAN_CULTURE_CODE, name: i18next.t("Costa Rica") },
            { culture: ECUADORIAN_CULTURE_CODE, name: i18next.t("Ecuador") },
            { culture: GUATEMALAN_CULTURE_CODE, name: i18next.t("Guatemala") },
            { culture: HONDURAN_CULTURE_CODE, name: i18next.t("Honduras") },
            { culture: MEXICAN_CULTURE_CODE, name: i18next.t("México") },
            { culture: NICARAGUAN_CULTURE_CODE, name: i18next.t("Nicaragua") },
            { culture: PANAMANIAN_CULTURE_CODE, name: i18next.t("Panamá") },
            { culture: PARAGUAYAN_CULTURE_CODE, name: i18next.t("V2-Paraguay") },
            { culture: PERUVIAN_CULTURE_CODE, name: i18next.t("V2-Peru") },
            { culture: SALVADORAN_CULTURE_CODE, name: i18next.t("El Salvador") },
            { culture: URUGUAYAN_CULTURE_CODE, name: i18next.t("V2-Uruguay") },
            { culture: USA_CULTURE_CODE, name: i18next.t("V2-Usa") },
            { culture: VENEZUELAN_CULTURE_CODE, name: i18next.t("Venezuela") },
        ];

        return list.filter((listItem) => isCultureEnabled(settings, listItem.culture));
    }, []);

    const cultures = useMemo(
        () =>
            getSelectableCulturesInOrder(
                appContext.CultureSettings || cug2AppContext.CultureSettings,
                cultureList,
                appContext.Culture || cug2AppContext.Culture,
            ),
        [cultureList, currentCulture],
    );

    // Templates

    const cultureTemplate = (culture: CultureListItem) => {
        const tempClassMap = classMap({
            flag: true,
            [`flag-${culture.culture.substring(3)}`]: true,
        });

        return html`
            <img class=${tempClassMap} />
            <span> ${culture.name}</span>
        `;
    };

    const openCultureTemplate = () => {
        const selectedCulture = getCultureListItemByCode(cultureList, currentCulture);
        return cultureTemplate(selectedCulture);
    };

    const cultureOptionTemplate = (culture: CultureListItem) => html`
        <li
            @click=${() => handleSelect(culture.culture)}
            data-test-id=${getTestId(T.COMMON.CULTURE_SELECTOR, {
                c: culture.culture,
            })}
        >
            ${cultureTemplate(culture)}
        </li>
    `;

    const cultureSelectorOpenerClassMap = classMap({
        "menu-button": true,
        "with-flag": true,
        "no-arrow": isDisabled(),
        "opened": isOpen,
        "disabled": isDisabled(),
    });

    return html`
        <div
            class="culture-selector"
            data-test-id=${T.COMMON.CULTURE_DROPDOWN}
            data-test-value=${currentCulture}
            ref=${ref(root)}
        >
            <a class=${cultureSelectorOpenerClassMap} @click=${openCultures}>${openCultureTemplate()}</a>
            <div
                ref=${ref(listElem)}
                class="cultures"
                style="max-height: ${isOpen ? `${listElem.current.scrollHeight}px` : "0"};"
            >
                <ul>
                    ${cultures.map(cultureOptionTemplate)}
                </ul>
            </div>
        </div>
    `;
};
