import i18next from "i18next";
import { html } from "lit-html";
import { useModal } from "../shared/useModal";

export const useAmericanBookingCancellationModal = () => {
    const handleRedirectButtonClick = () => (window.location.href = "/");

    const headerTemplate = () => html`
        <div class="flex h-full w-full flex-col items-center justify-center">
            <i class="js-new js-n-triangle-exclamation-thick"></i>
            <span>${i18next.t("Atención")}!</span>
        </div>
    `;

    const htmlTemplate = () => html`
        <div class="modal-body text-center leading-normal">
            <div class="text-medium">
                ${i18next.t(
                    "If you don't want to use your miles, you will return to the beginning of the purchasing process",
                )}.
            </div>
            <div class="mt-8 flex w-full flex-col-reverse items-center justify-around sm:flex-row">
                <a class="rounded-secondary-btn" @click=${handleRedirectButtonClick}>
                    ${i18next.t("Return to flight selection")}
                </a>
                <a class="rounded-primary-btn mb-4 sm:mb-0" @click=${() => modal.close()}>
                    ${i18next.t("Continue with miles")}
                </a>
            </div>
        </div>
    `;

    const modal = useModal({
        closing: { onClose: () => modal.hide(), isClosable: true },
        content: { classNames: "booking-modal-content american-modal-content", template: htmlTemplate },
        header: { template: headerTemplate },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};
