import BookingData from "../shared/BookingData";
import { commonDebug } from "../bootstrap";
import { ROUTES } from "../shared/apiRoutes";
import { useAjax } from "../shared/customHooks/useAjax/useAjax";
import { AjaxRequestParams } from "../shared/customHooks/useAjax/AjaxRequestParams";
import { AjaxResponse } from "../shared/customHooks/useAjax/AjaxResponse";

const bookingDataDaemon = {
    bookingData: undefined as BookingData,
    callbacks: new Array<(bookingData: BookingData) => void>(),
    timer: undefined as number,

    addCallback: (
        ajaxJsonRequest: <T>(data: AjaxRequestParams) => Promise<AjaxResponse<T>>,
        culture: string,
        callback: (bookingData: BookingData) => void,
    ) => {
        if (bookingDataDaemon.timer !== undefined) window.clearTimeout(bookingDataDaemon.timer);

        bookingDataDaemon.callbacks.push(callback);
        bookingDataDaemon.timer = window.setTimeout(
            () => bookingDataDaemon.fetchAndUpdate(ajaxJsonRequest, culture),
            1000,
        );
    },

    fetchAndUpdate: async (
        ajaxJsonRequest: <T>(data: AjaxRequestParams) => Promise<AjaxResponse<T>>,
        query?: string,
    ): Promise<void> => {
        bookingDataDaemon.bookingData = await bookingDataDaemon.fetchFromServer(ajaxJsonRequest, query);
        window.bookingData = bookingDataDaemon.bookingData;
        sessionStorage.setItem(sessionKey, JSON.stringify(bookingDataDaemon.bookingData));
        bookingDataDaemon.runCallbacks();
    },

    runCallbacks: () => {
        bookingDataDaemon.callbacks.forEach((cb) => cb(bookingDataDaemon.bookingData));
        bookingDataDaemon.callbacks = [];
    },

    set: (bookingData: BookingData) => {
        bookingDataDaemon.bookingData = bookingData;
        window.bookingData = bookingDataDaemon.bookingData;
        sessionStorage.setItem(sessionKey, JSON.stringify(bookingDataDaemon.bookingData));
        bookingDataDaemon.runCallbacks();
    },

    fetchFromServer: async (
        ajaxJsonRequest: <T>(data: AjaxRequestParams) => Promise<AjaxResponse<T>>,
        query?: string,
    ) => {
        try {
            const result = await ajaxJsonRequest<{ BookingSummary: BookingData }>({
                method: "GET",
                url: query ? `${ROUTES.GetBookingData}?${query}` : ROUTES.GetBookingData,
            });

            if (result?.statusCode === 200 || result?.statusCode === 204) {
                return result.data.BookingSummary;
            }
        } catch (e) {
            commonDebug.error(e);
        }

        return undefined;
    },
};

const sessionKey = "bookingData";

export const useBookingDataManager = () => {
    const getBookingDataFromSessionStorage = (): BookingData => {
        const bookingDataString = sessionStorage.getItem(sessionKey);
        return bookingDataString && isJson(bookingDataString) ? JSON.parse(bookingDataString) : undefined;
    };

    const handleBookingDataCallback = (culture: string, callback: (bookingData: BookingData) => void) => {
        const existingBookingData = getBookingDataFromSessionStorage();
        if (existingBookingData) {
            callback(existingBookingData);
        } else {
            bookingDataDaemon.addCallback(ajaxJsonRequest, culture, callback);
        }
    };

    const { ajaxJsonRequest, isJson } = useAjax();

    return {
        eraseBookingData: () => bookingDataDaemon.set(undefined),
        fetchAndUpdate: bookingDataDaemon.fetchAndUpdate,
        handleBookingDataCallback,
        setBookingData: (bookingData: BookingData) => bookingDataDaemon.set(bookingData),
    };
};
