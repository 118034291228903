import { useEffect, useMemo } from "../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { OUTBOUND } from "../../../shared/commonConstants";
import { BaggagePageState } from "../../../component-models/baggage/BaggagePageState";
import { html } from "lit-html";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { ApiBaggageViewModel } from "../../../component-models/baggage/ApiBaggageViewModel";
import { usePerBookingBundle } from "./perBookingView/usePerBookingBundle";
import { perBookingViewSwitch } from "./perBookingView/perBookingViewSwitch";
import { usePerJourneyPerPaxTable } from "./perJourneyPerPaxView/usePerJourneyPerPaxTable";
import { useMobilePerJourneyPerPaxTable } from "./perJourneyPerPaxView/useMobilePerJourneyPerPaxTable";
import { usePerBookingPaidChecked } from "./perBookingView/usePerBookingPaidChecked";
import { usePerBookingFreeChecked } from "./perBookingView/usePerBookingFreeChecked";
import { baggageIllustrations } from "./common/baggageIllustrations";
import { StaffBaggage } from "./common/useStaffBaggage";
import { perBookingValidationError } from "./perBookingView/perBookingValidationError";
import { useBookingContext } from "../../../managers/useBookingContext";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { paxAmount, paxLabel } from "../../../component-helpers/BaggageHelper";
import { baggageEditButton } from "./common/baggageEditButton";
import { useMobilePerBookingPaidChecked } from "./perBookingView/useMobilePerBookingPaidChecked";
import { useMobilePerBookingFreeChecked } from "./perBookingView/useMobilePerBookingFreeChecked";
import { useBaggageSection } from "./common/useBaggageSection";
import { BaggageSectionJourney } from "../../../component-models/baggage/BaggageSectionJourney";
import { BancoEstadoBaggage } from "./common/useBancoEstadoBaggage";

export interface Props {
    baggageSectionJourneys: BaggageSectionJourney[];
    bancoEstadoBaggage: BancoEstadoBaggage;
    classToScrollToOnClose: string;
    classToScrollToOnOpen: string;
    model: ApiBaggageViewModel;
    pageState: BaggagePageState;
    staffBaggage: StaffBaggage;
    setBaggageModel: (model: ApiBaggageViewModel) => void;
    setPageState: (updater: (newState: BaggagePageState) => BaggagePageState) => void;
}

export const useCheckedBags = (props: Props) => {
    const bookingContext = useBookingContext();

    const [userContext] = useReduxState("userContext");

    const checkedBag = useBaggageSection({
        baggageSectionJourneys: props.baggageSectionJourneys,
        bagType: "checkedBaggage",
        bancoEstadoBaggage: props.bancoEstadoBaggage,
        classToScrollToOnClose: props.classToScrollToOnClose,
        classToScrollToOnOpen: props.classToScrollToOnOpen,
        model: props.model,
        staffBaggage: props.staffBaggage,
        setModel: props.setBaggageModel,
        setPageState: props.setPageState,
    });

    const validationError = perBookingValidationError({
        baggageSection: checkedBag,
        bagType: "checkedBaggage",
        pageState: props.pageState,
    });

    const { perBookingDcTemplate, tooManyPaxForDcTemplate } = baggageIllustrations();

    const perBookingBundle = usePerBookingBundle({
        baggageSectionJourneys: props.baggageSectionJourneys,
        baggageState: checkedBag.sectionUi.uiState,
        bagType: "checkedBaggage",
    });

    const viewSwitch = perBookingViewSwitch({
        baggageSection: checkedBag,
        bagType: "checkedBaggage",
    });

    const perJourneyPerPaxTable = usePerJourneyPerPaxTable({
        baggageSection: checkedBag,
        bagType: "checkedBaggage",
        bancoEstadoBaggage: props.bancoEstadoBaggage,
        classToScrollToOnOpen: props.classToScrollToOnOpen,
        pageState: props.pageState,
        staffBaggage: props.staffBaggage,
    });

    const mobilePerJourneyPerPaxTable = useMobilePerJourneyPerPaxTable({
        baggageSection: checkedBag,
        bagType: "checkedBaggage",
        bancoEstadoBaggage: props.bancoEstadoBaggage,
        pageState: props.pageState,
        staffBaggage: props.staffBaggage,
    });

    const perBookingFreeOption = usePerBookingFreeChecked({
        baggageSection: checkedBag,
        pageState: props.pageState,
    });

    const perBookingPaidOption = usePerBookingPaidChecked({
        baggageSection: checkedBag,
        bancoEstadoBaggage: props.bancoEstadoBaggage,
        pageState: props.pageState,
        staffBaggage: props.staffBaggage,
    });

    const mobilePerBookingFreeOption = useMobilePerBookingFreeChecked({
        baggageSection: checkedBag,
    });

    const mobilePerBookingPaidOption = useMobilePerBookingPaidChecked({
        baggageSection: checkedBag,
        bancoEstadoBaggage: props.bancoEstadoBaggage,
        staffBaggage: props.staffBaggage,
    });

    const isViewSwitchDisabled = useMemo(
        () => checkedBag.sectionUi.uiState.journeys.length === 1 && paxAmount(checkedBag.sectionUi.uiState) === 1,
        [checkedBag.sectionUi.uiState],
    );

    const editButton = baggageEditButton({
        dataTestId: getTestId(T.BAGGAGE.OPEN_PER_JOURNEY_PER_PAX_VIEW_BUTTON, {
            c: "checkedBaggage",
            m: true,
        }),
        isDisabled: isViewSwitchDisabled,
        onClick: checkedBag.sectionUi.openPerJourneyPerPaxView,
    });

    const isBundleBought = useMemo(
        () => props.baggageSectionJourneys.some((journey) => journey.bundleType !== "None"),
        [props.baggageSectionJourneys],
    );

    const updateValidation = () => {
        if (!props.pageState.lastValidationTimestamp) return;
        checkedBag.updateCompulsoryValidation();
    };

    useEffect(updateValidation, [props.pageState.lastValidationTimestamp]);

    const headerTemplate = () => {
        const titleText = isBundleBought
            ? i18next.t("¿Deseas más equipaje de bodega?")
            : i18next.t("Selecciona tu equipaje de bodega");

        const subtitleText = isBundleBought
            ? i18next.t("Recuerda que puedes agregar hasta 5 piezas de equipaje facturado por pasajero, por tramo.")
            : i18next.t("Elige si quieres llevar equipaje facturado, este tiene un peso máximo de 23 kg.");

        return html`
            <header
                class="b2-section-header push-down ${props.classToScrollToOnClose}"
                data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION_HEADER, { c: "checkedBaggage" })}
            >
                <div class="flex items-center">
                    <span
                        class="b2-section-number"
                        data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION_NUMBER, { c: "checkedBaggage" })}
                    >
                        2
                    </span>
                    <div>
                        <h3>${titleText}</h3>
                        <h4>${subtitleText}</h4>
                    </div>
                </div>
                <div class="flex flex-col items-start">
                    ${bookingContext.isDiscountBooking ? perBookingDcTemplate("checkedBaggage") : ""}
                    ${perBookingBundle.htmlTemplate()}
                </div>
            </header>
        `;
    };

    const mobilePerBookingViewSwitchTemplate = () => {
        const infoText = (() => paxAmount(checkedBag.sectionUi.uiState) === 1)()
            ? i18next.t("Estás agregando para todos los tramos")
            : i18next.t("Estás agregando para todos los pasajeros y tramos");

        return checkedBag.sectionUi.uiState.perJourneyPerPaxState !== "open"
            ? html`
                  <div class="b2m-view">
                      <div class="b2m-info-amount">
                          <div class="b2m-view-info">${infoText}</div>
                          <div class="b2m-pax-amount">
                              ${paxAmount(checkedBag.sectionUi.uiState)}
                              ${paxLabel(paxAmount(checkedBag.sectionUi.uiState))}
                          </div>
                      </div>
                      ${editButton.htmlTemplate()}
                  </div>
              `
            : "";
    };

    const htmlTemplate = () => html`
        <div data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION, { c: "checkedBaggage" })}>
            ${headerTemplate()}
            <div
                class="b2-bag-grid b2-checked-grid hidden-xs"
                data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION_DT, { c: "checkedBaggage" })}
            >
                ${viewSwitch.htmlTemplate()}
                <div class="ac-per-booking-free-option">${perBookingFreeOption.htmlTemplate()}</div>
                <div class="ac-per-booking-paid-option">${perBookingPaidOption.htmlTemplate()}</div>
                <div class="ac-per-journey-per-pax">${perJourneyPerPaxTable.htmlTemplate()}</div>
                ${validationError.htmlTemplate(false)}
            </div>
            <div
                class="b2m-checked-bag-container hidden-sm-up"
                data-test-id=${getTestId(T.BAGGAGE.PAGE_SECTION_MB, { c: "checkedBaggage" })}
            >
                ${mobilePerBookingFreeOption.htmlTemplate()} ${mobilePerBookingPaidOption.htmlTemplate()}
                ${mobilePerBookingViewSwitchTemplate()} ${mobilePerJourneyPerPaxTable.htmlTemplate()}
                ${validationError.htmlTemplate(true)}
            </div>
            ${tooManyPaxForDcTemplate(
                checkedBag.sectionUi.uiState.journeys[OUTBOUND]?.passengers.length || 0,
                "checkedBaggage",
                userContext,
            )}
        </div>
    `;

    return {
        state: checkedBag.sectionUi.uiState,
        htmlTemplate,
        isPerJourneyPerPaxAddAvailable: checkedBag.perJourneyPerPax.isAddAvailable,
        setResetInsuranceOnChange: checkedBag.setResetInsuranceOnChange,
    };
};
