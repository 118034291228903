import dayjs from "dayjs";
import { useMemo, useState } from "../../shared/haunted/CustomHooks";
import { DEFAULT_DATE_FORMAT, INBOUND, OUTBOUND } from "../../shared/commonConstants";
import { getTestId, TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { isMobile, redoSearch, showLoader } from "../../shared/common";
import { FlightCarousel, FlightCarouselDate } from "../../component-models/flight/FlightCarousel";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
import * as IsSameOrAfter from "dayjs/plugin/isSameOrAfter";
import * as IsSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { html, useEffect } from "haunted";
import { useAppContext } from "../../managers/useAppContext";
import { FlightPageContext } from "../../component-models/flight/contexts/FlightPageContext";
import classNames from "classnames";
import { useBookingContext } from "../../managers/useBookingContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import i18next from "i18next";
import { useFlowContext } from "../../managers/useFlowContext";
dayjs.extend(IsSameOrAfter);
dayjs.extend(IsSameOrBefore);
dayjs.extend(CustomParseFormat);

export const displayedCarouselDatesToTheLeft = isMobile() ? 1 : 3;
export const displayedCarouselDatesToTheRight = isMobile() ? 1 : 2;

export interface Props {
    carousel: FlightCarousel;
    context: FlightPageContext;
    inboundDate: dayjs.Dayjs;
    outboundDate: dayjs.Dayjs;
    onClick?: (date: dayjs.Dayjs, journeyIndex: number) => void;
}

export const useFlightDateCarousel = (props: Props) => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const [currency] = useReduxState("booking.currency");

    const [currentDate, setCurrentDate] = useState<dayjs.Dayjs>(
        props.carousel.JourneyIndex === OUTBOUND ? dayjs(props.outboundDate) : dayjs(props.inboundDate),
    );

    const canGoBack = useMemo(() => {
        if (!props.outboundDate) return false;

        const isTodayOrAfter = currentDate.isAfter(dayjs().add(displayedCarouselDatesToTheLeft, "day"), "date");

        if (!isTodayOrAfter) return false;

        if (!props.inboundDate || props.carousel.JourneyIndex === OUTBOUND) return true;

        return currentDate.isAfter(props.outboundDate.add(displayedCarouselDatesToTheLeft, "day"), "day");
    }, [currentDate, props.carousel.JourneyIndex, props.inboundDate, props.outboundDate]);

    const displayedDates = useMemo(() => {
        const currentDateInModel = props.carousel.Dates.find((date) => date.Date.isSame(currentDate, "day"));
        const index = props.carousel.Dates.indexOf(currentDateInModel);
        const trimIndexLeft = index > displayedCarouselDatesToTheLeft ? index - displayedCarouselDatesToTheLeft : 0;
        const trimIndexRight =
            index +
            displayedCarouselDatesToTheRight +
            1 +
            (index < displayedCarouselDatesToTheLeft ? displayedCarouselDatesToTheLeft - index : 0);

        return props.carousel.Dates.slice(trimIndexLeft, trimIndexRight);
    }, [currentDate, props.carousel.Dates]);

    const showWeek = useMemo(() => {
        if (!props.context?.bundleState) return false;

        if (props.context.bundleState.bundlesMode === "Legacy") {
            return props.carousel.JourneyIndex === OUTBOUND
                ? !props.context.flightState.selectedOutboundFlight
                : !props.context.flightState.selectedInboundFlight;
        }

        return props.carousel.JourneyIndex === OUTBOUND
            ? !props.context.flightState.selectedOutboundFlight || !props.context.bundleState.selectedOutboundBundle
            : !props.context.flightState.selectedInboundFlight || !props.context.bundleState.selectedInboundBundle;
    }, [props.carousel.JourneyIndex, props.context.flightState, props.context.bundleState]);

    // HELPERS

    const redoSearchWithNewDate = (newDate: dayjs.Dayjs, journeyIndex: number) => {
        const newOutboundDate =
            journeyIndex === OUTBOUND
                ? newDate.format(DEFAULT_DATE_FORMAT)
                : props.context.model.FlightDataViewModel.UnformattedOutboundDate;
        const newInboundDate =
            journeyIndex === INBOUND
                ? newDate.format(DEFAULT_DATE_FORMAT)
                : props.context.model.FlightDataViewModel.UnformattedInboundDate || "";

        let parsedNewOutboundDate = dayjs(newOutboundDate, DEFAULT_DATE_FORMAT);
        let parsedNewInboundDate = newInboundDate ? dayjs(newInboundDate, DEFAULT_DATE_FORMAT) : undefined;

        if (journeyIndex === OUTBOUND && parsedNewInboundDate && parsedNewOutboundDate?.isAfter(parsedNewInboundDate)) {
            parsedNewInboundDate = parsedNewOutboundDate;
        }

        if (journeyIndex === INBOUND && parsedNewInboundDate && parsedNewOutboundDate?.isAfter(parsedNewInboundDate)) {
            parsedNewOutboundDate = parsedNewInboundDate;
        }

        redoSearch({
            adults: props.context.model.FlightDataViewModel.AdultNumber.toString(),
            children: props.context.model.FlightDataViewModel.ChildNumber.toString(),
            culture: appContext.Culture,
            currency,
            destination: props.context.model.FlightDataViewModel.Destination,
            infants: props.context.model.FlightDataViewModel.InfantNumber.toString(),
            isOneWay: props.context.model.FlightDataViewModel.IsOneWay,
            origin: props.context.model.FlightDataViewModel.Origin,
            promoCode: bookingContext.promoCode,
            unformattedInboundDate: parsedNewInboundDate ? parsedNewInboundDate.format(DEFAULT_DATE_FORMAT) : "",
            unformattedOutboundDate: parsedNewOutboundDate.format(DEFAULT_DATE_FORMAT),
            isRedemption: flowContext.isRedemptionFlow,
        });
    };

    const scrollCarousel = (direction: "back" | "forward") => {
        const newDate = currentDate.add(direction === "back" ? -1 : 1, "day");
        const trimLeftIndex = direction === "back" ? displayedCarouselDatesToTheLeft : 0;
        const trimRightIndex =
            direction === "forward"
                ? props.carousel.Dates.length - displayedCarouselDatesToTheRight
                : props.carousel.Dates.length;
        const datesTrimmedForDisplaying = props.carousel.Dates.slice(trimLeftIndex, trimRightIndex);

        const dayInModel = datesTrimmedForDisplaying.find((fare) => fare.Date.isSame(newDate, "date"));

        if (dayInModel) {
            setCurrentDate(newDate);
            return;
        }

        redoSearchWithNewDate(newDate, props.carousel.JourneyIndex);
    };

    const handleDateClick = (date: FlightCarouselDate) => {

        if (date.Url) {
            showLoader({});
            const url = flowContext.isRedemptionFlow ? `${date.Url}&isRedemptionFlow=true` : date.Url;
            window.location.href = url;
            return;
        }

        if (typeof props.onClick === "function") {
            props.onClick(date.Date, props.carousel.JourneyIndex);
        }
    };

    // COMPONENT

    useEffect(() => {
        const newDate = props.carousel.JourneyIndex === OUTBOUND ? props.outboundDate : props.inboundDate;
        if (!newDate || newDate?.isSame(currentDate, "day")) return;
        setCurrentDate(newDate);
    }, [props.carousel.JourneyIndex, props.inboundDate, props.outboundDate]);

    // TEMPLATES

    const weekdayPriceTemplate = (date: FlightCarouselDate) =>
        flowContext.isRedemptionFlow
            ? html`<span class="font-bold lowercase">${date.Miles} ${i18next.t("Miles")}</span>`
            : html`<span>${date.FormattedPrice}</span>`;

    const weekdayTemplate = (date: FlightCarouselDate, index: number) => {
        const dataTestId = getTestId(
            date.IsSelected ? T.FLIGHT.WEEKLY_SELECTOR_DAY_SELECTED : T.FLIGHT.WEEKLY_SELECTOR_DAY,
            { j: props.carousel.JourneyIndex, i: index },
        );

        return html`
            <li
                class=${classNames({ disabled: date.IsDisabled, selected: date.IsSelected })}
                data-test-id=${dataTestId}
                @click=${() => handleDateClick(date)}
            >
                <span>${date.Date.format("ddd DD-MM").replace(".", "")}</span>
                ${weekdayPriceTemplate(date)}
            </li>
        `;
    };

    const weekDaysTemplate = () => html`
        <ul>
            ${displayedDates.map(weekdayTemplate)}
        </ul>
    `;

    const goBackTemplate = () =>
        canGoBack
            ? html`
                  <div
                      class="scroll-week cursor-pointer"
                      data-test-id=${getTestId(T.FLIGHT.WEEKLY_SELECTOR_LEFT, { j: props.carousel.JourneyIndex })}
                      @click=${() => scrollCarousel("back")}
                  >
                      <i class="js-icon js-circle-chevron-right scroll-left-icon"></i>
                  </div>
              `
            : html` <div class="scroll-week">&nbsp;</div> `;

    const goForwardTemplate = () => html`
        <div
            class="scroll-week cursor-pointer"
            data-test-id=${getTestId(T.FLIGHT.WEEKLY_SELECTOR_RIGHT, { j: props.carousel.JourneyIndex })}
            @click=${() => scrollCarousel("forward")}
        >
            <i class="js-icon js-circle-chevron-right"></i>
        </div>
    `;

    const htmlTemplate = () => html`
        <div
            class=${classNames("week-selector", { open: showWeek })}
            data-test-id=${getTestId(T.FLIGHT.WEEKLY_SELECTOR, { j: props.carousel.JourneyIndex })}
        >
            ${goBackTemplate()} ${weekDaysTemplate()} ${goForwardTemplate()}
        </div>
    `;

    return { htmlTemplate };
};
