import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { useEffect, useMemo, useState } from "../../../shared/haunted/CustomHooks";
import i18next from "i18next";
import { html } from "haunted";
import { getCoords, hideLoader, showLoader } from "../../../shared/common";
import DomCrawlingHelper from "../../../shared/DomCrawlingHelper";
import { raiseBookingFlowContinueEvent } from "../../../shared/eventbus/raiseBookingFlowContinueEvent";
import BookingFlowHandler from "../../../shared/BookingFlowHandler";
import { BookingSteps } from "../../../shared/BookingSteps";
import { LOADER_CLASS_NAMES } from "../../../shared/LoaderClassNames";
import { BaggagePageState } from "../../../component-models/baggage/BaggagePageState";
import { DEFAULT_TIMESTAMP_FORMAT } from "../../../shared/commonConstants";
import { ApiBaggageViewModel } from "../../../component-models/baggage/ApiBaggageViewModel";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { useBookingManager } from "../../../managers/useBookingManager";
import { SpaContainerViewModel } from "../../../component-models/spa/SpaContainerViewModel";
import { useRunOnce } from "../../useRunOnce";
import { YesNo, setTestCircuitDomValue } from "../../../testing-helpers/TestCircuitHelper";
import { useCabinBags } from "./useCabinBags";
import { useCheckedBags } from "./useCheckedBags";
import { useOversizedBags } from "./useOversizedBags";
import { useTealiumManager } from "../../../managers/Tealium/useTealiumManager";
import { useStaffBaggage } from "./common/useStaffBaggage";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { GetBuildPageResult, SpaContent } from "../../../component-models/spa/SpaContent";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { ROUTES } from "../../../shared/apiRoutes";
import { usePubSub } from "../../../pub-sub-service/usePubSub";
import { useBookingContext } from "../../../managers/useBookingContext";
import { mapToBaggageContextSectionJourney } from "../../../component-mappers/BaggageMappers";
import classNames from "classnames";
import { useBancoEstadoBaggage } from "./common/useBancoEstadoBaggage";

export const pageClass = "b2-baggage-page";
export const loaderClass = "b2-loader";

export const baggageErrorClass = "b2-error";
export const baggagePerJourneyErrorClass = "b2-error-per-journey";

const classesToScrollToOnOpen = new Map<BagType, string>([
    ["cabinBaggage", "b2-cabin-scroll-to-on-open"],
    ["checkedBaggage", "b2-checked-scroll-to-on-open"],
    ["oversizedBaggage", "b2-oversized-scroll-to-on-open"],
]);

const classesToScrollToOnClose = new Map<BagType, string>([
    ["cabinBaggage", "b2-cabin-scroll-to-on-close"],
    ["checkedBaggage", "b2-checked-scroll-to-on-close"],
    ["oversizedBaggage", "b2-oversized-scroll-to-on-close"],
]);

export interface BaggagePage extends SpaContent {
    setBaggageModel: (model: ApiBaggageViewModel) => void;
}

export interface Props {
    model: SpaContainerViewModel;
    onForward: () => void;
    spaModalStart: (submitCallback: () => Promise<void>) => void;
}

export type BagType = "cabinBaggage" | "checkedBaggage" | "oversizedBaggage";

export const useBaggagePage = (props: Props): BaggagePage => {
    const bookingContext = useBookingContext();
    const bookingManager = useBookingManager();
    const runOnce = useRunOnce();

    const { ajaxJsonRequest } = useAjax();
    const { triggers } = usePubSub();

    const [userContext] = useReduxState("userContext");
    const [total] = useReduxState("booking.total");
    const [currentSpaSection] = useReduxState("spa.activeSection");
    const [isSpaLoading] = useReduxState("spa.isLoading");
    const [isSidebarLoaded] = useReduxState("isSidebarLoaded");

    const [baggageModel, setBaggageModel] = useState<ApiBaggageViewModel>(props.model.BaggageModel);
    const [pageState, setPageState] = useState<BaggagePageState>({
        isLoading: false,
        lastValidationTimestamp: undefined,
    });

    const isActive = useMemo(() => currentSpaSection === "Baggage", [currentSpaSection]);

    const cabinBaggageJourneys = useMemo(
        () =>
            baggageModel.Journeys.map((journey) =>
                mapToBaggageContextSectionJourney({
                    bagType: "cabinBaggage",
                    inboundBundleUrl: props.model.SpaGlobalDataModel.InboundBundleImgUrl,
                    journey,
                    model: baggageModel,
                    outboundBundleUrl: props.model.SpaGlobalDataModel.OutboundBundleImgUrl,
                }),
            ),
        [
            baggageModel,
            props.model.SpaGlobalDataModel.OutboundBundleImgUrl,
            props.model.SpaGlobalDataModel.InboundBundleImgUrl,
        ],
    );

    const checkedBaggageJourneys = useMemo(
        () =>
            baggageModel.Journeys.map((journey) =>
                mapToBaggageContextSectionJourney({
                    bagType: "checkedBaggage",
                    inboundBundleUrl: props.model.SpaGlobalDataModel.InboundBundleImgUrl,
                    journey,
                    model: baggageModel,
                    outboundBundleUrl: props.model.SpaGlobalDataModel.OutboundBundleImgUrl,
                }),
            ),
        [
            baggageModel,
            props.model.SpaGlobalDataModel.OutboundBundleImgUrl,
            props.model.SpaGlobalDataModel.InboundBundleImgUrl,
        ],
    );

    const oversizedBaggageJourneys = useMemo(
        () =>
            baggageModel.Journeys.map((journey) =>
                mapToBaggageContextSectionJourney({
                    bagType: "oversizedBaggage",
                    inboundBundleUrl: props.model.SpaGlobalDataModel.InboundBundleImgUrl,
                    journey,
                    model: baggageModel,
                    outboundBundleUrl: props.model.SpaGlobalDataModel.OutboundBundleImgUrl,
                }),
            ),
        [
            baggageModel,
            props.model.SpaGlobalDataModel.OutboundBundleImgUrl,
            props.model.SpaGlobalDataModel.InboundBundleImgUrl,
        ],
    );

    const staffBaggage = useStaffBaggage({
        cabinBaggageJourneys,
        checkedBaggageJourneys,
    });

    const bancoEstadoBaggage = useBancoEstadoBaggage({
        cabinBaggageJourneys,
        checkedBaggageJourneys,
        bancoEstadoFreeBaggages: props.model.BaggageModel.BancoEstadoFreeBaggages,
    });

    const cabinBags = useCabinBags({
        baggageSectionJourneys: cabinBaggageJourneys,
        bancoEstadoBaggage,
        classToScrollToOnClose: classesToScrollToOnClose.get("cabinBaggage"),
        classToScrollToOnOpen: classesToScrollToOnOpen.get("cabinBaggage"),
        model: baggageModel,
        pageState,
        staffBaggage,
        setBaggageModel,
        setPageState,
    });

    const checkedBags = useCheckedBags({
        baggageSectionJourneys: checkedBaggageJourneys,
        bancoEstadoBaggage,
        classToScrollToOnClose: classesToScrollToOnClose.get("checkedBaggage"),
        classToScrollToOnOpen: classesToScrollToOnOpen.get("checkedBaggage"),
        model: baggageModel,
        pageState,
        staffBaggage,
        setBaggageModel,
        setPageState,
    });

    const oversizedBags = useOversizedBags({
        baggageSectionJourneys: oversizedBaggageJourneys,
        bancoEstadoBaggage,
        classToScrollToOnClose: classesToScrollToOnClose.get("oversizedBaggage"),
        classToScrollToOnOpen: classesToScrollToOnOpen.get("oversizedBaggage"),
        model: baggageModel,
        pageState,
        staffBaggage,
        setBaggageModel,
        setPageState,
    });

    const tealiumManager = useTealiumManager();

    const isThereNotEnoughOversizedForAllPaxOneJourney = useMemo(
        () =>
            !baggageModel.Journeys.every(
                (journey) =>
                    journey.AvailableOversizedBaggageQuantity >=
                    bookingContext.adultsCount + bookingContext.childrenCount,
            ),
        [bookingContext, baggageModel],
    );

    const isPageValid = useMemo(
        () =>
            !oversizedBags.showOversizedErrors &&
            cabinBaggageJourneys.every((journey) =>
                journey.passengers.every((passenger) => {
                    const statePassenger = cabinBags.state.journeys[journey.index]?.passengers[passenger.index];
                    return (
                        statePassenger?.hasSelected ||
                        passenger.isSoldOut ||
                        !cabinBags.isPerJourneyPerPaxAddAvailable({ bookingContext, journey, passenger })
                    );
                }),
            ) &&
            checkedBaggageJourneys.every((journey) =>
                journey.passengers.every((passenger) => {
                    const statePassenger = checkedBags.state.journeys[journey.index]?.passengers[passenger.index];
                    return (
                        statePassenger?.hasSelected ||
                        passenger.isSoldOut ||
                        !checkedBags.isPerJourneyPerPaxAddAvailable({ bookingContext, journey, passenger })
                    );
                }),
            ),
        [
            bookingContext,
            cabinBaggageJourneys,
            cabinBags.state,
            checkedBaggageJourneys,
            checkedBags.state,
            oversizedBags.showOversizedErrors,
        ],
    );

    const init = () => BookingFlowHandler.storeCurrentStep(BookingSteps.Baggage);

    const isButtonDisabled = () => !isActive || pageState.isLoading;

    const getFirstError = () => {
        const errors = Array.from(
            DomCrawlingHelper.getElemByClass(document.body, pageClass).querySelectorAll(`.${baggageErrorClass}`),
        ) as HTMLDivElement[];

        return errors.find((e) => e.offsetHeight > 0);
    };

    const scrollToFirstError = () => {
        // DEVNOTE Settimeout needed for errors to render
        window.setTimeout(() => {
            const firstError = getFirstError();

            if (!firstError) return;

            const topOfElement = getCoords(firstError).top - 150;
            window.scroll({ top: topOfElement, behavior: "smooth" });
        }, 100);
    };

    const handleSubmitClick = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setPageState((currentState) => ({
            ...currentState,
            lastValidationTimestamp: dayjs().format(DEFAULT_TIMESTAMP_FORMAT),
        }));

        if (isPageValid) {
            if (typeof props.spaModalStart === "function") {
                props.spaModalStart(handleSubmit);
            } else {
                await handleSubmit();
            }
        } else {
            // DEVNOTE Settimeout is needed for error message to be displayed
            window.setTimeout(scrollToFirstError, 0);

            raiseBookingFlowContinueEvent(false);
        }
    };

    const [loader, setLoader] = useState<JsLoader>(null);

    useEffect(() => {
        if (pageState.isLoading) {
            setLoader(showLoader({ container: document.body, name: loaderClass }));
        } else {
            if (loader) hideLoader(loader);
            setLoader(null);
        }
    }, [pageState.isLoading]);

    const handleSubmit = async () => {
        const body = { stePassed: "true" };
        await bookingManager.postBaggageStepPassed(body, document.body, undefined);

        setPageState((currentState) => ({ ...currentState, isLoading: true }));
        raiseBookingFlowContinueEvent(true);
        checkedBags.setResetInsuranceOnChange(true);
        oversizedBags.setResetInsuranceOnChange(true);
        await tealiumManager.logContinueClicked();
        props.onForward();
        setPageState((currentState) => ({ ...currentState, isLoading: false, lastValidationTimestamp: undefined }));
    };

    useEffect(
        () => setPageState((currentState) => ({ ...currentState, lastValidationTimestamp: undefined })),
        [baggageModel],
    );

    const logDomLoad = async () => {
        await tealiumManager.logDomLoaded("baggage", {
            UpdateCommonUdo: true,
            UpdateRealUdo: true,
        });
    };

    useEffect(() => {
        if (isActive && isSidebarLoaded && total && !isSpaLoading) runOnce.run(() => logDomLoad());
        if (!isActive) runOnce.reset();
    }, [isActive, total, isSidebarLoaded, isSpaLoading]);

    useEffect(init, []);

    const headerTemplate = () => html`
        <header class="b2-header">
            <span class="js-circle-baggage js-icon title-icon" data-test-id=${T.BAGGAGE.MAIN_ICON}></span>
            <div class="title">
                <h2 class="main-title" data-test-id=${T.BAGGAGE.HEADER_MAIN_TITLE}>${i18next.t("Equipaje")}</h2>
                <div class="subtitle" data-test-id=${T.BAGGAGE.HEADER_SUBTITLE}>
                    ${i18next.t("Si compras online, te saldrá más barato que en el aeropuerto.")}
                </div>
            </div>
        </header>
    `;

    const staffBenefitMessageTemplate = () =>
        userContext.isStaff
            ? html`
                  <div class="b2-staff-benefit-message">
                      ${i18next.t(
                          "Recuerda que el beneficio es que pagas por el primer equipaje $1 (cabina o bodega) para todos los pasajeros de tu reserva y tramos, pero el segundo equipaje tiene costo.",
                      )}
                  </div>
              `
            : "";

    const oversizedBagsTemplate = () => {
        setTestCircuitDomValue<YesNo>(
            "PerJourneyPerPaxOpened",
            isThereNotEnoughOversizedForAllPaxOneJourney ? "YES" : "NO",
        );

        return html` <div class="ac-select-oversized-bag">${oversizedBags.htmlTemplate()}</div> `;
    };

    const buttonTemplate = () => html`
        <div class="flex w-full justify-end">
            <button
                class=${classNames("rounded-primary-btn booking", { disabled: isButtonDisabled() })}
                data-test-id=${T.BAGGAGE.SUBMIT_BUTTON}
                @click=${handleSubmitClick}
            >
                ${i18next.t("Continuar")}
            </button>
        </div>
    `;

    // EXPORTS

    const build = async (): Promise<GetBuildPageResult> => {
        try {
            const response = await ajaxJsonRequest<ApiBaggageViewModel>({
                method: "GET",
                noCors: true,
                nonCancellable: true,
                url: ROUTES.AjaxBaggageModel,
            });

            setBaggageModel(response.data);
            window.setTimeout(() => triggers.baggage.reset.publish({}), 100);

            return { type: "success" };
        } catch (e) {
            return { type: "error" };
        }
    };

    const htmlTemplate = () => html`
        <div
            class=${classNames(pageClass, loaderClass, LOADER_CLASS_NAMES.CommonLoaderWrapper)}
            data-test-id=${T.BAGGAGE.MAIN_CONTAINER}
        >
            ${headerTemplate()} ${staffBenefitMessageTemplate()} ${cabinBags.htmlTemplate()}
            ${checkedBags.htmlTemplate()} ${oversizedBagsTemplate()}
        </div>
        ${buttonTemplate()}
    `;
    return { build, htmlTemplate, setBaggageModel };
};
