import { CLASS_NAMES } from "../../shared/classNames";
import { ACTION_NAMES, CHILEAN_PESO_CODE, LoginType, NEW_SEARCH_ON_LOGIN_ACTIONS } from "../../shared/commonConstants";
import LoginResult, { ExtendedLoginResult } from "../../component-models/LoginResult";
import { ROUTES } from "../../shared/apiRoutes";
import { redoSearch, RedoSearchData, showLoader } from "../../shared/common";
import i18next from "i18next";
import BookingData from "../../shared/BookingData";
import { useAuthenticationTealiumManager } from "../../managers/Tealium/useAuthenticationTealiumManager";
import { useCugTealiumManager } from "../../managers/Tealium/useCugTealiumManager";
import { useBookingDataManager } from "../../managers/useBookingDataManager";
import { useAppContext } from "../../managers/useAppContext";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useDcInconsistencyModal } from "../discount-club-modals/useDcInconsistencyModal";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { ANTI_FORGERY_TOKEN_PROPERTY_NAME, useAjax } from "../../shared/customHooks/useAjax/useAjax";

export const useLogin = () => {
    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const authenticationTealiumManager = useAuthenticationTealiumManager();
    const bookingDataManager = useBookingDataManager();
    const cugTealiumManager = useCugTealiumManager();

    const { triggers } = usePubSub();
    const { checkPromoCodeAvailable } = useAjax();

    const [antiForgeryToken] = useReduxState("antiForgeryToken");
    const [currency] = useReduxState("booking.currency");

    const dcInconsistencyModal = useDcInconsistencyModal();

    const defaultLoginResult: ExtendedLoginResult = {
        BancoEstado: {
            Category: 0,
            RemainingFreeSeats: 0,
        },
        Cug: {
            IsAdminOrSupervisor: false,
            IsAgent: false,
            IsMember: false,
            OrgCode: "",
            OrgCurrency: "",
            OrgName: "",
        },
        DC: {
            DcLevel: "",
            GroupPriceFormatted: "",
            StandardPriceFormatted: "",
            UpgradePriceFormatted: "",
            HasGroupMembership: false,
            HasStandardMembership: false,
            IsMember: false,
            MembershipDaysRemaining: 0,
            ProgramNumber: "",
            ShowWarning15: false,
            ShowWarning30: false,
        },
        PeruCompra: {
            IsAdmin: false,
            IsMember: false,
            AvailableAmount: 0,
        },
        ErrorMessage: "",
        FirstName: "",
        IsFarelockDisabled: false,
        IsLoggedIn: false,
        IsStaffMember: false,
        IsUserAuthorizedToOpenBooking: true,
        LastName: "",
        LoginStatus: "notLoggedIn",
        UserName: "",
        UserProgram: "",
        UserRole: "",
    };

    interface LoginData {
        Lgi?: string;
        LoginType: LoginType;
        Password: string;
        Redirect: boolean;
        Username: string;
    }

    const handleRedirection = async (data: LoginData, response: Response): Promise<ExtendedLoginResult> => {
        if (data.LoginType === "AGENCY" || data.LoginType === "COMPANY") {
            await cugTealiumManager.logPortalLogin(data.Username, data.LoginType);
            window.setTimeout(() => (window.location.href = response.url), 1000);
        } else {
            window.location.href = response.url;
        }
        return {
            ...defaultLoginResult,
            LoginStatus: "redirected",
        };
    };

    const handleUnauthorized = (): ExtendedLoginResult => {
        window.location.href = "/";
        return {
            ...defaultLoginResult,
            LoginStatus: "redirected",
        };
    };

    const fetchLogin = async (loginData: FormData): Promise<Response> => {
        const fetchOptions: RequestInit = {
            method: "POST",
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                [ANTI_FORGERY_TOKEN_PROPERTY_NAME]: antiForgeryToken,
            },
            credentials: "same-origin",
            mode: "cors",
            body: loginData,
        };

        const fetchParameters = {
            options: fetchOptions,
            url: ROUTES.PageLogin,
        };

        return fetch(fetchParameters.url, fetchParameters.options);
    };

    const handleError = (result: LoginResult): ExtendedLoginResult => {
        let errorMessage = result.ErrorMessage;

        const errorMessageMap = new Map<string, string>([
            [
                "NoResponse",
                i18next.t(
                    "En estos momentos no es posible cargar tus descuentos/beneficiarios, por favor intenta más tarde.",
                ),
            ],
            [
                "ServerSideError",
                i18next.t("En estos momentos no es posible cargar tus beneficiarios, por favor intenta más tarde."),
            ],
            ["UserNotFound", i18next.t("Usuario no encontrado, escribenos a conectatesmart@jetsmart.com")],
            [
                "NoDiscountFound",
                i18next.t("En estos momentos no es posible cargar tus descuentos, por favor intenta más tarde."),
            ],
            [
                "NoFamilyMembers",
                i18next.t(
                    "Aún no tienes beneficiarios inscritos. Recuerda que para que tus beneficiarios puedan hacer uso de los descuentos, debes inscribirlos escribiendo a conectatesmart@jetsmart.com. Una vez esté validada la información podrás verlos en la sección de beneficiarios. A partir de ahí, podrán hacer uso de los descuentos.",
                ),
            ],
        ]);

        const transformedMessageKey = Array.from(errorMessageMap.keys()).find(
            (key) => result.ErrorMessage?.toLowerCase().trim().indexOf(key.toLowerCase()) > -1,
        );

        if (transformedMessageKey) {
            errorMessage = errorMessageMap.get(transformedMessageKey);
        }

        return { ...defaultLoginResult, LoginStatus: "notLoggedIn", ErrorMessage: errorMessage };
    };

    const handlePageReload = (): ExtendedLoginResult => {
        showLoader({});
        window.location.reload();

        return {
            ...defaultLoginResult,
            LoginStatus: "loggedIn",
        };
    };

    const handleBookingReset = (): ExtendedLoginResult => {
        showLoader({});
        window.location.href = ROUTES.BookingReset;

        return {
            ...defaultLoginResult,
            LoginStatus: "loggedIn",
        };
    };

    const isBancoEstadoLoginOnFlightPage = (result: LoginResult) =>
        result?.BancoEstado.Category > 0 && flowContext.action.toLocaleLowerCase() === ACTION_NAMES.FLIGHT;

    const handleRedoSearch = (jsonContent: LoginResult): ExtendedLoginResult => {
        bookingDataManager.handleBookingDataCallback(appContext.Culture, async (bookingData: BookingData) => {
            redoSearch(await getRedoSearchData(jsonContent, bookingData));
        });

        return {
            ...defaultLoginResult,
            LoginStatus: "loggedIn",
        };
    };

    const login = async (data: LoginData): Promise<ExtendedLoginResult> => {
        try {
            const loginData = new FormData();
            loginData.append("username", data.Username);
            loginData.append("password", data.Password);
            loginData.append("loginType", data.LoginType);
            loginData.append("redirect", data.Redirect.toString());

            if (data.Lgi) {
                loginData.append("lgi", data.Lgi);
            }

            const result = await fetchLogin(loginData);

            // DEVNOTE This happens when the user has to change password and gets redirected to
            // the password change page. Must be the first to be checked.
            // This also happens with PeruCompra login.
            if (result.redirected) {
                return handleRedirection(data, result);
            }

            const responseTextContent = await result.text();
            const loginResult = JSON.parse(responseTextContent) as LoginResult;

            if (!loginResult.IsUserAuthorizedToOpenBooking) {
                return handleUnauthorized();
            }

            if (!loginResult.IsLoggedIn) {
                return handleError(loginResult);
            }

            // DEVNOTE See JET-7227
            removeBackendErrorMessages();

            if (bookingContext.promoCode) {
                await checkPromoCodeAvailable("");
            }

            await authenticationTealiumManager.logUserLoginRegister();

            if (isGiftcardSelectPage()) {
                // DEVNOTE This is to update the prices
                return handlePageReload();
            }

            if (shouldGoBackToItineraryForFarelock() || shouldGoBackToItineraryForPostBooking()) {
                return handleBookingReset();
            }

            if (shouldRedoSearch() || isBancoEstadoLoginOnFlightPage(loginResult)) {
                return handleRedoSearch(loginResult);
            }

            if (shouldReloadPage()) {
                return handlePageReload();
            }

            return handleRegularLogin(loginResult);
        } catch (e) {
            return defaultLoginResult;
        }
    };

    const shouldForwardStandaloneDcFlowToPayment = (result: LoginResult) =>
        result && !result.DC.HasGroupMembership && !result.DC.HasStandardMembership && flowContext.isDcStandaloneFlow;

    const shouldBlockStandaloneDcFlow = (result: LoginResult) =>
        result && (result.DC.HasGroupMembership || result.DC.HasStandardMembership) && flowContext.isDcStandaloneFlow;

    const shouldRedoSearch = () =>
        (flowContext.isBookingFlow || flowContext.isFarelockRoundOne) &&
        NEW_SEARCH_ON_LOGIN_ACTIONS.some((action) => action.toLowerCase() === flowContext.action);

    // DEVNOTE See JET-6512
    const shouldGoBackToItineraryForFarelock = () =>
        flowContext.isFarelockRoundTwo &&
        NEW_SEARCH_ON_LOGIN_ACTIONS.some((action) => action.toLowerCase() === flowContext.action);

    // DEVNOTE See JET-7097
    const shouldGoBackToItineraryForPostBooking = () =>
        !flowContext.isFarelockRoundTwo && flowContext.isPostBookingFlow;

    const shouldReloadPage = () => flowContext.isPostBookingFlow && flowContext.action !== ACTION_NAMES.ITINERARY;

    const isGiftcardSelectPage = () => flowContext.action === ACTION_NAMES.GIFTCARD_SELECT;

    // DEVNOTE See JET-7227
    const removeBackendErrorMessages = () => {
        const backendErrors = Array.from(document.body.querySelectorAll(`.${CLASS_NAMES.BackendErrorsContanier}`));
        backendErrors.forEach((err) => err.remove());
    };

    const handleRegularLogin = (jsonContent: LoginResult): ExtendedLoginResult => {
        if (jsonContent?.Cug.IsMember && jsonContent?.Cug.OrgCurrency) {
            triggers.login.setFixedCurrency.publish(jsonContent.Cug.OrgCurrency);
        }

        if (jsonContent?.BancoEstado.Category > 0) {
            triggers.login.setFixedCurrency.publish(CHILEAN_PESO_CODE);
        }

        if (shouldBlockStandaloneDcFlow(jsonContent)) {
            dcInconsistencyModal.open();
        }

        if (shouldForwardStandaloneDcFlowToPayment(jsonContent)) {
            // DEVNOTE Yes, really, this will forward. JET-6464
            showLoader({});
            window.location.reload();
        }

        return { ...jsonContent, LoginStatus: "loggedIn" };
    };

    const getRedoSearchData = (jsonContent: LoginResult, bookingData: BookingData): RedoSearchData => ({
        adults: bookingData?.PassengersAdultCount.toString() || bookingContext.adultsCount.toString(),
        children: bookingData?.PassengersChildCount.toString() || bookingContext.childrenCount.toString(),
        culture: appContext.Culture,
        currency: jsonContent?.BancoEstado.Category > 0 ? CHILEAN_PESO_CODE : currency,
        destination: bookingData?.OutboundJourney?.ArrivalStationCode || bookingContext.destinationStationCode,
        infants: bookingData?.PassengersInfantCount.toString() || bookingContext.infantsCount.toString(),
        isOneWay: bookingData?.ReturnJourney !== null ? !bookingData.ReturnJourney : bookingContext.isOneWay,
        origin: bookingData?.OutboundJourney?.DepartureStationCode || bookingContext.originStationCode,
        promoCode: undefined,
        unformattedInboundDate: bookingData?.ReturnJourney?.DepartureDate || bookingContext.unformattedInboundDate,
        unformattedOutboundDate: bookingData?.OutboundJourney?.DepartureDate || bookingContext.unformattedOutboundDate,
    });

    return { login, htmlTemplate: dcInconsistencyModal.htmlTemplate };
};
