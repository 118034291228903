import { useAppContext } from "../managers/useAppContext";
import { useCug2AppContext } from "../managers/useCug2AppContext";
import {
    ARGENTINIAN_PESO_CODE,
    BRASIL_REAL_CODE,
    BRASIL_REAL_CURRENCY_SIGN,
    BRITISH_POUND_CODE,
    CANADIAN_DOLLAR_CODE,
    EURO_CODE,
    GENERAL_CURRENCY_SIGN,
    PERUVIAN_CULTURE_CODE,
    PERUVIAN_SOL_CODE,
    PERUVIAN_SOL_CURRENCY_SIGN,
    USA_CULTURE_CODE,
    USA_DOLLAR_CODE,
} from "./commonConstants";
import { useReduxState } from "./redux/useReduxState";

export const CULTURES_WITH_DECIMAL_DOT = [USA_CULTURE_CODE, PERUVIAN_CULTURE_CODE];
export const CURRENCIES_WITH_DECIMALS = [
    ARGENTINIAN_PESO_CODE,
    BRASIL_REAL_CODE,
    BRITISH_POUND_CODE,
    CANADIAN_DOLLAR_CODE,
    EURO_CODE,
    PERUVIAN_SOL_CODE,
    USA_DOLLAR_CODE,
];

export const useNumberFormatter = () => {
    const appContext = useAppContext();
    const cug2AppContext = useCug2AppContext();

    const [currency] = useReduxState("booking.currency");

    const formatNumber = (data: {
        amount: number;
        currency?: string;
        leadingSign?: boolean;
        omitGrouping?: boolean;
        omitSpace?: boolean;
    }) => {
        if (!currency && !data.currency) return data.amount.toString();

        const currencyToUse = data.currency || currency;
        const cultureToUse = appContext?.Culture || cug2AppContext?.Culture;

        data.leadingSign = data.leadingSign === undefined ? true : data.leadingSign;
        const currencySymbol = getCurrencySign(currencyToUse);

        const useDecimals = CURRENCIES_WITH_DECIMALS.indexOf(currencyToUse.toUpperCase()) > -1;

        const useDecimalDot = CULTURES_WITH_DECIMAL_DOT.indexOf(cultureToUse.toLowerCase()) > -1;

        data.amount = useDecimals ? Math.round(data.amount * 100) / 100 : Math.round(data.amount);
        let str = useDecimals ? data.amount.toFixed(2) : data.amount.toString();
        str = useDecimalDot ? str : str.replace(".", ",");

        str = data.omitGrouping
            ? str
            : useDecimalDot
              ? str.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : str.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        return data.leadingSign ? (data.omitSpace ? `${currencySymbol}${str}` : `${currencySymbol} ${str}`) : str;
    };

    const getCurrencySign = (currencyCode?: string): string => {
        if (currencyCode?.toUpperCase() === PERUVIAN_SOL_CODE) return PERUVIAN_SOL_CURRENCY_SIGN;

        return currencyCode?.toUpperCase() === BRASIL_REAL_CODE ? BRASIL_REAL_CURRENCY_SIGN : GENERAL_CURRENCY_SIGN;
    };

    return { formatNumber, getCurrencySign };
};
