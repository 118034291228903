import { html } from "haunted";
import { ApiPortalSpaViewModel } from "../../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import i18next from "i18next";
import { ROUTES } from "../../../shared/apiRoutes";

export interface Props {
    model: ApiPortalSpaViewModel;
}

export const useOrganizationPendingBookingsBox = (props: Props) => {
    // EVENT HANDLERS

    // COMPONENT

    // TEMPLATES
    const reservationsWarningTemplate = () =>
        props.model.SummaryViewModel.Warnings > 0
            ? html`
                  <div class="cug2b-summary-warning">
                      <i class="js-icon js-danger"></i> ${i18next.t("CUG-ReservationWarning {{amount}}", {
                          amount: props.model.SummaryViewModel.Warnings,
                      })}
                  </div>
              `
            : "";

    const htmlTemplate = () => html`
        <div class="cug2b-summary-data" @click=${() => (window.location.href = ROUTES.Cug2BReservationsPage)}>
            <h2>${i18next.t("Reservas Pendientes")}</h2>
            <ac-tooltip
                .icon=${"?"}
                .tooltip=${i18next.t("CUG-TT-PendingReservationsTip")}
                onclick="event.stopPropagation();"
            ></ac-tooltip>
            <span class="cug2b-summary-data-number">${props.model.SummaryViewModel.PendingReservationsCount}</span>
            ${reservationsWarningTemplate()}
        </div>
    `;

    return { htmlTemplate };
};
