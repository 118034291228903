import i18next from "i18next";
import { html } from "haunted";
import { useModal } from "../../shared/useModal";

export const usePendingPaymentModal = () => {
    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="description-container">
                <span>
                    ${i18next.t(
                        "Por favor, no cierres esta ventana, tu reserva se está finalizando en este momento. En breve te entregaremos tu número de reserva y confirmación.",
                    )}
                </span>
            </div>
        </div>
    `;

    const modal = useModal({
        overlay: { classNames: "primary-modal" },
        content: { classNames: "booking-modal-content", template: htmlTemplate },
        header: { classNames: "text-center", template: () => i18next.t("Importante") },
    });

    return modal;
};
