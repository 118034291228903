import i18next from "i18next";
import { InputElement } from "../useForm";
import { InputFieldAttribute } from "./../InputFieldAttribute";
import { frequentFlyerNumberValidator } from "../../../../validator/frequentFlyerNumberValidator";
import { html } from "lit-html";

export const frequentFlyerNumber = (): InputFieldAttribute => ({
    name: "frequent-flyer",
    validators: [
        {
            errorMessage: {
                id: "frequent-flyer",
                message: html`
                    <span>
                        ${i18next.t("El número AAdvantage ingresado no es válido. {{-reg}}", {
                            reg: '<span class="relative top-[-1px] font-body">&reg;</span>',
                        })}
                    </span>
                `,
                scope: "field",
            },

            validate: (field: InputElement) => {
                const result = !field.value || frequentFlyerNumberValidator().validateFrequentFlyerNumber(field.value);

                return Promise.resolve(result);
            },
        },
    ],
});
