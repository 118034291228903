import { html } from "lit-html";
import {
    ItineraryPax,
    PaxFaresAndFees,
    PaxFareOrFee,
} from "../../../component-models/itinerary/ApiItineraryViewModelV2";
import { getTestId, TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import classNames from "classnames";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import i18next from "i18next";
import { useFlowContext } from "../../../managers/useFlowContext";

export interface Props {
    isMobile: boolean;
}

export const useItineraryDetailsPaxFaresAndFees = (props: Props) => {
    const flowContext = useFlowContext();

    // TEMPLATES

    const paxFareAndFeeGroupTemplate = (fareGroup: PaxFaresAndFees, paxNumber: number) => html`
        <div class=${classNames("i2-fare-group", { "miles-fare-group": flowContext.isAaPostBooking })}>
            <span data-test-id=${getTestId(T.ITINERARY.BUNDLE_NAME_PER_PAX, { p: paxNumber, m: props.isMobile })}>
                ${fareGroup.GroupScreenName}
            </span>
            <span
                data-test-id=${getTestId(T.ITINERARY.BUNDLE_PRICE_PER_PAX, { p: paxNumber, m: props.isMobile })}
                data-test-value=${fareGroup.GroupPrice.toString()}
            >
                ${fareGroup.GroupFormattedPrice}
            </span>
        </div>
        ${paxFareAndFeeItemsTemplate(fareGroup)}
    `;

    const paxFareAndFeeItemsTemplate = (fareGroup: PaxFaresAndFees) =>
        fareGroup.Items?.length > 1 ? fareGroup.Items.map((item) => paxFareAndFeeItemTemplate(item)) : "";

    // FIXME Should not be based on ScreenName
    const paxFareAndFeeItemTemplate = (item: PaxFareOrFee) => html`
        <div class="i2-fare-item">
            ${item.ScreenName?.includes("AAdvantage")
                ? html`
                      <span>
                          ${unsafeHTML(
                              i18next.t("Millas AAdvantage {{-reg}}", {
                                  reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
                              }),
                          )}
                      </span>
                  `
                : html`<span>${item.ScreenName || item.ChargeCode}</span>`}
            ${item.Note ? html` <span>${item.Note}</span> ` : html` <span>${item.FormattedPrice}</span> `}
        </div>
    `;

    const htmlTemplate = (pax: ItineraryPax) =>
        pax.FaresAndTaxes?.map((fareGroup) => paxFareAndFeeGroupTemplate(fareGroup, pax.PassengerNumber)) || "";

    return { htmlTemplate };
};
