import classNames from "classnames";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import i18next from "i18next";
import { html } from "lit-html";
import { paxAmount, paxLabel } from "../../../../component-helpers/BaggageHelper";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { BaggageSectionJourney } from "../../../../component-models/baggage/BaggageSectionJourney";
import { baggageEditButton } from "../common/baggageEditButton";
import { perBookingQuantityButtons } from "./perBookingQuantityButtons";
import { StaffBaggage } from "../common/useStaffBaggage";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useCallback } from "haunted";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import { useBaggageUtils } from "../common/useBaggageUtils";
import { BancoEstadoBaggage } from "../common/useBancoEstadoBaggage";

export interface Props {
    baggageSectionJourneys: BaggageSectionJourney[];
    bancoEstadoBaggage: BancoEstadoBaggage;
    oversizedBag: BaggageSection;
    staffBaggage: StaffBaggage;
    handlePerJourneyPerPaxViewOpen: () => void;
}

export const usePerBookingOversized = (props: Props) => {
    const bookingContext = useBookingContext();

    const { formattedLowestNextPrice } = useBaggageUtils();

    const editButton = baggageEditButton({
        dataTestId: getTestId(T.BAGGAGE.OPEN_PER_JOURNEY_PER_PAX_VIEW_BUTTON, { c: "oversizedBaggage" }),
        isDisabled: props.oversizedBag.perBooking.quantity(props.baggageSectionJourneys) === undefined,
        onClick: () => props.handlePerJourneyPerPaxViewOpen(),
    });

    const quantityButtons = perBookingQuantityButtons({
        baggageSection: props.oversizedBag,
        bagType: "oversizedBaggage",
        isCheckinClosedInbound: bookingContext.isCheckinClosedInbound,
        isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
        isMobile: false,
        paxCount: bookingContext.adultsCount + bookingContext.childrenCount,
    });

    const dataTestId = getTestId(T.BAGGAGE.PER_BOOKING_ADD_FIRST_BUTTON, { c: "oversizedBaggage" });

    const isAddAvailable = useMemo(
        () =>
            props.oversizedBag.perBooking.isAddAvailable({
                baggageSectionJourneys: props.baggageSectionJourneys,
                bagType: "oversizedBaggage",
                canGetFreeBancoEstadoBaggage: false,
                isCheckinClosedInbound: bookingContext.isCheckinClosedInbound,
                isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
                paxCount: bookingContext.adultsCount + bookingContext.childrenCount,
            }),
        [props.baggageSectionJourneys, bookingContext],
    );

    const unformattedLowestNextPrice = useCallback(
        (isMinimum: boolean) =>
            props.oversizedBag.perBooking.nextPrice({
                baggageSectionJourneys: props.baggageSectionJourneys,
                bagType: "oversizedBaggage",
                bancoEstadoBaggage: props.bancoEstadoBaggage,
                isMinimum,
                isOriginal: false,
                staffBaggage: props.staffBaggage,
            }),
        [props.baggageSectionJourneys, props.staffBaggage, props.bancoEstadoBaggage],
    );

    const handleAddFirst = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        await props.oversizedBag.add();
    };

    const nextPriceTemplate = (isMobile: boolean) =>
        isAddAvailable
            ? html`
                  <div class="b2-add-another-oversized">
                      ${isMobile ? i18next.t("Agrega otro por") : i18next.t("Agrega otro desde")}
                      <span
                          class="b2-amount"
                          data-test-id=${getTestId(T.BAGGAGE.PER_BOOKING_NEXT_PRICE, { c: "oversizedBaggage" })}
                          data-test-value=${unformattedLowestNextPrice(false)}
                      >
                          ${formattedLowestNextPrice(unformattedLowestNextPrice(false))}
                      </span>
                  </div>
              `
            : "";

    const addFirstButtonTemplate = () =>
        props.oversizedBag.perBooking.quantity(props.baggageSectionJourneys) === 0
            ? html`
                  <button class="b2-primary-button" data-test-id=${dataTestId} @click=${handleAddFirst}>
                      ${i18next.t("Agregar")}
                  </button>
              `
            : "";

    const quantityTemplate = () =>
        props.oversizedBag.perBooking.quantity(props.baggageSectionJourneys) !== 0
            ? html`
                  <div class="flex w-full items-center justify-around">
                      <button disabled class="b2-primary-button ticked" data-test-id=${dataTestId}>
                          ${i18next.t("Agregado")}
                      </button>
                      <div class="b2-oversized-quantity">
                          <div class="ac-per-booking-quantity-buttons">${quantityButtons.htmlTemplate()}</div>
                      </div>
                  </div>
                  ${nextPriceTemplate(false)}
              `
            : "";

    const htmlTemplate = () => {
        const infoText =
            paxAmount(props.oversizedBag.sectionUi.uiState) === 1
                ? i18next.t("Estás agregando para todos los tramos")
                : i18next.t("Estás agregando para todos los pasajeros y tramos");

        const tempClassMap = classNames("b2-openable hidden-xs", {
            "b2-opened": props.oversizedBag.sectionUi.uiState.perJourneyPerPaxState !== "open",
        });

        return html`
            <div class=${tempClassMap}>
                <div class="flex w-full items-center justify-between">
                    <div class="b2-per-pax-view-switch-oversized">
                        <div class="b2-view-info">${infoText}</div>
                        <div class="b2-pax-amount">
                            ${paxAmount(props.oversizedBag.sectionUi.uiState)}
                            ${paxLabel(paxAmount(props.oversizedBag.sectionUi.uiState))}
                        </div>
                        ${editButton.htmlTemplate()}
                    </div>
                    <div class="flex flex-col items-center justify-center">
                        ${addFirstButtonTemplate()} ${quantityTemplate()}
                    </div>
                </div>
            </div>
        `;
    };

    return { htmlTemplate };
};
