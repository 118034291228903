import { SelectedDcMembershipType } from "../dc-banner/useDiscountClubBanner";
import { FlightPageFarelockState } from "../../../component-models/flight/contexts/FlightPageFarelockState";
import { FlightPageViewModel } from "../../../component-models/flight/FlightPageViewModel";
import { clone } from "../../../shared/common";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import { FlightOptionModel } from "../../../component-models/flight/FlightOptionModel";
import { FarelockContext } from "../../../component-models/flight/contexts/FarelockContext";
import DomCrawlingHelper from "../../../shared/DomCrawlingHelper";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import { defaultScrollTimeOffsetA } from "../flight-page";
import {
    mobileFarelockSelectorYOffsetLarge,
    mobileFarelockSelectorYOffsetSmall,
    FarelockType,
} from "../farelock/useFarelock";
import { useFlightPageAjaxHandler } from "../useFlightPageAjaxHandler";
import { useReduxState } from "../../../shared/redux/useReduxState";

const farelockDurationSelectorClassName = "duration-selector-elem";
const desktopFarelockSelectorYOffset = -100;

export interface FarelockContextProps {
    container: HTMLFormElement;
    model: FlightPageViewModel;
    onChange: () => void;
}

export const useFarelockContext = (props: FarelockContextProps): FarelockContext => {
    const [total] = useReduxState("booking.total");

    const { addFarelockToBooking, removeFarelockFromBooking } = useFlightPageAjaxHandler();

    const getInitialState = (): FlightPageFarelockState => ({
        selectorState:
            props.model.FarelockViewModel.SelectedFareLock && props.model.FarelockViewModel.SelectedFareLock !== 0
                ? "Closed"
                : "Initial",
        minimumPriceFor24: props.model.FarelockViewModel.MinimumPriceForFareLock24 || 0,
        minimumPriceFor48: props.model.FarelockViewModel.MinimumPriceForFareLock48 || 0,
        selectedType:
            props.model.FarelockViewModel.SelectedFareLock && props.model.FarelockViewModel.SelectedFareLock !== 0
                ? props.model.FarelockViewModel.SelectedFareLock === 24
                    ? "Duration24"
                    : "Duration48"
                : "None",
        showError: false,
    });

    const [state, setState] = useState<FlightPageFarelockState>(getInitialState());

    // HELPERS

    const scrollToFarelock = (mobileYOffset: number) => {
        const isMobileResolution = ScrollHelper.getWindowWidth() < 768;

        const element = DomCrawlingHelper.getElemByClass(props.container, farelockDurationSelectorClassName);

        ScrollHelper.scrollToElementAndHideNav({
            element,
            scrollToBottom: true,
            yOffset: isMobileResolution ? mobileYOffset : desktopFarelockSelectorYOffset,
            timeOffset: defaultScrollTimeOffsetA,
        });
    };

    const removeFarelockForMinimumPrice = () => {
        if (Number.isNaN(total) || Number(total) === 0) return false;

        return (
            (state.selectedType === "Duration24" &&
                state?.minimumPriceFor24 &&
                Number(total) < state.minimumPriceFor24) ||
            (state.selectedType === "Duration48" && state?.minimumPriceFor48 && Number(total) < state.minimumPriceFor48)
        );
    };

    const removeFarelockForDc = (dcPreventFarelock: boolean) =>
        (state.selectedType === "Duration24" || state.selectedType === "Duration48") && dcPreventFarelock;

    const removeFarelockForTimeLimit = (selectedOutboundFlight: FlightOptionModel) => {
        if (state.selectedType !== "Duration24" && state.selectedType !== "Duration48") {
            return false;
        }

        if (!selectedOutboundFlight) {
            return true;
        }

        if (
            state.selectedType === "Duration24" &&
            selectedOutboundFlight &&
            !selectedOutboundFlight.FlightSelectModel.Is24hFareLockEnabled
        ) {
            return true;
        }

        if (
            state.selectedType === "Duration24" &&
            selectedOutboundFlight &&
            !selectedOutboundFlight.FlightSelectModel.Is48hFareLockEnabled
        ) {
            return true;
        }

        return false;
    };

    const shouldRemoveFarelock = (data: {
        selectedDcMembershipType: SelectedDcMembershipType;
        selectedOutboundFlight: FlightOptionModel;
        dcPreventFarelock: boolean;
    }) => {
        return (
            (state?.selectedType === "Duration24" || state?.selectedType === "Duration48") &&
            (removeFarelockForMinimumPrice() ||
                removeFarelockForDc(data.dcPreventFarelock) ||
                removeFarelockForTimeLimit(data.selectedOutboundFlight))
        );
    };

    // EXPORTS

    const openFarelockSelector = () => {
        const newState = clone(state);

        newState.selectedType = "Unselected";
        newState.selectorState = "Open";

        setState(newState);

        scrollToFarelock(mobileFarelockSelectorYOffsetLarge);
    };

    const submitFarelockType = () => {
        const newState = clone(state);

        newState.showError = false;

        if (newState.selectedType === "Duration24" || newState.selectedType === "Duration48") {
            newState.selectorState = "Closed";
        } else {
            newState.showError = true;
        }

        setState(newState);

        scrollToFarelock(mobileFarelockSelectorYOffsetSmall);
    };

    const selectFarelockType = async (type: FarelockType) => {
        const newState = clone(state);

        newState.showError = false;
        newState.selectedType = type;

        setState(newState);

        await addFarelockToBooking(type, props.model, props.container);
    };

    const reset = () => {
        const newState = clone(state);
        newState.showError = false;
        newState.selectorState = "Initial";
        newState.selectedType = "Unselected";
        setState(newState);
    };

    const removeFarelock = async () => {
        if (state.selectedType === "None") return;

        const newState = clone(state);

        const shouldRemoveFarelockFromBE = newState.selectedType !== "Unselected";

        newState.showError = false;
        newState.selectorState = "Initial";
        newState.selectedType = "Unselected";

        setState(newState);

        if (shouldRemoveFarelockFromBE) {
            await removeFarelockFromBooking(props.container);
        }

        scrollToFarelock(mobileFarelockSelectorYOffsetLarge);
    };

    const removeFarelockIfPriceDrops = async (
        selectedDcMembershipType: SelectedDcMembershipType,
        selectedOutboundFlight: FlightOptionModel,
        dcPreventFarelock: boolean,
    ) => {
        if (shouldRemoveFarelock({ selectedDcMembershipType, selectedOutboundFlight, dcPreventFarelock })) {
            await removeFarelock();
        }
    };

    useEffect(props.onChange, [state?.selectedType]);

    return {
        state,
        openFarelockSelector,
        removeFarelock,
        removeFarelockIfPriceDrops,
        reset,
        selectFarelockType,
        submitFarelockType,
    };
};
