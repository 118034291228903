import { BannerDTO } from "../../../component-models/flight/BannerDTO";
import { DcContext } from "../../../component-models/flight/contexts/DcContext";
import { DcState } from "../../../component-models/flight/contexts/DcState";
import { FlightPageViewModel } from "../../../component-models/flight/FlightPageViewModel";
import { clone } from "../../../shared/common";
import { useEffect, useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { BannerType, SelectedDcMembershipType, TermsAcceptance } from "../dc-banner/useDiscountClubBanner";
import { useDcBannerContext } from "./useDcBannerContext";
import { SelectedFlightFeeType } from "../flight-page";
import { MAX_PAX_IN_STANDARD_DC_MEMBERSHIP, MAX_PAX_IN_GROUP_DC_MEMBERSHIP } from "../../../shared/commonConstants";
import { useFlightHttpContextHandler } from "../useFlightHttpContextHandler";
import { UserContext } from "../../../component-models/app/UserContext";
import { useReduxState } from "../../../shared/redux/useReduxState";

export interface DcContextProps {
    model: FlightPageViewModel;
    onBannerChange: () => void;
    onChange: () => void;
    setIsLoading: (isLoading: boolean) => void;
}

export const useDcContext = (props: DcContextProps): DcContext => {
    const bannerContext = useDcBannerContext({
        model: props.model,
        onChange: props.onBannerChange,
        setIsLoading: props.setIsLoading,
    });

    const [userContext] = useReduxState("userContext");

    const [state, setState] = useState<DcState>(undefined);

    const { getPreselectedFlightFeeType, getPreselectedMembership } = useFlightHttpContextHandler({
        model: props.model,
    });

    const tooManyPax = useMemo(
        () =>
            (userContext?.dc.hasStandardMembership &&
                props.model.FlightDataViewModel.NumberOfPax > MAX_PAX_IN_STANDARD_DC_MEMBERSHIP &&
                state?.selectedMembershipType !== "Upgrade") ||
            props.model.FlightDataViewModel.NumberOfPax > MAX_PAX_IN_GROUP_DC_MEMBERSHIP,
        [userContext, props.model, state?.selectedMembershipType],
    );

    const init = () => {
        const newState: DcState = {
            applyDiscount: undefined,
            hasMembershipOnBooking: false,
            isTooManyPaxModalAlreadyShown: false,
            selectedMembershipType: undefined,
        };

        const selectedFlightFeeType = getPreselectedFlightFeeType();

        newState.selectedMembershipType = getPreselectedMembership();
        newState.hasMembershipOnBooking = hasMembershipOnBooking();
        newState.applyDiscount = selectedFlightFeeType === "Club" || Boolean(newState.selectedMembershipType);

        setState(newState);
    };

    // HELPERS

    const hasMembershipOnBooking = () => {
        if (
            (props.model.DiscountClubViewModel.IsBuyingGroupMembership && props.model.HasBooking) ||
            (props.model.DiscountClubViewModel.IsBuyingStandardMembership && props.model.HasBooking) ||
            (props.model.DiscountClubViewModel.IsUpgradingMembership && props.model.HasBooking) ||
            (props.model.DiscountClubViewModel.IsExtendingMembership && props.model.HasBooking)
        ) {
            return true;
        }

        return false;
    };

    const shouldApplyDiscountValueAfterLogin = (
        newUserContext: UserContext,
        selectedFlightFeeType: SelectedFlightFeeType,
    ) =>
        !newUserContext.isStaff &&
        !newUserContext.cug.isMember &&
        (newUserContext.dc.hasMembership ||
            newUserContext.bancoEstado.category === 6 ||
            selectedFlightFeeType === "Club");

    // EXPORTS

    const dcPreventFareLock = () =>
        state?.selectedMembershipType !== undefined ||
        props.model.DiscountClubViewModel.IsBuyingGroupMembership ||
        props.model.DiscountClubViewModel.IsBuyingStandardMembership ||
        props.model.DiscountClubViewModel.IsUpgradingMembership;

    const shouldUpgrade = (selectedBannerOption: SelectedDcMembershipType) =>
        (["Extend", "Upgrade"] as SelectedDcMembershipType[]).includes(selectedBannerOption) ||
        ((["Standard", "Group"] as SelectedDcMembershipType[]).includes(selectedBannerOption) &&
            userContext.isLoggedIn);

    const shouldDowngrade = (selectedBannerOption: SelectedDcMembershipType) =>
        selectedBannerOption === "None" &&
        (["GetMembershipForMember", "GetMembershipForWA", "UpgradeMembership"] as BannerType[]).includes(
            bannerContext.state.bannerVM.bannerType,
        );

    const handleBancoEstadoDcUpgrade = async () => {
        bannerContext.handleBancoEstadoDcUpgrade();

        const newState = clone(state);
        newState.selectedMembershipType = "Upgrade";
        newState.applyDiscount = true;
        setState(newState);
    };

    const selectFlight = async (data: BannerDTO) => {
        bannerContext.updateBanner(data, userContext);

        const newState = clone(state);
        newState.applyDiscount = data.selectedFlightFeeType === "Club";
        setState(newState);
    };

    const handleRegister = async (membershipType: SelectedDcMembershipType) => {
        bannerContext.handleRegister(membershipType);

        const newState = clone(state);
        newState.selectedMembershipType = membershipType;
        newState.applyDiscount = true;
        setState(newState);
    };

    const selectDcMembershipOption = async (data: {
        acceptance: TermsAcceptance;
        selectedBannerOption: SelectedDcMembershipType;
    }) => {
        bannerContext.selectDcMembershipOption(data);

        const newState = clone(state);

        if (shouldUpgrade(data.selectedBannerOption)) {
            newState.applyDiscount = true;
        }

        if (shouldDowngrade(data.selectedBannerOption)) {
            newState.applyDiscount = props.model.DiscountClubViewModel.IsDcEligible || userContext?.dc.hasMembership;
        }

        switch (data.selectedBannerOption) {
            case "Extend":
                newState.selectedMembershipType = userContext.dc.hasStandardMembership ? "Standard" : "Group";
                newState.hasMembershipOnBooking = false;
                break;
            case "Upgrade":
                newState.selectedMembershipType = "Upgrade";
                break;
            case "Standard":
            case "Group":
                if (userContext?.isLoggedIn) {
                    const changedPreviouslySelectedMembershipType =
                        newState.selectedMembershipType &&
                        data.selectedBannerOption !== newState.selectedMembershipType;

                    if (changedPreviouslySelectedMembershipType) {
                        newState.hasMembershipOnBooking = false;
                    }
                }

                newState.selectedMembershipType = data.selectedBannerOption;

                break;
            case "None":
                newState.selectedMembershipType = undefined;
                newState.hasMembershipOnBooking = false;
                break;
        }

        setState(newState);
    };

    const closeDcMembershipModal = async (data: BannerDTO) => {
        bannerContext.closeDcMembershipModal(data);

        const newState = clone(state);
        newState.applyDiscount = props.model.DiscountClubViewModel.IsDcEligible || userContext?.dc.hasMembership;
        newState.hasMembershipOnBooking = false;
        newState.selectedMembershipType = undefined;
        setState(newState);
    };

    const showTooManyPaxModal = () => {
        const newState = clone(state);
        newState.isTooManyPaxModalAlreadyShown = true;
        setState(newState);
    };

    const handleLogin = async (data: { bannerDto: BannerDTO; newUserContext: UserContext }) => {
        bannerContext.handleLogin(data);

        const newState = clone(state);
        newState.applyDiscount = shouldApplyDiscountValueAfterLogin(
            data.newUserContext,
            data.bannerDto.selectedFlightFeeType,
        );
        setState(newState);
    };

    useEffect(props.onChange, [state?.applyDiscount, state?.selectedMembershipType]);

    useEffect(props.onBannerChange, [state?.selectedMembershipType]);

    useEffect(() => {
        if (userContext?.userRole) init();
    }, [userContext?.userRole]);

    return {
        bannerState: bannerContext.state,
        showTermsError: bannerContext.showTermsError,
        state,
        tooManyPax,
        changeTermsAcceptance: bannerContext.changeTermsAcceptance,
        closeDcMembershipModal,
        dcPreventFarelock: dcPreventFareLock,
        handleBancoEstadoDcUpgrade,
        handleLogin,
        handleRegister,
        initBanner: bannerContext.initBanner,
        reset: bannerContext.reset,
        selectBundle: bannerContext.selectBundle,
        selectDcMembershipOption,
        selectFlight,
        showTooManyPaxModal,
        shouldDowngrade,
        shouldUpgrade,
        showBanner: bannerContext.showBanner,
        upgradeBundle: bannerContext.upgradeBundle,
    };
};
