import { classMap } from "lit-html/directives/class-map";
import { HauntedFunc } from "../../../../shared/haunted/HooksHelpers";
import { html } from "haunted";
import i18next from "i18next";
import { InsurancePassengerModel } from "../../../../component-models/extras/insurance/InsurancePassengerModel";
import {
    AddBaggageInsuranceEvent,
    RemoveBaggageInsuranceEvent,
    ToggleBaggageInsuranceOpenForPaxEvent,
} from "../../../../component-models/extras/insurance/InsuranceEvents";
import { getPassengerName, getPaxLabel } from "../../../../shared/common";
import { useReduxState } from "../../../../shared/redux/useReduxState";
import { useNumberFormatter } from "../../../../shared/useNumberFormatter";
import { BagType } from "../../baggage/useBaggagePage";

export const name = "ac-insurance-baggage";

export interface Props {
    bagPriceCurrency: string;
    insurancePerBagPrice: number;
    pax: InsurancePassengerModel;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        bagPriceCurrency: host.bagPriceCurrency,
        insurancePerBagPrice: host.insurancePerBagPrice,
        pax: host.pax,
    };

    // HELPERS

    const isAmountChangeEnabled = (bagType: BagType) =>
        bagType === "checkedBaggage" ? props.pax.isCheckedBaggageEnabled : props.pax.isOversizedBaggageEnabled;

    const bagAmount = (bagType: BagType) =>
        bagType === "checkedBaggage" ? props.pax.checkedBaggageWithInsurance : props.pax.oversizedBaggageWithInsurance;

    const isBagMaxed = (bagType: BagType) =>
        bagType === "checkedBaggage"
            ? bagAmount(bagType) === props.pax.maxCheckedBaggage
            : bagAmount(bagType) === props.pax.maxOversizedBaggage;

    // EVENT HANDLERS

    const handleBaggageInsuranceAmountOpenerClick = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        host.dispatchEvent(new ToggleBaggageInsuranceOpenForPaxEvent({ paxIndex: props.pax.displayedIndex }));
    };

    const handleAddBaggage = (e: MouseEvent, bagType: BagType) => {
        e.preventDefault();
        e.stopPropagation();

        host.dispatchEvent(new AddBaggageInsuranceEvent({ paxIndex: props.pax.displayedIndex, bagType }));
    };

    const handleRemoveBaggage = (e: MouseEvent, bagType: BagType) => {
        e.preventDefault();
        e.stopPropagation();

        host.dispatchEvent(new RemoveBaggageInsuranceEvent({ paxIndex: props.pax.displayedIndex, bagType }));
    };

    // COMPONENT

    const { formatNumber } = useNumberFormatter();

    const [passengerNames] = useReduxState("booking.passengerNames");

    // TEMPLATES

    const desktopAmountSelector = () => html`
        <div class="hidden-xs baggage-insurance-amount-row ${props.pax.isBaggageInsuranceOpened ? "open" : ""}">
            <div class="baggage-insurance-amount-description">
                ${i18next.t("Selecciona la cantidad de piezas de equipaje que deseas asegurar")}
            </div>
            ${amountSelectorButtonsTemplate("checkedBaggage")} ${amountSelectorButtonsTemplate("oversizedBaggage")}
            <div class="baggage-insurance-amount-price"></div>
        </div>
    `;

    const mobileAmountSelector = (bagType: BagType) =>
        props.pax.isBaggageInsuranceOpened
            ? html`
                  <div class="visible-xs baggage-insurance-amount-row open">
                      <div class="mobile-baggage-insurance-amount-container">
                          <div class="baggage-insurance-amount-description">
                              ${i18next.t("Selecciona la cantidad de piezas de equipaje que deseas asegurar")}
                          </div>
                          ${amountSelectorButtonsTemplate(bagType)}
                      </div>
                  </div>
              `
            : "";

    const amountSelectorButtonsTemplate = (bagType: BagType) => {
        const title =
            bagType === "checkedBaggage" ? i18next.t("Equipaje facturado") : i18next.t("Equipaje Sobredimensionado");

        const tempClassMap = classMap({
            "baggage-insurance-amount-oversized-bag": bagType === "oversizedBaggage",
            "baggage-insurance-amount-checked-bag": bagType === "checkedBaggage",
        });

        const containerClassMap = classMap({
            "baggage-insurance-amount-button-container": true,
            "disabled": !isAmountChangeEnabled(bagType),
        });

        return html`
            <div class=${tempClassMap}>
                <div class="baggage-insurance-amount-title">${title}</div>
                <div class=${containerClassMap}>
                    ${minusButtonTemplate(bagType)}
                    <span class="font-semibold">${bagAmount(bagType)}</span>
                    ${plusButtonTemplate(bagType)}
                </div>
            </div>
        `;
    };

    const minusButtonTemplate = (bagType: BagType) => html`
        <button
            class="baggage-insurance-amount-button"
            ?disabled=${!isAmountChangeEnabled(bagType) || bagAmount(bagType) === 0}
            @click=${(e: MouseEvent) => handleRemoveBaggage(e, bagType)}
        >
            &minus;
        </button>
    `;

    const plusButtonTemplate = (bagType: BagType) => html`
        <button
            class="baggage-insurance-amount-button"
            ?disabled=${!isAmountChangeEnabled(bagType) || isBagMaxed(bagType)}
            @click=${(e: MouseEvent) => handleAddBaggage(e, bagType)}
        >
            &plus;
        </button>
    `;

    const paxLabelTemplate = () => html`
        <div class="insurance-baggage-insurance-title">
            ${i18next.t("Pasajero")} ${props.pax.displayedIndex + 1} - ${getPaxLabel(props.pax.type)}
        </div>
    `;

    const bagNameTemplate = () => html`
        <div class="insurance-baggage-insurance-name">
            <div class="mb-2">${i18next.t("Nombre y Apellidos")}</div>
            <div class="baggage-insurance-main-text">${getPassengerName(passengerNames, props.pax.displayedIndex)}</div>
        </div>
    `;

    const checkedBaggagePriceTemplate = () => {
        const tempClassMap = classMap({
            "insurance-baggage-insurance-checked-bag": true,
            "gray-bag-cell": !props.pax.isCheckedBaggageEnabled,
        });

        const quantity = props.pax.isBaggageInsuranceOpened
            ? props.pax.checkedBaggageWithInsurance
            : props.pax.maxCheckedBaggage;

        const formattedAmount = formatNumber({
            amount: props.insurancePerBagPrice * quantity,
            currency: props.bagPriceCurrency,
        });

        return html`
            <div class=${tempClassMap}>
                <i class="js-icon-bag js-bag-zipper-baggage"></i>
                <div>
                    <div>${i18next.t("Equipaje facturado")}</div>
                    <div class="baggage-insurance-main-text">${quantity} ${i18next.t("Pieza")}</div>
                    <div class="insurance-bag-cell-price">${formattedAmount}</div>
                </div>
            </div>
        `;
    };

    const oversizedBaggagePriceTemplate = () => {
        const tempClassMap = classMap({
            "insurance-baggage-insurance-oversized-bag": true,
            "gray-bag-cell": !props.pax.isOversizedBaggageEnabled,
        });

        const quantity = props.pax.isBaggageInsuranceOpened
            ? props.pax.oversizedBaggageWithInsurance
            : props.pax.maxOversizedBaggage;

        const formattedAmount = formatNumber({
            amount: props.insurancePerBagPrice * quantity,
            currency: props.bagPriceCurrency,
        });

        return html`
            <div class=${tempClassMap}>
                <i class="js-icon-bag js-bag-guitar-surf-golf"></i>
                <div>
                    <div>${i18next.t("Equipaje Sobredimensionado")}</div>
                    <div class="baggage-insurance-main-text">${quantity} ${i18next.t("Pieza")}</div>
                    <div class="insurance-bag-cell-price">${formattedAmount}</div>
                </div>
            </div>
        `;
    };

    const openerTemplate = () => {
        const tempClassMap = classMap({
            "rounded-primary-btn": true,
            "with-x-logo": props.pax.isBaggageInsuranceOpened,
            "blue-gray": props.pax.isBaggageInsuranceOpened,
            "gray-blue": !props.pax.isBaggageInsuranceOpened,
        });

        return html`
            <div class="insurance-baggage-insurance-price">
                <button class=${tempClassMap} @click=${handleBaggageInsuranceAmountOpenerClick}>
                    ${props.pax.isBaggageInsuranceOpened ? i18next.t("Quitar") : i18next.t("Agregar")}
                </button>
            </div>
        `;
    };

    const mainClassMap = classMap({
        "insurance-baggage-insurance-row": true,
        "inactive": !props.pax.isCheckedBaggageEnabled && !props.pax.isOversizedBaggageEnabled,
    });

    return html`
        ${paxLabelTemplate()}
        <div class=${mainClassMap}>
            ${bagNameTemplate()} ${checkedBaggagePriceTemplate()} ${mobileAmountSelector("checkedBaggage")}
            ${oversizedBaggagePriceTemplate()} ${mobileAmountSelector("oversizedBaggage")} ${openerTemplate()}
        </div>
        ${desktopAmountSelector()}
    `;
};
