import i18next from "i18next";
import { ApiTealiumInfo } from "../../component-models/ApiTealiumInfo";
import { PaymentResult } from "../../component-models/PaymentResult";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { classMap } from "lit-html/directives/class-map";
import { html } from "lit-html";
import { ref } from "../../directives/ref";
import { useRef } from "haunted";
import { useReduxState } from "../../shared/redux/useReduxState";
import { mediaObtainer } from "../shared/mediaObtainer";

export interface Props {
    firstName: string;
    paymentResults: PaymentResult[];
    tealiumProperties: ApiTealiumInfo;
}

export const useItineraryGiftcardSummary = (props: Props) => {
    const [userContext] = useReduxState("userContext");

    const getArea = () => printedArea?.current;

    const printedArea = useRef<HTMLDivElement>(undefined);

    const obtainer = mediaObtainer();

    const paymentResultsTemplate = () =>
        props.paymentResults.map((paymentResult: PaymentResult) => {
            return html`
                <ac-transaction-logger
                    .logWithTealium=${true}
                    .paymentResultData=${paymentResult}
                    .tealiumProperties=${props.tealiumProperties}
                ></ac-transaction-logger>
            `;
        });

    const sectionClassMap = classMap({
        "booking-wrapper": true,
        "transaction-wrapper": true,
        "banco-estado": [1, 2, 3, 7].includes(userContext.bancoEstado.category),
    });

    const downloadTitleTemplate = () =>
        props?.firstName
            ? `${i18next.t("Gift-PaymentSucceededTitle")} ${props.firstName}`
            : i18next.t("Gift-PaymentSucceededTitle");

    const htmlTemplate = () => html`
        <div class="itinerary-giftcard-container" filename="transaccion" area="ts-printed-area">
            <div id="mainContentWrapper">
                <section class=${sectionClassMap}>
                    <header class="transactions-header">
                        <i class="js-payment js-icon title-icon"></i>
                        <div class="title full-width-title">
                            <h2 class="main-title ts-download-title">${downloadTitleTemplate()}</h2>
                        </div>
                    </header>

                    <div ref=${ref(printedArea)} class="ts-printed-area">${paymentResultsTemplate()}</div>
                    <div class="giftcard-transaction-button-container no-print">
                        <a
                            class="rounded-transaction-btn print"
                            data-test-id=${T.ITINERARY.GIFTCARD_PRINT_BUTTON}
                            @click=${() => obtainer?.simplePrint()}
                            >${i18next.t("Print")}</a
                        >
                        <a
                            class="rounded-transaction-btn download"
                            data-test-id=${T.ITINERARY.GIFTCARD_DOWNLOAD_BUTTON}
                            @click=${() => obtainer?.download(getArea, "transaccion")}
                            >${i18next.t("Download")}</a
                        >
                    </div>
                </section>
            </div>
        </div>
    `;

    return { htmlTemplate };
};
