import { html } from "haunted";
import { CompanyInfo } from "../../../component-models/CompanyInfo";
import { useOrganizationInfoFields } from "./useOrganizationInfoFields";

export interface Props {
    model: CompanyInfo;
}

export const useCugOrgInfo = (props: Props) => {
    const orgInfo = useOrganizationInfoFields({
        model: props.model,
    });

    // TEMPLATES

    const htmlTemplate = () => html`
        <div class="cug2b-profile-table">
            <div class="cug2b-profile-line">
                ${orgInfo.countryNameTemplate()} ${orgInfo.businessName()} ${orgInfo.taxNumberTemplate()}
            </div>
            <div class="cug2b-profile-line">${orgInfo.giroTemplate()} ${orgInfo.invoicingEmailTemplate()}</div>
        </div>
    `;

    return { htmlTemplate };
};
