import { html } from "lit-html";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { getAntiForgeryTokenFromHtml, getParsedProperty, isMobile } from "../../shared/common";
import { BRASILIAN_CULTURE_CODE, FORCE_CUG2_LOGIN_URL_PARTIAL, USA_CULTURE_CODE } from "../../shared/commonConstants";
import { useEffect, useState } from "../../shared/haunted/CustomHooks";
import { HauntedFunc } from "../../shared/haunted/HooksHelpers";
import i18next from "i18next";
import { usePendingPaymentBanner } from "../payment/usePendingPaymentBanner";
import { useItineraryPaymentTransactions } from "./useItineraryPaymentTransactions";
import { TestIdDictionary as T } from "../../testing-helpers/TestIdHelper";
import { useBookingDataManager } from "../../managers/useBookingDataManager";
import { useItineraryTealiumManager } from "../../managers/Tealium/useItineraryTealiumManager";
import { useAppContext } from "../../managers/useAppContext";
import { useFlightMoveResultBanner } from "./useFlightMoveResultBanner";
import { useItineraryTabs } from "./useItineraryTabs";
import { useItineraryBanners } from "./useItineraryBanners";
import { useItineraryPageOptionals } from "./useItineraryPageOptionals";
import { ItineraryPageViewModel } from "../../component-models/itinerary/ItineraryPageViewModel";
import { ApiItineraryPageViewModel } from "../../component-models/itinerary/ApiItineraryPageViewModel";
import { mapItineraryPageViewModel } from "../../component-mappers/ItineraryMappers";
import { ScrollHelper } from "../../shared/ScrollHelper";
import { usePubSub } from "../../pub-sub-service/usePubSub";
import { useRefundWidget } from "./useRefundWidget";
import { useBookingContext } from "../../managers/useBookingContext";
import { useFlowContext } from "../../managers/useFlowContext";
import { useReduxState } from "../../shared/redux/useReduxState";
import { useAmericanAirlinesRedirectionModal } from "./itinerary-modals/useAmericanAirlinesRedirectionModal";
import { useCarryOnCheckinModal } from "./itinerary-modals/useCarryOnCheckinModal";
import { useGdsDataModal } from "./itinerary-modals/useGdsDataModal";
import { useInvalidPeruCompraModal } from "./itinerary-modals/useInvalidPeruCompraModal";
import { usePendingPaymentModal } from "./itinerary-modals/usePendingPaymentModal";
import classNames from "classnames";

export const observedAttributes: (keyof Attributes)[] = ["anti-forgery-token", "model"];
export const name = "ac-itinerary-page";

export interface Attributes {
    "anti-forgery-token": string;
    "model": string;
}

export interface Props {
    antiForgeryToken: string;
    model: ItineraryPageViewModel;
}

export const Component: HauntedFunc<Props> = (host) => {
    const props: Props = {
        antiForgeryToken: host.antiForgeryToken,
        model: mapItineraryPageViewModel(getParsedProperty<ApiItineraryPageViewModel>(host.model)),
    };

    const appContext = useAppContext();
    const bookingContext = useBookingContext();
    const flowContext = useFlowContext();

    const bookingDataManager = useBookingDataManager();
    const tealiumManager = useItineraryTealiumManager();

    const [userContext] = useReduxState("userContext");
    const [_, setAntiForgeryToken] = useReduxState("antiForgeryToken");

    const [isPrintingTransaction, setIsPrintingTransaction] = useState<boolean>(false);

    const pendingPaymentBanner = usePendingPaymentBanner({
        isActive: !props.model.ItineraryModelV2?.IsLastPaymentInFinalStatus,
        isDeclined: props.model.ItineraryModelV2?.IsLastPaymentDeclined,
        isCugOnHold: props.model.IsCugOnHold,
        isFlightless: !props.model.ItineraryModelV2.Journeys.length,
        paymentDescription: props.model.ItineraryModelV2?.PaymentDescription,
        retrieveUrl: `/Booking/Retrieve?em=${props.model.ItineraryModelV2?.UserEmail}&rl=${props.model.ItineraryModelV2?.Pnr}`,
    });

    const transactions = useItineraryPaymentTransactions({
        isPrintingTransaction,
        model: props.model,
        filename: "transaccion",
        handleMoreTransactionsButtonClick: () => handleMoreTransactionsButtonClick(),
    });

    const flightMoveResultBanner = useFlightMoveResultBanner();

    const pendingPaymentModal = usePendingPaymentModal();

    const getCancelledJourneyIndices = () =>
        props.model.ItineraryModelV2.Journeys.filter((j) =>
            props.model.CancelledJourneyDepartureStations.includes(j.DepartureStationCode),
        ).map((j) => j.JourneyIndex);

    const tabs = useItineraryTabs({
        model: props.model,
        cancelledJourneyIndices: getCancelledJourneyIndices(),
        togglePrintingTransaction: () => setIsPrintingTransaction(!isPrintingTransaction),
    });

    const optionals = useItineraryPageOptionals({ model: props.model.ItineraryModelV2 });

    const banners = useItineraryBanners({ model: props.model.ItineraryModelV2 });

    const { triggers } = usePubSub();

    const redirectionModal = useCarryOnCheckinModal({ url: props.model.CheckinRedirect.UrlRedirectOnModalClose });

    const invalidPeruCompraModal = useInvalidPeruCompraModal();

    const americanAirlinesModal = useAmericanAirlinesRedirectionModal();

    const gdsDataModal = useGdsDataModal();

    const refundWidget = useRefundWidget({
        antiForgeryToken: props.antiForgeryToken,
        isFlightDeparted: props.model.ItineraryModelV1.RefundDataReadOnly,
        model: props.model.RefundWidgetViewModel,
        refundBankInfoModel: props.model.RefundBankInfo,
    });

    // HELPERS

    const init = () => {
        setAntiForgeryToken(getAntiForgeryTokenFromHtml(props.antiForgeryToken));

        if (props.model.CheckinRedirect.IsItineraryRedirectionModalNeeded) {
            redirectionModal.open();
        }

        if (props.model.IsAmericanAirlinesFlight) {
            americanAirlinesModal.open();
        }

        if (enforceCug2Login()) {
            // DEVNOTE Settimeout is needed for login window to mount
            window.setTimeout(() => triggers.login.openLoginModal.publish({ isForced: true }), 0);
        }

        bookingDataManager.eraseBookingData();

        window.setTimeout(async () => {
            await tealiumManager.logItineraryPageLoad();
        }, 1000);

        // DEVNOTE JET-6254
        if ("scrollRestoration" in history) history.scrollRestoration = "manual";
        window.setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 100);
        // DEVNOTE End of JET-6254

        // DEVNOTE JET-6637 GDS needs email and phone
        if (!props.model.ItineraryModelV2.UserEmail) gdsDataModal.open();

        if (props.model.ItineraryModelV2.IsTotalPending && !props.model.ItineraryModelV2.IsLastPaymentDeclined) {
            pendingPaymentModal.open();
        }
    };

    const isPeruCompra = () => userContext.peruCompra.role !== "none" || bookingContext.isPeruCompraBooking;

    const isChileCompra = () => userContext?.chileCompra.role !== "none" || bookingContext.isChileCompraBooking;

    const showBannersAndAds = () =>
        !flowContext.isFarelockRoundTwo &&
        !flowContext.isPeruCompraAnonymousCheckinFlow &&
        !props.model.IsFarelockAmountNotFullyPaid &&
        !props.model.CancelledFlightDesignators &&
        !isPeruCompra() &&
        !isChileCompra();

    const checkUser = () => {
        // DEVNOTE Only show modal after userContext is built
        if (!userContext.userRole) return;

        if (
            bookingContext.isPeruCompraBooking &&
            userContext.peruCompra.role === "none" &&
            !flowContext.isPeruCompraAnonymousCheckinFlow
        ) {
            invalidPeruCompraModal.open();
        }
    };

    const showPnr = (): boolean => {
        return !props.model.ItineraryModelV2.IsTotalPending || props.model.ItineraryPayments.some((p) => p.IsApproved);
    };

    const enforceCug2Login = (): boolean => {
        return (
            window.location.search.toLowerCase().indexOf(FORCE_CUG2_LOGIN_URL_PARTIAL.toLowerCase()) > -1 &&
            !userContext.cug.isMember
        );
    };

    const handleMoreTransactionsButtonClick = () => {
        if (isMobile()) {
            tabs.setOpenMobileAccordion("Transactions");
            ScrollHelper.scrollToItineraryTransactions(true);
            return;
        }

        tabs.setSelectedTab("Transactions");
        ScrollHelper.scrollToItineraryTransactions(false);
    };

    useEffect(checkUser, [bookingContext, userContext]);

    useEffect(init, []);

    // TEMPLATES

    const dcBannerTemplate = () => {
        const imgSrc =
            appContext.Culture === USA_CULTURE_CODE
                ? "/Images/DcStandalone/dc-logo-2-en.png"
                : appContext.Culture === BRASILIAN_CULTURE_CODE
                  ? "/Images/DcStandalone/dc-logo-2-pt.png"
                  : "/Images/DcStandalone/dc-logo-2.png";

        const bannerText =
            userContext.bancoEstado.category !== 0 ? i18next.t("I2-DcBannerText-BE") : i18next.t("I2-DcBannerText");

        return props.model.ItineraryModelV2.BoughtDcInFlow &&
            !props.model.ItineraryModelV2.IsTotalPending &&
            props.model.IsBookingFlow &&
            !isChileCompra()
            ? html`
                  <div class="i2-itinerary-section i2-dc-banner no-print">
                      <div class="flex flex-col items-center sm:flex-row sm:justify-center">
                          <div class="i2db-left">
                              <div class="i2-dc-banner-title">${i18next.t("I2-DcBannerTitle")}</div>
                              <div class="i2-dc-banner-subtitle">${i18next.t("I2-DcBannerSubTitle")}</div>
                              <img src=${imgSrc} />
                          </div>
                          <div class="i2db-right">
                              <img src="/Images/BancoEstado/itinerary-dc-icons.svg" />
                              <div class="i2-dc-banner-text">${bannerText}</div>
                          </div>
                      </div>
                  </div>
              `
            : "";
    };

    const paymentErrorTemplate = () =>
        props.model.ItineraryModelV2.ShowPaymentErrorWarning
            ? html`
                  <div class="row no-print">
                      <div class="col-xs-1">
                          <div class="error-message-container">
                              <div class="form-error-message">
                                  ${i18next.t("Payment-NoRedirectPending")}
                                  <a href="/">${i18next.t("here")}</a>
                                  .
                              </div>
                          </div>
                      </div>
                  </div>
              `
            : "";

    const holdWarningTemplate = () =>
        props.model.IsCugOnHold
            ? html`
                  <div class="hold-booking-warning no-print">
                      <div>${i18next.t("CUG2-BookingOnHoldWarning")}</div>
                  </div>
              `
            : "";

    const pastBookingBannerTemplate = () =>
        props.model.ItineraryModelV2.AllFlightsInPast
            ? html`
                  <div class="all-flights-past no-print my-4">
                      <div>${i18next.t("DonePNRLine1")}</div>
                      <div>${i18next.t("DonePNRLine2")}</div>
                      <div>
                          ${unsafeHTML(
                              i18next.t("DonePNRLine3 {{-linkStart}}{{-linkEnd}}", {
                                  linkStart: `<a href="${props.model.CorporateBaseUrl}">`,
                                  linkEnd: "</a>",
                              }),
                          )}
                      </div>
                  </div>
              `
            : "";

    const transactionsTemplate = () =>
        props.model.ItineraryModelV2.ShowTransactionBox ? transactions.htmlTemplate() : "";

    const chileCompraOrderIdTemplate = () =>
        userContext.chileCompra.role !== "none" && props.model.ItineraryModelV2.Cug3OrderId
            ? html`<span
                  class="font-news text-[10px] text-brand-secondary sm:mt-1 sm:text-[11px] md:mt-[7px] md:text-xs/3 lg:mt-2 lg:text-[13px] xl:mt-3 xl:text-[17px]"
                  >${props.model.ItineraryModelV2.Cug3OrderId}</span
              >`
            : "";

    const headerTemplate = () => html`
        <div class="i2-itinerary-section i2-header no-print">
            <div class="flex items-center">
                <i class="js-icon-it js-it-plane-calendar"></i>
                <div class="flex flex-col">
                    <span class="i2-header-title" data-test-id="itinerary-title">
                        ${i18next.t("Administra tu viaje")}
                    </span>
                    ${!isChileCompra()
                        ? html` <span class="i2-header-subtitle">
                              ${i18next.t(
                                  "Aquí encontrarás todos los detalles de tu viaje, agrega opcionales, haz tu check-in y mucho más.",
                              )}
                          </span>`
                        : ""}
                </div>
            </div>
            <div class="mt-2 flex items-center sm:mt-0">
                <div class="mr-2 flex items-start border-r border-solid border-be-blue pr-2 md:mr-4 md:pr-4">
                    <span
                        class="i2-header-pax-nr"
                        data-test-id=${T.ITINERARY.PAX_DETAILS_HEADER_PAX_COUNT}
                        data-test-value=${props.model.ItineraryModelV2.Passengers.length}
                    >
                        ${props.model.ItineraryModelV2.Passengers.length}
                    </span>
                    <span class="i2-header-pax">
                        ${i18next.t("pasajero{{plural}}", {
                            plural: props.model.ItineraryModelV2.Passengers.length !== 1 ? "s" : "",
                        })}
                    </span>
                </div>
                <div class="flex flex-col items-center">
                    <span
                        class=${classNames("i2-header-pnr-title", {
                            "mb-[2px] sm:mb-2 md:mb-3 lg:mb-[14px] xl:mb-[18px]":
                                userContext.chileCompra.role === "none" || !props.model.ItineraryModelV2.Cug3OrderId,
                            "mb-[2px] sm:mb-1 md:mb-2 lg:mb-[10px] xl:mb-[14px]":
                                userContext.chileCompra.role !== "none" && props.model.ItineraryModelV2.Cug3OrderId,
                        })}
                        >${i18next.t("Código de reserva")}:</span
                    >
                    <span class="i2-header-pnr" data-test-id="itinerary-pnr-span"
                        >${showPnr() ? props.model.ItineraryModelV2.Pnr : ""}</span
                    >
                    ${chileCompraOrderIdTemplate()}
                </div>
            </div>
        </div>
    `;

    const insuranceTemplate = () => {
        if (
            isChileCompra() ||
            !props.model.ItineraryModelV2.Insurance.ExistingInsuranceDetails ||
            (props.model.ItineraryModelV2.Insurance.ExistingInsuranceDetails.HasInsuranceInBooking &&
                !props.model.ItineraryModelV2.Insurance.ExistingInsuranceDetails.HasBaggageInsurance &&
                !props.model.ItineraryModelV2.Insurance.ExistingInsuranceDetails.HasTravelInsurance)
        ) {
            return html``;
        }

        const insuranceTypeMessage =
            props.model.ItineraryModelV2.Insurance.ExistingInsuranceDetails.HasBaggageInsurance &&
            props.model.ItineraryModelV2.Insurance.ExistingInsuranceDetails.HasTravelInsurance
                ? i18next.t("Ya tienes tu Seguro de Viaje y Equipaje")
                : props.model.ItineraryModelV2.Insurance.ExistingInsuranceDetails.HasBaggageInsurance
                  ? i18next.t("Ya tienes tu Seguro de Equipaje")
                  : i18next.t("Ya tienes tu Seguro de Viaje");

        return html`
            <div class="i2-itinerary-section i2-insurance no-print">
                <div class="i2-insurance-logos">
                    <div class="flex items-center">
                        <i class="js-icon-it js-it-insurance"></i>
                        <div class="flex flex-col items-center justify-center">
                            <span>${insuranceTypeMessage}</span>
                            <span>
                                <i class="js-icon-it js-it-chubb"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <ul class="i2-insurance-info">
                    <li>
                        ${i18next.t("Válido desde")}:
                        ${props.model.ItineraryModelV2.Insurance.ExistingInsuranceDetails.FormattedValidFrom}
                    </li>
                    <li>
                        ${i18next.t("Válido hasta")}:
                        ${props.model.ItineraryModelV2.Insurance.ExistingInsuranceDetails.FormattedValidTo}
                    </li>
                    <li class="mb-4 font-semibold">
                        ${i18next.t("Póliza número")}:
                        ${props.model.ItineraryModelV2.Insurance.ExistingInsuranceDetails.Policy}
                    </li>
                    <li class="font-semibold">
                        ${i18next.t("Recuerda que recibirás tu póliza por correo electrónico")}
                    </li>
                </ul>
            </div>
        `;
    };

    const bookHotelTemplate = () => html`
        <div class="i2-book-hotel-section no-print">
            <span class="i2-book-hotel-content"
                >${i18next.t("Mejora tu viaje y reserva hotel, auto y/o transfer")}</span
            >
        </div>
    `;

    const farelockExpiryTemplate = () =>
        (flowContext.isFarelockRoundTwo || props.model.IsFarelockAmountNotFullyPaid) &&
        typeof props.model.FarelockExpiry === "string"
            ? html`
                  <div class="i2-itinerary-section i2-farelock-section no-print">
                      <span>${i18next.t("¡Recuerda!")}</span>
                      ${i18next.t("Podemos mantener el precio fijo de tu tarifa y Pack hasta el día")}
                      <span>${props.model.FarelockExpiry}</span>
                      ${i18next.t("horas.")}
                  </div>
              `
            : "";

    const mercadoPublicoWidgetTemplate = () =>
        userContext.chileCompra.role !== "none" && props.model.Cug3ApprovalUrl
            ? html`
                  <div class="i2-itinerary-section i2-farelock-section no-print">
                      <div class="px- flex h-11 items-center justify-between">
                          <img src="/Images/ChileCompra/chile-compra-logo.png" />
                          <a class="rounded-secondary-btn with-arrow" href=${props.model.Cug3ApprovalUrl}>
                              ${i18next.t("Volver a mercado público")}
                          </a>
                      </div>
                  </div>
              `
            : "";

    return html`
        ${transactionsTemplate()} ${paymentErrorTemplate()} ${pendingPaymentBanner.htmlTemplate()}
        ${holdWarningTemplate()} ${pastBookingBannerTemplate()}

        <div class="i2-itinerary-container">
            ${farelockExpiryTemplate()} ${mercadoPublicoWidgetTemplate()} ${headerTemplate()}
            ${flightMoveResultBanner.htmlTemplate()} ${dcBannerTemplate()} ${insuranceTemplate()} ${tabs.htmlTemplate()}
            ${showBannersAndAds()
                ? html` ${banners.htmlTemplate()} ${optionals.htmlTemplate()} ${bookHotelTemplate()} `
                : ""}
            <div class="i2-itinerary-section i2-third-party no-print">
                <div id="Widget"></div>
            </div>
        </div>
        ${redirectionModal.htmlTemplate()} ${pendingPaymentModal.htmlTemplate()}
        ${invalidPeruCompraModal.htmlTemplate()} ${americanAirlinesModal.htmlTemplate()} ${gdsDataModal.htmlTemplate()}
        ${appContext.isFeatureSwitchActive("ProConsumidor") ? refundWidget.htmlTemplate() : ""}
    `;
};
