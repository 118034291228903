import i18next from "i18next";
import { FlightOptionModel } from "../component-models/flight/FlightOptionModel";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import * as UTC from "dayjs/plugin/utc";
import { getLegDetails } from "./MultilegFlightLegInfoMapper";
import { capitalizeAndRemoveDot } from "../shared/common";
import { ExtendedTripModel } from "../component-models/flight/ExtendedTripModel";
import { ApiFlightSelectModel } from "../component-models/flight/api/ApiFlightSelectModel";
import { FlightCarouselDate } from "../component-models/flight/FlightCarousel";
dayjs.extend(UTC);

export const mapToCarouselDates = (journey: ExtendedTripModel): FlightCarouselDate[] =>
    journey?.LowestFares.map(
        (lowestFare): FlightCarouselDate => ({
            Date: dayjs(lowestFare.DayjsDate),
            FormattedPrice: lowestFare.LowestFarePriceString,
            IsDisabled: lowestFare.IsDisabled,
            IsSelected: lowestFare.IsSelectedDay,
            Url: lowestFare.LowFareLink,
            Miles: lowestFare.Miles,
        }),
    ) || [];

export const mapToFlightOption = (
    flightSelectModel: ApiFlightSelectModel,
    index: number,
    tripModel: ExtendedTripModel,
): FlightOptionModel => {
    const fare = flightSelectModel.FaresPerProductClass.xyz.Fare;

    if (!fare.HasClubFare && !fare.HasDiscountedFare && !fare.HasRegularFare) {
        return undefined;
    }

    const hours = flightSelectModel.Flight.TravelTime.Hours + flightSelectModel.Flight.TravelTime.Days * 24;
    const minutes = flightSelectModel.Flight.TravelTime.Minutes;

    const regularPrice = fare.HasRegularFare ? fare.RegularFare.AmountString : null;

    const sellKey = fare.HasDiscountedFare
        ? fare.DiscountedFare.FlightFareKey
        : fare.RegularFare !== null
          ? fare.RegularFare.FlightFareKey
          : null;

    const unformattedSmartPrice = fare.HasDiscountedFare ? fare.DiscountedFare.Amount : 0;
    const unformattedSmartPriceInUsd = fare.HasDiscountedFare ? fare.DiscountedFare.AmountInUsd : 0;
    const unformattedClubPrice = fare.HasClubFare ? fare.ClubFare.Amount : 0;
    const unformattedClubPriceInUsd = fare.HasClubFare ? fare.ClubFare.AmountInUsd : 0;

    return {
        ArrivalDate: dayjs.utc(flightSelectModel.ArrivalDate, "YYYY-MM-DD"),
        DepartureDate: dayjs.utc(flightSelectModel.DepartureDate, "YYYY-MM-DD"),
        FlightSelectModel: flightSelectModel,
        FormattedFlightDate: capitalizeAndRemoveDot(dayjs(tripModel.TripDate).format("ddd DD-MM")),
        HasClubFare: fare.HasClubFare,
        HasEnoughSeatsForInfants: flightSelectModel.HasEnoughSeatsForInfants,
        Hours: hours,
        Index: index,
        JourneyIndex: tripModel.TripIndex,
        LegDetails: flightSelectModel.Segments[0].Legs.map((leg, i) =>
            getLegDetails(flightSelectModel.Segments[0], leg, i),
        ),
        Minutes: minutes,
        RegularPrice: regularPrice,
        SellKey: sellKey,
        SubjectToGovtApproval: flightSelectModel.Flight.Legs[0].LegInfo.SubjectToGovtApproval,
        Tooltip1: i18next.t("V2-FlightTimeTooltip {{hours}} {{label}} {{minutes}}", {
            hours,
            label: hours === 1 ? i18next.t("Stop-Hour") : i18next.t("Stop-Hours"),
            minutes,
        }),
        UnformattedClubPrice: unformattedClubPrice,
        UnformattedSmartPrice: unformattedSmartPrice,
        UnformattedClubPriceInUsd: unformattedSmartPriceInUsd,
        UnformattedSmartPriceInUsd: unformattedClubPriceInUsd,
    };
};
