import dayjs from "dayjs";
import { UserContext } from "../../component-models/app/UserContext";
import { TravelDocument } from "../../component-models/checkin/TravelDocument";
import { ApiPassengerTravelDocument } from "../../component-models/passengers/ApiNewPassengersModel";
import { PassengerFormVM } from "../../component-models/passengers/PassengerFormVM";
import { FAKE_DOCUMENT_EXPIRATION_DATE, DEFAULT_DATE_FORMAT } from "../../shared/commonConstants";

export const passengerMapperHelpers = () => {
    const concatenateString = (parts: string[]): string =>
        parts
            .map((part) => part || "")
            .join(" ")
            .trim();

    const splitString = (text: string, defaultFirst = ""): string[] =>
        text ? text.split(" ").map((part) => part || "") : [defaultFirst, ""];

    const getPassengerNumber = (passenger: PassengerFormVM): number =>
        passenger.type === "INF" ? passenger.attachedPassengerNumber : passenger.passengerNumber;

    const getSuffix = (passenger: PassengerFormVM): string => (passenger.type === "INF" ? "I" : " ");

    const filterPassports = (passenger: PassengerFormVM, documents: TravelDocument[]): TravelDocument[] => {
        return documents.filter(
            (document) =>
                document.PassengerNumber === getPassengerNumber(passenger) &&
                document.DocSuffix === getSuffix(passenger) &&
                document.TypeCode &&
                document.Number,
        );
    };

    const getBancoEstadoDocument = (
        documents: ApiPassengerTravelDocument[],
        userContext: UserContext,
    ): ApiPassengerTravelDocument => {
        if (userContext.bancoEstado.category === 0) return undefined;

        const existingDocument = documents.find(
            (doc) => doc.DocumentType === "N" && doc.DocSuffix === " " && doc.DocumentNumber,
        );

        if (existingDocument) return existingDocument;

        return {
            DocSuffix: " ",
            DocumentNumber: userContext.userUserName,
            DocumentType: "N",
            ExpirationDate: FAKE_DOCUMENT_EXPIRATION_DATE,
            IssuedBy: "CL",
            IssuedDate: dayjs().format(DEFAULT_DATE_FORMAT),
        };
    };

    return { concatenateString, filterPassports, getBancoEstadoDocument, splitString };
};
