import { html, useRef } from "haunted";
import i18next from "i18next";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { ApiPortalSpaViewModel } from "../../../component-models/CUG2b/portal-spa/ApiPortalSpaViewModel";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { ref } from "../../../directives/ref";
import { ROUTES } from "../../../shared/apiRoutes";
import { maskCurrenciesForDisplay, getAntiforgerySegment, handleCugLoader } from "../../../shared/common";
import { PERUVIAN_SOL_CODE, PERUVIAN_SOL_CURRENCY_SIGN, GENERAL_CURRENCY_SIGN } from "../../../shared/commonConstants";
import { useState } from "../../../shared/haunted/CustomHooks";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";

export interface Props {
    model: ApiPortalSpaViewModel;
    antiForgeryToken: string;
    isToppingUp: boolean;
    setIsToppingUp: (isToppingUp: boolean) => void;
}

export const useTopupModal = (props: Props) => {
    const cug2AppContext = useCug2AppContext();

    const [showAmountError, setShowAmountError] = useState<boolean>(false);
    const [showMaxAmountError1, setShowMaxAmountError1] = useState<boolean>(false);
    const [showMaxAmountError2, setShowMaxAmountError2] = useState<boolean>(false);

    // HELPERS

    // DEVNOTE The quirks here are caused by the requirements. If an invalid amount is submitted, we display an error.
    // However, if we submit an invalid amount AGAIN, a DIFFERENT error message must be displayed.
    const validate = (alreadyValidated?: boolean) => {
        const value = Number(amountInput.current.value);
        const isValid = /^[0-9]+$/.test(amountInput.current.value) && value > 0;

        if (!isValid) {
            setShowAmountError(true);
            return false;
        }

        setShowAmountError(false);

        if (!props.model.SummaryViewModel.MaxPaymentAmount || value <= props.model.SummaryViewModel.MaxPaymentAmount) {
            setShowMaxAmountError1(false);
            setShowMaxAmountError2(false);
            return true;
        }

        if (alreadyValidated || showMaxAmountError2) {
            return false;
        }

        if (showMaxAmountError1) {
            setShowMaxAmountError1(false);
            setShowMaxAmountError2(true);
        } else {
            setShowMaxAmountError1(true);
            setShowMaxAmountError2(false);
        }

        return false;
    };

    const getAmountForOverpaymentError = () =>
        `${props.model.SummaryViewModel.MaxPaymentAmountFormatted} ${maskCurrenciesForDisplay(props.model.SummaryViewModel.CurrencyCode)}`;

    // EVENT HANDLERS

    const handleKeyPress = (e: KeyboardEvent) => {
        if (e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();

            submitForm();
        }
    };

    const handleInput = () => {
        if (showAmountError || showMaxAmountError1 || showMaxAmountError2) {
            validate(true);
        }
    };

    const submitForm = (e?: MouseEvent) => {
        e?.preventDefault();
        e?.stopPropagation();

        if (validate()) {
            handleCugLoader(form.current, "loadData");
            form.current.submit();
        }
    };

    // COMPONENT

    const form = useRef<HTMLFormElement>(undefined);
    const amountInput = useRef<HTMLInputElement>(undefined);

    // TEMPLATES

    const topupModalHeaderTemplate = () => i18next.t("Abono");

    const amountErrorTemplate = () =>
        showAmountError ? html` <span class="amount-error">${i18next.t("CUG2-AmountError")}</span> ` : "";

    const overpaymentErrorMessage1Template = () => {
        const overpaymentErrorMessageAmount = getAmountForOverpaymentError();
        return showMaxAmountError1
            ? html`
                  <span class="amount-error" data-test-id=${T.CUG2_SUMMARY.AMOUNT_ERROR_ON_MODAL_1}>
                      ${unsafeHTML(
                          i18next.t("CUG2-MaxAmountError1 {{-amount}}", {
                              amount: `<span>${overpaymentErrorMessageAmount}</span>`,
                          }),
                      )}
                  </span>
              `
            : "";
    };

    const overpaymentErrorMessage2Template = () => {
        const overpaymentErrorMessageAmount = getAmountForOverpaymentError();
        return showMaxAmountError2
            ? html`
                  <span class="amount-error" data-test-id=${T.CUG2_SUMMARY.AMOUNT_ERROR_ON_MODAL_2}>
                      ${unsafeHTML(
                          i18next.t("CUG2-MaxAmountError2 {{-amount}}", {
                              amount: `<span>${overpaymentErrorMessageAmount}</span>`,
                          }),
                      )}
                  </span>
              `
            : "";
    };

    const overpaymentErrorTemplate = () =>
        props.model.SummaryViewModel.MaxPaymentAmount > 0
            ? html` ${overpaymentErrorMessage1Template()} ${overpaymentErrorMessage2Template()} `
            : "";

    const topupModalContentTemplate = () => {
        const currencySignForPlaceholder =
            cug2AppContext.Currency === PERUVIAN_SOL_CODE ? PERUVIAN_SOL_CURRENCY_SIGN : GENERAL_CURRENCY_SIGN;
        return html`
            <form action=${ROUTES.Cug2BTopup} method="POST" ref=${ref(form)} class="cug2b-topup-form">
                ${getAntiforgerySegment(props.antiForgeryToken)}
                <div class="cug2b-topup-balance">
                    ${i18next.t("Saldo disponible")}:
                    <span
                        >${maskCurrenciesForDisplay(props.model.SummaryViewModel.CurrencyCode)}
                        ${props.model.SummaryViewModel.FormattedBalance}</span
                    >
                </div>
                <div class="cug2b-topup-input">
                    <label>${i18next.t("Monto a abonar")}</label>
                    <input
                        ref=${ref(amountInput)}
                        name="Amount"
                        type="text"
                        placeholder=${i18next.t("Ingresa un monto superior a {{-currency}}1", {
                            currency: currencySignForPlaceholder,
                        })}
                        data-test-id=${T.CUG2_SUMMARY.AMOUNT_TO_PAY_INPUT_FIELD}
                        @keypress=${handleKeyPress}
                        @input=${handleInput}
                    />
                </div>
                ${amountErrorTemplate()} ${overpaymentErrorTemplate()}
                <button
                    class="rounded-primary-btn mx-auto mt-6"
                    data-test-id=${T.CUG2_SUMMARY.MODAL_PAY_BUTTON}
                    @click=${submitForm}
                >
                    ${i18next.t("Abonar")}
                </button>
                <div class="cug2b-redirection-warning">${i18next.t("Serás redirigido a la página de pagos")}</div>
            </form>
        `;
    };

    const htmlTemplate = () => html`
        <ac-cug-modal
            .canBeClosed=${true}
            .content=${topupModalContentTemplate()}
            .customClass=${"cug2b-topup-modal"}
            .header=${topupModalHeaderTemplate()}
            .isOpen=${props.isToppingUp}
            @close=${() => {
                props.setIsToppingUp(false);
                setShowAmountError(false);
                setShowMaxAmountError1(false);
                setShowMaxAmountError2(false);
            }}
        ></ac-cug-modal>
    `;

    return { htmlTemplate };
};
