import { useEffect, useState } from "../../haunted/CustomHooks";

// https://stackoverflow.com/questions/42615556/how-to-preload-images-in-react-js
export default function useImagePreloader(imageList: string[]) {
    const [imagesPreloaded, setImagesPreloaded] = useState<boolean>(false);

    const preloadImage = (src: string) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                resolve(img);
            };
            img.onerror = img.onabort = () => {
                reject(src);
            };
            img.src = src;
        });
    };

    useEffect(() => {
        let isCancelled = false;

        async function doIt() {
            if (isCancelled) {
                return;
            }

            const imagesPromiseList: Promise<any>[] = [];

            for (const i of imageList) {
                imagesPromiseList.push(preloadImage(i));
            }

            await Promise.all(imagesPromiseList);

            if (isCancelled) {
                return;
            }

            setImagesPreloaded(true);
        }

        void doIt();

        return () => {
            isCancelled = true;
        };
    }, [JSON.stringify(imageList)]);

    return { imagesPreloaded };
}
