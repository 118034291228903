import i18next from "i18next";
import { html } from "haunted";
import { useModal } from "../shared/useModal";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { ROUTES } from "../../shared/apiRoutes";

export interface Props {
    isRmGroupBooking: boolean;
    rmGroupBookingRedirectUrl: string | undefined;
}

export const useGroupBookingWarningModal = (props: Props) => {
    const headerTemplate = () => html`<span>${i18next.t("Importante")}</span>`;

    const link = props.isRmGroupBooking ? props.rmGroupBookingRedirectUrl : `${ROUTES.PageItinerary}?tab=Modify`;

    const htmlTemplate = () => html`
        <div class="modal-body">
            <div class="description-container">
                <p>
                    ${unsafeHTML(
                        i18next.t(
                            "No es posible realizar tu check-in debido a que falta información por completar en tu reserva. Si eres el administrador de la reserva, haz clic {{- linkStart}}aquí{{- linkEnd}}. En caso contrario, por favor contáctate con tu administrador.",
                            {
                                linkStart: `<a target='_blank' href='${link}'>`,
                                linkEnd: "</a>",
                            },
                        ),
                    )}
                </p>
            </div>
            <div class="primary-modal-button-container">
                <button class="rounded-primary-btn" @click=${() => (window.location.href = link)}>
                    ${i18next.t("Ir a la página de reservas en grupos")}
                </button>
            </div>
        </div>
    `;

    const modal = useModal({
        content: { classNames: "booking-modal-content ", template: htmlTemplate },
        header: { template: headerTemplate },
        overlay: { classNames: "primary-modal" },
    });

    return modal;
};
