import dayjs, { Dayjs } from "dayjs";
import i18next from "i18next";
import { isEmpty } from "../component-helpers/stringHelper";
import { DEFAULT_DATE_FORMAT, FULL_EMAIL_REGEX } from "../shared/commonConstants";
import { TemplateResult } from "lit-html";

type RuleType =
    | "required"
    | "number"
    | "length"
    | "date_after"
    | "date_same_or_after"
    | "date_before"
    | "date_same_or_before"
    | "date_between_inclusive"
    | "condition"
    | "max"
    | "min"
    | "email"
    | "in"
    | "not_in"
    | "regex"
    | "not_regex"
    | "not_empty";

export interface Validator {
    validate: (value: any) => Promise<boolean>;
}

interface Rule {
    type: RuleType;
    message: Message;
    validate: (value: any) => Promise<boolean>;
}

interface Message {
    validateImmediately: boolean;
    scope?: "field" | "form";
    text: string | TemplateResult;
}

export type Messages<FN extends string> = { [key in FN]: Message };
export type MessageResult<FN extends string> = { field: FN; message: Message }[];

export interface AbstractValidation<FN extends string, VM> {
    validate: (model: VM) => Promise<MessageResult<FN>>;
}

export interface FluentValidatorMethodsPartial {
    getMessage: (field: string) => string | TemplateResult | undefined;
    isFieldValid: (field: string) => boolean;
}

export class Validation<FN extends string, VM> {
    private field: FN = undefined as any;
    private selector: (model: VM) => unknown = undefined as any;
    private rules: Rule[] = [];
    private validator: Validation<string, unknown>[] = [];
    private condition: (model: VM) => boolean = (_) => true;
    private arrayIdSelector?: (vm: any) => number;

    private constructor() {
        // empty
    }

    public static ruleFor = <FN2 extends string, VM2>(field: FN2, selector: (model: VM2) => unknown) => {
        const v = new Validation<FN2, VM2>();
        v.field = field;
        v.selector = selector;
        return v;
    };

    public isRequired = (message?: string, scope: "form" | "field" = "form", validateImmediately?: boolean) => {
        const rule: Rule = {
            message: {
                scope,
                text: message ?? i18next.t("V2-PleaseFillAllFields"),
                validateImmediately,
            },
            type: "required",
            validate: async (value) => !isEmpty(value?.toString().trim()),
        };
        this.rules.push(rule);

        return this;
    };

    public hasLength = (length: number, message?: string, validateImmediately?: boolean) => {
        const rule: Rule = {
            message: {
                text: message ?? i18next.t("Este campo debe tener {{length}} caracteres de longitud.", { length }),
                validateImmediately,
            },
            type: "length",
            validate: async (value) => {
                if (Array.isArray(value)) {
                    return value?.length === length;
                } else {
                    return value?.toString()?.length === length;
                }
            },
        };
        this.rules.push(rule);

        return this;
    };

    public isNumber = (message?: string, validateImmediately?: boolean) => {
        const rule: Rule = {
            message: {
                text: message ?? i18next.t("Number required"),
                validateImmediately,
            },
            type: "number",
            validate: async (value) => !isEmpty(value) && !isNaN(Number(value)),
        };
        this.rules.push(rule);

        return this;
    };

    public isDateAfter = (date: Dayjs, message?: string, validateImmediately?: boolean) => {
        const rule: Rule = {
            message: {
                text:
                    message ??
                    i18next.t("Date must be after {{date}}", {
                        date: date.format(DEFAULT_DATE_FORMAT),
                    }),
                validateImmediately,
            },
            type: "date_after",
            validate: async (value) => value !== undefined && dayjs(value).isAfter(date),
        };
        this.rules.push(rule);

        return this;
    };

    public isDateSameOrAfter = (date: Dayjs, message?: string, validateImmediately?: boolean) => {
        const rule: Rule = {
            message: {
                text:
                    message ??
                    i18next.t("Date must be same or after {{date}}", {
                        date: date.format(DEFAULT_DATE_FORMAT),
                    }),
                validateImmediately,
            },
            type: "date_same_or_after",
            validate: async (value) => value !== undefined && dayjs(value).isSameOrAfter(date),
        };
        this.rules.push(rule);

        return this;
    };

    public isDateBefore = (date: Dayjs, message?: string, validateImmediately?: boolean) => {
        const rule: Rule = {
            message: {
                text:
                    message ??
                    i18next.t("Date must be before {{date}}", {
                        date: date.format(DEFAULT_DATE_FORMAT),
                    }),
                validateImmediately,
            },
            type: "date_before",
            validate: async (value) => value !== undefined && dayjs(value).isBefore(date),
        };
        this.rules.push(rule);

        return this;
    };

    public isDateSameOrBefore = (date: Dayjs, message?: string, validateImmediately?: boolean) => {
        const rule: Rule = {
            message: {
                text:
                    message ??
                    i18next.t("Date must be same or before {{date}}", {
                        date: date.format(DEFAULT_DATE_FORMAT),
                    }),
                validateImmediately,
            },
            type: "date_same_or_before",
            validate: async (value) => value !== undefined && dayjs(value).isSameOrBefore(date),
        };
        this.rules.push(rule);

        return this;
    };

    public isDateBetweenInclusive = (from: Dayjs, to: Dayjs, message?: string, validateImmediately?: boolean) => {
        const rule: Rule = {
            message: {
                text:
                    message ??
                    i18next.t("Date must be between {{from}} and {{to}}", {
                        from: from.format(DEFAULT_DATE_FORMAT),
                        to: to.format(DEFAULT_DATE_FORMAT),
                    }),
                validateImmediately,
            },
            type: "date_between_inclusive",
            validate: async (value: Dayjs) =>
                value !== undefined && value.isSameOrAfter(from) && value.isSameOrBefore(to),
        };
        this.rules.push(rule);

        return this;
    };

    public max = (max: number, message?: string, validateImmediately?: boolean) => {
        const rule: Rule = {
            message: {
                text:
                    message ??
                    i18next.t("La longitud máxima es de {{max}} caracteres.", {
                        max,
                    }),
                validateImmediately,
            },
            type: "max",
            validate: async (value) => {
                if (isEmpty(value?.toString())) {
                    return true;
                }

                if (typeof value === "number") {
                    return value <= max;
                } else if (Array.isArray(value)) {
                    return value.length <= max;
                } else {
                    return value?.toString().length <= max;
                }
            },
        };
        this.rules.push(rule);

        return this;
    };

    public min = (min: number, message?: string, validateImmediately?: boolean) => {
        const rule: Rule = {
            message: {
                text:
                    message ??
                    i18next.t("La longitud mínima es de  {{min}} caracteres.", {
                        min,
                    }),
                validateImmediately,
            },
            type: "min",
            validate: async (value) => {
                if (isEmpty(value?.toString())) {
                    return true;
                }

                if (typeof value === "number") {
                    return value >= min;
                } else if (Array.isArray(value)) {
                    return value.length >= min;
                } else {
                    return value?.toString().length >= min;
                }
            },
        };
        this.rules.push(rule);

        return this;
    };

    public notEmpty = (message?: string, validateImmediately?: boolean) => {
        const rule: Rule = {
            message: {
                text: message ?? i18next.t("Must not be empty"),
                validateImmediately,
            },
            type: "not_empty",
            validate: async (value) => {
                if (Array.isArray(value)) {
                    return value.length > 0;
                } else {
                    return value?.toString().length > 0;
                }
            },
        };
        this.rules.push(rule);

        return this;
    };

    public isIn = (list: any[], message?: string, validateImmediately?: boolean) => {
        const rule: Rule = {
            message: {
                text: message ?? i18next.t("Value must be in list"),
                validateImmediately,
            },
            type: "in",
            validate: async (value) => list.includes(value),
        };
        this.rules.push(rule);

        return this;
    };

    public isNotIn = (list: any[], message?: string, validateImmediately?: boolean) => {
        const rule: Rule = {
            message: {
                text: message ?? i18next.t("Value must be in list"),
                validateImmediately,
            },
            type: "not_in",
            validate: async (value) => !list.includes(value),
        };
        this.rules.push(rule);

        return this;
    };

    public fulfilsValidations = <FN2 extends string, VM2>(
        validator: Validation<FN2, VM2>[],
        arrayIdSelector?: (vm: VM2) => number,
    ) => {
        this.validator = validator as Validation<string, unknown>[];
        this.arrayIdSelector = arrayIdSelector;
        return this;
    };

    public fulfils = (
        condition: (val: any) => Promise<boolean>,
        message: string | TemplateResult,
        scope: "form" | "field" = "field",
        validateImmediately?: boolean,
    ) => {
        const rule: Rule = {
            message: {
                text: message,
                scope,
                validateImmediately,
            },
            type: "condition",
            validate: condition,
        };
        this.rules.push(rule);

        return this;
    };

    public when = (condition: (model: VM) => boolean) => {
        this.condition = condition;

        return this;
    };

    public isEmail = (message?: string, validateImmediately?: boolean) => {
        const rule: Rule = {
            message: {
                text: message ?? i18next.t("El correo electrónico no es válido."),
                validateImmediately,
            },
            type: "email",
            validate: async (value) => FULL_EMAIL_REGEX.test(value.toString()),
        };

        this.rules.push(rule);

        return this;
    };

    public isMatch = (regex: RegExp, message?: string, validateImmediately?: boolean) => {
        const rule: Rule = {
            message: {
                text: message ?? i18next.t("The value must match the required pattern"),
                validateImmediately,
            },
            type: "regex",
            validate: async (value) => value.toString().match(regex) !== null,
        };
        this.rules.push(rule);

        return this;
    };

    public isNotMatch = (regex: RegExp, message?: string, validateImmediately?: boolean) => {
        const rule: Rule = {
            message: {
                text: message ?? i18next.t("The value must not match the required pattern"),
                validateImmediately,
            },
            type: "regex",
            validate: async (value) => value.toString().match(regex) === null,
        };
        this.rules.push(rule);

        return this;
    };

    public validate = async (model: VM): Promise<MessageResult<FN>> => {
        if (this.condition(model)) {
            const value = this.selector(model);

            for (const rule of this.rules) {
                const result = await rule.validate(value);
                if (!result) {
                    return [{ field: this.field, message: rule.message }];
                }
            }

            const results: MessageResult<FN> = [];
            if (this.validator) {
                for (const validation of this.validator) {
                    const arrValue = value as unknown[];
                    let i = 0;
                    for (const value2 of arrValue) {
                        const id = this.arrayIdSelector ? this.arrayIdSelector(value2) : i;
                        const results2 = (await validation.validate(value2)) as {
                            field: FN;
                            message: Message;
                        }[];
                        if (results2.length > 0) {
                            for (const res of results2) {
                                const field = `${this.field}.${id}.${res.field}`;
                                results.push({
                                    field: field as FN,
                                    message: res.message,
                                });
                            }
                        }
                        i++;
                    }
                }
            }

            return results;
        }

        return [];
    };
}
