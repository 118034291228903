import { TestIdDictionary as T, getTestId } from "../../../../testing-helpers/TestIdHelper";
import i18next from "i18next";
import { html } from "lit-html";
import { paxAmount, paxLabel } from "../../../../component-helpers/BaggageHelper";
import { baggageEditButton } from "../common/baggageEditButton";
import classNames from "classnames";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { BagType } from "../useBaggagePage";

export interface Props {
    baggageSection: BaggageSection;
    bagType: BagType;
}

export const perBookingViewSwitch = (props: Props) => {
    const editButton = baggageEditButton({
        dataTestId: getTestId(T.BAGGAGE.OPEN_PER_JOURNEY_PER_PAX_VIEW_BUTTON, { c: props.bagType }),
        isDisabled:
            paxAmount(props.baggageSection.sectionUi.uiState) === 1 &&
            props.baggageSection.sectionUi.uiState.journeys.length === 1,
        onClick: props.baggageSection.sectionUi.openPerJourneyPerPaxView,
    });

    const infoText = () =>
        paxAmount(props.baggageSection.sectionUi.uiState) === 1
            ? i18next.t("Estás agregando para todos los tramos")
            : i18next.t("Estás agregando para todos los pasajeros y tramos");

    const htmlTemplate = () => {
        const illustrationClassMap = classNames("b2-illustration", {
            taller: props.bagType === "checkedBaggage",
        });

        const containerClassMap = classNames("b2-openable", {
            "b2-opened": props.baggageSection.sectionUi.uiState.perJourneyPerPaxState !== "open",
        });

        return html`
            <div class="b2-view">
                <div class=${illustrationClassMap}></div>
                <div class=${containerClassMap}>
                    <div class="b2-view-switch">
                        <div class="b2-view-info" data-test-id=${T.BAGGAGE.OPEN_PER_JOURNEY_PER_PAX_VIEW_INFO}>
                            ${infoText()}
                        </div>
                        <div class="b2-pax-amount">
                            ${paxAmount(props.baggageSection.sectionUi.uiState)}
                            ${paxLabel(paxAmount(props.baggageSection.sectionUi.uiState))}
                        </div>
                        ${editButton.htmlTemplate()}
                    </div>
                </div>
            </div>
        `;
    };

    return { htmlTemplate };
};
