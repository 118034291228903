import {
    ApiInfantUpdate,
    ApiPassengerUpdate,
    ApiPassengersUpdateRequest,
} from "../component-models/passengers/ApiPassengersUpdateRequest";
import { DEFAULT_DATE_FORMAT, FAKE_DATE_OF_BIRTH } from "../shared/commonConstants";
import { TravelDocument } from "../component-models/checkin/TravelDocument";
import { PassengerFormVM } from "../component-models/passengers/PassengerFormVM";
import { mapToApiPassengerTravelDocument } from "./TravelDocumentMapper";
import { passengerMapperHelpers } from "../component-helpers/passengers/passengerMapperHelpers";

const { concatenateString, filterPassports } = passengerMapperHelpers();

const postAddress = (passenger: PassengerFormVM) =>
    passenger.email ||
    passenger.officePhoneNumber ||
    passenger.phoneNumber ||
    (Array.from(Object.keys(passenger)) as (keyof PassengerFormVM)[])
        .filter((key: keyof PassengerFormVM) => key.startsWith("address") && key.toLowerCase().indexOf("prefix") === -1)
        .some((key: keyof PassengerFormVM) => passenger[key]);

const postContact = (passenger: PassengerFormVM) =>
    (Array.from(Object.keys(passenger)) as (keyof PassengerFormVM)[])
        .filter((key: keyof PassengerFormVM) => key.startsWith("contact") && key.toLowerCase().indexOf("prefix") === -1)
        .some((key: keyof PassengerFormVM) => passenger[key]);

const getPassengerDateOfBirth = (passenger: PassengerFormVM): string =>
    passenger.dateOfBirth?.format(DEFAULT_DATE_FORMAT) || FAKE_DATE_OF_BIRTH;

// EXPORTS

export const mapToApiPassengersUpdateRequest = (data: {
    culture: string;
    departureStationCode: string;
    documents: TravelDocument[];
    passengers: PassengerFormVM[];
}): ApiPassengersUpdateRequest => ({
    PassengerUpdates: data.passengers
        .filter((passenger) => passenger.type !== "INF")
        .map((passenger): ApiPassengerUpdate => {
            return {
                Address: postAddress(passenger)
                    ? {
                          AddressLine1: passenger.addressAddressLine1 || "",
                          City: passenger.addressCity || "",
                          CompanyName: "",
                          CountryCode: passenger.addressCountry || "",
                          CultureCode: data.culture,
                          EmailAddress: passenger.email || "",
                          OfficePhone: passenger.officePhoneNumber
                              ? concatenateString([passenger.officePhonePrefix, passenger.officePhoneNumber])
                              : "",
                          Phone: passenger.phoneNumber
                              ? concatenateString([passenger.phonePrefix, passenger.phoneNumber])
                              : "",
                          PostalCode: passenger.addressPostalCode || "",
                          StationCode: "",
                      }
                    : null,
                ContactPersonInfo: postContact(passenger)
                    ? {
                          AddressLine1: passenger.contactAddressAddressLine1 || "",
                          City: passenger.contactAddressCity || "",
                          CompanyName: passenger.contactLastName
                              ? concatenateString([passenger.contactFirstName, passenger.contactLastName])
                              : "",
                          CountryCode: "",
                          CultureCode: data.culture,
                          EmailAddress: passenger.contactEmail || "",
                          OfficePhone: passenger.contactOfficePhoneNumber
                              ? concatenateString([
                                    passenger.contactOfficePhonePrefix,
                                    passenger.contactOfficePhoneNumber,
                                ])
                              : "",
                          Phone: passenger.contactPhoneNumber
                              ? concatenateString([passenger.contactPhonePrefix, passenger.contactPhoneNumber])
                              : "",
                          PostalCode: passenger.contactAddressPostalCode || "",
                          StationCode: passenger.departureStationCode || data.departureStationCode,
                      }
                    : null,
                DateOfBirth: getPassengerDateOfBirth(passenger),
                Gender: passenger.gender || "Male",
                Name: { FirstName: passenger.firstName, LastName: passenger.lastName },
                Nationality: passenger.country || "",
                PassengerNumber: passenger.passengerNumber,
                TravelDocuments: filterPassports(passenger, data.documents).map((document) =>
                    mapToApiPassengerTravelDocument({
                        ...data,
                        document,
                        passenger,
                    }),
                ),
            };
        }),
    InfantUpdates: data.passengers
        .filter((passenger) => passenger.type === "INF")
        .map(
            (passenger): ApiInfantUpdate => ({
                AttachedPassengerNumber: passenger.attachedPassengerNumber,
                Name: { FirstName: passenger.firstName, LastName: passenger.lastName },
                DateOfBirth: passenger.dateOfBirth?.format(DEFAULT_DATE_FORMAT),
                Gender: passenger.gender || "Male",
                Nationality: passenger.country || "",
                TravelDocuments: filterPassports(passenger, data.documents).map((document) =>
                    mapToApiPassengerTravelDocument({
                        ...data,
                        document,
                        passenger,
                    }),
                ),
            }),
        ),
});
