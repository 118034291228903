import {
    mapToPeruCompraCancelledReservations,
    mapToPeruCompraReservations,
} from "../../../component-mappers/PeruCompraReservationMapper";
import { CugReservation, CugBookings } from "../../../component-models/CUG2b/CugBookings";
import {
    ApiPeruCompraCancelledReservationModel,
    ApiPeruCompraCancelledReservationsModel,
} from "../../../component-models/peru-compra/ApiPeruCompraCancelledReservationModel";
import {
    ApiPeruCompraReservationModel,
    ApiPeruCompraReservationsModel,
    PCRABookingStatus,
} from "../../../component-models/peru-compra/ApiPeruCompraReservationModel";
import { PeruCompraCancelledReservationModel } from "../../../component-models/peru-compra/PeruCompraCancelledReservationModel";
import { PeruCompraReservationModel } from "../../../component-models/peru-compra/PeruCompraReservationModel";
import { GridState } from "../../../dc-components/dc-table-models";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import { ROUTES } from "../../../shared/apiRoutes";
import { DEFAULT_DATE_FORMAT } from "../../../shared/commonConstants";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import * as dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);

export const usePortalReservationsUtils = () => {
    const cug2AppContext = useCug2AppContext();
    const { ajaxJsonRequest } = useAjax();

    // HELPERS

    const remapToApiPeruCompraReservationModelProperties = (
        gridState: GridState<keyof PeruCompraReservationModel>,
    ): keyof ApiPeruCompraReservationModel => {
        return gridState.orderBy === "Id"
            ? "DepartureDate"
            : gridState.orderBy === "AdminId"
              ? "DNI"
              : gridState.orderBy === "MemberId"
                ? "RUC"
                : gridState.orderBy === "OperationType"
                  ? "BookingType"
                  : gridState.orderBy === "PNR"
                    ? "ReservationCode"
                    : gridState.orderBy === "TotalAmount"
                      ? "FormattedTotalAmount"
                      : gridState.orderBy === "FirstName"
                        ? "PassengerFirstName"
                        : gridState.orderBy === "LastName"
                          ? "PassengerLastName"
                          : gridState.orderBy === "PassengerDocumentId"
                            ? "PassengerId"
                            : gridState.orderBy;
    };

    const remapToApiPeruCompraCancelledReservationModelProperties = (
        gridState: GridState<keyof PeruCompraCancelledReservationModel>,
    ): keyof ApiPeruCompraCancelledReservationModel => {
        return gridState.orderBy === "Id"
            ? "DepartureDate"
            : gridState.orderBy === "AdminId"
              ? "DNI"
              : gridState.orderBy === "MemberId"
                ? "RUC"
                : gridState.orderBy === "OperationType"
                  ? "BookingType"
                  : gridState.orderBy === "PNR"
                    ? "ReservationCode"
                    : gridState.orderBy === "RefundAmount"
                      ? "FormattedRefundAmount"
                      : gridState.orderBy === "FirstName"
                        ? "PassengerFirstName"
                        : gridState.orderBy === "LastName"
                          ? "PassengerLastName"
                          : gridState.orderBy === "PassengerDocumentId"
                            ? "PassengerId"
                            : gridState.orderBy;
    };

    const loadConfirmedPeruCompraData = async (requestUrl: string, container: HTMLElement) => {
        const result = await ajaxJsonRequest<ApiPeruCompraReservationsModel>({
            nonCancellable: true,
            container,
            method: "GET",
            url: requestUrl,
        });

        return mapToPeruCompraReservations(result?.data);
    };

    const loadCancelledPeruCompraData = async (requestUrl: string, container: HTMLElement) => {
        const result = await ajaxJsonRequest<ApiPeruCompraCancelledReservationsModel>({
            nonCancellable: true,
            container,
            method: "GET",
            url: requestUrl,
        });

        return mapToPeruCompraCancelledReservations({
            OriginalParams: result?.data.OriginalParams,
            TotalCount: result?.data.TotalCount,
            Journeys: result?.data.Journeys.map((item) => {
                return { ...item, Id: `${item.TicketNumber}${item.Origin}${item.Destination}` };
            }),
        });
    };

    const getRouteForPcra = (bookingStatus: PCRABookingStatus) => {
        switch (bookingStatus) {
            case "Confirmed":
                return ROUTES.ApiRoutes.PeruCompraReservations;
            case "Cancelled":
                return ROUTES.ApiRoutes.PeruCompraCancelledReservations;
            // TODO: Is there any use of this case anywhere?
            case "Hold":
                return "";
        }
    };

    // EXPORTS

    const getReportUrl = (apiUrl: string, orgCode: string) =>
        apiUrl
            .replace("{0}", cug2AppContext.Country.toLowerCase())
            .replace("{1}", cug2AppContext.Language)
            .replace("{2}", encodeURIComponent(btoa(orgCode)));

    const loadCugData = async (
        data: {
            bookingStatus: "Confirmed" | "Hold";
            gridState: GridState<keyof CugReservation>;
        },
        container: HTMLElement,
        filterForDates: boolean,
        filterFlightDateFrom?: dayjs.Dayjs,
        filterFlightDateTo?: dayjs.Dayjs,
        filterPurchaseDateFrom?: dayjs.Dayjs,
        filterPurchaseDateTo?: dayjs.Dayjs,
    ) => {
        // DEVNOTE Only submit filter params to BE if the user has at least once clicked Search next to the datepickers
        const considerDateFiltering = data.bookingStatus === "Confirmed" && filterForDates;

        const result = await ajaxJsonRequest<CugBookings>({
            nonCancellable: true,
            container,
            method: "GET",
            url:
                `${ROUTES.ApiRoutes.AgencyReservations}/` +
                `?bookingStatus=${data.bookingStatus}` +
                `&pageSize=${data.gridState.pageSize}` +
                `&page=${data.gridState.pageIndex}` +
                (data.gridState.globalFilterExpression
                    ? `&searchExpression=${data.gridState.globalFilterExpression}`
                    : ``) +
                (considerDateFiltering && filterFlightDateFrom
                    ? `&flightDateFrom=${filterFlightDateFrom.format(DEFAULT_DATE_FORMAT)}`
                    : ``) +
                (considerDateFiltering && filterFlightDateTo
                    ? `&flightDateTo=${filterFlightDateTo.format(DEFAULT_DATE_FORMAT)}`
                    : ``) +
                (considerDateFiltering && filterPurchaseDateFrom
                    ? `&purchaseDateFrom=${filterPurchaseDateFrom.format(DEFAULT_DATE_FORMAT)}`
                    : ``) +
                (considerDateFiltering && filterPurchaseDateTo
                    ? `&purchaseDateTo=${filterPurchaseDateTo.format(DEFAULT_DATE_FORMAT)}`
                    : ``) +
                `&orderBy=${data.gridState.orderBy === "FormattedFlightDate" ? "FlightDate" : data.gridState.orderBy}` +
                `&TimeZoneOffsetMin=${(new Date().getTimezoneOffset() * -1).toString()}`,
        });

        return {
            totalCount: result?.data.TotalCount,
            newData: result?.data.Bookings.map((item) => {
                return { ...item, Id: item.ReservationCode };
            }),
        };
    };

    const loadPeruCompraData = async (
        data: {
            bookingStatus: PCRABookingStatus;
            gridState:
                | GridState<keyof PeruCompraReservationModel>
                | GridState<keyof PeruCompraCancelledReservationModel>;
        },
        container: HTMLElement,
        filterForDates: boolean,
        filterForUser: boolean,
        filterUserId?: number,
        filterFlightDateFrom?: dayjs.Dayjs,
        filterFlightDateTo?: dayjs.Dayjs,
    ) => {
        // DEVNOTE Only submit filter params to BE if the user has at least once clicked Search next to the datepickers
        const considerDateFiltering = data.bookingStatus === "Confirmed" && filterForDates;
        const considerUserFiltering = data.bookingStatus === "Confirmed" && filterForUser;
        const maskedBookingStatus = data.bookingStatus === "Cancelled" ? "Closed" : data.bookingStatus;

        const requestUrl =
            `${getRouteForPcra(data.bookingStatus)}/` +
            `?bookingStatus=${maskedBookingStatus}` +
            `&pageSize=${data.gridState.pageSize}` +
            `&page=${data.gridState.pageIndex}` +
            (considerUserFiltering && filterUserId ? `&agentId=${filterUserId}` : ``) +
            (considerDateFiltering && filterFlightDateFrom
                ? `&journeyDateFrom=${filterFlightDateFrom.format(DEFAULT_DATE_FORMAT)}`
                : ``) +
            (considerDateFiltering && filterFlightDateTo
                ? `&journeyDateTo=${filterFlightDateTo.format(DEFAULT_DATE_FORMAT)}`
                : ``) +
            `&orderBy=${
                data.bookingStatus === "Confirmed"
                    ? remapToApiPeruCompraReservationModelProperties(
                          data.gridState as GridState<keyof PeruCompraReservationModel>,
                      )
                    : remapToApiPeruCompraCancelledReservationModelProperties(
                          data.gridState as GridState<keyof PeruCompraCancelledReservationModel>,
                      )
            }` +
            `&orderDirection=${data.gridState.orderDir}` +
            `&TimeZoneOffsetMin=${(new Date().getTimezoneOffset() * -1).toString()}`;

        switch (data.bookingStatus) {
            case "Confirmed":
                return loadConfirmedPeruCompraData(requestUrl, container);

            case "Cancelled":
                return loadCancelledPeruCompraData(requestUrl, container);
            // TODO: Is there any use of this case anywhere?
            case "Hold":
                return undefined;
        }
    };

    return {
        getReportUrl,
        loadCugData,
        loadPeruCompraData,
    };
};
