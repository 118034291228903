import i18next from "i18next";
import { html } from "lit-html";
import { OUTBOUND } from "../../../../shared/commonConstants";
import { BaggageSection } from "../../../../component-models/baggage/BaggageSection";
import { BaggagePageState } from "../../../../component-models/baggage/BaggagePageState";
import { perJourneyPerPaxViewPaxCaption, paxLabel, paxAmount } from "../../../../component-helpers/BaggageHelper";
import { getTestId, TestIdDictionary as T } from "../../../../testing-helpers/TestIdHelper";
import { PerJourneyPerPaxPassengerDto } from "./per-journey-per-pax-passenger";
import { usePerJourneyPerPaxSoldOut } from "./usePerJourneyPerPaxSoldOut";
import { usePerJourneyPerPaxBundle } from "./usePerJourneyPerPaxBundle";
import classNames from "classnames";
import { useBookingContext } from "../../../../managers/useBookingContext";
import { useCallback } from "haunted";
import { BagType } from "../useBaggagePage";
import { BaggageSectionStateJourney } from "../../../../component-models/baggage/BaggageSectionStateJourney";
import { BaggageSectionJourney } from "../../../../component-models/baggage/BaggageSectionJourney";
import { BaggageSectionJourneyPassenger } from "../../../../component-models/baggage/BaggageSectionJourneyPassenger";
import { StaffBaggage } from "../common/useStaffBaggage";
import { useMemo } from "../../../../shared/haunted/CustomHooks";
import { perJourneyPerPaxFreeCabin } from "./perJourneyPerPaxFreeCabin";
import { perJourneyPerPaxPaidCabin } from "./perJourneyPerPaxPaidCabin";
import { perJourneyPerPaxFreeChecked } from "./perJourneyPerPaxFreeChecked";
import { perJourneyPerPaxPaidChecked } from "./perJourneyPerPaxPaidChecked";
import { useBaggageUtils } from "../common/useBaggageUtils";
import { BancoEstadoBaggage } from "../common/useBancoEstadoBaggage";

export interface Props {
    baggageSection: BaggageSection;
    bagType: BagType;
    bancoEstadoBaggage: BancoEstadoBaggage;
    pageState: BaggagePageState;
    staffBaggage: StaffBaggage;
}

export const useMobilePerJourneyPerPaxTable = (props: Props) => {
    const bookingContext = useBookingContext();

    const { formattedLowestNextPrice } = useBaggageUtils();

    const bundle = usePerJourneyPerPaxBundle({
        baggageSection: props.baggageSection,
        bagType: props.bagType,
        isMobile: true,
    });

    const soldOut = usePerJourneyPerPaxSoldOut({
        baggageSectionJourneys: props.baggageSection.journeys,
        bagType: props.bagType,
        isMobile: true,
    });

    const isResetAvailable = useMemo(
        () =>
            !props.baggageSection.canGetFreeBancoEstadoBaggage &&
            props.baggageSection.perBooking.isResetAvailable({
                baggageSectionJourneys: props.baggageSection.journeys,
                bagType: props.bagType,
                canGetFreeBancoEstadoBaggage: props.baggageSection.canGetFreeBancoEstadoBaggage,
                isCheckinClosedInbound: bookingContext.isCheckinClosedInbound,
                isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
                paxCount: bookingContext.adultsCount + bookingContext.childrenCount,
            }),
        [props.bagType, props.baggageSection.journeys, bookingContext],
    );

    const unformattedLowestNextPrice = useCallback(
        (isOriginal: boolean, isMinimum: boolean) =>
            props.baggageSection.perBooking.nextPrice({
                baggageSectionJourneys: props.baggageSection.journeys,
                bagType: props.bagType,
                bancoEstadoBaggage: props.bancoEstadoBaggage,
                isMinimum,
                isOriginal,
                staffBaggage: props.staffBaggage,
            }),
        [props.bagType, props.baggageSection.journeys, props.staffBaggage, props.bancoEstadoBaggage],
    );

    const freeCabinIllustration = perJourneyPerPaxFreeCabin();

    const paidCabinIllustration = perJourneyPerPaxPaidCabin({
        isInBundle: props.baggageSection.journeys.every((journey) => journey.bundleType !== "None"),
        isDiscountBooking: bookingContext.isDiscountBooking,
        unformattedNextPrice: unformattedLowestNextPrice(false, true),
        formattedNextPrice: formattedLowestNextPrice(unformattedLowestNextPrice(false, true)),
        unformattedOriginalPrice: unformattedLowestNextPrice(true, true),
        formattedOriginalPrice: formattedLowestNextPrice(unformattedLowestNextPrice(true, true)),
    });

    const freeCheckedIllustration = perJourneyPerPaxFreeChecked();

    const paidCheckedIllustration = perJourneyPerPaxPaidChecked({
        isDiscountBooking: bookingContext.isDiscountBooking,
        unformattedNextPrice: unformattedLowestNextPrice(true, false),
        formattedNextPrice: formattedLowestNextPrice(unformattedLowestNextPrice(true, false)),
        unformattedOriginalPrice: unformattedLowestNextPrice(false, true),
        formattedOriginalPrice: formattedLowestNextPrice(unformattedLowestNextPrice(false, false)),
    });

    const isAnyPaxOpenInThisJourney = (journey: BaggageSectionStateJourney) => journey.passengers.some((p) => p.isOpen);

    // Event handlers

    const handleJourneyClick = (e: MouseEvent, journey: BaggageSectionStateJourney) => {
        e.preventDefault();
        e.stopPropagation();

        if (journey.isOpen) {
            props.baggageSection.sectionUi.closeJourney(journey.index);
        } else {
            props.baggageSection.sectionUi.openJourney(journey.index, props.bagType === "cabinBaggage");
        }
    };

    const handleViewSwitch = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        // FIXME reset is async, so the order is important here
        await props.baggageSection.reset();
        props.baggageSection.sectionUi.closePerJourneyPerPaxView();
    };

    // Templates

    const viewSwitchButtonTemplate = () => {
        const buttonText =
            paxAmount(props.baggageSection.sectionUi.uiState) === 1
                ? i18next.t("Volver a la selección para todos los tramos")
                : i18next.t("Volver a la selección para todos los pasajeros");

        return isResetAvailable
            ? html`
                  <div
                      class="b2-reset-button"
                      data-test-id=${getTestId(T.BAGGAGE.CLOSE_PER_JOURNEY_PER_PAX_VIEW_BUTTON, {
                          c: props.bagType,
                          m: true,
                      })}
                      @click=${handleViewSwitch}
                  >
                      ${buttonText}
                  </div>
              `
            : "";
    };

    const journeysTemplate = () => html`
        <div class="b2m-journeys-container">
            <div class="b2m-journeys">${props.baggageSection.sectionUi.uiState.journeys.map(journeyTemplate)}</div>
        </div>
    `;

    const journeyTemplate = (stateJourney: BaggageSectionStateJourney) => {
        const contextJourney = props.baggageSection.journeys.find((journey) => journey.index === stateJourney.index);
        const directionText = stateJourney.index === OUTBOUND ? i18next.t("Ida") : i18next.t("Vuelta");

        return html`
            <div
                class=${classNames("b2m-journey-heading", { open: stateJourney.isOpen })}
                data-test-value=${stateJourney.isOpen}
                data-test-id=${getTestId(T.BAGGAGE.JOURNEY_OPENER, {
                    j: stateJourney.index,
                    c: props.bagType,
                })}
                @click=${(e: MouseEvent) => handleJourneyClick(e, stateJourney)}
            >
                <i class="js-icon-bag js-bag-plane-take-off"></i>
                <span class="uppercase">${directionText}</span>
                <span>&rarr;</span>
                <span>${contextJourney.departureStationName} - ${contextJourney.arrivalStationName}</span>
                <i class="js-icon js-circle-chevron-right"></i>
            </div>
            <div
                class="b2m-journey-details"
                data-test-id=${getTestId(T.BAGGAGE.PER_JOURNEY_PER_PAX_PASSENGERS, {
                    j: stateJourney.index,
                    c: props.bagType,
                    m: true,
                })}
            >
                ${soldOut.htmlTemplate(contextJourney.index)} ${journeyBundleTemplate(stateJourney)}
                ${contextJourney.passengers.map((pax) => passengerTemplate(contextJourney, pax))}
            </div>
        `;
    };

    const journeyBundleTemplate = (journey: BaggageSectionStateJourney) =>
        isAnyPaxOpenInThisJourney(journey) ? bundle.htmlTemplate(journey.index) : "";

    const passengerTemplate = (journey: BaggageSectionJourney, pax: BaggageSectionJourneyPassenger) => {
        const dto: PerJourneyPerPaxPassengerDto = {
            baggageSection: props.baggageSection,
            bagType: props.bagType,
            canGetFirstBaggageStaffDiscount: props.staffBaggage.canGetFirstBaggageStaffDiscount({
                bagType: props.bagType,
                journeyIndex: journey.index,
                paxIndex: pax.index,
            }),
            canGetFirstBaggageBancoEstadoDiscount:
                pax.index === 0 &&
                props.bancoEstadoBaggage.canGetFirstBaggageBancoEstadoDiscount({
                    bagType: props.bagType,
                    journeyIndex: journey.index,
                }),
            isMobile: true,
            journey,
            pageState: props.pageState,
            passenger: pax,
        };

        return html` <ac-per-journey-per-pax-passenger .dto=${dto}></ac-per-journey-per-pax-passenger> `;
    };

    const cabinIllustrationTemplate = () =>
        props.bagType === "cabinBaggage"
            ? html`
                  <div class="b2m-per-pax-container">
                      ${freeCabinIllustration.htmlTemplate()}${paidCabinIllustration.htmlTemplate()}
                  </div>
              `
            : "";

    const checkedIllustrationTemplate = () =>
        props.bagType === "checkedBaggage"
            ? html` <div class="b2m-per-pax-container">
                  ${freeCheckedIllustration.htmlTemplate()}${paidCheckedIllustration.htmlTemplate()}
              </div>`
            : "";

    const htmlTemplate = () =>
        props.baggageSection.sectionUi.uiState.perJourneyPerPaxState === "open"
            ? html`
                  ${cabinIllustrationTemplate()} ${checkedIllustrationTemplate()}
                  <div class="b2m-view per-pax">
                      <div class="b2m-info-amount">
                          <div class="b2m-view-info">
                              ${perJourneyPerPaxViewPaxCaption(paxAmount(props.baggageSection.sectionUi.uiState))}
                          </div>
                          <div class="b2m-pax-amount">
                              ${paxAmount(props.baggageSection.sectionUi.uiState)}
                              ${paxLabel(paxAmount(props.baggageSection.sectionUi.uiState))}
                          </div>
                      </div>
                      ${viewSwitchButtonTemplate()}
                  </div>
                  ${journeysTemplate()}
              `
            : html``;

    return { htmlTemplate };
};
