import { TemplateResult, html } from "lit-html";
import DomCrawlingHelper from "./DomCrawlingHelper";
import {
    DEFAULT_TAX_NUMBER_CODE,
    countryIndividualTaxnumberDictionary,
    countryTaxnumberDictionary,
    ALLOWED_SPECIAL_KEYS,
    BRASILIAN_CULTURE_CODE,
    USA_CULTURE_CODE,
    ADDRESS_CHARS_REGEX,
    DEFAULT_DATE_FORMAT,
    PaxType,
    PERUVIAN_SOL_CODE,
    PERUVIAN_SOL_CODE_ALTERNATIVE,
    DEBOUNCE_TIME,
    NON_GROUP_BOOKING_MAX_PAX,
    FAKE_COUNTRY_CODE,
} from "./commonConstants";
import { LOADER_CLASS_NAMES } from "./LoaderClassNames";
import { ROUTES } from "./apiRoutes";
import { commonDebug } from "../bootstrap";
import dayjs from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import LoginResult from "../component-models/LoginResult";
import { Region } from "../components/register/register";
import { ScrollHelper } from "./ScrollHelper";
import { LocalizedString } from "../component-models/LocalizedString";
import i18next from "i18next";
import { DEFAULT_USER_CONTEXT as DUMMY_USER_CONTEXT, UserContext } from "../component-models/app/UserContext";
import { FloorplanDictionary } from "../component-models/seatmap/FloorplanDictionary";
import { normalizeCulture } from "./localeHelper";
import { CultureDependentString } from "../component-models/CultureDependentString";
import { insensitiveEquals } from "../component-helpers/stringHelper";
import { ApiInternationalCallingCodeWithCountry } from "../component-models/ApiInternationalCallingCodeWithCountry";
import { BookingContext } from "../component-models/app/BookingContext";

export interface SearchRequest {
    o1: any;
    d1: any;
    dd1: any;
    dd2?: any;
    ADT: any;
    CHD: any;
    inl: any;
    r: boolean;
    s: boolean;
    mon: boolean;
    cur: string;
    culture: string;
    pc?: string;
    rc: string;
    c?: boolean;
    isRedemptionFlow?: boolean;
}

export interface FormResources {
    characters: string;
    charactersLength: string;
    emailIsNotValid: string;
    fieldIsRequired: string;
    fieldMinimumLengthIs: string;
    fieldMaximumLengthIs: string;
    fillAllFields: string;
    fieldMustHave: string;
    invalidFormat: string;
    noSpecialCharactersNumbersAllowed: string;
    numbersAllowed: string;
}

export interface SeatmapResources {
    addSeatsText: string;
    allSeatsTakenText: string;
    beSeatTooltip: string;
    checkinTooltip: string;
    confirmText: string;
    continueText: string;
    freeOfCharge: string;
    inboundText: string;
    mouseInfoText: string;
    noSeatText: string;
    noSeatsMobileText: string;
    okayLabel: string;
    outboundShortText: string;
    outboundText: string;
    passengerLabel: string;
    resetSeatText: string;
    reviewText: string;
    sameSeatsText: string;
    seatAssignText: string;
    seatAutoAssignText: string;
    seatCostsText: string;
    seatsTotalMobileText: string;
    warningText1: string;
    warningText2: string;
    warningText3: string;
    windowlessLabel: string;
    yesText: string;
    zoneLabel1: string;
    zoneLabel2: string;
}

export interface BodyToPost {
    [key: string]: string;
}

export class MaterialLabelElement extends HTMLLabelElement {
    public MaterialCheckbox: {
        enable: () => void;
        disable: () => void;
        check: () => void;
        uncheck: () => void;
    };
}

export function getFilteredBodyToPost(unfilteredBody: BodyToPost) {
    const filteredBodyToPost: BodyToPost = Object.entries(unfilteredBody).reduce((filteredObject, [key, value]) => {
        if (value) {
            filteredObject[key] = value;
        }
        return filteredObject;
    }, {} as BodyToPost);

    return filteredBodyToPost;
}

export function handleCugLoader(elem: HTMLElement | string, id: string, noPlane = false) {
    if (!window.currentCugLoader) {
        window.currentCugLoader = {};
    }

    if (!window.currentCugLoader[id]) {
        window.currentCugLoader[id] = showCugLoader(elem, noPlane);
    } else {
        hideLoader(window.currentCugLoader[id]);
        window.currentCugLoader[id] = null;
        const elems = DomCrawlingHelper.getArrayOfClass(document.body, "RTloader-parentoverflow");
        elems.forEach((elem) => elem.classList.remove("RTloader-parentoverflow"));
    }
}

export function showCugLoader(elem: HTMLElement | string, noPlane = false) {
    if (typeof elem === "string") {
        elem = document.body.querySelector(`.${elem}`) as HTMLElement;
    }

    elem.classList.add(LOADER_CLASS_NAMES.TemporaryLoaderParent);
    const querySelector = `.${LOADER_CLASS_NAMES.TemporaryLoaderParent}`;
    const loader = window.newRtLoader(querySelector, noPlane);

    if (loader) {
        loader.startLoader();
    }

    elem.classList.remove(LOADER_CLASS_NAMES.TemporaryLoaderParent);

    return loader;
}

export function showLoader(data: {
    name?: string;
    container?: HTMLElement;
    noPlane?: boolean;
    scrollToLoader?: boolean;
}): JsLoader {
    const newLoaderName = data.name || LOADER_CLASS_NAMES.CommonLoaderWrapper;

    data.container?.classList.add(LOADER_CLASS_NAMES.TemporaryLoaderParent);

    const querySelector = data.container
        ? `.${LOADER_CLASS_NAMES.TemporaryLoaderParent} .${newLoaderName}`
        : `.${newLoaderName}`;

    const loader = window.newRtLoader(querySelector, data.noPlane);

    if (loader) {
        loader.startLoader();
    }

    if (data.scrollToLoader) {
        data.container?.scrollIntoView({ behavior: "smooth", block: "end" });
    }

    data.container?.classList.remove(LOADER_CLASS_NAMES.TemporaryLoaderParent);

    return loader;
}

export function hideLoader(loader: JsLoader): void {
    try {
        loader.stopLoader();
        loader.destroy();
    } catch (e) {
        // commonDebug.info("Loader already removed.");
    }
}

export function getCoords(elem: HTMLElement) {
    // crossbrowser version
    const box = elem.getBoundingClientRect();

    const body = document.body;
    const docEl = document.documentElement;

    const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
    const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

    const clientTop = docEl.clientTop || body.clientTop || 0;
    const clientLeft = docEl.clientLeft || body.clientLeft || 0;

    const top = box.top + scrollTop - clientTop;
    const left = box.left + scrollLeft - clientLeft;

    return { top: Math.round(top), left: Math.round(left) };
}

// export const SHOW_ACCUMULATED_PRICE_FOR_BAGS = false;

export function toBoolean(value: string | boolean): boolean {
    if (!value) {
        return false;
    }
    return (typeof value !== "boolean" && value.toLowerCase() === "true") || (typeof value === "boolean" && value);
}

export function returnValueIfString(value: any): string {
    return typeof value === "string" && value ? value : "";
}

export function getRequestBodyFromObject<T>(obj: T, parentKey?: string): any {
    return Object.keys(obj).reduce((body, key) => {
        const newObject = obj[key as keyof T];
        if (newObject === null) return body;
        const newKey = parentKey ? (/^\d+$/.test(key) ? `${parentKey}[${key}]` : `${parentKey}.${key}`) : key;
        if (typeof newObject === "object") {
            return { ...body, ...getRequestBodyFromObject({ ...newObject }, newKey) };
        }

        return newObject || (newObject as any) === 0 ? { ...body, [newKey]: newObject } : body;
    }, {});
}

export function getRequestBodyFromInputs(form: HTMLFormElement): {} {
    const inputs = Array.from(form.querySelectorAll("input, select, textarea")) as HTMLInputElement[];
    return inputs.reduce((aggr, curr) => {
        (aggr as any)[curr.name] = curr.value;
        return aggr;
    }, {});
}

export function getRequestBodyFromNamedInputs(form: HTMLFormElement, filterValueless?: boolean): {} {
    const inputs = Array.from(form.querySelectorAll("input[name], select[name], textarea[name]")) as HTMLInputElement[];
    return inputs.reduce((aggr, curr) => {
        if (curr.value || !filterValueless) {
            (aggr as any)[curr.name] = curr.value;
        }
        return aggr;
    }, {});
}

export function padWithLeadingZeros(text: string): string {
    text = "00" + text;
    return text.substr(text.length - 2);
}

export function timeZoneOffsetter(date: Date): string {
    date.setHours(0, -date.getTimezoneOffset(), 0, 0);
    return date.toISOString().slice(0, 10);
}

export const getParsedProperty = <T>(data: any): T => JSON.parse(data as unknown as string) as T;

export const getParsedKeyValueOptionsProperty = (data: any): SelectOption[] =>
    JSON.parse(data as unknown as string).map((item: { Value: string; Key: string }) => ({
        Text: item.Value,
        Value: item.Key,
    }));

export const getParsedTextValueOptionsProperty = (data: any): SelectOption[] =>
    JSON.parse(data as unknown as string).map(
        (item: { Value: string; Text: string; Selected?: boolean; IsSelected?: boolean }): SelectOption => ({
            Text: item.Text,
            Value: item.Value,
            IsSelected: item.IsSelected || item.Selected,
        }),
    );

export function updateMdl(): void {
    window.setTimeout(() => {
        if ((window as any).componentHandler) {
            (window as any).componentHandler.upgradeDom();
        }
    }, 0);
}

export function getTaxnumberCodeByCountry(countryCode: string, individual?: boolean): string {
    let code = DEFAULT_TAX_NUMBER_CODE;

    if (!countryCode) {
        return code;
    }

    const dict = individual ? countryIndividualTaxnumberDictionary : countryTaxnumberDictionary;

    Object.keys(dict).forEach((k: string) => {
        if (dict.hasOwnProperty(k)) {
            const countries = (dict as any)[k] as string[];
            if (countries.indexOf(countryCode.toUpperCase()) > -1) {
                code = k;
            }
        }
    });

    return code;
}

export function sanitizeRutFieldValue(field: HTMLInputElement): string {
    const caretPosition = field.selectionStart;
    let newValue = Array.from(field.value)
        .filter(
            (char) =>
                (char !== "-" && char !== " " && Number(char) <= 9 && Number(char) >= 0) || char.toLowerCase() === "k",
        )
        .join("");
    let length = newValue.length;

    if (length > 9) {
        newValue = newValue.substr(0, 9);
        length = 9;
    }

    field.value = length > 1 ? `${newValue.substr(0, length - 1)}-${newValue.substr(length - 1)}` : newValue;

    //DEVNOTE JET-9870 - Defer setting the caret position and check activ element to ensure it works on iOS
    window.setTimeout(() => {
        if (document.activeElement === field) {
            const newCaretPosition = length === 2 ? caretPosition + 1 : caretPosition;
            field.setSelectionRange(newCaretPosition, newCaretPosition);
        }
    }, 0);

    return field.value;
}

export interface CommonRegion {
    CountryCode: string;
    RegionName: string;
}

export type InputType = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

export interface Currency {
    CurrencyCode: string;
    Description: string;
}

export function getUrlVars(): any {
    const splitPoint = window.location.href.indexOf("?");
    if (splitPoint === -1) {
        return {};
    }

    const vars: any = {};
    const hashes = window.location.href.slice(splitPoint + 1).split("&");
    for (const hash of hashes) {
        const [varKey, varValue] = hash.split("=");
        if (varKey && varValue) {
            if (vars[varKey]) {
                vars[varKey].push(varValue);
            } else {
                vars[varKey] = [varValue];
            }
        } else if (varKey && !varValue) {
            if (vars[varKey]) {
                vars[varKey].push("");
            } else {
                vars[varKey] = [""];
            }
        }
    }

    return vars;
}

export function getUrlVarsAsQueryString(exceptions: string[]): string {
    const currentVars = getUrlVars();
    const vars = Object.keys(currentVars)
        .reduce(
            (queryString, varName) =>
                exceptions.includes(varName)
                    ? queryString
                    : `${queryString}${currentVars[varName]
                          .map((value: string) => `&${varName}=${value ? value : ""}`)
                          .join("")}`,
            "",
        )
        .substring(1);

    return vars;
}

export function isKeyNumeric(event: KeyboardEvent, allowSpaceAndPlus: boolean): boolean {
    return (
        ALLOWED_SPECIAL_KEYS.indexOf(event.key) > -1 ||
        (event.key === "v" && event.ctrlKey) ||
        (event.key === "x" && event.ctrlKey) ||
        (event.key === "c" && event.ctrlKey) ||
        /^\d$/.test(event.key) ||
        (allowSpaceAndPlus && (event.key === "+" || event.key === " "))
    );
}

export function sanitizeRuc(inputField: HTMLInputElement) {
    const newValue = inputField.value
        .split("")
        .filter((char) => /^\d+$/.test(char))
        .join("")
        .substring(0, 11);

    inputField.value = newValue;
}

export function sanitizePhoneNumber(value: string, allowSpaceAndPlus: boolean): string {
    const regex = allowSpaceAndPlus ? /^[\d\s\+]$/ : /^\d$/;

    return value
        .split("")
        .filter((char, i) => regex.test(char) && (char !== "+" || i === 0) && (char !== " " || i !== 0))
        .join("");
}

export function sanitizeRefundInfoInput(value: string): string {
    const regex = /^([a-zA-Z0-9-\s])$/;

    return value
        .split("")
        .filter((char) => regex.test(char))
        .join("");
}

export function sanitizeAddress(value: string): string {
    return value
        .split("")
        .filter((char) => ADDRESS_CHARS_REGEX.test(char))
        .join("");
}

export function removeEmojis(value: string): string {
    const regex =
        /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    return value.replace(regex, "");
}

export const handleNumericInputChange = (e: KeyboardEvent) => {
    if (
        ALLOWED_SPECIAL_KEYS.indexOf(e.key) > -1 ||
        (e.key === "v" && e.ctrlKey) ||
        (e.key === "x" && e.ctrlKey) ||
        (e.key === "c" && e.ctrlKey) ||
        /^\d$/.test(e.key)
    ) {
        return true;
    } else {
        e.preventDefault();
        return false;
    }
};

export const isStringFullyNumeric = (message: string): boolean => {
    const regex = /^[0-9]+$/;
    return regex.test(message);
};

export const getCultureDependentStringValue = (value: CultureDependentString, culture: string): string => {
    if (!value) return "";

    return value[culture.toUpperCase().replace("-", "_") as keyof typeof value] || value.ES_CL || "";
};

export function getLocalizedStringValue(value: LocalizedString, culture: string): string {
    if (!value) {
        return "";
    }

    if (culture.toLowerCase() === BRASILIAN_CULTURE_CODE && value.Pt) {
        return value.Pt;
    }

    if (culture.toLowerCase() === USA_CULTURE_CODE && value.En) {
        return value.En;
    }

    return value.Es;
}

export function clone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

export function getAntiforgerySegment(antiForgeryToken: string): DocumentFragment {
    return document.createRange().createContextualFragment(antiForgeryToken);
}

export interface SelectOption {
    Text: string;
    Value: string;
    IsSelected?: boolean;
    TestId?: string;
    IsDisabled?: boolean;
}

export interface RedoSearchData {
    isOneWay: boolean;
    origin: string;
    destination: string;
    unformattedOutboundDate: string;
    adults: string;
    children: string;
    infants: string;
    unformattedInboundDate: string;
    promoCode: string;
    culture: string;
    currency: string;
    isCalendarView?: boolean;
    isRedemption?: boolean;
}

export function redoSearch(data: RedoSearchData) {
    const countryCode = data.culture.split("-", 2)[1];

    let req: SearchRequest = {
        o1: data.origin,
        d1: data.destination,
        dd1: data.unformattedOutboundDate,
        ADT: data.adults,
        CHD: data.children,
        inl: data.infants,
        r: false,
        s: !data.isCalendarView,
        mon: true,
        cur: data.currency,
        culture: normalizeCulture(data.culture),
        rc: countryCode.toUpperCase(),
    };
    if (!data.isOneWay) {
        req = {
            ...req,
            dd2: data.unformattedInboundDate,
            r: true,
        };
    }

    if (data.promoCode && typeof data.promoCode === "string") {
        req = {
            ...req,
            pc: data.promoCode,
        };
    }

    if (data.isCalendarView) {
        req = {
            ...req,
            c: true,
        };
    }
    if (data.isRedemption) {
        req = {
            ...req,
            isRedemptionFlow: data.isRedemption,
        };
    }

    const requestUrl = getRequestUrl(req);

    showLoader({});

    window.location.href = `${ROUTES.InternalSelect}${requestUrl}`;
}

function getRequestUrl(queryParams: any) {
    let first = true;
    let relativeUrl = "";
    for (const queryValue in queryParams) {
        /* eslint-disable eqeqeq */
        if (queryParams[queryValue] != null) {
            /* eslint-enable eqeqeq */
            const value = queryParams[queryValue];
            const valueString =
                typeof value === "object"
                    ? getSerializedObject(value)
                    : queryValue + "=" + encodeURIComponent(queryParams[queryValue]);

            if (first) {
                relativeUrl += "?" + valueString;
                first = false;
            } else {
                relativeUrl += "&" + valueString;
            }
        }
    }
    return relativeUrl;
}

function getSerializedObject(object: any) {
    let value = "";
    let first = true;

    for (const property in object) {
        if (object.hasOwnProperty(property)) {
            const prop = object[property];
            if (first && prop !== undefined) {
                value += property + "=" + encodeURIComponent(prop);
                first = false;
            } else if (prop !== undefined) {
                value += "&" + property + "=" + encodeURIComponent(prop);
            }
        }
    }

    return value;
}

export function convertLoginResultToUserContext(result: LoginResult): UserContext {
    return {
        bancoEstado: {
            category: result.BancoEstado.Category,
            remainingFreeSeats: result.BancoEstado.RemainingFreeSeats,
        },
        american: DUMMY_USER_CONTEXT.american,
        chileCompra: {
            availableAmount: 0,
            role: "none",
        },
        cug: {
            isAdminOrSupervisor: result.Cug.IsAdminOrSupervisor,
            isAgent: result.Cug.IsAgent,
            isMember: result.Cug.IsMember,
            orgCode: result.Cug.OrgCode,
            orgCurrency: result.Cug.OrgCurrency,
            orgName: result.Cug.OrgName,
        },
        dc: {
            canBuyMembership: !result.DC.HasGroupMembership && !result.Cug.IsMember && !result.IsStaffMember,
            dcLevel: result.DC.DcLevel,
            groupPriceFormatted: result.DC.GroupPriceFormatted,
            hasGroupMembership: result.DC.HasGroupMembership,
            hasMembership: result.DC.IsMember,
            hasStandardMembership: result.DC.HasStandardMembership,
            membershipDaysRemaining: result.DC.MembershipDaysRemaining,
            programNumber: result.DC.ProgramNumber,
            showWarning15: result.DC.ShowWarning15,
            showWarning30: result.DC.ShowWarning30,
            standardPriceFormatted: result.DC.StandardPriceFormatted,
            upgradePriceFormatted: result.DC.UpgradePriceFormatted,
        },
        peruCompra: {
            availableAmount: result.PeruCompra.AvailableAmount,
            role: result.PeruCompra.IsAdmin ? "admin" : result.PeruCompra.IsMember ? "member" : "none",
        },
        isFarelockDisabled: result.IsFarelockDisabled || result.Cug.IsAgent,
        isLoggedIn: result.IsLoggedIn,
        isStaff: result.IsStaffMember,
        userFirstName: result.FirstName,
        userLastName: result.LastName,
        userUserName: result.UserName,
        userProgram: result.UserProgram,
        userRole: result.UserRole,
    };
}

// DEVNOTE https://stackoverflow.com/questions/70657298/render-lit-lit-html-templateresult-as-string
export const getRenderString = (data: TemplateResult): string => {
    const { strings, values } = data;
    const v = [...values, ""];
    return strings.reduce((acc, s, i) => acc + s + v[i], "");
};

export function getAntiForgeryTokenFromHtml(htmlString: string): string {
    if (!htmlString) {
        return "";
    }

    try {
        return htmlString.split('value="')[1].split('"')[0];
    } catch (e) {
        commonDebug.error("Could not parse anti forgery token HTML.");
        return "";
    }
}

export function groupBy<T>(array: T[], key: keyof T | ((item: T) => string)): { [key: string]: T[] } {
    return typeof key === "function"
        ? array.reduce((aggr: { [key: string]: T[] }, curr: any) => {
              (aggr[key(curr)] = aggr[key(curr)] || []).push(curr);
              return aggr;
          }, {})
        : array.reduce((aggr: { [key: string]: T[] }, curr: any) => {
              (aggr[curr[key]] = aggr[curr[key]] || []).push(curr);
              return aggr;
          }, {});
}

export function chunk<T>(array: T[], size: number): T[][] {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
    }
    return chunked_arr;
}

// DEVNOTE Only works for primitive arrays, but no more is needed currently
// TODO Make this work with object or array arrays
export function areArraysTheSame<T>(array1: T[], array2: T[]): boolean {
    if ((array1 && !array2) || (!array1 && array2)) {
        return false;
    }

    return array1?.every((item) => array2?.includes(item)) && array2?.every((item) => array1?.includes(item));
}

export async function getRegionsAndComunas(): Promise<Region> {
    const headers = new Headers({
        Accept: "application/json",
    });
    const response = await fetch(`${ROUTES.Regions}`, {
        headers,
    });

    if (response.ok) {
        const regions = (await response.json()) as Region;
        return regions;
    }

    throw new Error("Network response was not ok.");
}

export function toShortDate(date?: Date) {
    if (date) {
        return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    }
    return undefined;
}

export function isMobile() {
    return ScrollHelper.getWindowWidth() < 768;
}

export function getMomentInstance(date: dayjs.Dayjs): dayjs.Dayjs {
    return date ? dayjs(date) : undefined;
}

export function getPassengerName(passengerNames: string[], paxIndex: number): string {
    return passengerNames ? passengerNames[paxIndex] : "";
}

export function getHtmlElementAttributeNames(elem: HTMLElement): string[] {
    return Array.from(elem.attributes).map((attribute) => attribute.name);
}

export function getAbbreviatedNameByPaxNumber(passengerNames: string[], paxNumber: number) {
    return getAbbreviatedPaxName(getPassengerName(passengerNames, paxNumber));
}

export function getAbbreviatedPaxName(name: string): string {
    const nameWords = name.split(" ");
    return nameWords.map((word, i) => (i < nameWords.length - 1 ? word : `${word.substring(0, 1)}.`)).join(" ");
}

export function removeAllTooltips() {
    Array.from(document.body.querySelectorAll("ac-tooltip-balloon")).forEach((e) => e.remove());
}

export function getPaxLabel(paxType: PaxType): string {
    const map = new Map<PaxType, string>([
        ["ADT", i18next.t("V2-AdultLabel")],
        ["CHD", i18next.t("V2-ChildLabel")],
        ["INF", i18next.t("V2-InfantLabel")],
    ]);

    return map.get(paxType);
}

export function convertToMoment(value: string | dayjs.Dayjs | (string | dayjs.Dayjs)[]): dayjs.Dayjs | dayjs.Dayjs[] {
    if (Array.isArray(value)) {
        return (value as (string | dayjs.Dayjs)[]).map((v: string | dayjs.Dayjs) =>
            convertToMoment(v),
        ) as dayjs.Dayjs[];
    }

    if (typeof value === "string") {
        if (value === "") {
            return undefined;
        }

        return dayjs(value, DEFAULT_DATE_FORMAT);
    }

    if (dayjs.isDayjs(value)) {
        return dayjs(value);
    }

    return undefined;
}

// https://stackoverflow.com/questions/42782645/how-to-create-guid-in-angular-2
export function getGuid(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0,
            v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function areAllPlanesIdentical(planeTypes: string[]): boolean {
    return Array.from(FloorplanDictionary.keys()).some((key) =>
        planeTypes.every((type) => Array.from(type.match(/(\d+)/))[0].indexOf(Array.from(key.match(/(\d+)/))[0]) > -1),
    );
}

export const capitalizeAndRemoveDot = (text: string): string =>
    (text.charAt(0).toUpperCase() + text.slice(1)).replace(".", "");

export function sanitizeCuit(value: string): string {
    return Array.from(value)
        .filter((char) => /^[\d\-]*$/.test(char))
        .join("")
        .trim();
}

export function getFormattedTimeDiff(a: dayjs.Dayjs, b: dayjs.Dayjs): string {
    const diffInSeconds = a.diff(b, "second");
    const hours = Math.floor(diffInSeconds / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;
    return `${padWithLeadingZeros(hours.toString())}:${padWithLeadingZeros(minutes.toString())}:${padWithLeadingZeros(
        seconds.toString(),
    )}`;
}

export const getPhonePrefixText = (country: ApiInternationalCallingCodeWithCountry) =>
    `${country.CountryName} (+${country.Code})`;

export const maskCurrenciesForDisplay = (currency: string) =>
    currency === PERUVIAN_SOL_CODE ? PERUVIAN_SOL_CODE_ALTERNATIVE : currency;

export const mapCountryCallingCodeListToSelectOptions = (
    model: ApiInternationalCallingCodeWithCountry[],
    selectedCulture?: string,
    selectedPrefix?: string,
): SelectOption[] =>
    [
        ...new Map(
            model.filter((item) => item.CountryCode !== FAKE_COUNTRY_CODE).map((item) => [item.CountryCode, item]),
        ).values(),
    ].map((countryWithCallingCode) => ({
        Value: countryWithCallingCode.Code,
        Text: getPhonePrefixText(countryWithCallingCode),
        IsSelected:
            (selectedPrefix && countryWithCallingCode.Code === selectedPrefix) ||
            (!selectedPrefix &&
                selectedCulture &&
                insensitiveEquals(countryWithCallingCode.CountryCode, selectedCulture.split("-")[1])),
    }));

export const mapCountryCallingCodeListToHtmlOptions = (
    model: ApiInternationalCallingCodeWithCountry[],
    selectedCulture?: string,
    selectedPrefix?: string,
): TemplateResult =>
    html`${mapCountryCallingCodeListToSelectOptions(model, selectedCulture, selectedPrefix).map(
        (option) => html`<option value=${option.Value} ?selected=${option.IsSelected}>${option.Text}</option>`,
    )}`;

export const debounceCallback = <T>(delayedCallback: () => Promise<T>) =>
    new Promise<T>((resolve) => window.setTimeout(resolve, DEBOUNCE_TIME, delayedCallback()));

const isElemInvisible = (elem: HTMLElement) =>
    !elem.getBoundingClientRect().width || !elem.getBoundingClientRect().height;

const shouldMoveUpInDom = (elem: HTMLElement) => {
    return isElemInvisible(elem) && elem.parentElement;
};

export const firstVisibleParent = (elem?: HTMLElement) => {
    let parent = elem?.parentElement;

    if (!parent) return undefined;

    while (shouldMoveUpInDom(parent!)) {
        parent = parent!.parentElement;
    }

    return parent || undefined;
};

// DEVNOTE INF:[0, 1], CHD:[2, 13], ADT:[14, 99]
export const ageDictionary = new Map<PaxType, { min: number; max: number }>([
    ["ADT", { min: 14, max: 99 }],
    ["CHD", { min: 2, max: 13 }],
    ["INF", { min: 0, max: 1 }],
]);

export const titleCase = (str: string) => {
    if (!str) return str;

    const splitStr = str.toLowerCase().split(" ");

    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    return splitStr.join(" ");
};

export const isInViewport = (element: HTMLElement, offsetBottom = 0) => {
    const rect = element.getBoundingClientRect();
    const html = document.documentElement;

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom + offsetBottom <= (window.innerHeight || html.clientHeight) &&
        rect.right <= (window.innerWidth || html.clientWidth)
    );
};

export const isGroupBooking = (bookingContext: BookingContext) =>
    bookingContext.adultsCount + bookingContext.childrenCount + bookingContext.infantsCount > NON_GROUP_BOOKING_MAX_PAX;
