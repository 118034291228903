import { FIRST_BAGGAGE_PRICE_FOR_STAFF_USERS, INBOUND, OUTBOUND } from "../../../../shared/commonConstants";
import { BookingContext } from "../../../../component-models/app/BookingContext";
import { BaggageSectionJourney } from "../../../../component-models/baggage/BaggageSectionJourney";
import { BaggageSectionJourneyPassenger } from "../../../../component-models/baggage/BaggageSectionJourneyPassenger";

export interface PerJourneyPerPaxChangeHandler {
    isPerJourneyPerPaxAddAvailable: (data: {
        bookingContext: BookingContext;
        journey: BaggageSectionJourney;
        passenger: BaggageSectionJourneyPassenger;
    }) => boolean;
    isPerJourneyPerPaxRemoveAvailable: (data: {
        bookingContext: BookingContext;
        journeyIndex: number;
        passenger: BaggageSectionJourneyPassenger;
    }) => boolean;
    isPerJourneyPerPaxResetAvailable: (data: {
        bookingContext: BookingContext;
        canGetFirstBaggageBancoEstadoDiscount: boolean;
        journeyIndex: number;
        passenger: BaggageSectionJourneyPassenger;
    }) => boolean;
    getNextPerJourneyPerPaxPrice: (data: {
        canGetFirstBaggageBancoEstadoDiscount: boolean;
        canGetFirstBaggageStaffDiscount: boolean;
        isOriginal: boolean;
        passenger: BaggageSectionJourneyPassenger;
    }) => number;
}

export const perJourneyPerPaxChangeHandler = (): PerJourneyPerPaxChangeHandler => {
    const isPerJourneyPerPaxAddAvailable = (data: {
        bookingContext: BookingContext;
        journey: BaggageSectionJourney;
        passenger: BaggageSectionJourneyPassenger;
    }) => {
        if (
            (data.journey.index === OUTBOUND && data.bookingContext.isCheckinClosedOutbound) ||
            (data.journey.index === INBOUND && data.bookingContext.isCheckinClosedInbound)
        ) {
            return false;
        }

        return !data.passenger.isSoldOut && data.passenger.quantity < data.passenger.max && data.journey.available > 0;
    };

    const isPerJourneyPerPaxRemoveAvailable = (data: {
        bookingContext: BookingContext;
        journeyIndex: number;
        passenger: BaggageSectionJourneyPassenger;
    }) => {
        if (
            (data.journeyIndex === OUTBOUND && data.bookingContext.isCheckinClosedOutbound) ||
            (data.journeyIndex === INBOUND && data.bookingContext.isCheckinClosedInbound)
        ) {
            return false;
        }

        return !data.passenger.isSoldOut && data.passenger.quantity > Math.max(1, data.passenger.min);
    };

    const isPerJourneyPerPaxResetAvailable = (data: {
        bookingContext: BookingContext;
        canGetFirstBaggageBancoEstadoDiscount: boolean;
        journeyIndex: number;
        passenger: BaggageSectionJourneyPassenger;
    }) => {
        if (
            (data.journeyIndex === OUTBOUND && data.bookingContext.isCheckinClosedOutbound) ||
            (data.journeyIndex === INBOUND && data.bookingContext.isCheckinClosedInbound)
        ) {
            return false;
        }

        return data.passenger.min === 0;
    };

    const getNextPerJourneyPerPaxPrice = (data: {
        canGetFirstBaggageBancoEstadoDiscount: boolean;
        canGetFirstBaggageStaffDiscount: boolean;
        isOriginal: boolean;
        passenger: BaggageSectionJourneyPassenger;
    }) => {
        // DEVNOTE Staff members get the first bag for $1
        if (data.canGetFirstBaggageStaffDiscount) {
            return FIRST_BAGGAGE_PRICE_FOR_STAFF_USERS;
        }

        // DEVNOTE BancoEstado members get the first bag for $0
        if (data.canGetFirstBaggageBancoEstadoDiscount && data.passenger.index === 0) {
            return 0;
        }

        const paxBagPrices = data.passenger[data.isOriginal ? "originalPrices" : "prices"];

        const index =
            data.passenger.quantity < data.passenger.max ? data.passenger.quantity : data.passenger.quantity - 1;

        return paxBagPrices[index];
    };

    return {
        getNextPerJourneyPerPaxPrice,
        isPerJourneyPerPaxAddAvailable,
        isPerJourneyPerPaxRemoveAvailable,
        isPerJourneyPerPaxResetAvailable,
    };
};
