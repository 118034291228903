import { html, useRef } from "haunted";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import i18next from "i18next";
import { useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { ref } from "../../../directives/ref";
import { ROUTES } from "../../../shared/apiRoutes";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";
import { handleCugLoader } from "../../../shared/common";
import { TravelPartnerHelper } from "../../../component-helpers/TravelPartnerHelper";
import { URL_VARS } from "../../../shared/commonConstants";
import { Group } from "../../../component-models/CUG2b/Group";
import { GridHelper } from "../../../component-helpers/GridHelper";
import { Partner } from "../../../component-models/CUG2b/Partner";
import {
    GridState,
    PageChangeEvent,
    SortChangeEvent,
    RowsSelectedEvent,
    ViewModel,
    Column,
} from "../../../dc-components/dc-table-models";
import { usePortalTravelPartnersUtils } from "./usePortalTravelPartnersUtils";
import { useDeletePartnerModal } from "./useDeletePartnerModal";
import { useEditPartnerModal } from "./useEditPartnerModal";

const actionCellClass = ["w-16", "sm:w-16", "md:w-32", "p-0", "pinned-on-mobile"];
const actionCellContentClass = ["w-16", "sm:w-16", "md:w-32", "action-cell-user"];

export const useCugEditTravelGroupPage = () => {
    const root = useRef<HTMLDivElement>(undefined);

    const { getTravelPartnerInfo, postTravelPartnerInfo } = useAjax();
    const { orderedModel, isAnythingSelected } = usePortalTravelPartnersUtils();

    const initialGridState = useMemo<GridState<keyof Partner>>(
        () => ({
            pageIndex: 0,
            appliedFilters: [],
            pageSize: 10,
            orderBy: "LastName",
            orderDir: "asc",
            globalFilterExpression: "",
        }),
        [],
    );

    const [travelPartnerInfo, setTravelPartnerInfo] = useState<TravelPartnerInfo>(undefined);
    const [gridState, setGridState] = useState<GridState<keyof Partner>>(initialGridState);
    const [selectedIds, setSelectedIds] = useState<Set<number>>(new Set<number>());
    const [partnersToDelete, setPartnersToDelete] = useState<number[]>(undefined);
    const [group, setGroup] = useState<Group>(undefined);
    const [partnerToEdit, setPartnerToEdit] = useState<Partner>(undefined);

    const partnersOfSelectedGroup = useMemo(
        () => travelPartnerInfo?.Partners.filter((p) => p.GroupId === group.Id),
        [group, travelPartnerInfo],
    );

    // HELPERS

    const init = () => {
        const doIt = async () => {
            const result = await getTravelPartnerInfo();

            setTravelPartnerInfo(result);

            const urlParams = new URLSearchParams(window.location.search);
            const groupId = decodeURIComponent(urlParams.get(URL_VARS.FFLYER_IDS));

            setGroup(result.Groups.find((g) => g.Id === Number(groupId)));
        };

        void doIt();
    };

    const deletePartners = async () => {
        const newTravelPartnerInfo = TravelPartnerHelper.deletePartners(travelPartnerInfo, partnersToDelete);

        setPartnersToDelete(undefined);
        handleCugLoader(root.current, "loadData");

        const result = await postTravelPartnerInfo(newTravelPartnerInfo);

        setTravelPartnerInfo(result);

        handleCugLoader(root.current, "loadData");
    };

    // EVENT HANDLERS

    const onPageChange = (e: PageChangeEvent) => {
        setGridState({
            ...gridState,
            pageIndex: e.detail.selectedPageIndex,
            pageSize: e.detail.selectedPageSize,
        });
    };

    const onSortChange = (e: SortChangeEvent) => {
        setGridState({
            ...gridState,
            orderBy: e.detail.orderBy as keyof Partner,
            orderDir: e.detail.orderBy !== gridState.orderBy ? "asc" : e.detail.orderDir,
        });
    };

    const onRowsSelect = (event: RowsSelectedEvent) => {
        setSelectedIds(
            GridHelper.getSelectedIds(
                event,
                selectedIds,
                orderedModel(travelPartnerInfo, gridState, partnersOfSelectedGroup),
            ) as Set<number>,
        );
    };

    const handleSingleDelete = async (row: Partner) => {
        setPartnersToDelete([row.Id]);
    };

    const handleMultiDelete = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        setPartnersToDelete(Array.from(selectedIds.values()));
    };

    const handleEditSubmit = async (partner: Partner) => {
        const newTravelPartnerInfo = TravelPartnerHelper.updatePartner(travelPartnerInfo, partner);
        setPartnerToEdit(undefined);

        handleCugLoader(root.current, "loadData");

        const result = await postTravelPartnerInfo(newTravelPartnerInfo);

        setTravelPartnerInfo(result);

        handleCugLoader(root.current, "loadData");
    };

    const handleEdit = (e: MouseEvent, row: Partner) => {
        e.preventDefault();
        e.stopPropagation();
        setPartnerToEdit(row);
    };

    // COMPONENT

    const deletePartnerModal = useDeletePartnerModal({
        partnersToDelete,
        setPartnersToDelete,
        travelPartnerInfo,
        handleDelete: deletePartners,
    });

    const editPartnerModal = useEditPartnerModal({
        partner: partnerToEdit,
        travelPartnerInfo,
        setPartnerToEdit,
        handleEditSubmit,
    });

    // TEMPLATES

    const breadcrumbsTemplate = () => html`
        <div class="travel-partner-breadcrumb-container">
            <div
                class="travel-partner-breadcrumb past"
                @click=${() => (window.location.href = ROUTES.Cug2BTravelPartnersPage)}
            >
                ${i18next.t("Pasajeros frecuentes")}
            </div>
            <div class="travel-partner-breadcrumb divider">/</div>
            <div
                class="travel-partner-breadcrumb past"
                @click=${() => (window.location.href = ROUTES.Cug2BTravelGroupsPage)}
            >
                ${i18next.t("Grupos")}
            </div>
            <div class="travel-partner-breadcrumb divider">/</div>
            <div
                class="travel-partner-breadcrumb past"
                @click=${() => (window.location.href = ROUTES.Cug2BAdminTravelGroupsPage)}
            >
                ${i18next.t("Administrar grupos")}
            </div>
            <div class="travel-partner-breadcrumb divider">/</div>
            <div class="travel-partner-breadcrumb">${group.Name}</div>
        </div>
    `;

    const staticStuffTemplate = () => html`
        ${breadcrumbsTemplate()}
        <div class="travel-partner-title pull-up">
            <h2>${i18next.t("Administrar grupos")}</h2>
        </div>
        <div class="travel-partner-text-and-button">
            ${i18next.t(
                "Aquí podrás revisar toda la información de los grupos creados, administrar pasajeros frecuentes, editar y eliminar grupos.",
            )}
        </div>
        <div class="mb-4 flex flex-col justify-between sm:flex-row sm:items-end md:mb-8">
            <div>
                <div class="travel-partner-title pull-up">
                    <h2>${i18next.t("Editar grupo")}</h2>
                </div>
                <div class="travel-partner-group-name mt-4">
                    <h2>${group.Name}</h2>
                </div>
                <div class="travel-partner-edit-group-info">
                    ${i18next.t("Cantidad de pasajeros")}:
                    <span class="font-bold"
                        >${travelPartnerInfo.Partners.filter((p) => p.GroupId === group.Id).length}</span
                    >
                </div>
            </div>
            <div class="text-center sm:text-left">
                <div
                    class="cug2b-pill mr-4"
                    @click=${() =>
                        (window.location.href = `${ROUTES.Cug2BAddPartnerPage}?${URL_VARS.FFLYER_GROUP_ID}=${group.Id}`)}
                >
                    <i class="js-icon-cug js-cug-man-and-plus"></i>${i18next.t("Agregar Pasajero")}
                </div>
                <div
                    class="cug2b-pill warning ${!isAnythingSelected(selectedIds) ? "disabled" : ""}"
                    @click=${handleMultiDelete}
                >
                    <i class="js-icon-cug js-cug-bin"></i>${i18next.t("Eliminar")}
                </div>
            </div>
        </div>
    `;

    const rowEditTemplate = (index: number) => {
        const row = orderedModel(travelPartnerInfo, gridState, partnersOfSelectedGroup)[index];

        return html`
            <td class=${GridHelper.getClassMap(actionCellClass)}>
                <div class=${GridHelper.getClassMap(actionCellContentClass)}>
                    <i
                        class="js-icon-cug js-cug-edit relative mr-4"
                        @click=${(e: MouseEvent) => handleEdit(e, row)}
                    ></i>
                    <i class="js-icon-cug js-cug-bin" @click=${() => handleSingleDelete(row)}></i>
                </div>
            </td>
        `;
    };

    const vm: ViewModel<keyof Partner> = {
        columns: [
            {
                field: "LastName",
                columnType: "string",
                label: i18next.t("Nombres y Apellidos"),
                sortable: true,
                columnClass: "",
                cellTemplate: (index: number) =>
                    `${orderedModel(travelPartnerInfo, gridState, partnersOfSelectedGroup)[index].FirstName} ${orderedModel(travelPartnerInfo, gridState, partnersOfSelectedGroup)[index].LastName}`,
            } as Column<keyof Partner>,
            {
                field: "DocumentId",
                columnType: "string",
                label: i18next.t("RUT / DNI / Pasaporte"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
            } as Column<keyof Partner>,
            {
                field: "DOB",
                columnType: "string",
                label: i18next.t("Fecha de Nacimiento"),
                sortable: true,
                columnClass: "",
                cellClass: () => "text-center",
                cellTemplate: (index: number) =>
                    orderedModel(travelPartnerInfo, gridState, partnersOfSelectedGroup)[index].DOB.format("DD-MM-YYYY"),
            } as Column<keyof Partner>,
            {
                field: "Alias",
                columnType: "string",
                label: i18next.t("Alias"),
                sortable: true,
                columnClass: "",
            } as Column<keyof Partner>,
        ],
        data: orderedModel(travelPartnerInfo, gridState, partnersOfSelectedGroup),
        paging: {
            pageable: true,
            pageIndex: gridState.pageIndex,
            pageSize: gridState.pageSize,
            buttonCount: 5,
            pageSizes: [10],
            itemCount: travelPartnerInfo?.Partners.length,
            showInfo: false,
        },
        sorting: {
            orderBy: gridState.orderBy,
            orderDir: gridState.orderDir,
            showSorterArrow: false,
        },
        selection: {
            selectable: true,
            selectedIndices: Array.from(selectedIds.values())
                .filter((id) =>
                    orderedModel(travelPartnerInfo, gridState, partnersOfSelectedGroup).some((item) => item.Id === id),
                )
                .map((id) =>
                    orderedModel(travelPartnerInfo, gridState, partnersOfSelectedGroup)
                        .map((i) => i.Id)
                        .indexOf(id),
                ),
        },
        rowCustomization: [],
        appliedFilters: [],
        rowEditTemplate,
        actionCellClass,
        actionCellContentClass,
        useEllipsis: false,
    };

    const htmlTemplate = () =>
        travelPartnerInfo
            ? html`
                  <div ref=${ref(root)}>
                      ${staticStuffTemplate()}
                      <dc-table
                          .vm=${vm}
                          @onSortChange=${onSortChange}
                          @onPageChange=${onPageChange}
                          @onRowsSelect=${onRowsSelect}
                      ></dc-table>
                      ${editPartnerModal.htmlTemplate()} ${deletePartnerModal.htmlTemplate()}
                  </div>
              `
            : html``;

    return { init, htmlTemplate };
};
