import i18next from "i18next";
import { html } from "haunted";
import { ROUTES } from "../../../shared/apiRoutes";
import { useEffect, useState } from "../../../shared/haunted/CustomHooks";
import { ScrollHelper } from "../../../shared/ScrollHelper";
import PerfectScrollbar from "perfect-scrollbar";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { ItineraryPageViewModel } from "../../../component-models/itinerary/ItineraryPageViewModel";
import { useTransactionDetailsModal } from "./useTransactionDetailsModal";
import { useBookingContext } from "../../../managers/useBookingContext";
import { useReduxState } from "../../../shared/redux/useReduxState";
import { spaSectionOrderFactory } from "../../spa/spaSectionOrderFactory";
import { useItineraryTransactionsNormalTable } from "./useItineraryTransactionsNormalTable";
import { ApiItineraryRedemptionPayment } from "../../../component-models/itinerary/ApiItineraryRedemptionPayment";
import { REDEMPTION_MILES_CODE } from "../../../shared/commonConstants";
import { useNumberFormatter } from "../../../shared/useNumberFormatter";
import { itineraryTransactionsUtils } from "./itineraryTransactionsUtils";

export interface Props {
    model: ItineraryPageViewModel;
    togglePrintingTransaction: () => void;
}

const NORMAL_TRANSACTION_TABLE = "normal-transaction-table";
const REDEMPTION_TRANSACTION_TABLE = "redemption-transaction-table";

export const useItineraryTabsTransactionsRedemption = (props: Props) => {
    const [openedTransactionDetailsIndex, setOpenedTransactionDetailsIndex] = useState<number>(-1);
    const bookingContext = useBookingContext();
    const { formatNumber } = useNumberFormatter();
    const { visiblePaymentButtonType } = itineraryTransactionsUtils();
    const { getSpaSectionOrder } = spaSectionOrderFactory();
    const [userContext] = useReduxState("userContext");
    const [normalTableScrollbar, setNormalTableScrollbar] = useState<PerfectScrollbar>(undefined);
    const [redemptionTableScrollbar, setredemptionTableScrollbar] = useState<PerfectScrollbar>(undefined);

    // HELPERS

    const addScrollBarToNormalTable = () => {
        const x = ScrollHelper.getWindowWidth();
        if (x < 768) {
            const scrollBar = ScrollHelper.addPerfectScrollbar(NORMAL_TRANSACTION_TABLE, {
                wheelPropagation: false,
                wheelSpeed: 1,
                swipeEasing: true,
                suppressScrollX: false,
                useBothWheelAxes: true,
            })[0];
            setNormalTableScrollbar(scrollBar);
        }
    };

    const addScrollBarToRedemptionTable = () => {
        const x = ScrollHelper.getWindowWidth();
        if (x < 768) {
            const scrollBar = ScrollHelper.addPerfectScrollbar(REDEMPTION_TRANSACTION_TABLE, {
                wheelPropagation: false,
                wheelSpeed: 1,
                swipeEasing: true,
                suppressScrollX: false,
                useBothWheelAxes: true,
            })[0];
            setredemptionTableScrollbar(scrollBar);
        }
    };

    // EVENT HANDLERS

    const handlePayClick = () => {
        window.location.href = ROUTES.PagePayment;
    };

    const handlePayFarelockFullClick = () => {
        const order = getSpaSectionOrder({
            flowType: "FarelockRoundTwo",
            hasBundle: props.model.ItineraryModelV2.Journeys.some((journey) => journey.BundleType !== "None"),
            isStaff: userContext.isStaff || bookingContext.isStaffBooking,
            isCheckinClosedOutbound: bookingContext.isCheckinClosedOutbound,
            isChileCompraBookingForNonChileCompra: false,
            chileCompraRole: userContext.chileCompra.role,
        });
        const firstPageIndex = Math.min(...order.map((x) => x.index));

        window.location.href = `/${order.find((o) => o.index === firstPageIndex).section.uri}`;
    };

    const handleOpenPaymentDetails = (index: number) => {
        props.togglePrintingTransaction();
        setOpenedTransactionDetailsIndex(index);
        modal.open();
    };

    const handleClickOnTransaction = () => window.setTimeout(redemptionTableScrollbar?.update, 200);

    // COMPONENT
    const modal = useTransactionDetailsModal({
        farelockExpiry: props.model.FarelockExpiry,
        filename: "transaction-details",
        paymentResult:
            openedTransactionDetailsIndex >= 0
                ? props.model.ItineraryPayments[openedTransactionDetailsIndex].PaymentResult
                : undefined,
        tealiumProperties: props.model.TealiumProperties,
        showTransactionBox: props.model.ItineraryModelV2.ShowTransactionBox,
        onClose: () => {
            props.togglePrintingTransaction();
            setOpenedTransactionDetailsIndex(-1);
        },
    });

    const transactionsTable = useItineraryTransactionsNormalTable({
        model: props.model,
        scrollBar: normalTableScrollbar,
        handleOpenPaymentDetails,
    });

    useEffect(() => {
        addScrollBarToNormalTable();
        addScrollBarToRedemptionTable();
    }, []);

    // TEMPLATES

    const payButtonTemplate = () =>
        visiblePaymentButtonType(props.model) === "Pay"
            ? html`
                  <div class="m-4 flex items-center justify-end">
                      <a @click=${handlePayClick} class="rounded-primary-btn">${i18next.t("Pagar")}</a>
                  </div>
              `
            : "";

    const payFarelockFullAmountButtonTemplate = () =>
        visiblePaymentButtonType(props.model) === "PayFarelockFull"
            ? html`
                  <div class="m-4 flex items-center justify-end">
                      <a @click=${handlePayFarelockFullClick} class="rounded-primary-btn"
                          >${i18next.t("Pagar monto total")}</a
                      >
                  </div>
              `
            : "";

    const gdsOnHoldWarningMessageTemplate = () =>
        props.model.IsGdsOnHold && bookingContext.isGdsBooking
            ? html`
                  <div
                      class="mb-3.5 mt-7 w-full bg-brand-primary px-5 py-6 font-body text-sm text-white sm:mb-0 sm:rounded-xl sm:text-base"
                  >
                      <div>
                          <span class="font-bold">${i18next.t("Atención")}:</span>
                          ${unsafeHTML(
                              i18next.t("gds-itinerary-warning-line-one {{-spanStart}} {{-spanEnd}}", {
                                  spanStart: "<span class='underline'>",
                                  spanEnd: "</span>",
                              }),
                          )}
                      </div>
                      <div class="mt-4">${i18next.t("gds-itinerary-warning-line-two")}</div>
                  </div>
              `
            : "";

    const redemptionTransactionTemplate = (payment: ApiItineraryRedemptionPayment) =>
        html` <tr>
            <td><img src=${payment.ImgSource} /></td>
            <td>${unsafeHTML(payment.CreatedAt)}</td>
            <td>${i18next.t("JetSmart miles")}</td>
            <td>
                ${formatNumber({
                    amount: payment.Amount,
                    currency: REDEMPTION_MILES_CODE,
                    leadingSign: false,
                })}
                ${i18next.t("Miles")}
            </td>
            <!-- DEVNOTE: There are no installments in redemption (?) -->
            <td>&nbsp;</td>
            <td>-</td>
            <td><span>${payment.Status}</span></td>
        </tr>`;

    const redemptionTableTemplate = () =>
        html` <table class="i2-transactions-table" @click=${handleClickOnTransaction}>
            <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>${i18next.t("Fecha")}</th>
                    <th>${i18next.t("Medio de pago")}</th>
                    <th>${i18next.t("Monto")}</th>
                    <th>${i18next.t("Nr. Cuotas")}</th>
                    <th>${i18next.t("Detalle")}</th>
                    <th>${i18next.t("Estado")}</th>
                </tr>
            </thead>
            <tbody>
                ${props.model.ItineraryPayments
                    ? props.model.RedemptionItineraryPayments.map(redemptionTransactionTemplate)
                    : ""}
            </tbody>
        </table>`;

    const redemptionSectionTemplate = () => html`
        <div
            class="mb-2.5 pl-[15px] text-[15px] text-brand-secondary sm:pl-0 md:mb-6 md:text-[22px] lg:mb-7 lg:text-[26px]"
        >
            ${unsafeHTML(
                i18next.t("Payment of your flight with AAdvantage miles {{-reg}}", {
                    reg: '<span class="relative font-body top-[-1px]">&reg;</span>',
                }),
            )}
        </div>
        <div class=${`i2-transaction-container ${REDEMPTION_TRANSACTION_TABLE}`}>${redemptionTableTemplate()}</div>
    `;

    const optionalsSectionTemplate = () => html`
        <div
            class="mb-2.5 mt-5 pl-[15px] text-[15px] text-brand-secondary sm:pl-0 md:mb-6 md:mt-10 md:text-[22px] lg:mb-7 lg:text-[26px]"
        >
            ${i18next.t("Payment for optional services")}
        </div>
        <div class=${`i2-transaction-container ${NORMAL_TRANSACTION_TABLE}`}>${transactionsTable.htmlTemplate()}</div>
    `;

    const htmlTemplate = () => html`
        <div class="no-print">
            <div class="i2-tab-title">
                <i class="js-icon-it js-it-money-list"></i>
                <div>${i18next.t("Transacciones")}</div>
            </div>
            ${redemptionSectionTemplate()} ${optionalsSectionTemplate()} ${gdsOnHoldWarningMessageTemplate()}
            ${payButtonTemplate()} ${payFarelockFullAmountButtonTemplate()}
        </div>
        ${modal.htmlTemplate()}
    `;

    return { htmlTemplate };
};
