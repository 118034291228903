import { html } from "haunted";
import i18next from "i18next";
import { UserInfo } from "../../../component-models/UserInfo";
import { PeruCompraMemberData } from "../../../component-models/PeruCompraMemberData";
import { USER_ROLE_CODE } from "../../../shared/commonConstants";

export interface Props {
    model: UserInfo;
    peruCompraModel?: PeruCompraMemberData;
}

export const useUserInfoFields = (props: Props) => {
    // HELPERS
    const getFormattedRoleName = (roleCode: string) => {
        switch (roleCode) {
            case USER_ROLE_CODE.CHILECOMPRA_OBSERVER:
                return i18next.t("Observador");
            case USER_ROLE_CODE.CHILECOMPRA_SUPERVISOR:
                return i18next.t("Supervisor");
            default:
                return roleCode;
        }
    };

    // TEMPLATES TO EXPORT

    const fullNameTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("Nombre y Apellido")}</header>
            <article>${props.model.Name}</article>
        </div>
    `;

    const phoneTemplate = () =>
        html` <div class="cug2b-profile-item">
            <header>${i18next.t("Teléfono")}</header>
            <article>${props.model.HomePhome}</article>
        </div>`;

    const officePhoneTemplate = () =>
        props.peruCompraModel
            ? html`
                  <div class="cug2b-profile-item">
                      <header>${i18next.t("Office Phone")}</header>
                      <article class="break-all">${props.peruCompraModel.BusinessPhone?.Number}</article>
                  </div>
              `
            : "";

    const mobilePhoneTemplate = () =>
        props.peruCompraModel
            ? html`
                  <div class="cug2b-profile-item">
                      <header>${i18next.t("Teléfono móvil")}</header>
                      <article class="break-all">${props.peruCompraModel.MobilePhone?.Number}</article>
                  </div>
              `
            : "";

    const emailTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("Correo electrónico")}</header>
            <article class="break-all">${props.model.Email}</article>
        </div>
    `;

    const userNameTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("User Name")}</header>
            <article class="break-all">${props.model.UserName}</article>
        </div>
    `;

    const passwordTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("Contraseña")}</header>
            <article>************</article>
        </div>
    `;

    const dniTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("DNI")}</header>
            <article class="break-all">${props.model.UserName}</article>
        </div>
    `;

    const docNumberTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("RUT")}</header>
            <article class="break-all">${props.model.Rut}</article>
        </div>
    `;

    const roleNameTemplate = () => html`
        <div class="cug2b-profile-item">
            <header>${i18next.t("Role")}</header>
            <article class="break-all">${getFormattedRoleName(props.model.RoleCode)}</article>
        </div>
    `;

    return {
        fullNameTemplate,
        phoneTemplate,
        officePhoneTemplate,
        mobilePhoneTemplate,
        emailTemplate,
        userNameTemplate,
        passwordTemplate,
        dniTemplate,
        docNumberTemplate,
        roleNameTemplate,
    };
};
