export const TestIdDictionary = {
    BAGGAGE: {
        BACKPACK_TOOLTIP: "baggage-backpack-tooltip",
        BACKPACK_TOOLTIP_DIMENSIONS: "baggage-backpack-tooltip-dimensions",
        BACKPACK_TOOLTIP_OPENER: "baggage-backpack-tooltip-opener",
        CABIN_BAG_WARNING: "baggage-cabin-bag-warning",
        CLOSE_PER_JOURNEY_PER_PAX_VIEW_BUTTON: "baggage-close-per-journey-per-pax-view-button",
        DC_INFO: "baggage-dc-info",
        ERROR_MESSAGE: "baggage-error-message",
        HEADER_MAIN_TITLE: "baggage-header-main-title",
        HEADER_SUBTITLE: "baggage-header-subtitle",
        IS_INCLUDED: "baggage-is-included",
        JOURNEY_OPENER: "baggage-journey-opener",
        MAIN_CONTAINER: "baggage-main-container",
        MAIN_ICON: "baggage-main-icon",
        OPEN_PER_JOURNEY_PER_PAX_VIEW_BUTTON: "baggage-open-per-journey-per-pax-view-button",
        OPEN_PER_JOURNEY_PER_PAX_VIEW_INFO: "baggage-open-per-journey-per-pax-view-info",
        OVERSIZED_ACCORDION: "baggage-oversized-accordion",
        OVERSIZED_ACCORDION_OPENER: "baggage-oversized-accordion-opener",
        OVERSIZED_TITLE: "baggage-oversized-title",
        PAGE_SECTION: "baggage-page-section",
        PAGE_SECTION_DT: "baggage-page-section-dt",
        PAGE_SECTION_HEADER: "baggage-page-section-header",
        PAGE_SECTION_MB: "baggage-page-section-mb",
        PAGE_SECTION_NUMBER: "baggage-page-section-number",
        PAX_OPENER: "baggage-pax-opener",
        PER_BOOKING_ADD_BUTTON: "baggage-per-booking-add-button",
        PER_BOOKING_ADD_FIRST_BUTTON: "baggage-per-booking-add-first-button",
        PER_BOOKING_BUNDLE_MESSAGE: "baggage-per-booking-bundle-message",
        PER_BOOKING_CROSSED_OUT_MINIMUM_PRICE: "baggage-per-booking-crossed-out-minimum-price",
        PER_BOOKING_CROSSED_OUT_NEXT_PRICE: "baggage-per-booking-crossed-out-next-price",
        PER_BOOKING_FREE_ILLUSTRATION: "baggage-per-booking-free-illustration",
        PER_BOOKING_FREE_ILLUSTRATION_DIMENSIONS: "baggage-per-booking-free-illustration-dimensions",
        PER_BOOKING_FREE_ILLUSTRATION_IMAGE: "baggage-per-booking-free-illustration-image",
        PER_BOOKING_FREE_ILLUSTRATION_NAME: "baggage-per-booking-free-illustration-name",
        PER_BOOKING_FREE_ILLUSTRATION_TOOLTIP_OPENER: "baggage-per-booking-free-illustration-tooltip-opener",
        PER_BOOKING_FREE_ILLUSTRATION_WEIGHT: "baggage-per-booking-free-illustration-weight",
        PER_BOOKING_MINIMUM_PRICE: "baggage-per-booking-minimum-price",
        PER_BOOKING_NEXT_PRICE: "baggage-per-booking-next-price",
        PER_BOOKING_PAID_BUTTON_CONTAINER: "baggage-per-booking-paid-button-container",
        PER_BOOKING_PAID_ILLUSTRATION: "baggage-per-booking-paid-illustration",
        PER_BOOKING_PAID_ILLUSTRATION_DIMENSIONS: "baggage-per-booking-paid-illustration-dimensions",
        PER_BOOKING_PAID_ILLUSTRATION_IMAGE: "baggage-per-booking-paid-illustration-image",
        PER_BOOKING_PAID_ILLUSTRATION_NAME: "baggage-per-booking-paid-illustration-name",
        PER_BOOKING_PAID_ILLUSTRATION_RIBBON: "baggage-per-booking-paid-illustration-ribbon",
        PER_BOOKING_PAID_ILLUSTRATION_TOOLTIP_OPENER: "baggage-per-booking-paid-illustration-tooltip-opener",
        PER_BOOKING_PAID_ILLUSTRATION_WEIGHT: "baggage-per-booking-paid-illustration-weight",
        PER_BOOKING_QUANTITY: "baggage-per-booking-quantity",
        PER_BOOKING_REMOVE_BUTTON: "baggage-per-booking-remove-button",
        PER_BOOKING_RESET_BUTTON: "baggage-per-booking-reset-button",
        PER_JOURNEY_PER_PAX_ADD_BUTTON: "baggage-per-journey-per-pax-add-button",
        PER_JOURNEY_PER_PAX_ADD_FIRST_BUTTON: "baggage-per-journey-per-pax-add-first-button",
        PER_JOURNEY_PER_PAX_BUNDLE_MESSAGE: "baggage-per-journey-per-pax-bundle-message",
        PER_JOURNEY_PER_PAX_MINIMUM_PRICE: "baggage-per-journey-per-pax-minimum-price",
        PER_JOURNEY_PER_PAX_NEXT_PRICE: "baggage-per-journey-per-pax-next-price",
        PER_JOURNEY_PER_PAX_PASSENGER: "baggage-per-journey-per-pax-passenger",
        PER_JOURNEY_PER_PAX_PASSENGERS: "baggage-per-journey-per-pax-passengers",
        PER_JOURNEY_PER_PAX_QUANTITY: "baggage-per-journey-per-pax-quantity",
        PER_JOURNEY_PER_PAX_REMOVE_BUTTON: "baggage-per-journey-per-pax-remove-button",
        PER_JOURNEY_PER_PAX_RESET_BUTTON: "baggage-per-journey-per-pax-reset-button",
        PER_JOURNEY_PER_PAX_VIEW: "baggage-per-journey-per-pax-view",
        REGULAR_BAG_TOOLTIP: "baggage-regular-bag-tooltip",
        REGULAR_BAG_TOOLTIP_DIMENSIONS: "baggage-regular-bag-tooltip-dimensions",
        REGULAR_BAG_TOOLTIP_OPENER: "baggage-regular-bag-tooltip-opener",
        SOLD_OUT_MESSAGE: "baggage-sold-out-message",
        SUBMIT_BUTTON: "baggage-submit-button",
        TOO_MANY_PAX_FOR_DC: "baggage-too-many-pax-for-dc",
        ZIPPED_BAG_TOOLTIP: "baggage-zipped-bag-tooltip",
        ZIPPED_BAG_TOOLTIP_DIMENSIONS: "baggage-zipped-bag-tooltip-dimensions",
        ZIPPED_BAG_TOOLTIP_OPENER: "baggage-zipped-bag-tooltip-opener",
    },
    BUNDLE: {
        BUTTON_MOBILE: "bundle-button-mobile",
        EDIT_SELECTED: "bundle-edit-selected",
        HEADER: "bundle-header",
        HEADER_IMAGE: "bundle-header-image",
        NON_DISCOUNTED_PRICE: "bundle-non-discounted-price",
        PRICE: "bundle-price",
        PRICE_INFO: "bundle-price-info",
        SAVINGS: "bundle-savings",
        SAVINGS_IMAGE: "bundle-savings-image",
        SELECTED_CONTAINER: "bundle-selected-container",
        SELECTED_HEADER: "bundle-selected-header",
        SELECTED_IMAGE: "bundle-selected-image",
        SELECTED_PER_LEG: "bundle-selected-per-leg",
        SELECTED_PRICE: "bundle-selected-price",
        SELECTED_PRICE_CURRENCY_SIGN: "bundle-selected-price-currency-sign",
        SELECTOR: "bundle-selector",
        SELECTOR_OPTION: "bundle-selector-option",
        SELECTOR_SUBTITLE: "bundle-selector-subtitle",
        SELECTOR_TITLE: "bundle-selector-title",
        SELECT_BUTTON: "bundle-select-button",
        SSRS: "bundle-ssrs",
        SSR_ITEM: "bundle-ssr-item",
        SSR_ITEM_NAME: "bundle-ssr-item-name",
        SSR_TICK: "bundle-ssr-tick",
        TOOLTIP: "bundle-tooltip",
        TOOLTIP_INFO: "bundle-tooltip-info",
        TOOLTIP_TITLE: "bundle-tooltip-title",
        UPGRADE_BUTTON: "bundle-upgrade-button",
        UPGRADE_CONTAINER: "bundle-upgrade-container",
        UPGRADE_IMAGE: "bundle-upgrade-image",
        UPGRADE_PRICE: "bundle-upgrade-price",
        UPGRADE_PRICE_CONTAINER: "bundle-upgrade-price-container",
        UPGRADE_TITLE: "bundle-upgrade-title",
    },
    CHECKIN: {
        ADDITIONAL_INFO_CONTAINER: "checkin-additional-info-container",
        ADDITIONAL_INFO_SUBTITLE: "checkin-additional-info-subtitle",
        ADDITIONAL_INFO_TITLE: "checkin-additional-info-title",
        BOARDING_PASS: "checkin-boarding-pass",
        BREADCRUMBS: "checkin-breadcrumbs",
        FLOW_PASSENGER_CONTAINER: "checkin-flow-passenger-container",
        FLOW_PASSENGER_DESTINATION: "checkin-flow-passenger-destination",
        FLOW_PASSENGER_ORIGIN: "checkin-flow-passenger-origin",
        FLOW_PASSENGER_TRAVEL_DATE: "checkin-flow-passenger-travel-date",
        FLOW_PLANE_ICON: "checkin-flow-plane-icon",
        MULTILEG_FLIGHT_LINK: "checkin-multileg-flight-link",
        MULTILEG_FLIGHT_MODAL_OPENER: "checkin-multileg-flight-modal-opener",
        MULTISEGMENT_FLIGHT_LINK: "checkin-multisegment-flight-link",
        MULTISEGMENT_FLIGHT_MODAL_OPENER: "checkin-multisegment-flight-modal-opener",
        PASSENGERS: "checkin-passengers",
        PASSENGERS_SELECT_ALL: "checkin-passengers-select-all",
        PASSENGERS_SUBMIT_BUTTON: "checkin-passengers-submit-button",
        PASSENGERS_SUBTITLE: "checkin-passengers-subtitle",
        PASSENGERS_TITLE: "checkin-passengers-title",
        PASSENGER_DOCUMENT: "checkin-passenger-document",
        RIBBON: "checkin-ribbon",
        SECURITY_CHECKBOX: "checkin-security-checkbox",
    },
    CUSTOMER_LOGIN: {
        DNI: "customer-login-dni",
        EMAIL: "customer-login-email",
        ERROR_MESSAGE: "customer-login-error-message",
        ERROR_MESSAGE_CONTAINER: "customer-login-error-message-container",
        FORGOT_PASSWORD_BUTTON: "customer-login-forgot-password-button",
        MEMBER_LIST: "customer-login-member-list",
        PASSWORD: "customer-login-password",
        REGISTER_BUTTON: "customer-login-register-button",
        SUBMIT_BUTTON: "customer-login-submit-button",
        SUBTITLE: "customer-login-subtitle",
        TITLE: "customer-login-title",
    },
    COMMON: {
        ACCEPT_TERMS_AND_CONDITIONS_CHECKBOX: "common-accept-term-and-conditions-checkbox",
        CULTURE_DROPDOWN: "common-culture-dropdown",
        CULTURE_SELECTOR: "common-culture-selector",
        EMERGENCY_CONTACT: "common-emergency-contact",
        FORM_ERROR: "common-form-error",
        FORM_FIELD_ERROR: "common-form-field-error",
        INSURANCE_MODAL: "common-insurance-modal",
        INSURANCE_MODAL_CLOSE_BUTTON: "insurance-modal-close-button",
        INSURANCE_MODAL_CONFIRM_BUTTON: "insurance-modal-confirm-button",
        MODIFY_BOOKING_MODAL: "common-modify-booking-modal",
        MODIFY_BOOKING_MODAL_MODIFY_BUTTON: "common-modify-booking-modal-modify-button",
        REMOVE_INSURANCE_MODAL_CLOSE_BUTTON: "common-remove-insurance-modal-close-button",
        SPA_MODAL: "common-spa-modal",
        SPA_MODAL_ACCEPT_BUTTON: "common-spa-modal-accept-button",
        SPA_MODAL_CLOSE_BUTTON: "common-spa-modal-close-button",
    },
    DATE: {
        DATE: "date-date",
        FIRST_MONTH_NAVIGATION_CONTAINER: "date-first-month-navigation-container",
        FIRST_MONTH_NAVIGATION_MOVE_BACK: "date-first-month-navigation-move-back",
        FIRST_MONTH_NAVIGATION_MOVE_FORWARD: "date-first-month-navigation-move-forward",
        FIRST_MONTH_NAVIGATION_NAME: "date-first-month-navigation-name",
        SECOND_MONTH_NAVIGATION_CONTAINER: "date-second-month-navigation-container",
        SECOND_MONTH_NAVIGATION_MOVE_BACK: "date-second-month-navigation-move-back",
        SECOND_MONTH_NAVIGATION_MOVE_FORWARD: "date-second-month-navigation-move-forward",
        SECOND_MONTH_NAVIGATION_NAME: "date-second-month-navigation-name",
    },
    EXTRAS: {
        DOCUMENT_TYPE_OPTION: "extras-doc-type-option",
        DOCUMENT_TYPE_SELECT: "extras-doc-type-select",
        INSURANCE_CONTAINER: "extras-insurance-container",
        INSURANCE_DATA_OPENER: "extras-insurance-data-opener",
        INSURANCE_HEADER: "extras-insurance-header",
        INSURANCE_HEADER_SUBTITLE: "extras-insurance-subtitle",
        INSURANCE_HEADER_SUBTITLE_CHUBB_LOGO: "extras-insurance-subtitle-chubb-logo",
        INSURANCE_PASSENGER: "extras-insurance-passenger",
        INSURANCE_VERSION: "extras-insurance-version",
        NEW_INSURANCE_PASSENGER_LIST_CONTAINER: "extras-new-insurance-passenger-list-container",
        PAGE: "extras-page",
        SUBMIT_BUTTON: "extras-submit-button",
        TICK_ICON: "extras-tick-icon",
    },
    EXTRAS_BAGGAGE_INSURANCE: {
        CONTAINER: "extras-baggage-insurance-container",
        SUBTITLE: "extras-baggage-insurance-subtitle",
        TITLE: "extras-baggage-insurance-title",
    },
    EXTRAS_CHECKIN: {
        CONTAINER: "extras-checkin-container",
        COPY_CHECKBOX: "extras-checkin-copy-checkbox",
        FREE_OPTION: "extras-checkin-free-option",
        FREE_OPTION_AMOUNT: "extras-checkin-free-option-amount",
        FREE_OPTION_TITLE: "extras-checkin-free-option-title",
        FREE_OPTION_WARNING: "extras-checkin-free-option-warning",
        JOURNEY_BUNDLE: "extras-checkin-journey-bundle",
        PACKAGE_TITLE: "extras-checkin-package-title",
        PAID_OPTION: "extras-checkin-paid-option",
        PAID_OPTION_AMOUNT: "extras-checkin-paid-option-amount",
        PAID_OPTION_TITLE: "extras-checkin-paid-option-title",
        PAID_OPTION_WARNING: "extras-checkin-paid-option-warning",
        PASSENGER: "extras-checkin-passenger",
        PASSENGER_JOURNEY: "extras-checkin-passenger-journey",
        PASSENGER_TITLE: "extras-checkin-passenger-title",
        REQUIRED_FIELD_WARNING: "extras-checkin-required-field-warning",
        SUBTITLE: "extras-checkin-subtitle",
        TITLE: "extras-checkin-title",
    },
    EXTRAS_FLEXI_FEE: {
        BUTTON: "extras-flexi-fee-button",
        BUTTON_CAPTION: "extras-flexi-fee-button-caption",
        CONTAINER: "extras-flexi-fee-container",
        JOURNEY: "extras-flexi-fee-journey",
        JOURNEY_BUNDLE: "extras-flexi-fee-journey-bundle",
        SUBTITLE: "extras-flexi-fee-subtitle",
        TITLE: "extras-flexi-fee-title",
    },
    EXTRAS_PET_IN_CABIN: {
        BUTTON: "extras-pet-in-cabin-button",
        BUTTON_CAPTION: "extras-pet-in-cabin-button-caption",
        CONTAINER: "extras-pet-in-cabin-container",
        JOURNEY: "extras-pet-in-cabin-journey",
        PASSENGER_OPTION: "extras-pet-in-cabin-passenger-option",
        SUBTITLE: "extras-pet-in-cabin-subtitle",
        TERMS_LINK: "extras-pet-in-cabin-terms-link",
        TITLE: "extras-pet-in-cabin-title",
    },
    EXTRAS_PRIORITY_BOARDING: {
        BANCO_ESTADO_FOOTER: "extras-priority-boarding-banco-estado-footer",
        BANCO_ESTADO_HEADING: "extras-priority-boarding-banco-estado-heading",
        BANCO_ESTADO_INCLUDED_LABEL: "extras-priority-boarding-banco-estado-included-label",
        BUNDLE_HEADING: "extras-priority-boarding-bundle-heading",
        BUTTON: "extras-priority-boarding-button",
        BUTTON_CAPTION: "extras-priority-boarding-button-caption",
        CONTAINER: "extras-priority-boarding-container",
        JOURNEY: "extras-priority-boarding-journey",
        JOURNEY_BUNDLE: "extras-priority-boarding-journey-bundle",
        PASSENGER_OPTION: "extras-priority-boarding-passenger-option",
        SUBTITLE: "extras-priority-boarding-subtitle",
        TITLE: "extras-priority-boarding-title",
    },
    EXTRAS_TRAVEL_INSURANCE: {
        ACCEPT_CHECKBOX: "extras-travel-insurance-accept-checkbox",
        BUTTON: "extras-travel-insurance-button",
        DOC_NUMBER_CONTAINER: "extras-travel-insurance-doc-number-container",
        SUBTITLE: "extras-travel-insurance-subtitle",
        TITLE: "extras-travel-insurance-title",
    },
    FARELOCK: {
        BUTTON_24: "farelock-button-24",
        BUTTON_48: "farelock-button-48",
        BUTTON_CUG: "farelock-button-cug",
        BUTTON_NO: "farelock-button-no",
        BUTTON_YES: "farelock-button-yes",
        CONTAINER: "farelock-container",
        CONTINUE_BUTTON: "farelock-continue-button",
        DURATION_24: "farelock-duration-24",
        DURATION_48: "farelock-duration-48",
        DURATION_CUG: "farelock-duration-cug",
        DURATION_SELECTOR: "farelock-duration-selector",
        ICON: "farelock-icon",
        INFO: "farelock-info",
        MIN_PRICE: "farelock-min-price",
        SELECTED_TIME: "farelock-selected-time",
        SELECTOR: "farelock-selector",
        SELECTOR_CANCEL: "farelock-selector-cancel",
        SUMMARY_CONTAINER: "farelock-summary-container",
    },
    FLIGHT: {
        AIRPORT_WARNING: "flight-airport-warning",
        BANCO_ESTADO_FEE: "flight-banco-estado-fee",
        CLUB_FEE: "flight-club-fee",
        CONTAINER: "flight-container",
        CONTINUE_BUTTON: "flight-continue-button",
        FEE_OPTION: "flight-fee-option",
        FLIGHT_INFO: "flight-flight-info",
        FLIGHT_INFO_CONTENT: "flight-flight-info-content",
        FLIGHT_INFO_DESTINATION: "flight-flight-info-destination",
        FLIGHT_INFO_ORIGIN: "flight-flight-info-origin",
        HEADER: "flight-header",
        HEADER_LABEL: "flight-header-label",
        ITINERARY: "flight-itinerary",
        ITINERARY_DESTINATION: "flight-itinerary-destination",
        ITINERARY_INBOUND_DATE: "flight-itinerary-inbound-date",
        ITINERARY_INBOUND_DATE_MOBILE: "flight-itinerary-inbound-date-mobile",
        ITINERARY_ORIGIN: "flight-itinerary-origin",
        ITINERARY_OUTBOUND_DATE: "flight-itinerary-outbound-date",
        MILES_FEE: "flight-miles-fee",
        MODAL_OPENER: "flight-modal-opener",
        OPEN_CHANGE_SEARCH: "flight-open-change-search",
        OPERATOR: "flight-operator",
        OPTIONS_LIST: "flight-options-list",
        SMART_FEE: "flight-smart-fee",
        SWITCH_TO_CALENDAR: "flight-switch-to-calendar",
        SWITCH_TO_CALENDAR_CONTAINER: "flight-switch-to-calendar-container",
        TICK: "flight-tick",
        TOOLTIP: "flight-tooltip",
        TOOLTIP_OPENER: "flight-tooltip-opener",
        TOO_MANY_PAX_MODAL: "flight-too-many-pax-modal",
        WEEKLY_SELECTOR: "flight-weekly-selector",
        WEEKLY_SELECTOR_DAY: "flight-weekly-selector-day",
        WEEKLY_SELECTOR_DAY_SELECTED: "flight-weekly-selector-day-selected",
        WEEKLY_SELECTOR_LEFT: "flight-weekly-selector-left",
        WEEKLY_SELECTOR_RIGHT: "flight-weekly-selector-right",
    },
    FLIGHT_DC_BANNER: {
        CONTAINER: "flight-dc-banner-container",
        GROUP_BUTTON: "flight-dc-banner-group-button",
        MEMBERSHIP_SECTION: "flight-dc-banner-membership-section",
        MEMBERSHIP_SECTIONS_CONTAINER: "flight-dc-banner-membership-sections-container",
        MEMBER_LINK: "flight-dc-banner-member-link",
        NO_DISCOUNT_LINK: "flight-dc-banner-no-discount-link",
        SAVED_AMOUNT: "flight-dc-banner-saved-amount",
        STANDARD_BUTTON: "flight-dc-banner-standard-button",
        TERMS_ACCEPTANCE_CHECKBOX: "flight-dc-banner-terms-acceptance-checkbox",
        TERMS_ACCEPTANCE_LABEL: "flight-dc-banner-terms-acceptance-label",
        TOOLTIP: "flight-dc-banner-tooltip",
        TOTAL_PRICE: "flight-dc-banner-total-price",
        TOTAL_PRICE_WITH_DC: "flight-dc-banner-total-price-with-dc",
        WITHOUT_DC: "flight-dc-banner-without-dc",
        WITHOUT_DC_CURRENCY_SIGN: "flight-dc-banner-without-dc-currency-sign",
        WITH_DC: "flight-dc-banner-with-dc",
        WITH_DC_CURRENCY_SIGN: "flight-dc-banner-with-dc-currency-sign",
    },
    FLIGHT_DC_BANNER_MODAL: {
        ACCEPT_TERMS: "flight-dc-banner-modal-accept-terms",
        ACCEPT_TERMS_ERROR: "flight-dc-banner-modal-accept-terms-error",
        ACCEPT_TERMS_LABEL: "flight-dc-banner-modal-accept-terms-label",
        AGE_ERROR: "flight-dc-banner-modal-age-error",
        BIRTH_DAY: "flight-dc-banner-modal-birth-day",
        BIRTH_MONTH: "flight-dc-banner-modal-birth-month",
        BIRTH_YEAR: "flight-dc-banner-modal-birth-year",
        CANCEL_BUTTON: "flight-dc-banner-modal-cancel-button",
        CLOSE_BUTTON: "flight-dc-banner-modal-close-button",
        CONFIRM_EMAIL: "flight-dc-banner-modal-confirm-email",
        CONFIRM_PASSWORD: "flight-dc-banner-modal-confirm-password",
        CONTINUE_BUTTON: "flight-dc-banner-modal-continue-button",
        EMAIL: "flight-dc-banner-modal-email",
        FIRST_NAME: "flight-dc-banner-modal-first-name",
        GENDER: "flight-dc-banner-modal-gender",
        LAST_NAME: "flight-dc-banner-modal-last-name",
        MEMBERSHIP: "flight-dc-banner-modal-membership",
        PASSWORD: "flight-dc-banner-modal-password",
        SCROLLER_CONTAINER: "flight-dc-banner-modal-scroller-container",
    },
    FLIGHT_CALENDAR: {
        DAY_CONTENT: "flight-calendar-day-content",
        DAY_CONTENT_DISABLED: "flight-calendar-day-content-disabled",
        DAY_CONTENT_UNAVAILABLE: "flight-calendar-day-content-unavailable",
        JOURNEY: "flight-calendar-journey",
        MONTH: "flight-calendar-month",
        MONTH_SELECTED: "flight-calendar-month-selected",
        MONTH_SELECTOR: "flight-calendar-month-selector",
        SUBMIT_BUTTON: "flight-calendar-submit-button",
    },
    FLIGHT_LOGIN_MODAL: {
        CONTINUE_BUTTON: "flight-login-modal-continue-button",
        EMAIL: "flight-login-modal-email",
        PASSWORD: "flight-login-modal-password",
        RETURN_BUTTON: "flight-login-modal-return-button",
    },
    GIFTCARD: {
        ACTIVATION_BACK_BUTTON: "giftcard-activation-back-button",
        ACTIVATION_CODE: "giftcard-activation-code",
        ACTIVATION_EMAIL: "giftcard-activation-email",
        ACTIVATION_NEXT_BUTTON: "giftcard-activation-next-button",
        ACTIVATION_PASSWORD: "giftcard-activation-password",
        ITINERARY_ACTIVATE_BUTTON: "giftcard-itinerary-activate-button",
        ITINERARY_IMAGE: "giftcard-itinerary-image",
        PAYMENT_TOGGLE_ACTIVE_GIFTCARDS: "giftcard-payment-toggle-active-giftcards",
        PAYMENT_UTILIZE_GIFTCARD: "giftcard-payment-utilize-giftcard",
        SELECT_ACCEPT_TERMS_LABEL: "giftcard-select-accept-terms-label",
        SELECT_AMOUNT: "giftcard-select-amount",
        SELECT_CURRENCY: "giftcard-select-currency",
        SELECT_QUANTITY: "giftcard-select-quantity",
        SELECT_SUBMIT: "giftcard-select-submit",
        SUCCESS_MODAL: "giftcard-success-modal",
        SUCCESS_MODAL_CLOSE_BUTTON: "giftcard-success-modal-close-button",
    },
    LOGIN_MODAL: {
        AGENCY_RADIO_BUTTON: "login-modal-agency-radio-button",
        AGENCY_RADIO_BUTTON_LABEL: "login-modal-agency-radio-button-label",
        BANCO_ESTADO_RADIO_BUTTON: "login-modal-banco-estado-radio-button",
        BANCO_ESTADO_RADIO_BUTTON_LABEL: "login-modal-banco-estado-radio-button-label",
        COMPANY_RADIO_BUTTON: "login-modal-company-radio-button",
        COMPANY_RADIO_BUTTON_LABEL: "login-modal-company-radio-button-label",
        CONTAINER: "login-modal-container",
        EMAIL: "login-modal-email",
        ERROR_MESSAGE: "login-modal-error-message",
        FORGOT_PASSWORD_BUTTON: "login-modal-forgot-password-button",
        PASSWORD: "login-modal-password",
        PERSON_RADIO_BUTTON: "login-modal-person-radio-button",
        PERU_COMPRA_RADIO_BUTTON: "login-modal-peru-compra-radio-button",
        SUBMIT_BUTTON: "login-modal-submit-button",
    },
    LOGIN_TYPE_SELECTOR: {
        BANCO_ESTADO_RADIO_BUTTON: "login-type-selector-banco-estado-radio-button",
        PERSON_RADIO_BUTTON: "login-type-selector-person-radio-button",
        PERU_COMPRA_RADIO_BUTTON: "login-type-selector-peru-compra-radio-button",
    },
    ITINERARY: {
        ARRIVAL_STATION_CODE: "itinerary-arrival-station-code",
        ARRIVAL_TIME: "itinerary-arrival-time",
        BUNDLE_NAME_PER_PAX: "itinerary-bundle-name-per-pax",
        BUNDLE_PRICE_PER_PAX: "itinerary-bundle-price-per-pax",
        DEPARTURE_STATION_CODE: "itinerary-departure-station-code",
        DEPARTURE_TIME: "itinerary-departure-time",
        DETAILS_ACCORDION_PER_PAX: "itinerary-details-accordion-per-pax",
        DETAILS_PAX_NAME: "itinerary-details-pax-name",
        FARE_LOCK_INFO: "itinerary-fare-lock-info",
        FARE_LOCK_ITINERARY_INFO: "itinerary-fare-lock-itinerary-info",
        FARE_LOCK_PAY_NOW_BUTTON: "itinerary-fare-lock-pay-now-button",
        GIFTCARD_DOWNLOAD_BUTTON: "itinerary-giftcard-download-button",
        GIFTCARD_PRINT_BUTTON: "itinerary-giftcard-print-button",
        MORE_TRANSACTIONS_BUTTON: "itinerary-more-transactions-button",
        PAX_ADDRESS: "itinerary-pax-address",
        PAX_CITY: "itinerary-pax-city",
        PAX_COUNTRY: "itinerary-pax-country",
        PAX_DETAILS_HEADER_PAX_COUNT: "itinerary-pax-details-header-pax-count",
        PAX_EMAIL: "itinerary-pax-email",
        PAX_FORM_EDIT_BUTTON: "itinerary-pax-form-edit-button",
        PAX_FORM_SUBMIT_BUTTON: "itinerary-pax-from-submit-button",
        PAX_PHONE_NUMBER: "itinerary-pax-phone-number",
        PAX_PHONE_PREFIX: "itinerary-pax-phone-prefix",
        PAX_TAB_PAX_NAME: "itinerary-pax-tab-pax-name",
        PAX_USE_SAME_DATA_FOR_ALL_CHECKBOX: "itinerary-pax-use-same-data-for-all-checkbox",
        PAX_USE_SAME_DATA_FOR_ALL_CHECKBOX_LABEL: "itinerary-pax-use-same-data-for-all-checkbox-label",
        REFUND_WIDGET_CHECKBOX_LABEL: "itinerary-refund-widget-checkbox-label",
        REFUND_WIDGET_CONTACT_FIRST_NAME: "itinerary-refund-widget-contact-first-name",
        REFUND_WIDGET_CONTACT_LAST_NAME: "itinerary-refund-widget-contact-last-name",
        REFUND_WIDGET_EMAIL_FIELD: "itinerary-refund-widget-email-field",
        REFUND_WIDGET_LOGIN_BUTTON: "itinerary-refund-widget-login-button",
        REFUND_WIDGET_LOGIN_HERE_BUTTON: "itinerary-refund-widget-login-here-button",
        REFUND_WIDGET_OPENER: "itinerary-refund-widget-opener",
        REFUND_WIDGET_PASSWORD_FIELD: "itinerary-refund-widget-password-field",
        REFUND_WIDGET_REGISTER_CONTINUE_BUTTON: "itinerary-refund-widget-continue-button",
        REFUND_WIDGET_REGISTER_TERM_CHECKBOX: "itinerary-refund-widget-register-term-checkbox",
        STANDALONE_DC_DOWNLOAD_BUTTON: "itinerary-standalone-dc-download-button",
        STANDALONE_DC_PRINT_BUTTON: "itinerary-standalone-dc-print-button",
        STANDALONE_DC_SEARCH_FLIGHT_BUTTON: "itinerary-standalone-dc-search-flight-button",
        SUCCESSFUL_TRAVEL: "itinerary-successful-travel",
        TAB: "itinerary-tab",
        TOTAL_PRICE_PER_PAX: "itinerary-total-price-per-pax",
        TRANSACTIONS_DOWNLOAD_BUTTON: "itinerary-transactions-download-button",
        TRANSACTIONS_PRINT_BUTTON: "itinerary-transactions-print-button",
        TRANSACTION_LOGGER_TABLE: "itinerary-transaction-logger-table",
        UNFILLED_FORM_HIGHLIGHT_ICON: "itinerary-unfilled-form-highlight-icon",
    },
    HEADER_MENU: {
        CONTAINER: "header-menu-container",
        LOG_OUT_BUTTON: "header-menu-log-out-button",
        REGISTER_BUTTON: "header-menu-register-button",
        USERNAME: "header-menu-username",
    },
    PASSENGERS: {
        ACCORDION_HEADER: "passengers-accordion-header",
        AGE_ERROR: "passengers-age-error",
        ASSISTANCE_ACCORDION_ICON: "passengers-assistance-accordion-icon",
        ASSISTANCE_ACCORDION_LABEL: "passengers-assistance-accordion-label",
        ASSISTANCE_CHECKBOX: "passengers-assistance-checkbox",
        ASSISTANCE_CHECKBOX_INPUT: "passengers-assistance-checkbox",
        ASSISTANCE_OPENER: "passengers-assistance-opener",
        ASSISTANCE_TITLE: "passengers-assistance-title",
        CONTACT_EMAIL: "passengers-contact-email",
        CONTACT_FIRST_NAME: "passengers-contact-first-name",
        CONTACT_LAST_NAME: "passengers-contact-last-name",
        CONTACT_OFFICE_PHONE_NUMBER: "passengers-contact-office-phone-number",
        CONTACT_OFFICE_PHONE_PREFIX: "passengers-contact-office-phone-prefix",
        CONTACT_PHONE_NUMBER: "passengers-contact-phone-number",
        CONTACT_PHONE_PREFIX: "passengers-contact-phone-prefix",
        COUNTRY: "passengers-country",
        DETAILS_PAGE: "passengers-details-page",
        DETAILS_PAGE_HEADER: "passengers-details-page-header",
        DETAILS_PAGE_HEADER_MAIN_TITLE: "passengers-details-page-header-main-title",
        DETAILS_PAGE_HEADER_SUBTITLE: "passengers-details-page-header-subtitle",
        DISCOUNT_SELECTOR: "passengers-discount-selector",
        DOB_DAY: "passengers-dob-day",
        DOB_DAY_LIST: "passengers-dob-day-list",
        DOB_MONTH: "passengers-dob-month",
        DOB_MONTH_LIST: "passengers-dob-month-list",
        DOB_SELECTOR: "passengers-dob-selector",
        DOB_YEAR: "passengers-dob-year",
        DOB_YEAR_LIST: "passengers-dob-year-list",
        DOC_EXP_DAY: "passenger-doc-exp-day",
        DOC_EXP_DAY_LIST: "passenger-doc-exp-day-list",
        DOC_EXP_MONTH: "passenger-doc-exp-month",
        DOC_EXP_MONTH_LIST: "passenger-doc-exp-month-list",
        DOC_EXP_SELECTOR: "passenger-doc-exp-selector",
        DOC_EXP_YEAR: "passenger-doc-exp-year",
        DOC_EXP_YEAR_LIST: "passenger-doc-exp-year-list",
        DOC_NUMBER: "passengers-doc-number",
        DOC_TYPE: "passengers-doc-type",
        DOC_UNIQUENESS_ERROR: "passengers-doc-uniqueness-error",
        DUPLICATE_NAME_ERROR: "passengers-duplicate-name-error",
        EMAIL: "passengers-email",
        FARELOCK_INFO: "passengers-farelock-info",
        FARELOCK_PASSENGER: "passengers-farelock-passenger",
        FIRST_NAME: "passengers-first-name",
        FREQUENT_FLYER_NUMBER: "passengers-frequent-flyer-number",
        LAST_NAME: "passengers-last-name",
        OFFICE_PHONE_NUMBER: "passengers-office-phone-number",
        OFFICE_PHONE_PREFIX: "passengers-office-phone-prefix",
        PAGE: "passengers-page",
        PAYING_FARELOCK_INFO: "passengers-paying-farelock-info",
        PHONE: "passengers-phone",
        PHONE_PREFIX: "passengers-phone-prefix",
        RUT: "passengers-rut",
        SAVE_BUTTON: "passengers-save-button",
        SUBMIT_BUTTON: "passengers-submit-button",
        TITLE: "passenger-title",
    },
    PAYMENT: {
        CARD_CVV: "payment-card-cvv",
        CARD_HOLDER_NAME: "payment-card-holder-name",
        CARD_ISSUER_COUNTRY: "payment-card-issuer-country",
        CARD_NUMBER: "payment-card-number",
        CARD_TYPE: "payment-card-type",
        CUIT_INPUT: "payment-cuit-input",
        EXECUTE_TEST_PAYMENT_BUTTON: "payment-execute-test-payment-button",
        EXPIRY_MONTH: "payment-expiry-month",
        EXPIRY_YEAR: "payment-expiry-year",
        GIFTCARD_OPENER: "payment-giftcard-opener",
        METHOD_SELECTOR_ICON: "payment-method-selector-icon",
        METHOD_SELECTOR_ICON_LABEL: "payment-method-selector-icon-label",
        MM_WARNING: "payment-mm-warning",
        MUST_COMPLETE_MESSAGE: "payment-must-complete-message",
        NON_CHILE_LINK: "payment-non-chile-link",
        PAGE: "payment-page",
        SUBMIT_PAYMENT_BUTTON: "payment-submit-payment-button",
        TERMS_CHECKBOX_LABEL: "payment-terms-checkbox-label",
        VOUCHER_TOOLTIP: "payment-voucher-tooltip",
    },
    PAYMENT_CONTACT_FORM: {
        COPY_CHECKBOX: "payment-contact-form-copy-checkbox",
        COPY_CHECKBOX_LABEL: "payment-contact-form-copy-checkbox-label",
        EMAIL: "payment-contact-form-email",
        FIRST_NAME: "payment-contact-form-first-name",
        HOMEPHONE_NUMBER: "payment-contact-form-homephone-number",
        HOMEPHONE_PREFIX: "payment-contact-form-homephone-prefix",
        LAST_NAME: "payment-contact-form-last-name",
        RUC: "payment-contact-form-ruc",
    },
    PAYMENT_INVOICE_FORM: {
        ADDRESS: "payment-invoice-form-address",
        CITY: "payment-invoice-form-city",
        COMPANY_NAME: "payment-invoice-form-company-name",
        ECUADOR_COMPANY_ID: "payment-invoice-form-ecuador-company-id",
        EMAIL: "payment-invoice-form-email",
        EMAIL_COST_CENTER: "payment-invoice-form-email-cost-center",
        GIRO: "payment-invoice-form-giro",
        POSTAL_CODE: "payment-invoice-form-postal-code",
        REGION: "payment-invoice-form-region",
        RUT_COMPANY: "payment-invoice-form-rut-company",
        SELECT: "payment-invoice-form-select",
    },
    REGISTER: {
        ACCEPT_TERMS_CHECKBOX: "register-accept-terms-checkbox",
        BIRTH_DAY: "register-birth-day",
        BIRTH_MONTH: "register-birth-month",
        BIRTH_YEAR: "register-birth-year",
        COUNTRY_CODE: "register-country-code",
        EMAIL: "register-email",
        EMAIL_CONFIRMATION: "register-email-confirmation",
        FIRST_NAME: "register-first-name",
        FORM: "register-form",
        GENDER: "register-gender",
        LAST_NAME: "register-last-name",
        MAIN_CONTENT_CONTAINER: "register-main-content-container",
        MAIN_TITLE: "register-main-title",
        PASSWORD: "register-password",
        PASSWORD_CONFIRMATION: "register-password-confirmation",
        PHONE_NUMBER: "register-phone-number",
        PHONE_PREFIX: "register-phone-prefix",
        REGION_OR_CITY: "register-region-or-city",
        REGISTER_DESCRIPTIONS_LIST: "register-description-list",
        SUBMIT_BUTTON: "register-submit-button",
    },
    REFUND_BANK_INFO_MODAL: {
        ACCOUNT_NUMBER_INPUT: "refund-bank-info-modal-account-number-input",
        BANK_NAME_INPUT: "refund-bank-info-modal-bank-name-input",
        CARD_TYPE_INPUT: "refund-bank-info-modal-card-type-input",
        CLOSE_BUTTON: "refund-bank-info-modal-close-button",
        CONTAINER: "refund-bank-info-modal-container",
        COUNTRY_INPUT: "refund-bank-info-modal-country-input",
        DOCUMENT_NUMBER_INPUT: "refund-bank-info-modal-document-number-input",
        DOCUMENT_TYPE_INPUT: "refund-bank-info-modal-document-type-input",
        SUBMIT_BUTTON: "refund-bank-info-modal-submit-button",
    },
    SEARCH: {
        ADULTS_DECREASE: "search-adults-decrease",
        ADULTS_INCREASE: "search-adults-increase",
        ADULT_AMOUNT: "search-adult-amount",
        CHILDREN_DECREASE: "search-children-decrease",
        CHILDREN_INCREASE: "search-children-increase",
        CHILD_AMOUNT: "search-child-amount",
        CITY: "search-city",
        CONTAINER: "search-container",
        COUNTRY: "search-country",
        CURRENCY_ITEM: "search-currency-item",
        CURRENCY_SELECTOR: "search-currency-selector",
        DESTINATION: "search-destination",
        DESTINATION_INPUT: "search-destination-input",
        DESTINATION_OPTIONS: "search-destination-options",
        INBOUND_DATE: "search-inbound-date",
        INFANTS_DECREASE: "search-infants-decrease",
        INFANTS_INCREASE: "search-infants-increase",
        INFANT_AMOUNT: "search-infant-amount",
        ONE_WAY_TRIP: "search-one-way-trip",
        ONE_WAY_TRIP_INPUT: "search-one-way-trip-input",
        ORIGIN: "search-origin",
        ORIGIN_INPUT: "search-origin-input",
        ORIGIN_OPTIONS: "search-origin-options",
        OUTBOUND_DATE: "search-outbound-date",
        REDEMPTION_SEARCH: "search-redemption-search",
        REDEMPTION_SEARCH_INPUT: "search-redemption-search-input",
        ROUND_TRIP: "search-round-trip",
        ROUND_TRIP_INPUT: "search-round-trip-input",
        SUBMIT_BUTTON: "search-submit-button",
    },
    SEATMAP: {
        APPLY_RECOMMENDATION_BUTTON_DESKTOP: "seatmap-apply-recommendation-button-desktop",
        APPLY_RECOMMENDATION_BUTTON_MOBILE: "seatmap-apply-recommendation-button-mobile",
        BANCO_ESTADO_56_PASSENGER_NAME_AND_SEAT_CONTAINER: "seatmap-banco-estado-56-passenger-name-and-seat-container",
        BANCO_ESTADO_56_PASSENGER_SEAT_NUMBER: "seatmap-banco-estado-56-passenger-seat-number",
        BANCO_ESTADO_56_SEAT_DELETE_BUTTON: "seatmap-banco-estado-56-seat-delete-button",
        CONTINUE_BUTTON: "seatmap-continue-button",
        DIRECTION_SWITCH: "seatmap-direction-switch",
        EMERGENCY_SEAT_CHECKBOX: "seatmap-emergency-seat-checkbox",
        EMERGENCY_SEAT_CHECKBOX_WARNING: "seatmap-emergeny-seat-checkbox-warning",
        EMERGENCY_WARNING_ACCEPT_TERMS_TEXT: "seatmap-emergency-warning-accept-terms-text",
        EMERGENCY_WARNING_LINK: "seatmap-emergency-warning-link",
        EMERGENCY_WARNING_MODAL: "seatmap-emergency-warning-modal",
        EMERGENCY_WARNING_MODAL_CLOSE_BUTTON: "seatmap-emergency-warning-modal-close-button",
        JOURNEY_PLANE: "seatmap-journey-plane",
        JOURNEY_PRICE: "seatmap-journey-price",
        JOURNEY_PRICE_DIFFERENCE: "seatmap-journey-price-difference",
        MOBILE_PASSENGER_NAME_AND_SEAT_CONTAINER: "seatmap-mobile-passenger-name-and-seat-container",
        MOBILE_PASSENGER_SEAT_NUMBER: "seatmap-mobile-passenger-seat-number",
        MODAL_CONTINUE_BUTTON: "seatmap-modal-continue-button",
        NON_BANCO_ESTADO_56_PASSENGER_NAME_AND_SEAT_CONTAINER:
            "seatmap-non-banco-estado-56-passenger-name-and-seat-container",
        NON_BANCO_ESTADO_56_PASSENGER_SEAT_NUMBER: "seatmap-non-banco-estado-56-passenger-seat-number",
        NON_BANCO_ESTADO_56_SEAT_DELETE_BUTTON: "seatmap-non-banco-estado-56-seat-delete-button",
        NO_AUTO_ASSIGNED_SEATS_MODAL: "seatmap-no-auto-assigned-modal",
        NO_AUTO_ASSIGNED_SEATS_MODAL_OK_BUTTON: "seatmap-no-auto-assigned-modal-ok-button",
        NO_SEAT_SELECTION_BUTTON: "seatmap-no-seat-selection-button",
        PAGE: "seatmap-page",
        PLANE_INSIDE: "seatmap-plane-inside",
        QUIT_RECOMMENDATION_BUTTON_DESKTOP: "seatmap-quit-recommendation-button-desktop",
        QUIT_RECOMMENDATION_BUTTON_MOBILE: "seatmap-quit-recommendation-button-mobile",
        RECOMMENDATOR_BANNER: "seatmap-recommendator-banner",
        RESET_SEATS_BUTTON: "seatmap-reset-seats-button",
        REVIEW_BACK_BUTTON_MOBILE: "seatmap-review-back-button-mobile",
        REVIEW_BUTTON_MOBILE: "seatmap-review-button",
        REVIEW_INBOUND_ROUTE_MOBILE: "seatmap-inbound-route-mobile",
        REVIEW_MAP_CONTINUE_BUTTON_MOBILE: "seatmap-review-continue-button-mobile",
        REVIEW_NO_SEAT_BUTTON_MOBILE: "seatmap-review-no-seat-button-mobile",
        REVIEW_OUTBOUND_ROUTE_MOBILE: "seatmap-outbound-route-mobile",
        REVIEW_SEATS_CONFIRM_BUTTON_MOBILE: "seatmap-review-seats-confirm-button-mobile",
        REVIEW_SEAT_TOTAL_MOBILE: "seatmap-review-seat-total-mobile",
        ROW: "seatmap-row",
        SAME_RETURN_SEATS_BUTTON: "seatmap-same-return-seats-button",
        SAME_RETURN_SEATS_CANCEL_BUTTON: "seatmap-same-return-seats-cancel-button",
        SAME_RETURN_SEATS_SHOW_BUBBLE: "seatmap-same-return-seats-show-bubble",
        SEAT: "seatmap-seat",
        SEAT_FOR_ALL_ARE_YOU_SURE_MODAL: "seatmap-seat-for-all-are-you-sure-modal",
        SEAT_LETTER: "seatmap-seat-letter",
        SEAT_TOOLTIP: "seatmap-seat-tooltip",
        SEAT_TOOLTIP_CATEGORY: "seatmap-seat-tooltip-category",
        SEAT_TOOLTIP_PRICE: "seatmap-seat-tooltip-price",
        SEAT_TOOLTIP_PRICE_DIFFERENCE: "seatmap-seat-tooltip-price-difference",
        SEAT_TOOLTIP_SELECT_BUTTON_MOBILE: "seatmap-seat-tooltip-select-button-mobile",
        SEAT_WINDOWLESS_WARNING: "seatmap-seat-windowless-warning",
    },
    SIDEBAR: {
        ARRIVAL_STATION_CODE: "sidebar-arrival-station-code",
        ARRIVAL_TIME: "sidebar-arrival-time",
        BANCO_ESTADO_DISCOUNTS: "sidebar-banco-estado-discounts",
        BANCO_ESTADO_DISCOUNTS_PRICE: "sidebar-banco-estado-discounts-price",
        BANCO_ESTADO_DISCOUNT_ITEM: "sidebar-banco-estado-discount-item",
        BANCO_ESTADO_DISCOUNT_ITEM_PRICE: "sidebar-banco-estado-discount-item-price",
        BOOKING_BREADCRUMB: "sidebar-booking-breadcrumb",
        BOOKING_BREADCRUMBS: "sidebar-booking-breadcrumbs",
        BOOKING_BREADCRUMB_PLANE_ICON: "sidebar-booking-breadcrumb-plane-icon",
        BOOKING_DETAILS_JOURNEY_DATE: "sidebar-booking-details-journey-date",
        BOOKING_DETAILS_JOURNEY_TIME: "sidebar-booking-details-journey-time",
        BOOKING_DETAILS_TITLE: "sidebar-booking-details-title",
        BREAKDOWN: "sidebar-breakdown",
        BREAKDOWN_CONTAINER: "sidebar-breakdown-container",
        BUNDLE: "sidebar-bundle",
        BUNDLE_ITEM_NAME: "sidebar-bundle-item-name",
        BUNDLE_ITEM_PRICE: "sidebar-bundle-item-price",
        BUNDLE_NAME: "sidebar-bundle-name",
        BUNDLE_PRICE: "sidebar-bundle-price",
        CHECKIN_BREADCRUMB: "sidebar-checkin-breadcrumb",
        CHECKIN_BREADCRUMBS: "sidebar-checkin-breadcrumbs",
        CONTENT_CONTAINER: "sidebar-content-container",
        CURRENCY_SWITCH: "sidebar-currency-switch",
        DC_DISCOUNTS: "sidebar-dc-discounts",
        DC_DISCOUNTS_PRICE: "sidebar-dc-discounts-price",
        DC_DISCOUNT_ITEM: "sidebar-dc-discount-item",
        DC_DISCOUNT_ITEM_PRICE: "sidebar-dc-discount-item-price",
        DEPARTURE_STATION_CODE: "sidebar-departure-station-code",
        DEPARTURE_TIME: "sidebar-departure-time",
        FARELOCK: "sidebar-farelock",
        FARELOCK_PRICE: "sidebar-farelock-price",
        FARELOCK_PRICE_LABEL: "sidebar-farelock-price-label",
        FARES: "sidebar-fares",
        FARES_ITEM: "sidebar-fares-item",
        FARES_ITEM_PRICE: "sidebar-fares-item-price",
        FARES_PRICE: "sidebar-fares-price",
        MOBILE_OPENER: "sidebar-mobile-opener",
        OPTIONALS: "sidebar-optionals",
        OPTIONALS_PRICE: "sidebar-optionals-price",
        OPTIONAL_ITEM: "sidebar-optional-item",
        OPTIONAL_ITEM_PRICE: "sidebar-optional-item-price",
        PASSENGER: "sidebar-passenger",
        PASSENGER_NAME: "sidebar-passenger-name",
        PAX_COUNT: "sidebar-pax-count",
        PAX_TOTAL: "sidebar-pax-total",
        PAX_TOTAL_PRICE: "sidebar-pax-total-price",
        PERU_COMPRA_PAX_DISCOUNT: "sidebar-peru-compra-pax-discount",
        PERU_COMPRA_PAX_DISCOUNT_VALUE: "sidebar-peru-compra-pax-discount-value",
        PROMO_CODE_ACCEPTED_ICON: "sidebar-promo-code-accepted-icon",
        PROMO_CODE_BOX: "sidebar-promo-code-box",
        PROMO_CODE_BUTTON: "sidebar-promo-code-button",
        PROMO_CODE_HEADER: "sidebar-promo-code-header",
        PROMO_CODE_ICON: "sidebar-promo-code-icon",
        PROMO_CODE_INPUT: "sidebar-promo-code-input",
        PROMO_CODE_OPENER_INPUT: "sidebar-promo-code-opener-input",
        PROMO_DISCOUNTS: "sidebar-promo-discounts",
        PROMO_DISCOUNTS_PRICE: "sidebar-promo-discounts-price",
        PROMO_DISCOUNT_ITEM: "sidebar-promo-discount-item",
        PROMO_DISCOUNT_ITEM_PRICE: "sidebar-promo-discount-item-price",
        SEPARATE_CHARGE: "sidebar-separate-charge",
        SEPARATE_CHARGE_ITEM: "sidebar-separate-charge-item",
        SEPARATE_CHARGE_ITEM_PRICE: "sidebar-separate-charge-item-price",
        SEPARATE_CHARGE_PRICE: "sidebar-separate-charge-price",
        TAX_MESSAGE: "sidebar-tax-message",
        TOTAL: "sidebar-total",
        TOTAL_AMOUNT_LABEL: "sidebar-total-amount-label",
        TOTAL_AMOUNT_VALUE: "sidebar-total-amount-value",
        TOTAL_AMOUNT_VALUE_WITH_CURRENCY_SIGN: "sidebar-total-amount-value-with-currency-sign",
        TOTAL_CURRENCY: "sidebar-total-currency",
        TOTAL_MILES_LABEL: "sidebar-total-miles-label",
        TOTAL_MILES_VALUE: "sidebar-total-miles-value",
        TOTAL_MOBILE: "sidebar-total-mobile",
    },
    MULTILEG_MODAL: {
        CONTAINER: "multileg-modal-container",
        OPENER: "multileg-modal-opener",
    },
    // TODO replace to right login ids
    BOOKING_LOGIN: {
        AGENCY_RADIO_BUTTON: "booking-login-agency-radio-button",
        BUTTON: "booking-login-button",
        BUTTON_CONTAINER: "booking-login-button-container",
        MODAL: "booking-login-modal",
        STATUS_BUTTON: "booking-login-status-button",
    },
    CUG2_LOGIN: {
        BUTTON: "cug2-login-button",
        EMAIL_OR_USERNAME_INPUT: "cug2-login-email-or-username-input",
        FORM: "cug2-login-form",
        INVALID_AGENT_TYPE_REDIRECT_LINK: "cug2-login-invalid-agent-type-redirect-link",
        PASSWORD_INPUT: "cug2-login-password-input",
        REGISTER_BUTTON: "cug2-login-register-button",
    },
    CUG2_REGISTER: {
        ADDRESS: "cug2-register-address",
        ADMIN_CONFIRM_PASSWORD: "cug2-register-admin-confirm-password",
        ADMIN_CONTINUE_BUTTON: "cug2-register-admin-continue-button",
        ADMIN_COUNTRY: "cug2-register-admin-country",
        ADMIN_DOCUMENT_NUMBER: "cug2-register-admin-document-number",
        ADMIN_EMAIL: "cug2-register-admin-email",
        ADMIN_FIRST_NAME: "cug2-register-admin-first-name",
        ADMIN_HEADER: "cug2-register-admin-header",
        ADMIN_LAST_NAME: "cug2-register-admin-last-name",
        ADMIN_PASSWORD: "cug2-register-admin-password",
        ADMIN_PHONE_NUMBER: "cug2-register-admin-phone-number",
        ADMIN_PHONE_PREFIX: "cug2-register-admin-phone-prefix",
        ADMIN_USERNAME: "cug2-register-admin-username",
        AGENCY_CHECKBOX: "cug2-register-agency-checkbox",
        BAR: "cug2-register-bar",
        BAR_SECTION: "cug2-register-bar-section",
        BUSINESS_NAME: "cug2-register-business-name",
        BUSINESS_TYPE: "cug2-register-business-type",
        CITY: "cug2-register-city",
        COMPANY_CHECKBOX: "cug2-register-company-checkbox",
        CONTAINER: "cug2-register-container",
        CONTINUE_BUTTON: "cug2-register-continue-button",
        COUNTRY: "cug2-register-country",
        CURRENCY: "cug2-register-currency",
        FORM: "cug2-register-form",
        HEADER: "cug2-register-header",
        INVOICE_EMAIL: "cug2-register-invoice-email",
        REGION: "cug2-register-region",
        SUMMARY_ACCEPT_TERMS_CHECKBOX: "cug2-register-summary-accept-terms-checkbox",
        SUMMARY_ACCEPT_TERMS_CONTAINER: "cug2-register-summary-accept-terms-container",
        SUMMARY_ADMIN_SECTION: "cug2-register-summary-admin-section",
        SUMMARY_CONTAINER: "cug2-register-summary-container",
        SUMMARY_CUG_SECTION: "cug2-register-summary-cug-section",
        SUMMARY_HEADER: "cug2-register-summary-header",
        SUMMARY_INVOICE_WARNING: "cug2-register-summary-invoice-warning",
        TAX_NUMBER: "cug2-register-tax-number",
    },
    CUG2_SUMMARY: {
        AGENCY_INFO_MODAL: "cug2-summary-agency-info-modal",
        AGENCY_INFO_MODAL_MOBILE_IMAGE: "cug2-summary-agency-info-modal-mobile-image",
        AMOUNT_ERROR_ON_MODAL_1: "cug2-summary-amount-error-1",
        AMOUNT_ERROR_ON_MODAL_2: "cug2-summary-amount-error-2",
        AMOUNT_TO_PAY_INPUT_FIELD: "cug2-summary-amount-to-pay-input-field",
        EDIT_BUTTON: "cug2-summary-edit-button",
        MODAL_PAY_BUTTON: "cug2-summary-modal-pay-button",
        PAY_BUTTON: "cug2-summary-pay-button",
    },
    CUG2_AGENCY_EDIT: {
        ADDRESS_INPUT_FIELD: "cug2-agency-edit-address-input-field",
        CITY_INPUT_FIELD: "cug2-agency-edit-city-input-field",
        COUNTRY_SELECTOR: "cug2-agency-edit-country-selector",
        CURRENCY_INPUT_FIELD: "cug2-agency-edit-currency-input-field",
        EMAIL_INPUT_FIELD: "cug2-agency-edit-email-input-field",
        GIRO_INPUT_FIELD: "cug2-giro-input-field",
        NAME_INPUT_FIELD: "cug2-agency-edit-name-input-field",
        REGION_INPUT_FIELD: "cug2-agency-edit-region-input-field",
        REGION_SELECTOR: "cug2-agency-edit-region-selector",
        RUT_INPUT_FIELD: "cug2-agency-edit-rut-input-field",
        SAVE_BUTTON: "cug2-agency-edit-save-button",
        SAVE_MESSAGE: "cug2-agency-edit-save-message",
    },
    CUG2_USER_EDIT: {
        COUNTRY_SELECTOR: "cug2-user-edit-country-selector",
        EMAIL_INPUT_FIELD: "cug2-user-edit-email-input-field",
        FIRST_NAME_INPUT_FIELD: "cug2-user-edit-first-name-input-field",
        LAST_NAME_INPUT_FIELD: "cug2-user-edit-last-name-input-field",
        PHONE_INPUT_FIELD: "cug2-user-edit-phone-input-field",
        SAVE_BUTTON: "cug2-user-edit-save-button",
        SAVE_MESSAGE: "cug2-user-edit-save-message",
        USER_NAME: "cug2-user-edit-user-name",
    },
    CUG2_CHANGE_PASSWORD: {
        CONFIRM_PASSWORD_INPUT_FIELD: "cug2-change-password-confirm-password-input-field",
        NEW_PASSWORD_INPUT_FIELD: "cug2-change-password-new-password-input-field",
        SAVE_BUTTON: "cug2-change-password-save-button",
    },
    CUG2_COMMON: {
        MODAL_CLOSE_BUTTON: "cug2-common-modal-close-button",
    },
    CUG2_HEADER: {
        HAMBURGER_MENU: "cug2-header-hamburger-menu",
        HELP_DROPDOWN: "cug2-header-help-dropdown",
        INFO_DROPDOWN: "cug2-header-info-dropdown",
        LOGOUT_USER_OPTION: "cug2-header-logout-user-option",
        USER_DROPDOWN: "cug2-header-user-dropdown",
    },
    CUG2_SIDEBAR: {
        BOOKINGS: "cug2-sidebar-bookings",
        CONTACT: "cug2-sidebar-contact",
        FREQUENT_USERS: "cug2-sidebar-frequent-users",
        GO_HOME_BUTTON: "cug2-sidebar-go-home-button",
        PROFILE: "cug2-sidebar-profile",
        RESERVATIONS: "cug2-sidebar-reservations",
        SUMMARY: "cug2-sidebar-summary",
        USERS: "cug2-sidebar-users",
    },
    FORGOT_PASSWORD: {
        BUTTON_CONTAINER: "forgot-password-button-container",
        CANCEL_BUTTON: "forgot-password-cancel-button",
        CONTINUE_BUTTON: "forgot-password-continue-button",
        EMAIL_INPUT_FIELD: "forgot-password-email-input-field",
        FORM_CAPTCHA: "forgot-password-form-captcha",
        INSTRUCTIONS: "forgot-password-instructions",
        TITLE: "forgot-password-title",
    },
    PASSWORD_RESET: {
        OLD_PASSWORD_INPUT_FIELD: "password-reset-old-password-input-field",
        PASSWORD_CONFIRMATION_INPUT_FIELD: "password-reset-confirm-password-input-field",
        PASSWORD_INPUT_FIELD: "password-reset-password-input-field",
        SUBMIT_BUTTON: "password-reset-submit-button",
        USERNAME_INPUT_FIELD: "password-reset-username-input-field",
    },
    CUG2_TRAVEL_PARTNERS: {
        ADD_PASSENGER_BUTTON: "cug2-travel-partners-add-passenger-button",
        BIG_GROUP_BUTTON: "cug2-travel-partners-big-group-button",
        GROUP_PASSENGERS_BUTTON: "cug2-travel-partners-group-passengers-button",
        REMOVE_BUTTON: "cug2-travel-partners-remove-button",
    },
    CUG2_USERS: {
        ADD_USER_BUTTON: "cug2-users-add-user-button",
        ADD_USER_MODAL_EMAIL_INPUT_FIELD: "cug2-users-add-user-modal-email-input-field",
        ADD_USER_MODAL_FIRST_NAME_INPUT_FIELD: "cug2-users-add-user-modal-first-name-input-field",
        ADD_USER_MODAL_FORM: "cug2b-add-user-modal-form",
        ADD_USER_MODAL_LAST_NAME_INPUT_FIELD: "cug2-users-add-user-modal-last-name-input-field",
        ADD_USER_MODAL_MOBILE_PHONE_INPUT_FIELD: "cug2-users-add-user-modal-mobile-phone-input-field",
        ADD_USER_MODAL_NATIONALITY_SELECTOR: "cug2-users-add-user-nationality-selector",
        ADD_USER_MODAL_PHONE_INPUT_FIELD: "cug2-users-add-user-modal-phone-input-field",
        ADD_USER_MODAL_ROLE_OPTION_SELECTOR: "cug2-users-add-user-role-option-selector",
        ADD_USER_MODAL_SUBMIT_BUTTON: "cug2-users-add-user-modal-submit-button",
        ADD_USER_MODAL_TAX_NUMBER_INPUT_FIELD: "cug2-users-add-user-modal-tax-number-input-field",
        ADD_USER_MODAL_USER_NAME_INPUT_FIELD: "cug2-users-add-user-modal-user-name-input-field",
    },
    CUG2_ADD_TRAVEL_PARTNER: {
        BACK_BUTTON: "cug2-add-travel-partner-back-button",
        CANCEL_BUTTON: "cug2-add-travel-partner-cancel-button",
        DAY_SELECTOR: "cug2-add-travel-partner-day-selector",
        DOCUMENT_NUMBER_INPUT_FIELD: "cug2-add-travel-partner-document-number-input-field",
        DOCUMENT_TYPE_SELECTOR: "cug2-add-travel-partner-document-type-selector",
        FIRST_NAME_INPUT_FIELD: "cug2-add-travel-partner-first-name-input-field",
        GROUP_SELECTOR: "cug2-add-travel-partner-group-selector",
        LAST_NAME_INPUT_FIELD: "cug2-add-travel-partner-last-name-input-field",
        MONTH_SELECTOR: "cug2-add-travel-partner-month-selector",
        NATIONALITY_SELECTOR: "cug2-add-travel-partner-nationality-selector",
        NICKNAME_INPUT_FIELD: "cug2-add-travel-partner-nickname-input-field",
        SUBMIT_BUTTON: "cug2-add-travel-partner-submit-button",
        YEAR_SELECTOR: "cug2-add-travel-partner-year-selector",
    },
    FLIGHT_MOVE: {
        FLIGHT_MOVE_TERMS_CHECKBOX: "flight-move-terms-checkbox",
        FLIGHT_MOVE_TERMS_CHECKBOX_LABEL: "flight-move-terms-checkbox-label",
        NO_LONGER_AVAILABLE_MODAL: "flight-move-no-longer-available-modal",
        NO_LONGER_AVAILABLE_MODAL_NEW_SEARCH_BUTTON: "flight-move-no-longer-available-modal-new-search-button",
        NO_LONGER_AVAILABLE_MODAL_OTHER_OPTIONS_BUTTON: "flight-move-no-longer-available-modal-other-options-button",
    },
    PERU_COMPRA_USER_EDIT: {
        DNI: "peru-compra-user-edit-dni",
        MOBILE_PHONE_INPUT_FIELD: "peru-compra-user-edit-mobile-phone-input-field",
        OFFICE_PHONE_INPUT_FIELD: "peru-compra-user-edit-office-phone-input-field",
    },
    PERU_COMPRA_USER_ADD: {
        OFFICE_PHONE_INPUT_FIELD: "peru-compra-user-add-office-phone-input-field",
    },
    PERU_COMPRA_PAYMENT: {
        PERU_COMPRA_LOW_BALANCE_MODAL_ACCEPT_BUTTON: "peru-compra-low-balance-modal-accept-button",
        PERU_COMPRA_LOW_BALANCE_MODAL_CLOSE_BUTTON: "peru-compra-low-balance-modal-close-button",
    },
    PERU_COMPRA_MODAL: {
        LOGIN_INFO_MODAL: "peru-compra-modal-login-info-modal",
        LOGIN_INFO_MODAL_ACCEPT_BUTTON: "peru-compra-modal-login-info-accept-button",
    },
    BOOKING_OPENER: {
        EMAIL_INPUT_FIELD: "booking-opener-email-input-field",
        PNR_INPUT_FIELD: "booking-opener-pnr-input-field",
        SUBMIT_CHECKIN_BUTTON: "booking-opener-submit-checkin-button",
    },
    ITINERARY_MODIFY_TAB: {
        CHANGE_PASSENGER_DATA_BUTTON: "itinerary-modify-tab-change-passenger-data-button",
    },
    MODIFY_BOOKING: {
        REVIEW_SUBMIT_BUTTON: "modify-booking-review-submit-button",
    },
    BANCOE_ESTADO_BAR: {
        UPGRADE_MEMBERSHIP_INPUT: "banco-estado-bar-upgrade-membership-input",
    },
};

interface TestIdOptions {
    j?: number; // journeyIndex
    p?: number; // paxIndex
    c?: string; // code
    i?: number; // index
    s?: number; // segment
    m?: boolean; // isMobile
}

const nameSeparator = "--";
const separator = "-";
const journeyIndexPrefix = "j|";
const paxIndexPrefix = "p|";
const codePrefix = "c|";
const indexPrefix = "i|";
const segmentPrefix = "s|";
const mobilePrefix = "m|";

export function getTestId(name: string, options: TestIdOptions = {}): string {
    let retVal = "";
    retVal += options.j !== undefined ? `${retVal ? separator : ""}${journeyIndexPrefix}${options.j}` : "";
    retVal += options.p !== undefined ? `${retVal ? separator : ""}${paxIndexPrefix}${options.p}` : "";
    retVal += options.c !== undefined ? `${retVal ? separator : ""}${codePrefix}${options.c}` : "";
    retVal += options.i !== undefined ? `${retVal ? separator : ""}${indexPrefix}${options.i}` : "";
    retVal += options.s !== undefined ? `${retVal ? separator : ""}${segmentPrefix}${options.s}` : "";
    retVal += options.m === true ? `${retVal ? separator : ""}${mobilePrefix}1` : "";

    return [name, retVal].filter((i) => i).join(nameSeparator);
}

export function decodeTestId(id: string) {
    const [name, rest] = id.split(nameSeparator);
    const args = rest.split(separator);

    const journeyIndex = args.find((a) => a.startsWith(journeyIndexPrefix))?.substring(journeyIndexPrefix.length);
    const paxIndex = args.find((a) => a.startsWith(paxIndexPrefix))?.substring(paxIndexPrefix.length);
    const code = args.find((a) => a.startsWith(codePrefix))?.substring(codePrefix.length);
    const index = args.find((a) => a.startsWith(indexPrefix))?.substring(indexPrefix.length);
    const segment = args.find((a) => a.startsWith(segmentPrefix))?.substring(segmentPrefix.length);
    const isMobile = args.some((a) => a.startsWith(mobilePrefix));

    return { name, journeyIndex, paxIndex, code, index, isMobile, segment };
}
