import i18next from "i18next";
import { useEffect, useMemo, useState } from "../../../shared/haunted/CustomHooks";
import { HauntedFunc } from "../../../shared/haunted/HooksHelpers";
import { html, useRef } from "haunted";
import { Partner } from "../../../component-models/CUG2b/Partner";
import { commonValidationRules } from "../../../shared/commonValidationRules";
import { ref } from "../../../directives/ref";
import { sanitizeRutFieldValue, updateMdl } from "../../../shared/common";
import { TravelPartnerInfo } from "../../../component-models/CUG2b/TravelPartnerInfo";
import dayjs, { Dayjs } from "dayjs";
import * as CustomParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(CustomParseFormat);
import { Cug2bDocumentType } from "../../../component-models/checkin/TravelDocument";
import { classMap } from "lit-html/directives/class-map";
import { sanitizeInputFieldValue } from "../../../component-helpers/InputSanitizerHelper";
import { useForm } from "../../../shared/customHooks/useForm/useForm";
import { InputFieldAttribute } from "../../../shared/customHooks/useForm/InputFieldAttribute";
import { unconditionalRut } from "../../../shared/customHooks/useForm/custom-attributes/unconditionalRut";
import { TestIdDictionary as T } from "../../../testing-helpers/TestIdHelper";
import { URL_VARS } from "../../../shared/commonConstants";
import { useCug2AppContext } from "../../../managers/useCug2AppContext";
import { useCompactDatepicker } from "../../ui/useCompactDatepicker/useCompactDatepicker";
import { dni } from "../../../shared/customHooks/useForm/custom-attributes/dni";

export const observedAttributes: (keyof Attributes)[] = [] as any;
export const name = "ac-travel-partner";
export interface Attributes {}

export interface Properties {
    model: TravelPartnerInfo;
    showCancelButton: boolean;
    showBackButton: boolean;
    vm: Partner;
    handleEditSubmit: (partner: Partner) => Promise<void>;
    setIsModalOpen?: (isOpen: boolean) => void;
}

export const Component: HauntedFunc<Properties> = (host) => {
    const props: Properties = {
        model: host.model,
        showCancelButton: host.showBackButton,
        showBackButton: host.showBackButton,
        vm: host.vm,
        handleEditSubmit: host.handleEditSubmit,
        setIsModalOpen: host.setIsModalOpen,
    };

    const customValidationRules = (): InputFieldAttribute[] => [unconditionalRut(), dni()];

    const getInitialGroupId = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const preselectedGroupId = urlParams.get(URL_VARS.FFLYER_GROUP_ID);

        return preselectedGroupId ? Number(preselectedGroupId) : props.vm?.GroupId;
    };

    const handleGroupIdChange = (e: MouseEvent) => {
        const value = (e.target as HTMLSelectElement).value;

        setGroupId(value ? Number(value) : undefined);

        setButtonEnabled(true);
    };

    const handleSubmit = async (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const isFormValid = await form.validate();

        const docNumberIsNotUnique = props.model.Partners.some(
            (p) =>
                p.DocumentId &&
                documentId &&
                p.DocumentId.toLowerCase().trim() === documentId?.toLowerCase().trim() &&
                p.Id !== props.vm?.Id,
        );

        if (docNumberIsNotUnique) {
            setShowUniquenessError(true);
            return;
        }

        if (isFormValid) {
            const newModel: Partner = {
                ...props.vm,
                Alias: alias,
                DOB: dateOfBirth,
                DocumentId: documentId,
                DocumentType: documentType,
                FirstName: firstName,
                GroupId: groupId,
                LastName: lastName,
                Nationality: nationality,
            };

            await props.handleEditSubmit(newModel);
        }
    };

    const handleDocNumberInput = (e?: KeyboardEvent) => {
        setShowUniquenessError(false);

        if (documentType === "RUT") {
            sanitizeRutFieldValue(docNumberField.current);
            setDocumentId(docNumberField.current.value);
            handleDocNumberChange();
        } else if (e) {
            const sanitizedDocFieldValue = sanitizeInputFieldValue(e, "travel-document-id");
            setDocumentId(sanitizedDocFieldValue);
            handleDocNumberChange();
        }
    };

    const handleDocNumberChange = () => {
        const previousDocumentId = props.vm?.DocumentId || "";
        if (docNumberField.current.value !== previousDocumentId) {
            setButtonEnabled(true);
        }
    };

    const handleFirstNameInput = (e: KeyboardEvent) => {
        const sanitizedInput = sanitizeInputFieldValue(e, "accepted-text-chars");
        setFirstName(sanitizedInput);
        setButtonEnabled(true);
    };

    const handleLastNameInput = (e: KeyboardEvent) => {
        const sanitizedInput = sanitizeInputFieldValue(e, "accepted-text-chars");
        setLastName(sanitizedInput);
        setButtonEnabled(true);
    };

    const handleAliasInput = (e: KeyboardEvent) => {
        const sanitizedInput = sanitizeInputFieldValue(e, "accepted-text-chars");
        setAlias(sanitizedInput);
        setButtonEnabled(true);
    };

    const handleNationalityChange = (e: MouseEvent) => {
        setNationality((e.target as HTMLSelectElement).value);
        setButtonEnabled(true);
    };

    const handleDocTypeChange = (e: MouseEvent) => {
        setDocumentType((e.target as HTMLSelectElement).value as Cug2bDocumentType);
        setButtonEnabled(true);
    };

    const handleDateOfBirthChange = (value: Dayjs) => {
        setDateOfBirth(value);
        setButtonEnabled(true);
    };

    const formElem = useRef<HTMLFormElement>(undefined);
    const docNumberField = useRef<HTMLInputElement>(undefined);

    const cug2AppContext = useCug2AppContext();

    const [alias, setAlias] = useState<string>(props.vm?.Alias || "");
    const [documentId, setDocumentId] = useState<string>(props.vm?.DocumentId || "");
    const [documentType, setDocumentType] = useState<Cug2bDocumentType>(props.vm?.DocumentType || "RUT");
    const [firstName, setFirstName] = useState<string>(props.vm?.FirstName || "");
    const [groupId, setGroupId] = useState<number>(getInitialGroupId());
    const [lastName, setLastName] = useState<string>(props.vm?.LastName || "");
    const [nationality, setNationality] = useState<string>(props.vm?.Nationality || cug2AppContext.Country);
    const [showUniquenessError, setShowUniquenessError] = useState<boolean>(false);
    const [buttonEnabled, setButtonEnabled] = useState<boolean>(false);
    const [dateOfBirth, setDateOfBirth] = useState<dayjs.Dayjs>(props.vm?.DOB);

    const minDate = useMemo(() => dayjs().subtract(99, "year").add(1, "day"), []);
    const maxDate = useMemo(() => dayjs().subtract(18, "year"), []);

    const birthDate = useCompactDatepicker({
        index: 0,
        isRequired: true,
        label: i18next.t("DateOfBirthLabel"),
        maxDate,
        minDate,
        opensToModal: true,
        type: "date-of-birth",
        value: dateOfBirth,
        onChange: handleDateOfBirthChange,
    });

    const form = useForm({
        customAttributes: [...commonValidationRules(), ...customValidationRules()],
        noScroll: true,
    });

    useEffect(() => form.init(formElem.current), [formElem.current]);

    useEffect(handleDocNumberInput, [documentType]);

    const firstNameTemplate = () => html`
        <div class="col-xs-1 col-sm-1-3">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                <label class="mdl-textfield__label">${i18next.t("V2-FirstNameLabel")}</label>
                <input
                    type="text"
                    class="mdl-textfield__input js-input"
                    data-test-id=${T.CUG2_ADD_TRAVEL_PARTNER.FIRST_NAME_INPUT_FIELD}
                    data-required
                    data-validation="PartnerForm.FirstName"
                    value=${firstName}
                    @input=${handleFirstNameInput}
                />
            </div>
        </div>
    `;

    const lastNameTemplate = () => html`
        <div class="col-xs-1 col-sm-1-3">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                <label class="mdl-textfield__label">${i18next.t("V2-LastNameLabel")}</label>
                <input
                    type="text"
                    class="mdl-textfield__input js-input"
                    data-test-id=${T.CUG2_ADD_TRAVEL_PARTNER.LAST_NAME_INPUT_FIELD}
                    data-required
                    data-validation="PartnerForm.LastName"
                    value=${lastName}
                    @input=${handleLastNameInput}
                />
            </div>
        </div>
    `;

    const dateOfBirthTemplate = () => html` <div class="col-xs-1 col-sm-1-3">${birthDate.htmlTemplate()}</div> `;

    const nationalityOptionsTemplate = () =>
        cug2AppContext.Countries.map(
            (c) => html` <option value=${c.Value} ?selected=${c.Value === nationality}>${c.Text}</option> `,
        );

    const nationalityTemplate = () => html`
        <div class="col-xs-1 col-sm-1-3">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                <label class="mdl-textfield__label">${i18next.t("Nacionalidad")}</label>
                <select
                    class="mdl-textfield__input js-input js-select"
                    data-test-id=${T.CUG2_ADD_TRAVEL_PARTNER.NATIONALITY_SELECTOR}
                    @change=${handleNationalityChange}
                >
                    ${nationalityOptionsTemplate()}
                </select>
            </div>
        </div>
    `;

    const docTypeTemplate = () => html`
        <div class="col-xs-1 col-sm-1-3">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                <label class="mdl-textfield__label">${i18next.t("Tipo de documento")}</label>
                <select
                    class="mdl-textfield__input js-input js-select"
                    data-test-id=${T.CUG2_ADD_TRAVEL_PARTNER.DOCUMENT_TYPE_SELECTOR}
                    @change=${handleDocTypeChange}
                >
                    <option value="RUT" ?selected=${documentType === "RUT"}>${i18next.t("RUT")}</option>
                    <option value="PAS" ?selected=${documentType === "PAS"}>${i18next.t("Pasaporte")}</option>
                    <option value="DNI" ?selected=${documentType === "DNI"}>${i18next.t("DNI")}</option>
                </select>
            </div>
        </div>
    `;

    const docNumberTemplate = () => html`
        <div class="col-xs-1 col-sm-1-3">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                <label class="mdl-textfield__label">${i18next.t("Numero de documento")}</label>
                <input
                    ref=${ref(docNumberField)}
                    type="text"
                    value=${documentId}
                    class="mdl-textfield__input js-input"
                    ?dni=${documentType === "DNI"}
                    ?rut=${documentType === "RUT"}
                    data-test-id=${T.CUG2_ADD_TRAVEL_PARTNER.DOCUMENT_NUMBER_INPUT_FIELD}
                    @input=${handleDocNumberInput}
                />
            </div>
            ${uniquenessErrorTemplate()}
        </div>
    `;

    const uniquenessErrorTemplate = () =>
        showUniquenessError
            ? html` <div class="error-message-container"><span>${i18next.t("Passengers-UniquenessError")}</span></div> `
            : "";

    const aliasTemplate = () => html`
        <div class="col-xs-1 col-sm-1-3">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label">
                <label class="mdl-textfield__label">${i18next.t("Alias")}</label>
                <input
                    type="text"
                    class="mdl-textfield__input js-input"
                    data-test-id=${T.CUG2_ADD_TRAVEL_PARTNER.NICKNAME_INPUT_FIELD}
                    data-validation="PartnerForm.Alias"
                    value=${alias}
                    @input=${handleAliasInput}
                />
            </div>
        </div>
    `;

    const groupOptionsTemplate = () => html`
        <option ?selected=${props.model.Groups.length === 0 || !groupId}>${i18next.t("No asignar a un grupo")}</option>
        ${props.model.Groups.map((g) => html` <option value=${g.Id} ?selected=${g.Id === groupId}>${g.Name}</option> `)}
    `;

    const groupTemplate = () => html`
        <div class="col-xs-1 col-sm-1-3">
            <div class="mdl-textfield mdl-js-textfield mdl-textfield--floating-label js-select-arrow">
                <label class="mdl-textfield__label">${i18next.t("Grupo")}</label>
                <select
                    class="mdl-textfield__input js-input js-select"
                    data-test-id=${T.CUG2_ADD_TRAVEL_PARTNER.GROUP_SELECTOR}
                    @change=${handleGroupIdChange}
                >
                    ${groupOptionsTemplate()}
                </select>
            </div>
        </div>
    `;

    const backButtonTemplate = () =>
        props.showBackButton
            ? html`
                  <div class="hidden-xs flex w-full justify-start">
                      <div
                          class="travel-partner-back-btn-container"
                          data-test-id=${T.CUG2_ADD_TRAVEL_PARTNER.BACK_BUTTON}
                          @click=${() => window.history.back()}
                      >
                          <i class="js-icon js-circle-chevron-right"></i>
                          <span>${i18next.t("Volver")}</span>
                      </div>
                  </div>
              `
            : "";

    const cancelButtonTemplate = () =>
        props.showBackButton
            ? html`
                  <button
                      class="rounded-primary-btn cug2b-white mb-4 sm:mb-0 sm:mr-4"
                      data-test-id=${T.CUG2_ADD_TRAVEL_PARTNER.CANCEL_BUTTON}
                      @click=${() => window.history.back()}
                  >
                      ${i18next.t("Cancelar")}
                  </button>
              `
            : "";

    const buttonsTemplate = () => {
        const btnClassMap = classMap({
            "rounded-primary-btn": true,
            "disabled": !buttonEnabled,
        });
        return html`
            <div class="mt-2 flex w-full flex-col justify-end sm:flex-row md:mt-4">
                ${backButtonTemplate()} ${cancelButtonTemplate()}
                <button
                    class=${btnClassMap}
                    data-test-id=${T.CUG2_ADD_TRAVEL_PARTNER.SUBMIT_BUTTON}
                    @click=${handleSubmit}
                >
                    ${i18next.t("Guardar")}
                </button>
            </div>
        `;
    };

    return html`
        <form ref=${ref(formElem)} class="travel-partner-form">
            <div class="row">
                ${firstNameTemplate()} ${lastNameTemplate()} ${dateOfBirthTemplate()} ${nationalityTemplate()}
                ${docTypeTemplate()} ${docNumberTemplate()} ${aliasTemplate()} ${groupTemplate()}
            </div>
        </form>
        ${buttonsTemplate()} ${updateMdl()}
    `;
};
