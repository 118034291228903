import { html } from "haunted";
import i18next from "i18next";
import { unsafeHTML } from "lit-html/directives/unsafe-html";
import { CugUser } from "../../../component-models/CUG2b/CugUserData";
import { ROUTES } from "../../../shared/apiRoutes";
import { handleCugLoader } from "../../../shared/common";
import { useAjax } from "../../../shared/customHooks/useAjax/useAjax";

export interface Props {
    rootElement: HTMLElement;
    gridElement: HTMLElement;
    userToResetPassword: CugUser;
    setUserToResetPassword: (user: CugUser) => void;
    updateDataState: () => Promise<void>;
}

export const useResetPasswordModal = (props: Props) => {
    const { ajaxRequest } = useAjax();

    // EVENT-HANDLERS

    const onPasswordReset = async () => {
        const userId = props.userToResetPassword?.Username;
        props.setUserToResetPassword(undefined);
        handleCugLoader(props.gridElement, "loadData");
        await ajaxRequest({
            body: { "forgotMemberPassword.Username": userId },
            container: props.rootElement,
            url: ROUTES.SendForgetPasswordMail,
        });
        handleCugLoader(props.gridElement, "loadData");
        await props.updateDataState();
    };

    // TEMPLATES

    const modalHeaderTemplate = () => html`
        <i class="js-icon js-question-broken-circle big-modal-icon absolute left-0 ml-8 vertical-center"></i>
        <span>${i18next.t("¿Estás seguro?")}</span>
    `;

    const resetPasswordModalContentTemplate = () => html`
        <div class="p-8">
            <div class="cug2b-modal-question mb-4 sm:mb-8">
                ${unsafeHTML(
                    i18next.t("¿Estás seguro que quieres {{-spanStart}}restablecer{{-spanEnd}} la contraseña?", {
                        spanStart: "<span>",
                        spanEnd: "</span>",
                    }),
                )}
            </div>
            <div class="cug2b-modal-question">${i18next.t("User Name")}: ${props.userToResetPassword?.Username}</div>
            <div class="cug2b-modal-question">
                ${i18next.t("Nombre y apellido")}: ${props.userToResetPassword?.FirstName}
                ${props.userToResetPassword?.LastName}
            </div>
            <div class="mt-8 flex flex-col items-center justify-center sm:mt-16 sm:flex-row">
                <button
                    class="rounded-secondary-btn mb-4 sm:mb-0 sm:mr-8"
                    @click=${() => props.setUserToResetPassword(undefined)}
                >
                    ${i18next.t("Cancelar")}
                </button>
                <button class="rounded-primary-btn" @click=${() => onPasswordReset()}>${i18next.t("Aceptar")}</button>
            </div>
        </div>
    `;

    const htmlTemplate = () => html`
        <ac-cug-modal
            .canBeClosed=${true}
            .content=${resetPasswordModalContentTemplate()}
            .customClass=${"cug2b-reset-password-modal"}
            .header=${modalHeaderTemplate()}
            .isOpen=${Boolean(props.userToResetPassword)}
            @close=${() => props.setUserToResetPassword(undefined)}
        ></ac-cug-modal>
    `;

    return { htmlTemplate };
};
